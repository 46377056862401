import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class GstrReportService {

    constructor(private _http: HttpClient) { }

    gstrreport(data) {
        return this._http.post(environment.api_url + 'seller/report/myinvoice', data);
    }

}