<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<h3 class="fs24 wt700 txtdefault">Transaction Report</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Wallet</a>
						</li>
						<li class="breadcrumb-item active">Transaction Report</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:void(0);" class="mr-sm-4 btn-d-outline d-inline-block" (click)="gettransaction('yes')">
					<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="export_loading"></span>
					&nbsp; Export
				</a>
				<a href="{{ export_path }}"
					*ngIf="export_path"
					class="btn btn-default"
					target="_blank"
					download="Seller.csv"
					>Download Now
				</a>
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4 mb-0">

			<div class="row d-flex justify-content-between mb-20 report-cta">
				<div class="col-md-4">
					<div class="report-input">
                        <div class="input-group-custom">
                            <input type="text" placeholder="Search by Order ID, Sender ID, Receiver ID, Sender Name & Receiver Name"
                                class="form-control" [(ngModel)]="search_term" (change)="gettransaction()">
                            <div class="input-group-append-custom">
                                <img src="assets/images/svg/search_logo.svg">
                            </div>
                        </div>
                    </div>
				</div>

				<div class="col-md-8">
					<div class="row">
						<div class="col-md-4 pr-0">
                            <div class="form-container m-0">
                                <div class="report-input floating-label">
                                    <div class="input-group-custom">
                                        <input type="text" [(ngModel)]="from_date" (ngModelChange) = "gettransaction()" class="form-control" placeholder=" " ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" autocomplete="off"/>
                                        <span class="highlight"></span>
                                        <label>From Date</label>
                                        <div class="input-group-append-custom">
                                            <img src="assets/images/icons/filter-calendar.svg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4 pr-0">
                            <div class="form-container m-0">
                                <div class="report-input floating-label">
                                    <div class="input-group-custom">
                                        <input type="text" [(ngModel)]="to_date" (ngModelChange) = "gettransaction()" class="form-control" placeholder=" " ngbDatepicker #dd="ngbDatepicker" (click)="dd.toggle()" autocomplete="off"/>
                                        <span class="highlight"></span>
                                        <label>To Date</label>
                                        <div class="input-group-append-custom">
                                            <img src="assets/images/icons/filter-calendar.svg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

						<div class="col-md-4 pr-0">
							<div class="tpbr auto-w">
								<label class="fs12 txtseconday tpbrlbl">Payment Type</label>
								<ng-selectize [config]="config" [options] = "data" placeholder="" [(ngModel)]="payment_type" (ngModelChange)="gettransaction()">
								</ng-selectize>	
							</div>
						</div>
						
						<!-- <div class="col-md-3">
							<a href="javascript:void(0);" class="btn-d-outline d-inline-block">
								<svg xmlns="http://www.w3.org/2000/svg" width="12.681" height="17.061" viewBox="0 0 12.681 17.061">
								  <g id="np_export_2535025_000000" transform="translate(-32.121 -25.962)">
								    <path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#ff7135"/>
								    <path id="Path_2356" data-name="Path 2356" d="M44.093,70.07H32.83a.709.709,0,1,0,0,1.419H44.093a.709.709,0,0,0,0-1.419Z" transform="translate(0 -28.466)" fill="#ff7135"/>
								  </g>
								</svg>

								Export
							</a>
						</div> -->
					</div>
				</div>
			</div>

			<div class="fs20 f-700">{{ total_records }} transactions </div>

			<table class="table custom-table">
				<thead>
					<tr>
						<th>Sr. no.</th>
						<th>Transaction Id</th>
						<th>Order Id</th>
						<th>Sender Id</th>
						<th>Sender Name</th>
						<th>Receiver Id</th>
						<th>Receiver Name</th>
						<th>Amount (in ₹)</th>
						<th>Transaction Date</th>
						<!-- <th>Balance</th> -->
					</tr>
				</thead>
				<tbody>
					<!--Empty State-->
					<tr *ngIf="!transaction_records.length">
						<td colspan="9">
							<div class="row pt-40 pb-40">
								<div class="col-md-12 text-center">
									<div class="mb-20"><img src="/assets/images/empty-state.png">
									</div>
									<div class="fs16">There are no records to display!</div>
								</div>
							</div>
						</td>
					</tr>
					<!--End Empty State-->
					<tr *ngFor="let record of transaction_records; let i = index;">
						<td>{{ i+1 }}</td>
						<td>{{ record.transaction_id }}</td>
						<td *ngIf="record.meta_id"><a href="javascript:;" class="link-2 color-orange" [routerLink]="['/admin/ordderdetails/'+record.meta_id]">{{ record.order_id }}</a></td>
						<td *ngIf="!record.meta_id">--</td>
						<td>{{ record.sender_id }}</td>
						<td>{{ record.sender_name}}</td>
						<td>{{ record.receiver_id }}</td>
						<td>{{ record.receiver_name }}</td>
						<td>{{ record.amount_type == 'out'?'- '+record.amount:record.amount }}</td>
						<td>{{ record.transaction_date }}<br>{{ record.transaction_time }}</td>
						<!-- <td>1,23,455</td> -->
					</tr>

				</tbody>
			</table>
		</div>
		<div class="row mb-50" *ngIf="transaction_pagination.totalPages> 1">
            <div class="col-md-6">
                <div class="text-left pl-3">
                    <nav class="mt-3 tbpg rows-per-page">
                        <div>Rows per page:</div>
                        <select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
                            <option *ngFor="let option of page_per_view_options" [ngValue]="option.value">
                                {{option.name}}</option>
                        </select>
                    </nav>
                </div>
            </div>
            <div class="col-md-6">
                <div class="text-right pr-3">
                    <nav class="mt-3 tbpg">
                        <ul class="pagination align-items-center">
                            <li class="page-item" [ngClass]="{disabled:transaction_pagination.currentPage === 1}"><a
                                    class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a>
                            </li>

                            <li class="page-item" [ngClass]="{disabled:transaction_pagination.currentPage === 1}"><a
                                    class="page-link sprevicon" href="javascript:;"
                                    (click)="setpage(transaction_pagination.currentPage - 1)"></a></li>

                            <li class="page-item" i *ngFor="let page of transaction_pagination.pages"
                                [ngClass]="{active:transaction_pagination.currentPage === page}"><a class="page-link"
                                    href="javascript:;" (click)="setpage(page)">{{page}}</a></li>

                            <li *ngIf="(transaction_pagination.totalPages - transaction_pagination.startPage) > 5 ">
                                <a (click)="setpage(transaction_pagination.startPage + 5)">...</a>
                            </li>
                            <li *ngIf="(transaction_pagination.totalPages - transaction_pagination.startPage) > 5">
                                <a
                                    (click)="setpage(transaction_pagination.totalPages)">{{transaction_pagination.totalPages}}</a>
                            </li>

                            <li class="page-item"
                                [ngClass]="{disabled:transaction_pagination.currentPage === transaction_pagination.totalPages}">
                                <a class="page-link snexticon" href="javascript:;"
                                    (click)="setpage(transaction_pagination.currentPage + 1)"></a>
                            </li>
                            <li class="page-item"
                                [ngClass]="{disabled:transaction_pagination.currentPage === transaction_pagination.totalPages}">
                                <a class="page-link nexticon" href="javascript:;"
                                    (click)="setpage(transaction_pagination.totalPages)"></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
	</div>
</div>