<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain pb-4">
	<div class="container pb-4">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-12">
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);" [routerLink]="['/admin/product-listing']">My Products  </a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" [routerLink]="['/admin/product-listing']">My Listings</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" [routerLink]="['/admin/product-listing']">Samsung Galaxy M21 2021 Edition… </a>
						</li>
						<li class="breadcrumb-item active">Edit Listing</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				&nbsp;
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="row d-flex align-items-center mb-20">
			<div class="col-sm-6 mb-2">
				<h3 class="f-700 fs22 txtdefault pb-0"><a href="javascript:;" class="go-back"><i class="fa fa-angle-left"></i></a> Edit Listing</h3>
			</div>
			<div class="col-sm-6 text-md-right text-center pl-0">
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10">
					<svg xmlns="http://www.w3.org/2000/svg" width="17.391" height="12.976" viewBox="0 0 17.391 12.976">
					  <g id="Icon_feather-eye" data-name="Icon feather-eye" transform="translate(-0.9 -5.4)">
					    <path id="Path_10492" data-name="Path 10492" d="M1.5,11.888S4.444,6,9.6,6s8.1,5.888,8.1,5.888-2.944,5.888-8.1,5.888S1.5,11.888,1.5,11.888Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
					    <path id="Path_10493" data-name="Path 10493" d="M17.916,15.708A2.208,2.208,0,1,1,15.708,13.5,2.208,2.208,0,0,1,17.916,15.708Z" transform="translate(-6.112 -3.82)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
					  </g>
					</svg>
					Buyer preview
				</a>
			</div>
		</div>
		<div class="el-crousal">
			<div class="row mb-4 pb-3">
				<div class="col-sm-8 ">
					<div class="bid-order-info">
						<div class="order-img">
							<div class="feedback-slides  ">
							   <div class="client-feedback dtlfull">
									<ngx-slick-carousel 
										class="carousel feedback" 
										#slickModal="slick-carousel" 
										[config]="imagesSlider">
										<div ngxSlickItem *ngFor="let slide of slides" class="slide">
												<img src="{{ slide.img }}" alt="" >
										</div>
									</ngx-slick-carousel>
								</div>
							</div>

						</div>
						<div class="pd-seller-cntnt">
							<!--Product Name-->
							<h5 class="mb-10 fs16 wt700">Vivo X60 Pro (Midnight Black, 12GB RAM, 256GB Storage) | Upto 12 Months No Cost EMI | Extra 4000 Off on Exchange</h5>
							
							<!--Edit / Delete-->
							<div class="text-left light-text mt-10">
								
								<a (click)="openWindowCustomClass(content)" href="javascript:;" ><img src="assets/images/icons/delete-icon.svg"></a>
							</div>
						</div>
					</div>
				</div>
				<div class="col-sm-4">
					<div class="d-flex align-items-center justify-content-end">
						&nbsp; Listing Status &nbsp;&nbsp;
						<div class="tglbtn dactivestatus inactivedata">
							<label class="switch">
							  <input type="checkbox">
							  <span class="slider round"></span>
							</label>
						</div>
					</div>
				</div>
			</div>
		
			<div class="client-thumbnails dtlthmb text-center">
				 <ngx-slick-carousel 
					class="carousel thumbs" 
					#slickModal="slick-carousel" 
					[config]="thumbnailsSlider ">
					<div ngxSlickItem *ngFor="let slide of slides" class="slide">
						<div class="thmbnaildtl">
							<img src="{{ slide.img }}" alt="" width="100%">
						</div>	
					</div>
				</ngx-slick-carousel>
			</div>
		</div>	
		<!-- TABING -->
		<div class="add-pro-wrap">
			<a href="javascript:void(0);" class="btn-d-outline d-inline-block addltpro">
				<svg xmlns="http://www.w3.org/2000/svg" width="14.681" height="14.681" viewBox="0 0 14.681 14.681">
				  <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1 1)">
					<path id="Path_8" data-name="Path 8" d="M18,7.5V20.181" transform="translate(-11.659 -7.5)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					<path id="Path_9" data-name="Path 9" d="M7.5,18H20.181" transform="translate(-7.5 -11.659)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
				  </g>
				</svg>
				Add/Delete Product Variant
				
			</a>
			<ul ngbNav #prodtlnav="ngbNav" class="nav-tabs mbltype">
				<li [ngbNavItem]="21">
					<a ngbNavLink>4GB + 64GB (Blue)</a>
					<ng-template ngbNavContent>
						<div class="row">
							<!-- RIGHT COL -->  
							<div class="col-md-12 ad-p-rgt">
								<div class="card card-ui p-0">
									<div class="card-body add-pro-wrap pt-0 p-4">
										<ul ngbNav #prdctinfonav="ngbNav" [(activeId)]="active"  class="nav-tabs">
											<!-- Consent -->
											<li [ngbNavItem]="9">
												<a ngbNavLink>Consent</a>
												<ng-template ngbNavContent>
													<div class="profileipt editlisting">
														<div class="d-flex align-items-center justify-content-end mt-20">
															Variant Status &nbsp;&nbsp;
															<div class="tglbtn dactivestatus inactivedata">
																<label class="switch">
																  <input type="checkbox">
																  <span class="slider round"></span>
																</label>
															</div>
														</div>
														<div class="form-container">
															<div class="mb-4 pb-2 staticlabel">
																<div class="css-check mb-2">
																	<input name="saletype" class="css-checkbx" type="checkbox" />
																	<label  class="css-label txtdefault wt700">I have a legit Purchase Invoice / Brand Authorization letter. I will be able to share the same as when asked.
																	</label>
																</div>		  						
															</div>
															<!-- INPUT ROW -->
															<div class="floating-label mb-10 flselcted"> 
																<input type="text" class="form-control" placeholder=" ">
																<button class="btn btn-outline-secondary fs16 btn-o-default" type="button">
																	Replace
																	

																</button>	  
															   <span class="highlight"></span>
															   <label>Upload Document</label>
															   <div class="docselected"><img src="assets/images/svg/attach.svg"><span>GST Document.pdf</span></div>
															   <div class="fs14 pt-2 wt500 txtseconday">
																Upload Document of Purchase Invoice/ Brand authorization letter
															   </div>
															</div>
															<!-- /INPUT ROW -->
															<div class="pt-4">
																<button type="button" class="btn buybtn fs16 radius10 ripple">Next</button>
															</div>
														</div>	
													</div>		
												</ng-template> 
											</li>
											<!-- /Consent -->
										  <li [ngbNavItem]="10">
											<a ngbNavLink>Unique ID</a>
											<ng-template ngbNavContent>
												<div class="d-flex align-items-center justify-content-end mt-20">
													Variant Status &nbsp;&nbsp;
													<div class="tglbtn dactivestatus inactivedata">
														<label class="switch">
														  <input type="checkbox">
														  <span class="slider round"></span>
														</label>
													</div>
												</div>

												<div class="form-container">	
													<div class="row">
														<div class="col-md-6">
															<div class="floating-label">      
															  <input placeholder=" " disabled="" class="form-control" value="EB123A4567890O1" >
															  <span class="highlight"></span>
															  <label>Equibiz Product Identification Number (EBPID)</label>
															   <i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert"></i>
															</div>
														</div>

														<div class="col-md-6">
															<div class="floating-label">      
															  <input placeholder=" " class="form-control" value="KS90528TUT" >
															  <span class="highlight"></span>
															  <label>SKU</label>
															   <i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert"></i>
															</div>
														</div>

														<div class="col-md-6">
															<div class="floating-label">      
															  <input placeholder=" " class="form-control" value="18001250" >
															  <span class="highlight"></span>
															  <label>HSN</label>
															   <i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert"></i>
															</div>
															<div><a href="javascript:;" class="link-alt fs14">Find relevant HSN Code</a></div>
														</div>

														<div class="col-md-6">
															<div class="floating-label">      
															  <input placeholder=" " class="form-control" value="KS90528TUT" >
															  <span class="highlight"></span>
															  <label>Compulsory Registration Scheme (CRS)</label>
															   <i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert"></i>
															</div>
														</div>
													</div>
												</div> 

												<a href="javascript:;" class="btn buybtn fs16 radius10 ripple">Next</a>
											</ng-template>
										  </li>
										  <li [ngbNavItem]="11">
											<a ngbNavLink>Product Details</a>
											<ng-template ngbNavContent>
											 	<div class="d-flex align-items-center justify-content-end mt-20">
													Variant Status &nbsp;&nbsp;
													<div class="tglbtn dactivestatus inactivedata">
														<label class="switch">
														  <input type="checkbox">
														  <span class="slider round"></span>
														</label>
													</div>
												</div>

												<div class="form-container">
													<div class="row">
														<div class="col-md-6">
															<div class="floating-label">      
															  <input placeholder=" " disabled="" class="form-control" value="₹ 10,999" >
															  <span class="highlight"></span>
															  <label>Market Operated Price</label>
															   <i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert"></i>
															</div>
														</div>

														<div class="col-md-6">
															<div class="floating-label">      
															  <input placeholder=" " class="form-control" value="₹ 10,999" >
															  <span class="highlight"></span>
															  <label>SKU</label>
															   <i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert"></i>
															</div>
														</div>

														<div class="col-md-6">
															<div class="floating-label">      
															  <select class="form-control">
															   	<option selected="selected" value="">India</option>
															   		<option>3</option>
																</select>
															   <span class="highlight"></span>
															   <label>Country of Origin<span class="req">*</span></label>
															</div>
														</div>

														<div class="col-md-6">
															<div class="floating-label">      
															  <select class="form-control">
															   	<option selected="selected" value="">2</option>
															   		<option>3</option>
																</select>
															   <span class="highlight"></span>
															   <label>Pack of<span class="req">*</span></label>
															</div>
														</div>
													</div>
												</div> 

												<hr class="mt-0 mb-30">

												<h4 class="fs16 f-700 mb-4">Product Dimensions</h4>

												<div class="form-container">
													<div class="row">
														<div class="col-md-6">
															<div class="row">
																<div class="col-md-4">
																	<div class="floating-label">      
																	  <input placeholder=" " class="form-control" value="328 cm" >
																	  <span class="highlight"></span>
																	  <label>Length</label>
																	   <i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert"></i>
																	</div>
																</div>
																<div class="col-md-4">
																	<div class="floating-label">      
																	  <input placeholder=" " class="form-control" value="128 cm" >
																	  <span class="highlight"></span>
																	  <label>Width</label>
																	   <i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert"></i>
																	</div>
																</div>
																<div class="col-md-4">
																	<div class="floating-label">      
																	  <input placeholder=" " class="form-control" value="328 cm" >
																	  <span class="highlight"></span>
																	  <label>Height</label>
																	   <i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert"></i>
																	</div>
																</div>
															</div>
														</div>
														<div class="col-md-6">
															<div class="floating-label">      
															  <input placeholder=" " class="form-control" value="228 grams" >
															  <span class="highlight"></span>
															  <label>Dead Weight</label>
															   <i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert"></i>
															</div>
														</div>
													</div>
												</div> 

												<hr class="mt-0 mb-30">

												<h4 class="fs16 f-700 mb-4">Warranty</h4>

												<!-- FORM BLOCK REPEAT -->
												<div class="form-container">
													<div class="row">
														<div class="col-sm-8 mb-4 col-12 col-xl-9">
															<div class="floating-label mb-0">      
															  <input class="form-control pr-5" placeholder=" ">
															  <span class="highlight"></span>
															  <label>Domestic Warranty</label>
															  <i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert"></i>
															</div>
														</div>
														<div class="col-sm-4 mb-4 col-12 pl-sm-0 col-xl-3 selectactive">
															<ng-selectize [config]="config" [options] = "monthdata" placeholder="In Months">		</ng-selectize>	
														</div>
													</div>
													<div class="row">
														<div class="col-sm-8 mb-4 col-12 col-xl-9">
															<div class="floating-label mb-0">      
															  <input class="form-control pr-5" placeholder=" ">
															  <span class="highlight"></span>
															  <label>International Warranty</label>
															  <i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert"></i>
															</div>
														</div>
														<div class="col-sm-4 mb-4 col-12 pl-md-0 col-xl-3 selectactive">
															<ng-selectize [config]="config" [options] = "monthdata" placeholder="In Months">		</ng-selectize>	
														</div>
													</div>
													<div class="floating-label">      
													  <input  class="form-control pr-5" placeholder=" ">
													  <span class="highlight"></span>
													  <label>Warranty Summary</label>
													  <i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert"></i>
													</div>
													<div class="floating-label">      
													  <input  class="form-control pr-5" placeholder=" ">
													  <span class="highlight"></span>
													  <label>Warranty Service Type</label>
													  <i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert"></i>
													</div>
												</div> 
												<!-- /FORM BLOCK REPEAT -->
												<div class="custom-control custom-checkbox mb-0">
													<input type="checkbox" class="custom-control-input"
														id="agrcustomCheckBox" name="checkbox1">
													<label class="custom-control-label" for="agrcustomCheckBox">
														Warranty facilitated by Brand
													</label>
												</div>

												<div class="mt-40"><input type="submit" value="Save and Next" class="btn px-4 buybtn fs16 radius10 "/></div>
											</ng-template>
										  </li>
										  <li [ngbNavItem]="12">
											<a ngbNavLink>Marketplace</a>
											<ng-template ngbNavContent class="slrtypeui">
											  	<div class="d-flex align-items-center justify-content-end mt-20">
													Variant Status &nbsp;&nbsp;
													<div class="tglbtn dactivestatus inactivedata">
														<label class="switch">
														  <input type="checkbox">
														  <span class="slider round"></span>
														</label>
													</div>
												</div>
												<div class="form-container form-ipt-info lstnobd">
													<div class="mb-2">
														<div class="css-check mb-2">
															<input name="saletype" class="css-checkbx" type="radio" />
															<label  class="css-label txtdefault wt700">Wholesale
															<p class="wt500">Lorem ipsum dollar sit amet, consuecatur elit, sed do elumsod tempor inodient ut</p>
															</label>
														</div>								
													</div>
													<div class="mb-2">
														<div class="css-check mb-3">
															<input name="saletype" class="css-checkbx" type="radio" />
															<label class="css-label txtdefault wt700">Bulk
															<p class="wt500">Lorem ipsum dollar sit amet, consuecatur elit, sed do elumsod tempor inodient ut</p>											
															</label>
														</div>	
														<div class="css-check ml-4">
															<input name="saletype"  class="css-checkbx" type="radio" />
															<label class="css-label txtdefault wt700">Bid & Offers
															<p class="wt500">Lorem ipsum dollar sit amet, consuecatur elit, sed do elumsod tempor inodient ut</p>
															</label>
														</div>
													</div>
												</div>
												<div class="mt-20"><input type="submit" value="Save and Next" class="btn px-4 buybtn fs16 radius10 "/></div>
											</ng-template>
										  </li>
										  <li [ngbNavItem]="13" >
											<a ngbNavLink>Stock & Pricing</a>
											<ng-template ngbNavContent>

												<div class="d-flex align-items-center justify-content-end mt-20">
													Variant Status &nbsp;&nbsp;
													<div class="tglbtn dactivestatus inactivedata">
														<label class="switch">
														  <input type="checkbox">
														  <span class="slider round"></span>
														</label>
													</div>
												</div>

												<h4 class="fs16 f-700 mb-4">Wholesale</h4>

												<!-- FORM BLOCK REPEAT -->
												<div class="form-container">
													<div class="row">
														<div class="col-md-6">
															<div class="floating-label">      
															  <input class="form-control pr-5" placeholder=" " value="₹ 9,999">
															  <span class="highlight"></span>
															  <label>Listed Price</label>
															  <i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert"></i>
															</div>
														</div>

														<div class="col-md-6">
															<div class="row">
																<div class="col-md-6">
																	<div class="floating-label">      
																	  <input class="form-control pr-5" placeholder=" " value="300">
																	  <span class="highlight"></span>
																	  <label>Total Stock Quantity</label>
																	  <i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert"></i>
																	</div>
																</div>
																<div class="col-md-6">
																	<div class="floating-label">      
																	  <input class="form-control pr-5" placeholder=" " value="10">
																	  <span class="highlight"></span>
																	  <label>Minimum Order Quantity</label>
																	  <i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert"></i>
																	</div>
																</div>
															</div>
														</div>

													</div>
												</div>

												<hr class="mt-0 mb-30">

												<h4 class="fs16 f-700 mb-4">Bulk</h4>

												<!-- FORM BLOCK REPEAT -->
												<div class="form-container">
													<div class="row">
														<div class="col-md-6">
															<div class="floating-label">      
															  <input class="form-control pr-5" placeholder=" " value="₹ 9,999">
															  <span class="highlight"></span>
															  <label>Listed Price</label>
															  <i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert"></i>
															</div>

															<div class="floating-label">      
															  <input class="form-control pr-5" placeholder=" " value="₹ 8,999">
															  <span class="highlight"></span>
															  <label>Lowest Bid Price (Bids & Offers)</label>
															  <i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert"></i>
															</div>

														</div>

														<div class="col-md-6">
															<div class="row">
																<div class="col-md-6">
																	<div class="floating-label">      
																	  <input class="form-control pr-5" placeholder=" " value="300">
																	  <span class="highlight"></span>
																	  <label>Total Stock Quantity</label>
																	  <i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert"></i>
																	</div>
																</div>
																<div class="col-md-6">
																	<div class="floating-label">      
																	  <input class="form-control pr-5" placeholder=" " value="50">
																	  <span class="highlight"></span>
																	  <label>Minimum Order Quantity</label>
																	  <i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert"></i>
																	</div>
																</div>
															</div>
														</div>

													</div>
												</div>

												<div class="mt-20"><input type="submit" value="Save and Next" class="btn px-4 buybtn fs16 radius10 "/></div>
											</ng-template>
										  </li>
										  <li [ngbNavItem]="14" >
											<a ngbNavLink>Payment & Shipment</a>
											<ng-template ngbNavContent>
												<div class="d-flex align-items-center justify-content-end mt-20">
													Variant Status &nbsp;&nbsp;
													<div class="tglbtn dactivestatus inactivedata">
														<label class="switch">
														  <input type="checkbox">
														  <span class="slider round"></span>
														</label>
													</div>
												</div>

												<h4 class="fs16 f-700 mb-4">Payment Type (Bulk)</h4>

												<div class="custom-control custom-checkbox mb-20">
													<input type="checkbox" class="custom-control-input" checked="" id="agrcustomCheckBox" name="checkbox1">
													<label class="custom-control-label opacity-1" for="agrcustomCheckBox">
														<b>Full advance payment</b> <div class="fs12">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</div>
													</label>
												</div>

												<div class="custom-control custom-checkbox mb-20">
													<input type="checkbox" class="custom-control-input" checked="" id="agrcustomCheckBox" name="checkbox1">
													<label class="custom-control-label opacity-1" for="agrcustomCheckBox">
														<b>Token & Final payment before dispatch</b> &nbsp;&nbsp;<span class="pd-taglist"><span class="f-600 fs10 radius15">Equibiz Suggested</span></span> <div class="fs12">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</div>
													</label>
												</div>

												<div class="d-flex align-items-center">
													<div class="mr-2"><img src="assets/images/icons/info.png"></div>
													<div>For wholesale - full payment option by default</div>
												</div>

												<hr class="mt-30 mb-30">

												<h4 class="fs16 f-700 mb-4">Delivery Date</h4>

												<div class="floating-label mb-10 qs-chk-rp"> 
													<div class="row">
														<div class="col-md-12">
															<div class="cssinptchk">
																<span class="css-check">
																	<input class="css-checkbx"  name="expdlv" type="radio" checked="" />
																	<label class="css-label"></label>
																</span>
																<p>Ready to Ship</p>															
															</div>
														</div>
													
														<div class="col-md-12">
															<div class="cssinptchk">
																<span class="css-check">
																	<input class="css-checkbx"  name="expdlv" type="radio" />
																	<label class="css-label"></label>
																</span>
																<p>Dispatch in 24 hours</p>															
															</div>
														</div>
														<div class="col-md-12">
															<div class="cssinptchk">
																<span class="css-check">
																	<input class="css-checkbx"  name="expdlv" type="radio" />
																	<label class="css-label"></label>
																</span>
																<p>Dispatch in 48 hours</p>															
															</div>
														</div>
														<div class="col-md-12">
															<div class="cssinptchk">
																<span class="css-check">
																	<input class="css-checkbx"  name="expdlv" type="radio" />
																	<label class="css-label"></label>
																</span>
																<p>Dispatch after 2 days</p>															
															</div>
														</div>
													</div>
												</div>
												<div class="mt-20"><input type="submit" value="Save Changes" class="btn px-4 buybtn fs16 radius10 "/></div>
											</ng-template>
										  </li>
										</ul>

										<div [ngbNavOutlet]="prdctinfonav" class="mt-2"></div>
									</div>
								</div>	
							</div>
						<!-- /RIGHT COL -->
					</div>		
					</ng-template>
				</li>
				<li [ngbNavItem]="22">
					<a ngbNavLink>6GB + 128GB (Blue)</a>
					<ng-template ngbNavContent>
					  <p>Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four loko
						farm-to-table craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts
						ullamco ad vinyl cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus mollit. Keytar
						helvetica VHS salvia yr, vero magna velit sapiente labore stumptown. Vegan fanny pack odio cillum wes anderson
						8-bit, sustainable jean shorts beard ut DIY ethical culpa terry richardson biodiesel. Art party scenester
						stumptown, tumblr butcher vero sint qui sapiente accusamus tattooed echo park.</p>
					</ng-template>
				</li>
				<li [ngbNavItem]="23">
					<a ngbNavLink>8GB + 64GB (Black)</a>
					<ng-template ngbNavContent>
					  <p>Sed commodo, leo at suscipit dictum, quam est porttitor sapien, eget sodales nibh elit id diam. Nulla facilisi.
						Donec egestas ligula vitae odio interdum aliquet. Duis lectus turpis, luctus eget tincidunt eu, congue et odio.
						Duis pharetra et nisl at faucibus. Quisque luctus pulvinar arcu, et molestie lectus ultrices et. Sed diam urna,
						egestas ut ipsum vel, volutpat volutpat neque. Praesent fringilla tortor arcu. Vivamus faucibus nisl enim, nec
						tristique ipsum euismod facilisis. Morbi ut bibendum est, eu tincidunt odio. Orci varius natoque penatibus et
						magnis dis parturient montes, nascetur ridiculus mus. Mauris aliquet odio ac lorem aliquet ultricies in eget
						neque. Phasellus nec tortor vel tellus pulvinar feugiat.</p>
					</ng-template>
				</li>
				<li [ngbNavItem]="24">
					<a ngbNavLink>4GB + 64GB (Black)</a>
					<ng-template ngbNavContent>
					  <p>Sed commodo, leo at suscipit dictum, quam est porttitor sapien, eget sodales nibh elit id diam. Nulla facilisi.
						Donec egestas ligula vitae odio interdum aliquet. Duis lectus turpis, luctus eget tincidunt eu, congue et odio.
						Duis pharetra et nisl at faucibus. Quisque luctus pulvinar arcu, et molestie lectus ultrices et. Sed diam urna,
						egestas ut ipsum vel, volutpat volutpat neque. Praesent fringilla tortor arcu. Vivamus faucibus nisl enim, nec
						tristique ipsum euismod facilisis. Morbi ut bibendum est, eu tincidunt odio. Orci varius natoque penatibus et
						magnis dis parturient montes, nascetur ridiculus mus. Mauris aliquet odio ac lorem aliquet ultricies in eget
						neque. Phasellus nec tortor vel tellus pulvinar feugiat.</p>
					</ng-template>
				</li>
				
			</ul>
		</div>
		<div [ngbNavOutlet]="prodtlnav" class="mt-2"></div>
		<!-- /TABING -->

		
	</div>
</div>		
<!-- REMOVE PRODUCT MODAL -->  
<ng-template #content let-modal>
  <div class="modal-header nobd text-center pt-4 pb-0">
    <h4 class="modal-title fs28 txtdefault wt700 mx-auto pt-3">Remove Product</h4>
    <button type="button" class="btn-close close rmvmdl" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
	<div class="modal-body">
		<p class="fs12 txtseconday py-3 mb-2 text-center">
		<span class="mr-2">
			<svg id="Group_46" data-name="Group 46" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="7.5" cy="7.5" r="7.5" fill="#07f"/>
			  <path id="Path_2235" data-name="Path 2235" d="M7,8.171l-.128.523q-.576.227-.919.346a2.425,2.425,0,0,1-.8.119,1.591,1.591,0,0,1-1.084-.34,1.1,1.1,0,0,1-.387-.864,3.056,3.056,0,0,1,.029-.416q.029-.213.093-.48l.48-1.7q.064-.244.108-.462a2.01,2.01,0,0,0,.044-.4A.616.616,0,0,0,4.3,4.047a.759.759,0,0,0-.513-.128,1.345,1.345,0,0,0-.381.058c-.131.039-.242.076-.336.11L3.2,3.564q.471-.192.9-.329A2.7,2.7,0,0,1,4.916,3.1a1.552,1.552,0,0,1,1.068.335,1.111,1.111,0,0,1,.375.869c0,.074-.008.2-.026.39a2.606,2.606,0,0,1-.1.512L5.759,6.9a4.492,4.492,0,0,0-.1.466,2.4,2.4,0,0,0-.047.4.57.57,0,0,0,.15.459.85.85,0,0,0,.523.122,1.509,1.509,0,0,0,.4-.061A2.21,2.21,0,0,0,7,8.171Zm.121-7.1a.99.99,0,0,1-.334.753,1.138,1.138,0,0,1-.8.312,1.15,1.15,0,0,1-.809-.312.988.988,0,0,1-.337-.753A1,1,0,0,1,5.169.314a1.194,1.194,0,0,1,1.614,0A1,1,0,0,1,7.117,1.07Z" transform="translate(2.406 2.92)" fill="#fff"/>
			</svg>
		</span>
		Removing the product will lead to removing this product from your listing. The buyer won’t be able to place an order for this product.</p>
		<p class="fs16 txtseconday pb-3 mb-2 text-center">Are you sure you want to remove the product?</p>
		<div class="d-table mb-4 w-100">
			<div class="d-table-cell cart-thumb align-middle">
				<img width="60" src="assets/images/products/p-2.jpg">
			</div>
			<div class="d-table-cell pl-3 align-middle">
				<p class="txtdefault wt700 mb-0 fs14 lnht20">Vivo X60 Pro (Midnight Black, 12GB RAM, 256GB Storage) | Upto 12 Months No Cost EMI | Extra 4000 Off on Exchange</p>
			</div>
		</div>
	</div>
  <div class="modal-footer  text-center nobd mx-auto pb-4 mb-3">
    <button type="button" class="btn-d-outline d-inline-block cancel-outline mr-3" (click)="modal.close('Close click')">Cancel</button>
	<button type="button" class="btn buybtn fs16 radius10">Remove</button>
  </div>
</ng-template>
<!-- /REMOVE PRODUCT MODAL -->  