import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class ViewOrderqcDetailsService {

    constructor(private _http: HttpClient) { }

    vieworderqcdetails(data) {
        return this._http.post(environment.api_url + 'admin/order/qc/details', data);
    }

    uploadqcdetails(data) {
        return this._http.post(environment.api_url + 'admin/order/qc/csv', data);
    }

    reviewnotify(data) {
        return this._http.post(environment.api_url + 'admin/order/qc/confirm', data);
    }

    updatereason(data) {
        return this._http.post(environment.api_url + 'admin/order/qc/updaterejectstatus', data);
    }

}