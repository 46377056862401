<app-header-seller></app-header-seller>
<app-sidebar-seller></app-sidebar-seller>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<h3 class="fs24 wt700 txtdefault">Bidding Orders</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Orders</a>
						</li>
						<li class="breadcrumb-item active">Bidding Orders</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:void(0);" [routerLink]="['/seller/assistance']" class="btn buybtn fs16 radius10 ml-3 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card card-ui card-uiovt byregwrp">
			<div class="table-responsive">
				<table class="table custom-table xs2">
				  <thead>
					  <tr>
						<th width="20%">Product Name</th>
						<th>Date of Bid</th>
						<th>Total No. of Bids</th>
						<th>Order Quantity</th>
						<th>Price/Unit</th>
						<th>Max Bid Received</th>
						<th>Action</th>
					  </tr>
				  </thead>
				  <tbody>
					<!--Empty State-->
					<tr *ngIf="!bid_records.length">
						<td colspan="7">
							<div class="row pt-40 pb-40">
								<div class="col-md-12 text-center">
									<div class="mb-20"><img src="/assets/images/empty-state.png">
									</div>
									<div class="fs16">There are no records to display!</div>
								</div>
							</div>
						</td>
					</tr>
					<!--End Empty State-->
					<!-- REPEAT -->
					<tr *ngFor="let order of bid_records;">
						<td>
							<div class="order-info">
								<div class="order-img">
									<img [src]="order.logo" />
								</div>
								<div class="pd-seller-cntnt">
									<div class="txtdefault">
										<a href="javascript:;" class="order-link">{{ order.product_name }}</a>
									</div>
								</div>
							</div>
						</td>
						<td>{{ order.bid_date }}</td>
						<td>{{ order.total_bids }}</td>
						<td>{{ order.order_qty }}</td>
						<td>{{ order.unit_price }}</td>
						<td>{{ order.max_bid_received }}</td>
						<td>
							<a href="javascript:void(0);" [routerLink]="['/view-bids/'+order.product_id]" class="btn-d-outline ripple d-inline-block place-bid">View Bids</a>
						</td>
					  </tr>
					<!-- /REPEAT -->
				  </tbody>
				</table>
			</div> 
		</div>

		<div class="row">
			<div class="col-md-6">
				<div class="text-left pl-3">
					<nav class="mt-3 tbpg rows-per-page">
						<div>Rows per page:</div>
						<select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
							<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">{{option.name}}</option>
						</select>
					</nav>
				</div> 
			</div>
			<div class="col-md-6" *ngIf="order_pagination.pages && order_pagination.pages.length">
				<div class="text-right md-text-left pr-3">
					<nav class="mt-3 tbpg">
					  	<ul class="pagination align-items-center">
							<li class="page-item" [ngClass]="{disabled:order_pagination.currentPage === 1}"><a class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a></li>
							<li class="page-item" [ngClass]="{disabled:order_pagination.currentPage === 1}"><a class="page-link sprevicon" href="javascript:;" (click)="setpage(order_pagination.currentPage - 1)"></a></li>
							<li class="page-item"i *ngFor="let page of order_pagination.pages" [ngClass]="{active:order_pagination.currentPage === page}"><a class="page-link" href="javascript:;" (click)="setpage(page)">{{page}}</a></li>
							<li *ngIf="(order_pagination.totalPages - order_pagination.startPage) > 5 ">
								<a (click)="setpage(order_pagination.startPage + 5)">...</a>
							</li>
						 	<li *ngIf="(order_pagination.totalPages - order_pagination.startPage) > 5" >
								<a (click)="setpage(order_pagination.totalPages)">{{order_pagination.totalPages}}</a>
							</li>
							<li class="page-item" [ngClass]="{disabled:order_pagination.currentPage === order_pagination.totalPages}"><a class="page-link snexticon" href="javascript:;"  (click)="setpage(order_pagination.currentPage + 1)"></a></li>
							<li class="page-item" [ngClass]="{disabled:order_pagination.currentPage === order_pagination.totalPages}"><a class="page-link nexticon" href="javascript:;" (click)="setpage(order_pagination.totalPages)"></a></li>
					  	</ul>
					</nav>
				</div> 
			</div>
		</div>
	</div>
</div>

<app-footer-seller></app-footer-seller>