<!-- footer mobile -->
<div class="mb-footer sl-mb-footer text-center"> 
	<div class="row">
		<div class="col-3">
			<a href="" class="d-inline-block w-100">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="21.3" height="19.967" viewBox="0 0 21.3 19.967">
					  <g id="browser" transform="translate(7 5)">
						<path id="Path_321" data-name="Path 321" d="M56.058,204.611H41.914A1.916,1.916,0,0,1,40,202.7V192.629a.666.666,0,0,1,1.331,0V202.7a.583.583,0,0,0,.582.582H56.058a.583.583,0,0,0,.582-.582V192.629a.666.666,0,0,1,1.331,0V202.7A1.916,1.916,0,0,1,56.058,204.611Z" transform="translate(-45.336 -189.643)" fill="#1f1d44"/>
						<path id="Path_322" data-name="Path 322" d="M20.635,26.332a.663.663,0,0,1-.471-.195l-8.278-8.278a1.749,1.749,0,0,0-2.471,0L1.137,26.137A.666.666,0,0,1,.2,25.2l8.278-8.278a3.082,3.082,0,0,1,4.354,0L21.105,25.2a.666.666,0,0,1-.471,1.136Z" transform="translate(-7 -21.017)" fill="#1f1d44"/>
						<path id="Path_323" data-name="Path 323" d="M181.991,296.636h-5.325a.666.666,0,0,1-.666-.666v-5.907a2.082,2.082,0,0,1,2.08-2.08h2.5a2.082,2.082,0,0,1,2.08,2.08v5.907A.666.666,0,0,1,181.991,296.636Zm-4.659-1.331h3.994v-5.242a.75.75,0,0,0-.749-.749h-2.5a.75.75,0,0,0-.749.749Z" transform="translate(-175.678 -281.669)" fill="#1f1d44"/>
					  </g>
					</svg>
				</span>			
				<p class="txtdefault fs12">Home</p>
			</a>
		</div>
		<div class="col-3">
			<a href="javascript:void(0);" (click)="swcatgkEvent()" class="d-inline-block w-100">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="18.629" height="18.629" viewBox="0 0 18.629 18.629">
					  <g id="Group_13684" data-name="Group 13684" transform="translate(-1211.686 -2122.87)">
						<g id="Group_13582" data-name="Group 13582" transform="translate(1211.686 2133.115)">
						  <path id="Path_2335" data-name="Path 2335" d="M8.3,43.383H4.08A2.08,2.08,0,0,1,2,41.3V37.08A2.08,2.08,0,0,1,4.08,35H9.762a.623.623,0,0,1,.621.621V41.3a2.08,2.08,0,0,1-2.08,2.08ZM4.08,36.242a.835.835,0,0,0-.838.838V41.3a.835.835,0,0,0,.838.838H8.3a.835.835,0,0,0,.838-.838V36.242Z" transform="translate(-2 -35)" fill="#1f1d44"/>
						</g>
						<g id="Group_13583" data-name="Group 13583" transform="translate(1221.931 2122.87)">
						  <path id="Path_2336" data-name="Path 2336" d="M41.3,10.383H35.621A.623.623,0,0,1,35,9.762V4.08A2.08,2.08,0,0,1,37.08,2H41.3a2.08,2.08,0,0,1,2.08,2.08V8.3a2.08,2.08,0,0,1-2.08,2.08ZM36.242,9.141H41.3a.835.835,0,0,0,.838-.838V4.08a.835.835,0,0,0-.838-.838H37.08a.835.835,0,0,0-.838.838Z" transform="translate(-35 -2)" fill="#1f1d44"/>
						</g>
						<g id="Group_13584" data-name="Group 13584" transform="translate(1211.686 2122.87)">
						  <path id="Path_2337" data-name="Path 2337" d="M9.762,10.383H4.08A2.08,2.08,0,0,1,2,8.3V4.08A2.08,2.08,0,0,1,4.08,2H8.3a2.08,2.08,0,0,1,2.08,2.08V9.762a.623.623,0,0,1-.621.621ZM4.08,3.242a.835.835,0,0,0-.838.838V8.3a.835.835,0,0,0,.838.838H9.141V4.08A.835.835,0,0,0,8.3,3.242Z" transform="translate(-2 -2)" fill="#1f1d44"/>
						</g>
						<g id="Rectangle_1420" data-name="Rectangle 1420" transform="translate(1221.934 2133.115)" fill="none" stroke="#1f1d44" stroke-width="1.3">
						  <path d="M.168,0H4.19A4.19,4.19,0,0,1,8.38,4.19v0A4.19,4.19,0,0,1,4.19,8.38h0A4.19,4.19,0,0,1,0,4.19V.168A.168.168,0,0,1,.168,0Z" stroke="none"/>
						  <path d="M1.016.65H4.19A3.54,3.54,0,0,1,7.73,4.19v0A3.54,3.54,0,0,1,4.19,7.73h0A3.54,3.54,0,0,1,.65,4.19V1.016A.366.366,0,0,1,1.016.65Z" fill="none"/>
						</g>
					  </g>
					</svg>
				</span>			
				<p class="txtdefault fs12">Categories</p>
			</a>
			<!-- <div class="p-3">
				<div class="eqmenu-section">
				  <ngb-accordion [closeOthers]="true" activeIds="static-1" class="accordion theme-accordion" id="accordionExample">
					<ngb-panel *ngFor="let category of categories; let i = index;" id="static-{{i+1}}" title="{{ category.name }}">
						<ng-template ngbPanelContent>
							<ngb-accordion #a="ngbAccordion" activeIds="mbpanel1" class="sbchldcstm">
								<ngb-panel  *ngFor="let cat of category.child; let k = index;" id="mbpanel{{ k+1 }}">
									<ng-template ngbPanelHeader let-opened="opened">
									  <div class="d-flex align-items-center justify-content-between">
										<h5 class="m-0" *ngIf="cat.ifchild"><img [src]="cat.icon"> {{ cat.name }}</h5>
										<h5 class="m-0" *ngIf="!cat.ifchild" (click)="swcatgkEvent(cat.slug)"><img [src]="cat.icon"> {{ cat.name }} </h5>
										<button ngbPanelToggle class="btn btn-link p-0" *ngIf="cat.ifchild"></button>
									  </div>
									</ng-template>
									<ng-template ngbPanelContent *ngIf="cat.ifchild">
										<ng-container *ngFor="let sub of cat.child;">
											<h4 class="th-cmn-bd weight500 fs14" *ngIf="sub.ifchild">{{ sub.name }}</h4>
											<h4 class="th-cmn-bd weight500 fs14" (click)="swcatgkEvent(sub.slug)" *ngIf="!sub.ifchild">{{ sub.name }}</h4>
											<ul class="pb-4" *ngIf="sub.ifchild">
												<li *ngFor="let sub1 of sub.child;">
													<a href="javascript:void(0);" (click)="swcatgkEvent(sub1.slug)">{{ sub1.name }}</a>
												</li>
											</ul>
										</ng-container>
									</ng-template>
								</ngb-panel>
							</ngb-accordion>
						</ng-template>
					</ngb-panel>
				  </ngb-accordion>
				</div>
			</div> -->
		</div>
		
		<div class="col-3">
			<a href="javascript:void(0);" class="d-inline-block lwopacity w-100 sellbtn" [routerLink]="['/buyer/buyer-wallet']">
				<span>
					<img src="assets/images/icons/wallet-menu.png">
				</span>			
				<p class="txtdefault txtch fs12">Wallet</p>
			</a>
		</div>
		<div class="col-3">
			<a href="javascript:void(0);" class="d-inline-block w-100" [routerLink]="['/buyer/order-history']">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="18.623" height="18.576" viewBox="0 0 18.623 18.576">
					  <g id="delivery-box" transform="translate(0.224 0.204)">
						<path id="Path_8740" data-name="Path 8740" d="M.378,19.569a.376.376,0,0,0,.175-.043l8.7-4.543a.379.379,0,0,0,.2-.335V6.129A.378.378,0,0,0,8.91,5.79l-8.7,4.354A.379.379,0,0,0,0,10.483V19.19a.379.379,0,0,0,.182.324A.373.373,0,0,0,.378,19.569ZM8.7,14.418.756,18.565V10.716L8.7,6.741Z" transform="translate(8.714 -1.397)" fill="#1f1d44" stroke="#1f1d44" stroke-width="0.4"/>
						<path id="Path_8741" data-name="Path 8741" d="M20.588,19.569a.378.378,0,0,0,.379-.379V10.482a.379.379,0,0,0-.209-.338l-8.7-4.354a.379.379,0,0,0-.548.338v8.518a.379.379,0,0,0,.2.335l8.7,4.543a.376.376,0,0,0,.175.043Zm-.379-8.852v7.849l-7.944-4.148V6.741Zm-8.323,3.931h-.008Z" transform="translate(-11.508 -1.397)" fill="#1f1d44" stroke="#1f1d44" stroke-width="0.4"/>
						<path id="Path_8742" data-name="Path 8742" d="M17.792,5.111a.378.378,0,0,0,.17-.717L9.248.04a.38.38,0,0,0-.338,0L.208,4.394a.379.379,0,0,0,.338.678L9.079.8l8.544,4.269a.377.377,0,0,0,.17.04Z" transform="translate(0.001 0)" fill="#1f1d44" stroke="#1f1d44" stroke-width="0.4"/>
						<path id="Path_8743" data-name="Path 8743" d="M6.386,12.025a.379.379,0,0,0,.379-.379V7.716l8.493-4.249a.379.379,0,0,0-.339-.678l-8.7,4.354a.381.381,0,0,0-.209.339v4.165a.379.379,0,0,0,.379.379Z" transform="translate(-1.843 -0.668)" fill="#1f1d44" stroke="#1f1d44" stroke-width="0.4"/>
					  </g>
					</svg>
				</span>			
				<p class="txtdefault fs12">Order</p>
			</a>
		</div>
		<div class="col-3">
			<a href="javascript:void(0);" class="d-inline-block w-100" [routerLink]="['/buyer/dashboard']">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="5.88" viewBox="0 0 20 5.88">
					  <g id="Group_13963" data-name="Group 13963" transform="translate(-5816 -994)">
						<g id="Group_189" data-name="Group 189" transform="translate(5830.121 994)">
						  <g id="Group_188" data-name="Group 188">
							<path id="Path_327" data-name="Path 327" d="M364.424,180.742a2.94,2.94,0,1,0,2.94,2.94A2.943,2.943,0,0,0,364.424,180.742Zm0,4.434a1.494,1.494,0,1,1,1.494-1.494A1.5,1.5,0,0,1,364.424,185.176Z" transform="translate(-361.484 -180.742)" fill="#1f1d44"/>
						  </g>
						</g>
						<g id="Group_191" data-name="Group 191" transform="translate(5823.061 994)">
						  <g id="Group_190" data-name="Group 190">
							<path id="Path_328" data-name="Path 328" d="M183.682,180.742a2.94,2.94,0,1,0,2.94,2.94A2.943,2.943,0,0,0,183.682,180.742Zm0,4.434a1.494,1.494,0,1,1,1.494-1.494A1.5,1.5,0,0,1,183.682,185.176Z" transform="translate(-180.742 -180.742)" fill="#1f1d44"/>
						  </g>
						</g>
						<g id="Group_13962" data-name="Group 13962" transform="translate(5816 994)">
						  <g id="Group_190-2" data-name="Group 190">
							<path id="Path_328-2" data-name="Path 328" d="M183.682,180.742a2.94,2.94,0,1,0,2.94,2.94A2.943,2.943,0,0,0,183.682,180.742Zm0,4.434a1.494,1.494,0,1,1,1.494-1.494A1.5,1.5,0,0,1,183.682,185.176Z" transform="translate(-180.742 -180.742)" fill="#1f1d44"/>
						  </g>
						</g>
					  </g>
					</svg>

				</span>			
				<p class="txtdefault fs12">Account</p>
			</a>
		</div>
	</div>
</div>
<!-- /footer mobile -->

