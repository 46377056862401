<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain pb-4">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center pinfo-head">
			<div class="col-md-6 mb-4">
				<h3 class="fs24 wt700 txtdefault">Request Callback List</h3>				
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">	
						<li class="breadcrumb-item"><a href="javascript:void(0);" class="txtseconday">Request Callback Management </a></li>
						<li class="breadcrumb-item active">Request Callback List</li>
					</ol>
				</nav>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4 mb-0">
			<div class="notifications-container theme-tab">
				
				<div class="tab-content">
					<!--Filters-->
					<div class="row d-flex justify-content-between mt-0  report-cta">
						<div class="col-sm-5 mb-3">
							<div class="report-input">
								<div class="input-group-custom">
									<input type="text" placeholder="Search by Phone" class="form-control">
									<div class="input-group-append-custom">
										<img src="assets/images/svg/search_logo.svg">
									</div>
								</div>
							</div>
						</div>
					</div>
					<!--End Filters-->
					<div class="table-responsive brndmgnttable">
						<table class="table custom-table xs">
							<thead>
								<tr>
									<th>Phone</th>
									<th>Created at</th>
								</tr>
							</thead>
							<tbody>
								<!-- TR -->
								<tr *ngFor="let record of requestcallback_records;">
									<td>
										<div class="d-flex align-items-center">
											<div>{{ record.mobile }}</div>
										</div>
									</td>
									<td>{{ record.created_at }}</td>
								</tr>
								<!-- /TR -->

								<!--Empty State-->
								<tr *ngIf="!requestcallback_records.length">
									<td colspan="4">
										<div class="row pt-40 pb-40">
											<div class="col-md-12 text-center">
												<div class="mb-20"><img src="/assets/images/empty-state.png"></div>
												<div class="fs16">There are no records to display!</div>
											</div>
										</div>
									</td>
								</tr>
								<!--End Empty State-->

							</tbody>
						</table>
					</div>	
				</div>		               
			</div>
		</div>
		<div class="row mb-40" *ngIf="requestcallback_pagination.totalPages> 1">
			<div class="col-md-6">
				<div class="text-left pl-3">
					<nav class="mt-3 tbpg rows-per-page">
						<div>Rows per page:</div>
						<select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
							<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">{{option.name}}</option>
						</select>
					</nav>
				</div> 
			</div>
			<div class="col-md-6" *ngIf="requestcallback_pagination.pages && requestcallback_pagination.pages.length">
				<div class="text-right pr-3">
					<nav class="mt-3 tbpg">
					  <ul class="pagination align-items-center">
						<li class="page-item" [ngClass]="{disabled:requestcallback_pagination.currentPage === 1}"><a class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a></li>

						<li class="page-item" [ngClass]="{disabled:requestcallback_pagination.currentPage === 1}"><a class="page-link sprevicon" href="javascript:;" (click)="setpage(requestcallback_pagination.currentPage - 1)"></a></li>

						<li class="page-item"i *ngFor="let page of requestcallback_pagination.pages" [ngClass]="{active:requestcallback_pagination.currentPage === page}"><a class="page-link" href="javascript:;" (click)="setpage(page)">{{page}}</a></li>

						<li *ngIf="(requestcallback_pagination.totalPages - requestcallback_pagination.startPage) > 5 ">
							<a (click)="setpage(requestcallback_pagination.startPage + 5)">...</a>
						</li>
						 <li *ngIf="(requestcallback_pagination.totalPages - requestcallback_pagination.startPage) > 5" >
							<a (click)="setpage(requestcallback_pagination.totalPages)">{{requestcallback_pagination.totalPages}}</a>
						</li>
						
						<li class="page-item" [ngClass]="{disabled:requestcallback_pagination.currentPage === requestcallback_pagination.totalPages}"><a class="page-link snexticon" href="javascript:;"  (click)="setpage(requestcallback_pagination.currentPage + 1)"></a></li>
						<li class="page-item" [ngClass]="{disabled:requestcallback_pagination.currentPage === requestcallback_pagination.totalPages}"><a class="page-link nexticon" href="javascript:;" (click)="setpage(requestcallback_pagination.totalPages)"></a></li>
					  </ul>
					</nav>
				</div> 
			</div>
		</div> 
	</div>	