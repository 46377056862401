import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { SubSellerProductsService } from './subseller-products.service';

@Component({
    selector: 'app-sub-seller-products',
    templateUrl: './sub-seller-products.component.html',
    styleUrls: ['./sub-seller-products.component.scss']
})
export class SubSellerProductsComponent implements OnInit {

    public sub_seller_id: string;
    public basic_info: any = {};
    public product_records: any = [];
    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 20;
    public product_pagination: any = {};
    

    public page_per_view_options = [
        {
            value: 20,
            name: 20
        },
        {
            value: 40,
            name: 40
        }
    ];

    constructor(
        private _subsellerproductservice: SubSellerProductsService,
        private _pagination: PaginationService,
        private _toastr: ToasterService,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.sub_seller_id = this.route.snapshot.paramMap.get('id');
        this.getproducts();
        this.basic_info.key_performance = {};
    }

    getproducts() {
        this._subsellerproductservice.getproducts({ limit: this.page_per_view, offset: this.offset, current_page: this.current_page, seller_id: this.sub_seller_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.basic_info = data['basic'];
                this.product_records = data['records'];
                let total_records = data['total_records'];
                this.product_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.getproducts();
    }

    setpageperview() {
        this.current_page = 1;
        this.getproducts();
    }

}
