import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { MakePaymentService } from './make-payment.service';
@Component({
  selector: 'app-make-payment',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.scss']
})
export class MakePaymentComponent implements OnInit {

  	constructor(
		private _makepaymentservice: MakePaymentService,
		private _toastr: ToasterService
	) { }
	public buttonLoading:boolean = false;
	public user_info:any = {};
	public selected_user_type:string = 'buyer';
	public selected_to_user_type:string = 'seller';
	public selected_payment_type:string = 'wallet';
	public selected_id:string = '';
	public toselected_id:string = '';
	public order_transaction_no:string = '';
	public remark:string = '';
	public document_file: any = '';
	public document_file_name:string = 'OrderId.pdf';
	public payment_amount:number = 10000;
	public file_uploded:boolean = false;
	public from_balance:number = 0;
	public to_balance:number = 0;
	public predefined_amounts = [
        {
            value: 10000,
            label: '10,000',
        },
        {
            value: 20000,
            label: '20,000',
        },
        {
            value: 50000,
            label: '50,000',
        },
        {
            value: 100000,
            label: '1,00,000',
        }
    ];
  	ngOnInit(): void {
		  this.userbasicinfo();
  	}

	userbasicinfo() {
        this._makepaymentservice.userbasicinfo(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.user_info = data['data'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }
  	public val = null;
	config = {
		labelField: 'label',
		valueField: 'value',
  	};
  	data = [
    	{
      		label: 'Wallet to Wallet',
      		value: 'wallet'
    	},
      	{
      		label: 'Wallet to Bank',
      		value: 'bank'
    	}
  	]

    user_type = [
		{
			label: 'Admin',
			value: 'admin'
	  	},
      	{
      		label: 'Buyer',
      		value: 'buyer'
    	},
      	{
      		label: 'Seller',
      		value: 'seller'
    	}
  	]

	updateamount(amounts){
        this.payment_amount = amounts.value;
    }

	changeusertype(){
		this.selected_id = '';
		this.result = [];
	}

  	keyword = 'search_txt';
  	result = [];

  	selectEvent(item) {
    	this.selected_id = item.id;
		this.from_balance = 0;
		this._makepaymentservice.getbalance({id: item.id}).subscribe((data) => {
			if (data['status'] == "success") {
				this.from_balance = data['balance'];
			} else if (data['status'] == "error") {
				this._toastr.error(data['message']);
			}
		});
  	}
 
  	onChangeSearch(val: string) {
		this.result = [];
		setTimeout( () => {
			this._makepaymentservice.searchuser({type: this.selected_user_type, search: val}).subscribe((data) => {
				if (data['status'] == "success") {
					this.result = [];
					this.result = data['searchResult'];
				} else if (data['status'] == "error") {
					this._toastr.error(data['message']);
				}
			});
		}, 1000)
		
  	}

  	onFocused(e){
    	// do something when input is focused
  	}


	  //To Select Box
		toresult = [];

		toselectEvent(item) {
			this.toselected_id = item.id;
			this.to_balance = 0;
			this._makepaymentservice.getbalance({id: item.id}).subscribe((data) => {
				if (data['status'] == "success") {
					this.to_balance = data['balance'];
				} else if (data['status'] == "error") {
					this._toastr.error(data['message']);
				}
			});
		}
	
		toonChangeSearch(val: string) {
			this.toresult = [];
			setTimeout( () => {
				this._makepaymentservice.searchuser({type: this.selected_to_user_type, search: val}).subscribe((data) => {
					if (data['status'] == "success") {
						this.toresult = [];
						this.toresult = data['searchResult'];
					} else if (data['status'] == "error") {
						this._toastr.error(data['message']);
					}
				});
			}, 1000)
			
		}

		toonFocused(e){
			// do something when input is focused
		}

	makepayment(){

		if(!this.file_uploded){
			this._toastr.error('Please attach order id / reference id document');
			return false;
		}
		this.buttonLoading = true;
		let data: any = new FormData();
        data.append('remark', this.remark);
		data.append('payment_type', this.selected_payment_type);
		data.append('order_transaction_no', this.order_transaction_no);

		data.append('user_type', this.selected_user_type);
		if(this.selected_user_type != 'admin'){
			data.append('id', this.selected_id);
		}

		data.append('to_user_type', this.selected_to_user_type);
		if(this.selected_to_user_type != 'admin'){
			data.append('to_id', this.toselected_id);
		}

		data.append('amount', this.payment_amount);
		data.append('document', this.document_file);
		this._makepaymentservice.makepayment(data).subscribe((data) => {
            if(data['status'] == "success"){
				this.file_uploded = false;
				this.document_file = '';
				this.document_file_name = 'OrderId.pdf';
				this.remark = '';
				this.order_transaction_no = '';
				this.selected_user_type = 'buyer';
				this.selected_to_user_type = 'seller';
				this.selected_payment_type =  'wallet';
				this.from_balance = 0;
				this.to_balance = 0;
				this.selected_id = '';
				this.toselected_id = '';
				this.result = [];
				this.payment_amount = 10000;
                this._toastr.success('Amount has been transferred successfully.');
				this.buttonLoading = false;
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
				this.buttonLoading = false;
            }
        }, (error) => {
            this.buttonLoading = false;
        });
	}

	uploadfile(event: any) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
			event.target.value = '';
            this.document_file_name = file.name;
            this.document_file = file;
			this.file_uploded = true;
        }
    }


}
