import { Component, OnInit } from '@angular/core';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { SellerTrackOrdersService } from './track-my-orders.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-track-my-order',
  templateUrl: './track-my-order.component.html',
  styleUrls: ['./track-my-order.component.scss']
})
export class TrackMyOrderComponent implements OnInit {

    public orders:any = [];
    public return_order:any = {};
    public show_sample_data:boolean = false;

    public seller_orders: any = [];
    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 10;
    public seller_order_pagination: any = {};

    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];

    //return request
    public return_quantity:any = '';
    public return_reason:string = '';
    public return_message:string = '';
    public return_images:any = [];
    public return_updated_image:any = [];
    public return_status:any = '';
    public return_status_text:string = '';

  constructor(
        private modalService: NgbModal,
        private _trackmyorders: SellerTrackOrdersService,
        private _toastr: ToasterService,
        private _pagination: PaginationService
  ) { }

    ngOnInit(): void {
        this.getorders();
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.getorders();
    }

    setpageperview() {
        this.current_page = 1;
        this.getorders();
    }

  	getorders(){
        let data = { 
            limit: this.page_per_view, 
            offset: this.offset, 
            current_page: this.current_page, 
        };
		this._trackmyorders.getorders(data).subscribe((data) => {
			if(data['status'] == "success"){
                this.orders = data['records'];
                let total_records = data['total_records'];
                this.seller_order_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
			}else if(data['status'] == "error"){
				this._toastr.error(data['message']);
			}
		});
  	}

	acceptorder(record){
        this._trackmyorders.acceptorder({id: record.id}).subscribe((data) => {
            if(data['status'] == "success"){
                this._toastr.success('Order has been accepted successfully.');
                this.getorders();
            }else if(data['status'] == "error"){
              this._toastr.error(data['message']);
            }
        });
    }

    rejectorder(record){
        this._trackmyorders.rejectorder({id: record.id}).subscribe((data) => {
            if(data['status'] == "success"){
                this._toastr.success('Order has been accepted successfully.');
                this.getorders();
            }else if(data['status'] == "error"){
              this._toastr.error(data['message']);
            }
        });
    }

	readytodispatch(record){
        this._trackmyorders.readytodispatch({id: record.id}).subscribe((data) => {
            if(data['status'] == "success"){
                this._toastr.success('Stock status has been updated successfully.');
                this.getorders();
            }else if(data['status'] == "error"){
              this._toastr.error(data['message']);
            }
        });
    }


    openWindowReturnRequest(content, order:any) {
        this.return_order = order;
        if(this.return_order.return_request){
            this.return_quantity = this.return_order.return_request_info.quantity;
            this.return_reason = this.return_order.return_request_info.reason;
            this.return_message = this.return_order.return_request_info.message;
            this.return_status = this.return_order.return_request_info.status;
            this.return_status_text = this.return_order.return_request_info.status_text;
            this.return_updated_image = this.return_order.return_request_info.images;
        }
        this.modalService.open(content, { windowClass: 'midmodal-lg', size: 'lg', centered: true, });
    }

    thumbnailsSlider = {
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: true,
        focusOnSelect: true,
        asNavFor: ".feedback",
        //prevArrow:'.client-thumbnails .prev-arrow',
        // nextArrow:'.client-thumbnails .next-arrow',
    };
    slides = [
        { img: "assets/images/admin/mfront.png" },
        { img: "assets/images/admin/back.png" },
        { img: "assets/images/admin/top.png" },

        { img: "assets/images/admin/side.png" }
    ];

    imagesSlider = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: ".thumbs",
    };

    
    submitreturn(type){
        let data = {
            meta_id: this.return_order.id,
            type:type
        }
        this._trackmyorders.acceptrejectreturn(data).subscribe((data) => {
            if (data['status'] == "success") {
                if(type == 'approve'){
                    this._toastr.success('Return request has been accepted successfully.');
                }else if(type == 'reject'){
                    this._toastr.success('Return request has been rejected successfully.');
                }
                this.modalService.dismissAll();
                this.getorders();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        }, (error) => {
            this._toastr.error(data['message']);
        });
    }


}
