import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { BuyerTrackOrdersService } from './buyer-track-orders.service';
import { PaginationService } from 'src/app/helpers/pagination.service';

@Component({
  selector: 'app-buyer-track-orders',
  templateUrl: './buyer-track-orders.component.html',
  styleUrls: ['./buyer-track-orders.component.scss']
})
export class BuyerTrackOrdersComponent implements OnInit {

    public closeResult = '';
    public cancel_order:any = {};
    public return_order:any = {};
    public orders:any = [];
    public selected_record:any = {};

    public buyer_orders: any = [];
    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 10;
    public buyer_order_pagination: any = {};

    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];

    constructor(
        private modalService: NgbModal,
        private _orderservice: BuyerTrackOrdersService,
        private _toastr: ToasterService,
        private _pagination: PaginationService
    ) { }

    ngOnInit(): void {
        this.getorders();
    }
  public val = null;
    config = {
        labelField: 'label',
        valueField: 'value',
    };
	data = [
        {
            label: 'Download Labels',
            value: 'Download Labels'
        },
        {
            label: 'Download POD',
            value: 'Download POD'
        },
        {
            label: 'Download E Way Bill',
            value: 'Download E Way Bill'
        }
    ];

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.getorders();
    }

    setpageperview() {
        this.current_page = 1;
        this.getorders();
    }

    getorders(){
        let data = { 
            limit: this.page_per_view, 
            offset: this.offset, 
            current_page: this.current_page, 
        };
        this._orderservice.getorders(data).subscribe((data) => {
            if(data['status'] == "success"){
                this.orders = data['records'];
                let total_records = data['total_records'];
                this.buyer_order_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    payremainingamount(){
        this._orderservice.payremainingamount({id: this.selected_record.id}).subscribe((data) => {
            if(data['status'] == "success"){
                this._toastr.success('Balanced amount has been paid successfully.');
                this.modalService.dismissAll();
                this.getorders();
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    acceptqcorder(record){
        this._orderservice.acceptqcorder({id: record.id}).subscribe((data) => {
            if(data['status'] == "success"){
                this._toastr.success('Products has been verified successfully.');
                this.getorders();
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    cancelrequest(id:any){
        this._orderservice.cancelorder({meta_id: id}).subscribe((data) => {
            if(data['status'] == "success"){
                this.modalService.dismissAll();
                this._toastr.success('Order has been cancelled successfully.');
                this.getorders();
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    openWindowReturnRequest(content, order:any) {
        this.return_order = order;
        if(this.return_order.return_request){
            this.return_quantity = this.return_order.return_request_info.quantity;
            this.return_reason = this.return_order.return_request_info.reason;
            this.return_message = this.return_order.return_request_info.message;
            this.return_status = this.return_order.return_request_info.status;
            this.return_updated_image = this.return_order.return_request_info.images;
        }
        this.modalService.open(content, { windowClass: 'midmodal-lg', size: 'lg', centered: true, });
    }

    imageUpload(event: any) {
        for (var i = 0; i < event.target.files.length; i++) {
            this.return_images.push(event.target.files[i]);
        }
    }

    submitreturn(){
        let total_quantity = 0;
        if(this.return_order.parentchild != 'yes'){
            total_quantity = this.return_quantity;
        }else{
            for(let k = 0; k < this.return_order.parentchild_records.length; k++){
                var qty = this.return_order.parentchild_records[k].return_qty;
                if(qty){
                    total_quantity = total_quantity + qty;
                }
            }
        }
        if(total_quantity == 0){
            this._toastr.error('Please enter return quantity');
            return false;
        }
        if(!this.return_reason){
            this._toastr.error('Please enter return reason');
            return false;
        }
        let data: any = new FormData();
        data.append('meta_id', this.return_order.id);
        let child_records = [];
        if(this.return_order.parentchild == 'yes'){
            child_records = this.return_order.parentchild_records;
        }
        data.append('child_records', JSON.stringify(child_records));
        data.append('quantity', this.return_quantity);
        data.append('reason', this.return_reason);
        data.append('message', this.return_message);
        for (var i = 0; i < this.return_images.length; i++) {
            data.append("file[]", this.return_images[i]);
        }
        this._orderservice.returnrequest(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.return_images = [];
                this.return_quantity = '';
                this.return_reason = '';
                this.return_message = '';
                this._toastr.success('Return request has been submitted successfully.');
                this.modalService.dismissAll();
                this.getorders();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        }, (error) => {
            this._toastr.error(data['message']);
        });
    }

    //return request
    public return_quantity:any = '';
    public return_reason:string = '';
    public return_message:string = '';
    public return_images:any = [];
    public return_updated_image:any = [];
    public return_status:string = '';


    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    open(content, record) {
        this.selected_record = record;
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    cancelopen(content, order:any) {
        this.cancel_order = order;
        console.log(this.cancel_order)
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    thumbnailsSlider = {
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: true,
        focusOnSelect: true,
        asNavFor: ".feedback",
        //prevArrow:'.client-thumbnails .prev-arrow',
        // nextArrow:'.client-thumbnails .next-arrow',
    };
    slides = [
        { img: "assets/images/admin/mfront.png" },
        { img: "assets/images/admin/back.png" },
        { img: "assets/images/admin/top.png" },

        { img: "assets/images/admin/side.png" }
    ];

    imagesSlider = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: ".thumbs",
    };


}
