import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-footer-admin',
  templateUrl: './footer-admin.component.html',
  styleUrls: ['./footer-admin.component.scss']
})
export class FooterAdminComponent implements OnInit {

  
  constructor() { }

  ngOnInit(): void {
  }
}
