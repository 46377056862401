<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">User Management</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" [routerLink]="['/manageseller']">Seller</a>
						</li>
						<li class="breadcrumb-item active">View Supplier Performance</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<h3 class="f-700 fs22 txtdefault pb-10"><a href="javascript:;" class="go-back"><i class="fa fa-angle-left"></i></a> View Supplier Performance</h3>

		<!-- VENDOR INFO -->
		<div class="row mt-30 mb-4">
			<div class="col-md-8 pb-2">
				<div class="row">
					<div class="col-sm-2 col-3 seller-thumb">
						<img src="assets/images/svg/vendor.svg" />
					</div>
					<div class="col-sm-10 col-9 pl-0 seller-info">
						<h4 class="f-600 fs22">Digixpress Trade Private Limited</h4>
						<div class="fs12 txtseconday mb-1">Seller</div>
						<span class="pb-sm-2 pl-3 seller-loc fs12">
						<svg xmlns="http://www.w3.org/2000/svg" width="9.375" height="11.752" viewBox="0 0 9.375 11.752">
	  					<path id="Path_2306" data-name="Path 2306" d="M25.689,5.029a4.825,4.825,0,0,0-4.154,5.16c.285,3.272,3.293,5.711,4.324,6.457a.562.562,0,0,0,.677-.012c1.071-.836,4.357-3.67,4.357-6.942a4.691,4.691,0,0,0-5.2-4.663Zm.514,6.524a1.861,1.861,0,1,1,1.861-1.861A1.861,1.861,0,0,1,26.2,11.553Z" transform="translate(-21.517 -5.002)" fill="#5e6a7c"/>
						</svg>Mumbai, Maharashtra, India</span>
						<p class="pt-2 fs14 txtseconday">Digixpress Trade Private Limited is a very well known seller in the market. It is committed to providing each customer with the highest standard of customer service.</p>
					</div>
				</div>
			</div>
			<div class="col-md-4 pb-2">
				<div class="card pl-4 pt-4 keyperform pb-2">
					<h4 class="fs16 f-700 mb-3 txtdefault">Key Performance</h4>
					<ul class="row mb-0">
						<li class="col-md-12">
							<div class="keyratewrp">
								<div class="keyrate">
									<img src="assets/images/png/circle.png" />
									<span class="f-700 txtdefault">4.0</span>
									<div class="ratings star-ratings pr-1 d-inline-block">
										<i class="fa fa-star" aria-hidden="true"></i>
									</div>
								</div>
								<div class="mb-10">Order Fulfillment</div>
							</div>
						</li>
						<li class="col-md-12">
							<div class="keyratewrp">
								<div class="keyrate">
									<img src="assets/images/png/circle.png" />
									<span class="f-700 txtdefault">3.8</span>
									<div class="ratings star-ratings pr-1 d-inline-block">
										<i class="fa fa-star" aria-hidden="true"></i>
									</div>
								</div>
								<div class="mb-10">Delivery</div>
							</div>
						</li>
						<li class="col-md-12">
							<div class="keyratewrp">
								<div class="keyrate">
									<img src="assets/images/png/circle.png" />
									<span class="f-700 txtdefault">4.7</span>
									<div class="ratings star-ratings pr-1 d-inline-block">
										<i class="fa fa-star" aria-hidden="true"></i>
									</div>
								</div>
								<div class="mb-10">Quality Assurance</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /VENDOR INFO -->
		<!-- PRODUCT LISTING -->
		<nav ngbNav #nav="ngbNav" class="nav-tabs listnav mb-3">
			<ng-container ngbNavItem>
				<a ngbNavLink class="txtseconday px-0 mx-sm-3 mx-2">Quality & User Satisfaction</a>
				<ng-template ngbNavContent>

					<div class="row mt-30 mb-30 d-flex align-items-center">
						<div class="col-md-6">
							<div class="ovlrating">
								<h4 class="fs14 f-600 mb-2">Overall Rating</h4>
								<div>
									<div class="ratings star-ratings pr-1 d-inline-block">
										<i class="fa fa-star" aria-hidden="true"></i>
										<i class="fa fa-star" aria-hidden="true"></i>
										<i class="fa fa-star" aria-hidden="true"></i>
										<i class="fa fa-star" aria-hidden="true"></i>
										<i class="fa fa-star-o" aria-hidden="true"></i>
									</div>
									<span class="fs16 f-700 txtdefault">4.5</span> out of <span class="fs16 f-700 txtdefault">5</span> &nbsp;<i class="fs12 txtseconday pt-2 ">(3,999 ratings)</i>
								</div>
							</div>
						</div>

						<div class="col-md-6">
							<div class="sortselect" [ngClass]="ratingst ? 'showsort' : ''">
								<div class="fltrshead srthead"> 
									<h4>Sort By</h4> 
									<a  (click)="ratingsort()"  class="closefilter" href="javascript:void(0);"><svg _ngcontent-serverApp-c132="" xmlns="http://www.w3.org/2000/svg" width="6.508" height="6.508" viewBox="0 0 6.508 6.508"><path _ngcontent-serverApp-c132="" id="np_cross_1707162_000000" d="M30.883,30.883a.507.507,0,0,1,.717,0l2.389,2.389,2.389-2.389a.507.507,0,0,1,.717.717L34.7,33.988l2.389,2.389a.507.507,0,0,1-.717.717L33.988,34.7,31.6,37.094a.507.507,0,0,1-.717-.717l2.389-2.389L30.883,31.6A.507.507,0,0,1,30.883,30.883Z" transform="translate(-30.734 -30.734)" fill="#1f1d44"></path></svg></a>
								</div>
								<ng-selectize placeholder="Sort By Date" [config]="config" [options] = "data"></ng-selectize>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-12 rating-content">
							<div class="card p-3 px-2 pt-0 radius15">
								<div class="card-body pt-0">
									<div class="rating-table-responsive"> 
										<div class="rating-table">
											<!-- THEAD -->
											<div class="row rating-head py-4 align-items-center rating-bd ">
												<div class="col-sm-2">
													<p class="txtdefault mb-0">User’s Rating</p>
												</div>
												<div class="col-sm-2">
													<p class="txtdefault mb-0">Date of Review</p>
												</div>
												<div class="col-sm-6">
													<p class="txtdefault mb-0">User’s Review</p>
												</div>
												<div class="col-sm-2">
													<p class="txtdefault mb-0">Is User Satisfied?</p>
												</div>
											</div>
											<!-- /THEAD -->
											<!-- REPEAT -->
											<div class="row py-4 align-items-center rating-bd ">
												<div class="col-lg-2">
													<div class="ratings star-ratings">
														<i class="fa fa-star" aria-hidden="true"></i>
														<i class="fa fa-star" aria-hidden="true"></i>
														<i class="fa fa-star" aria-hidden="true"></i>
														<i class="fa fa-star" aria-hidden="true"></i>
														<i class="fa fa-star-o" aria-hidden="true"></i>
													</div>
												</div>
												<div class="col-lg-2 col-6 dtrt md-ord4">
													<p class="txtseconday mb-0">28/08/2021</p>
												</div>
												<div class="col-lg-6 rtdescp"> 
													<p class="txtseconday mb-0 lnht20">This is a must buy phone. Battery capacity is too good but fast charging maintains it. Zooming is also good. Phone doesn’t heat too much. It doesn’t lags.</p>
												</div>
												<div class="col-lg-2 col-6">
													<label class="satisfiedusr fs14 f-600 txtdefault">Is User Satisfied?</label>
													<p class="txtseconday mb-0">Yes</p>
												</div>
											</div>
											<!-- /REPEAT -->
											<!-- REPEAT -->
											<div class="row py-4 align-items-center rating-bd ">
												<div class="col-lg-2">
													<div class="ratings star-ratings">
														<i class="fa fa-star" aria-hidden="true"></i>
														<i class="fa fa-star" aria-hidden="true"></i>
														<i class="fa fa-star" aria-hidden="true"></i>
														<i class="fa fa-star" aria-hidden="true"></i>
														<i class="fa fa-star-o" aria-hidden="true"></i>
													</div>
												</div>
												<div class="col-lg-2 col-6 dtrt md-ord4">
													<p class="txtseconday mb-0">28/08/2021</p>
												</div>
												<div class="col-lg-6 rtdescp"> 
													<p class="txtseconday mb-0 lnht20">This is a must buy phone. Battery capacity is too good but fast charging maintains it. Zooming is also good. Phone doesn’t heat too much. It doesn’t lags.</p>
												</div>
												<div class="col-lg-2 col-6">
													<label class="satisfiedusr fs14 f-600 txtdefault">Is User Satisfied?</label>
													<p class="txtseconday mb-0">Yes</p>
												</div>
											</div>
											<!-- /REPEAT -->
											<!-- REPEAT -->
											<div class="row py-4 align-items-center rating-bd ">
												<div class="col-lg-2">
													<div class="ratings star-ratings">
														<i class="fa fa-star" aria-hidden="true"></i>
														<i class="fa fa-star" aria-hidden="true"></i>
														<i class="fa fa-star" aria-hidden="true"></i>
														<i class="fa fa-star" aria-hidden="true"></i>
														<i class="fa fa-star-o" aria-hidden="true"></i>
													</div>
												</div>
												<div class="col-lg-2 col-6 dtrt md-ord4">
													<p class="txtseconday mb-0">28/08/2021</p>
												</div>
												<div class="col-lg-6 rtdescp"> 
													<p class="txtseconday mb-0 lnht20">This is a must buy phone. Battery capacity is too good but fast charging maintains it. Zooming is also good. Phone doesn’t heat too much. It doesn’t lags.</p>
												</div>
												<div class="col-lg-2 col-6">
													<label class="satisfiedusr fs14 f-600 txtdefault">Is User Satisfied?</label>
													<p class="txtseconday mb-0">Yes</p>
												</div>
											</div>
											<!-- /REPEAT -->
											<!-- REPEAT -->
											<div class="row py-4 align-items-center pb-0 ">
												<div class="col-lg-2">
													<div class="ratings star-ratings">
														<i class="fa fa-star" aria-hidden="true"></i>
														<i class="fa fa-star" aria-hidden="true"></i>
														<i class="fa fa-star" aria-hidden="true"></i>
														<i class="fa fa-star" aria-hidden="true"></i>
														<i class="fa fa-star-o" aria-hidden="true"></i>
													</div>
												</div>
												<div class="col-lg-2 col-6 dtrt md-ord4">
													<p class="txtseconday mb-0">28/08/2021</p>
												</div>
												<div class="col-lg-6 rtdescp"> 
													<p class="txtseconday mb-0 lnht20">This is a must buy phone. Battery capacity is too good but fast charging maintains it. Zooming is also good. Phone doesn’t heat too much. It doesn’t lags.</p>
												</div>
												<div class="col-lg-2 col-6">
													<label class="satisfiedusr fs14 f-600 txtdefault">Is User Satisfied?</label>
													<p class="txtseconday mb-0">Yes</p>
												</div>
											</div>
											<!-- /REPEAT -->
											<a href="javascript:void(0)" class="add-whislist-link mt-4 showintablet"><span>View More</span></a>
										</div>
									</div> 								
								</div>
							  </div> 
							<div class="text-right pr-4 mb-50">
								<nav class="mt-3 tbpg">
								  <ul class="pagination align-items-center">
									<li class="page-item"><a class="page-link previcon" href="#"></a></li>
									<li class="page-item"><a class="page-link sprevicon" href="#"></a></li>
									<li class="page-item"><a class="page-link" href="#">1</a></li>
									<li class="page-item"><a class="page-link" href="#">2</a></li>
									<li class="page-item"><a class="page-link" href="#">3</a></li>
									<li class="page-item"><a class="page-link" href="#">4</a></li>
									<li class="page-item"><a class="page-link snexticon" href="#"></a></li>
									<li class="page-item"><a class="page-link nexticon" href="#"></a></li>
								  </ul>
								</nav>
							</div>  
						</div>
					</div>
				</ng-template>
			</ng-container>
			<ng-container ngbNavItem>
				<a ngbNavLink class="txtseconday px-0 mx-sm-3 mx-2">On-Time-Delivery</a>
				<ng-template ngbNavContent>
					<div class="card cardui carduipro p-4 mb-0">
					  	<div class="mgb-head">
							<div class="row mb-4 pb-2 align-items-center">
								<div class="col-md-7">
									<div class="fs18 f-700">50 orders placed in</div>
								</div>	
								<div class="col-md-5">
									<div class="tpbr">
										<label class="fs12 txtseconday tpbrlbl">Report</label>
										<ng-selectize [config]="config" [options] = "data" placeholder="" >
										</ng-selectize>	
									</div>
								</div>	
							</div>
							<a href="javascript:void(0);" class="d-inline-block fltr-round-btn radius10 txtseconday">
								<img src="assets/images/svg/filter.svg">
								Filters
							</a>
						</div>
						<div class="table-responsive">
							<table class="table custom-table xs">
								<thead>
									<tr>
										<th>Order Id</th>
										<th>Order Date</th>
										<th>Delivery Date</th>
										<th>Buyer</th>
										<th>Status</th>
										<th>Delayed by <i class="fa fa-long-arrow-down"></i></th>
										<th>&nbsp;</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>Sep 29, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>Delivered</td>
										<td>
											<a href="javascript:;" class="link-2 color-orange">No Delay</a>
										</td>
										<td>
											<a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Details</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>Sep 29, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>Delivered</td>
										<td>
											<a href="javascript:;" class="link-2 color-orange">No Delay</a>
										</td>
										<td>
											<a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Details</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>Sep 29, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>Delivered</td>
										<td>
											<a href="javascript:;" class="link-2 color-orange">No Delay</a>
										</td>
										<td>
											<a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Details</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>Sep 29, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>Delivered</td>
										<td>
											<a href="javascript:;" class="link-2 color-orange">No Delay</a>
										</td>
										<td>
											<a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Details</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>Sep 29, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>Delivered</td>
										<td>
											<a href="javascript:;" class="link-2 color-orange">No Delay</a>
										</td>
										<td>
											<a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Details</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>Sep 29, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>Delivered</td>
										<td>
											<a href="javascript:;" class="link-2 color-orange">No Delay</a>
										</td>
										<td>
											<a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Details</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>Sep 29, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>Delivered</td>
										<td>
											<a href="javascript:;" class="link-2 color-orange">1 day (*)</a>
										</td>
										<td>
											<a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Details</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>Sep 29, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>Delivered</td>
										<td>
											<a href="javascript:;" class="link-2 color-orange">2 days (*)</a>
										</td>
										<td>
											<a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Details</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>Sep 29, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>Delivered</td>
										<td>
											<a href="javascript:;" class="link-2 color-orange">2 days (*)</a>
										</td>
										<td>
											<a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Details</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>Sep 29, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>Delivered</td>
										<td>
											<a href="javascript:;" class="link-2 color-orange">2 days (*)</a>
										</td>
										<td>
											<a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Details</a>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div class="row mb-50">
						<div class="col-md-6">
							<div class="text-left pl-3">
								<nav class="mt-3 tbpg rows-per-page">
									<div>Rows per page:</div>
									<select class="select-box">
										<option>10</option>
										<option>20</option>
									</select>
								</nav>
							</div> 
						</div>
						<div class="col-md-6">
							<div class="text-right pr-3">
								<nav class="mt-3 tbpg">
								  <ul class="pagination align-items-center">
									<li class="page-item"><a class="page-link previcon" href="#"></a></li>
									<li class="page-item"><a class="page-link sprevicon" href="#"></a></li>
									<li class="page-item"><a class="page-link" href="#">1</a></li>
									<li class="page-item"><a class="page-link" href="#">2</a></li>
									<li class="page-item"><a class="page-link" href="#">3</a></li>
									<li class="page-item"><a class="page-link" href="#">4</a></li>
									<li class="page-item"><a class="page-link snexticon" href="#"></a></li>
									<li class="page-item"><a class="page-link nexticon" href="#"></a></li>
								  </ul>
								</nav>
							</div> 
						</div>
					</div> 
				</ng-template>
			</ng-container>
			<ng-container ngbNavItem>
				<a ngbNavLink class="txtseconday px-0 mx-sm-3 mx-2">Order Fulfillment</a>
				<ng-template ngbNavContent>
				  <div class="card cardui carduipro p-4 mb-0">
						<div class="row mb-4 pb-2 align-items-center">
							<div class="col-md-7">
								<div class="fs18 f-700">50 orders placed in</div>
							</div>	
							<div class="col-md-5">
								<div class="tpbr">
									<label class="fs12 txtseconday tpbrlbl">Report</label>
									<ng-selectize [config]="config" [options] = "data" placeholder="" >
									</ng-selectize>	
								</div>
							</div>	
						</div>
						<div class="table-responsive">
							<table class="table custom-table xs">
								<thead>
									<tr>
										<th>EB Order Id</th>
										<th>Order Date</th>
										<th>Buyer</th>
										<th>Order Quantity</th>
										<th>Order Status</th>
										<th>&nbsp;</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>50</td>
										<td>Accepted</td>
										<td>
											<a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Details</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>50</td>
										<td>Accepted</td>
										<td>
											<a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Details</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>50</td>
										<td>Accepted</td>
										<td>
											<a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Details</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>50</td>
										<td>Accepted</td>
										<td>
											<a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Details</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>50</td>
										<td>Accepted</td>
										<td>
											<a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Details</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>50</td>
										<td>Accepted</td>
										<td>
											<a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Details</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>50</td>
										<td>Accepted</td>
										<td>
											<a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Details</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>50</td>
										<td>Accepted</td>
										<td>
											<a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Details</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>50</td>
										<td>Accepted</td>
										<td>
											<a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Details</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>50</td>
										<td>Accepted</td>
										<td>
											<a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Details</a>
										</td>
									</tr>
									
								</tbody>
							</table>
						</div>
					</div>

					<div class="row mb-50">
						<div class="col-md-6">
							<div class="text-left pl-3">
								<nav class="mt-3 tbpg rows-per-page">
									<div>Rows per page:</div>
									<select class="select-box">
										<option>10</option>
										<option>20</option>
									</select>
								</nav>
							</div> 
						</div>
						<div class="col-md-6">
							<div class="text-right pr-3">
								<nav class="mt-3 tbpg">
								  <ul class="pagination align-items-center">
									<li class="page-item"><a class="page-link previcon" href="#"></a></li>
									<li class="page-item"><a class="page-link sprevicon" href="#"></a></li>
									<li class="page-item"><a class="page-link" href="#">1</a></li>
									<li class="page-item"><a class="page-link" href="#">2</a></li>
									<li class="page-item"><a class="page-link" href="#">3</a></li>
									<li class="page-item"><a class="page-link" href="#">4</a></li>
									<li class="page-item"><a class="page-link snexticon" href="#"></a></li>
									<li class="page-item"><a class="page-link nexticon" href="#"></a></li>
								  </ul>
								</nav>
							</div> 
						</div>
					</div> 
				</ng-template>
			</ng-container>
			<ng-container ngbNavItem>
				<a ngbNavLink class="txtseconday px-0 mx-sm-3 mx-2">GST Filing Frequency</a>
				<ng-template ngbNavContent>
					<div class="card cardui carduipro p-4 mb-0">
						<div class="row mb-4 pb-2 align-items-center">
							<div class="col-md-7">
								<div class="fs18 f-700"><span class="opacity-60">GST Filing Frequency :</span> Monthly</div>
							</div>
						</div>

						<div class="table-responsive">
							<table class="table custom-table">
								<thead>
									<tr>
										<th>GSTIN Number</th>
										<th>Last GST Payment Date</th>
										<th>Buyer</th>
										<th>Document PDF</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>
											<a href="javascript:;" class="document d-flex align-items-center">
												<img src="assets/images/icons/pdf.png" width="25"> &nbsp;<div class="filename">GST Doc.pdf</div>
											</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>
											<a href="javascript:;" class="document d-flex align-items-center">
												<img src="assets/images/icons/pdf.png" width="25"> &nbsp;<div class="filename">GST Doc.pdf</div>
											</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>
											<a href="javascript:;" class="document d-flex align-items-center">
												<img src="assets/images/icons/pdf.png" width="25"> &nbsp;<div class="filename">GST Doc.pdf</div>
											</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>
											<a href="javascript:;" class="document d-flex align-items-center">
												<img src="assets/images/icons/pdf.png" width="25"> &nbsp;<div class="filename">GST Doc.pdf</div>
											</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>
											<a href="javascript:;" class="document d-flex align-items-center">
												<img src="assets/images/icons/pdf.png" width="25"> &nbsp;<div class="filename">GST Doc.pdf</div>
											</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>
											<a href="javascript:;" class="document d-flex align-items-center">
												<img src="assets/images/icons/pdf.png" width="25"> &nbsp;<div class="filename">GST Doc.pdf</div>
											</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>
											<a href="javascript:;" class="document d-flex align-items-center">
												<img src="assets/images/icons/pdf.png" width="25"> &nbsp;<div class="filename">GST Doc.pdf</div>
											</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>
											<a href="javascript:;" class="document d-flex align-items-center">
												<img src="assets/images/icons/pdf.png" width="25"> &nbsp;<div class="filename">GST Doc.pdf</div>
											</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>
											<a href="javascript:;" class="document d-flex align-items-center">
												<img src="assets/images/icons/pdf.png" width="25"> &nbsp;<div class="filename">GST Doc.pdf</div>
											</a>
										</td>
									</tr>
									<tr>
										<td>EB20210623203757</td>
										<td>Sep 20, 2021</td>
										<td>M/s. Computer Labtronics</td>
										<td>
											<a href="javascript:;" class="document d-flex align-items-center">
												<img src="assets/images/icons/pdf.png" width="25"> &nbsp;<div class="filename">GST Doc.pdf</div>
											</a>
										</td>
									</tr>
									
								</tbody>
							</table>
						</div>
					</div>

					<div class="row mb-50">
						<div class="col-md-6">
							<div class="text-left pl-3">
								<nav class="mt-3 tbpg rows-per-page">
									<div>Rows per page:</div>
									<select class="select-box">
										<option>10</option>
										<option>20</option>
									</select>
								</nav>
							</div> 
						</div>
						<div class="col-md-6">
							<div class="text-right pr-3">
								<nav class="mt-3 tbpg">
								  <ul class="pagination align-items-center">
									<li class="page-item"><a class="page-link previcon" href="#"></a></li>
									<li class="page-item"><a class="page-link sprevicon" href="#"></a></li>
									<li class="page-item"><a class="page-link" href="#">1</a></li>
									<li class="page-item"><a class="page-link" href="#">2</a></li>
									<li class="page-item"><a class="page-link" href="#">3</a></li>
									<li class="page-item"><a class="page-link" href="#">4</a></li>
									<li class="page-item"><a class="page-link snexticon" href="#"></a></li>
									<li class="page-item"><a class="page-link nexticon" href="#"></a></li>
								  </ul>
								</nav>
							</div> 
						</div>
					</div> 
				</ng-template>
			</ng-container>
		</nav>

		<div [ngbNavOutlet]="nav" class="mt-2"></div>
		<!-- /PRODUCT LISTING -->
	</div>
</div>

