import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { AddBrandService } from '../add-brand/add-brand.service';
import { ViewSellerListingService } from './view-seller-listing.service';

@Component({
    selector: 'app-view-seller-listing-products',
    templateUrl: './view-seller-listing-products.component.html',
    styleUrls: ['./view-seller-listing-products.component.scss']
})
export class ViewSellerListingProductsComponent implements OnInit {

    public category_id:string = '';
    public listing_status:string = '';
    public seller_id: string;
    public basic_info: any = {};
    public seller_records: any = [];
    public search_term:string = '';

    config = {
        labelField: 'name',
        valueField: 'value',
    }


    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 10;
    public seller_listing_pagination: any = {};
    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];

    public listing_status_options = [
        {
            value: '',
            name: 'All',
        },
        {
            value: 'active',
            name: 'Active'
        },
        {
            value: 'inactive',
            name: 'Inactive'
        }
    ];



    constructor(
        private _sellerlistingservice: ViewSellerListingService,
        private _toastr: ToasterService,
        private _route: ActivatedRoute,
        private _pagination: PaginationService,
        private _addbrandservice: AddBrandService
    ) { }

    ngOnInit(): void {
        this.basic_info.key_performance = {};
        this.seller_id = this._route.snapshot.paramMap.get('id');
        this.viewsellerlisting();
    }

    viewsellerlisting() {
        let limit = this.page_per_view;
        let offset = this.offset;
        let current_page = this.current_page;
        this._sellerlistingservice.viewsellerlisting({ category: this.category_id, listing_status: this.listing_status, seller_id: this.seller_id, limit: limit, offset: offset, current_page: current_page, search: this.search_term }).subscribe((data) => {
            if (data['status'] == "success") {
                let total_records = data['total_records'];
                this.seller_listing_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
                this.basic_info = data['basic'];
                this.seller_records = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    deletesellerlisting(uniqueId) {
        this._sellerlistingservice.deletesellerlisting({ seller_id: this.seller_id, unique_id: uniqueId }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Seller listing has been deleted successfully.');
                this.viewsellerlisting();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.viewsellerlisting();
    }

    setpageperview() {
        this.current_page = 1;
        this.viewsellerlisting();
    }

    keyword = 'name'
    result = [];

    selectEvent(item) {
        this.category_id = item.c_id;
        this.viewsellerlisting();
    }

    onChangeSearch(val: string) {
        this._addbrandservice.searchcategory({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.result = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    onFocused(e) {
        // do something when input is focused
    }

}
