import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AddNewPartnerService {

    constructor(private _http: HttpClient) { }

    addpartner(data) {
        return this._http.post(environment.api_url+'admin/addlogisticpartner',data);
    }

}
