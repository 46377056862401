import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { ProductDetailsService } from '../product-details/product-details.service';
import { BuyerWishlistService } from './buyer-wishlist.service';

@Component({
  selector: 'app-buyer-wishlist',
  templateUrl: './buyer-wishlist.component.html',
  styleUrls: ['./buyer-wishlist.component.scss']
})
export class BuyerWishlistComponent implements OnInit {

    public wishlist_records:any = [];
    constructor(
        private _wishlistservice: BuyerWishlistService,
        private _toastr: ToasterService,
        private _productdetailsservice: ProductDetailsService
    ) { }

    ngOnInit(): void {
        this.getwishlist();
    }

    getwishlist(){
        this._wishlistservice.getwishlist(null).subscribe((data) => {
            if(data['status'] == "success"){
                this.wishlist_records = data['records']
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    wishlist(record){
        this._productdetailsservice.wishlist({unique_id: record.unique_id}).subscribe((data) => {
            if(data['status'] == "success"){
                this._toastr.success(data['msg']);
                this.getwishlist();
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

}
