import { Injectable } from '@angular/core';
import { SignupService } from '../shared/components/modal/signup-modal/signup-modal.service';
@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private _signupservice: SignupService,) { }

    isLoggedIn() {
        let token = this._signupservice.gettoken();
        if (token) {
            return true;
        }
        return false;
    }

    loggedInUserType(expected) {
        let type = this._signupservice.getusertype();
        if (type && type == expected) {
            return true;
        }
        return false;
    }

    logoutUser() {
        this._signupservice.logout();
        return true;
    }

}
