<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<h3 class="fs24 wt700 txtdefault">Stock Details</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);" [routerLink]="['/admin/product-listing']">Products Management</a>
						</li>
						<li class="breadcrumb-item active">Stocks Details</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4 mb-0">
			<div class="notifications-container theme-tab">
                <div class="tab-content">
                    <!--Filters-->
                    <div class="row d-flex justify-content-between mt-20 report-cta">
                      <div class="col-md-3">
                          <div class="report-input">
                              <div class="input-group-custom">
                                  <input type="text" placeholder="Product Name, Brand Name & Model No." class="form-control" 
                                  class="form-control" [(ngModel)]="search_term" (change)="getproductlistingfilters()">
                                  <div class="input-group-append-custom">
                                      <img src="assets/images/svg/search_logo.svg">
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div class="col-md-3">
                          <div class="report-input">
                              <div class="input-group-custom">
                                  <div class="ng-autocomplete">
                                      <ng-autocomplete 
                                          [data]="result"
                                          [searchKeyword]="keyword"
                                          (selected)='selectEvent($event)'
                                          (inputChanged)='onChangeSearch($event)'
                                          (inputFocused)='onFocused($event)'
                                          [itemTemplate]="itemTemplate"
                                          [notFoundTemplate]="notFoundTemplate"
                                          placeholder="Search by Category Name"
                                      >
                                      </ng-autocomplete>
                                      <ng-template #itemTemplate let-item>
                                          <a href="javascript:void(0)">
                                              <div class="d-flex">
                                                  <span [innerHTML]="item.name"></span>
                                                  <!-- <span [innerHTML]="item.current" class="current"></span> -->
                                              </div>
                                          </a>
                                      </ng-template>
          
                                      <ng-template #notFoundTemplate let-notFound>
                                          <div [innerHTML]="notFound"></div>
                                      </ng-template>
                                      <div class="search-icon"><img src="assets/images/svg/search_logo.svg"></div>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div class="col-md-3">
                        <div class="report-input">
                            <div class="input-group-custom">
                                <div class="ng-autocomplete">
                                    <ng-autocomplete 
                                        [data]="resultSeller"
                                        [searchKeyword]="keyword"
                                        (selected)='selectEventSeller($event)'
                                        (inputChanged)='onChangeSearchSeller($event)'
                                        (inputFocused)='onFocusedSeller($event)'
                                        [itemTemplate]="itemTemplate"
                                        [notFoundTemplate]="notFoundTemplate"
                                        placeholder="Search by Seller Name"
                                    >
                                    </ng-autocomplete>
                                    <ng-template #itemTemplate let-item>
                                        <a href="javascript:void(0)">
                                            <div class="d-flex">
                                                <span [innerHTML]="item.name"></span>
                                                <!-- <span [innerHTML]="item.current" class="current"></span> -->
                                            </div>
                                        </a>
                                    </ng-template>
        
                                    <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                    <div class="search-icon"><img src="assets/images/svg/search_logo.svg"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                      <div class="col-md-2">
                        <div class="report-input">
                            <div class="input-group-custom">
                                <ng-selectize [config]="config" name="market_place" [(ngModel)]="market_place"
                                [options]="marketplace_options" placeholder="Marketplace" (ngModelChange)="getproductlistingfilters()"></ng-selectize>
                            </div>
                        </div>
                    </div>
                  </div>
                  <!--End Filters-->
                  <table class="table custom-table xs">
                      <thead>
                          <tr>
                              <th>Product ID</th>
                              <th width="18%">Product Name</th>
                              <th width="18%">Seller Name</th>
                              <th width="10%">Category</th>
                              <th>Listed On</th>
                              <th>Market Segment</th>
                              <th>Wholesale Qty.</th>
                              <th>Bulk Qty.</th>
                              <th width="18%">Action</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let product of stockListing; let i = index;">
                              <td>{{ product.product_code }}</td>
                              <td>
                                  <div class="order-info">
                                      <div class="order-img">
                                          <img src="{{ product.image? product.image: 'assets/images/p-2.jpg' }}" />
                                      </div>
                                      <div class="pd-seller-cntnt">
                                          <div class="txtdefault">
                                              <a href="javascript:;" [routerLink]="['/admin/edit-seller-listing/'+ product.uniqueId]" class="order-link">{{ product.name }}</a>
                                          </div>
                                      </div>
                                  </div>
                              </td>
                              <td>{{ product.seller_name }}</td>
                              <td>{{ product.category_name }}</td>
                              <td>{{ product.listed_on }}</td>
                              <td [innerHtml]="product.marketplace"></td>
                              <td>{{ !product.wholesale_outofstock?product.wholesale_quantity:"Out of stock ("+product.wholesale_quantity+")" }}</td>
                              <td>{{ !product.bulk_outofstock?product.bulk_quantity:'Out of stock ('+product.bulk_quantity+')' }}</td>
                              <td class="d-flex align-items-center">
                                  <a href="javascript:void(0);" class="btn-d-outline ripple d-inline-block place-bid mr-3" [routerLink]="['/seller/viewlisting', product.uniqueId]">View Details</a>
                              </td>
                          </tr>
                          
                          <!--Empty State-->
                          <tr *ngIf="!stockListing.length">
                              <td colspan="8">
                                  <div class="row pt-40 pb-40">
                                      <div class="col-md-12 text-center">
                                          <div class="mb-20"><img src="/assets/images/empty-state.png"></div>
                                          <div class="fs16">There are no records to display!</div>
                                      </div>
                                  </div>
                              </td>
                          </tr>
                          <!--End Empty State-->
                          
                          
                      </tbody>
                  </table>
                </div>
	    	</div>
		</div>
		<!-- Product Listing Pagination-->
		<div class="row" *ngIf="activeTab == 'productListing' && stockListing.length > 0">
			<div class="col-md-6">
				<div class="text-left pl-3">
					<nav class="mt-3 tbpg rows-per-page">
						<div>Rows per page:</div>
						<select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
							<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">{{option.name}}</option>
						</select>
					</nav>
				</div> 
			</div>
			<div class="col-md-6" *ngIf="stock_listing_pagination.pages && stock_listing_pagination.pages.length">
				<div class="text-right pr-3">
					<nav class="mt-3 tbpg">
					  <ul class="pagination align-items-center">
						<li class="page-item" [ngClass]="{disabled:stock_listing_pagination.currentPage === 1}"><a class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a></li>

						<li class="page-item" [ngClass]="{disabled:stock_listing_pagination.currentPage === 1}"><a class="page-link sprevicon" href="javascript:;" (click)="setpage(stock_listing_pagination.currentPage - 1)"></a></li>

						<li class="page-item"i *ngFor="let page of stock_listing_pagination.pages" [ngClass]="{active:stock_listing_pagination.currentPage === page}"><a class="page-link" href="javascript:;" (click)="setpage(page)">{{page}}</a></li>

						<li *ngIf="(stock_listing_pagination.totalPages - stock_listing_pagination.startPage) > 5 ">
							<a (click)="setpage(stock_listing_pagination.startPage + 5)">...</a>
						</li>
						 <li *ngIf="(stock_listing_pagination.totalPages - stock_listing_pagination.startPage) > 5" >
							<a (click)="setpage(stock_listing_pagination.totalPages)">{{stock_listing_pagination.totalPages}}</a>
						</li>
						
						<li class="page-item" [ngClass]="{disabled:stock_listing_pagination.currentPage === stock_listing_pagination.totalPages}"><a class="page-link snexticon" href="javascript:;"  (click)="setpage(stock_listing_pagination.currentPage + 1)"></a></li>
						<li class="page-item" [ngClass]="{disabled:stock_listing_pagination.currentPage === stock_listing_pagination.totalPages}"><a class="page-link nexticon" href="javascript:;" (click)="setpage(stock_listing_pagination.totalPages)"></a></li>
					  </ul>
					</nav>
				</div> 
			</div>
		</div>
		<!-- Product Listing Pagination -->
	</div>
</div>
