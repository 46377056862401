<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain pb-4">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center pinfo-head">
			<div class="col-md-5 mb-4">
				<h3 class="fs24 wt700 txtdefault">Orders</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">						
						<li class="breadcrumb-item "><a href="javascript:void(0);" class="txtseconday">Order Management</a></li>
						<li class="breadcrumb-item active">Orders</li>
					</ol>
				</nav>
			</div>
			<div class="col-md-7  mb-4 text-md-right text-center">
				
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4 mb-0">
			<div class="notifications-container theme-tab">
				<ngb-tabset [justify]="'left'" [type]="'pills'" class="tabs tab-title">
		            <!-- All Orders -->
					<ngb-tab>
						<ng-template ngbTabTitle><span (click)="getorders('all')">All Orders</span></ng-template>
					</ngb-tab>

					<!-- Booked Orders -->
					<ngb-tab>
						<ng-template ngbTabTitle><span (click)="getorders('booked')">Booked</span></ng-template>
					</ngb-tab>

					<!-- Approved Orders -->
					<ngb-tab>
						<ng-template ngbTabTitle><span (click)="getorders('approved')">Approved</span></ng-template>
					</ngb-tab>

					<!-- Rejected Orders -->
					<ngb-tab>
						<ng-template ngbTabTitle><span (click)="getorders('rejected')">Rejected</span></ng-template>
					</ngb-tab>

					<!-- Payment Done Orders -->
					<ngb-tab>
						<ng-template ngbTabTitle><span (click)="getorders('payment_done')">Payment Done</span></ng-template>
					</ngb-tab>

					<!-- Ready to Dispatch Orders -->
					<ngb-tab>
						<ng-template ngbTabTitle><span (click)="getorders('ready_to_dispatch')">Ready to Dispatch</span></ng-template>
					</ngb-tab>

					<!-- Quality Done Orders -->
					<ngb-tab>
						<ng-template ngbTabTitle><span (click)="getorders('quality_done')">Quality Done</span></ng-template>
					</ngb-tab>

					<!-- Dispatched Orders -->
					<ngb-tab>
						<ng-template ngbTabTitle><span (click)="getorders('dispatched')">Dispatched</span></ng-template>
					</ngb-tab>

					<!-- Delivered Orders -->
					<ngb-tab>
						<ng-template ngbTabTitle><span (click)="getorders('delivered')">Delivered</span></ng-template>
					</ngb-tab>
		            										
	        	</ngb-tabset>
			</div>
			<div class="tab-content">
				<!--Filters-->
				<div class="row d-flex justify-content-between mt-20 report-cta">
					<div class="col-lg-5 mb-3">
						<div class="report-input">
							<div class="input-group-custom">
								<input type="text" placeholder="Search by Order ID, Transaction ID, Product Name & Brand" class="form-control" 
								class="form-control" [(ngModel)]="search_term" (change)="getordersfilters()">
								<div class="input-group-append-custom">
									<img src="assets/images/svg/search_logo.svg">
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg-7">
						<div class="d-flex clbxgrd justify-content-end lg-justify-content-start">
							<!-- <div class="mr-3">
								<a href="javascript:void(0);" class="d-inline-block fltr-round-btn radius10 txtseconday">
									<img src="assets/images/svg/filter.svg">
									Filters
								</a>
								<div class="filter-show-div" style="display: none;">
									<div class="row no-gutters">
										<div class="col-md-3">
											<div class="left-filter">
												<ul>
													<li class="active"><a href="javascript:;">Category</a></li>
													<li><a href="javascript:;">Listing Available</a></li>
													<li><a href="javascript:;">Brand</a></li>
												</ul>
											</div>
										</div>
										<div class="col-md-9 right-contcc">

											<div class="right-filter lsting">
												<div class="report-input mb-3">
													<div class="input-group-custom">
														<input type="text" placeholder="Search by Category" class="form-control">
														<div class="input-group-append-custom">
															<img src="assets/images/svg/search_logo.svg">
														</div>
													</div>
												</div>
												<ul class="list-group">
													<li class="list-group-item">
														<div class="css-check">
															<input class="css-checkbx" type="checkbox" />
															<label class="css-label">Mobile Phones & Accessories (121)</label>
														</div>								
													</li>
													<li class="list-group-item">
														<div class="css-check">
															<input class="css-checkbx" type="checkbox" />
															<label class="css-label">Computers & Electronics (67)</label>
														</div>
													</li>
													<li class="list-group-item">
														<div class="css-check">
															<input class="css-checkbx" type="checkbox" />
															<label class="css-label">Laptops & Computers (34)</label>
														</div>
													</li>
													<li class="list-group-item">
														<div class="css-check">
															<input class="css-checkbx" type="checkbox" />
															<label class="css-label">Healthcare Devices (12)</label>
														</div>
													</li>
													<li class="list-group-item">
														<div class="css-check">
															<input class="css-checkbx" type="checkbox" />
															<label class="css-label">Smart Electronic Devices (34)</label>
														</div>
													</li>
													<li class="list-group-item">
														<div class="css-check">
															<input class="css-checkbx" type="checkbox" />
															<label class="css-label">Personal Care Devices (33)</label>
														</div>
													</li>
												</ul>
											</div>

											<div class="right-filter lsting" style="display: none;">
												<ul class="list-group">
													<li class="list-group-item">
														<div class="css-check">
															<input class="css-checkbx" type="checkbox" />
															<label class="css-label">Wholesale (121)</label>
														</div>								
													</li>
													<li class="list-group-item">
														<div class="css-check">
															<input class="css-checkbx" type="checkbox" />
															<label class="css-label">Bulk (60)</label>
														</div>
													</li>
													<li class="list-group-item">
														<div class="css-check">
															<input class="css-checkbx" type="checkbox" />
															<label class="css-label">Bids & Offers (34)</label>
														</div>
													</li>
												</ul>
											</div>

											<div class="right-filter lsting" style="display: none;">
												<div class="report-input mb-3">
													<div class="input-group-custom">
														<input type="text" placeholder="Search by Category" class="form-control">
														<div class="input-group-append-custom">
															<img src="assets/images/svg/search_logo.svg">
														</div>
													</div>
												</div>
												<ul class="list-group">
													<li class="list-group-item">
														<div class="css-check">
															<input class="css-checkbx" type="checkbox" />
															<label class="css-label">OPPO (121)</label>
														</div>								
													</li>
													<li class="list-group-item">
														<div class="css-check">
															<input class="css-checkbx" type="checkbox" />
															<label class="css-label">VIVO (67)</label>
														</div>
													</li>
													<li class="list-group-item">
														<div class="css-check">
															<input class="css-checkbx" type="checkbox" />
															<label class="css-label">Samsung (34)</label>
														</div>
													</li>
													<li class="list-group-item">
														<div class="css-check">
															<input class="css-checkbx" type="checkbox" />
															<label class="css-label">Xiaomi (121)</label>
														</div>
													</li>
													<li class="list-group-item">
														<div class="css-check">
															<input class="css-checkbx" type="checkbox" />
															<label class="css-label">One plus (121)</label>
														</div>
													</li>
													<li class="list-group-item">
														<div class="css-check">
															<input class="css-checkbx" type="checkbox" />
															<label class="css-label">PMobile Phones & Accessories (121)</label>
														</div>
													</li>
												</ul>
											</div>

											<div class="footer-cta">
												<div class="mr-3">
													<a href="javascript:;"><svg xmlns="http://www.w3.org/2000/svg" width="10.239" height="10.245" viewBox="0 0 10.239 10.245">
														<path id="Icon_material-refresh" data-name="Icon material-refresh" d="M14.749,7.5a5.123,5.123,0,1,0,1.332,4.9H14.749a3.84,3.84,0,1,1-3.618-5.123,3.787,3.787,0,0,1,2.7,1.14l-2.062,2.062h4.482V6Z" transform="translate(-6.015 -6)" fill="#ff7135"/>
													</svg> &nbsp;&nbsp;<span class="link-alt fs14">Reset Filters</span></a>
												</div>
												<div><a href="" class="btn buybtn fs16 radius10 ripple">Apply</a></div>
											</div>
										</div>
									</div>
								</div>
							</div> -->

							
							<div class="form-container mt-0 mb-0 mr-3">
								<div class="report-input floating-label">
									<div class="input-group-custom">
										<input type="text" [(ngModel)]="from_date" (ngModelChange) = "getordersfilters()" class="form-control" placeholder=" " ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" autocomplete="off"/>
										<span class="highlight"></span>
										<label>From Date</label>
										<div class="input-group-append-custom">
											<img src="assets/images/icons/filter-calendar.svg">
										</div>
									</div>
								</div>
							</div>

							<div class="form-container mt-0 mb-0 mr-3">
								<div class="form-container m-0">
									<div class="report-input floating-label">
										<div class="input-group-custom">
											<input type="text" [(ngModel)]="to_date" (ngModelChange) = "getordersfilters()" class="form-control" placeholder=" " ngbDatepicker #dd="ngbDatepicker" (click)="dd.toggle()" autocomplete="off"/>
											<span class="highlight"></span>
											<label>To Date</label>
											<div class="input-group-append-custom">
												<img src="assets/images/icons/filter-calendar.svg">
											</div>
										</div>
									</div>
								</div>
							</div>

							<!-- <div>
								<a href="javascript:void(0);" class="btn-d-outline d-inline-block">
									<svg xmlns="http://www.w3.org/2000/svg" width="12.681" height="17.061" viewBox="0 0 12.681 17.061">
										<g id="np_export_2535025_000000" transform="translate(-32.121 -25.962)">
										<path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#ff7135"/>
										<path id="Path_2356" data-name="Path 2356" d="M44.093,70.07H32.83a.709.709,0,1,0,0,1.419H44.093a.709.709,0,0,0,0-1.419Z" transform="translate(0 -28.466)" fill="#ff7135"/>
										</g>
									</svg>
									Export
								</a>
							</div> -->
						</div>
					</div>
				</div>
				<!--End Filters-->
				<div class="cssinptchk d-inline-block mr-4 position-relative pl-4">
					<span class="css-check">
						<input class="css-checkbx" name="map_type" type="radio" value="all" [(ngModel)]="map_type" (ngModelChange)="getordersfilters()">
						<label class="css-label"></label>
					</span>
					<p>All</p>															
				</div>
				<div class="cssinptchk d-inline-block mr-3 position-relative pl-4">
					<span class="css-check">
						<input class="css-checkbx" name="map_type" type="radio" value="bulk"  [(ngModel)]="map_type" (ngModelChange)="getordersfilters()"/>
						<label class="css-label"></label>
					</span>
					<p>Bulk</p>															
				</div>
				<div class="cssinptchk d-inline-block mr-3 position-relative pl-4">
					<span class="css-check">
						<input class="css-checkbx" name="map_type" type="radio" value="wholesale"  [(ngModel)]="map_type" (ngModelChange)="getordersfilters()"/>
						<label class="css-label"></label>
					</span>
					<p>Wholesale</p>															
				</div>

				<div class="cssinptchk d-inline-block mr-3 position-relative pl-4 text-right">
					<div class="text-right mb-10">
						<a href="javascript:void(0);" (click)="getordersfilters('yes')" class="btn-d-outline d-inline-block place-bid" >
							<svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
							<g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
								<path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#ff7135"/>
							</g>
							</svg>					
							<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
									*ngIf="export_loading"></span>
								&nbsp;Export Report
						</a>
						<a href="{{ export_path }}"
							*ngIf="export_path"
							class="btn btn-default"
							target="_blank"
							download="Seller.csv"
							>Download Now
						</a>
					</div>														
				</div>

				<div class="table-responsive brndmgnttable odmgnttabl"> 
					<table class="table custom-table xs">
						<thead>
							<tr>
								<th>Order Id</th>
								<th>Transaction Id</th>
								<th width="18%">Product Name</th>
								<th>Buyer</th>
								<th width="10%">Price (in ₹)
								<img src="assets/images/admin/arrow.svg" />
								</th>
								<th width="10%">Pre-Booked On</th>
								<th>Order Status</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							<!--Empty State-->
							<tr *ngIf="!allorder_records.length">
								<td colspan="7">
									<div class="row pt-40 pb-40">
										<div class="col-md-12 text-center">
											<div class="mb-20"><img src="/assets/images/empty-state.png">
											</div>
											<div class="fs16">There are no records to display!</div>
										</div>
									</div>
								</td>
							</tr>
							<!--End Empty State-->
							<tr *ngFor="let record of allorder_records;">
								<td>{{ record.order_id }}</td>
								<td>{{ record.transaction_id }}</td>
								<td>
									<div class="order-info d-flex align-items-start">
										<div class="order-img">
											<img [src]="record.product_image" />
										</div>
										<div class="pd-seller-cntnt">
											<div class="txtdefault">
												<a href="javascript:;" class="order-link">{{ record.product_name }}</a>
											</div>
											<div class="pt-2">
												<span class="py-1 px-3 odbdge btn-d-outline ripple d-inline-block fs12">{{ record.map_type }}</span>
											</div>
										</div>
									</div>

								</td>												
								<td>{{ record.buyer_name }}</td>
								<td>{{ record.total_amount }}</td>
								<td>{{ record.prebookedon }}</td>
								<td>{{ record.order_status }}</td>												
								<td>
									<div class="d-flex align-items-center">
										<a href="javascript:voi(0);" class="add-whislist-link mr-3"><span>Track Order</span></a>
										<a href="javascript:void(0);" [routerLink]="['/admin/ordderdetails/'+record.id]" class="btn-d-outline align-middle ripple d-inline-block place-bid mr-3">Order Details</a>
										
										<a class="mr-3" href="javascript:void(0);" >
											<img src="assets/images/admin/edit.svg">
										</a>
										<span class="vdivider mr-3"></span>
										<a href="javascript:void(0);" [routerLink]="['/admin/duplicateorder']"><img src="assets/images/admin/dfile.svg"></a>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>	
				</div>
			<div class="row mb-40" *ngIf="allorder_pagination.totalPages> 1">
				<div class="col-md-6">
					<div class="text-left pl-3">
						<nav class="mt-3 tbpg rows-per-page">
							<div>Rows per page:</div>
							<select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
								<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">
									{{option.name}}</option>
							</select>
						</nav>
					</div>
				</div>
				<div class="col-md-6">
					<div class="text-right pr-3">
						<nav class="mt-3 tbpg">
							<ul class="pagination align-items-center">
								<li class="page-item" [ngClass]="{disabled:allorder_pagination.currentPage === 1}"><a
										class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a>
								</li>

								<li class="page-item" [ngClass]="{disabled:allorder_pagination.currentPage === 1}"><a
										class="page-link sprevicon" href="javascript:;"
										(click)="setpage(allorder_pagination.currentPage - 1)"></a></li>

								<li class="page-item" i *ngFor="let page of allorder_pagination.pages"
									[ngClass]="{active:allorder_pagination.currentPage === page}"><a class="page-link"
										href="javascript:;" (click)="setpage(page)">{{page}}</a></li>

								<li *ngIf="(allorder_pagination.totalPages - allorder_pagination.startPage) > 5 ">
									<a (click)="setpage(allorder_pagination.startPage + 5)">...</a>
								</li>
								<li *ngIf="(allorder_pagination.totalPages - allorder_pagination.startPage) > 5">
									<a
										(click)="setpage(allorder_pagination.totalPages)">{{allorder_pagination.totalPages}}</a>
								</li>

								<li class="page-item"
									[ngClass]="{disabled:allorder_pagination.currentPage === allorder_pagination.totalPages}">
									<a class="page-link snexticon" href="javascript:;"
										(click)="setpage(allorder_pagination.currentPage + 1)"></a>
								</li>
								<li class="page-item"
									[ngClass]="{disabled:allorder_pagination.currentPage === allorder_pagination.totalPages}">
									<a class="page-link nexticon" href="javascript:;"
										(click)="setpage(allorder_pagination.totalPages)"></a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
			</div> 
		</div>
	</div>
</div>	
<!-- REMOVE PRODUCT MODAL -->  
<ng-template #content let-modal>
  <div class="modal-header nobd text-center pt-4 pb-0">
    <h4 class="modal-title fs28 txtdefault wt700 mx-auto pt-3">Delete Request</h4>
    <button type="button" class="btn-close close rmvmdl" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
	<div class="modal-body">
		<p class="fs12 txtseconday py-3 mb-2 ">
		<span class="mr-2">
			<svg id="Group_46" data-name="Group 46" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="7.5" cy="7.5" r="7.5" fill="#07f"/>
			  <path id="Path_2235" data-name="Path 2235" d="M7,8.171l-.128.523q-.576.227-.919.346a2.425,2.425,0,0,1-.8.119,1.591,1.591,0,0,1-1.084-.34,1.1,1.1,0,0,1-.387-.864,3.056,3.056,0,0,1,.029-.416q.029-.213.093-.48l.48-1.7q.064-.244.108-.462a2.01,2.01,0,0,0,.044-.4A.616.616,0,0,0,4.3,4.047a.759.759,0,0,0-.513-.128,1.345,1.345,0,0,0-.381.058c-.131.039-.242.076-.336.11L3.2,3.564q.471-.192.9-.329A2.7,2.7,0,0,1,4.916,3.1a1.552,1.552,0,0,1,1.068.335,1.111,1.111,0,0,1,.375.869c0,.074-.008.2-.026.39a2.606,2.606,0,0,1-.1.512L5.759,6.9a4.492,4.492,0,0,0-.1.466,2.4,2.4,0,0,0-.047.4.57.57,0,0,0,.15.459.85.85,0,0,0,.523.122,1.509,1.509,0,0,0,.4-.061A2.21,2.21,0,0,0,7,8.171Zm.121-7.1a.99.99,0,0,1-.334.753,1.138,1.138,0,0,1-.8.312,1.15,1.15,0,0,1-.809-.312.988.988,0,0,1-.337-.753A1,1,0,0,1,5.169.314a1.194,1.194,0,0,1,1.614,0A1,1,0,0,1,7.117,1.07Z" transform="translate(2.406 2.92)" fill="#fff"/>
			</svg>
		</span>
		By deleting the request, the Equibiz team won’t be able to see your requested listing.</p>
		<p class="fs16 txtseconday pb-3 mb-2 text-center">Are you sure you want to delete the requested listing?</p>
		<div class="d-table mb-4 w-100">
			<div class="d-table-cell cart-thumb align-middle">
				<img width="60" src="assets/images/products/p-2.jpg">
			</div>
			<div class="d-table-cell pl-3 align-middle">
				<p class="txtdefault wt700 mb-0 fs14 lnht20">Samsung Galaxy M21 2021 Edition (Arctic Blue, 4GB RAM, 64GB Storage) | FHD+ sAMOLED | 6 Months Free Screen Replacement for Prime</p>
			</div>
		</div>
	</div>
  <div class="modal-footer  text-center nobd mx-auto pb-4 mb-3">
    <button type="button" class="btn-d-outline d-inline-block cancel-outline mr-3" (click)="modal.close('Close click')">Cancel</button>
	<button type="button" class="btn buybtn fs16 radius10">Remove</button>
  </div>
</ng-template>
<!-- /REMOVE PRODUCT MODAL --> 