<app-header-seller></app-header-seller>
<app-sidebar-seller></app-sidebar-seller>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<h3 class="fs24 wt700 txtdefault">Payment Report</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">My Business Reports</a>
						</li>
						<li class="breadcrumb-item active">Payment Report</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:void(0);" [routerLink]="['/seller/assistance']" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="row">
			<div class="col-md-4">
				<div class="card cardui carduipro p-4 mb-30 mb-xs-10">
					<a [routerLink]="['/consolidated-payment-report']">
						<div class="view-reports">
							<svg xmlns="http://www.w3.org/2000/svg" width="12.756" height="9.983" viewBox="0 0 12.756 9.983">
							  <path id="np_arrow_2050215_000000" d="M39.757,36.987a.506.506,0,0,0-.121-.347L36.03,32.2a.569.569,0,0,0-.8-.083.546.546,0,0,0-.061.777l2.868,3.536H27.555a.555.555,0,1,0,0,1.109H38.041l-2.868,3.536a.572.572,0,0,0,.075.809.614.614,0,0,0,.783-.116l3.6-4.437a.467.467,0,0,0,.121-.347Z" transform="translate(-27.001 -31.995)" fill="#ff7135"/>
							</svg>
						</div>
						<h3 class="f-700 fs18 txtdefault pb-10">Consolidated Payment Report</h3>
						<div class="card-text">Download the detailed payment report</div>
					</a>
				</div> 
			</div>
			<div class="col-md-4">
				<div class="card cardui carduipro p-4 mb-30 mb-xs-10">
					<a [routerLink]="['/wallet-transaction']">
						<div class="view-reports">
							<svg xmlns="http://www.w3.org/2000/svg" width="12.756" height="9.983" viewBox="0 0 12.756 9.983">
							  <path id="np_arrow_2050215_000000" d="M39.757,36.987a.506.506,0,0,0-.121-.347L36.03,32.2a.569.569,0,0,0-.8-.083.546.546,0,0,0-.061.777l2.868,3.536H27.555a.555.555,0,1,0,0,1.109H38.041l-2.868,3.536a.572.572,0,0,0,.075.809.614.614,0,0,0,.783-.116l3.6-4.437a.467.467,0,0,0,.121-.347Z" transform="translate(-27.001 -31.995)" fill="#ff7135"/>
							</svg>
						</div>
						<h3 class="f-700 fs18 txtdefault pb-10">Wallet Transaction Report</h3>
						<div class="card-text">Download the transaction report routed through the wallet</div>
					</a>
				</div> 
			</div>
			<div class="col-md-4">
				<div class="card cardui carduipro p-4 mb-30 mb-xs-10">
					<a [routerLink]="['/outstanding-payment']">
						<div class="view-reports">
							<svg xmlns="http://www.w3.org/2000/svg" width="12.756" height="9.983" viewBox="0 0 12.756 9.983">
							  <path id="np_arrow_2050215_000000" d="M39.757,36.987a.506.506,0,0,0-.121-.347L36.03,32.2a.569.569,0,0,0-.8-.083.546.546,0,0,0-.061.777l2.868,3.536H27.555a.555.555,0,1,0,0,1.109H38.041l-2.868,3.536a.572.572,0,0,0,.075.809.614.614,0,0,0,.783-.116l3.6-4.437a.467.467,0,0,0,.121-.347Z" transform="translate(-27.001 -31.995)" fill="#ff7135"/>
							</svg>
						</div>
						<h3 class="f-700 fs18 txtdefault pb-10">Outstanding Payment Report</h3>
						<div class="card-text">Download the detailed report of all outstanding payment which are pending for settlemen</div>
					</a>
				</div> 
			</div>
			<div class="col-md-4">
				<div class="card cardui carduipro p-4 mb-30 mb-xs-10">
					<a [routerLink]="['/settled-payment']">
						<div class="view-reports">
							<svg xmlns="http://www.w3.org/2000/svg" width="12.756" height="9.983" viewBox="0 0 12.756 9.983">
							  <path id="np_arrow_2050215_000000" d="M39.757,36.987a.506.506,0,0,0-.121-.347L36.03,32.2a.569.569,0,0,0-.8-.083.546.546,0,0,0-.061.777l2.868,3.536H27.555a.555.555,0,1,0,0,1.109H38.041l-2.868,3.536a.572.572,0,0,0,.075.809.614.614,0,0,0,.783-.116l3.6-4.437a.467.467,0,0,0,.121-.347Z" transform="translate(-27.001 -31.995)" fill="#ff7135"/>
							</svg>
						</div>
						<h3 class="f-700 fs18 txtdefault pb-10">Settled Payment Report</h3>
						<div class="card-text">Download the detailed report of all the payment due for settled transaction</div>
					</a>
				</div> 
			</div>
			<div class="col-md-4">
				<div class="card cardui carduipro p-4 mb-30 mb-xs-10">
					<a [routerLink]="['/charges-and-commission']">
						<div class="view-reports">
							<svg xmlns="http://www.w3.org/2000/svg" width="12.756" height="9.983" viewBox="0 0 12.756 9.983">
							  <path id="np_arrow_2050215_000000" d="M39.757,36.987a.506.506,0,0,0-.121-.347L36.03,32.2a.569.569,0,0,0-.8-.083.546.546,0,0,0-.061.777l2.868,3.536H27.555a.555.555,0,1,0,0,1.109H38.041l-2.868,3.536a.572.572,0,0,0,.075.809.614.614,0,0,0,.783-.116l3.6-4.437a.467.467,0,0,0,.121-.347Z" transform="translate(-27.001 -31.995)" fill="#ff7135"/>
							</svg>
						</div>
						<h3 class="f-700 fs18 txtdefault pb-10">Charges and Commission Report</h3>
						<div class="card-text">Download the order wise charges and commission report</div>
					</a>
				</div> 
			</div>
			<div class="col-md-4">
				<div class="card cardui carduipro p-4 mb-30 mb-xs-10">
					<a [routerLink]="['/refund-report']">
						<div class="view-reports">
							<svg xmlns="http://www.w3.org/2000/svg" width="12.756" height="9.983" viewBox="0 0 12.756 9.983">
							  <path id="np_arrow_2050215_000000" d="M39.757,36.987a.506.506,0,0,0-.121-.347L36.03,32.2a.569.569,0,0,0-.8-.083.546.546,0,0,0-.061.777l2.868,3.536H27.555a.555.555,0,1,0,0,1.109H38.041l-2.868,3.536a.572.572,0,0,0,.075.809.614.614,0,0,0,.783-.116l3.6-4.437a.467.467,0,0,0,.121-.347Z" transform="translate(-27.001 -31.995)" fill="#ff7135"/>
							</svg>
						</div>
						<h3 class="f-700 fs18 txtdefault pb-10">Refund Report</h3>
						<div class="card-text">Download the detailed refund report</div>
					</a>
				</div> 
			</div>
		</div>
	</div>
</div>

<app-footer-seller></app-footer-seller>