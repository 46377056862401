import { Component, OnInit } from '@angular/core';
import { SignupService } from "../../shared/components/modal/signup-modal/signup-modal.service";
import { SidebarAdminService } from './sidebar-admin.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-sidebar-admin',
    templateUrl: './sidebar-admin.component.html',
    styleUrls: ['./sidebar-admin.component.scss']
})
export class SidebarAdminComponent implements OnInit {
    constructor(
        public _signupservice: SignupService,
        private _sidebaradminservice: SidebarAdminService,
        private _toastr: ToasterService,
        private router: Router
    ) { }
    activeIds: string[] = [];
    showside: boolean = false
    public user_info: any = {};
    ngOnInit(): void {
        this.userbasicinfo();
        //this.activeIds = ['panel-user'];
        this.activeurl();
    }
    showsidebar() {
        this.showside = !this.showside
    }
    logout() {
        this._signupservice.logout();
        window.location.href = "/home";
    }

    activeurl() {
        var current_url = this.router.url;
        if (current_url == '/admin/manage-buyer/*' || current_url == '/admin/manage-seller/*') {
            this.activeIds = ['panel-user'];
        }
    }

    userbasicinfo() {
        this._sidebaradminservice.userbasicinfo(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.user_info = data['data'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

}
