import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private _http: HttpClient) { }

  registration(data) {
    return this._http.post(environment.api_url+'registration',data);
  }
  
  loginuserdetail(data) {
    return this._http.post(environment.api_url+'sociallogindetail',data);
  }

  getbusinesscategory(data) {
    return this._http.post(environment.api_url+'master/businesscategory/list',data);
  }
}