<app-header-one [sticky]="true"></app-header-one>

<section class="small-section">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h2 class="section-title font-alt">Equibiz <span class="color-blue">Buy Now</span> - <span class="color-orange">Pay Later</span></h2>
			</div>
		</div>
	</div>
</section>

<section class="small-section pt-0 hygiene-text text-left">
	<div class="container">
		<div class="row d-flex justify-content-center">
			<div class="col-md-6">
				<div class="contact-form-container text-center">
					<img *ngIf="token" src="assets/images/bnpl.jpg" class="img-fluid" [routerLink]="['/buyer/buyer-buy-now-pay-later']">
					<img *ngIf="!token" src="assets/images/bnpl.jpg" class="img-fluid" (click)="SignIn.openSigninModal('home')">
				</div>
			</div>
		</div>
	</div>
</section>

<app-signup-modal #signIn></app-signup-modal>
<app-footer-one></app-footer-one>