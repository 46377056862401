import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CmsBuyerHomeService {

  constructor(private _http: HttpClient) { }

    savecms(data) {
        return this._http.post(environment.api_url+'master/brand/add',data);
    }

    searchcategory(data) {
        return this._http.post(environment.api_url+'searchcategory',data);
    }

    searchproduct(data) {
      return this._http.post(environment.api_url+'cms/searchproduct/autocomplete',data);
    }

    savehomecms(data) {
      return this._http.post(environment.api_url+'admin/cms/update',data);
    }

    getcmsdata(data) {
      return this._http.post(environment.api_url+'admin/cms/home/data',data);
    }

}
