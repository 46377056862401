import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { AdminWalletService } from './wallet.service';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})
export class WalletComponent implements OnInit {

    public wallet_details:any = {};
    constructor(
        private _adminwalletservice: AdminWalletService,
        private _toastr: ToasterService
    ) { }

    ngOnInit(): void {
        this.getwalletdetails();
    }

    getwalletdetails(){
        this._adminwalletservice.getwalletdetails(null).subscribe((data) => {
            if(data['status'] == "success"){
                this.wallet_details = data['record'];
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

}
