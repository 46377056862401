import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seller-report',
  templateUrl: './seller-report.component.html',
  styleUrls: ['./seller-report.component.scss']
})
export class SellerReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
