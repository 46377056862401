import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SignupService } from 'src/app/shared/components/modal/signup-modal/signup-modal.service';
import { HeaderOneService } from 'src/app/shared/header/header-one/header-one.service';

@Component({
    selector: 'app-sidebar-seller',
    templateUrl: './sidebar-seller.component.html',
    styleUrls: ['./sidebar-seller.component.scss']
})
export class SidebarSellerComponent implements OnInit {

    public name: string;
    public wallet_amount: string;
    public seller_type:string;
    constructor(
        public _signupservice: SignupService,
        public router: Router,
        public _headerservice: HeaderOneService,
    ) { }
    showside: boolean = false
    public token: string;
    ngOnInit() {
        this.token = this._signupservice.gettoken();
        this._signupservice.tokenChanged.subscribe((response: string) => {
            this.token = this._signupservice.gettoken();
        });
        this.getuserdetails();
    }

    showsidebar() {
        this.showside = !this.showside
    }

    getuserdetails() {
        this._headerservice.getuserdetails(null).subscribe((data) => {
            if (data['status'] == "success") {
                this._signupservice.updatestatus(data['data']['adminverified']);
                this._signupservice.updateregstatus(data['data']['reg_status']);
                this._signupservice.updatesellertype(data['data']['seller_type']);
                this.seller_type = data['data']['seller_type'];
                this.name = data['data']['name']
                this.wallet_amount = data['data']['walletbalance']

                if(data['data']['reg_status'] == 'no' || data['data']['adminverified'] == 'no'){
                    this.router.navigate(['/seller/pending']);
                }
            }
        }, (error) => {

        });
    }

    logout() {
        this._signupservice.logout();
        window.location.href = "/home";
    }
}
