<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">User Management</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" [routerLink]="['/admin/manage-seller']">Seller </a>
						</li>
						<li class="breadcrumb-item active">View Details</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:;" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="row d-flex align-items-center mb-20">
			<div class="col-md-6">
				<h3 class="f-700 fs22 txtdefault pb-0"><a href="javascript:;" class="go-back"><i class="fa fa-angle-left"></i></a> View Details</h3>
			</div>
		</div>

		<div class="row mb-10">
			<div class="col-md-12 pb-2">
				<div class="custom-tab-menu">
					<ul>
						<li><a [routerLink]="['/admin/manage-seller/seller-view-details']" class="active">Personal Details</a></li>
						<li><a [routerLink]="['/admin/manage-seller/seller-business-details']">Business Details</a></li>
						<li><a [routerLink]="['/admin/manage-seller/seller-bank-details']"href="javascript:;">Bank Details</a></li>
					</ul>
				</div>
			</div>

			<div class="col-md-12 pt-2 pb-2">
				<div class="text-right">
					<a [routerLink]="['/admin/manage-seller/seller-business-details']" class="btn buybtn-success fs16 radius10 ripple mr-2">Continue</a>
					<a href="javascript:;" (click)="openmodal(rejectseller)" class="btn buybtn-danger fs16 radius10 ripple">Reject</a>
				</div>
			</div>
		</div>

		<div class="card cardui carduipro p-4">
			<div class="mb-0 pb-2">
				<div class="d-table">
					<div class="d-table-cell align-middle proavtwrp">
						<div class="proavt">						
							<img src="assets/images/team/proavt.jpg">
							<a (click)="openmdl(profilephoto)" href="javascript:void(0);" class="editpro">
								<img src="assets/images/icons/profile-edit.svg" />
							</a>
						</div>
					</div>
					<div class="d-table-cell align-middle pl-3">
						<h4 class="fs14 txtdefault wt700">Sunil Gupta</h4>
						<p class="fs12 textsecondary mb-1">Buyer</p>
						<p class="fs12 txtdefault mb-0 lcbx-mrk"><svg xmlns="http://www.w3.org/2000/svg" width="9.375" height="11.752" viewBox="0 0 9.375 11.752">
						  	<g id="np_location_4211118_000000" transform="translate(-21.517 -5.002)" opacity="0.6">
								<path id="Path_2306" data-name="Path 2306" d="M25.689,5.029a4.825,4.825,0,0,0-4.154,5.16c.17,1.95,2.631,5,2.631,5s1.277,1.153,1.693,1.455a.562.562,0,0,0,.677-.012c1.071-.836,4.357-3.67,4.357-6.942a4.691,4.691,0,0,0-5.2-4.663Zm.514,6.524s-1.861-.833-1.861-1.861a1.861,1.861,0,0,1,3.723,0C28.064,10.72,26.2,11.553,26.2,11.553Z" transform="translate(0 0)" fill="#5e6a7c"/>
							  </g>
							</svg>
							Mumbai, Maharashtra, India
						</p>
					</div>
				</div>
			</div>

			<div class="form-container mdl-frm-lbl mb-0">
				<div class="row profileipt">
					<div class="col-sm-6">
						<div class="mt-0 mb-30 p-eml-rt">
							<div class="floating-label mb-10"> 
								<input type="text" class="form-control" placeholder=" " value="sunil16@gmail.com">
								<button class="btn btn-outline-secondary fs16 btn-o-default" type="button">
									<span>Change</span>
									<svg xmlns="http://www.w3.org/2000/svg" width="16.615" height="16.617" viewBox="0 0 16.615 16.617">
									  <path id="np_edit_3094244_000000" d="M13.5-.008A3.1,3.1,0,0,0,11.3.9L.857,11.344l4.4,4.4L15.7,5.307A3.114,3.114,0,0,0,13.5-.008ZM.317,12.273,0,16.046a.519.519,0,0,0,.561.561l3.771-.32Z" transform="translate(0.003 0.008)" fill="#ff7135"/>
									</svg>
								</button>	  
							   <span class="highlight"></span>
							   <label>Email ID</label>
							</div>
						</div>

						<div class="mt-0 mb-0 p-eml-rt">
							<div class="floating-label mb-10"> 
								<input type="text" class="form-control" placeholder=" " value="+91 98765 04321">
								<button class="btn btn-outline-secondary fs16 btn-o-default" type="button">
									<span>Change</span>
									<svg xmlns="http://www.w3.org/2000/svg" width="16.615" height="16.617" viewBox="0 0 16.615 16.617">
									  <path id="np_edit_3094244_000000" d="M13.5-.008A3.1,3.1,0,0,0,11.3.9L.857,11.344l4.4,4.4L15.7,5.307A3.114,3.114,0,0,0,13.5-.008ZM.317,12.273,0,16.046a.519.519,0,0,0,.561.561l3.771-.32Z" transform="translate(0.003 0.008)" fill="#ff7135"/>
									</svg>
								</button>	  
							   <span class="highlight"></span>
							   <label>Mobile Number</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- EDIT PROFILE PHOTO -->
<ng-template #profilephoto let-modal>
   <div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
      </button>
      <h5 class="modal-title text-center">Edit Profile Photo</h5>
      <div class="form-container">
         <div class="file-inpt-cntnt radius10 text-center">
            <input type="file" class="fltyp" >
            <p class="fs16 txtseconday">Drag and Drop an Image Here</p>
         </div>
      </div>
      <div class="cta  pb-4 text-right">
         <a  class="btn btn-mod btn-color btn-medium btn-round ripple">Upload from Device</a>
         <a  class="resend d-inline-block ml-3">Remove Photo</a>
      </div>
   </div>
</ng-template>
<!-- EDIT PROFILE PHOTO -->

<!-- EDIT PROFILE PHOTO -->
<ng-template #rejectseller let-modal>
   	<div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
      	<h5 class="modal-title text-center pb-20">Reject with Reasons</h5>
      	<div class="bid-order-info">
			<div class="order-img">
				<img src="assets/images/icons/info.png" />
			</div>
			<div class="pd-seller-cntnt">
				<div class="fs12">The buyer will be notified to upload a fresh document immediately.</div>
			</div>
		</div>

		<div class="form-container">
			<div class="floating-label mb-20">      
			   <select class="form-control">
			   	<option selected="selected" value="">Improper Documents</option>
			   		<option>Address Verification pending</option>
			   		<option>Aadhar number mismatch</option>
			   		<option>Incorrect PAN number</option>
			   		<option>Aadhar not Linked with Account</option>
			   		<option>Missing Authorized Person</option>
			   		<option>Other</option>
				</select>
			   <span class="highlight"></span>
			   <label>Reason of Rejection</label>
			</div>
			<div class="floating-label mb-0">      
			   <textarea class="form-control floating-textarea">Please reupload the GST Filed document for the last month.</textarea>
			   <span class="highlight"></span>
			   <label>Message</label>
			</div>
		</div>

        <div class="text-center pt-30 pb-20">
           <button type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')" class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4">
		      Cancel
		   	</button>
		   	<button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4">
		      Notify the Buyer
		   	</button>
        </div>
   	</div>
</ng-template>
<!-- EDIT PROFILE PHOTO -->