<app-header-buyer></app-header-buyer>
<app-sidebar-buyer></app-sidebar-buyer>
<div class="wrpmain-buyer">
    <div class="container">
        <!--- HEAD  --->
        <div class="row align-items-center mb-4 pinfo-head">
            <div class="col-sm-6 col-7 pr-0">
                <h3 class="fs24 wt700 txtdefault">Manage Payment Methods</h3>
                <nav class="breadcrumb breadcrumb-dui mb-0">
                    <ol class="breadcrumb p-0 bg-transparent  mb-0">
                        <li class="breadcrumb-item">
                            <a class="txtseconday" href="javascript:void(0);"
                                [routerLink]="['/buyer/buyer-wallet']">Wallet and Payments</a>
                        </li>
                        <li class="breadcrumb-item active">Manage Payment Methods</li>
                    </ol>
                </nav>
            </div>
            <div class="col-sm-6 col-5 text-right pl-0">
                <a href="javascript:void(0);" [routerLink]="['/buyer/assistance']" class="btn buybtn fs16 radius10 ripple">Assistance</a>
            </div>
        </div>
        <!--- /HEAD  --->
        <div class="card cardui carduipro p-4 mb-40">
            <div class="form-container mdl-frm-lbl mb-3 mt-0">
                <h3 class="f-700 fs20 txtdefault pb-10 pb-xs-0">Wallet</h3>
                <div class="row d-flex align-items-center md-block">
                    <div class="col-12 col-md-6">
                        <div class="wallet-bal">
                            <img src="assets/images/icons/eb-icon.png">
                            <p>EB Wallet:</p>
                            <h4>{{ wallet_amount }}</h4>
                            <div><a href="javascript:void(0);"
                                    class="btn btn-mod btn-border btn-circle btn-xs">Default</a></div>
                        </div>
                    </div>

                    <div class="col-5 col-md-6">
                        <div class="text-right light-text md-text-left mt-xs-10">
                            <a [routerLink]="['/buyer/buyer-add-money']" class="link-alt">Add Money</a>
                        </div>
                    </div>
                </div>

                <hr class="gray">

                <h3 class="f-700 fs20 txtdefault pb-10">Cards</h3>

                <div class="row d-flex align-items-center mb-20"
                    *ngFor="let card of saved_cards_records; let i = index;">
                    <div class="col-md-6">
                        <div class="saved-cards">
                            <img src="assets/images/icons/visa-card.svg" class="mr-sm-4">
                            <h4>{{ card.number }}</h4>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="text-right light-text">
                            <a href="javascript:;" (click)="open(content, card)"><img
                                    src="assets/images/icons/delete-icon.svg"></a>
                            <ng-container *ngIf="!card.default">
                                &nbsp; | &nbsp;
                                <a href="javascript:;" class="link-alt" (click)="setdefault(card)">Set as default</a>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="row d-flex align-items-center mb-20" *ngIf="saved_cards_records.length == 0">
                    <div class="col-md-12 text-center">
                        <div class="mb-20"><img src="/assets/images/empty-state.png"></div>
                        <div class="fs14">Records not available.</div>
                    </div>
                </div>

                <!-- <div class="text-left mt-30">
					<a [routerLink]="['/buyer/buyer-add-payment-method']" class="mr-sm-4 btn-d-outline d-inline-block">+ Add Payment Method</a>
					<i placement="top" ngbTooltip="more info goes here"><img src="assets/images/icons/shield-alt.svg" /></i>
					&nbsp;To protect your security, Equibiz does not store your CVV. <a href="javascript:;" class="fs14 link-alt">Learn More</a>
				</div> -->
            </div>
        </div>
    </div>
</div>
<!-- VERIFY EMAIL -->
<ng-template #content let-modal>
    <div class="modal-body text-center mdlcmnui pt-4 mt-3">
        <h5 class="modal-title mb-10">Remove Payment Method</h5>
        <div class="modal-subtitle">Are you sure you want to remove the following from saved payments?</div>

        <div class="form-container">
            <div class="saved-cards justify-content-center">
                <img src="assets/images/icons/visa-card.svg" class="mr-sm-4">
                <h4>{{ selected_card.number }}</h4>
            </div>
        </div>

        <div class="text-center pt-20 pb-20">
            <button type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"
                class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4">
                Cancel
            </button>
            <button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4" (click)="removecard()">
                Remove
            </button>
        </div>
    </div>
</ng-template>

<app-footer-buyer></app-footer-buyer>