<app-header-buyer></app-header-buyer>
<app-sidebar-buyer></app-sidebar-buyer>
<div class="wrpmain pb-4">
  <div class="container">
    <!--- HEAD  --->
    <div class="row align-items-center pinfo-head mt-30">
      <div class="col-md-5 mb-4">
        <h3 class="fs24 wt700 txtdefault">BNPL Transaction Reports</h3>
        <nav class="breadcrumb breadcrumb-dui mb-0">
          <ol class="breadcrumb p-0 bg-transparent mb-0">
            <li class="breadcrumb-item">
              <a href="javascript:void(0);" class="txtseconday"
                >Transaction Management</a
              >
            </li>
            <li class="breadcrumb-item active">Transaction</li>
          </ol>
        </nav>
      </div>
      <div class="col-md-7 mb-4 text-md-right text-center">
        <a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple"
          >Assistance</a
        >
      </div>
    </div>
    <!--- /HEAD  --->
    <div class="card cardui carduipro p-4 mb-0">
      <div class="tab-content">
        <div class="row d-flex justify-content-between mt-20 report-cta">
          <div class="col-lg-5 mb-3">
            <div class="report-input">
              <div class="input-group-custom">
                <input
                  type="text"
                  placeholder="Search by Order ID, Amount, Due Date & Created Payment Date"
                  class="form-control"
                  class="form-control"
                  [(ngModel)]="search_term"
                  (change)="getinvoicerecordsfilters()"
                />
                <div class="input-group-append-custom">
                  <img src="assets/images/svg/search_logo.svg" />
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-7">
            <div
              class="d-flex clbxgrd justify-content-end lg-justify-content-start"
            >
              <div class="form-container mt-0 mb-0 mr-3">
                <div class="report-input floating-label">
                  <div class="input-group-custom">
                    <input
                      type="text"
                      [(ngModel)]="from_date"
                      (ngModelChange)="getinvoicerecordsfilters()"
                      class="form-control"
                      placeholder=" "
                      ngbDatepicker
                      #d="ngbDatepicker"
                      (click)="d.toggle()"
                      autocomplete="off"
                    />
                    <span class="highlight"></span>
                    <label>From Date</label>
                    <div class="input-group-append-custom">
                      <img src="assets/images/icons/filter-calendar.svg" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-container mt-0 mb-0 mr-3">
                <div class="form-container m-0">
                  <div class="report-input floating-label">
                    <div class="input-group-custom">
                      <input
                        type="text"
                        [(ngModel)]="to_date"
                        (ngModelChange)="getinvoicerecordsfilters()"
                        class="form-control"
                        placeholder=" "
                        ngbDatepicker
                        #dd="ngbDatepicker"
                        (click)="dd.toggle()"
                        autocomplete="off"
                      />
                      <span class="highlight"></span>
                      <label>To Date</label>
                      <div class="input-group-append-custom">
                        <img src="assets/images/icons/filter-calendar.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive brndmgnttable odmgnttabl">
          <table class="table custom-table xs">
            <thead>
              <tr>
                    <th>S. No.</th>
                    <th>Order Id</th>
                    <th>Amount</th>
                    <th>Due Date</th>
                    <th>Interest Free Upto</th>
                    <th>Created Payment Date</th>
                    <th></th>
              </tr>
            </thead>
            <tbody>
              <!--Empty State-->
              <tr *ngIf="!page_records.length">
                <td colspan="13">
                  <div class="row pt-40 pb-40">
                    <div class="col-md-12 text-center">
                      <div class="mb-20">
                        <img src="/assets/images/empty-state.png" />
                      </div>
                      <div class="fs16">There are no records to display!</div>
                    </div>
                  </div>
                </td>
              </tr>
                <tr *ngFor="let dues of page_records; let i = index;">
                    <td>{{i + 1}}</td>
                    <td>{{dues?.merchant_order_id}}</td>
                    <td>₹ {{dues?.due_amount}}</td>
                    <td>{{(dues?.due_date)}}</td>
                    <td>{{dues?.interest_free_upto}}</td>
                    <td>{{dues?.created_payment_date}}</td>
                    <td><a href="javascript:;" class="link-alt fs12" (click)="viewDetails(dues?.order_unique_id)">View Order</a></td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row mb-40" >
        <div class="col-md-6">
          <div class="text-right pr-3">
            <nav class="mt-3 tbpg">
              <ul class="pagination align-items-center">
                <li
                  class="page-item"
                  i
                  *ngFor="let page of page_pagination.pages"
                  [ngClass]="{ active: page_pagination.currentPage === page }"
                >
                  <a
                    class="page-link"
                    href="javascript:;"
                    (click)="setpage(page)"
                    >{{ page }}</a
                  >
                </li>

                <li
                  *ngIf="
                    page_pagination.totalPages - page_pagination.startPage > 5
                  "
                >
                  <a (click)="setpage(page_pagination.startPage + 5)">...</a>
                </li>
                <li
                  *ngIf="
                    page_pagination.totalPages - page_pagination.startPage > 5
                  "
                >
                  <a (click)="setpage(page_pagination.totalPages)">{{
                    page_pagination.totalPages
                  }}</a>
                </li>

                <li
                  class="page-item"
                  [ngClass]="{
                    disabled:
                      page_pagination.currentPage === page_pagination.totalPages
                  }"
                >
                  <a
                  *ngIf="page_pagination.totalItems > 0"
                    class="page-link snexticon"
                    href="javascript:;"
                    (click)="setpage(page_pagination.currentPage + 1)"
                  ></a>
                </li>
                <li
                  class="page-item"
                  [ngClass]="{
                    disabled:
                      page_pagination.currentPage === page_pagination.totalPages
                  }"
                >
                  <a
                  *ngIf="page_pagination.totalItems > 0"
                    class="page-link nexticon"
                    href="javascript:;"
                    (click)="setpage(page_pagination.totalPages)"
                  ></a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
