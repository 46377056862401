<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">User Management</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" [routerLink]="['/admin/manage-seller']">Seller</a>
						</li>
						<li class="breadcrumb-item active">View Details</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:;" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="row d-flex align-items-center mb-20">
			<div class="col-md-6">
				<h3 class="f-700 fs22 txtdefault pb-0"><a href="javascript:;" class="go-back"><i class="fa fa-angle-left"></i></a> View Details</h3>
			</div>
		</div>

		<div class="row mb-10">
			<div class="col-md-12 pb-2">
				<div class="custom-tab-menu">
					<ul>
						<li><a [routerLink]="['/admin/manage-seller/seller-view-details']" class="active">Personal Details</a></li>
						<li><a [routerLink]="['/admin/manage-seller/seller-business-details']" class="active">Business Details</a></li>
						<li><a [routerLink]="['/admin/manage-seller/seller-bank-details']"href="javascript:;" class="active">Bank Details</a></li>
					</ul>
				</div>
			</div>

			<div class="col-md-12 pt-2 pb-2">
				<div class="text-right">
					<a href="javascript:;" class="btn buybtn-success fs16 radius10 ripple mr-2">Continue</a>
					<a href="javascript:;" class="btn buybtn-danger fs16 radius10 ripple">Reject</a>
				</div>
			</div>
		</div>

		<div class="card cardui carduipro p-4 mb-2">
			<div class="form-container mdl-frm-lbl mb-3">
				<div class="row">
					<div class="col-sm-12 mb-4">
						<div class="floating-label mb-0">      
						  <input type="text" value="State Bank of India (SBI)" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>Bank Name</label>
						</div> 
					</div>
				</div>
				<div class="row">
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input value="3 Minds Digital Pvt. Ltd." type="text" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>Account Holder Name</label>
						</div> 
					</div>
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input value="4321 4321 4321" type="text" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>Account Number</label>
						</div> 
					</div>	
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input value="HDFC9876504" type="text" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>IFSC Code</label>
						</div> 
					</div>
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input value="Thane, Mumbai" value="" type="text" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>Bank Branch</label>
						</div> 
					</div>	
				</div>
			</div>
		</div>
	</div>
</div>