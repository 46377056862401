import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PendingOrdersService {

    constructor(private _http: HttpClient) { }

    getpendingorders(data) {
        return this._http.post(environment.api_url+'seller/orders/pending',data);
    }

    acceptorder(data) {
        return this._http.post(environment.api_url+'seller/orders/accept',data);
    }

    rejectorder(data) {
        return this._http.post(environment.api_url+'seller/orders/reject',data);
    }
    
}