import { Component, OnInit } from '@angular/core';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { SignupService } from 'src/app/shared/components/modal/signup-modal/signup-modal.service';
import { BuyerTransportationDocumentsService } from './buyer-transportation-documents.service';

@Component({
  selector: 'app-buyer-transportation-documents',
  templateUrl: './buyer-transportation-documents.component.html',
  styleUrls: ['./buyer-transportation-documents.component.scss']
})
export class BuyerTransportationDocumentsComponent implements OnInit {

    public search_term:string = '';
    public from_date:any = '';
    public to_date:any = '';

    constructor(
        private _pageservice: BuyerTransportationDocumentsService,
        private _toastr: ToasterService,
        private _pagination: PaginationService,
        private _signupservice: SignupService
    ) { }

    ngOnInit(): void {
        this.getinvoicerecords();
    }

    public page_records: any = [];
    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 10;
    public page_pagination: any = {};
    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.invoicerecords();
    }

    setpageperview() {
        this.current_page = 1;
        this.invoicerecords();
    }

    getinvoicerecords() {
        this.search_term = '';
        this.from_date = '';
        this.to_date = '';
        this.page_per_view = 10;
        this.offset = 0;
        this.current_page = 1;
        this.invoicerecords();
        
    }

    getinvoicerecordsfilters(){
        this.page_per_view = 10;
        this.offset = 0;
        this.current_page = 1;
        this.invoicerecords();
    }

    invoicerecords() {
        let data = { 
            limit: this.page_per_view, 
            offset: this.offset, 
            current_page: this.current_page, 
            search: this.search_term,
            from_date: this.from_date,
            to_date: this.to_date
        };
        this._pageservice.documentrecords(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.page_records = data['records'];
                let total_records = data['total_records'];
                this.page_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

}
