import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { EncrDecrService } from 'src/app/helpers/EncrDecrService';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { AddNewPartnerService } from './add-new-partner.service';

@Component({
  selector: 'app-add-new-partner',
  templateUrl: './add-new-partner.component.html',
  styleUrls: ['./add-new-partner.component.scss']
})
export class AddNewPartnerComponent implements OnInit {

	public buttonLoading:boolean = false;
	addNewPartnerForm: FormGroup;
	public pincode_document:any;
	public tracking_document:any;
	public pincode_document_status:boolean = false;
	public tracking_document_status:boolean = false;
  	constructor(
		private _addpartner: AddNewPartnerService,
		private fb: FormBuilder,
		private _toastr: ToasterService,
		private _EncrDecr: EncrDecrService
  	) { }

  	ngOnInit(): void {
		this.addNewPartnerForm = this.fb.group({
            name: this.fb.control(null, [Validators.required]),
            gst_no: this.fb.control(null, [Validators.required]),
        });
  	}

	get pf() { return this.addNewPartnerForm.controls; }

	addlogisticspartner() {

		if(!this.pincode_document_status){
			this._toastr.error('Please upload pincode file');
			return false;
		}

		if(!this.tracking_document_status){
			this._toastr.error('Please upload tracking series file');
			return false;
		}
		this.buttonLoading = true;
		let data: any = new FormData();
        data.append('name', this._EncrDecr.set(this.addNewPartnerForm.get('name').value));
		data.append('gst_no', this._EncrDecr.set(this.addNewPartnerForm.get('gst_no').value));
		data.append('pincode', this.pincode_document);
		data.append('tracking', this.tracking_document);
        this._addpartner.addpartner(data).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('New logistics partner added successfully.');
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
			this.addNewPartnerForm.reset();
			this.buttonLoading = false;
        }, (error) => {
            this.buttonLoading = false;
        });
    }

	uploadFile(event, type) {
        const file = event.target.files;
		if(type == 'pincode'){
			this.pincode_document = file[0];
			this.pincode_document_status = true;
		}else if(type == 'tracking'){
			this.tracking_document = file[0];
			this.tracking_document_status = true;
		}
        event.target.value = '';

    }

}
