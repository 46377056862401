import { Component, OnInit } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

    classstatus: boolean = false;
    constructor() { }
    minValue: number = 0;
    maxValue: number = 10000;
    options: Options = {
        floor: 0,
        ceil: 10000
    };
    ngOnInit(): void {
    }
    tglclass() {
        this.classstatus = !this.classstatus;
    }
    public val = null;
    config = {
        labelField: 'label',
        valueField: 'value',

    };

    data = [
        {
            label: 'Price Low to High',
            value: 'Price High to Low'
        },
        {
            label: 'Product Ratings',
            value: 'Product Ratings'
        },
        {
            label: 'Relevance',
            value: 'Relevance'
        },
        {
            label: 'Latest Arrivals',
            value: 'Latest Arrivals'
        }
    ]



    ngAfterViewChecked() {
    }

    public changed() {
    }
    ratingst: boolean = false;
    ratingsort() {
        this.ratingst = !this.ratingst;
    }
}
