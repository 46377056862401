<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
    <div class="container pb-4">
        <!--- HEAD  --->
        <div class="row align-items-center mb-4 pinfo-head">
            <div class="col-sm-12">
                <nav class="breadcrumb breadcrumb-dui mb-0">
                    <ol class="breadcrumb p-0 bg-transparent  mb-0">
                        <li class="breadcrumb-item">
                            <a class="txtseconday">CMS</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a class="txtseconday">Category</a>
                        </li>
                        <li class="breadcrumb-item active">Product</li>
                    </ol>
                </nav>
            </div>
        </div>
        <!--- /HEAD  --->
        <div class="row d-flex align-items-center mb-20">
            <div class="col-md-12">
                <h3 class="f-700 fs22 txtdefault pb-0">
                    <a class="go-back"><i class="fa fa-angle-left"></i></a> {{ category_name }} Products - CMS
                </h3>
            </div>
        </div>
        <!-- CARD -->

        <!-- Latest  CARD -->
        <form>
            <div class="card card-ui p-0 profileipt mb-20">
                <div class="card-body add-pro-wrap pt-0 p-4">
                    <div class="form-container mb-0">

                        <!-- Latest Arrival -->
                        <div class="row form">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <input type="text" placeholder="Latest Arrival Section Title" name="latest_arrival_name" [(ngModel)]="latest_arrival_name" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="form-group">
                                    <input type="file" placeholder="Latest Arrival Banner" name="latest_arrival_banner" (change)="uploadBanner($event, 'latest_arrival_banner')" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-2" *ngIf="latest_arrival_banner_path">
                                <div class="form-group">
                                    <a class="btn btn-outline-secondary fs16 btn-o-default" href="latest_arrival_banner_path" target="_blank">View Banner</a>
                                </div>
                            </div>
                            <!-- <div class="col-md-4">
                                <select name="latest_arrival_status" [(ngModel)]="latest_arrival_status" class="form-control">
                                    <option value="yes">Enable</option>
                                    <option value="no">Disable</option>
                                </select>
                            </div> -->
                        </div>
                        <div class="floating-label mb-4 flselcted">
                            <div class="position-relative">
                                <div class="selected-categories h-50">
                                    <ul *ngIf="latest_arrival.length > 0">
                                        <li *ngFor="let rec of latest_arrival; let i = index;">{{ rec.name }}<a
                                                href="javascript:;" (click)="removerecords(i, 'latest_arrival')">x</a></li>
                                    </ul>
                                </div>
                                <i placement="top" ngbTooltip="Select minimum 10 Products to display"
                                    class="infoinput info-alert"></i>
                            </div>
                            <button class="btn btn-outline-secondary fs16 btn-o-default" type="button"
                                (click)="openproductsearch(productsearch, 'latest_arrival')">
                                <svg class="d-inline-block" xmlns="http://www.w3.org/2000/svg" width="14.681"
                                    height="14.681" viewBox="0 0 14.681 14.681" class="plus">
                                    <g id="Icon_feather-plus" data-name="Icon feather-plus"
                                        transform="translate(1 1)">
                                        <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181"
                                            transform="translate(-11.659 -7.5)" fill="none" stroke="#ff7135"
                                            stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181"
                                            transform="translate(-7.5 -11.659)" fill="none" stroke="#ff7135"
                                            stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                    </g>
                                </svg>
                                <span>
                                    Add New
                                </span>
                            </button>
                            <span class="highlight"></span>
                            <label>Latest Arrival</label>
                        </div>
                        <hr>

                        <!-- Top Selling -->
                        <div class="row form">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <input type="text" placeholder="Top Selling Section Title" name="top_selling_name" [(ngModel)]="top_selling_name" class="form-control">
                                </div>
                            </div>

                            <div class="col-md-5">
                                <div class="form-group">
                                    <input type="file" placeholder="Top Selling Banner" name="top_selling_banner"  (change)="uploadBanner($event, 'top_selling_banner')" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-2" *ngIf="top_selling_banner_path">
                                <div class="form-group">
                                    <a class="btn btn-outline-secondary fs16 btn-o-default" href="top_selling_banner_path" target="_blank">View Banner</a>
                                </div>
                            </div>
                            <!-- <div class="col-md-4">
                                <select name="top_selling_status" [(ngModel)]="top_selling_status" class="form-control">
                                    <option value="yes">Enable</option>
                                    <option value="no">Disable</option>
                                </select>
                            </div> -->
                        </div>
                        <div class="floating-label mb-4 flselcted">
                            <div class="position-relative">
                                <div class="selected-categories h-50">
                                    <ul *ngIf="top_selling_products_records.length > 0">
                                        <li *ngFor="let rec of top_selling_products_records; let i = index;">{{ rec.name }}<a
                                                href="javascript:;" (click)="removerecords(i, 'top_selling')">x</a></li>
                                    </ul>
                                </div>
                                <i placement="top" ngbTooltip="Select minimum 10 Products to display"
                                    class="infoinput info-alert"></i>
                            </div>
                            <button class="btn btn-outline-secondary fs16 btn-o-default" type="button"
                                (click)="openproductsearch(productsearch, 'top_selling')">
                                <svg class="d-inline-block" xmlns="http://www.w3.org/2000/svg" width="14.681"
                                    height="14.681" viewBox="0 0 14.681 14.681" class="plus">
                                    <g id="Icon_feather-plus" data-name="Icon feather-plus"
                                        transform="translate(1 1)">
                                        <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181"
                                            transform="translate(-11.659 -7.5)" fill="none" stroke="#ff7135"
                                            stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181"
                                            transform="translate(-7.5 -11.659)" fill="none" stroke="#ff7135"
                                            stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                    </g>
                                </svg>
                                <span>
                                    Add New
                                </span>
                            </button>
                            <span class="highlight"></span>
                            <label>Top Selling Products</label>
                        </div>
                        <hr>

                        <!-- Fast Moving -->
                        <div class="row form">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <input type="text" placeholder="Fast Moving Section Title" name="fast_selling_name" [(ngModel)]="fast_selling_name" class="form-control">
                                </div>
                            </div>

                            <div class="col-md-5">
                                <div class="form-group">
                                    <input type="file" placeholder="Fast Moving Banner" name="fast_selling_banner"  (change)="uploadBanner($event, 'fast_selling_banner')" class="form-control">
                                </div>
                            </div>

                            <div class="col-md-2" *ngIf="fast_selling_banner_path">
                                <div class="form-group">
                                    <a class="btn btn-outline-secondary fs16 btn-o-default" href="fast_selling_banner_path" target="_blank">View Banner</a>
                                </div>
                            </div>
                            <!-- <div class="col-md-4">
                                <select name="fast_selling_status" [(ngModel)]="fast_selling_status" class="form-control">
                                    <option value="yes">Enable</option>
                                    <option value="no">Disable</option>
                                </select>
                            </div> -->
                        </div>
                        <div class="floating-label mb-4 flselcted">
                            <div class="position-relative">
                                <div class="selected-categories h-50">
                                    <ul *ngIf="fast_selling_records.length > 0">
                                        <li *ngFor="let rec of fast_selling_records; let i = index;">{{ rec.name }}<a
                                                href="javascript:;" (click)="removerecords(i, 'fast_selling')">x</a></li>
                                    </ul>
                                </div>
                                <i placement="top" ngbTooltip="Select minimum 10 Products to display"
                                    class="infoinput info-alert"></i>
                            </div>
                            <button class="btn btn-outline-secondary fs16 btn-o-default" type="button"
                                (click)="openproductsearch(productsearch, 'fast_selling')">
                                <svg class="d-inline-block" xmlns="http://www.w3.org/2000/svg" width="14.681"
                                    height="14.681" viewBox="0 0 14.681 14.681" class="plus">
                                    <g id="Icon_feather-plus" data-name="Icon feather-plus"
                                        transform="translate(1 1)">
                                        <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181"
                                            transform="translate(-11.659 -7.5)" fill="none" stroke="#ff7135"
                                            stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181"
                                            transform="translate(-7.5 -11.659)" fill="none" stroke="#ff7135"
                                            stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                    </g>
                                </svg>
                                <span>
                                    Add New
                                </span>
                            </button>
                            <span class="highlight"></span>
                            <label>Fast Selling Products</label>
                        </div>
                        <hr>

                        <!-- Dynamic Section -->
                        <div class="row form">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" placeholder="Dynamic Section Name & Status" name="dynamic_section_name" [(ngModel)]="dynamic_section_name" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <select name="dynamic_section_status" [(ngModel)]="dynamic_section_status" class="form-control">
                                    <option value="yes">Enable</option>
                                    <option value="no">Disable</option>
                                </select>
                            </div>
                            <div class="col-md-5">
                                <div class="form-group">
                                    <input type="file" placeholder="Dynamic Section Banner" name="dynamic_section_banner"  (change)="uploadBanner($event, 'section_banner')" class="form-control">
                                </div>
                            </div>

                            <div class="col-md-2" *ngIf="dynamic_section_banner_path">
                                <div class="form-group">
                                    <a class="btn btn-outline-secondary fs16 btn-o-default" href="dynamic_section_banner_path" target="_blank">View Banner</a>
                                </div>
                            </div>
                        </div>  
                        <div class="floating-label mb-4 flselcted">
                            <div class="position-relative">
                                <div class="selected-categories h-50">
                                    <ul *ngIf="dynamic_section.length > 0">
                                        <li *ngFor="let rec of dynamic_section; let i = index;">{{ rec.name }}<a
                                                href="javascript:;" (click)="removerecords(i, 'dynamic_section')">x</a></li>
                                    </ul>
                                </div>
                                <i placement="top" ngbTooltip="Select minimum 10 Products to display"
                                    class="infoinput info-alert"></i>
                            </div>
                            <button class="btn btn-outline-secondary fs16 btn-o-default" type="button"
                                (click)="openproductsearch(productsearch, 'dynamic_section')">
                                <svg class="d-inline-block" xmlns="http://www.w3.org/2000/svg" width="14.681"
                                    height="14.681" viewBox="0 0 14.681 14.681" class="plus">
                                    <g id="Icon_feather-plus" data-name="Icon feather-plus"
                                        transform="translate(1 1)">
                                        <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181"
                                            transform="translate(-11.659 -7.5)" fill="none" stroke="#ff7135"
                                            stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181"
                                            transform="translate(-7.5 -11.659)" fill="none" stroke="#ff7135"
                                            stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                    </g>
                                </svg>
                                <span>
                                    Add New
                                </span>
                            </button>
                            <span class="highlight"></span>
                            <label>Dynamic Section</label>
                        </div>
                        
                        


                        <div class="mt-20">
                            <button type="button" class="btn buybtn fs16 px-4 mr-4 radius10 ripple" (click)="cmsupdate()" [disabled]="buttonLoading">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="buttonLoading"></span>
                                &nbsp;Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <!-- /CARD -->

    </div>
</div>

<!-- Edit Category -->
<ng-template #productsearch let-modal>
    <div class="modal-body mdlcmnui pt-4 mt-3">
        <h5 class="modal-title text-center mb-20">Select Product</h5>

        <div class="text-right mt-20 mb-10">
            <!-- <a href="javascript:;" class="link-alt color-orange">Product Categories</a> -->
        </div>
        <div class="row mt-10">
            <div class="col-md-12">
                <div class="form-container mdl-frm-lbl mb-4 mt-0">
                    <div class="floating-label mb-0">
                        <div class="ng-autocomplete">
                            <ng-autocomplete 
                            [data]="result" 
                            [searchKeyword]="keyword" 
                            (selected)='selectEvent($event)'
                            (inputChanged)='onChangeSearch($event)' 
                            (inputFocused)='onFocused($event)'
                            [itemTemplate]="itemTemplate"
                            [notFoundTemplate]="notFoundTemplate"
                            placeholder="Search by Product Name">
                            </ng-autocomplete>
                            <ng-template #itemTemplate let-item>
                                <a href="javascript:void(0)">
                                    <div class="d-flex">
                                        <span [innerHTML]="item.name"></span>
                                        <!-- <span [innerHTML]="item.current" class="current"></span> -->
                                    </div>
                                </a>
                            </ng-template>

                            <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                            </ng-template>
                            <div class="search-icon"><img src="assets/images/svg/search_logo.svg"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>