import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { WriteReviewService } from './write-review.service';

@Component({
    selector: 'app-write-review',
    templateUrl: './write-review.component.html',
    styleUrls: ['./write-review.component.scss']
})
export class WriteReviewComponent implements OnInit {

    public review_orders:any = [];
    public myreview_orders:any = [];
    constructor(
        private _writereviews: WriteReviewService,
        private _toastr: ToasterService
    ) { }

    ngOnInit(): void {
        this.getrevieworders();
        this.getmyreviews();
    }

    getrevieworders() {
        this._writereviews.getrevieworders(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.review_orders = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    submitreview(record) {
        let data: any = new FormData();
        data.append("id", record.id);
        data.append('product_review_desc', record.product_review_desc);
        data.append('document', record.product_review_file);
        data.append('product_review_rating', record.product_review_rating);
        data.append('seller_review_desc', record.seller_review_desc);
        data.append('seller_review_satisfied', record.seller_review_satisfied);
        data.append('seller_review_rating', record.seller_review_rating);

        this._writereviews.submitreview(data).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Your review has been submitted successfully');
                this.getrevieworders();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    updateproductreview(record){
        record.product_rating = !record.product_rating;
    }

    uploadFile(event, record) {
        const file = event.target.files;
        record.product_review_file = file[0];
        event.target.value = '';
    }

    getmyreviews() {
        this._writereviews.getmyreviews(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.myreview_orders = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

}
