import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SignupService } from 'src/app/shared/components/modal/signup-modal/signup-modal.service';
import { PaginationService } from '../../helpers/pagination.service';
import { ProductCategoryService } from 'src/app/admin/product-category/product-category.service';
import { AddListingService } from 'src/app/seller/add-listing/addlisting.service';
import { AdminAllBidandOffersService } from '../admin-all-bids/admin-all-bid-and-offers.service';

@Component({
  selector: 'app-admin-product-bids',
  templateUrl: './admin-product-bids.component.html',
  styleUrls: ['./admin-product-bids.component.scss']
})
export class AdminProductBidsComponent implements OnInit {

    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 10;
    public all_bids_pagination: any = {};
    public all_bids_records: any = [];
    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];

    keyword = 'name';
    public closeResult = '';
    public user_status: string;
    public user_token: string;
    public user_reg_status;
    public last_bid_record: any = {};
    public product_detail: any = {};
    public bid_records: any = [];
    public bid_edit_type = 'quantity';
    public selected_bid: any = {};
    public bid_view_history = false;
    public bid_history_records: any = [];
    public last_traded_records: any = [];
    public updated_quantity: number;
    public updated_price: number;
    public selected_category: string = '';
    public product_id:string = ''
    constructor(
        private modalService: NgbModal,
        private _allbidsandoffers: AdminAllBidandOffersService,
        private _toastr: ToasterService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _pagination: PaginationService,
        private _signupservice: SignupService,
        private _editproductcategoryservice: ProductCategoryService,
        private _addlistingservice: AddListingService,
    ) { }

    ngOnInit(): void {
        this.product_id = this._route.snapshot.paramMap.get('productId');
        this.user_status = this._signupservice.getuserstatus();
        this.user_token = this._signupservice.gettoken();
        this.user_reg_status = this._signupservice.getuserregstatus();
        this.getallbids();
        this.product_detail.name = '';
        this.product_detail.image = '';
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.getallbids();
    }

    setpageperview() {
        this.current_page = 1;
        this.getallbids();
    }

    getallbids() {
        let limit = this.page_per_view;
        let offset = this.offset;
        let current_page = this.current_page;
        this._allbidsandoffers.getallbids({ limit: limit, offset: offset, current_page: current_page, category: this.selected_category, product_id: this.product_id }).subscribe((data) => {
            if (data['status'] == "success") {
                let total_records = data['total_records'];
                this.all_bids_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
                this.all_bids_records = data['records'];
                this.product_detail = data['basic'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    open(content, bid_id) {
        this._allbidsandoffers.gettradedprice({ bid_id: bid_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.last_traded_records = data['records'];
                this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    showhistory() {
        this.bid_view_history = !this.bid_view_history;
    }

    updatebid(type) {
        if (type == 'price' && !this.updated_price) {
            this._toastr.error('Please enter bid price.');
            return false;
        } else if (type == 'quantity' && !this.updated_quantity) {
            this._toastr.error('Please enter bid quantity.');
            return false;
        }
        var value = type == 'price' ? this.updated_price : this.updated_quantity;
        this._allbidsandoffers.updatebid({ type: type, value: value, bid_id: this.selected_bid.bid_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.updated_price = null;
                this.updated_quantity = null;
                this.modalService.dismissAll();
                this.last_bid_record = data['record'];
                document.getElementById('thankyoumodal').click();
                this.getallbids();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    result = [];
    presult = [];

    selectEvent(item) {
        this.selected_category = item.c_id;
        this.getallbids();
    }

    onChangeSearch(val: string) {
        this.selected_category = val;
        this._editproductcategoryservice.searchcategory({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.result = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    onFocused(e) {
        // do something when input is focused
    }

    pselectEvent(item) {
        this._router.navigate(['/admin/all-bids/' + item.product_id]);
    }

    ponChangeSearch(val: string) {
        this._allbidsandoffers.productsearch({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.presult = data['searchResult'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    ponFocused(e) {
        // do something when input is focused
    }

}
