<app-header-seller></app-header-seller>
<app-sidebar-seller></app-sidebar-seller>
<div class="wrpmain pb-4">
	<div class="container pb-4">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6">
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);" [routerLink]="['/admin/product-listing']">My Products  </a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" >Add Product </a>
						</li>
						
						<li class="breadcrumb-item active">Post your requirement</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:void(0);" [routerLink]="['/seller/assistance']" class="btn buybtn fs16 radius10">
					Assistance
				</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="row d-flex align-items-center ">
			<div class="col-sm-12 mb-4 pb-md-2">
				<h3 class="f-700 fs22 txtdefault pb-0"><a href="javascript:;" class="go-back"><i class="fa fa-angle-left"></i></a> Post your requirement</h3>
				<p class="fs12 txtseconday mb-0">
				<i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert position-static align-middle pl-4"></i>
				Tell us about your product and maximize the trade on Equibiz. This will help us create a catalogue for you to start listing your products.</p>
			</div>
			
		</div>
		<div class="card card-ui p-0">
			<div class="card-body add-pro-wrap p-4">
				<p class="fs12 txtseconday mb-4">
				<i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert position-static align-middle"></i>
				When multiple sellers sell the same product through a single detail page, we combine and present the best product data to ensure customers get the best experience.</p>
				<form  [formGroup]="requirementForm">
					<div class="form-container">	
						<div class="row">
							<div class="col-md-12">
								<div class="floating-label">
									<ng-autocomplete 
										[data]="result" 
										[searchKeyword]="keyword" 
										(selected)='selectEvent($event)'
										(inputChanged)='onChangeSearch($event)' 
										(inputFocused)='onFocused($event)' 
										[itemTemplate]="itemTemplate"
										formControlName="category"
										placeholder="Select a Category for the product to be listed" >
									</ng-autocomplete>
									<ng-template #itemTemplate let-item>
										<a href="javascript:void(0)">
											<div class="d-flex">
												<span [innerHTML]="item.name"></span>
												<!-- <span [innerHTML]="item.current" class="current"></span> -->
											</div>
										</a>
									</ng-template>

									<!-- <ng-template #notFoundTemplate let-notFound>
										[notFoundTemplate]="notFoundTemplate" 
										<div [innerHTML]="notFound"></div>
									</ng-template> -->
									<div class="search-icon"><img src="assets/images/svg/search_logo.svg"></div>
									<!-- <input placeholder="" class="form-control" value="" formControlName="category">
									<span class="highlight"></span>
									<label>Select a Category for the product to be listed</label> -->
									<span class="help-block" *ngIf="reqf.category.invalid && reqf.category.touched">Please select category</span>
								</div>
							</div>

							<div class="col-md-12">
								<div class="floating-label">      
									<input placeholder=" " class="form-control" value="" formControlName="product_name">
									<span class="highlight"></span>
									<label>Product Name</label>
									<i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert"></i>
									<span class="help-block" *ngIf="reqf.product_name.invalid && reqf.product_name.touched">Product name is required.</span>
								</div>
							</div>

							<div class="col-md-6">
								<div class="floating-label">      
									<input placeholder=" " class="form-control" value="" formControlName="model_number">
									<span class="highlight"></span>
									<label>Model Number</label>
									<i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert"></i>
									<span class="help-block" *ngIf="reqf.model_number.invalid && reqf.model_number.touched">Model is required.</span>
								</div>
							</div>

							<div class="col-md-6">
								<div class="floating-label">      
									<input placeholder=" " class="form-control" value="" formControlName="brand_name" [readonly]= "requirementForm.get('no_brand').value">
									<span class="highlight"></span>
									<label>Brand Name (Optional)</label>
									<i  placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert"></i>
									<div class="css-check mb-2 mt-2">
										<input name="saletype" class="css-checkbx" type="checkbox" formControlName="no_brand" (change)="isBrandChange()"/>
										<label  class="css-label txtdefault wt500 position-static">This product does not have a brand name
										</label>
									</div>
								</div>
								
							</div>
							<div class="col-md-12">
								<div class="floating-label">      
									<textarea placeholder=" " class="form-control txtareaht80" formControlName="message"></textarea>
									<span class="highlight"></span>
									<label>Message</label>
									<i placement="top" ngbTooltip="Tooltop content goes here"  class="infoinput info-alert"></i>
									<span class="help-block" *ngIf="reqf.message.invalid && reqf.message.touched">Message is required.</span>
								</div>
							</div>
						</div>
						<button type="button" class="btn buybtn fs16 radius10" (click)="saverequirement()" [disabled]="buttonLoading || requirementForm.invalid">
							<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="buttonLoading"></span>
							&nbsp;Submit
						</button>
					</div>
				</form>
				
			</div>
		</div>
	</div>
</div>		