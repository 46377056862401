<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain pb-4">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center pinfo-head">
			<div class="col-md-6 mb-4">
				<h3 class="fs24 wt700 txtdefault">Unclear Balance List</h3>				
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">	
						<li class="breadcrumb-item"><a href="javascript:void(0);" class="txtseconday">Unclear Balance Management </a></li>
						<li class="breadcrumb-item active">Unclear Balance List</li>
					</ol>
				</nav>
			</div>
			<div class="col-md-6  mb-4 text-md-right text-center">
				<a href="javascript:void(0);" class="mr-sm-4 btn-d-outline d-inline-block" (click)="getunclearbalance('yes')">
					<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="export_loading"></span>
					&nbsp; Export
				</a>
				<a href="{{ export_path }}"
					*ngIf="export_path"
					class="btn btn-default"
					target="_blank"
					download="Seller.csv"
					>Download Now
				</a>
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4 mb-0">
			<div class="notifications-container theme-tab">
				
				<div class="tab-content">
					<!--Filters-->
					<div class="row d-flex justify-content-between mt-0  report-cta">
						<div class="col-sm-5 mb-3">
							<div class="report-input">
								<div class="input-group-custom">
									<input type="text" placeholder="Search by Order ID" class="form-control" [(ngModel)]="search_term" (change)="getunclearbalance()">
									<div class="input-group-append-custom">
										<img src="assets/images/svg/search_logo.svg">
									</div>
								</div>
							</div>
						</div>
					</div>
					<!--End Filters-->
					<div class="table-responsive brndmgnttable">
						<table class="table custom-table xs">
							<thead>
								<tr>
									<th>Seller Business Name</th>
                                    <th>Contact Person Name</th>
                                    <th>Order No.</th>
									<th>Transaction ID</th>
                                    <th>Type</th>
                                    <th>Amount</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<!-- TR -->
								<tr *ngFor="let record of unclear_records; let i = index;">
									<td>
										<div class="d-flex align-items-center">
											<div class="mr-2"><img src="{{ record.logo }}"></div>
											<div>{{ record.businessname }}</div>
										</div>
									</td>
                                    <td>{{ record.name }}</td>
									<td>{{ record.order_no }}</td>
									<td>{{ record.transaction_id }}</td>
									<td>{{ record.order_type }}</td>
                                    <td>{{ record.amount }}</td>
									<td class="d-flex align-items-center">
										<button class="btn-d-outline ripple d-inline-block place-bid mr-3" (click)="open(content, record)">
											<div class="d-flex align-items-center">
												<div>Approve</div>
											</div>
										</button>
									</td>
								</tr>
								<!-- /TR -->

                                <!-- TR -->
								<tr *ngIf="!unclear_records.length">
									<td colspan="6">
                                        <div class="row pt-40 pb-40">
                                            <div class="col-md-12 text-center">
                                                <div class="mb-20"><img src="/assets/images/empty-state.png"></div>
                                                <div class="fs16">There are no records to display!</div>
                                            </div>
                                        </div>
                                    </td>
								</tr>
								<!-- /TR -->
							</tbody>
						</table>
					</div>	
				</div>		               
			</div>
		</div>
		<!-- <div class="row mb-40">
			<div class="col-md-6">
				<div class="text-left pl-3">
					<nav class="mt-3 tbpg rows-per-page">
						<div>Rows per page:</div>
						<select class="select-box">
							<option>10</option>
							<option>20</option>
						</select>
					</nav>
				</div> 
			</div>
			<div class="col-md-6">
				<div class="text-right pr-3">
					<nav class="mt-3 tbpg">
					  <ul class="pagination align-items-center">
						<li class="page-item"><a class="page-link previcon" href="#"></a></li>
						<li class="page-item"><a class="page-link sprevicon" href="#"></a></li>
						<li class="page-item"><a class="page-link" href="#">1</a></li>
						<li class="page-item"><a class="page-link" href="#">2</a></li>
						<li class="page-item"><a class="page-link" href="#">3</a></li>
						<li class="page-item"><a class="page-link" href="#">4</a></li>
						<li class="page-item"><a class="page-link snexticon" href="#"></a></li>
						<li class="page-item"><a class="page-link nexticon" href="#"></a></li>
					  </ul>
					</nav>
				</div> 
			</div>
		</div>  -->
	</div>	


    	<!-- VERIFY EMAIL -->
<ng-template #content let-modal>
	<div class="modal-body text-center mdlcmnui pt-4 mt-3">
        <h5 class="modal-title mb-20">Approve Unclear Balance</h5>
        <!-- <div class="bid-order-info">
			<div class="order-img">
				<img src="assets/images/icons/info.png" />
			</div>
			<div class="pd-seller-cntnt">
				<div class="fs12">By removing the logistic partner, the orders may get affected. </div>
			</div>
		</div> -->

        <div class="modal-subtitle text-center pt-30 pb-30">Are you sure you want to approve the {{ selected_record.businessname }} unclear balance?</div>

        <div class="fs16">Amount: <b>{{ selected_record.amount }}</b></div>

        <div class="text-center pt-30 pb-20">
           <button type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')" class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4">
		      Cancel
		   	</button>
		   	<button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4" (click)="approveunclearbalance()" [disabled]="buttonLoading">
				<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="buttonLoading"></span>&nbsp;Approve
		   	</button>
        </div>
      </div>
</ng-template>