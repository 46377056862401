<app-header-one [sticky]="true"></app-header-one>
<div class="container pb-5">
	<!-- BREADCRUMB -->
	<nav class="mb-sm-2 pt-2 pb-sm-2" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
	  <ol class="breadcrumb p-0 bg-transparent">
		<li class="breadcrumb-item"><a href="javascript:void(0);" class="txtseconday">Home </a></li>
		<li class="breadcrumb-item"><a href="javascript:void(0);" class="txtseconday">Seller</a></li>
		<li class="breadcrumb-item active" aria-current="page">{{ seller_detail.name }}</li>
	  </ol>
	</nav>
	<!-- /BREADCRUMB -->
	<!-- VENDOR INFO -->
	<div class="row mb-4 ">
		<div class="col-lg-6 pb-2 col-xl-7">
			<div class="row">
				<div class="col-sm-2 col-3 seller-thumb">
					<img [src]="seller_detail.logo?seller_detail.logo:'assets/images/svg/vendor.svg'" />
				</div>
				<div class="col-sm-10 col-9 pl-0 seller-info">
					<h4 class="f-600 fs22">{{ seller_detail.name }}</h4>
					<div class="fs12 txtseconday mb-1">Seller</div>
					<span class="pb-sm-2 pl-3 seller-loc fs12">
					<svg xmlns="http://www.w3.org/2000/svg" width="9.375" height="11.752" viewBox="0 0 9.375 11.752">
  					<path id="Path_2306" data-name="Path 2306" d="M25.689,5.029a4.825,4.825,0,0,0-4.154,5.16c.285,3.272,3.293,5.711,4.324,6.457a.562.562,0,0,0,.677-.012c1.071-.836,4.357-3.67,4.357-6.942a4.691,4.691,0,0,0-5.2-4.663Zm.514,6.524a1.861,1.861,0,1,1,1.861-1.861A1.861,1.861,0,0,1,26.2,11.553Z" transform="translate(-21.517 -5.002)" fill="#5e6a7c"/>
					</svg>{{ seller_detail.address }}</span>
					<p class="pt-2 fs14 txtseconday">{{ seller_detail.description? seller_detail.description: seller_detail.name+' is a very well known seller in the market. It is committed to providing each customer with the highest standard of customer service.' }}</p>
				</div>
			</div>
		</div>
		<!-- <div class="col-lg-6 pb-2 col-xl-5">
			<div class="card pl-4 pt-4 keyperform pb-2">
				<h4 class="fs16 f-700 mb-3 txtdefault">Key Performance</h4>
				<ul class="row mb-0">
					<li class="col-sm-6">
						<div class="keyratewrp">
							<div class="keyrate">
								<img src="assets/images/png/circle.png" />
								<span class="f-700 txtdefault">{{ seller_detail.key_performance?.order_fulfilment_rating }}</span>
								<div class="ratings star-ratings pr-1 d-inline-block">
									<i class="fa fa-star" aria-hidden="true"></i>
								</div>
							</div>
							<p>Order Fulfillment</p>
						</div>
					</li>
					<li class="col-sm-6">
						<div class="keyratewrp">
							<div class="keyrate">
								<img src="assets/images/png/circle.png" />
								<span class="f-700 txtdefault">{{ seller_detail.key_performance?.deliery_rating }}</span>
								<div class="ratings star-ratings pr-1 d-inline-block">
									<i class="fa fa-star" aria-hidden="true"></i>
								</div>
							</div>
							<p>Delivery</p>
						</div>
					</li>
					<li class="col-sm-6">
						<div class="keyratewrp">
							<div class="keyrate">
								<img src="assets/images/png/circle.png" />
								<span class="f-700 txtdefault">{{ seller_detail.key_performance?.quality_assurance }}</span>
								<div class="ratings star-ratings pr-1 d-inline-block">
									<i class="fa fa-star" aria-hidden="true"></i>
								</div>
							</div>
							<p>Quality Assurance</p>
						</div>
					</li>
					<li class="col-sm-6">
						<div class="keyratewrp">
							<div class="keyrate">
								<img src="assets/images/png/circle.png" />
								<span class="f-700 txtdefault">{{ seller_detail.key_performance?.user_statisfaction }}</span>
								<div class="ratings star-ratings pr-1 d-inline-block">
									<i class="fa fa-star" aria-hidden="true"></i>
								</div>
							</div>
							<p>User Satisfaction</p>
						</div>
					</li>
				</ul>
			</div>
		</div> -->
	</div>
	<!-- /VENDOR INFO -->

	<!-- PRODUCT LISTING -->
	<nav ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs listnav mb-3">
		<ng-container  [ngbNavItem]="1">
			<a ngbNavLink class="txtseconday px-0 mx-sm-3 mx-2">Ratings & Reviews</a>
			<ng-template ngbNavContent>
				<app-rating-reviews></app-rating-reviews>
			</ng-template>
		</ng-container>
		<ng-container  [ngbNavItem]="2">
			<a ngbNavLink class="txtseconday px-0 mx-sm-3 mx-2">Seller's Listings</a>
			<ng-template ngbNavContent>
			  <app-seller-product-listing></app-seller-product-listing>
			</ng-template>
		</ng-container>
		<ng-container  [ngbNavItem]="3">
			<a ngbNavLink class="txtseconday px-0 mx-sm-3 mx-2">More about the Seller</a>
			<ng-template ngbNavContent>
			  <app-about-seller></app-about-seller>
			</ng-template>
		</ng-container>
		<ng-container  [ngbNavItem]="4">
			<a ngbNavLink class="txtseconday px-0 mx-sm-3 mx-2">Help</a>
			<ng-template ngbNavContent>
				<app-help></app-help>
			</ng-template>
		</ng-container>
	</nav>

	<div [ngbNavOutlet]="nav" class="mt-2"></div>
	<!-- /PRODUCT LISTING -->
	
</div>

<app-footer-one></app-footer-one>