import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { ViewSubSellersService } from './view-sub-sellers.service';

@Component({
    selector: 'app-view-sub-sellers',
    templateUrl: './view-sub-sellers.component.html',
    styleUrls: ['./view-sub-sellers.component.scss']
})
export class ViewSubSellersComponent implements OnInit {

    closeResult = '';
    public seller_id: string;
    public basic_info: any = {};
    public product_records: any = [];
    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 20;
    public product_pagination: any = {};

    public page_per_view_options = [
        {
            value: 20,
            name: 20
        },
        {
            value: 40,
            name: 40
        }
    ];

    constructor(
        private modalService: NgbModal,
        private _viewsubsellersservice: ViewSubSellersService,
        private _pagination: PaginationService,
        private _toastr: ToasterService,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.seller_id = this.route.snapshot.paramMap.get('id');
        this.getsubsellers();
    }

    getsubsellers() {
        this._viewsubsellersservice.getsubsellers({ limit: this.page_per_view, offset: this.offset, current_page: this.current_page, seller_id: this.seller_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.basic_info = data['basic'];
                this.product_records = data['records'];
                let total_records = data['total_records'];
                this.product_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.getsubsellers();
    }

    setpageperview() {
        this.current_page = 1;
        this.getsubsellers();
    }
    open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true, }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    openWindowCustomClass(content) {
        this.modalService.open(content, { windowClass: 'modal-500' });
    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

}
