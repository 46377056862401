import { Component, OnInit, NgZone, HostListener  } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { EncrDecrService } from 'src/app/helpers/EncrDecrService';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { CardConfirmationService } from './card-confirmation.service';
import { CreditCardValidators } from 'angular-cc-library';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-card-confirmation',
  templateUrl: './card-confirmation.component.html',
  styleUrls: ['./card-confirmation.component.scss']
})
export class CardConfirmationComponent implements OnInit {

    closeResult = '';
    public order_token:string;
    public order_unique_id:string;
    public successMessage:string;
    public errorMessage:string;
    constructor(
        private modalService: NgbModal,
        private route: ActivatedRoute,
        private router: Router,
        private _cardconfirmationservice: CardConfirmationService,
        private _EncrDecr: EncrDecrService,
        private _toastr: ToasterService,
        private ngZone: NgZone,
        private fb: FormBuilder
    ) {
        const gaScript = document.createElement('script');
        gaScript.innerHTML = `gtag('event', 'conversion', {'send_to': 'AW-10853447367/_mWcCOuVqbQDEMftqbco'});
        `;
        document.head.appendChild(gaScript);
     }


    ngOnInit(): void {
        this.route.queryParams
            .subscribe(params => {
                if(params['order_token'] !== undefined && params['order_token'] != ''){
                    this.order_token = params['order_token'];
                }
                if(params['order_id'] !== undefined && params['order_id'] != ''){
                    this.order_unique_id = params['order_id'];
                }
                setTimeout( () => {
                    this.confirmorderstatus();
                }, 1000);
            }
        );
    }


    confirmorderstatus() {
        this._cardconfirmationservice.confirmorderstatus({order_token: this.order_token, order_unique_id: this.order_unique_id}).subscribe((data) => {
            if(data['status'] == "success"){
                this.successMessage = "Order placed successfully";
                setTimeout( () => {
                    this.router.navigate(['/buyer/buyer-track-orders']);
                }, 3000);
            }else if(data['status'] == "error"){
                this.errorMessage = data['message'];
            }
        });
    }

}
