import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ViewSellerReviewService {
    
    constructor(private _http: HttpClient) { }

    reviewdetails(data) {
        return this._http.post(environment.api_url+'admin/review/details',data);
    }

    reviewstatus(data) {
        return this._http.post(environment.api_url+'admin/review/updatestatus',data);
    }

    updatereview(data) {
        return this._http.post(environment.api_url+'admin/review/updaterating',data);
    }

}
