import { Component, OnInit, Input, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { SellerMoreArticlesService } from './seller-more-articles.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-seller-more-articles',
  templateUrl: './seller-more-articles.component.html',
  styleUrls: ['./seller-more-articles.component.scss']
})
export class SellerMoreArticlesComponent implements OnInit {
	@Input() class: string;
	@Input() sticky: boolean = false; // Default false
  	public stick: boolean = false;
	public assistance_record:any = {};
	public article_id:string;

  	constructor(
		private _morearticlesservice: SellerMoreArticlesService,
		private _route: ActivatedRoute,
	) { }

  	ngOnInit(): void {
		this.article_id = this._route.snapshot.paramMap.get('id');
		this.getassistance();
  	}

  	disabled = false;
  	// @HostListener Decorator
    @HostListener("window:scroll", [])
    onWindowScroll() {
        let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	    if (number >= 150 && window.innerWidth > 400) { 
  	        this.stick = true;
  	    }else {
  	         this.stick = false;
  	    }
    }

	getassistance() {
        this._morearticlesservice.getassistance({id: this.article_id}).subscribe((data) => {
            if(data['status'] == "success"){
                this.assistance_record = data['record'];
            }
        },(error) => {
        });
    }

}
