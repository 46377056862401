import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class BuyerInvoiceService {

    constructor(private _http: HttpClient) { }

    invoicerecords(data) {
        return this._http.post(environment.api_url + 'buyer/report/myinvoice', data);
    }

}