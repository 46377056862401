import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewOrderDetailsService } from './view-order-details.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { ActivatedRoute } from '@angular/router';
import { AdminOrderDetailsService } from 'src/app/admin/order-details/order.service';

@Component({
    selector: 'app-view-order-details',
    templateUrl: './view-order-details.component.html',
    styleUrls: ['./view-order-details.component.scss']
})
export class ViewOrderDetailsComponent implements OnInit {

    //return request
    public return_quantity:any = '';
    public return_reason:string = '';
    public return_message:string = '';
    public return_images:any = [];
    public return_updated_image:any = [];
    public return_status:any = '';
    public return_status_text:string = '';

    public order_details: any = {};
    public order_address:any = {};
    public shipping_label_document:any;
    public eway_bill_document:any;
    public ss_eway_bill_document:any;
    public manifest_document:any;
    public imeilist_doucment:any;
    public edit_type:string;
    public cityArray:any = [];
    public stateArray:any = [];
    public order_id: string;
    public invoiceupdateLoading:boolean = false;
    constructor(
        private modalService: NgbModal,
        private _vieworderdetails: ViewOrderDetailsService,
        private _toastr: ToasterService,
        private _route: ActivatedRoute,
        private _adminorderdetails: AdminOrderDetailsService
    ) { }

    ngOnInit(): void {
        this.order_details.sold_by = {};
        this.order_details.product_location = {};
        this.order_id = this._route.snapshot.paramMap.get('orderId');
        this.getorderdetails();
        setTimeout( () => {
            this.getstate();
        }, 1000)
    }
    config = {
        labelField: 'label',
        valueField: 'value',
    };
    data = [
        {
            label: 'India',
            value: 'india'
        },
        {
            label: 'in $',
            value: 'in $'
        }
    ]

    getorderdetails() {
        this._vieworderdetails.getorderdetails({ orderId: this.order_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.order_details = data['records'];
                if(this.order_details.return_request){
                    this.return_quantity = this.order_details.return_request_info.quantity;
                    this.return_reason = this.order_details.return_request_info.reason;
                    this.return_message = this.order_details.return_request_info.message;
                    this.return_status = this.order_details.return_request_info.status;
                    this.return_status_text = this.order_details.return_request_info.status_text;
                    this.return_updated_image = this.order_details.return_request_info.images;
                }
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    generateinvoice() {
        this._vieworderdetails.generateinvoice({ orderId: this.order_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Invoice has been generated successfully.');
                this.getorderdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    uploadinvoice() {
        this._vieworderdetails.uploadinvoice({ orderId: this.order_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this._toastr.success('Invoice has been uploaded successfully.');
                this.getorderdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    generatemanifest(){
        this._vieworderdetails.generatemanifest({orderId: this.order_id}).subscribe((data) => {
            if (data['status'] == "success") {
                window.open(data['manifest_path'], '_blank');
                this.getorderdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    generatedetails(type){
        let data: any = new FormData();
        data.append('orderId', this.order_id);
        data.append('type', type);
        if(type == 'shipping_label'){
            data.append("document", this.shipping_label_document);
        }else if(type == 'ss_eway_bill'){
            data.append("document", this.ss_eway_bill_document);
        }else if(type == 'eway_bill'){
            data.append("document", this.eway_bill_document);
        }else if(type == 'manifest'){
            data.append("document", this.manifest_document);
        }else if(type == 'imeilist'){
            data.append("imeilist", this.imeilist_doucment);
        }
        
        this._vieworderdetails.generatedetails(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                if(type == 'shipping_label'){
                    this._toastr.success('Shipping label has been uploaded successfully.');
                }else if(type == 'eway_bill'){
                    this._toastr.success('Eway bill has been uploaded successfully.');
                }else if(type == 'manifest'){
                    this._toastr.success('Manifest document has been uploaded successfully.');
                }else if(type == 'imeilist'){
                    this._toastr.success('IMEI list document has been uploaded successfully.');
                }
                    
                this.getorderdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    editorderdetailsmodal(newadrs, type) {
        this.edit_type = type;
        var rec = this.order_details;
        if(type == 'sold_by'){
            this.getcity(this.edit_type);
            this.order_address.address = rec.sold_by.supplier_adress;
            this.order_address.state = rec.sold_by.supplier_state;
            this.order_address.city = rec.sold_by.supplier_city;
            this.order_address.pincode = rec.sold_by.supplier_pincode;
        }else if(type == 'product_location'){
            this.getcity(this.edit_type);
            this.order_address.address = rec.product_location.address;
            this.order_address.city = rec.product_location.city;
            this.order_address.state = rec.product_location.state;
            this.order_address.pincode = rec.product_location.pincode;
        }else if(type == 'invoice'){
            this.order_address.invoice_number = rec.invoice_number;
        }
        this.modalService.open(newadrs, { windowClass: 'lgmodal mblbtm' });
    }

    getcity(type='sold_by') {
        var data = {};
        if(type == 'sold_by'){
            data = { state: this.order_details.sold_by.supplier_state };
        }else{
            data = { state: this.order_details.product_location.state };
        }
        this._adminorderdetails.getcity(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.cityArray = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getstate() {
        this._adminorderdetails.getstate(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.stateArray = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    updateorderdetails() {
        this.invoiceupdateLoading = true;
        var data = {};
        if(this.edit_type == 'invoice'){
            data = {
                order_id: this.order_id,
                type: this.edit_type,
                invoice_number: this.order_address.invoice_number
            }
        }else{
            data = {
                order_id: this.order_id,
                type: this.edit_type,
                address: this.order_address.address,
                state: this.order_address.state,
                city: this.order_address.city,
                pincode: this.order_address.pincode
            }
        }
        this._vieworderdetails.regenerateinvoice(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.getorderdetails();
                this._toastr.success('Order details has been updated successfully.');
                this.modalService.dismissAll();
                this.invoiceupdateLoading = false;
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
                this.invoiceupdateLoading = false;
            }
        });
    }

    uploadFile(event, type) {
        const file = event.target.files;
        if(type == 'shipping_label'){
            this.shipping_label_document = file[0];
        }else if(type == 'eway_bill'){
            this.eway_bill_document = file[0];
        }else if(type == 'ss_eway_bill'){
            this.ss_eway_bill_document = file[0];
        }else if(type == 'manifest'){
            this.manifest_document = file[0];
        }else if(type == 'imeilist'){
            this.imeilist_doucment = file[0];
        }
        event.target.value = '';
    }

    openWindowReturnRequest(content) {
        this.modalService.open(content, { windowClass: 'midmodal-lg', size: 'lg', centered: true, });
    }
    openWindowCancellationRequest(content2) {
        this.modalService.open(content2, { windowClass: 'midmodal', centered: true, });
    }
    openWindowUploadInvoice(content3) {
        this.modalService.open(content3, { windowClass: 'midmodal', centered: true, });
    }
    imagesSlider = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: ".thumbs",
    };
    thumbnailsSlider = {
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: true,
        focusOnSelect: true,
        asNavFor: ".feedback",
        //prevArrow:'.client-thumbnails .prev-arrow',
        // nextArrow:'.client-thumbnails .next-arrow',
    };
    slides = [
        { img: "assets/images/admin/mfront.png" },
        { img: "assets/images/admin/back.png" },
        { img: "assets/images/admin/top.png" },

        { img: "assets/images/admin/side.png" }
    ];

    submitreturn(type){
        let data = {
            meta_id: this.order_id,
            type:type
        }
        this._vieworderdetails.acceptrejectreturn(data).subscribe((data) => {
            if (data['status'] == "success") {
                if(type == 'approve'){
                    this._toastr.success('Return request has been accepted successfully.');
                }else if(type == 'reject'){
                    this._toastr.success('Return request has been rejected successfully.');
                }
                this.modalService.dismissAll();
                this.getorderdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        }, (error) => {
            this._toastr.error(data['message']);
        });
    }
}
