<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7">
				<h3 class="fs24 wt700 txtdefault">Personal Details</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Settings</a>
						</li>
						<li class="breadcrumb-item active">Personal Details</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right">
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4">
			<div class="mb-4 pb-2">
				<div class="d-table">
					<div class="d-table-cell align-middle proavtwrp">
						<div class="proavt">						
							<img src="{{ profile_image_url }}">
                     <input type="file" #file (change)="uploadImage($event)" style="display: none;">
							<a (click)="file.click()"  href="javascript:void(0);" class="editpro">
								<img src="assets/images/icons/profile-edit.svg" />
							</a>
						</div>
					</div>
					<div class="d-table-cell align-middle pl-3">
						<h4 class="fs14 txtdefault wt700">{{ personal_detail.name }}</h4>
						<p class="fs12 textsecondary mb-1">Admin</p>
						<p class="fs12 txtdefault mb-0 lcbx-mrk" *ngIf="personal_detail.address"><svg xmlns="http://www.w3.org/2000/svg" width="9.375" height="11.752" viewBox="0 0 9.375 11.752">
						  	<g id="np_location_4211118_000000" transform="translate(-21.517 -5.002)" opacity="0.6">
								<path id="Path_2306" data-name="Path 2306" d="M25.689,5.029a4.825,4.825,0,0,0-4.154,5.16c.17,1.95,2.631,5,2.631,5s1.277,1.153,1.693,1.455a.562.562,0,0,0,.677-.012c1.071-.836,4.357-3.67,4.357-6.942a4.691,4.691,0,0,0-5.2-4.663Zm.514,6.524s-1.861-.833-1.861-1.861a1.861,1.861,0,0,1,3.723,0C28.064,10.72,26.2,11.553,26.2,11.553Z" transform="translate(0 0)" fill="#5e6a7c"/>
							  </g>
							</svg>
							{{ personal_detail.address }}
						</p>
					</div>
				</div>
			</div>	
			<div class="profileipt">
				<!-- INPUT ROW -->
				<div class="form-container mt-0 mb-4 p-eml-rt">
					<div class="floating-label mb-10"> 
						<input type="text" class="form-control" placeholder=""  [disabled]="!changeEmail" [(ngModel)]="personal_detail.email" [ngModelOptions]="{standalone: true}">
						<button (click)="changeemail(entermobile)" class="btn btn-outline-secondary fs16 btn-o-default" type="button">
                     <span>Change</span>
                     <svg xmlns="http://www.w3.org/2000/svg" width="13.222" height="12.895" viewBox="0 0 13.222 12.895">
                     <path id="Path_2407" data-name="Path 2407" d="M95.131,17.273a1.407,1.407,0,0,1-1.068-.491l-3.376-3.938a1.407,1.407,0,0,1,2.136-1.831l2.207,2.575,6.975-8.411c1.08-1.512,2.21-.675,1.125.844L96.276,16.684a1.407,1.407,0,0,1-1.082.588Z" transform="translate(-90.349 -4.379)" fill="#ff7135"/>
                     </svg>
						</button>	  
					   <span class="highlight"></span>
					   <label>Email ID</label>
					</div>
				</div>
				<!-- /INPUT ROW -->
				<!-- INPUT ROW -->
				<div class="form-container mt-0 mb-4 p-eml-rt">
					<div class="floating-label mb-10"> 
						<input type="text" class="form-control" placeholder=" " value="*************" readonly>
						<button (click)="open(changepassword)" class="btn btn-outline-secondary fs16 btn-o-default" type="button">
							<span>Change</span>
							<svg xmlns="http://www.w3.org/2000/svg" width="16.615" height="16.617" viewBox="0 0 16.615 16.617">
							  <path id="np_edit_3094244_000000" d="M13.5-.008A3.1,3.1,0,0,0,11.3.9L.857,11.344l4.4,4.4L15.7,5.307A3.114,3.114,0,0,0,13.5-.008ZM.317,12.273,0,16.046a.519.519,0,0,0,.561.561l3.771-.32Z" transform="translate(0.003 0.008)" fill="#ff7135"/>
							</svg>
						</button>	  	  
					   <span class="highlight"></span>
					   <label>Password</label>
					</div>
				</div>							
				<!-- /INPUT ROW -->
				<!-- INPUT ROW -->
				<div class="form-container mt-0 mb-0 p-eml-rt">
					<div class="floating-label mb-10"> 
						<input type="text" class="form-control" placeholder=" " [disabled]="!changeMobile" [(ngModel)]="personal_detail.mobile" [ngModelOptions]="{standalone: true}">
						<button (click)="changemobile(entermobile)" class="btn btn-outline-secondary fs16 btn-o-default" type="button">
							<span>Change</span>
							<svg xmlns="http://www.w3.org/2000/svg" width="16.615" height="16.617" viewBox="0 0 16.615 16.617">
							  <path id="np_edit_3094244_000000" d="M13.5-.008A3.1,3.1,0,0,0,11.3.9L.857,11.344l4.4,4.4L15.7,5.307A3.114,3.114,0,0,0,13.5-.008ZM.317,12.273,0,16.046a.519.519,0,0,0,.561.561l3.771-.32Z" transform="translate(0.003 0.008)" fill="#ff7135"/>
							</svg>
						</button>	  
					   <span class="highlight"></span>
					   <label>Mobile Number</label>
					</div>
				</div>								
				<!-- /INPUT ROW -->
				
			</div>
		</div>
	</div>
</div>
<app-footer-seller></app-footer-seller>


 <!-- CHANGE PASSWORD -->
 <ng-template #changepassword let-modal>
   <div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
      </button>
      <h5 class="modal-title">Change Password</h5>
      <div class="form-container">
         <div class="floating-label mb-0">      
            <input class="form-control" type="password" placeholder=" " [(ngModel)]="current_password">
            <span class="highlight"></span>
            <div class="spassword"><a href="javascript:;"><div class="show-password"></div></a></div>
            <label>Current Password</label>
         </div>
         <div class="floating-label mt-20 mb-0">      
            <input class="form-control" type="password" placeholder=" " [(ngModel)]="new_password">
            <span class="highlight"></span>
            <div class="spassword"><a href="javascript:;"><div class="show-password"></div></a></div>
            <label>New Password</label>
         </div>
         <div class="floating-label mt-20 mb-0">      
            <input class="form-control" type="password" placeholder=" " [(ngModel)]="confirm_new_password">
            <span class="highlight"></span>
            <div class="spassword"><a href="javascript:;"><div class="hide-password"></div></a></div>
            <label>Re-enter New Password</label>
         </div>
         <ul class="cpswd-hint">
            <li class="fs12 txtseconday">At least 8 characters—the more characters, the better.</li>
            <li class="fs12 txtseconday">A mixture of both uppercase and lowercase letters.</li>
            <li class="fs12 txtseconday">A mixture of letters and numbers.</li>
            <li class="fs12 txtseconday">Inclusion of at least one special character, e.g., ! @ # ? ]</li>
         </ul>
      </div>
      <div class="cta pb-4 text-center">
         <a  class="btn btn-mod btn-color mx-auto btn-medium btn-round ripple btn-block chmx" (click)="submitPassword()">Change Password</a>
      </div>
   </div>
</ng-template>
<!-- CHANGE PASSWORD -->


<!-- CHANGE MOBILE NUMBER -->
<ng-template #entermobile let-modal>
   <div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
      </button>
      <h5 class="modal-title">Change {{verify_type == 'email'? 'Email Address' : "Mobile Number"}}</h5>
      <div class="modal-subtitle pb-5">Enter Your New {{verify_type == 'email'? 'Email Address' : "Mobile Number"}}</div>
      <div class="form-container mb-10">
         <div class="floating-label mb-0">      
            <input class="form-control" type="text" value="" placeholder=" " [(ngModel)]="changeValue">
            <span class="highlight"></span>
            <label>{{verify_type == 'email'? 'Email Address' : "Mobile Number"}}</label>
         </div>
      </div>
      <!-- <div class="help-block text-left">Please enter the correct password</div> -->
      <div class="text-right pb-60">
      </div>
      <div class="cta mt-40 pb-4 mb-2">
         <a href="javascript:void(0);" (click)="changeotp(changecontent)" class="btn btn-mod btn-color btn-medium btn-round ripple btn-block">Send OTP</a>
      </div>
   </div>
</ng-template>
<!-- CHANGE MOBILE NUMBER -->


 <!-- VERIFY EMAIL -->
 <ng-template #changecontent let-modal>
   <div class="modal-body text-center mdlcmnui pt-4 mt-3">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
      </button>
      <h5 class="modal-title mb-10 ">Verify {{ verify_type == 'email'? 'Email Address': 'Mobile Number' }}</h5>
      <div class="modal-subtitle">Enter the verification code sent <br>to {{ changeValue }}</div>
      <div class="form-container">
         <form action="" class="mt-40 mb-10 vrf-frm-ipt">

            <input class="otp" type="text" (keyup)="onDigitInput($event)" maxlength=1 [(ngModel)] = "otp1" [ngModelOptions]="{standalone: true}" autofocus> 
            <input class="otp" type="text" (keyup)="onDigitInput($event)" maxlength=1 [(ngModel)] = "otp2" [ngModelOptions]="{standalone: true}">
            <input class="otp" type="text" (keyup)="onDigitInput($event)" maxlength=1 [(ngModel)] = "otp3" [ngModelOptions]="{standalone: true}">
            <input class="otp" type="text" (keyup)="onDigitInput($event)" maxlength=1 [(ngModel)] = "otp4" [ngModelOptions]="{standalone: true}">

         </form>
         <!-- <span class="help-block">Incorrect OTP, enter the correct OTP</span>     -->
      </div>
      <div class="pt-20 pb-20">
         <div class="modal-subtitle">Didn’t received code?<br>
            <a href="javascript:;" class="resend" (click)="changeresendotp(changecontent)">Resend Code</a>
         </div>
      </div>
      <div class="cta mt-40 pb-4 mb-1">
         <button class="btn btn-mod btn-color btn-medium btn-round ripple btn-block" (click)="changevalue()">Verify {{ verify_type == 'email' ? 'Email ID':'Mobile Number' }}</button>
      </div>
   </div>
</ng-template>
<div class="toasteml wt700 ">
   <i></i>
   Email address has been verified successfully.
   <span></span>
</div>
<!-- VERIFY EMAIL -->

