<app-header-seller></app-header-seller>
<app-sidebar-seller></app-sidebar-seller>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<h3 class="fs24 wt700 txtdefault">Return Orders</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Order History</a>
						</li>
						<li class="breadcrumb-item active">Return Orders</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:void(0);" [routerLink]="['/seller/assistance']" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="us-tab">
			<nav ngbNav #nav="ngbNav" class="nav-tabs">
			  <ng-container ngbNavItem>
				<a ngbNavLink>My Return Orders</a>
				<ng-template ngbNavContent>
				  	<div class="card cardui carduipro p-4 mb-0 table-card">
						<div class="tab-content">
							<!--Filters-->
							<div class="row d-flex justify-content-between mt-20 report-cta">
							  <div class="col-md-5">
								  <div class="report-input">
									  <div class="input-group-custom">
										<input type="text" placeholder="Search by Product name, Order ID & Transaction ID" 
										class="form-control" [(ngModel)]="search_term" (change)="getsellerordersfilters()">
										  <div class="input-group-append-custom">
											  <img src="assets/images/svg/search_logo.svg">
										  </div>
									  </div>
								  </div>
							  </div>
						  </div>
						  <!--End Filters-->
						  <table class="table custom-table xs">
							  <thead>
								  	<tr>
										<th>Order No.</th>
										<th>Transaction No.</th>
										<th width="18%">Product Name</th>
										<th>Buyer Name</th>
										<th width="10%">Quantity</th>
										<th width="10%">Reason</th>
										<th>Status</th>
										<th>View</th>
								  	</tr>
							  </thead>
							  <tbody>

								  <tr *ngIf="!return_orders.length">
									<td colspan="8">
										<div class="row pt-40 pb-40">
											<div class="col-md-12 text-center">
												<div class="mb-20"><img src="/assets/images/empty-state.png">
												</div>
												<div class="fs16">There are no records to display!</div>
											</div>
										</div>
									</td>
								</tr>
								<!--End Empty State-->
								<tr *ngFor="let record of return_orders;">
									<td>{{ record.unique_id }}</td>
									<td>{{ record.order_no }}</td>
									<td>
										<div class="order-info d-flex align-items-start">
											<div class="order-img">
												<img [src]="record.product_image" />
											</div>
											<div class="pd-seller-cntnt">
												<div class="txtdefault">
													<a href="javascript:;" class="order-link">{{ record.product_name }}</a>
												</div>
												<div class="pt-2">
													<span class="py-1 px-3 odbdge btn-d-outline ripple d-inline-block fs12">{{ record.map_type }}</span>
												</div>
											</div>
										</div>
	
									</td>												
									<td>{{ record.buyer_name }}</td>
									<td>{{ record.qty }}</td>
									<td>{{ record.reason }}</td>
									<td>{{ record.status }}</td>
									<td>
										<div class="d-flex align-items-center">
											<a href="javascript:void(0);" [routerLink]="['/seller/view-order-details/'+record.meta_id]" class="btn-d-outline align-middle ripple d-inline-block place-bid mr-3">Order Details</a>
										</div>
									</td>
								</tr>
							  </tbody>
						  </table>
						</div>
						
					</div>
					
					<div class="row mb-40" *ngIf="return_order_pagination.totalPages> 1">
						<div class="col-md-6">
							<div class="text-left pl-3">
								<nav class="mt-3 tbpg rows-per-page">
									<div>Rows per page:</div>
									<select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
										<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">
											{{option.name}}</option>
									</select>
								</nav>
							</div>
						</div>
						<div class="col-md-6">
							<div class="text-right md-text-left pr-3">
								<nav class="mt-3 tbpg">
									<ul class="pagination align-items-center">
										<li class="page-item" [ngClass]="{disabled:return_order_pagination.currentPage === 1}"><a
												class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a>
										</li>
	
										<li class="page-item" [ngClass]="{disabled:return_order_pagination.currentPage === 1}"><a
												class="page-link sprevicon" href="javascript:;"
												(click)="setpage(return_order_pagination.currentPage - 1)"></a></li>
	
										<li class="page-item" i *ngFor="let page of return_order_pagination.pages"
											[ngClass]="{active:return_order_pagination.currentPage === page}"><a class="page-link"
												href="javascript:;" (click)="setpage(page)">{{page}}</a></li>
	
										<li *ngIf="(return_order_pagination.totalPages - return_order_pagination.startPage) > 5 ">
											<a (click)="setpage(return_order_pagination.startPage + 5)">...</a>
										</li>
										<li *ngIf="(return_order_pagination.totalPages - return_order_pagination.startPage) > 5">
											<a
												(click)="setpage(return_order_pagination.totalPages)">{{return_order_pagination.totalPages}}</a>
										</li>
	
										<li class="page-item"
											[ngClass]="{disabled:return_order_pagination.currentPage === return_order_pagination.totalPages}">
											<a class="page-link snexticon" href="javascript:;"
												(click)="setpage(return_order_pagination.currentPage + 1)"></a>
										</li>
										<li class="page-item"
											[ngClass]="{disabled:return_order_pagination.currentPage === return_order_pagination.totalPages}">
											<a class="page-link nexticon" href="javascript:;"
												(click)="setpage(return_order_pagination.totalPages)"></a>
										</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</ng-template>
			  </ng-container>
			  <ng-container ngbNavItem *ngIf="seller_type == 'super'">
				<a ngbNavLink>Sub-Seller Return Orders</a>
				<ng-template ngbNavContent>					
					<div class="card card-ui card-uiovt byregwrp">
						<!--Filters-->
						<div class="row d-flex justify-content-between mt-20 report-cta">
							<div class="col-md-5">
								<div class="report-input">
									<div class="input-group-custom">
										<input type="text" placeholder="Search by Product name, Order ID & Transaction ID" 
										class="form-control" [(ngModel)]="ss_search_term" (change)="getsubsellerordersfilters()">
										<div class="input-group-append-custom">
											<img src="assets/images/svg/search_logo.svg">
										</div>
									</div>
								</div>
							</div>
						</div>
						<!--End Filters-->
						<div class="table-responsive">
							<table class="table custom-table xs">
								<thead>
									<tr>
										<th>Order No.</th>
										<th>Transaction No.</th>
										<th width="18%">Product Name</th>
										<th>Buyer Name</th>
										<th width="10%">Seller Name</th>
										<th width="10%">Quantity</th>
										<th width="10%">Reason</th>
										<th>Status</th>
										<th>View</th>
									</tr>
								</thead>
								<tbody>
									<!--Empty State-->
									<tr *ngIf="!supplier_return_orders.length">
										<td colspan="9">
											<div class="row pt-40 pb-40">
												<div class="col-md-12 text-center">
													<div class="mb-20"><img src="/assets/images/empty-state.png">
													</div>
													<div class="fs16">There are no records to display!</div>
												</div>
											</div>
										</td>
									</tr>
									<!--End Empty State-->
									<tr *ngFor="let record of supplier_return_orders;">
										<td>{{ record.unique_id }}</td>
										<td>{{ record.order_no }}</td>
										<td>
											<div class="order-info d-flex align-items-start">
												<div class="order-img">
													<img [src]="record.product_image" />
												</div>
												<div class="pd-seller-cntnt">
													<div class="txtdefault">
														<a href="javascript:;" class="order-link">{{ record.product_name }}</a>
													</div>
													<div class="pt-2">
														<span class="py-1 px-3 odbdge btn-d-outline ripple d-inline-block fs12">{{ record.map_type }}</span>
													</div>
												</div>
											</div>
		
										</td>												
										<td>{{ record.buyer_name }}</td>
										<td>{{ record.seller_name }}</td>
										<td>{{ record.qty }}</td>
										<td>{{ record.reason }}</td>
										<td>{{ record.status }}</td>
										<td>
											<div class="d-flex align-items-center">
												<a href="javascript:void(0);" [routerLink]="['/seller/view-order-details/'+record.meta_id]" class="btn-d-outline align-middle ripple d-inline-block place-bid mr-3">Order Details</a>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="row mb-40" *ngIf="supplier_order_pagination.totalPages> 1">
						<div class="col-md-6">
							<div class="text-left pl-3">
								<nav class="mt-3 tbpg rows-per-page">
									<div>Rows per page:</div>
									<select class="select-box" [(ngModel)]="sub_page_per_view" (change)="subsetpageperview()">
										<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">
											{{option.name}}</option>
									</select>
								</nav>
							</div>
						</div>
						<div class="col-md-6">
							<div class="text-right md-text-left pr-3">
								<nav class="mt-3 tbpg">
									<ul class="pagination align-items-center">
										<li class="page-item" [ngClass]="{disabled:supplier_order_pagination.currentPage === 1}"><a
												class="page-link previcon" href="javascript:;" (click)="subsetpage(1)"></a>
										</li>
	
										<li class="page-item" [ngClass]="{disabled:supplier_order_pagination.currentPage === 1}"><a
												class="page-link sprevicon" href="javascript:;"
												(click)="subsetpage(supplier_order_pagination.currentPage - 1)"></a></li>
	
										<li class="page-item" i *ngFor="let page of supplier_order_pagination.pages"
											[ngClass]="{active:supplier_order_pagination.currentPage === page}"><a class="page-link"
												href="javascript:;" (click)="subsetpage(page)">{{page}}</a></li>
	
										<li *ngIf="(supplier_order_pagination.totalPages - supplier_order_pagination.startPage) > 5 ">
											<a (click)="subsetpage(supplier_order_pagination.startPage + 5)">...</a>
										</li>
										<li *ngIf="(supplier_order_pagination.totalPages - supplier_order_pagination.startPage) > 5">
											<a
												(click)="subsetpage(supplier_order_pagination.totalPages)">{{supplier_order_pagination.totalPages}}</a>
										</li>
	
										<li class="page-item"
											[ngClass]="{disabled:supplier_order_pagination.currentPage === supplier_order_pagination.totalPages}">
											<a class="page-link snexticon" href="javascript:;"
												(click)="subsetpage(supplier_order_pagination.currentPage + 1)"></a>
										</li>
										<li class="page-item"
											[ngClass]="{disabled:supplier_order_pagination.currentPage === supplier_order_pagination.totalPages}">
											<a class="page-link nexticon" href="javascript:;"
												(click)="subsetpage(supplier_order_pagination.totalPages)"></a>
										</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</ng-template>
			  </ng-container>
			  
			</nav>

			<div [ngbNavOutlet]="nav" class="mt-2"></div>
		</div>
	</div>
</div>

<app-footer-seller></app-footer-seller>