<app-header-seller></app-header-seller>
<app-sidebar-seller></app-sidebar-seller>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<h3 class="fs24 wt700 txtdefault">Transportation Document</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Invoices</a>
						</li>
						<li class="breadcrumb-item active">Transportation Document</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:void(0);" [routerLink]="['/seller/assistance']" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->


		<div class="us-tab" *ngIf="seller_type == 'super'">
			<nav ngbNav #nav="ngbNav" class="nav-tabs">
			  <ng-container ngbNavItem>
				<a ngbNavLink (click)="changeinvoicetype('mydocuments')">My Documents</a>
			  </ng-container>
			  <ng-container ngbNavItem>
				<a ngbNavLink (click)="changeinvoicetype('ssdocuments')">Supplier Documents</a>
			  </ng-container>
			</nav>

			<div [ngbNavOutlet]="nav" class="mt-2"></div>
		</div>

		<div class="card cardui carduipro p-4 mb-0">
			<!--Filters-->
			<div class="row d-flex justify-content-between mt-20 report-cta">
				<div class="col-md-5">
					<div class="report-input">
						<div class="input-group-custom">
						  <input type="text" placeholder="Search by Invoice no. or Order no." 
						  class="form-control" [(ngModel)]="search_term" (change)="getdocumentsrecordsfilters()">
							<div class="input-group-append-custom">
								<img src="assets/images/svg/search_logo.svg">
							</div>
						</div>
					</div>
				</div>

				<div class="col-md-7 mt-xs-10">
					<div class="d-flex justify-content-end">
							<div class="form-container mt-0 mb-0 mr-3">
								<div class="report-input floating-label">
									<div class="input-group-custom">
										<input type="text" [(ngModel)]="from_date" (ngModelChange) = "getdocumentsrecordsfilters()" class="form-control" placeholder=" " ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" autocomplete="off"/>
										<span class="highlight"></span>
										<label>From Date</label>
										<div class="input-group-append-custom">
											<img src="assets/images/icons/filter-calendar.svg">
										</div>
									</div>
								</div>
							</div>

							<div class="form-container mt-0 mb-0 mr-3">
							  	<div class="form-container m-0">
								  	<div class="report-input floating-label">
									  	<div class="input-group-custom">
										  	<input type="text" [(ngModel)]="to_date" (ngModelChange) = "getdocumentsrecordsfilters()" class="form-control" placeholder=" " ngbDatepicker #dd="ngbDatepicker" (click)="dd.toggle()" autocomplete="off"/>
										  	<span class="highlight"></span>
										  	<label>To Date</label>
										  	<div class="input-group-append-custom">
											 	<img src="assets/images/icons/filter-calendar.svg">
										  	</div>
									  	</div>
								  	</div>
							  	</div>
						  </div>
					</div>
				</div>
			</div>
			<!--End Filters-->
        	<table class="table custom-table">
				<thead>
					<tr>
						<th>{{ invoice_type == 'mydocuments'?'Buyer Name':'Sub seller Name' }}</th>
						<th>{{ invoice_type == 'mydocuments'?'Buyer GSTIN':'Sub seller GSTIN' }}</th>
						<th>Order Date</th>
						<th>Order Number</th>
						<th>Shipping<br>PIN code</th>
						<th>Qty</th>
						<th>Total Invoice<br>Value (in ₹)</th>
						<th>C&F</th>
						<th>E-way Bill</th>
						<th>Shipping Label</th>
					</tr>
				</thead>
				<tbody>
					<!--Empty State-->
					<tr *ngIf="!page_records.length">
						<td colspan="9">
							<div class="row pt-40 pb-40">
								<div class="col-md-12 text-center">
									<div class="mb-20"><img src="/assets/images/empty-state.png"></div>
									<div class="fs16">There are no records to display!</div>
								</div>
							</div>
						</td>
					</tr>
					<!--End Empty State-->
					<tr *ngFor="let rec of page_records">
						<td>{{ invoice_type == 'mydocuments'?rec.buyer_name:rec.subseller_name }}</td>
						<td>{{ invoice_type == 'mydocuments'?rec.buyer_gst:rec.subseller_gst }}</td>
						<td>{{ rec.order_date }}</td>
						<td>{{ rec.order_id }}</td>
						<td>{{ invoice_type == 'mydocuments'?rec.buyer_shipping_pincode:rec.subseller_pincode }}</td>
						<td>{{ rec.qty }}</td>
						<td>{{ rec.total_amount }}</td>
						<td>{{ rec.candf?'Yes':'No' }}</td>
						<td *ngIf="rec.eway_bill_path"><a href="{{ rec.eway_bill_path }}" target="_blank" class="btn-d-outline ripple d-inline-block place-bid">Download</a></td>
						<td *ngIf="!rec.eway_bill_path">--</td>
						<td *ngIf="rec.shipping_label_path"><a href="{{ rec.shipping_label_path }}" target="_blank" class="btn-d-outline ripple d-inline-block place-bid">Download</a></td>
						<td *ngIf="!rec.shipping_label_path">--</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="row mb-40" *ngIf="page_pagination.totalPages> 1">
			<div class="col-md-6">
				<div class="text-left pl-3">
					<nav class="mt-3 tbpg rows-per-page">
						<div>Rows per page:</div>
						<select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
							<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">
								{{option.name}}</option>
						</select>
					</nav>
				</div>
			</div>
			<div class="col-md-6">
				<div class="text-right md-text-left pr-3">
					<nav class="mt-3 tbpg">
						<ul class="pagination align-items-center">
							<li class="page-item" [ngClass]="{disabled:page_pagination.currentPage === 1}"><a
									class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a>
							</li>

							<li class="page-item" [ngClass]="{disabled:page_pagination.currentPage === 1}"><a
									class="page-link sprevicon" href="javascript:;"
									(click)="setpage(page_pagination.currentPage - 1)"></a></li>

							<li class="page-item" i *ngFor="let page of page_pagination.pages"
								[ngClass]="{active:page_pagination.currentPage === page}"><a class="page-link"
									href="javascript:;" (click)="setpage(page)">{{page}}</a></li>

							<li *ngIf="(page_pagination.totalPages - page_pagination.startPage) > 5 ">
								<a (click)="setpage(page_pagination.startPage + 5)">...</a>
							</li>
							<li *ngIf="(page_pagination.totalPages - page_pagination.startPage) > 5">
								<a
									(click)="setpage(page_pagination.totalPages)">{{page_pagination.totalPages}}</a>
							</li>

							<li class="page-item"
								[ngClass]="{disabled:page_pagination.currentPage === page_pagination.totalPages}">
								<a class="page-link snexticon" href="javascript:;"
									(click)="setpage(page_pagination.currentPage + 1)"></a>
							</li>
							<li class="page-item"
								[ngClass]="{disabled:page_pagination.currentPage === page_pagination.totalPages}">
								<a class="page-link nexticon" href="javascript:;"
									(click)="setpage(page_pagination.totalPages)"></a>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</div>
	</div>
</div>

<app-footer-seller></app-footer-seller>