import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BnplPaymentSuccessService  {

    constructor(private _http: HttpClient) { }

    confirmOrder(data) {
        return this._http.post(environment.api_url + 'bnpl/confirmOrder', data);
    }

}
