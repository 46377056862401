<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Logistics Management</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" [routerLink]="['/admin/logistic-partners-list']">Logistic Partners List</a>
						</li>
						<li class="breadcrumb-item active">Edit New Logistic Partner</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0" style="display: none;">
				<a href="javascript:;" class="btn buybtn fs16 radius10 ripple">View Buyer Details</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="row d-flex align-items-center mb-20">
			<div class="col-md-12">
				<h3 class="f-700 fs22 txtdefault pb-0"><a href="javascript:;" class="go-back"><i class="fa fa-angle-left"></i></a> Edit New Logistic Partner</h3>
			</div>
		</div>

		<div class="card card-ui card-uiovt byregwrp">
			<form [formGroup]="editNewPartnerForm">

				<div class="form-container">
					<div class="row">
						<div class="col-md-6">
							<div class="floating-label mb-4">      
								<input type="text" placeholder=" " class="form-control" formControlName="name">
								<span class="highlight"></span>
								<label>Logistic Partner</label>
								<span class="help-block" *ngIf="pf.name.invalid && pf.name.touched">Enter logistics partner name</span>
							</div>

							<div class="floating-label mb-2">      
								<input type="text" placeholder=" " class="form-control" formControlName="gst_no">
								<span class="highlight"></span>
								<label>GST Number</label>
								<span class="help-block" *ngIf="pf.gst_no.invalid && pf.gst_no.touched">Enter logistics partner GST number</span>
							</div>
						</div>
					</div>
				</div>

				<div class="fs15 f-700 mb-20">You can upload the documents in CSV format:</div>
				
				<div class="row">
					<div class="col-md-12">
						<div class="logistics-upload">
							<div class="title">1. PinCode Map</div>
							<div>
								<input type="file" name="pincode_file" #pincode (change)="uploadFile($event, 'pincode')" style="display: none;">
								<a href="javascript:void(0);" (click)="pincode.click()" class="btn-d-outline ripple d-inline-block mb-0">
									<div class="d-flex align-items-center">
										<div class="mr-2"><img src="assets/images/icons/csv.png"></div>
										<div>Upload New</div>
									</div>
								</a>
							</div>
						</div>
						<div class="logistics-upload">
							<div class="title">2. Tracking Series</div>
							<div>
								<input type="file" name="tracking_file" #tracking (change)="uploadFile($event, 'tracking')" style="display: none;">
								<a href="javascript:void(0);" (click)="tracking.click()" class="btn-d-outline ripple d-inline-block mb-0">
									<div class="d-flex align-items-center">
										<div class="mr-2"><img src="assets/images/icons/csv.png"></div>
										<div>Upload New</div>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>

				<div class="mt-10">
					<button class="btn buybtn fs16 radius10 ripple" (click)="updatelogisticspartner()" [disabled]="editNewPartnerForm.invalid || buttonLoading">
						<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="buttonLoading"></span>Save Changes</button>
				</div>
			</form>
		</div> 
	</div>
</div>