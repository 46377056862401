<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
    <div class="container pb-4">
        <!--- HEAD  --->
        <div class="row align-items-center mb-4 pinfo-head">
            <div class="col-sm-12">
                <nav class="breadcrumb breadcrumb-dui mb-0">
                    <ol class="breadcrumb p-0 bg-transparent  mb-0">
                        <li class="breadcrumb-item">
                            <a class="txtseconday">CMS</a>
                        </li>
                        <li class="breadcrumb-item active">Privacy Policy</li>
                    </ol>
                </nav>
            </div>
        </div>
        <!--- /HEAD  --->
        <div class="row d-flex align-items-center mb-20">
            <div class="col-md-12">
                <h3 class="f-700 fs22 txtdefault pb-0">
                    <a class="go-back"><i class="fa fa-angle-left"></i></a> Privacy Policy - CMS
                </h3>
            </div>
        </div>
        <!-- CARD -->

        <!-- Latest  CARD -->
        <!-- <form> -->
            <div class="card card-ui p-0 profileipt ">
                <div class="card-body add-pro-wrap pt-0 p-4">
                    <div class="form-container">
                        <!-- INPUT ROW -->
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="floating-label mb-4  pr-0">
                                    <angular-editor [(ngModel)]="privay_policy_content" [config]="config"></angular-editor>
                                </div>
                            </div>
                        </div>
                        <!-- /INPUT ROW -->
                        <div class="mt-20">
                            <button type="button" class="btn buybtn fs16 px-4 mr-4 radius10 ripple" (click)="cmsupdate()" [disabled]="buttonLoading">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="buttonLoading"></span>
                                &nbsp;Save
                            </button>
                            <a class="cancel-outline btn-d-outline d-inline-block px-4"
                                [routerLink]="['/admin/cms/privacy-policy']" href="javascript:void(0);">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        <!-- </form> -->
        <!-- /CARD -->

    </div>
</div>