import { Component, OnInit } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { ManageBuyerService } from './manage-buyer.service';
import { EncrDecrService } from '../../helpers/EncrDecrService';
import { PaginationService } from '../../helpers/pagination.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-manage-buyer',
    templateUrl: './manage-buyer.component.html',
    styleUrls: ['./manage-buyer.component.scss']
})
export class ManageBuyerComponent implements OnInit {

    classstatus: boolean = false;
    public search_term:string = '';
    public un_search_term:string = '';
    public un_status:string = '';
    public un_export_path:string = '';
    public export_path:string = '';
    public un_export_loading:boolean = false;
    public export_loading:boolean = false;
    constructor(
        private _managebuyerservice: ManageBuyerService,
        private _EncrDecr: EncrDecrService,
        private _pagination: PaginationService,
        private _toastr: ToastrService
    ) { }
    minValue: number = 0;
    maxValue: number = 10000;
    options: Options = {
        floor: 0,
        ceil: 10000
    };

    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 10;
    public manage_buyer_pagination: any = {};
    public manage_buyer_records: any = [];
    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];

    public registration_status = [
        {
            name: 'Select Registration Completed Status',
            value: '',
        },
        {
            name: 'Signup Completed',
            value: 0,
        },
        {
            name: 'Business Details',
            value: 1,
        },
        {
            name: 'Authorized Persons',
            value: 2,
        },
        {
            name: 'Bank Details',
            value: 3,
        },
    ]


    //Buyer Registered Records
    public by_current_page: number = 1;
    public by_offset: number = 0;
    public by_page_per_view: number = 10;
    public registered_buyer_pagination: any = {};
    public registered_buyer_records: any[];

    ngOnInit() {
        this.getmanagebuyer();
        this.getregisteredbuyer();
    }

    getmanagebuyer(download='') {
        this.export_path = '';
        this.export_loading = false;
        let limit = this.page_per_view;
        let offset = this.offset;
        let current_page = this.current_page;
        if(download == 'yes'){
            this.export_loading = true;
        }
        this._managebuyerservice.managebuyer({ limit: limit, offset: offset, current_page: current_page, search: this.search_term, download: download }).subscribe((data) => {
            if (data['status'] == "success") {
                if(data['download']){
                    this.export_path = data['path'];
                    this.export_loading = false;
                }else{
                    let total_records = data['total_records'];
                    let pages = Math.ceil(total_records / this.page_per_view);
                    this.manage_buyer_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
                    this.manage_buyer_records = data['records'];
                }
                this.export_loading = false;
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getregisteredbuyer(download='') {
        this.un_export_path = '';
        this.un_export_loading = false;
        let limit = this.by_page_per_view;
        let offset = this.by_offset;
        let current_page = this.by_current_page;
        if(download == 'yes'){
            this.un_export_loading = true;
        }
        this._managebuyerservice.registeredbuyer({ limit: limit, offset: offset, current_page: current_page, search: this.un_search_term, status: this.un_status, download: download }).subscribe((data) => {
            if (data['status'] == "success") {
                if(data['download']){
                    this.un_export_path = data['path'];
                    this.un_export_loading = false;
                }else{
                    let total_records = data['total_records'];
                    let pages = Math.ceil(total_records / this.by_page_per_view);
                    this.registered_buyer_pagination = this._pagination.getPager(total_records, this.by_current_page, this.by_page_per_view);
                    this.registered_buyer_records = data['records'];
                }
                this.un_export_loading = false;
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    updatestatus(record) {
        this._managebuyerservice.updatestatus({type:'buyer', id: record.userid}).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Buyer status has been changed successfully.')
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    tglclass() {
        this.classstatus = !this.classstatus;
    }
    public val = null;
    config = {
        labelField: 'label',
        valueField: 'value',

    };

    data = [
        {
            label: 'Price Low to High',
            value: 'Price High to Low'
        },
        {
            label: 'Product Ratings',
            value: 'Product Ratings'
        },
        {
            label: 'Relevance',
            value: 'Relevance'
        },
        {
            label: 'Latest Arrivals',
            value: 'Latest Arrivals'
        }
    ]

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.getmanagebuyer();
    }

    setpageperview() {
        this.current_page = 1;
        this.getmanagebuyer();
    }

    setsrpage(page) {
        this.by_current_page = page;
        this.by_offset = (page - 1) * this.by_page_per_view;
        this.getregisteredbuyer();
    }

    setsrpageperview() {
        this.by_current_page = 1;
        this.getregisteredbuyer();
    }



    ngAfterViewChecked() {
    }

    public changed() {
    }
    ratingst: boolean = false;
    ratingsort() {
        this.ratingst = !this.ratingst;
    }

}
