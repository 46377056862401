import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ProductListingService } from './product-listing.service';
import { PaginationService } from '../../helpers/pagination.service';
import { EncrDecrService } from '../../helpers/EncrDecrService';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { AddBrandService } from '../add-brand/add-brand.service';


@Component({
    selector: 'app-product-listing',
    templateUrl: './product-listing.component.html',
    styleUrls: ['./product-listing.component.scss']
})
export class ProductListingComponent implements OnInit {

    closeResult = '';
    activeTab = "productListing";
    pageTitle = "Product Listing";

    public import_file:any = '';
    public import_errors:any = [];

    public export_path:string = '';
    public export_loading:boolean = false;

    public seller_export_path:string = '';
    public seller_export_loading:boolean = false;
    
    constructor(
        private modalService: NgbModal,
        private _editproductlistingservice: ProductListingService,
        private _toastr: ToasterService,
        private _pagination: PaginationService,
        private _EncrDecr: EncrDecrService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _addbrandservice: AddBrandService
    ) { }

    //Product Listing
    public listing_status:string = '';
    public market_place:string = '';
    public category_id:string = '';
    public search_term:string = '';
    public from_date:any = '';
    public to_date:any = '';
    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 10;
    public product_listing_pagination: any = {};
    public productListing: any = [];

    //Request Listing
    public req_search_term:string = '';
    public req_from_date:any = '';
    public req_to_date:any = '';
    public req_current_page: number = 1;
    public req_offset: number = 0;
    public req_page_per_view: number = 10;
    public request_listing_pagination: any = {};
    public requestListing: any = [];
    public selected_record: any;

    //Seller Listing
    public seller_search_term:string = '';
    public seller_from_date:any = '';
    public seller_to_date:any = '';
    public seller_current_page: number = 1;
    public seller_offset: number = 0;
    public seller_page_per_view: number = 10;
    public seller_listing_pagination: any = {};
    public sellerListing: any = [];


    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];

    config = {
        labelField: 'name',
        valueField: 'value',

    };

    public marketplace_options = [
        {
            value: '',
            name: 'All',
        },
        {
            value: 'wholesale',
            name: 'Wholesale'
        },
        {
            value: 'bulk',
            name: 'Bulk'
        }
    ];

    public listing_status_options = [
        {
            value: '',
            name: 'All',
        },
        {
            value: 'active',
            name: 'Active'
        },
        {
            value: 'inactive',
            name: 'Inactive'
        }
    ];

    ngOnInit() {
        this._route.queryParams
            .subscribe(params => {
                if (params['tab'] !== undefined) {
                    if (params['tab'] == 2) {
                        this.changetab('requestListing');
                    } else if (params['tab'] == 3) {
                        this.changetab('sellerListing');
                    } else {
                        this.changetab();
                    }
                } else {
                    this.changetab();
                }
            }
            );
    }

    changetab(type = 'productListing') {
        this.activeTab = type;
        if (this.activeTab == 'productListing') {
            this.search_term = '';
            this.from_date = '';
            this.to_date = '';
            this.pageTitle = 'Product Listing';
            this.getproductlisting();
        } else if (this.activeTab == 'requestListing') {
            this.req_search_term = '';
            this.req_from_date = '';
            this.req_to_date = '';
            this.pageTitle = 'Requested Listing';
            this.getrequestlisting();
        } else if (this.activeTab == 'sellerListing') {
            this.seller_search_term = '';
            this.seller_from_date = '';
            this.seller_to_date = '';
            this.pageTitle = 'Seller Listing';
            this.getsellerlisting();
        }
    }

    getproductlisting(download = '') {
        let limit = this.page_per_view;
        let offset = this.offset;
        let current_page = this.current_page;
        let data = { 
            limit: limit,
            offset: offset,
            current_page: current_page,
            search: this.search_term,
            category: this.category_id,
            market_place: this.market_place,
            listing_status: this.listing_status,
            from_date: this.from_date,
            to_date: this.to_date,
            download: download
        };
        this._editproductlistingservice.getproductlisting(data).subscribe((data) => {
            if (data['status'] == "success") {
                if(data['download']){
                    this.export_path = data['path'];
                    this.export_loading = false;
                }else{
                    this.export_loading = false;
                    this.export_path = '';
                    let total_records = data['total_records'];
                    this.product_listing_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
                    this.productListing = data['searchResults'];
                }
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getproductlistingfilters(){
        this.page_per_view = 10;
        this.offset = 0;
        this.current_page = 1;
        this.getproductlisting();
    }

    getrequestlisting() {
        let limit = this.req_page_per_view;
        let offset = this.req_offset;
        let current_page = this.req_current_page;
        let data = { 
            limit: limit,
            offset: offset,
            current_page: current_page,
            search: this.req_search_term,
            from_date: this.req_from_date,
            to_date: this.req_to_date
        };
        this._editproductlistingservice.getrequestlisting(data).subscribe((data) => {
            if (data['status'] == "success") {
                let total_records = data['total_records'];
                this.request_listing_pagination = this._pagination.getPager(total_records, this.req_current_page, this.req_page_per_view);
                this.requestListing = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }
    getrequestlistingfilters(){
        this.req_page_per_view = 10;
        this.req_offset = 0;
        this.req_current_page = 1;
        this.getrequestlisting();
    }

    getsellerlisting(download = '') {
        let limit = this.seller_page_per_view;
        let offset = this.seller_offset;
        let current_page = this.seller_current_page;
        let data = { 
            limit: limit,
            offset: offset,
            current_page: current_page,
            search: this.seller_search_term,
            download: download
        }
        this._editproductlistingservice.getsellerlisting(data).subscribe((data) => {
            if (data['status'] == "success") {
                if(data['download']){
                    this.seller_export_path = data['path'];
                    this.seller_export_loading = false;
                }else{
                    this.seller_export_loading = false;
                    this.seller_export_path = '';
                    let total_records = data['total_records'];
                    this.seller_listing_pagination = this._pagination.getPager(total_records, this.seller_current_page, this.seller_page_per_view);
                    this.sellerListing = data['records'];
                }
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getsellerlistingfilters(){
        this.seller_page_per_view = 10;
        this.seller_offset = 0;
        this.seller_current_page = 1;
        this.getsellerlisting();
    }

    copylisting(unique_id) {
        this._editproductlistingservice.copylisting({ unique_id: unique_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Product template has been cloned successfully.');
                this._router.navigate(['/admin/products/add', data['uniqueId']]);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.getproductlisting();
    }

    setpageperview() {
        this.current_page = 1;
        this.getproductlisting();
    }

    setreqpage(page) {
        this.req_current_page = page;
        this.req_offset = (page - 1) * this.req_page_per_view;
        this.getrequestlisting();
    }

    setreqpageperview() {
        this.req_current_page = 1;
        this.getrequestlisting();
    }

    setsellerpage(page) {
        this.seller_current_page = page;
        this.seller_offset = (page - 1) * this.seller_page_per_view;
        this.getsellerlisting();
    }

    setsellerpageperview() {
        this.seller_current_page = 1;
        this.getsellerlisting();
    }

    openimport(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true, }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    open(content, listing) {
        this.selected_record = listing;
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true, }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    startrequestedlisting(record) {
        this._editproductlistingservice.startrequestedlisting({ id: record.id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.getrequestlisting();
                this._toastr.success('Requested product has been listed successfully.');
                this._router.navigate(['/admin/products/add', data['uniqueId']]);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    deleterequestlisting() {
        this._editproductlistingservice.deleterequestedlisting({ id: this.selected_record.id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this.getrequestlisting();
                this._toastr.success('Requested product has been deleted successfully.');
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    keyword = 'name'
    result = [];

    selectEvent(item) {
        this.category_id = item.c_id;
        this.getproductlistingfilters();
    }

    onChangeSearch(val: string) {
        this._addbrandservice.searchcategory({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.result = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    onFocused(e) {
        // do something when input is focused
    }


    importfiles(){
        this.import_errors = [];
        let data: any = new FormData();
        data.append("document", this.import_file);
        this._editproductlistingservice.importproducts(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.import_errors = data['error_info'];
                this.result = data['records'];
                this._toastr.success(data['msg']);
                this.getproductlisting();
                this.modalService.dismissAll();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    uploadFile(event){
        this.import_file = event.target.files[0];
        //event.target.value = '';
    }
}
