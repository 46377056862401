import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class ViewcandfRequestService {

    constructor(private _http: HttpClient) { }

    getdetails(data) {
        return this._http.post(environment.api_url + 'admin/user/details', data);
    }

    changestatus(data) {
        return this._http.post(environment.api_url + 'admin/candf/approve', data);
    }

}
