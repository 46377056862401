import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from '../../helpers/toastr.service';
import { BrandManagementService } from './brand-management.service';
@Component({
  selector: 'app-brand-management',
  templateUrl: './brand-management.component.html',
  styleUrls: ['./brand-management.component.scss']
})
export class BrandManagementComponent implements OnInit {

    public export_path:string = '';
    public export_loading:boolean = false;
    closeResult = '';
    public search_term: string = '';
    public current_page:number = 1;
    public offset:number = 0;
    public page_per_view:number = 10;
    public brand_pagination:any = {};
    public records: any = [];
    public selectedRecord:any = {};

    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];

    setpage(page) {
        this.current_page = page;
        this.offset = (page-1)*this.page_per_view;
        this.getbrands();
    }

    setpageperview(){
        this.current_page = 1;
        this.getbrands();
    }
  constructor(
    private modalService: NgbModal,
    private _brandservice: BrandManagementService,
    private _toastr: ToasterService,
    private _pagination: PaginationService
    ) { }

    ngOnInit(): void {
        this.getbrands();
    }
    open(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true,}).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    openWindowCustomClass(content, record) {
        this.modalService.open(content, { windowClass: 'modal-500' });
        this.selectedRecord = record;
    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    getbrands(download='') {
        this._brandservice.getbrands({download: download, limit: this.page_per_view, offset: this.offset, current_page: this.current_page, search: this.search_term}).subscribe((data) => {
            if(data['status'] == "success"){
                if(data['download']){
                    this.export_path = data['path'];
                    this.export_loading = false;
                }else{
                    this.records = data['searchResults'];
                    let total_records = data['total_records'];
                    this.brand_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
                }
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    deletebrand() {
        this._brandservice.deletebrand({brand_id: this.selectedRecord.id}).subscribe((data) => {
            if(data['status'] == "success"){
                this._toastr.success('Brand deleted successfully');
                this.modalService.dismissAll();
                this.getbrands();
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

}
