import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { HeaderOneService } from 'src/app/shared/header/header-one/header-one.service';
import { BuyerManagePaymentMethodsService } from './buyer-manage-payment-methods.service';

@Component({
  selector: 'app-buyer-manage-payment-methods',
  templateUrl: './buyer-manage-payment-methods.component.html',
  styleUrls: ['./buyer-manage-payment-methods.component.scss']
})
export class BuyerManagePaymentMethodsComponent implements OnInit {
	
	closeResult = '';
    public selected_card:any = {};
    public wallet_amount:string;
    public saved_cards_records:any = [];
  	constructor(
        private modalService: NgbModal,
        private _paymentmethods: BuyerManagePaymentMethodsService,
        private _toastr: ToasterService,
        private _headerservice: HeaderOneService,
    ) {}

    getuserdetails() {
        this._headerservice.getuserdetails(null).subscribe((data) => {
            if(data['status'] == "success"){
              this.wallet_amount = data['data']['walletbalance']
            }
        },(error) => {

        });
    }

    getsavedcard(){
        this._paymentmethods.savedcards(null).subscribe((data) => {
            if(data['status'] == "success"){
                this.saved_cards_records = data['records'];
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    setdefault(card){
        this._paymentmethods.setdefault({card_id: card.id}).subscribe((data) => {
            if(data['status'] == "success"){
                this._toastr.success('Default card updated');
                this.getsavedcard();
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    removecard(){
        this._paymentmethods.removecard({card_id: this.selected_card.id}).subscribe((data) => {
            if(data['status'] == "success"){
                this.modalService.dismissAll();
                this._toastr.success('Card removed successfully.');
                this.getsavedcard();
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    open(content, card) {
        this.selected_card = card;
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'mblbtm' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
  
    ngOnInit(): void {
        this.getsavedcard();
        this.getuserdetails();
    }

}
