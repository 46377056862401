import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UnclearBalanceService {

  constructor(private _http: HttpClient) { }

    getunclearbalance(data) {
        return this._http.post(environment.api_url+'admin/wallet/unclearbalance',data);
    }

    approveunclear(data) {
        return this._http.post(environment.api_url+'admin/wallet/unclearbalance/approve',data);
    }

}
