import { Component, OnInit } from '@angular/core';
import { ToasterService } from '../../helpers/toastr.service';
import { EncrDecrService } from '../../helpers/EncrDecrService';
import { EditBrandService } from './edit-brand.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-edit-brand',
    templateUrl: './edit-brand.component.html',
    styleUrls: ['./edit-brand.component.scss']
})
export class EditBrandComponent implements OnInit {

    brandForm: FormGroup;

    dropdownList = [];
    selectedItems = [];
    dropdownSettings = {};
    closeResult = '';

    public brandLoading:boolean = false;
    public brand_logo_name: string;
    public brand_logo_image: any;
    public brand_logo: string;
    public brand_id: string;
    public countryArray = [];
    public brand_logo_status: boolean = false;
    public categories = [];

    keyword = 'name'
    result = [];
    public categoryListName = [];

    public info: any = {};
    constructor(
        private _toastr: ToasterService,
        private modalService: NgbModal,
        private _editbrandservice: EditBrandService,
        private _EncrDecr: EncrDecrService,
        private fb: FormBuilder,
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.brand_id = this.route.snapshot.paramMap.get('brandId');
        this.getbrand();
        
        this.dropdownList = [];
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'value',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 100,
            allowSearchFilter: true
        };

        //Brand Form
        this.brandForm = this.fb.group({
            brand_name: this.fb.control(null, [Validators.required]),
            categories: this.fb.control(null),
            brand_website: this.fb.control("", [Validators.required]),
            service_centers: this.fb.control("", [Validators.required]),
            owner_company_name: this.fb.control(null, [Validators.required]),
            country_of_origin: this.fb.control(null, [Validators.required])
        });

        this.getcountry();
    }
    config = {
        labelField: 'name',
        valueField: 'value',

    };

    get bf() { return this.brandForm.controls; }

    onItemSelect(item: any) {
    }
    onSelectAll(items: any) {
    }

    onItemDeSelect($event) {
    }


    brandsubmit() {
        this.categories = [];
        for (let i = 0; i < this.selectedItems.length; i++) {
            var id = this.selectedItems[i].value;
            this.categories.push(id);
        }
        this.brandLoading = true;
        let data: any = new FormData();
        data.append('brand_id', this.brand_id);
        data.append('brand_name', this._EncrDecr.set(this.brandForm.get('brand_name').value));
        data.append('categories', this.dropdownList);
        data.append('brand_website', this._EncrDecr.set(this.brandForm.get('brand_website').value));
        data.append('service_centers', this._EncrDecr.set(this.brandForm.get('service_centers').value));
        data.append('owner_company_name', this._EncrDecr.set(this.brandForm.get('owner_company_name').value));
        data.append('country_of_origin', this._EncrDecr.set(this.brandForm.get('country_of_origin').value));
        if (this.brand_logo_status) {
            data.append("document", this.brand_logo);
        } else {
            data.append("document", '');
        }
        this._editbrandservice.updatebrand(data).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Brand details updated successfully.');
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
            this.brandLoading = false;
        }, (error) => {
            this.brandLoading = false;
        });
    }

    getcountry() {
        this._editbrandservice.getcountry(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.countryArray = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getbrand() {
        this._editbrandservice.getbrand({ brand_id: this.brand_id }).subscribe((data) => {
            if (data['status'] == "success") {
                var record = data['record'];
                this.brandForm.patchValue({
                    brand_name: record.brand_name,
                    brand_website: record.brand_website,
                    service_centers: record.service_centers,
                    owner_company_name: record.owner_company_name,
                    country_of_origin: record.country_of_origin,
                });
                var i = 0;
                for (i; i < record.categories.length; i++) {
                    this.dropdownList.push(record.categories[i].id);
                    this.categoryListName.push(record.categories[i].name);
                }
                this.brand_logo_image = record.brand_logo;
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    uploadfile(event) {
        const file = event.target.files[0];
        this.brand_logo = file;
        this.brand_logo_status = true;
        this.brand_logo_name = file.name;
        const reader = new FileReader();
        reader.onload = e => this.brand_logo_image = reader.result;
        reader.readAsDataURL(file);
    }

    openeditcategory(editcategory) {
        this.modalService.open(editcategory, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    removecategory(cat) {
        let index = this.categoryListName.findIndex(x => x === cat);
        this.categoryListName.splice(index, 1);
        this.dropdownList.splice(index, 1);
    }

    selectEvent(item) {
        if (this.categoryListName.indexOf(item.c_name) !== -1) {
            this._toastr.error('Category already added');
            return false;
        } else {
            this.categoryListName.push(item.c_name);
            this.dropdownList.push(item.c_id);
        }

        this.modalService.dismissAll();
    }

    onChangeSearch(val: string) {
        this._editbrandservice.searchcategory({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.result = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    onFocused(e) {
        // do something when input is focused
    }


}
