<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Product Management</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" [routerLink]="['/admin/order-quality-check']">Quality Check</a>
						</li>
						<li class="breadcrumb-item active">View Details</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ml-3">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="row d-flex align-items-center mb-20">
			<div class="col-md-12">
				<h3 class="f-700 fs22 txtdefault pb-0"><a href="javascript:;" class="go-back"><i class="fa fa-angle-left"></i></a> View Details</h3>
			</div>
			
		</div>

		<div class="card card-ui card-uiovt byregwrp mb-30">
			<div class="row d-flex align-items-center justify-content-between">
				<div class="col-md-6">
					<h4 class="fs20 f-700 mb-2">SKU#{{ record.sku }}</h4>
					<h4 class="fs20 f-700 mb-2">Transaction ID#{{ record.transaction_id }}</h4>
					<div style="display: none;">Paid via Wallet on 27/08/2021, 06:20:25 pm </div>
				</div>
				<div class="col-md-6">
					<div class="text-right">
						<h4 class="fs16 f-700 mb-2">Order Status:</h4>
						<!-- <div>QC in Progress</div> -->
						<div>{{ record.order_status }}</div>
					</div>
				</div>
			</div>

			<hr>

			<div class="row d-flex align-items-end">
				<div class="col">
					<div class="text-dark f-500">Order ID:</div>
					<div class="text-muted">{{ record.order_id }}</div>
				</div>
				<div class="col">
					<div class="text-dark f-500">Order Date:</div>
					<div class="text-muted">{{ record.order_date }}</div>
				</div>
				<div class="col">
					<div class="text-dark f-500">QC Requested on:</div>
					<div class="text-muted">{{ record.qc_requsted_on }}</div>
				</div>
				<div class="col">
					<div class="text-dark f-500">QC Processed on:</div>
					<div class="text-muted">{{ record.qc_processed_status }}</div>
				</div>
				<div class="col">
					<div class="text-right"><a href="javascript:;" [routerLink]="'/admin/ordderdetails/'+order_id" class="link-alt fs14">View Order Details</a></div>
				</div>
			</div>

			<hr>

			<div class="fs15 f-700 mb-20">Product Info:</div>
			<div class="row d-flex align-items-center mb-30">
				<div class="col-md-6">
					<div class="d-flex align-items-center">
						<div class="mr-3"><img src="{{ record.product_image }}" style="max-width:80px"></div>
						<h5 class="mb-0 fs14 wt700">{{ record.product_name }}</h5>
					</div>
				</div>	

				<div class="col-md-6 text-right">
					<div class="row">
						<div class="col text-center">
							<div class="text-dark f-600">Verified:</div>
							<div class="text-dark">{{ record.verified>0?record.verified:'-' }} &nbsp;<a href="javascript:;" style="display: none;">
								<img src="assets/images/icons/edit.png"></a></div>
							<!--Empty State <div>-</div> -->
						</div>
						<div class="col text-center">
							<div class="text-dark f-600">Rejected:</div>
							<div class="text-dark">{{ record.rejected>0?record.rejected:'-' }}</div>
							<!--Empty State <div>-</div> -->
						</div>
						<div class="col text-center">
							<div class="text-dark f-600">Total Order Qty:</div>
							<div class="text-dark">{{ record.total_qty }}</div>
							<!--Empty State <div>-</div> -->
						</div>
					</div>
				</div>
			</div>
			
			<div class="row d-flex align-items-center mb-30" *ngIf="record.parentchild != 'yes'">
				<div class="col-md-6">
					<div class="d-flex">
					<svg xmlns="http://www.w3.org/2000/svg" width="21.3" height="20.229" viewBox="0 0 21.3 20.229">
					  <g id="Group_17026" data-name="Group 17026" transform="translate(-0.769 -0.415)">
					    <g id="Group_16501" data-name="Group 16501" transform="matrix(0.966, 0.259, -0.259, 0.966, 3.887, 0.595)">
					      <path id="Path_3274" data-name="Path 3274" d="M0,7.509a7.508,7.508,0,1,1,7.507,7.508" transform="translate(2.856 0)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
					      <path id="Path_3275" data-name="Path 3275" d="M0,0H5.712L2.856,4.536Z" transform="translate(0 7.509)" fill="#ff7135"/>
					    </g>
					    <path id="Path_10477" data-name="Path 10477" d="M9927.637,13576.114v4.359h4.037" transform="translate(-9916.015 -13568.78)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
					  </g>
					</svg>
					<a href="javascript:;" class="link-alt fs15 ml-2" (click)="showloghistory()">
						View log History
					</a>
					</div>
				</div>	
				<div class="col-md-6">
					<div class="text-right d-flex justify-content-end">
						<!--Enable this CTA after QC started-->
						<a href="javascript:void(0);" *ngIf="(record.buyer_accepted == 0 || record.buyer_accepted == 1) && record.qc_file" (click)="reviewnotify()" class="btn buybtn fs16 radius10 mr-3">Review and Notify</a>
						<!--END-->
						<input type="file" #qcfile name="qc_file" style="display:none;" (change)="uploadFile($event)">
						<a *ngIf="record.buyer_accepted == 0 || record.buyer_accepted == 1" href="javascript:void(0);" (click)="qcfile.click()" class="btn-d-outline ripple d-inline-block">
							<div class="d-flex align-items-center">
								<div class="mr-2"><img src="assets/images/icons/csv.png"></div>
								<div>Upload EXCEL file</div>
							</div>
						</a>
					</div>	
				</div>
			</div>

			<ng-container *ngIf="record.parentchild == 'yes'">
				<hr class="mt-10 mb-20">
				<div class="row table-card">
					<div class="col-md-12">
						<div class="fs14 f-700 mb-2">Child Product Information:</div>
						<table class="table custom-table">
							<thead>
								<tr>
									<th>Image</th>
									<th>Name</th>
									<th>Total Quantity</th>
									<th>Verified</th>
									<th>Rejected</th>
									<th>Upload Status</th>
									<th>Upload QC</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let child of record.parentchild_records; let i = index;">
									<td><img src="{{ child.image }}" alt="{{ child.name }}" style="height:80px;width:80px"></td>
									<td>{{ child.name }}</td>
									<td>{{ child.quantity }}</td>
									<td>{{ child.approved }}</td>
									<td>{{ child.rejected }}</td>
									<td>{{ child.status }}</td>
									<td>
										<div class="text-right d-flex justify-content-end">
											<input type="file" #qcfile{{i}} name="qc_file{{i}}" (change)="uploadChildFile($event, child)">
										</div>	
									</td>
								</tr>
							</tbody>
						</table>
						<div class="text-right d-flex justify-content-end">
							<!--Enable this CTA after QC started-->
							<a href="javascript:void(0);" *ngIf="(record.buyer_accepted == 0 || record.buyer_accepted == 1) && record.qc_file" (click)="reviewnotify()" class="btn buybtn fs16 radius10 mr-3">Review and Notify</a>
							<!--END-->
						</div>	
					</div>
				</div>
			</ng-container>
		</div> 

		<h4 class="fs18 f-600 mb-10" *ngIf="showlog_history && record.qc_log.length > 0">Quality Check Log</h4>
		<div class="card card-ui card-uiovt byregwrp qualityCheck" *ngIf="showlog_history && record.qc_log.length > 0">
			<div class="table-responsive">
				<table class="table custom-table xs">
					<thead>
						<tr>
							<th>Product Name</th>
							<th>IMEI No.</th>
							<th>QC Status</th>
							<th width="30%">Reason for Rejection</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let log of record.qc_log">
							<td>{{ record.product_name }}</td>
							<td>{{ log.imei }}</td>
							<td>{{ log.status }}</td>
							<td>
								<div class="selectiveForm relative" *ngIf="log.status == 'rejected'">
									<select class="form-control" [(ngModel)]="log.reason" (change)="updatereason(log)">
										<option *ngFor="let option of reject_options;" value="{{ option.value }}">{{ option.label }}</option>
									</select>
									<div class="selectDiv"></div>
								</div>
							</td>
						</tr>
						
					</tbody>
				</table>
				</div>
		</div>
	</div>
</div>