import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {NgbNavChangeEvent} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-post-orders',
  templateUrl: './post-orders.component.html',
  styleUrls: ['./post-orders.component.scss']
})
export class PostOrdersComponent implements OnInit {

  active=9;
  sampleactive=1;
disabled = true;
  constructor(private modalService: NgbModal) { }
flactive = 13;
  ngOnInit(): void {
  }  
 config = {
    labelField: 'label',
    valueField: 'value',
     
  }; 
data = [
    {
      label: 'India',
      value: 'india'
    },
    {
      label: 'in $',
      value: 'in $'
    }
  ]
 prdata = [
    {
      label: 'in % of M',
      value: 'in % of M'
    },
    {
      label: 'in % of M',
      value: 'in % of M'
    }
  ] 
  gbstorage=[
	{
      label: 'In GB',
      value: 'In GB'
    },
    {
      label: 'In GB',
      value: 'In GB'
    }
  ]
  regdata = [
    {
      label: 'Registered Brand Owner',
      value: 'owner'
    },
    {
      label: 'Option2',
      value: 'Option2'
    }
  ]
  monthdata = [
    {
      label: 'In Months',
      value: 'In Months'
    },
    {
      label: 'In Year',
      value: 'In Year'
    }
  ]
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'modal-500' });
  }



closeResult = '';
  


}
