import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { ViewRequestedListingService } from './view-requested-listing.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-view-requested-details',
  templateUrl: './view-requested-details.component.html',
  styleUrls: ['./view-requested-details.component.scss']
})
export class ViewRequestedDetailsComponent implements OnInit {

	closeResult = '';
    constructor(
        private modalService: NgbModal,
        private _reqeustedservice: ViewRequestedListingService,
        private _toastr: ToasterService,
        private _route: ActivatedRoute,
        private _router: Router
    ) { }
    public requested_id:string;
    public record:any = {};

    ngOnInit(): void {
        this.requested_id = this._route.snapshot.paramMap.get('id');
        this.viewrequestedlisting();
    }

    viewrequestedlisting(){
        this._reqeustedservice.viewrequestedlisting({id: this.requested_id}).subscribe((data) => {
            if(data['status'] == "success"){
                this.record = data['records'];
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    open(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true,}).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    deleterequestlisting(){
        this._reqeustedservice.deleterequestedlisting({id: this.requested_id}).subscribe((data) => {
            if(data['status'] == "success"){
                this.modalService.dismissAll();
                this._toastr.success('Requested product has been deleted successfully.');
                this._router.navigate(['/admin/product-listing/'], { queryParams: {tab: 2}});
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    startrequestedlisting(){
        this._reqeustedservice.startrequestedlisting({id: this.record.id}).subscribe((data) => {
            if(data['status'] == "success"){
                this._toastr.success('Requested product has been listed successfully.');
                this._router.navigate(['/admin/products/add', data['uniqueId']]);
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

}
