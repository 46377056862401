<app-header-buyer></app-header-buyer>
<app-sidebar-buyer></app-sidebar-buyer>
<div class="wrpmain-buyer">
    <div class="container">
        <!--- HEAD  --->
        <div class="row align-items-center mb-4 pinfo-head mb-xs-0">
            <div class="col-sm-6 col-12 pr-0">
                <nav class="breadcrumb breadcrumb-dui mb-0">
                    <ol class="breadcrumb p-0 bg-transparent  mb-0">
                        <li class="breadcrumb-item">
                            <a class="txtseconday" href="javascript:void(0);"
                                [routerLink]="['/buyer/buyer-track-orders']">My Orders</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a class="txtseconday" href="javascript:void(0);"
                                [routerLink]="['/buyer/buyer-track-orders']">Order History</a>
                        </li>
                        <li class="breadcrumb-item active">View Order</li>
                    </ol>
                </nav>
            </div>
            <div class="col-sm-6 col-5 text-right pl-0">
                &nbsp;
            </div>
        </div>
        <!--- /HEAD  --->
        <div class="row align-items-center adprotop mb-0">
            <div class="col-md-6 mb-4">
                <h4 class="wt700 mb-0 txtdefault fs22">
                    <a href="javascript:void();" [routerLink]="['/buyer/buyer-track-orders']" class="go-back"><i class="fa fa-angle-left"></i></a>
                    View Order
                </h4>
            </div>
            <div class="col-md-6 d-flex justify-content-end">
                <div *ngIf="order_details.imei_status">
                    <b>Download:</b>
                    <span *ngIf="order_details.imei_status && order_details.imei_path">&nbsp;&nbsp; <a href="{{ order_details.imei_path }}" target="_blank">IMEI Bill</a></span> 
                    <span *ngIf="order_details.qc_file">&nbsp;|&nbsp; <a href="{{ order_details.qc_file }}" target="_blank">QC Report</a></span> 
                    <span *ngIf="order_details.invoice_status && order_details.invoice_path">&nbsp;|&nbsp; <a href="{{ order_details.invoice_path }}" target="_blank">Invoice</a> </span>
                    <span *ngIf="order_details.eway_bill_status && order_details.eway_bill_path">&nbsp;|&nbsp; <a href="{{ order_details.eway_bill_path }}" target="_blank">E-Way Bill</a></span>
                    <span *ngIf="order_details.shipping_label_status && order_details.shipping_label_path">&nbsp;|&nbsp; <a href="{{ order_details.shipping_label_path }}" target="_blank">Shipping Label</a></span>
                    <span *ngIf="order_details.admin_buyer_charge_invoice_path">&nbsp;|&nbsp; <a href="{{ order_details.admin_buyer_charge_invoice_path }}" target="_blank">Charge Invoice</a></span>
                </div>
            </div>
        </div>

        <div class="card card-ui mb-3">
            <div class="cs-item-row">
                <div class="row">
                    <div class="col-md-6">
                        <div class="d-table mb-4 w-100">
                            <div class="d-table-cell cart-thumb2 align-middle">
                                <img src="{{ order_details.product_image }}" />
                            </div>
                            <div class="d-table-cell pl-3 align-middle">
                                <p class="txtdefault fs18 mb-2 f-700">Delivered On {{ order_details.delivered_on }}
                                    <span class="md-block"><span class="badge-d-outline odbdge fs10">{{ order_details.order_type }}</span></span>
                                </p>
                                <!-- <p class="txtdefault fs18 mb-2 f-700">70 out of 100 are verified  <span class="badge-d-outline odbdge fs10">Bulk</span> &nbsp;<i placement="top" ngbTooltip="This will act as a default filter every time you will toggle."><img src="/assets/images/icons/info.png"></i></p>
								<p class="txtdefault fs14 text-inactive">30 products found defective</p> -->
                                <p class="txtdefault wt700 mb-0 fs14">{{ order_details.product_name }}</p>
                                <p class="txtseconday my-1 fs14"><span class="txtdefault wt500">Order ID: </span> {{
                                    order_details.order_id }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 text-right">
                        <a href="javascript:;" class="btn-d-outline d-inline-block" *ngIf="order_details.current_status == 9"  (click)="openWindowReturnRequest(returnrequest)">
							<img src="/assets/images/icons/np_return.svg"> Request Return
						</a>

                        <!--Accept CTA-->
                        <div class="d-flex justify-content-end">
                            <!-- <a href="javascript:;" class="btn buybtn-success fs16 radius10 ripple mr-2">
								Accept {{ order_details.order_quantity }} Verified Products
							</a> -->
                            <!-- <a href="javascript:;" class="btn-d-outline d-inline-block">
                                <img src="/assets/images/icons/np_help.svg"> Help
                            </a> -->
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="order_details.current_status == 9">
                    <div class="col-md-6" *ngIf="order_details.if_seller_rating == 'yes'">
                        <div class="fs14 f-700 mb-2">Rate your Seller ({{ order_details.seller_name }})</div>
                        <div class="ratings star-ratings pr-1 d-inline-block">
                            <ngb-rating [max]="5" [(rate)]="order_details.seller_rating" [readonly]="true" class="rating_star"></ngb-rating>
                        </div>
                    </div>
                    <div class="col-md-6" *ngIf="order_details.if_seller_rating == 'no'">
                        <div class="text-right md-text-left"><a href="javascript:;" [routerLink]="['/buyer/write-review']" class="link-alt fs14">Write a Review</a></div>
                    </div>
                </div>

                <hr class="mt-10 mb-20">

                <div class="row">
                    <div class="col-md-6">
                        <div class="fs14 f-700 mb-2">Shipping Address:</div>
                        <div class="txtdefault">{{ order_details.shipping_address }}</div>
                    </div>
                    <div class="col-md-6 mt-xs-10">
                        <div class="text-right md-text-left"><a href="javascript:;" class="link-alt fs14" [routerLink]="['/buyer/buyer-track-orders']">Track your Package</a>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="order_details.parentchild == 'yes'">
                    <hr class="mt-10 mb-20">
                    <div class="row table-card">
                        <div class="col-md-12">
                            <div class="fs14 f-700 mb-2">Child Product Information:</div>
                            <table class="table custom-table">
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Name</th>
                                        <th>Quantity</th>
                                        <th>Price per unit</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let child of order_details.parentchild_records;">
                                        <td><img src="{{ child.image }}" alt="{{ child.name }}" style="height:80px;width:80px"></td>
                                        <td>{{ child.name }}</td>
                                        <td>{{ child.quantity }}</td>
                                        <td>{{ child.price_per_unit }}</td>
                                        <td>{{ child.status }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ng-container>

                <hr class="mt-10 mb-20">

                <div class="row table-card">
                    <div class="col-md-12">
                        <div class="fs14 f-700 mb-2">Payment Information:</div>
                        <table class="table custom-table">
                            <thead>
                                <tr>
                                    <th>Date of Payment</th>
                                    <th>Transaction Id</th>
                                    <th>Amount (in ₹)</th>
                                    <th>Mode of Payment</th>
                                    <th>Payment Status</th>
                                    <th>Transaction Type</th>
                                    <!-- <th>Paid To</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let payment of order_details.payment_information;">
                                    <td>{{ payment.date }}</td>
                                    <td>{{ payment.transaction_id }}</td>
                                    
                                    <td>{{ payment.amount_type == 'out'?'- '+payment.amount:payment.amount }} <span class="badge-d-outline odbdge fs10 ml-2">{{
                                            payment.note }}</span></td>
                                    <td>{{ payment.mode_of_payment == 'pg'?'Payment Gateway':payment.mode_of_payment }}
                                    </td>
                                    <td>{{ payment.trans_status }}</td>
                                    <td>{{ payment.trans_type }}</td>
                                    <!-- <td>{{ order_details.seller_name }}</td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <hr class="mt-10 mb-20">

                <div class="row">
                    <div class="col-md-12">
                        <div class="fs14 f-700 mb-2">Order Details:</div>
                        <div class="inrcard p-3 radius10 position-relative">
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="row d-flex justify-content-between">
                                        <div class="col-6 col-md-6 py-2">ID:</div>
                                        <div class="col-6 col-md-6 py-2 text-right">{{ order_details.order_id }}</div>
                                    </div>
                                    <div class="row d-flex justify-content-between">
                                        <div class="col-6 col-md-6 py-2">Date & Time of booking:</div>
                                        <div class="col-6 col-md-6 py-2 text-right">{{ order_details.booking_date }}</div>
                                    </div>
                                    <div class="row d-flex justify-content-between">
                                        <div class="col-6 col-md-6 py-2">Logistic preference:</div>
                                        <div class="col-6 col-md-6 py-2 text-right">{{ order_details.logistics }} &nbsp;<i
                                                placement="top"
                                                ngbTooltip="This will act as a default filter every time you will toggle."><img
                                                    src="/assets/images/icons/info.png"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr class="mt-10 mb-20">

                <div class="row">
                    <div class="col-md-12">
                        <div class="fs14 f-700 mb-2">Amount Payable Details:</div>
                        <div class="inrcard p-3 radius10 position-relative">
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="row d-flex justify-content-between">
                                        <div class="col-6 col-md-6 py-2">Order Quantity:</div>
                                        <div class="col-6 col-md-6 py-2 text-right">{{ order_details.order_quantity }}</div>
                                    </div>
                                    <div class="row d-flex justify-content-between" *ngIf="order_details.parentchild != 'yes'">
                                        <div class="col-6 col-md-6 py-2">Price Per Unit:</div>
                                        <div class="col-6 col-md-6 py-2 text-right">{{ order_details.price_per_unit }}</div>
                                    </div>
                                    <div class="row d-flex justify-content-between">
                                        <div class="col-6 col-md-6 py-2">Net Goods Value:</div>
                                        <div class="col-6 col-md-6 py-2 text-right">{{ order_details.net_amount }}</div>
                                    </div>
                                    <div class="row d-flex justify-content-between">
                                        <div class="col-6 col-md-6 py-2">GST:</div>
                                        <div class="col-6 col-md-6 py-2 text-right">{{ order_details.gst_amount }}</div>
                                    </div>
                                    <div class="row d-flex justify-content-between">
                                        <div class="col-6 col-md-6 py-1">Logistics Charges:</div>
                                        <div class="col-6 col-md-6 py-1 text-right">{{ order_details.logistic_fee }}</div>
                                    </div>
                                    <div class="row d-flex justify-content-between">
                                        <div class="col-6 col-md-6 py-1">Convenience Charges:</div>
                                        <div class="col-6 col-md-6 py-1 text-right">{{ order_details.convenience_fee }}</div>
                                    </div>
                                    <div class="row d-flex justify-content-between">
                                        <div class="col-6 col-md-6 py-1">Insurance Charges:</div>
                                        <div class="col-6 col-md-6 py-1 text-right">{{ order_details.insurance_fee }}</div>
                                    </div>
                                    <div class="row d-flex justify-content-between">
                                        <div class="col-6 col-md-6 py-1">Payment Gateway Charges:</div>
                                        <div class="col-6 col-md-6 py-1 text-right">{{ order_details.payment_charge }}</div>
                                    </div>
                                    <div class="row d-flex justify-content-between">
                                        <div class="col-6 col-md-6 py-2">Total Payable Amount:</div>
                                        <div class="col-6 col-md-6 py-2 text-right">{{ order_details.order_total }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Order Total -->
<ng-template #content let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>

    <div class="modal-body pb-3">
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead text-center">
                    <h4 class="txtdefault f-700 fs18 mb-20">Order Total</h4>
                </div>

                <div class="inrcard p-3 radius10 position-relative">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row d-flex justify-content-between">
                                <div class="col-6 col-md-6 py-1">Order Quantity:</div>
                                <div class="col-6 col-md-6 py-1 text-right">{{ order_details.order_quantity }}</div>
                            </div>
                            <div class="row d-flex justify-content-between">
                                <div class="col-6 col-md-6 py-1">Price Per Unit: &nbsp;<i placement="top"
                                        ngbTooltip="Inclusive of GST"><img src="/assets/images/icons/info.png"></i>
                                </div>
                                <div class="col-6 col-md-6 py-1 text-right">{{ order_details.price_per_unit }}</div>
                            </div>
                            
                            <hr>
                            <div class="row d-flex justify-content-between">
                                <div class="col-6 col-md-6 py-1">Net Goods Value:</div>
                                <div class="col-6 col-md-6 py-1 text-right">{{ order_details.net_amount }}</div>
                            </div>
                            <div class="row d-flex justify-content-between">
                                <div class="col-6 col-md-6 py-1">GST:</div>
                                <div class="col-6 col-md-6 py-1 text-right">{{ order_details.gst_amount }}</div>
                            </div>
                            <div class="row d-flex justify-content-between">
							<div class="col-6 col-md-6 py-1">Coupon Discount:</div>
							<div class="col-6 col-md-6 py-1 text-right text-active">- ₹ 150</div>
						</div> 
                            <hr>
                            <div class="row d-flex justify-content-between">
                                <div class="col-6 col-md-6 py-1 f-700">Total Order Value:</div>
                                <div class="col-6 col-md-6 py-1 text-right f-700">{{ order_details.order_total }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- /UPLOAD INVOICE -->


<!-- RETURN REQUEST -->
<ng-template #returnrequest let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>

    <div class="modal-body">
        <div class="row align-items-center">
            <div [ngClass]="{'col-sm-7': order_details.return_request, 'col-sm-12':!order_details.return_request}">
                <div class="">
                    <div class="rtrqsthead">
                        <h4 class="txtdefault wt700 fs22">Return Request</h4>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 pr-0 rtnrqtmdl">
                            <div class="d-table mb-4 w-100">
                                <div class="d-table-cell cart-thumb align-middle">
                                    <img src="{{ order_details.product_image }}" />
                                </div>
                                <div class="d-table-cell pl-2 align-middle">
                                    <p class="txtdefault wt700 mb-0 fs12">{{ order_details.product_name }}
                                    </p>
                                    <p class="txtseconday my-1 fs12"><span class="txtdefault wt500">Order ID: </span>
                                        {{ order_details.order_id }}</p>
                                        <div class="badge-d-outline odbdge d-inline fs10" *ngIf="order_details.return_request"><b>Return Status:</b> {{ return_status }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-container mb-0 mt-0">

                        <div class="row" *ngIf="order_details.parentchild != 'yes'">
                            <div class="col-lg-12 ">
                                <div class="floating-label">
                                    <input type="text" placeholder=" " class="form-control" name="return_quantity" [(ngModel)]="return_quantity">
                                    <span class="highlight"></span>
                                    <label>No. of Products</label>
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="order_details.parentchild == 'yes'">
                            <div class="col-lg-12" *ngFor="let ch of order_details.parentchild_records">
                                <div class="floating-label">
                                    <input type="text" placeholder=" " class="form-control" name="return_quantity" [(ngModel)]="ch.return_qty">
                                    <span class="highlight"></span>
                                    <label>{{ ch.name }} - No. of Products</label>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="!order_details.return_request">
                            <div class="col-lg-12 ">
                                <div class="floating-label">
                                    <input type="file" placeholder=" " class="form-control" name="return_images" multiple (change)="imageUpload($event)">
                                    <span class="highlight"></span>
                                    <label>Products Images</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 ">
                                <div class="floating-label">
                                    <input type="text" placeholder=" " class="form-control" name="return_reason" [(ngModel)]="return_reason">
                                    <span class="highlight"></span>
                                    <label>Reason for Cancellation</label>
                                </div>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-lg-12 ">
                                <div class="floating-label">
                                    <textarea placeholder="" name="return_message" [(ngModel)]="return_message" class="form-control txtareaht80"></textarea>
                                    <span class="highlight"></span>
                                    <label>Message to Seller</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="client-thumbnails dtlthmb odimgsz text-center img-option-list" *ngIf="order_details.return_request">
                        <ngx-slick-carousel class="carousel thumbs" #slickModal="slick-carousel"
                            [config]="thumbnailsSlider ">
                            <div ngxSlickItem *ngFor="let slide of return_updated_image" class="slide">
                                <a class="d-inline-block w-100" href="javascript:void(0);">
                                    <img src="{{ slide.img }}" alt="">
                                </a>
                                <!-- <p class="fs12 txtseconday mb-0">Front image*</p> -->
                            </div>
                        </ngx-slick-carousel>
                    </div>
                    <div class="text-right" *ngIf="!order_details.return_request">
                        <a href="javascript:void(0);" class="btn buybtn-success fs16 radius10 ripple mr-2" (click)="submitreturn()">Submit</a>
                        <a href="javascript:void(0);" class="btn-d-outline btn-danger-outline d-inline-block ml-sm-3 ml-2" (click)="modal.dismiss('Cross click')">Cancel</a>
                    </div>
                    <div class="text-right" *ngIf="order_details.return_request">
                        <a href="javascript:void(0);" class="btn-d-outline btn-danger-outline d-inline-block ml-sm-3 ml-2" (click)="modal.dismiss('Cross click')">Cancel</a>
                    </div>
                </div>
            </div>
            <div class="col-sm-5 text-center" *ngIf="order_details.return_request">
                <div class="feedback-slides  ">
                    <div class="client-feedback dtlfull">
                        <ngx-slick-carousel class="carousel feedback" #slickModal="slick-carousel"
                            [config]="imagesSlider">
                            <div ngxSlickItem *ngFor="let slide of return_updated_image" class="slide ">
                                <div class="thmbnaildtl">
                                    <img src="{{ slide.img }}" alt="" width="100%">
                                </div>
                            </div>
                        </ngx-slick-carousel>
                    </div>
                </div>
                <p class="fs10 txtseconday pt-3">Image Preview</p>
            </div>
        </div>
    </div>

</ng-template>
<!-- /RETURN REQUEST -->