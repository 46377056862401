import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscribersListService {

    constructor(private _http: HttpClient) { }

    getsubscribers(data) {
        return this._http.post(environment.api_url+'admin/subscriber/list',data);
    }

}
