import { Component, OnInit } from '@angular/core';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { ProductReviewService } from './review-moderation.service';

@Component({
  selector: 'app-review-moderation',
  templateUrl: './review-moderation.component.html',
  styleUrls: ['./review-moderation.component.scss']
})
export class ReviewModerationComponent implements OnInit {

    public product_records: any = [];
    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 20;
    public product_pagination: any = {};

    public page_per_view_options = [
        {
            value: 20,
            name: 20
        },
        {
            value: 40,
            name: 40
        }
    ];
    constructor(
        private _productreviews: ProductReviewService,
        private _toastr: ToasterService,
        private _pagination: PaginationService
    ) { }

    ngOnInit(): void {
        this.getproductreviews();
    }

    getproductreviews() {
        this._productreviews.getproductreviews({ limit: this.page_per_view, offset: this.offset, current_page: this.current_page }).subscribe((data) => {
            if (data['status'] == "success") {
                this.product_records = data['records'];
                let total_records = data['total_records'];
                this.product_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.getproductreviews();
    }

    setpageperview() {
        this.current_page = 1;
        this.getproductreviews();
    }

}
