<app-header-seller></app-header-seller>
<app-sidebar-seller></app-sidebar-seller>
<div class="wrpmain">
    <div class="container">
        <!--- HEAD  --->
        <div class="row align-items-center mb-4 pinfo-head">
            <div class="col-sm-6 col-7 pr-0">
                <nav class="breadcrumb breadcrumb-dui mb-0">
                    <ol class="breadcrumb p-0 bg-transparent  mb-0">
                        <li class="breadcrumb-item">
                            <a class="txtseconday" href="javascript:void(0);">Orders</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a class="txtseconday" [routerLink]="['/bidding-orders']">Bidding Orders</a>
                        </li>
                        <li class="breadcrumb-item active">Bidding Orders</li>
                    </ol>
                </nav>
            </div>
            <div class="col-sm-6 col-5 text-right pl-0">
                <a href="javascript:void(0);" [routerLink]="['/seller/assistance']" class="btn buybtn fs16 radius10">Assistance</a>
            </div>
        </div>
        <!--- /HEAD  --->
        <h3 class="f-700 fs22 txtdefault pb-20"><a href="javascript:;" class="go-back"><i
                    class="fa fa-angle-left"></i></a> View Bids</h3>

        <div class="row mb-40">
            <div class="col-md-12">
                <div class="bid-order-info">
                    <div class="order-img">
                        <img [src]="basic_info.image" />
                    </div>
                    <div class="pd-seller-cntnt">
                        <h5 class="mb-10">{{ basic_info.name }}</h5>
                        <p>{{ basic_info.variant_txt }}</p>

                        <div class="row mt-30 product-data">
                            <div class="col-md-3">
                                <b>Minimum Order Quantity:</b>
                                <div>{{ basic_info.min_order_qty }}</div>
                            </div>
                            <div class="col-md-3">
                                <b>Total No. of Bids:</b>
                                <div>{{ basic_info.total_bids }}</div>
                            </div>
                            <div class="col-md-3">
                                <b>Price Per Unit:</b>
                                <div>{{ basic_info.price_per_unit }}</div>
                            </div>
                            <div class="col-md-3">
                                <b>Maximum Bid Amount/Unit:</b>
                                <div>{{ basic_info.max_bid_received }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-ui card-uiovt byregwrp">
            <div class="table-responsive">
                <table class="table custom-table v-align-middle">
                    <thead>
                        <tr>
                            <th>Bid ID</th>
                            <th>Time of Bid</th>
                            <th>Order Quantity <i class="long-arrow-down"></i></th>
                            <th>Bid Received</th>
                            <th>
                                <i placement="top"
                                    ngbTooltip="This will act as a default filter every time you will toggle.">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                                        <g id="Group_68" data-name="Group 68" transform="translate(-545 -281)">
                                            <circle id="Ellipse_1" data-name="Ellipse 1" cx="7.5" cy="7.5" r="7.5"
                                                transform="translate(545 281)" fill="#07f" />
                                            <path id="Path_2235" data-name="Path 2235"
                                                d="M7,8.171l-.128.523q-.576.227-.919.346a2.425,2.425,0,0,1-.8.119,1.591,1.591,0,0,1-1.084-.34,1.1,1.1,0,0,1-.387-.864,3.056,3.056,0,0,1,.029-.416q.029-.213.093-.48l.48-1.7q.064-.244.108-.462a2.01,2.01,0,0,0,.044-.4A.616.616,0,0,0,4.3,4.047a.759.759,0,0,0-.513-.128,1.345,1.345,0,0,0-.381.058c-.131.039-.242.076-.336.11L3.2,3.564q.471-.192.9-.329A2.7,2.7,0,0,1,4.916,3.1a1.552,1.552,0,0,1,1.068.335,1.111,1.111,0,0,1,.375.869c0,.074-.008.2-.026.39a2.606,2.606,0,0,1-.1.512L5.759,6.9a4.492,4.492,0,0,0-.1.466,2.4,2.4,0,0,0-.047.4.57.57,0,0,0,.15.459.85.85,0,0,0,.523.122,1.509,1.509,0,0,0,.4-.061A2.21,2.21,0,0,0,7,8.171Zm.121-7.1a.99.99,0,0,1-.334.753,1.138,1.138,0,0,1-.8.312,1.15,1.15,0,0,1-.809-.312.988.988,0,0,1-.337-.753A1,1,0,0,1,5.169.314a1.194,1.194,0,0,1,1.614,0A1,1,0,0,1,7.117,1.07Z"
                                                transform="translate(547.406 283.92)" fill="#fff" />
                                        </g>
                                    </svg>
                                </i>&nbsp; Price Difference <i class="long-arrow-down"></i>
                            </th>
                            <th width="35%">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- REPEAT -->
                        <tr *ngFor="let record of product_records; let i = index;">
                            <td>{{ record.bid_unique_id }}</td>
                            <td>{{ record.bid_date }}</td>
                            <td>{{ record.order_qty }}</td>
                            <td>{{ record.bid_received_text }}</td>
                            <td *ngIf="record.price_difference < 1">-₹ {{ record.base_price - record.bid_received }}
                            </td>
                            <td *ngIf="record.price_difference > 0">₹ {{ record.bid_received - record.base_price}}</td>
                            <td>
                                <div class="d-flex align-items-center">
                                    <a href="javascript:;" *ngIf="record.status == 0"
                                        class="btn-d-outline-success ripple d-inline-block place-bid mr-2"
                                        (click)="openaccept(contentaccept, record)">Accept Bid</a>
                                    <a href="javascript:;" *ngIf="record.status == 0" (click)="open(content, record)"
                                        class="btn-d-outline ripple d-inline-block place-bid mr-2">Counter Offer</a>
                                    <a href="javascript:;" *ngIf="record.status == 0"
                                        (click)="openaccept(contentdecline, record)"
                                        class="btn-d-outline ripple d-inline-block place-bid mr-2">Decline</a>
                                </div>
                                <div class="d-flex align-items-center">
                                    <a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid"
                                        *ngIf="record.status == 1"
                                        [routerLink]="['/seller/view-order-details/'+record.order_meta_id]">View
                                        Order</a>
                                    <a href="javascript:;" class="link-2 color-orange"
                                        *ngIf="record.status == 2">Declined</a>
                                </div>
                            </td>
                        </tr>
                        <!-- /REPEAT -->
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="text-left pl-3">
                    <nav class="mt-3 tbpg rows-per-page">
                        <div>Rows per page:</div>
                        <select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
                            <option *ngFor="let option of page_per_view_options" [ngValue]="option.value">
                                {{option.name}}</option>
                        </select>
                    </nav>
                </div>
            </div>
            <div class="col-md-6" *ngIf="product_pagination.pages && product_pagination.pages.length">
                <div class="text-right md-text-left pr-3">
                    <nav class="mt-3 tbpg">
                        <ul class="pagination align-items-center">
                            <li class="page-item" [ngClass]="{disabled:product_pagination.currentPage === 1}"><a
                                    class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a></li>
                            <li class="page-item" [ngClass]="{disabled:product_pagination.currentPage === 1}"><a
                                    class="page-link sprevicon" href="javascript:;"
                                    (click)="setpage(product_pagination.currentPage - 1)"></a></li>
                            <li class="page-item" i *ngFor="let page of product_pagination.pages"
                                [ngClass]="{active:product_pagination.currentPage === page}"><a class="page-link"
                                    href="javascript:;" (click)="setpage(page)">{{page}}</a></li>
                            <li *ngIf="(product_pagination.totalPages - product_pagination.startPage) > 5 ">
                                <a (click)="setpage(product_pagination.startPage + 5)">...</a>
                            </li>
                            <li *ngIf="(product_pagination.totalPages - product_pagination.startPage) > 5">
                                <a
                                    (click)="setpage(product_pagination.totalPages)">{{product_pagination.totalPages}}</a>
                            </li>
                            <li class="page-item"
                                [ngClass]="{disabled:product_pagination.currentPage === product_pagination.totalPages}">
                                <a class="page-link snexticon" href="javascript:;"
                                    (click)="setpage(product_pagination.currentPage + 1)"></a>
                            </li>
                            <li class="page-item"
                                [ngClass]="{disabled:product_pagination.currentPage === product_pagination.totalPages}">
                                <a class="page-link nexticon" href="javascript:;"
                                    (click)="setpage(product_pagination.totalPages)"></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- PLACE BID MODAL -->
<ng-template #content let-modal>
    <button type="button" class="close closemodal" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="mb-3 mt-2">
            <div class="pd-seller-cntnt">
                <div class="txtdefault mb-2 mdl-pd-sellername">
                    <span class="seller-name-tk">Bid ID : {{ selected_record.bid_unique_id }}</span>
                </div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-md-7">
                <div class="mdl-crd">
                    <div class="row mb-3">
                        <p class="col-7 col-md-7 mb-0 fs14 txtseconday">
                            Order Quantity:
                        </p>
                        <label class="col-5 col-md-5 mb-0 text-right fs14 txtdefault">
                            {{ selected_record.order_qty }}
                        </label>
                    </div>
                    <div class="row mb-3">
                        <p class="col-7 col-md-7 fs14 mb-0  txtseconday">
                            Bid Received:
                        </p>
                        <label class="col-5 col-md-5 fs14 mb-0 text-right txtdefault">
                            {{ selected_record.bid_received }}
                        </label>
                    </div>
                    <div class="row">
                        <p class="col-7 col-md-7 fs14 mb-0  txtseconday">
                            Price Difference: <i placement="top"
                                ngbTooltip="This will act as a default filter every time you will toggle.">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                                    <g id="Group_68" data-name="Group 68" transform="translate(-545 -281)">
                                        <circle id="Ellipse_1" data-name="Ellipse 1" cx="7.5" cy="7.5" r="7.5"
                                            transform="translate(545 281)" fill="#07f" />
                                        <path id="Path_2235" data-name="Path 2235"
                                            d="M7,8.171l-.128.523q-.576.227-.919.346a2.425,2.425,0,0,1-.8.119,1.591,1.591,0,0,1-1.084-.34,1.1,1.1,0,0,1-.387-.864,3.056,3.056,0,0,1,.029-.416q.029-.213.093-.48l.48-1.7q.064-.244.108-.462a2.01,2.01,0,0,0,.044-.4A.616.616,0,0,0,4.3,4.047a.759.759,0,0,0-.513-.128,1.345,1.345,0,0,0-.381.058c-.131.039-.242.076-.336.11L3.2,3.564q.471-.192.9-.329A2.7,2.7,0,0,1,4.916,3.1a1.552,1.552,0,0,1,1.068.335,1.111,1.111,0,0,1,.375.869c0,.074-.008.2-.026.39a2.606,2.606,0,0,1-.1.512L5.759,6.9a4.492,4.492,0,0,0-.1.466,2.4,2.4,0,0,0-.047.4.57.57,0,0,0,.15.459.85.85,0,0,0,.523.122,1.509,1.509,0,0,0,.4-.061A2.21,2.21,0,0,0,7,8.171Zm.121-7.1a.99.99,0,0,1-.334.753,1.138,1.138,0,0,1-.8.312,1.15,1.15,0,0,1-.809-.312.988.988,0,0,1-.337-.753A1,1,0,0,1,5.169.314a1.194,1.194,0,0,1,1.614,0A1,1,0,0,1,7.117,1.07Z"
                                            transform="translate(547.406 283.92)" fill="#fff" />
                                    </g>
                                </svg>
                            </i>
                        </p>
                        <label class="col-5 col-md-5 fs14 mb-0 text-right txtdefault"
                            *ngIf="selected_record.price_difference < 1">-₹ {{selected_record.base_price -
                            selected_record.bid_received }}</label>
                        <label class="col-5 col-md-5 fs14 mb-0 text-right txtdefault"
                            *ngIf="selected_record.price_difference > 0">₹ {{selected_record.bid_received -
                            selected_record.base_price }}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-11">
                <div class="input-group plcbidinpt mb-3">
                    <input type="text" class="form-control radius10 txtseconday" placeholder="Enter the Bid Amount"
                        [(ngModel)]="update_bid_amount">
                    <div class="input-group-append pl-2">
                        <button class="buybtn d-inline-block px-4 radius10" type="button"
                            [disabled]="!update_bid_amount" (click)="savecounteroffer()">Place Counter Offer</button>
                    </div>
                </div>
                <p class="fs12 txtdefault">Please Enter {{ selected_record.bid_received }} or more</p>
            </div>
        </div>
    </div>

    <div class="modal-footer justify-content-center">
        <p class="txtdefault fs16 text-center"><a class=" txtactive warm-link" href="javascript:void(0);">
                <i placement="top" ngbTooltip="This will act as a default filter every time you will toggle.">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                        <g id="Group_68" data-name="Group 68" transform="translate(-545 -281)">
                            <circle id="Ellipse_1" data-name="Ellipse 1" cx="7.5" cy="7.5" r="7.5"
                                transform="translate(545 281)" fill="#07f" />
                            <path id="Path_2235" data-name="Path 2235"
                                d="M7,8.171l-.128.523q-.576.227-.919.346a2.425,2.425,0,0,1-.8.119,1.591,1.591,0,0,1-1.084-.34,1.1,1.1,0,0,1-.387-.864,3.056,3.056,0,0,1,.029-.416q.029-.213.093-.48l.48-1.7q.064-.244.108-.462a2.01,2.01,0,0,0,.044-.4A.616.616,0,0,0,4.3,4.047a.759.759,0,0,0-.513-.128,1.345,1.345,0,0,0-.381.058c-.131.039-.242.076-.336.11L3.2,3.564q.471-.192.9-.329A2.7,2.7,0,0,1,4.916,3.1a1.552,1.552,0,0,1,1.068.335,1.111,1.111,0,0,1,.375.869c0,.074-.008.2-.026.39a2.606,2.606,0,0,1-.1.512L5.759,6.9a4.492,4.492,0,0,0-.1.466,2.4,2.4,0,0,0-.047.4.57.57,0,0,0,.15.459.85.85,0,0,0,.523.122,1.509,1.509,0,0,0,.4-.061A2.21,2.21,0,0,0,7,8.171Zm.121-7.1a.99.99,0,0,1-.334.753,1.138,1.138,0,0,1-.8.312,1.15,1.15,0,0,1-.809-.312.988.988,0,0,1-.337-.753A1,1,0,0,1,5.169.314a1.194,1.194,0,0,1,1.614,0A1,1,0,0,1,7.117,1.07Z"
                                transform="translate(547.406 283.92)" fill="#fff" />
                        </g>
                    </svg>
                </i> How Counter Offer Works?</a></p>
    </div>
</ng-template>
<!-- /PLACE BID MODAL -->

<!-- ACCEPT BID MODAL -->
<ng-template #contentaccept let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>

    <div class="modal-body pb-3">
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead">
                    <h4 class="txtdefault text-center f-700 fs18 mb-20">Accept a Bid</h4>
                </div>
                <div class="mb-10 f-600 fs10"><i placement="top"
                        ngbTooltip="This will act as a default filter every time you will toggle." class="mr-2"> <img
                            src="/assets/images/icons/info.png"></i> After accepting the offer, your new total listed
                    quantity will be {{ selected_bid.total_listed_qty }}. This will change across all Buyers. <a
                        href="javascript:;" class="link-alt fs10">Update Stock</a></div>

                <div class="accept-bid table-card">
                    <table class="table custom-table xs mb-0">
                        <thead>
                            <tr>
                                <th>Bid Id</th>
                                <th>Order Qty</th>
                                <th>Bid Price</th>
                                <th>Supply Location</th>
                                <th>Total Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ selected_bid.bid_unique_id }}</td>
                                <td>{{ selected_bid.order_qty }}</td>
                                <td>{{ selected_bid.bid_price }}</td>
                                <td>{{ selected_bid.supply_location }}</td>
                                <td><b>{{ selected_bid.total_amount }}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="text-left mt-10">
                    <div class="text-muted mb-10">Are you sure you want to accept this Bid?</div>
                    <div class="d-flex align-items-center">
                        <a class="btn-d-outline d-inline-block place-bid mr-2" href="javascript:void(0);"
                            (click)="open(content, selected_record)">
                            Counter Offer
                        </a>
                        <a href="javascript:void(0);" class="btn-d-outline-success ripple d-inline-block place-bid2"
                            (click)="acceptbid()">
                            Accept Bid
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead text-center">
                    <h4 class="txtdefault wt700 fs16 mb-10">Net Receivable Amount</h4>
                </div>

                <div class="inrcard p-3 radius10 position-relative">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row d-flex justify-content-between">
                                <div class="col-6 col-md-6 card-price-info">Net Goods Value:</div>
                                <div class="col-6 col-md-6 card-price-info text-right">{{ selected_bid.breakdown.without_gst
                                    }}</div>
                            </div>
                            <div class="row d-flex justify-content-between">
                                <div class="col-6 col-md-6 card-price-info">GST:</div>
                                <div class="col-6 col-md-6 card-price-info text-right">{{ selected_bid.breakdown.gst_amount }}
                                </div>
                            </div>
                            <div class="row d-flex justify-content-between" *ngIf="selected_bid.is_subseller=='no'">
                                <div class="col-6 col-md-6 card-price-info">Convenience Charges:</div>
                                <div class="col-6 col-md-6 card-price-info text-right">{{ selected_bid.breakdown.convience_fee
                                    }}</div>
                            </div>
                            <div class="row d-flex justify-content-between" *ngIf="selected_bid.is_subseller=='no'">
                                <div class="col-6 col-md-6 card-price-info">Logistics Charges:</div>
                                <div class="col-6 col-md-6 card-price-info text-right">{{ selected_bid.breakdown.logistics_fee
                                    }}</div>
                            </div>
                            <div class="row d-flex justify-content-between" *ngIf="selected_bid.is_subseller=='no'">
                                <div class="col-6 col-md-6 card-price-info">TCS:</div>
                                <div class="col-6 col-md-6 card-price-info text-right">{{ selected_bid.breakdown.tcs_amount }}
                                </div>
                            </div>
                            <div class="row d-flex justify-content-between" *ngIf="selected_bid.is_subseller == 'no'">
                                <div class="col-6 col-md-6 card-price-info">TDS:</div>
                                <div class="col-6 col-md-6 card-price-info text-right">{{ selected_bid.breakdown.tds_amount }}
                                </div>
                            </div>
                            <!-- <div class="row d-flex justify-content-between">
							<div class="col-6 col-md-6 card-price-info">Coupon Discount:</div>
							<div class="col-6 col-md-6 card-price-info text-right text-active">- {{ discount_amount }}</div>
						</div> -->
                            <hr class="mt-1 mb-1">
                            <div class="row d-flex justify-content-between">
                                <div class="col-6 col-md-6 card-price-info f-700">Net Realization Value:</div>
                                <div class="col-6 col-md-6 card-price-info text-right f-700">{{
                                    selected_bid.breakdown.net_realization }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row align-items-center">
            <div class="col-md-12">
                <div class="text-center fs10">
                    By accepting a bid, you are agreeing to sell this item and will be required to sell within Dispatch
                    date. The buyer has 24 hours to pay the full amount.
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- /ACCEPT BID MODAL -->

<!-- Decline BID MODAL -->
<ng-template #contentdecline let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>

    <div class="modal-body pb-3">
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead">
                    <h4 class="txtdefault text-center wt700 fs22 mb-20">Decline a Bid</h4>
                </div>
                <!-- <div class="mb-10 f-600 fs10">
				<i placement="top" ngbTooltip="This will act as a default filter every time you will toggle." class="mr-2">
				<img src="/assets/images/icons/info.png"></i>
				After accepting the offer, your new total listed quantity will be {{ selected_bid.total_listed_qty }}. This will change across all Buyers.
				<a href="javascript:;" class="link-alt fs10">Update Stock</a>
			</div> -->

                <div class="accept-bid">
                    <table class="table custom-table xs mb-0">
                        <thead>
                            <tr>
                                <th>Bid Id</th>
                                <th>Order Qty</th>
                                <th>Bid Price</th>
                                <th>Supply Location</th>
                                <th>Total Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ selected_bid.bid_unique_id }}</td>
                                <td>{{ selected_bid.order_qty }}</td>
                                <td>{{ selected_bid.bid_price }}</td>
                                <td>{{ selected_bid.supply_location }}</td>
                                <td><b>{{ selected_bid.total_amount }}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="text-left mt-30">
                    <div class="text-muted mb-10">Are you sure you want to decline this Bid?</div>
                    <div class="d-flex align-items-center">
                        <a class="btn-d-outline d-inline-block place-bid mr-2" href="javascript:void(0);"
                            (click)="open(content, selected_record)">
                            Counter Offer
                        </a>
                        <a href="javascript:void(0);" class="btn-d-outline-success ripple d-inline-block place-bid2"
                            (click)="declinebid()">
                            Decline Bid
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <!-- <div class="row align-items-center">
		<div class="col-md-12">
			<div class="text-center fs10">
				By accepting a bid, you are agreeing to sell this item and will be required to sell within Dispatch date. The buyer has 24 hours to pay the full amount.
			</div>
		</div>
	</div> -->
    </div>
</ng-template>
<!-- /ACCEPT BID MODAL -->

<app-footer-seller></app-footer-seller>