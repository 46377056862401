import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { ViewBidService } from './viewbids.service';

@Component({
  selector: 'app-view-bids',
  templateUrl: './view-bids.component.html',
  styleUrls: ['./view-bids.component.scss']
})
export class ViewBidsComponent implements OnInit {

    closeResult = '';
    public update_bid_amount:number;
    public product_id:string;
    public basic_info:any = {};
    public selected_bid:any = {};
    public selected_record:any = {};
    public product_records:any = [];
    public current_page:number = 1;
    public offset:number = 0;
    public page_per_view:number = 10;
    public product_pagination:any = {};
    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];
    constructor(
        private modalService: NgbModal,
        private _toastr: ToasterService,
        private _viewbidservice: ViewBidService,
        private _pagination: PaginationService,
        private _route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.product_id = this._route.snapshot.paramMap.get('product_id');
        this.getproductbids();
    }

    getproductbids(){
        let limit = this.page_per_view;
        let offset = this.offset;
        let current_page = this.current_page;
        this._viewbidservice.getproductbid({limit: limit, offset: offset, current_page: current_page, product_id: this.product_id}).subscribe((data) => {
            if(data['status'] == "success"){
                this.basic_info = data['basic'];
                this.product_records = data['records'];
                let total_records = data['total_records'];
                let pages = Math.ceil(total_records/this.page_per_view);
                this.product_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
            }else if(data['status'] == "error"){
              this._toastr.error(data['message']);
            }
        });
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page-1)*this.page_per_view;
        this.getproductbids();
    }

    setpageperview(){
        this.current_page = 1;
        this.getproductbids();
    }

    open(content, record) {
        this.modalService.dismissAll();
        this.selected_bid = record;
        this.selected_record = record;
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    openaccept(contentaccept, record) {
        this.modalService.dismissAll();
        this._viewbidservice.getbidinfo({bid_id: record.bid_id}).subscribe((data) => {
            if(data['status'] == "success"){
                this.selected_bid = data['record'];
                this.selected_record = record;
                this.modalService.open(contentaccept, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    acceptbid(){
        this._viewbidservice.acceptbid({bid_id: this.selected_bid.bid_id}).subscribe((data) => {
            if(data['status'] == "success"){
                this.modalService.dismissAll();
                this._toastr.success('Bid offer has been accepted.');
                this.getproductbids();
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    declinebid(){
        this._viewbidservice.declinebid({bid_id: this.selected_bid.bid_id}).subscribe((data) => {
            if(data['status'] == "success"){
                this.modalService.dismissAll();
                this._toastr.success('Bid offer has been declined.');
                this.getproductbids();
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    savecounteroffer(){
        this._viewbidservice.savecounteroffer({bid_id: this.selected_bid.bid_id, amount: this.update_bid_amount}).subscribe((data) => {
            if(data['status'] == "success"){
                this.modalService.dismissAll();
                this._toastr.success('Counter offer has been submitted.');
                this.getproductbids();
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }
}
