import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { BidOffersService } from './bid-offers.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductCategoryService } from 'src/app/admin/product-category/product-category.service';
import { AddListingService } from 'src/app/seller/add-listing/addlisting.service';
import { ProductDetailsService } from '../product-details/product-details.service';
import { MybidsService } from '../my-bids/my-bids.service';

@Component({
    selector: 'app-bids-offers',
    templateUrl: './bids-offers.component.html',
    styleUrls: ['./bids-offers.component.scss']
})
export class BidsOffersComponent implements OnInit {

    public closeResult = '';
    public last_bid_record: any = {};
    public product_id: string;
    public product_detail: any = {};
    public bid_records: any = [];
    public mybid_records: any = [];
    public bid_edit_type = 'quantity';
    public selected_delivery: string = 'standard';
    public selected_product_id:string = '';
    public bid_quantity: number;
    public supply_location:string;
    public modalInsuranceReference:any;
    public enable_insurance: boolean = false;
    public selected_bid: any = {};
    public bid_view_history = false;
    public bid_history_records: any = [];
    public last_traded_records: any = [];
    public watchlist_records:any = [];
    public updated_quantity: number;
    public updated_price: number;
    keyword = 'name';
    public selected_category: string = '';
    public search_term:string = '';

    public express_records:any = [];
    public standard_city_records:any = [];
    public standard_outsidecity_records:any = [];
    constructor(
        private modalService: NgbModal,
        private _bidofferservice: BidOffersService,
        private _toastr: ToasterService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _editproductcategoryservice: ProductCategoryService,
        private _addlistingservice: AddListingService,
        private _productdetailsservice: ProductDetailsService,
        private _mybidsservice: MybidsService
    ) { }

    @ViewChild('tabset') tabset;

    ngOnInit(): void {
        this.product_id = this._route.snapshot.paramMap.get('product_id');
        if (this._bidofferservice.bid_placed && this._bidofferservice.bid_id) {
            this.getlastbiddetails(this._bidofferservice.bid_id);
        }
        this.getproductbids();
        setTimeout( () => {
            this.getmybids();
        }, 1000);
    }

    getmybids() {
        this._mybidsservice.getmybids({search: this.search_term}).subscribe((data) => {
            if (data['status'] == "success") {
                this.mybid_records = data['records'];
                this.watchlist_records = [];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    changedelivery(type) {
        this.selected_delivery = type;
    }

    changesupplylocation(index){
        this.supply_location = index;
    }

    placebid() {
        this._productdetailsservice.savebiddetails({insurance: this.enable_insurance, logistics_type: this.selected_delivery, supply_location: this.supply_location, sp_id: this.selected_bid.sp_id, amount: this.selected_bid.bid_amount, quantity: this.bid_quantity }).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this._bidofferservice.bid_placed = true;
                this._bidofferservice.bid_id = data['bid_id'];
               this.getproductbids();
            } else if (data['status'] == "error") {
                //this.modalService.dismissAll();
                this._toastr.error(data['message']);
            }
        });
    }

    openinsurance(contentinsurance, type = 'button') {
        if (this.enable_insurance && type == 'button') {
            this.enableinsurance('no', contentinsurance);
        } else {
            this.modalInsuranceReference = this.modalService.open(contentinsurance, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, }).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }

    enableinsurance(type = 'no', contentinsurance) {
        if(type == 'no'){
            this.enable_insurance = false;
        }else{
            this.enable_insurance = true;
        }
        //this.modalService.dismissAll();
    }

    openlogistics(content) {
        this.modalService.open(content, {
            ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, windowClass: 'modal-ui'
        }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    getlogisticscharges() {
        this._productdetailsservice.getlogisticscharges(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.express_records = data['expressrecord'];
                this.standard_city_records = data['standardwithincity'];
                this.standard_outsidecity_records = data['standardoutsidecity'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    changebillingaddress(){
        this.modalService.dismissAll();
        this._router.navigate(['/buyer/business-details']);
    }

    getlastbiddetails(bid_id) {
        this._bidofferservice.getbiddetail({ bid_id: bid_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.last_bid_record = data['record'];
                document.getElementById('thankyoumodal').click();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    gotodeal() {
        this.modalService.dismissAll();
        this._router.navigate(['/deal/' + this.last_bid_record.sp_id + '/bulk'])
    }

    getproductbids() {
        this._bidofferservice.getproductbidlisting({ product_id: this.product_id, category: this.selected_category }).subscribe((data) => {
            if (data['status'] == "success") {
                this.bid_records = data['records'];
                this.product_detail = data['basic'];
                //this.mybid_records = data['mybidrecords'];
                this.getlogisticscharges();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    open(content, bid_id) {
        this._bidofferservice.gettradedprice({ bid_id: bid_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.last_traded_records = data['records'];
                this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    openplaceabid(content, listing) {
        this._productdetailsservice.getbiddetails({ sp_id: listing.sp_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.selected_product_id = this.product_id;
                this.selected_bid = data['record'];
                this.selected_bid.bid_amount = '';
                this.modalService.dismissAll();
                this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    changebidedit(type) {
        this.bid_edit_type = type;
    }

    acceptcounteroffer() {
        this._bidofferservice.acceptcounteroffer({ bid_id: this.selected_bid.bid_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Counter offer accepted successfully.');
                this.modalService.dismissAll();
                this.getproductbids();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    viewcounteroffer(content, record) {
        this.selected_bid = record;
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }


    openCounterOffer(content2, record, type) {
        this.bid_edit_type = type;
        this._bidofferservice.editbiddetails({ bid_id: record.bid_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.selected_bid = data['basic'];
                this.bid_history_records = data['records'];
                this.modalService.open(content2, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    openthankyou(content3) {
        this.modalService.open(content3, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    deletebid(record) {
        this._bidofferservice.deletebid({ bid_id: record.bid_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Bid record has been cancelled successfully.');
                this.getproductbids();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    showhistory() {
        this.bid_view_history = !this.bid_view_history;
    }

    updatebid(type) {
        if (type == 'price' && !this.updated_price) {
            this._toastr.error('Please enter bid price.');
            return false;
        } else if (type == 'quantity' && !this.updated_quantity) {
            this._toastr.error('Please enter bid quantity.');
            return false;
        }
        var value = type == 'price' ? this.updated_price : this.updated_quantity;
        this._bidofferservice.updatebid({ type: type, value: value, bid_id: this.selected_bid.bid_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.updated_price = null;
                this.updated_quantity = null;
                this.modalService.dismissAll();
                this.last_bid_record = data['record'];
                document.getElementById('thankyoumodal').click();
                this.getproductbids();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    result = [];
    presult = [];

    selectEvent(item) {
        this.selected_category = item.c_id;
        this.tabset.select('allBids');
        this.getproductbids();
    }

    onChangeSearch(val: string) {
        this.selected_category = val;
        this._editproductcategoryservice.searchcategory({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.result = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    onFocused(e) {
        // do something when input is focused
    }

    pselectEvent(item) {
        this._router.navigate(['/buyer/bids-offers/' + item.product_id]);
    }

    ponChangeSearch(val: string) {
        this._addlistingservice.productsearch({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.presult = data['searchResult'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    ponFocused(e) {
        // do something when input is focused
    }
}
