import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { JoincfService } from './join-a-candf.service';

@Component({
    selector: 'app-join-a-candf',
    templateUrl: './join-a-candf.component.html',
    styleUrls: ['./join-a-candf.component.scss']
})
export class JoinACandfComponent implements OnInit {

    public show_superseller: boolean = false;
    public supersellers_records: any = [];
    public selected_seller: any = {};
    public selected_superseller_id: string;
    closeResult = '';
    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 20;
    public joincf_pagination: any = {};
    public candf_basic_info:any = {};
    public page_per_view_options = [
        {
            value: 20,
            name: 20
        },
        {
            value: 40,
            name: 40
        }
    ];

    constructor(
        private modalService: NgbModal,
        private _joincfservice: JoincfService,
        private _pagination: PaginationService,
        private _toastr: ToasterService,
    ) { }

    ngOnInit(): void {
        this.getsellerdetails();
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.showsuperseller();
    }

    setpageperview() {
        this.current_page = 1;
        this.showsuperseller();
    }

    getsupersellers() {
        this.page_per_view = 10;
        this.offset = 0;
        this.current_page = 1;
        this.showsuperseller();
    }

    showsuperseller() {
        this._joincfservice.getsupersellers({ limit: this.page_per_view, offset: this.offset, current_page: this.current_page }).subscribe((data) => {
            if (data['status'] == "success") {
                this.supersellers_records = data['records'];
                let total_records = data['total_records'];
                this.joincf_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
                this.show_superseller = true;
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    opensuperseller(content, record) {
        this.selected_seller = record;
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    openWindowCustomClass(content) {
        this.modalService.open(content, { windowClass: 'modal-500' });
    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    applysuperseller() {
        this._joincfservice.applysuperseller(null).subscribe((data) => {
            this.modalService.dismissAll();
            if (data['status'] == "success") {
                this.show_superseller = false;
                this.getsellerdetails();
                this._toastr.success('Your request has been successfully sent to admin.');
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    applysubseller() {
        this._joincfservice.applysubseller({ seller_id: this.selected_seller.id }).subscribe((data) => {
            this.modalService.dismissAll();
            if (data['status'] == "success") {
                this.show_superseller = false;
                this.getsellerdetails();
                this._toastr.success('Your request has been successfully sent to Super Seller.');
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getsellerdetails() {
        this._joincfservice.getsellerdetails(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.candf_basic_info = data['basicinfo'];
            } else if (data['status'] == "error") {
            }
        });
    }

}
