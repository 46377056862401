

<section class="w-h bg-blue pt-0">
  <div class="equibiz-header">
      <div class="logo-bar">
        <div class="container">
          <div class="row d-flex justify-content-center">
             <div class="col-md-12 text-center">
                <a [routerLink]="['/home']"><img src="assets/images/svg/eq_logo.svg"></a>
             </div>
          </div>
        </div>
      </div>
    </div>
  <div class="container">
    <!--Start admin card login-->
    <div class="admin-login popup-container">
      <div class="modal-body text-center" *ngIf="loginModal">
          <h5 class="modal-title mb-20">Login</h5>
          <div class="modal-subtitle">Entered the registered Email ID or Phone Number to Login</div>
          <form [formGroup]="loginForm">
            <div class="form-container">
                <div class="floating-label mb-0">      
                    <input class="form-control" type="text" placeholder="" name="uniqueId" formControlName="uniqueId" [(ngModel)]="uniqueId">
                    <span class="highlight"></span>
                    <label>Phone Number or Email ID</label>
                    <span class="help-block" *ngIf="l.uniqueId.invalid && l.uniqueId.touched">Enter valid Phone number or Email ID</span>
                </div>
            </div>   
            <div class="cta mt-20">
                <button class="btn btn-mod btn-color btn-medium btn-round ripple btn-block" (click)="phoneLoginFormSubmit()" [disabled]="loginForm.invalid || buttonLoading"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="buttonLoading"></span>&nbsp;&nbsp;Login</button>
            </div>
            <div class="cta mt-40" align="center">
              <div class="pt-0"><a href="javascript:;" class="resend" (click)=showLoginPasswordModal() *ngIf="uniqueId">Login with Password</a></div>
            </div>
          </form>
      </div>

      <!--OTP Container-->
      <div class="modal-body text-center" *ngIf="loginOTPModal">
          <h5 class="modal-title mb-10" *ngIf="this.signupType == 'mobile'">Verify Mobile Number</h5>
          <h5 class="modal-title mb-10" *ngIf="this.signupType == 'email'">Verify Email ID</h5>
          <div class="modal-subtitle" *ngIf="this.signupType == 'mobile'">An OTP has been sent to your<br>mobile number +91 {{this.uniqueId}} <a href="javascript:;" (click) = showLoginModal()><i class="edit-icon"></i></a></div>
          <div class="modal-subtitle" *ngIf="this.signupType == 'email'">An OTP has been sent to your<br>email {{this.uniqueId}} <a href="javascript:;" (click) = showLoginModal()><i class="edit-icon"></i></a></div>
          <div class="form-container">
              <form [formGroup]="otpForm" class="mt-40 mb-10">
                  <input class="otp" formControlName="otp1" type="text" (keyup)="onDigitInput($event)" maxlength=1 [(ngModel)] = "otp1" autofocus> 
                  <input class="otp" formControlName="otp2" type="text" (keyup)="onDigitInput($event)" maxlength=1 [(ngModel)] = "otp2">
                  <input class="otp" formControlName="otp3" type="text" (keyup)="onDigitInput($event)" maxlength=1 [(ngModel)] = "otp3">
                  <input class="otp" formControlName="otp4" type="text" (keyup)="onDigitInput($event)" maxlength=1 [(ngModel)] = "otp4">
              </form>
              <span class="help-block" *ngIf="(o.otp1.invalid && o.otp1.touched) || (o.otp2.invalid && o.otp2.touched) || (o.otp3.invalid && o.otp3.touched) || (o.otp4.invalid && o.otp4.touched)">Incorrect OTP, enter the correct OTP</span>
          </div>
          <div class="pt-20 pb-20">
              <div class="modal-subtitle">Didn’t received code?<br>
                  <a href="javascript:;" class="resend" (click)="phoneLoginFormSubmit()">Resend Code</a>
              </div>
          </div>
          <div class="cta mt-40">
              <button class="btn btn-mod btn-color btn-medium btn-round ripple btn-block"  [disabled]="otpForm.invalid || buttonLoading"  (click)="verifyotp()"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="buttonLoading" (click)="verifyotp()"></span>&nbsp;&nbsp;Verify OTP</button>
              <div class="pt-20"><a href="javascript:;" class="resend" (click)="showLoginPasswordModal()">Login with Password</a></div>
          </div>
      </div>

      <!--Login with Password Container-->
      <div class="modal-body" *ngIf="loginPasswordModal">
           <form [formGroup]="passwordForm">
           <h5 class="modal-title">Enter Password</h5>
           <div class="modal-subtitle">Enter the password for account<br>{{ uniqueId }} <a href="javascript:;" (click) = showLoginModal()><i class="edit-icon"></i></a></div>
           <div class="form-container mb-10">
              <div class="floating-label mb-0">      
                 <input class="form-control" type="password" placeholder="" formControlName="login_password" [(ngModel)]="login_password">
                 <span class="highlight"></span>
                 <label>Password</label>
              </div>
           </div>
           <div class="help-block" *ngIf="p.login_password.invalid && p.login_password.touched">Please enter the correct password</div>
           <div class="text-right pb-60">
              <a href="javascript:;" class="resend" (click) = forgotPassword()>Forgot Password?</a>
            </div>
            <div class="cta mt-40">
              <button class="btn btn-mod btn-color btn-medium btn-round ripple btn-block" (click)=verifypassword() [disabled]="passwordForm.invalid || buttonLoading"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="buttonLoading"></span>&nbsp;&nbsp;Login</button>
               <div class="text-center pt-20"><a href="javascript:;" class="resend" (click) = "phoneLoginFormSubmit()">Login with OTP</a></div>
           </div>
           </form>
     </div>

      <!--Email Sent Container-->
      <div class="modal-body text-center" *ngIf="forgotPasswordModal">
        <div class="verify-icon">
          <img src="assets/images/icons/email-sent.svg">
      </div>

      <div class="body-content pt-0 pb-0">
          <h5 class="modal-title mb-10">Password Recovery</h5>
          <div class="modal-subtitle">A link will be send on your registered email ID to reset the password</div>
        </div>
        <div class="cta mt-40 pt-30">
             <div class="text-center pt-0"><a href="javascript:;" class="resend" (click)="showLoginOTPModal()">Login with OTP</a></div>
         </div>
      </div>

      <!--Re-set Password Container-->
      <div class="modal-body" *ngIf="resetPasswordModal">
            <h5 class="modal-title">Reset your Password</h5>
            <form [formGroup]="resetPasswordForm">
            <div class="form-container">
               <div class="floating-label mb-0">      
                  <input class="form-control" type="password" placeholder="" formControlName="reset_password">
                  <span class="highlight"></span>
                  <label>Password</label>
               </div>
               <div class="alt-service-item">
                  <div class="alt-service-icon">
                     <i class="info-alert"></i>
                  </div>
                  Atleast use 8 characters with a mix of letters, numbers, and special character.
               </div>

               <div class="floating-label mt-20 mb-0">      
                  <input class="form-control" type="password" placeholder="" formControlName="reset_confirm_password">
                  <span class="highlight"></span>
                  <label>Confirm Password</label>
               </div>
               <div class="alt-service-item">
                  <div class="alt-service-icon">
                     <i class="info-alert"></i>
                  </div>
                  Should be same as above.
               </div>
               <!-- <span class="help-block" *ngIf="sp.confirm_password.errors.confirmedValidator">Password and Confirm Password must be match.</span> -->
            </div>   

            <div class="cta mt-40">
                <button class="btn btn-mod btn-color btn-medium btn-round ripple btn-block" (click)=resetpassword() [disabled]="resetPasswordForm.invalid || buttonLoading"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="buttonLoading"></span>&nbsp;&nbsp;Submit</button>
            </div>
          </form>
      </div>
    </div>
    <!--end admin card login-->
  </div>
</section>

