import { Component, OnInit } from '@angular/core';
import { ProductCategoryService } from './product-category.service';
import { EncrDecrService } from '../../helpers/EncrDecrService';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-product-category',
  templateUrl: './product-category.component.html',
  styleUrls: ['./product-category.component.scss']
})
export class ProductCategoryComponent implements OnInit {

    public categories:any = [];
    public all_categories:any = [];
    public subcategories:any = [];
    public default_preview:string = "assets/images/admin/homeappliance.png";
    keyword = 'name';

    public product_template:string = 'normal';

    constructor(
        private _editproductcategoryservice: ProductCategoryService,
        private _EncrDecr: EncrDecrService,
        private _router: Router,
        private _toastr: ToastrService
    ) { }

    ngOnInit() {
        this.getcategories();
    }

    getcategories(cat_id="all", parent="no"){
        this._editproductcategoryservice.getcategory({cat_id: cat_id, parent: parent}).subscribe((data) => {
            if(data['status'] == "success"){
                if(cat_id == 'all'){
                    this.categories = data['categories'];
                }else{
                    if(data['categories'].length > 0){
                        this.subcategories.push(data['categories']);
                    }else{
                        this.gotoaddproduct(cat_id);
                    }
                }
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    gotoaddproduct(id){
        if(!this.product_template){
            this._toastr.error('Please select product template');
            return false;
        }
        this._editproductcategoryservice.saveproductcategory({c_id: id, product_template: this.product_template}).subscribe((data) => {
            if(data['status'] == "success"){
                this._router.navigate(['/admin/products/add', data['uniqueId']]);
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });

    }

    getsubcategory(cat, i, type){
        if(type == 'sub'){
            this.subcategories.splice(i+1, this.subcategories.length-1);
        }else{
            this.subcategories = [];
        }
        if(this.subcategories.length != 0){
            this.subcategories[i].forEach(element => {
                element.open = true;
            });
        }
        cat.open = false;
        this.getcategories(cat.c_id, 'yes');
    }

    result = [];

    selectEvent(item) {
        this.gotoaddproduct(item.c_id);
        // do something with selected item
    }
   
    onChangeSearch(val: string) {
        // fetch remote data from here
        // And reassign the 'data' which is binded to 'data' property.
        this._editproductcategoryservice.searchcategory({search: val}).subscribe((data) => {
            if(data['status'] == "success"){
                this.result = data['records'];          
            }else if(data['status'] == "error"){
              this._toastr.error(data['message']);
            }
        });
    }

    onFocused(e){
        // do something when input is focused
    }


}
