<div class="card p-md-3 p-2 px-md-4 seller-more-info radius15">
	<div class="card-body p-md-3 p-2">
		<!-- REPEAT -->
		<div class="row">
			<div class="col-xl-3 col-md-4 col-6">
				<label class="txtseconday">Business Category:</label>
			</div>
			<div class="col-xl-9  col-md-8  col-6">
				<p class="txtdefault">{{ more_seller_detail.business_category }}</p>
			</div>
		</div>
		<!-- /REPEAT -->
		<!-- REPEAT -->
		<div class="row">
			<div class="col-xl-3  col-md-4  col-6">
				<label class="txtseconday">State and Region:</label>
			</div>
			<div class="col-xl-9  col-md-8  col-6">
				<p class="txtdefault">{{ more_seller_detail.state_region }}</p>
			</div>
		</div>
		<!-- /REPEAT -->
		<!-- REPEAT -->
		<div class="row">
			<div class="col-xl-3  col-md-4  col-6">
				<label class="txtseconday">Business Type:</label>
			</div>
			<div class="col-xl-9  col-md-8  col-6">
				<p class="txtdefault">{{ more_seller_detail.business_type }}</p>
			</div>
		</div>
		<!-- /REPEAT -->
		<!-- REPEAT -->
		<div class="row">
			<div class="col-xl-3 col-md-4  col-6">
				<label class="txtseconday">Year of Establishment:</label>
			</div>
			<div class="col-xl-9 col-md-8  col-6">
				<p class="txtdefault">{{ more_seller_detail.year?more_seller_detail:'Not Available' }}</p>
			</div>
		</div>
		<!-- /REPEAT -->
		<!-- REPEAT -->
		<div class="row">
			<div class="col-xl-3 col-md-4  col-6">
				<label class="txtseconday">Seller Name:</label>
			</div>
			<div class="col-xl-9 col-md-8  col-6">
				<p class="txtdefault">{{ more_seller_detail.trade_name }}</p>
			</div>
		</div>
		<!-- /REPEAT -->
		<!-- REPEAT -->
		<div class="row">
			<div class="col-xl-3 col-md-4  col-6">
				<label class="txtseconday">Seller Address:</label>
			</div>
			<div class="col-xl-9 col-md-8  col-6">
				<p class="txtdefault">{{ more_seller_detail.trade_name }} {{more_seller_detail.factory_info}}, India</p>
			</div>
		</div>
		<!-- /REPEAT -->
		<!-- REPEAT -->
		<div class="row">
			<div class="col-xl-3 col-md-4  col-6">
				<label class="txtseconday">Seller GSTIN:</label>
			</div>
			<div class="col-xl-9 col-md-8  col-6">
				<p class="txtdefault">{{ more_seller_detail.gstin }}</p>
			</div>
		</div>
		<!-- /REPEAT -->
		<!-- REPEAT -->
		<div class="row">
			<div class="col-xl-3 col-md-4  col-6">
				<label class="txtseconday">Seller GST Filing Frequency:</label>
			</div>
			<div class="col-xl-9 col-md-8  col-6">
				<p class="txtdefault">{{ more_seller_detail.gst_filling_frequency }}</p>
			</div>
		</div>
		<!-- /REPEAT -->
		<!-- REPEAT -->
		<div class="row">
			<div class="col-xl-3 col-md-4  col-6">
				<label class="txtseconday">Seller Product Listing Categories:</label>
			</div>
			<div class="col-xl-9 col-md-8  col-6">
				<p class="txtdefault">{{ more_seller_detail.category_name? more_seller_detail.category_name:'Not Available' }}</p>
			</div>
		</div>
		<!-- /REPEAT -->
		<!-- REPEAT -->
		<div class="row">
			<div class="col-xl-3 col-md-4  col-6">
				<label class="txtseconday">Brand Website:</label>
			</div>
			<div class="col-xl-9 col-md-8  col-6">
				<p class="txtdefault">{{ more_seller_detail.website }}</p>
			</div>
		</div>
		<!-- /REPEAT -->
	</div>
  </div> 