<app-header-buyer></app-header-buyer>
<app-sidebar-buyer></app-sidebar-buyer>
<div class="wrpmain-buyer">
    <div class="container">
    	<h3 class="fs24 wt700 txtdefault mb-20">Equibiz Pay Later</h3>
    	<div class="row" *ngIf="status == 0 || status == 1 || status == 2 || status == 4 "> 
    		<div class="col-md-4">
    			<div>
    				<img src="assets/images/bnpl-buyer.jpg" class="img-fluid">
    			</div>
    			<div class="fs12">* Terms and Conditions apply</div>
    		</div>

    		<div class="col-md-8">
    			<div class="card cardui carduipro p-4">
    				<div class="timelineContainer">
	    				<div class="timeline-block timeline-block-right">
						    <div class='marker' [ngClass]="{'active' : status != 0}"><i class='fa fa-check' [ngClass]="{'active' : status != 0}" aria-hidden="true"></i></div>
						    <div class="timeline-content">
						    	<div class="stepCount">Step 1</div>
						        <h3>Equibiz Pay Later</h3>
						        <span class="text-dark">Credit is never been so easy for you...<br>
						        <b>Avail of Interest-free credit and give a boost to your business</b></span>

						        <div class="mt-2"><a [class.disabled]="status != 0" (click)="bnplStart()" href="javascript:;" class="btn buybtn fs16 radius10">Get Started</a></div>
						    </div>
						</div>

						<div class="timeline-block timeline-block-left">
						    <div class="marker" [ngClass]="{'active' : status == 1 || status == 2 || status == 3 || status == 4}"><i class="fa fa-check" [ngClass]="{'active' : status == 1 || status == 2 || status == 3 || status == 4}" aria-hidden="true"></i></div>
						    <div class="timeline-content">
						        <div class="stepCount">Step 2</div>
						        <div class="d-flex align-items-center justify-content-between">
						        	<h3>Documentation Process</h3>
						        	<div *ngIf="status == 1" class="mb-1"><b>Status:</b> <span class="statusDot pending">Pending</span></div>
									<div *ngIf="status == 1" class="mb-1"><a href={{activation_url}} target="_blank" class="statusDot pending">Continue</a></div>
						        	<div *ngIf="status == 2 || status == 3" class="mb-1"><b>Status:</b> <span class="statusDot pending">Completed</span></div>
						        </div>
						    </div>
						</div>

						<div class="timeline-block timeline-block-right">
						    <div class="marker" [ngClass]="{'active' : status == 2 || status == 3 || status == 4}"><i class="fa fa-check" [ngClass]="{'active' : status == 2 || status == 3 || status == 4}" aria-hidden="true"></i></div>
						    <div class="timeline-content">
						        <div class="stepCount">Step 3</div>
						        <div class="d-flex align-items-center justify-content-between">
						        	<h3>Pre-Approval</h3>
						        	<div *ngIf="status == 2" class="mb-1"><b>Status:</b> <span class="statusDot pending">Pending for Approval</span></div>
						        	<div *ngIf="status == 3" class="mb-1"><b>Status:</b> <span class="statusDot pending">Approved</span></div>
						        	<div *ngIf="status == 4" class="mb-1"><b>Status:</b> <span class="statusDot pending">Denied</span></div>
								</div>

						        <div class="mb-1"><b>Pre-Approval Amount:</b> ₹ {{paymentStatus?.accountDetails?.account?.limit?.value}}</div>
						    </div>
						</div>
					</div>
    			</div>
    		</div>
    	</div>

    	<div class="row"  *ngIf="status == 3 ">
    		<div class="col-md-12">
    			<div class="card cardui carduipro p-4 mb-4">
    				<div class="row">
    					<div class="col-md-3">
    						<div class="tile">
    							<div class="tile-header">
									<img src="assets/images/icons/rupee.png">
									<h3>
										<span>₹ {{paymentStatus?.accountDetails?.account?.limit?.value}}</span>
										<span>Credit Limit</span>
									</h3>
								</div>
								<a href="#">
									<span>View Details</span>
									<span class="icon-button">
										<img src="assets/images/icons/right-arrow.png">
									</span>
								</a>
    						</div>
    					</div>

    					<div class="col-md-3">
    						<div class="tile">
    							<div class="tile-header">
									<img src="assets/images/icons/available-credit.png">
									<h3>
										<span>₹ {{paymentStatus?.accountDetails?.account?.balance?.value}}</span>
										<span>Available Credit</span>
									</h3>
								</div>
								<a href="#">
									<span>View Details</span>
									<span class="icon-button">
										<img src="assets/images/icons/right-arrow.png">
									</span>
								</a>
    						</div>
    					</div>

    					<div class="col-md-3">
    						<div class="tile bg-2">
    							<div class="tile-header">
									<img src="assets/images/icons/paid.png">
									<h3>
										<span>₹ 0</span>
										<span>Paid Amount</span>
									</h3>
								</div>
								<a href="#">
									<span>View Details</span>
									<span class="icon-button">
										<img src="assets/images/icons/right-arrow.png">
									</span>
								</a>
    						</div>
    					</div>

    					<div class="col-md-3">
    						<div class="tile bg-3">
    							<div class="tile-header">
									<img src="assets/images/icons/balance.png">
									<h3>
										<span>₹ {{bnplDues?.total_amount?.value}}</span>
										<span>Total Due</span>
									</h3>
								</div>
								<span class="fs13 f-600 text-dark"><b>Upcoming Due:</b> ₹ {{bnplDues?.amount?.value}} </span>
								<span class="fs13 f-600 text-dark"><b>Due Date:</b> {{bnplDues?.date}}</span>

								<a href="{{repayment_url}}" target="_blank" class="buybtn btn fs12 radius10 place-bid text-white text-center">
									<span>Pay Now</span>
								</a>

    						</div>
    					</div>
    				</div>
    			</div>

    			<div class="card cardui carduipro p-4 mb-4">
    				<h3 class="fs18 wt700 txtdefault mb-2">Recent Transactions</h3>
		            <table class="table custom-table xs">
		                <thead>
		                    <tr>
		                        <th>S. No.</th>
		                        <th>Order Id</th>
		                        <th>Amount</th>
		                        <th>Due Date</th>
		                        <th>Interest Free Upto</th>
		                        <th>Created Payment Date</th>
		                        <th></th>
		                    </tr>
		                </thead>
		                <tbody *ngIf="duesData.length > 0">
		                    <tr *ngFor="let dues of duesData; let i = index;">
		                    	<td>{{i + 1}}</td>
		                    	<td>{{dues?.merchant_order_id}}</td>
		                    	<td>₹ {{dues?.due_amount}}</td>
		                    	<td>{{(dues?.due_date)}}</td>
		                    	<td>{{dues?.interest_free_upto}}</td>
		                    	<td>{{dues?.created_payment_date}}</td>
		                    	<td><a href="javascript:;" class="link-alt fs12" (click)="viewDetails(dues?.order_unique_id)">View Order</a></td>
		                    </tr>
		                </tbody>
						<tbody *ngIf="duesData.length == 0">
		                    <span>There is no records</span>
		                </tbody>
		            </table>
					<button (click)="viewAllOrders()" class="buybtn btn fs12 radius10 place-bid text-white text-center">View All Orders</button>
    			</div>
    		</div>
    	</div>
    </div>
</div>
<app-footer-buyer></app-footer-buyer>