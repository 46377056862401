<app-header-buyer></app-header-buyer>
<app-sidebar-buyer></app-sidebar-buyer>
<div class="wrpmain-buyer">
    <div class="container">
        <!--- HEAD  --->
        <div class="row align-items-center mb-4 pinfo-head">
            <div class="col-sm-6 col-7 pr-0">
                <nav class="breadcrumb breadcrumb-dui mb-0">
                    <ol class="breadcrumb p-0 bg-transparent  mb-0">
                        <li class="breadcrumb-item">
                            <a class="txtseconday" href="javascript:void(0);" [routerLink]="['/home']">Home</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a class="txtseconday" [routerLink]="['/buyer/buyer-wallet']">Wallet and Payments</a>
                        </li>
                        <li class="breadcrumb-item active">Add Money</li>
                    </ol>
                </nav>
            </div>
            <div class="col-sm-6 col-5 text-right pl-0">
                <a href="javascript:void(0);" [routerLink]="['/buyer/assistance']" class="btn buybtn fs16 radius10 ripple">Assistance</a>
            </div>
        </div>
        <!--- /HEAD  --->
        <h3 class="f-700 fs22 txtdefault pb-10">
            <a href="javascript:;" [routerLink]="['/buyer/buyer-wallet']" class="go-back">
                <i class="fa fa-angle-left"></i>
            </a>
            Add Money
        </h3>

        <div class="card cardui carduipro p-4 mb-40">
            <div class="form-container mdl-frm-lbl mb-3 mt-0">
                <h3 class="f-700 fs20 txtdefault pb-20 pb-xs-0">Equibiz Wallet</h3>
                <div class="wallet-balance">
                    <div class="mr-sm-3">
                        <img src="assets/images/icons/wallet-balance.svg">
                    </div>
                    <div>
                        <p>Current Balance:</p>
                        <h4>{{ wallet_amount }}</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 mb-2">
                        <div class="floating-label mb-0">
                            <input type="text" class="form-control" placeholder="Enter amount (min ₹1,000)" [(ngModel)]='payment_amount'
                                [disabled]='show_addmoney' />
                            <!-- <span class="highlight"></span> -->
                            <!-- <label>Enter amount (min ₹1,000)</label> -->
                        </div>
                    </div>
                </div>
                <div class="help-info">Transaction Charges : 0.1% <a href="javascript:;" class="fs14 link-alt">Know
                        more</a></div>

                <div class="wallet-amount">
                    <ul>
                        <li *ngFor="let amounts of predefined_amounts;" (click)="updateamount(amounts)"><a
                                href="javascript:;" [ngClass]="{'selected': payment_amount == amounts.value}">₹ {{
                                amounts.label }}</a></li>
                    </ul>
                </div>
                <div class="text-left mt-30">
                    <button class="mr-sm-4 btn-d-outline d-inline-block md-block mb-xs-10"
                        [disabled]="!payment_amount" (click)="placeorder()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14.681" height="14.681"
                            viewBox="0 0 14.681 14.681" class="plus">
                            <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1 1)">
                                <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181"
                                    transform="translate(-11.659 -7.5)" fill="none" stroke="#ff7135"
                                    stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181"
                                    transform="translate(-7.5 -11.659)" fill="none" stroke="#ff7135"
                                    stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                            </g>
                        </svg>
                        Add Money
                    </button>
                    <i placement="top" ngbTooltip="more info goes here"><img
                            src="assets/images/icons/shield-alt.svg" /></i>
                    &nbsp;Safe and securely add money to your wallet.
                </div>
            </div>
        </div>

        <div class="row" *ngIf="show_addmoney">
            <div class="col-md-12">
                <div>
                    <p class="text-muted fs14">
                        Select Payment Type&nbsp;&nbsp;
                        <button class="mr-sm-4 btn-d-outline d-inline-block"
                            (click)="cancelpayment()">
                            
                            Cancel Payment
                        </button>
                    </p>
                    
                    <div class="card card-ui mb-3 eq-selectPg">
                        <div class="gstfq-wp roundradio">
                            <div class="cssinptchk css-check">
                                <input type="radio" name="payment_type" id="card_payment_type" class="css-checkbx"
                                    (click)="makepayment('card')" />
                                <label class="css-label" for="card_payment_type">
                                    <div class="d-flex align-items-center">
                                        <span class="f-700 fs14 mr-4">Card</span>
                                    </div>
                                </label>
                            </div>
                            <hr>
                            <div class="cssinptchk css-check">
                                <input type="radio" name="payment_type" id="upi_payment_type" class="css-checkbx"
                                    (click)="makepayment('upi')" />
                                <label class="css-label" for="upi_payment_type">
                                    <div class="d-flex align-items-center">
                                        <span class="f-700 fs14 mr-4">UPI</span>
                                    </div>
                                </label>
                            </div>
                            <hr>
                            <div class="cssinptchk css-check">
                                <input type="radio" name="payment_type" id="netbanking_payment_type" class="css-checkbx"
                                    (click)="makepayment('netbanking')" />
                                <label class="css-label" for="netbanking_payment_type">
                                    <div class="d-flex align-items-center">
                                        <span class="f-700 fs14 mr-4">Net Banking</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card card-ui mb-3" style="display: none;" id="dropincontainerMainDiv">
                    <div class="customCard" id="drop_in_container">
                    </div>
                </div>

                <div *ngIf="saved_cards_records.length > 0 && payment_type == 'cardd'">
                    <p class="text-muted fs14">Select from Saved Cards</p>
                    <div class="card card-ui mb-3 eq-savedCards">
                        <div class="gstfq-wp roundradio">
                            <div class="saved-item cssinptchk css-check"
                                *ngFor="let card of saved_cards_records; let i = index;">
                                <label class="css-label">
                                    <div>
                                        <div class="row d-flexa align-items-center justify-content-between">
                                            <div class="col-md-2">
                                                <span class="f-700 fs15 captiablize">{{ card.name }}</span>
                                            </div>
                                            <div class="col-md-4">
                                                <span class="f-500 fs15">{{ card.number }}</span>
                                            </div>
                                            <div class="col-md-2">
                                                <span class="f-500 fs15">{{ card.expiry }}</span>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="text" class="form-control" placeholder="cvv"
                                                    [(ngModel)]="card.cvv">
                                            </div>
                                            <div class="col-md-2">
                                                <button class="btn px-4 buybtn fs16 btn-block radius10"
                                                    (click)="submitsavedcard(card)">Pay Now</button>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="payment_type == 'cardd'">
                    <p class="text-muted fs14">Enter Card Details</p>
                    <div class="card card-ui mb-3">
                        <div class="customCard" id="card_in_container">
                            <form [formGroup]="creditCardForm" #demoForm="ngForm" (ngSubmit)="cardsubmit('new')"
                                novalidate>
                                <div class="row d-flex justify-content-center">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label for="">Card Number</label>
                                                    <input class="card-input__input" id="cc-number"
                                                        formControlName="creditCard" type="tel" autocomplete="cc-number"
                                                        ccNumber>
                                                    <span class="help-block"
                                                        *ngIf="cc.creditCard.invalid && cc.creditCard.touched">Enter
                                                        Valid Card Number</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">Card Expiry Date</label>
                                                    <input class="card-input__input" id="cc-exp-date"
                                                        formControlName="expirationDate" type="tel"
                                                        autocomplete="cc-exp" ccExp>
                                                    <span class="help-block"
                                                        *ngIf="cc.expirationDate.invalid && cc.expirationDate.touched">Enter
                                                        Valid Expiry Date</span>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">CVV</label>
                                                    <input class="card-input__input" id="cc-cvc" formControlName="cvv"
                                                        type="tel" autocomplete="off" ccCvc>
                                                    <span class="help-block"
                                                        *ngIf="cc.cvv.invalid && cc.cvv.touched">Enter Valid CVV
                                                        Number</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label for="">Card Holder Name</label>
                                                    <input class="card-input__input" formControlName="cardHolderName"
                                                        type="text" autocomplete="off" style="text-transform:uppercase">
                                                    <span class="help-block"
                                                        *ngIf="cc.cardHolderName.invalid && cc.cardHolderName.touched">Enter
                                                        card holder name.</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div class="css-check">
                                                <input class="css-checkbx" type="checkbox"
                                                    formControlName="save_card" />
                                                <label class="css-label">Save Card for Faster Checkout</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-30">
                                    <button class="btn px-4 buybtn fs18 btn-block radius10"
                                        [disabled]="creditCardForm.invalid">Pay</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mb-2 faqpanel accordionarrow">
            <h4 class="f-700 fs22 txtdefault pb-10">Frequently Asked Questions</h4>
            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
                <ngb-panel title="What is the Equibiz internal wallet account?">
                    <ng-template ngbPanelContent>
                      <p class="txtdefault fs16 lnht24 mb-0">It is a digital construct of the nodal (A type of Escrow) account to aggregate all your funding and payment in one place. Your wallet account number is mapped with your bank account provided at the time of registration.</p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="Can this wallet balance be used for making payments outside the Equibiz platform?">
                    <ng-template ngbPanelContent>
                      <p class="txtdefault fs16 lnht24 mb-0">No, you can use the wallet balance to make a purchase over the Equibiz platform only.</p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="What are the advantages of using Equibiz internal wallet for making purchase payments?">
                    <ng-template ngbPanelContent>
                     <p class="txtdefault fs16 lnht24 mb-0">The wallet funding charges using internet banking are free.</p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="How do I fund my Equibiz wallet account?">
                    <ng-template ngbPanelContent>
                      <p class="txtdefault fs16 lnht24 mb-0">You can use internet banking to transfer funds to your wallet account. You must register your Equibiz wallet account details as a beneficiary through internet banking. You can then transfer the amount to the wallet account before you initiate any purchase.</p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="Is Equibiz wallet compulsory as a payment option for purchase?">
                    <ng-template ngbPanelContent>
                      <p class="txtdefault fs16 lnht24 mb-0">No, you can use any of the listed payment options. In the case of other payment options, you may have to pay certain transaction charges, displayed at the time of payment.</p>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>
</div>

<app-footer-buyer></app-footer-buyer>