import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detailed-order-report',
  templateUrl: './detailed-order-report.component.html',
  styleUrls: ['./detailed-order-report.component.scss']
})
export class DetailedOrderReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
