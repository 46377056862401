import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { ViewOrderqcDetailsService } from './view-pending-qc-details.service';

@Component({
  selector: 'app-view-pending-qc-details',
  templateUrl: './view-pending-qc-details.component.html',
  styleUrls: ['./view-pending-qc-details.component.scss']
})
export class ViewPendingQcDetailsComponent implements OnInit {

  public order_id:string;
  public record:any = {};
  public showlog_history:boolean = false;
  constructor(
    private _vieworderqcservice: ViewOrderqcDetailsService,
    private _toastr: ToasterService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.order_id = this.route.snapshot.paramMap.get('id');
    this.getqcdetails();
  }

  showloghistory(){
    this.showlog_history = !this.showlog_history;
  }

  public reject_options = [
    {
      	value: "Defective Quality Product",
      	label:"Defective Quality Product",
    },
    {
        value: "Incorrect model measurements",
        label:"Incorrect model measurements",
    },
    {
        value: "Bar code not found on the package",
        label:"Bar code not found on the package",
    },
    {
        value: "Package found open",
        label:"Package found open",
    },
    {
        value: "Low Image resolution. Need Clear images",
        label:"Low Image resolution. Need Clear images",
    },
    {
        value: "Missing additional Information",
        label:"Missing additional Information",
    },
    {
        value: "Other",
        label:"Other",
    }
  ];

  getqcdetails() {
    this._vieworderqcservice.vieworderqcdetails({ order_id: this.order_id }).subscribe((data) => {
        if (data['status'] == "success") {
            this.record = data['record'];
        } else if (data['status'] == "error") {
            this._toastr.error(data['message']);
        }
    });
}

reviewnotify() {
    this._vieworderqcservice.reviewnotify({ order_id: this.order_id }).subscribe((data) => {
        if (data['status'] == "success") {
            this._toastr.success(data['msg']);
			this.getqcdetails();
        } else if (data['status'] == "error") {
            this._toastr.error(data['message']);
        }
    });
}



updatereason(log) {
    this._vieworderqcservice.updatereason({ order_id: this.order_id, qc_id:log.id, remark:log.reason }).subscribe((data) => {
        if (data['status'] == "success") {
            this._toastr.success('Rejected reason has been updated');
        } else if (data['status'] == "error") {
            this._toastr.error(data['message']);
        }
    });
}

uploadFile(event) {
  const file = event.target.files;
  let data: any = new FormData();
  data.append('order_id', this.order_id);
  data.append("qc", file[0]);
      this._vieworderqcservice.uploadqcdetails(data).subscribe((data) => {
          if (data['status'] == "success") {
            setTimeout( () => {
              this.getqcdetails();
            }, 2000)
            this._toastr.success('Quality check Sheet has been updated successfully.');
          } else if (data['status'] == "error") {
              this._toastr.error(data['message']);
          }
      }, (error) => {
          event.target.value = '';
      });
  
  event.target.value = '';

}

uploadChildFile(event, child) {
    const file = event.target.files;
    let data: any = new FormData();
    data.append('order_id', this.order_id);
    data.append('child_id', child.id);
    data.append("qc", file[0]);
        this._vieworderqcservice.uploadqcdetails(data).subscribe((data) => {
            if (data['status'] == "success") {
              setTimeout( () => {
                this.getqcdetails();
              }, 2000)
              this._toastr.success('Quality check Sheet has been updated successfully.');
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        }, (error) => {
            event.target.value = '';
        });
    
    event.target.value = '';
}


  config = {
    labelField: 'label',
    valueField: 'value',
  }; 
  data = [
    {
      label: 'India',
      value: 'india'
    },
    {
      label: 'in $',
      value: 'in $'
    }
  ]
}
