import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/do';
import { _throw as throwError } from 'rxjs/observable/throw';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SignupService } from '../shared/components/modal/signup-modal/signup-modal.service';

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

    constructor(
        public router: Router,
        private _toastr: ToastrService,
        private _signupservice: SignupService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if account is logged in and request is to the api url
        const token = localStorage.getItem("loggedInUserToken");
        if (token) {
            request = request.clone({
                setHeaders: { Authorization: `Bearer ${token}` }
            });
        }

        return next.handle(request).do((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // do stuff with response if you want
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    localStorage.clear();
                    this._signupservice.logout();
                    this.router.navigate(['/home']);
                    // redirect to the login route
                    // or show a modal
                } else if (err.status === 500) {
                    this._toastr.error(err.message);
                } else {
                    this._toastr.error('Error. Please try again. ' + err.statusText);
                }
            }
            return throwError(err);
        });
    }
}
