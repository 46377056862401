<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain mgwrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head mgb-sm-ui-rw">
			<div class="col-md-6 ">
				<h3 class="fs28 txtdefault wt700">Quality Check</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Order Management</a>
						</li>
						<li class="breadcrumb-item active">Quality Checks</li>
					</ol>
				</nav>
			</div>
			<div class="col-md-6 text-right sm-ui-rw">
				<a href="javascript:void(0);" class="btn-d-outline d-inline-block place-bid">
					<svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
					  <g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
						<path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#ff7135"/>
					  </g>
					</svg>					
					Download Report
				</a>
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ml-3">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card px-2 pt-0 radius15 mb-40">
			<div class="card-body">
				<div class="notifications-container theme-tab">
		          	<ngb-tabset [justify]="'left'" [type]="'pills'" class="tabs tab-title">
			            <ngb-tab>
			                <ng-template ngbTabTitle>Pending Quality Check ({{pending_records.length}})</ng-template>
			                <ng-template ngbTabContent>
			                  <div class="tab-content">
			                    	<!--Filters-->
									<div class="row d-flex justify-content-between mt-20 report-cta">
										<div class="col-md-5">
											<div class="report-input">
												<div class="input-group-custom">
													<input type="text" placeholder="Search by Order ID, Transaction ID, Product Name & Brand" class="form-control" 
													class="form-control" [(ngModel)]="search_term" (change)="getpendingqc()">
													<div class="input-group-append-custom">
														<img src="assets/images/svg/search_logo.svg">
													</div>
												</div>
											</div>
										</div>

										<div class="col-md-7">
											<div class="d-flex justify-content-end">
												<!-- <div class="mr-3">
													<a href="javascript:void(0);" class="d-inline-block fltr-round-btn radius10 txtseconday">
														<img src="assets/images/svg/filter.svg">
														Filters
													</a>
												</div> -->
												<div class="form-container mt-0 mb-0 mr-3">
													<div class="report-input floating-label">
														<div class="input-group-custom">
															<input type="text" [(ngModel)]="from_date" (ngModelChange) = "getpendingqc()" class="form-control" placeholder=" " ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" autocomplete="off"/>
															<span class="highlight"></span>
															<label>From Date</label>
															<div class="input-group-append-custom">
																<img src="assets/images/icons/filter-calendar.svg">
															</div>
														</div>
													</div>
												</div>
					
												<div class="form-container mt-0 mb-0 mr-3">
													<div class="form-container m-0">
														<div class="report-input floating-label">
															<div class="input-group-custom">
																<input type="text" [(ngModel)]="to_date" (ngModelChange) = "getpendingqc()" class="form-control" placeholder=" " ngbDatepicker #dd="ngbDatepicker" (click)="dd.toggle()" autocomplete="off"/>
																<span class="highlight"></span>
																<label>To Date</label>
																<div class="input-group-append-custom">
																	<img src="assets/images/icons/filter-calendar.svg">
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<!--End Filters-->

									<div class="table-responsive">
										<table class="table custom-table xs">
											<thead>
												<tr>
													<th>Sr. no.</th>
													<th>Product Name</th>
													<th>order ID</th>
													<th>Transaction ID</th>
													<th>Pre-booked on</th>
													<th>Price (in ₹) <i class="long-arrow-down"></i></th>
													<th>Tracking ID</th>
													<th>Order Status</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												<!--Empty State-->
												<tr *ngIf="!pending_records.length">
													<td colspan="9">
														<div class="row pt-40 pb-40">
															<div class="col-md-12 text-center">
																<div class="mb-20"><img src="/assets/images/empty-state.png">
																</div>
																<div class="fs16">There are no records to display!</div>
															</div>
														</div>
													</td>
												</tr>
												<!--End Empty State-->
												<tr *ngFor="let record of pending_records; let i = index;">
													<td>{{ i+1 }}</td>
													<td>{{ record.product_name }}</td>
													<td>{{ record.order_id }}</td>
													<td>{{ record.transaction_id }}</td>
													<td>{{ record.booking_date }}</td>
													<td>{{ record.amount }}</td>
													<td>{{ record.tracking_id }}</td>
													<td>{{ record.order_status }}</td>
													<td>
														<a href="javascript:void(0);" 
														class="btn-d-outline ripple d-inline-block place-bid" 
														[routerLink]="['/admin/view-pending-qc-details/'+record.id]">
															View Details
														</a>
													</td>
												</tr>
												
											</tbody>
										</table>
									</div>

									<div class="row mb-40" *ngIf="pending_pagination.totalPages> 1">
										<div class="col-md-6">
											<div class="text-left pl-3">
												<nav class="mt-3 tbpg rows-per-page">
													<div>Rows per page:</div>
													<select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
														<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">
															{{option.name}}</option>
													</select>
												</nav>
											</div>
										</div>
										<div class="col-md-6">
											<div class="text-right pr-3">
												<nav class="mt-3 tbpg">
													<ul class="pagination align-items-center">
														<li class="page-item" [ngClass]="{disabled:pending_pagination.currentPage === 1}"><a
																class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a>
														</li>
					
														<li class="page-item" [ngClass]="{disabled:pending_pagination.currentPage === 1}"><a
																class="page-link sprevicon" href="javascript:;"
																(click)="setpage(pending_pagination.currentPage - 1)"></a></li>
					
														<li class="page-item" i *ngFor="let page of pending_pagination.pages"
															[ngClass]="{active:pending_pagination.currentPage === page}"><a class="page-link"
																href="javascript:;" (click)="setpage(page)">{{page}}</a></li>
					
														<li *ngIf="(pending_pagination.totalPages - pending_pagination.startPage) > 5 ">
															<a (click)="setpage(pending_pagination.startPage + 5)">...</a>
														</li>
														<li *ngIf="(pending_pagination.totalPages - pending_pagination.startPage) > 5">
															<a
																(click)="setpage(pending_pagination.totalPages)">{{pending_pagination.totalPages}}</a>
														</li>
					
														<li class="page-item"
															[ngClass]="{disabled:pending_pagination.currentPage === pending_pagination.totalPages}">
															<a class="page-link snexticon" href="javascript:;"
																(click)="setpage(pending_pagination.currentPage + 1)"></a>
														</li>
														<li class="page-item"
															[ngClass]="{disabled:pending_pagination.currentPage === pending_pagination.totalPages}">
															<a class="page-link nexticon" href="javascript:;"
																(click)="setpage(pending_pagination.totalPages)"></a>
														</li>
													</ul>
												</nav>
											</div>
										</div>
									</div>
			                  </div>
			                </ng-template>
			            </ngb-tab>	
			            <ngb-tab>
			                <ng-template ngbTabTitle>Quality Check Done ({{ completed_records.length }})</ng-template>
			                <ng-template ngbTabContent>
				                 <div class="tab-content">
			                    	<!--Filters-->
									<div class="row d-flex justify-content-between mt-20 report-cta">
										<div class="col-md-5">
											<div class="report-input">
												<div class="input-group-custom">
													<input type="text" placeholder="Search by Order ID, Transaction ID, Product Name & Brand" class="form-control" 
													class="form-control" [(ngModel)]="co_search_term" (change)="getcompletedqc()">
													<div class="input-group-append-custom">
														<img src="assets/images/svg/search_logo.svg">
													</div>
												</div>
											</div>
										</div>

										<div class="col-md-7">
											<div class="d-flex justify-content-end">
												<!-- <div class="mr-3">
													<a href="javascript:void(0);" class="d-inline-block fltr-round-btn radius10 txtseconday">
														<img src="assets/images/svg/filter.svg">
														Filters
													</a>
												</div> -->
												<div class="form-container mt-0 mb-0 mr-3">
													<div class="report-input floating-label">
														<div class="input-group-custom">
															<input type="text" [(ngModel)]="co_from_date" (ngModelChange) = "getcompletedqc()" class="form-control" placeholder=" " ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" autocomplete="off"/>
															<span class="highlight"></span>
															<label>From Date</label>
															<div class="input-group-append-custom">
																<img src="assets/images/icons/filter-calendar.svg">
															</div>
														</div>
													</div>
												</div>
					
												<div class="form-container mt-0 mb-0 mr-3">
													<div class="form-container m-0">
														<div class="report-input floating-label">
															<div class="input-group-custom">
																<input type="text" [(ngModel)]="co_to_date" (ngModelChange) = "getcompletedqc()" class="form-control" placeholder=" " ngbDatepicker #dd="ngbDatepicker" (click)="dd.toggle()" autocomplete="off"/>
																<span class="highlight"></span>
																<label>To Date</label>
																<div class="input-group-append-custom">
																	<img src="assets/images/icons/filter-calendar.svg">
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<!--End Filters-->

									<div class="table-responsive">
										<table class="table custom-table xs">
											<thead>
												<tr>
													<th>Sr. no.</th>
													<th>Product Name</th>
													<th>order ID</th>
													<th>Transaction ID</th>
													<th>Pre-booked on</th>
													<th>Price (in ₹) <i class="long-arrow-down"></i></th>
													<th>Tracking ID</th>
													<th>Order Status</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												<!--Empty State-->
												<tr *ngIf="!completed_records.length">
													<td colspan="9">
														<div class="row pt-40 pb-40">
															<div class="col-md-12 text-center">
																<div class="mb-20"><img src="/assets/images/empty-state.png">
																</div>
																<div class="fs16">There are no records to display!</div>
															</div>
														</div>
													</td>
												</tr>
												<!--End Empty State-->
												<tr *ngFor="let record of completed_records; let i = index;">
													<td>{{ i+1 }}</td>
													<td>{{ record.product_name }}</td>
													<td>{{ record.order_id }}</td>
													<td>{{ record.transaction_id }}</td>
													<td>{{ record.booking_date }}</td>
													<td>{{ record.amount }}</td>
													<td>{{ record.tracking_id }}</td>
													<td><svg xmlns="http://www.w3.org/2000/svg" width="17.76" height="17.76" viewBox="0 0 17.76 17.76">
													  <g id="np_tick_3451338_000000" transform="translate(-10 -10)">
													    <path id="Path_10504" data-name="Path 10504" d="M18.88,27.76a8.879,8.879,0,1,1,6.279-2.6A8.882,8.882,0,0,1,18.88,27.76Zm0-16.279a7.4,7.4,0,1,0,5.232,2.167A7.4,7.4,0,0,0,18.88,11.48Z" fill="#35cb4e"/>
													    <path id="Path_10505" data-name="Path 10505" d="M35.542,41.843a.746.746,0,0,1-.526-.214l-1.48-1.48h0A.743.743,0,0,1,34.588,39.1l1,1.007,3.922-3.271h0a.741.741,0,1,1,.947,1.139l-4.44,3.7a.74.74,0,0,1-.473.17Z" transform="translate(-18.142 -20.743)" fill="#35cb4e"/>
													  </g>
													</svg> &nbsp; {{ record.order_status }}</td>
													<td>
														<a href="javascript:void(0);" class="btn-d-outline ripple d-inline-block place-bid" 
														[routerLink]="['/admin/view-pending-qc-details/'+record.id]">
															View Details
														</a>
													</td>
												</tr>
											</tbody>
										</table>
									</div>

									<div class="row mb-40" *ngIf="completed_pagination.totalPages> 1">
										<div class="col-md-6">
											<div class="text-left pl-3">
												<nav class="mt-3 tbpg rows-per-page">
													<div>Rows per page:</div>
													<select class="select-box" [(ngModel)]="page_per_view" (change)="setcopageperview()">
														<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">
															{{option.name}}</option>
													</select>
												</nav>
											</div>
										</div>
										<div class="col-md-6">
											<div class="text-right pr-3">
												<nav class="mt-3 tbpg">
													<ul class="pagination align-items-center">
														<li class="page-item" [ngClass]="{disabled:completed_pagination.currentPage === 1}"><a
																class="page-link previcon" href="javascript:;" (click)="setcopage(1)"></a>
														</li>
					
														<li class="page-item" [ngClass]="{disabled:completed_pagination.currentPage === 1}"><a
																class="page-link sprevicon" href="javascript:;"
																(click)="setcopage(completed_pagination.currentPage - 1)"></a></li>
					
														<li class="page-item" i *ngFor="let page of completed_pagination.pages"
															[ngClass]="{active:completed_pagination.currentPage === page}"><a class="page-link"
																href="javascript:;" (click)="setcopage(page)">{{page}}</a></li>
					
														<li *ngIf="(completed_pagination.totalPages - completed_pagination.startPage) > 5 ">
															<a (click)="setcopage(completed_pagination.startPage + 5)">...</a>
														</li>
														<li *ngIf="(completed_pagination.totalPages - completed_pagination.startPage) > 5">
															<a
																(click)="setcopage(completed_pagination.totalPages)">{{completed_pagination.totalPages}}</a>
														</li>
					
														<li class="page-item"
															[ngClass]="{disabled:completed_pagination.currentPage === completed_pagination.totalPages}">
															<a class="page-link snexticon" href="javascript:;"
																(click)="setcopage(completed_pagination.currentPage + 1)"></a>
														</li>
														<li class="page-item"
															[ngClass]="{disabled:completed_pagination.currentPage === completed_pagination.totalPages}">
															<a class="page-link nexticon" href="javascript:;"
																(click)="setcopage(completed_pagination.totalPages)"></a>
														</li>
													</ul>
												</nav>
											</div>
										</div>
									</div>
			                  </div>
			                </ng-template>
			            </ngb-tab>
			        </ngb-tabset>
			    </div>
			</div>
		</div>
	</div>
</div>	