import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { SellerRatingReviewsService } from './rating-reviews.service';

@Component({
    selector: 'app-rating-reviews',
    templateUrl: './rating-reviews.component.html',
    styleUrls: ['./rating-reviews.component.scss']
})
export class RatingReviewsComponent implements OnInit {

    public filter_by_rating:any = [];
    public sort_by_rating:string = 'date_desc';
    public star_count:number;
    public rating_total_records:number;
    public seller_id:string = '';


    public review_records: any = [];
    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 20;
    public review_pagination: any = {};

    public page_per_view_options = [
        {
            value: 20,
            name: 20
        },
        {
            value: 40,
            name: 40
        }
    ];

    constructor(
        private _sellerreviewservice: SellerRatingReviewsService,
        private _toastr: ToasterService,
        private _pagination: PaginationService,
        private _route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this._route.queryParams
            .subscribe(params => {
                if (params['s_id'] !== undefined) {
                    this.seller_id = params['s_id'];
                }
            }
        );
        this.getsellerreviews();
    }
    ratingclassstatus: boolean = false;
    ratingst: boolean = false;
    ratingtglclass() {
        this.ratingclassstatus = !this.ratingclassstatus;
    }

    ratingsort() {
        this.ratingst = !this.ratingst;
    }
    public val = null;
    config = {
        labelField: 'label',
        valueField: 'value',

    };

    getsellerreviews() {
        let data = { 
            limit: this.page_per_view,
            offset: this.offset,
            current_page: this.current_page,
            rating: this.filter_by_rating,
            sorting: this.sort_by_rating,
            seller_id: this.seller_id 
        };

        this._sellerreviewservice.getsellerreviews(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.review_records = data['records'];
                let total_records = data['total_records'];
                this.rating_total_records = data['total_records'];
                this.star_count = data['star_count'];
                this.review_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.getsellerreviews();
    }

    setpageperview() {
        this.current_page = 1;
        this.getsellerreviews();
    }

    updatereviewfilters(val){
        if(this.filter_by_rating.indexOf(val) !== -1){
            var index = this.filter_by_rating.indexOf(val);
            this.filter_by_rating.splice(index, 1);
        } else{
            this.filter_by_rating.push(val);
        }
        this.getsellerreviews();
    }


}
