import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-header-seller',
    templateUrl: './header-seller.component.html',
    styleUrls: ['./header-seller.component.scss']
})
export class HeaderSellerComponent implements OnInit {

    public currentDateTime: string;
    constructor(
        public datepipe: DatePipe
    ) { }

    ngOnInit(): void {
        this.currentDateTime = this.datepipe.transform((new Date), 'dd/MM/yyyy, h.mma');
    }

}
