<app-header-seller></app-header-seller>
<app-sidebar-seller></app-sidebar-seller>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7">
				<h3 class="fs24 wt700 txtdefault">Signature & Invoicing</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">My Profile</a>
						</li>
						<li class="breadcrumb-item active">Signature & Invoicing</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right">
				<a href="javascript:void(0);" [routerLink]="['/seller/assistance']" class="btn buybtn fs16 radius10">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4">
			<div class="profileipt">
				<div class="form-container mt-0 mb-0 p-eml-rt">
					<div class="floating-label mb-10 flselcted"> 
						<input type="text" class="form-control" placeholder=" ">
						<button class="btn btn-outline-secondary fs16 btn-o-default" type="button">
							<span>Change</span>
							<svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107" viewBox="0 0 18.71 12.107">
						  <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
							<path id="Path_2337" data-name="Path 2337" d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z" transform="translate(-14.582 -27.082)" fill="#ff7135"/>
							<g id="Group_15718" data-name="Group 15718">
							  <g id="Group_15717" data-name="Group 15717">
								<g id="Group_15716" data-name="Group 15716">
								  <path id="Path_2338" data-name="Path 2338" d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4" transform="translate(-36.046 -39.347)" fill="#ff7135"/>
								</g>
							  </g>
							</g>
						  </g>
						</svg>

						</button>	  
					   <span class="highlight"></span>
					   <label>Signature</label>
					   <div class="docselected"><img src="assets/images/svg/attach.svg"><span>Signature.jpg</span></div>
					</div>
				</div>
				<a href="javascript:;" (click)="open(content)" class="link-alt fs14">What is signature & its image guidelines?</a>

				<div class="form-container mt-20 mb-0 p-eml-rt">
					<div class="floating-label mb-10 flselcted"> 
						<input type="text" class="form-control" placeholder=" ">
						<button class="btn btn-outline-secondary fs16 btn-o-default" type="button">
							<span>Change</span>
							<svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107" viewBox="0 0 18.71 12.107">
						  <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
							<path id="Path_2337" data-name="Path 2337" d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z" transform="translate(-14.582 -27.082)" fill="#ff7135"/>
							<g id="Group_15718" data-name="Group 15718">
							  <g id="Group_15717" data-name="Group 15717">
								<g id="Group_15716" data-name="Group 15716">
								  <path id="Path_2338" data-name="Path 2338" d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4" transform="translate(-36.046 -39.347)" fill="#ff7135"/>
								</g>
							  </g>
							</g>
						  </g>
						</svg>

						</button>	  
					   <span class="highlight"></span>
					   <label>Logo</label>
					   <div class="docselected"><img src="assets/images/svg/attach.svg"><span>Logo.jpg</span></div>
					</div>
				</div>
			</div>
			<div class="form-container mb-0">
				<div class="floating-label mb-10">      
				  <input class="form-control pr-5" placeholder=" " value="EB12345">
				  <span class="highlight"></span>
				  <label>Invoice Prefix</label>
				  <i placement="top" ngbTooltip="Invoicing Prefix" class="infoinput info-alert"></i>
				</div>
			</div>
			<div class="fs14 text-muted">Example Invoice No: EB123451001001</div>

			<div class="mt-30">
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Save</a>
			</div>
		</div>
	</div>
</div>
<app-footer-seller></app-footer-seller>
<!-- CHANGE PASSWORD -->

<!-- VIEW IMAGE GUIDELINES MODAL -->
<ng-template #content let-modal>
  <div class="modal-header nobd">
    <h4 class="modal-title fs20 mt-2 wt700 txtdefault text-center">Signature & Image Guidelines</h4>
    <button type="button" class="btn-close close nobd bg-transparent " aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
  </div>
  <div class="modal-body img-g-list">
	<div class="mb-3 pb-3 th-cmn-bd">
		<h4 class="fs16 wt700 txtdefault  mb-2">What is signature and why it’s needed?</h4>
		<p class="txtseconday mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>		
	</div>
	<div class="mb-3 pb-3 th-cmn-bd">
		<h4 class="fs16 wt700 txtdefault  mb-2">File Format </h4>
		<p class="txtseconday mb-0">1. PNG, JPEG, JPG files are supported only.</p>
	</div>
	<div class="mb-3 pb-3 th-cmn-bd nobd">
		<h4 class="fs16 wt700 txtdefault  mb-2">Following Images won’t work</h4>
		<ol class="mb-0">
			<li><p class="txtseconday mb-0">Graphic/ Inverted/ Pixelated image won’t work.</p>
			<li><p class="txtseconday mb-0">Images should not contain price/brand logo for the product.</p>
			<li><p class="txtseconday mb-0">Partial signature image won’t work.</p>
			<li><p class="txtseconday mb-0">Offensive/Objectionable images won’t work.</p>	
		</ol>		
	</div>
  </div>
</ng-template>
<!-- /VIEW IMAGE GUIDELINES MODAL -->

<app-footer-seller></app-footer-seller>