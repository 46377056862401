import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BuyerTrackOrdersService {

    constructor(private _http: HttpClient) { }

    getorders(data) {
        return this._http.post(environment.api_url+'buyer/myorders',data);
    }

    payremainingamount(data) {
      return this._http.post(environment.api_url+'buyer/order/payremaining',data);
    }

    acceptqcorder(data) {
      return this._http.post(environment.api_url+'buyer/order/acceptqcstatus',data);
    }

    cancelorder(data) {
      return this._http.post(environment.api_url+'buyer/order/cancel',data);
    }

    returnrequest(data) {
      return this._http.post(environment.api_url+'buyer/order/returnrequest',data);
    }

}
