import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MakePaymentService {

  constructor(private _http: HttpClient) { }

    userbasicinfo(data) {
        return this._http.post(environment.api_url+'admin/userbasicinfo',data);
    }

    searchuser(data) {
        return this._http.post(environment.api_url+'admin/user/autocomplete',data);
    }

    makepayment(data) {
        return this._http.post(environment.api_url+'admin/wallet/makepayment',data);
    }

    getbalance(data) {
      return this._http.post(environment.api_url+'admin/user/wallet/balance',data);
    }

    generatechargeinvoice(data) {
      return this._http.post(environment.api_url+'admin/order/chargegenerateinvoice',data);
    }


}
