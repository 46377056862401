<aside class="aside-seller set-new-position" [ngClass]="showside ? 'sidebarslide' : ''">
	<a (click)="showsidebar()" class="asidelg" [routerLink]="['/home']"><img src="assets/images/svg/eq_logo.svg"></a>
	<a (click)="showsidebar()" href="javascript:void(0);" class="asidetoggle">MENU</a>
	<h4 class="fs18 txtdefault mt-0 mb-4 mb-xs-10 pt-xs-0 mrhead"><i (click)="showsidebar()"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		  <g id="Group_14366" data-name="Group 14366" transform="translate(-140 -468)">
			<path id="back_3_" data-name="back (3)" d="M19.288,10.1H3.973L8.583,5.487A.871.871,0,1,0,7.351,4.255l-6.1,6.1a.871.871,0,0,0,0,1.231l6.1,6.1a.871.871,0,1,0,1.231-1.231L3.973,11.838H19.288a.871.871,0,0,0,0-1.742Z" transform="translate(141.42 469.033)" fill="#1f1d44"/>
			<g id="Rectangle_1447" data-name="Rectangle 1447" transform="translate(140 468)" fill="none" stroke="#707070" stroke-width="1" opacity="0">
			  <rect width="24" height="24" stroke="none"/>
			  <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
			</g>
		  </g>
		</svg></i> EB Dashboard</h4>
	<ngb-accordion  [closeOthers]="true" #acc="ngbAccordion" >
		<ngb-panel>
			<ng-template ngbPanelHeader>
			  <div class="d-flex align-items-center justify-content-between">
				<button  class="btn btn-link alt noarrow" [routerLink]="['/buyer/dashboard']">
					<svg xmlns="http://www.w3.org/2000/svg" width="20.737" height="13.226" viewBox="0 0 20.737 13.226">
						<path id="np_dashboard_637107_000000" d="M22.77,24.09a8.87,8.87,0,0,0-9.141,8.8c.114,3.2,1.371,3.428,4.113,3.428H28.254c2.743,0,3.657-.457,3.657-3.428A9.164,9.164,0,0,0,22.77,24.09Zm1.713,6.171s-.394.737-.736,1.422-.427.853-.634,1.32a1.232,1.232,0,0,1,0,1.257,1.117,1.117,0,0,1-1.6.457,1.179,1.179,0,0,1-.571-1.6c.229-.343.571-.8,1.143-.685l1.371-2.743c.114-.229.343-.685.914-.343a.542.542,0,0,1,.114.914Zm5.6,2.628h-.571c-.229,0-.457-.343-.571-.571a5.953,5.953,0,0,0-6.17-5.141A5.948,5.948,0,0,0,16.6,32.432a.612.612,0,0,1-.571.571h-.571a.64.64,0,0,1-.571-.685,7.526,7.526,0,0,1,7.884-6.856,7.57,7.57,0,0,1,7.77,6.856c.114.228-.115.57-.458.57Z" transform="translate(-13.126 -23.59)" fill="#5e6a7c" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" opacity="0.8"/>
					</svg>
					Dashboard 
				</button>					
			  </div>
			</ng-template>
		</ngb-panel>
		<ngb-panel>
			<ng-template ngbPanelHeader>
			  <div class="d-flex align-items-center justify-content-between">
				
				<button ngbPanelToggle class="btn btn-link alt">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12.973" height="15" viewBox="0 0 12.973 15">
					  <defs>
						<linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
						  <stop offset="0" stop-color="#79b7ff"/>
						  <stop offset="1" stop-color="#07f"/>
						</linearGradient>
					  </defs>
					  <path id="np_profile_1594246_000000" d="M46.973,46.595a.406.406,0,0,1-.4.405H34.4a.407.407,0,0,1-.4-.405,6.486,6.486,0,0,1,12.973,0Zm-6.486-7.3a3.649,3.649,0,1,1,3.649-3.649A3.65,3.65,0,0,1,40.486,39.3Z" transform="translate(-34 -32)" fill-rule="evenodd" fill="url(#linear-gradient)"/>
					</svg>
				Account Profile
				</button>
			  </div>
			</ng-template>
			<ng-template ngbPanelContent>
				<ul class="nav flex-column">
				  <li class="nav-item">
					<a href="javascript:;" class="nav-link active fs14 textsecondary" [routerLink]="['/buyer/personal-details']">Personal Details</a>
				  </li>
				  <li class="nav-item">
					<a href="javascript:;" class="nav-link fs14 textsecondary" [routerLink]="['/buyer/business-details']">Business Details</a>
				  </li>
				  <li class="nav-item ">
					<a href="javascript:;" class="nav-link fs14 textsecondary" [routerLink]="['/buyer/bank-details']">Bank Details</a>
				  </li>				 
				</ul>  
			</ng-template>
		</ngb-panel>
		
		<ngb-panel>
			<ng-template ngbPanelHeader>
			  <div class="d-flex align-items-center justify-content-between">
				<button  class="btn btn-link alt noarrow" [routerLink]="['/buyer/buyer-buy-now-pay-later']">
					<svg xmlns="http://www.w3.org/2000/svg" width="20.737" height="13.226" viewBox="0 0 20.737 13.226">
						<path id="np_dashboard_637107_000000" d="M22.77,24.09a8.87,8.87,0,0,0-9.141,8.8c.114,3.2,1.371,3.428,4.113,3.428H28.254c2.743,0,3.657-.457,3.657-3.428A9.164,9.164,0,0,0,22.77,24.09Zm1.713,6.171s-.394.737-.736,1.422-.427.853-.634,1.32a1.232,1.232,0,0,1,0,1.257,1.117,1.117,0,0,1-1.6.457,1.179,1.179,0,0,1-.571-1.6c.229-.343.571-.8,1.143-.685l1.371-2.743c.114-.229.343-.685.914-.343a.542.542,0,0,1,.114.914Zm5.6,2.628h-.571c-.229,0-.457-.343-.571-.571a5.953,5.953,0,0,0-6.17-5.141A5.948,5.948,0,0,0,16.6,32.432a.612.612,0,0,1-.571.571h-.571a.64.64,0,0,1-.571-.685,7.526,7.526,0,0,1,7.884-6.856,7.57,7.57,0,0,1,7.77,6.856c.114.228-.115.57-.458.57Z" transform="translate(-13.126 -23.59)" fill="#5e6a7c" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" opacity="0.8"/>
					</svg>
					Equibiz Pay Later 
				</button>					
			  </div>
			</ng-template>
			
		</ngb-panel>
		<ngb-panel>
			<ng-template ngbPanelHeader>
			  <div class="d-flex align-items-center justify-content-between">
				<button ngbPanelToggle class="btn btn-link alt">
					<svg xmlns="http://www.w3.org/2000/svg" width="15.473" height="15.522" viewBox="0 0 15.473 15.522">
					  <g id="np_wallet_1594672_000000" transform="translate(-2.7 -2.5)" opacity="0.8">
						<path id="Path_2329" data-name="Path 2329" d="M12.977,13.137v-1.8a1.855,1.855,0,0,1,1.846-1.846h2.418V8.17A1.346,1.346,0,0,0,15.9,6.83V6.062a1.75,1.75,0,0,0-1.748-1.748H12.161L10.853,2.5,8.321,4.314H4.432A1.729,1.729,0,0,0,2.7,5.965V16.437a1.586,1.586,0,0,0,1.585,1.585H15.657a1.586,1.586,0,0,0,1.585-1.585V14.983H14.823a1.844,1.844,0,0,1-1.846-1.846Zm1.176-7.843a.767.767,0,0,1,.768.768V6.83h-.964L12.863,5.294ZM12.748,6.83H6.49l4.134-2.974ZM4.432,5.294H6.965L4.824,6.83H4.432a.768.768,0,1,1,0-1.536Z" fill="#5e6a7c"/>
						<path id="Path_2330" data-name="Path 2330" d="M74,49.6H71.042A1.137,1.137,0,0,0,69.9,50.746v1.8a1.137,1.137,0,0,0,1.144,1.144H74a.4.4,0,0,0,.392-.392v-3.3A.387.387,0,0,0,74,49.6Zm-2.337,2.892a.842.842,0,1,1,0-1.683.841.841,0,0,1,.834.849A.817.817,0,0,1,71.663,52.494Z" transform="translate(-56.219 -39.406)" fill="#5e6a7c"/>
					  </g>
					</svg>
				Wallet & Payments
				</button>
			  </div>
			</ng-template>
			<ng-template ngbPanelContent>
				<ul class="nav flex-column">
				  <li class="nav-item">
					<a href="javascript:;" [routerLink]="['/buyer/buyer-wallet']" class="nav-link active fs14 textsecondary">My Wallet</a>
				  </li>
				  <li class="nav-item">
					<a href="javascript:;" [routerLink]="['/buyer/buyer-manage-payment-methods']" class="nav-link fs14 textsecondary">Manage Payment Methods</a>
				  </li>
				  <li class="nav-item ">
					<a href="javascript:;" [routerLink]="['/buyer/buyer-transaction-history']" class="nav-link fs14 textsecondary">Transaction History</a>
				  </li>				 
				</ul>  
			</ng-template>
		</ngb-panel>
		<ngb-panel>
			<ng-template ngbPanelHeader>
			  <div class="d-flex align-items-center justify-content-between">
				<button ngbPanelToggle class="btn btn-link alt ">
					<svg xmlns="http://www.w3.org/2000/svg" width="15.613" height="16.784" viewBox="0 0 15.613 16.784">
					  <path id="np_box_848942_000000" d="M17.806,7,20.38,8.031l-6.849,3.08-3-1.2Zm4.4,1.763,2.873,1.147-7.276,2.909-2.744-1.1Zm3.4,1.781V20.856L18.2,23.784V13.507ZM10,10.543l3.123,1.25v3.013l1.561.585V12.416l2.732,1.092V23.784L10,20.856Z" transform="translate(-10 -7)" fill="#5e6a7c" opacity="0.8"/>
					</svg>	All Orders 
				</button>
			  </div>
			</ng-template>
			<ng-template ngbPanelContent>
				<ul class="nav flex-column">
					<li class="nav-item ">
						<a href="javascript:;" [routerLink]="['/buyer/bids-offers']" class="nav-link fs14 textsecondary" href="javascript:void(0);">Bids & Offers</a>
					</li>
					<li class="nav-item ">
						<a href="javascript:;" [routerLink]="['/buyer/my-bids']" class="nav-link fs14 textsecondary" href="javascript:void(0);">My Bids</a>
					</li>
				  <li class="nav-item ">
					<a href="javascript:;" [routerLink]="['/buyer/buyer-track-orders']" class="nav-link fs14 textsecondary" href="javascript:void(0);">Track My Orders</a>
				  </li>	
				  <li class="nav-item ">
					<a href="javascript:;" [routerLink]="['/buyer/order-history']" class="nav-link fs14 textsecondary" href="javascript:void(0);">Order History</a>
				  </li>		 
				</ul> 
			</ng-template>
		</ngb-panel>

		<ngb-panel>
			<ng-template ngbPanelHeader>
			  <div class="d-flex align-items-center justify-content-between">
				<button ngbPanelToggle class="btn btn-link alt">
					<svg xmlns="http://www.w3.org/2000/svg" width="15.973" height="15.973" viewBox="0 0 15.973 15.973">
					    <path data-name="Path 8691" d="M61.318 23.332a7.986 7.986 0 1 0 7.982 7.986 7.985 7.985 0 0 0-7.982-7.986zm4.09 8.482-3.524 3.663a.8.8 0 0 1-1.129 0l-3.524-3.663a2.395 2.395 0 1 1 3.386-3.388l.7.7.7-.7a2.4 2.4 0 1 1 3.388 3.388z" transform="translate(-53.332 -23.332)" style="fill:#5e6a7c;opacity:.8"/>
					</svg>
				My Wishlist 
				</button>
			  </div>
			</ng-template>
			<ng-template ngbPanelContent>
				<ul class="nav flex-column">
				  <li class="nav-item">
					<a class="nav-link  fs14 textsecondary" [routerLink]="['/buyer/buyer-wishlist']" href="javascript:void(0);">Wishlist</a>
				  </li>			 
				</ul> 
			</ng-template>
		</ngb-panel>
		<ngb-panel>
			<ng-template ngbPanelHeader>
			  <div class="d-flex align-items-center justify-content-between">
				<button ngbPanelToggle class="btn btn-link alt">
					<svg xmlns="http://www.w3.org/2000/svg" width="15.318" height="15.318" viewBox="0 0 15.318 15.318">
					    <path d="M27.818 22.712V25.9a1.915 1.915 0 0 1-1.918 1.918h-3.188A1.915 1.915 0 0 1 20.8 25.9v-3.188a1.915 1.915 0 0 1 1.912-1.912H25.9a1.915 1.915 0 0 1 1.918 1.912zM17.606 12.5h-3.191a1.915 1.915 0 0 0-1.915 1.915v3.191a1.915 1.915 0 0 0 1.915 1.915h3.191a1.915 1.915 0 0 0 1.915-1.915v-3.191a1.915 1.915 0 0 0-1.915-1.915zm6.7 7.021a3.516 3.516 0 1 0-2.48-1.03 3.51 3.51 0 0 0 2.48 1.03zm-6.7 1.277h-3.191a1.915 1.915 0 0 0-1.915 1.914V25.9a1.915 1.915 0 0 0 1.915 1.915h3.191a1.915 1.915 0 0 0 1.915-1.915v-3.188a1.915 1.915 0 0 0-1.915-1.912z" transform="translate(-12.5 -12.5)" style="fill:#5e6a7c;opacity:.8"/>
					</svg>
				Select Product Categories
				</button>
			  </div>
			</ng-template>
			<ng-template ngbPanelContent>
				<ul class="nav flex-column">
				  <li class="nav-item">
					<a class="nav-link fs14 textsecondary" [routerLink]="['/buyer/manage-categories']">Manage Categories</a>
				  </li>		 
				</ul> 
			</ng-template>
		</ngb-panel>
		<ngb-panel>
			<ng-template ngbPanelHeader>
			  <div class="d-flex align-items-center justify-content-between">
				<button ngbPanelToggle class="btn btn-link alt">
					<svg xmlns="http://www.w3.org/2000/svg" width="17.526" height="17.526" viewBox="0 0 17.526 17.526">
					  <path id="np_tax_3953297_000000" d="M18.946,9.135V6.59l3.122,3.122H19.523A.577.577,0,0,1,18.946,9.135Zm3.463,1.731v9.811a1.732,1.732,0,0,1-1.731,1.731H14.329A5.771,5.771,0,0,0,9.712,12.136V7.981a1.732,1.732,0,0,1,1.731-1.731h6.348V9.135a1.732,1.732,0,0,0,1.731,1.731Zm-5.771-.577a.576.576,0,0,0-.577-.577H12.6a.577.577,0,0,0,0,1.154h3.463a.576.576,0,0,0,.577-.577ZM20.1,18.946a.576.576,0,0,0-.577-.577H18.369a.577.577,0,0,0,0,1.154h1.154a.576.576,0,0,0,.577-.577Zm0-2.886a.576.576,0,0,0-.577-.577H18.369a.577.577,0,1,0,0,1.154h1.154a.576.576,0,0,0,.577-.577Zm0-2.886a.576.576,0,0,0-.577-.577H17.215a.577.577,0,0,0,0,1.154h2.308a.576.576,0,0,0,.577-.577Zm-4.617,4.617a4.618,4.618,0,1,1-1.353-3.264A4.615,4.615,0,0,1,15.483,17.792ZM9.135,16.638a.577.577,0,1,0,.577-.577.577.577,0,0,0-.577.577ZM12.6,18.946a.576.576,0,1,0-.169.408.577.577,0,0,0,.169-.408Zm-.167-2.718a.577.577,0,0,0-.82,0L9.3,18.536a.579.579,0,1,0,.82.82l2.308-2.308a.577.577,0,0,0,0-.82Z" transform="translate(-5.75 -5.383)" fill="#5e6a7c" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" opacity="0.8"/>
					</svg> Deal Invoices and Documents
				</button>
			  </div>
			</ng-template>
			<ng-template ngbPanelContent>
				<ul class="nav flex-column">
				  <li class="nav-item">
					<a href="javascript:;" class="nav-link  fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/buyer/invoice']">Order Invoices</a>
				  </li>
				  <li class="nav-item">
					<a href="javascript:;" class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/buyer/charges-invoice']">Charges Invoices</a>
				  </li>
				  <li class="nav-item ">
					<a href="javascript:;" class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/buyer/transportation-documents']">Transportation Docs</a>
				  </li>				 
				</ul>  
			</ng-template>
		</ngb-panel>
		<ngb-panel>
			<ng-template ngbPanelHeader>
			  <div class="d-flex align-items-center justify-content-between">
				<button ngbPanelToggle class="btn btn-link alt">
					<svg xmlns="http://www.w3.org/2000/svg" width="14.875" height="17" viewBox="0 0 14.875 17">
					  <g id="np_report_3888336_000000" transform="translate(-6.25)" opacity="0.8">
						<path id="Path_2331" data-name="Path 2331" d="M34.375.531A.531.531,0,0,1,34.906,0h4.25a.531.531,0,0,1,.531.531V2.656a.531.531,0,0,1-.531.531h-4.25a.531.531,0,0,1-.531-.531Z" transform="translate(-23.344)" fill="#5e6a7c"/>
						<path id="Path_2332" data-name="Path 2332" d="M8.906,6.25H9.969V8.729a.727.727,0,0,0,.744.709h5.95a.727.727,0,0,0,.744-.709V6.25h1.063a2.656,2.656,0,0,1,2.656,2.656V19.531a2.656,2.656,0,0,1-2.656,2.656H8.906A2.656,2.656,0,0,1,6.25,19.531V8.906A2.656,2.656,0,0,1,8.906,6.25ZM10.5,18.469a.531.531,0,0,0,1.063,0V12.094a.531.531,0,0,0-1.063,0ZM13.688,19a.531.531,0,0,1-.531-.531v-4.25a.531.531,0,0,1,1.063,0v4.25A.531.531,0,0,1,13.688,19Zm2.656,0a.531.531,0,0,1-.531-.531V16.344a.531.531,0,0,1,1.062,0v2.125A.531.531,0,0,1,16.344,19Z" transform="translate(0 -5.188)" fill="#5e6a7c" fill-rule="evenodd"/>
					  </g>
					</svg>
					Business Reports
				</button>
			  </div>
			</ng-template>
			<ng-template ngbPanelContent>
				<ul class="nav flex-column">
				  <li class="nav-item">
					<a href="javascript:;" class="nav-link  fs14 textsecondary" href="javascript:void(0);">Tax Report</a>
				  </li>
				  <li class="nav-item">
					<a href="javascript:;" class="nav-link fs14 textsecondary" href="javascript:void(0);">Purchase Report</a>
				  </li>
				  <li class="nav-item ">
					<a href="javascript:;" class="nav-link fs14 textsecondary" href="javascript:void(0);">Payment Report</a>
				  </li>	
				  <li class="nav-item ">
					<a href="javascript:;"  class="nav-link fs14 textsecondary" href="javascript:void(0);">Order Report</a>
				  </li>		
				  <li class="nav-item ">
					<a href="javascript:;"  class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/buyer/bnpl/transaction/reports']">BNPL Transaction Report</a>
				  </li>				 
				</ul>  
			</ng-template>
		</ngb-panel>
		<ngb-panel>
			<ng-template ngbPanelHeader>
			  <div class="d-flex align-items-center justify-content-between">
				<button  class="btn btn-link alt noarrow" (click)="logout()">
					<svg xmlns="http://www.w3.org/2000/svg" width="15.231" height="20.444" viewBox="0 0 15.231 20.444">
						<g id="np_log-out_738609_000000" opacity="0.8">
						<path id="Path_2333" data-name="Path 2333" d="M12.929.319q2.968.749,5.938,1.487c.174.043.18.147.18.287q.006,3.19.016,6.38.008,4.62.017,9.239c0,.185-.041.276-.225.34q-3.349,1.154-6.691,2.326c-.061.021-.123.035-.213.061-.008-.149-.02-.28-.021-.409q-.031-3.31-.062-6.619-.057-5.619-.117-11.238C11.744,1.447,11.73.722,11.719,0h.039c.388.108.778.224,1.171.323Zm-.353,10.475a.4.4,0,0,0,.64-.043.832.832,0,0,0,.1-.863.507.507,0,0,0-.493-.348.976.976,0,0,0-.245.147.862.862,0,0,0,0,1.107Z" transform="translate(-3.849 0.004)" fill="#5e6a7c"/>
						<path id="Path_2334" data-name="Path 2334" d="M54.971,7.508c-.173,0-.235.042-.233.232.009,1.213,0,2.426,0,3.64v.41c-.311,0-.626,0-.932-.009-.03,0-.078-.123-.078-.189-.006-.753,0-1.506,0-2.26V6.693c0-.12,0-.214.169-.212,1.18.006,2.361,0,3.542,0h0c.033,0,.067,0,.1.01V7.512H57.3c-.774,0-1.551,0-2.331,0Z" transform="translate(-50.661 -5.155)" fill="#5e6a7c"/>
						<path id="Path_2335" data-name="Path 2335" d="M53.136,67.577c.263.011.533,0,.805,0v3.631H56.9V72.26c-.078,0-.119.01-.176.01-1.192,0-2.384,0-3.575,0-.176,0-.217-.055-.216-.225q.008-2.129,0-4.257c0-.166.047-.22.207-.216Z" transform="translate(-49.863 -53.76)" fill="#5e6a7c"/>
						<path id="Path_2336" data-name="Path 2336" d="M57.468,39.009a.637.637,0,0,0,.272.394q.889.679,1.772,1.366a.468.468,0,0,0,.73-.156h0a.67.67,0,0,0,.05-.233c.008-.15,0-.3,0-.462a3.187,3.187,0,0,1,1.963.668,3.368,3.368,0,0,1,1.191,1.736,3.364,3.364,0,0,0-3.155-4.347V37.5h0a.477.477,0,0,0-.171-.378.461.461,0,0,0-.6,0c-.23.176-.459.354-.688.53-.363.28-.724.562-1.089.839a.627.627,0,0,0-.272.394v.12Z" transform="translate(-57.468 -29.444)" fill="#5e6a7c"/>
						</g>
					</svg>
					Logout 
				</button>					
			  </div>
			</ng-template>
			
		</ngb-panel>
		
	</ngb-accordion>
</aside>