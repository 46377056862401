<div class="row">
	<!-- FILTER -->
	<div class="col-lg-3 mt-4 pt-2 filtercol filtermbl" [ngClass]="classstatus ? 'showfilter' : ''">
		<div class="fltrshead"> 
			<h4>Filter</h4> 
			<a  (click)="tglclass()"  class="closefilter" href="javascript:void(0);"><svg _ngcontent-serverApp-c132="" xmlns="http://www.w3.org/2000/svg" width="6.508" height="6.508" viewBox="0 0 6.508 6.508"><path _ngcontent-serverApp-c132="" id="np_cross_1707162_000000" d="M30.883,30.883a.507.507,0,0,1,.717,0l2.389,2.389,2.389-2.389a.507.507,0,0,1,.717.717L34.7,33.988l2.389,2.389a.507.507,0,0,1-.717.717L33.988,34.7,31.6,37.094a.507.507,0,0,1-.717-.717l2.389-2.389L30.883,31.6A.507.507,0,0,1,30.883,30.883Z" transform="translate(-30.734 -30.734)" fill="#1f1d44"></path></svg></a>
		</div>
		<div class="lsting">
			<ngb-accordion #acc="ngbAccordion" activeIds="{{ active_ids }}" class="accordion">
				<ngb-panel id="toggle-1" opened class="bg-transparent" title="Market Preference">
					<ng-template ngbPanelContent >
						<ul class="list-group">
							<li class="list-group-item" *ngFor="let mlist of filter_marketplace; let i = index;">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox"  [checked]="mlist.selected" (change)="createparams('marketplace', mlist)"/>
									<label class="css-label">{{ mlist.name }}</label>
								</div>								
							</li>
						</ul>
					</ng-template>
				</ngb-panel>
				<ngb-panel id="toggle-2" title="Product Types">
					<ng-template ngbPanelContent>
						<ul class="list-group">
							<li class="list-group-item" *ngFor="let category of filter_category; let i = index;">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" value="{{ category.slug }}"  [checked]="category.selected" (change)="createparams('category', category)"/>
									<label class="css-label">{{ category.name }} ({{ category.count }})</label>
								</div>								
							</li>
						</ul>
					</ng-template>
				</ngb-panel>
				<!-- <ngb-panel id="toggle-3" title="Model">
					<ng-template ngbPanelContent>
						<div class="input-group mb-3 inptsearch">
						  <span class="input-group-text">
							<svg xmlns="http://www.w3.org/2000/svg" width="13.685" height="13.681" viewBox="0 0 13.685 13.681">
  <path id="np_search_1120430_000000" d="M29.587,29.163l-2.728-2.748A5.6,5.6,0,0,0,28.3,22.649a5.649,5.649,0,1,0-5.65,5.669,5.578,5.578,0,0,0,3.767-1.461l2.728,2.728a.281.281,0,0,0,.423,0,.261.261,0,0,0,.019-.422Zm-6.937-1.5a5.025,5.025,0,1,1,5.034-5.016A5.052,5.052,0,0,1,22.65,27.665Z" transform="translate(-16.501 -16.5)" fill="#5e6a7c" stroke="#5e6a7c" stroke-width="1"/>
</svg>

						  </span>
						  <input type="text" class="form-control" placeholder="Search here" >
						</div>
						<ul class="list-group">
							<li class="list-group-item">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" />
									<label class="css-label">A31</label>
								</div>								
							</li>
							<li class="list-group-item">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" />
									<label class="css-label">A15</label>
								</div>
							</li>
							<li class="list-group-item">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" />
									<label class="css-label">A21</label>
								</div>								
							</li>
							<li class="list-group-item">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" />
									<label class="css-label">F5</label>
								</div>
							</li>
							<li class="list-group-item">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" />
									<label class="css-label">F17</label>
								</div>								
							</li>
							<li class="list-group-item">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" />
									<label class="css-label">Reno2</label>
								</div>
							</li>
						</ul>
						<a class="vwmre" href="javascript:void(0);">View More</a>
					</ng-template>
				</ngb-panel> -->
				<ngb-panel id="brand" title="Brands">
					<ng-template ngbPanelContent>
						<div class="input-group mb-3 inptsearch">
						  <span class="input-group-text">
							<svg xmlns="http://www.w3.org/2000/svg" width="13.685" height="13.681" viewBox="0 0 13.685 13.681">
  <path id="np_search_1120430_000000" d="M29.587,29.163l-2.728-2.748A5.6,5.6,0,0,0,28.3,22.649a5.649,5.649,0,1,0-5.65,5.669,5.578,5.578,0,0,0,3.767-1.461l2.728,2.728a.281.281,0,0,0,.423,0,.261.261,0,0,0,.019-.422Zm-6.937-1.5a5.025,5.025,0,1,1,5.034-5.016A5.052,5.052,0,0,1,22.65,27.665Z" transform="translate(-16.501 -16.5)" fill="#5e6a7c" stroke="#5e6a7c" stroke-width="1"/>
</svg>

						  </span>
						  <input type="text" class="form-control" placeholder="Search here" >
						</div>
						<ul class="list-group">
							<li class="list-group-item" *ngFor="let brand of filter_brands; let i = index;">
								<!-- | filter:{name: brand_search} -->
								<div class="css-check">
									<input class="css-checkbx" type="checkbox"  value="brand.slug" [checked]="brand.selected" (change)="createparams('brand', brand)"/>
									<label class="css-label">{{ brand.brand_name }}</label>
								</div>								
							</li>
						</ul>
						<a class="vwmre" href="javascript:void(0);">View More</a>
					</ng-template>
				</ngb-panel>
				<ngb-panel id="startingprice" title="Starting Price">
					<ng-template ngbPanelContent>
						<div class="input-group mb-3 inptsearch">
						  <span class="input-group-text">
							<svg xmlns="http://www.w3.org/2000/svg" width="13.685" height="13.681" viewBox="0 0 13.685 13.681">
								 <path id="np_search_1120430_000000" d="M29.587,29.163l-2.728-2.748A5.6,5.6,0,0,0,28.3,22.649a5.649,5.649,0,1,0-5.65,5.669,5.578,5.578,0,0,0,3.767-1.461l2.728,2.728a.281.281,0,0,0,.423,0,.261.261,0,0,0,.019-.422Zm-6.937-1.5a5.025,5.025,0,1,1,5.034-5.016A5.052,5.052,0,0,1,22.65,27.665Z" transform="translate(-16.501 -16.5)" fill="#5e6a7c" stroke="#5e6a7c" stroke-width="1"/>
							</svg>
						  </span>
						  <input type="text" class="form-control" placeholder="Search here" >
						</div>
						<ul class="list-group">
							<li class="list-group-item" *ngFor="let price of filter_prices; let i = index;">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" [checked]="price.selected" (change)="createparams('price', price)"/>
									<label class="css-label">{{ price.name }}</label>
								</div>								
							</li>						
						</ul>
						<ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options" (click)="createparams('custom_price', '')"></ngx-slider>
						<div class="row g-2">
							<div class="col-6">
								<input class="form-control" type="number" [(ngModel)]="minValue" (change)="createparams('custom_price', '')">
							</div>
							<div class="col-6">
								<input class="form-control" type="number" [(ngModel)]="maxValue" (change)="createparams('custom_price', '')">
							</div>
						</div>
					</ng-template>
				</ngb-panel>
				<ngb-panel *ngFor="let variant of filter_variants; let i = index;" id="{{variant.slug}}" title="{{ variant.name }}" >
					<ng-template ngbPanelContent>
						<ul class="list-group">
							<li class="list-group-item" *ngFor="let meta of variant.metas; let k = index;">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" [checked]="meta.selected" (change)="createparams('variant', meta)"/>
									<label class="css-label">{{ meta.name }}</label>
								</div>								
							</li>
						</ul>
					</ng-template>
				</ngb-panel>
				<!-- <ngb-panel id="activationstatus" title="Activation Status">
					<ng-template ngbPanelContent>
						<ul class="list-group">
							<li class="list-group-item">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" />
									<label class="css-label">Activated</label>
								</div>								
							</li>
							<li class="list-group-item">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" />
									<label class="css-label">Sealed Packed</label>
								</div>
							</li>
							<li class="list-group-item">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" />
									<label class="css-label">Sealed Packed with master 
carton</label>
								</div>								
							</li>
							
						</ul>						
					</ng-template>
				</ngb-panel>
				<ngb-panel id="supplylocation" title="Supply Location">
					<ng-template ngbPanelContent>
						<ul class="list-group">
							<li class="list-group-item">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" />
									<label class="css-label">Bhiwandi</label>
								</div>								
							</li>
							<li class="list-group-item">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" />
									<label class="css-label">Jaipur</label>
								</div>
							</li>
							<li class="list-group-item">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" />
									<label class="css-label">Mumbai</label>
								</div>								
							</li>
							
						</ul>						
					</ng-template>
				</ngb-panel>
				<ngb-panel id="countryorigin" title="Country of origin">
					<ng-template ngbPanelContent>
						<ul class="list-group">
							<li class="list-group-item">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" />
									<label class="css-label">Made in India</label>
								</div>								
							</li>
							<li class="list-group-item">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" />
									<label class="css-label">Made in Japan</label>
								</div>
							</li>
							<li class="list-group-item">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" />
									<label class="css-label">Made in South Korea</label>
								</div>								
							</li>
							
						</ul>						
					</ng-template>
				</ngb-panel> -->
			</ngb-accordion>
			<div class="tex-center clr-apl-btn showintablet">			
				<a href="javascript:void(0);" class="btn-d-outline mr-3 d-inline-block place-bid">Clear All</a>
				<a href="javascript:void(0);" class="buybtn d-inline-block radius10">Apply</a>
			</div>
		</div>
	</div>
	<!-- /FILTER -->
	<!-- LISTING -->
	<div class="col-lg-9 pt-3 listing-content">
		<!-- SORTING -->
		<div class="row mb-4">
			<div class="col-lg-8  srtlist">
				<h3 class="txtdefault f-700 fs16 mb-3">{{ total_products }} products for {{ seller_name }}</h3>
				<!-- <span class="btn btn-outline-dark f-600 txtdefault">Smart Phones
					<a class="crosstag">
						<svg xmlns="http://www.w3.org/2000/svg" width="6.508" height="6.508" viewBox="0 0 6.508 6.508">
						  <path id="np_cross_1707162_000000" d="M30.883,30.883a.507.507,0,0,1,.717,0l2.389,2.389,2.389-2.389a.507.507,0,0,1,.717.717L34.7,33.988l2.389,2.389a.507.507,0,0,1-.717.717L33.988,34.7,31.6,37.094a.507.507,0,0,1-.717-.717l2.389-2.389L30.883,31.6A.507.507,0,0,1,30.883,30.883Z" transform="translate(-30.734 -30.734)" fill="#1f1d44"/>
						</svg>
					</a>
				</span>
				<span class="btn btn-outline-dark f-600 txtdefault">Oppo
					<a class="crosstag">
						<svg xmlns="http://www.w3.org/2000/svg" width="6.508" height="6.508" viewBox="0 0 6.508 6.508">
						  <path id="np_cross_1707162_000000" d="M30.883,30.883a.507.507,0,0,1,.717,0l2.389,2.389,2.389-2.389a.507.507,0,0,1,.717.717L34.7,33.988l2.389,2.389a.507.507,0,0,1-.717.717L33.988,34.7,31.6,37.094a.507.507,0,0,1-.717-.717l2.389-2.389L30.883,31.6A.507.507,0,0,1,30.883,30.883Z" transform="translate(-30.734 -30.734)" fill="#1f1d44"/>
						</svg>
					</a>
				</span>
				<span class="btn btn-outline-dark f-600 txtdefault">5000 - 10000
					<a class="crosstag">
						<svg xmlns="http://www.w3.org/2000/svg" width="6.508" height="6.508" viewBox="0 0 6.508 6.508">
						  <path id="np_cross_1707162_000000" d="M30.883,30.883a.507.507,0,0,1,.717,0l2.389,2.389,2.389-2.389a.507.507,0,0,1,.717.717L34.7,33.988l2.389,2.389a.507.507,0,0,1-.717.717L33.988,34.7,31.6,37.094a.507.507,0,0,1-.717-.717l2.389-2.389L30.883,31.6A.507.507,0,0,1,30.883,30.883Z" transform="translate(-30.734 -30.734)" fill="#1f1d44"/>
						</svg>
					</a>
				</span>
				<span class="btn btn-outline-dark f-600 txtdefault">Smart Phones
					<a class="crosstag">
						<svg xmlns="http://www.w3.org/2000/svg" width="6.508" height="6.508" viewBox="0 0 6.508 6.508">
						  <path id="np_cross_1707162_000000" d="M30.883,30.883a.507.507,0,0,1,.717,0l2.389,2.389,2.389-2.389a.507.507,0,0,1,.717.717L34.7,33.988l2.389,2.389a.507.507,0,0,1-.717.717L33.988,34.7,31.6,37.094a.507.507,0,0,1-.717-.717l2.389-2.389L30.883,31.6A.507.507,0,0,1,30.883,30.883Z" transform="translate(-30.734 -30.734)" fill="#1f1d44"/>
						</svg>
					</a>
				</span>
				<a class="btn-link nav-btn-link" href="javascript:void(0);">Clear All</a> -->
			</div>
			<div class="col-lg-4 listingsort">
				<div class="fltsortnv mt-0">
					<a href="javascript:void(0);" (click)="ratingsort()" class="sorticon mr-2"> 
						<img src="assets/images/svg/sort.svg">
					</a> 
					<a href="javascript:void(0);" (click)="tglclass()"  class="filtericon">
						<img src="assets/images/svg/filter.svg">
					</a> 
				</div>
				<div class="sortselect" [ngClass]="ratingst ? 'showsort' : ''">
					<div class="fltrshead srthead"> 
					<h4>Sort By</h4> 
						<a  (click)="ratingsort()"  class="closefilter" href="javascript:void(0);"><svg _ngcontent-serverApp-c132="" xmlns="http://www.w3.org/2000/svg" width="6.508" height="6.508" viewBox="0 0 6.508 6.508"><path _ngcontent-serverApp-c132="" id="np_cross_1707162_000000" d="M30.883,30.883a.507.507,0,0,1,.717,0l2.389,2.389,2.389-2.389a.507.507,0,0,1,.717.717L34.7,33.988l2.389,2.389a.507.507,0,0,1-.717.717L33.988,34.7,31.6,37.094a.507.507,0,0,1-.717-.717l2.389-2.389L30.883,31.6A.507.507,0,0,1,30.883,30.883Z" transform="translate(-30.734 -30.734)" fill="#1f1d44"></path></svg></a>
					</div>
					<ng-selectize [config]="config" [options] = "sorting_order" placeholder="Sort By Relevance" ([ngModel])="sort_by"  (ngModelChange)="changesorting()">
						
					</ng-selectize>	
				</div>
				
			</div>
		</div>
		<!-- /SORTING  -->
		<!-- PRODUCT ITEMS -->
		<div class="row p-list-item" *ngIf="productListing.length > 0">
			<!-- REPEAT -->
			<div class="col-6 col-md-4 col-xl-3  mb-3" *ngFor="let product of productListing; let i = index;"> 
				<div class="product-item  pt-2" [routerLink]="['/product/details/'+product.unique_id]">
					<div class="ex-deal" style="display:block" *ngIf="product.total_bid > 0"><span>{{
						product.total_bid }} {{ product.total_bid > 1 ?'Bids': 'Bid' }}</span></div>
				  <div class='image pt-0'>
					<div class="d-table">
						<div class="d-table-cell align-middle">
							<img [src]='product.p_front_image'>
						</div>
					</div>					
				  </div>
				  <div class="row mb-3">
					<div class="col-12  pr-0">
						<div class="product-name fs14">
							{{ product.name }}
						</div>
					</div>
					<!-- <div class="col-6 pr-0 pl-2">
						<span class="buyerbadge txtseconday"><img src="assets/images/svg/buyer.svg">12 Buyers</span>
					</div> -->
				  </div>				 
				  <div class="row no-gutters d-flex justify-content-between">
					<div class="col-5">
					  <div class="seller-count">
						Total Stock <br /> <span>{{ product.total_stock }}</span>
					  </div>
					</div>
					<div class="col-7">
					  <div class="price">
						Starting at <br /><span><i class="fa fa-inr"></i> {{ product.sales_price }} <b class="strikethrough">{{ product.regular_price }}</b></span>
					  </div>
					</div>
				  </div>				  
				</div>
			</div>
			<!-- /REPEAT -->
			
		</div>

		<div class="row">
			<div class="col-md-6">
				<div class="text-left pl-3">
					<nav class="mt-3 tbpg rows-per-page">
						<div>Rows per page:</div>
						<select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
							<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">{{option.name}}</option>
						</select>
					</nav>
				</div> 
			</div>
			<div class="col-md-6" *ngIf="seller_pagination.pages && seller_pagination.pages.length">
				<div class="text-right pr-3">
					<nav class="mt-3 tbpg">
					  <ul class="pagination align-items-center">
						<li class="page-item" [ngClass]="{disabled:seller_pagination.currentPage === 1}"><a class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a></li>

						<li class="page-item" [ngClass]="{disabled:seller_pagination.currentPage === 1}"><a class="page-link sprevicon" href="javascript:;" (click)="setpage(seller_pagination.currentPage - 1)"></a></li>

						<li class="page-item"i *ngFor="let page of seller_pagination.pages" [ngClass]="{'active': seller_pagination.currentPage === page}"><a class="page-link" href="javascript:;" (click)="setpage(page)">{{page}}</a></li>

						<li *ngIf="(seller_pagination.totalPages - seller_pagination.startPage) > 5 ">
							<a (click)="setpage(seller_pagination.startPage + 5)">...</a>
						</li>
						 <li *ngIf="(seller_pagination.totalPages - seller_pagination.startPage) > 5" >
							<a (click)="setpage(seller_pagination.totalPages)">{{seller_pagination.totalPages}}</a>
						</li>
						
						<li class="page-item" [ngClass]="{disabled:seller_pagination.currentPage === seller_pagination.totalPages}"><a class="page-link snexticon" href="javascript:;"  (click)="setpage(seller_pagination.currentPage + 1)"></a></li>
						<li class="page-item" [ngClass]="{disabled:seller_pagination.currentPage === seller_pagination.totalPages}"><a class="page-link nexticon" href="javascript:;" (click)="setpage(seller_pagination.totalPages)"></a></li>
					  </ul>
					</nav>
				</div> 
			</div>
		</div>
	</div>
	<!-- /LISTING -->
</div>


