import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { HsnandGstManagementService } from './hsnandgstmanagement.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ToasterService } from '../../helpers/toastr.service';
import { PaginationService } from 'src/app/helpers/pagination.service';

@Component({
    selector: 'app-hsnandgstmanagement',
    templateUrl: './hsnandgstmanagement.component.html',
    styleUrls: ['./hsnandgstmanagement.component.scss']
})
export class HsnandgstmanagementComponent implements OnInit {

    addnewhsn: FormGroup;
    closeResult = '';
    public addHsnLoading:boolean = false;
    public search_key:string;
    public selected_record:any = {};
    public current_page:number = 1;
    public offset:number = 0;
    public page_per_view:number = 10;
    public hsn_pagination:any = {};
    public hsn_records: any = [];
    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];
  	constructor(
        private modalService: NgbModal,
        private _hsnandgstservice: HsnandGstManagementService,
        private _toastr: ToasterService,
        private _pagination: PaginationService,
        private fb: FormBuilder,
    ) {}

    open(content, record) {
        this.selected_record.id = record.id;
        this.selected_record.gst = record.gst;
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'mblbtm' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    opennew(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'mblbtm' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    addgstrate() {
        this.addHsnLoading = true;
        var data = {
            description: this.addnewhsn.get('description').value,
            product: this.addnewhsn.get('product').value,
            hsn_code: this.addnewhsn.get('hsn_code').value,
            gst: this.addnewhsn.get('gst').value
        }
        this._hsnandgstservice.addnewhsngst(data).subscribe((data) => {
            if(data['status'] == "success"){
                this.addHsnLoading = false;
                this.modalService.dismissAll();
                this._toastr.success('New HSN & GST rate created successfully');
                this.gethsndetails();
            }else if(data['status'] == "error"){
                this.addHsnLoading = false;
              this._toastr.error(data['message']);
            }
        });
    }

    savegstrate() {
        this._hsnandgstservice.savegstrate({value: this.selected_record.gst, id: this.selected_record.id}).subscribe((data) => {
            if(data['status'] == "success"){
                this.modalService.dismissAll();
                this._toastr.success('GST rate updated.');
                this.gethsndetails();
            }else if(data['status'] == "error"){
              this._toastr.error(data['message']);
            }
        });
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page-1)*this.page_per_view;
        this.gethsndetails();
    }

    setpageperview(){
        this.current_page = 1;
        this.gethsndetails();
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
  
    ngOnInit(): void {
        this.gethsndetails();

         //Add New HSN & GST Form
         this.addnewhsn = this.fb.group({
            description: this.fb.control(null, [Validators.required]),
            product: this.fb.control(null),
            hsn_code: this.fb.control("", [Validators.required, Validators.pattern("^[0-9]*$"),]),
            gst: this.fb.control("", [Validators.required, Validators.pattern("^[0-9]*$"),  Validators.maxLength(3)]),
        });
    }

    get an() {
        return this.addnewhsn.controls;
    }

    hsnsearch(){
        this.page_per_view = 10;
        this.offset = 0;
        this.current_page = 1;
        setTimeout(() => {
            this.gethsndetails();
        }, 500);
        
    }

    gethsndetails(){
        let limit = this.page_per_view;
        let offset = this.offset;
        let current_page = this.current_page;
        this._hsnandgstservice.gethsndetails({limit: limit, offset: offset, current_page: current_page, search: this.search_key}).subscribe((data) => {
            if(data['status'] == "success"){
                let total_records = data['total_records'];
                this.hsn_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
                this.hsn_records = data['records'];
            }else if(data['status'] == "error"){
              this._toastr.error(data['message']);
            }
        });
    }

    uploadhsnfile(event){
        const file = event.target.files[0];
        let data: any = new FormData();
        data.append('document', file);
        this._hsnandgstservice.uploadexcel(data).subscribe((data) => {
            if(data['status'] == "success"){
                this._toastr.success('HSN and GST has been imported successfully.');
                event.target.value = '';
                this.gethsndetails();
            }else if(data['status'] == "error"){
                event.target.value = '';
                this._toastr.error(data['message']);
            }
        });
    }

}
