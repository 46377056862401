import { Component, OnInit } from '@angular/core';
import { HeaderAdminService } from './header-admin.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-header-admin',
    templateUrl: './header-admin.component.html',
    styleUrls: ['./header-admin.component.scss']
})
export class HeaderAdminComponent implements OnInit {

    public currentDateTime:string;
    constructor(
       private _headeradminservice: HeaderAdminService,
       private _toastr: ToasterService,
       public datepipe: DatePipe
    ) { }

    public date: any;
    ngOnInit(): void {
        this.currentDateTime =this.datepipe.transform((new Date), 'dd/MM/yyyy, h.mma');
    }

}
