import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { SpiceMoneyUsersService } from './spice-money-users.component.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ToasterService } from '../../helpers/toastr.service';
import { PaginationService } from 'src/app/helpers/pagination.service';
@Component({
  selector: 'app-spice-money-users',
  templateUrl: './spice-money-users.component.html',
  styleUrls: ['./spice-money-users.component.scss']
})
export class SpiceMoneyUsersComponent implements OnInit {

  addspicemoneyuser: FormGroup;
  closeResult = '';
  public adduserLoading:boolean = false;
  public search_key:string;
  public selected_record:any = {};
  public current_page:number = 1;
  public offset:number = 0;
  public page_per_view:number = 10;
  public user_pagination:any = {};
  public user_records: any = [];
  public editButton: boolean = false;
  public editId: string = "";
  public page_per_view_options = [
      {
          value: 10,
          name: 10
      },
      {
          value: 20,
          name: 20
      }
  ];
  constructor(
      private modalService: NgbModal,
      private spiceuserservice: SpiceMoneyUsersService,
      private _toastr: ToasterService,
      private _pagination: PaginationService,
      private fb: FormBuilder,
  ) {}

  public status = [
    {
      value:'active',
      label: "Active",
    },
    {
      value: 'inactive',
      label: "Inactive",
    }
  ];

  config = {
    labelField: "label",
    valueField: "value",
  };

  opennew(content, id) {
    if(id) {
      this.editButton = true;
      this.editId = id;
      let editForm = this.user_records.find(a => a._id === id);
      this.addspicemoneyuser.patchValue({
       'name' : editForm.name,
       'email' : editForm.email,
       'mobile' : editForm.mobile,
       'status' : editForm.status
        });
    }
    else {
      this.editButton = false;
      this.editId = '';
      this.addspicemoneyuser.patchValue({
        'name' : '',
        'email' : '',
        'mobile' : '',
        'status' : ''
         });
    }
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'mblbtm' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  adduser() {
      this.adduserLoading = true;
      var data = {
          name: this.addspicemoneyuser.get('name').value,
          mobile: Number(this.addspicemoneyuser.get('mobile').value),
          email: this.addspicemoneyuser.get('email').value,
          status: this.addspicemoneyuser.get('status').value,
          id: this.editButton ? this.editId : ''
      }
      this.spiceuserservice.addspiceuser(data).subscribe((data) => {
          if(data['status'] == "success"){
              this.adduserLoading = false;
              this.modalService.dismissAll();
              this._toastr.success(`Spice Money User ${!this.editButton ? "Created" : 'Updated'} successfully`);
              this.getspiceuserdetails();
              this.editButton = false;
              this.editId = '';
          }
          else if(data['status'] == "error"){
              this.adduserLoading = false;
            this._toastr.error(data['message']);
          }
      });
  }

  setpage(page) {
      this.current_page = page;
      this.offset = (page-1)*this.page_per_view;
      this.getspiceuserdetails();
  }

  setpageperview(){
      this.current_page = 1;
      this.getspiceuserdetails();
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return `with: ${reason}`;
      }
  }

  ngOnInit(): void {
      this.getspiceuserdetails();
       this.addspicemoneyuser = this.fb.group({
          name: this.fb.control(null, [Validators.required]),
          mobile: this.fb.control("", [Validators.required, Validators.pattern("^[0-9]*$"),  Validators.maxLength(10)]),
          email: this.fb.control(null, [Validators.required, Validators.email]),
          status: this.fb.control(null, [Validators.required]),
      });
  }

  get an() {
      return this.addspicemoneyuser.controls;
  }

  usersearch(){
      this.page_per_view = 10;
      this.offset = 0;
      this.current_page = 1;
      setTimeout(() => {
          this.getspiceuserdetails();
      }, 500);
      
  }

  getspiceuserdetails(){
      let limit = this.page_per_view;
      let offset = this.offset;
      let current_page = this.current_page;
      this.spiceuserservice.getspiceuserdetails({limit: limit, offset: offset, current_page: current_page, search: this.search_key}).subscribe((data) => {
          if(data['status'] == "success"){
              let total_records = data['total_records'];
              this.user_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
              this.user_records = data['records'];
          }else if(data['status'] == "error"){
            this._toastr.error(data['message']);
          }
      });
  }

  uploadhsnfile(event){
      const file = event.target.files[0];
      let data: any = new FormData();
      data.append('sma', file);
      this.spiceuserservice.uploadexcel(data).subscribe((data) => {
          if(data['status'] == "success"){
              this._toastr.success('Spice Users has been imported successfully.');
              event.target.value = '';
              this.getspiceuserdetails();
          }else if(data['status'] == "error"){
              event.target.value = '';
              this._toastr.error(data['message']);
          }
      });
  }
}
