<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<h3 class="fs24 wt700 txtdefault">Access Management</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Settings</a>
						</li>
						<li class="breadcrumb-item active">Access Management</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4 mb-20">
			<h4 class="fs20 f-700">Me</h4>
			<div class="table-responsive">
				<table class="table custom-table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Email Id</th>
							<th>Access Type</th>
							<th>Status</th>
							<th>Last Login</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<div class="d-flex align-items-center">
									<div class="access-img mr-2"><img src="assets/images/user-01.jpg"></div>
									<div>Anil Bapat</div>
								</div>
							</td>
							<td>shubhamjaiswal@gmail.com</td>
							<td>All</td>
							<td>Confirmed</td>
							<td>12/04/2021, 11:54 pm</td>
							<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>	
		<div class="card cardui carduipro p-4 mb-2">
			<h4 class="fs20 f-700">Others</h4>

			<!--Filters-->
			<div class="row d-flex justify-content-between mt-10 mb-20 report-cta">
				<div class="col-md-5">
					<div class="report-input">
						<div class="input-group-custom">
							<input type="text" placeholder="Search by Product name or Transaction ID" class="form-control">
							<div class="input-group-append-custom">
								<img src="assets/images/svg/search_logo.svg">
							</div>
						</div>
					</div>
				</div>

				<div class="col-md-7">
					<div class="text-right">
						<a href="javascript:;" class="mr-sm-4 mr-2 btn-d-outline d-inline-block" [routerLink]="['/admin/settings/access-management/grant-access']">Grant Access</a>
					</div>
				</div>
			</div>
			<div class="table-responsive">
				<table class="table custom-table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Email Id</th>
							<th>Access Type</th>
							<th>Status</th>
							<th>Last Login</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<div class="d-flex align-items-center">
									<div class="access-img mr-2"><img src="assets/images/user-01.jpg"></div>
									<div>Shubham Jaiswal</div>
								</div>
							</td>
							<td>shubhamjaiswal@gmail.com</td>
							<td>User Management</td>
							<td>Pending Confirmation</td>
							<td>12/04/2021, 11:54 pm</td>
							<td>
								<div class="d-flex align-items-center">
									<a href="javascript:;" class="link-alt fs14 mr-3">View Activity</a>
									<a href="javascript:;" class=""><img src="assets/images/icons/edit-big.png"></a>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="d-flex align-items-center">
									<div class="access-img mr-2"><img src="assets/images/user-01.jpg"></div>
									<div>Shubham Jaiswal</div>
								</div>
							</td>
							<td>shubhamjaiswal@gmail.com</td>
							<td>Order Management</td>
							<td>Confirmed</td>
							<td>12/04/2021, 11:54 pm</td>
							<td>
								<div class="d-flex align-items-center">
									<a href="javascript:;" class="link-alt fs14 mr-3">View Activity</a>
									<a href="javascript:;" class=""><img src="assets/images/icons/edit-big.png"></a>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="d-flex align-items-center">
									<div class="access-img mr-2"><img src="assets/images/user-01.jpg"></div>
									<div>Shubham Jaiswal</div>
								</div>
							</td>
							<td>shubhamjaiswal@gmail.com</td>
							<td>User Management</td>
							<td>Confirmed</td>
							<td>12/04/2021, 11:54 pm</td>
							<td>
								<div class="d-flex align-items-center">
									<a href="javascript:;" class="link-alt fs14 mr-3">View Activity</a>
									<a href="javascript:;" class=""><img src="assets/images/icons/edit-big.png"></a>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="d-flex align-items-center">
									<div class="access-img mr-2"><img src="assets/images/user-01.jpg"></div>
									<div>Shubham Jaiswal</div>
								</div>
							</td>
							<td>shubhamjaiswal@gmail.com</td>
							<td>User Management</td>
							<td>Confirmed</td>
							<td>12/04/2021, 11:54 pm</td>
							<td>
								<div class="d-flex align-items-center">
									<a href="javascript:;" class="link-alt fs14 mr-3">View Activity</a>
									<a href="javascript:;" class=""><img src="assets/images/icons/edit-big.png"></a>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
<!-- UPDATE BANK DETAILS -->
<ng-template #content let-modal>
   <div class="modal-body text-center mdlcmnui pt-4 mt-3">
      <h5 class="modal-title mb-10 ">Add New Authorized Person</h5>
      	<div class="form-container mdl-frm-lbl mb-3">
			<div class="row">
				<div class="col-md-12">
					<div class="floating-label mb-4">      
					  <input value="Shubham Jaiswal" type="text" class="form-control" placeholder=" " />
					  <span class="highlight"></span>
					  <label>Full Name</label>
					</div> 
				</div>
				<div class="col-md-12">
					<div class="floating-label mb-4">      
					  <input value="+91 89876 50432" type="text" class="form-control" placeholder=" " />
					  <span class="highlight"></span>
					  <label>Mobile Number</label>
					</div> 
				</div>
				<div class="col-md-12">
					<div class="floating-label mb-4">      
					  <input value="abhinav.kumar@gmail.com" type="text" class="form-control" placeholder=" " />
					  <span class="highlight"></span>
					  <label>Email ID</label>
					</div> 
				</div>	
				<div class="col-md-12">
					<div class="access-info">
						<label>Access for</label>
						<div class="access-list">
							<ul>
								<li><a href="javascript:;" class="active">User Management</a></li>
								<li><a href="javascript:;">User Management</a></li>
								<li><a href="javascript:;">User Management</a></li>
								<li><a href="javascript:;">User Management</a></li>
								<li><a href="javascript:;">User Management</a></li>
							</ul>
						</div>
					</div>
				</div>	
			</div>
		</div>
      	<div class="text-center nobd mx-auto pb-4 mb-3">
		    <button type="button" class="btn-d-outline d-inline-block cancel-outline mr-3" (click)="modal.close('Close click')">Cancel</button>
			<button type="button" class="btn buybtn fs16 radius10">Save Changes</button>
		  </div>
   </div>
</ng-template>
<!-- UPDATE BANK DETAILS -->
<!-- OTP VERIFICATION -->
<ng-template #removePerson let-modal>
   <div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
      <h5 class="modal-title">Remove Authorized Person</h5>
        <div class="modal-subtitle text-center pt-30 pb-30">Are you sure you want to remove this authorized person?</div>

        <div class="bid-order-info">
			<div class="order-img">
				<img src="assets/images/icons/info.png" />
			</div>
			<div class="pd-seller-cntnt">
				<div class="fs12">By removing this person, the access to User Management will be removed</div>
			</div>
		</div>

		<div class="row d-flex justify-content-center mt-30">
			<div class="col-md-6">
				<div class="d-table">
					<div class="d-table-cell align-middle text-center  txtavtarwrp">
						<div class="txtavtar fs20 textwhite wt700 sn-color">SN</div>
					</div>
					<div class="d-table-cell dsg-pinfo pl-3 align-middle">
						<h5 class="fs14 wt700 mb-1 mt-0 txtdefault d-flex align-items-center">Suresh Nair</h5>
					</div>
				</div>
			</div>
		</div>

        <div class="text-center pt-30 pb-20">
           <button type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')" class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4">
		      Cancel
		   	</button>
		   	<button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4">
		      Remove
		   	</button>
        </div>
   </div>
</ng-template>
<!-- OTP VERIFICATION -->