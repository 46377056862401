<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Product Management</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" [routerLink]="['/admin/review-moderation/product-reviews']">Review Moderation</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" [routerLink]="['/admin/review-moderation/product-reviews']">Product Review</a>
						</li>
						<li class="breadcrumb-item active">View Details</li>
					</ol>
				</nav>
			</div>
			<!-- <div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:;" class="btn buybtn fs16 radius10 ripple">View Buyer Details</a>
			</div> -->
		</div>
		<!--- /HEAD  --->
		<div class="row d-flex align-items-center mb-20">
			<div class="col-md-12">
				<h3 class="f-700 fs22 txtdefault pb-0">
					<a href="javascript:;" class="go-back" [routerLink]="['/admin/review-moderation/product-reviews']"><i class="fa fa-angle-left"></i></a> 
					View Details</h3>
			</div>
		</div>

		<div class="card card-ui card-uiovt byregwrp">
			<div class="row d-flex align-items-center">
				<div class="col-md-8">
					<div class="d-table">
						<div class="d-flex align-items-center">
							<div class="mr-2"><img src="{{ review_details.seller_logo }}"></div>
							<h4 class="f-700 fs20 txtdefault pb- mb-0">{{ review_details.seller_name }}</h4>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="text-right" *ngIf="review_details.product_status == 0">
						<a href="javascript:;" class="btn buybtn-success fs16 radius10 ripple mr-2" (click)="reviewstatus(1)">Approve</a>
						<a href="javascript:;" class="btn buybtn-danger fs16 radius10 ripple" (click)="reviewstatus(0)">Reject</a>
					</div>

					<div class="text-right" *ngIf="review_details.product_status == 1">
						<a href="javascript:;" class="btn buybtn-success fs16 radius10 ripple mr-2">Approved</a>
					</div>

					<div class="text-right" *ngIf="review_details.product_status == 2">
						<a href="javascript:;" class="btn buybtn-danger fs16 radius10 ripple">Rejected</a>
					</div>
				</div>
			</div>


			<hr>

			<div class="fs15 f-700 mb-20">Product Info:</div>
			<div class="row d-flex align-items-center mb-30">
				<div class="col-md-6">
					<div class="d-flex align-items-center">
						<div class="mr-3"><img src="{{ review_details.product_image }}" style="max-width:80px"></div>
						<h5 class="mb-0 fs14 wt700">{{ review_details.product_name }}</h5>
					</div>
				</div>	

				<div class="col-md-6 text-right">
					<div class="row">
						<div class="col">
							<b>Buyer name:</b>
							<div class="text-muted">{{ review_details.buyer_name }}</div>
						</div>
						<div class="col">
							<b>Total deal amount:</b>
							<div class="text-muted">{{ review_details.order_amount }}</div>
						</div>
						<div class="col">
							<b>Order Details:</b>
							<div class="text-muted"><a href="javascript:;" class="link-alt fs14"  [routerLink]="['/admin/ordderdetails/'+review_details.order_id]">View Details</a></div>
						</div>
					</div>
				</div>
			</div>
			<form>
			<div class="row mb-30">
				<div class="col-md-3">
					<div class="fs15 f-700 mb-10">Product Rating:</div>
					<div class="ratings star-ratings pr-1 d-inline-block">
						<ngb-rating [max]="5" [(rate)]="review_details.product_review_rating" [readonly]="false" class="rating_star"></ngb-rating>
					</div>
				</div>	

				<div class="col-md-3">
					<div class="fs15 f-700 mb-10">Product Image:</div>
					<div class="uploaded-image">
						<img src="{{ review_details.product_review_image }}">
					</div>
				</div>
			</div>

			
				<div class="form-container mb-3">
					<div class="floating-label mb-0">      
					   <textarea class="form-control floating-textarea" cols="30" rows="3" name="product_review_desc" [(ngModel)]="review_details.product_review_desc"></textarea>
					   <span class="highlight"></span>
					   <label>Your Product Review</label>
					</div>
				</div>

				<div class="text-left">
					<a href="javascript:;" class="btn buybtn-success fs16 radius10 ripple mr-2" (click)="updatereview()">Update Review</a>
				</div>

			</form>
					<!-- End Basic Form-->
		</div> 
	</div>
</div>