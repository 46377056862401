<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">User Management</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" [routerLink]="['/admin/manage-buyer']" *ngIf="personal_detail.usertype == 1">Buyer</a>
							<a class="txtseconday" [routerLink]="['/admin/manage-seller']" *ngIf="personal_detail.usertype == 2">Seller</a>
						</li>
						<li class="breadcrumb-item active">View Details</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:;" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="row d-flex align-items-center justify-content-between mb-20">
			<div class="col-md-6">
				<h3 class="f-700 fs22 txtdefault pb-0" *ngIf="personal_detail.usertype == 1">
					<a href="javascript:;" class="go-back" [routerLink]="['/admin/manage-buyer']">
					<i class="fa fa-angle-left"></i></a> View Details</h3>
				<h3 class="f-700 fs22 txtdefault pb-0" *ngIf="personal_detail.usertype == 2">
					<a href="javascript:;" class="go-back" [routerLink]="['/admin/manage-seller']">
						<i class="fa fa-angle-left"></i></a> View Details</h3>
			</div>

			<div class="col-md-6">
				<div class="registration-status mb-0 text-right">
					<ul class="mb-0">
						<li [ngClass]="{'completed': personal_detail.document_verified}"><i class="fa fa-check-circle"></i>&nbsp;Business Details</li>	
						<li [ngClass]="{'completed': personal_detail.business_verified}"><i class="fa fa-check-circle"></i>&nbsp;Authorized Persons</li>
						<li [ngClass]="{'completed': personal_detail.bank_verified}"><i class="fa fa-check-circle"></i>&nbsp;Bank Details</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="row mb-10">
			<div class="col-md-12 pb-2">
				<div class="custom-tab-menu">
					<ul *ngIf="personal_detail.usertype == 1">
						<li><a href="javascript:;" [routerLink]="['/admin/manage-buyer/view-details/', buyerId]" class="active">Personal Details</a></li>
						<li><a href="javascript:;" [routerLink]="['/admin/manage-buyer/buyer-business-details/', buyerId]" class="active">Business Details</a></li>
						<li><a href="javascript:;" [routerLink]="['/admin/manage-buyer/buyer-bank-details/', buyerId]" class="active">Bank Details</a></li>
					</ul> 
					<ul *ngIf="personal_detail.usertype == 2">
						<li><a href="javascript:;" [routerLink]="['/admin/manage-seller/view-details/', buyerId]" class="active">Personal Details</a></li>
						<li><a href="javascript:;" [routerLink]="['/admin/manage-seller/seller-business-details/', buyerId]" class="active">Business Details</a></li>
						<li><a href="javascript:;" [routerLink]="['/admin/manage-seller/seller-bank-details/', buyerId]" class="active">Bank Details</a></li>
					</ul>
				</div>
			</div>

			<div class="col-md-12 pt-2 pb-2">
				<div class="text-right">
					<a href="javascript:;" class="btn buybtn-success fs16 radius10 ripple mr-2" (click)="changestatus('approve')" *ngIf="personal_detail.admin_verified == 0">Approve</a>
					<a href="javascript:;" class="btn buybtn-success fs16 radius10 ripple mr-2" *ngIf="personal_detail.admin_verified == 1">Approved</a>
					<a href="javascript:;" class="btn buybtn-danger fs16 radius10 ripple" (click)="openmodal(rejectbuyer)" *ngIf="personal_detail.admin_verified == 0">Reject</a>
					<a href="javascript:;" class="btn buybtn-danger fs16 radius10 ripple" *ngIf="personal_detail.admin_verified == 2">Rejected</a>
				</div>
			</div>
		</div>

		<div class="card cardui carduipro p-4 mb-2">
			<div class="form-container mdl-frm-lbl mb-3">
				<div class="row">
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input [(ngModel)]="bank_detail.ifsc_code" (change)="ifscdetails()" type="text" class="form-control" placeholder=""  [readonly]="personal_detail.admin_verified == 1"/>
						  <span class="highlight"></span>
						  <label>IFSC Code</label>
						</div> 
					</div>
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input type="text" [(ngModel)]="bank_detail.bank_name" class="form-control" placeholder="" [readonly]="personal_detail.admin_verified == 1"/>
						  <span class="highlight"></span>
						  <label>Bank Name</label>
						</div> 
					</div>
				</div>
				<div class="row">
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input [(ngModel)]="bank_detail.account_name" type="text" class="form-control" placeholder=""  [readonly]="personal_detail.admin_verified == 1"/>
						  <span class="highlight"></span>
						  <label>Account Holder Name</label>
						</div> 
					</div>
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input [(ngModel)]="bank_detail.account_number" type="text" class="form-control" placeholder=""  [readonly]="personal_detail.admin_verified == 1"/>
						  <span class="highlight"></span>
						  <label>Account Number</label>
						</div> 
					</div>	
					
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input [(ngModel)]="bank_detail.branch" value="" type="text" class="form-control" placeholder=""  [readonly]="personal_detail.admin_verified == 1"/>
						  <span class="highlight"></span>
						  <label>Bank Branch</label>
						</div> 
					</div>
					<div class="col-sm-6 mb-4" *ngIf="bank_detail.document_path">
						<a href="{{ bank_detail.document_path }}" target="_blank" class="link-alt fs13">View Cancelled Cheque Copy</a>
					</div>
				</div>
				<div class="col-md-8 text-left" *ngIf="personal_detail.admin_verified == 0">
					<button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4"
						(click)="bankDetailsSave()">
						<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
							*ngIf="bankLoading"></span>&nbsp;
						Update</button>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- EDIT PROFILE PHOTO -->
<ng-template #rejectbuyer let-modal>
	<div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
	   <h5 class="modal-title text-center pb-20">Reject with Reasons</h5>
	   <div class="bid-order-info">
		 <div class="order-img">
			 <img src="assets/images/icons/info.png" />
		 </div>
		 <div class="pd-seller-cntnt">
			 <div class="fs12">The {{ personal_detail.usertype == 'buyer'? 'buyer':'seller' }} will be notified to upload a fresh document immediately.</div>
		 </div>
	 </div>

	 <div class="form-container">
		 <div class="floating-label mb-20">      
			<select class="form-control" [(ngModel)]="reject_reason">
				<option value="" selected="selected">Selected</option>
				<option *ngFor="let item of reasons; let i = index;" value="{{item.value}}">{{ item.name }}</option>
			</select>
			<span class="highlight"></span>
			<label>Reason of Rejection</label>
		 </div>
		 <div class="floating-label mb-0">      
			<textarea class="form-control floating-textarea" [(ngModel)]="reject_message"></textarea>
			<!-- Please reupload the GST Filed document for the last month. -->
			<span class="highlight"></span>
			<label>Message</label>
		 </div>
	 </div>

	 <div class="text-center pt-30 pb-20">
		<button type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')" class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4">
		   Cancel
			</button>
			<button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4" (click)="changestatus('reject')">Notify the Buyer</button>
	 </div>
	</div>
</ng-template>
<!-- EDIT PROFILE PHOTO -->