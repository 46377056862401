import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogisticsPartnerListService {

    constructor(private _http: HttpClient) { }

    getlogisticspartners(data) {
        return this._http.post(environment.api_url+'admin/logisticpartner/list',data);
    }

	deletepartner(data){
		return this._http.post(environment.api_url+'admin/logisticpartner/remove',data);
	}

}
