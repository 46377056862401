<app-header-seller></app-header-seller>
<app-sidebar-seller></app-sidebar-seller>
<div class="wrpmain pb-4">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-9">
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">My Products   </a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" [routerLink]="['/product-listing']">My Listings</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" [routerLink]="['/product-listing']">Past Orders </a>
						</li>
						<li class="breadcrumb-item active">Edit Listing</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-3 text-right">
				<a href="javascript:void(0);" [routerLink]="['/seller/assistance']" class="btn buybtn fs16 radius10">					
					Assistance
				</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="row d-flex align-items-center mb-20">
			<div class="col-md-6">
				<h3 class="f-700 fs22 txtdefault pb-0"><a href="javascript:;" class="go-back"><i class="fa fa-angle-left"></i></a> Past Orders</h3>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				
			</div>
		</div>
		<div >
			<div class="row mb-4 pb-3">
				<div class="col-lg-8">
					<div class="bid-order-info">
						<div class="order-img">
							<img src="assets/images/product/mobile/mobile.png" alt="" >
						</div>
						<div class="pd-seller-cntnt pl-2">
							<!--Product Name-->
							<h5 class="mb-10 fs16">Vivo X60 Pro (Midnight Black, 12GB RAM, 256GB Storage) | Upto 12 Months No Cost EMI | Extra 4000 Off on Exchange</h5>
							
							<!--Edit / Delete-->
							<div class="text-left light-text mt-10">
								
								<a class="link-alt" href="javascript:void(0);" >View Details</a>
							</div>
						</div>
					</div>
				</div>				
			</div>
			<div class="row">
				<div class="col-md-10">
					<div class="row">
						<div class="col-md-4 col-6">
							<label class="txtdefault mb-2">Total No. of Orders:</label>
							<p class="txtseconday">40</p>
						</div>
						<div class="col-md-4 col-6">
							<label class="txtdefault mb-2">Price Per Unit:</label>
							<p class="txtseconday">₹ 7,599</p>
						</div>
					</div>	
				</div>
		</div>	
		<!-- TABING -->
		<div class="add-pro-wrap">
			<div class="row">
				<!-- RIGHT COL -->  
				<div class="col-md-12 ad-p-rgt">
					<div class="card card-ui p-0">
						<div class="card-body add-pro-wrap pt-0 p-4">
							<ul ngbNav #prdctinfonav="ngbNav" [(activeId)]="active"  class="nav-tabs">
								<!-- Consent -->
								<li [ngbNavItem]="9">
									<a ngbNavLink>Bulk</a>
									<ng-template ngbNavContent>
										<div class="">
											<!--Filters-->
											<div class="row d-flex justify-content-between mt-20 report-cta">
												<div class="col-md-12">
													<div class="d-flex justify-content-end">
														<div class="text-right">
															<a href="javascript:void(0);" class="d-inline-block fltr-round-btn radius10 txtseconday">
																<img src="assets/images/svg/filter.svg">
																Filters
															</a>
														</div>

														<div style="display:none" class="filter-show-div">
															<div class="row no-gutters">
																<div class="col-md-3">
																	<div class="left-filter">
																		<ul>
																			<li class="active"><a href="javascript:;">Category</a></li>
																			<li><a href="javascript:;">Listing Available</a></li>
																			<li><a href="javascript:;">Brand</a></li>
																		</ul>
																	</div>
																</div>
																<div class="col-md-9 right-contcc">
																	<div class="right-filter lsting">
																		<ul class="list-group">
																			<li class="list-group-item">
																				<div class="css-check">
																					<input class="css-checkbx" type="checkbox" />
																					<label class="css-label">Smart Phones (45)</label>
																				</div>								
																			</li>
																			<li class="list-group-item">
																				<div class="css-check">
																					<input class="css-checkbx" type="checkbox" />
																					<label class="css-label">Feature Phones (45)</label>
																				</div>
																			</li>
																		</ul>
																	</div>

																	<div class="footer-cta">
																		<div class="mr-3">
																			<a href="javascript:;"><svg xmlns="http://www.w3.org/2000/svg" width="10.239" height="10.245" viewBox="0 0 10.239 10.245">
																			  <path id="Icon_material-refresh" data-name="Icon material-refresh" d="M14.749,7.5a5.123,5.123,0,1,0,1.332,4.9H14.749a3.84,3.84,0,1,1-3.618-5.123,3.787,3.787,0,0,1,2.7,1.14l-2.062,2.062h4.482V6Z" transform="translate(-6.015 -6)" fill="#ff7135"/>
																			</svg> &nbsp;&nbsp;<span class="link-alt fs14">Reset Filters</span></a>
																		</div>
																		<div><a href="" class="btn buybtn fs16 radius10 ripple">Apply</a></div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<!--End Filters-->
											<div class="tbl-firstcell-nospace">
												<div class="table-responsive brndmgnttable">
													<table class="table custom-table xs">
														<thead>
															<tr>
																<th>Order Id</th>
																<th width="20%">Variant Name</th>
																<th width="14%">Market Place</th>
																<th>Price (in ₹)</th>
																<th>Pre-Booked On</th>
																<th width="15%">Order Status</th>
																
																<th width="16%">Action</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>123456</td>
																<td>
																	<div class="order-info">
																		<div class="order-img">
																			<img src="assets/images/p-2.jpg" />
																		</div>
																		<div class="pd-seller-cntnt">
																			<div class="txtdefault">
																				<a href="javascript:;" class="order-link">Samsung Galaxy M21 2021 Editio……</a>
																			</div>
																		</div>
																	</div>
																</td>
																<td>Bulk</td>
																
																<td>1,23,455</td>
																<td>12/04/2021</td>											
																<td>Quality Check</td>
																
																<td class="d-flex align-items-center">
																	<a href="javascript:void(0);" class="btn-d-outline ripple d-inline-block place-bid ">View Order</a>
																	
																</td>
															</tr>
															<tr>
																<td>123456</td>
																<td>
																	<div class="order-info">
																		<div class="order-img">
																			<img src="assets/images/p-2.jpg" />
																		</div>
																		<div class="pd-seller-cntnt">
																			<div class="txtdefault">
																				<a href="javascript:;" class="order-link">Samsung Galaxy M21 2021 Editio……</a>
																			</div>
																		</div>
																	</div>
																</td>
																<td>Wholesale</td>
																
																<td>1,23,455</td>
																<td>12/04/2021</td>											
																<td>
																Pending for Approval
																</td>
																
																<td class="d-flex align-items-center">
																	<a href="javascript:void(0);" class="btn-d-outline ripple d-inline-block place-bid ">View Order</a>
																	
																</td>
															</tr>
															<tr>
																<td>123456</td>
																<td>
																	<div class="order-info">
																		<div class="order-img">
																			<img src="assets/images/p-2.jpg" />
																		</div>
																		<div class="pd-seller-cntnt">
																			<div class="txtdefault">
																				<a href="javascript:;" class="order-link">Samsung Galaxy M21 2021 Editio……</a>
																			</div>
																		</div>
																	</div>
																</td>
																<td>Wholesale</td>
																
																<td>1,23,455</td>
																<td>12/04/2021</td>											
																<td>Ready to Dispatch
																<span class="fs10 radius10 badge badge-d-outline">Dispatch in 16:00 hrs</span></td>
																
																<td class="d-flex align-items-center">
																	<a href="javascript:void(0);" class="btn-d-outline ripple d-inline-block place-bid ">View Order</a>
																	
																</td>
															</tr>
															<tr>
																<td>123456</td>
																<td>
																	<div class="order-info">
																		<div class="order-img">
																			<img src="assets/images/p-2.jpg" />
																		</div>
																		<div class="pd-seller-cntnt">
																			<div class="txtdefault">
																				<a href="javascript:;" class="order-link">Samsung Galaxy M21 2021 Editio……</a>
																			</div>
																		</div>
																	</div>
																</td>
																<td>Bulk</td>
																
																<td>1,23,455</td>
																<td>12/04/2021</td>											
																<td>Dispatch
																<span class="fs10 radius10 badge badge-d-outline dspatchbadge-d-outline">Delayed in 2 days</span></td>
																
																<td class="d-flex align-items-center">
																	<a href="javascript:void(0);" class="btn-d-outline ripple d-inline-block place-bid ">View Order</a>
																	
																</td>
															</tr>
															<tr>
																<td>123456</td>
																<td>
																	<div class="order-info">
																		<div class="order-img">
																			<img src="assets/images/p-2.jpg" />
																		</div>
																		<div class="pd-seller-cntnt">
																			<div class="txtdefault">
																				<a href="javascript:;" class="order-link">Samsung Galaxy M21 2021 Editio……</a>
																			</div>
																		</div>
																	</div>
																</td>
																<td>Wholesale</td>
																
																<td>1,23,455</td>
																<td>12/04/2021</td>											
																<td>Dispatched</td>
																
																<td class="d-flex align-items-center">
																	<a href="javascript:void(0);" class="btn-d-outline ripple d-inline-block place-bid ">View Order</a>
																	
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>	
										</div>		
									</ng-template> 
								</li>
								<!-- /Consent -->
							  <li [ngbNavItem]="10">
								<a ngbNavLink>Wholesale</a>
								<ng-template ngbNavContent>
									2
								</ng-template>
							  </li>
							  <li [ngbNavItem]="11">
								<a ngbNavLink>Bids & Offers</a>
								<ng-template ngbNavContent>
									3
								</ng-template>
							  </li>
							  
							</ul>

							<div [ngbNavOutlet]="prdctinfonav" class="mt-2"></div>
						</div>
					</div>	
				</div>
			<!-- /RIGHT COL -->
					
		</div>
		<!-- /TABING -->
		<div class="row mb-40">
			<div class="col-md-6">
				<div class="text-left pl-3">
					<nav class="mt-3 tbpg rows-per-page">
						<div>Rows per page:</div>
						<select class="select-box">
							<option>10</option>
							<option>20</option>
						</select>
					</nav>
				</div> 
			</div>
			<div class="col-md-6">
				<div class="text-right pr-3">
					<nav class="mt-3 tbpg">
					  <ul class="pagination align-items-center">
						<li class="page-item"><a class="page-link previcon" href="#"></a></li>
						<li class="page-item"><a class="page-link sprevicon" href="#"></a></li>
						<li class="page-item"><a class="page-link" href="#">1</a></li>
						<li class="page-item"><a class="page-link" href="#">2</a></li>
						<li class="page-item"><a class="page-link" href="#">3</a></li>
						<li class="page-item"><a class="page-link" href="#">4</a></li>
						<li class="page-item"><a class="page-link snexticon" href="#"></a></li>
						<li class="page-item"><a class="page-link nexticon" href="#"></a></li>
					  </ul>
					</nav>
				</div> 
			</div>
		</div> 
	</div>
</div>		
<!-- REMOVE PRODUCT MODAL -->  
<ng-template #content let-modal>
  <div class="modal-header nobd text-center pt-4 pb-0">
    <h4 class="modal-title fs28 txtdefault wt700 mx-auto pt-3">Remove Product</h4>
    <button type="button" class="btn-close close rmvmdl" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
	<div class="modal-body">
		<p class="fs12 txtseconday py-3 mb-2 text-center">
		<span class="mr-2">
			<svg id="Group_46" data-name="Group 46" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="7.5" cy="7.5" r="7.5" fill="#07f"/>
			  <path id="Path_2235" data-name="Path 2235" d="M7,8.171l-.128.523q-.576.227-.919.346a2.425,2.425,0,0,1-.8.119,1.591,1.591,0,0,1-1.084-.34,1.1,1.1,0,0,1-.387-.864,3.056,3.056,0,0,1,.029-.416q.029-.213.093-.48l.48-1.7q.064-.244.108-.462a2.01,2.01,0,0,0,.044-.4A.616.616,0,0,0,4.3,4.047a.759.759,0,0,0-.513-.128,1.345,1.345,0,0,0-.381.058c-.131.039-.242.076-.336.11L3.2,3.564q.471-.192.9-.329A2.7,2.7,0,0,1,4.916,3.1a1.552,1.552,0,0,1,1.068.335,1.111,1.111,0,0,1,.375.869c0,.074-.008.2-.026.39a2.606,2.606,0,0,1-.1.512L5.759,6.9a4.492,4.492,0,0,0-.1.466,2.4,2.4,0,0,0-.047.4.57.57,0,0,0,.15.459.85.85,0,0,0,.523.122,1.509,1.509,0,0,0,.4-.061A2.21,2.21,0,0,0,7,8.171Zm.121-7.1a.99.99,0,0,1-.334.753,1.138,1.138,0,0,1-.8.312,1.15,1.15,0,0,1-.809-.312.988.988,0,0,1-.337-.753A1,1,0,0,1,5.169.314a1.194,1.194,0,0,1,1.614,0A1,1,0,0,1,7.117,1.07Z" transform="translate(2.406 2.92)" fill="#fff"/>
			</svg>
		</span>
		Removing the product will lead to removing this product from your listing. The buyer won’t be able to place an order for this product.</p>
		<p class="fs16 txtseconday pb-3 mb-2 text-center">Are you sure you want to remove the product?</p>
		<div class="d-table mb-4 w-100">
			<div class="d-table-cell cart-thumb align-middle">
				<img width="60" src="assets/images/products/p-2.jpg">
			</div>
			<div class="d-table-cell pl-3 align-middle">
				<p class="txtdefault wt700 mb-0 fs14 lnht20">Vivo X60 Pro (Midnight Black, 12GB RAM, 256GB Storage) | Upto 12 Months No Cost EMI | Extra 4000 Off on Exchange</p>
			</div>
		</div>
	</div>
  <div class="modal-footer  text-center nobd mx-auto pb-4 mb-3">
    <button type="button" class="btn-d-outline d-inline-block cancel-outline mr-3" (click)="modal.close('Close click')">Cancel</button>
	<button type="button" class="btn buybtn fs16 radius10">Remove</button>
  </div>
</ng-template>
<!-- /REMOVE PRODUCT MODAL -->  

<app-footer-seller></app-footer-seller>