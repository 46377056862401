import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buyer-notification',
  templateUrl: './buyer-notification.component.html',
  styleUrls: ['./buyer-notification.component.scss']
})
export class BuyerNotificationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  	ratingclassstatus: boolean = false;
	ratingst: boolean = false;
	ratingtglclass(){
	    this.ratingclassstatus = !this.ratingclassstatus;       
	}
}
