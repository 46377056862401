import { Component, OnInit } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { PaginationService } from "src/app/helpers/pagination.service";
import { ToasterService } from "../../helpers/toastr.service";
import { BNPLChargesService } from "./bnpl-charges.service";
import { AddBrandService } from "../add-brand/add-brand.service";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";

@Component({
  selector: "app-bnpl-charges",
  templateUrl: "./bnpl-charges.component.html",
  styleUrls: ["./bnpl-charges.component.scss"],
})
export class BnplChargesComponent implements OnInit {
  public current_page: number = 1;
  public offset: number = 0;
  public page_per_view: number = 5;
  public media_pagination: any = {};
  public page_per_view_options = [
    {
      value: 20,
      name: 20,
    },
    {
      value: 40,
      name: 40,
    },
  ];
  closeResult = "";
  public search_term: string = "";
  public records: any = [];
  public chargesList: any = [];
  public category: string = "";
  public category_id: string = "";
  public category_name: string = "";
  public category_list: any = [];
  public editButton: boolean = false;
  public editCategoryButton: boolean = false;
  public editId: string = "";
  chargesForm: FormGroup;

  constructor(
    private modalService: NgbModal,
    private _pageservice: BNPLChargesService,
    private _toastr: ToasterService,
    private _pagination: PaginationService,
    private _addbrandservice: AddBrandService,
    private fb: FormBuilder
  ) {}

  config = {
    labelField: "label",
    valueField: "value",
  };

  public convenience_units = [
    {
      value: "both",
      label: "From Both",
    },
    {
      value: "buyer",
      label: "From Buyer",
    },
    {
      value: "seller",
      label: "From Seller",
    },
  ];

  public convenience_charges_units = [
    {
      value: "fixed",
      label: "in INR PER PCS",
    },
    {
      value: "fixed_total",
      label: "in INR (on total order value)",
    },
    {
      value: "percentage",
      label: "% of Deal Value",
    },
  ];

  public status = [
    {
      value: 1,
      label: "Active",
    },
    {
      value: 2,
      label: "Inactive",
    }
  ];

  ngOnInit(): void {
    this.chargesLists();
    this.chargesForm = this.fb.group({
      charges: this.fb.control(null, [Validators.required]),
      charges_type: this.fb.control(null, [Validators.required]),
      status: this.fb.control(null, [Validators.required]),
    });
  }

  showCategory: boolean =false;

  changeCategory () {
    this.showCategory = true;
    this.category_id = '';
    this.category_name = '';
  }

  setpage(page) {
    this.current_page = page;
    this.offset = (page - 1) * this.page_per_view;
    this.chargesLists();
  }

  setpageperview() {
    this.current_page = 1;
    this.chargesLists();
  }

  getproductlistingfilters() {
    this.page_per_view = 5;
    this.offset = 0;
    this.current_page = 1;
  }

  result = [];
  keyword = "name";

  onFocused(e) {
    // do something when input is focused
  }

  onChangeCategorySearch(val: string) {
    this._addbrandservice.searchcategory({ search: val }).subscribe((data) => {
      if (data["status"] == "success") {
        this.result = data["records"];
      } else if (data["status"] == "error") {
        this._toastr.error(data["message"]);
      }
    });
  }

  selectCategoryEvent(item) {
      this.category_name = item.name;
      this.category_id = item.c_id;
  }

  open(content, id, type) {
    if(type) {
      this.showCategory = false;
    }
    if(id) {
      this.editButton = true;
      this.editId = id;
      let editForm = this.chargesList.find(a => a._id === id);
      this.category_id = editForm.category_id;
      this.category_name = editForm.category_name;
      this.chargesForm.patchValue({
       'charges' : editForm.charges,
       'charges_type' : editForm.charges_type,
       'status' : editForm.status
        });
    }
    else {
      this.editButton = false;
      this.editId = '';
      this.chargesForm.patchValue({
        'charges' : '',
        'charges_type' : '',
        'status' : ''
         });
    }
    this.modalService.open(content, {
        ariaLabelledBy: "modal-basic-title",
        size: "lg",
        centered: true,
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  chargesLists() {
    var data = {
      search: this.search_term,
      limit: this.page_per_view,
      offset: this.offset,
      current_page: this.current_page,
    };
    this._pageservice.getCharges(data).subscribe((categoryData) => {
      if (categoryData["status"] == "success") {
        this.chargesList = categoryData['data'];
        let total_records = data['pagination'];
        this.media_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
      }
    });
  }

  addCharges() {
    let data = {
      'category_id': this.category_id,
      'charges': this.chargesForm.value.charges,
      'charges_from': 'buyer',
      'charges_type': this.chargesForm.value.charges_type,
      'status': this.chargesForm.value.status,
      'id': this.editButton ? this.editId : ''
    }
    if(!this.editButton){
    this._pageservice.saveCharges(data).subscribe((data) => {
      if (data["status"] == "success") {
        this._toastr.success("New Charges has been added successfully.");
        this.chargesLists();
        this.modalService.dismissAll();
        this.editButton = false;
        this.editId = '';
      } else if (data["status"] == "error") {
        this._toastr.error(data["message"]);
      }
    });
    }
    else {
      this._pageservice.saveCharges(data).subscribe((data) => {
        if (data["status"] == "success") {
          this._toastr.success("Updated successfully.");
          this.chargesLists();
          this.modalService.dismissAll();
          this.editButton = false;
          this.editId = '';
        } else if (data["status"] == "error") {
          this._toastr.error(data["message"]);
        }
      });
    }
    }
}
