import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { LogisticsChargesService } from './logistics-charges.service';

@Component({
    selector: 'app-logistics-charges',
    templateUrl: './logistics-charges.component.html',
    styleUrls: ['./logistics-charges.component.scss']
})
export class LogisticsChargesComponent implements OnInit {

    public standardCityCharges:any = [];
    public standardOutsideCharges:any = [];
    public expressCharges:any = [];
    constructor(
        private _logisticsservice: LogisticsChargesService,
        private _toastr: ToasterService,
    ) { }

    ngOnInit(): void {
        this.getcharges();
    }

    getcharges(){
        this._logisticsservice.getcharges(null).subscribe((data) => {
            if(data['status'] == "success"){
                this.standardCityCharges = data['standardwithincity'];
                this.standardOutsideCharges = data['standardoutsidecity'];
                this.expressCharges = data['expressrecord'];
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

}
