import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AddNewProductService {
    
  constructor(private _http: HttpClient) { }

    getvarianttype(data) {
        return this._http.post(environment.api_url+'getvarianttype',data);
    }

    saveproductdescription(data) {
        return this._http.post(environment.api_url+'admin/addproduct/basic',data);
    }

    getproductbasic(data) {
        return this._http.post(environment.api_url+'admin/getproduct/basic',data);
    }

    deletebanner(data) {
        return this._http.post(environment.api_url+'admin/addproduct/deletebanner',data);
    }

    savevariant(data) {
        return this._http.post(environment.api_url+'admin/addproduct/variant',data);
    }

    deletevariant(data) {
        return this._http.post(environment.api_url+'admin/addproduct/deletevariant',data);
    }

    getvariant(data) {
        return this._http.post(environment.api_url+'admin/getproduct/variant',data);
    }

    uploadimage(data) {
        return this._http.post(environment.api_url+'admin/addproduct/images',data);
    }

    getimages(data) {
        return this._http.post(environment.api_url+'admin/getproduct/images',data);
    }

    saveattribute(data) {
        return this._http.post(environment.api_url+'admin/attribute/save', data);
    }

    savesellertype(data) {
        return this._http.post(environment.api_url+'admin/addproduct/sellertype',data);
    }

    getsellertype(data) {
        return this._http.post(environment.api_url+'admin/getproduct/sellertype',data);
    }

    savepricing(data) {
        return this._http.post(environment.api_url+'admin/addproduct/pricing',data);
    }

    getpricing(data) {
        return this._http.post(environment.api_url+'admin/getproduct/pricing',data);
    }

    updatebanner(data){
        return this._http.post(environment.api_url+'admin/addproduct/updatebanner',data);
    }

    saveattributes(data){
        return this._http.post(environment.api_url+'admin/addproduct/attribute',data);
    }

    savechild(data){
        return this._http.post(environment.api_url+'admin/addchildproduct',data);
    }

    updatechild(data){
        return this._http.post(environment.api_url+'admin/updatechildproduct',data);
    }

    getattributes(data){
        return this._http.post(environment.api_url+'admin/getproduct/attribute',data);
    }

    updateattribute(data){
        return this._http.post(environment.api_url+'admin/addproduct/updateattribute',data);
    }

    deleteattribute(data){
        return this._http.post(environment.api_url+'admin/addproduct/deleteattribute',data);
    }

    templatestatus(data) {
        return this._http.post(environment.api_url+'admin/addproduct/updatestatus',data);
    }

    searchbrand(data) {
        return this._http.post(environment.api_url+'master/brand/autocomplete',data);
    }

    sortattribute(data) {
        return this._http.post(environment.api_url+'admin/addproduct/sortattribute',data);
    }   

    gstdetails(data) {
        return this._http.post(environment.api_url+'/master/hsn/gst',data);
    }

    searchhsn(data) {
        return this._http.post(environment.api_url+'master/hsn/autocomplete', data);
    }

}
