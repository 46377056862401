<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center justify-content-between mb-4 pinfo-head">
			<div class="col-md-6 col-7">
				<h3 class="fs24 f-700 txtdefault">Hello Admin,</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">Welcome to Equibiz Platform</li>
					</ol>
				</nav>
			</div>
		</div>

      <div class="row">
			<div class="col-md-12">
			   <div class="card cardui carduipro p-4 mb-20">
				  <div class="row">
					 <div class="col-md-3">
						<div class="fs16 f-700 mb-20">Sales Overview</div>
					 </div>
					 <div class="col-md-9">
						<div class="row">
						   <div class="col-md-8">
						   </div>		
						   <div class="col-md-4">
							   <ng-selectize [config]="config" [options] = "months_array" placeholder="" [(ngModel)]="purchase_overview_filter" (ngModelChange)="mydashboarddetails('purchase_overview')">
							   </ng-selectize>	
						   </div>		
						</div>
					 </div>
				  </div>
				  <div class="row align-items-center">
					 <div class="col-md-3 mb-xs-20">
						<div class="dash-left">
						   <div class="d-flex align-items-center justify-content-between mb-2">
							  <div><img src="assets/images/icons/bag.png"></div>
							  <!-- <div class="key-value">+ 25.27%</div> -->
						   </div>
						   <div class="fs24 f-700">{{ total_purchase }}</div>
						   <div class="text-muted fs16 f-500">Sales</div>

						   <hr>

						   <div class="d-flex align-items-center justify-content-between mb-2">
							  <div><img src="assets/images/icons/money.png"></div>
							  <!-- <div class="key-value">+ 35.18%</div> -->
						   </div>
						   <div class="fs24 f-700">{{ total_orders }}</div>
						   <div class="text-muted fs16 f-500">Total Orders</div>
						</div>
					 </div>
					 <div class="col-md-9">
						<div>
						   <div id="chart">
							   <apx-chart
								   [series]="chartOptions.series"
								   [chart]="chartOptions.chart"
								   [xaxis]="chartOptions.xaxis"
								   [dataLabels]="chartOptions.dataLabels"
								   [grid]="chartOptions.grid"
								   [stroke]="chartOptions.stroke"
								   [title]="chartOptions.title"
							   ></apx-chart>
						   </div>
						</div>
					 </div>
				  </div>
			   </div>
			</div>
		 </div>

      <div class="row">
         <div class="col-md-12">
            <div class="card cardui carduipro p-4 mb-40">
               <div class="fs16 f-700 mb-20">User Management</div>

               <div class="row">
                  <div class="col-md-3">
                     <div class="dash-left">
                        <a href="javascript:;">
                           <div class="fs24 f-700 text-dark">{{ approved_buyer }}</div>
                           <div class="text-muted fs16 f-500">Registered Buyers</div>
                        </a>
                     </div>
                  </div>
                  <div class="col-md-3">
                     <div class="dash-left">
                        <a href="javascript:;">
                           <div class="fs24 f-700 text-dark">{{ approved_seller }}</div>
                           <div class="text-muted fs16 f-500">Registered Sellers</div>
                        </a>
                     </div>
                  </div>
                  <div class="col-md-3">
                     <div class="dash-left">
                        <a href="javascript:;">
                           <div class="fs24 f-700 text-dark">{{ pending_buyer }}</div>
                           <div class="text-muted fs16 f-500">Pending Buyer Verification</div>
                        </a>
                     </div>
                  </div>
                  <div class="col-md-3">
                     <div class="dash-left">
                        <a href="javascript:;">
                           <div class="fs24 f-700 text-dark">{{ pending_seller }}</div>
                           <div class="text-muted fs16 f-500">Pending Seller Verification</div>
                        </a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="row">
            <div class="col-md-12">
            <div class="card cardui carduipro p-4 mb-20">
               <div class="row d-flex align-items-center mb-20">
                  <div class="col-md-8">
                     <div class="fs16 f-700 mb-0">Orders Management</div>
                  </div>		
                  <div class="col-md-4">
                     <ng-selectize [config]="config" [options] = "months_array" placeholder="" [(ngModel)]="order_filter" (ngModelChange)="mydashboarddetails('orders')">
                     </ng-selectize>	
                  </div>		
               </div>

               <div class="row">
                  <div class="col-md-3">
                     <div class="dash-left mb-xs-10 mb-20">
                        <a href="javascript:;">
                           <div class="fs24 f-700 text-dark">{{ orders_details.total_order }}</div>
                           <div class="text-muted fs16 f-500">All Orders</div>
                        </a>
                     </div>
                  </div>
                  <div class="col-md-3">
                     <div class="dash-left mb-xs-10 mb-20">
                        <a href="javascript:;">
                           <div class="fs24 f-700 text-dark">{{ orders_details.booked }}</div>
                           <div class="text-muted fs16 f-500">Booked Orders</div>
                        </a>
                     </div>
                  </div>
                  <div class="col-md-3">
                     <div class="dash-left mb-xs-10 mb-20">
                        <a href="javascript:;">
                           <div class="fs24 f-700 text-dark">{{ orders_details.approved }}</div>
                           <div class="text-muted fs16 f-500">Approved Orders</div>
                        </a>
                     </div>
                  </div>
                  <div class="col-md-3">
                     <div class="dash-left mb-xs-10 mb-20">
                        <a href="javascript:;">
                           <div class="fs24 f-700 text-dark">{{ orders_details.rejected }}</div>
                           <div class="text-muted fs16 f-500">Rejected Orders</div>
                        </a>
                     </div>
                  </div>
            <div class="col-md-3">
               <div class="dash-left mb-xs-10 mb-20">
                 <a href="javascript:;">
                   <div class="fs24 f-700 text-dark">{{ orders_details.payment_done }}</div>
                   <div class="text-muted fs16 f-500">Payment Done</div>
                 </a>
               </div>
            </div>

            <div class="col-md-3">
               <div class="dash-left mb-xs-10 mb-20">
                 <a href="javascript:;">
                   <div class="fs24 f-700 text-dark">{{ orders_details.ready_to_dispatch }}</div>
                   <div class="text-muted fs16 f-500">Ready To Dispatch</div>
                 </a>
               </div>
            </div>

            <div class="col-md-3">
               <div class="dash-left mb-xs-10 mb-20">
                 <a href="javascript:;">
                   <div class="fs24 f-700 text-dark">{{ orders_details.qc_done }}</div>
                   <div class="text-muted fs16 f-500">Quality Done</div>
                 </a>
               </div>
            </div>

            <div class="col-md-3">
               <div class="dash-left mb-xs-10 mb-20">
                 <a href="javascript:;">
                   <div class="fs24 f-700 text-dark">{{ orders_details.dispatched }}</div>
                   <div class="text-muted fs16 f-500">Dispatched</div>
                 </a>
               </div>
            </div>

            <div class="col-md-3">
               <div class="dash-left mb-xs-10 mb-20">
                 <a href="javascript:;">
                      <div class="fs24 f-700 text-dark">{{ orders_details.delivered }}</div>
                      <div class="text-muted fs16 f-500">Delivered</div>
                 </a>
               </div>
            </div>
          </div>



        </div>
      </div>
   </div>

      <div class="row">
         <div class="col-md-12">
            <div class="card cardui carduipro p-4 mb-40">
               <div class="fs16 f-700 mb-20">Product Management</div>

               <div class="row">
                  <div class="col-md-3">
                     <div class="dash-left">
                        <a href="javascript:;">
                           <div class="fs24 f-700 text-dark">{{ active_product_listing }}</div>
                           <div class="text-muted fs16 f-500">Active Product Listings</div>
                        </a>
                     </div>
                  </div>
                  <div class="col-md-3">
                     <div class="dash-left">
                        <a href="javascript:;">
                           <div class="fs24 f-700 text-dark">{{ active_product_template }}</div>
                           <div class="text-muted fs16 f-500">Active Product Template</div>
                        </a>
                     </div>
                  </div>
                  <div class="col-md-3">
                     <div class="dash-left">
                        <a href="javascript:;">
                           <div class="fs24 f-700 text-dark">{{ requested_listing }}</div>
                           <div class="text-muted fs16 f-500">Requested Listings</div>
                        </a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      
      <div class="card cardui carduipro p-4 mb-0">
        <div class="notifications-container theme-tab">
            <ngb-tabset [justify]="'left'" [type]="'pills'" class="tabs tab-title">
                <!-- All Orders -->
                <ngb-tab>
                    <ng-template ngbTabTitle><span (click)="getorders('all')">All Orders</span></ng-template>
                </ngb-tab>

                <!-- Booked Orders -->
                <ngb-tab>
                    <ng-template ngbTabTitle><span (click)="getorders('booked')">Booked</span></ng-template>
                </ngb-tab>

                <!-- Approved Orders -->
                <ngb-tab>
                    <ng-template ngbTabTitle><span (click)="getorders('approved')">Approved</span></ng-template>
                </ngb-tab>

                <!-- Rejected Orders -->
                <ngb-tab>
                    <ng-template ngbTabTitle><span (click)="getorders('rejected')">Rejected</span></ng-template>
                </ngb-tab>

                <!-- Payment Done Orders -->
                <ngb-tab>
                    <ng-template ngbTabTitle><span (click)="getorders('payment_done')">Payment Done</span></ng-template>
                </ngb-tab>

                <!-- Ready to Dispatch Orders -->
                <ngb-tab>
                    <ng-template ngbTabTitle><span (click)="getorders('ready_to_dispatch')">Ready to Dispatch</span></ng-template>
                </ngb-tab>

                <!-- Quality Done Orders -->
                <ngb-tab>
                    <ng-template ngbTabTitle><span (click)="getorders('quality_done')">Quality Done</span></ng-template>
                </ngb-tab>

                <!-- Dispatched Orders -->
                <ngb-tab>
                    <ng-template ngbTabTitle><span (click)="getorders('dispatched')">Dispatched</span></ng-template>
                </ngb-tab>

                <!-- Delivered Orders -->
                <ngb-tab>
                    <ng-template ngbTabTitle><span (click)="getorders('delivered')">Delivered</span></ng-template>
                </ngb-tab>
                                                        
            </ngb-tabset>
        </div>
        <div class="tab-content">
            <!--Filters-->
            <div class="row d-flex justify-content-between mt-20 report-cta">
                <div class="col-lg-5 mb-3">
                    <div class="report-input">
                        <div class="input-group-custom">
                            <input type="text" placeholder="Search by Order ID, Transaction ID, Product Name & Brand" class="form-control" 
                            class="form-control" [(ngModel)]="search_term" (change)="getordersfilters()">
                            <div class="input-group-append-custom">
                                <img src="assets/images/svg/search_logo.svg">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-7">
                    <div class="d-flex clbxgrd justify-content-end lg-justify-content-start">
                       
                        <div class="form-container mt-0 mb-0 mr-3">
                            <div class="report-input floating-label">
                                <div class="input-group-custom">
                                    <input type="text" [(ngModel)]="from_date" (ngModelChange) = "getordersfilters()" class="form-control" placeholder=" " ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" autocomplete="off"/>
                                    <span class="highlight"></span>
                                    <label>From Date</label>
                                    <div class="input-group-append-custom">
                                        <img src="assets/images/icons/filter-calendar.svg">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-container mt-0 mb-0 mr-3">
                            <div class="form-container m-0">
                                <div class="report-input floating-label">
                                    <div class="input-group-custom">
                                        <input type="text" [(ngModel)]="to_date" (ngModelChange) = "getordersfilters()" class="form-control" placeholder=" " ngbDatepicker #dd="ngbDatepicker" (click)="dd.toggle()" autocomplete="off"/>
                                        <span class="highlight"></span>
                                        <label>To Date</label>
                                        <div class="input-group-append-custom">
                                            <img src="assets/images/icons/filter-calendar.svg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div>
                            <a href="javascript:void(0);" class="btn-d-outline d-inline-block">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12.681" height="17.061" viewBox="0 0 12.681 17.061">
                                    <g id="np_export_2535025_000000" transform="translate(-32.121 -25.962)">
                                    <path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#ff7135"/>
                                    <path id="Path_2356" data-name="Path 2356" d="M44.093,70.07H32.83a.709.709,0,1,0,0,1.419H44.093a.709.709,0,0,0,0-1.419Z" transform="translate(0 -28.466)" fill="#ff7135"/>
                                    </g>
                                </svg>
                                Export
                            </a>
                        </div> -->
                    </div>
                </div>
            </div>
            <!--End Filters-->
            <div class="cssinptchk d-inline-block mr-4 position-relative pl-4">
                <span class="css-check">
                    <input class="css-checkbx" name="map_type" type="radio" value="all" [(ngModel)]="map_type" (ngModelChange)="getordersfilters()">
                    <label class="css-label"></label>
                </span>
                <p>All</p>															
            </div>
            <div class="cssinptchk d-inline-block mr-3 position-relative pl-4">
                <span class="css-check">
                    <input class="css-checkbx" name="map_type" type="radio" value="bulk"  [(ngModel)]="map_type" (ngModelChange)="getordersfilters()"/>
                    <label class="css-label"></label>
                </span>
                <p>Bulk</p>															
            </div>
            <div class="cssinptchk d-inline-block mr-3 position-relative pl-4">
                <span class="css-check">
                    <input class="css-checkbx" name="map_type" type="radio" value="wholesale"  [(ngModel)]="map_type" (ngModelChange)="getordersfilters()"/>
                    <label class="css-label"></label>
                </span>
                <p>Wholesale</p>															
            </div>
            <div class="table-responsive brndmgnttable odmgnttabl"> 
                <table class="table custom-table xs">
                    <thead>
                        <tr>
                            <th>Order Id</th>
                            <th>Transaction Id</th>
                            <th width="18%">Product Name</th>
                            <th>Buyer</th>
                            <th width="10%">Price (in ₹)
                            <img src="assets/images/admin/arrow.svg" />
                            </th>
                            <th width="10%">Pre-Booked On</th>
                            <th>Order Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!--Empty State-->
                        <tr *ngIf="!allorder_records.length">
                            <td colspan="7">
                                <div class="row pt-40 pb-40">
                                    <div class="col-md-12 text-center">
                                        <div class="mb-20"><img src="/assets/images/empty-state.png">
                                        </div>
                                        <div class="fs16">There are no records to display!</div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <!--End Empty State-->
                        <tr *ngFor="let record of allorder_records;">
                            <td>{{ record.order_id }}</td>
                            <td>{{ record.transaction_id }}</td>
                            <td>
                                <div class="order-info d-flex align-items-start">
                                    <div class="order-img">
                                        <img [src]="record.product_image" />
                                    </div>
                                    <div class="pd-seller-cntnt">
                                        <div class="txtdefault">
                                            <a href="javascript:;" class="order-link">{{ record.product_name }}</a>
                                        </div>
                                        <div class="pt-2">
                                            <span class="py-1 px-3 odbdge btn-d-outline ripple d-inline-block fs12">{{ record.map_type }}</span>
                                        </div>
                                    </div>
                                </div>

                            </td>												
                            <td>{{ record.buyer_name }}</td>
                            <td>{{ record.total_amount }}</td>
                            <td>{{ record.prebookedon }}</td>
                            <td>{{ record.order_status }}</td>												
                            <td>
                                <div class="d-flex align-items-center">
                                    <a href="javascript:voi(0);" class="add-whislist-link mr-3"><span>Track Order</span></a>
                                    <a href="javascript:void(0);" [routerLink]="['/admin/ordderdetails/'+record.id]" class="btn-d-outline align-middle ripple d-inline-block place-bid mr-3">Order Details</a>
                                    
                                    <a class="mr-3" href="javascript:void(0);" >
                                        <img src="assets/images/admin/edit.svg">
                                    </a>
                                    <span class="vdivider mr-3"></span>
                                    <a href="javascript:void(0);" [routerLink]="['/admin/duplicateorder']"><img src="assets/images/admin/dfile.svg"></a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>	
            </div>
        <div class="row mb-40" *ngIf="allorder_pagination.totalPages> 1">
            <div class="col-md-6">
                <div class="text-left pl-3">
                    <nav class="mt-3 tbpg rows-per-page">
                        <div>Rows per page:</div>
                        <select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
                            <option *ngFor="let option of page_per_view_options" [ngValue]="option.value">
                                {{option.name}}</option>
                        </select>
                    </nav>
                </div>
            </div>
            <div class="col-md-6">
                <div class="text-right pr-3">
                    <nav class="mt-3 tbpg">
                        <ul class="pagination align-items-center">
                            <li class="page-item" [ngClass]="{disabled:allorder_pagination.currentPage === 1}"><a
                                    class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a>
                            </li>

                            <li class="page-item" [ngClass]="{disabled:allorder_pagination.currentPage === 1}"><a
                                    class="page-link sprevicon" href="javascript:;"
                                    (click)="setpage(allorder_pagination.currentPage - 1)"></a></li>

                            <li class="page-item" i *ngFor="let page of allorder_pagination.pages"
                                [ngClass]="{active:allorder_pagination.currentPage === page}"><a class="page-link"
                                    href="javascript:;" (click)="setpage(page)">{{page}}</a></li>

                            <li *ngIf="(allorder_pagination.totalPages - allorder_pagination.startPage) > 5 ">
                                <a (click)="setpage(allorder_pagination.startPage + 5)">...</a>
                            </li>
                            <li *ngIf="(allorder_pagination.totalPages - allorder_pagination.startPage) > 5">
                                <a
                                    (click)="setpage(allorder_pagination.totalPages)">{{allorder_pagination.totalPages}}</a>
                            </li>

                            <li class="page-item"
                                [ngClass]="{disabled:allorder_pagination.currentPage === allorder_pagination.totalPages}">
                                <a class="page-link snexticon" href="javascript:;"
                                    (click)="setpage(allorder_pagination.currentPage + 1)"></a>
                            </li>
                            <li class="page-item"
                                [ngClass]="{disabled:allorder_pagination.currentPage === allorder_pagination.totalPages}">
                                <a class="page-link nexticon" href="javascript:;"
                                    (click)="setpage(allorder_pagination.totalPages)"></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div> 
    </div>




	</div>
</div>
<app-footer-seller></app-footer-seller>
<!-- VERIFY EMAIL -->
<ng-template #content let-modal>
   <div class="modal-body text-center mdlcmnui pt-4 mt-3">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
      </button>
      <h5 class="modal-title mb-10 ">Verify Email Address</h5>
      <div class="modal-subtitle">Enter the verification code sent <br>to sunil16@gmail.com</div>
      <div class="form-container">
         <form action="" class="mt-40 mb-10 vrf-frm-ipt">
            <input class="otp" type="text" oninput='digitValidate(this)' onkeyup='tabChange(1)' maxlength=1 > <!--  form-error -->
            <input class="otp" type="text" oninput='digitValidate(this)' onkeyup='tabChange(2)' maxlength=1 >
            <input class="otp" type="text" oninput='digitValidate(this)' onkeyup='tabChange(3)' maxlength=1 >
            <input class="otp" type="text" oninput='digitValidate(this)'onkeyup='tabChange(4)' maxlength=1 >
         </form>
         <!-- <span class="help-block">Incorrect OTP, enter the correct OTP</span>     -->
      </div>
      <div class="pt-20 pb-20">
         <div class="modal-subtitle">Didn’t received code?<br>
            <a href="javascript:;" class="resend">Resend Code</a>
         </div>
      </div>
      <div class="cta mt-40 pb-4 mb-1">
         <button class="btn btn-mod btn-color btn-medium btn-round ripple btn-block">Verify Email ID</button>
      </div>
   </div>
</ng-template>
<div class="toasteml wt700 ">
   <i></i>
   Email address has been verified successfully.
   <span></span>
</div>
<!-- VERIFY EMAIL -->
<!-- CHANGE MOBILE NUMBER -->
<ng-template #entermobile let-modal>
   <div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
      </button>
      <h5 class="modal-title">Change Mobile Number</h5>
      <div class="modal-subtitle pb-5">Enter Your New Mobile Number</div>
      <div class="form-container mb-10">
         <div class="floating-label mb-0">      
            <input class="form-control" type="text" value="+91 89876 50432" placeholder=" ">
            <span class="highlight"></span>
            <label>Phone Number</label>
         </div>
      </div>
      <!-- <div class="help-block text-left">Please enter the correct password</div> -->
      <div class="text-right pb-60">
      </div>
      <div class="cta mt-40 pb-4 mb-2">
         <a href="javascript:void(0);"  class="btn btn-mod btn-color btn-medium btn-round ripple btn-block">Send OTP</a>
      </div>
   </div>
</ng-template>
<!-- CHANGE MOBILE NUMBER -->
<!-- CHANGE PASSWORD -->
<ng-template #changepassword let-modal>
   <div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
      </button>
      <h5 class="modal-title">Change Password</h5>
      <div class="form-container">
         <div class="floating-label mb-0">      
            <input class="form-control" type="password" placeholder=" ">
            <span class="highlight"></span>
            <label>Current Password</label>
         </div>
         <div class="floating-label mt-20 mb-0">      
            <input class="form-control" type="password" placeholder=" ">
            <span class="highlight"></span>
            <label>New Password</label>
         </div>
         <div class="floating-label mt-20 mb-0">      
            <input class="form-control" type="password" placeholder=" ">
            <span class="highlight"></span>
            <label>Re-enter New Password</label>
         </div>
         <ul class="cpswd-hint">
            <li class="fs12 txtseconday">At least 8 characters—the more characters, the better.</li>
            <li class="fs12 txtseconday">A mixture of both uppercase and lowercase letters.</li>
            <li class="fs12 txtseconday">A mixture of letters and numbers.</li>
            <li class="fs12 txtseconday">Inclusion of at least one special character, e.g., ! @ # ? ]</li>
         </ul>
      </div>
      <div class="cta pb-4 text-center">
         <a  class="btn btn-mod btn-color mx-auto btn-medium btn-round ripple btn-block chmx">Change Password</a>
      </div>
   </div>
</ng-template>
<!-- CHANGE PASSWORD -->
<!-- EDIT PROFILE PHOTO -->
<ng-template #profilephoto let-modal>
   <div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
      </button>
      <h5 class="modal-title text-center">Edit Profile Photo</h5>
      <div class="form-container">
         <div class="file-inpt-cntnt radius10 text-center">
            <input type="file" class="fltyp" >
            <p class="fs16 txtseconday">Drag and Drop an Image Here</p>
         </div>
      </div>
      <div class="cta  pb-4 text-right">
         <a  class="btn btn-mod btn-color btn-medium btn-round ripple">Upload from Device</a>
         <a  class="resend d-inline-block ml-3">Remove Photo</a>
      </div>
   </div>
</ng-template>
<!-- EDIT PROFILE PHOTO -->

