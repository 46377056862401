<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain pb-4">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center pinfo-head">
			<div class="col-md-6 mb-4">
				<h3 class="fs24 wt700 txtdefault">Review Moderation</h3>				
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">	
						<li class="breadcrumb-item"><a href="javascript:void(0);" class="txtseconday">Product Management</a></li>
						<li class="breadcrumb-item active">Review Moderation</li>
					</ol>
				</nav>
			</div>
			<div class="col-md-6  mb-4 text-md-right text-center">
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4 mb-0">

			<div class="custom-tabs">
				<ul>
					<li><a [routerLink]="['/admin/review-moderation/product-reviews']">Product Review</a></li>
					<li><a [routerLink]="['/admin/review-moderation/seller-reviews']" href="javascript:;" class="active">Seller Review</a></li>
				</ul>
			</div>
			
			<!--Filters-->
      		<div class="row d-flex justify-content-between mt-20 report-cta">
				<div class="col-md-5">
					<div class="report-input">
						<div class="input-group-custom">
							<input type="text" placeholder="Search by Product name or Transaction ID" class="form-control">
							<div class="input-group-append-custom">
								<img src="assets/images/svg/search_logo.svg">
							</div>
						</div>
					</div>
				</div>

				<div class="col-md-7">
					<div class="d-flex justify-content-end">
						<div class="mr-3">
							<a href="javascript:void(0);" class="d-inline-block fltr-round-btn radius10 txtseconday">
								<img src="assets/images/svg/filter.svg">
								Filters
							</a>
						</div>
						<div class="form-container mt-0 mb-0 mr-3">
							<div class="report-input floating-label">
								<div class="input-group-custom">
									<input type="text" value="Last 30 Days" class="form-control" placeholder=" " />
									<span class="highlight"></span>
									<label>Date</label>
									<div class="input-group-append-custom">
										<img src="assets/images/icons/filter-calendar.svg">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!--End Filters-->
        	<table class="table custom-table">
				<thead>
					<tr>
						<th width="10%">Date of Review</th>
						<th width="15%">Buyer Name</th>
						<th>Seller Name</th>
						<th>User’s Rating</th>
						<th width="15%">User’s Review</th>
						<th>Status</th>
						<th width="15%">Action</th>
					</tr>
				</thead>
				<tbody>
					<!--Empty State-->
					<tr *ngIf="!seller_records.length">
						<td colspan="8">
							<div class="row pt-40 pb-40">
								<div class="col-md-12 text-center">
									<div class="mb-20"><img src="/assets/images/empty-state.png">
									</div>
									<div class="fs16">There are no records to display!</div>
								</div>
							</div>
						</td>
					</tr>
					<!--End Empty State-->
					<tr *ngFor="let record of seller_records;">
						<td>{{ record.created_at }}</td>
						<td>
							<a href="javascript:;" class="link-alt fs16">{{ record.buyer_name }}</a>
						</td>
						<td><a href="javascript:;" class="link-alt fs16">{{ record.seller_name }}</a></td>
						<td>
							<div class="ratings star-ratings pr-1 d-inline-block">
								<ngb-rating [max]="5" [(rate)]="record.rating" [readonly]="false" class="rating_star"></ngb-rating>
							</div>
						</td>
						<td>
							{{ record.review }}
						</td>
						<td>{{ record.status }}</td>
						<td>
							<a href="javascript:void(0);" [routerLink]="['/admin/review-moderation/view-seller-review/'+record.id]" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Details</a>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="row mb-40" *ngIf="seller_pagination.totalPages> 1">
			<div class="col-md-6">
				<div class="text-left pl-3">
					<nav class="mt-3 tbpg rows-per-page">
						<div>Rows per page:</div>
						<select class="select-box" [(ngModel)]="page_per_view"
							(change)="setpageperview()">
							<option *ngFor="let option of page_per_view_options"
								[ngValue]="option.value">
								{{option.name}}</option>
						</select>
					</nav>
				</div>
			</div>
			<div class="col-md-6">
				<div class="text-right pr-3">
					<nav class="mt-3 tbpg">
						<ul class="pagination align-items-center">
							<li class="page-item"
								[ngClass]="{disabled:seller_pagination.currentPage === 1}"><a
									class="page-link previcon" href="javascript:;"
									(click)="setpage(1)"></a>
							</li>

							<li class="page-item"
								[ngClass]="{disabled:seller_pagination.currentPage === 1}"><a
									class="page-link sprevicon" href="javascript:;"
									(click)="setpage(seller_pagination.currentPage - 1)"></a></li>

							<li class="page-item" i *ngFor="let page of seller_pagination.pages"
								[ngClass]="{active:seller_pagination.currentPage === page}"><a
									class="page-link" href="javascript:;"
									(click)="setpage(page)">{{page}}</a></li>

							<li
								*ngIf="(seller_pagination.totalPages - seller_pagination.startPage) > 5 ">
								<a (click)="setpage(seller_pagination.startPage + 5)">...</a>
							</li>
							<li
								*ngIf="(seller_pagination.totalPages - seller_pagination.startPage) > 5">
								<a
									(click)="setpage(seller_pagination.totalPages)">{{seller_pagination.totalPages}}</a>
							</li>

							<li class="page-item"
								[ngClass]="{disabled:seller_pagination.currentPage === seller_pagination.totalPages}">
								<a class="page-link snexticon" href="javascript:;"
									(click)="setpage(seller_pagination.currentPage + 1)"></a>
							</li>
							<li class="page-item"
								[ngClass]="{disabled:seller_pagination.currentPage === seller_pagination.totalPages}">
								<a class="page-link nexticon" href="javascript:;"
									(click)="setpage(seller_pagination.totalPages)"></a>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</div>
	</div>	 