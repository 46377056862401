<app-header-seller></app-header-seller>
<app-sidebar-seller></app-sidebar-seller>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Home</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:;">Bids and Offers</a>
						</li>
						<li class="breadcrumb-item active">Bids Market Place</li>
					</ol>
				</nav>
			</div>
		</div>
		<!--- /HEAD  --->

		<div class="row mb-40">
            <div class="col-md-4">
                <div class="bid-order-info mb-10 d-flex align-items-center">
                    <div class="ng-autocomplete">
                        <ng-autocomplete [data]="result" [searchKeyword]="keyword" (selected)='selectEvent($event)'
                            (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)'
                            [itemTemplate]="itemTemplate" placeholder="Search Category">
                        </ng-autocomplete>
                        <ng-template #itemTemplate let-item>
                            <a href="javascript:void(0)">
                                <div class="d-flex">
                                    <span [innerHTML]="item.name"></span>
                                </div>
                            </a>
                        </ng-template>
                        <div class="search-icon"><img src="assets/images/svg/search_logo.svg"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="bid-order-info mb-10 d-flex align-items-center">

                    <div class="ng-autocomplete">
                        <ng-autocomplete [data]="presult" [searchKeyword]="keyword" (selected)='pselectEvent($event)'
                            (inputChanged)='ponChangeSearch($event)' (inputFocused)='ponFocused($event)'
                            [itemTemplate]="pitemTemplate" placeholder="Search Product">
                        </ng-autocomplete>
                        <ng-template #pitemTemplate let-item>
                            <a href="javascript:void(0)">
                                <div class="d-flex">
                                    <span [innerHTML]="item.name"></span>
                                </div>
                            </a>
                        </ng-template>
                        <div class="search-icon"><img src="assets/images/svg/search_logo.svg"></div>
                    </div>
                </div>
            </div>
        </div>

		
		<div class="notifications-container theme-tab">
			<ngb-tabset [justify]="'left'" [type]="'pills'" class="tabs tab-title" #tabset="ngbTabset">
	            <ngb-tab id="allBids">
	                <ng-template ngbTabTitle>All Bids ({{ bid_records.length }})</ng-template>
	                <ng-template ngbTabContent>
	                	<div class="card cardui carduipro p-4 mb-0">
		                  	<div class="tab-content">
		                  		<div class="table-responsive">
		                  			<table class="table custom-table bids-offers-table xs">
		                  				<thead>
		                  					<tr>
		                  						<th colspan="4" class="text-center buyer"><b>Buyer</b></th>
		                  						<th colspan="7" class="text-center"><b>Seller</b></th>
		                  					</tr>
		                  				</thead>
		                  				<thead>
		                  					<tr>
		                  						<th class="buyer">Bid Id</th>
                                                <th class="buyer" width="15%">Product</th>
		                  						<th class="buyer">Order<br>Qty</th>
		                  						<th class="buyer">Best Bid Price</th>
		                  						<th>Listed<br>Price</th>
		                  						<th>Min Order<br>Qty</th>
		                  						<th>Total<br>Listed Qty</th>
		                  						<th>Supply<br>Location</th>
		                  						<th>Dispatched<br>(in Days)</th>
		                  						<th width="12%">Seller Name</th>
		                  						<th>Last Traded<br>Price</th>
												<th>Action</th>
		                  					</tr>
		                  				</thead>
		                  				<tbody>
                                            <!--Empty State-->
                                            <tr *ngIf="!bid_records.length">
                                                <td colspan="12">
                                                    <div class="row pt-40 pb-40">
                                                        <div class="col-md-12 text-center">
                                                            <div class="mb-20"><img
                                                                    src="/assets/images/empty-state.png">
                                                            </div>
                                                            <div class="fs16">There are no records to
                                                                display!</div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <!--End Empty State-->
		                  					<tr *ngFor="let record of bid_records; let i = index;">
		                  						<td>{{ record.bid_unique_id }}</td>
                                                <td>
													<div class="order-info">
                                                        <div class="order-img">
                                                            <img src="{{ record.product_image }}" />
                                                        </div>
                                                        <div class="pd-seller-cntnt">
                                                            <div class="txtdefault">
                                                                <a href="javascript:;" [routerLink]="['/seller/bids-and-offers/'+record.product_id]">{{ record.product_name }}</a>
                                                            </div>
                                                        </div>
                                                    </div>
												</td>
		                  						<td><b>{{ record.order_qty }}</b></td>
		                  						<td><div><b>{{ record.bid_price }}</b></div> <div class="badge-d-outline d-inline-block odbdge fs10">{{ record.total_bid }} Bids</div></td>
		                  						<td>{{ record.listed_price }}</td>
		                  						<td>{{ record.min_qty }}</td>
		                  						<td>{{ record.listed_qty }}</td>
		                  						<td>{{ record.supply_location }}</td>
		                  						<td>{{ record.dispached_in }}</td>
		                  						<td>
													<div class="order-info">
														<!-- <div class="order-img">
															<img [src]="record.seller_logo?record.seller_logo:'assets/images/svg/vendor.svg'" />
														</div> -->
														<div class="pd-seller-cntnt">
															<div class="txtdefault">
																<a href="javascript:;" class="order-link">{{ record.seller_name }}</a>
															</div>
														</div>
													</div>
												</td>
												<td>{{ record.last_traded_price }}</td>
												<td>
                                                    <div class="d-flex align-items-center">
                                                        <a class="btn-d-outline d-inline-block place-bid" href="javascript:void(0);" [routerLink]="['/seller/bids-and-offers/'+record.product_id]">
                                                            View All Bid ({{ record.total_bid }})
                                                        </a>
                                                    </div>
                                                </td>
		                  					</tr>
		                  					

		                  				</tbody>
		                  			</table>
		                  		</div>
		                  	</div>
		                </div>

						<div class="row" *ngIf="all_bids_pagination.totalPages> 1">
                            <div class="col-md-6">
                                <div class="text-left pl-3">
                                    <nav class="mt-3 tbpg rows-per-page">
                                        <div>Rows per page:</div>
                                        <select class="select-box" [(ngModel)]="page_per_view"
                                            (change)="setpageperview()">
                                            <option *ngFor="let option of page_per_view_options"
                                                [ngValue]="option.value">{{option.name}}</option>
                                        </select>
                                    </nav>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="all_bids_pagination.pages && all_bids_pagination.pages.length">
                                <div class="text-right pr-3">
                                    <nav class="mt-3 tbpg">
                                        <ul class="pagination align-items-center">
                                            <li class="page-item"
                                                [ngClass]="{disabled:all_bids_pagination.currentPage === 1}"><a
                                                    class="page-link previcon" href="javascript:;"
                                                    (click)="setpage(1)"></a></li>

                                            <li class="page-item"
                                                [ngClass]="{disabled:all_bids_pagination.currentPage === 1}"><a
                                                    class="page-link sprevicon" href="javascript:;"
                                                    (click)="setpage(all_bids_pagination.currentPage - 1)"></a></li>

                                            <li class="page-item" i *ngFor="let page of all_bids_pagination.pages"
                                                [ngClass]="{active:all_bids_pagination.currentPage === page}"><a
                                                    class="page-link" href="javascript:;"
                                                    (click)="setpage(page)">{{page}}</a></li>

                                            <li
                                                *ngIf="(all_bids_pagination.totalPages - all_bids_pagination.startPage) > 5 ">
                                                <a (click)="setpage(all_bids_pagination.startPage + 5)">...</a>
                                            </li>
                                            <li
                                                *ngIf="(all_bids_pagination.totalPages - all_bids_pagination.startPage) > 5">
                                                <a
                                                    (click)="setpage(all_bids_pagination.totalPages)">{{all_bids_pagination.totalPages}}</a>
                                            </li>

                                            <li class="page-item"
                                                [ngClass]="{disabled:all_bids_pagination.currentPage === all_bids_pagination.totalPages}">
                                                <a class="page-link snexticon" href="javascript:;"
                                                    (click)="setpage(all_bids_pagination.currentPage + 1)"></a>
                                            </li>
                                            <li class="page-item"
                                                [ngClass]="{disabled:all_bids_pagination.currentPage === all_bids_pagination.totalPages}">
                                                <a class="page-link nexticon" href="javascript:;"
                                                    (click)="setpage(all_bids_pagination.totalPages)"></a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
	                </ng-template>
	            </ngb-tab>	
	            <ngb-tab id="myBids">
	                <ng-template ngbTabTitle>My Bids ({{ mybid_records.length }})</ng-template>
	                <ng-template ngbTabContent>
						<div class="row mb-40">
							<div class="col-md-4">
								<div class="bid-order-info mb-10 d-flex align-items-center">
									<div class="col-md-12">
										<div class="floating-label mb-4">
											<select name="bid_status" [(ngModel)]="bid_status" class="form-control" (change)="getbidmylisting()">
												<option value="pending">Pending Bids</option>
												<option value="accepted">Accepted Bids</option>
												<option value="declined">Declined/Rejected Bids</option>
											</select>
										</div> 
									</div>
								</div>
							</div>
						</div>
						<div class="card cardui carduipro p-4 mb-20"
                            *ngFor="let record of mybid_records; let i = index;">
                            <div class="tab-content">
                                <div class="row d-flex align-items-center justify-content-between">
                                    <div class="col-12 col-md-6">
                                        <span class="fs20 f-700 mr-4">Bid ID : {{ record.bid_unique_id }}</span>
                                        <span class="status-badge danger"
                                            *ngIf="record.status == 'Declined'">Declined</span>
                                        <span class="status-badge success"
                                            *ngIf="record.status == 'Accepted'">Accepted</span> <span
                                            class="ml-3 text-active f-600" *ngIf="record.status == 'Accepted'">You saved
                                            {{ record.saved_amount }}</span>
                                        <span class="status-badge pending"
                                            *ngIf="record.status == 'Pending'">Pending</span>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="d-flex align-items-center justify-content-end text-muted md-block">
                                            <!-- <a href="javascript:;" class="link-alt fs14 mr-2"
                                                *ngIf="!record.watchlist">Add to Watchlist</a> -->
                                            <!-- <span class="mr-2"
                                                *ngIf="!record.watchlist && record.status != 'Accepted'">&nbsp;|&nbsp;</span> -->
                                            <!-- <a href="javascript:;" *ngIf="record.status != 'Accepted'" (click)="deletebid(record)"><img
                                                    src="/assets/images/icons/delete-icon.svg"></a> -->
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="row mb-0">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="fs15 f-700 mb-20">Product Info:</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark f-600">Order Qty:</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark f-600">Bidding Price:</div>
                                            </div>
											<div class="col text-center">
                                                <div class="text-dark f-600">Counter Offer:</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark f-600">Listed Price:</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark f-600">Minimum Order Qty.:</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark f-600">Total Listed Qty.:</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark f-600">Supply Location:</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark f-600">Dispatched(in Days):</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark f-600">Buyer Name:</div>
                                            </div>
                                            <div class="col text-center" *ngIf="record.status == 'Accepted'">
                                                <div class="text-muted f-600">Total Amount:</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-md-4">
                                                <div class="d-flex align-items-center">
                                                    <div class="mr-3"><img [src]="record.product_image" class="mx-w-80"></div>
                                                    <h5 class="mb-0 fs14 wt700">{{ record.product_name }}</h5>
                                                </div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark"><b>{{ record.order_qty }}</b></div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark"><b>{{ record.best_bid_price }}</b></div>
                                            </div>
											<div class="col text-center">
                                                <div class="text-dark"><b>{{ record.seller_amount }}</b></div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark">{{ record.listed_price }}</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark">{{ record.min_order_qty }}</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark">{{ record.total_listed_qty }}</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark">{{ record.supply_location }}</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark">{{ record.dispatched_in }}</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark">{{ record.buyer_name }}</div>
                                            </div>
                                            <div class="col text-center" *ngIf="record.status == 'Accepted'">
                                                <div class="text-dark">{{ record.total_amount }}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
										<hr>
                                        <div class="mt-20 mb-10 text-right">
											<a *ngIf="record.status == 0" href="javascript:void(0);" (click)="open(content, record)" class="btn-d-outline-success d-inline-block place-bid mr-2">
												Accept Bid
											</a>
											<a *ngIf="record.status == 0" class="btn-d-outline d-inline-block place-bid mr-2" (click)="openCounterOffer(content2, record)" href="javascript:void(0);">
												Counter Offer
											</a>
											<a *ngIf="record.status == 0" class="btn-d-outline d-inline-block place-bid mr-2" (click)="open(contentdecline, record)" href="javascript:void(0);">
												Decline
											</a>
											<a *ngIf="record.status == 2" class="btn-d-outline d-inline-block place-bid mr-2" href="javascript:void(0);">
												Declined
											</a>
										</div>
                                    </div>
                                </div>
                            </div>
                        </div>
	                  	<!-- <div class="card cardui carduipro p-4 mb-0">
		                  	<div class="tab-content">
		                  		<div class="table-responsive">
		                  			<table class="table custom-table bids-offers-table xs">
		                  				<thead>
		                  					<tr>
		                  						<th colspan="4" class="text-center buyer"><b>Buyer</b></th>
		                  						<th colspan="8" class="text-center"><b>Seller</b></th>
		                  					</tr>
		                  				</thead>
		                  				<thead>
		                  					<tr>
		                  						<th class="buyer">Bid Id</th>
                                                <th class="buyer">Product</th>
		                  						<th class="buyer">Order<br>Qty</th>
		                  						<th class="buyer">Best Bid Price</th>
		                  						<th>Listed<br>Price</th>
		                  						<th>Min Order<br>Qty</th>
		                  						<th>Total<br>Listed Qty</th>
		                  						<th>Supply<br>Location</th>
		                  						<th>Dispatched<br>(in Days)</th>
		                  						<th width="15%">Seller Name</th>
		                  						<th>Last Traded<br>Price</th>
		                  						<th>Actions</th>
		                  					</tr>
		                  				</thead>
		                  				<tbody>
                                            <tr *ngIf="!mybid_records.length">
                                                <td colspan="12">
                                                    <div class="row pt-40 pb-40">
                                                        <div class="col-md-12 text-center">
                                                            <div class="mb-20"><img
                                                                    src="/assets/images/empty-state.png">
                                                            </div>
                                                            <div class="fs16">There are no records to
                                                                display!</div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
		                  					<tr *ngFor="let record of mybid_records; let i = index;">
												<td>{{ record.bid_unique_id }}</td>
                                                <td><a href="javascript:;"
                                                    [routerLink]="['/seller/bids-and-offers/'+record.product_id]">{{
                                                    record.product_name }}</a></td>
												<td>{{ record.order_qty }}</td>
												<td>{{ record.best_bid_price }}</td>
												<td>{{ record.listed_price }}</td>
												<td>{{ record.min_order_qty }}</td>
												<td>{{ record.total_listed_qty }}</td>
												<td>{{ record.supply_location }}</td>
												<td>{{ record.dispatched_in }}</td>
		                  						<td>
													<div class="order-info">
														<div class="order-img">
															<img [src]="record.seller_logo?record.seller_logo:'assets/images/svg/vendor.svg'" />
														</div>
														<div class="pd-seller-cntnt">
															<div class="txtdefault">
																<a href="javascript:;" class="order-link">{{ record.seller_name }}</a>
															</div>
														</div>
													</div>
												</td>
												<td>{{ record.last_traded_price }}</td>
												<td>
													<div class="d-flex align-items-center">
														<a *ngIf="record.status == 0" href="javascript:void(0);" (click)="open(content, record)" class="btn-d-outline-success ripple d-inline-block place-bid2 mr-2">
															<svg xmlns="http://www.w3.org/2000/svg" width="24.175" height="16.822" viewBox="0 0 24.175 16.822">
															  <path id="np_tick_1446951_000000" d="M24.933,43.667a1.473,1.473,0,0,0,2.1,0L40.9,29.762a1.476,1.476,0,0,0-2.066-2.1L25.967,40.532l-6.308-6.307a1.486,1.486,0,1,0-2.1,2.1Z" transform="translate(-17.121 -27.286)" fill="#35cb4e"/>
															</svg>
														</a>
														<a *ngIf="record.status == 0" class="btn-d-outline d-inline-block place-bid" (click)="openCounterOffer(content2, record)" href="javascript:void(0);">
															Counter Offer
														</a>
														<a *ngIf="record.status == 0" class="btn-d-outline d-inline-block place-bid" (click)="open(contentdecline, record)" href="javascript:void(0);">
															Decline
														</a>
														<a *ngIf="record.status == 2" class="btn-d-outline d-inline-block place-bid" href="javascript:void(0);">
															Declined
														</a>
													</div>
												</td>
		                  					</tr>
		                  				</tbody>
		                  			</table>
		                  		</div>
		                  	</div>
		                </div> -->
	                </ng-template>
	            </ngb-tab>									
	    	</ngb-tabset>
	    </div>
	</div>
</div>

<!-- ACCEPT BID MODAL -->
<ng-template #content let-modal>
	<div class="text-right pt-3 pr-3 od-mdl-tl">
	<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
	</div>
  
  	<div class="modal-body pb-3">
    <div class="row align-items-center mb-30">
		<div class="col-md-12">
			<div class="rtrqsthead"> 
				<h4 class="txtdefault text-center wt700 fs22 mb-20">Accept a Bid</h4>
			</div>
			<div class="mb-10 f-600 fs10"><i placement="top" ngbTooltip="This will act as a default filter every time you will toggle." class="mr-2"> <img src="/assets/images/icons/info.png"></i> After accepting the offer, your new total listed quantity will be {{ selected_bid.total_listed_qty }}. This will change across all Buyers. <a href="javascript:;" class="link-alt fs10">Update Stock</a></div>
			
			<div class="accept-bid">
				<table class="table custom-table xs mb-0">
					<thead>
						<tr>
							<th>Bid Id</th>
							<th>Order Qty</th>
							<th>Bid Price</th>
							<th>Supply Location</th>
							<th>Total Amount</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{{ selected_bid.bid_unique_id }}</td>
							<td>{{ selected_bid.order_qty }}</td>
							<td>{{ selected_bid.bid_price }}</td>
							<td>{{ selected_bid.supply_location }}</td>
							<td><b>{{ selected_bid.total_amount }}</b></td>
						</tr>
					</tbody>
				</table>	
			</div>

			<div class="text-left mt-30">
				<div class="text-muted mb-10">Are you sure you want to accept this Bid?</div>
				<div class="d-flex align-items-center">
					<a class="btn-d-outline d-inline-block place-bid mr-2" href="javascript:void(0);" (click)="openCounterOffer(content2, selected_bid)">
						Counter Offer
					</a>
					<a href="javascript:void(0);" class="btn-d-outline-success ripple d-inline-block place-bid2" (click)="acceptbid()">
						Accept Bid
					</a>
				</div>
			</div>
		</div>
	</div>
	<hr>
	<div class="row align-items-center">
		<div class="col-md-12">
			<div class="text-center fs10">
				By accepting a bid, you are agreeing to sell this item and will be required to sell within Dispatch date. The buyer has 24 hours to pay the full amount.
			</div>
		</div>
	</div>
 	</div>
</ng-template>
<!-- /ACCEPT BID MODAL -->

<!-- COUNTER OFFER MODAL -->
<ng-template #content2 let-modal>
	<div class="text-right pt-3 pr-3 od-mdl-tl">
	<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
	</div>
  
  	<div class="modal-body pb-3">
    <div class="row align-items-center mb-20">
		<div class="col-md-12">
			<div class="rtrqsthead"> 
				<h4 class="txtdefault text-center wt700 fs22 mb-20">Counter Offer</h4>
			</div>
			<div class="mb-20 f-600 fs10"><i placement="top" ngbTooltip="This will act as a default filter every time you will toggle." class="mr-2"> <img src="/assets/images/icons/info.png"></i> Your offer will be sent to 1 interested buyer. Interested buyers are people watching your item. Once you send the offer, they’ll have 48 hours to respond.</div>
			
			<div class="accept-bid">
				<table class="table custom-table xs mb-0">
					<thead>
						<tr>
							<th>Bid Id</th>
							<th>Order Qty</th>
							<th>Bid Price</th>
							<th>Counter Offer</th>
							<th>Supply Location</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{{ selected_bid.bid_unique_id }}</td>
							<td>{{ selected_bid.order_qty }}</td>
							<td>{{ selected_bid.bid_price }}</td>
							<td>{{ selected_bid.seller_amount }}</td>
							<td>{{ selected_bid.supply_location }}</td>
						</tr>
					</tbody>
				</table>	
			</div>

			<div class="row mt-30">
				<div class="col-md-10">
					<div class="profileipt">
						<div class="form-container mt-0 mb-0 p-eml-rt">
							<div class="floating-label mb-10"> 
								<input type="text" class="form-control" placeholder="" [(ngModel)]="new_bid_amount">
		                  		<button class="btn btn-outline-secondary fs16 btn-o-default" (click)="savecounteroffer()" type="button" [disabled]="!new_bid_amount">
		                     		<span>Change</span>
								</button>
							   <span class="highlight"></span>
							   <label>Enter the New Amount</label>
							</div>
						</div>
					</div>
					<p class="mb-0 fs12">Please enter atleast {{ selected_bid.reduce_percentage }}% less than your listed price <span class="text-muted">[{{ selected_bid.max_bid_price }} or less]</span></p>
				</div>
			</div>
		</div>
	</div>
	<hr>
	<div class="row align-items-center">
		<div class="col-md-12">
			<div class="text-center fs10">
				By counter offering a bid, you are agreeing to sell this item if the buyer accept your offer and will be required to sell within Dispatch date. Once you send the offer, they’ll have 48 hours to respond.
			</div>
		</div>
	</div>
 	</div>
</ng-template>
<!-- /COUNTER OFFER MODAL -->


<!-- REMOVE PRODUCT -->
<ng-template #content3 let-modal>
	<div class="modal-body mdlcmnui pt-4 mt-3">
        <h4 class="txtdefault text-center wt700 fs22 mb-20">Remove Product from Bids and Offers</h4>
        <div class="bid-order-info">
			<div class="order-img">
				<img src="assets/images/icons/info.png" />
			</div>
			<div class="pd-seller-cntnt">
				<div class="fs12">Removing the product will only delist the product from Bids & Offers. It will not delist the product from the market. Buyers will be able to order it on MRP.</div>
			</div>
		</div>

		<div class="modal-subtitle text-center pt-30 pb-30">Are you sure you want to remove the following product from Bids and Offers?</div>

        <div class="bid-order-info d-flex align-items-center">
			<div class="order-img">
				<img src="/assets/images/p-2.jpg" />
			</div>
			<div class="pd-seller-cntnt">
				<h5 class="mb-0 fs16">Vivo X60 Pro (Midnight Black, 12GB RAM, 256GB Storage) | Upto 12 Months No Cost EMI | Extra 4000 Off on Exchange</h5>
			</div>
		</div>

        <div class="text-center pt-30 pb-20">
           <button type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')" class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4">
		      Cancel
		   	</button>
		   	<button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4">
		      Remove from Bids and Offers
		   	</button>
        </div>
      </div>
</ng-template>

<!-- Decline BID MODAL -->
<ng-template #contentdecline let-modal>
	<div class="text-right pt-3 pr-3 od-mdl-tl">
	<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
	</div>
  
  	<div class="modal-body pb-3">
    <div class="row align-items-center mb-30">
		<div class="col-md-12">
			<div class="rtrqsthead"> 
				<h4 class="txtdefault text-center wt700 fs22 mb-20">Decline a Bid</h4>
			</div>
			<!-- <div class="mb-10 f-600 fs10">
				<i placement="top" ngbTooltip="This will act as a default filter every time you will toggle." class="mr-2">
				<img src="/assets/images/icons/info.png"></i>
				After accepting the offer, your new total listed quantity will be {{ selected_bid.total_listed_qty }}. This will change across all Buyers.
				<a href="javascript:;" class="link-alt fs10">Update Stock</a>
			</div> -->
			
			<div class="accept-bid table-card">
				<table class="table custom-table xs mb-0">
					<thead>
						<tr>
							<th>Bid Id</th>
							<th>Order Qty</th>
							<th>Bid Price</th>
							<th>Supply Location</th>
							<th>Total Amount</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{{ selected_bid.bid_unique_id }}</td>
							<td>{{ selected_bid.order_qty }}</td>
							<td>{{ selected_bid.bid_price }}</td>
							<td>{{ selected_bid.supply_location }}</td>
							<td><b>{{ selected_bid.total_amount }}</b></td>
						</tr>
					</tbody>
				</table>	
			</div>

			<div class="text-left mt-30">
				<div class="text-muted mb-10">Are you sure you want to decline this Bid?</div>
				<div class="d-flex align-items-center">
					<a class="btn-d-outline d-inline-block place-bid mr-2" href="javascript:void(0);" (click)="openCounterOffer(content2, selected_bid)">
						Counter Offer
					</a>
					<a href="javascript:void(0);" class="btn-d-outline-success ripple d-inline-block place-bid2" (click)="declinebid()">
						Decline Bid
					</a>
				</div>
			</div>
		</div>
	</div>
	<hr>
	<!-- <div class="row align-items-center">
		<div class="col-md-12">
			<div class="text-center fs10">
				By accepting a bid, you are agreeing to sell this item and will be required to sell within Dispatch date. The buyer has 24 hours to pay the full amount.
			</div>
		</div>
	</div> -->
 	</div>
</ng-template>
<!-- /ACCEPT BID MODAL -->

<app-footer-seller></app-footer-seller>