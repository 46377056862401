import { Component, OnInit } from '@angular/core';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { BuyerTransactionHistoryService } from './buyer-transaction-history.service';

@Component({
  selector: 'app-buyer-transaction-history',
  templateUrl: './buyer-transaction-history.component.html',
  styleUrls: ['./buyer-transaction-history.component.scss']
})
export class BuyerTransactionHistoryComponent implements OnInit {

    public search_term:string = '';
    public from_date:any = '';
    public to_date:any = '';
    public transaction_records:any = [];
    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 20;
    public transaction_pagination: any = {};
    public page_per_view_options = [
        {
            value: 20,
            name: 20
        },
        {
            value: 40,
            name: 40
        }
    ];

    constructor(
        private _transactionservice: BuyerTransactionHistoryService,
        private _toastr: ToasterService,
        private _pagination: PaginationService

    ) { }

    ngOnInit(): void {
        this.gettransaction();
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.getdetails();
    }

    setpageperview() {
        this.current_page = 1;
        this.getdetails();
    }

    gettransaction() {
        this.page_per_view = 10;
        this.offset = 0;
        this.current_page = 1;
        this.getdetails();
    }

    

    getdetails() {
        let data = { 
            limit: this.page_per_view, 
            offset: this.offset, 
            current_page: this.current_page, 
            search: this.search_term,
            from_date: this.from_date,
            to_date: this.to_date
        };
        this._transactionservice.gettransactiondetails(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.transaction_records = data['records'];
                let total_records = data['total_records'];
                this.transaction_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    openDetails(record){
        record.view_details = !record.view_details;
    }

}

