import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AboutSellerService {

    constructor(private _http: HttpClient) { }

    getsellermore(data) {
        return this._http.post(environment.api_url+'product/seller/details',data);
    }

}
