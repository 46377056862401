<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);" [routerLink]="['/admin/product-listing']">Product Management</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" [routerLink]="['/admin/manage-product-categories']">Manage Product Categories</a>
						</li>
						<li class="breadcrumb-item active">Add New Category</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ml-3">Assistance</a>
			</div>
		</div>

		<h3 class="f-700 fs22 txtdefault pb-20"><a href="javascript:;" [routerLink]="['/admin/manage-product-categories']" class="go-back"><i class="fa fa-angle-left"></i></a> Add New Category</h3>
		<form  [formGroup]="addCategoryForm">
		<div class="card card-ui card-uiovt byregwrp">
			<div class="d-flex align-items-center">
				<i placement="top" ngbTooltip="This will act as a default filter every time you will toggle.">
					<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
					  <g id="Group_68" data-name="Group 68" transform="translate(-545 -281)">
						<circle id="Ellipse_1" data-name="Ellipse 1" cx="7.5" cy="7.5" r="7.5" transform="translate(545 281)" fill="#07f"/>
						<path id="Path_2235" data-name="Path 2235" d="M7,8.171l-.128.523q-.576.227-.919.346a2.425,2.425,0,0,1-.8.119,1.591,1.591,0,0,1-1.084-.34,1.1,1.1,0,0,1-.387-.864,3.056,3.056,0,0,1,.029-.416q.029-.213.093-.48l.48-1.7q.064-.244.108-.462a2.01,2.01,0,0,0,.044-.4A.616.616,0,0,0,4.3,4.047a.759.759,0,0,0-.513-.128,1.345,1.345,0,0,0-.381.058c-.131.039-.242.076-.336.11L3.2,3.564q.471-.192.9-.329A2.7,2.7,0,0,1,4.916,3.1a1.552,1.552,0,0,1,1.068.335,1.111,1.111,0,0,1,.375.869c0,.074-.008.2-.026.39a2.606,2.606,0,0,1-.1.512L5.759,6.9a4.492,4.492,0,0,0-.1.466,2.4,2.4,0,0,0-.047.4.57.57,0,0,0,.15.459.85.85,0,0,0,.523.122,1.509,1.509,0,0,0,.4-.061A2.21,2.21,0,0,0,7,8.171Zm.121-7.1a.99.99,0,0,1-.334.753,1.138,1.138,0,0,1-.8.312,1.15,1.15,0,0,1-.809-.312.988.988,0,0,1-.337-.753A1,1,0,0,1,5.169.314a1.194,1.194,0,0,1,1.614,0A1,1,0,0,1,7.117,1.07Z" transform="translate(547.406 283.92)" fill="#fff"/>
					  </g>
					</svg>
				</i> &nbsp; Adding the category will affect across all the buyers and sellers. The buyer and seller will be able to see the category.
			</div>

<!-- 			<h4 class="f-500 fs18 txtdefault pt-20 pb-0"><span class="f-700">Category Hierarchy:</span> Consumer Electronics / Mobile & Tablets / Mobile Phone / Smart Phones</h4>
 -->
			<div class="form-container mdl-frm-lbl edit-category mb-30">
				<!--main category-->
				<div class="row">
					<div class="col-md-5">
						<div class="floating-label mb-0">      
						  	<div class="default">
						  		<input type="text" class="form-control" placeholder="" formControlName="name"/>
						  		<span class="highlight"></span>
						  		<label>Category Name</label>
								<span class="help-block" *ngIf="add.name.invalid && add.name.touched">Please enter category name</span>
						  	</div>
						</div>
					</div>
					<div class="col-md-5">
							<div class="floating-label mb-0">      
							  <div class="form-control">
							  	<div class="custom-table category-table p-0">
								  	<div class="catg"><a href="javascript:void(0);"><img [src]="previewImage" class="attachment">&nbsp;Category Image</a></div>
								  </div>
							  </div>
							  <label>Category Image</label>
							</div>
						</div>
					<div class="col-md-2">
						<div class="floating-label mb-0">      
							<div class="d-flex align-items-center">
								<input type="file" value="" formControlName="image" #parent (change)="onFileChange($event)" style="display: none;">
							  	<a href="javascript:;" (click)="parent.click()" class="btn-d-outline d-inline-block mr-3">Upload</a>
						  	</div>
						</div>
					</div>
				</div>
				<!--main category-->
				<div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
					<div class="sub-category" formArrayName="sub_categories" *ngFor="let item of addCategoryForm.get('sub_categories')['controls']; let i = index">
						<div class="reorder" cdkDrag>
							<img src="assets/images/icons/reorder.png">
						</div>
						<div class="row" [formGroupName]="i">
							<div class="col-md-5">
								<div class="floating-label mb-0">      
								<input type="text" class="form-control" value="" formControlName="name"/>
								<span class="highlight"></span>
								<label>Sub - Category {{i+1}}</label>
								</div>
							</div>
							<div class="col-md-5">
								<div class="floating-label mb-0">      
								<div class="form-control">
									<div class="custom-table category-table p-0">
										<div class="catg"><a href="javascript:void(0);"><img [src]="item.value.preview" class="attachment">Sub-Category Image</a></div>
									</div>
								</div>
								<label>Sub - Category Image</label>
								</div>
							</div>
							<div class="col-md-2">
								<div class="floating-label mb-0">      
									<div class="d-flex align-items-center">
										<input type="file" formControlName="image" #subparent (change)="onFileChangeSubcategory($event, i)" style="display: none;">
										<a href="javascript:;" class="btn-d-outline d-inline-block mr-3" (click)="subparent.click()">Upload</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="d-inline-block mb-20">
				<a href="javascript:;" class="mr-sm-4 btn-d-outline" (click)="newSubCategory()"><svg xmlns="http://www.w3.org/2000/svg" width="14.681" height="14.681" viewBox="0 0 14.681 14.681" class="plus">
					  <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1 1)">
					    <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181" transform="translate(-11.659 -7.5)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					    <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181" transform="translate(-7.5 -11.659)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					  </g>
					</svg>
					Add New Sub-Category
				</a>
			</div>

			<div class="form-container mt-30 mb-3">
				<div class="floating-label mb-0">      
				   <textarea class="form-control floating-textarea" formControlName="description" placeholder=" "></textarea>
				   <span class="highlight"></span>
				   <label>Category Description (Optional)</label>
				</div>
			</div>
			<div class="d-inline-block mb-20">
				<button type="button" class="btn buybtn fs16 radius10" (click)="addproductcategory()" [disabled]="addCategoryForm.invalid || businessLoading">
				<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="businessLoading"></span>&nbsp;Add Category</button>
			</div>

			<div class="fs12 mt-20">Note: Recommended category image resolution is 22px x 22px</div>
		</div>
		</form>


	</div>
</div>