<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain pb-4">
    <div class="container">
        <!--- HEAD  --->
        <div class="row align-items-center pinfo-head">
            <div class="col-md-6 mb-4">
                <h3 class="fs24 wt700 txtdefault">Media</h3>
                <nav class="breadcrumb breadcrumb-dui mb-0">
                    <ol class="breadcrumb p-0 bg-transparent  mb-0">
                        <li class="breadcrumb-item"><a class="txtseconday" [routerLink]="['/admin/dashboard']">Dashboard </a></li>
                        <li class="breadcrumb-item active">Media</li>
                    </ol>
                </nav>
            </div>
            <div class="col-md-6  mb-4 text-md-right text-center">
                <a (click)="open(addmedia)" href="javascript:;" class="mr-sm-4 mr-2 btn-d-outline d-inline-block"><svg
                        xmlns="http://www.w3.org/2000/svg" width="14.681" height="14.681" viewBox="0 0 14.681 14.681"
                        class="plus">
                        <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1 1)">
                            <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181" transform="translate(-11.659 -7.5)"
                                fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="2" />
                            <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181" transform="translate(-7.5 -11.659)"
                                fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="2" />
                        </g>
                    </svg>
                    Add New Media
                </a>
                <a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Assistance</a>
            </div>
        </div>
        <!--- /HEAD  --->
        <div class="card cardui carduipro p-4 mb-0">
            <div class="notifications-container theme-tab">

                <div class="tab-content">
                    <!--Filters-->
                    <div class="row mt-0  report-cta">
                        <div class="col-sm-4">
                            <div class="report-input">
                                <div class="input-group-custom">
                                    <input type="text" placeholder="Search by a name" class="form-control" [(ngModel)]="search_term" (keyup)="medialist()">
                                    <div class="input-group-append-custom">
                                        <img src="assets/images/svg/search_logo.svg">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 mb-3">
                            <div class="report-input">
                                <div class="ng-autocomplete">
                                    <ng-autocomplete [data]="category_list" [searchKeyword]="keyword"
                                        (selected)='selectFilterCategoryEvent($event)' (inputChanged)='onChangeSearch($event)'
                                        (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate" placeholder="Search by Category Name">
                                    </ng-autocomplete>
                                    <ng-template #itemTemplate let-item>
                                        <a href="javascript:void(0)">
                                            <div class="d-flex">
                                                <span [innerHTML]="item.name"></span>
                                            </div>
                                        </a>
                                    </ng-template>
                                    <div class="search-icon"><img src="assets/images/svg/search_logo.svg"></div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4 mb-3">
                            <div class="report-input">
                                <div class="ng-autocomplete">
                                    <ng-autocomplete [data]="brand_list"
                                        [searchKeyword]="keyword"
                                        (selected)='selectFilterBrandEvent($event)'
                                        (inputChanged)='onChangeSearchBrand($event)'
                                        (inputFocused)='onFocusedBrand($event)'
                                        [itemTemplate]="itemTemplate"
                                        placeholder="Search by Brand Name">
                                    </ng-autocomplete>
    
                                    <ng-template #itemTemplate let-item>
                                        <a href="javascript:void(0)">
                                            <div class="d-flex">
                                                <span [innerHTML]="item.name"></span>
                                            </div>
                                        </a>
                                    </ng-template>
                                    <div class="search-icon"><img
                                            src="assets/images/svg/search_logo.svg">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--End Filters-->
                    <div class="table-responsive brndmgnttable" *ngIf="records.length > 0">
                        <table class="table custom-table xs">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>File</th>
                                    <th>Category</th>
                                    <th>Brand</th>
                                    <th>Download</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!--Empty State-->
                                <tr *ngIf="records.length == 0">
                                    <td colspan="6">
                                        <div class="row pt-40 pb-40">
                                            <div class="col-md-12 text-center">
                                                <div class="mb-20"><img src="/assets/images/empty-state.png">
                                                </div>
                                                <div class="fs16">There are no records to display!</div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <!--End Empty State-->
                                <!-- TR -->
                                <tr *ngFor="let record of records; let i = index;">
                                    <td>{{ record.name }}</td>
                                    <td>
                                        <img src="{{record.image}}" width="100" height="100" *ngIf="record.file_extension != 'pdf'">
                                        <a href="{{ record.image }}" *ngIf="record.file_extension == 'pdf'" target="_blank">View PDF</a>
                                    </td>
                                    <td>{{ record.category_name }}</td>
                                    <td>{{ record.brand_name }}</td>
                                    <td><a href="{{ record.image }}" target="_blank">Download</a></td>
                                    <td class="d-flex align-items-center">
                                        <a href="javascript:;" class="place-bid"  (click)="copyText(record.path)">Copy</a>
                                    </td>
                                </tr>
                                <!-- /TR -->
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="row mb-40" *ngIf="media_pagination.totalPages> 1">
                    <div class="col-md-6">
                        <div class="text-left pl-3">
                            <nav class="mt-3 tbpg rows-per-page">
                                <div>Rows per page:</div>
                                <select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
                                    <option *ngFor="let option of page_per_view_options" [ngValue]="option.value">
                                        {{option.name}}</option>
                                </select>
                            </nav>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="text-right pr-3">
                            <nav class="mt-3 tbpg">
                                <ul class="pagination align-items-center">
                                    <li class="page-item" [ngClass]="{disabled:media_pagination.currentPage === 1}"><a
                                            class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a>
                                    </li>
    
                                    <li class="page-item" [ngClass]="{disabled:media_pagination.currentPage === 1}"><a
                                            class="page-link sprevicon" href="javascript:;"
                                            (click)="setpage(media_pagination.currentPage - 1)"></a></li>
    
                                    <li class="page-item" i *ngFor="let page of media_pagination.pages"
                                        [ngClass]="{active:media_pagination.currentPage === page}"><a class="page-link"
                                            href="javascript:;" (click)="setpage(page)">{{page}}</a></li>
    
                                    <li *ngIf="(media_pagination.totalPages - media_pagination.startPage) > 5 ">
                                        <a (click)="setpage(media_pagination.startPage + 5)">...</a>
                                    </li>
                                    <li *ngIf="(media_pagination.totalPages - media_pagination.startPage) > 5">
                                        <a
                                            (click)="setpage(media_pagination.totalPages)">{{media_pagination.totalPages}}</a>
                                    </li>
    
                                    <li class="page-item"
                                        [ngClass]="{disabled:media_pagination.currentPage === media_pagination.totalPages}">
                                        <a class="page-link snexticon" href="javascript:;"
                                            (click)="setpage(media_pagination.currentPage + 1)"></a>
                                    </li>
                                    <li class="page-item"
                                        [ngClass]="{disabled:media_pagination.currentPage === media_pagination.totalPages}">
                                        <a class="page-link nexticon" href="javascript:;"
                                            (click)="setpage(media_pagination.totalPages)"></a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>


<!-- ADD MEDIA DETAILS -->
<ng-template #addmedia let-modal>
    <div class="modal-body text-center mdlcmnui pt-4 mt-3">
       <h5 class="modal-title mb-10 ">Add Media</h5>
           <div class="bid-order-info d-flex align-items-center">
                <div class="order-img">
                    <img src="assets/images/icons/info.png" />
                </div>
            </div>
            <div class="form-container mdl-frm-lbl mb-1">
                <div class="row">
					<div class="col-md-12">
						<div class="floating-label mb-4">
                            <input type="file" value="" class="form-control" multiple (change)="uploadFile($event)">
						    <span class="highlight"></span>
						    <label>Media Files</label>
						</div> 
					</div>

                    <div class="col-md-12">
						<div class="floating-label mb-4">
                            <div class="ng-autocomplete">
                                <ng-autocomplete [data]="category_list" [searchKeyword]="keyword"
                                    (selected)='selectEvent($event)' (inputChanged)='onChangeSearch($event)'
                                    (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate" placeholder="Search by Category Name">
                                </ng-autocomplete>
                                <ng-template #itemTemplate let-item>
                                    <a href="javascript:void(0)">
                                        <div class="d-flex">
                                            <span [innerHTML]="item.name"></span>
                                        </div>
                                    </a>
                                </ng-template>
                                <div class="search-icon"><img src="assets/images/svg/search_logo.svg"></div>
                            </div>
						</div> 
					</div>

                    <div class="col-md-12">
						<div class="floating-label mb-4">
                            <div class="ng-autocomplete">
                                <ng-autocomplete [data]="brand_list"
                                    [searchKeyword]="keyword"
                                    (selected)='selectBrandEvent($event)'
                                    (inputChanged)='onChangeSearchBrand($event)'
                                    (inputFocused)='onFocusedBrand($event)'
                                    [itemTemplate]="itemTemplate"
                                    placeholder="Search by Brand Name">
                                </ng-autocomplete>

                                <ng-template #itemTemplate let-item>
                                    <a href="javascript:void(0)">
                                        <div class="d-flex">
                                            <span [innerHTML]="item.name"></span>
                                        </div>
                                    </a>
                                </ng-template>
                                <div class="search-icon"><img
                                        src="assets/images/svg/search_logo.svg">
                                </div>
                            </div>
						</div> 
					</div>
                </div>
            </div>
           <div class="text-center nobd mx-auto pb-4 mb-3">
                <button type="button" class="btn-d-outline d-inline-block cancel-outline mr-3" (click)="modal.close('Close click')">Cancel</button>
                <button type="button" class="btn buybtn fs16 radius10" (click)="addfiles()">Save Changes</button>
           </div>
    </div>
 </ng-template>
 <!-- ADD MEDIA DETAILS -->