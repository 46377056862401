import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class BidOffersService {

    public bid_placed = false;
    public bid_id: string;

    constructor(private _http: HttpClient) { }

    getproductbidlisting(data) {
        return this._http.post(environment.api_url + 'product/bids/listing', data);
    }

    getbiddetail(data) {
        return this._http.post(environment.api_url + 'product/bids/placed', data);
    }

    gettradedprice(data) {
        return this._http.post(environment.api_url + 'product/bids/accepted', data);
    }

    editbiddetails(data) {
        return this._http.post(environment.api_url + 'product/buyer/bids/history', data);
    }

    deletebid(data) {
        return this._http.post(environment.api_url + 'product/bids/delete', data);
    }

    updatebid(data) {
        return this._http.post(environment.api_url + 'product/bids/update', data);
    }

    acceptcounteroffer(data) {
        return this._http.post(environment.api_url + '/seller/bids/accept', data);
    }

}
