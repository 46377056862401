<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<h3 class="fs24 wt700 txtdefault">Add Banks (Global)</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Settings</a>
						</li>
						<li class="breadcrumb-item active">Add Banks (Global)</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:;" class="mr-sm-4 mr-2 btn-d-outline d-inline-block" (click)="open(content)"><svg xmlns="http://www.w3.org/2000/svg" width="14.681" height="14.681" viewBox="0 0 14.681 14.681" class="plus">
					  <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1 1)">
					    <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181" transform="translate(-11.659 -7.5)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					    <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181" transform="translate(-7.5 -11.659)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					  </g>
					</svg>
					Add New Bank
				</a>
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4 mb-2">
			<div class="bid-order-info d-flex align-items-center">
				<div class="order-img">
					<img src="assets/images/icons/info.png" />
				</div>
				<div class="pd-seller-cntnt">
					<div class="fs12">All these banks will be visible to Buyers & Sellers</div>
				</div>
			</div>

			<!--Filters-->
			<div class="row d-flex justify-content-between mt-20 mb-20 report-cta">
				<div class="col-md-5">
					<div class="report-input">
						<div class="input-group-custom">
							<input type="text" placeholder="Search by a bank name" class="form-control">
							<div class="input-group-append-custom">
								<img src="assets/images/svg/search_logo.svg">
							</div>
						</div>
					</div>
				</div>
			</div>
			<!--End Filters-->

			<div class="bank-global">
				<div class="row d-flex align-items-center justify-content-between">
					<div class="col-md-8">
						<div class="d-flex align-items-center">
							<div class="mr-3"><img src="assets/images/icons/sbi.png"></div>
							<div>State Bank of India</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="d-flex align-items-center justify-content-end">
							<a href="javascript:void(0);" (click)="open(content)">
								<img src="assets/images/icons/edit-big.png">
							</a>
							<span class="vdivider ml-3 mr-3"></span>
							<a href="javascript:;" (click)="openmdl(removePerson)"><img src="assets/images/icons/delete-icon.svg"></a>
						</div>
					</div>
				</div>
			</div>


			<div class="bank-global">
				<div class="row d-flex align-items-center justify-content-between">
					<div class="col-md-8">
						<div class="d-flex align-items-center">
							<div class="mr-3"><img src="assets/images/icons/boi.png"></div>
							<div>Bank of India</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="d-flex align-items-center justify-content-end">
							<a href="javascript:void(0);" (click)="open(content)">
								<img src="assets/images/icons/edit-big.png">
							</a>
							<span class="vdivider ml-3 mr-3"></span>
							<a href="javascript:;" (click)="openmdl(removePerson)"><img src="assets/images/icons/delete-icon.svg"></a>
						</div>
					</div>
				</div>
			</div>

			<div class="bank-global">
				<div class="row d-flex align-items-center justify-content-between">
					<div class="col-md-8">
						<div class="d-flex align-items-center">
							<div class="mr-3"><img src="assets/images/icons/union-bank.png"></div>
							<div>United Bank of India</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="d-flex align-items-center justify-content-end">
							<a href="javascript:void(0);" (click)="open(content)">
								<img src="assets/images/icons/edit-big.png">
							</a>
							<span class="vdivider ml-3 mr-3"></span>
							<a href="javascript:;" (click)="openmdl(removePerson)"><img src="assets/images/icons/delete-icon.svg"></a>
						</div>
					</div>
				</div>
			</div>

			<div class="bank-global">
				<div class="row d-flex align-items-center justify-content-between">
					<div class="col-md-8">
						<div class="d-flex align-items-center">
							<div class="mr-3"><img src="assets/images/icons/axis.png"></div>
							<div>Axis Bank</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="d-flex align-items-center justify-content-end">
							<a href="javascript:void(0);" (click)="open(content)">
								<img src="assets/images/icons/edit-big.png">
							</a>
							<span class="vdivider ml-3 mr-3"></span>
							<a href="javascript:;" (click)="openmdl(removePerson)"><img src="assets/images/icons/delete-icon.svg"></a>
						</div>
					</div>
				</div>
			</div>
		</div>	
	</div>
</div>
<!-- UPDATE BANK DETAILS -->
<ng-template #content let-modal>
   <div class="modal-body text-center mdlcmnui pt-4 mt-3">
      <h5 class="modal-title mb-10 ">Edit Bank Details</h5>
      	<div class="bid-order-info d-flex align-items-center">
			<div class="order-img">
				<img src="assets/images/icons/info.png" />
			</div>
			<div class="pd-seller-cntnt">
				<div class="fs12">The change will occur across all the buyers and sellers.</div>
			</div>
		</div>
      	<div class="form-container mdl-frm-lbl mb-1">
			<div class="row">
				<div class="col-md-12">
					<div class="floating-label mb-4">      
					  <input value="State Bank of India" type="text" class="form-control" placeholder=" " />
					  <span class="highlight"></span>
					  <label>Bank Name</label>
					</div> 
				</div>
			</div>
		</div>
		<div class="profileipt">
			<div class="form-container mt-0 mb-4 p-eml-rt">
				<div class="floating-label mb-10 flselcted"> 
					<input type="text" class="form-control" placeholder=" ">
					<button class="btn btn-outline-secondary fs16 btn-o-default" type="button">
						<span><a href="javascript:;" target="_blank">Upload</a></span>
						<svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107" viewBox="0 0 18.71 12.107">
					  <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
						<path id="Path_2337" data-name="Path 2337" d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z" transform="translate(-14.582 -27.082)" fill="#ff7135"/>
						<g id="Group_15718" data-name="Group 15718">
						  <g id="Group_15717" data-name="Group 15717">
							<g id="Group_15716" data-name="Group 15716">
							  <path id="Path_2338" data-name="Path 2338" d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4" transform="translate(-36.046 -39.347)" fill="#ff7135"/>
							</g>
						  </g>
						</g>
					  </g>
					</svg>

					</button>	  
				   <span class="highlight"></span>
				   <label>Bank Logo (Optional)</label>
				   <div class="docselected"><img src="assets/images/svg/attach.svg"><span>SBI_Logo.png</span></div>
				</div>
			</div>
		</div>
		<p>By editing the bank details you agree with our <a href="javascript:;" class="link-alt fs14">Terms & Conditions</a></p>
      	<div class="text-center nobd mx-auto pb-4 mb-3">
		    <button type="button" class="btn-d-outline d-inline-block cancel-outline mr-3" (click)="modal.close('Close click')">Cancel</button>
			<button type="button" class="btn buybtn fs16 radius10">Save Changes</button>
		  </div>
   </div>
</ng-template>
<!-- UPDATE BANK DETAILS -->
<!-- OTP VERIFICATION -->
<ng-template #removePerson let-modal>
   <div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
      <h5 class="modal-title">Remove Bank</h5>
        <div class="modal-subtitle text-center pt-30 pb-30">Are you sure you want to remove this bank?</div>

		<div class="row d-flex justify-content-center mt-10 mb-30">
			<div class="col-md-8">
				<div class="d-table">
					<div class="d-table-cell align-middle text-center  txtavtarwrp">
						<img src="assets/images/icons/sbi.png">
					</div>
					<div class="d-table-cell dsg-pinfo pl-3 align-middle">
						<h5 class="fs14 wt700 mb-1 mt-0 txtdefault d-flex align-items-center">State Bank of India</h5>
					</div>
				</div>
			</div>
		</div>

		<div class="bid-order-info">
			<div class="order-img">
				<img src="assets/images/icons/info.png" />
			</div>
			<div class="pd-seller-cntnt">
				<div class="fs12">It will remove across all the buyers and sellers.</div>
			</div>
		</div>

        <div class="text-center pt-30 pb-20">
           <button type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')" class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4">
		      Cancel
		   	</button>
		   	<button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4">
		      Remove
		   	</button>
        </div>
   </div>
</ng-template>
<!-- OTP VERIFICATION -->