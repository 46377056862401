import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tax-reports',
  templateUrl: './tax-reports.component.html',
  styleUrls: ['./tax-reports.component.scss']
})
export class TaxReportsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
