import { Component, OnInit } from '@angular/core';
import { EditSubCategoryService } from './edit-sub-category.service';
import { EncrDecrService } from '../../helpers/EncrDecrService';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ToasterService } from '../../helpers/toastr.service';

@Component({
    selector: 'app-edit-sub-category',
    templateUrl: './edit-sub-category.component.html',
    styleUrls: ['./edit-sub-category.component.scss']
})
export class EditSubCategoryComponent implements OnInit {

    categoryForm: FormGroup;
    public c_id: string;
    public subcategories: any = [];
    public changeCategory: boolean = false;
    public categoriesFlow: string;
    public buttonLoading: boolean = false;

    public previewImage: any = 'assets/images/icons/attachment.png';
    public previewName: string = 'Category Image';
    public parentImage: boolean = false;
    public subPreviewImage: any = '';

    constructor(
        private _editsubcategoryservice: EditSubCategoryService,
        private _EncrDecr: EncrDecrService,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private _router: Router,
        private _toastr: ToasterService
    ) { }

    get update() { return this.categoryForm.controls; }

    ngOnInit(): void {

        this.c_id = this.route.snapshot.paramMap.get('slug');
        //Add Category Form
        this.categoryForm = this.fb.group({
            name: this.fb.control('', [Validators.required]),
            c_id: this.fb.control(this.c_id, []),
            image: this.fb.control('', []),
            imageSource: new FormControl('', []),
            description: this.fb.control('', []),
            sub_categories: this.fb.array([]),
        });

        this.getcategory();

    }
    keyword = 'name';

    createSubcategory() {
        return this.fb.group({
            name: '',
            c_id: '',
            fresh: true,
            file: false,
            preview: 'assets/images/icons/attachment.png',
            preview_name: "Sub-Category Image",
            raw_image: '',
            new_file: false
        });
    }

    updateSubcategory(name, c_id, fresh, preview = 'assets/images/icons/attachment.png', preview_name = 'Sub-Category Image') {
        return this.fb.group({
            name: name,
            c_id: c_id,
            fresh: fresh,
            file: preview != '' ? true : false,
            preview: preview != '' ? preview : 'assets/images/icons/attachment.png',
            preview_name: preview_name != '' ? preview_name : "Sub-Category Image",
            raw_image: '',
            new_file: false
        });
    }

    newSubCategory() {
        (this.categoryForm.controls['sub_categories'] as FormArray).push(this.createSubcategory());
    }

    oldSubCategory() {
        if (this.subcategories.length > 0) {
            for (let i = 0; i < this.subcategories.length; i++) {
                let name = this.subcategories[i].name;
                let c_id = this.subcategories[i].c_id;
                let fresh = false;
                let preview = this.subcategories[i].preview;
                let preview_name = this.subcategories[i].img_name;

                (this.categoryForm.controls['sub_categories'] as FormArray).push(this.updateSubcategory(name, c_id, fresh, preview, preview_name));
            }
        } else {
            (<FormArray>this.categoryForm.get('sub_categories')).clear();
        }
    }

    chooseCategory() {
        this.changeCategory = !this.changeCategory;
    }

    getcategory() {
        this._editsubcategoryservice.getcategory({ cat_id: this.c_id, parent: 'yes' }).subscribe((data) => {
            if (data['status'] == "success") {
                this.categoryForm = this.fb.group({
                    name: this.fb.control('', [Validators.required]),
                    c_id: this.fb.control(this.c_id, []),
                    image: this.fb.control('', []),
                    imageSource: new FormControl('', []),
                    description: this.fb.control('', []),
                    sub_categories: this.fb.array([]),
                });
                this.categoryForm.get('name').setValue(data['parent_info'].name);
                this.categoryForm.get('c_id').setValue(data['parent_info'].c_id);
                this.categoryForm.get('description').setValue(data['parent_info'].description);
                this.subcategories = data['categories'];
                this.oldSubCategory();
                this.categoriesFlow = data['parent_info'].categoryflow;
                this.previewImage = data['parent_info'].preview;
                this.previewName = data['parent_info'].img_name;
                if (data['parent_info'].preview != '') {
                    this.parentImage = true;
                } else {
                    this.parentImage = false;
                }
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    updatecategory() {
        this.buttonLoading = true;
        let data: any = new FormData();
        let value = '';
        data.append('name', this._EncrDecr.set(this.categoryForm.get('name').value));
        data.append('parent_image', this.categoryForm.get('imageSource').value);
        data.append('c_id', this.categoryForm.get('c_id').value);
        data.append('description', this._EncrDecr.set(this.categoryForm.get('description').value));
        data.append('sub_categories', JSON.stringify(this.categoryForm.get('sub_categories').value));
        let length = this.categoryForm.get('sub_categories').value.length;
        for (let i = 0; i < length; i++) {
            value = ((this.categoryForm.get('sub_categories') as FormArray).at(i) as FormGroup).get('raw_image').value;
            if (value != '') {
                data.append("file_source_" + i, value);
            }
        }
        this._editsubcategoryservice.updatecategory(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.getcategory();
                this._toastr.success(data['message']);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            } else if (data['status'] == 'validation') {
                this._toastr.validation(data['errors'])
            }
            this.buttonLoading = false;
        }, (error) => {
            this.buttonLoading = false;
        });
    }

    onFileUploadSub(event, i) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];

            const readerr = new FileReader();
            readerr.onload = e => this.subPreviewImage = readerr.result;
            setTimeout(() => {
                ((this.categoryForm.get('sub_categories') as FormArray).at(i) as FormGroup).get('file').patchValue(true);
                ((this.categoryForm.get('sub_categories') as FormArray).at(i) as FormGroup).get('new_file').patchValue(true);
                ((this.categoryForm.get('sub_categories') as FormArray).at(i) as FormGroup).get('preview').patchValue(this.subPreviewImage);
                ((this.categoryForm.get('sub_categories') as FormArray).at(i) as FormGroup).get('preview_name').patchValue(file.name);

                ((this.categoryForm.get('sub_categories') as FormArray).at(i) as FormGroup).get('raw_image').patchValue(file);

            }, 500)
            readerr.readAsDataURL(file);
        }
    }

    deleteimage(c_id, type, i) {
        let fresh = ((this.categoryForm.get('sub_categories') as FormArray).at(i) as FormGroup).get('fresh').value;
        if (!fresh) {
            let data = {
                c_id: c_id
            };
            this._editsubcategoryservice.deletecategoryimage(data).subscribe((data) => {
                if (data['status'] == "success") {
                    if (type == 'sub') {
                        ((this.categoryForm.get('sub_categories') as FormArray).at(i) as FormGroup).get('file').patchValue(false);
                        ((this.categoryForm.get('sub_categories') as FormArray).at(i) as FormGroup).get('preview').patchValue('assets/images/icons/attachment.png');
                        ((this.categoryForm.get('sub_categories') as FormArray).at(i) as FormGroup).get('preview_name').patchValue('Sub-Category Image');
                    }
                } else if (data['status'] == "error") {
                    this._toastr.error(data['message']);
                }
            });
        } else {
            if (type == 'sub') {
                ((this.categoryForm.get('sub_categories') as FormArray).at(i) as FormGroup).get('file').patchValue(false);
                ((this.categoryForm.get('sub_categories') as FormArray).at(i) as FormGroup).get('preview').patchValue('assets/images/icons/attachment.png');
                ((this.categoryForm.get('sub_categories') as FormArray).at(i) as FormGroup).get('preview_name').patchValue('Sub-Category Image');
            }
        }

    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.categoryForm.get('sub_categories')['controls'], event.previousIndex, event.currentIndex);
        moveItemInArray(this.categoryForm.get('sub_categories').value, event.previousIndex, event.currentIndex);
    }

    result = [];

    selectEvent(item) {
        this._router.navigate(['/admin/product-category/edit-sub-category/', item.c_id]);
        this.changeCategory = false;
        this.c_id = item.c_id;
        this.getcategory();
        // do something with selected item
    }

    onChangeSearch(val: string) {
        // fetch remote data from here
        // And reassign the 'data' which is binded to 'data' property.
        this._editsubcategoryservice.searchcategory({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.result = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    onFocused(e) {
        // do something when input is focused
    }
}
