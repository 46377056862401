<app-header-seller></app-header-seller>
<app-sidebar-seller></app-sidebar-seller>
<div class="wrpmain tpslgbx pb-4">
    <div class="container ">
        <div class="row align-items-center">
            <div class="col-6 col-md-6">
                <!-- BREADCRUMB -->
                <nav class=" pt-2 pb-2" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                    <ol class="breadcrumb p-0 bg-transparent">
                        <li class="breadcrumb-item"><a href="javascript:void(0);" class="txtseconday">Orders</a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0);" [routerLink]="['/seller/order-history']"
                                class="txtseconday">Order History</a></li>
                        <li class="breadcrumb-item active" aria-current="page">View Order</li>
                    </ol>
                </nav>
                <!-- /BREADCRUMB -->
            </div>
            <div class="col-6 col-md-6 mb-4 text-right">
                <a href="javascript:void(0);" [routerLink]="['/seller/assistance']" class="btn px-4 buybtn fs16 radius10 ml-sm-3 mr-2">Assistance</a>
                <a href="javascript:void(0);"  *ngIf="order_details.delivered_status == 1 && order_details.return_request && (order_details.is_subseller == 'yes' || (order_details.is_subseller == 'no' && order_details.is_super_seller == 'no'))"  (click)="openWindowReturnRequest(returnrequest)" class="btn-d-outline d-inline-block"><img src="/assets/images/icons/np_return.svg">View Request Return</a>
            </div>
        </div>
        <div class="row align-items-center adprotop">
            <div class="col-12 col-md-6 mb-4">
                <h4 class="wt700 mb-0 txtdefault fs22">
                    <a href="javascript:void();" class="go-back" [routerLink]="['/seller/order-history']"><i class="fa fa-angle-left"></i></a>
                    Order Details
                </h4>
            </div>

            <div class="col-12 col-md-6 d-flex justify-content-end">
                <div *ngIf="order_details.superseller_invoice_status && order_details.is_super_seller == 'yes' && order_details.is_super_seller_user == 'yes'">
                    <b>Download:</b>
                    <span *ngIf="order_details.superseller_invoice_status">&nbsp;&nbsp; <a href="{{ order_details.superseller_invoice_path }}" target="_blank">Order Invoice</a> </span>
                    <span *ngIf="order_details.invoice_status">&nbsp;|&nbsp; <a href="{{ order_details.invoice_path }}" target="_blank">Buyer Invoice</a> </span>
                    <span *ngIf="order_details.superseller_eway_bill_status && order_details.superseller_eway_bill_path">&nbsp;|&nbsp; <a href="{{ order_details.superseller_eway_bill_path }}" target="_blank">Eway Bill</a> </span>
                    <span *ngIf="order_details.shipping_label_status">&nbsp;|&nbsp; <a href="{{ order_details.shipping_label_path }}" target="_blank">Shipping Label</a> </span>
                    <span *ngIf="order_details.manifest_status">&nbsp;|&nbsp; <a href="{{ order_details.manifest_path }}" target="_blank">Manifest</a> </span>
                </div>
                <div *ngIf="order_details.is_super_seller == 'yes' && order_details.is_subseller == 'yes'">
                    <b>Download:</b>
                    <span *ngIf="order_details.imei_status && order_details.imei_path">&nbsp;&nbsp; <a href="{{ order_details.imei_path }}" target="_blank">IMEI Bill</a></span> 
                    <span *ngIf="order_details.superseller_invoice_status">&nbsp;&nbsp; <a href="{{ order_details.superseller_invoice_path }}" target="_blank">Invoice</a> </span>
                    <span *ngIf="order_details.shipping_label_status">&nbsp;&nbsp; 
                        <a href="{{ order_details.shipping_label_path }}" target="_blank">Shipping Label</a>
                    </span>
                    <span *ngIf="order_details.manifest_status">&nbsp;|&nbsp; <a href="{{ order_details.manifest_path }}" target="_blank">Manifest</a> </span>
                    <span *ngIf="order_details.admin_seller_charge_invoice_path">&nbsp;|&nbsp; <a href="{{ order_details.admin_seller_charge_invoice_path }}" target="_blank">Charge Invoice</a> </span>
                </div>
                <div *ngIf="order_details.is_super_seller == 'no'" >
                    <b>Download:</b>
                    <span *ngIf="order_details.imei_status && order_details.imei_path">&nbsp;&nbsp; <a href="{{ order_details.imei_path }}" target="_blank">IMEI Bill</a></span> 
                    <span *ngIf="order_details.invoice_status">&nbsp;&nbsp; <a href="{{ order_details.invoice_path }}" target="_blank">Invoice</a> </span>
                    <span *ngIf="order_details.shipping_label_status">&nbsp;&nbsp; 
                        <a href="{{ order_details.shipping_label_path }}" target="_blank">Shipping Label</a>
                    </span>
                    <span *ngIf="order_details.manifest_status">&nbsp;|&nbsp; <a href="{{ order_details.manifest_path }}" target="_blank">Manifest</a> </span>
                    <span *ngIf="order_details.admin_seller_charge_invoice_path">&nbsp;|&nbsp; <a href="{{ order_details.admin_seller_charge_invoice_path }}" target="_blank">Charge Invoice</a> </span>
                </div>
            </div>

        </div>
        <!-- CONTENT -->
        <div class="row">
            <div class="col-md-12 mb-4">
                <!-- CARD -->
                <div class="card card-ui mb-4 mb-xs-0">
                    <div class="row mb-2 align-items-center">
                        <div class="col-md-7">
                            <div>
                                <label class="fs16 txtdefault align-middle wt700 mb-0 mr-2">Order ID {{
                                    order_details.order_id }}</label>
                                <br/>
                                <label class="fs16 txtdefault align-middle wt700 mb-0 mr-2">Transaction ID {{
                                        order_details.transaction_id }}</label>
                                <span class="badge-d-outline odbdge fs10">
                                    {{ order_details.marketplace }}
                                </span>
                                <!-- <p class="fs12 txtseconday pt-2 mb-0">Wallet Payment on 27/08/2021, 06:20:25 pm </p> -->
                            </div>
                        </div>
                        <div class="col-md-5 text-right" *ngIf="(order_details.is_super_seller == 'no' || order_details.is_super_seller_user == 'no') &&  order_details.cancel_status == 0">
                            <a href="javascript:void(0);" *ngIf="order_details.quality_status && (order_details.is_subseller == 'yes' && !order_details.superseller_invoice_path) || (order_details.is_subseller == 'no' && !order_details.invoice_path)" class="btn px-3 buybtn fs14 radius10" (click)="generateinvoice()">
								<svg xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
								  <g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
								    <path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#fff"/>
								  </g>
								</svg> <span class="ml-2">Generate Invoice</span>
							</a>

                            <a href="javascript:void(0);" *ngIf="((order_details.superseller_invoice_path || order_details.invoice_path) && (!order_details.superseller_invoice_status && !order_details.invoice_status))" (click)="openWindowUploadInvoice(content3)" class="btn-d-outline d-inline-block">Upload Invoice</a>
<!-- 
                            <a href="{{ order_details.shipping_label_path}}" target="_blank" *ngIf="order_details.invoice_status && order_details.eway_bill_status && order_details.shipping_label_status" class="btn px-3 buybtn fs14 radius10">
								<svg xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
								  <g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
								    <path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#fff"/>
								  </g>
								</svg> <span class="ml-2">Download Shipping Label</span>
							</a> -->

                            <a href="javascript:void(0);" *ngIf="order_details.invoice_status && order_details.is_super_seller == 'yes' && !order_details.superseller_eway_bill_status" class="btn px-3 buybtn fs14 radius10" (click)="openWindowUploadInvoice(ssewaybill)">
								<svg xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
								  <g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
								    <path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#fff"/>
								  </g>
								</svg> <span class="ml-2">Upload Eway Bill</span>
							</a>

                            <a href="javascript:void(0);" *ngIf="order_details.invoice_status && order_details.is_super_seller == 'yes' && !order_details.superseller_eway_bill_status" class="btn px-3 buybtn fs14 radius10" (click)="generatedetails('no_ss_eway_bill')">
								<svg xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
								  <g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
								    <path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#fff"/>
								  </g>
								</svg> <span class="ml-2">No Eway Bill</span>
							</a>

                            <a href="javascript:void(0);" *ngIf="order_details.invoice_status && order_details.is_super_seller == 'no' && !order_details.eway_bill_status" class="btn px-3 buybtn fs14 radius10" (click)="openWindowUploadInvoice(ewaybill)">
								<svg xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
								  <g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
								    <path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#fff"/>
								  </g>
								</svg> <span class="ml-2">Upload Eway Bill</span>
							</a>

                            <a href="javascript:void(0);" *ngIf="order_details.invoice_status && order_details.is_super_seller == 'no' && !order_details.eway_bill_status" class="btn px-3 buybtn fs14 radius10"  (click)="generatedetails('no_eway_bill')">
								<svg xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
								  <g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
								    <path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#fff"/>
								  </g>
								</svg> <span class="ml-2">No Eway Bill</span>
							</a>
                            

                            <a href="javascript:void(0);" *ngIf="order_details.invoice_path && order_details.shipping_label_status && !order_details.manifest_status" class="btn px-3 buybtn fs14 radius10" (click)="generatemanifest()">
								<svg xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
								  <g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
								    <path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#fff"/>
								  </g>
								</svg> <span class="ml-2">Generate & Download Manifest</span>
							</a>

                            <a href="javascript:void(0);" *ngIf="order_details.stock_status && !order_details.imei_status" class="btn px-3 buybtn fs14 radius10" (click)="openWindowUploadInvoice(imeilist)">
								<svg xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
								  <g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
								    <path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#fff"/>
								  </g>
								</svg> <span class="ml-2">Upload IMEI List</span>
							</a>

                            <!-- Ready to Dispatch-->
                            <!-- <a href="javascript:void(0);" class="btn-d-outline d-inline-block">
								Ready to Dispatch
							</a> -->
                            <!-- View Cancellation Request-->
                            <!-- <a href="javascript:void(0);" (click)="openWindowCancellationRequest(content2)" class="btn-d-outline d-inline-block">
								View Cancellation Request
							</a> -->

                            <!--Accept & Reject Order-->
                            <!-- <div>
								<a href="javascript:;" class="btn buybtn-danger fs16 radius10 ripple mr-2">Reject Order</a>
								<a href="javascript:;" class="btn buybtn-success fs16 radius10 ripple">Accept Order (01:00:00 hrs)</a>
							</div> -->

                            <!--Upload IMEI List-->
                            <!-- <a href="javascript:void(0);" class="btn-d-outline d-inline-block">
								Upload IMEI List
							</a> -->

                            <!--View Return Request-->
                            
                            
                        </div>

                        <div class="col-md-5 text-right" *ngIf="(order_details.is_super_seller == 'yes' && order_details.is_super_seller_user == 'yes') && order_details.cancel_status == 0">
                            <a href="javascript:void(0);" *ngIf="order_details.quality_status && order_details.superseller_invoice_status && !order_details.invoice_path" class="btn px-3 buybtn fs14 radius10" (click)="generateinvoice()">
								<svg xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
								  <g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
								    <path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#fff"/>
								  </g>
								</svg> <span class="ml-2">Generate Invoice</span>
							</a>

                            <a href="javascript:void(0);" *ngIf="order_details.superseller_invoice_status && order_details.invoice_path && !order_details.invoice_status" (click)="openWindowUploadInvoice(content3)" class="btn-d-outline d-inline-block">Upload Invoice</a>

                            <a href="javascript:void(0);" *ngIf="order_details.superseller_invoice_path && order_details.superseller_eway_bill_status && !order_details.eway_bill_status" class="btn px-3 buybtn fs14 radius10"  (click)="openWindowUploadInvoice(ewaybill)">
								<svg xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
								  <g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
								    <path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#fff"/>
								  </g>
								</svg> <span class="ml-2"> Upload Eway Bill</span>
							</a>

                            <a href="javascript:void(0);" *ngIf="order_details.superseller_invoice_path && order_details.superseller_eway_bill_status && !order_details.eway_bill_status" class="btn px-3 buybtn fs14 radius10" (click)="generatedetails('no_eway_bill')">
								<svg xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
								  <g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
								    <path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#fff"/>
								  </g>
								</svg> <span class="ml-2"> No Eway Bill</span>
							</a>

                            <a href="javascript:void(0);" *ngIf="order_details.invoice_path && order_details.shipping_label_status && !order_details.manifest_status" class="btn px-3 buybtn fs14 radius10" (click)="generatemanifest()">
								<svg xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
								  <g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
								    <path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#fff"/>
								  </g>
								</svg> <span class="ml-2">Generate & Download Manifest</span>
							</a>
                        </div>
                    </div>
                    
                    <hr class="mb-20">

                    <!-- ORDER TOP -->
                    <div class="orderdscp nobd">
                        <div class="row">
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-6 col-md-4 mb-20">
                                        <label class="f-700 txtdefault mb-1">Order ID:</label>
                                        <p class="f-500 txtseconday mb-0">{{ order_details.order_id }}</p>
                                    </div>
                                    <div class="col-6 col-md-4 mb-20">
                                        <label class="f-700 txtdefault mb-1">Order Date:</label>
                                        <p class="f-500 txtseconday mb-0">{{ order_details.order_date }}</p>
                                    </div>
                                    <div class="col-6 col-md-4 mb-20">
                                        <label class="f-700 txtdefault mb-1">Prebooked on:</label>
                                        <p class="f-500 txtseconday mb-0">{{ order_details.prebookedon}}</p>
                                    </div>
                                    <div class="col-6 col-md-4 mb-20">
                                        <label class="f-700 txtdefault mb-1">Quantity:</label>
                                        <p class="f-500 txtseconday mb-0">{{ order_details.order_qty }}</p>
                                    </div>
                                    <div class="col-6 col-md-4 mb-20">
                                        <label class="f-700 txtdefault mb-1">Order Status:</label>
                                        <p class="f-500 txtseconday mb-0"><span class="text-active">{{
                                                order_details.order_status }}</span></p>
                                    </div>
                                    <div class="col-6 col-md-4 mb-20">
                                        <label class="f-700 txtdefault mb-1">Logistic Preference:</label>
                                        <p class="f-500 txtseconday mb-0">{{ order_details.logistics_preference }}<br>
                                            <!-- <span class="fs12">Free Shipping</span> -->
                                        </p>
                                    </div>
                                    <div class="col-6 col-md-4 mb-4">
                                        <label class="f-700 txtdefault mb-1">Logistic Partner:</label>
                                        <p class="f-500 text-danger mb-0">{{ order_details.logistic_partner }}</p>
                                    </div>
                                    <div class="col-6 col-md-4 mb-4">
                                        <label class="f-700 txtdefault mb-1">Tracking Number:</label>
                                        <p class="f-500 mb-0"><a href="javascript:;" class="link-alt fs14">{{
                                                order_details.tracking_number }}</a></p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label class="f-700 txtdefault mb-1">Buyer Details:</label>
                                        <p class="f-500 txtseconday mb-0">
                                            <b>{{ order_details.buyer_business_name }}</b>
                                            {{ order_details.buyer_reg_address }}
                                        </p>
                                        <div class="mt-20">
                                            <label class="f-700 txtdefault mb-1">Email:</label>
                                            <p class="f-500 mb-0">{{ order_details.buyer_email }}</p>
                                        </div>

                                        <div class="mt-20">
                                            <label class="f-700 txtdefault mb-1">Mobile:</label>
                                            <p class="f-500 mb-0">{{ order_details.buyer_mobile }}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="f-700 txtdefault mb-1">Shipping Address::</label>
                                        <p class="f-500 txtseconday mb-0">{{ order_details.shipping_address }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-4 col-md-4 mb-20">
                                        <label class="f-700 txtdefault mb-1">Registered Address:
                                            <a href="javascript:void(0);" (click)="editorderdetailsmodal(editssbillingaddressModal, 'sold_by')" class="edit-icon position-static"></a>
                                        </label>
                                        <br/><b>{{ order_details.sold_by.supplier_name }}</b><br/>
                                            {{ order_details.sold_by.supplier_adress }},<br/>
                                            {{ order_details.sold_by.supplier_city }},<br/>
                                            {{ order_details.sold_by.supplier_state }} - {{ order_details.sold_by.supplier_pincode }}
                                    </div>

                                    <div class="col-md-4 col-md-4 mb-20" *ngIf="order_details.is_super_seller == 'no'|| order_details.is_super_seller_user == 'no'">
                                        <label class="f-700 txtdefault mb-1">Product Location:
                                            <a href="javascript:void(0);" (click)="editorderdetailsmodal(editssbillingaddressModal, 'product_location')" class="edit-icon position-static"></a>
                                        </label>
                                        <br/>{{ order_details.product_location.address }},<br/>
                                            {{ order_details.product_location.city }},<br/>
                                            {{ order_details.product_location.state }} - {{ order_details.product_location.pincode }}
                                    </div>

                                    <div class="col-md-4 col-md-4 mb-20">
                                        <label class="f-700 txtdefault mb-1">Invoice Number:
                                            <a href="javascript:void(0);" (click)="editorderdetailsmodal(editinvoicenumberModal, 'invoice')" class="edit-icon position-static"></a>
                                        </label>
                                        <p class="f-500 txtseconday mb-0">{{ order_details.invoice_number }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /ORDER TOP -->

                    <hr class="mt-10 mb-20">
                    <div class="row table-card">
                        <div class="col-md-12">
                            <div class="fs14 f-700 mb-2">Payment Information:</div>
                            <table class="table custom-table">
                                <thead>
                                    <tr>
                                        <th>Date of Payment</th>
                                        <th>Transaction Id</th>
                                        <th>Amount (in ₹)</th>
                                        <th>Mode of Payment</th>
                                        <th>Payment Status</th>
                                        <th>Transaction Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let payment of order_details.payment_information;">
                                        <td>{{ payment.date }}</td>
                                        <td>{{ payment.transaction_id }}</td>
                                        <td>{{ payment.amount_type == 'out'?'- '+payment.amount:payment.amount }} 
                                            <span class="badge-d-outline odbdge fs10 ml-2">{{ payment.note }}</span>
                                        </td>
                                        <td>{{ payment.mode_of_payment == 'pg'?'Payment Gateway':payment.mode_of_payment }}</td>
                                        <td>{{ payment.trans_status }}</td>
                                        <td>{{ payment.trans_type }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                
                <!-- CARD -->
                <div class="card card-ui dc-pinfo">
                    <div class="fs15 f-700 mb-20">Product Info:</div>
                    <div class="row d-flex align-items-center mb-30 mb-xs-10">
                        <div class="col-md-6">
                            <div class="d-flex align-items-center">
                                <div class="mr-3"><img src="{{ order_details.product_image }}" style="max-width:80px">
                                </div>
                                <h5 class="mb-0 fs14 wt700">{{ order_details.product_name }}</h5>
                            </div>
                        </div>

                        <div class="col-md-6 text-right md-text-left mt-xs-10">
                            <div class="row">
                                <div class="col text-right md-text-left">
                                    <div class="text-dark f-600">Order quantity:</div>
                                    <div class="text-dark">{{ order_details.order_qty }}</div>
                                </div>
                                <div class="col text-right md-text-left" *ngIf="order_details.parentchild != 'yes'">
                                    <div class="text-dark f-600">Price Per Unit:</div>
                                    <div class="text-dark">{{ order_details.price_per_unit }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="order_details.parentchild == 'yes'">
                        <hr class="mt-10 mb-20">
                        <div class="row table-card">
                            <div class="col-md-12">
                                <div class="fs14 f-700 mb-2">Child Product Information:</div>
                                <table class="table custom-table">
                                    <thead>
                                        <tr>
                                            <th>Image</th>
                                            <th>Name</th>
                                            <th>Quantity</th>
                                            <th>Price per unit</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let child of order_details.parentchild_records;">
                                            <td><img src="{{ child.image }}" alt="{{ child.name }}" style="height:80px;width:80px"></td>
                                            <td>{{ child.name }}</td>
                                            <td>{{ child.quantity }}</td>
                                            <td>{{ child.price_per_unit }}</td>
                                            <td>{{ child.status }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ng-container>

                    <hr class="mt-10 mb-20">

                    <div class="row">
                        <div class="col-md-12">
                            <div class="fs14 f-700 mb-2">Order Details:</div>
                            <div class="inrcard p-3 radius10 position-relative">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="row d-flex justify-content-between">
                                            <div class="col-6 col-md-6 py-2">ID:</div>
                                            <div class="col-6 col-md-6 py-2 text-right">{{ order_details.order_id }}</div>
                                        </div>
                                        <div class="row d-flex justify-content-between">
                                            <div class="col-6 col-md-6 py-2">Date & Time of booking:</div>
                                            <div class="col-6 col-md-6 py-2 text-right">{{ order_details.booking_date }}</div>
                                        </div>
                                        <div class="row d-flex justify-content-between">
                                            <div class="col-6 col-md-6 py-2">Logistic preference:</div>
                                            <div class="col-6 col-md-6 py-2 text-right">{{ order_details.logistics }} &nbsp;<i
                                                    placement="top"
                                                    ngbTooltip="This will act as a default filter every time you will toggle."><img
                                                        src="/assets/images/icons/info.png"></i></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                 

                    <hr class="mt-10 mb-20">

                    <div class="row">
                        <div class="col-md-12">
                            <div class="fs14 f-700 mb-2">Amount Receivable Details:</div>
                            <div class="inrcard p-3 radius10 position-relative">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="row d-flex justify-content-between" *ngIf="order_details.is_subseller == 'no'">
                                            <div class="col-6 col-md-6 py-2">Deal Value:</div>
                                            <div class="col-6 col-md-6 py-2 text-right">{{ order_details.total_order_value }}</div>
                                        </div>
                                        <div class="row d-flex justify-content-between" *ngIf="order_details.is_subseller == 'no'">
                                            <div class="col-6 col-md-6 py-1">Logistics Charges:</div>
                                            <div class="col-6 col-md-6 py-1 text-right">{{ order_details.seller_logistics_fee }}</div>
                                        </div>
                                        <div class="row d-flex justify-content-between" *ngIf="order_details.is_subseller == 'no'">
                                            <div class="col-6 col-md-6 py-1">Convenience Charges:</div>
                                            <div class="col-6 col-md-6 py-1 text-right">{{ order_details.seller_convience_fee }}</div>
                                        </div>
                                        <div class="row d-flex justify-content-between" *ngIf="order_details.is_subseller == 'no'">
                                            <div class="col-6 col-md-6 py-1">TDS:</div>
                                            <div class="col-6 col-md-6 py-1 text-right">{{ order_details.tds_amount }}</div>
                                        </div>
                                        <div class="row d-flex justify-content-between" *ngIf="order_details.is_subseller == 'no'">
                                            <div class="col-6 col-md-6 py-1">TCS:</div>
                                            <div class="col-6 col-md-6 py-1 text-right">{{ order_details.tcs_amount }}</div>
                                        </div>
                                        <div class="row d-flex justify-content-between" *ngIf="order_details.is_subseller == 'no'">
                                            <div class="col-6 col-md-6 py-2">Total Receivable Amount:</div>
                                            <div class="col-6 col-md-6 py-2 text-right">{{ order_details.seller_receivable_amount }}</div>
                                        </div>
                                        <div class="row d-flex justify-content-between" *ngIf="order_details.is_subseller != 'no'">
                                            <div class="col-6 col-md-6 py-2">Total Receivable Amount:</div>
                                            <div class="col-6 col-md-6 py-2 text-right">{{ order_details.total_order_value }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /CARD -->
            </div>
        </div>
    </div>
</div>
<!-- RETURN REQUEST -->
<ng-template #content let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>

    <div class="modal-body">
        <div class="row align-items-center">
            <div class="col-sm-7">
                <div class="">
                    <div class="rtrqsthead">
                        <h4 class="txtdefault wt700 fs22">Return Request</h4>
                        <p class="txtseconday fs12">Request received on 24th Jan 2022, 11:15:25 AM</p>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 pr-0 rtnrqtmdl">
                            <div class="d-table mb-4 w-100">
                                <div class="d-table-cell cart-thumb align-middle">
                                    <img src="assets/images/products/p-2.jpg" />
                                </div>
                                <div class="d-table-cell pl-2 align-middle">
                                    <p class="txtdefault wt700 mb-0 fs12">OnePlus Nord 2 5G (Blue Haze, 8GB RAM, 128GB)
                                    </p>
                                    <p class="txtseconday my-1 fs12"><span class="txtdefault wt500">Order ID: </span>
                                        EQB9876598754ABCD</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-container mb-0 mt-0">
                        <div class="row">
                            <div class="col-lg-12 ">
                                <div class="floating-label">
                                    <input placeholder=" " class="form-control" value="Incomplete technical info">
                                    <span class="highlight"></span>
                                    <label>Reason for Cancellation</label>
                                </div>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-lg-12 ">
                                <div class="floating-label">
                                    <textarea placeholder=" "
                                        class="form-control txtareaht80">Multiple RAM variants missing</textarea>
                                    <span class="highlight"></span>
                                    <label>Message from Buyer</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="client-thumbnails dtlthmb odimgsz text-center img-option-list">
                        <ngx-slick-carousel class="carousel thumbs" #slickModal="slick-carousel"
                            [config]="thumbnailsSlider ">
                            <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                                <a class="d-inline-block w-100" href="javascript:void(0);">
                                    <img src="{{ slide.img }}" alt="">
                                </a>
                                <p class="fs12 txtseconday mb-0">Front image*</p>
                            </div>
                        </ngx-slick-carousel>
                    </div>

                    <div class="text-right">
                        <input type="submit" value="Accept" class="btn px-4 buybtn fs16 radius10 btn-success" />
                        <a href="javascript:void(0);"
                            class="btn-d-outline btn-danger-outline d-inline-block ml-sm-3 ml-2">Reject</a>
                    </div>
                </div>
            </div>
            <div class="col-sm-5 text-center">
                <div class="feedback-slides  ">
                    <div class="client-feedback dtlfull">
                        <ngx-slick-carousel class="carousel feedback" #slickModal="slick-carousel"
                            [config]="imagesSlider">
                            <div ngxSlickItem *ngFor="let slide of slides" class="slide ">
                                <div class="thmbnaildtl">
                                    <img src="{{ slide.img }}" alt="" width="100%">
                                </div>
                            </div>
                        </ngx-slick-carousel>
                    </div>
                </div>
                <p class="fs10 txtseconday pt-3">Image Preview</p>
            </div>
        </div>
    </div>

</ng-template>
<!-- /RETURN REQUEST -->
<!-- CANCEL REQUEST -->
<ng-template #content2 let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>

    <div class="modal-body pb-3">
        <div class="row align-items-center">
            <div class="col-sm-12">
                <div class="">
                    <div class="rtrqsthead">
                        <h4 class="txtdefault wt700 fs22">Cancellation Request</h4>
                        <p class="txtseconday fs12">Request received on 24th Jan 2022, 11:15:25 AM</p>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 pr-0 rtnrqtmdl">
                            <div class="d-table mb-4 w-100">
                                <div class="d-table-cell cart-thumb align-middle">
                                    <img src="assets/images/products/p-2.jpg" />
                                </div>
                                <div class="d-table-cell pl-2 align-middle">
                                    <p class="txtdefault wt700 mb-0 fs12">OnePlus Nord 2 5G (Blue Haze, 8GB RAM, 128GB)
                                    </p>
                                    <p class="txtseconday my-1 fs12"><span class="txtdefault wt500">Order ID: </span>
                                        EQB9876598754ABCD</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-container mb-0 mt-0">
                        <div class="row">
                            <div class="col-lg-12 col-xl-7">
                                <div class="floating-label">
                                    <input placeholder=" " class="form-control" value="Incomplete technical info">
                                    <span class="highlight"></span>
                                    <label>Reason for Cancellation</label>
                                </div>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-lg-12  col-xl-7">
                                <div class="floating-label">
                                    <textarea placeholder=" "
                                        class="form-control txtareaht80">Multiple RAM variants missing</textarea>
                                    <span class="highlight"></span>
                                    <label>Message from Buyer</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-center">
                        <input type="submit" value="Accept" class="btn px-4 buybtn fs16 radius10 btn-success" />
                        <a href="javascript:void(0);"
                            class="btn-d-outline btn-danger-outline d-inline-block ml-sm-3 ml-2">Reject</a>
                    </div>
                </div>
            </div>

        </div>
    </div>

</ng-template>
<!-- /CANCEL REQUEST -->
<!-- UPLOAD INVOICE -->
<ng-template #content3 let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>

    <div class="modal-body pb-3">
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead">
                    <h4 class="txtdefault wt700 fs22 mb-20">Upload Invoice</h4>
                </div>
                <div class="mb-10 f-600 fs16">E-Invoice Declaration <i placement="top"
                        ngbTooltip="This will act as a default filter every time you will toggle."> <img
                            src="/assets/images/icons/info.png"></i></div>
                <div class="css-check mb-30" style="display: none;">
                    <input class="css-checkbx" type="checkbox" />
                    <label class="css-label">Last year turnover was above INR 50 Crores?</label>
                </div>

                <div class="row mb-30">
                    <div class="col-md-12">
                        <div class="generate-invoice">
                            <div><a href="{{ order_details.is_subseller == 'yes'?order_details.superseller_invoice_path:order_details.invoice_path }}" target="_blank"><img src="/assets/images/icons/pdf.png" class="mr-2"> Generate Invoice</a></div>
                            <div><a href="{{ order_details.is_subseller == 'yes'?order_details.superseller_invoice_path:order_details.invoice_path }}" target="_blank"><img src="/assets/images/icons/export-download.png"></a></div>
                        </div>
                    </div>
                </div>

                    <div class="row">
                        <!-- INPUT ROW -->
                        <div class="col-md-12">
                            <div class="form-container mt-0 mb-4 p-eml-rt">
                                <div class="floating-label mb-10 flselcted">
                                    <input type="text" class="form-control" placeholder=" ">
                                    
                                    <span class="highlight"></span>
                                    <label>Upload Invoice</label>
                                    <div class="docselected"><img
                                            src="assets/images/svg/attach.svg"><span>Invoice.pdf</span></div>
                                </div>
                            </div>
                        </div>
                        <!-- /INPUT ROW -->
                    </div>

                <div class="text-center">
                    <input type="button" value="Upload" class="btn px-4 buybtn fs16 radius10" (click)="uploadinvoice()"/>
                </div>
            </div>
        </div>
        <hr>
        <div class="row align-items-center">
            <div class="col-md-12">
                <div class="text-center">
                    <i placement="top" ngbTooltip="This will act as a default filter every time you will toggle."> <img
                            src="/assets/images/icons/info.png"></i> Download the Generated Invoice and then upload it.
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- /UPLOAD INVOICE -->




<!-- UPLOAD EWAY BILL -->
<ng-template #ssewaybill let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>

    <div class="modal-body pb-3">
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead">
                    <h4 class="txtdefault wt700 fs22 mb-20">Upload Eway Bill</h4>
                </div>

                <div class="profileipt">
                    <div class="row">
                        <input type="file" #ssewaybillfile style="display: none;" (change)="uploadFile($event, 'ss_eway_bill')">
                        <!-- INPUT ROW -->
                        <div class="col-md-12">
                            <div class="form-container mt-0 mb-4 p-eml-rt">
                                <div class="floating-label mb-10 flselcted">
                                    <input type="text" class="form-control" placeholder=" ">
                                    <button class="btn btn-outline-secondary fs16 btn-o-default" type="button" (click)="ssewaybillfile.click()">
                                        <span><a href="javascript:;">Upload</a></span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107"
                                            viewBox="0 0 18.71 12.107">
                                            <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
                                                <path id="Path_2337" data-name="Path 2337"
                                                    d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z"
                                                    transform="translate(-14.582 -27.082)" fill="#ff7135" />
                                                <g id="Group_15718" data-name="Group 15718">
                                                    <g id="Group_15717" data-name="Group 15717">
                                                        <g id="Group_15716" data-name="Group 15716">
                                                            <path id="Path_2338" data-name="Path 2338"
                                                                d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4"
                                                                transform="translate(-36.046 -39.347)" fill="#ff7135" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>

                                    </button>
                                    <span class="highlight"></span>
                                    <label>Upload Eway Bill</label>
                                    <div class="docselected"><img
                                            src="assets/images/svg/attach.svg"><span>ewaybill.pdf</span></div>
                                </div>
                            </div>
                        </div>
                        <!-- /INPUT ROW -->
                    </div>
                </div>

                <div class="text-center">
                    <input type="button" value="Submit" class="btn px-4 buybtn fs16 radius10" (click)="generatedetails('ss_eway_bill')" />
                </div>
            </div>
        </div>
        <!-- <hr>
        <div class="row align-items-center">
            <div class="col-md-12">
                <div class="text-center">
                    <i placement="top" ngbTooltip="This will act as a default filter every time you will toggle."> <img
                            src="/assets/images/icons/info.png"></i> Download the Generated Invoice and then upload it.
                </div>
            </div>
        </div> -->
    </div>
</ng-template>
<!-- /UPLOAD EWAY BILL -->

<!-- UPLOAD EWAY BILL -->
<ng-template #ewaybill let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>

    <div class="modal-body pb-3">
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead">
                    <h4 class="txtdefault wt700 fs22 mb-20">Upload Eway Bill</h4>
                </div>

                <div class="profileipt">
                    <div class="row">
                        <input type="file" #ewaybillfile style="display: none;" (change)="uploadFile($event, 'eway_bill')">
                        <!-- INPUT ROW -->
                        <div class="col-md-12">
                            <div class="form-container mt-0 mb-4 p-eml-rt">
                                <div class="floating-label mb-10 flselcted">
                                    <input type="text" class="form-control" placeholder=" ">
                                    <button class="btn btn-outline-secondary fs16 btn-o-default" type="button" (click)="ewaybillfile.click()">
                                        <span><a href="javascript:;">Upload</a></span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107"
                                            viewBox="0 0 18.71 12.107">
                                            <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
                                                <path id="Path_2337" data-name="Path 2337"
                                                    d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z"
                                                    transform="translate(-14.582 -27.082)" fill="#ff7135" />
                                                <g id="Group_15718" data-name="Group 15718">
                                                    <g id="Group_15717" data-name="Group 15717">
                                                        <g id="Group_15716" data-name="Group 15716">
                                                            <path id="Path_2338" data-name="Path 2338"
                                                                d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4"
                                                                transform="translate(-36.046 -39.347)" fill="#ff7135" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>

                                    </button>
                                    <span class="highlight"></span>
                                    <label>Upload Eway Bill</label>
                                    <div class="docselected"><img
                                            src="assets/images/svg/attach.svg"><span>ewaybill.pdf</span></div>
                                </div>
                            </div>
                        </div>
                        <!-- /INPUT ROW -->
                    </div>
                </div>

                <div class="text-center">
                    <input type="button" value="Submit" class="btn px-4 buybtn fs16 radius10" (click)="generatedetails('eway_bill')" />
                </div>
            </div>
        </div>
        <!-- <hr>
        <div class="row align-items-center">
            <div class="col-md-12">
                <div class="text-center">
                    <i placement="top" ngbTooltip="This will act as a default filter every time you will toggle."> <img
                            src="/assets/images/icons/info.png"></i> Download the Generated Invoice and then upload it.
                </div>
            </div>
        </div> -->
    </div>
</ng-template>
<!-- /UPLOAD EWAY BILL -->


<!-- UPLOAD mANIFEST -->
<ng-template #manifest let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>

    <div class="modal-body pb-3">
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead">
                    <h4 class="txtdefault wt700 fs22 mb-20">Upload Manifest</h4>
                </div>

                <div class="profileipt">
                    <div class="row">
                        <input type="file" #manifestfile style="display: none;" (change)="uploadFile($event, 'manifest')">
                        <!-- INPUT ROW -->
                        <div class="col-md-12">
                            <div class="form-container mt-0 mb-4 p-eml-rt">
                                <div class="floating-label mb-10 flselcted">
                                    <input type="text" class="form-control" placeholder=" ">
                                    <button class="btn btn-outline-secondary fs16 btn-o-default" type="button" (click)="manifestfile.click()">
                                        <span><a href="javascript:;">Upload</a></span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107"
                                            viewBox="0 0 18.71 12.107">
                                            <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
                                                <path id="Path_2337" data-name="Path 2337"
                                                    d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z"
                                                    transform="translate(-14.582 -27.082)" fill="#ff7135" />
                                                <g id="Group_15718" data-name="Group 15718">
                                                    <g id="Group_15717" data-name="Group 15717">
                                                        <g id="Group_15716" data-name="Group 15716">
                                                            <path id="Path_2338" data-name="Path 2338"
                                                                d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4"
                                                                transform="translate(-36.046 -39.347)" fill="#ff7135" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>

                                    </button>
                                    <span class="highlight"></span>
                                    <label>Upload Manifest</label>
                                    <div class="docselected"><img
                                            src="assets/images/svg/attach.svg"><span>manifest.pdf</span></div>
                                </div>
                            </div>
                        </div>
                        <!-- /INPUT ROW -->
                    </div>
                </div>

                <div class="text-center">
                    <input type="button" value="Submit" class="btn px-4 buybtn fs16 radius10" (click)="generatedetails('manifest')" />
                </div>
            </div>
        </div>
        <!-- <hr>
        <div class="row align-items-center">
            <div class="col-md-12">
                <div class="text-center">
                    <i placement="top" ngbTooltip="This will act as a default filter every time you will toggle."> <img
                            src="/assets/images/icons/info.png"></i> Download the Generated Invoice and then upload it.
                </div>
            </div>
        </div> -->
    </div>
</ng-template>
<!-- /UPLOAD MANIFEST -->

<!-- UPLOAD IMEI LIST -->
<ng-template #imeilist let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>

    <div class="modal-body pb-3">
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead">
                    <h4 class="txtdefault wt700 fs22 mb-20">Upload IMEI List</h4>
                </div>

                <div class="profileipt">
                    <div class="row">
                        
                        <!-- INPUT ROW -->
                        <div class="col-md-12">
                            <div class="form-container mt-0 mb-4 p-eml-rt">
                                <div class="floating-label mb-10 flselcted">
                                    <input type="text" class="form-control" placeholder=" ">
                                    <input type="file" value="Upload" #imeilistfile style="display: none;" (change)="uploadFile($event, 'imeilist')">
                                    <button class="btn btn-outline-secondary fs16 btn-o-default" type="button" (click)="imeilistfile.click()">
                                        <span><a href="javascript:;">Upload</a></span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107"
                                            viewBox="0 0 18.71 12.107">
                                            <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
                                                <path id="Path_2337" data-name="Path 2337"
                                                    d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z"
                                                    transform="translate(-14.582 -27.082)" fill="#ff7135" />
                                                <g id="Group_15718" data-name="Group 15718">
                                                    <g id="Group_15717" data-name="Group 15717">
                                                        <g id="Group_15716" data-name="Group 15716">
                                                            <path id="Path_2338" data-name="Path 2338"
                                                                d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4"
                                                                transform="translate(-36.046 -39.347)" fill="#ff7135" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>

                                    </button>
                                    <span class="highlight"></span>
                                    <label>Upload IMEI</label>
                                    <div class="docselected"><img
                                            src="assets/images/svg/attach.svg"><span>IMEI.xlsx</span></div>
                                </div>
                            </div>
                        </div>
                        <!-- /INPUT ROW -->
                    </div>
                </div>

                <div class="text-center">
                    <input type="button" value="Submit" class="btn px-4 buybtn fs16 radius10" (click)="generatedetails('imeilist')" />
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- /UPLOAD IMEI LIST -->

<!-- Billing ADDRESS -->
<ng-template #editssbillingaddressModal let-modal>
    <div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title text-center cg-ttl pb-2">Update {{ edit_type == 'sold_by'? 'Registered': 'Product Location' }} Address</h5>
            <div class="form-container mdl-frm-lbl mb-3">
                <div class="row">
                    <div class="col-sm-12 mb-4">
                        <div class="floating-label mb-0">
                            <textarea class="form-control" placeholder="" [(ngModel)]="order_address.address"></textarea>
                            <span class="highlight"></span>
                            <label>Address</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <select class="form-control" [(ngModel)]="order_address.state" (change)="getcity()">
                                <option selected="selected" value="">Please Select</option>
                                <option *ngFor="let item of stateArray" [ngValue]="item.value">{{item.name}}</option>
                            </select>
                            <span class="highlight"></span>
                            <label>State</label>
                        </div>
                    </div>
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <select class="form-control" [(ngModel)]="order_address.city">
                                <option selected="selected" value="">Please Select</option>
                                <option *ngFor="let item of cityArray" [ngValue]="item.value">{{item.name}}</option>
                            </select>
                            <span class="highlight"></span>
                            <label>City</label>
                        </div>
                    </div>
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <input type="text" class="form-control" placeholder=" " [(ngModel)]="order_address.pincode" />
                            <span class="highlight"></span>
                            <label>Pincode</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="cta  pb-4 text-center">
                <button class="btn btn-mod btn-color btn-medium btn-round ripple mnbtn250"
                     (click)="updateorderdetails()"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                     *ngIf="invoiceupdateLoading"></span>
                 &nbsp;Update Address</button>

            </div>

    </div>
</ng-template>
<!-- /Super Seller Billing ADDRESS -->

<!-- Update invoice number -->
<ng-template #editinvoicenumberModal let-modal>
    <div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title text-center cg-ttl pb-2">Update Invoice Number</h5>
            <div class="form-container mdl-frm-lbl mb-3">
                <div class="row">
                    <div class="col-sm-12 mb-4">
                        <div class="floating-label mb-0">
							<input type="text" class="form-control" placeholder="" [(ngModel)]="order_address.invoice_number">
                            <span class="highlight"></span>
                            <label>Invoice Number</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="cta  pb-4 text-center">
                <button class="btn btn-mod btn-color btn-medium btn-round ripple mnbtn250"
                     (click)="updateorderdetails()"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                     *ngIf="invoiceupdateLoading"></span>
                 &nbsp;Update Invoice Number</button>

            </div>

    </div>
</ng-template>
<!-- /ADD NEW ADDRESS -->


<app-footer-seller></app-footer-seller>

<!-- RETURN REQUEST -->
<ng-template #returnrequest let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>

    <div class="modal-body">
        <div class="row align-items-center">
            <div class="col-sm-7">
                <div class="">
                    <div class="rtrqsthead">
                        <h4 class="txtdefault wt700 fs22">Return Request</h4>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 pr-0 rtnrqtmdl">
                            <div class="d-table mb-4 w-100">
                                <div class="d-table-cell cart-thumb align-middle">
                                    <img src="{{ order_details.product_image }}" />
                                </div>
                                <div class="d-table-cell pl-2 align-middle">
                                    <p class="txtdefault wt700 mb-0 fs12">{{ order_details.product_name }}
                                    </p>
                                    <p class="txtseconday my-1 fs12"><span class="txtdefault wt500">Order ID: </span>
                                        {{ order_details.order_no }}</p>
                                    <div class="badge-d-outline odbdge d-inline fs10" *ngIf="order_details.return_request"><b>Return Status:</b> {{ return_status_text }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-container mb-0 mt-0">
                        <div class="row" *ngIf="order_details.parentchild != 'yes'">
                            <div class="col-lg-12 ">
                                <div class="floating-label">
                                    <input type="text" placeholder=" " class="form-control" name="return_quantity" [(ngModel)]="return_quantity">
                                    <span class="highlight"></span>
                                    <label>No. of Products</label>
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="order_details.parentchild == 'yes'">
                            <div class="col-lg-12" *ngFor="let ch of order_details.parentchild_records">
                                <div class="floating-label">
                                    <input type="text" placeholder=" " class="form-control" name="return_quantity" [(ngModel)]="ch.return_qty">
                                    <span class="highlight"></span>
                                    <label>{{ ch.name }} - No. of Products</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 ">
                                <div class="floating-label">
                                    <input type="text" placeholder=" " class="form-control" name="return_reason" [(ngModel)]="return_reason">
                                    <span class="highlight"></span>
                                    <label>Reason for Cancellation</label>
                                </div>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-lg-12 ">
                                <div class="floating-label">
                                    <textarea placeholder="" name="return_message" [(ngModel)]="return_message" class="form-control txtareaht80"></textarea>
                                    <span class="highlight"></span>
                                    <label>Message to Seller</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="client-thumbnails dtlthmb odimgsz text-center img-option-list" *ngIf="order_details.return_request">
                        <ngx-slick-carousel class="carousel thumbs" #slickModal="slick-carousel"
                            [config]="thumbnailsSlider ">
                            <div ngxSlickItem *ngFor="let slide of return_updated_image" class="slide">
                                <a class="d-inline-block w-100" href="javascript:void(0);">
                                    <img src="{{ slide.img }}" alt="">
                                </a>
                                <!-- <p class="fs12 txtseconday mb-0">Front image*</p> -->
                            </div>
                        </ngx-slick-carousel>
                    </div>
                    <div class="text-right" *ngIf="return_status == 1">
                        <a href="javascript:void(0);" class="btn buybtn-success fs16 radius10 ripple mr-2" (click)="submitreturn('approve')">Approve</a>
                        <a href="javascript:void(0);" class="btn-d-outline btn-danger-outline d-inline-block ml-sm-3 ml-2" (click)="submitreturn('reject')">Reject</a>
                    </div>
                    <div class="text-right" *ngIf="return_status > 1">
                        <a href="javascript:void(0);" class="btn-d-outline btn-danger-outline d-inline-block ml-sm-3 ml-2" (click)="modal.dismiss('Cross click')">Cancel</a>
                    </div>
                </div>
            </div>
            <div class="col-sm-5 text-center">
                <div class="feedback-slides  ">
                    <div class="client-feedback dtlfull">
                        <ngx-slick-carousel class="carousel feedback" #slickModal="slick-carousel"
                            [config]="imagesSlider">
                            <div ngxSlickItem *ngFor="let slide of return_updated_image" class="slide ">
                                <div class="thmbnaildtl">
                                    <img src="{{ slide.img }}" alt="" width="100%">
                                </div>
                            </div>
                        </ngx-slick-carousel>
                    </div>
                </div>
                <p class="fs10 txtseconday pt-3">Image Preview</p>
            </div>
        </div>
    </div>

</ng-template>
<!-- /RETURN REQUEST -->