import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class WalletBalanceService {
    constructor(private _http: HttpClient) { }

    walletbalance(data) {
        return this._http.post(environment.api_url+'cashfree/createorder',data);
    }

    userbasicinfo(data) {
        return this._http.post(environment.api_url+'admin/userbasicinfo',data);
    }
}
