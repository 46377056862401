<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain pb-4">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center pinfo-head">
			<div class="col-md-5 mb-4">
				<h3 class="fs24 wt700 txtdefault">Logistics Charges List</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">						
						<li class="breadcrumb-item"><a href="javascript:void(0);" class="txtseconday">Logistics Management</a></li>
						<li class="breadcrumb-item active">Logistics Charges</li>
					</ol>
				</nav>
			</div>
			<div class="col-md-7  mb-4 text-md-right text-center">
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4 mb-0">
			<div class="notifications-container theme-tab">
				<ngb-tabset [justify]="'left'" [type]="'pills'" class="tabs tab-title">
		            <ngb-tab>
		                <ng-template ngbTabTitle>Standard - Within City</ng-template>
		                <ng-template ngbTabContent>
		                  	<div class="tab-content">
								<div class="table-responsive brndmgnttable odmgnttabl"> 
									<table class="table custom-table xs">
										<thead>
											<tr>
												<th>Weight</th>
												<th>Flat Rate</th>
												<th>GST Percentage</th>
												<th>GST Amount</th>
                                                <th>Grand Total</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let charge of standardCityCharges;">
												<td>{{ charge.weight }}</td>
												<td>{{ charge.flat_rate }}</td>
												<td>{{ charge.gst_percentage }}</td>
												<td>{{ charge.gst_rate }}</td>
                                                <td>{{ charge.total }}</td>
											</tr>
										</tbody>
									</table>
								</div>	
		                  	</div>
		                </ng-template>
		            </ngb-tab>	
					<ngb-tab>
		                <ng-template ngbTabTitle>Standard - Rest of India</ng-template>
		                <ng-template ngbTabContent>
		                  	<div class="tab-content">

								<div class="table-responsive brndmgnttable odmgnttabl"> 
									<table class="table custom-table xs">
										<thead>
											<tr>
												<th>Weight</th>
												<th>Flat Rate</th>
												<th>GST Percentage</th>
												<th>GST Amount</th>
                                                <th>Grand Total</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let charge of standardOutsideCharges;">
												<td>{{ charge.weight }}</td>
												<td>{{ charge.flat_rate }}</td>
												<td>{{ charge.gst_percentage }}</td>
												<td>{{ charge.gst_rate }}</td>
                                                <td>{{ charge.total }}</td>
											</tr>
										</tbody>
									</table>
								</div>	
		                  	</div>
		                </ng-template>
		            </ngb-tab>
		            <ngb-tab>
		                <ng-template ngbTabTitle>Express</ng-template>
		                <ng-template ngbTabContent>
		                  	<div class="tab-content">
								<div class="table-responsive brndmgnttable odmgnttabl"> 
									<table class="table custom-table xs">
										<thead>
											<tr>
												<th>Weight</th>
												<th>Flat Rate</th>
												<th>GST Percentage</th>
												<th>GST Amount</th>
                                                <th>Grand Total</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let charge of expressCharges;">
												<td>{{ charge.weight }}</td>
												<td>{{ charge.flat_rate }}</td>
												<td>{{ charge.gst_percentage }}</td>
												<td>{{ charge.gst_rate }}</td>
                                                <td>{{ charge.total }}</td>
											</tr>
										</tbody>
									</table>
								</div>	
		                  	</div>
		                </ng-template>
		            </ngb-tab>						
	        	</ngb-tabset>
			</div>			
		</div>
	</div>
</div>	 