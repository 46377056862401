import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MyListingService } from './my-listing.service';
import { ToasterService } from '../../helpers/toastr.service';
import { PaginationService } from '../../helpers/pagination.service';
import { SignupService } from 'src/app/shared/components/modal/signup-modal/signup-modal.service';
import { ViewListingService } from '../view-listing/view-listing.service';
import { Observable, forkJoin } from 'rxjs';

@Component({
    selector: 'app-my-listing',
    templateUrl: './my-listing.component.html',
    styleUrls: ['./my-listing.component.scss']
})
export class MyListingComponent implements OnInit {
    closeResult = '';

    public supplier_records:any = [];

    public search_term:string = '';
    public product_status:string = '';
    public rsearch_term:string = '';
    public ssasearch_term:string = '';
    public sspsearch_term:string = '';

    public ssasupplier_id:string = '';
    public sspsupplier_id:string = '';

    constructor(
        private modalService: NgbModal,
        private _mylistingservice: MyListingService,
        private _toastr: ToasterService,
        private _pagination: PaginationService,
        private _signupservice: SignupService,
        private _viewlistingservice: ViewListingService
    ) { }
    public margin_type:string = 'add';
    public seller_type:string;
    public wholesale_netrealization:any = {};
    public bulk_netrealization:any = {};
    public netrealization_type_wholesale:string = 'inside';
    public netrealization_type_bulk:string = 'inside';

    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 10;
    public product_listing_pagination: any = {};
    
    public req_current_page: number = 1;
    public req_offset: number = 0;
    public req_page_per_view: number = 10;
    public request_listing_pagination: any = {};
    public records: any = [];
    public requested_records: any[];

    public selected_list: any = {};

    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];

    ngOnInit() {
        this.seller_type = this._signupservice.getsellertype();
        this.getproductlisting();
        this.requestedlisting();
        setTimeout( () => {
            this.getsupplierlist();
        }, 1500);
        
        if(this.seller_type == 'super'){
            setTimeout( () => {
                this.getsubsellerapprovedproducts();
                this.getsubsellerpendingproducts();
            }, 10000);
            
        }

        // setTimeout(() => {
        //     this.changeproductstatus('627ebe09944d24ce1efbd287');
        // }, 5000);

    }

    updatestatus(type, record) {
        this._viewlistingservice.updatestatus({ unique_id: record.uniqueId, variant_id: '', type: type, value: record.status }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Product listing status has been changed.');
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getproductlisting() {
        this._mylistingservice.productlisting({ search: this.search_term, status: this.product_status, limit: this.page_per_view, offset: this.offset, current_page: this.current_page }).subscribe((data) => {
            if (data['status'] == "success") {
                this.records = data['searchResults'];
                let total_records = data['total_records'];
                let pages = Math.ceil(total_records / this.page_per_view);
                this.product_listing_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);

            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getsupplierlist() {
        this._mylistingservice.supplierlist(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.supplier_records = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    requestedlisting() {
        this._mylistingservice.requestedlisting({ search: this.rsearch_term, limit: this.req_page_per_view, offset: this.req_offset, current_page: this.req_current_page }).subscribe((data) => {
            if (data['status'] == "success") {
                this.requested_records = data['records'];
                let total_records = data['total_records'];
                let pages = Math.ceil(total_records / this.req_page_per_view);
                this.request_listing_pagination = this._pagination.getPager(total_records, this.req_current_page, this.req_page_per_view);

            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    removerequestedlist() {
        this._mylistingservice.removerequestedlist({ id: this.selected_list.id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this._toastr.success('Requested listing has been deleted successfully.');
                this.requestedlisting();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.getproductlisting();
    }

    setpageperview() {
        this.current_page = 1;
        this.getproductlisting();
    }

    setreqpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.getproductlisting();
    }

    setreqpageperview() {
        this.current_page = 1;
        this.getproductlisting();
    }


    open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true, }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    openmargin(content, record, type) {
        this.margin_type = type;
        this.selected_sub_pending_record = record;
        this.modalService.open(content, { windowClass: 'addmargin-popup', ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true, }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
        if(this.margin_type == 'update'){
            this.supersellernetrealization('wholesale');
            setTimeout( () => {
                this.supersellernetrealization('bulk');
            }, 2000);
            
        }
    }

    openWindowCustomClass(content, list) {
        this.selected_list = list;
        this.modalService.open(content, { windowClass: 'modal-500' });
    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }



    //Pending Sub Seller Products
    public selected_sub_pending_record: any = {};
    public sub_pending_current_page: number = 1;
    public sub_pending_offset: number = 0;
    public sub_pending_page_per_view: number = 10;
    public sub_pending_listing_pagination: any = {};
    public sub_pending_records: any = [];

    setsubpendingpage(page) {
        this.sub_pending_current_page = page;
        this.sub_pending_offset = (page - 1) * this.sub_pending_page_per_view;
        this.getsubsellerpendingproducts();
    }

    setsubpendingpageperview() {
        this.sub_pending_current_page = 1;
        this.getsubsellerpendingproducts();
    }

    getsubsellerpendingproducts() {
        this._mylistingservice.getsubsellerpendingproducts({ supplier_id: this.sspsupplier_id, search: this.sspsearch_term, limit: this.sub_pending_page_per_view, offset: this.sub_pending_offset, current_page: this.sub_pending_current_page }).subscribe((data) => {
            if (data['status'] == "success") {
                this.sub_pending_records = data['records'];
                let total_records = data['total_records'];
                this.sub_pending_listing_pagination = this._pagination.getPager(total_records, this.sub_pending_current_page, this.sub_pending_page_per_view);

            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    supersellernetrealization(type) {
        if(type == 'wholesale'){
            var margin = this.selected_sub_pending_record.wholesale_margin;
        }else{
            var margin = this.selected_sub_pending_record.bulk_margin;
        }
        this._mylistingservice.netrealization({ sp_id: this.selected_sub_pending_record.sp_id, margin: margin, market_place: type }).subscribe((data) => {
            if (data['status'] == "success") {
                if(type == 'wholesale'){
                    this.wholesale_netrealization = data['record'];
                }else{
                    this.bulk_netrealization = data['record'];
                }
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    calculatetotal() {
        this.selected_sub_pending_record.wholesale_total_price = parseInt(this.selected_sub_pending_record.wholesale_price) + parseInt(this.selected_sub_pending_record.wholesale_margin);
        this.selected_sub_pending_record.bulk_total_price = parseInt(this.selected_sub_pending_record.bulk_price) + parseInt(this.selected_sub_pending_record.bulk_margin);
    }

    calculatechildtotal() {
        var i = 0;
        for(i; i<this.selected_sub_pending_record.child_records.length; i++){
            this.selected_sub_pending_record.child_records[i].wholesale_total_price = parseInt(this.selected_sub_pending_record.child_records[i].wholesale_price) + parseInt(this.selected_sub_pending_record.wholesale_margin);
            this.selected_sub_pending_record.child_records[i].bulk_total_price = parseInt(this.selected_sub_pending_record.child_records[i].bulk_price) + parseInt(this.selected_sub_pending_record.bulk_margin);
        }
    }

    //Add Margin Product
    addmargintoproduct() {
        this._mylistingservice.subsellerpendingapprove({ type: this.margin_type, sp_id: this.selected_sub_pending_record.sp_id, wholesale_margin: this.selected_sub_pending_record.wholesale_margin, bulk_margin: this.selected_sub_pending_record.bulk_margin }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Margin has been added to sub seller product.');
                this.getsubsellerpendingproducts();
                setTimeout(() => {
                    this.getsubsellerapprovedproducts();
                }, 1000);
                this.modalService.dismissAll();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    subsellerpendingreject(record) {
        this._mylistingservice.subsellerpendingreject({ sp_id: record.sp_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Sub seller product has been rejected successfully.');
                this.getsubsellerpendingproducts();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }



    //Approved Sub Seller Products
    public sub_approved_current_page: number = 1;
    public sub_approved_offset: number = 0;
    public sub_approved_page_per_view: number = 10;
    public sub_approved_listing_pagination: any = {};
    public sub_approved_records: any = [];

    setsubapprovedpage(page) {
        this.sub_approved_current_page = page;
        this.sub_approved_offset = (page - 1) * this.sub_approved_page_per_view;
        this.getsubsellerapprovedproducts();
    }

    setsubapprovedpageperview() {
        this.sub_approved_current_page = 1;
        this.getsubsellerapprovedproducts();
    }

    getsubsellerapprovedproducts() {
        this._mylistingservice.subsellerapprovedproducts({ supplier_id: this.ssasupplier_id, search: this.ssasearch_term, limit: this.sub_approved_page_per_view, offset: this.sub_approved_offset, current_page: this.sub_approved_current_page }).subscribe((data) => {
            if (data['status'] == "success") {
                this.sub_approved_records = data['records'];
                let total_records = data['total_records'];
                this.sub_approved_listing_pagination = this._pagination.getPager(total_records, this.sub_approved_current_page, this.sub_approved_page_per_view);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }


    //Change Product Status
    changeproductstatus(sp_id) {
        this._mylistingservice.changeproductstatus({ sp_id: sp_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Product status has been changed successfully.');
                this.getsubsellerapprovedproducts();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

}
