import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { EncrDecrService } from 'src/app/helpers/EncrDecrService';
import { EditNewPartnerService } from './edit-new-partner.service';

@Component({
  selector: 'app-edit-new-partner',
  templateUrl: './edit-new-partner.component.html',
  styleUrls: ['./edit-new-partner.component.scss']
})
export class EditNewPartnerComponent implements OnInit {
	editNewPartnerForm: FormGroup;

	public buttonLoading:boolean = false;
	public partner_id:string;
	public partner_record:any = {};
	public pincode_document:any;
	public tracking_document:any;
	public pincode_document_status:boolean = false;
	public tracking_document_status:boolean = false;
  	constructor(
		private _editpartner: EditNewPartnerService,
		private fb: FormBuilder,
		private _toastr: ToasterService,
		private _EncrDecr: EncrDecrService,
		private route: ActivatedRoute,

  	) { }

    ngOnInit(): void {
		this.partner_id = this.route.snapshot.paramMap.get('id');

		this.editNewPartnerForm = this.fb.group({
            name: this.fb.control(null, [Validators.required]),
            gst_no: this.fb.control(null, [Validators.required]),
        });

		this.partnerinfo();
    }

	get pf() { return this.editNewPartnerForm.controls; }

	partnerinfo() {
        this._editpartner.partnerinfo({partner_id: this.partner_id}).subscribe((data) => {
            if (data['status'] == "success") {
                var record = data['record'];
				this.editNewPartnerForm.patchValue({
                    name: record.name,
					gst_no: record.gst
                });
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

	updatelogisticspartner() {
		this.buttonLoading = true;
		let data: any = new FormData();
		data.append('partner_id', this.partner_id);
        data.append('name', this._EncrDecr.set(this.editNewPartnerForm.get('name').value));
		data.append('gst_no', this._EncrDecr.set(this.editNewPartnerForm.get('gst_no').value));
		if(this.pincode_document_status){
			data.append('pincode', this.pincode_document);
		}
		if(this.tracking_document_status){
			data.append('tracking', this.tracking_document);
		}
        this._editpartner.partnerupdate(data).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Logistics partner details has been updated successfully.');
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
			this.buttonLoading = false;
        }, (error) => {
            this.buttonLoading = false;
        });
    }

	uploadFile(event, type) {
        const file = event.target.files;
		if(type == 'pincode'){
			this.pincode_document = file[0];
			this.pincode_document_status = true;
		}else if(type == 'tracking'){
			this.tracking_document = file[0];
			this.tracking_document_status = true;
		}
        event.target.value = '';

    }

}
