<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Product Management</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" [routerLink]="['/admin/product-listing']">Product Listing</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday">Seller Listing</a>
						</li>
						<li class="breadcrumb-item active">View Products</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				&nbsp;
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="row d-flex align-items-center mb-20">
			<div class="col-md-6">
				<h3 class="f-700 fs22 txtdefault pb-0"><a href="javascript:;" class="go-back"><i class="fa fa-angle-left"></i></a> View Products</h3>
			</div>
			<div class="col-md-6 text-right">
				<a href="javascript:;" class="btn buybtn fs16 radius10 ripple" [routerLink]="['/admin/manage-seller/view-details/'+seller_id]">Manage Seller</a>
			</div>
		</div>

		<div class="row mb-10">
			<div class="col-md-8 pb-2">
				<div class="row">
					<div class="col-sm-2 col-3 seller-thumb">
						<img [src]="basic_info.logo?basic_info.logo:'assets/images/svg/vendor.svg'" />
					</div>
					<div class="col-sm-10 col-9 pl-0 seller-info">
						<h4 class="f-600 fs22">{{ basic_info.name }}</h4>
						<div class="fs12 txtseconday mb-1">{{ basic_info.type }}</div>
						<span class="pb-sm-2 pl-3 seller-loc fs12">
						<svg xmlns="http://www.w3.org/2000/svg" width="9.375" height="11.752" viewBox="0 0 9.375 11.752">
	  					<path id="Path_2306" data-name="Path 2306" d="M25.689,5.029a4.825,4.825,0,0,0-4.154,5.16c.285,3.272,3.293,5.711,4.324,6.457a.562.562,0,0,0,.677-.012c1.071-.836,4.357-3.67,4.357-6.942a4.691,4.691,0,0,0-5.2-4.663Zm.514,6.524a1.861,1.861,0,1,1,1.861-1.861A1.861,1.861,0,0,1,26.2,11.553Z" transform="translate(-21.517 -5.002)" fill="#5e6a7c"/>
						</svg>{{ basic_info.location }}</span>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="d-flex align-items-center justify-content-end">
					Seller Status &nbsp;&nbsp;
					<div class="tglbtn">
						<label class="switch">
						  <input type="checkbox" [(ngModel)]="basic_info.status">
						  <span class="slider round"></span>
						</label>
					</div>
				</div>
			</div>

			<!-- <div class="col-md-12 mt-20 pb-2">
				<div class="card pl-4 pt-4 keyperform pb-2">
					<div class="d-flex align-items-center justify-content-between mb-10">
						<h4 class="fs16 f-700 txtdefault">Key Performance</h4>
						<a href="javascript:;" class="link-alt mr-4">View Supplier Performance</a>
					</div>
					<ul class="row mb-0">
						<li class="col-md-3">
							<div class="keyratewrp">
								<div class="keyrate">
									<img src="assets/images/png/circle.png" />
									<span class="f-700 txtdefault">{{ basic_info.key_performance.order_fulfilment_rating }}</span>
									<div class="ratings star-ratings pr-1 d-inline-block">
										<i class="fa fa-star" aria-hidden="true"></i>
									</div>
								</div>
								<div class="mb-10">Order Fulfillment</div>
							</div>
						</li>
						<li class="col-md-3">
							<div class="keyratewrp">
								<div class="keyrate">
									<img src="assets/images/png/circle.png" />
									<span class="f-700 txtdefault">{{ basic_info.key_performance.deliery_rating }}</span>
									<div class="ratings star-ratings pr-1 d-inline-block">
										<i class="fa fa-star" aria-hidden="true"></i>
									</div>
								</div>
								<div class="mb-10">Delivery</div>
							</div>
						</li>
						<li class="col-md-3">
							<div class="keyratewrp">
								<div class="keyrate">
									<img src="assets/images/png/circle.png" />
									<span class="f-700 txtdefault">{{ basic_info.key_performance.quality_assurance }}</span>
									<div class="ratings star-ratings pr-1 d-inline-block">
										<i class="fa fa-star" aria-hidden="true"></i>
									</div>
								</div>
								<div class="mb-10">Quality Assurance</div>
							</div>
						</li>
					</ul>
				</div>
			</div> -->
		</div>

		<!--Filters-->
  		<div class="row d-flex mt-20 report-cta mb-20">
			<div class="col-md-3">
				<div class="report-input">
					<div class="input-group-custom">
						<input type="text" placeholder="Search by Product Name, Brand Name & Model No." class="form-control" 
						class="form-control" [(ngModel)]="search_term" (change)="viewsellerlisting()">
						<div class="input-group-append-custom">
							<img src="assets/images/svg/search_logo.svg">
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-3">
				<div class="report-input">
					<div class="input-group-custom">
						<div class="ng-autocomplete">
							<ng-autocomplete 
								[data]="result"
								[searchKeyword]="keyword"
								(selected)='selectEvent($event)'
								(inputChanged)='onChangeSearch($event)'
								(inputFocused)='onFocused($event)'
								[itemTemplate]="itemTemplate"
								[notFoundTemplate]="notFoundTemplate"
								placeholder="Search by Category Name"
							>
							</ng-autocomplete>
							<ng-template #itemTemplate let-item>
								<a href="javascript:void(0)">
									<div class="d-flex">
										<span [innerHTML]="item.name"></span>
										<!-- <span [innerHTML]="item.current" class="current"></span> -->
									</div>
								</a>
							</ng-template>

							<ng-template #notFoundTemplate let-notFound>
								<div [innerHTML]="notFound"></div>
							</ng-template>
							<div class="search-icon"><img src="assets/images/svg/search_logo.svg"></div>
						</div>
					</div>
				</div>
			</div>

			<div class="form-container mt-0 mb-0 mr-3">
				<div class="report-input floating-label">
					<div class="input-group-custom">
						<ng-selectize [config]="config" name="listing_status" [(ngModel)]="listing_status"
						[options]="listing_status_options" placeholder="Listing Status" (ngModelChange)="viewsellerlisting()"></ng-selectize>
					</div>
				</div>
			</div>

			<!-- <div class="col-md-7">
				<div class="d-flex justify-content-start">
					<div class="mr-3">
						<a href="javascript:void(0);" class="d-inline-block fltr-round-btn radius10 txtseconday">
							<img src="assets/images/svg/filter.svg">
							Filters
						</a>
					</div>
				</div>
			</div> -->
		</div>
		<!--End Filters-->

		<div class="card card-ui card-uiovt byregwrp">
			<div class="table-responsive">
				<table class="table custom-table">
				  <thead>
					  <tr>
						<th>Product ID</th>
						<th width="22%">Product Name</th>
						<th>Category</th>
						<th>Listing Available</th>
						<th width="22%">Action</th>
					  </tr>
				  </thead>
				  <tbody>
					<!-- REPEAT -->
					<tr *ngFor="let record of seller_records; let i = index;">
						<td>{{ record.unique_no }}</td>
						<td>
							<div class="order-info">
								<div class="order-img">
									<img [src]="record.image" />
								</div>
								<div class="pd-seller-cntnt">
									<div class="txtdefault">
										<a href="javascript:;" class="order-link">{{ record.name }}</a>
									</div>
								</div>
							</div>
						</td>
						<td>{{ record.category_name }}</td>
						<td>{{ record.maps }}</td>
						<td>
							<div class="d-flex align-items-center">
								<a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Orders</a>
								<div class="light-text">
									<a href="javascript:;" [routerLink]="['/admin/edit-seller-listing/'+seller_id+'/'+record.uniqueId]"><img src="assets/images/icons/edit-big.png"></a>
									&nbsp; | &nbsp;
									<a href="javascript:;" (click)="deletesellerlisting(record.uniqueId)"><img src="assets/images/icons/delete-icon.svg"></a>
								</div>
							</div>
						</td>
					</tr>
					<!-- /REPEAT -->

					<!--Empty State-->
					<tr *ngIf="!seller_records.length">
						<td colspan="7">
							<div class="row pt-40 pb-40">
								<div class="col-md-12 text-center">
									<div class="mb-20"><img src="/assets/images/empty-state.png"></div>
									<div class="fs16">There are no records to display!</div>
								</div>
							</div>
						</td>
					</tr>
					<!--End Empty State-->	
					
				  </tbody>
				</table>
			</div> 
		</div>

		<div class="row mb-40" *ngIf="seller_records.length > 0">
			<div class="col-md-6">
				<div class="text-left pl-3">
					<nav class="mt-3 tbpg rows-per-page">
						<div>Rows per page:</div>
						<select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
							<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">{{option.name}}</option>
						</select>
					</nav>
				</div> 
			</div>
			<div class="col-md-6" *ngIf="seller_listing_pagination.pages && seller_listing_pagination.pages.length">
				<div class="text-right pr-3">
					<nav class="mt-3 tbpg">
					  <ul class="pagination align-items-center">
						<li class="page-item" [ngClass]="{disabled:seller_listing_pagination.currentPage === 1}"><a class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a></li>
						<li class="page-item" [ngClass]="{disabled:seller_listing_pagination.currentPage === 1}"><a class="page-link sprevicon" href="javascript:;" (click)="setpage(seller_listing_pagination.currentPage - 1)"></a></li>
						<li class="page-item"i *ngFor="let page of seller_listing_pagination.pages" [ngClass]="{active:seller_listing_pagination.currentPage === page}"><a class="page-link" href="javascript:;" (click)="setpage(page)">{{page}}</a></li>
						<li *ngIf="(seller_listing_pagination.totalPages - seller_listing_pagination.startPage) > 5 ">
							<a (click)="setpage(seller_listing_pagination.startPage + 5)">...</a>
						</li>
						 <li *ngIf="(seller_listing_pagination.totalPages - seller_listing_pagination.startPage) > 5" >
							<a (click)="setpage(seller_listing_pagination.totalPages)">{{seller_listing_pagination.totalPages}}</a>
						</li>
						<li class="page-item" [ngClass]="{disabled:seller_listing_pagination.currentPage === seller_listing_pagination.totalPages}"><a class="page-link snexticon" href="javascript:;"  (click)="setpage(seller_listing_pagination.currentPage + 1)"></a></li>
						<li class="page-item" [ngClass]="{disabled:seller_listing_pagination.currentPage === seller_listing_pagination.totalPages}"><a class="page-link nexticon" href="javascript:;" (click)="setpage(seller_listing_pagination.totalPages)"></a></li>
					  </ul>
					</nav>
				</div> 
			</div>
		</div>

	</div>
</div>