import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AddListingService } from './addlisting.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '../../helpers/toastr.service';
import { PreviewComponent } from 'src/app/shared/components/modal/preview/preview.component';
@Component({
    selector: 'app-add-listing',
    templateUrl: './add-listing.component.html',
    styleUrls: ['./add-listing.component.scss']
})
export class AddListingComponent implements OnInit {

    @ViewChild("preview") Preview: PreviewComponent;

    keyword = 'name';
    public activeId = "custom-panel-1";
    public searchword: string;
    closeResult = '';
    constructor(
        private modalService: NgbModal,
        private _addlistingservice: AddListingService,
        private route: ActivatedRoute,
        private _router: Router,
        private _toastr: ToasterService
    ) { }

    ngOnInit(): void {
    }
    openWindowCustomClass(content) {
        this.modalService.open(content, { windowClass: 'p-preview-modal', backdropClass: 'hidebackdrop' });
    }
    ratingclassstatus: boolean = false;
    ratingtglclass() {
        this.ratingclassstatus = !this.ratingclassstatus;
    }

    showcomperr: boolean = false;
    showcompmsg() {
        this.showcomperr = true;
    }
    imagesSlider = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: ".thumbs",
    };
    thumbnailsSlider = {
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: true,
        focusOnSelect: true,
        asNavFor: ".feedback",
        //prevArrow:'.client-thumbnails .prev-arrow',
        // nextArrow:'.client-thumbnails .next-arrow',
    };

    showhidetab(result) {
        result.show = !result.show;
    }



    slides = [];


    open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    result = [];
    searchedProducts = [];

    selectEvent(item) {
        this._addlistingservice.productdetails({ search: this.searchword, item: item }).subscribe((data) => {
            if (data['status'] == "success") {
                this.searchedProducts = data['searchResult'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    onChangeSearch(val: string) {
        this.searchword = val;
        // fetch remote data from here
        // And reassign the 'data' which is binded to 'data' property.
        this._addlistingservice.productsearch({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.result = [];
                this.result = data['searchResult'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    onFocused(e) {
        // do something when input is focused
    }


    start_listing(result) {
        var sellerlisting = [];
        sellerlisting['unique_id'] = result.uniqueId;
        var variants = [];
        for (let i = 0; i < result.variants.length; i++) {
            if (result.variants[i].selected) {
                var record = { id: result.variants[i].id, product_id: result.variants[i].product_id };
                variants.push(record);
            }
        }
        sellerlisting['variants'] = variants;
        this._addlistingservice.productadd({ unique_id: result.uniqueId, variants: variants }).subscribe((data) => {
            if (data['status'] == "success") {
                this._router.navigate(['/seller/addproduct/' + result.uniqueId]);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    openPreview(unique_id, variant_id) {
        this.Preview.openPreviewModal(unique_id, variant_id);
    }

}
