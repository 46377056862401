import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { CreditCardValidators } from 'angular-cc-library';
import { EncrDecrService } from 'src/app/helpers/EncrDecrService';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { BuyerAddMoneyService } from './buyer-add-money.service';
import { environment } from '../../../environments/environment';

declare const window: any;

@Component({
  selector: 'app-buyer-add-money',
  templateUrl: './buyer-add-money.component.html',
  styleUrls: ['./buyer-add-money.component.scss']
})
export class BuyerAddMoneyComponent implements OnInit {

    creditCardForm: FormGroup;
    public saved_cards_records:any = [];
    public payment_type:string;
    public selectedCard:any = {};
    public order_token:string;
    public order_unique_id:string;
    public wallet_amount:string;
    public payment_amount:number;
    public show_addmoney:boolean = false;
    public predefined_amounts = [
        {
            value: 10000,
            label: '10,000',
        },
        {
            value: 20000,
            label: '20,000',
        },
        {
            value: 50000,
            label: '50,000',
        },
        {
            value: 100000,
            label: '1,00,000',
        }
    ];

    constructor(
        private _buyeraddmoneyservice: BuyerAddMoneyService,
        private _toastr: ToasterService,
        private _EncrDecr: EncrDecrService,
        private fb: FormBuilder
    ) { }

    ngOnInit(): void {
        this.creditCardForm = this.fb.group({
            cardHolderName: ['', [Validators.required]],
            creditCard: ['', [CreditCardValidators.validateCCNumber]],
            expirationDate: ['', [CreditCardValidators.validateExpDate]],
            cvv: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(3)]],
            save_card: [false],
        });
        this.invokeStripe();
        this.getuserdetails();
        this.getsavedcard();
    }

    getuserdetails() {
        this._buyeraddmoneyservice.getuserdetails(null).subscribe((data) => {
            if(data['status'] == "success"){
                this.wallet_amount = data['data']['walletbalance'];
            }
        },(error) => {
        });
    }

    getsavedcard(){
        this._buyeraddmoneyservice.savedcards(null).subscribe((data) => {
            if(data['status'] == "success"){
                this.saved_cards_records = data['records'];
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    cancelpayment(){
        this.order_token = '';
        this.order_unique_id = '';
        this.payment_amount = 0;
        this.payment_type = '';
        this.show_addmoney = false;
    }

    placeorder(){
        if(this.payment_amount < 1000){
            this._toastr.error('Amount should be more than Rs.1000');
            return false;
        }
        if((!this.order_token || !this.order_unique_id) && this.payment_amount >= 1000 ){
            this._buyeraddmoneyservice.placeorder({amount: this.payment_amount}).subscribe((data) => {
                if(data['status'] == "success"){
                    this.order_token = data['order_token'];
                    this.order_unique_id = data['order_unique_id'];
                    this.show_addmoney = !this.show_addmoney;
                }else if(data['status'] == "error"){
                    this._toastr.error(data['message']);
                }
            });
        }
    }

    updateamount(amounts){
        this.payment_amount = amounts.value;
    }

    get cc() { return this.creditCardForm.controls; }

    invokeStripe() {
        if (!window.document.getElementById('stripe-script')) {
            const script = window.document.createElement('script');
            script.id = 'stripe-script';
            script.type = 'text/javascript';
            script.src = environment.cashfree_sandbox;
            script.onload = () => {};
            window.document.body.appendChild(script);
        }
    }

    makepayment(type) {
        this.payment_type = type;
        if(type == 'upi' || type == 'netbanking' || type == 'card'){
            var main = document.getElementById('dropincontainerMainDiv');
            main.style.display = main.style.display === 'none' ? '' : '';
            var parent = document.getElementById('drop_in_container');
            setTimeout(() => {
                parent.innerHTML = '';
                let cashfree = new (<any>window).Cashfree();
                cashfree.initialiseDropin(parent, {
                    orderToken: this.order_token,
                    onSuccess: function (data) {
                        var order_id = data.order.orderId;
                        var transaction_amount = data.transaction.transactionAmount;
                        var transaction_id = data.transaction.transactionId;
                        var http = new XMLHttpRequest();
                        var url = environment.api_url+"buyer/cf/addtowallet/verifyandconfirm";
                        var params = 'order_id='+order_id+'&transaction_amount='+transaction_amount+'&transaction_id='+transaction_id;
                        http.open('POST', url, true);

                        //Send the proper header information along with the request
                        http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                        http.setRequestHeader("Accept", "application/json");
                        http.setRequestHeader("Authorization", 'Bearer '+localStorage.getItem('loggedInUserToken'));
                        http.onreadystatechange = function() {
                            //Call a function when the state changes.
                            if(http.readyState == 4 && http.status == 200) {
                                let response = JSON.parse(http.responseText);
                                if(response.status == 'success'){
                                    alert(response.msg);
                                    window.location.reload();
                                }
                            }
                        }
                        http.send(params);
                    },
                    onFailure: function (data) {
                        if(data.order.status == 'ERROR'){
                            alert(data.order.errorText);
                        }else{
                            if(data.transaction.txStatus == 'FAILED'){
                                alert('Transaction Failed. Please check again');
                            }
                        }
                    },
                    components: [type], //'order-details', //'card', 'upi', 'app', 'netbanking'
                    style: {
                        backgroundColor: '#ffffff',
                        color: '#FF7135',
                        fontFamily: 'Lato',
                        fontSize: '14px',
                        errorColor: '#ff0000',
                        theme: 'light', //(or dark)
                    },
                });
            }, 500)
        }
    }

    cardsubmit(type) {
        var request_data = {};
        if(type == 'new'){
            request_data = {
                type: type,
                order_token: this.order_token,
                order_unique_id: this._EncrDecr.set(this.order_unique_id),
                card_holder: this._EncrDecr.set(this.creditCardForm.get('cardHolderName').value),
                card_number: this._EncrDecr.set(this.creditCardForm.get('creditCard').value),
                card_expiry: this._EncrDecr.set(this.creditCardForm.get('expirationDate').value),
                card_cvv: this._EncrDecr.set(this.creditCardForm.get('cvv').value),
                save_card: this.creditCardForm.get('save_card').value,
            };
        }else{
            request_data = {
                type: type,
                card_id: this.selectedCard.id,
                order_token: this.order_token,
                order_unique_id: this._EncrDecr.set(this.order_unique_id),
                card_cvv: this._EncrDecr.set(this.selectedCard.cvv),
            };
        }
        
        this._buyeraddmoneyservice.submitcard(request_data).subscribe((data) => {
            if(data['status'] == "success"){
                let redirect_url = data['redirect_url'];
                window.location.href=redirect_url;
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    submitsavedcard(card){
        var main = document.getElementById('dropincontainerMainDiv');
        main.style.display = main.style.display === 'none' ? '' : 'none';
        this.selectedCard = card;
        this.cardsubmit('saved');
    }

}
