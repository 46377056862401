<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<h3 class="fs24 wt700 txtdefault">{{ pageTitle }}</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Product Management</a>
						</li>
						<li class="breadcrumb-item active">{{ pageTitle }}</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:;" (click)="openimport(importproducts)" class="mr-sm-4 btn-d-outline d-inline-block">
					<svg xmlns="http://www.w3.org/2000/svg" width="14.681" height="14.681" viewBox="0 0 14.681 14.681" class="plus">
					  <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1 1)">
					    <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181" transform="translate(-11.659 -7.5)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					    <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181" transform="translate(-7.5 -11.659)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					  </g>
					</svg>
					Import Products
				</a>
				<a href="javascript:;" [routerLink]="['/admin/product/category/add']" class="mr-sm-4 btn-d-outline d-inline-block">
					<svg xmlns="http://www.w3.org/2000/svg" width="14.681" height="14.681" viewBox="0 0 14.681 14.681" class="plus">
					  <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1 1)">
					    <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181" transform="translate(-11.659 -7.5)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					    <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181" transform="translate(-7.5 -11.659)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					  </g>
					</svg>
					Add New Product
				</a>
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4 mb-0">
			<table class="table error-table mb-10" *ngIf="import_errors.length > 0">
				<thead>
					<tr>
						<th><b>Row No.</b></th>
						<th><b>Error Message</b></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let err of import_errors">
						<td>{{ err.row }}</td>
						<td>{{ err.error }}</td>
					</tr>
				</tbody>
			</table>
			<div class="notifications-container theme-tab">
	          	<ngb-tabset [activeId]="activeTab" [justify]="'left'" [type]="'pills'" class="tabs tab-title">
		            <ngb-tab id="productListing">
		                <ng-template ngbTabTitle><div (click)="changetab('productListing')"><a href="javascript:;"> Product Listing</a></div></ng-template>
		                <ng-template ngbTabContent>
		                  	<div class="tab-content">
		                  		<!--Filters-->
		                  		<div class="row d-flex justify-content-between mt-20 report-cta">
									<div class="col-md-4">
										<div class="report-input">
											<div class="input-group-custom">
												<input type="text" placeholder="Product Name, Brand Name & Model No." class="form-control" 
												class="form-control" [(ngModel)]="search_term" (change)="getproductlistingfilters()">
												<div class="input-group-append-custom">
													<img src="assets/images/svg/search_logo.svg">
												</div>
											</div>
										</div>
									</div>

									<div class="col-md-4 pl-0">
										<div class="report-input">
											<div class="input-group-custom">
												<div class="ng-autocomplete">
													<ng-autocomplete 
														[data]="result"
														[searchKeyword]="keyword"
														(selected)='selectEvent($event)'
														(inputChanged)='onChangeSearch($event)'
														(inputFocused)='onFocused($event)'
														[itemTemplate]="itemTemplate"
														[notFoundTemplate]="notFoundTemplate"
														placeholder="Search by Category Name"
													>
													</ng-autocomplete>
													<ng-template #itemTemplate let-item>
														<a href="javascript:void(0)">
															<div class="d-flex">
																<span [innerHTML]="item.name"></span>
																<!-- <span [innerHTML]="item.current" class="current"></span> -->
															</div>
														</a>
													</ng-template>
						
													<ng-template #notFoundTemplate let-notFound>
														<div [innerHTML]="notFound"></div>
													</ng-template>
													<div class="search-icon"><img src="assets/images/svg/search_logo.svg"></div>
												</div>
											</div>
										</div>
									</div>

									<div class="col-md-4 text-right mb-10">
										<a href="javascript:void(0);" (click)="getproductlisting('yes')" class="btn-d-outline d-inline-block place-bid" >
											<svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
											<g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
												<path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#ff7135"/>
											</g>
											</svg>					
											<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
													*ngIf="export_loading"></span>
												&nbsp;Export Report
										</a>
										<a href="{{ export_path }}"
											*ngIf="export_path"
											class="btn btn-default"
											target="_blank"
											download="Seller.csv"
											>Download Now
										</a>
									</div>	

									<div class="col-md-12">
										<div class="row d-flex justify-content-start mt-3">
											<div class="col-md-3 form-container mt-0 mb-0">
												<div class="report-input floating-label">
													<div class="input-group-custom">
														<ng-selectize [config]="config" name="market_place" [(ngModel)]="market_place"
                                    					[options]="marketplace_options" placeholder="Marketplace" (ngModelChange)="getproductlistingfilters()"></ng-selectize>
													</div>
												</div>
											</div>
											<div class="col-md-3 form-container mt-0 mb-0">
												<div class="report-input floating-label">
													<div class="input-group-custom">
														<ng-selectize [config]="config" name="listing_status" [(ngModel)]="listing_status"
                                    					[options]="listing_status_options" placeholder="Listing Status" (ngModelChange)="getproductlistingfilters()"></ng-selectize>
													</div>
												</div>
											</div>
											<div class="col-md-3 form-container mt-0 mb-0">
												<div class="report-input floating-label">
													<div class="input-group-custom">
														<input type="text" [(ngModel)]="from_date" (ngModelChange) = "getproductlistingfilters()" class="form-control" placeholder=" " ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" autocomplete="off"/>
														<span class="highlight"></span>
														<label>From Date</label>
														<div class="input-group-append-custom">
															<img src="assets/images/icons/filter-calendar.svg">
														</div>
													</div>
												</div>
											</div>
				
											<div class="col-md-3 form-container mt-0 mb-0">
												<div class="form-container m-0">
													<div class="report-input floating-label">
														<div class="input-group-custom">
															<input type="text" [(ngModel)]="to_date" (ngModelChange) = "getproductlistingfilters()" class="form-control" placeholder=" " ngbDatepicker #dd="ngbDatepicker" (click)="dd.toggle()" autocomplete="off"/>
															<span class="highlight"></span>
															<label>To Date</label>
															<div class="input-group-append-custom">
																<img src="assets/images/icons/filter-calendar.svg">
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									
								</div>
								<!--End Filters-->
		                    	<table class="table custom-table xs">
									<thead>
										<tr>
											<th>Product ID</th>
											<th width="18%">Product Name</th>
											<th width="10%">Category</th>
											<th>No. of Variants</th>
											<th>Listed On</th>
											<th>No. of Sellers</th>
											<th>Market Segment</th>
											<th width="18%">Action</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let product of productListing; let i = index;">
											<td>{{ product.product_id }}</td>
											<td>
												<div class="order-info">
													<div class="order-img">
														<img src="{{ product.image? product.image: 'assets/images/p-2.jpg' }}" />
													</div>
													<div class="pd-seller-cntnt">
														<div class="txtdefault">
															<a href="javascript:;" class="order-link">{{ product.product_name }}</a>
														</div>
													</div>
												</div>
											</td>
											<td>{{ product.category_name }}</td>
											<td>{{ product.total_variants }}</td>
											<td>{{ product.listed_on }}</td>
											<td>{{ product.no_of_sellers }}</td>
											<td [innerHtml]="product.market_segment"></td>
											<td class="d-flex align-items-center">
												<a href="javascript:void(0);" class="btn-d-outline ripple d-inline-block place-bid mr-3" [routerLink]="['/admin/view-product-details', product.uniqueId]">View Details</a>
												<a href="javascript:;" (click)="copylisting(product.uniqueId)"><img src="assets/images/icons/clone.png"></a>
											</td>
										</tr>
										
										<!--Empty State-->
										<tr *ngIf="!productListing.length">
											<td colspan="8">
												<div class="row pt-40 pb-40">
													<div class="col-md-12 text-center">
														<div class="mb-20"><img src="/assets/images/empty-state.png"></div>
														<div class="fs16">There are no records to display!</div>
													</div>
												</div>
											</td>
										</tr>
										<!--End Empty State-->
										
										
									</tbody>
								</table>
		                  	</div>
		                </ng-template>
		            </ngb-tab>	
		            <ngb-tab id="requestListing">
		                <ng-template ngbTabTitle><div (click)="changetab('requestListing')">Requested Listing</div></ng-template>
						<ng-template ngbTabContent>
							<div class="tab-content">
								<!--Filters-->
								<div class="row d-flex justify-content-between mt-20 report-cta">
									<div class="col-md-5">
										<div class="report-input">
											<div class="input-group-custom">
												<input type="text" placeholder="Search by Product Name, Brand Name & Model No." class="form-control" 
												class="form-control" [(ngModel)]="req_search_term" (change)="getrequestlistingfilters()">
												<div class="input-group-append-custom">
													<img src="assets/images/svg/search_logo.svg">
												</div>
											</div>
										</div>
									</div>

									<div class="col-md-7">
										<div class="d-flex justify-content-end">
											<div class="form-container mt-0 mb-0 mr-3">
												<div class="report-input floating-label">
													<div class="input-group-custom">
														<input type="text" [(ngModel)]="req_from_date" (ngModelChange) = "getrequestlistingfilters()" class="form-control" placeholder=" " ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" autocomplete="off"/>
														<span class="highlight"></span>
														<label>From Date</label>
														<div class="input-group-append-custom">
															<img src="assets/images/icons/filter-calendar.svg">
														</div>
													</div>
												</div>
											</div>
				
											<div class="form-container mt-0 mb-0 mr-3">
												<div class="form-container m-0">
													<div class="report-input floating-label">
														<div class="input-group-custom">
															<input type="text" [(ngModel)]="req_to_date" (ngModelChange) = "getrequestlistingfilters()" class="form-control" placeholder=" " ngbDatepicker #dd="ngbDatepicker" (click)="dd.toggle()" autocomplete="off"/>
															<span class="highlight"></span>
															<label>To Date</label>
															<div class="input-group-append-custom">
																<img src="assets/images/icons/filter-calendar.svg">
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
							  </div>
							  <!--End Filters-->
							  <table class="table custom-table xs">
								  <thead>
									  <tr>
										  <th width="18%">Product Name</th>
										  <th>Modal Name</th>
										  <th>Brand Name</th>
										  <th width="10%">Category</th>
										  <th width="10%">Sub-Category</th>
										  <th>Requested On</th>
										  <th width="25%">Action</th>
									  </tr>
								  </thead>
								  <tbody>
									  <tr *ngFor="let record of requestListing; let i = index;">
										  <td>
											  <div class="order-info">
												  <!-- <div class="order-img">
													  <img src="assets/images/p-2.jpg" />
												  </div> -->
												  <div class="pd-seller-cntnt">
													  <div class="txtdefault">
														  <a href="javascript:;" class="order-link">{{ record.product_name }}</a>
													  </div>
												  </div>
											  </div>
										  </td>
										  <td>{{ record.model_no }}</td>
										  <td>{{ record.brand_name }}</td>
										  <td>{{ record.parent_category }}</td>
										  <td>{{ record.category_name }}</td>
										  <td>{{ record.date }}</td>
										  <td class="d-flex align-items-center">
											  <a href="javascript:;" class="link-alt fs14 mr-3" [routerLink]="['/admin/view-requested-details/'+record.id]">View Details</a>
											  <button href="javascript:void(0);" class="btn-d-outline ripple d-inline-block place-bid mr-3" (click)="startrequestedlisting(record)">Start Listing</button>
											  <a href="javascript:;" (click)="open(content, record)"><img src="assets/images/icons/delete-icon.svg"></a>
										  </td>
									  </tr>

									  <!--Empty State-->
										<tr *ngIf="!requestListing.length">
											<td colspan="7">
												<div class="row pt-40 pb-40">
													<div class="col-md-12 text-center">
														<div class="mb-20"><img src="/assets/images/empty-state.png"></div>
														<div class="fs16">There are no records to display!</div>
													</div>
												</div>
											</td>
										</tr>
										<!--End Empty State-->
								  </tbody>
							  </table>
							</div>
					  </ng-template>
		            </ngb-tab>	
		            <ngb-tab id="sellerListing">
		                <ng-template ngbTabTitle><div (click)="changetab('sellerListing')">Seller Listing</div></ng-template>
						<ng-template ngbTabContent>
							<div class="tab-content">
								<!--Filters-->
								<div class="row d-flex justify-content-between mt-20 report-cta">
								  <div class="col-md-5">
									  <div class="report-input">
										  <div class="input-group-custom">
											  <input type="text" placeholder="Search by Seller Name, GSTIN, PAN No. & Email" class="form-control" 
											  class="form-control" [(ngModel)]="seller_search_term" (change)="getsellerlistingfilters()">
											  <div class="input-group-append-custom">
												  <img src="assets/images/svg/search_logo.svg">
											  </div>
										  </div>
									  </div>
								  </div>
								  	<div class="text-right mb-10">
										<a href="javascript:void(0);" (click)="getsellerlisting('yes')" class="btn-d-outline d-inline-block place-bid" >
											<svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
											<g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
												<path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#ff7135"/>
											</g>
											</svg>					
											<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
													*ngIf="seller_export_loading"></span>
												&nbsp;Export Report
										</a>
										<a href="{{ seller_export_path }}"
											*ngIf="seller_export_path"
											class="btn btn-default"
											target="_blank"
											download="Seller.csv"
											>Download Now
										</a>
									</div>
							  </div>
							  <!--End Filters-->
							  <div class="table-responsive">
								  <table class="table custom-table">
									<thead>
										<tr>
										  <th width="18%">Seller Name</th>
										  <th>Mobile</th>
										  <th>Email</th>
										  <th>GSTIN</th>
										  <th>PAN Number</th>
										  <th width="22%">Action</th>
										</tr>
									</thead>
									<tbody>
									  <!-- REPEAT -->
									  <tr *ngFor="let record of sellerListing; let k = index;">
										  <td>
											  <div class="order-info">
												  <div class="order-img">
													  <img [src]="record.logo? record.logo:'assets/images/svg/vendor.svg'" />
												  </div>
												  <div class="pd-seller-cntnt">
													  <div class="txtdefault">
														  <a href="javascript:;" class="order-link">{{ record.name }}</a>
													  </div>
												  </div>
											  </div>
										  </td>
										  <td>+91 {{ record.mobile }}</td>
										  <td>{{ record.email }}</td>
										  <td>{{ record.gstin }}</td>
										  <td>{{ record.panno }}</td>
										  <td>
											  <div class="d-flex align-items-center justify-content-between">
												  <a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3" [routerLink]="['/admin/view-seller-listing-products/'+record.id]">View Products</a>
												  <a href="javascript:;" class="link-alt fs14" [routerLink]="['/admin/manage-seller/view-details/'+record.id]">Manage Seller</a>
											  </div>
										  </td>
									  </tr>
									  <!-- /REPEAT -->
									  <!-- REPEAT -->
									  <!-- <tr>
										  <td>
											  <div class="order-info">
												  <div class="order-img">
													  <img src="assets/images/svg/vendor.svg" />
												  </div>
												  <div class="pd-seller-cntnt">
													  <div class="txtdefault">
														  <a href="javascript:;" class="order-link">ABC Technologies Private Ltd</a>
													  </div>
												  </div>
											  </div>
										  </td>
										  <td>+91 987******0</td>
										  <td>info@appariosales.com</td>
										  <td>22AABCE0000M1D7</td>
										  <td>AUTPP1234D</td>
										  <td>
											  <div class="d-flex align-items-center justify-content-between">
												  <a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Products</a>
												  <a href="javascript:;" class="link-alt fs14">Manage Seller</a>
											  </div>
										  </td>
									  </tr> -->
									  <!-- /REPEAT -->

									  <!--Empty State-->
										<tr *ngIf="!sellerListing.length">
											<td colspan="6">
												<div class="row pt-40 pb-40">
													<div class="col-md-12 text-center">
														<div class="mb-20"><img src="/assets/images/empty-state.png"></div>
														<div class="fs16">There are no records to display!</div>
													</div>
												</div>
											</td>
										</tr>
										<!--End Empty State-->
									  
									</tbody>
								  </table>
							  </div> 
							</div>
					  </ng-template>
		            </ngb-tab>										
	        	</ngb-tabset>
	    	</div>
		</div>
		<!-- Product Listing Pagination-->
		<div class="row" *ngIf="activeTab == 'productListing' && productListing.length > 0">
			<div class="col-md-6">
				<div class="text-left pl-3">
					<nav class="mt-3 tbpg rows-per-page">
						<div>Rows per page:</div>
						<select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
							<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">{{option.name}}</option>
						</select>
					</nav>
				</div> 
			</div>
			<div class="col-md-6" *ngIf="product_listing_pagination.pages && product_listing_pagination.pages.length">
				<div class="text-right pr-3">
					<nav class="mt-3 tbpg">
					  <ul class="pagination align-items-center">
						<li class="page-item" [ngClass]="{disabled:product_listing_pagination.currentPage === 1}"><a class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a></li>

						<li class="page-item" [ngClass]="{disabled:product_listing_pagination.currentPage === 1}"><a class="page-link sprevicon" href="javascript:;" (click)="setpage(product_listing_pagination.currentPage - 1)"></a></li>

						<li class="page-item"i *ngFor="let page of product_listing_pagination.pages" [ngClass]="{active:product_listing_pagination.currentPage === page}"><a class="page-link" href="javascript:;" (click)="setpage(page)">{{page}}</a></li>

						<li *ngIf="(product_listing_pagination.totalPages - product_listing_pagination.startPage) > 5 ">
							<a (click)="setpage(product_listing_pagination.startPage + 5)">...</a>
						</li>
						 <li *ngIf="(product_listing_pagination.totalPages - product_listing_pagination.startPage) > 5" >
							<a (click)="setpage(product_listing_pagination.totalPages)">{{product_listing_pagination.totalPages}}</a>
						</li>
						
						<li class="page-item" [ngClass]="{disabled:product_listing_pagination.currentPage === product_listing_pagination.totalPages}"><a class="page-link snexticon" href="javascript:;"  (click)="setpage(product_listing_pagination.currentPage + 1)"></a></li>
						<li class="page-item" [ngClass]="{disabled:product_listing_pagination.currentPage === product_listing_pagination.totalPages}"><a class="page-link nexticon" href="javascript:;" (click)="setpage(product_listing_pagination.totalPages)"></a></li>
					  </ul>
					</nav>
				</div> 
			</div>
		</div>
		<!-- Product Listing Pagination -->

		<!-- Requested Listing Pagination-->
		<div class="row" *ngIf="activeTab == 'requestListing' && requestListing.length > 0">
			<div class="col-md-6">
				<div class="text-left pl-3">
					<nav class="mt-3 tbpg rows-per-page">
						<div>Rows per page:</div>
						<select class="select-box" [(ngModel)]="req_page_per_view" (change)="setpageperview()">
							<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">{{option.name}}</option>
						</select>
					</nav>
				</div> 
			</div>
			<div class="col-md-6" *ngIf="request_listing_pagination.pages && request_listing_pagination.pages.length">
				<div class="text-right pr-3">
					<nav class="mt-3 tbpg">
					  <ul class="pagination align-items-center">
						<li class="page-item" [ngClass]="{disabled:request_listing_pagination.currentPage === 1}"><a class="page-link previcon" href="javascript:;" (click)="setreqpage(1)"></a></li>

						<li class="page-item" [ngClass]="{disabled:request_listing_pagination.currentPage === 1}"><a class="page-link sprevicon" href="javascript:;" (click)="setreqpage(request_listing_pagination.currentPage - 1)"></a></li>

						<li class="page-item"i *ngFor="let page of request_listing_pagination.pages" [ngClass]="{active:request_listing_pagination.currentPage === page}"><a class="page-link" href="javascript:;" (click)="setreqpage(page)">{{page}}</a></li>

						<li *ngIf="(request_listing_pagination.totalPages - request_listing_pagination.startPage) > 5 ">
							<a (click)="setreqpage(request_listing_pagination.startPage + 5)">...</a>
						</li>
						 <li *ngIf="(request_listing_pagination.totalPages - request_listing_pagination.startPage) > 5" >
							<a (click)="setreqpage(request_listing_pagination.totalPages)">{{request_listing_pagination.totalPages}}</a>
						</li>
						
						<li class="page-item" [ngClass]="{disabled:request_listing_pagination.currentPage === request_listing_pagination.totalPages}"><a class="page-link snexticon" href="javascript:;"  (click)="setreqpage(request_listing_pagination.currentPage + 1)"></a></li>
						<li class="page-item" [ngClass]="{disabled:request_listing_pagination.currentPage === request_listing_pagination.totalPages}"><a class="page-link nexticon" href="javascript:;" (click)="setreqpage(request_listing_pagination.totalPages)"></a></li>
					  </ul>
					</nav>
				</div> 
			</div>
		</div>
		<!-- Requested Listing Pagination -->

		<!-- Seller Listing Pagination-->
		<div class="row" *ngIf="activeTab == 'sellerListing' && sellerListing.length > 0">
			<div class="col-md-6">
				<div class="text-left pl-3">
					<nav class="mt-3 tbpg rows-per-page">
						<div>Rows per page:</div>
						<select class="select-box" [(ngModel)]="seller_page_per_view" (change)="setsellerpageperview()">
							<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">{{option.name}}</option>
						</select>
					</nav>
				</div> 
			</div>
			<div class="col-md-6" *ngIf="seller_listing_pagination.pages && seller_listing_pagination.pages.length">
				<div class="text-right pr-3">
					<nav class="mt-3 tbpg">
					  <ul class="pagination align-items-center">
						<li class="page-item" [ngClass]="{disabled:seller_listing_pagination.currentPage === 1}"><a class="page-link previcon" href="javascript:;" (click)="setsellerpage(1)"></a></li>

						<li class="page-item" [ngClass]="{disabled:seller_listing_pagination.currentPage === 1}"><a class="page-link sprevicon" href="javascript:;" (click)="setsellerpage(seller_listing_pagination.currentPage - 1)"></a></li>

						<li class="page-item"i *ngFor="let page of seller_listing_pagination.pages" [ngClass]="{active:seller_listing_pagination.currentPage === page}"><a class="page-link" href="javascript:;" (click)="setsellerpage(page)">{{page}}</a></li>

						<li *ngIf="(seller_listing_pagination.totalPages - seller_listing_pagination.startPage) > 5 ">
							<a (click)="setsellerpage(seller_listing_pagination.startPage + 5)">...</a>
						</li>
						 <li *ngIf="(seller_listing_pagination.totalPages - seller_listing_pagination.startPage) > 5" >
							<a (click)="setsellerpage(seller_listing_pagination.totalPages)">{{seller_listing_pagination.totalPages}}</a>
						</li>
						
						<li class="page-item" [ngClass]="{disabled:seller_listing_pagination.currentPage === seller_listing_pagination.totalPages}"><a class="page-link snexticon" href="javascript:;"  (click)="setsellerpage(seller_listing_pagination.currentPage + 1)"></a></li>
						<li class="page-item" [ngClass]="{disabled:seller_listing_pagination.currentPage === seller_listing_pagination.totalPages}"><a class="page-link nexticon" href="javascript:;" (click)="setsellerpage(seller_listing_pagination.totalPages)"></a></li>
					  </ul>
					</nav>
				</div> 
			</div>
		</div>
		<!-- Seller Listing Pagination -->
	</div>
</div>

<!-- Delete Request -->
<ng-template #content let-modal>
	<div class="modal-body mdlcmnui pt-4 mt-3">
        <h5 class="modal-title text-center mb-20">Delete Request</h5>

        <div class="bid-order-info">
			<div class="order-img">
				<img src="assets/images/icons/info.png" />
			</div>
			<div class="pd-seller-cntnt">
				<div class="fs12">By deleting the request, Seller will get notified that the listing has been rejected by Equibiz Team. </div>
			</div>
		</div>

		<div class="modal-subtitle text-center pt-30 pb-30">Are you sure you want to delete the requested listing?</div>

        <div class="bid-order-info">
			<!-- <div class="order-img">
				<img src="assets/images/p-2.jpg" />
			</div> -->
			<div class="pd-seller-cntnt">
				<h5 class="mb-10 fs16">{{ selected_record.product_name }}</h5>
			</div>
		</div>

        <div class="text-center pt-30 pb-20">
           <button type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')" class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4">
		      Cancel
		   	</button>
		   	<button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4" (click)="deleterequestlisting()">
		      Remove
		   	</button>
        </div>
      </div>
</ng-template>


<!-- Clone Request -->
<ng-template #contentclone let-modal>
	<div class="modal-body mdlcmnui pt-4 mt-3">
        <h5 class="modal-title text-center mb-20">Clone Product Listing</h5>

        <div class="bid-order-info">
			<div class="order-img">
				<img src="assets/images/icons/info.png" />
			</div>
			<!-- <div class="pd-seller-cntnt">
				<div class="fs12">By deleting the request, Seller will get notified that the listing has been rejected by Equibiz Team. </div>
			</div> -->
		</div>

		<div class="modal-subtitle text-center pt-30 pb-30">Are you sure you want to clone the requested listing?</div>

        <div class="bid-order-info">
			<!-- <div class="order-img">
				<img src="assets/images/p-2.jpg" />
			</div> -->
			<div class="pd-seller-cntnt">
				<h5 class="mb-10 fs16">{{ selected_record.product_name }}</h5>
			</div>
		</div>

        <div class="text-center pt-30 pb-20">
           <button type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')" class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4">
		      Cancel
		   	</button>
		   	<button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4" (click)="deleterequestlisting()">
		      Remove
		   	</button>
        </div>
      </div>
</ng-template>

<!-- ADD IMPORT PRODUCT DETAILS -->
<ng-template #importproducts let-modal>
    <div class="modal-body text-center mdlcmnui pt-4 mt-3">
       <h5 class="modal-title mb-10 ">Import Products</h5>
           <div class="bid-order-info d-flex align-items-center">
                <div class="order-img">
                    <img src="assets/images/icons/info.png" />
                </div>
				<div class="pd-seller-cntnt">
					<div class="fs12">Download sample excel here <a href="/assets/data/products-templates.xlsx">Download</a></div>
				</div>
            </div>
            <div class="form-container mdl-frm-lbl mb-1">
                <div class="row">
					<div class="col-md-12">
						<div class="floating-label mb-4">
                            <input type="file" value="" class="form-control" (change)="uploadFile($event)">
						    <span class="highlight"></span>
						    <label>Excel Sheet</label>
						</div> 
					</div>
                </div>
            </div>
           <div class="text-center nobd mx-auto pb-4 mb-3">
                <button type="button" class="btn-d-outline d-inline-block cancel-outline mr-3" (click)="modal.close('Close click')">Cancel</button>
                <button type="button" class="btn buybtn fs16 radius10" (click)="importfiles()">Import</button>
           </div>
    </div>
 </ng-template>
 <!-- ADD IMPORT PRODUCT DETAILS -->
