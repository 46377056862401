import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class DealService {

    constructor(private _http: HttpClient) { }

    getproductdetail(data) {
        return this._http.post(environment.api_url + 'product/single/view', data);
    }

    getproductsbyseller(data) {
        return this._http.post(environment.api_url + 'product/byseller', data);
    }

    addtocart(data) {
        return this._http.post(environment.api_url + 'addtocart', data);
    }

    getlogisticscharges(data) {
        return this._http.post(environment.api_url + 'admin/logistic/pricing', data);
    }

    getreviews(data) {
        return this._http.post(environment.api_url + 'product/rating/list', data);
    }

    getcomboproducts(data) {
        return this._http.post(environment.api_url + 'product/combo/list', data);
    }

}
