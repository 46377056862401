<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain mgwrpmain">
    <div class="container">
        <!--- HEAD  --->
        <div class="row align-items-center mb-4 pinfo-head mgb-sm-ui-rw">
            <div class="col-md-6 ">
                <h3 class="fs28 txtdefault wt700">Manage C&F</h3>
                <nav class="breadcrumb breadcrumb-dui mb-0">
                    <ol class="breadcrumb p-0 bg-transparent  mb-0">
                        <li class="breadcrumb-item">
                            <a class="txtseconday" href="javascript:void(0);">User Management</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a class="txtseconday" href="javascript:void(0);">C & F</a>
                        </li>
                        <li class="breadcrumb-item active">Manage C&F</li>
                    </ol>
                </nav>
            </div>
            <div class="col-md-6 text-right sm-ui-rw">
                <a href="javascript:void(0);" class="btn buybtn fs16 radius10 ml-3">Assistance</a>
            </div>
        </div>
        <!--- /HEAD  --->
        <div class="us-tab">
            <nav ngbNav #nav="ngbNav" class="nav-tabs">
                <ng-container ngbNavItem>
                    <a ngbNavLink>Manage C&F</a>
                    <ng-template ngbNavContent>
                        <div class="card card-ui card-uiovt">
                            <!--Filters-->
                            <div class="row d-flex justify-content-between mt-20 report-cta">
                                <div class="col-md-5">
                                    <div class="report-input">
                                        <div class="input-group-custom">
                                            <input type="text" placeholder="Search by Seller Name, GSTIN, PAN No. & Email" class="form-control" [(ngModel)]="search_term" (change)="getcandfsellers()">
                                            <div class="input-group-append-custom">
                                                <img src="assets/images/svg/search_logo.svg">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="col-md-7">
                                    <div class="d-flex justify-content-end">
                                        <div class="mr-3">
                                            <a href="javascript:void(0);"
                                                class="d-inline-block fltr-round-btn radius10 txtseconday">
                                                <img src="assets/images/svg/filter.svg">
                                                Filters
                                            </a>
                                        </div>
                                        <div class="form-container mt-0 mb-0 mr-3">
                                            <div class="report-input floating-label">
                                                <div class="input-group-custom">
                                                    <input type="text" value="Last 30 Days" class="form-control"
                                                        placeholder=" " />
                                                    <span class="highlight"></span>
                                                    <label>Date</label>
                                                    <div class="input-group-append-custom">
                                                        <img src="assets/images/icons/filter-calendar.svg">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <a href="javascript:void(0);" class="btn-d-outline d-inline-block">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12.681" height="17.061"
                                                    viewBox="0 0 12.681 17.061">
                                                    <g id="np_export_2535025_000000"
                                                        transform="translate(-32.121 -25.962)">
                                                        <path id="Path_2355" data-name="Path 2355"
                                                            d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z"
                                                            transform="translate(-0.003)" fill="#ff7135" />
                                                        <path id="Path_2356" data-name="Path 2356"
                                                            d="M44.093,70.07H32.83a.709.709,0,1,0,0,1.419H44.093a.709.709,0,0,0,0-1.419Z"
                                                            transform="translate(0 -28.466)" fill="#ff7135" />
                                                    </g>
                                                </svg>
                                                Export
                                            </a>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <!--End Filters-->

                            <div class="table-responsive">
                                <table class="table custom-table xs">
                                    <thead>
                                        <tr>
                                            <th width="15%">C&F Name</th>
                                            <th>Mobile</th>
                                            <th>Email</th>
                                            <th>GSTIN</th>
                                            <th>PAN Number</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!--Empty State-->
                                        <tr *ngIf="!candfseller_records.length">
                                            <td colspan="7">
                                                <div class="row pt-40 pb-40">
                                                    <div class="col-md-12 text-center">
                                                        <div class="mb-20"><img src="/assets/images/empty-state.png">
                                                        </div>
                                                        <div class="fs16">There are no records to display!</div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <!--End Empty State-->
                                        <!-- TR -->
                                        <tr *ngFor="let record of candfseller_records;">
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <div class="mr-2"><img
                                                            src="{{ record.logo?record.logo:'assets/images/icons/sellerinfo.svg' }}">
                                                    </div>
                                                    <div>{{ record.candfname }}</div>
                                                </div>
                                            </td>
                                            <td>{{ record.mobile }}</td>
                                            <td>{{ record.email }}</td>
                                            <td>{{ record.gst }}</td>
                                            <td>{{ record.pannumber }}</td>
                                            <td>
                                                <span class="text-active"
                                                    *ngIf="record.status == 'active'">Active</span>
                                                <span class="text-inactive"
                                                    *ngIf="record.status == 'inactive'">Inactive</span>
                                            </td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <a href="javascript:void(0);"
                                                        class="btn-d-outline ripple d-inline-block place-bid mr-3"
                                                        [routerLink]="['/admin/view-sub-sellers/'+record.id]">
                                                        View Sub-Sellers
                                                    </a>
                                                    <a href="javascript:;" class="link-alt fs14"
                                                        [routerLink]="['/admin/view-canf-requests/'+record.id]">View
                                                        Details</a>
                                                </div>
                                            </td>
                                        </tr>
                                        <!-- /TR -->


                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div class="row mb-40" *ngIf="candfseller_pagination.totalPages> 1">
                            <div class="col-md-6">
                                <div class="text-left pl-3">
                                    <nav class="mt-3 tbpg rows-per-page">
                                        <div>Rows per page:</div>
                                        <select class="select-box" [(ngModel)]="page_per_view"
                                            (change)="setpageperview()">
                                            <option *ngFor="let option of page_per_view_options"
                                                [ngValue]="option.value">
                                                {{option.name}}</option>
                                        </select>
                                    </nav>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="text-right pr-3">
                                    <nav class="mt-3 tbpg">
                                        <ul class="pagination align-items-center">
                                            <li class="page-item"
                                                [ngClass]="{disabled:candfseller_pagination.currentPage === 1}"><a
                                                    class="page-link previcon" href="javascript:;"
                                                    (click)="setpage(1)"></a>
                                            </li>

                                            <li class="page-item"
                                                [ngClass]="{disabled:candfseller_pagination.currentPage === 1}"><a
                                                    class="page-link sprevicon" href="javascript:;"
                                                    (click)="setpage(candfseller_pagination.currentPage - 1)"></a></li>

                                            <li class="page-item" i *ngFor="let page of candfseller_pagination.pages"
                                                [ngClass]="{active:candfseller_pagination.currentPage === page}"><a
                                                    class="page-link" href="javascript:;"
                                                    (click)="setpage(page)">{{page}}</a></li>

                                            <li
                                                *ngIf="(candfseller_pagination.totalPages - candfseller_pagination.startPage) > 5 ">
                                                <a (click)="setpage(candfseller_pagination.startPage + 5)">...</a>
                                            </li>
                                            <li
                                                *ngIf="(candfseller_pagination.totalPages - candfseller_pagination.startPage) > 5">
                                                <a
                                                    (click)="setpage(candfseller_pagination.totalPages)">{{candfseller_pagination.totalPages}}</a>
                                            </li>

                                            <li class="page-item"
                                                [ngClass]="{disabled:candfseller_pagination.currentPage === candfseller_pagination.totalPages}">
                                                <a class="page-link snexticon" href="javascript:;"
                                                    (click)="setpage(candfseller_pagination.currentPage + 1)"></a>
                                            </li>
                                            <li class="page-item"
                                                [ngClass]="{disabled:candfseller_pagination.currentPage === candfseller_pagination.totalPages}">
                                                <a class="page-link nexticon" href="javascript:;"
                                                    (click)="setpage(candfseller_pagination.totalPages)"></a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
                <ng-container ngbNavItem>
                    <a ngbNavLink>C&F Requests</a>
                    <ng-template ngbNavContent>
                        <div class="card card-ui card-uiovt byregwrp">
                            <!--Filters-->
                            <div class="row d-flex justify-content-between mt-20 report-cta">
                                <div class="col-md-5">
                                    <div class="report-input">
                                        <div class="input-group-custom">
                                            <input type="text" placeholder="Search by Seller Name, GSTIN, PAN No. & Email" class="form-control" [(ngModel)]="r_search_term" (change)="getcandfrequest()">
                                            <div class="input-group-append-custom">
                                                <img src="assets/images/svg/search_logo.svg">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="col-md-7">
                                    <div class="d-flex justify-content-end">
                                        <div class="mr-3">
                                            <a href="javascript:void(0);"
                                                class="d-inline-block fltr-round-btn radius10 txtseconday">
                                                <img src="assets/images/svg/filter.svg">
                                                Filters
                                            </a>
                                        </div>
                                        <div class="form-container mt-0 mb-0 mr-3">
                                            <div class="report-input floating-label">
                                                <div class="input-group-custom">
                                                    <input type="text" value="Last 30 Days" class="form-control"
                                                        placeholder=" " />
                                                    <span class="highlight"></span>
                                                    <label>Date</label>
                                                    <div class="input-group-append-custom">
                                                        <img src="assets/images/icons/filter-calendar.svg">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <a href="javascript:void(0);" class="btn-d-outline d-inline-block">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12.681" height="17.061"
                                                    viewBox="0 0 12.681 17.061">
                                                    <g id="np_export_2535025_000000"
                                                        transform="translate(-32.121 -25.962)">
                                                        <path id="Path_2355" data-name="Path 2355"
                                                            d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z"
                                                            transform="translate(-0.003)" fill="#ff7135" />
                                                        <path id="Path_2356" data-name="Path 2356"
                                                            d="M44.093,70.07H32.83a.709.709,0,1,0,0,1.419H44.093a.709.709,0,0,0,0-1.419Z"
                                                            transform="translate(0 -28.466)" fill="#ff7135" />
                                                    </g>
                                                </svg>
                                                Export
                                            </a>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <!--End Filters-->
                            <div class="table-responsive">
                                <table class="table custom-table xs">
                                    <thead>
                                        <tr>
                                            <th width="15%">C&F Name</th>
                                            <th width="15%">Ratings</th>
                                            <th width="15%">Categories</th>
                                            <th>No. of SKUs</th>
                                            <th>Listing Available</th>
                                            <th>Location</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!--Empty State-->
                                        <tr *ngIf="candfrequest_records.length == 0">
                                            <td colspan="7">
                                                <div class="row pt-40 pb-40">
                                                    <div class="col-md-12 text-center">
                                                        <div class="mb-20"><img src="/assets/images/empty-state.png">
                                                        </div>
                                                        <div class="fs16">There are no records to display!</div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <!--End Empty State-->
                                        <tr *ngFor="let record of candfrequest_records">
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <div class="mr-2"><img
                                                            src="{{ record.logo?record.logo:'assets/images/icons/sellerinfo.svg' }}">
                                                    </div>
                                                    <div>{{ record.candfname }}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="ratings star-ratings pr-1 d-inline-block">
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star-o"></i>

                                                    <i placement="top" ngbTooltip="Tooltip content">
                                                        <img src="assets/images/icons/info.png">
                                                    </i>
                                                </div>
                                            </td>
                                            <td [innerHtml]="record.categories"></td>
                                            <td>{{ record.sku }}</td>
                                            <td [innerHtml]="record.listing_available"></td>
                                            <td [innerHtml]="record.locations"></td>
                                            <td>
                                                <div class="d-flex">
                                                    <a href="javascript:void(0);"
                                                        class="btn-d-outline ripple d-inline-block place-bid mr-3"
                                                        [routerLink]="['/admin/view-canf-requests/'+record.id]">
                                                        View Details
                                                    </a>
                                                    <div class="mgb-action-wrp" style="display: none;">
                                                        <div ngbDropdown class="d-inline-block mgb-action-in">
                                                            <div class="mgb-action" id="dropdownBasic1"
                                                                ngbDropdownToggle>
                                                                <span></span>
                                                                <span></span>
                                                                <span></span>
                                                            </div>

                                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                                <button ngbDropdownItem>Activate</button>
                                                                <button ngbDropdownItem>Deactivate</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="row mb-40" *ngIf="candfrequest_pagination.totalPages> 1">
                                <div class="col-md-6">
                                    <div class="text-left pl-3">
                                        <nav class="mt-3 tbpg rows-per-page">
                                            <div>Rows per page:</div>
                                            <select class="select-box" [(ngModel)]="rpage_per_view"
                                                (change)="setrpageperview()">
                                                <option *ngFor="let option of page_per_view_options"
                                                    [ngValue]="option.value">
                                                    {{option.name}}</option>
                                            </select>
                                        </nav>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="text-right pr-3">
                                        <nav class="mt-3 tbpg">
                                            <ul class="pagination align-items-center">
                                                <li class="page-item"
                                                    [ngClass]="{disabled:candfrequest_pagination.currentPage === 1}"><a
                                                        class="page-link previcon" href="javascript:;"
                                                        (click)="setrpage(1)"></a>
                                                </li>

                                                <li class="page-item"
                                                    [ngClass]="{disabled:candfrequest_pagination.currentPage === 1}"><a
                                                        class="page-link sprevicon" href="javascript:;"
                                                        (click)="setrpage(candfrequest_pagination.currentPage - 1)"></a>
                                                </li>

                                                <li class="page-item" i
                                                    *ngFor="let page of candfrequest_pagination.pages"
                                                    [ngClass]="{active:candfrequest_pagination.currentPage === page}"><a
                                                        class="page-link" href="javascript:;"
                                                        (click)="setrpage(page)">{{page}}</a></li>

                                                <li
                                                    *ngIf="(candfrequest_pagination.totalPages - candfrequest_pagination.startPage) > 5 ">
                                                    <a (click)="setrpage(candfrequest_pagination.startPage + 5)">...</a>
                                                </li>
                                                <li
                                                    *ngIf="(candfrequest_pagination.totalPages - candfrequest_pagination.startPage) > 5">
                                                    <a
                                                        (click)="setrpage(candfrequest_pagination.totalPages)">{{candfrequest_pagination.totalPages}}</a>
                                                </li>

                                                <li class="page-item"
                                                    [ngClass]="{disabled:candfrequest_pagination.currentPage === candfrequest_pagination.totalPages}">
                                                    <a class="page-link snexticon" href="javascript:;"
                                                        (click)="setrpage(candfrequest_pagination.currentPage + 1)"></a>
                                                </li>
                                                <li class="page-item"
                                                    [ngClass]="{disabled:candfrequest_pagination.currentPage === candfrequest_pagination.totalPages}">
                                                    <a class="page-link nexticon" href="javascript:;"
                                                        (click)="setrpage(candfrequest_pagination.totalPages)"></a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>

            </nav>

            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
    </div>
</div>