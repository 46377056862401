import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BNPLChargesService {

    constructor(private _http: HttpClient) { }

    getCharges(data) {
        return this._http.post(environment.api_url+'admin/charges/list',data);
    }

    saveCharges(data) {
        return this._http.post(environment.api_url+'admin/charges/add',data);
    }

}
