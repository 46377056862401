<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="">
    <div class="equibiz-header">
      <div class="logo-bar">
        <div class="container">
          <div class="row d-flex justify-content-between">
             <div class="col-md-3 d-flex align-items-center">
                <a [routerLink]="['/home']"><img src="assets/images/svg/eq_logo.svg"></a>
             </div>
             <div class="col-md-9">
             	<div class="text-right right-header">
             		<ul class="header-dropdown">
					  <li class="menu-item"><a href="javascript:;" [routerLink]="['/how-it-works']" class="active">Home</a></li>
					  <li class="menu-item"><a href="javascript:;" [routerLink]="['/about-us']">About Us</a></li>
					  <li class="menu-item"><a href="javascript:;" [routerLink]="['/services']">Services</a></li>
					  <li class="menu-item"><a href="javascript:;" [routerLink]="['/partners']">Partners</a></li>
					</ul>
             	</div>
             </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<section class="page-section pt-50 bg-white">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="seller-banner-container" style="background-image: url('assets/images/how-it-works.jpg');">
					<div class="row">
						<div class="col-md-5 text-left">
							<h3 class="hs-line-6 font-alt mb-20">
								Connecting Businesses.<br>Ensuring Profits.<br>Spreading Happiness
							</h3>
							<div class="section-text-xs text-left mb-30">
								Welcome to India's premium B2B trading platform and marketplace.<br>Welcome to Equibiz.<br>A unique B2B platform offering price discovery and price negotiation.
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-7 text-left">
							<div class="how-it-work-video">
								<div class="fs14 text-white mb-10 f-700">How to start selling on Equibiz?</div>
								<div class="d-flex align-items-center">
									<img src="assets/images/icons/play-video.png" class="mr-3">
									<div class="fs14 text-white">Watch Video</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="page-section bg-light-blue">
	<div class="container">
		<div class="row d-flex justify-content-center">
			<div class="col-md-12">
				<h2 class="hs-line-7 text-center font-alt mb-40">What is Equibiz & how is it different?</h2>
			</div>
		</div>

		<div class="row d-flex justify-content-center">
			<div class="col-md-8 text-center">
				<p><b>The clue is in the name. Equal opportunities for all Businesses. Big or small</b><br>
				Yes, we do not differentiate.<br>
				Seller, Buyer, Supplier. Wholesale Deals. Bulk Deals. Everything has a place here at Equibiz.</p>
				<p>Equibiz provides a level playing field for both buyer and seller and help create business opportunities to maximising  profits by adopting the best trade practices. It is a one stop solution for trusted business deals in the digital world. A place where you can deal with complete trust and without any hesitations.</p>
				<p>Equibiz’s goal is to overhaul the way B2B dealings happen in the online world by overcoming the limitations of today's market.</p>
			</div>
		</div>

		<div class="row mt-40">
			<div class="col-md-4">
				<div class="card-alt mb-20">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="45" height="37.5" viewBox="0 0 45 37.5">
					  <defs>
					    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
					      <stop offset="0" stop-color="#7fbbff"/>
					      <stop offset="1" stop-color="#0278ff"/>
					    </linearGradient>
					  </defs>
					  <g id="briefcase" transform="translate(0.001 0.001)">
					    <g id="Group_16807" data-name="Group 16807" transform="translate(0 0)">
					      <g id="Group_16806" data-name="Group 16806">
					        <path id="Union_6" data-name="Union 6" d="M10514.75,1483.249a3.755,3.755,0,0,1-3.752-3.749v-13.148a.938.938,0,0,1,1.5-.747,3.7,3.7,0,0,0,2.247.771h15v2.811a.936.936,0,0,0,.938.938h5.624a.938.938,0,0,0,.938-.938v-2.811h15a3.716,3.716,0,0,0,2.247-.771.936.936,0,0,1,1.5.747V1479.5a3.754,3.754,0,0,1-3.749,3.749Zm22.5-18.748v-.938a.938.938,0,0,0-.937-.938h-5.624a.936.936,0,0,0-.937.938v.938h-15a3.755,3.755,0,0,1-3.752-3.751v-5.624a3.753,3.753,0,0,1,3.752-3.749h9.375V1449.5a3.753,3.753,0,0,1,3.749-3.751h11.251a3.755,3.755,0,0,1,3.749,3.751v1.876h9.375a3.752,3.752,0,0,1,3.749,3.749v5.624a3.755,3.755,0,0,1-3.749,3.751Zm-9.375-13.124h11.251V1449.5h-11.251Z" transform="translate(-10510.999 -1445.75)" fill="url(#linear-gradient)"/>
					      </g>
					    </g>
					  </g>
					</svg>
					<h4>Organized Marketplace</h4>
					<p>End to end digital solutions for seamless transaction fulfilment, with secured payment mechanism and efficient logistic support</p>
				</div>
			</div>
			<div class="col-md-4">
				<div class="card-alt mb-20">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="46" viewBox="0 0 32 46">
					  <defs>
					    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
					      <stop offset="0" stop-color="#7fbbff"/>
					      <stop offset="1" stop-color="#0278ff"/>
					    </linearGradient>
					  </defs>
					  <path id="Shape" d="M0,30.218a29.731,29.731,0,0,1,2.384-11.1A52.181,52.181,0,0,1,7.547,9.622,66.028,66.028,0,0,1,15.035.4,1.378,1.378,0,0,1,16.523.1a1.348,1.348,0,0,1,.843,1.245v11.3a3.024,3.024,0,0,0,6.048,0,1.35,1.35,0,0,1,1-1.3,1.373,1.373,0,0,1,1.536.6C29.965,18.5,32,24.647,32,30.218A15.911,15.911,0,0,1,16,46,15.911,15.911,0,0,1,0,30.218Z" transform="translate(0 0)" fill="url(#linear-gradient)"/>
					</svg>
					<h4>Optimum business performance</h4>
					<p>Increase turnover and reduce costs by selling products to buyers across different segments without incurring additional marketing expenses.</p>
				</div>
			</div>
			<div class="col-md-4">
				<div class="card-alt mb-20">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="37.945" height="35.929" viewBox="0 0 37.945 35.929">
					  <defs>
					    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
					      <stop offset="0" stop-color="#7fbbff"/>
					      <stop offset="1" stop-color="#0278ff"/>
					    </linearGradient>
					  </defs>
					  <g id="Layer_2" data-name="Layer 2" transform="translate(-1 -1.804)">
					    <path id="Path_9671" data-name="Path 9671" d="M36.681,14.5a4.958,4.958,0,0,1-3.908-6.7,2.327,2.327,0,0,0-.7-2.618,18.656,18.656,0,0,0-5.565-3.225,2.34,2.34,0,0,0-2.643.734,4.945,4.945,0,0,1-3.9,1.9,4.945,4.945,0,0,1-3.9-1.9,2.34,2.34,0,0,0-2.643-.734A18.745,18.745,0,0,0,8.247,4.86a2.353,2.353,0,0,0-.734,2.694A4.933,4.933,0,0,1,3.4,14.333a2.34,2.34,0,0,0-2.049,1.885A17.885,17.885,0,0,0,1,19.759a18.112,18.112,0,0,0,.24,2.972A2.34,2.34,0,0,0,3.3,24.692a4.933,4.933,0,0,1,4.022,6.969,2.3,2.3,0,0,0,.645,2.757,18.8,18.8,0,0,0,5.515,3.175,2.53,2.53,0,0,0,.8.139,2.327,2.327,0,0,0,1.9-.987,4.895,4.895,0,0,1,4.047-2.125,4.958,4.958,0,0,1,3.972,2,2.327,2.327,0,0,0,2.732.772,18.972,18.972,0,0,0,5.059-3.023,2.34,2.34,0,0,0,.683-2.669,4.933,4.933,0,0,1,3.959-6.817,2.34,2.34,0,0,0,1.986-1.923,18.34,18.34,0,0,0,.329-3.2,18.16,18.16,0,0,0-.317-3.381A2.315,2.315,0,0,0,36.681,14.5ZM26.3,19.759a6.324,6.324,0,1,1-6.324-6.324A6.324,6.324,0,0,1,26.3,19.759Z" fill="url(#linear-gradient)"/>
					  </g>
					</svg>
					<h4>Seamless Process</h4>
					<p>Buyers and suppliers connect directly, reducing communication gaps thus encouraging transparent trade.</p>
				</div>
			</div>
			<div class="col-md-4">
				<div class="card-alt mb-20">
					<svg id="bitcoin" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="34.32" height="45.055" viewBox="0 0 34.32 45.055">
					  <defs>
					    <linearGradient id="linear-gradient" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox">
					      <stop offset="0" stop-color="#0479ff"/>
					      <stop offset="1" stop-color="#7ebbff"/>
					    </linearGradient>
					  </defs>
					  <path id="Path" d="M33.418,5.348,17.578.068a1.323,1.323,0,0,0-.835,0L.9,5.348A1.319,1.319,0,0,0,0,6.6V21.758A24.454,24.454,0,0,0,16.742,44.987a1.31,1.31,0,0,0,.835,0A24.454,24.454,0,0,0,34.32,21.758V6.6A1.319,1.319,0,0,0,33.418,5.348Z" fill="url(#linear-gradient)"/>
					  <path id="Combined_Shape" data-name="Combined Shape" d="M0,14.25A2.25,2.25,0,1,1,2.25,16.5,2.253,2.253,0,0,1,0,14.25Zm0-6v-6a2.25,2.25,0,0,1,4.5,0v6a2.25,2.25,0,0,1-4.5,0Z" transform="translate(15 14)" fill="#e9f3ff"/>
					</svg>
					<h4>No Default Risk</h4>
					<p>Equibiz protects Buyers’ money and Suppliers’ goods at every level of transaction and in every possible scenario.</p>
				</div>
			</div>
			<div class="col-md-4">
				<div class="card-alt mb-20">
					<svg id="Group_31" data-name="Group 31" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="47" height="35" viewBox="0 0 47 35">
					  <defs>
					    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
					      <stop offset="0" stop-color="#7cbaff"/>
					      <stop offset="1" stop-color="#067aff"/>
					    </linearGradient>
					  </defs>
					  <path id="Shape" d="M29.909,28.437H17.091A6.492,6.492,0,0,1,10.682,35a6.492,6.492,0,0,1-6.409-6.563H0V4.375A4.323,4.323,0,0,1,4.273,0H34.182V8.75h6.409L47,17.5V28.437H42.728a6.411,6.411,0,1,1-12.819,0Z" fill="url(#linear-gradient)"/>
					  <path id="Shape-2" data-name="Shape" d="M6.552,11.837c-.571-.48-1.108-.92-1.628-1.345C1.959,8.066,0,6.462,0,4.021A3.878,3.878,0,0,1,3.85,0,3.717,3.717,0,0,1,7,1.767,3.716,3.716,0,0,1,10.15,0,3.879,3.879,0,0,1,14,4.021c0,2.441-1.959,4.045-4.925,6.471-.519.425-1.057.865-1.627,1.345a.7.7,0,0,1-.9,0Z" transform="translate(10 8)" fill="#e9f3ff" stroke="#e9f3ff" stroke-miterlimit="10" stroke-width="0.84"/>
					</svg>

					<h4>Hassle-free Logistics</h4>
					<p>Efficient and cost-effective logistics support for buyers and sellers, facilitated by basic quality check of goods before transport.</p>
				</div>
			</div>
			<div class="col-md-4">
				<div class="card-alt mb-20">
					<svg id="Group_21" data-name="Group 21" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="37" height="43" viewBox="0 0 37 43">
					  <defs>
					    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
					      <stop offset="0" stop-color="#7fbbff"/>
					      <stop offset="1" stop-color="#0278ff"/>
					    </linearGradient>
					  </defs>
					  <path id="Fill_1" data-name="Fill 1" d="M27.592,20.916V1.87A1.826,1.826,0,0,0,25.771,0H10.323A1.826,1.826,0,0,0,8.5,1.87V20.916H1.889a1.942,1.942,0,0,0-1.509,3.1l16.157,18.2a1.841,1.841,0,0,0,3.019,0l17.09-18.244a1.934,1.934,0,0,0-1.509-3.054Z" transform="translate(0)" fill="url(#linear-gradient)"/>
					  <path id="Path" d="M7.63,3.486H8.992a1,1,0,1,1,0,1.993H7.63A4.875,4.875,0,0,1,3.545,8.766L7.427,12.3h0a.99.99,0,0,1,.05,1.4.913.913,0,0,1-.757.3,1.21,1.21,0,0,1-.706-.248L.32,8.368A1.082,1.082,0,0,1,.067,7.223a1.055,1.055,0,0,1,.957-.747h2.27a2.985,2.985,0,0,0,2.218-1H1.025a1,1,0,1,1,0-1.993H5.512A2.316,2.316,0,0,0,3.295,1.993H1.025A1,1,0,0,1,.017,1,1,1,0,0,1,1.025,0H8.992A1,1,0,0,1,10,1a1,1,0,0,1-1.008,1H7.026a9.28,9.28,0,0,1,.6,1.493Z" transform="translate(13 16)" fill="#ffeae0"/>
					</svg>
					<h4>Reduce the Cost. Increase the Margin</h4>
					<p>The unique Price discovery model enables the trader increase margin and lower charges options with multiple fulfillments centres across India.</p>
				</div>
			</div>
		</div>

		<div class="row d-flex justify-content-center mt-30">
			<div class="col-md-12">
				<div class="text-center">
					<div class="fs30 f-500 dark-text opacity-60">"Just focus on your business. Rest we will take care"</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="page-section bg-white">
	<div class="container">
		<div class="row d-flex justify-content-center">
			<div class="col-md-12">
				<h2 class="hs-line-7 text-center font-alt mb-30">How can I Join</h2>
			</div>
		</div>

		<div class="row d-flex justify-content-center">
			<div class="col-md-8 text-center">
				<p>this booming platform and make my business a <b>Happy Business?</b></p>
			</div>
		</div>

		<div class="row d-flex justify-content-center mt-20">
			<div class="col-md-4">
				<div class="card-new-alt">
					<b>First-time trader?</b>
					<br>Here’s how to get Equibiz to work for you
				</div>
			</div>
			<div class="col-md-4">
				<div class="card-new-alt">
					<b>Register as a buyer/seller</b><br>
					Sign up to login your account and register before initiating a trade.
				</div>
			</div>
			<div class="col-md-4">
				<div class="card-new-alt">
					<b>Want to start a trade?</b><br>
					Complete your business registration in a few simple steps<br>
					Select your products if you are a buyer<br>
					List your products if you are a seller
				</div>
			</div>
		</div>

		<div class="row mt-30">
			<div class="col-md-6">
				<div class="card-border">
					<div class="d-flex align-items-center mb-20">
						<img src="assets/images/seller.svg" class="mr-3">
						<div class="fs14 f-700">Benefits as a Seller</div>
					</div>

					<ul>
						<li>Launch a new product or expand the existing business and increase your market share</li>
						<li>Use the large base of dealers and retailers over the platform to reach out the end user</li>
						<li>Focus on your core business without spending on supply chain network while reducing infrastructure cost like warehouses, etc</li>
						<li>Gain Higher sales volume and avail advantage of liquidating stocked inventory</li>
						<li>Hassle free pickup and delivery to the buyer</li>
					</ul>
				</div>
			</div>

			<div class="col-md-6">
				<div class="card-border">
					<div class="d-flex align-items-center mb-20">
						<img src="assets/images/buyer.svg" class="mr-3">
						<div class="fs14 f-700">Benefits as a Buyer</div>
					</div>

					<ul>
						<li>Get access to verified and trustworthy suppliers across the country</li>
						<li>Explore and obtain best prices through price discovery mechanism</li>
						<li>A Comprehensive one stop shop buying solution for all requirements</li>
						<li>Instant access to latest and trendy products</li>
						<li>Pay only token amount and book your order for Bulk Deals</li>
						<li>QC is performed by Equibiz before the goods get picked up in case of Bulk Deals</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="page-section bg-orange">
	<div class="container">
		<div class="row d-flex justify-content-center">
			<div class="col-md-12 text-center">
				<h2 class="hs-line-7 font-alt mb-30">How it works</h2>

				<div class="row seller-testi-carousel">
			      	<div class="col-md-12">
			      		<owl-carousel-o class="product-m" [options]="ProductSliderConfig">
			      			<ng-template carouselSlide>
								<div class="how-it-works-card">
									<div class="icon">
										<img src="assets/images/icons/how-01.png">
									</div>
									<div class="fs18 f-700 mb-10 text-dark">Compare, Select and Book/ Pre Book deal by paying a small token amount as a sign of trust</div>
									<div class="description">
										In case of bulk orders, the buyer can place orders with the seller by paying a token amount or full value of the deal amount.
									</div>
								</div>
							</ng-template> 
							<ng-template carouselSlide>
								<div class="how-it-works-card">
									<div class="icon">
										<img src="assets/images/icons/how-02.png">
									</div>
									<div class="fs18 f-700 mb-10 text-dark">Seller’s confirmation- An easy way to close bulk deals</div>
									<div class="description">
										The seller accepts pre booking request, the buyer transfers the balance payment. Once the stock gets ready to dispatch, pick up of goods is arranged by Equibiz.
									</div>
								</div>
							</ng-template> 	
							<ng-template carouselSlide>
								<div class="how-it-works-card">
									<div class="icon">
										<img src="assets/images/icons/how-03.png">
									</div>
									<div class="fs18 f-700 mb-10 text-dark">Dispatch to Buyer</div>
									<div class="description">
										Goods are then dispatched to buyer’s preferred place of delivery in his chosen mode of transportation
									</div>
								</div>
							</ng-template> 	
							<ng-template carouselSlide>
								<div class="how-it-works-card">
									<div class="icon">
										<img src="assets/images/icons/how-04.png">
									</div>
									<div class="fs18 f-700 mb-10 text-dark">Confirm with Equibiz</div>
									<div class="description">
										Buyer receives goods and confirms the same with Equibiz
									</div>
								</div>
							</ng-template> 
							<ng-template carouselSlide>
								<div class="how-it-works-card">
									<div class="icon">
										<img src="assets/images/icons/how-05.png">
									</div>
									<div class="fs18 f-700 mb-10 text-dark">Equibiz transfers payment</div>
									<div class="description">
										Equibiz transfers payment to the seller, concluding the transaction
									</div>
								</div>
							</ng-template> 
		        		</owl-carousel-o>
			      	</div>
			    </div>
			</div>
		</div>
	</div>
</section>

<section class="page-section bg-white">
	<div class="container">
		<div class="row d-flex justify-content-center">
			<div class="col-md-12">
				<h2 class="hs-line-7 text-center font-alt mb-30">Tell Me More</h2>
			</div>
		</div>
		<div class="row seller-testi-carousel">
	      	<div class="col-md-12">
	      		<owl-carousel-o class="product-m" [options]="ProductSliderConfig">
	      			<ng-template carouselSlide>
						<div class="how-it-works-card border">
							<div class="icon">
								<img src="assets/images/icons/how-06.png">
							</div>
							<div class="fs18 f-700 mb-10 text-dark">Price Discovery Model</div>
							<div class="description">
								Equibiz aggregates demand and supply over the platform, and facilitates buyers and sellers to initiate transactions based on their agreed price and deal terms.
							</div>
						</div>
					</ng-template> 
					<ng-template carouselSlide>
						<div class="how-it-works-card border">
							<div class="icon">
								<img src="assets/images/icons/how-07.png">
							</div>
							<div class="fs18 f-700 mb-10 text-dark">Secured Payment and Settlement System</div>
							<div class="description">
								Equibiz acts as a custodian of money and goods, ensuring minimal risk to the partners.
							</div>
						</div>
					</ng-template> 	
					<ng-template carouselSlide>
						<div class="how-it-works-card border">
							<div class="icon">
								<img src="assets/images/icons/how-08.png">
							</div>
							<div class="fs18 f-700 mb-10 text-dark">Genuine Buyers and Sellers</div>
							<div class="description">
								Mandatory KYC to ensure you only deal with verified B2B partners.
							</div>
						</div>
					</ng-template> 	
					<ng-template carouselSlide>
						<div class="how-it-works-card border">
							<div class="icon">
								<img src="assets/images/icons/how-09.png">
							</div>
							<div class="fs18 f-700 mb-10 text-dark">Round the Clock Customer Support</div>
							<div class="description">
								We always listen to you. Approach our fantastic customer support with your queries and they will be readily available to serve you.
							</div>
						</div>
					</ng-template>
					<ng-template carouselSlide>
						<div class="how-it-works-card border">
							<div class="icon">
								<img src="assets/images/icons/how-10.png">
							</div>
							<div class="fs18 f-700 mb-10 text-dark">Transparent Policies, Processes and Charges</div>
							<div class="description">
								Transparent policies and processes ensure protection of interest of all our partners.
							</div>
						</div>
					</ng-template>
					<ng-template carouselSlide>
						<div class="how-it-works-card border">
							<div class="icon">
								<img src="assets/images/icons/how-11.png">
							</div>
							<div class="fs18 f-700 mb-10 text-dark">Partner Brands</div>
							<div class="description">
								Buyers and sellers can choose products from a variety of global and indigenous brands available in the market. This will increase their reach, boost sales, and maximise stock value
							</div>
						</div>
					</ng-template>
        		</owl-carousel-o>
	      	</div>
	    </div>
	</div>
</section>

<section class="page-section bg-light-blue">
	<div class="container">
		<h2 class="hs-line-7 text-center font-alt mb-10">What our customers say</h2>
		<div class="row d-flex justify-content-center mb-30">
			<div class="col-md-4 text-center">
				<p>Proud and humbled, we at Equibiz are thankful to the trust and support we are constantly receiving from our patrons.</p>
			</div>
		</div>
		<div class="row seller-testi-carousel">
	      	<div class="col-md-12">
	      		<owl-carousel-o class="product-m" [options]="ProductSliderConfig">
	      			<ng-template carouselSlide>
						<div class="how-it-works-card testi-card">
							<div class="testimonial-author">
								<img src="assets/images/ritesh.png" class="author mr-3">
								<div>
									<h4 class="mb-1 fs18 f-700 text-dark">Mr. Ritesh Kumar</h4>
									<div class="location">R K Agency, Danapur, Patna</div>
								</div>
							</div>
							<div class="description">
								I am glad I found Equibiz. It has given me access to a huge number of verified buyers. I am extremely happy. All the very best to Equibiz. 
							</div>
						</div>
					</ng-template> 
					<ng-template carouselSlide>
						<div class="how-it-works-card testi-card">
							<div class="testimonial-author">
								<img src="assets/images/abhay.png" class="author mr-3">
								<div>
									<h4 class="mb-1 fs18 f-700 text-dark">Mr. Abhay Nagpure</h4>
									<div class="location">Infinity Communication, Nagpur</div>
								</div>
							</div>
							<div class="description">
								Equibiz experience is phenomenal. Their payment system is quick and easy. I am happy to have found an online B2B trading platform that offers such transparency and on time payment realisations. Thank you for a great platform.
							</div>
						</div>
					</ng-template> 	
					<ng-template carouselSlide>
						<div class="how-it-works-card testi-card">
							<div class="testimonial-author">
								<img src="assets/images/om-prakash.png" class="author mr-3">
								<div>
									<h4 class="mb-1 fs18 f-700 text-dark">Mr. Om Prakash Devnani</h4>
									<div class="location">My Choice Collection, Bhopal</div>
								</div>
							</div>
							<div class="description">
								I was surprised to find the navigation and browsing to be such a breeze. Access to wholesale as well as Bulk buyer segment in a single listing saves me so much time. Equibiz is really changing the way online B2B trading works.
							</div>
						</div>
					</ng-template>
					<ng-template carouselSlide>
						<div class="how-it-works-card testi-card">
							<div class="testimonial-author">
								<img src="assets/images/arun.png" class="author mr-3">
								<div>
									<h4 class="mb-1 fs18 f-700 text-dark">Mr. S. Arun Kumar</h4>
									<div class="location">S and G Ventures, Chennai</div>
								</div>
							</div>
							<div class="description">
								From easy Invoicing, E-way bill, listing of products to getting the payment without any headache. Equibiz is truly an amazing business partner in the online B2b trading world.
							</div>
						</div>
					</ng-template>
        		</owl-carousel-o>
	      	</div>
	    </div>
		
	</div>
</section>

<section class="large-section bg-white">
	<div class="container">
		<div class="row d-flex justify-content-center">
			<div class="col-md-8">
				<div class="dotter-map"><img src="assets/images/dotter-map.png"></div>
				<div class="text-dark text-center fs26 f-700 mb-20">Delivered 29,000+ products successfully</div>		
				<div class="equibiz-count">
					<div class="row d-flex justify-content-between">
						<div class="col-md-4">
							<div class="count-info">
								<img src="assets/images/icons/cities.png" class="mr-3">
								<div>
									<h4 class="count-no">25000+</h4>
									<p>Pincodes</p>
								</div>
							</div>
						</div>
						<div class="col-md-5">
							<div class="count-info">
								<img src="assets/images/icons/customers.png" class="mr-3">
								<div>
									<h4 class="count-no">1000+</h4>
									<p>Customers</p>
								</div>
							</div>
						</div>
						<div class="col-md-3">
							<div class="count-info">
								<img src="assets/images/icons/categories.png" class="mr-3">
								<div>
									<h4 class="count-no">15+</h4>
									<p>Categories</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>	
	</div>
</section>
<app-footer-one></app-footer-one>