<header class="sellerheader">
    <!-- Facebook Pixel Code -->
    <script>
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window,document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '2221262798031748'); 
        fbq('track', 'PageView');
    </script>
    <noscript>
        <img height="1" width="1" 
        src="https://www.facebook.com/tr?id=2221262798031748&ev=PageView
        &noscript=1"/>
    </noscript>
    <!-- End Facebook Pixel Code -->
	
	
	<!-- Global site tag (gtag.js) - Google Ads: 10853447367 --> 
	<script async src="https://www.googletagmanager.com/gtag/js?id=AW-10853447367"></script> 
	<script> window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-10853447367'); </script>
	
    <div class="">
        <div class="equibiz-header">
            <div class="logo-bar">
                <div class="container">
                    <div class="row no-gutters align-items-center justify-content-between">
                        <div class="col-md-3">
                            <a [routerLink]="['/seller/dashboard']"><img src="assets/images/svg/eq_logo.svg"></a>

                        </div>
                        <div class="col-md-6">
                            <div class="input-group-custom">
                                <input class="form-control py-2 border-right-0 border" type="text"
                                    placeholder="Search Products, Brands and Categories" id="example-search-input">
                                <div class="input-group-append-custom">
                                    <img src="assets/images/svg/search_logo.svg">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mblsignup ascal">
                            <div class="text-right header-notifications">
                              <ul class="header-dropdown">
                                 <li>
                                    <p class="mb-0 fs14 wt700 txtdefault headdt mr-3">{{ currentDateTime }}</p>
                                 </li>
                                 <li class="onhover-dropdown mobile-account">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.1" height="19.463" viewBox="0 0 20.1 19.463">
                                       <g id="Group_98" data-name="Group 98" transform="translate(-1331.4 -25.5)">
                                          <g id="np_bell_4209940_000000" transform="translate(1313.9 8.363)" opacity="0.6">
                                             <path id="Path_2300" data-name="Path 2300" d="M44.794,76.5H40.215a.715.715,0,1,0,0,1.431h4.579a.715.715,0,1,0,0-1.431Z" transform="translate(-15.705 -41.331)" fill="#5e6a7c"/>
                                             <path id="Path_2301" data-name="Path 2301" d="M35.385,32.479H34.3V26.1a7.5,7.5,0,1,0-14.994,0v6.382H18.215a.715.715,0,0,0,0,1.431h17.17a.715.715,0,1,0,0-1.431Z" transform="translate(0 0)" fill="#5e6a7c"/>
                                          </g>
                                          <circle id="Ellipse_6" data-name="Ellipse 6" cx="4" cy="4" r="4" transform="translate(1343 26)" fill="#07f" stroke="#fff" stroke-width="1"/>
                                       </g>
                                    </svg>
                                    <span class="header-notifications-container">
                                    <ul class="onhover-show-div">
                                       <li class="header"><div class="fs14 f-700 text-dark">Notification</div></li>
                                       <li>No Notifications Found</li>
                                       <!-- <li>
                                          <div class="new-one"></div>
                                          <div class="fs14 text-dark">
                                            Book now to get 10% Off. It is an Excellent Deal
                                          </div>
                                          <div class="fs13">2 min ago</div>
                                        </li>
                                        <li>
                                          <div class="new-one"></div>
                                          <div class="fs14 text-dark">
                                            Xiaomi has launched a new product. Xiaomi has launched a new product.
                                          </div>
                                          <div class="fs13">15 mins ago</div>
                                        </li>
                                        <li>
                                            <div class="fs14 text-dark">
                                              Pre-book amount Rs. 7,999 is to be paid.
                                            </div>
                                            <div class="fs13">Yesterday, 12:54 pm</div>
                                        </li>
                                        <li class="footer text-center">
                                            <a href="javascript:;" class="link-alt fs14">View all notifications</a>
                                        </li> -->
                                    </ul>
                                    </span>
                                 </li>
                              </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</header>