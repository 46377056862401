import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AddMoneyCardConfirmationService } from './add-money-card-confirmation.service';

@Component({
  selector: 'app-add-money-card-confirmation',
  templateUrl: './add-money-card-confirmation.component.html',
  styleUrls: ['./add-money-card-confirmation.component.scss']
})
export class AddMoneyCardConfirmationComponent implements OnInit {

    public order_token:string;
    public order_unique_id:string;
    public successMessage:string;
    public errorMessage:string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private _addmoneycardconfirmationservice: AddMoneyCardConfirmationService,
    ) { }

    ngOnInit(): void {
        this.route.queryParams
            .subscribe(params => {
                if(params['order_token'] !== undefined && params['order_token'] != ''){
                    this.order_token = params['order_token'];
                }
                if(params['order_id'] !== undefined && params['order_id'] != ''){
                    this.order_unique_id = params['order_id'];
                }
                setTimeout( () => {
                    this.confirmorderstatus();
                }, 1000);
            }
        );
    }

    confirmorderstatus() {
        this._addmoneycardconfirmationservice.confirmorderstatus({order_token: this.order_token, order_unique_id: this.order_unique_id}).subscribe((data) => {
            if(data['status'] == "success"){
                this.successMessage = "Money added successfully. It will reflect in your wallet with in 24 hours.";
                setTimeout( () => {
                    this.router.navigate(['/buyer/buyer-add-money']);
                }, 3000);
            }else if(data['status'] == "error"){
                this.errorMessage = data['message'];
            }
        });
    }

}
