<app-header-one [sticky]="true"></app-header-one>

<section class="small-section pb-20">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h2 class="section-title text-left font-alt">Logistics Policy</h2>
			</div>
		</div>
	</div>
</section>

<section class="small-section pt-0 hygiene-text text-left">
	<div class="container">
		<div class="row">
			<div class="col-md-12" [innerHtml]="page_content">
			</div>
		</div>
	</div>
</section>


<app-footer-one></app-footer-one>