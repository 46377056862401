import { Component, OnInit, Input, HostListener, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { RegistrationDetailsService } from './registration-details.service';
import { EncrDecrService } from '../../helpers/EncrDecrService';
import { ToasterService } from '../../helpers/toastr.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardComponent } from "angular-archwizard";

@Component({
    selector: 'app-registration-details',
    templateUrl: './registration-details.component.html',
    styleUrls: ['./registration-details.component.scss']
})
export class RegistrationDetailsComponent implements OnInit {

    @ViewChild(WizardComponent)
    public wizard: WizardComponent;

    businessDetailsForm: FormGroup;
    personDetailsForm: FormGroup;
    bankDetailsForm: FormGroup;
    public businessLoading: boolean = false;
    public personsLoading: boolean = false;
    public bankLoading: boolean = false;
    public nextForm: boolean = false;

    @Input() class: string;
    @Input() sticky: boolean = false; // Default false

    //public cheque_file:

    public userType: string;
    public gst_file: any;
    public pan_file: any;
    public aadhar_file: any;
    public gst_date_file: any;
    public business_document_file: any;
    public businessDetailsSubmitted = false;
    public last_filed_gst_date: any;
    public cheque_file: any;
    public userType_text: string;
    public bank_document: any;
    businessCategoryArray: Array<Object> = [];
    businessTypeArray: Array<Object> = [];
    stateArray: Array<Object> = [];
    cityArray: Array<Object> = [];
    supplyLocations: Array<Object> = [];
    countryArray: Array<Object> = [];

    designationArray: Array<Object> = [
        { value: 'proprietor', name: 'Proprietor' },
        { value: 'owner', name: 'Owner' },
        { value: 'Director', name: 'Director' },
        { value: 'Partner', name: 'Partner' },
        { value: 'karta', name: 'Karta' },
        { value: 'Chief Administrator Officer (CEO)', name: 'Chief Administrator Officer (CEO)' },
        { value: 'Chief Operating Officer (COO)', name: 'Chief Operating Officer (COO)' },
        { value: 'Managing Director (MD)', name: 'Managing Director (MD)' },
        { value: 'Any Others', name: 'Any Others' },
    ];

    public gstFilingArray: any = [
        {
            value: 'Monthly',
            name: 'Monthly'
        },
        {
            value: 'Quarterly',
            name: 'Quarterly'
        }
    ];

    public businessDocumentsArray: any = [
        {
            value: 'Udyog Aadhar',
            name: 'Udyog Aadhar'
        },
        {
            value: 'Shop and Establishment Certificate',
            name: 'Shop and Establishment Certificate'
        },
        {
            value: 'Shop Photograph',
            name: 'Shop Photograph'
        },
        {
            value: 'Any other',
            name: 'Any other'
        }
    ];

    public stick: boolean = false;

    constructor(
        private _registrationdetailsservice: RegistrationDetailsService,
        private _EncrDecr: EncrDecrService,
        private fb: FormBuilder,
        private _toastr: ToasterService,
        private route: ActivatedRoute,
        public router: Router,
    ) { }

    ngOnInit(): void {

        this.userType = this.route.snapshot.paramMap.get('slug');
        if (this.userType != 'seller' && this.userType != 'buyer') {
            this.router.navigate(['/home']);
        }
        if (this.userType == 'seller') {
            this.userType_text = 'Pickup';
        } else {
            this.userType_text = 'Supply';
        }




        //Phone Form
        this.businessDetailsForm = this.fb.group({
            gstin: this.fb.control(null, []),//Validators.required, Validators.minLength(15), Validators.maxLength(15)
            no_gst: this.fb.control(false, []),
            business_name: this.fb.control(null, [Validators.required]),
            business_category: this.fb.control(null, [Validators.required]),
            business_type: this.fb.control(null, [Validators.required]),
            previous_year_turnover: this.fb.control(null, [Validators.required, Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')]),
            business_document: this.fb.control(null, []),
            gst_filing_frequency: this.fb.control(null, []),
            last_filed_gst_date: this.fb.control(null, []),
            pan_number: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
            aadhar_card_id: new FormControl('', []),
            registered_office_address: this.fb.control(null, [Validators.required]),
            registered_office_state: this.fb.control(null, [Validators.required]),
            registered_office_city: this.fb.control(null, [Validators.required]),
            registered_office_pincode: this.fb.control(null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
            primary_location: this.fb.control(null, [Validators.required]),
            shipping_city: this.fb.control(null, []),
            shipping_state: this.fb.control(null, []),
            same_as_registered_office: new FormControl(true, []),
            secondary_locations: this.fb.array([]),
        });

        this.personDetailsForm = this.fb.group({
            designation: this.fb.control(null, [Validators.required]),
            first_name: this.fb.control(null, [Validators.required]),
            last_name: this.fb.control(null, [Validators.required]),
            country_code: this.fb.control('+91', [Validators.required]),
            phone_number: this.fb.control(null, [Validators.required, Validators.pattern('[- +()0-9]+'), Validators.maxLength(10), Validators.minLength(10)]),
            authorized_persons: this.fb.array([]),
        });

        this.bankDetailsForm = this.fb.group({
            ifsc_code: this.fb.control(null, [Validators.required]),
            business_name: this.fb.control(null, [Validators.required]),
            bank_name: this.fb.control(null, []),
            branch_name: this.fb.control(null, []),
            branch_address: this.fb.control(null, []),
            account_number: this.fb.control(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
            confirm_account_number: this.fb.control(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
            agree_terms: this.fb.control(false, [Validators.required]),
        });

        this.getuserdetails();
    }

    createItem(id = "") {
        return this.fb.group({
            city: id,
            state: '',
            pincode: '',
            address: '',
            address_name: '',
            is_default: false
        });
    }

    setRequired() {
        return [Validators.required];
    }

    setAadharCardRequired() {
        return [Validators.required, Validators.minLength(12), Validators.maxLength(12)];
    }

    setGstinRequired() {
        return [Validators.required, Validators.minLength(15), Validators.maxLength(15)];
    }

    isGstChange(event) {
        if (this.businessDetailsForm.value.no_gst) {
            this.businessDetailsForm.get('gstin').setValue('');
            // this.businessDetailsForm.get('business_document').setValidators(this.setRequired());
            // this.businessDetailsForm.controls['business_document'].updateValueAndValidity()
            this.businessDetailsForm.get('gst_filing_frequency').clearValidators();
            this.businessDetailsForm.controls['gst_filing_frequency'].updateValueAndValidity();
            this.businessDetailsForm.get('last_filed_gst_date').clearValidators();
            this.businessDetailsForm.controls['last_filed_gst_date'].updateValueAndValidity();
            // this.businessDetailsForm.get('aadhar_card_id').setValidators(this.setAadharCardRequired());
            // this.businessDetailsForm.controls['aadhar_card_id'].updateValueAndValidity();
        } else {
            this.businessDetailsForm.get('business_document').clearValidators();
            this.businessDetailsForm.controls['business_document'].updateValueAndValidity()
            this.businessDetailsForm.get('gst_filing_frequency').setValidators(this.setRequired());
            this.businessDetailsForm.controls['gst_filing_frequency'].updateValueAndValidity();
            this.businessDetailsForm.get('last_filed_gst_date').setValidators(this.setRequired());
            this.businessDetailsForm.controls['last_filed_gst_date'].updateValueAndValidity();
            this.businessDetailsForm.get('gstin').setValidators(this.setGstinRequired());
            this.businessDetailsForm.controls['gstin'].updateValueAndValidity();
            this.businessDetailsForm.get('aadhar_card_id').clearValidators();
            this.businessDetailsForm.controls['aadhar_card_id'].updateValueAndValidity();
        }
    }

    getuserdetails() {
        this._registrationdetailsservice.getuserdetails(null).subscribe((data) => {
            if (data['status'] == "success") {
                var usertype = data['data']['usertype'];
                var regstatus = data['data']['reg_status'];
                
                if (this.userType == 'buyer') {
                    if (usertype == 2 && regstatus == 'no') {
                        this.router.navigate(['/registration/seller/details']);

                    } else if (usertype == 2 && regstatus == 'yes') {
                        this.router.navigate(['/sellerdashboard']);
                    } else if (usertype == 1 && regstatus == 'yes') {
                        this.router.navigate(['/home']);
                    }
                } else if (this.userType == 'seller') {
                    if (usertype == 1 && regstatus == 'no') {
                        this.router.navigate(['/registration/buyer/details']);
                    } else if (usertype == 1 && regstatus == 'yes') {
                        this.router.navigate(['/home']);
                    } else if (usertype == 2 && regstatus == 'yes') {
                        this.router.navigate(['/seller/dashboard']);
                    }
                } else {
                    this.router.navigate(['/home']);
                }

                if ((this.userType == 'buyer' && usertype == 1) || (this.userType == 'seller' && usertype == 2)) {
                    this.getregistrationdetails();
                    this.getbusinesscategory();
                    this.getbusinesstype();
                    this.getstate();
                    this.getcountry();
                }
            }
        }, (error) => {
            this._toastr.error('User details not available.');
        });
    }

    get bd() { return this.businessDetailsForm.controls; }

    getcityandlocations() {
        this.getcity();
        this.gethublocation();
    }

    newSupplyLocation() {
        var id = '';
        (this.businessDetailsForm.controls['secondary_locations'] as FormArray).push(this.createItem(id));
    }

    updateSupplyLocation(locations) {
        if (locations.length > 0) {
            for (let i = 0; i < locations.length; i++) {
                if (locations[i].is_hub == undefined) {
                    (this.businessDetailsForm.controls['secondary_locations'] as FormArray).push(this.createItem(locations[i].city));
                }
            }
        } else {
            this.newSupplyLocation();
        }
    }

    //Authorized Persons Array
    createPerson(name = "", country_code = "+91", phone_number = "") {
        return this.fb.group({
            name: name,
            country_code: country_code,
            phone_number: phone_number,
            email: ''
        });
    }

    addAuthorizedPersons() {
        (this.personDetailsForm.controls['authorized_persons'] as FormArray).push(this.createPerson());
    }

    updateAuthorizedPersons(persons) {
        if (persons.length > 0) {
            for (let i = 0; i < persons.length; i++) {
                (this.personDetailsForm.controls['authorized_persons'] as FormArray).push(this.createPerson(persons[i].name, persons[i].country_code, persons[i].phone_number));
            }
        } else {
            this.addAuthorizedPersons();
        }
    }

    getregistrationdetails() {
        this._registrationdetailsservice.getregistrationdetails(null).subscribe((data) => {
            if (data['status'] == "success") {

                //Business Details
                var records = data['records']['business_detail'];
                this.businessDetailsForm.patchValue({
                    gstin: records.gst_number,
                    no_gst: records.no_gst,
                    business_name: records.buyer_b_name,
                    business_category: records.business_category,
                    business_type: records.business_type,
                    previous_year_turnover: records.previous_year_turnover,
                    pan_number: records.pan_number,
                    aadhar_card_id: records.aadhar_number,
                    registered_office_address: records.registered_office_address,
                    registered_office_state: records.registered_office_state,
                    registered_office_city: records.registered_office_city,
                    registered_office_pincode: records.registered_office_pincode,
                    primary_location: records.primary_location,
                    shipping_address: records.registered_office_address,
                    shipping_pincode: records.registered_office_pincode,
                    same_as_registered_office: records.same_as_registered_office,
                });
                if (!records.same_as_registered_office && records.secondary_locations.length > 0) {
                    this.updateSupplyLocation(records.secondary_locations);
                }

                this.aadhar_file = records.aadhar_document_path;
                this.pan_file = records.pan_document_path;

                if (records.no_gst) {
                    this.business_document_file = records.nogstbusinessdocpath;
                    this.businessDetailsForm.patchValue({
                        business_document: records.nogstbusinessdocvalue,
                    });
                } else {
                    this.gst_file = records.gst_document_path;
                    this.gst_date_file = records.gst_last_filled_path;
                    this.businessDetailsForm.patchValue({
                        gst_filing_frequency: records.gst_filling_frequency,
                    });
                    this.businessDetailsForm.get('last_filed_gst_date').setValue({
                        year: records.gst_last_payment_date.year,
                        month: records.gst_last_payment_date.month,
                        day: records.gst_last_payment_date.date
                    });
                }




                this.getcityandlocations();

                //Persons Details
                this.personDetailsForm.patchValue({
                    designation: records.owner_designation,
                    first_name: records.owner_firstname,
                    last_name: records.owner_lastname,
                    country_code: records.owner_countrycode,
                    phone_number: records.owner_mobile
                });
                this.updateAuthorizedPersons(records.authorized_persons);

                //Bank Details
                var records = data['records']['bank_detail'];
                this.bankDetailsForm.patchValue({
                    ifsc_code: records.ifsc_code,
                    business_name: records.account_name,
                    bank_name: records.bank_name,
                    branch_name: records.branch,
                    branch_address: records.branch_address,
                    account_number: records.account_number,
                    confirm_account_number: records.account_number,
                    agree_terms: records.agree_terms
                });
                this.cheque_file = records.document_path;

            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getbusinesscategory() {
        this._registrationdetailsservice.getbusinesscategory(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.businessCategoryArray = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getbusinesstype() {
        this._registrationdetailsservice.getbusinesstype(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.businessTypeArray = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getstate() {
        this._registrationdetailsservice.getstate(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.stateArray = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getcountry() {
        this._registrationdetailsservice.getcountry(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.countryArray = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    gethublocation() {
        let data = { state: this.businessDetailsForm.get('registered_office_state').value };
        this._registrationdetailsservice.gethublocation(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.supplyLocations = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getcity() {
        let data = { state: this.businessDetailsForm.get('registered_office_state').value };
        this._registrationdetailsservice.getcity(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.cityArray = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    businessDetailsSave() {
        var no_gst = this.businessDetailsForm.get('no_gst').value;
        if (!no_gst) {
            var gstin = this.businessDetailsForm.get('gstin').value;
            if (!gstin) {
                this._toastr.error('Please enter gstin number');
                return false;
            }
            var gst_filing_frequency = this.businessDetailsForm.get('gst_filing_frequency').value;
            if (!gst_filing_frequency) {
                this._toastr.error('Please enter gst filing frequency');
                return false;
            }
            var last_filed_gst_date = this.businessDetailsForm.get('last_filed_gst_date').value;
            if (!last_filed_gst_date) {
                this._toastr.error('Please enter last filed gst date');
                return false;
            }

        } else {
            var business_document = this.businessDetailsForm.get('business_document').value;
            if (!business_document) {
                // this._toastr.error('Please select business document');
                // return false;
            }
        }



        this.businessLoading = true;
        let data = {
            gstin: this._EncrDecr.set(this.businessDetailsForm.get('gstin').value),
            no_gst: this.businessDetailsForm.get('no_gst').value,
            business_name: this._EncrDecr.set(this.businessDetailsForm.get('business_name').value),
            business_category: this._EncrDecr.set(this.businessDetailsForm.get('business_category').value),
            business_type: this._EncrDecr.set(this.businessDetailsForm.get('business_type').value),
            previous_year_turnover: this._EncrDecr.set(this.businessDetailsForm.get('previous_year_turnover').value),
            gst_filing_frequency: this._EncrDecr.set(this.businessDetailsForm.get('gst_filing_frequency').value),
            last_filed_gst_date: JSON.stringify(this.businessDetailsForm.get('last_filed_gst_date').value),
            business_document: this._EncrDecr.set(this.businessDetailsForm.get('business_document').value),
            pan_number: this._EncrDecr.set(this.businessDetailsForm.get('pan_number').value),
            aadhar_card_id: this._EncrDecr.set(this.businessDetailsForm.get('aadhar_card_id').value),
            registered_office_address: this._EncrDecr.set(this.businessDetailsForm.get('registered_office_address').value),
            registered_office_state: this._EncrDecr.set(this.businessDetailsForm.get('registered_office_state').value),
            registered_office_city: this._EncrDecr.set(this.businessDetailsForm.get('registered_office_city').value),
            registered_office_pincode: this._EncrDecr.set(this.businessDetailsForm.get('registered_office_pincode').value),
            primary_location: this._EncrDecr.set(this.businessDetailsForm.get('primary_location').value),
            same_as_registered_office: this.businessDetailsForm.get('same_as_registered_office').value,
            secondary_locations: this.businessDetailsForm.get('secondary_locations').value,
            shipping_address: this._EncrDecr.set(this.businessDetailsForm.get('registered_office_address').value),
            shipping_pincode: this._EncrDecr.set(this.businessDetailsForm.get('registered_office_pincode').value),
            shipping_city: this._EncrDecr.set(this.businessDetailsForm.get('shipping_city').value),
            shipping_state: this._EncrDecr.set(this.businessDetailsForm.get('registered_office_state').value),
            savelater: this._EncrDecr.set(1)
        };
        this._registrationdetailsservice.registrationdetails(data).subscribe((data) => {
            this.businessLoading = false;
            if (data['status'] == "success") {
                this.wizard.goToNextStep();
                this.businessDetailsSubmitted = true;
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            } else if (data['status'] == 'validation') {
                this._toastr.validation(data['errors'])
            }
        }, (error) => {
            this.businessLoading = false;
        });
    }

    personDetailsSave() {
        this.personsLoading = true;
        let data = {
            designation: this._EncrDecr.set(this.personDetailsForm.get('designation').value),
            first_name: this._EncrDecr.set(this.personDetailsForm.get('first_name').value),
            last_name: this._EncrDecr.set(this.personDetailsForm.get('last_name').value),
            country_code: this._EncrDecr.set(this.personDetailsForm.get('country_code').value),
            phone_number: this._EncrDecr.set(this.personDetailsForm.get('phone_number').value),
            authorized_persons: this.personDetailsForm.get('authorized_persons').value,
            savelater: this._EncrDecr.set(1),
        };
        this._registrationdetailsservice.personsdetails(data).subscribe((data) => {
            this.personsLoading = false;
            if (data['status'] == "success") {
                this.getregistrationdetails();
                this.wizard.goToNextStep();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            } else if (data['status'] == 'validation') {
                this._toastr.validation(data['errors'])
            }
        }, (error) => {
            this.personsLoading = false;
        });
    }

    bankDetailsSave() {
        this.bankLoading = true;
        let data: any = new FormData();
        data.append('ifsc_code', this._EncrDecr.set(this.bankDetailsForm.get('ifsc_code').value));
        data.append("business_name", this._EncrDecr.set(this.bankDetailsForm.get('business_name').value));

        data.append('bank_name', this._EncrDecr.set(this.bankDetailsForm.get('bank_name').value));
        data.append("branch_name", this._EncrDecr.set(this.bankDetailsForm.get('branch_name').value));

        data.append('branch_address', this._EncrDecr.set(this.bankDetailsForm.get('branch_address').value));
        data.append("account_number", this._EncrDecr.set(this.bankDetailsForm.get('account_number').value));

        data.append('confirm_account_number', this._EncrDecr.set(this.bankDetailsForm.get('confirm_account_number').value));
        data.append("agree_terms", this.bankDetailsForm.get('agree_terms').value);
        data.append("document", this.bank_document);

        this._registrationdetailsservice.bankdetails(data).subscribe((data) => {
            this.bankLoading = false;
            if (data['status'] == "success") {
                this.wizard.goToNextStep();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            } else if (data['status'] == 'validation') {
                this._toastr.validation(data['errors'])
            }
        }, (error) => {
            this.bankLoading = false;
        });
    }

    gstdetails() {
        let gstin = this._EncrDecr.set(this.businessDetailsForm.get('gstin').value);
        this._registrationdetailsservice.gstdetails({ gstin: gstin }).subscribe((data) => {
            if (data['status'] == "success" && data['gst_info']['valid']) {
                var gstin = this.businessDetailsForm.get('gstin').value;
                var str1 = gstin.slice(2);
                var str2 = str1.slice(0, -3);
                this.businessDetailsForm.get('pan_number').setValue(str2);

                var gst = data['gst_info']['gstinfo'];
                var pr_address = data['gst_info']['gstinfo']['pr_address'];
                var address = '';
                if (pr_address.bno) {
                    address = pr_address.bno;
                }
                if (pr_address.bnm) {
                    address = address + ", " + pr_address.bnm;
                }
                if (pr_address.st) {
                    address = address + ", " + pr_address.st;
                }
                if (pr_address.loc) {
                    address = address + ", " + pr_address.loc;
                }

                var state = pr_address.stcd;
                var city = pr_address.dst;
                var pincode = pr_address.pncd;
                var lstupdt_format = gst.lstupdt_format;
                this.businessDetailsForm.patchValue({
                    business_name: gst.tradeNam,
                    registered_office_address: address,
                    registered_office_state: state,
                    registered_office_city: city,
                    registered_office_pincode: pincode,
                    primary_location: city
                });
                // this.businessDetailsForm.get('last_filed_gst_date').setValue({
                //     year: lstupdt_format.year,
                //     month: lstupdt_format.month,
                //     day: lstupdt_format.date
                // });
                this.getcity();
                //this.gethublocation();
            }
            else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }




    ifscdetails() {
        let ifsc_code = this._EncrDecr.set(this.bankDetailsForm.get('ifsc_code').value);
        this._registrationdetailsservice.getifscdetails({ ifsc_code: ifsc_code }).subscribe((data) => {
            if (data['status'] == "success") {
                this.bankDetailsForm.patchValue({
                    bank_name: data['ifscdetails']['BANK'],
                    branch_name: data['ifscdetails']['BRANCH'],
                    branch_address: data['ifscdetails']['ADDRESS']
                });
            }
            else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    disabled = false;
    // @HostListener Decorator
    @HostListener("window:scroll", [])
    onWindowScroll() {
        let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (number >= 150 && window.innerWidth > 400) {
            this.stick = true;
        } else {
            this.stick = false;
        }
    }




    uploadFile(event, type, label) {
        if (label == undefined) {
            label = '';
        }
        const file = event.target.files;
        let data: any = new FormData();
        data.append("document_type", this._EncrDecr.set(type));
        data.append("document", file[0]);
        if (type != 'cheque') {
            this._registrationdetailsservice.uploaddoc(data).subscribe((data) => {
                if (data['status'] == "success") {
                    if (type == 'last_gst_file') {
                        this.gst_date_file = data['document_path'];
                        this._toastr.success('Last filed GST document updated.');
                    } else if (type == 'pan') {
                        this.pan_file = data['document_path'];
                        this._toastr.success('PAN document updated.');
                    } else if (type == 'aadhar') {
                        this.aadhar_file = data['document_path'];
                        this._toastr.success('AADHAR document updated.');
                    } else if (type == 'gst') {
                        this.gst_file = data['document_path'];
                        this._toastr.success('GST document updated.');
                    } else if (type == 'nogst') {
                        this.business_document_file = data['document_path'];
                        this._toastr.success('Business document updated.');
                    }
                } else if (data['status'] == "error") {
                    this._toastr.error(data['message']);
                }
            }, (error) => {
                event.target.value = '';
                this.businessLoading = false;
            });
        } else {
            this.bank_document = file[0];
            const reader = new FileReader();
            if (type == 'cheque') {
                reader.onload = e => this.cheque_file = reader.result;
            }
            reader.readAsDataURL(file[0]);
        }
        event.target.value = '';

    }

    sameasregisteredoffice() {
        if (this.businessDetailsForm.get('same_as_registered_office').value) {
            this.businessDetailsForm.get('primary_location').setValue(this.businessDetailsForm.get('registered_office_city').value);
        }
    }
}
