<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<h3 class="fs24 wt700 txtdefault">Authorized Persons</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Settings</a>
						</li>
						<li class="breadcrumb-item active">Authorized Persons</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:;" class="mr-sm-4 mr-2 btn-d-outline d-inline-block" (click)="open(content)"><svg xmlns="http://www.w3.org/2000/svg" width="14.681" height="14.681" viewBox="0 0 14.681 14.681" class="plus">
					  <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1 1)">
					    <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181" transform="translate(-11.659 -7.5)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					    <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181" transform="translate(-7.5 -11.659)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					  </g>
					</svg>
					Add New
				</a>
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4 mb-2">

			<div class="authorized-person">
				<div class="row">
					<div class="col-md-9">
						<div class="d-table">
							<div class="d-table-cell align-middle text-center  txtavtarwrp">
								<div class="txtavtar2 fs20 textwhite wt700">AK</div>
							</div>
							<div class="d-table-cell dsg-pinfo pl-3 align-middle">
								<h5 class="fs14 wt700 mb-1 mt-0 txtdefault d-flex align-items-center">Abhinav Kumar <span class="ml-4 py-1 px-3 odbdge btn-d-outline ripple d-inline-block fs12">Access : User Management</span></h5>
								<p class="fs12 txtdefault wt400 mr-4 d-inline-block mb-0"><i class="d-inline-block p-2 callicon"></i> +91 98765 04321</p>
								<p class="fs12 txtdefault wt500 d-inline-block mb-0"><i class="d-inline-block p-2 envelopeicon"></i> abhinav.kumar@gmail.com</p>
							</div>
						</div>
					</div>
					<div class="col-md-3">
						<div class="d-flex align-items-center justify-content-end">
							<a href="javascript:void(0);">
								<img src="assets/images/icons/edit-big.png">
							</a>
							<span class="vdivider ml-3 mr-3"></span>
							<a href="javascript:;" (click)="openmdl(removePerson)"><img src="assets/images/icons/delete-icon.svg"></a>
						</div>
					</div>
				</div>
			</div>

			<div class="authorized-person">
				<div class="row">
					<div class="col-md-9">
						<div class="d-table">
							<div class="d-table-cell align-middle text-center  txtavtarwrp">
								<div class="txtavtar2 fs20 textwhite wt700 hg-color">SG</div>
							</div>
							<div class="d-table-cell dsg-pinfo pl-3 align-middle">
								<h5 class="fs14 wt700 mb-1 mt-0 txtdefault d-flex align-items-center">Sanjay Gupta <span class="ml-4 py-1 px-3 odbdge btn-d-outline ripple d-inline-block fs12">Access : User Management</span></h5>
								<p class="fs12 txtdefault wt400 mr-4 d-inline-block mb-0"><i class="d-inline-block p-2 callicon"></i> +91 98765 04321</p>
								<p class="fs12 txtdefault wt500 d-inline-block mb-0"><i class="d-inline-block p-2 envelopeicon"></i> abhinav.kumar@gmail.com</p>
							</div>
						</div>
					</div>
					<div class="col-md-3">
						<div class="d-flex align-items-center justify-content-end">
							<a href="javascript:void(0);">
								<img src="assets/images/icons/edit-big.png">
							</a>
							<span class="vdivider ml-3 mr-3"></span>
							<a href="javascript:;" (click)="openmdl(removePerson)"><img src="assets/images/icons/delete-icon.svg"></a>
						</div>
					</div>
				</div>
			</div>

			<div class="authorized-person">
				<div class="row">
					<div class="col-md-9">
						<div class="d-table">
							<div class="d-table-cell align-middle text-center  txtavtarwrp">
								<div class="txtavtar2 fs20 textwhite wt700 sg-color">AG</div>
							</div>
							<div class="d-table-cell dsg-pinfo pl-3 align-middle">
								<h5 class="fs14 wt700 mb-1 mt-0 txtdefault d-flex align-items-center">Abhinav Gupta <span class="ml-4 py-1 px-3 odbdge btn-d-outline ripple d-inline-block fs12">Access : User Management</span></h5>
								<p class="fs12 txtdefault wt400 mr-4 d-inline-block mb-0"><i class="d-inline-block p-2 callicon"></i> +91 98765 04321</p>
								<p class="fs12 txtdefault wt500 d-inline-block mb-0"><i class="d-inline-block p-2 envelopeicon"></i> abhinav.kumar@gmail.com</p>
							</div>
						</div>
					</div>
					<div class="col-md-3">
						<div class="d-flex align-items-center justify-content-end">
							<a href="javascript:void(0);">
								<img src="assets/images/icons/edit-big.png">
							</a>
							<span class="vdivider ml-3 mr-3"></span>
							<a href="javascript:;" (click)="openmdl(removePerson)"><img src="assets/images/icons/delete-icon.svg"></a>
						</div>
					</div>
				</div>
			</div>

			<div class="authorized-person">
				<div class="row">
					<div class="col-md-9">
						<div class="d-table">
							<div class="d-table-cell align-middle text-center  txtavtarwrp">
								<div class="txtavtar2 fs20 textwhite wt700 sn-color">SN</div>
							</div>
							<div class="d-table-cell dsg-pinfo pl-3 align-middle">
								<h5 class="fs14 wt700 mb-1 mt-0 txtdefault d-flex align-items-center">Suresh Nair <span class="ml-4 py-1 px-3 odbdge btn-d-outline ripple d-inline-block fs12">Access : User Management</span></h5>
								<p class="fs12 txtdefault wt400 mr-4 d-inline-block mb-0"><i class="d-inline-block p-2 callicon"></i> +91 98765 04321</p>
								<p class="fs12 txtdefault wt500 d-inline-block mb-0"><i class="d-inline-block p-2 envelopeicon"></i> abhinav.kumar@gmail.com</p>
							</div>
						</div>
					</div>
					<div class="col-md-3">
						<div class="d-flex align-items-center justify-content-end">
							<a href="javascript:void(0);">
								<img src="assets/images/icons/edit-big.png">
							</a>
							<span class="vdivider ml-3 mr-3"></span>
							<a href="javascript:;" (click)="openmdl(removePerson)"><img src="assets/images/icons/delete-icon.svg"></a>
						</div>
					</div>
				</div>
			</div>
		</div>	
	</div>
</div>
<!-- UPDATE BANK DETAILS -->
<ng-template #content let-modal>
   <div class="modal-body text-center mdlcmnui pt-4 mt-3">
      <h5 class="modal-title mb-10 ">Add New Authorized Person</h5>
      	<div class="form-container mdl-frm-lbl mb-3">
			<div class="row">
				<div class="col-md-12">
					<div class="floating-label mb-4">      
					  <input value="Shubham Jaiswal" type="text" class="form-control" placeholder=" " />
					  <span class="highlight"></span>
					  <label>Full Name</label>
					</div> 
				</div>
				<div class="col-md-12">
					<div class="floating-label mb-4">      
					  <input value="+91 89876 50432" type="text" class="form-control" placeholder=" " />
					  <span class="highlight"></span>
					  <label>Mobile Number</label>
					</div> 
				</div>
				<div class="col-md-12">
					<div class="floating-label mb-4">      
					  <input value="abhinav.kumar@gmail.com" type="text" class="form-control" placeholder=" " />
					  <span class="highlight"></span>
					  <label>Email ID</label>
					</div> 
				</div>	
				<div class="col-md-12">
					<div class="access-info">
						<label>Access for</label>
						<div class="access-list">
							<ul>
								<li><a href="javascript:;" class="active">User Management</a></li>
								<li><a href="javascript:;">User Management</a></li>
								<li><a href="javascript:;">User Management</a></li>
								<li><a href="javascript:;">User Management</a></li>
								<li><a href="javascript:;">User Management</a></li>
							</ul>
						</div>
					</div>
				</div>	
			</div>
		</div>
      	<div class="text-center nobd mx-auto pb-4 mb-3">
		    <button type="button" class="btn-d-outline d-inline-block cancel-outline mr-3" (click)="modal.close('Close click')">Cancel</button>
			<button type="button" class="btn buybtn fs16 radius10">Save Changes</button>
		  </div>
   </div>
</ng-template>
<!-- UPDATE BANK DETAILS -->
<!-- OTP VERIFICATION -->
<ng-template #removePerson let-modal>
   <div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
      <h5 class="modal-title">Remove Authorized Person</h5>
        <div class="modal-subtitle text-center pt-30 pb-30">Are you sure you want to remove this authorized person?</div>

        <div class="bid-order-info">
			<div class="order-img">
				<img src="assets/images/icons/info.png" />
			</div>
			<div class="pd-seller-cntnt">
				<div class="fs12">By removing this person, the access to User Management will be removed</div>
			</div>
		</div>

		<div class="row d-flex justify-content-center mt-30">
			<div class="col-md-6">
				<div class="d-table">
					<div class="d-table-cell align-middle text-center  txtavtarwrp">
						<div class="txtavtar fs20 textwhite wt700 sn-color">SN</div>
					</div>
					<div class="d-table-cell dsg-pinfo pl-3 align-middle">
						<h5 class="fs14 wt700 mb-1 mt-0 txtdefault d-flex align-items-center">Suresh Nair</h5>
					</div>
				</div>
			</div>
		</div>

        <div class="text-center pt-30 pb-20">
           <button type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')" class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4">
		      Cancel
		   	</button>
		   	<button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4">
		      Remove
		   	</button>
        </div>
   </div>
</ng-template>
<!-- OTP VERIFICATION -->