<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain mgwrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head mgb-sm-ui-rw">
			<div class="col-md-6 ">
				<h3 class="fs28 txtdefault wt700">Spice Money Users</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);" [routerLink]="['/admin/product-listing']">Product Management</a>
						</li>
						<li class="breadcrumb-item active">Spice Money Users</li>
					</ol>
				</nav>
			</div>
			<div class="col-md-6 text-right sm-ui-rw">
				<a href="javascript:;" (click)="opennew(addsmamodal,'')" class="mr-sm-4 btn-d-outline d-inline-block">
					<svg xmlns="http://www.w3.org/2000/svg" width="14.681" height="14.681" viewBox="0 0 14.681 14.681" class="plus">
					  <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1 1)">
					    <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181" transform="translate(-11.659 -7.5)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					    <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181" transform="translate(-7.5 -11.659)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					  </g>
					</svg>
					Add New Spice Money User
				</a>
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ml-3">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<!--Filters-->
		<div class="row d-flex justify-content-between mt-20 report-cta mb-20">
			<div class="col-md-5">
				<div class="report-input">
					<div class="input-group-custom">
						<input type="text" placeholder="Search by a Spice Money User name" class="form-control" [(ngModel)]="search_key" (keyup)="usersearch()">
						<div class="input-group-append-custom">
							<img src="assets/images/svg/search_logo.svg">
						</div>
					</div>
				</div>
			</div>

			<div class="col-md-7">
				<div class="text-right">
					<a href="javascript:void(0);" class="ripple d-inline-block place-bid mr-3">
					<div class="d-flex align-items-center">
						<div class="mr-2"><img src="assets/images/icons/csv.png"></div>
						<div>Download Sample</div>
					</div>
				</a>
				<input type="file" #hsnfile (change)="uploadhsnfile($event)" style="display: none;">
				<a href="javascript:void(0);" (click)="hsnfile.click()" class="btn-d-outline ripple d-inline-block place-bid">
					<div class="d-flex align-items-center">
						<div class="mr-2"><img src="assets/images/icons/csv.png"></div>
						<div>Upload Excel Sheet</div>
					</div>
				</a>
				</div>
			</div>
		</div>
		<!--End Filters-->

		<div class="card card-ui card-uiovt">
			<!-- <p>Chapter</p> -->
			<div class="gstcodepanel doc-od">
				<table class="table custom-table xs">
					<thead>
						<tr>
                            <th>Name</th>
							<th width="50%">Email</th>
							<th>Mobile</th>
							<th>Status</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let record of user_records;">	
							<td>{{ record.name }}</td>
							<td>{{ record.email }}</td>
							<td>{{ record.mobile }}</td>
							<td>{{ record.status }}</td>
							<td><a href="javascript:;" (click)="opennew(addsmamodal, record._id)" class="link-alt fs14">Edit</a></td>
						</tr>
						<!--Empty State-->
						<tr *ngIf="!user_records.length">
							<td colspan="7">
								<div class="row pt-40 pb-40">
									<div class="col-md-12 text-center">
										<div class="mb-20"><img src="/assets/images/empty-state.png"></div>
										<div class="fs16">There are no records to display!</div>
									</div>
								</div>
							</td>
						</tr>
						<!--End Empty State-->
					</tbody>
				</table>
			</div>
		</div>
		<div class="row mb-40" *ngIf="user_pagination.totalPages > 1">
			<div class="col-md-6">
				<div class="text-left pl-3">
					<nav class="mt-3 tbpg rows-per-page">
						<div>Rows per page:</div>
						<select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
							<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">{{option.name}}</option>
						</select>
					</nav>
				</div> 
			</div>
			<div class="col-md-6" *ngIf="user_pagination.pages && user_pagination.pages.length">
				<div class="text-right pr-3">
					<nav class="mt-3 tbpg">
					  <ul class="pagination align-items-center">
						<li class="page-item" [ngClass]="{disabled:user_pagination.currentPage === 1}"><a class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a></li>

						<li class="page-item" [ngClass]="{disabled:user_pagination.currentPage === 1}"><a class="page-link sprevicon" href="javascript:;" (click)="setpage(user_pagination.currentPage - 1)"></a></li>

						<li class="page-item"i *ngFor="let page of user_pagination.pages" [ngClass]="{active:user_pagination.currentPage === page}"><a class="page-link" href="javascript:;" (click)="setpage(page)">{{page}}</a></li>

						<li *ngIf="(user_pagination.totalPages - user_pagination.startPage) > 5 ">
							<a (click)="setpage(user_pagination.startPage + 5)">...</a>
						</li>
						 <li *ngIf="(user_pagination.totalPages - user_pagination.startPage) > 5" >
							<a (click)="setpage(user_pagination.totalPages)">{{user_pagination.totalPages}}</a>
						</li>
						
						<li class="page-item" [ngClass]="{disabled:user_pagination.currentPage === user_pagination.totalPages}"><a class="page-link snexticon" href="javascript:;"  (click)="setpage(user_pagination.currentPage + 1)"></a></li>
						<li class="page-item" [ngClass]="{disabled:user_pagination.currentPage === user_pagination.totalPages}"><a class="page-link nexticon" href="javascript:;" (click)="setpage(user_pagination.totalPages)"></a></li>
					  </ul>
					</nav>
				</div> 
			</div>
		</div>
	</div>
</div>	

<ng-template #addsmamodal let-modal>
	<div class="modal-body text-center mdlcmnui pt-4 mt-3">
        <h5 class="modal-title mb-20">{{editButton == false ? "Add Spice Money User" : "Update Spice Money User"}}</h5>
        <form  [formGroup]="addspicemoneyuser">
			<div class="form-container mt-30 mb-10">
				<div class="floating-label">
					<div class="input-group-custom">
						<textarea name="name" class="form-control" id="name" cols="30" rows="3" formControlName="name"></textarea>
						<span class="highlight"></span>
						<label>Name</label>
						<span class="help-block" *ngIf="an.name.invalid && an.name.touched">This field is required</span>
					</div>
				</div>
			</div>

			<div class="form-container mt-30 mb-10">
				<div class="floating-label">
					<div class="input-group-custom">
						<input type="text" value="" class="form-control" name="mobile" formControlName="mobile" placeholder=""/>
						<span class="highlight"></span>
						<label>Mobile</label>
						<span class="help-block" *ngIf="an.mobile.invalid && an.mobile.touched">Please enter valid Mobile</span>
					</div>
				</div>
			</div>

			<div class="form-container mt-30 mb-10">
				<div class="floating-label">
					<div class="input-group-custom">
						<input type="email" value="" class="form-control" name="email" formControlName="email" placeholder=""/>
						<span class="highlight"></span>
						<label>Email</label>
					</div>
				</div>
			</div>

			<div class="form-container mt-30 mb-10">
				<div class="floating-label">
					<div class="input-group-custom">
                        <div class="selectactive">
                            <ng-selectize
                              [config]="config"
                              [options]="status"
                              formControlName="status"
                              placeholder="Select"
                            >
                            </ng-selectize>
                          </div>
					</div>
				</div>
			</div>

			<div class="text-center pt-30 pb-20">
			<button type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')" class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4">
				Cancel
				</button>
				<button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4" (click)="adduser()" [disabled]="adduserLoading || addspicemoneyuser.invalid">
					<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="adduserLoading"></span>
					&nbsp;
         {{editButton == false ? "Save Changes" : "Update Changes"}}
				</button>
			</div>
		</form>
      </div>
</ng-template>

