<app-header-seller></app-header-seller>
<app-sidebar-seller></app-sidebar-seller>
<div class="wrpmain pb-4">
    <div class="container pb-4">
        <!--- HEAD  --->
        <div class="row align-items-center mb-4 pinfo-head mb-xs-0">
            <div class="col-sm-12">
                <nav class="breadcrumb breadcrumb-dui mb-0">
                    <ol class="breadcrumb p-0 bg-transparent  mb-0">
                        <li class="breadcrumb-item">
                            <a class="txtseconday" href="javascript:void(0);" [routerLink]="['/seller/mylisting']">My
                                Products </a>
                        </li>
                        <li class="breadcrumb-item active">Add Product</li>
                    </ol>
                </nav>
            </div>
            <div class="col-sm-6 col-5 text-right pl-0">
                &nbsp;
            </div>
        </div>
        <!--- /HEAD  --->
        <div class="row d-flex align-items-center mb-20">
            <div class="col-6 col-md-6">
                <h3 class="f-700 fs22 txtdefault pb-0"><a href="javascript:;" [routerLink]="['/seller/mylisting']"
                        class="go-back"><i class="fa fa-angle-left"></i></a> Add Product</h3>
            </div>
            <div class="col-6 col-5 text-right pl-0">
                <a href="javascript:void(0);" (click)="openPreview(unique_id, active_variant_id)"
                    class="btn buybtn fs16 radius10">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17.391" height="12.976" viewBox="0 0 17.391 12.976">
                        <g id="Icon_feather-eye" data-name="Icon feather-eye" transform="translate(-0.9 -5.4)">
                            <path id="Path_10492" data-name="Path 10492"
                                d="M1.5,11.888S4.444,6,9.6,6s8.1,5.888,8.1,5.888-2.944,5.888-8.1,5.888S1.5,11.888,1.5,11.888Z"
                                fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="1.2" />
                            <path id="Path_10493" data-name="Path 10493"
                                d="M17.916,15.708A2.208,2.208,0,1,1,15.708,13.5,2.208,2.208,0,0,1,17.916,15.708Z"
                                transform="translate(-6.112 -3.82)" fill="none" stroke="#fff" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="1.2" />
                        </g>
                    </svg>
                    Buyer preview
                </a>
            </div>
        </div>
        <div class="row align-items-center mb-4 pb-4 mb-xs-0 pb-xs-0">
            <div class="col-sm-12 text-center text-lg-left">
                <ul class="prdnav">
                    <li class="active">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 8.591 7.338">
                                <path id="np_tick_1318229_000000"
                                    d="M37.756,43.764a.449.449,0,1,0-.615.655l2.394,2.244a.449.449,0,0,0,.662-.051l4.189-5.386a.449.449,0,1,0-.708-.552l-3.888,5Z"
                                    transform="translate(-36.444 -39.946)" fill="#1f1f37" stroke="#1f1f37"
                                    stroke-width="1" />
                            </svg>
                        </span>
                        <p class="mb-1 fs16">Select Product - - -</p>
                    </li>
                    <li>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 8.591 7.338">
                                <path id="np_tick_1318229_000000"
                                    d="M37.756,43.764a.449.449,0,1,0-.615.655l2.394,2.244a.449.449,0,0,0,.662-.051l4.189-5.386a.449.449,0,1,0-.708-.552l-3.888,5Z"
                                    transform="translate(-36.444 -39.946)" fill="#1f1f37" stroke="#1f1f37"
                                    stroke-width="1" />
                            </svg>
                        </span>
                        <p class="mb-1 fs16">Product Details</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="el-crousal">
            <div class="row mb-4 pb-3 mb-xs-0 pb-xs-0">
                <div class="col-md-8">
                    <div class="bid-order-info">
                        <div class="order-img" *ngIf="slides.length > 0">
                            <div class="feedback-slides  ">
                                <div class="client-feedback dtlfull">
                                    <ngx-slick-carousel class="carousel feedback" #slickModal="slick-carousel"
                                        [config]="imagesSlider">
                                        <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                                            <img src="{{ slide.img }}" alt="">
                                        </div>
                                    </ngx-slick-carousel>
                                </div>
                            </div>

                        </div>
                        <div class="pd-seller-cntnt pt-4">
                            <!--Product Name-->
                            <h5 class="mb-10 fs16 wt700">{{ info.name }}</h5>
                            <!-- 	Vivo X60 Pro (Midnight Black, 12GB RAM, 256GB Storage) | Upto 12 Months No Cost EMI | Extra 4000 Off on Exchange -->

                        </div>
                    </div>
                </div>
                <div class="col-md-4 pt-4 pt-xs-10">
                    <div class="d-flex align-items-center justify-content-end">
                        &nbsp; Listing Status &nbsp;&nbsp;
                        <div class="tglbtn dactivestatus inactivedata">
                            <label class="switch">
                                <input type="checkbox" [(ngModel)]="listing_status" (change)="updatestatus('listing')">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="client-thumbnails dtlthmb text-center">
                <ngx-slick-carousel class="carousel thumbs" #slickModal="slick-carousel" [config]="thumbnailsSlider ">
                    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                        <div class="thmbnaildtl">
                            <img src="{{ slide.img }}" alt="" width="100%">
                        </div>
                    </div>
                </ngx-slick-carousel>
            </div>
        </div>
        <!-- TABING -->
        <div class="add-pro-wrap">
            <a *ngIf="variants.length > 0 && is_variant == true" (click)="openWindowCustomClass(content)"
                href="javascript:void(0);" class="btn-d-outline d-inline-block addltpro">
                <svg xmlns="http://www.w3.org/2000/svg" width="14.681" height="14.681" viewBox="0 0 14.681 14.681">
                    <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1 1)">
                        <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181" transform="translate(-11.659 -7.5)"
                            fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" />
                        <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181" transform="translate(-7.5 -11.659)"
                            fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" />
                    </g>
                </svg>
                Add/Delete Product Variant

            </a>
            <ul ngbNav [(activeId)]="sampleactive" #prodtlnav="ngbNav" class="nav-tabs mbltype">
                <li *ngFor="let variant of active_variants; let i = index;" [ngbNavItem]="i+1"
                    (click)="getsellerproductdetails(variant.id)">
                    <a ngbNavLink>{{ variant.variant_name }}</a>

                </li>

            </ul>
        </div>

        <div class="add-pro-wrap">
            <div class="row">
                <!-- RIGHT COL -->
                <div class="col-md-12 ad-p-rgt">
                    <div class="card card-ui p-0">
                        <div class="card-body add-pro-wrap pt-0 p-4 xs-scroller">
                            <ul ngbNav #prdctinfonav="ngbNav" [(activeId)]="active" class="nav-tabs">

                                <!-- Consent -->
                                <li [ngbNavItem]="9">
                                    <a ngbNavLink>Consent</a>
                                    <ng-template ngbNavContent>
                                        <form [formGroup]="consentForm">
                                            <div class="profileipt editlisting">
                                                <div class="d-flex align-items-center justify-content-end mt-20">
                                                    Variant Status &nbsp;&nbsp;
                                                    <div class="tglbtn dactivestatus inactivedata">
                                                        <label class="switch">
                                                            <input type="checkbox" [(ngModel)]="variant_status"
                                                                [ngModelOptions]="{standalone: true}"
                                                                (change)="updatestatus('variant')">
                                                            <span class="slider round"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="form-container">
                                                    <div class="mb-4 pb-2 staticlabel">
                                                        <div class="css-check mb-2">
                                                            <input name="legit_purchase" class="css-checkbx"
                                                                type="checkbox" formControlName="legit_purchase" />
                                                            <label class="css-label txtdefault wt700">I have a legit
                                                                Purchase Invoice / Brand Authorization letter. I will be
                                                                able to share the same as when asked.
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <!-- INPUT ROW -->
                                                    <div class="floating-label mb-10 ">
                                                        <input type="text" class="form-control" placeholder=" "
                                                            disabled>
                                                        <button class="btn btn-outline-secondary fs16 btn-o-default"
                                                            type="button">
                                                            <span>Upload</span>
                                                            <input type="file" class="upldfile"
                                                                (change)="uploadfile($event, 'consent')">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18.71"
                                                                height="12.107" viewBox="0 0 18.71 12.107">
                                                                <g id="Group_15719" data-name="Group 15719"
                                                                    transform="translate(0 0)">
                                                                    <path id="Path_2337" data-name="Path 2337"
                                                                        d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z"
                                                                        transform="translate(-14.582 -27.082)"
                                                                        fill="#ff7135" />
                                                                    <g id="Group_15718" data-name="Group 15718">
                                                                        <g id="Group_15717" data-name="Group 15717">
                                                                            <g id="Group_15716" data-name="Group 15716">
                                                                                <path id="Path_2338"
                                                                                    data-name="Path 2338"
                                                                                    d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4"
                                                                                    transform="translate(-36.046 -39.347)"
                                                                                    fill="#ff7135" />
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>

                                                        </button>
                                                        <span class="highlight"></span>
                                                        <label>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="7.532"
                                                                height="15.065" viewBox="0 0 7.532 15.065">
                                                                <path id="Icon_material-attach-file"
                                                                    data-name="Icon material-attach-file"
                                                                    d="M17.005,4.924V12.8a2.739,2.739,0,1,1-5.478,0V4.239a1.712,1.712,0,0,1,3.424,0v7.19a.685.685,0,0,1-1.37,0V4.924H12.554v6.505a1.712,1.712,0,1,0,3.424,0V4.239a2.739,2.739,0,0,0-5.478,0V12.8a3.766,3.766,0,0,0,7.532,0V4.924Z"
                                                                    transform="translate(-10.5 -1.5)" fill="#5e6a7c" />
                                                            </svg>
                                                            Upload Document
                                                        </label>
                                                        <div class="fs14 pt-2 wt500 txtseconday">
                                                            Upload Document of Purchase Invoice/ Brand authorization
                                                            letter
                                                        </div>
                                                    </div>
                                                    <!-- /INPUT ROW -->
                                                    <div class="form-container form-ipt-info lstnobd cstm-checkbx">
                                                        <div class="mb-2">
                                                            <div class="css-check mb-2">
                                                                <input name="terms_conditions" class="css-checkbx" type="checkbox" formControlName="terms_conditions" />
                                                                <label class="css-label txtseconday ">
                                                                    <p class="wt500">I acknowledge that I have read, and do hereby accept the <a class="link-alt"href="#" [routerLink]="['/terms-of-use']" target="_blank">terms and conditions</a> for products to follow the guidelines laid out by Government and/or Equibiz for 1. IMEI Certification from GSM association 2. GPS and Panic Button (ERSS), and 3. All applicable legislations under BIS CRS/WPC Product Safety and Standards Regulations
                                                                    </p>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="mb-2">
                                                            <div class="css-check mb-3">
                                                                <input name="govt_conditions" class="css-checkbx"
                                                                    type="checkbox" formControlName="govt_conditions" />
                                                                <label class="css-label txtseconday ">
                                                                    <p class="wt500">I acknowledge that I have read, and
                                                                        do hereby accept the terms and conditions for
                                                                        products to follow Government and/or Equibiz
                                                                        BIS-CRS-ISI BEE requirements </p>
                                                                </label>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="pt-4">
                                                        <button type="button" class="btn buybtn fs16 radius10 ripple"
                                                            [disabled]="consentForm.invalid"
                                                            (click)="submitConsent()">Save and Next</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </ng-template>
                                </li>
                                <!-- /Consent -->

                                <li [ngbNavItem]="10">
                                    <a ngbNavLink>Unique ID</a>
                                    <ng-template ngbNavContent>
                                        <form [formGroup]="uniqueForm">
                                            <div class="d-flex align-items-center justify-content-end mt-20">
                                                Variant Status &nbsp;&nbsp;
                                                <div class="tglbtn dactivestatus inactivedata">
                                                    <label class="switch">
                                                        <input type="checkbox" [(ngModel)]="variant_status"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (change)="updatestatus('variant')">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>

                                            <div class="form-container">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="floating-label">
                                                            <input placeholder="" disabled class="form-control" value=""
                                                                formControlName="eb_product_id">
                                                            <span class="highlight"></span>
                                                            <label>Equibiz Product Identification Number (EBPID)</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="floating-label">
                                                            <input placeholder=" " class="form-control" value=""
                                                                formControlName="sku">
                                                            <span class="highlight"></span>
                                                            <label>SKU</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="floating-label">
                                                            <div class="ng-autocomplete">
                                                                <ng-autocomplete [data]="hsns" [searchKeyword]="keyword"
                                                                    (selected)='selectHsnEvent($event)'
                                                                    (inputChanged)='onChangeSearchHsn($event)'
                                                                    (inputFocused)='onFocusedHsn($event)'
                                                                    [itemTemplate]="itemTemplate" formControlName="hsn"
                                                                    placeholder="HSN Number">
                                                                </ng-autocomplete>

                                                                <ng-template #itemTemplate let-item>
                                                                    <a href="javascript:void(0)">
                                                                        <div class="d-flex">
                                                                            <span [innerHTML]="item.name"></span>
                                                                        </div>
                                                                    </a>
                                                                </ng-template>
                                                                <div class="search-icon"><img
                                                                        src="assets/images/svg/search_logo.svg"></div>
                                                            </div>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                        <!-- <div><a href="javascript:;" class="link-alt fs14">Find relevant HSN Code</a></div> -->
                                                    </div>

                                                    <div class="col-md-6"
                                                        *ngIf="((info.seller_type != 'registered-brand-owner' && info.is_crs == 'yes' ) || (info.seller_type == 'registered-brand-owner' ))">
                                                        <div class="floating-label">
                                                            <input placeholder=" " class="form-control" value=""
                                                                formControlName="crs"
                                                                [disabled]="(info.seller_type == 'registered-brand-owner' && info.is_crs == 'yes')">
                                                            <span class="highlight"></span>
                                                            <label>Compulsory Registration Scheme (CRS)</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <button class="btn buybtn fs16 radius10 ripple"
                                                [disabled]="uniqueForm.invalid" (click)="submitUnique()">Save and Next</button>
                                        </form>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="11">
                                    <a ngbNavLink>Product Details</a>
                                    <ng-template ngbNavContent>
                                        <form [formGroup]="productForm">
                                            <div class="d-flex align-items-center justify-content-end mt-20">
                                                Variant Status &nbsp;&nbsp;
                                                <div class="tglbtn dactivestatus inactivedata">
                                                    <label class="switch">
                                                        <input type="checkbox" [(ngModel)]="variant_status"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (change)="updatestatus('variant')">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>

                                            <div class="form-container">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="floating-label">
                                                            <input placeholder=" " class="form-control"
                                                                value=""
                                                                formControlName="market_operated_price">
                                                            <span class="highlight"></span>
                                                            <label>Market Operated Price</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                            <span class="help-block"
                                                            *ngIf="prf.market_operated_price.invalid && prf.market_operated_price.touched">Please
                                                            enter valid operating price</span>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="floating-label">
                                                            <select class="form-control" formControlName="country">
                                                                <option selected="selected" value="">Please Select
                                                                </option>
                                                                <option *ngFor="let code of countryArray"
                                                                    [ngValue]="code.value">{{code.name}}</option>
                                                            </select>
                                                            <span class="highlight"></span>
                                                            <label>Country of Origin<span class="req">*</span></label>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="floating-label">
                                                            <select class="form-control"
                                                                formControlName="no_item_perpack">
                                                                <option selected="selected" value="">Select</option>
                                                                <option *ngFor="let code of packArray"
                                                                    [ngValue]="code.value">{{code.name}}</option>
                                                            </select>
                                                            <span class="highlight"></span>
                                                            <label>Pack of<span class="req">*</span></label>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="floating-label">
                                                            <select class="form-control"
                                                                formControlName="product_status">
                                                                <option selected="selected" value="">Select</option>
                                                                <option *ngFor="let code of product_status_array"
                                                                    [ngValue]="code.value">{{code.label}}</option>
                                                            </select>
                                                            <span class="highlight"></span>
                                                            <label>Product Status<span class="req">*</span></label>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-12" *ngIf="child_product">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="floating-label">
                                                                    <input class="form-control pr-5" placeholder="" formControlName="total_minimum_order_qty">
                                                                    <span class="highlight"></span>
                                                                    <label>Wholesale - Total Minimum Order Quantity</label>
                                                                    <i placement="top"
                                                                        ngbTooltip="Tooltop content goes here"
                                                                        class="infoinput info-alert"></i>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6" *ngIf="info.admin_bulk">
                                                                <div class="floating-label">
                                                                    <input class="form-control pr-5" placeholder="" formControlName="total_minimum_order_qty_bulk">
                                                                    <span class="highlight"></span>
                                                                    <label>Bulk - Total Minimum Order Quantity</label>
                                                                    <i placement="top"
                                                                        ngbTooltip="Tooltop content goes here"
                                                                        class="infoinput info-alert"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        
                                                </div>
                                            </div>

                                            <hr class="mt-0 mb-30">

                                            <h4 class="fs16 f-700 mb-4">Product Dimensions</h4>

                                            <div class="form-container">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <div class="floating-label">
                                                                    <input placeholder=" " class="form-control"
                                                                        value="328 cm" formControlName="length">
                                                                    <span class="highlight"></span>
                                                                    <label>Length (in cm)</label>
                                                                    <i placement="top"
                                                                        ngbTooltip="Tooltop content goes here"
                                                                        class="infoinput info-alert"></i>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="floating-label">
                                                                    <input placeholder=" " class="form-control"
                                                                        value="128 cm" formControlName="width">
                                                                    <span class="highlight"></span>
                                                                    <label>Width (in cm)</label>
                                                                    <i placement="top"
                                                                        ngbTooltip="Tooltop content goes here"
                                                                        class="infoinput info-alert"></i>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="floating-label">
                                                                    <input placeholder=" " class="form-control"
                                                                        value="328 cm" formControlName="height">
                                                                    <span class="highlight"></span>
                                                                    <label>Height (in cm)</label>
                                                                    <i placement="top"
                                                                        ngbTooltip="Tooltop content goes here"
                                                                        class="infoinput info-alert"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="floating-label">
                                                            <input placeholder=" " class="form-control"
                                                                value="228 grams" formControlName="deadweight">
                                                            <span class="highlight"></span>
                                                            <label>Dead Weight</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 selectactive">
                                                        <ng-selectize [config]="config" [options]="deatweight_units"
                                                            formControlName="deadweight_unit" placeholder="Select">
                                                        </ng-selectize>
                                                    </div>
                                                </div>
                                            </div>

                                            <hr class="mt-0 mb-30">

                                            <h4 class="fs16 f-700 mb-4">Warranty</h4>
                                            <div class="custom-control custom-checkbox mb-0">
                                                <input type="checkbox" class="custom-control-input"
                                                    id="agrcustomCheckBox" name="checkbox1"
                                                    formControlName="warranty_by_brand"
                                                    (change)="isWarrantyChange($event)">
                                                <label class="custom-control-label" for="agrcustomCheckBox">
                                                    Warranty facilitated by Brand
                                                </label>
                                            </div>
                                            <!-- FORM BLOCK REPEAT -->
                                            <div class="form-container"
                                                *ngIf="!productForm.get('warranty_by_brand').value">
                                                <div class="row">

                                                    <div class="col-sm-8 mb-4 col-12 col-xl-9">
                                                        <div class="floating-label  mb-0">
                                                            <input class="form-control pr-5" placeholder=" "
                                                                formControlName="domestic_warranty">
                                                            <span class="highlight"></span>
                                                            <label>Domestic Warranty</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4 mb-4 col-12 pl-sm-0 col-xl-3 selectactive">
                                                        <ng-selectize [config]="config" [options]="units"
                                                            formControlName="domestic_warranty_unit"
                                                            placeholder="Select"> </ng-selectize>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-8 mb-4 col-12 col-xl-9">
                                                        <div class="floating-label mb-0">
                                                            <input class="form-control pr-5" placeholder=" "
                                                                formControlName="international_warranty">
                                                            <span class="highlight"></span>
                                                            <label>International Warranty</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4 mb-4 col-12 pl-md-0 col-xl-3 selectactive">
                                                        <ng-selectize [config]="config"
                                                            formControlName="international_warranty_unit"
                                                            [options]="units" placeholder="Select"> </ng-selectize>
                                                    </div>
                                                </div>
                                                <div class="floating-label">
                                                    <!-- <input class="form-control pr-5" placeholder=" "
                                                        formControlName="warranty_summary">
                                                    <span class="highlight"></span>
                                                    <label>Warranty Summary</label>
                                                    <i placement="top" ngbTooltip="Tooltop content goes here"
                                                        class="infoinput info-alert"></i> -->
                                                </div>
                                                <div class="floating-label">
                                                    <input class="form-control pr-5" placeholder=" "
                                                        formControlName="warranty_server_type">
                                                    <span class="highlight"></span>
                                                    <label>Warranty Service Type</label>
                                                    <i placement="top" ngbTooltip="Tooltop content goes here"
                                                        class="infoinput info-alert"></i>
                                                </div>
                                            </div>
                                            <!-- /FORM BLOCK REPEAT -->


                                         
                                        </form>
                                        <angular-editor *ngIf="!productForm.get('warranty_by_brand').value" [(ngModel)]="warranty_summary" [config]="editor_config"></angular-editor>
                                        <div class="mt-40">
                                            <button type="button" class="btn px-4 buybtn fs16 radius10"
                                                (click)="submitProduct()">Save and Next</button>
                                        </div>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="12">
                                    <a ngbNavLink>Marketplace</a>
                                    <ng-template ngbNavContent class="slrtypeui">
                                        <form [formGroup]="marketplaceForm">
                                            <div class="d-flex align-items-center justify-content-end mt-20">
                                                Variant Status &nbsp;&nbsp;
                                                <div class="tglbtn dactivestatus inactivedata">
                                                    <label class="switch">
                                                        <input type="checkbox" [(ngModel)]="variant_status"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (change)="updatestatus('variant')">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="form-container form-ipt-info lstnobd">
                                                <div class="mb-2" *ngIf="info.admin_wholesale">
                                                    <div class="css-check mb-2">
                                                        <input name="wholesale" class="css-checkbx" type="checkbox"
                                                            formControlName="wholesale"
                                                            (change)="changemarketplace('wholesale')" />
                                                        <label class="css-label txtdefault wt700">Wholesale
                                                            <p class="wt500">Sell your products to small buyers and
                                                                retailers across the country and expand your business
                                                                and visibility.</p>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="mb-2" *ngIf="info.admin_bulk">
                                                    <div class="css-check mb-3">
                                                        <input name="bulk" class="css-checkbx" type="checkbox"
                                                            formControlName="bulk"
                                                            (change)="changemarketplace('bulk')" />
                                                        <label class="css-label txtdefault wt700">Bulk
                                                            <p class="wt500">List and sell products to bulk buyers,
                                                                stockiest, corporate, and large buyers, and offload
                                                                inventory at your price.</p>
                                                        </label>
                                                    </div>
                                                    <div class="css-check ml-4" *ngIf="info.admin_bid">
                                                        <input name="bid_offer" class="css-checkbx" type="checkbox"
                                                            formControlName="bid_offer"
                                                            (change)="changemarketplace('bid_offer')" />
                                                        <label class="css-label txtdefault wt700">Bid & Offers
                                                            <p class="wt500">Participate in Bid and Offer and engage
                                                                with multiple buyers with their bids and explore the
                                                                world of price discovery with online negotiation and
                                                                comparison. Set your minimum bid price and select the
                                                                best bid and close the deal at your preferred price.</p>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-20">
                                                <button type="button" class="btn px-4 buybtn fs16 radius10"
                                                    [disabled]="marketplaceForm.invalid"
                                                    (click)="submitMarketplace()">Save and Next</button>
                                            </div>
                                        </form>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="15" *ngIf="child_product">
                                    <a ngbNavLink>Child Products</a>
                                    <ng-template ngbNavContent>
                                        <form>
                                            <!-- FORM BLOCK REPEAT -->
                                            <div class="card card-ui p-0 mb-3 childProducts" *ngFor="let child of child_records; let j = index;">
                                                <div class="card-body p-3">
                                                    <div class="row d-flex align-items-center justify-content-between">
                                                        <div class="col-md-6">
                                                            <div class="d-flex align-items-center">
                                                                <div class="image"><img src="{{ child.image }}"></div>
                                                                <h4 class="fs16 f-700">{{ child.name }}</h4>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-6">
                                                            <div class="tglbtn dactivestatus inactivedata" style="text-align:right">
                                                                <label class="switch">
                                                                    <input type="checkbox" [(ngModel)]="child.status" name="wholesale_status{{j+1}}"
                                                                        [ngModelOptions]="{standalone: true}">
                                                                    <span class="slider round"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="py-3">
                                                        <a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3" (click)="openaddchildquantitymodal(childaddqtymodal, 'wholesale', child)">Wholesale - Add/Reduce Qty</a>
                                                        <a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3" (click)="openaddchildquantitymodal(childaddqtymodal, 'bulk', child)">Bulk - Add/Reduce Qty</a>
                                                    </div>

                                                    <h4 class="fs16 f-700 mb-2" *ngIf="marketplaceForm.get('wholesale').value">
                                                        Wholesale</h4>
                                                    
                                                    <div class="form-container mt-10 mb-10" *ngIf="marketplaceForm.get('wholesale').value">
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <div class="floating-label mb-0">
                                                                    <input class="form-control pr-5" name="wholesale_price{{j+1}}" [(ngModel)]="child.wholesale_price">
                                                                    <span class="highlight"></span>
                                                                    <label>Listed Price</label>
                                                                    <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                        class="infoinput info-alert"></i>
                                                                </div>
                                                            </div>

                                                            <div class="col-md-8">
                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <div class="floating-label mb-0">
                                                                            <input class="form-control pr-5" name="wholesale_qty{{j+1}}" [(ngModel)]="child.wholesale_qty" disabled >
                                                                            <span class="highlight"></span>
                                                                            <label>Stock Quantity</label>
                                                                            <i placement="top"
                                                                                ngbTooltip="Tooltop content goes here"
                                                                                class="infoinput info-alert"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <div class="floating-label mb-0">
                                                                            <input class="form-control pr-5" name="wholesale_min_qty{{j+1}}"
                                                                                [(ngModel)]="child.wholesale_min_qty">
                                                                            <span class="highlight"></span>
                                                                            <label>MOQ</label>
                                                                            <i placement="top"
                                                                                ngbTooltip="Tooltop content goes here"
                                                                                class="infoinput info-alert"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <h4 class="fs16 f-700 mb-2" *ngIf="marketplaceForm.get('bulk').value">
                                                        Bulk</h4>
                                                    
                                                    <div class="form-container mt-10 mb-10" *ngIf="marketplaceForm.get('bulk').value">
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <div class="floating-label">
                                                                    <input class="form-control pr-5" name="bulk_price{{j+1}}" [(ngModel)]="child.bulk_price">
                                                                    <span class="highlight"></span>
                                                                    <label>Listed Price</label>
                                                                    <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                        class="infoinput info-alert"></i>
                                                                </div>
                                                            </div>

                                                            <div class="col-md-8">
                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <div class="floating-label">
                                                                            <input class="form-control pr-5" name="bulk_qty{{j+1}}" [(ngModel)]="child.bulk_qty" disabled >
                                                                            <span class="highlight"></span>
                                                                            <label>Stock Quantity</label>
                                                                            <i placement="top"
                                                                                ngbTooltip="Tooltop content goes here"
                                                                                class="infoinput info-alert"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <div class="floating-label">
                                                                            <input class="form-control pr-5" name="bulk_min_qty{{j+1}}"
                                                                                [(ngModel)]="child.bulk_min_qty">
                                                                            <span class="highlight"></span>
                                                                            <label>MOQ</label>
                                                                            <i placement="top"
                                                                                ngbTooltip="Tooltop content goes here"
                                                                                class="infoinput info-alert"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    

                                                    <div class="col-md-12 text-right">
                                                        <button type="button" class="btn btn-xs px-4 buybtn fs16 radius10" (click)="updatechild(child)">Update</button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="mt-20">
                                                <button type="button" class="btn px-4 buybtn fs16 radius10" (click)="submitChild()">Next</button>
                                            </div>
                                        </form>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="13" *ngIf="!child_product">
                                    <a ngbNavLink>Stock & Pricing</a>
                                    <ng-template ngbNavContent>
                                        <form [formGroup]="pricingForm">
                                            <div class="d-flex align-items-center justify-content-end mt-20">
                                                Variant Status &nbsp;&nbsp;
                                                <div class="tglbtn dactivestatus inactivedata">
                                                    <label class="switch">
                                                        <input type="checkbox" [(ngModel)]="variant_status"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (change)="updatestatus('variant')">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>

                                            <h4 class="fs16 f-700 mb-4" *ngIf="marketplaceForm.get('wholesale').value">
                                                Wholesale</h4>

                                            <!-- FORM BLOCK REPEAT -->
                                            <div class="form-container" *ngIf="marketplaceForm.get('wholesale').value">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="floating-label">
                                                            <input class="form-control pr-5" placeholder=" "
                                                                formControlName="wholesale_price"
                                                                (change)="netrealizationvalue('wholesale')">
                                                            <span class="highlight"></span>
                                                            <label>Listed Price</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                        <div class="alert alert-warning p-2"  *ngIf="wholesale_price_warning">
                                                            <div class="fs13"><b>Warning:</b>  {{ wholesale_price_warning_msg }}</div>
                                                        </div>

                                                        <div class="alert alert-danger p-2"  *ngIf="wholesale_mrp_price_error_msg">
                                                            <div class="fs13"><b>Error:</b>  {{ wholesale_mrp_price_error_msg }}</div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="floating-label">
                                                                    <input class="form-control pr-5" placeholder=" "
                                                                        formControlName="wholesale_qty" disabled>
                                                                    <a href="javascript:;" (click)="openaddquantitymodal(addqtymodal, 'wholesale')">Add/Reduce Qty</a>
                                                                    <span class="highlight"></span>
                                                                    <label>Total Stock Quantity</label>
                                                                    <i placement="top"
                                                                        ngbTooltip="Tooltop content goes here"
                                                                        class="infoinput info-alert"></i>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="floating-label">
                                                                    <input class="form-control pr-5" placeholder=" "
                                                                        formControlName="wholesale_min_qty"
                                                                        (change)="netrealizationvalue('wholesale')">
                                                                    <span class="highlight"></span>
                                                                    <label>Minimum Order Quantity</label>
                                                                    <i placement="top"
                                                                        ngbTooltip="Tooltop content goes here"
                                                                        class="infoinput info-alert"></i>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="floating-label">
                                                                    <p style="margin-bottom: 5px;">Spice Money Offers</p>
                                                                    <ng-selectize 
                                                                    [config]="config" 
                                                                    [options]="spicemoney_options"
                                                                    formControlName="spicemoney_percentage"
                                                                    placeholder="Spice Money Offers"> 
                                                                    </ng-selectize>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6"
                                                        *ngIf="wholesale_realization_details.net_realization != ''">
                                                        Net Realization Value: <b>{{
                                                            wholesale_realization_details.net_realization }}</b> &nbsp;
                                                        <i placement="top" ngbTooltip="View Price Breakup"
                                                            (click)="openbreakup(contentbreakup, 'wholesale')"><img
                                                                src="assets/images/icons/info.png"></i>
                                                        <div class="fs12 mt-2">
                                                            <p>The net realization value is shown here for your cash flow understanding of a platform deal which is based on your listed price ,MOQ as quantity and tentative platform charges.</p>
                                                            <p>Please check the actual realization amount of your deal in the order section.</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <hr class="mt-0 mb-30" *ngIf="marketplaceForm.get('wholesale').value">

                                            <h4 class="fs16 f-700 mb-4" *ngIf="marketplaceForm.get('bulk').value">Bulk
                                            </h4>

                                            <!-- FORM BLOCK REPEAT -->
                                            <div class="form-container" *ngIf="marketplaceForm.get('bulk').value">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="floating-label">
                                                            <input class="form-control pr-5" placeholder=" "
                                                                formControlName="bulk_price"
                                                                (change)="netrealizationvalue('bulk')">
                                                            <span class="highlight"></span>
                                                            <label>Listed Price</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                        <div class="alert alert-warning p-2" *ngIf="bulk_price_warning">
                                                            <div class="fs13"><b>Warning:</b> {{ bulk_price_warning_msg }}</div>
                                                        </div>
                                                        <div class="alert alert-danger p-2"  *ngIf="bulk_mrp_price_error_msg">
                                                            <div class="fs13"><b>Error:</b>  {{ bulk_mrp_price_error_msg }}</div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-4">
                                                        <div class="floating-label">
                                                            <input class="form-control pr-5" placeholder=" " value=""
                                                                formControlName="bulk_qty" disabled>
                                                                <a href="javascript:;" (click)="openaddquantitymodal(addqtymodal, 'bulk')">Add/Reduce Qty</a>
                                                            <span class="highlight"></span>
                                                            <label>Total Stock Quantity</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-4"
                                                        *ngIf="marketplaceForm.get('bid_offer').value">
                                                        <div class="floating-label">
                                                            <input class="form-control pr-5" placeholder=" "
                                                                formControlName="lowest_bid_amt">
                                                            <span class="highlight"></span>
                                                            <label>Lowest Bid Price (Bids & Offers)</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-4"
                                                        *ngIf="marketplaceForm.get('bulk').value">
                                                        <div class="floating-label">
                                                            <input class="form-control pr-5" placeholder=" "
                                                                formControlName="bid_upper_moq">
                                                            <span class="highlight"></span>
                                                            <label>Upper MOQ</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-4"
                                                        *ngIf="marketplaceForm.get('bulk').value">
                                                        <div class="floating-label">
                                                            <input class="form-control pr-5" placeholder=" "
                                                                formControlName="bid_lower_moq"
                                                                (change)="netrealizationvalue('bulk')">
                                                            <span class="highlight"></span>
                                                            <label>Lower MOQ</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6"
                                                        *ngIf="bulk_realization_details.net_realization != ''">
                                                        Net Realization Value: <b>{{
                                                            bulk_realization_details.net_realization }}</b> &nbsp;
                                                        <i placement="top" ngbTooltip="View Price Breakup"
                                                            (click)="openbreakup(contentbreakup, 'bulk')"><img
                                                                src="assets/images/icons/info.png"></i>
                                                        <div class="fs12 mt-2">
                                                            <p>The net realization value is shown here for your cash flow understanding of a platform deal which is based on your listed price ,MOQ as quantity and tentative platform charges.</p>
                                                            <p>Please check the actual realization amount of your deal in the order section.</p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6"
                                                        *ngIf="bulk_realization_details.net_realization != ''"
                                                        style="display: none;">
                                                        Net Realization Value (Express): <b>{{
                                                            bulk_realization_details.net_realization }}</b> &nbsp;
                                                        <i placement="top" ngbTooltip="View Price Breakup"
                                                            (click)="openbreakup(contentbreakup, 'bulk')"><img
                                                                src="assets/images/icons/info.png"></i>
                                                        <div class="fs12 mt-2">
                                                            <p>The net realization value is shown here for your cash flow understanding of a platform deal which is based on your listed price ,MOQ as quantity and tentative platform charges.</p>
                                                            <p>Please check the actual realization amount of your deal in the order section.</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="mt-20">
                                                <button type="button" class="btn px-4 buybtn fs16 radius10"
                                                    [disabled]="pricingForm.invalid || bulk_mrp_price_error_msg || wholesale_mrp_price_error_msg" (click)="submitPricing()">Save and
                                                    Next</button>
                                            </div>
                                        </form>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="14">
                                    <a ngbNavLink>Payment & Shipment</a>
                                    <ng-template ngbNavContent>
                                        <form [formGroup]="paymentForm">
                                            <div class="d-flex align-items-center justify-content-end mt-20">
                                                Variant Status &nbsp;&nbsp;
                                                <div class="tglbtn dactivestatus inactivedata">
                                                    <label class="switch">
                                                        <input type="checkbox" [(ngModel)]="variant_status"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (change)="updatestatus('variant')">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>

                                            <h4 class="fs16 f-700 mb-4">Product Location</h4>
                                            <div class="floating-label mb-10 qs-chk-rp">
                                                <div class="row">
                                                    <div class="col-md-12"
                                                        *ngFor="let addr of seller_address; let k = index;">
                                                        <div class="cssinptchk">
                                                            <span class="css-check">
                                                                <input class="css-checkbx" name="product_location"
                                                                    formControlName="product_location" value="{{k}}"
                                                                    type="radio" />
                                                                <label class="css-label"></label>
                                                            </span>
                                                            <p>
                                                                <b>{{ addr.address }}, {{ addr.city }}, {{ addr.state }}
                                                                    {{ addr.pincode }}</b>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <h4 class="fs16 f-700 mb-4">Payment Type (Bulk)</h4>
                                            <div class="floating-label mb-10 qs-chk-rp"
                                                *ngIf="marketplaceForm.get('bulk').value">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="cssinptchk">
                                                            <span class="css-check">
                                                                <input class="css-checkbx" name="payment_type"
                                                                    formControlName="payment_type" value="full_payment"
                                                                    type="radio" />
                                                                <label class="css-label"></label>
                                                            </span>
                                                            <p>
                                                                <b>Full Advance Payment</b><br />
                                                                <span class="fs12">This payment method selection is
                                                                    recommended in case of listings which may result in
                                                                    lower deal value for the buyers. Recommended value
                                                                    is less than INR 50,000/.<br>
                                                                    <b>For example,</b> listing price of your product is
                                                                    1000 and MOQ is 25 , the expected deal value in case
                                                                    will be INR 25000 and therefore use this payment
                                                                    collection method for higher chance of deal making
                                                                    from buyers.</span>
                                                            </p>

                                                        </div>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <div class="cssinptchk">
                                                            <span class="css-check">
                                                                <input class="css-checkbx" name="payment_type"
                                                                    formControlName="payment_type" value="token_payment"
                                                                    type="radio" />
                                                                <label class="css-label"></label>
                                                            </span>
                                                            <p>
                                                                <b>Token & Final payment before dispatch</b>
                                                                &nbsp;&nbsp;<span class="pd-taglist"><span
                                                                        class="f-600 fs10 radius15">Equibiz
                                                                        Suggested</span></span> <br />
                                                                <span class="fs12">This payment method selection is
                                                                    recommended in case of listings which may result in
                                                                    higher deal value for the buyers. Recommended value
                                                                    is INR 50,000/ and above.<br>
                                                                    <b>For example,</b> listing price of your product is
                                                                    3000 and MOQ is 25 , the expected deal value in case
                                                                    will be INR 75000 and therefore use this payment
                                                                    collection method for higher chance of deal making
                                                                    from buyers.</span>
                                                            </p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center"
                                                *ngIf="marketplaceForm.get('wholesale').value">
                                                <div class="mr-2"><img src="assets/images/icons/info.png"></div>
                                                <div>For wholesale - full payment option by default</div>
                                            </div>

                                            <!-- <h4 class="fs16 f-700 mb-4">Payment Type (Bulk)</h4>

										<div class="custom-control custom-checkbox mb-20">
											<input type="checkbox" class="custom-control-input" checked="" id="full_advance_payment" name="full_advance_payment" formControlName="full_advance_payment">
											<label class="custom-control-label opacity-1" for="full_advance_payment">
												<b>Full advance payment</b> <div class="fs12">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</div>
											</label>
										</div>

										<div class="custom-control custom-checkbox mb-20">
											<input type="checkbox" class="custom-control-input" checked="" id="token_payment_before_dispatch" name="token_payment_before_dispatch" formControlName="token_payment_before_dispatch">
											<label class="custom-control-label opacity-1" for="token_payment_before_dispatch">
												<b>Token & Final payment before dispatch</b> &nbsp;&nbsp;
												<span class="pd-taglist"><span class="f-600 fs10 radius15">Equibiz Suggested</span></span> 
												<div class="fs12">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</div>
											</label>
										</div> -->

                                            <hr class="mt-30 mb-30">

                                            <h4 class="fs16 f-700 mb-4">Delivery Date</h4>

                                            <div class="floating-label mb-10 qs-chk-rp">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="cssinptchk">
                                                            <span class="css-check">
                                                                <input class="css-checkbx" name="delivery_date"
                                                                    formControlName="delivery_date" value="1"
                                                                    type="radio" />
                                                                <label class="css-label"></label>
                                                            </span>
                                                            <p>Ready to Ship</p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <div class="cssinptchk">
                                                            <span class="css-check">
                                                                <input class="css-checkbx" name="delivery_date"
                                                                    formControlName="delivery_date" value="2"
                                                                    type="radio" />
                                                                <label class="css-label"></label>
                                                            </span>
                                                            <p>Dispatch in 24 hours</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="cssinptchk">
                                                            <span class="css-check">
                                                                <input class="css-checkbx" name="delivery_date"
                                                                    formControlName="delivery_date" value="3"
                                                                    type="radio" />
                                                                <label class="css-label"></label>
                                                            </span>
                                                            <p>Dispatch in 48 hours</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="cssinptchk">
                                                            <span class="css-check">
                                                                <input class="css-checkbx" name="delivery_date"
                                                                    formControlName="delivery_date" value="4"
                                                                    type="radio" />
                                                                <label class="css-label"></label>
                                                            </span>
                                                            <p>Dispatch after 2 days</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-20">
                                                <button type="button" class="btn px-4 buybtn fs16 radius10"
                                                    [disabled]="paymentForm.invalid" (click)="submitPayment()">Save
                                                    Changes</button>
                                            </div>
                                        </form>
                                    </ng-template>
                                </li>
                            </ul>

                            <div [ngbNavOutlet]="prdctinfonav" class="mt-2"></div>
                        </div>
                    </div>
                </div>
                <!-- /RIGHT COL -->
            </div>
        </div>
        <div [ngbNavOutlet]="prodtlnav" class="mt-2"></div>
        <!-- /TABING -->

        <div class="card card-ui mt-3 profileipt" *ngIf="!child_product">
            <h4 class="fs16 wt700 mb-2 txtdefault">Add Frequently bought together</h4>
            <hr>
            <!-- INPUT ROW -->
            <div class="floating-label mb-10">
                <div class="ng-autocomplete">
                    <ng-autocomplete [data]="result" 
                    [searchKeyword]="keyword" 
                    (selected)='selectEvent($event)'
                    (inputChanged)='onChangeSearch($event)'
                    (inputFocused)='onFocused($event)'
                    [itemTemplate]="itemTemplate" 
                    placeholder="Search by Product name">
                    </ng-autocomplete>
                    <ng-template #itemTemplate let-item>
                        <a href="javascript:void(0)">
                            <div class="d-flex">
                                <span [innerHTML]="item.name"></span>
                            </div>
                        </a>
                    </ng-template>
                    <div class="input-group-append-custom"><img src="assets/images/svg/search_logo.svg"></div>
                </div>
                <!-- <input type="text" class="form-control" placeholder="Search for you product"> -->
                <!-- <button class="btn btn-outline-secondary fs16 btn-o-default"
                    type="button">
                    <span>Upload</span>
                </button> -->
                <span class="highlight"></span>
            </div>
            <!-- /INPUT ROW -->

            <table class="table custom-table xs">
                <thead>
                    <tr>
                        <th>Product ID</th>
                        <th>Product Name</th>
                        <th>Total Stock</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <!--Empty State-->
                    <tr *ngIf="!combo_products_list.length">
                        <td colspan="7">
                            <div class="row pt-40 pb-40">
                                <div class="col-md-12 text-center">
                                    <div class="mb-20"><img
                                            src="/assets/images/empty-state.png">
                                    </div>
                                    <div class="fs16">There are no records to
                                        display!</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <!--End Empty State-->
                    <tr *ngFor="let rec of combo_products_list">
                        <td>{{ rec.product_code }}</td>
                        <td>
                            <div class="order-info">
                                <div class="order-img">
                                    <img src="{{ rec.image }}" />
                                </div>
                                <div class="pd-seller-cntnt">
                                    <div class="txtdefault">
                                        <a href="javascript:;" class="order-link">{{ rec.name }}</a>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>{{ rec.total_stock }}</td>
                        <td>
                            <div class="tglbtn no-evt">
                                <label class="switch">
                                    <input type="checkbox" disabled [(ngModel)]="rec.status">
                                    <span class="slider round"></span>
                                    <i>{{rec.status?'Active':'Inactive'}}</i>
                                </label>
                            </div>
                        </td>
                        <td class="d-flex align-items-center">
                            <a href="javascript:void(0);" class="btn-d-outline ripple d-inline-block place-bid" (click)="comboviewdetails(rec);">View Details</a>
                            <a href="javascript:void(0);" class="btn-d-outline ripple d-inline-block place-bid" (click)="removecombo(rec)">Remove</a>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>
</div>
<!-- REMOVE PRODUCT MODAL -->
<ng-template #content let-modal>
    <div class="modal-header nobd text-center pt-4 pb-0">
        <h4 class="modal-title fs28 txtdefault wt700 mx-auto pt-3">Add/Delete Product Variant</h4>
        <button type="button" class="btn-close close rmvmdl" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <h4 class="fs16 wt700 txtdefault mb-3">Variation types</h4>
        <ul class="varianttyp">
            <!-- REPEAT -->
            <li *ngFor="let variant of variants; let i = index;">
                <div class="css-check">
                    <input name="saletype" type="checkbox" class="css-checkbx" [(ngModel)]="variant.new">
                    <label class="css-label txtseconday">&nbsp;</label>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="pt-2 cs-item-row pb-2 fnobd">
                            <div class="d-table mb-2 w-100">
                                <div class="d-table-cell cart-thumb align-middle">
                                    <img [src]="variant.image" />
                                </div>
                                <div class="d-table-cell pl-3 align-middle">
                                    <p class="txtdefault wt700 mb-0 fs14 lnht20">{{ variant.name }}</p>
                                </div>
                            </div>
                            <div class="d-table mb-2 w-100">
                                <div class="d-table-cell cart-thumb align-middle"></div>
                                <div class="d-table-cell pl-3 align-middle">
                                    <div class="row align-items-center">
                                        <div class="col-sm-4 col-4 pr-0" *ngFor="let meta of variant.meta">
                                            <label class="txtseconday wt500">{{meta.label_name}}:</label>
                                            <p class="txtdefault wt500 mb-0">{{meta.variant_value}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </li>
            <!-- /REPEAT -->
        </ul>
    </div>
    <div class="modal-footer  text-center nobd mx-auto pb-4 mb-3">
        <button type="button" class="btn-d-outline d-inline-block cancel-outline mr-3"
            (click)="modal.close('Close click')">Cancel</button>
        <button type="button" class="btn buybtn fs16 radius10" (click)="updatevariant()">Update</button>
    </div>
</ng-template>
<!-- /REMOVE PRODUCT MODAL -->
<!-- REMOVE PRODUCT MODAL -->
<!--ng-template #content let-modal>
  <div class="modal-header nobd text-center pt-4 pb-0">
    <h4 class="modal-title fs28 txtdefault wt700 mx-auto pt-3">Remove Product</h4>
    <button type="button" class="btn-close close rmvmdl" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
	<div class="modal-body">
		<p class="fs12 txtseconday py-3 mb-2 text-center">
		<span class="mr-2">
			<svg id="Group_46" data-name="Group 46" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="7.5" cy="7.5" r="7.5" fill="#07f"/>
			  <path id="Path_2235" data-name="Path 2235" d="M7,8.171l-.128.523q-.576.227-.919.346a2.425,2.425,0,0,1-.8.119,1.591,1.591,0,0,1-1.084-.34,1.1,1.1,0,0,1-.387-.864,3.056,3.056,0,0,1,.029-.416q.029-.213.093-.48l.48-1.7q.064-.244.108-.462a2.01,2.01,0,0,0,.044-.4A.616.616,0,0,0,4.3,4.047a.759.759,0,0,0-.513-.128,1.345,1.345,0,0,0-.381.058c-.131.039-.242.076-.336.11L3.2,3.564q.471-.192.9-.329A2.7,2.7,0,0,1,4.916,3.1a1.552,1.552,0,0,1,1.068.335,1.111,1.111,0,0,1,.375.869c0,.074-.008.2-.026.39a2.606,2.606,0,0,1-.1.512L5.759,6.9a4.492,4.492,0,0,0-.1.466,2.4,2.4,0,0,0-.047.4.57.57,0,0,0,.15.459.85.85,0,0,0,.523.122,1.509,1.509,0,0,0,.4-.061A2.21,2.21,0,0,0,7,8.171Zm.121-7.1a.99.99,0,0,1-.334.753,1.138,1.138,0,0,1-.8.312,1.15,1.15,0,0,1-.809-.312.988.988,0,0,1-.337-.753A1,1,0,0,1,5.169.314a1.194,1.194,0,0,1,1.614,0A1,1,0,0,1,7.117,1.07Z" transform="translate(2.406 2.92)" fill="#fff"/>
			</svg>
		</span>
		Removing the product will lead to removing this product from your listing. The buyer won’t be able to place an order for this product.</p>
		<p class="fs16 txtseconday pb-3 mb-2 text-center">Are you sure you want to remove the product?</p>
		<div class="d-table mb-4 w-100">
			<div class="d-table-cell cart-thumb align-middle">
				<img width="60" src="assets/images/products/p-2.jpg">
			</div>
			<div class="d-table-cell pl-3 align-middle">
				<p class="txtdefault wt700 mb-0 fs14 lnht20">Vivo X60 Pro (Midnight Black, 12GB RAM, 256GB Storage) | Upto 12 Months No Cost EMI | Extra 4000 Off on Exchange</p>
			</div>
		</div>
	</div>
  <div class="modal-footer  text-center nobd mx-auto pb-4 mb-3">
    <button type="button" class="btn-d-outline d-inline-block cancel-outline mr-3" (click)="modal.close('Close click')">Cancel</button>
	<button type="button" class="btn buybtn fs16 radius10">Remove</button>
  </div>
</ng-template-->
<!-- /REMOVE PRODUCT MODAL -->

<!-- Order Total -->
<ng-template #contentbreakup let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>

    <div class="modal-body pb-3">
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead text-center">
                    <h4 class="txtdefault f-700 fs18 mb-20">Net Realization Value</h4>
                </div>

                <div class="inrcard p-3 radius10 position-relative">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <input type="radio" value="inside" name="netrealization_type" [(ngModel)]="netrealization_type">&nbsp;Inside City&nbsp;&nbsp;
                                <input type="radio" value="outside" name="netrealization_type" [(ngModel)]="netrealization_type">&nbsp;Outside City
                            </div>
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6 py-1">Order Quantity:</div>
                                <div class="col-md-6 py-1 text-right">{{ display_details.quantity }}</div>
                            </div>
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6 py-1">Price Per Unit: &nbsp;<i placement="top"
                                        ngbTooltip="Inclusive of GST"><img src="/assets/images/icons/info.png"></i>
                                </div>
                                <div class="col-md-6 py-1 text-right">{{ display_details.listing_price }}</div>
                            </div>
                            <hr>
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6 py-1">Net Goods Value:</div>
                                <div class="col-md-6 py-1 text-right">{{ display_details.without_gst }}</div>
                            </div>
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6 py-1">GST:</div>
                                <div class="col-md-6 py-1 text-right">{{ display_details.gst_amount }}</div>
                            </div>

                            <div class="row d-flex justify-content-between" *ngIf="display_details.other_charges.length > 0 && netrealization_type == 'inside'">
                                <div class="col-6 pr-0 " ngbDropdown>
                                    <p class="fs14 txtactive" id="dropdownBasic1" ngbDropdownToggle>Other Charges
                                    </p>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                        <button ngbDropdownItem
                                            *ngFor="let other of display_details.other_charges; let i = index;">{{ other
                                            }} </button>
                                    </div>
                                </div>
                                <div class="col-md-6 py-1 text-right">{{ display_details.other_charges_amount }}</div>
                            </div>

                            <div class="row d-flex justify-content-between" *ngIf="display_details.outside_other_charges.length > 0 && netrealization_type == 'outside'">
                                <div class="col-6 pr-0 " ngbDropdown>
                                    <p class="fs14 txtactive" id="dropdownBasic1" ngbDropdownToggle>Other Charges
                                    </p>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                        <button ngbDropdownItem
                                            *ngFor="let other of display_details.outside_other_charges; let i = index;">{{ other
                                            }} </button>
                                    </div>
                                </div>
                                <div class="col-md-6 py-1 text-right">{{ display_details.outside_other_charges_amount }}</div>
                            </div>
                            <hr>
                            <div class="row d-flex justify-content-between" *ngIf="netrealization_type == 'inside'">
                                <div class="col-md-6 py-1 f-700">Net Realization Value:</div>
                                <div class="col-md-6 py-1 text-right f-700">{{ display_details.net_realization }}</div>
                            </div>
                            <div class="row d-flex justify-content-between" *ngIf="netrealization_type == 'outside'">
                                <div class="col-md-6 py-1 f-700">Net Realization Value:</div>
                                <div class="col-md-6 py-1 text-right f-700">{{ display_details.outside_net_realization }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- /UPLOAD INVOICE -->

<!-- COUNTER OFFER MODAL -->
<ng-template #addqtymodal let-modal>
	<div class="text-right pt-3 pr-3 od-mdl-tl">
	<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
	</div>
  
  	<div class="modal-body pb-3">
    <div class="row align-items-center mb-20">
		<div class="col-md-12">
			<div class="rtrqsthead"> 
				<h4 class="txtdefault text-center wt700 fs22 mb-20">Add/Reduce Qty</h4>
			</div>
			<div class="mb-20 f-600 fs10"><i placement="top" ngbTooltip="This will act as a default filter every time you will toggle." class="mr-2"> <img src="/assets/images/icons/info.png"></i> Use Add from the drop-down to list the total quantity or further increase the total quantity. Use Reduce from the drop-down to lower the listed quantity.</div>
			
			<h4>Available total stock: {{addquantity_map_type == 'wholesale'?pricingForm.get('wholesale_qty').value: pricingForm.get('bulk_qty').value}}</h4>

			<div class="row mt-30">
				<div class="col-md-12">
					<div class="profileipt">
						<div class="form-container mt-0 mb-0 p-eml-rt">
                            <div class="floating-label mb-20"> 
                                <select [(ngModel)]="qty_type" class="form-control">
                                    <option value="plus">Add</option>
                                    <option value="minus">Reduce</option>
                                </select>
							   <span class="highlight"></span>
							   <label>Action Type</label>
							</div>
							<div class="floating-label mb-20"> 
								<input type="text" class="form-control" placeholder="" [(ngModel)]="new_qty"  value="new_qty > 0 ? new_qty:''">
                                <button class="btn btn-outline-secondary fs16 btn-o-default" (click)="addquantity()" type="button" [disabled]="!new_qty">
                                    <span>{{ qty_type == 'minus'?'Reduce':'Add' }}</span>
                               </button>
                                <span class="highlight"></span>
							   <label>Add/Reduce Quantity</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<hr>
	<div class="row align-items-center">
		<div class="col-md-12">
			<div class="text-center fs10">
				By counter offering a bid, you are agreeing to sell this item if the buyer accept your offer and will be required to sell within Dispatch date. Once you send the offer, they’ll have 48 hours to respond.
			</div>
		</div>
	</div>
 	</div>
</ng-template>
<!-- /COUNTER OFFER MODAL -->


<!-- CHILD QTY MODAL -->
<ng-template #childaddqtymodal let-modal>
	<div class="text-right pt-3 pr-3 od-mdl-tl">
	<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
	</div>
  
  	<div class="modal-body pb-3">
    <div class="row align-items-center mb-20">
		<div class="col-md-12">
			<div class="rtrqsthead"> 
				<h4 class="txtdefault text-center wt700 fs22 mb-20">Add/Reduce Qty</h4>
			</div>
			<div class="mb-20 f-600 fs10"><i placement="top" ngbTooltip="This will act as a default filter every time you will toggle." class="mr-2"> <img src="/assets/images/icons/info.png"></i> Use Add from the drop-down to list the total quantity or further increase the total quantity. Use Reduce from the drop-down to lower the listed quantity.</div>
			
			<h4>Available total stock: {{child_addquantity_map_type == 'wholesale'?child_selected_record.wholesale_qty: child_selected_record.bulk_qty}}</h4>

			<div class="row mt-30">
				<div class="col-md-12">
					<div class="profileipt">
						<div class="form-container mt-0 mb-0 p-eml-rt">
                            <div class="floating-label mb-20"> 
                                <select [(ngModel)]="child_qty_type" class="form-control">
                                    <option value="plus">Add</option>
                                    <option value="minus">Reduce</option>
                                </select>
							   <span class="highlight"></span>
							   <label>Action Type</label>
							</div>
							<div class="floating-label mb-20"> 
								<input type="text" class="form-control" placeholder="" [(ngModel)]="child_new_qty"  value="new_qty > 0 ? new_qty:''">
                                <button class="btn btn-outline-secondary fs16 btn-o-default" (click)="updatechildinventory()" type="button" [disabled]="!child_new_qty">
                                    <span>{{ child_qty_type == 'minus'?'Reduce':'Add' }}</span>
                               </button>
                                <span class="highlight"></span>
							   <label>Add/Reduce Quantity</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<hr>
	<div class="row align-items-center">
		<div class="col-md-12">
			<div class="text-center fs10">
				By counter offering a bid, you are agreeing to sell this item if the buyer accept your offer and will be required to sell within Dispatch date. Once you send the offer, they’ll have 48 hours to respond.
			</div>
		</div>
	</div>
 	</div>
</ng-template>
<!-- /CHILD QTY MODAL -->

<app-preview #preview></app-preview>

<app-footer-seller></app-footer-seller>