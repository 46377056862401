import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegistrationDetailsService {

  constructor(private _http: HttpClient) { }

  getuserdetails(data) {
    return this._http.post(environment.api_url+'userbasicinfo',data);
  }

  registrationdetails(data) {
    return this._http.post(environment.api_url+'registerbusinessdetails',data);
  }

  personsdetails(data) {
    return this._http.post(environment.api_url+'registerpersondetails',data);
  }

  bankdetails(data) {
    return this._http.post(environment.api_url+'registerbankdetails',data);
  }

  getifscdetails(data) {
    return this._http.post(environment.api_url+'getifscdetails',data);
  }

  gstdetails(data) {
  	return this._http.post(environment.api_url+'gst/verify',data);
  }

  uploaddoc(data) {
    return this._http.post(environment.api_url+'uploadbusinessdocument', data);
  }

  getregistrationdetails(data) {
    return this._http.post(environment.api_url+'getregistrationdetails',data);
  }

  getpersonsdetails(data) {
    return this._http.post(environment.api_url+'getpersondetails',data);
  }

  getbankdetails(data) {
    return this._http.post(environment.api_url+'getbankdetails',data);
  }

  getbusinesscategory(data) {
    return this._http.post(environment.api_url+'master/businesscategory/list',data);
  }

  getbusinesstype(data) {
    return this._http.post(environment.api_url+'master/businesstype/list',data);
  }

  getstate(data) {
    return this._http.post(environment.api_url+'master/state/list',data);
  }

  getcity(data) {
    return this._http.post(environment.api_url+'master/city/list',data);
  }

  getcountry(data) {
    return this._http.post(environment.api_url+'master/countries/list',data);
  }

  gethublocation(data) {
    return this._http.post(environment.api_url+'master/hublocation/list',data);
  }

}
