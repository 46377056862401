import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { BuyerWalletService } from './buyer-wallet.service';

@Component({
  selector: 'app-buyer-wallet',
  templateUrl: './buyer-wallet.component.html',
  styleUrls: ['./buyer-wallet.component.scss']
})
export class BuyerWalletComponent implements OnInit {

    public wallet_details:any = {};
    constructor(
        private _toastr:ToasterService,
        private _userwalletservice: BuyerWalletService,
    ) { }

    ngOnInit(): void {
        this.getwalletdetails();
    }

    getwalletdetails(){
        this._userwalletservice.getwalletdetails(null).subscribe((data) => {
            if(data['status'] == "success"){
                this.wallet_details = data['record'];
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }






}
