import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { UnconfirmedordersService } from './unconfirmed-orders.service';

@Component({
  selector: 'app-unconfirmed-orders',
  templateUrl: './unconfirmed-orders.component.html',
  styleUrls: ['./unconfirmed-orders.component.scss']
})
export class UnconfirmedOrdersComponent implements OnInit {

    // public search_term:string = '';
    // public from_date:any = '';
    // public to_date:any = '';
    constructor(
        private _pageservice: UnconfirmedordersService,
        private _toastr: ToasterService
    ) { }

    ngOnInit(): void {
        this.getunconfirmedorders();
    }

    public page_records: any = [];

    getunconfirmedorders() {
        this._pageservice.getrecords(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.page_records = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

}
