import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewListingService } from './view-listing.service';
import { ToasterService } from '../../helpers/toastr.service';
import { PreviewComponent } from 'src/app/shared/components/modal/preview/preview.component';

@Component({
    selector: 'app-view-listing',
    templateUrl: './view-listing.component.html',
    styleUrls: ['./view-listing.component.scss']
})
export class ViewListingComponent implements OnInit {

    @ViewChild("preview") Preview: PreviewComponent;

    public flag: boolean = false;
    public seller_id: string;
    public unique_id: string;
    public basic_info: any = {};
    public variant_name: string;
    public listing_status: boolean;
    active = 21;
    sampleactive = 1;
    disabled = true;
    public records = [];
    constructor(
        private modalService: NgbModal,
        private route: ActivatedRoute,
        private _viewlistingservice: ViewListingService,
        private _toastr: ToasterService,
        private _router: Router,
    ) { }
    flactive = 13;
    ngOnInit(): void {
        this.unique_id = this.route.snapshot.paramMap.get('uniqueId');
        this.route.queryParams
            .subscribe(params => {
                if (params['seller'] !== undefined) {
                    this.seller_id = params['seller'];
                }
            }
            );
        setTimeout(() => {
            this.getproductdetails();
        }, 2000)
    }

    variantinfo(item) {
        this.basic_info = item.basic;
        this.variant_name = item.basic.name;
    }

    getproductdetails() {
        this._viewlistingservice.getproductdetails({ unique_id: this.unique_id, seller_id: this.seller_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.flag = data['flag'];
                this.listing_status = data['listing_status'];
                this.records = data['records'];
                if (this.records.length > 0) {
                    this.basic_info = this.records[0].basic;
                    this.variant_name = this.records[0].basic.name;

                    let images = [];
                    if (this.basic_info.front_image) {
                        images.push({ img: this.basic_info.front_image });
                    }

                    if (this.basic_info.back_image) {
                        images.push({ img: this.basic_info.back_image });
                    }

                    if (this.basic_info.sales_image) {
                        images.push({ img: this.basic_info.sales_image });
                    }

                    if (this.basic_info.side_image) {
                        images.push({ img: this.basic_info.side_image });
                    }

                    if (this.basic_info.top_image) {
                        images.push({ img: this.basic_info.top_image });
                    }

                    this.slides = images;

                } else {
                    this.basic_info = {};
                    this.variant_name = '';
                }
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }


    config = {
        labelField: 'label',
        valueField: 'value',
    };
    data = [
        {
            label: 'India',
            value: 'india'
        },
        {
            label: 'in $',
            value: 'in $'
        }
    ]
    prdata = [
        {
            label: 'in % of M',
            value: 'in % of M'
        },
        {
            label: 'in % of M',
            value: 'in % of M'
        }
    ]
    gbstorage = [
        {
            label: 'In GB',
            value: 'In GB'
        },
        {
            label: 'In GB',
            value: 'In GB'
        }
    ]
    regdata = [
        {
            label: 'Registered Brand Owner',
            value: 'owner'
        },
        {
            label: 'Option2',
            value: 'Option2'
        }
    ]
    monthdata = [
        {
            label: 'In Months',
            value: 'In Months'
        },
        {
            label: 'In Year',
            value: 'In Year'
        }
    ]
    openWindowCustomClass(content) {
        this.modalService.open(content, { windowClass: 'modal-500' });
    }

    open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true, }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }



    closeResult = '';

    ratingclassstatus: boolean = false;
    ratingtglclass() {
        this.ratingclassstatus = !this.ratingclassstatus;
    }

    showcomperr: boolean = false;
    showcompmsg() {
        this.showcomperr = true;
    }
    imagesSlider = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: ".thumbs",
    };
    thumbnailsSlider = {
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: true,
        focusOnSelect: true,
        asNavFor: ".feedback",
        //prevArrow:'.client-thumbnails .prev-arrow',
        // nextArrow:'.client-thumbnails .next-arrow',
    };





    slides = [];

    updatestatus(type) {
        var value = this.listing_status;
        this._viewlistingservice.updatestatus({ unique_id: this.unique_id, variant_id: '', type: type, value: value }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Product listing status has been changed.');
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    deletesellerlisting() {
        this._viewlistingservice.deletesellerlisting({ unique_id: this.unique_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Product listing has been deleted successfully.');
                this.modalService.dismissAll();
                this._router.navigate(['/seller/mylisting']);

            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    openPreview() {
        this.Preview.openPreviewModal(this.basic_info.uniqueId, this.basic_info.product_id, null, null, 'seller');
    }


}
