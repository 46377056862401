<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain pb-4">
  <div class="container">
    <!--- HEAD  --->
    <div class="row align-items-center pinfo-head">
      <div class="col-md-6 mb-4">
        <h3 class="fs24 wt700 txtdefault">BNPL</h3>
        <nav class="breadcrumb breadcrumb-dui mb-0">
          <ol class="breadcrumb p-0 bg-transparent mb-0">
            <li class="breadcrumb-item">
              <a class="txtseconday" [routerLink]="['/admin/dashboard']"
                >Dashboard
              </a>
            </li>
            <li class="breadcrumb-item active">Charges</li>
          </ol>
        </nav>
      </div>
      <div class="col-md-6 mb-4 text-md-right text-center">
        <a
          (click)="open(openChargesModel, '', false)"
          href="javascript:;"
          class="mr-sm-4 mr-2 btn-d-outline d-inline-block"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="14.681"
            height="14.681"
            viewBox="0 0 14.681 14.681"
            class="plus"
          >
            <g
              id="Icon_feather-plus"
              data-name="Icon feather-plus"
              transform="translate(1 1)"
            >
              <path
                id="Path_8"
                data-name="Path 8"
                d="M18,7.5V20.181"
                transform="translate(-11.659 -7.5)"
                fill="none"
                stroke="#ff7135"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
              <path
                id="Path_9"
                data-name="Path 9"
                d="M7.5,18H20.181"
                transform="translate(-7.5 -11.659)"
                fill="none"
                stroke="#ff7135"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </g>
          </svg>
          Add BNPL Charges
        </a>
      </div>
    </div>
    <div class="card cardui carduipro p-4 mb-0">
      <div class="notifications-container theme-tab">
        <div class="tab-content">
          <div class="row mt-0 report-cta">
            <div class="col-sm-4">
              <div class="report-input">
                <div class="input-group-custom">
                  <input
                    type="text"
                    placeholder="Search by Charges From, Charges Type"
                    class="form-control"
                    [(ngModel)]="search_term"
                    (keyup)="chargesLists()"
                  />
                  <div class="input-group-append-custom">
                    <img src="assets/images/svg/search_logo.svg" />
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="table-responsive brndmgnttable">
              <table class="table custom-table xs">
                  <thead>
                      <tr>
                          <th>Category</th>
                          <th>Charges</th>
                          <th>Charges From</th>
                          <th>Charges Type</th>
                          <th>Status</th>
                          <th>Action</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngIf="chargesList.length == 0">
                          <td colspan="6">
                              <div class="row pt-40 pb-40">
                                  <div class="col-md-12 text-center">
                                      <div class="mb-20"><img src="/assets/images/empty-state.png">
                                      </div>
                                      <div class="fs16">There are no records to display!</div>
                                  </div>
                              </div>
                          </td>
                      </tr>
                      <tr *ngFor="let record of chargesList; let i = index;">
                          <td>{{ record.category_name }}</td>
                          <td>{{ record.charges }}</td>
                          <td>{{ record.charges_from }}</td>
                          <td>{{ record.charges_type }}</td>
                          <td *ngIf="record.status == 1">Active</td>
                          <td *ngIf="record.status == 2">Inactive</td>
                          <td><a href="javascript:;" (click)="open(openChargesModel, record._id, true)" class="link-alt fs14">Edit</a></td>
                      </tr>
                  </tbody>
              </table>
          </div>
        </div>

        <div class="row mb-40" *ngIf="media_pagination.totalPages> 1">
                    <div class="col-md-6">
                        <div class="text-left pl-3">
                            <nav class="mt-3 tbpg rows-per-page">
                                <div>Rows per page:</div>
                                <select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
                                    <option *ngFor="let option of page_per_view_options" [ngValue]="option.value">
                                        {{option.name}}</option>
                                </select>
                            </nav>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="text-right pr-3">
                            <nav class="mt-3 tbpg">
                                <ul class="pagination align-items-center">
                                    <li class="page-item" [ngClass]="{disabled:media_pagination.currentPage === 1}"><a
                                            class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a>
                                    </li>
    
                                    <li class="page-item" [ngClass]="{disabled:media_pagination.currentPage === 1}"><a
                                            class="page-link sprevicon" href="javascript:;"
                                            (click)="setpage(media_pagination.currentPage - 1)"></a></li>
    
                                    <li class="page-item" i *ngFor="let page of media_pagination.pages"
                                        [ngClass]="{active:media_pagination.currentPage === page}"><a class="page-link"
                                            href="javascript:;" (click)="setpage(page)">{{page}}</a></li>
    
                                    <li *ngIf="(media_pagination.totalPages - media_pagination.startPage) > 5 ">
                                        <a (click)="setpage(media_pagination.startPage + 5)">...</a>
                                    </li>
                                    <li *ngIf="(media_pagination.totalPages - media_pagination.startPage) > 5">
                                        <a
                                            (click)="setpage(media_pagination.totalPages)">{{media_pagination.totalPages}}</a>
                                    </li>
    
                                    <li class="page-item"
                                        [ngClass]="{disabled:media_pagination.currentPage === media_pagination.totalPages}">
                                        <a class="page-link snexticon" href="javascript:;"
                                            (click)="setpage(media_pagination.currentPage + 1)"></a>
                                    </li>
                                    <li class="page-item"
                                        [ngClass]="{disabled:media_pagination.currentPage === media_pagination.totalPages}">
                                        <a class="page-link nexticon" href="javascript:;"
                                            (click)="setpage(media_pagination.totalPages)"></a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div> 
      </div>
    </div>
  </div>

  <ng-template #openChargesModel let-modal>
    <div class="modal-body text-center mdlcmnui pt-4 mt-3">
      <h5 class="modal-title mb-10">{{editButton == false ? "Add BNPL Charges" : "Update BNPL Charges"}}</h5>
      <div class="customFrom" [formGroup]="chargesForm">
        <div class="row">
          <div class="col-md-12" *ngIf="editButton == false || showCategory">
            <div class="ng-autocomplete">
              <ng-autocomplete
                [data]="result"
                [searchKeyword]="keyword"
                (selected)="selectCategoryEvent($event)"
                (inputChanged)="onChangeCategorySearch($event)"
                (inputFocused)="onFocused($event)"
                [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate"
                placeholder="Search by Category Name"
              >
              </ng-autocomplete>
              <ng-template #itemTemplate let-item>
                <a href="javascript:void(0)">
                  <div class="d-flex">
                    <span [innerHTML]="item.name"></span>
                  </div>
                </a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
              <div class="search-icon">
                <img src="assets/images/svg/search_logo.svg" />
              </div>
            </div>
          </div>
          <div class="col-md-12" *ngIf="editButton == true && !showCategory">
            <label>Category Name : {{category_name}}</label> <button class="changeCategory" (click)="changeCategory()">Change Category</button>
          </div>
        </div>
        <div class="row pbrktscl mt-20">
            <div class="col-md-6">
              <div class="form-group">
                <label>BNPL Charges</label>
                <input
                  class="form-control pr-5"
                  placeholder=" "
                  formControlName="charges"
                  type="number"
                  />
              </div>
            </div>
            <!-- <div class="col-md-6 selectactive form-group">
              <label>Charges From</label>
              <ng-selectize
                [config]="config"
                [options]="convenience_units"
                formControlName="charges_from"
                placeholder="Select"
              >
              </ng-selectize>
            </div> -->
            <div class="col-md-6 selectactive form-group">
              <label>Charges Type</label>
              <ng-selectize
                [config]="config"
                [options]="convenience_charges_units"
                formControlName="charges_type"
                placeholder="Select"
              >
              </ng-selectize>
            </div>
            <div class="form-group col-md-6">
              <label>Status</label>
              <div class="selectactive">
                <ng-selectize
                  [config]="config"
                  [options]="status"
                  formControlName="status"
                  placeholder="Select"
                >
                </ng-selectize>
              </div>
            </div>
        </div>
      </div>
      <div class="text-center nobd mx-auto pb-4 mb-3 mt-20">
        <button
          type="button"
          class="btn-d-outline d-inline-block cancel-outline mr-3"
          (click)="modal.close('Close click')"
        >
          Cancel
        </button>
        <button
          [disabled]="chargesForm.invalid || !category_id"
          type="button"
          class="btn buybtn fs16 radius10"
          (click)="addCharges()"
        >
         {{editButton == false ? "Save Changes" : "Update Changes"}}
        </button>
      </div>
    </div>
  </ng-template>
</div>
