import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private _http: HttpClient) { }

  currentToken: string = localStorage.getItem('loggedInUserToken');
  tokenChanged = new EventEmitter<string>();

  updatetoken(t){
    localStorage.setItem('loggedInUserToken', t);
    this.currentToken = t;
    this.tokenChanged.emit(this.currentToken);
  }

  gettoken() {
    return this.currentToken;
  }

  postverify(data) {
    return this._http.post(environment.api_url+'auth/verify',data);
  }

  otpgenerate(data) {
    return this._http.post(environment.api_url+'admin/otp/generate',data);
  }

  verifyotp(data) {
    return this._http.post(environment.api_url+'admin/login',data);
  }

  resetpassword(data) {
    return this._http.post(environment.api_url+'resetpassword',data);
  }

    forgotpassword(data) {
        return this._http.post(environment.api_url+'admin/forgotpassword',data);
    }
  
}
