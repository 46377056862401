import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { AllBidandOffersService } from './all-bid-and-offers.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SignupService } from 'src/app/shared/components/modal/signup-modal/signup-modal.service';
import { PaginationService } from '../../helpers/pagination.service';
import { ProductCategoryService } from 'src/app/admin/product-category/product-category.service';
import { AddListingService } from 'src/app/seller/add-listing/addlisting.service';
import { ProductDetailsService } from '../product-details/product-details.service';
import { BidOffersService } from '../bids-offers/bid-offers.service';
import { MybidsService } from '../my-bids/my-bids.service';

@Component({
    selector: 'app-all-bids-and-offers',
    templateUrl: './all-bids-and-offers.component.html',
    styleUrls: ['./all-bids-and-offers.component.scss']
})
export class AllBidsAndOffersComponent implements OnInit {

    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 10;
    public all_bids_pagination: any = {};
    public all_bids_records: any = [];
    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];

    keyword = 'name';
    public closeResult = '';
    public user_status: string;
    public user_token: string;
    public user_reg_status;
    public last_bid_record: any = {};
    public product_detail: any = {};
    public bid_records: any = [];
    public mybid_records: any = [];
    public bid_edit_type = 'quantity';
    public selected_delivery: string = 'standard';
    public bid_quantity: number;
    public supply_location:string;
    public modalInsuranceReference:any;
    public enable_insurance: boolean = false;
    public selected_bid: any = {};
    public selected_product_id:string = '';
    public bid_view_history = false;
    public bid_history_records: any = [];
    public last_traded_records: any = [];
    public updated_quantity: number;
    public updated_price: number;
    public selected_category: string = '';
    public search_term:string = '';

    public express_records:any = [];
    public standard_city_records:any = [];
    public standard_outsidecity_records:any = [];

    public watchlist_records:any = [];

    constructor(
        private modalService: NgbModal,
        private _allbidsandoffers: AllBidandOffersService,
        private _toastr: ToasterService,
        private _route: ActivatedRoute,
        private _bidofferservice: BidOffersService,
        private _router: Router,
        private _pagination: PaginationService,
        private _signupservice: SignupService,
        private _editproductcategoryservice: ProductCategoryService,
        private _addlistingservice: AddListingService,
        private _productdetailsservice: ProductDetailsService,
        private _mybidsservice: MybidsService,
    ) { }

    @ViewChild('tabset') tabset;

    ngOnInit(): void {
        this.user_status = this._signupservice.getuserstatus();
        this.user_token = this._signupservice.gettoken();
        this.user_reg_status = this._signupservice.getuserregstatus();
        this.getallbids();
        setTimeout( () => {
            this.getmybids();
        }, 1000);
    }

    getmybids() {
        this._mybidsservice.getmybids({search: this.search_term}).subscribe((data) => {
            if (data['status'] == "success") {
                this.mybid_records = data['records'];
                this.watchlist_records = [];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    changedelivery(type) {
        this.selected_delivery = type;
    }

    changesupplylocation(index){
        this.supply_location = index;
    }

    placebid() {
        this._productdetailsservice.savebiddetails({insurance: this.enable_insurance, logistics_type: this.selected_delivery, supply_location: this.supply_location, sp_id: this.selected_bid.sp_id, amount: this.selected_bid.bid_amount, quantity: this.bid_quantity }).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this._bidofferservice.bid_placed = true;
                this._bidofferservice.bid_id = data['bid_id'];
                this._router.navigate(['/buyer/bids-offers/' + data['product_id']]);
            } else if (data['status'] == "error") {
                //this.modalService.dismissAll();
                this._toastr.error(data['message']);
            }
        });
    }

    openinsurance(contentinsurance, type = 'button') {
        if (this.enable_insurance && type == 'button') {
            this.enableinsurance('no', contentinsurance);
        } else {
            this.modalInsuranceReference = this.modalService.open(contentinsurance, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, }).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }

    enableinsurance(type = 'no', contentinsurance) {
        if(type == 'no'){
            this.enable_insurance = false;
        }else{
            this.enable_insurance = true;
        }
        //this.modalService.dismissAll();
    }

    openlogistics(content) {
        this.modalService.open(content, {
            ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, windowClass: 'modal-ui'
        }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    getlogisticscharges() {
        this._productdetailsservice.getlogisticscharges(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.express_records = data['expressrecord'];
                this.standard_city_records = data['standardwithincity'];
                this.standard_outsidecity_records = data['standardoutsidecity'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    changebillingaddress(){
        this.modalService.dismissAll();
        this._router.navigate(['/buyer/business-details']);
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.getallbids();
    }

    setpageperview() {
        this.current_page = 1;
        this.getallbids();
    }

    gotodeal() {
        this.modalService.dismissAll();
        this._router.navigate(['/deal/' + this.last_bid_record.sp_id + '/bulk'])
    }

    getallbids(type = 'no') {
        let limit = this.page_per_view;
        let offset = this.offset;
        let current_page = this.current_page;
        this._allbidsandoffers.getallbids({ limit: limit, offset: offset, current_page: current_page, my_bids: type, category: this.selected_category }).subscribe((data) => {
            if (data['status'] == "success") {
                if (type == 'no') {
                    let total_records = data['total_records'];
                    this.all_bids_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
                    this.all_bids_records = data['records'];
                } else {
                    //this.mybid_records = data['records'];
                }
                this.getlogisticscharges();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });

        // this._allbidsandoffers.getproductbidlisting({product_id: this.product_id}).subscribe((data) => {
        //     if(data['status'] == "success"){
        //         this.bid_records = data['records'];
        //         this.product_detail = data['basic'];
        //         this.mybid_records = this.bid_records((product) => product.is_buyer == true);
        //     }else if(data['status'] == "error"){
        //         this._toastr.error(data['message']);
        //     }
        // });
    }

    openCounterOffer(content2, record, type) {
        this.bid_edit_type = type;
        this._mybidsservice.editbiddetails({ bid_id: record.bid_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.selected_bid = data['basic'];
                this.bid_history_records = data['records'];
                this.modalService.open(content2, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    open(content, bid_id) {
        this._allbidsandoffers.gettradedprice({ bid_id: bid_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.last_traded_records = data['records'];
                this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    showcounteroffer(content, record) {
        this.selected_bid = record;
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    changebidedit(type) {
        this.bid_edit_type = type;
    }

    openthankyou(content3) {
        this.modalService.open(content3, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    acceptcounteroffer() {
        this._mybidsservice.acceptcounteroffer({ bid_id: this.selected_bid.bid_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Counter offer accepted successfully.');
                this.modalService.dismissAll();
                this.getmybids();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    openplaceabid(content, listing) {
        this._productdetailsservice.getbiddetails({ sp_id: listing.sp_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.selected_product_id = listing.product_id;
                this.selected_bid = data['record'];
                this.selected_bid.bid_amount = '';
                this.modalService.dismissAll();
                this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    deletebid(record) {
        this._allbidsandoffers.deletebid({ bid_id: record.bid_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Bid record has been deleted successfully.');
                this.getmybids();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    showhistory() {
        this.bid_view_history = !this.bid_view_history;
    }

    updatebid(type) {
        if (type == 'price' && !this.updated_price) {
            this._toastr.error('Please enter bid price.');
            return false;
        } else if (type == 'quantity' && !this.updated_quantity) {
            this._toastr.error('Please enter bid quantity.');
            return false;
        }
        var value = type == 'price' ? this.updated_price : this.updated_quantity;
        this._allbidsandoffers.updatebid({ type: type, value: value, bid_id: this.selected_bid.bid_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.updated_price = null;
                this.updated_quantity = null;
                this.modalService.dismissAll();
                this.last_bid_record = data['record'];
                document.getElementById('thankyoumodal').click();
                this.getmybids();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    result = [];
    presult = [];

    selectEvent(item) {
        this.selected_category = item.c_id;
        this.tabset.select('allBids');
        this.getallbids();
        setTimeout(() => {
            this.getmybids();
        }, 3000);
    }

    onChangeSearch(val: string) {
        this.selected_category = val;
        this._editproductcategoryservice.searchcategory({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.result = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    onFocused(e) {
        // do something when input is focused
    }

    pselectEvent(item) {
        this._router.navigate(['/buyer/bids-offers/' + item.product_id]);
    }

    ponChangeSearch(val: string) {
        this._addlistingservice.productsearch({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.presult = data['searchResult'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    ponFocused(e) {
        // do something when input is focused
    }

}
