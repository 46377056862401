import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PersonalDetailsService } from 'src/app/seller/personal-details/personal-details.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '../../../helpers/toastr.service';
import { EncrDecrService } from '../../../helpers/EncrDecrService';
@Component({
    selector: 'app-bank-details-buyer',
    templateUrl: './bank-details-buyer.component.html',
    styleUrls: ['./bank-details-buyer.component.scss']
})
export class BankDetailsBuyerComponent implements OnInit {

    constructor(
        private modalService: NgbModal,
        private route: ActivatedRoute,
        private _router: Router,
        private _toastr: ToasterService,
        private _EncrDecr: EncrDecrService,
        private _personaldetailsservice: PersonalDetailsService,
    ) { }

    public personal_detail: any = {};
    public bank_detail: any = {};

    ngOnInit(): void {
        this.getdetails();
    }

    getdetails() {
        this._personaldetailsservice.getdetails(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.personal_detail = data['records']['personal_detail'];
                this.bank_detail = data['records']['bank_detail'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

}
