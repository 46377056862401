<app-header-admin></app-header-admin>
<div class="main-content">
	<div class="container pb-3">
		<!-- BREADCRUMB -->
		<nav class=" pt-2 pb-2" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
		  <ol class="breadcrumb p-0 bg-transparent">
			<li class="breadcrumb-item"><a href="javascript:void(0);" [routerLink]="['/admin/product-listing']" class="txtseconday">Product Management </a></li>		
			<li class="breadcrumb-item active" aria-current="page">Add New Product</li>
		  </ol>		
		</nav>
		<!-- /BREADCRUMB -->
		<div class="row align-items-center adprotop">
			<div class="col-md-6 mb-4">
				<h4 class="wt700 mb-0 txtdefault fs22">
				<a href="javascript:void();" [routerLink]="['/admin/product-listing']" class="go-back"><i class="fa fa-angle-left"></i></a>
				Add New Product</h4>
			</div>
			<div class="col-md-6 mb-4 text-right">
				<a href="javascript:void(0);" class="sglcatalog txtdefault radius10">
				<img src="assets/images/admin/video.png"> 
				How to upload Single catalog?</a>
				
				
				<a href="javascript:void(0);" class="btn px-4 buybtn fs16 radius10 ml-sm-3 ml-2">Assistance</a>
			</div>
		</div>
		<div class="row align-items-center mb-4">
			<div class="col-sm-12 text-center text-lg-left">
				<ul class="prdnav">
					<li class="active" [routerLink]="['/admin/manage-product-categories']">
						<span>
							<svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 8.591 7.338">
							  <path id="np_tick_1318229_000000" d="M37.756,43.764a.449.449,0,1,0-.615.655l2.394,2.244a.449.449,0,0,0,.662-.051l4.189-5.386a.449.449,0,1,0-.708-.552l-3.888,5Z" transform="translate(-36.444 -39.946)" fill="#1f1f37" stroke="#1f1f37" stroke-width="1"/>
							</svg>
						</span>
						<p class="mb-1 fs16">Product Category - - -</p>
					</li>
					<li>
						<span>
							<svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 8.591 7.338">
							  <path id="np_tick_1318229_000000" d="M37.756,43.764a.449.449,0,1,0-.615.655l2.394,2.244a.449.449,0,0,0,.662-.051l4.189-5.386a.449.449,0,1,0-.708-.552l-3.888,5Z" transform="translate(-36.444 -39.946)" fill="#1f1f37" stroke="#1f1f37" stroke-width="1"/>
							</svg>
						</span>
						<p class="mb-1 fs16">Product Details</p>
					</li>
				</ul>
			</div>			
		</div>
		<!-- SEARCH -->
		<div class="row mb-4">
			<div class="col-xl-12 col-lg-12">
				<div class="floating-label">
					<label>Product Template</label>
					<i placement="top" ngbTooltip="Marketing Operating Price"
						class="infoinput info-alert"></i>
					<select name="product_template" id="product_template" class="form-control pr-5" [(ngModel)]="product_template">
						<option value="normal">Normal Template</option>
						<option value="parentchild">Parent/Child Template</option>
					</select>
					<span class="highlight"></span>
					
				</div>
			</div>
		</div>	
		<div class="row mb-4">
			<div class="col-xl-5 col-lg-7">
				<div class="input-group-custom pro-catg-srch">
					<div class="ng-autocomplete">
						<ng-autocomplete 
							[data]="result" 
							[searchKeyword]="keyword" 
							(selected)='selectEvent($event)'
							(inputChanged)='onChangeSearch($event)' 
							(inputFocused)='onFocused($event)' 
							[itemTemplate]="itemTemplate"
							placeholder="Search Category" >
						</ng-autocomplete>
						<ng-template #itemTemplate let-item>
							<a href="javascript:void(0)">
								<div class="d-flex">
									<span [innerHTML]="item.name"></span>
									<!-- <span [innerHTML]="item.current" class="current"></span> -->
								</div>
							</a>
						</ng-template>
						<div class="search-icon"><img src="assets/images/svg/search_logo.svg"></div>
					</div>
					<!-- <input class="form-control radius10 "  placeholder="Search Category">	
					<div  class="input-group-append-custom"><img src="assets/images/svg/search_logo.svg"></div>	 -->
				</div>
			</div>
		</div>	
		<div class="row scrolcustom">   
			<div class="col-sm-12">
				<h4 class="wt700 txtdefault fs14 mb-4">Select a Category</h4>
			</div>
			<!-- COL -->
			<div class="col-lg-3 col-sm-6">
				<div class="card proctg radius10">
					<div class="card-body p-2 py-3">
						<div class="eqmenu-subitems">
						  <ul>
							<li *ngFor="let category of categories; let i = index;">
								<a href="javascript:void(0);" (click)="getsubcategory(category, i, 'parent')">
									<img [src]="category.preview?category.preview:default_preview">{{ category.name }}
								</a>
							</li>
						  </ul>
						</div>
					</div>
				</div>
			</div>
			<!-- /COL -->
			<!-- COL -->
			<div class="col-lg-3 col-sm-6" *ngFor="let sub of subcategories; let i = index;">
				<div class="card proctg radius10">
					<div class="card-body p-2 py-3">
						<div class="eqmenu-subitems">
						  <ul>
							<li *ngFor="let cat of sub;"  [ngClass]="{'selected': !cat.open}"><a href="javascript:void(0);" (click)="getsubcategory(cat, i, 'sub')"> <img [src]="cat.preview?cat.preview:default_preview">{{cat.name }}</a></li>
						  </ul>
						</div>
					</div>
				</div>
			</div>
			<!-- /COL -->
		</div>
	</div>
</div>	
