import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class HeaderAdminService {

  constructor(private _http: HttpClient) { }

  userbasicinfo(data) {
    return this._http.post(environment.api_url+'admin/userbasicinfo',data);
  }

}
