import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seller-business-details',
  templateUrl: './seller-business-details.component.html',
  styleUrls: ['./seller-business-details.component.scss']
})
export class SellerBusinessDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
