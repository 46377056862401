<app-header-admin></app-header-admin>
<div class="main-content">
    <div class="container pb-5">
        <!-- BREADCRUMB -->
        <nav class=" pt-2 pb-2" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
            <ol class="breadcrumb p-0 bg-transparent">
                <li class="breadcrumb-item"><a href="javascript:void(0);" class="txtseconday"
                        [routerLink]="['/admin/product-listing']">Product Management </a></li>
                <li class="breadcrumb-item" *ngIf="add_type == 'edit'">
                    <a href="javascript:void(0);" class="txtseconday" [routerLink]="['/admin/product-listing']">Product
                        Listing
                    </a>
                </li>
                <li class="breadcrumb-item" *ngIf="add_type == 'edit'">
                    <a href="javascript:void(0);" class="txtseconday">Edit Template
                    </a>
                </li>
                <li class="breadcrumb-item active" aria-current="page" *ngIf="add_type == 'add'">Add New Product</li>
            </ol>
        </nav>
        <!-- /BREADCRUMB -->

        <div class="row align-items-center adprotop">
            <div class="col-md-6 mb-4">
                <h4 class="wt700 mb-0 fs20">
                    <a [routerLink]="['/admin/product-listing']" class="go-back">
                        <i class="fa fa-angle-left"></i>
                    </a>
                    {{ add_type == 'add' ? 'Add New Product' : 'Edit Template' }}
                </h4>
            </div>
            <div class="col-md-6 mb-4 text-right">
                <a href="javascript:void(0);" class="sglcatalog txtdefault radius10">
                    <img src="assets/images/admin/video.png">
                    How to upload Single catalog?
                </a>
                <a href="javascript:void(0);" class="btn px-4 buybtn fs16 radius10 ml-sm-3 ml-2">Assistance</a>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-lg-5 pr-0 text-center text-lg-left">
                <ul class="prdnav" *ngIf="add_type == 'add'">
                    <li class="doneprocatg" [routerLink]="['/admin/manage-product-categories']">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 8.591 7.338">
                                <path id="np_tick_1318229_000000"
                                    d="M37.756,43.764a.449.449,0,1,0-.615.655l2.394,2.244a.449.449,0,0,0,.662-.051l4.189-5.386a.449.449,0,1,0-.708-.552l-3.888,5Z"
                                    transform="translate(-36.444 -39.946)" fill="#1f1f37" stroke="#1f1f37"
                                    stroke-width="1" />
                            </svg>
                        </span>
                        <p class="mb-1 fs16">Product Category - - -</p>
                    </li>
                    <li class="active" [routerLink]="['/addnewproduct']">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 8.591 7.338">
                                <path id="np_tick_1318229_000000"
                                    d="M37.756,43.764a.449.449,0,1,0-.615.655l2.394,2.244a.449.449,0,0,0,.662-.051l4.189-5.386a.449.449,0,1,0-.708-.552l-3.888,5Z"
                                    transform="translate(-36.444 -39.946)" fill="#1f1f37" stroke="#1f1f37"
                                    stroke-width="1" />
                            </svg>
                        </span>
                        <p class="mb-1 fs16">Product Details</p>
                    </li>
                </ul>

                <div class="el-crousal" *ngIf="add_type == 'edit'">
                    <div class="row mb-4 pb-3">
                        <div class="col-md-8 ">
                            <div class="bid-order-info">
                                <div class="order-img">
                                    <div class="feedback-slides">
                                        <div class="client-feedback dtlfull">
                                            <ngx-slick-carousel class="carousel feedback" #slickModal="slick-carousel"
                                                [config]="imagesSlider">
                                                <div ngxSlickItem *ngFor="let slide of slides; let i = index"
                                                    class="slide">
                                                    <img src="{{ slide.img }}" alt="" *ngIf="slide.img">
                                                </div>
                                            </ngx-slick-carousel>
                                        </div>
                                    </div>
                                </div>
                                <div class="pd-seller-cntnt">
                                    <!--Product Name-->
                                    <h5 class="mb-10 fs18">{{ common_info.name }}</h5>

                                    <!--Edit / Delete -->
                                    <div class="text-left light-text mt-10">
                                        <a href="javascript:;" (click)="openeditcategory(editcategory)">
                                            <span class="pd-taglist md">
                                                <span class="radius15">{{ common_info.category_name }} <img
                                                        src="assets/images/icons/edit.png" class="ml-2">
                                                </span>
                                            </span>
                                        </a> &nbsp; | &nbsp; <a href="javascript:;"
                                            (click)="openremoveproduct(removeProduct)"><img
                                                src="assets/images/icons/delete-icon.svg"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="client-thumbnails dtlthmb text-center">
                        <ngx-slick-carousel class="carousel thumbs" #slickModal="slick-carousel"
                            [config]="thumbnailsSlider ">
                            <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                                <div class="thmbnaildtl" *ngIf="slide.img">
                                    <img src="{{ slide.img }}" alt="" width="100%">
                                </div>
                            </div>
                        </ngx-slick-carousel>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 text-md-left text-center text-lg-right tmplstts">
                <strong class="mr-2 align-middle" *ngIf="infoactive == 98 && active_variant_id">Template Status</strong>
                <div class="tglbtn d-inline-block mr-2 tmpstatustgl" *ngIf="infoactive == 98 && active_variant_id">
                    <label class="switch">
                        <input type="checkbox" [(ngModel)]="template_status">
                        <span class="slider round"></span>
                    </label>
                </div>
                <a href="javascript:void(0);" class="btn px-4 btn-d-outline fs16 radius10 ml-3"
                    (click)="openPreview(uniqueId, active_variant_id)">Preview</a>
                <button class="btn px-4 buybtn fs16 btn-success radius10 ml-3"
                    (click)="savedetails('finish')" [disabled]="finishLoading">{{ infoactive == 98 ? 'Save and Finish' : 'Save' }}</button>
            </div>
        </div>
        <div class="add-pro-wrap">
            <a (click)="openWindowCustomClass(mcontent)" href="javascript:void(0);"
                class="btn px-3 btn-d-outline fs16 radius10 addprobtn" *ngIf="product_template == 'normal'">
                <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="14.681" height="14.681"
                    viewBox="0 0 14.681 14.681">
                    <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1 1)">
                        <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181" transform="translate(-11.659 -7.5)"
                            fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" />
                        <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181" transform="translate(-7.5 -11.659)"
                            fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" />
                    </g>
                </svg>
                Add/Manage Product Variant
            </a>

            <a (click)="openWindowCustomClassadd(addparentchild)" href="javascript:void(0);"
                class="btn px-3 btn-d-outline fs16 radius10 addprobtn" *ngIf="product_template == 'parentchild'">
                <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="14.681" height="14.681"
                    viewBox="0 0 14.681 14.681">
                    <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1 1)">
                        <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181" transform="translate(-11.659 -7.5)"
                            fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" />
                        <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181" transform="translate(-7.5 -11.659)"
                            fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" />
                    </g>
                </svg>
                Add Child Product
            </a>

            <ul ngbNav #prodtlnav="ngbNav" class="nav-tabs mbltype" [(activeId)]="variantactive">
                <li *ngFor="let item of saved_variants; let i = index;" [ngbNavItem]="i+1"
                    (click)="changeactivevariant(item)">
                    <a ngbNavLink>{{ item.name }}</a>
                </li>
            </ul>
            <div class="add-pro-wrap">
                <div class="row pt-40">
                    <!-- LEFT COL -->
                    <div class="col-lg-5 mb-4">
                        <div class="card card-ui p-0">
                            <div class="card-header bg-transparent py-3 px-4">
                                <h4 class="wt700">Product images</h4>
                                <p class="mb-0 txtseconday">{{ category_txt }} </p>
                            </div>
                            <div class="card-body p-4 pt-0 demoimg">
                                <ul ngbNav #nav="ngbNav" [(activeId)]="imageactive" class="nav-tabs addpro-navtabs">
                                    <li *ngFor="let img of productImages; let i = index;" [ngbNavItem]="i+1">
                                        <a ngbNavLink>{{ img.key }}</a>
                                        <ng-template ngbNavContent>
                                            <div class="text-center upldimg mb-5">
                                                <p class="mb-0" *ngIf="img.default">Sample {{ img.key }} Image</p>
                                                <div class="image"><img class="my-3 "
                                                        [src]="img.default?defaultImage:img.value"></div>
                                                <p class="mb-4"><img placement="top"
                                                        ngbTooltip="Tooltop content goes here"
                                                        class="mr-2 align-middle infoicon"
                                                        src="assets/images/icons/info.png">Please provide only
                                                    {{img.key.toLowerCase()}} Image</p>
                                                <a href="javascript:void(0);"
                                                    class="btn-d-outline d-inline-block place-bid">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.373"
                                                        height="17.373" viewBox="0 0 17.373 17.373">
                                                        <g id="Icon_feather-upload" data-name="Icon feather-upload"
                                                            transform="translate(-3.75 -3.75)">
                                                            <path id="Path_10507" data-name="Path 10507"
                                                                d="M20.373,22.5v3.527a1.764,1.764,0,0,1-1.764,1.764H6.264A1.764,1.764,0,0,1,4.5,26.027V22.5"
                                                                transform="translate(0 -7.418)" fill="none"
                                                                stroke="#ff7135" stroke-linecap="round"
                                                                stroke-linejoin="round" stroke-width="1.5" />
                                                            <path id="Path_10508" data-name="Path 10508"
                                                                d="M19.318,8.909,14.909,4.5,10.5,8.909"
                                                                transform="translate(-2.473)" fill="none"
                                                                stroke="#ff7135" stroke-linecap="round"
                                                                stroke-linejoin="round" stroke-width="1.5" />
                                                            <path id="Path_10509" data-name="Path 10509"
                                                                d="M18,4.5V15.082" transform="translate(-5.563)"
                                                                fill="none" stroke="#ff7135" stroke-linecap="round"
                                                                stroke-linejoin="round" stroke-width="1.5" />
                                                        </g>
                                                    </svg> &nbsp; Upload image
                                                    <input type="file" class="upldfile"
                                                        (change)="uploadImage($event, img)" />
                                                </a>
                                            </div>

                                        </ng-template>
                                    </li>
                                </ul>

                                <div [ngbNavOutlet]="nav" class="mt-2"></div>
                                <div class="imgguiline">
                                    <h4 class="wt700 fs16 mb-3">Image Guidelines</h4>
                                    <ul>
                                        <li>
                                            Check out the sample images to ensure you provide the correct image View
                                            e.g. First image has to be the Front View
                                        </li>
                                        <li>
                                            Upload authentice product photos taken in bright lighting
                                        </li>
                                        <li>
                                            Images with text/Watermark are not acceptable in primary image
                                        </li>
                                        <li>
                                            Product image should not have any text
                                        </li>
                                        <li>
                                            Please add solo product images without any props
                                        </li>
                                    </ul>
                                    <a (click)="openWindowCustomClass(content)" href="javascript:void(0);">View
                                        Equibiz's Images Guidelines</a>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- /LEFT COL -->
                    <!-- RIGHT COL -->
                    <div class="col-lg-7 ad-p-rgt">
                        <div class="card card-ui p-0">
                            <div class="card-header p-4 bg-transparent">
                                <h4 class="mb-0 fs16 wt700">Product Information</h4>
                            </div>
                            <div class="card-body pt-0 p-4">
                                <ul ngbNav #prdctinfonav="ngbNav" [(activeId)]="infoactive" class="nav-tabs">
                                    <li [ngbNavItem]="95">
                                        <a ngbNavLink (click)="getproductdetails('basic')">Product Description</a>
                                        <ng-template ngbNavContent>
                                            <form [formGroup]="productForm">
                                                <!-- FORM BLOCK REPEAT -->
                                                <div class="form-container form-ipt-info">
                                                    <div class="floating-label">
                                                        <input class="form-control pr-5" placeholder=" "
                                                            formControlName="product_name">
                                                        <span class="highlight"></span>
                                                        <label>Product Name</label>
                                                        <i placement="top" ngbTooltip="Marketing Operating Price"
                                                            class="infoinput info-alert"></i>
                                                        <span class="help-block"
                                                            *ngIf="pf.product_name.invalid && pf.product_name.touched">Please
                                                            enter product name</span>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <div class="floating-label">
                                                                <div class="ng-autocomplete">
                                                                    <ng-autocomplete [data]="brands"
                                                                        [searchKeyword]="keyword"
                                                                        (selected)='selectBrandEvent($event)'
                                                                        (inputChanged)='onChangeSearchBrand($event)'
                                                                        (inputFocused)='onFocusedBrand($event)'
                                                                        [itemTemplate]="itemTemplate"
                                                                        formControlName="brand_name"
                                                                        placeholder="Brand Name">
                                                                    </ng-autocomplete>

                                                                    <ng-template #itemTemplate let-item>
                                                                        <a href="javascript:void(0)">
                                                                            <div class="d-flex">
                                                                                <span [innerHTML]="item.name"></span>
                                                                                <!-- <span [innerHTML]="item.current" class="current"></span> -->
                                                                            </div>
                                                                        </a>
                                                                    </ng-template>
                                                                    <div class="search-icon"><img
                                                                            src="assets/images/svg/search_logo.svg">
                                                                    </div>
                                                                </div>

                                                                <!-- <input value="2.5x" class="form-control pr-5" placeholder="" formControlName="brand_name"> -->
                                                                <span class="highlight"></span>
                                                                <!-- <label>Brand Name</label> -->
                                                                <i placement="top"
                                                                    ngbTooltip="Tooltop content goes here"
                                                                    class="infoinput info-alert"></i>
                                                                <span class="help-block"
                                                                    *ngIf="pf.brand_name.invalid && pf.brand_name.touched">Please
                                                                    enter brand name</span>

                                                                <a href="javascript:void(0);" target="_blank"
                                                                    [routerLink]="['/admin/brandmanagment']"
                                                                    class="add-whislist-link d-inline-block pt-2"><span>Manage
                                                                        Brands</span></a>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="floating-label">
                                                                <input value="2.5x" class="form-control pr-5"
                                                                    placeholder="" formControlName="model_number">
                                                                <span class="highlight"></span>
                                                                <label>Model Number</label>
                                                                <i placement="top"
                                                                    ngbTooltip="Tooltop content goes here"
                                                                    class="infoinput info-alert"></i>
                                                                <span class="help-block"
                                                                    *ngIf="pf.model_number.invalid && pf.model_number.touched">Please
                                                                    enter model number</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="floating-label">
                                                        <textarea placeholder=" " class="form-control msght"
                                                            formControlName="message"></textarea>
                                                        <span class="highlight"></span>
                                                        <label>Message</label>
                                                        <i placement="top" ngbTooltip="Tooltop content goes here"
                                                            class="infoinput msgalert info-alert"></i>
                                                    </div>
                                                    <div class="floating-label">
                                                        <textarea placeholder=" " class="form-control"
                                                            formControlName="key_features"></textarea>
                                                        <span class="highlight"></span>
                                                        <label>List of Key features</label>
                                                        <i placement="top" ngbTooltip="Tooltop content goes here"
                                                            class="infoinput kyfeatalert info-alert"></i>
                                                    </div>

                                                    <div class="uploadedBanners">
                                                        <ul>
                                                            <li *ngFor="let img of updatedBannerFiles; let i = index;">
                                                                <img src="{{ img }}">
                                                                <input type="file" #editbanner
                                                                    (change)="updatebanner($event, i)"
                                                                    style="display: none;">

                                                                <div class="text-center mt-10"><a href="javascript:;"
                                                                        (click)="editbanner.click()"><img
                                                                            src="assets/images/icons/edit-big.png"></a>
                                                                </div>

                                                                <div class="delete-img"><a href="javascript:;"
                                                                        (click)="deleteBanner(i)"><img
                                                                            src="assets/images/icons/delete.png"></a>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div class="mt-10 pb-lg-4">
                                                        <div class="mb-1">
                                                            <input type="file" #banner style="display: none;"
                                                                (change)="bannerUpload($event)" multiple>
                                                            <a href="javascript:void(0);" (click)="banner.click()"
                                                                class="add-whislist-link"> <svg
                                                                    xmlns="http://www.w3.org/2000/svg" width="12"
                                                                    height="12" viewBox="0 0 14.681 14.681"
                                                                    class="mr-1">
                                                                    <g id="Icon_feather-plus"
                                                                        data-name="Icon feather-plus"
                                                                        transform="translate(1 1)">
                                                                        <path id="Path_8" data-name="Path 8"
                                                                            d="M18,7.5V20.181"
                                                                            transform="translate(-11.659 -7.5)"
                                                                            fill="none" stroke="#ff7135"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round" stroke-width="2">
                                                                        </path>
                                                                        <path id="Path_9" data-name="Path 9"
                                                                            d="M7.5,18H20.181"
                                                                            transform="translate(-7.5 -11.659)"
                                                                            fill="none" stroke="#ff7135"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round" stroke-width="2">
                                                                        </path>
                                                                    </g>
                                                                </svg><span>Add feature banner images</span></a>
                                                        </div>
                                                    </div>

                                                </div>
                                                <!-- /FORM BLOCK REPEAT -->
                                                <!-- FORM BLOCK REPEAT -->
                                                <div class="form-container">
                                                    <h4 class="fs14 wt700 mb-4">Return</h4>
                                                    <div class="row">
                                                        <div class="col-sm-8 col-7 col-xl-9">
                                                            <div class="floating-label">
                                                                <input class="form-control pr-5" placeholder=" "
                                                                    formControlName="return_period">
                                                                <span class="highlight"></span>
                                                                <label>Return Period</label>
                                                                <i placement="top"
                                                                    ngbTooltip="Tooltop content goes here"
                                                                    class="infoinput info-alert"></i>
                                                            </div>
                                                            <span class="help-block"
                                                                *ngIf="pf.return_period.invalid && pf.return_period.touched">Please
                                                                enter valid return period</span>
                                                        </div>
                                                        <div class="col-sm-4 col-5 pl-0 col-xl-3 selectactive">
                                                            <ng-selectize [config]="config"
                                                                formControlName="return_period_unit"
                                                                [options]="monthdata" placeholder="Select">
                                                            </ng-selectize>
                                                        </div>

                                                    </div>
                                                </div>
                                                <!-- /FORM BLOCK REPEAT -->
                                              
                                            </form>
                                            <angular-editor [(ngModel)]="return_summary" [config]="editor_config"></angular-editor>
                                            <button (click)="saveproductdescription()" [disabled]="productForm.invalid || basicLoading" class="btn px-4 buybtn fs16 radius10 ">
                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="basicLoading"></span>
                                                &nbsp;Save and Next
                                            </button>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="99" [disabled]="example" *ngIf="product_template == 'parentchild'">
                                        <a ngbNavLink>Child Details</a>
                                        <ng-template ngbNavContent *ngIf="product_template == 'parentchild'">
                                            <!-- FORM BLOCK REPEAT -->
                                            <div class="form-container tch-rw-rpt" cdkDropList
                                                (cdkDropListDropped)="drop($event)">

                                                <div class="table-responsive">
                                                    <table class="table custom-table xs">
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Status</th>
                                                                <th>Edit</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            <!--Empty State-->
                                                            <tr *ngIf="!childsDetails.length">
                                                                <td colspan="12">
                                                                    <div class="row pt-40 pb-40">
                                                                        <div class="col-md-12 text-center">
                                                                            <div class="mb-20"><img
                                                                                    src="/assets/images/empty-state.png"></div>
                                                                            <div class="fs16">There are no records to display!</div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <!--End Empty State-->

                                                            <!-- TR -->
                                                            <tr *ngFor="let child of childsDetails; let i = index;">
                                                                <td>
                                                                    <div class="order-info">
                                                                        <div class="order-img">
                                                                            <img src="{{ child.image? child.image: 'assets/images/p-2.jpg' }}" />
                                                                        </div>
                                                                        <div class="pd-seller-cntnt">
                                                                            <div class="txtdefault">
                                                                                <a href="javascript:;">{{ child.name }}</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td *ngIf="child.status == 1"><span class="text-active">Active</span></td>
                                                                <td *ngIf="child.status == 0"><span class="text-inactive">Inctive</span></td>
                                                                <td class="d-flex align-items-center">
                                                                    <a  (click)="openWindowCustomClassedit(editparentchild, child)" href="javascript:;" class="place-bid">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            xmlns:xlink="http://www.w3.org/1999/xlink" width="14" height="14"
                                                                            viewBox="0 0 7.984 7.984">
                                                                            <defs>
                                                                                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1"
                                                                                    gradientUnits="objectBoundingBox">
                                                                                    <stop offset="0" stop-color="#79b7ff" />
                                                                                    <stop offset="1" stop-color="#07f" />
                                                                                </linearGradient>
                                                                            </defs>
                                                                            <path id="np_edit_3094244_000000"
                                                                                d="M6.485-.008A1.491,1.491,0,0,0,5.428.43L.41,5.447,2.527,7.563,7.544,2.546A1.5,1.5,0,0,0,6.485-.008ZM.151,5.893,0,7.706a.249.249,0,0,0,.269.269l1.812-.154Z"
                                                                                transform="translate(0.003 0.008)" fill="url(#linear-gradient)" />
                                                                        </svg>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            <!-- /TR -->
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <input type="submit" (click)="getproductdetails('technical')"
                                                value="Next" class="btn px-4 buybtn fs16 radius10 " />
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="96" [disabled]="example" (click)="getproductdetails('technical')">
                                        <a ngbNavLink>Technical Details</a>
                                        <ng-template ngbNavContent>
                                            <!-- FORM BLOCK REPEAT -->
                                            <div class="form-container tch-rw-rpt" cdkDropList
                                                (cdkDropListDropped)="drop($event)">
                                                <div *ngFor="let attr of productAttributes; let i = index;">

                                                    <!-- INPUT TEXT FIELD-->
                                                    
                                                            <div class="floating-label" cdkDrag
                                                                *ngIf="attr.type_info == 'text' || attr.type_info == 'link'">
                                                                <span class="dragos"></span>
                                                                <div class="position-relative">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <input placeholder=" " class="form-control"
                                                                                [disabled]="!attr.isEnabled"
                                                                                [(ngModel)]="attr.attribute_value">
                                                                            <span class="highlight"></span>
                                                                            <label>{{ attr.label_name }}</label>
                                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                                class="infoinput info-alert"></i>
                                                                        </div>
                                                                        <div class="col-md-4" *ngIf="hideTechnicalFilter">
                                                                            <select class="form-control" [(ngModel)]="attr.filter" [disabled]="!attr.isEnabled">
                                                                                <option value="yes">Yes</option>
                                                                                <option value="no">No</option>
                                                                            </select>
                                                                            <span class="highlight"></span>
                                                                            <label>Show in Filter</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <ul class="tch-d-actn">
                                                                    <li>
                                                                        <a class="tdedit" href="javascript:void(0);"
                                                                            (click)="updateattribute(attr, 'enable')"
                                                                            *ngIf="!attr.isEnabled"></a>
                                                                        <a class="tddsv" href="javascript:void(0);"
                                                                            (click)="updateattribute(attr, 'save')"
                                                                            *ngIf="attr.isEnabled"></a>
                                                                    </li>
                                                                    <li>
                                                                        <a class="tddlt" href="javascript:void(0);"
                                                                            (click)="deleteattribute(attr.id)"></a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        
                                                    <!-- /INPUT TEXT FIELD -->

                                                    <!-- INPUT DATE TIME FIELD-->
                                                    <div class="floating-label" cdkDrag
                                                        *ngIf="attr.type_info == 'date-time'">
                                                        <span class="dragos"></span>
                                                        <div class="position-relative">
                                                            <div class="row">
                                                                <div class="col-md-8">
                                                                    <input placeholder=" " class="form-control" ngbDatepicker
                                                                        #ds="ngbDatepicker" (click)="ds.toggle()"
                                                                        [disabled]="!attr.isEnabled"
                                                                        [(ngModel)]="attr.attribute_value">
                                                                    <span class="highlight"></span>
                                                                    <label>{{ attr.label_name }}</label>
                                                                    <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                        class="infoinput info-alert"></i>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <select class="form-control" [(ngModel)]="attr.filter" [disabled]="!attr.isEnabled">
                                                                        <option value="yes">Yes</option>
                                                                        <option value="no">No</option>
                                                                    </select>
                                                                    <span class="highlight"></span>
                                                                    <label>Show in Filter</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ul class="tch-d-actn">
                                                            <li>
                                                                <a class="tdedit" href="javascript:void(0);"
                                                                    (click)="updateattribute(attr, 'enable')"
                                                                    *ngIf="!attr.isEnabled"></a>
                                                                <a class="tddsv" href="javascript:void(0);"
                                                                    (click)="updateattribute(attr, 'save')"
                                                                    *ngIf="attr.isEnabled"></a>
                                                            </li>
                                                            <li>
                                                                <a class="tddlt" href="javascript:void(0);"
                                                                    (click)="deleteattribute(attr.id)"></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <!-- /INPUT DATE TIME FIELD -->




                                                    <!-- INPUT -->
                                                    <div class="floating-label" cdkDrag
                                                        *ngIf="attr.type_info == 'yes-or-no'">
                                                        <span class="dragos"></span>
                                                        <div class="row">
                                                            <div class="col-md-8">
                                                                <ng-selectize [config]="config" [disabled]="!attr.isEnabled"
                                                                    [(ngModel)]="attr.attribute_value" [options]="yesNoArray"
                                                                    placeholder="{{attr.label_name}}">
                                                                </ng-selectize>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <select class="form-control" [(ngModel)]="attr.filter" [disabled]="!attr.isEnabled">
                                                                    <option value="yes">Yes</option>
                                                                    <option value="no">No</option>
                                                                </select>
                                                                <span class="highlight"></span>
                                                                <label>Show in Filter</label>
                                                            </div>
                                                        </div>
                                                        <ul class="tch-d-actn">
                                                            <li>
                                                                <a class="tdedit" href="javascript:void(0);"
                                                                    (click)="updateattribute(attr, 'enable')"
                                                                    *ngIf="!attr.isEnabled"></a>
                                                                <a class="tddsv" href="javascript:void(0);"
                                                                    (click)="updateattribute(attr, 'save')"
                                                                    *ngIf="attr.isEnabled"></a>
                                                            </li>
                                                            <li>
                                                                <a class="tddlt" href="javascript:void(0);"
                                                                    (click)="deleteattribute(attr.id)"></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <!-- /INPUT -->


                                                    <!-- INPUT -->
                                                    <div class="floating-label" cdkDrag
                                                        *ngIf="attr.type_info == 'numeric'">
                                                        <span class="dragos"></span>
                                                        <div class="row pbrktscl">
                                                            <div class="col-sm-4">
                                                                <input placeholder="{{attr.label_name}}"
                                                                    [disabled]="!attr.isEnabled" class="form-control"
                                                                    [(ngModel)]="attr.attribute_value">
                                                            </div>
                                                            <div class="col-sm-4 selectactive">
                                                                <ng-selectize [config]="config"
                                                                    [options]="numericFieldArray"
                                                                    [disabled]="!attr.isEnabled"
                                                                    [(ngModel)]="attr.attribute_value_addition"
                                                                    placeholder="">

                                                                </ng-selectize>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <select class="form-control" [(ngModel)]="attr.filter" [disabled]="!attr.isEnabled">
                                                                    <option value="yes">Yes</option>
                                                                    <option value="no">No</option>
                                                                </select>
                                                                <span class="highlight"></span>
                                                                <label>Show in Filter</label>
                                                            </div>
                                                        </div>
                                                        <ul class="tch-d-actn">
                                                            <li>
                                                                <a class="tdedit" href="javascript:void(0);"
                                                                    (click)="updateattribute(attr, 'enable')"
                                                                    *ngIf="!attr.isEnabled"></a>
                                                                <a class="tddsv" href="javascript:void(0);"
                                                                    (click)="updateattribute(attr, 'save')"
                                                                    *ngIf="attr.isEnabled"></a>
                                                            </li>
                                                            <li>
                                                                <a class="tddlt" href="javascript:void(0);"
                                                                    (click)="deleteattribute(attr.id)"></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <!-- /INPUT -->
                                                </div>
                                            </div>
                                            <!-- /FORM BLOCK REPEAT -->
                                            <div class="mb-3 ">
                                                <a href="javascript:void(0);"
                                                    (click)="openWindowCustomClass(addattribute)"
                                                    class="add-whislist-link"> <svg xmlns="http://www.w3.org/2000/svg"
                                                        width="12" height="12" viewBox="0 0 14.681 14.681" class="mr-1">
                                                        <g id="Icon_feather-plus" data-name="Icon feather-plus"
                                                            transform="translate(1 1)">
                                                            <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181"
                                                                transform="translate(-11.659 -7.5)" fill="none"
                                                                stroke="#ff7135" stroke-linecap="round"
                                                                stroke-linejoin="round" stroke-width="2"></path>
                                                            <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181"
                                                                transform="translate(-7.5 -11.659)" fill="none"
                                                                stroke="#ff7135" stroke-linecap="round"
                                                                stroke-linejoin="round" stroke-width="2"></path>
                                                        </g>
                                                    </svg><span>Add New Attribute</span></a>
                                            </div>
                                            <input type="submit" (click)="getproductdetails('seller')"
                                                value="Save  and Next" class="btn px-4 buybtn fs16 radius10 " />
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="97" (click)="getproductdetails('seller')">
                                        <a ngbNavLink>Seller Type</a>
                                        <ng-template ngbNavContent class="slrtypeui">
                                            <form [formGroup]="sellerTypeForm">
                                                <!-- FORM BLOCK REPEAT -->
                                                <div class="form-container form-ipt-info">
                                                    <div class="floating-label">
                                                        <ng-selectize [config]="config" formControlName="seller_type"
                                                            [options]="regdata" placeholder="Seller Type"
                                                            (ngModelChange)="changesellertype($event)"> </ng-selectize>
                                                    </div>
                                                    <div class="floating-label" *ngIf="seller_type != 'any-seller'">
                                                        <input placeholder=" " class="form-control"
                                                            formControlName="seller_brand_name">
                                                        <span class="highlight"></span>
                                                        <label>Brand Name</label>
                                                        <i placement="top" ngbTooltip="Tooltop content goes here"
                                                            class="infoinput info-alert"></i>
                                                    </div>
                                                </div>
                                                <!-- /FORM BLOCK REPEAT -->
                                                <div class="form-container form-ipt-info"
                                                    *ngIf="seller_type != 'registered-brand-owner'">
                                                    <div class="floating-label mb-10 qs-chk-rp">
                                                        <h4 class="txtseconday wt700 fs14 mb-3">Does Seller has Brand
                                                            Logo and Website?</h4>
                                                        <div class="cssinptchk">
                                                            <span class="css-check">
                                                                <input class="css-checkbx" name="brand_authorization"
                                                                    type="radio" value="yes"
                                                                    formControlName="brand_authorization" />
                                                                <label class="css-label"></label>
                                                            </span>
                                                            <p>Yes</p>
                                                        </div>
                                                        <div class="cssinptchk">
                                                            <span class="css-check">
                                                                <input class="css-checkbx" name="brand_authorization"
                                                                    type="radio" value="no"
                                                                    formControlName="brand_authorization" />
                                                                <label class="css-label"></label>
                                                            </span>
                                                            <p>No</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- FORM BLOCK REPEAT -->
                                                <div class="form-container form-ipt-info"
                                                    *ngIf="seller_type == 'registered-brand-owner' || sellerTypeForm.get('brand_authorization').value == 'yes'">
                                                    <h4 class="fs14 wt700 mb-4">Brand Details</h4>
                                                    <div class="floating-label">
                                                        <input placeholder=" " class="form-control"
                                                            formControlName="brand_website_link">
                                                        <span class="highlight"></span>
                                                        <label>Brand Website Link</label>
                                                        <i placement="top" ngbTooltip="Tooltop content goes here"
                                                            class="infoinput info-alert"></i>
                                                    </div>
                                                    <div class="row mbl-two-col">
                                                        <div class="col-xl-9">
                                                            <div class="ipt-w-btn">
                                                                <div class="floating-label">
                                                                    <input type="text" class="form-control"
                                                                        placeholder=" " disabled>
                                                                    <a href="javascript:void(0)"
                                                                        class="btn-d-outline d-inline-block place-bid">
                                                                        {{ sellertype_logo_name? 'Replace' : 'Upload'}}
                                                                        <input type="file" class="upldfile"
                                                                            (change)="onFileChange($event, 'logo')">
                                                                    </a>
                                                                    <span class="highlight"></span>
                                                                    <label>
                                                                        <svg class="align-middle"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="7.532" height="15.065"
                                                                            viewBox="0 0 7.532 15.065">
                                                                            <path id="Icon_material-attach-file"
                                                                                data-name="Icon material-attach-file"
                                                                                d="M17.005,4.924V12.8a2.739,2.739,0,1,1-5.478,0V4.239a1.712,1.712,0,0,1,3.424,0v7.19a.685.685,0,0,1-1.37,0V4.924H12.554v6.505a1.712,1.712,0,1,0,3.424,0V4.239a2.739,2.739,0,0,0-5.478,0V12.8a3.766,3.766,0,0,0,7.532,0V4.924Z"
                                                                                transform="translate(-10.5 -1.5)"
                                                                                fill="#5E6A7C" />
                                                                        </svg>
                                                                        {{ sellertype_logo_name? sellertype_logo_name :
                                                                        'Logo Image'}}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-20" *ngIf="seller_logo_link">
                                                        <div class="col-md-2">
                                                            <a href="{{ seller_logo_link }}" target="_blank"><img src="{{ seller_logo_link }}" class="img-fluid"></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-container form-ipt-info">
                                                    <div class="floating-label mb-10 qs-chk-rp">
                                                        <h4 class="txtseconday wt700 fs14 mb-3">Is compulsory
                                                            Registration Scheme (CRS) mandatory?</h4>
                                                        <div class="cssinptchk">
                                                            <span class="css-check">
                                                                <input class="css-checkbx" name="isCrs" type="radio"
                                                                    value="yes" formControlName="isCrs" [checked]="" />
                                                                <label class="css-label"></label>
                                                            </span>
                                                            <p>Yes</p>
                                                        </div>
                                                        <div class="cssinptchk">
                                                            <span class="css-check">
                                                                <input class="css-checkbx" name="isCrs" type="radio"
                                                                    value="no" formControlName="isCrs" [checked]="" />
                                                                <label class="css-label"></label>
                                                            </span>
                                                            <p>No</p>
                                                        </div>
                                                        <div class="floating-label"
                                                            *ngIf="sellerTypeForm.get('isCrs').value == 'yes'">
                                                            <input placeholder=" " class="form-control"
                                                                formControlName="seller_crs_number">
                                                            <span class="highlight"></span>
                                                            <label>CRS Number</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                    </div>



                                                </div>
                                                <!-- /FORM BLOCK REPEAT -->
                                                <!-- FORM BLOCK REPEAT -->
                                                <div class="form-container form-ipt-info"
                                                    *ngIf="seller_type == 'registered-brand-owner'">
                                                    <h4 class="fs14 wt700 mb-4">Trademark Details</h4>
                                                    <div class="floating-label mb-4 qs-chk-rp">
                                                        <h4 class="txtseconday wt700 fs14 mb-3">Is your trademark
                                                            registered or pending</h4>
                                                        <a class="position-relative mr-4 d-inline-block"
                                                            href="javascript:void(0);">
                                                            <input name="registered_trademark_type" type="radio"
                                                                class="css-checkbx" value="registered"
                                                                formControlName="registered_trademark_type">
                                                            <span class="btn-d-outline px-4 place-bid">Registered</span>
                                                        </a>
                                                        <a class="position-relative d-inline-block"
                                                            href="javascript:void(0);">
                                                            <input type="radio" name="registered_trademark_type"
                                                                class="css-checkbx" value="pending"
                                                                formControlName="registered_trademark_type">
                                                            <span class="btn-d-outline px-4 place-bid">Pending</span>
                                                        </a>

                                                    </div>
                                                    <div class="floating-label"
                                                        *ngIf="sellerTypeForm.get('registered_trademark_type').value == 'registered'">
                                                        <input placeholder=" " class="form-control"
                                                            formControlName="registration_trademark_number">
                                                        <span class="highlight"></span>
                                                        <label>Trademark Registration Number</label>
                                                        <i placement="top" ngbTooltip="Tooltop content goes here"
                                                            class="infoinput info-alert"></i>
                                                    </div>
                                                    <div class="row mbl-two-col"
                                                        *ngIf="sellerTypeForm.get('registered_trademark_type').value == 'registered'">
                                                        <div class="col-xl-9">
                                                            <div class="ipt-w-btn">
                                                                <div class="floating-label">
                                                                    <input type="text" class="form-control"
                                                                        placeholder=" ">
                                                                    <a href="javascript:void(0)"
                                                                        class="btn-d-outline d-inline-block place-bid">
                                                                        {{ sellertype_trademark_name? 'Replace' :
                                                                        'Upload'}}
                                                                        <input type="file" class="upldfile"
                                                                            (change)="onFileChange($event, 'trademark')">
                                                                    </a>
                                                                    <span class="highlight"></span>
                                                                    <label>
                                                                        <svg class="align-middle"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="7.532" height="15.065"
                                                                            viewBox="0 0 7.532 15.065">
                                                                            <path id="Icon_material-attach-file"
                                                                                data-name="Icon material-attach-file"
                                                                                d="M17.005,4.924V12.8a2.739,2.739,0,1,1-5.478,0V4.239a1.712,1.712,0,0,1,3.424,0v7.19a.685.685,0,0,1-1.37,0V4.924H12.554v6.505a1.712,1.712,0,1,0,3.424,0V4.239a2.739,2.739,0,0,0-5.478,0V12.8a3.766,3.766,0,0,0,7.532,0V4.924Z"
                                                                                transform="translate(-10.5 -1.5)"
                                                                                fill="#5E6A7C" />
                                                                        </svg>
                                                                        {{ sellertype_trademark_name?
                                                                        sellertype_trademark_name : 'Trademark
                                                                        certificate'}}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="floating-label mb-4 qs-chk-rp">
                                                        <h4 class="txtseconday wt700 fs14 mb-3">What is the Trademark
                                                            Type</h4>
                                                        <a class="position-relative mr-4 d-inline-block"
                                                            href="javascript:void(0);">
                                                            <input name="trademark_type" type="radio"
                                                                class="css-checkbx" value="device_mark"
                                                                formControlName="trademark_type">
                                                            <span class="btn-d-outline px-4 place-bid">Device
                                                                Mark</span>
                                                        </a>
                                                        <a class="position-relative d-inline-block"
                                                            href="javascript:void(0);">
                                                            <input type="radio" name="trademark_type"
                                                                class="css-checkbx" value="word_mark"
                                                                formControlName="trademark_type">
                                                            <span class="btn-d-outline px-4 place-bid">Word Mark</span>
                                                        </a>

                                                    </div>
                                                    <div class="row mbl-two-col">
                                                        <div class="col-xl-9">
                                                            <div class="ipt-w-btn">
                                                                <div class="floating-label">
                                                                    <input type="text" class="form-control"
                                                                        placeholder=" ">
                                                                    <a href="javascript:void(0)"
                                                                        class="btn-d-outline d-inline-block place-bid">
                                                                        {{ sellertype_device_name? 'Replace' :
                                                                        'Upload'}}
                                                                        <input type="file" class="upldfile"
                                                                            (change)="onFileChange($event, 'device')">
                                                                    </a>
                                                                    <span class="highlight"></span>
                                                                    <label>
                                                                        <svg class="align-middle"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="7.532" height="15.065"
                                                                            viewBox="0 0 7.532 15.065">
                                                                            <path id="Icon_material-attach-file"
                                                                                data-name="Icon material-attach-file"
                                                                                d="M17.005,4.924V12.8a2.739,2.739,0,1,1-5.478,0V4.239a1.712,1.712,0,0,1,3.424,0v7.19a.685.685,0,0,1-1.37,0V4.924H12.554v6.505a1.712,1.712,0,1,0,3.424,0V4.239a2.739,2.739,0,0,0-5.478,0V12.8a3.766,3.766,0,0,0,7.532,0V4.924Z"
                                                                                transform="translate(-10.5 -1.5)"
                                                                                fill="#5E6A7C" />
                                                                        </svg>
                                                                        {{ sellertype_device_name?
                                                                        sellertype_device_name : 'Device Image'}}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- /FORM BLOCK REPEAT -->
                                                <!-- FORM BLOCK REPEAT -->
                                                <div class="form-container form-ipt-info lstnobd">
                                                    <h4 class="fs14 wt700 mb-4"
                                                        *ngIf="seller_type == 'registered-brand-owner'">More Details
                                                    </h4>
                                                    <div class="floating-label mb-4 qs-chk-rp rdodlbl"
                                                        *ngIf="seller_type == 'registered-brand-owner'">
                                                        <h4 class="txtseconday wt700 fs14 mb-3">How do you Sell?</h4>
                                                        <a class="position-relative mr-4 d-inline-block"
                                                            href="javascript:void(0);">
                                                            <input name="sell_type" type="radio" class="css-checkbx"
                                                                value="online" formControlName="sell_type">
                                                            <span class="btn-d-outline px-4 place-bid">Online</span>
                                                        </a>
                                                        <a class="position-relative d-inline-block"
                                                            href="javascript:void(0);">
                                                            <input type="radio" name="sell_type" class="css-checkbx"
                                                                value="offline" formControlName="sell_type">
                                                            <span class="btn-d-outline px-4 place-bid">Offline</span>
                                                        </a>
                                                    </div>
                                                    <div class="floating-label mb-4 qs-chk-rp "
                                                        *ngIf="seller_type == 'registered-brand-owner'">
                                                        <h4 class="txtseconday wt700 fs14 mb-3">Do you want other
                                                            sellers to list your product</h4>
                                                        <a class="position-relative mr-4 d-inline-block"
                                                            href="javascript:void(0);">
                                                            <input name="other_sellers" type="radio" class="css-checkbx"
                                                                value="yes" formControlName="other_sellers">
                                                            <span class="btn-d-outline px-4 place-bid">Yes</span>
                                                        </a>
                                                        <a class="position-relative d-inline-block"
                                                            href="javascript:void(0);">
                                                            <input type="radio" name="other_sellers" class="css-checkbx"
                                                                value="no" formControlName="other_sellers">
                                                            <span class="btn-d-outline px-4 place-bid">No</span>
                                                        </a>
                                                    </div>

                                                    <div class="row mbl-two-col"
                                                        *ngIf="seller_type == 'registered-brand-owner'">
                                                        <div class="col-xl-9">
                                                            <div class="ipt-w-btn">
                                                                <div class="floating-label">
                                                                    <input type="text" class="form-control"
                                                                        placeholder=" ">
                                                                    <a href="javascript:void(0)"
                                                                        class="btn-d-outline d-inline-block place-bid">
                                                                        {{ sellertype_distributor_name? 'Replace' :
                                                                        'Upload'}}
                                                                        <input type="file" class="upldfile"
                                                                            (change)="onFileChange($event, 'distributor')">
                                                                    </a>
                                                                    <span class="highlight"></span>
                                                                    <label>
                                                                        <svg class="align-middle"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="7.532" height="15.065"
                                                                            viewBox="0 0 7.532 15.065">
                                                                            <path id="Icon_material-attach-file"
                                                                                data-name="Icon material-attach-file"
                                                                                d="M17.005,4.924V12.8a2.739,2.739,0,1,1-5.478,0V4.239a1.712,1.712,0,0,1,3.424,0v7.19a.685.685,0,0,1-1.37,0V4.924H12.554v6.505a1.712,1.712,0,1,0,3.424,0V4.239a2.739,2.739,0,0,0-5.478,0V12.8a3.766,3.766,0,0,0,7.532,0V4.924Z"
                                                                                transform="translate(-10.5 -1.5)"
                                                                                fill="#5E6A7C" />
                                                                        </svg>
                                                                        {{ sellertype_distributor_name?
                                                                        sellertype_distributor_name : 'Distributor List
                                                                        (XLSX)'}}
                                                                    </label>
                                                                </div>
                                                                <div class="floating-label">
                                                                    <a href="{{ distributor_link }}"
                                                                        [download]="distributor_link"
                                                                        class="btn-d-outline d-inline-block place-bid"
                                                                        *ngIf="distributor_link">
                                                                        Download
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="floating-label mb-4 qs-chk-rp"
                                                        *ngIf="seller_type != 'any-seller'">
                                                        <h4 class="txtseconday wt700 fs14 mb-3">Is the product is made
                                                            in India</h4>
                                                        <a class="position-relative mr-4 d-inline-block"
                                                            href="javascript:void(0);">
                                                            <input name="made_in_india" type="radio" class="css-checkbx"
                                                                value="yes" formControlName="made_in_india">
                                                            <span class="btn-d-outline px-4 place-bid">Yes</span>
                                                        </a>
                                                        <a class="position-relative d-inline-block"
                                                            href="javascript:void(0);">
                                                            <input type="radio" name="made_in_india" class="css-checkbx"
                                                                value="no" formControlName="made_in_india">
                                                            <span class="btn-d-outline px-4 place-bid">No</span>
                                                        </a>
                                                    </div>
                                                    <div class="floating-label"
                                                        *ngIf="seller_type != 'any-seller' && sellerTypeForm.get('made_in_india').value == 'no'">
                                                        <input placeholder=" " class="form-control"
                                                            formControlName="country_of_origin">
                                                        <span class="highlight"></span>
                                                        <label>Country of Origin</label>
                                                        <i placement="top" ngbTooltip="Tooltop content goes here"
                                                            class="infoinput info-alert"></i>
                                                    </div>
                                                    <div class="floating-label"
                                                        *ngIf="seller_type != 'any-seller' && sellerTypeForm.get('made_in_india').value == 'no'">
                                                        <input placeholder=" " class="form-control"
                                                            formControlName="importer_type">
                                                        <span class="highlight"></span>
                                                        <label>Importer Name</label>
                                                        <i placement="top" ngbTooltip="Tooltop content goes here"
                                                            class="infoinput info-alert"></i>
                                                    </div>
                                                    <div class="floating-label"
                                                        *ngIf="seller_type != 'any-seller' && sellerTypeForm.get('made_in_india').value == 'no'">
                                                        <input placeholder=" " class="form-control"
                                                            formControlName="iec_number">
                                                        <span class="highlight"></span>
                                                        <label>IEC Number</label>
                                                        <i placement="top" ngbTooltip="Tooltop content goes here"
                                                            class="infoinput info-alert"></i>
                                                    </div>
                                                    <div class="floating-label mb-4 qs-chk-rp"
                                                        *ngIf="seller_type != 'any-seller'">
                                                        <h4 class="txtseconday wt700 fs14 mb-3">Do you manufacture your
                                                            product?</h4>
                                                        <a class="position-relative mr-4 d-inline-block"
                                                            href="javascript:void(0);">
                                                            <input name="manufacturing" type="radio" class="css-checkbx"
                                                                value="yes" formControlName="manufacturing">
                                                            <span class="btn-d-outline px-4 place-bid">Yes</span>
                                                        </a>
                                                        <a class="position-relative d-inline-block"
                                                            href="javascript:void(0);">
                                                            <input type="radio" name="manufacturing" class="css-checkbx"
                                                                value="no" formControlName="manufacturing">
                                                            <span class="btn-d-outline px-4 place-bid">No</span>
                                                        </a>
                                                    </div>
                                                    <div class="floating-label"
                                                        *ngIf="seller_type != 'any-seller' && sellerTypeForm.get('manufacturing').value == 'yes'">
                                                        <textarea placeholder=" " class="form-control"
                                                            formControlName="manufacturing_address"></textarea>
                                                        <span class="highlight"></span>
                                                        <label>Address of manufacturing unit</label>
                                                        <i placement="top" ngbTooltip="Tooltop content goes here"
                                                            class="infoinput info-alert"></i>
                                                    </div>
                                                    <div class="row mbl-two-col"
                                                        *ngIf="seller_type != 'any-seller' && sellerTypeForm.get('manufacturing').value == 'yes'">
                                                        <div class="col-xl-9">
                                                            <div class="ipt-w-btn">
                                                                <div class="floating-label">
                                                                    <input type="text" class="form-control"
                                                                        placeholder=" ">
                                                                    <a href="javascript:void(0)"
                                                                        class="btn-d-outline d-inline-block place-bid">
                                                                        {{ sellertype_address_name? 'Replace' :
                                                                        'Upload'}}
                                                                        <input type="file" class="upldfile"
                                                                            (change)="onFileChange($event, 'address')">
                                                                    </a>
                                                                    <span class="highlight"></span>
                                                                    <label>
                                                                        <svg class="align-middle"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="7.532" height="15.065"
                                                                            viewBox="0 0 7.532 15.065">
                                                                            <path id="Icon_material-attach-file"
                                                                                data-name="Icon material-attach-file"
                                                                                d="M17.005,4.924V12.8a2.739,2.739,0,1,1-5.478,0V4.239a1.712,1.712,0,0,1,3.424,0v7.19a.685.685,0,0,1-1.37,0V4.924H12.554v6.505a1.712,1.712,0,1,0,3.424,0V4.239a2.739,2.739,0,0,0-5.478,0V12.8a3.766,3.766,0,0,0,7.532,0V4.924Z"
                                                                                transform="translate(-10.5 -1.5)"
                                                                                fill="#5E6A7C" />
                                                                        </svg>
                                                                        {{ sellertype_address_name?
                                                                        sellertype_address_name : 'Address Proof'}}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <!-- /FORM BLOCK REPEAT -->
                                                <button type="button" class="btn px-4 buybtn fs16 radius10"
                                                    (click)="savesellertype()" [disabled]="sellerLoading || sellerTypeForm.invalid">
                                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="sellerLoading"></span>&nbsp;Save and Next
                                                </button>
                                            </form>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="98" (click)="getproductdetails('pricing')">
                                        <a ngbNavLink>Pricing & Marketplace</a>
                                        <ng-template ngbNavContent>
                                            <form [formGroup]="pricingForm">
                                                <!-- FORM BLOCK REPEAT -->
                                                <div class="form-container form-ipt-info">
                                                    <h4 class="fs14 wt700 mb-4">Product Price</h4>
                                                    <div class="floating-label">
                                                        <input class="form-control pr-5" placeholder=" "
                                                            formControlName="operating_price">
                                                        <span class="highlight"></span>
                                                        <label>Marketing Operating Price (MOP) (in ₹)</label>
                                                        <i placement="top" ngbTooltip="Marketing Operating Price"
                                                            class="infoinput info-alert"></i>
                                                        <span class="help-block"
                                                            *ngIf="prf.operating_price.invalid && prf.operating_price.touched">Please
                                                            enter valid operating price</span>
                                                    </div>
                                                    <div class="floating-label">
                                                        <input placeholder=" " class="form-control"
                                                            formControlName="retail_price">
                                                        <span class="highlight"></span>
                                                        <label>Maximum Retail Price (MRP) (in ₹)</label>
                                                        <i placement="top" ngbTooltip="Tooltop content goes here"
                                                            class="infoinput info-alert"></i>
                                                        <span class="help-block"
                                                            *ngIf="prf.retail_price.invalid && prf.retail_price.touched">Please
                                                            enter valid retail price</span>
                                                    </div>
                                                    <div class="row pbrktscl">
                                                        <div class="col-sm-8">
                                                            <div class="floating-label">
                                                                <input class="form-control pr-5" placeholder=" "
                                                                    formControlName="upper_bracket">
                                                                <span class="highlight"></span>
                                                                <label>Upper Bracket (in ₹)</label>
                                                                <i placement="top"
                                                                    ngbTooltip="Tooltop content goes here"
                                                                    class="infoinput info-alert"></i>
                                                            </div>
                                                            <span class="help-block"
                                                                *ngIf="prf.upper_bracket.invalid && prf.upper_bracket.touched">Please
                                                                enter valid upper bracket value</span>
                                                        </div>
                                                        <div class="col-sm-4 selectactive">
                                                            <ng-selectize [config]="config" [options]="currency_data"
                                                                formControlName="upper_bracket_unit"
                                                                placeholder="Select"> </ng-selectize>
                                                        </div>
                                                    </div>
                                                    <div class="row pbrktscl">
                                                        <div class="col-sm-8">
                                                            <div class="floating-label">
                                                                <input class="form-control pr-5" placeholder=" "
                                                                    formControlName="lower_bracket">
                                                                <span class="highlight"></span>
                                                                <label>Lower Bracket (in %)</label>
                                                                <i placement="top"
                                                                    ngbTooltip="Tooltop content goes here"
                                                                    class="infoinput info-alert"></i>
                                                            </div>
                                                            <span class="help-block"
                                                                *ngIf="prf.lower_bracket.invalid && prf.lower_bracket.touched">Please
                                                                enter valid lower bracket value</span>
                                                        </div>
                                                        <div class="col-sm-4 selectactive">
                                                            <ng-selectize [config]="config" [options]="currency_data"
                                                                formControlName="lower_bracket_percentage"
                                                                placeholder="Select"> </ng-selectize>
                                                        </div>
                                                    </div>
                                                    <div class="row pbrktscl">
                                                        <div class="col-md-12">
                                                            <div class="ng-autocomplete">
                                                                <ng-autocomplete [data]="hsns" [searchKeyword]="Hsnkeyword"
                                                                    (selected)='selectHsnEvent($event)'
                                                                    (inputChanged)='onChangeSearchHsn($event)'
                                                                    (inputFocused)='onFocusedHsn($event)'
                                                                    [itemTemplate]="itemTemplate" formControlName="hsn"
                                                                    placeholder="HSN Number">
                                                                </ng-autocomplete>

                                                                <ng-template #itemTemplate let-item>
                                                                    <a href="javascript:void(0)">
                                                                        <div class="d-flex">
                                                                            <span [innerHTML]="item.name"></span>
                                                                        </div>
                                                                    </a>
                                                                </ng-template>
                                                                <div class="search-icon"><img src="assets/images/svg/search_logo.svg"></div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <h4 class="mt-20 fs16 f-700 mb-20">Other Charges</h4>

                                                    <div class="row pbrktscl">
                                                        <div class="col-md-8">
                                                            <div class="floating-label">
                                                                <input class="form-control pr-5" placeholder=""
                                                                    formControlName="logistics_wholesale_standard"
                                                                    disabled>
                                                                <span class="highlight"></span>
                                                                <label>Logistic Charges Multiplier - Wholesale -
                                                                    Standard</label>
                                                                <i placement="top"
                                                                    ngbTooltip="Tooltop content goes here"
                                                                    class="infoinput info-alert"></i>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4 selectactive">
                                                            <ng-selectize [config]="config" [options]="logistics_units"
                                                                formControlName="logistics_wholesale_standard_unit"
                                                                placeholder="Select"> </ng-selectize>
                                                        </div>
                                                    </div>

                                                    <div class="row pbrktscl">
                                                        <div class="col-md-8">
                                                            <div class="floating-label">
                                                                <input class="form-control pr-5" placeholder=" "
                                                                    formControlName="logistics_bulk_standard" disabled>
                                                                <span class="highlight"></span>
                                                                <label>Logistic Charges Multiplier - Bulk -
                                                                    Standard</label>
                                                                <i placement="top"
                                                                    ngbTooltip="Tooltop content goes here"
                                                                    class="infoinput info-alert"></i>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4 selectactive">
                                                            <ng-selectize [config]="config" [options]="logistics_units"
                                                                formControlName="logistics_bulk_standard_unit"
                                                                placeholder="Select"> </ng-selectize>
                                                        </div>
                                                    </div>

                                                    <div class="row pbrktscl" style="display: none;">
                                                        <div class="col-md-8">
                                                            <div class="floating-label">
                                                                <input class="form-control pr-5" placeholder=" "
                                                                    formControlName="logistics_wholesale_express"
                                                                    disabled>
                                                                <span class="highlight"></span>
                                                                <label>Logistic Charges Multiplier - Wholesale -
                                                                    Express</label>
                                                                <i placement="top"
                                                                    ngbTooltip="Tooltop content goes here"
                                                                    class="infoinput info-alert"></i>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4 selectactive">
                                                            <ng-selectize [config]="config" [options]="logistics_units"
                                                                formControlName="logistics_wholesale_express_unit"
                                                                placeholder="Select"> </ng-selectize>
                                                        </div>
                                                    </div>

                                                    <div class="row pbrktscl">
                                                        <div class="col-md-8">
                                                            <div class="floating-label">
                                                                <input class="form-control pr-5" placeholder=" "
                                                                    formControlName="logistics_bulk_express" disabled>
                                                                <span class="highlight"></span>
                                                                <label>Logistic Charges Multiplier - Bulk -
                                                                    Express</label>
                                                                <i placement="top"
                                                                    ngbTooltip="Tooltop content goes here"
                                                                    class="infoinput info-alert"></i>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4 selectactive">
                                                            <ng-selectize [config]="config" [options]="logistics_units"
                                                                formControlName="logistics_bulk_express_unit"
                                                                placeholder="Select"> </ng-selectize>
                                                        </div>
                                                    </div>

                                                    <div class="row pbrktscl">
                                                        <div class="col-md-6">
                                                            <div class="floating-label">
                                                                <input class="form-control pr-5" placeholder=" "
                                                                    formControlName="convenience_wholesale">
                                                                <span class="highlight"></span>
                                                                <label>Convenience Charges - Wholesale</label>
                                                                <i placement="top"
                                                                    ngbTooltip="Tooltop content goes here"
                                                                    class="infoinput info-alert"></i>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-3 selectactive">
                                                            <ng-selectize [config]="config"
                                                                [options]="convenience_units"
                                                                formControlName="convenience_wholesale_from"
                                                                placeholder="Select"> </ng-selectize>
                                                        </div>
                                                        <div class="col-sm-3 selectactive">
                                                            <ng-selectize [config]="config"
                                                                [options]="convenience_charges_units"
                                                                formControlName="convenience_wholesale_type"
                                                                placeholder="Select"> </ng-selectize>
                                                        </div>
                                                    </div>

                                                    <div class="row pbrktscl">
                                                        <div class="col-md-6">
                                                            <div class="floating-label">
                                                                <input class="form-control pr-5" placeholder=" "
                                                                    formControlName="convenience_bulk">
                                                                <span class="highlight"></span>
                                                                <label>Convenience Charges - Bulk</label>
                                                                <i placement="top"
                                                                    ngbTooltip="Tooltop content goes here"
                                                                    class="infoinput info-alert"></i>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-3 selectactive">
                                                            <ng-selectize [config]="config"
                                                                [options]="convenience_units"
                                                                formControlName="convenience_bulk_from"
                                                                placeholder="Select"> </ng-selectize>
                                                        </div>
                                                        <div class="col-sm-3 selectactive">
                                                            <ng-selectize [config]="config"
                                                                [options]="convenience_charges_units"
                                                                formControlName="convenience_bulk_type"
                                                                placeholder="Select"> </ng-selectize>
                                                        </div>
                                                    </div>

                                                    <div class="row pbrktscl">
                                                        <div class="col-md-12">
                                                            <div class="floating-label">
                                                                <input class="form-control pr-5" placeholder=" "
                                                                    formControlName="insurance_charges">
                                                                <span class="highlight"></span>
                                                                <label>Insurance Charges</label>
                                                                <div class="add-info">in Percentage</div>
                                                                <i placement="top"
                                                                    ngbTooltip="Tooltop content goes here"
                                                                    class="infoinput info-alert"></i>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- /FORM BLOCK REPEAT -->
                                                <!-- FORM BLOCK REPEAT -->
                                                <div class="form-container form-ipt-info lstnobd">
                                                    <h4 class="fs14 wt700 mb-4">Marketplace</h4>
                                                    <div class="mb-2">
                                                        <div class="css-check">
                                                            <input name="marketplace_wholesaler" value="yes"
                                                                class="css-checkbx" type="checkbox"
                                                                formControlName="marketplace_wholesaler" />
                                                            <label class="css-label txtdefault wt700">Wholesale
                                                                <p class="wt500">Lorem ipsum dollar sit amet,
                                                                    consuecatur elit, sed do elumsod tempor inodient ut
                                                                </p>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="mb-2">
                                                        <div class="css-check">
                                                            <input name="marketplace_bulk" value="yes"
                                                                class="css-checkbx" type="checkbox"
                                                                formControlName="marketplace_bulk" />
                                                            <label class="css-label txtdefault wt700">Bulk
                                                                <p class="wt500">Lorem ipsum dollar sit amet,
                                                                    consuecatur elit, sed do elumsod tempor inodient ut
                                                                </p>
                                                            </label>
                                                        </div>
                                                        <div class="css-check ml-4" *ngIf="product_template == 'normal'">
                                                            <input name="marketplace_bid" value="yes"
                                                                class="css-checkbx" type="checkbox"
                                                                formControlName="marketplace_bid" />
                                                            <label class="css-label txtdefault wt700">Bid & Offers
                                                                <p class="wt500">Lorem ipsum dollar sit amet,
                                                                    consuecatur elit, sed do elumsod tempor inodient ut
                                                                </p>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- /FORM BLOCK REPEAT -->
                                                <!-- FORM BLOCK REPEAT -->
                                                <div class="form-container form-ipt-info"
                                                    *ngIf="pricingForm.get('marketplace_bid').value">
                                                    <h4 class="fs14 wt700 mb-4">Bids & Offers</h4>
                                                    <div class="row pbrktscl">
                                                        <div class="col-sm-12">
                                                            <div class="floating-label">
                                                                <input class="form-control pr-5" placeholder=" "
                                                                    formControlName="bid_upper_moq">
                                                                <span class="highlight"></span>
                                                                <label>Upper MOQ</label>
                                                                <i placement="top"
                                                                    ngbTooltip="Tooltop content goes here"
                                                                    class="infoinput info-alert"></i>
                                                            </div>
                                                            <span class="help-block"
                                                                *ngIf="prf.bid_upper_moq.invalid && prf.bid_upper_moq.touched">Please
                                                                enter valid upper MOQ</span>
                                                        </div>
                                                    </div>
                                                    <div class="row pbrktscl">
                                                        <div class="col-sm-12">
                                                            <div class="floating-label">
                                                                <input class="form-control pr-5" placeholder=" "
                                                                    formControlName="bid_lower_moq">
                                                                <span class="highlight"></span>
                                                                <label>Lower MOQ</label>
                                                                <i placement="top"
                                                                    ngbTooltip="Tooltop content goes here"
                                                                    class="infoinput info-alert"></i>
                                                            </div>
                                                            <span class="help-block"
                                                                *ngIf="prf.bid_lower_moq.invalid && prf.bid_lower_moq.touched">Please
                                                                enter valid lower MOQ</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- /FORM BLOCK REPEAT -->
                                                <button type="button" class="btn px-4 buybtn fs16 radius10"
                                                    (click)="savepricing()" [disabled]="pricingLoading || pricingForm.invalid">
                                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="pricingLoading"></span>&nbsp;Save and Preview
                                                </button>
                                                
                                                    
                                            </form>
                                        </ng-template>
                                    </li>
                                </ul>

                                <div [ngbNavOutlet]="prdctinfonav" class="mt-2"></div>
                            </div>
                        </div>
                    </div>
                    <!-- /RIGHT COL -->
                </div>
            </div>

        </div>
    </div>
    <!-- VIEW IMAGE GUIDELINES MODAL -->
    <ng-template #content let-modal>
        <div class="modal-header nobd">
            <h4 class="modal-title fs22 mt-2 wt700 txtdefault text-center mx-auto">Image Guidelines</h4>
            <button type="button" class="btn-close close nobd bg-transparent " aria-label="Close"
                (click)="modal.dismiss('Cross click')">&times;</button>
        </div>
        <div class="modal-body img-g-list">
            <div class="mb-3 pb-3 th-cmn-bd">
                <h4 class="fs16 wt700 txtdefault  mb-2">File Format </h4>
                <p class="txtseconday mb-0">1. PNG, JPEG, JPG files are supported only. </p>
            </div>
            <div class="mb-3 pb-3 th-cmn-bd">
                <h4 class="fs16 wt700 txtdefault  mb-2">Following Images won't work </h4>
                <ol class="mb-0">
                    <li>
                        <p class="txtseconday mb-0">Graphic/ Inverted/ Pixelated image won't work. </p>
                    </li>
                    <li>
                        <p class="txtseconday mb-0">Images with text/Watermark won't work in primary images. </p>
                    </li>
                    <li>
                        <p class="txtseconday mb-0">Blur images and clutter images won't work.</p>
                    </li>
                    <li>
                        <p class="txtseconday mb-0">Images should not contain price/brand logo for the product. </p>
                    </li>
                    <li>
                        <p class="txtseconday mb-0">Product images must not be shrunk, elongated or stretched. </p>
                    </li>
                    <li>
                        <p class="txtseconday mb-0">Partial product image won't work. </p>
                    </li>
                    <li>
                        <p class="txtseconday mb-0">Offensive/Objectionable images/products won't work. </p>
                    </li>
                </ol>
            </div>
            <div class="mb-3 pb-3 th-cmn-bd nobd">
                <h4 class="fs16 wt700 txtdefault  mb-2">Image standards </h4>
                <ol class="mb-0">
                    <li>
                        <p class="txtseconday mb-0"> All images must be at least less than 2MB or larger and set at
                            highest quality. </p>
                    </li>
                    <li>
                        <p class="txtseconday mb-0"> Recommended image resolution should be 450px x 450px </p>
                    </li>
                    <li>
                        <p class="txtseconday mb-0">Product image should not have any text.</p>
                    </li>
                </ol>
            </div>
        </div>
    </ng-template>
    <!-- /VIEW IMAGE GUIDELINES MODAL -->
    <!-- ADD PRODUCT VARIENT MODAL -->
    <ng-template #mcontent let-modal>
        <div class="modal-body add-variant-list pb-4">
            <h4 class="modal-title fs22 mt-2 wt700 txtdefault text-center mx-auto mb-20">Add Product Variant</h4>
            <button type="button" class="btn-close close nobd bg-transparent " aria-label="Close"
                (click)="modal.dismiss('Cross click')">&times;</button>
            <div class="mb-3 pb-2 th-cmn-bd">
                <h4 class="fs14 wt700 txtdefault  mb-3">Choose Variation Type</h4>
                <ul *ngIf="variant_types.length > 0">
                    <li class="mb-3 align-middle" *ngFor="let item of variant_types; let i = index;">
                        <div class="css-check">
                            <input name="saletype" class="css-checkbx" [checked]="item.selected" type="checkbox"
                                (change)="updatevarianttype($event, item)" />
                            <label class="css-label txtdefault wt700">{{ item.label_name }}
                                <i class="info-alert"></i>
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- VARIANT-->
            <div class="" *ngFor="let variant of variants; let i = index;">
                <h4 class="fs14 wt700 txtdefault  mb-3 variant-action">Variant {{i+1}}
                    <a class="align-middle ml-auto" href="javascript:void(0);" (click)="deletevariant(variant, i)"><img
                            width="12" src="assets/images/icons/delete-icon.svg"> </a>
                </h4>
                <div class="form-container mb-10">
                    <div class="row">
                        <div class="col-md-6 pr-sm-0">
                            <div class="floating-label">
                                <input class="form-control pr-5" value="" name="variant_name_{{i}}"
                                    [(ngModel)]="variant.name" placeholder="">
                                <span class="highlight"></span>
                                <label>Variant Name</label>
                                <i placement="top" ngbTooltip="Tooltop content goes here"
                                    class="infoinput info-alert"></i>
                            </div>
                        </div>

                        <div class="col-md-6" *ngFor="let type of variant.types; let k = index;">
                            <div *ngIf="type.selected && type.type_info == 'text_field'">
                                <div class="floating-label">
                                    <input class="form-control pr-5" placeholder=" " name="variant_value_{{i}}_{{k}}"
                                        [(ngModel)]="type.variant_value">
                                    <span class="highlight"></span>
                                    <label>{{ type.label_name }}</label>
                                    <i placement="top" ngbTooltip="Tooltop content goes here"
                                        class="infoinput info-alert"></i>
                                </div>
                            </div>

                            <div *ngIf="type.selected && type.type_info == 'numeric_field'">
                                <div class="row">
                                    <div class="col-md-7 pr-0">
                                        <div class="floating-label">
                                            <input class="form-control pr-5" placeholder=" "
                                                [(ngModel)]="type.variant_value">
                                            <span class="highlight"></span>
                                            <label>{{ type.label_name }}</label>
                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                class="infoinput info-alert"></i>
                                        </div>
                                    </div>
                                    <div class="col-md-5 selectactive">
                                        <ng-selectize [config]="config" [(ngModel)]="type.variant_value_addition"
                                            [options]="type.variant_meta" placeholder="Select"> </ng-selectize>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /VARIANT-->
            <div class="mb-3">
                <a (click)="addvariant()" href="javascript:void(0);" class="txtactive">
                    <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                        viewBox="0 0 14.681 14.681">
                        <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1 1)">
                            <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181" transform="translate(-11.659 -7.5)"
                                fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="2" />
                            <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181" transform="translate(-7.5 -11.659)"
                                fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="2" />
                        </g>
                    </svg>
                    Add More Variant
                </a>
            </div>
            <button type="button" class="btn px-5 buybtn fs16 radius10"
                (click)="savevariant()" [disabled]="variantLoading">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="variantLoading"></span>&nbsp;Save
            </button>
        </div>
    </ng-template>
    <!-- /ADD PRODUCT VARIENT -->

    <!-- EDIT PROFILE PHOTO -->
    <ng-template #addattribute let-modal>
        <div class="modal-body add-variant-list pb-4">
            <h4 class="modal-title fs22 mt-2 wt700 txtdefault text-center mx-auto mb-20">Select type of field/attribute
            </h4>
            <button type="button" class="btn-close close nobd bg-transparent " aria-label="Close"
                (click)="modal.dismiss('Cross click')">&times;</button>
            <div class="form-container selectiveForm mb-10" *ngFor="let att of newAttributes; let i = index;"
                style="border-bottom: 1px solid #ddd">
                <div class="floating-label mb-20">
                    <select class="form-control" [(ngModel)]="att.type_info">
                        <option selected="selected" value="">Select Type of field</option>
                        <option value="text">Text Field</option>
                        <option value="numeric">Numeric Field</option>
                        <option value="date-time">Date Field</option>
                        <option value="link">Link</option>
                        <option value="yes-or-no">Yes-No Option</option>
                    </select>
                    <div class="selectDiv"></div>
                    <span class="highlight"></span>
                    <label>Type of field/attribute</label>
                </div>

                <div class="floating-label mt-20 mb-20">
                    <input type="text" class="form-control" placeholder=" " value="" [(ngModel)]="att.label_name" />
                    <span class="highlight"></span>
                    <label>Information Title</label>
                </div>

                <div class="row" *ngIf="att.type_info == 'text' || att.type_info == 'link'">
                    <div class="col-md-12">
                        <div class="floating-label mb-20">
                            <input type="text" class="form-control" placeholder=" " value=""
                                [(ngModel)]="att.attribute_value" />
                            <span class="highlight"></span>
                            <label>Value</label>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="att.type_info == 'date-time'">
                    <div class="col-md-12">
                        <div class="floating-label mb-20">
                            <input type="text" class="form-control" placeholder=" " ngbDatepicker #d="ngbDatepicker"
                                (click)="d.toggle()" [(ngModel)]="att.attribute_value" />
                            <span class="highlight"></span>
                            <label>Value</label>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="att.type_info == 'numeric'">
                    <div class="col-md-6">
                        <div class="floating-label mb-20">
                            <input type="text" class="form-control" placeholder=" " value=""
                                [(ngModel)]="att.attribute_value" />
                            <span class="highlight"></span>
                            <label>Value</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="floating-label mb-20">
                            <select class="form-control" [(ngModel)]="att.attribute_value_addition">
                                <option value="" selected>Select</option>
                                <option *ngFor="let nu of numericFieldArray;" value="{{ nu.value }}">{{ nu.label }}
                                </option>
                            </select>
                            <div class="selectDiv"></div>
                            <span class="highlight"></span>
                            <label>Unit</label>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="att.type_info == 'yes-or-no'">
                    <div class="col-md-12">
                        <div class="floating-label mb-20">
                            <select class="form-control" [(ngModel)]="att.attribute_value">
                                <option value="" selected>Select</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                            <div class="selectDiv"></div>
                            <span class="highlight"></span>
                            <label>Value</label>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="hideTechnicalFilter">
                    <div class="col-md-12">
                        <div class="floating-label mb-20">
                            <select class="form-control" [(ngModel)]="att.filter">
                                <option value="yes">Yes</option>
                                <option value="no" selected>No</option>
                            </select>
                            <div class="selectDiv"></div>
                            <span class="highlight"></span>
                            <label>Show in Filter</label>
                        </div>
                    </div>
                </div>




            </div>

            <div class="text-left">
                <a href="javascript:;" class="link-alt fs14" (click)="attributeadd()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="11.691" height="11.691" viewBox="0 0 11.691 11.691">
                        <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1 1)">
                            <path id="Path_8" data-name="Path 8" d="M18,7.5v9.691" transform="translate(-13.154 -7.5)"
                                fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="2" />
                            <path id="Path_9" data-name="Path 9" d="M7.5,18h9.691" transform="translate(-7.5 -13.154)"
                                fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="2" />
                        </g>
                    </svg> Add Fields</a>
            </div>

            <div class="text-center pt-30 pb-0">
                <button type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"
                    class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4">
                    Cancel
                </button>
                <button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4"
                    (click)="saveattributes()" [disabled]="technicalLoading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="technicalLoading"></span>&nbsp;Save and Next
                </button>
            </div>

        </div>
    </ng-template>
    <!-- EDIT PROFILE PHOTO -->

     <!-- EDIT PROFILE PHOTO -->
     <ng-template #addparentchild let-modal>
        <div class="modal-body add-variant-list pb-4">
            <h4 class="modal-title fs22 mt-2 wt700 txtdefault text-center mx-auto mb-20">Add Child
            </h4>
            <button type="button" class="btn-close close nobd bg-transparent " aria-label="Close"
                (click)="modal.dismiss('Cross click')">&times;</button>
            <div class="form-container selectiveForm mb-10" style="border-bottom: 1px solid #ddd">

                <div class="floating-label mt-20 mb-20">
                    <input type="text" class="form-control" placeholder=" " value="" [(ngModel)]="child.name" />
                    <span class="highlight"></span>
                    <label>Name</label>
                </div>

                <div class="floating-label mb-20">
                    <input type="file" class="form-control" placeholder=" " value="" (change)="uploadchildimage($event)" />
                    <span class="highlight"></span>
                    <label>Image</label>
                </div>
                <div class="floating-label mb-20">
                    <select name="status" id="status" class="form-control" [(ngModel)]="child.status">
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                    </select>
                    <span class="highlight"></span>
                    <label>Status</label>
                </div>
            </div>

            <div class="text-center pt-30 pb-0">
                <button type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"
                    class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4">
                    Cancel
                </button>
                <button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4"
                    (click)="savechild()">
                    Save
                </button>
            </div>

        </div>
    </ng-template>
    <!-- EDIT PROFILE PHOTO -->

     <!-- EDIT PROFILE PHOTO -->
     <ng-template #editparentchild let-modal>
        <div class="modal-body add-variant-list pb-4">
            <h4 class="modal-title fs22 mt-2 wt700 txtdefault text-center mx-auto mb-20">Update Child
            </h4>
            <button type="button" class="btn-close close nobd bg-transparent " aria-label="Close"
                (click)="modal.dismiss('Cross click')">&times;</button>
            <div class="form-container selectiveForm mb-10" style="border-bottom: 1px solid #ddd">

                <div class="floating-label mt-20 mb-20">
                    <input type="text" class="form-control" placeholder=" " value="" [(ngModel)]="selected_child.name" />
                    <span class="highlight"></span>
                    <label>Name</label>
                </div>

                <div class="floating-label mb-20">
                    <input type="file" class="form-control" placeholder=" " value="" (change)="updatechildimage($event)" />
                    <span class="highlight"></span>
                    <label>Image</label>
                </div>

                <div class="floating-label mb-20">
                    <img src="{{ selected_child.image }}" alt="{{ selected_child.name }}" width="150" height="150">
                    <label>Uploaded Image</label>
                </div>

                <div class="floating-label mb-20">
                    <select name="status" id="status" class="form-control" [(ngModel)]="selected_child.status">
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                    </select>
                    <span class="highlight"></span>
                    <label>Status</label>
                </div>
            </div>

            <div class="text-center pt-30 pb-0">
                <button type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"
                    class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4">
                    Cancel
                </button>
                <button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4"
                    (click)="updatechild()">
                    Save
                </button>
            </div>

        </div>
    </ng-template>
    <!-- EDIT PROFILE PHOTO -->


    <!-- Remove Product -->
    <ng-template #removeProduct let-modal>
        <div class="modal-body mdlcmnui pt-4 mt-3">
            <h5 class="modal-title text-center mb-20">Remove Product Template</h5>

            <div class="bid-order-info">
                <div class="order-img">
                    <img src="assets/images/icons/info.png" />
                </div>
                <div class="pd-seller-cntnt">
                    <div class="fs12">Removing the product will lead to removing this product from Equibiz. The buyer
                        and seller won’t be able to see this product. No more orders can be placed.</div>
                </div>
            </div>

            <div class="modal-subtitle text-center pt-30 pb-30">Are you sure you want to remove the product?</div>

            <div class="bid-order-info">
                <div class="order-img">
                    <img [src]="slides[0].img" />
                </div>
                <div class="pd-seller-cntnt">
                    <h5 class="mb-10 fs16">{{ common_info.brand_name }} {{ common_info.product_name }} | {{
                        common_info.model_number }}</h5>
                </div>
            </div>

            <div class="text-center pt-30 pb-20">
                <button type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"
                    class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4">
                    Cancel
                </button>
                <button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4"
                    (click)="changestatus('remove')">
                    Remove
                </button>
            </div>
        </div>
    </ng-template>

    <!-- Edit Category -->
    <ng-template #editcategory let-modal>
        <div class="modal-body mdlcmnui pt-4 mt-3">
            <h5 class="modal-title text-center mb-20">Edit Category</h5>

            <div class="bid-order-info">
                <div class="order-img" *ngIf="slides.length > 0">
                    <img [src]="slides[0].img" />
                </div>
                <div class="pd-seller-cntnt">
                    <h5 class="mt-10 fs16">{{ common_info.brand_name }} {{ common_info.product_name }} | {{
                        common_info.model_number }}</h5>
                </div>
            </div>

            <div class="text-right mt-20 mb-10">
                <a href="javascript:;" [routerLink]="['/admin/manage-product-categories']"
                    class="link-alt color-orange">Manage Product Categories</a>
            </div>
            <div class="row mt-10">
                <div class="col-md-12">
                    <div class="form-container mdl-frm-lbl mb-4 mt-0">
                        <div class="floating-label mb-0">
                            <div class="ng-autocomplete">
                                <ng-autocomplete [data]="result" [searchKeyword]="keyword"
                                    (selected)='selectEvent($event)' (inputChanged)='onChangeSearch($event)'
                                    (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
                                    [notFoundTemplate]="notFoundTemplate" placeholder="Search by Category Name">
                                </ng-autocomplete>
                                <ng-template #itemTemplate let-item>
                                    <a href="javascript:void(0)">
                                        <div class="d-flex">
                                            <span [innerHTML]="item.name"></span>
                                            <!-- <span [innerHTML]="item.current" class="current"></span> -->
                                        </div>
                                    </a>
                                </ng-template>

                                <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                </ng-template>
                                <div class="search-icon"><img src="assets/images/svg/search_logo.svg"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <app-preview #preview></app-preview>