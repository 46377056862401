import { Component, OnInit } from '@angular/core';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { WalletTransactionHistoryService } from './wallet-transaction-history.service';

@Component({
  selector: 'app-wallet-transaction-history',
  templateUrl: './wallet-transaction-history.component.html',
  styleUrls: ['./wallet-transaction-history.component.scss']
})
export class WalletTransactionHistoryComponent implements OnInit {

    public export_path:string = '';
    public export_loading:boolean = false;
    public download:string = '';

    public search_term:string = '';
    public from_date:any = '';
    public to_date:any = '';
    public payment_type:string = 'all';

    public transaction_records:any = [];
    public current_page: number = 1;
    public offset: number = 0;
    public total_records:number = 0;
    public page_per_view: number = 20;
    public transaction_pagination: any = {};
    public page_per_view_options = [
        {
            value: 20,
            name: 20
        },
        {
            value: 40,
            name: 40
        }
    ];
    constructor(
        private _transactionservice: WalletTransactionHistoryService,
        private _toastr: ToasterService,
        private _pagination: PaginationService
    ) { }

    ngOnInit(): void {
        this.gettransaction();
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.getdetails();
    }

    setpageperview() {
        this.current_page = 1;
        this.getdetails();
    }

    gettransaction(download='') {
        this.page_per_view = 10;
        this.offset = 0;
        this.current_page = 1;
        this.download = download;
        this.getdetails();
    }

    getdetails() {
        let data = { 
            limit: this.page_per_view,
            offset: this.offset,
            current_page: this.current_page,
            from_date: this.from_date,
            to_date: this.to_date,
            search: this.search_term,
            payment_type: this.payment_type,
            download: this.download
        };
        this._transactionservice.gettransactionhistory(data).subscribe((data) => {
            if (data['status'] == "success") {
                if(data['download']){
                    this.export_path = data['path'];
                    this.export_loading = false;
                }else{
                    this.transaction_records = data['records'];
                    let total_records = data['total_records'];
                    this.total_records = data['total_records'];
                    this.transaction_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
                }
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }


    public val = null;
	config = {
        labelField: 'label',
        valueField: 'value',
    };
    data = [
        {
            label: 'All',
            value: 'all'
        },
        {
            label: 'In',
            value: 'in'
        },
        {
            label: 'Out',
            value: 'out'
        }
    ]
}
