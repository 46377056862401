<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="">
    <div class="equibiz-header">
      <div class="logo-bar">
        <div class="container">
          <div class="row d-flex justify-content-between">
             <div class="col-md-3 d-flex align-items-center">
                <a [routerLink]="['/home']"><img src="assets/images/svg/eq_logo.svg"></a>
                <!-- <span class="separator"></span>
                <ul class="header-dropdown">
				  <li class="onhover-dropdown mobile-account menu-item topbar-menu">
					Categories
					<ul class="onhover-show-div w-4">
						<li><div class="header-title">Categories</div></li>
						<li><a herf="javascript:;">Consumer Electronics</a></li>
						<li><a herf="javascript:;">Home Appliances</a></li>
						<li><a herf="javascript:;">Office Furniture</a></li>
					</ul>
				  </li>
				</ul> -->
             </div>
             <div class="col-md-6 d-none">
                <div class="input-group-custom">
                   <input class="form-control py-2 border-right-0 border" type="text" placeholder="Search Products, Brands and Categories" id="example-search-input">
                   <div class="input-group-append-custom">
                      <img src="assets/images/svg/search_logo.svg">
                   </div>
                </div>
             </div>
             <div class="col-md-3">
             	<div class="text-right right-header">
             		<ul class="header-dropdown">
					  <li class="menu-item"><a href="javascript:;" (click)="SignIn.openSigninModal('home')">Login</a></li>
					  <li class="menu-item"><a href="javascript:;" class="btn btn-mod btn-color btn-medium btn-round ripple" (click)="SignIn.openSigninModal('home')">Start Selling</a></li>
					</ul>
             	</div>
             </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<section class="small-section bg-white">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="seller-banner-container" style="background-image: url('assets/images/seller-bg.jpg');">
					<div class="row">
						<div class="col-md-5 text-left">
							<h3 class="hs-line-6 font-alt mb-20">
								Grow your business faster by targeting your ideal buyers
							</h3>
							<div class="section-text-xs text-left mb-30">
								Sell the variety of products on Equibiz with the easiest way to start and grow your online business.
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-7 text-left">
							<div class="seller-info section-text-xs">
								<div class="info-1"><img src="assets/images/icons/quick.svg"> Quick Start</div>
								<div class="info-1"><img src="assets/images/icons/growth.svg"> Optimized Growth</div>
								<div class="info-1"><img src="assets/images/icons/rupee.svg"> Make Money</div>
							</div>
						</div>
					</div>

					<div class="seller-info one">
						<div><img src="assets/images/icons/seller-01.svg"></div>
						<div class="title">Order #12454 Delivered</div>
					</div>

					<div class="seller-info two">
						<div><img src="assets/images/icons/seller-02.svg"></div>
						<div class="title">Accept New Orders</div>
					</div>

					<div class="seller-info three">
						<div><img src="assets/images/icons/seller-03.svg"></div>
						<div class="title">Total Sales Today <i class="fa fa-inr"></i> 34678</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="small-section bg-white">
	<div class="container">
		<div class="row d-flex justify-content-center">
			<div class="col-md-12">
				<h2 class="hs-line-7 text-center font-alt mb-30">Sell products in just 3 steps</h2>
			</div>
		</div>

		<div class="sell-product">
			<div class="line"></div>
		</div>
		<div class="row">
			<div class="col-md-4">
				<div class="alt-features-item text-center">
                    <div class="alt-features-icon">
                        <img src="assets/images/icons/register-yourself.svg">
                    </div>
                    <h3 class="alt-features-title font-alt">Register Yourself</h3>
                    <div class="alt-features-descr">
                        Register yourself with the GSTIN details to sell your products online
                    </div>
                    <div><a href="javascript:;" class="link">Watch Video ></a></div>
                </div>
			</div>

			<div class="col-md-4">
				<div class="alt-features-item text-center">
                    <div class="alt-features-icon">
                        <img src="assets/images/icons/add-product.svg">
                    </div>
                    <h3 class="alt-features-title font-alt">Add your product</h3>
                    <div class="alt-features-descr">
                        Upload your product listing you want to sell on Equibiz
                    </div>
                    <div><a href="javascript:;" class="link">Watch Video ></a></div>
                </div>
			</div>

			<div class="col-md-4">
				<div class="alt-features-item text-center">
                    <div class="alt-features-icon">
                        <img src="assets/images/icons/grow-business.svg">
                    </div>
                    <h3 class="alt-features-title font-alt">Grow your business</h3>
                    <div class="alt-features-descr">
                        It’s time to grow your business with a comprehensive dashboard
                    </div>
                    <div><a href="javascript:;" class="link">Watch Video ></a></div>
                </div>
			</div>
		</div>

		<div class="row d-flex justify-content-center mt-30">
			<div class="col-md-12">
				<div class="text-center"><a href="javascript:;" class="btn btn-mod btn-color btn-medium btn-round ripple px-4"  (click)="SignIn.openSigninModal('home')">Start Selling</a></div>
			</div>
		</div>
	</div>
</section>

<section class="page-section bg-white">
	<div class="container">
		<div class="row d-flex justify-content-center">
			<div class="col-md-12">
				<h2 class="hs-line-7 text-center font-alt mb-70">Reasons you should sell on Equibiz</h2>
			</div>
		</div>

		<div class="row d-flex justify-content-between align-items-center mb-40">
			<div class="col-md-6">
				<img src="assets/images/reason-01.jpg" class="img-fluid">
			</div>
			<div class="col-md-5">
				<div class="alt-features-subtitle font-alt">Reason 1</div>
				<div class="alt-features-title font-alt">A dashboard to track everything</div>
				<div class="alt-features-descr">Selling online might hard to handle. With the help of a Dashboard, a seller can track the sales, orders, product lisitngs, etc and optimized for a growth.</div>
			</div>
		</div>

		<div class="row d-flex justify-content-between align-items-center mb-40">
			<div class="col-md-5">
				<div class="alt-features-subtitle font-alt">Reason 2</div>
				<div class="alt-features-title font-alt">Bids & Offers</div>
				<div class="alt-features-descr">This will help both Buyers and Seller to discover the price of a listed product under bulk segment by placing their bids and counter offers. This empowers both buyers and sellers to deal at their best price.</div>
			</div>

			<div class="col-md-6">
				<img src="assets/images/reason-02.jpg" class="img-fluid">
			</div>
		</div>

		<div class="row d-flex justify-content-between align-items-center mb-40">
			<div class="col-md-6">
				<img src="assets/images/reason-03.jpg" class="img-fluid">
			</div>
			<div class="col-md-5">
				<div class="alt-features-subtitle font-alt">Reason 3</div>
				<div class="alt-features-title font-alt">Insights from the market</div>
				<div class="alt-features-descr">The Insight Report will help sellers to get the feedback about transactions over the platform, Segment wise Sales trends ,  and help them to find tuned sales and marketing strategies.</div>
			</div>
		</div>
	</div>
</section>

<!-- <section class="small-section bg-white pt-0 pb-0">
	<div class="container">
		<div class="row d-flex justify-content-center">
			<div class="col-md-12 text-center">
				<h2 class="hs-line-7 font-alt mb-10">Registered brands selling on Equibiz</h2>
				<div class="alt-features-descr">Contrary to popular belief, Lorem Ipsum is not simply random text.</div>

				<div class="row d-flex justify-content-center mt-30">
					<div class="col-md-10">
						<div class="row">
							<div class="col-md-3">
								<img src="assets/images/brand-01.jpg">
							</div>
							<div class="col-md-3">
								<img src="assets/images/brand-02.jpg">
							</div>
							<div class="col-md-3">
								<img src="assets/images/brand-03.jpg">
							</div>
							<div class="col-md-3">
								<img src="assets/images/brand-04.jpg">
							</div>
						</div>
					</div>
				</div>
				<div class="mt-40 text-center">
					<a href="javascript:;" class="btn-d-outline d-inline-block">Partner with us</a>
				</div>
			</div>
		</div>
	</div>
</section> -->

<section class="page-section bg-white">
	<div class="container">
		<div class="row d-flex justify-content-center">
			<div class="col-md-12">
				<h2 class="hs-line-7 text-center font-alt mb-40">What sellers say about us?</h2>
			</div>
		</div>
		<div class="row seller-testi-carousel">
	      	<div class="col-md-12">
	      		<owl-carousel-o class="product-m" [options]="ProductSliderConfig">
	      			<ng-template carouselSlide>
						<div class="seller-testimonial">
							<div class="open-quote"><img src="assets/images/icons/open-quote.png"></div>
							<!-- <div class="user-image"><img src="assets/images/testimonial-01.png"></div> -->
							<div class="description mb-30">
								I am glad I found Equibiz. It has given me access to a huge number of verified buyers. I am extremely happy. All the very best to Equibiz. 
							</div>
							<div class="name font-alt mb-10">Mr. Ritesh Kumar</div>
							<div class="designation">R K Agency, Danapur , Patna</div>
						</div>
					</ng-template> 
					<ng-template carouselSlide>
						<div class="seller-testimonial">
							<div class="open-quote"><img src="assets/images/icons/open-quote.png"></div>
							<!-- <div class="user-image"><img src="assets/images/testimonial-02.png"></div> -->
							<div class="description mb-30">
								Equibiz experience is phenomenal. Their payment system is quick and easy. I am happy to have found an online B2B trading platform that offers such transparency and on time payment realisations. Thank you for a great platform.
							</div>
							<div class="name font-alt mb-10">Mr. Abhay Nagpure </div>
							<div class="designation">Infinity Communication, Nagpur</div>
						</div>
					</ng-template> 	
					<ng-template carouselSlide>
						<div class="seller-testimonial">
							<div class="open-quote"><img src="assets/images/icons/open-quote.png"></div>
							<!-- <div class="user-image"><img src="assets/images/testimonial-01.png"></div> -->
							<div class="description mb-30">
								I was surprised to find the navigation and browsing to be such a breeze. Access to wholesale as well as Bulk buyer segment in a single listing saves me so much time. Equibiz is really changing the way online B2B trading works.
							</div>
							<div class="name font-alt mb-10">Mr. Om Prakash Devnani </div>
							<div class="designation">My Choice Collection, Bhopal</div>
						</div>
					</ng-template> 	
					<ng-template carouselSlide>
						<div class="seller-testimonial">
							<div class="open-quote"><img src="assets/images/icons/open-quote.png"></div>
							<!-- <div class="user-image"><img src="assets/images/testimonial-02.png"></div> -->
							<div class="description mb-30">
								From easy Invoicing, E-way bill, listing of products to getting the payment without any headache. Equibiz is truly an amazing business partner in the online B2b trading world.
							</div>
							<div class="name font-alt mb-10">Mr. S. Arun Kumar </div>
							<div class="designation">S and G Ventures, Chennai</div>
						</div>
					</ng-template> 
        		</owl-carousel-o>
	      	</div>
	    </div>
	</div>
</section>


<section class="small-section bg-light-blue">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h2 class="hs-line-7 text-left font-alt mb-30">Learn more about selling online</h2>
			</div>
		</div>

		<div class="row faqpanel accordionarrow">
			<div class="col-md-12">
				<ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
				 	<ngb-panel title="How can you start transacting over Equibiz?">
						<ng-template ngbPanelContent>
						  <p>To start transacting over Equibiz and reap its benefits, sign up using your mobile number, email ID, or via social signup options. Login to your account and complete the business registration process by submitting business details and documents. Once the registration request is approved, you are good to start trading.</p>
						  <ul>
							<li>Step 1: Log in to the portal www.equibiz.in</li>
							<li>Step 2: Click on Sign Up and input mobile number and password</li>
							<li>Step 3: Log in with your provided credentials</li>
							<li>Step 4: Input business details and documents</li>
							<li>Step 5: Start Trading</li>
						  </ul>
						</ng-template>
				  	</ngb-panel>
				  	<ngb-panel title="What documents are required to register on Equibiz, and are there any registration charges?">
						<ng-template ngbPanelContent>
						 <p>A valid GST certificate (regular, not composite), business bank account, and PAN number are required to register on Equibiz. Registration over the Equibiz platform is free. However, transaction-related charges may apply to both buyers and sellers, which are displayed at the time of the transaction.</p>
						</ng-template>
				  	</ngb-panel>
				  	<ngb-panel title="I am unable to book or list the deal?">
						<ng-template ngbPanelContent>
						  <p>Seller must verify the email sent on their registered email id before starting transacting over Equibiz.</p>
						</ng-template>
				  	</ngb-panel>
				  	<ngb-panel title="I am unable to upload documents?">
						<ng-template ngbPanelContent>
						  <p>If you are unable to upload documents, this may be due to any of the following reasons:<br>The Image should be greater than 300KB or lesser than 20MB. The correct image format is JPEG, PNG & PDF. </p>
						</ng-template>
				  	</ngb-panel>
				  	<ngb-panel title="Can I register my business as a seller, if I don’t have GST number?">
						<ng-template ngbPanelContent>
						  <p>No, if you want to register as a seller, GST number is a must.</p>
						</ng-template>
				  	</ngb-panel>
				  	<ngb-panel title="I am Seller , What is Bulk and Wholesale Market Segment ?">
						<ng-template ngbPanelContent>
						  <p>Equibiz facilitates both large and small value transactions to meet the requirements of different stakeholders such as brand, wholesaler, distributor, retailer, corporate, exporter, importer, etc. Under the bulk segment, the suppliers have the freedom to select deal terms such as minimum order quantity, minimum bid price, supply location, payment option, etc. Whereas under the wholesale segment, the minimum transaction value must be INR 3,000/- or above and the trade terms are set to facilitate the retailer’s or small value buyer’s requirement.</p>
						</ng-template>
				  	</ngb-panel>
				  	<ngb-panel title="I am a seller, what is the bulk and wholesale market segment?">
						<ng-template ngbPanelContent>
						  	<p>The seller may choose to list the product under bulk or wholesale segment depending upon the business requirement and preferred target segment. It is suggested that listing deals in the wholesale segment must be targeted at retailers, dealers, small traders, etc. as the minimum cart value is kept at INR 3,000/ for buyers. Whereas, listing in the bulk segment/bid & offer must be targeted at the wholesaler, stockiest, distributors, retail chain stores, corporates, etc. as the minimum deal value is kept at INR 1,00,000/ for buyers.</p>
						</ng-template>
				  	</ngb-panel>
				  	<ngb-panel title="What is bid and offer?">
						<ng-template ngbPanelContent>
						  <p>A bid is a counter price of any product set by the buyer against the listed price of the seller under the bid & offer segment. This proprietary feature of our e-commerce platform Equibiz is to facilitates the trading of products under the bulk listing. If the seller agrees with the bid set by the buyer, the seller can close the deal at the price set by the buyer. Apart from this, the rest of the other deal conditions will remain the same as set by the seller at the time of product listing.</p>
						</ng-template>
				  	</ngb-panel>
				  	<ngb-panel title="I want to Delete/Hold my listings?">
						<ng-template ngbPanelContent>
						  <p>The quickest and easiest way to delete/hold your listing is by using your seller account.</p>
						  <ul>
							<li>Step 1: Log in to your seller account.</li>
							<li>Step 2: Click on “My Products”</li>
							<li>Step 3: Click on the toggle to make the product inactive.</li>
						  </ul>
						</ng-template>
				  	</ngb-panel>
				  	<ngb-panel title="I want to Un-Hold my listings.">
						<ng-template ngbPanelContent>
						  <p>The quickest and easiest way to Un Hold your listing is by using your seller account.</p>
						  <ul>
							<li>Step 1: Log in to your seller account.</li>
							<li>Step 2: Click on “My Products”</li>
							<li>Step 3: Click on the toggle to make the product active.</li>
						  </ul>
						</ng-template>
				  	</ngb-panel>
				  	<ngb-panel title="I want to change MRP/Selling Price/Dispatch Date/Color/MOQ/Total Quantity /Other details of my listing.">
						<ng-template ngbPanelContent>
						  <p>The quickest and easiest way to change the details of your listing is by using your seller account.</p>
						  <ul>
							<li>Step 1: Log in to your seller account.</li>
							<li>Step 2: Click on “My Products”</li>
							<li>Step 3 : Click on “View Listing”</li>
							<li>Step 3: Click on the Edit Listing</li>
							<li>Step 4: Change the deal details</li>
							<li>Step 5: Save the changes</li>
						  </ul>
						</ng-template>
				  	</ngb-panel>
				  	<ngb-panel title="I want to add Key Features/Product specifications.">
						<ng-template ngbPanelContent>
						  <p>Currently, only Equibiz can add/change the Key Features and Product Specifications. Sellers can request for a change using the feedback form on the Contact Us page.<br>Equibiz will be bringing the options to add product details for sellers soon.</p>
						</ng-template>
				  	</ngb-panel>
				  	<ngb-panel title="What are the image guidelines?">
						<ng-template ngbPanelContent>
						  <p>Image guidelines are the basic standards that we encourage you to follow while listing your products to ensure your product is visible and all the angles of your product are highlighted to help the buyer make an informed decision, and in turn, minimize your customer returns.</p>
						</ng-template>
				  	</ngb-panel>
				  	<ngb-panel title="I want to update the inventory of my catalogs">
						<ng-template ngbPanelContent>
						  <ul>
							<li>Step 1: Log in to your seller account.</li>
							<li>Step 2: Click on “My Products”</li>
							<li>Step 3 : Click on “View Listing”</li>
							<li>Step 3: Click on the Edit Listing</li>
							<li>Step 4: Change the ‘Total Stock Quantity’ under ‘Stock & Pricing’ tab.</li>
							<li>Step 5: Save the changes</li>
						  </ul>
						</ng-template>
				  	</ngb-panel>
				  	<ngb-panel title="What is the HSN code I have to enter? ">
						<ng-template ngbPanelContent>
						  <p>Six-digit HSN Code prescribed by CBIC must be entered. To find the HSN code, steps to follow,</p>
						  <ul>
							<li>Step1: Click on the ‘Find relevant HSN code’ under Unique ID tab in ‘Add Listing’ page </li>
							<li>Step 2: Select the code from the drop-down or manually input the HSN code.</li>
						  </ul>
						</ng-template>
				  	</ngb-panel>
				  	<ngb-panel title="I am not able to find the product I want to list?">
						<ng-template ngbPanelContent>
						  <p>Please connect with Equibiz support at support@equibiz.in or call on 18002102194</p>
						</ng-template>
				  	</ngb-panel>
				  	<ngb-panel title="My listing is not live?">
						<ng-template ngbPanelContent>
						  <p>Please reach out to Equibiz support at support@equibiz.in or call on 18002102194 in case you cannot find the listed product under My Products. If you are a sub-seller/supplier, your product listing will go live once approved by the Super-seller.</p>
						</ng-template>
				  	</ngb-panel>
				  	<ngb-panel title="When will sellers receive their payment, and how much TDS and TCS are applicable?">
						<ng-template ngbPanelContent>
						  <p>For the bulk segment transaction (in case of the open box pick up), 90 % of the deal amount will be credited to the bank account on the date of dispatch, and the remaining 10% after delivery of the consignment to the buyer. For the remaining types of transactions, payment to the supplier will be made after three working days once the return period is over.<br>Presently TDS rate is 1% on the post GST amount and TCS rate is 1 % on the pre-GST amount which will be collected out of the total deal proceed at the time of making the final payment to the seller.</p>
						</ng-template>
				  	</ngb-panel>
				</ngb-accordion>
			</div>
		</div>
	</div>
</section>

<section class="page-section bg-white">
	<div class="container">
		<div class="row d-flex justify-content-center">
			<div class="col-md-12 text-center">
				<h2 class="hs-line-7 font-alt mb-10">Start your seller journey with us</h2>
				<div class="alt-features-descr">Sell the variety of products on Equibiz with the easiest way to start and grow your online business.</div>
				<div class="mt-50 text-center">
					<a href="javascript:;"  class="btn btn-mod btn-color btn-medium btn-round ripple px-4"  (click)="SignIn.openSigninModal('home')">Start Selling</a>
				</div>
			</div>
		</div>
	</div>
</section>

<app-footer-one></app-footer-one>

<app-signup-modal #signIn></app-signup-modal>