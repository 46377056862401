import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ManageBuyerDetailsService } from '../view-details/view-details.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { PersonalDetailsService } from 'src/app/seller/personal-details/personal-details.service';

@Component({
    selector: 'app-buyer-business-details',
    templateUrl: './buyer-business-details.component.html',
    styleUrls: ['./buyer-business-details.component.scss']
})
export class BuyerBusinessDetailsComponent implements OnInit {

    addRegisteredForm: FormGroup
    closeResult = '';
    stateArray: any = [];
    cityArray: any = [];
    constructor(
        private modalService: NgbModal,
        private _managebuyerdetailsservice: ManageBuyerDetailsService,
        private _toastr: ToastrService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private _personaldetailsservice: PersonalDetailsService
    ) { }

    ngOnInit(): void {
        this.business_detail.authorized_persons = [];
        this.buyerId = this.route.snapshot.paramMap.get('id');
        this.getdetails();
        this.getstate();
        this.addRegisteredForm = this.fb.group({
            address: this.fb.control(null, [Validators.required]),
            state: this.fb.control(null, [Validators.required]),
            city: this.fb.control(null, [Validators.required]),
            pincode: this.fb.control(null, [Validators.required, Validators.maxLength(6), Validators.minLength(6)]),
        });
    }

    public reject_reason = '';
    public reject_message = '';
    public reasons = [
        {
            name: 'Improper Documents',
            value: 'Improper Documents',
        },
        {
            name: 'Address Verification pending',
            value: 'Address Verification pending',
        },
        {
            name: 'Aadhar number mismatch',
            value: 'Aadhar number mismatch',
        },
        {
            name: 'Incorrect PAN number',
            value: 'Incorrect PAN number',
        },
        {
            name: 'Aadhar not Linked with Account',
            value: 'Aadhar not Linked with Account',
        },
        {
            name: 'Missing Authorized Person',
            value: 'Missing Authorized Person',
        },
        {
            name: 'Other',
            value: 'Other',
        }
    ];

    changestatus(type) {
        if (!this.reject_reason) {
            this._toastr.error('Please select reject reason.');
            return false;
        }
        if (!this.reject_message) {
            this._toastr.error('Please select reject message.');
            return false;
        }
        var data = { reason: this.reject_reason, message: this.reject_message, user_id: this.buyerId, type: type };
        this._managebuyerdetailsservice.changestatus(data).subscribe((data) => {
            if (data['status'] == "success") {
                if (this.personal_detail.usertype == 1) {
                    this._toastr.success('Buyer rejected')
                } else {
                    this._toastr.success('Seller rejected')
                }
                this.getdetails();
                this.modalService.dismissAll();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getnamecode(str, str1 = null) {
        if (str != '' || str1 != '') {
            if (str1) {
                str = str + " " + str1;
            }
            var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
            var acronym = matches.join('');
            return acronym;
        }
        return '';
    }

    public personal_detail: any = {};
    public buyerId: string = '';
    public business_detail: any = {};
    

    getdetails() {
        this._managebuyerdetailsservice.getdetails({ id: this.buyerId }).subscribe((data) => {
            if (data['status'] == "success") {
                this.business_detail = data['records']['business_detail'];
                this.personal_detail = data['records']['personal_detail'];
                this.addRegisteredForm.patchValue({
                    address: this.business_detail.reg_req_address,
                    state: this.business_detail.reg_req_state,
                    city: this.business_detail.reg_req_city,
                    pincode: this.business_detail.reg_req_pincode
                })
                this.getcity();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getfulladdress(addr) {
        var address = '';
        if (addr.address) {
            address = addr.address;
        }
        if (addr.city) {
            if (address) {
                address = address + ", " + addr.city;
            } else {
                address = addr.city;
            }

        }
        if (addr.state) {
            if (address) {
                address = address + ", " + addr.state;
            } else {
                address = addr.state;
            }
        }
        if (addr.pincode) {
            if (address) {
                address = address + ", " + addr.pincode;
            } else {
                address = addr.pincode;
            }
        }
        return address;
    }

    getstate() {
        this._personaldetailsservice.getstate(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.stateArray = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getcity() {
        var data = {};
        data = { state: this.addRegisteredForm.get('state').value };
        this._personaldetailsservice.getcity(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.cityArray = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    openmdl(profilephoto) {
        this.modalService.open(profilephoto, { windowClass: 'midmodal' });
    }
    openmodal(rejectbuyer) {
        this.modalService.open(rejectbuyer, { ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'mblbtm', size: 'md' });
    }
    openattribute(addattribute) {
        this.modalService.open(addattribute, { ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'mblbtm', size: 'lg' });
    }

    open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'mblbtm' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    adrsmdl(newadrs) {
        this.modalService.open(newadrs, { windowClass: 'lgmodal mblbtm' });
    }

    updateregisteredform() {
        var data = {
            user_id: this.buyerId,
            address: this.addRegisteredForm.get('address').value,
            state: this.addRegisteredForm.get('state').value,
            city: this.addRegisteredForm.get('city').value,
            pincode: this.addRegisteredForm.get('pincode').value,
        };
        this._managebuyerdetailsservice.updateregisteredaddress(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this._toastr.success('Registered address has been updated successfully.');
                this.getdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

}
