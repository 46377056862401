import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ProductListingService {
    
    constructor(private _http: HttpClient) { }

    getproductlisting(data) {
        return this._http.post(environment.api_url+'admin/getproduct/listing',data);
    }

    getrequestlisting(data) {
        return this._http.post(environment.api_url+'admin/product/requirement',data);
    }

    getsellerlisting(data) {
        return this._http.post(environment.api_url+'admin/product/sellerlisting',data);
    }

    copylisting(data) {
        return this._http.post(environment.api_url+'admin/product/clone',data);
    }

    deleterequestedlisting(data) {
        return this._http.post(environment.api_url+'admin/product/requirement/delete',data);
    }

    startrequestedlisting(data) {
        return this._http.post(environment.api_url+'admin/product/requirement/confirm',data);
    }

    importproducts(data) {
        return this._http.post(environment.api_url+'admin/addproduct/import',data);
    }

}
