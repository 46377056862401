import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
    constructor(private toastr: ToastrService) {}
  
    public validation(errors) {
        this.toastr.clear();
        errors.forEach((obj, index) => {
            this.toastr.error(obj.msg, '', { 
                positionClass: 'toast-top-right' 
           });
        });
    }

    public error(message: string, title: string = '') {
        this.toastr.clear();
        this.toastr.error(message, title, { 
            positionClass: 'toast-bottom-center' 
        });
    }

    public success(message: string, title: string = '') {
        this.toastr.clear();
        this.toastr.success(message, title, { 
            positionClass: 'toast-bottom-center' 
        });
    }

    public info(message: string, title: string = '') {
        this.toastr.clear();
        this.toastr.info(message, title, { 
            positionClass: 'toast-bottom-center' 
        });
    }

    public warning(message: string, title: string = '') {
        this.toastr.clear();
        this.toastr.warning(message, title, { 
            positionClass: 'toast-bottom-center' 
        });
    }

  }
