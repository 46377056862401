import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MybidsService {

    constructor(private _http: HttpClient) { }

    getmybids(data) {
        return this._http.post(environment.api_url+'product/buyer/bids',data);
    }

    getmywatchlist(data) {
        return this._http.post(environment.api_url+'mybids',data);
    }

    editbiddetails(data) {
        return this._http.post(environment.api_url+'product/buyer/bids/history',data);
    }

    updatebid(data) {
        return this._http.post(environment.api_url+'product/bids/update',data);
    }

    deletebid(data) {
        return this._http.post(environment.api_url+'product/bids/delete',data);
    }

    acceptcounteroffer(data) {
        return this._http.post(environment.api_url+'seller/bids/accept',data);
    }

}
