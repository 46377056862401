<app-header-seller></app-header-seller>
<app-sidebar-seller></app-sidebar-seller>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<h3 class="fs24 wt700 txtdefault">Deal Invoice</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Invoices</a>
						</li>
						<li class="breadcrumb-item active">Deal Invoice</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:void(0);" [routerLink]="['/seller/assistance']" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4 mb-0">
			<!--Filters-->
			<div class="row d-flex align-items-center justify-content-between mt-20 report-cta">
				<div class="col-md-5">
					<div class="cssinptchk d-inline-block mr-4 position-relative pl-4">
						<span class="css-check">
							<input class="css-checkbx" checked="" name="isCrs" type="radio" value="yes" />
							<label class="css-label"></label>
						</span>
						<p>All</p>															
					</div>
					<div class="cssinptchk d-inline-block mr-3 position-relative pl-4">
						<span class="css-check">
							<input class="css-checkbx"  name="isCrs" type="radio" value="yes" />
							<label class="css-label"></label>
						</span>
						<p>Bulk</p>															
					</div>
					<div class="cssinptchk d-inline-block mr-3 position-relative pl-4">
						<span class="css-check">
							<input class="css-checkbx"  name="isCrs" type="radio" value="yes" />
							<label class="css-label"></label>
						</span>
						<p>Wholesale</p>															
					</div>
				</div>

				<div class="col-md-7">
					<div class="d-flex justify-content-end">
						<div class="mr-3">
							<a href="javascript:void(0);" class="d-inline-block fltr-round-btn radius10 txtseconday">
								<img src="assets/images/svg/filter.svg">
								Filters
							</a>
						</div>
						<div class="form-container mt-0 mb-0 mr-3">
							<div class="report-input floating-label">
								<div class="input-group-custom">
									<input type="text" value="Last 30 Days" class="form-control" placeholder=" " />
									<span class="highlight"></span>
									<label>Date</label>
									<div class="input-group-append-custom">
										<img src="assets/images/icons/filter-calendar.svg">
									</div>
								</div>
							</div>
						</div>

						<div>
							<a href="javascript:void(0);" class="btn-d-outline d-inline-block">
								<svg xmlns="http://www.w3.org/2000/svg" width="12.681" height="17.061" viewBox="0 0 12.681 17.061">
								  <g id="np_export_2535025_000000" transform="translate(-32.121 -25.962)">
								    <path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#ff7135"/>
								    <path id="Path_2356" data-name="Path 2356" d="M44.093,70.07H32.83a.709.709,0,1,0,0,1.419H44.093a.709.709,0,0,0,0-1.419Z" transform="translate(0 -28.466)" fill="#ff7135"/>
								  </g>
								</svg>
								Export
							</a>
						</div>
					</div>
				</div>
			</div>
			<!--End Filters-->
        	<table class="table custom-table">
				<thead>
					<tr>
						<th>Invoice No.</th>
						<th>Mkt. Place</th>
						<th>Buyer Name</th>
						<th>Order Date</th>
						<th width="20%">Product Name</th>
						<th>Order Qty.</th>
						<th>Amount</th>
						<th>Status</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>EB123456</td>
						<td>Wholesale</td>
						<td>Suraj Enterprises</td>
						<td>12/04/2021</td>
						<td>Vivo X60 Pro (Midnight Black, 8GB RAM, 128GB Storage) without offers<br><a href="javascript:;" class="link-alt fs14">View 2 more items</a></td>
						<td>50</td>
						<td>₹ 50,000.00</td>
						<td>Delivered</td>
						<td>
							<a href="javascript:;"><img src="/assets/images/icons/pdf.png"></a>
						</td>
					</tr>
					<tr>
						<td>EB123456</td>
						<td>Bulk</td>
						<td>Sahana Mobiles</td>
						<td>12/04/2021</td>
						<td>Samsung Galaxy M21 2021 Edition (Blazing Black, 8GB RAM, 128 GB Stora…</td>
						<td>50</td>
						<td>₹ 50,000.00</td>
						<td>Delivered</td>
						<td>
							<a href="javascript:;"><img src="/assets/images/icons/pdf.png"></a>
						</td>
					</tr>
					<tr>
						<td>EB123456</td>
						<td>Wholesale</td>
						<td>Vishaal Digital</td>
						<td>12/04/2021</td>
						<td>Xiaomi 11 Lite NE 5G(Vinyl Black 6GB RAM, 128 GB Storage) Slimmest (6.8 m…</td>
						<td>50</td>
						<td>₹ 50,000.00</td>
						<td>Delivered</td>
						<td>
							<a href="javascript:;"><img src="/assets/images/icons/pdf.png"></a>
						</td>
					</tr>
					<tr>
						<td>EB123456</td>
						<td>Wholesale</td>
						<td>Mobile Galaxy</td>
						<td>12/04/2021</td>
						<td>Vivo X60 Pro (Midnight Black, 8GB RAM, 128GB Storage) without offers<br><a href="javascript:;" class="link-alt fs14">View 2 more items</a></td>
						<td>50</td>
						<td>₹ 50,000.00</td>
						<td>Delivered</td>
						<td>
							<a href="javascript:;"><img src="/assets/images/icons/pdf.png"></a>
						</td>
					</tr>

				</tbody>
			</table>
		</div>
		<div class="row mb-40">
			<div class="col-md-6">
				<div class="text-left pl-3">
					<nav class="mt-3 tbpg rows-per-page">
						<div>Rows per page:</div>
						<select class="select-box">
							<option>10</option>
							<option>20</option>
						</select>
					</nav>
				</div> 
			</div>
			<div class="col-md-6">
				<div class="text-right pr-3">
					<nav class="mt-3 tbpg">
					  <ul class="pagination align-items-center">
						<li class="page-item"><a class="page-link previcon" href="#"></a></li>
						<li class="page-item"><a class="page-link sprevicon" href="#"></a></li>
						<li class="page-item"><a class="page-link" href="#">1</a></li>
						<li class="page-item"><a class="page-link" href="#">2</a></li>
						<li class="page-item"><a class="page-link" href="#">3</a></li>
						<li class="page-item"><a class="page-link" href="#">4</a></li>
						<li class="page-item"><a class="page-link snexticon" href="#"></a></li>
						<li class="page-item"><a class="page-link nexticon" href="#"></a></li>
					  </ul>
					</nav>
				</div> 
			</div>
		</div> 
	</div>
</div>