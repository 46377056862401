import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BnplPaymentSuccessService } from "./bnpl-payment-success.component.service";

@Component({
  selector: "app-bnpl-payment-success",
  templateUrl: "./bnpl-payment-success.component.html",
  styleUrls: ["./bnpl-payment-success.component.scss"],
})
export class BnplPaymentSuccessComponent implements OnInit {
  public orderStatus: any = "";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _pageservice: BnplPaymentSuccessService
  ) {
    const gaScript = document.createElement("script");
    gaScript.innerHTML = `gtag('event', 'conversion', {'send_to': 'AW-10853447367/_mWcCOuVqbQDEMftqbco'});
    `;
    document.head.appendChild(gaScript);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this._pageservice.confirmOrder(params).subscribe((data) => {
        if (data["status"] == "success") {
          setTimeout( () => {
           this.router.navigate(['/buyer/buyer-track-orders']);
        }, 4000);
          this.orderStatus = '1';
        }
        else {
          this.orderStatus = '2';
        }
      });
    });
  }
}
