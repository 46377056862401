<ng-container>
<tr class="level-{{key}}" >
			            <td>
			               <div class="d-flex align-items-center">
			                  <!--reorder-->
			                  <div class="reorder">
			                     <img src="assets/images/icons/reorder.png">
			                  </div>
			                  <!--checkbox-->
			                  <div class="css-check">
			                     <input class="css-checkbx" type="checkbox" />
			                     <label class="css-label">
			                     &nbsp;
			                     </label>
			                  </div>
			                  <!--category name-->
			                  <div class="catg"><a href="javascript:void(0);"><img src="assets/images/admin/econsumer.png">{{ cat.name }}</a></div>
			               </div>
			            </td>
			            <td>
			               <span class="subcount">10</span> &nbsp;
			               <i placement="top" ngbTooltip="This will act as a default filter every time you will toggle.">
			                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
			                     <g id="Group_68" data-name="Group 68" transform="translate(-545 -281)">
			                        <circle id="Ellipse_1" data-name="Ellipse 1" cx="7.5" cy="7.5" r="7.5" transform="translate(545 281)" fill="#07f"/>
			                        <path id="Path_2235" data-name="Path 2235" d="M7,8.171l-.128.523q-.576.227-.919.346a2.425,2.425,0,0,1-.8.119,1.591,1.591,0,0,1-1.084-.34,1.1,1.1,0,0,1-.387-.864,3.056,3.056,0,0,1,.029-.416q.029-.213.093-.48l.48-1.7q.064-.244.108-.462a2.01,2.01,0,0,0,.044-.4A.616.616,0,0,0,4.3,4.047a.759.759,0,0,0-.513-.128,1.345,1.345,0,0,0-.381.058c-.131.039-.242.076-.336.11L3.2,3.564q.471-.192.9-.329A2.7,2.7,0,0,1,4.916,3.1a1.552,1.552,0,0,1,1.068.335,1.111,1.111,0,0,1,.375.869c0,.074-.008.2-.026.39a2.606,2.606,0,0,1-.1.512L5.759,6.9a4.492,4.492,0,0,0-.1.466,2.4,2.4,0,0,0-.047.4.57.57,0,0,0,.15.459.85.85,0,0,0,.523.122,1.509,1.509,0,0,0,.4-.061A2.21,2.21,0,0,0,7,8.171Zm.121-7.1a.99.99,0,0,1-.334.753,1.138,1.138,0,0,1-.8.312,1.15,1.15,0,0,1-.809-.312.988.988,0,0,1-.337-.753A1,1,0,0,1,5.169.314a1.194,1.194,0,0,1,1.614,0A1,1,0,0,1,7.117,1.07Z" transform="translate(547.406 283.92)" fill="#fff"/>
			                     </g>
			                  </svg>
			               </i>
			            </td>
			            <td>
			               <div class="d-flex align-items-center justify-container-start">
			                  <div class="status">Active</div>
			                  <div class="tglbtn">
			                     <label class="switch">
			                     <input type="checkbox">
			                     <span class="slider round"></span>
			                     </label>
			                  </div>
			               </div>
			            </td>
			            <td>
			               <a href="javascript:;"><img src="assets/images/icons/edit-big.png"></a>
			            </td>
			            <td>
			            	<a href="javascript:;" style="cursor: pointer;">
				               	<svg xmlns="http://www.w3.org/2000/svg" width="12.849" height="7.351" viewBox="0 0 12.849 7.351">
				                  <path id="Path_2277" data-name="Path 2277" d="M1.154,12.15A.676.676,0,0,1,.2,11.194l5.02-5.02L.2,1.154A.676.676,0,0,1,1.154.2l5.5,5.5a.675.675,0,0,1,0,.956Z" transform="translate(0.25 7.101) rotate(-90)" fill="#1f1d44" stroke="#1f1d44" stroke-width="0.5"/>
				               	</svg>
			               	</a>
			            </td>
			        </tr>
</ng-container>
<ng-container *ngIf="cat.ifchild">
	<app-category-table *ngFor="let category of cat.child"  [cat]="category" [key]="key+1"></app-category-table>
</ng-container>
