import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BuyerManageCategoriesService {

    constructor(private _http: HttpClient) { }

    getcategories(data) {
        return this._http.post(environment.api_url+'buyer/favoritecategory/list',data);
    }

    savecategories(data) {
        return this._http.post(environment.api_url+'buyer/favoritecategory/save',data);
    }

    // deletebrand(data) {
    //     return this._http.post(environment.api_url+'master/brand/delete',data);
    // }

}
