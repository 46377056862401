import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class GuestGuard implements CanActivate {
    constructor(private auth: AuthService, private router: Router) {

    }
    canActivate() {
        if (!this.auth.isLoggedIn()) {
            return true;
        } else {
            let type = localStorage.getItem('loggedInUserType');
            if (type == '1') {
                this.router.navigate(['/home']);
                return false;
            } else if (type == '2') {
                this.router.navigate(['/seller/dashboard']);
                return false;
            } else if (type == '3') {
                this.router.navigate(['/admin/dashboard']);
                return false;
            }
        }
        this.auth.logoutUser();
        this.router.navigate(['/home']);
        return false;
    }

}
