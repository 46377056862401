<a (click)="showsidebar()" href="javascript:void(0);" class="asidetoggle admntglmn">
	<div class="admin-tglbar">
		<span>
		</span>
		<span>
		</span>
		<span>
		</span>
	</div>
	</a>
<aside class="aside-seller admin-aside" [ngClass]="showside ? 'sidebarslide' : ''">
	
	<a (click)="showsidebar()" href="javascript:void(0);" class="asidetoggle">MENU
	<div class="admin-tglbar">
		<span>
		</span>
		<span>
		</span>
		<span>
		</span>
	</div>
	</a>
	
	<div class="as-avt-wrp">
		<div class="d-table">
			<div class="d-table-cell as-avt align-middle">
				<img [src]="user_info.profile_image ? user_info.profile_image : 'assets/images/admin/adminprofile.jpg'">
			</div>
			<div class="d-table-cell pl-2 align-middle">
				<h4 class="fs14 f-700 mb-1">{{ user_info.name }}</h4>
				<p class="mb-0 fs14 txtdefault d-flex align-items-center"><svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="15.473" height="15.522" viewBox="0 0 15.473 15.522">
					  <g id="np_wallet_1594672_000000" transform="translate(-2.7 -2.5)" opacity="0.8">
						<path id="Path_2329" data-name="Path 2329" d="M12.977,13.137v-1.8a1.855,1.855,0,0,1,1.846-1.846h2.418V8.17A1.346,1.346,0,0,0,15.9,6.83V6.062a1.75,1.75,0,0,0-1.748-1.748H12.161L10.853,2.5,8.321,4.314H4.432A1.729,1.729,0,0,0,2.7,5.965V16.437a1.586,1.586,0,0,0,1.585,1.585H15.657a1.586,1.586,0,0,0,1.585-1.585V14.983H14.823a1.844,1.844,0,0,1-1.846-1.846Zm1.176-7.843a.767.767,0,0,1,.768.768V6.83h-.964L12.863,5.294ZM12.748,6.83H6.49l4.134-2.974ZM4.432,5.294H6.965L4.824,6.83H4.432a.768.768,0,1,1,0-1.536Z" fill="#5e6a7c"/>
						<path id="Path_2330" data-name="Path 2330" d="M74,49.6H71.042A1.137,1.137,0,0,0,69.9,50.746v1.8a1.137,1.137,0,0,0,1.144,1.144H74a.4.4,0,0,0,.392-.392v-3.3A.387.387,0,0,0,74,49.6Zm-2.337,2.892a.842.842,0,1,1,0-1.683.841.841,0,0,1,.834.849A.817.817,0,0,1,71.663,52.494Z" transform="translate(-56.219 -39.406)" fill="#5e6a7c"/>
					  </g>
					</svg> {{ user_info.walletbalance }}</p>
			</div>
		</div>
	</div>
	<ngb-accordion  [closeOthers]="true" #acc="ngbAccordion" [activeIds]="activeIds">
		<ngb-panel >
			<ng-template ngbPanelHeader>
			  <div class="d-flex align-items-center justify-content-between">
				<button  class="btn btn-link noarrow active" [routerLink]="['/admin/dashboard']">
						<svg xmlns="http://www.w3.org/2000/svg" width="20.737" height="13.226" viewBox="0 0 20.737 13.226">
						  <path id="np_dashboard_637107_000000" d="M22.77,24.09a8.87,8.87,0,0,0-9.141,8.8c.114,3.2,1.371,3.428,4.113,3.428H28.254c2.743,0,3.657-.457,3.657-3.428A9.164,9.164,0,0,0,22.77,24.09Zm1.713,6.171s-.394.737-.736,1.422-.427.853-.634,1.32a1.232,1.232,0,0,1,0,1.257,1.117,1.117,0,0,1-1.6.457,1.179,1.179,0,0,1-.571-1.6c.229-.343.571-.8,1.143-.685l1.371-2.743c.114-.229.343-.685.914-.343a.542.542,0,0,1,.114.914Zm5.6,2.628h-.571c-.229,0-.457-.343-.571-.571a5.953,5.953,0,0,0-6.17-5.141A5.948,5.948,0,0,0,16.6,32.432a.612.612,0,0,1-.571.571h-.571a.64.64,0,0,1-.571-.685,7.526,7.526,0,0,1,7.884-6.856,7.57,7.57,0,0,1,7.77,6.856c.114.228-.115.57-.458.57Z" transform="translate(-13.126 -23.59)" fill="#5e6a7c" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" opacity="0.8"/>
						</svg>
					Dashboard
				</button>	
			  </div>
			</ng-template>			
		</ngb-panel>
		<ngb-panel>
			<ng-template ngbPanelHeader>
			  <div class="d-flex align-items-center justify-content-between">
				<button ngbPanelToggle class="btn btn-link">
					<svg xmlns="http://www.w3.org/2000/svg" width="15.473" height="15.522" viewBox="0 0 15.473 15.522">
					  <g id="np_wallet_1594672_000000" transform="translate(-2.7 -2.5)" opacity="0.8">
						<path id="Path_2329" data-name="Path 2329" d="M12.977,13.137v-1.8a1.855,1.855,0,0,1,1.846-1.846h2.418V8.17A1.346,1.346,0,0,0,15.9,6.83V6.062a1.75,1.75,0,0,0-1.748-1.748H12.161L10.853,2.5,8.321,4.314H4.432A1.729,1.729,0,0,0,2.7,5.965V16.437a1.586,1.586,0,0,0,1.585,1.585H15.657a1.586,1.586,0,0,0,1.585-1.585V14.983H14.823a1.844,1.844,0,0,1-1.846-1.846Zm1.176-7.843a.767.767,0,0,1,.768.768V6.83h-.964L12.863,5.294ZM12.748,6.83H6.49l4.134-2.974ZM4.432,5.294H6.965L4.824,6.83H4.432a.768.768,0,1,1,0-1.536Z" fill="#5e6a7c"/>
						<path id="Path_2330" data-name="Path 2330" d="M74,49.6H71.042A1.137,1.137,0,0,0,69.9,50.746v1.8a1.137,1.137,0,0,0,1.144,1.144H74a.4.4,0,0,0,.392-.392v-3.3A.387.387,0,0,0,74,49.6Zm-2.337,2.892a.842.842,0,1,1,0-1.683.841.841,0,0,1,.834.849A.817.817,0,0,1,71.663,52.494Z" transform="translate(-56.219 -39.406)" fill="#5e6a7c"/>
					  </g>
					</svg>
				Wallet 
				</button>
			  </div>
			</ng-template>
			<ng-template ngbPanelContent>
				<ul class="nav flex-column">
				  	<li class="nav-item">
						<a class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/wallet']" routerLinkActive="active">Wallet Details</a>
				  	</li>
				  	<!-- <li class="nav-item">
						<a class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/wallet/balance']" routerLinkActive="active">Wallet Balance</a>
				  	</li> -->
				  	<li class="nav-item ">
						<a class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/wallet/make-payment']" routerLinkActive="active">Make Payment</a>
				  	</li>
				  	<li class="nav-item ">
						<a class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/wallet/transaction-history']" routerLinkActive="active">Transaction Report</a>
				  	</li>
					<li class="nav-item">
						<a class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/unclear/balance']" routerLinkActive="active">Approve Transaction</a>
				  	</li>				 
				</ul> 
			</ng-template>
		</ngb-panel>
		<!-- <ngb-panel >
			<ng-template ngbPanelHeader>
			  <div class="d-flex align-items-center justify-content-between">
				<button  class="btn btn-link noarrow">					
					<svg xmlns="http://www.w3.org/2000/svg" width="15.886" height="15.926" viewBox="0 0 15.886 15.926">
						<g id="Group_13793" data-name="Group 13793" transform="translate(-444.324 -30.209)" opacity="0.8">
						<path id="Union_3" data-name="Union 3" d="M11.2,14.754a1.172,1.172,0,1,1,1.173,1.172A1.172,1.172,0,0,1,11.2,14.754Zm-6.313,0a1.172,1.172,0,1,1,1.173,1.172A1.172,1.172,0,0,1,4.89,14.754Zm.133-1.908a.647.647,0,0,1-.636-.554L2.712,1.273H.636A.637.637,0,0,1,.636,0H3.282a.648.648,0,0,1,.636.552l.235,1.608H15.222a.631.631,0,0,1,.5.234h0a.6.6,0,0,1,.152.519l-1.307,6.8a.662.662,0,0,1-.637.52H5.375l.2,1.339h7.569a.637.637,0,0,1,0,1.274ZM10.216,7.9a.642.642,0,0,0,.452.184A.651.651,0,0,0,11.12,7.9a.638.638,0,0,0,0-.9l-.787-.786.787-.787a.639.639,0,0,0-.9-.9l-.787.786-.787-.786a.639.639,0,1,0-.9.9l.787.787L7.737,7a.636.636,0,0,0,.452,1.088A.647.647,0,0,0,8.641,7.9l.787-.787Z" transform="translate(444.324 30.209)" fill="#5e6a7c"/>
						</g>
					</svg>

					Refund/ Return & Cancellations	
				</button>	
			  </div>
			</ng-template>			
		</ngb-panel> -->
		<ngb-panel>
			<ng-template ngbPanelHeader>
			  <div class="d-flex align-items-center justify-content-between">
				<button ngbPanelToggle class="btn btn-link ">
					<svg xmlns="http://www.w3.org/2000/svg" width="15.613" height="16.784" viewBox="0 0 15.613 16.784">
					  <path id="np_box_848942_000000" d="M17.806,7,20.38,8.031l-6.849,3.08-3-1.2Zm4.4,1.763,2.873,1.147-7.276,2.909-2.744-1.1Zm3.4,1.781V20.856L18.2,23.784V13.507ZM10,10.543l3.123,1.25v3.013l1.561.585V12.416l2.732,1.092V23.784L10,20.856Z" transform="translate(-10 -7)" fill="#5e6a7c" opacity="0.8"/>
					</svg> Order Management 
				</button>
			  </div>
			</ng-template>
			<ng-template ngbPanelContent>
				<ul class="nav flex-column">
					<li class="nav-item">
						<a class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/all-bids']">All Bids</a>
					</li>
				  <li class="nav-item">
					<a class="nav-link  fs14 textsecondary" [routerLink]="['/admin/order']">Orders</a>
				  </li>
				  <li class="nav-item">
					<a class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/order-quality-check']">Quality Check</a>
				  </li>
				  <li class="nav-item ">
					<a class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/trackorder']">Track Orders</a>
				  </li>
				  
				  <li class="nav-item ">
					<a class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/order-return']">Return Orders</a>
				  </li>	
				</ul> 
			</ng-template>
		</ngb-panel>
		<ngb-panel>
			<ng-template ngbPanelHeader>
			  <div class="d-flex align-items-center justify-content-between">
				
				<button ngbPanelToggle class="btn btn-link">
					<svg xmlns="http://www.w3.org/2000/svg" width="15.697" height="18" viewBox="0 0 15.697 18">
					  <path id="np_bag_943843_000000" d="M23.531,17.342l-1.122-9.9a.317.317,0,0,0-.338-.285H20.149V6.217a3.934,3.934,0,0,0-7.869,0V7.16H10.358a.346.346,0,0,0-.338.285L8.9,17.342V17.4a1.907,1.907,0,0,0,1.9,1.9h10.8a1.907,1.907,0,0,0,1.9-1.9c.019,0,.019-.036.019-.054ZM13.4,6.2a2.813,2.813,0,0,1,5.625,0v.944H13.4Zm-.391,5a.979.979,0,1,1,.979-.979A.965.965,0,0,1,13.01,11.2Zm6.408,0a.979.979,0,1,1,.979-.979A.976.976,0,0,1,19.419,11.2Z" transform="translate(-8.398 -1.801)" fill="#5e6a7c" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" opacity="0.8"/>
					</svg>
				Product Management 
				</button>
			  </div>
			</ng-template>
			<ng-template ngbPanelContent>
				<ul class="nav flex-column">
				  <li class="nav-item">
					<a  class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/product-listing']" routerLinkActive="active">Product Lisiting</a>
				  </li>
				  <li class="nav-item">
					<a class="nav-link fs14 textsecondary" href="javascript:void(0);"  [routerLink]="['/admin/product/category/add']" routerLinkActive="active">Add Product</a>
				  </li>
				  <li class="nav-item">
					<a class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/brandmanagment']">Brands Management</a>
				  </li>	
				  <li class="nav-item">
					<a class="nav-link fs14 textsecondary" href="javascript:void(0);"  [routerLink]="['/admin/manage-product-categories']" routerLinkActive="active">Manage Product Categories</a>
				  </li>	
				  <!-- <li class="nav-item">
					<a class="nav-link fs14 textsecondary" href="javascript:void(0);">Inventory Management</a>
				  </li>	 -->
				  <li class="nav-item">
					<a class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/review-moderation/product-reviews']">Review Moderation</a>
				  </li>	
				  <!-- <li class="nav-item">
					<a class="nav-link fs14 textsecondary" href="javascript:void(0);">Price Overrides</a>
				  </li>	 -->
				  <li class="nav-item">
					<a class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/hsnandgstmanagement']">HSN + GST Management</a>
				  </li>	
				  
				  <li class="nav-item">
					<a class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/stock-listing']">Stock Listing</a>
				  </li>	
				</ul>  
			</ng-template>
		</ngb-panel>
		<ngb-panel id="panel-user">
			<!--  -->
			<ng-template ngbPanelHeader>
			  <div class="d-flex align-items-center justify-content-between">
				<button ngbPanelToggle class="btn btn-link" aria-expanded="true">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12.973" height="15" viewBox="0 0 12.973 15">
					  <defs>
						<linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
						  <stop offset="0" stop-color="#79b7ff"/>
						  <stop offset="1" stop-color="#07f"/>
						</linearGradient>
					  </defs>
					  <path id="np_profile_1594246_000000" d="M46.973,46.595a.406.406,0,0,1-.4.405H34.4a.407.407,0,0,1-.4-.405,6.486,6.486,0,0,1,12.973,0Zm-6.486-7.3a3.649,3.649,0,1,1,3.649-3.649A3.65,3.65,0,0,1,40.486,39.3Z" transform="translate(-34 -32)" fill-rule="evenodd" fill="url(#linear-gradient)"/>
					</svg>
				User Management
				</button>
			  </div>
			</ng-template>
			<ng-template ngbPanelContent>
				<ul class="nav flex-column collapse show">
				  <li class="nav-item">
					<a [routerLink]="['/admin/manage-buyer']" routerLinkActive="active" class="nav-link fs14 textsecondary" href="javascript:void(0);">Buyer</a>
				  </li>
				  <li class="nav-item">
					<a [routerLink]="['/admin/manage-seller']" routerLinkActive="active" class="nav-link fs14 textsecondary" href="javascript:void(0);">Seller</a>
				  </li>
				  <li class="nav-item">
					<a [routerLink]="['/admin/managecandf']" routerLinkActive="active" class="nav-link fs14 textsecondary" href="javascript:void(0);">C&F</a>
				  </li>		 
				</ul>  
			</ng-template>
		</ngb-panel>
		<!-- <ngb-panel>
			<ng-template ngbPanelHeader>
			  <div class="d-flex align-items-center justify-content-between">
				<button ngbPanelToggle class="btn btn-link">
						<svg xmlns="http://www.w3.org/2000/svg" width="15.318" height="19.538" viewBox="0 0 15.318 19.538">
					  <g id="np_box_4146188_000000" transform="translate(-13.864 -17.861)" opacity="0.8">
						<path id="Path_8721" data-name="Path 8721" d="M21.523,29.533a2.825,2.825,0,1,1,0,5.645,3.112,3.112,0,0,1-1.2-.237l-.767.411c-.233.125-.412-.042-.354-.291l.175-.755a2.747,2.747,0,0,1-.8-1.952,2.854,2.854,0,0,1,2.944-2.822ZM16.686,22.91a1.632,1.632,0,0,0-1.657,1.105L13.951,27.4a1.635,1.635,0,0,0-.087.52v8.6c0,.488.519.881,1.164.881h12.99c.644,0,1.163-.392,1.163-.881v-8.6a1.593,1.593,0,0,0-.089-.52l-1.076-3.386a1.634,1.634,0,0,0-1.658-1.105h-.917c-.7,0-.7,1.181,0,1.181h.917a.526.526,0,0,1,.528.416l.962,3.022H15.2l.96-3.022a.527.527,0,0,1,.529-.416H17.6c.7,0,.7-1.181,0-1.181Z" fill="#5e6a7c" fill-rule="evenodd"/>
						<path id="Path_8722" data-name="Path 8722" d="M40.816,66.758a.5.5,0,1,0,.5.5A.5.5,0,0,0,40.816,66.758Z" transform="translate(-20.854 -34.863)" fill="#5e6a7c" fill-rule="evenodd"/>
						<path id="Path_8723" data-name="Path 8723" d="M47.877,66.758a.5.5,0,1,0,.5.5A.5.5,0,0,0,47.877,66.758Z" transform="translate(-26.351 -34.863)" fill="#5e6a7c" fill-rule="evenodd"/>
						<path id="Path_8724" data-name="Path 8724" d="M55.438,67.258a.5.5,0,1,1-.5-.5.5.5,0,0,1,.5.5Z" transform="translate(-31.854 -34.863)" fill="#5e6a7c" fill-rule="evenodd"/>
						<path id="Path_8725" data-name="Path 8725" d="M35.586,5.809a.718.718,0,0,0-.735.706v6.649h-.726a.55.55,0,1,0,0,1.1H41.1a.55.55,0,1,0,0-1.1h-.728V6.514a.718.718,0,0,0-.734-.706Z" transform="translate(-16.089 12.052)" fill="#5e6a7c" fill-rule="evenodd"/>
					  </g>
					</svg>

				Customer Complaint & Dispute Management 
				</button>
			  </div>
			</ng-template>
			<ng-template ngbPanelContent>
				<ul class="nav flex-column">
				  <li class="nav-item">
					<a class="nav-link  fs14 textsecondary" href="javascript:void(0);">Option 1</a>
				  </li>
				  <li class="nav-item">
					<a class="nav-link fs14 textsecondary" href="javascript:void(0);">Option 2</a>
				  </li>
				  <li class="nav-item ">
					<a class="nav-link fs14 textsecondary" href="javascript:void(0);">Option 3</a>
				  </li>				 
				</ul>  
			</ng-template>
		</ngb-panel> -->
		<ngb-panel>
			<ng-template ngbPanelHeader>
			  <div class="d-flex align-items-center justify-content-between">
				<button ngbPanelToggle class="btn btn-link">
					<svg xmlns="http://www.w3.org/2000/svg" width="15.584" height="17" viewBox="0 0 15.584 17">
					  <g id="np_logistic_3976314_000000" transform="translate(-4.168 -0.01)" opacity="0.8">
						<path id="Path_8719" data-name="Path 8719" d="M19.593,5.027,12.155.068h0a.351.351,0,0,0-.389,0L4.328,5.028h0a.355.355,0,0,0-.159.294V16.655a.354.354,0,0,0,.354.354H6.647A.354.354,0,0,0,7,16.655V9.217h9.918v7.438a.354.354,0,0,0,.354.354H19.4a.354.354,0,0,0,.354-.354V5.321a.355.355,0,0,0-.159-.294ZM16.919,8.509H7V8.155h9.918Zm0-1.063H7V7.092h9.918Z" fill="#5e6a7c"/>
						<path id="Path_8720" data-name="Path 8720" d="M35.3,61.238h-1.13V58.655a.323.323,0,0,0-.323-.323H30.944a.323.323,0,0,0-.323.323v2.583h-1.13a.323.323,0,0,0-.323.323v2.906a.322.322,0,0,0,.323.323H35.3a.322.322,0,0,0,.323-.323V61.561a.323.323,0,0,0-.323-.323Z" transform="translate(-20.436 -47.78)" fill="#5e6a7c"/>
					  </g>
					</svg>
					Logistic Management	
				</button>
			  </div>
			</ng-template>
			<ng-template ngbPanelContent>
				<ul class="nav flex-column">
				  <li class="nav-item">
					<a class="nav-link  fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/logistic-partners-list']">Logistic Partners List</a>
				  </li>
				  <li class="nav-item">
					<a class="nav-link  fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/logistics-charges']">Logistic Charges</a>
				  </li>		 
				</ul>  
			</ng-template>
		</ngb-panel>
		<ngb-panel >
			<ng-template ngbPanelHeader>
			  <div class="d-flex align-items-center justify-content-between">
				<button  class="btn btn-link noarrow active" [routerLink]="['/admin/media']">
						<svg xmlns="http://www.w3.org/2000/svg" width="20.737" height="13.226" viewBox="0 0 20.737 13.226">
						  <path id="np_dashboard_637107_000000" d="M22.77,24.09a8.87,8.87,0,0,0-9.141,8.8c.114,3.2,1.371,3.428,4.113,3.428H28.254c2.743,0,3.657-.457,3.657-3.428A9.164,9.164,0,0,0,22.77,24.09Zm1.713,6.171s-.394.737-.736,1.422-.427.853-.634,1.32a1.232,1.232,0,0,1,0,1.257,1.117,1.117,0,0,1-1.6.457,1.179,1.179,0,0,1-.571-1.6c.229-.343.571-.8,1.143-.685l1.371-2.743c.114-.229.343-.685.914-.343a.542.542,0,0,1,.114.914Zm5.6,2.628h-.571c-.229,0-.457-.343-.571-.571a5.953,5.953,0,0,0-6.17-5.141A5.948,5.948,0,0,0,16.6,32.432a.612.612,0,0,1-.571.571h-.571a.64.64,0,0,1-.571-.685,7.526,7.526,0,0,1,7.884-6.856,7.57,7.57,0,0,1,7.77,6.856c.114.228-.115.57-.458.57Z" transform="translate(-13.126 -23.59)" fill="#5e6a7c" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" opacity="0.8"/>
						</svg>
					Media
				</button>	
			  </div>
			</ng-template>			
		</ngb-panel>
		<ngb-panel >
			<ng-template ngbPanelHeader>
			  <div class="d-flex align-items-center justify-content-between">
				<button  class="btn btn-link noarrow active" [routerLink]="['/admin/bnpl-charges']">
						<svg xmlns="http://www.w3.org/2000/svg" width="20.737" height="13.226" viewBox="0 0 20.737 13.226">
						  <path id="np_dashboard_637107_000000" d="M22.77,24.09a8.87,8.87,0,0,0-9.141,8.8c.114,3.2,1.371,3.428,4.113,3.428H28.254c2.743,0,3.657-.457,3.657-3.428A9.164,9.164,0,0,0,22.77,24.09Zm1.713,6.171s-.394.737-.736,1.422-.427.853-.634,1.32a1.232,1.232,0,0,1,0,1.257,1.117,1.117,0,0,1-1.6.457,1.179,1.179,0,0,1-.571-1.6c.229-.343.571-.8,1.143-.685l1.371-2.743c.114-.229.343-.685.914-.343a.542.542,0,0,1,.114.914Zm5.6,2.628h-.571c-.229,0-.457-.343-.571-.571a5.953,5.953,0,0,0-6.17-5.141A5.948,5.948,0,0,0,16.6,32.432a.612.612,0,0,1-.571.571h-.571a.64.64,0,0,1-.571-.685,7.526,7.526,0,0,1,7.884-6.856,7.57,7.57,0,0,1,7.77,6.856c.114.228-.115.57-.458.57Z" transform="translate(-13.126 -23.59)" fill="#5e6a7c" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" opacity="0.8"/>
						</svg>
					BNPL Charges
				</button>	
			  </div>
			</ng-template>			
		</ngb-panel>
		<ngb-panel>
		   <ng-template ngbPanelHeader>
		      <div class="d-flex align-items-center justify-content-between">
		         <button ngbPanelToggle class="btn btn-link">
		            <svg xmlns="http://www.w3.org/2000/svg" width="16.897" height="16" viewBox="0 0 16.897 16">
		               <g id="Layer_2" data-name="Layer 2" transform="translate(-1 -1.804)" opacity="0.8">
		                  <path id="Path_10470" data-name="Path 10470" d="M16.889,7.457a2.208,2.208,0,0,1-1.74-2.985,1.036,1.036,0,0,0-.31-1.166,8.308,8.308,0,0,0-2.478-1.436,1.042,1.042,0,0,0-1.177.327,2.2,2.2,0,0,1-3.47,0,1.042,1.042,0,0,0-1.177-.327,8.347,8.347,0,0,0-2.309,1.3,1.048,1.048,0,0,0-.327,1.2A2.2,2.2,0,0,1,2.07,7.384a1.042,1.042,0,0,0-.912.839A7.964,7.964,0,0,0,1,9.8a8.066,8.066,0,0,0,.107,1.324A1.042,1.042,0,0,0,2.025,12a2.2,2.2,0,0,1,1.791,3.1A1.025,1.025,0,0,0,4.1,16.328a8.37,8.37,0,0,0,2.456,1.414,1.126,1.126,0,0,0,.355.062,1.036,1.036,0,0,0,.845-.439,2.18,2.18,0,0,1,1.8-.946,2.208,2.208,0,0,1,1.769.89,1.036,1.036,0,0,0,1.217.344A8.449,8.449,0,0,0,14.8,16.305a1.042,1.042,0,0,0,.3-1.188,2.2,2.2,0,0,1,1.763-3.036,1.042,1.042,0,0,0,.884-.856A8.167,8.167,0,0,0,17.9,9.8a8.087,8.087,0,0,0-.141-1.506,1.031,1.031,0,0,0-.867-.838ZM12.265,9.8A2.816,2.816,0,1,1,9.449,6.984,2.816,2.816,0,0,1,12.265,9.8Z" transform="translate(0)" fill="#5e6a7c"/>
		               </g>
		            </svg>
		            Settings 
		         </button>
		      </div>
		   </ng-template>
		   <ng-template ngbPanelContent>
				<ul class="nav flex-column">
					<li class="nav-item">
						<a class="nav-link  fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/settings/personal-details']">Personal Details</a>
					</li>
					<li class="nav-item">
						<a class="nav-link  fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/settings/countries']">Countries</a>
					</li>
					<li class="nav-item">
						<a class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/settings/authorized-persons']">Authorized Persons</a>
					</li>
					<li class="nav-item ">
						<a class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/settings/access-management']">Access Management</a>
					</li>
					<li class="nav-item ">
						<a class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/settings/bank-account-details']">Bank Account Details</a>
					</li>
					<li class="nav-item ">
						<a class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/settings/add-banks-global']">Add Banks (Global)</a>
					</li>
					<li class="nav-item ">
						<a class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/settings/activity-logs']">Activity Log</a>
					</li>
				</ul>
		   </ng-template>
		</ngb-panel>

		<ngb-panel>
			<ng-template ngbPanelHeader>
			   <div class="d-flex align-items-center justify-content-between">
				  <button ngbPanelToggle class="btn btn-link">
					 <svg xmlns="http://www.w3.org/2000/svg" width="16.897" height="16" viewBox="0 0 16.897 16">
						<g id="Layer_2" data-name="Layer 2" transform="translate(-1 -1.804)" opacity="0.8">
						   <path id="Path_10470" data-name="Path 10470" d="M16.889,7.457a2.208,2.208,0,0,1-1.74-2.985,1.036,1.036,0,0,0-.31-1.166,8.308,8.308,0,0,0-2.478-1.436,1.042,1.042,0,0,0-1.177.327,2.2,2.2,0,0,1-3.47,0,1.042,1.042,0,0,0-1.177-.327,8.347,8.347,0,0,0-2.309,1.3,1.048,1.048,0,0,0-.327,1.2A2.2,2.2,0,0,1,2.07,7.384a1.042,1.042,0,0,0-.912.839A7.964,7.964,0,0,0,1,9.8a8.066,8.066,0,0,0,.107,1.324A1.042,1.042,0,0,0,2.025,12a2.2,2.2,0,0,1,1.791,3.1A1.025,1.025,0,0,0,4.1,16.328a8.37,8.37,0,0,0,2.456,1.414,1.126,1.126,0,0,0,.355.062,1.036,1.036,0,0,0,.845-.439,2.18,2.18,0,0,1,1.8-.946,2.208,2.208,0,0,1,1.769.89,1.036,1.036,0,0,0,1.217.344A8.449,8.449,0,0,0,14.8,16.305a1.042,1.042,0,0,0,.3-1.188,2.2,2.2,0,0,1,1.763-3.036,1.042,1.042,0,0,0,.884-.856A8.167,8.167,0,0,0,17.9,9.8a8.087,8.087,0,0,0-.141-1.506,1.031,1.031,0,0,0-.867-.838ZM12.265,9.8A2.816,2.816,0,1,1,9.449,6.984,2.816,2.816,0,0,1,12.265,9.8Z" transform="translate(0)" fill="#5e6a7c"/>
						</g>
					 </svg>
					 CMS 
				  </button>
			   </div>
			</ng-template>
			<ng-template ngbPanelContent>
			   <ul class="nav flex-column">
				  <li class="nav-item">
					 <a class="nav-link  fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/cms/buyer-home']">Buyer Home Page</a>
				  </li>
				  	<li class="nav-item">
						<a class="nav-link  fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/cms/buyer-category/add']">Buyer Category Page</a>
				 	</li>
				  <li class="nav-item">
					<a class="nav-link  fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/cms/terms']">Terms of use</a>
				 </li>
				 <li class="nav-item">
					<a class="nav-link  fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/cms/privacy-policy']">Privacy Policy</a>
				 </li>
				<li class="nav-item">
					<a class="nav-link  fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/cms/return-policy']">Return Shipments Policy</a>
				 </li>
				 <li class="nav-item">
					<a class="nav-link  fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/cms/ip-reports-infringement-policy']">IP/Reports Infringement Policy</a>
				 </li>
				 <li class="nav-item">
					<a class="nav-link  fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/cms/anti-counterfeiting-policy']">Anti-Counterfeiting Policy</a>
				 </li>
				 <li class="nav-item">
					<a class="nav-link  fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/cms/logistics-policy']">Logistics Policy</a>
				 </li>
			   </ul>
			</ng-template>
		 </ngb-panel>
		<!-- <ngb-panel>
			<ng-template ngbPanelHeader>
			  <div class="d-flex align-items-center justify-content-between">
				<button  class="btn btn-link noarrow">
					<svg xmlns="http://www.w3.org/2000/svg" width="15.427" height="17.322" viewBox="0 0 15.427 17.322">
					  <path id="np_list_2711442_000000" d="M37.194,21.488H26.787a1.919,1.919,0,0,0-1.993,1.791v12.74a1.919,1.919,0,0,0,1.993,1.791H37.194a1.929,1.929,0,0,0,2.027-1.791V23.279a1.929,1.929,0,0,0-2.027-1.791Zm-5.946,7.671h4.461a.5.5,0,0,1,.541.473.527.527,0,0,1-.541.506H31.247a.538.538,0,0,1-.575-.506A.513.513,0,0,1,31.247,29.159Zm-2.94,0h.879a.5.5,0,0,1,.541.473.527.527,0,0,1-.541.506h-.879a.528.528,0,0,1-.541-.506A.5.5,0,0,1,28.307,29.159Zm2.94-3.413h4.461a.528.528,0,0,1,.541.506.5.5,0,0,1-.541.473H31.247a.513.513,0,0,1-.575-.473A.537.537,0,0,1,31.247,25.745Zm-2.94,0h.879a.528.528,0,0,1,.541.506.5.5,0,0,1-.541.473h-.879a.5.5,0,0,1-.541-.473A.527.527,0,0,1,28.307,25.745Zm2.94,6.792h4.461a.528.528,0,0,1,.541.506.5.5,0,0,1-.541.473H31.247a.513.513,0,0,1-.575-.473A.537.537,0,0,1,31.247,32.537Zm-2.94,0h.879a.528.528,0,0,1,.541.506.5.5,0,0,1-.541.473h-.879a.5.5,0,0,1-.541-.473A.527.527,0,0,1,28.307,32.537Z" transform="translate(-24.293 -20.988)" fill="#5e6a7c" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" fill-rule="evenodd" opacity="0.8"/>
					</svg>
					Buyer Request List
				</button>	
			  </div>
			</ng-template>			
		</ngb-panel>
		<ngb-panel>
			<ng-template ngbPanelHeader>
			  <div class="d-flex align-items-center justify-content-between">
				<button  class="btn btn-link noarrow">
					<svg xmlns="http://www.w3.org/2000/svg" width="16.855" height="16.855" viewBox="0 0 16.855 16.855">
					  <g id="Group_13799" data-name="Group 13799" transform="translate(-444.324 -26.967)" opacity="0.8">
						<path id="Union_6" data-name="Union 6" d="M7.519,16.582l-.944-.618a.53.53,0,0,0-.327-.089L5.1,15.947a1.63,1.63,0,0,1-1.543-.891l-.509-1.016a.591.591,0,0,0-.236-.237L1.8,13.295a1.612,1.612,0,0,1-.89-1.543l.073-1.145a.868.868,0,0,0-.073-.363L.272,9.3a1.592,1.592,0,0,1,0-1.78L.89,6.575a.532.532,0,0,0,.091-.327L.908,5.1A1.627,1.627,0,0,1,1.8,3.561l1.016-.51a.491.491,0,0,0,.236-.235L3.559,1.8A1.611,1.611,0,0,1,5.1.908L6.248.98A.842.842,0,0,0,6.61.908L7.556.273a1.585,1.585,0,0,1,1.78,0l.944.618a.529.529,0,0,0,.327.09L11.751.908a1.626,1.626,0,0,1,1.543.891L13.8,2.815a.5.5,0,0,0,.236.236l1.017.51a1.612,1.612,0,0,1,.89,1.543l-.073,1.144a.738.738,0,0,0,.073.345l.636.946h0a1.593,1.593,0,0,1,0,1.781l-.618.963a.525.525,0,0,0-.091.326l.038,1.126a1.628,1.628,0,0,1-.89,1.544l-1.016.508a.5.5,0,0,0-.236.236l-.509,1.018a1.61,1.61,0,0,1-1.544.89l-1.144-.072a.68.68,0,0,0-.327.09l-.944.636a1.59,1.59,0,0,1-1.781,0ZM8.5,10.806a2.016,2.016,0,0,0,4.032,0A2,2,0,0,0,10.516,8.79,2.024,2.024,0,0,0,8.5,10.806Zm3.014-6.228L4.577,11.5a.527.527,0,0,0,0,.762.525.525,0,0,0,.763,0L12.259,5.34a.542.542,0,0,0,.019-.762.551.551,0,0,0-.389-.164A.5.5,0,0,0,11.514,4.578ZM4.323,6.049A2,2,0,0,0,6.339,8.065,2.017,2.017,0,1,0,4.323,6.049Zm5.267,4.74a.926.926,0,0,1,1.853,0,.918.918,0,0,1-.926.926A.93.93,0,0,1,9.589,10.789ZM5.412,6.049a.9.9,0,0,1,.926-.909.931.931,0,0,1,.926.927.915.915,0,0,1-.926.907A.93.93,0,0,1,5.412,6.049Z" transform="translate(444.324 26.967)" fill="#5e6a7c"/>
					  </g>
					</svg>
					Discount Engine
				</button>	
			  </div>
			</ng-template>			
		</ngb-panel> -->
		<ngb-panel>
		   <ng-template ngbPanelHeader>
		      <div class="d-flex align-items-center justify-content-between">
		         <button ngbPanelToggle class="btn btn-link">
		            <svg xmlns="http://www.w3.org/2000/svg" width="14.875" height="17" viewBox="0 0 14.875 17">
					  <g id="np_report_3888336_000000" transform="translate(-6.25)" opacity="0.8">
						<path id="Path_2331" data-name="Path 2331" d="M34.375.531A.531.531,0,0,1,34.906,0h4.25a.531.531,0,0,1,.531.531V2.656a.531.531,0,0,1-.531.531h-4.25a.531.531,0,0,1-.531-.531Z" transform="translate(-23.344)" fill="#5e6a7c"/>
						<path id="Path_2332" data-name="Path 2332" d="M8.906,6.25H9.969V8.729a.727.727,0,0,0,.744.709h5.95a.727.727,0,0,0,.744-.709V6.25h1.063a2.656,2.656,0,0,1,2.656,2.656V19.531a2.656,2.656,0,0,1-2.656,2.656H8.906A2.656,2.656,0,0,1,6.25,19.531V8.906A2.656,2.656,0,0,1,8.906,6.25ZM10.5,18.469a.531.531,0,0,0,1.063,0V12.094a.531.531,0,0,0-1.063,0ZM13.688,19a.531.531,0,0,1-.531-.531v-4.25a.531.531,0,0,1,1.063,0v4.25A.531.531,0,0,1,13.688,19Zm2.656,0a.531.531,0,0,1-.531-.531V16.344a.531.531,0,0,1,1.062,0v2.125A.531.531,0,0,1,16.344,19Z" transform="translate(0 -5.188)" fill="#5e6a7c" fill-rule="evenodd"/>
					  </g>
					</svg>
		            Reports 
		         </button>
		      </div>
		   </ng-template>
		   <ng-template ngbPanelContent>
		      <ul class="nav flex-column">
		         <li class="nav-item">
		            <a class="nav-link  fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/subscribers']">Subscibers</a>
		         </li>
				 <li class="nav-item">
		            <a class="nav-link  fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/charges-invoice']">Charges invoice</a>
		         </li>
		         <li class="nav-item">
		            <a class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/request-callback']">Request Callback</a>
		         </li>
		         <li class="nav-item ">
		            <a class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/enquiry-list']">Contact Form</a>
		         </li>
				 <li class="nav-item ">
		            <a class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/unconfirmed-orders']">Unconfirmed Orders</a>
		         </li>
				 <li class="nav-item ">
		            <a class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/unconfirmed-wallet']">Unconfirmed Wallet</a>
		         </li>
				 <li class="nav-item ">
		            <a class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/bnpl/transaction/reports']">BNPL Transaction Reports</a>
		         </li>
				 <li class="nav-item ">
		            <a class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/admin/spice/money/reports']">Spice Money Users</a>
		         </li>
		      </ul>
		   </ng-template>
		</ngb-panel>
		<ngb-panel>
			<ng-template ngbPanelHeader>
			  <div class="d-flex align-items-center justify-content-between">
				<button  class="btn btn-link noarrow" (click)="logout()">
					
						<svg xmlns="http://www.w3.org/2000/svg" width="15.231" height="20.444" viewBox="0 0 15.231 20.444">
						  <g id="np_log-out_738609_000000" opacity="0.8">
							<path id="Path_2333" data-name="Path 2333" d="M12.929.319q2.968.749,5.938,1.487c.174.043.18.147.18.287q.006,3.19.016,6.38.008,4.62.017,9.239c0,.185-.041.276-.225.34q-3.349,1.154-6.691,2.326c-.061.021-.123.035-.213.061-.008-.149-.02-.28-.021-.409q-.031-3.31-.062-6.619-.057-5.619-.117-11.238C11.744,1.447,11.73.722,11.719,0h.039c.388.108.778.224,1.171.323Zm-.353,10.475a.4.4,0,0,0,.64-.043.832.832,0,0,0,.1-.863.507.507,0,0,0-.493-.348.976.976,0,0,0-.245.147.862.862,0,0,0,0,1.107Z" transform="translate(-3.849 0.004)" fill="#5e6a7c"/>
							<path id="Path_2334" data-name="Path 2334" d="M54.971,7.508c-.173,0-.235.042-.233.232.009,1.213,0,2.426,0,3.64v.41c-.311,0-.626,0-.932-.009-.03,0-.078-.123-.078-.189-.006-.753,0-1.506,0-2.26V6.693c0-.12,0-.214.169-.212,1.18.006,2.361,0,3.542,0h0c.033,0,.067,0,.1.01V7.512H57.3c-.774,0-1.551,0-2.331,0Z" transform="translate(-50.661 -5.155)" fill="#5e6a7c"/>
							<path id="Path_2335" data-name="Path 2335" d="M53.136,67.577c.263.011.533,0,.805,0v3.631H56.9V72.26c-.078,0-.119.01-.176.01-1.192,0-2.384,0-3.575,0-.176,0-.217-.055-.216-.225q.008-2.129,0-4.257c0-.166.047-.22.207-.216Z" transform="translate(-49.863 -53.76)" fill="#5e6a7c"/>
							<path id="Path_2336" data-name="Path 2336" d="M57.468,39.009a.637.637,0,0,0,.272.394q.889.679,1.772,1.366a.468.468,0,0,0,.73-.156h0a.67.67,0,0,0,.05-.233c.008-.15,0-.3,0-.462a3.187,3.187,0,0,1,1.963.668,3.368,3.368,0,0,1,1.191,1.736,3.364,3.364,0,0,0-3.155-4.347V37.5h0a.477.477,0,0,0-.171-.378.461.461,0,0,0-.6,0c-.23.176-.459.354-.688.53-.363.28-.724.562-1.089.839a.627.627,0,0,0-.272.394v.12Z" transform="translate(-57.468 -29.444)" fill="#5e6a7c"/>
						  </g>
						</svg>
					Logout 
				</button>					
			  </div>
			</ng-template>
			
		</ngb-panel>
		
	</ngb-accordion>
</aside>
<router-outlet></router-outlet>