import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { ViewProductReviewService } from './view-product-reviews.service';

@Component({
  selector: 'app-view-product-review',
  templateUrl: './view-product-review.component.html',
  styleUrls: ['./view-product-review.component.scss']
})
export class ViewProductReviewComponent implements OnInit {

    public review_id:string = '';
    public review_details:any = {};
    constructor(
        private _viewproductreview: ViewProductReviewService,
        private _toastr: ToasterService,
        private _route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.review_id = this._route.snapshot.paramMap.get('id');
        this.reviewdetails();
    }

    reviewdetails() {
        this._viewproductreview.reviewdetails({ id: this.review_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.review_details = data['record'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    reviewstatus(val) {
        this._viewproductreview.reviewstatus({ id: this.review_id, type: 'product', status: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Review status has been updated');
                this.reviewdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    updatereview() {
        this._viewproductreview.updatereview({ id: this.review_id, type: 'product', rating: this.review_details.product_review_rating , notes: this.review_details.product_review_desc }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Review details has been updated');
                this.reviewdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

}
