import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { BidsAndOffersService } from './bids-and-offers.service';
import { ProductCategoryService } from 'src/app/admin/product-category/product-category.service';
import { AddListingService } from 'src/app/seller/add-listing/addlisting.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-bids-and-offers',
    templateUrl: './bids-and-offers.component.html',
    styleUrls: ['./bids-and-offers.component.scss']
})
export class BidsAndOffersComponent implements OnInit {

    closeResult = '';
    public product_id: string;
    public bid_records: any = [];
    public mybid_records: any = [];
    public product_detail: any = {};
    public selected_bid: any = {};
    public new_bid_amount: number;
    keyword = 'name';
  public selected_category: string = '';
    constructor(
        private modalService: NgbModal,
        private _bidsandoffersservice: BidsAndOffersService,
        private _toastr: ToasterService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _editproductcategoryservice: ProductCategoryService,
          private _addlistingservice: AddListingService,
    ) { }

    ngOnInit(): void {
        this.product_id = this._route.snapshot.paramMap.get('product_id');
        this.getproductbids();
        setTimeout( () => {
            this.getbidmylisting();
        }, 1000);
        
    }

    @ViewChild('tabset') tabset;

    getproductbids() {
        this._bidsandoffersservice.getproductbidlisting({ product_id: this.product_id, bid_type: 'product', category: this.selected_category }).subscribe((data) => {
            if (data['status'] == "success") {
                this.bid_records = data['records'];
                this.product_detail = data['basic'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getbidmylisting() {
        this._bidsandoffersservice.getbidmylisting({ product_id: this.product_id, bid_type: 'product' }).subscribe((data) => {
            if (data['status'] == "success") {
                this.mybid_records = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    open(content, record) {
        this._bidsandoffersservice.getbidinfo({ bid_id: record.bid_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.selected_bid = data['record'];
                this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }


    acceptbid() {
        this._bidsandoffersservice.acceptbid({ bid_id: this.selected_bid.bid_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this._toastr.success('Bid offer has been accepted.');
                this.getproductbids();
                this.getbidmylisting();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    declinebid() {
        this._bidsandoffersservice.declinebid({ bid_id: this.selected_bid.bid_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this._toastr.success('Bid offer has been declined.');
                this.getproductbids();
                this.getbidmylisting();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    openCounterOffer(content2, record) {
        this.modalService.dismissAll();
        this._bidsandoffersservice.getbidinfo({ bid_id: record.bid_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.selected_bid = data['record'];
                this.modalService.open(content2, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    savecounteroffer() {
        this._bidsandoffersservice.savecounteroffer({ bid_id: this.selected_bid.bid_id, amount: this.new_bid_amount }).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this._toastr.success('Counter offer has been submitted.');
                this.getproductbids();
                this.getbidmylisting();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    openRemoveProduct(content3) {
        this.modalService.open(content3, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    result = [];
    presult = [];

    selectEvent(item) {
        this.selected_category = item.c_id;
        this.tabset.select('allBids');
        this.getproductbids();
        setTimeout(() => {
            this.getbidmylisting();
        }, 3000);
    }

    onChangeSearch(val: string) {
        this.selected_category = val;
        this._editproductcategoryservice.searchcategory({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.result = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    onFocused(e) {
        // do something when input is focused
    }

    pselectEvent(item) {
        this._router.navigate(['/seller/bids-and-offers/' + item.product_id]);
    }

    ponChangeSearch(val: string) {
        this._addlistingservice.productsearch({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.presult = data['searchResult'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    ponFocused(e) {
        // do something when input is focused
    }

}
