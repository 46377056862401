import { Component, OnInit } from '@angular/core';
import { HeaderOneService } from 'src/app/shared/header/header-one/header-one.service';
import { SignupService } from 'src/app/shared/components/modal/signup-modal/signup-modal.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-registration-pending',
  templateUrl: './registration-pending.component.html',
  styleUrls: ['./registration-pending.component.scss']
})
export class RegistrationPendingComponent implements OnInit {

    constructor(
        private _headerservice: HeaderOneService,
        public _signupservice: SignupService,
        private _router:Router,
    ) { }

    public user_token:string;
    public user_reg_status:string;
    public user_status:string;
    public usertype: any;
    public token: string;

    ngOnInit() {
        this.user_token = this.token = this._signupservice.gettoken();
        this.usertype = this._signupservice.getusertype();
        this.user_status = this._signupservice.getuserstatus();
        this._signupservice.tokenChanged.subscribe((response: string) => {
            this.token = response;
            this.user_token = response;
        });
        this._signupservice.userRegStatusChanged.subscribe((response: string) => {
            this.user_reg_status = response;
        });
        this._signupservice.userStatusChanged.subscribe((response: string) => {
            this.user_status = response;
        });
        if (this.token) {
            this.getuserdetails();
        }
    }

    getuserdetails() {
        this._headerservice.getuserdetails(null).subscribe((data) => {
            if (data['status'] == "success") {
                this._signupservice.updatestatus(data['data']['adminverified']);
                this._signupservice.updateregstatus(data['data']['reg_status']);
                this._signupservice.updatesellertype(data['data']['seller_type']);
                this.user_reg_status = data['data']['reg_status'];
                this.user_status = data['data']['adminverified'];
                if(this.user_reg_status == 'no' || this.user_status == 'no'){
                    //this._router.navigate(['/seller/pending']);
                }
            }
        }, (error) => {

        });
    }


    logout() {
        this._signupservice.logout();
        window.location.href = "/home";
    }

}
