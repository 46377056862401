import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AllBidandOffersService {

    constructor(private _http: HttpClient) { }

    getallbids(data) {
        return this._http.post(environment.api_url+'product/bids/all',data);
    }

    getbiddetail(data) {
        return this._http.post(environment.api_url+'product/bids/placed',data);
    }

    gettradedprice(data) {
        return this._http.post(environment.api_url+'product/bids/accepted',data);
    }

    editbiddetails(data) {
        return this._http.post(environment.api_url+'product/buyer/bids/history',data);
    }

    deletebid(data) {
        return this._http.post(environment.api_url+'product/bids/delete',data);
    }

    updatebid(data) {
        return this._http.post(environment.api_url+'product/bids/update',data);
    }

}
