<app-header-seller></app-header-seller>
<app-sidebar-seller></app-sidebar-seller>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Payments</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" [routerLink]="['/manage-payment-methods']">Manage Payment Methods</a>
						</li>
						<li class="breadcrumb-item active">Add New Payment Method</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:void(0);" [routerLink]="['/seller/assistance']" class="btn buybtn fs16 radius10 ripple ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<h3 class="f-700 fs22 txtdefault pb-10"><a href="javascript:;" class="go-back"><i class="fa fa-angle-left"></i></a> Add New Payment Method</h3>

		<div class="card cardui carduipro p-4 mb-40">
			<div class="form-container mdl-frm-lbl mb-3 mt-0">
				<div class="row d-flex align-items-center mb-40">
					<div class="col-md-5">
						<img src="assets/images/add-payment-method.png">
					</div>
					<div class="col-md-7">
						<div class="text-right">
							<a href="#" class="link-alt">Looking for other supported cards?</a>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-7 mb-30">
						<div class="floating-label mb-0">      
						  <input type="text" value="9876 8765 7654 5432" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>Card Number</label>
						</div> 
					</div>
					<div class="col-md-2 mb-30">
						<div class="floating-label mb-0">      
						  <input type="text" value="07/27" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>Expiration Date</label>
						</div> 
					</div>
					<div class="col-md-3 mb-30">
						<div class="floating-label mb-0">      
						  <input type="text" value="***" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>CVV</label>
						</div> 
					</div>

					<div class="col-md-12 mb-2">
						<div class="floating-label mb-0">      
						  <input type="text" value="Shivam Tiwari" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>Name on Card</label>
						</div> 
					</div>
				</div>
				<div class="row d-flex align-items-center mt-30">
					<div class="col-md-7">
						<i placement="top" ngbTooltip="more info goes here"><img src="assets/images/icons/shield-alt.svg" /></i>
						&nbsp;To protect your security, Equibiz does not store your CVV. <a href="javascript:;" class="fs14 link-alt">Learn More</a>
					</div>
					<div class="col-md-5">
						<div class="text-right">
						   	<button type="button" class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4">
						      Cancel
						   	</button>
						   	<button type="button" class="btn buybtn fs16 radius10 ripple px-4">
						      Add Card
						   	</button>
						</div>	
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<app-footer-seller></app-footer-seller>