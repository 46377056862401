<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain pb-4">
    <div class="container">
        <!--- HEAD  --->
        <div class="row align-items-center pinfo-head">
            <div class="col-md-6 mb-4">
                <h3 class="fs24 wt700 txtdefault">All Bids</h3>
                <nav class=" pt-2 pb-2" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                    <ol class="breadcrumb p-0 bg-transparent">
                        <li class="breadcrumb-item"><a href="javascript:void(0);" class="txtseconday"
                                [routerLink]="['/admin/dashboard']">Dashboard </a></li>
                        <li class="breadcrumb-item active" aria-current="page">All Bids</li>
                    </ol>
                </nav>
            </div>
            <div class="col-md-6  mb-4 text-md-right text-center">
				<a href="javascript:void(0);" class="mr-sm-4 btn-d-outline d-inline-block" (click)="getallbids('yes')">
					<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="export_loading"></span>
					&nbsp; Export
				</a>
				<a href="{{ export_path }}"
					*ngIf="export_path"
					class="btn btn-default"
					target="_blank"
					download="Seller.csv"
					>Download Now
				</a>
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
        </div>
        <!--- /HEAD  --->
        <div class="card cardui carduipro p-4 mb-0">
            <div class="notifications-container theme-tab">

                <div class="tab-content">
                    <!--Filters-->
                    <div class="row mt-0  report-cta">
                        <div class="col-sm-5 mb-3">
                            <div class="report-input">
                                <div class="ng-autocomplete">
                                    <ng-autocomplete [data]="result" [searchKeyword]="keyword" (selected)='selectEvent($event)'
                                        (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)'
                                        [itemTemplate]="itemTemplate" placeholder="Search Category">
                                    </ng-autocomplete>
                                    <ng-template #itemTemplate let-item>
                                        <a href="javascript:void(0)">
                                            <div class="d-flex">
                                                <span [innerHTML]="item.name"></span>
                                            </div>
                                        </a>
                                    </ng-template>
                                    <div class="search-icon"><img src="assets/images/svg/search_logo.svg"></div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-5 mb-3">
                            <div class="report-input">
                                <div class="ng-autocomplete">
                                    <ng-autocomplete [data]="presult" [searchKeyword]="keyword" (selected)='pselectEvent($event)'
                                        (inputChanged)='ponChangeSearch($event)' (inputFocused)='ponFocused($event)'
                                        [itemTemplate]="pitemTemplate" placeholder="Search Product">
                                    </ng-autocomplete>
                                    <ng-template #pitemTemplate let-item>
                                        <a href="javascript:void(0)">
                                            <div class="d-flex">
                                                <span [innerHTML]="item.name"></span>
                                            </div>
                                        </a>
                                    </ng-template>
                                    <div class="search-icon"><img src="assets/images/svg/search_logo.svg"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--End Filters-->
                    <div class="tab-content">
                        <div class="table-responsive">
                            <table class="table custom-table bids-offers-table xs">
                                <thead>
                                    <tr>
                                        <th colspan="5" class="text-center buyer"><b>Buyer</b></th>
                                        <th colspan="8" class="text-center"><b>Seller</b></th>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr>
                                        <th class="buyer">Bid Id</th>
                                        <th class="buyer">Product Name</th>
                                        <th class="buyer">Order<br>Qty</th>
                                        <th class="buyer">Best Bid Price</th>
                                        <th class="buyer">Buyer Details</th>
                                        <th>Listed<br>Price</th>
                                        <th>Min Order<br>Qty</th>
                                        <th>Total<br>Listed Qty</th>
                                        <th>Supply<br>Location</th>
                                        <th>Dispatched<br>(in Days)</th>
                                        <th width="15%">Supplier Details</th>
                                        <th width="15%">Super Seller Details</th>
                                        <th>Last Traded<br>Price</th>
                                        <th>Created Date</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <!--Empty State-->
                                    <tr *ngIf="!all_bids_records.length">
                                        <td colspan="12">
                                            <div class="row pt-40 pb-40">
                                                <div class="col-md-12 text-center">
                                                    <div class="mb-20"><img
                                                            src="/assets/images/empty-state.png"></div>
                                                    <div class="fs16">There are no records to display!</div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <!--End Empty State-->


                                    <tr *ngFor="let record of all_bids_records; let i = index;"
                                        [ngClass]="{'buyer-mybid': record.is_buyer == true}">
                                        <td>{{ record.bid_unique_id }}</td>
                                        <td><a href="javascript:;"
                                                [routerLink]="['/admin/all-bids/'+record.product_id]">{{
                                                record.product_name }}</a></td>
                                        <td>{{ record.order_qty }}</td>
                                        <td>{{ record.counter_offer?record.counter_amount:record.bid_price }}</td>
                                        <td>{{ record.buyer_name }}<br/>{{ record.buyer_mobile }}<br/>{{ record.buyer_email }}</td>
                                        <td>{{ record.listed_price }}</td>
                                        <td>{{ record.min_qty }}</td>
                                        <td>{{ record.listed_qty }}</td>
                                        <td>{{ record.supply_location }}</td>
                                        <td>{{ record.dispached_in }}</td>
                                        <td>{{ record.seller_name }}<br/>{{ record.seller_mobile }}<br/>{{ record.seller_email }}</td>
                                        <td>{{ record.superseller_name }}<br/>{{ record.superseller_mobile }}<br/>{{ record.superseller_email }}</td>
                                        <td>
                                            <div class="cursor" (click)="open(content, record.bid_id)"
                                                *ngIf="record.last_traded_price != 'NA'">{{
                                                record.last_traded_price }}
                                                <i class="arrow-down-line"></i>
                                            </div>
                                        </td>
                                        <td>{{ record.created_date }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="all_bids_pagination.totalPages> 1">
            <div class="col-md-6">
                <div class="text-left pl-3">
                    <nav class="mt-3 tbpg rows-per-page">
                        <div>Rows per page:</div>
                        <select class="select-box" [(ngModel)]="page_per_view"
                            (change)="setpageperview()">
                            <option *ngFor="let option of page_per_view_options"
                                [ngValue]="option.value">{{option.name}}</option>
                        </select>
                    </nav>
                </div>
            </div>
            <div class="col-md-6" *ngIf="all_bids_pagination.pages && all_bids_pagination.pages.length">
                <div class="text-right pr-3">
                    <nav class="mt-3 tbpg">
                        <ul class="pagination align-items-center">
                            <li class="page-item"
                                [ngClass]="{disabled:all_bids_pagination.currentPage === 1}"><a
                                    class="page-link previcon" href="javascript:;"
                                    (click)="setpage(1)"></a></li>

                            <li class="page-item"
                                [ngClass]="{disabled:all_bids_pagination.currentPage === 1}"><a
                                    class="page-link sprevicon" href="javascript:;"
                                    (click)="setpage(all_bids_pagination.currentPage - 1)"></a></li>

                            <li class="page-item" i *ngFor="let page of all_bids_pagination.pages"
                                [ngClass]="{active:all_bids_pagination.currentPage === page}"><a
                                    class="page-link" href="javascript:;"
                                    (click)="setpage(page)">{{page}}</a></li>

                            <li
                                *ngIf="(all_bids_pagination.totalPages - all_bids_pagination.startPage) > 5 ">
                                <a (click)="setpage(all_bids_pagination.startPage + 5)">...</a>
                            </li>
                            <li
                                *ngIf="(all_bids_pagination.totalPages - all_bids_pagination.startPage) > 5">
                                <a
                                    (click)="setpage(all_bids_pagination.totalPages)">{{all_bids_pagination.totalPages}}</a>
                            </li>

                            <li class="page-item"
                                [ngClass]="{disabled:all_bids_pagination.currentPage === all_bids_pagination.totalPages}">
                                <a class="page-link snexticon" href="javascript:;"
                                    (click)="setpage(all_bids_pagination.currentPage + 1)"></a>
                            </li>
                            <li class="page-item"
                                [ngClass]="{disabled:all_bids_pagination.currentPage === all_bids_pagination.totalPages}">
                                <a class="page-link nexticon" href="javascript:;"
                                    (click)="setpage(all_bids_pagination.totalPages)"></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>


    <!-- Last 10 Traded Price MODAL -->
<ng-template #content let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>
    <div class="modal-body pb-3">
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead">
                    <h4 class="txtdefault text-center wt700 fs22 mb-20">Last 10 Traded Price</h4>
                </div>
                <div class="accept-bid">
                    <table class="table custom-table xs mb-0">
                        <thead>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Bid Time</th>
                                <th>Order Quantity</th>
                                <th>Last Traded price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let record of last_traded_records; let k = index;">
                                <td>{{ k+1 }}</td>
                                <td>{{ record.bid_time }}</td>
                                <td>{{ record.order_qty }}</td>
                                <td>{{ record.traded_price }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- /Last 10 Traded Price MODAL -->