import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { SellerHelpService } from './help.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

    public help_seller_detail:any = {};
    public seller_id:string;
    constructor(
        private _helpsellerservice: SellerHelpService,
        private _toastr: ToasterService,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.route.queryParams
            .subscribe(params => {
                if(params['s_id'] !== undefined){
                    this.seller_id = params['s_id'];
                }
                this.getmoresellerdetail();
            }
        );
    }

    getmoresellerdetail(){
        this._helpsellerservice.getsellerhelp({s_id: this.seller_id}).subscribe((data) => {
            if(data['status'] == "success"){
                this.help_seller_detail = data['record'];
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

}
