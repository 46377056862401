import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RegistrationAuthGuard implements CanActivate {
	constructor(private auth: AuthService, private router: Router) {

	}
  canActivate(){
  	if(this.auth.isLoggedIn() && (this.auth.loggedInUserType(1) || this.auth.loggedInUserType(2))){
  		return true;
  	}
  	this.router.navigate(['/home']);
    return false;
  }
  
}
