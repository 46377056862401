import { Component, OnInit, Input, HostListener, ViewChild } from '@angular/core';
import { AddNewProductService } from './add-new-product.service';
import { EncrDecrService } from '../../helpers/EncrDecrService';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ViewProductDetailsService } from '../view-product-details/view-product.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { PreviewComponent } from 'src/app/shared/components/modal/preview/preview.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
    selector: 'app-add-new-product',
    templateUrl: './add-new-product.component.html',
    styleUrls: ['./add-new-product.component.scss']
})
export class AddNewProductComponent implements OnInit {

    @ViewChild("preview") Preview: PreviewComponent;

    public hideTechnicalFilter:boolean = false;

    public finishLoading:boolean = false;
    public basicLoading:boolean = false;
    public technicalLoading:boolean = false;
    public sellerLoading:boolean = false;
    public pricingLoading:boolean = false;
    public variantLoading:boolean = false;


    productForm: FormGroup;
    sellerTypeForm: FormGroup;
    pricingForm: FormGroup;

    imageactive = 1;
    infoactive = 95;
    variantactive = 1;
    disabled = true;
    example = false;
    public seller_logo_link:string;
    public product_template:string = '';

    public hsns: any = [];
    public child:any = {name:'', image:'', status: 'active'};
    public selected_child:any = {};
    public selected_hsn_id: string;
    public add_type: string = 'add';
    public defaultImage = 'assets/images/admin/front.png';
    public selectedCategory: string;
    public slug: string;
    public distributor_link: string;
    public uniqueId: string = '';
    public category_txt: string;
    public variant_types: any = [];
    public variants: any = [];
    public selected_variant_types: any = [];
    public active_variant_id: string = '';
    public saved_variants: any = [];
    public common_info: any = {};

    editor_config: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '15rem',
        minHeight: '0',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter text here...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '',
        fonts: [
            {class: 'arial', name: 'Arial'},
            {class: 'times-new-roman', name: 'Times New Roman'},
            {class: 'calibri', name: 'Calibri'},
            {class: 'comic-sans-ms', name: 'Comic Sans MS'}
        ],
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ],
        // uploadUrl: 'v1/image',
        // upload: (file: File) => { },
        // uploadWithCredentials: false,
        sanitize: true,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
            [],
            []
        ]
    };

    public return_summary:string = '';

    public childsDetails:any = [];

    public template_status = false;
    public logistics_units = [
        {
            value: 'buyer',
            label: 'From Buyer'
        },
        {
            value: 'seller',
            label: 'From Seller'
        }
    ];
    public convenience_units = [
        {
            value: 'both',
            label: 'From Both'
        },
        {
            value: 'buyer',
            label: 'From Buyer'
        },
        {
            value: 'seller',
            label: 'From Seller'
        }
    ];

    public convenience_charges_units = [
        {
            value: 'fixed',
            label: 'in INR PER PCS'
        },
        {
            value: 'fixed_total',
            label: 'in INR (on total order value)'
        },
        {
            value: 'percentage',
            label: '% of Deal Value'
        }
    ];

    //Seller Type
    public seller_type = 'registered-brand-owner';
    public sellertype_logo_name = '';
    public sellertype_logo_image: any;

    public sellertype_trademark_name = '';
    public sellertype_trademark_image: any;

    public sellertype_device_name = '';
    public sellertype_device_image: any;

    public sellertype_distributor_name = '';
    public sellertype_distributor_image: any;

    public sellertype_address_name = '';
    public sellertype_address_image: any;

    //Product Description
    public bannerFiles: string[] = [];
    public updatedBannerFiles = [];

    //Pricing


    //Product Images
    productImages = [];

    productAttributes = [];
    newAttributes = [];

    changesellertype(event) {
        this.seller_type = this.sellerTypeForm.get('seller_type').value;
    }

    newattribute() {
        return {
            type_info: '',
            attribute_value: '',
            label_name: '',
            attribute_meta: [],
            attribute_value_addition: '',
            filter: 'no'
        }
    }
    attributeadd() {
        this.newAttributes.push(this.newattribute());
    }
    saveattributes(type = null) {

        var i = 0
        for (i; i < this.newAttributes.length; i++) {
            var type = this.newAttributes[i].type_info;
            var label_name = this.newAttributes[i].label_name;
            var value = this.newAttributes[i].attribute_value;
            if (!type) {
                this._toastr.error('Please select type for all attributes');
                return false;
            }

            if (!label_name) {
                this._toastr.error('Please enter label name for all attributes');
                return false;
            }

            if (!value) {
                this._toastr.error('Please enter value for all attributes');
                return false;
            }
        }

        this.finishLoading = true;
        this.technicalLoading = true;


        // let data = this.newAttributes;
        // var i = 0
        // for(i; i < this.newAttributes.length; i++){
        //     var type = this.newAttributes[i].type_info;
        //     var label_name = this.newAttributes[i].label_name;
        //     var value = this.newAttributes[i].attribute_value;
        //     if(type && label_name && value){
        //         data.push(this.newAttributes[i]);
        //     }
        // }
        let attribute = {
            variant_id: this.active_variant_id,
            uniqueId: this.uniqueId,
            attributes: this.newAttributes
        };
        this._addnewproductservice.saveattributes(attribute).subscribe((data) => {
            if (data['status'] == "success") {
                if (type != 'finish') {
                    this.newAttributes = [];
                    this.modalService.dismissAll();
                    this.getattributes();
                }
                this._toastr.success('Technical details has been updated successfully.')
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
            this.finishLoading = false;
            this.technicalLoading = false;
        }, (error) => {
            this.finishLoading = false;
            this.technicalLoading = false;
        });
    }

    savechild(){
        let data: any = new FormData();
        data.append('name', this.child.name);
        data.append('uniqueId', this.uniqueId);
        data.append('document', this.child.image);
        data.append('status', this.child.status);
        this._addnewproductservice.savechild(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this.child.name = '';
                this.child.image = '';
                this.child.status = 'active';
                this.getproductbasic();
                this._toastr.success('New child product added successfully.')
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        }, (error) => {
            this._toastr.error(data['message']);
        });
    }

    updatechild(){
        let data: any = new FormData();
        data.append('name', this.selected_child.name);
        data.append('id', this.selected_child.id);
        data.append('uniqueId', this.uniqueId);
        data.append('document', this.selected_child.new_image);
        data.append('status', this.selected_child.status);
        this._addnewproductservice.updatechild(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this.getproductbasic();
                this._toastr.success('Child product details updated successfully.')
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        }, (error) => {
            this._toastr.error(data['message']);
        });
    }

    uploadchildimage(event){
        this.child.image = event.target.files[0];
    }

    updatechildimage(event){
        this.selected_child.new_image = event.target.files[0];
    }

    constructor(
        private modalService: NgbModal,
        private _addnewproductservice: AddNewProductService,
        private _EncrDecr: EncrDecrService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private _router: Router,
        private _toastr: ToastrService,
        private _viewproductdetails: ViewProductDetailsService,
    ) { }

    flactive = 13;
    ngOnInit() {
        this.uniqueId = this.route.snapshot.paramMap.get('uniqueId');
        this.attributeadd();
        this.getvariant();
        //this.getvarianttype();
        //this.getsellertype();
        //this.getpricing();

        this.productForm = this.fb.group({
            product_name: this.fb.control(null, [Validators.required]),
            brand_name: this.fb.control(null, [Validators.required]),
            model_number: this.fb.control(null, [Validators.required]),
            message: this.fb.control(null, []),
            key_features: this.fb.control(null, []),
            return_period: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            return_period_unit: this.fb.control(null, []),
            // return_summary: this.fb.control(null, []),
        });

        this.sellerTypeForm = this.fb.group({
            seller_type: this.fb.control(null, [Validators.required]),
            seller_brand_name: this.fb.control(null),
            brand_website_link: this.fb.control(null),
            isCrs: this.fb.control('yes'),
            seller_crs_number: this.fb.control(null),
            brand_authorization: this.fb.control(false),
            registered_trademark_type: this.fb.control('registered'),
            registration_trademark_number: this.fb.control(null),
            trademark_type: this.fb.control(null),
            sell_type: this.fb.control(null),
            other_sellers: this.fb.control(null),
            made_in_india: this.fb.control(null),
            country_of_origin: this.fb.control(null),
            importer_type: this.fb.control(null),
            iec_number: this.fb.control(null),
            manufacturing: this.fb.control(null),
            manufacturing_address: this.fb.control(null),
            logoSource: this.fb.control(null),
            trademarkSource: this.fb.control(null),
            deviceSource: this.fb.control(null),
            distributorSource: this.fb.control(null),
            addressProofSource: this.fb.control(null),
        });

        this.pricingForm = this.fb.group({
            operating_price: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            retail_price: this.fb.control(null, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            upper_bracket: this.fb.control(null, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            upper_bracket_unit: this.fb.control(null, [Validators.required]),
            lower_bracket: this.fb.control(1100, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            lower_bracket_percentage: this.fb.control(null),
            hsn: this.fb.control(null, [Validators.required]),
            logistics_wholesale_standard: this.fb.control(null),
            logistics_wholesale_standard_unit: this.fb.control(null, [Validators.required]),
            logistics_wholesale_express: this.fb.control(null),
            logistics_wholesale_express_unit: this.fb.control(null),
            logistics_bulk_standard: this.fb.control(null),
            logistics_bulk_standard_unit: this.fb.control(null, [Validators.required]),
            logistics_bulk_express: this.fb.control(null),
            logistics_bulk_express_unit: this.fb.control(null, [Validators.required]),
            convenience_wholesale: this.fb.control(null, [Validators.required]),
            convenience_wholesale_from: this.fb.control(null, [Validators.required]),
            convenience_wholesale_type: this.fb.control(null, [Validators.required]),
            convenience_bulk: this.fb.control(null, [Validators.required]),
            convenience_bulk_from: this.fb.control(null, [Validators.required]),
            convenience_bulk_type: this.fb.control(null, [Validators.required]),
            insurance_charges: this.fb.control(null, [Validators.required]),
            bid_upper_moq: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            bid_lower_moq: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            marketplace_wholesaler: this.fb.control(false),
            marketplace_bulk: this.fb.control(false),
            marketplace_bid: this.fb.control(false),
        });
    }

    get pf() { return this.productForm.controls; }
    get prf() { return this.pricingForm.controls; }

    getattributes() {
        this._addnewproductservice.getattributes({ uniqueId: this.uniqueId, variant_id: this.active_variant_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.productAttributes = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            } else if (data['status'] == "desc_required") {
                this._toastr.error('please save product information.');
                this.infoactive = 95;
            }
        });
    }

    deleteattribute(id) {
        this._addnewproductservice.deleteattribute({ uniqueId: this.uniqueId, variant_id: this.active_variant_id, attribute_id: id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.getattributes();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    updateattribute(attribute, type) {
        if (type == 'save') {
            this._addnewproductservice.updateattribute({ uniqueId: this.uniqueId, variant_id: this.active_variant_id, attribute: attribute }).subscribe((data) => {
                if (data['status'] == "success") {
                    this.getattributes();
                } else if (data['status'] == "error") {
                    this._toastr.error(data['message']);
                }
            });
        } else {
            attribute.isEnabled = true;
        }

    }

    getvarianttype() {
        this._addnewproductservice.getvarianttype({ uniqueId: this.uniqueId }).subscribe((data) => {
            if (data['status'] == "success") {
                this.variant_types = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getproductbasic() {
        this._addnewproductservice.getproductbasic({ uniqueId: this.uniqueId, variant_id: this.active_variant_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.category_txt = data['category_txt'];
                this.add_type = data['add_type'];
                var records = data['records'];
                this.template_status = records.template_status;
                if (Object.keys(records).length > 0) {
                    this.productForm.patchValue({
                        product_name: records.product_name,
                        brand_name: records.brand_name,
                        model_number: records.model_number,
                        message: records.message,
                        key_features: records.key_features,
                        return_period: records.return_period,
                        return_period_unit: records.return_period_unit,
                    });
                    this.return_summary = records.return_summary;
                    this.updatedBannerFiles = records.bannerImage;
                    this.selectedBrandId = records.brand_id;
                    this.childsDetails = [];
                    if(data['child'] == true){
                        this.childsDetails = data['child_records'];
                    }

                    //Common info
                    this.common_info.product_name = records.product_name;
                    this.common_info.brand_name = records.brand_name;
                    this.common_info.model_number = records.model_number;
                    this.common_info.category_name = data['category_name'];
                    this.common_info.name = records.name;

                }
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getvariant() {
        this._addnewproductservice.getvariant({ uniqueId: this.uniqueId }).subscribe((data) => {
            if (data['status'] == "success") {

                this.product_template = data['product_template'];
                this.saved_variants = data['varients'];
                this.variants = data['records'];
                for(let i = 0; i < this.variants.length; i++){
                    var array = this.variants[i].types;
                    array.sort((a,b) =>  a.sorting - b.sorting)
                    this.variants[i].types = array;
                }
                if (this.saved_variants.length > 0) {
                    this.active_variant_id = this.saved_variants[0].id;
                }

                setTimeout(() => {
                    this.getvarianttype();
                }, 2000);

                if(this.active_variant_id){
                    this.getimages();
                    this.getproductbasic();
                }
                this.modalService.dismissAll();
                //this._router.navigate(['/admin/products/add/'+this.uniqueId]);
                //this.getattributes();
               

                
                
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
                this._router.navigate(['/admin/products/add/'+this.uniqueId]);
            }
        });
    }

    getimages() {
        this._addnewproductservice.getimages({ uniqueId: this.uniqueId, variant_id: this.active_variant_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.productImages = data['records'];
                this.slides = [];
                for (let i = 0; i < this.productImages.length; i++) {
                    if (this.productImages[i].value != '') {
                        this.slides.push({ img: this.productImages[i].value });
                    }
                }
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getsellertype() {
        this._addnewproductservice.getsellertype({ uniqueId: this.uniqueId, variant_id: this.active_variant_id }).subscribe((data) => {
            if (data['status'] == "success") {
                var records = data['records'];
                this.sellerTypeForm.reset(this.sellerTypeForm.value);
                if (Object.keys(records).length > 0) {
                    this.sellerTypeForm.patchValue({
                        seller_type: records.seller_type,
                        seller_brand_name: records.seller_brand_name,
                        brand_website_link: records.brand_website_link,
                        isCrs: records.isCrs,
                        seller_crs_number: records.seller_crs_number,
                        brand_authorization: records.brand_authorization,
                        registered_trademark_type: records.registered_trademark_type,
                        registration_trademark_number: records.registration_trademark_number,
                        trademark_type: records.trademark_type,
                        sell_type: records.sell_type,
                        other_sellers: records.other_sellers,
                        made_in_india: records.made_in_india,
                        country_of_origin: records.country_of_origin,
                        importer_type: records.importer_type,
                        iec_number: records.iec_number,
                        manufacturing: records.manufacturing,
                        manufacturing_address: records.manufacturing_address,
                        logoSource: records.logoSource,
                        trademarkSource: records.trademarkSource,
                        deviceSource: records.deviceSource,
                        distributorSource: records.distributorSource,
                        addressProofSource: records.addressProofSource,
                    });
                    this.distributor_link = records.seller_distributor;
                    this.seller_logo_link = records.seller_logo;
                }
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            } else if (data['status'] == "desc_required") {
                this._toastr.error('please save product information.');
                this.infoactive = 95;
            }
        });
    }

    getpricing() {
        this._addnewproductservice.getpricing({ uniqueId: this.uniqueId, variant_id: this.active_variant_id }).subscribe((data) => {
            if (data['status'] == "success") {
                var records = data['records'];
                this.pricingForm.reset(this.pricingForm.value);
                if (Object.keys(records).length > 0) {
                    this.pricingForm.patchValue({
                        operating_price: records.operating_price,
                        retail_price: records.retail_price,
                        upper_bracket: records.upper_bracket,
                        upper_bracket_unit: records.upper_bracket_unit,
                        lower_bracket: records.lower_bracket,
                        lower_bracket_percentage: records.lower_bracket_percentage,
                        hsn: records.hsn.name,

                        convenience_bulk: records.convenience_bulk,
                        convenience_bulk_from: records.convenience_bulk_from,
                        convenience_bulk_type: records.convenience_bulk_type,
                        convenience_wholesale: records.convenience_wholesale,
                        convenience_wholesale_from: records.convenience_wholesale_from,
                        convenience_wholesale_type: records.convenience_wholesale_type,
                        insurance_charges: records.insurance_charges,
                        //logistics_bulk_express: records.logistics_bulk_express,
                        logistics_bulk_express_unit: records.logistics_bulk_express_unit,
                        //logistics_bulk_standard: records.logistics_bulk_standard,
                        logistics_bulk_standard_unit: records.logistics_bulk_standard_unit,
                        //logistics_wholesale_express: records.logistics_wholesale_express,
                        logistics_wholesale_express_unit: records.logistics_wholesale_express_unit,
                        //logistics_wholesale_standard: records.logistics_wholesale_standard,
                        logistics_wholesale_standard_unit: records.logistics_wholesale_standard_unit,


                        bid_upper_moq: records.bid_upper_moq,
                        bid_lower_moq: records.bid_lower_moq,
                        marketplace_wholesaler: records.marketplace_wholesaler,
                        marketplace_bulk: records.marketplace_bulk,
                        marketplace_bid: records.marketplace_bid,
                    });
                    this.selected_hsn_id = records.hsn.code;
                }
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            } else if (data['status'] == "desc_required") {
                this._toastr.error('please save product information.');
                this.infoactive = 95;
            }
        });
    }

    getproductdetails(type) {
        if (type == 'basic') {
            this.getproductbasic();
        } else if (type == 'technical') {
            this.infoactive = 96;
            this.getattributes();
        } else if (type == 'seller') {
            this.infoactive = 97;
            this.getsellertype();
        } else if (type == 'pricing') {
            this.getpricing();
        }
    }

    changeactivevariant(item) {
        this.active_variant_id = item.id;
        this.infoactive = 95;
        this.getimages();
        this.getproductbasic();
    }

    //Product Description related function
    bannerUpload(event: any) {
        for (var i = 0; i < event.target.files.length; i++) {
            this.bannerFiles.push(event.target.files[i]);
        }
    }

    saveproductdescription(type = null) {
        if (!this.selectedBrandId) {
            this._toastr.error('Please select valid brand name');
            return false;
        }
        this.finishLoading = true;
        this.basicLoading = true;
        let data: any = new FormData();
        data.append('uniqueId', this.uniqueId);
        data.append('variantId', this.active_variant_id);
        data.append('product_name', this._EncrDecr.set(this.productForm.get('product_name').value));
        data.append('brand_id', this.selectedBrandId);
        data.append('brand_name', this._EncrDecr.set(this.selectedBrandName));
        data.append('model_number', this._EncrDecr.set(this.productForm.get('model_number').value));
        data.append('message', this._EncrDecr.set(this.productForm.get('message').value));
        data.append('key_features', this._EncrDecr.set(this.productForm.get('key_features').value));
        data.append('return_period', this._EncrDecr.set(this.productForm.get('return_period').value));

        data.append('return_period_unit', this._EncrDecr.set(this.productForm.get('return_period_unit').value));
        data.append('return_summary', this._EncrDecr.set(this.return_summary));

        for (var i = 0; i < this.bannerFiles.length; i++) {
            data.append("file[]", this.bannerFiles[i]);
        }
        this._addnewproductservice.saveproductdescription(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.bannerFiles = [];
                this.active_variant_id = data['product_id'];
                if (type != 'finish' && this.product_template == 'normal') {
                    this.infoactive = 96;
                    this.getattributes();
                }else{
                    this.infoactive = 99;
                    this.getproductbasic();
                }
                //this.add_type = 'edit';
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
            this.finishLoading = false;
            this.basicLoading = false;
        }, (error) => {
            this.finishLoading = false;
            this.basicLoading = false;
        });
    }

    deleteBanner(index) {
        this._addnewproductservice.deletebanner({ uniqueId: this.uniqueId, variant_id: this.active_variant_id, index: index }).subscribe((data) => {
            if (data['status'] == "success") {
                this.updatedBannerFiles.splice(index, 1);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    updatebanner(event, i) {
        const file = event.target.files;
        let data: any = new FormData();
        data.append("uniqueId", this.uniqueId);
        data.append("variant_id", this.active_variant_id);
        data.append("index", i);
        data.append('document', file[0]);
        this._addnewproductservice.updatebanner(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.getproductbasic();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    //Variant Types
    newvariant() {
        return {
            exist_product_variant: '',
            name: '',
            types: []
        }
    }

    addvariant() {
        this.variants.push(this.newvariant());
        this.addvarianttype(this.variants.length - 1);
    }

    addvarianttype(index) {
        for (let i = 0; i < this.variant_types.length; i++) {
            if (this.variant_types[i].selected) {
                this.variants[index].types.push({
                    exist_product_variant_type: '',
                    id: this.variant_types[i].id,
                    type_info: this.variant_types[i].type_info,
                    variant_value: this.variant_types[i].variant_value,
                    label_name: this.variant_types[i].label_name,
                    variant_meta: this.variant_types[i].variant_meta,
                    variant_value_addition: this.variant_types[i].variant_value_addition,
                    filter: this.variant_types[i].filter,
                    selected: true,
                    sorting: this.variant_types[i].sorting
                })
            }
        }
    }

    updatevarianttype(event, item) {
        if (event.target.checked) {
            item.selected = true;
            this.updatetype(item.id, item.type_info, item.variant_value, item.label_name, item.variant_meta, item.variant_value_addition, item.sorting, item.filter);
        } else {
            item.selected = false;
            this.removetype(item.id);
        }
    }



    updatetype(id, type_info, variant_value, label_name, variant_meta, variant_value_addition, sorting, filter) {
        for (let i = 0; i < this.variants.length; i++) {
            this.variants[i].types.push({
                exist_product_variant_type: '',
                id: id,
                type_info: type_info,
                variant_value: variant_value,
                label_name: label_name,
                variant_meta: variant_meta,
                variant_value_addition: variant_value_addition,
                filter: filter,
                selected: true,
                sorting:sorting
            });

            var array = this.variants[i].types;
            array.sort((a,b) =>  a.sorting - b.sorting)
            this.variants[i].types = array;

        }



    }

    removetype(id) {
        for (let i = 0; i < this.variants.length; i++) {
            var types = this.variants[i].types;
            for (let k = 0; k < types.length; k++) {
                if (types[k].id == id) {
                    this.variants[i].types.splice(k, 1);
                }

            }
        }
    }

    deletevariant(variant, i) {
        if (variant.exist_product_variant) {
            this._addnewproductservice.deletevariant({ variant_id: variant.exist_product_variant, uniqueId: this.uniqueId }).subscribe((data) => {
                if (data['status'] == "success") {
                    this._toastr.success('Variant has been deleted successfully.');
                    this.getvariant();
                } else if (data['status'] == "error") {
                    this._toastr.error(data['message']);
                }
            });
        } else {
            this.variants.splice(i, 1);
        }
    }

    savevariant() {
        this.variantLoading = true;
        this._addnewproductservice.savevariant({ variants: this.variants, uniqueId: this.uniqueId }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Product variant details has been updated successfully.');
                this.getvariant();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
            this.variantLoading = false;
        }, (error) => {
            this.variantLoading = false;
        });
    }


    //Images File Upload
    uploadImage(event, item) {
        const file = event.target.files;
        let data: any = new FormData();
        data.append("uniqueId", this.uniqueId);
        data.append("variant_id", this.active_variant_id);
        data.append("document_type", item.key.toLowerCase());
        data.append('document', file[0]);
        this._addnewproductservice.uploadimage(data).subscribe((data) => {
            if (data['status'] == "success") {
                item.value = data['image_path'];
                item.default = false;
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            } else if (data['status'] == "desc_required") {
                this._toastr.error('please save product information.');
                this.infoactive = 95;
            }
        });
    }

    //Seller Type
    savesellertype(type = null) {
        this.finishLoading = true;
        this.sellerLoading = true;
        let data: any = new FormData();
        data.append('uniqueId', this.uniqueId);
        data.append('variant_id', this.active_variant_id);
        data.append('seller_type', this._EncrDecr.set(this.sellerTypeForm.get('seller_type').value));
        data.append('seller_brand_name', this._EncrDecr.set(this.sellerTypeForm.get('seller_brand_name').value));
        data.append('seller_crs_number', this._EncrDecr.set(this.sellerTypeForm.get('seller_crs_number').value));
        data.append('brand_website_link', this._EncrDecr.set(this.sellerTypeForm.get('brand_website_link').value));
        data.append('isCrs', this._EncrDecr.set(this.sellerTypeForm.get('isCrs').value));
        data.append('brand_authorization', this._EncrDecr.set(this.sellerTypeForm.get('brand_authorization').value));
        data.append('registered_trademark_type', this._EncrDecr.set(this.sellerTypeForm.get('registered_trademark_type').value));
        data.append('registration_trademark_number', this._EncrDecr.set(this.sellerTypeForm.get('registration_trademark_number').value));
        data.append('trademark_type', this._EncrDecr.set(this.sellerTypeForm.get('trademark_type').value));
        data.append('sell_type', this._EncrDecr.set(this.sellerTypeForm.get('sell_type').value));
        data.append('other_sellers', this._EncrDecr.set(this.sellerTypeForm.get('other_sellers').value));
        data.append('made_in_india', this._EncrDecr.set(this.sellerTypeForm.get('made_in_india').value));
        data.append('country_of_origin', this._EncrDecr.set(this.sellerTypeForm.get('country_of_origin').value));
        data.append('importer_type', this._EncrDecr.set(this.sellerTypeForm.get('importer_type').value));
        data.append('iec_number', this._EncrDecr.set(this.sellerTypeForm.get('iec_number').value));
        data.append('manufacturing', this._EncrDecr.set(this.sellerTypeForm.get('manufacturing').value));
        data.append('manufacturing_address', this._EncrDecr.set(this.sellerTypeForm.get('manufacturing_address').value));
        data.append('file_logo', this.sellerTypeForm.get('logoSource').value);
        data.append('file_trademark', this.sellerTypeForm.get('trademarkSource').value);
        data.append('file_device', this.sellerTypeForm.get('deviceSource').value);
        data.append('file_distributor', this.sellerTypeForm.get('distributorSource').value);
        data.append('file_address', this.sellerTypeForm.get('addressProofSource').value);
        this._addnewproductservice.savesellertype(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.distributor_link = data['distributor_link'];
                if (type != 'finish') {
                    this.infoactive = 98;
                    this.getpricing();
                }
                this._toastr.success('Seller type has been added successfully.');
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
            this.finishLoading = false;
            this.sellerLoading = false;
        }, (error) => {
            this.finishLoading = false;
            this.sellerLoading = false;
        });
    }

    onFileChange(event, type) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            if (type == 'logo') {
                this.sellerTypeForm.patchValue({
                    logoSource: file
                });
                this.sellertype_logo_name = file.name;
                const reader = new FileReader();
                reader.onload = e => this.sellertype_logo_image = reader.result;
                reader.readAsDataURL(file);
            } else if (type == 'trademark') {
                this.sellerTypeForm.patchValue({
                    trademarkSource: file
                });
                this.sellertype_trademark_name = file.name;
                const reader = new FileReader();
                reader.onload = e => this.sellertype_trademark_image = reader.result;
                reader.readAsDataURL(file);
            } else if (type == 'device') {
                this.sellerTypeForm.patchValue({
                    deviceSource: file
                });
                this.sellertype_device_name = file.name;
                const reader = new FileReader();
                reader.onload = e => this.sellertype_device_image = reader.result;
                reader.readAsDataURL(file);
            } else if (type == 'distributor') {
                this.sellerTypeForm.patchValue({
                    distributorSource: file
                });
                this.sellertype_distributor_name = file.name;
                const reader = new FileReader();
                reader.onload = e => this.sellertype_distributor_image = reader.result;
                reader.readAsDataURL(file);
            } else if (type == 'address') {
                this.sellerTypeForm.patchValue({
                    addressProofSource: file
                });
                this.sellertype_address_name = file.name;
                const reader = new FileReader();
                reader.onload = e => this.sellertype_address_image = reader.result;
                reader.readAsDataURL(file);
            }
        }
    }


    //Pricing & Marketplace
    savepricing(type = null) {
        if (!this.selected_hsn_id) {
            this._toastr.error('Please select hsn number.')
            return false;
        }
        this.finishLoading = true;
        this.pricingLoading = true;
        let data = this.pricingForm.value;
        data.uniqueId = this.uniqueId;
        data.variant_id = this.active_variant_id;
        data.selected_hsn_id = this.selected_hsn_id;
        this._addnewproductservice.savepricing(data).subscribe((data) => {
            if (data['status'] == "success") {
                if (type == 'finish') {
                    this.changestatus(type);
                } else {
                    this.getpricing();
                    this.openPreview(this.uniqueId, this.active_variant_id);
                    this._toastr.success('Pricing details has been added successfully.');
                }
                // setTimeout( () => {
                //     this._router.navigate(['/admin/product-listing']);
                // }, 3000)
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
            this.finishLoading = false;
            this.pricingLoading = false;
        }, (error) => {
            this.finishLoading = false;
            this.pricingLoading = false;
        });
    }


    config = {
        labelField: 'label',
        valueField: 'value',
    };

    numericFieldArray = [
        {
            label: 'Miligrams',
            value: 'miligrams',
        },
        {
            label: 'Grams',
            value: 'grams',
        },
        {
            label: 'Kilograms',
            value: 'kilograms',
        },
        {
            label: 'Tons',
            value: 'tons',
        },
        {
            label: 'Milimeters',
            value: 'milimeters',
        },
        {
            label: 'Centimeters',
            value: 'centimeters',
        },
    ];

    yesNoArray = [
        {
            label: 'Yes',
            value: 'yes'
        },
        {
            label: 'No',
            value: 'no'
        }
    ];

    currency_data = [
        {
            label: 'in ₹',
            value: 'rupee'
        },
        {
            label: 'in %',
            value: 'percentage'
        }
    ];
    regdata = [
        {
            label: 'Registered Brand Owner',
            value: 'registered-brand-owner'
        },
        {
            label: 'Generic Brand',
            value: 'generic-brand'
        },
        {
            label: 'Any Seller',
            value: 'any-seller'
        }
    ]
    monthdata = [
        {
            label: 'In Days',
            value: 'days'
        },
        {
            label: 'In Months',
            value: 'month'
        },
        {
            label: 'In Year',
            value: 'year'
        }
    ];

    openWindowCustomClass(content) {
        this.newAttributes = [];
        this.attributeadd();
        this.modalService.open(content, { windowClass: 'modal-mdsz' });
    }

    openWindowCustomClassadd(content) {
        this.modalService.open(content, { windowClass: 'modal-mdsz' });
    }

    openWindowCustomClassedit(content, child:any) {
        this.selected_child = child;
        this.modalService.open(content, { windowClass: 'modal-lgsz' });
    }

    changestatus(type) {
        this._addnewproductservice.templatestatus({ uniqueId: this.uniqueId, type: type, template_status: this.template_status }).subscribe((data) => {
            if (data['status'] == "success") {
                if (type == 'remove') {
                    this._toastr.success('Product template has been removed from listing.');
                    this._router.navigate(['/admin/product-listing']);
                } else if (type == 'finish') {
                    this._toastr.success('Product template status has been approved.');
                    this._router.navigate(['/admin/product-listing']);
                } else {
                    this._toastr.success('Product template status has been changed.');
                }
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    savedetails(type) {
        if (this.infoactive == 95) {
            this.saveproductdescription(type);
        } else if (this.infoactive == 96) {
            this.saveattributes(type);
        } else if (this.infoactive == 97) {
            this.savesellertype(type);
        } else if (this.infoactive == 98) {
            this.savepricing(type);
        }
    }


    slides = [];

    closeResult = '';

    ratingclassstatus: boolean = false;
    ratingtglclass() {
        this.ratingclassstatus = !this.ratingclassstatus;
    }

    showcomperr: boolean = false;
    showcompmsg() {
        this.showcomperr = true;
    }

    imagesSlider = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: ".thumbs",
    };

    thumbnailsSlider = {
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: true,
        focusOnSelect: true,
        asNavFor: ".feedback",
        //prevArrow:'.client-thumbnails .prev-arrow',
        // nextArrow:'.client-thumbnails .next-arrow',
    };



    open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true, }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    openeditcategory(editcategory) {
        this.modalService.open(editcategory, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    openremoveproduct(removeProduct) {
        this.modalService.open(removeProduct, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    keyword = 'name';
    brandKeyword = 'name';
    result = [];
    brands = [];
    selectedBrandId = '';
    selectedBrandName = '';

    selectEvent(item) {
        this._viewproductdetails.updatecategory({ uniqueId: this.uniqueId, c_id: item.c_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this._toastr.success('Product template category has been changed.');
                this.getproductbasic();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }


    onChangeSearch(val: string) {
        this._viewproductdetails.searchcategory({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.result = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    onFocused(e) {
        // do something when input is focused
    }


    selectBrandEvent(item) {
        this.selectedBrandId = item.id;
        this.selectedBrandName = item.name;
    }

    onChangeSearchBrand(val: string) {
        this._addnewproductservice.searchbrand({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.brands = data['searchResult'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    onFocusedBrand(e) {
        // do something when input is focused
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.productAttributes, event.previousIndex, event.currentIndex);
        this._addnewproductservice.sortattribute({ uniqueId: this.uniqueId, variant_id: this.active_variant_id, attributes: this.productAttributes }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Technical details order have been saved.');
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    openPreview(unique_id, variant_id) {
        this.Preview.openPreviewModal(unique_id, variant_id);
    }

    Hsnkeyword = 'name';

    selectHsnEvent(item) {
        this.selected_hsn_id = item.id;
        //this.uniqueForm.get('hsn').setValue(item.name);
    }

    onChangeSearchHsn(val: string) {
        this._addnewproductservice.searchhsn({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.hsns = data['searchResult'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    onFocusedHsn(e) {
        // do something when input is focused
    }

}
