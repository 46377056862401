<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain pb-4">
    <div class="container">
        <!--- HEAD  --->
        <div class="row align-items-center pinfo-head">
            <div class="col-md-6 mb-4">
                <h3 class="fs24 wt700 txtdefault">Brand Management</h3>
                <nav class="breadcrumb breadcrumb-dui mb-0">
                    <ol class="breadcrumb p-0 bg-transparent  mb-0">
                        <li class="breadcrumb-item"><a class="txtseconday"
                                [routerLink]="['/admin/product-listing']">Product Management </a></li>
                        <li class="breadcrumb-item active">Brand Management</li>
                    </ol>
                </nav>
            </div>
            <div class="col-md-6  mb-4 text-md-right text-center">
                <a href="javascript:void(0);" class="mr-sm-4 btn-d-outline d-inline-block" (click)="getbrands('yes')">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="export_loading"></span>
                    &nbsp; Export
                </a>
                <a href="{{ export_path }}"
                    *ngIf="export_path"
                    class="btn btn-default"
                    target="_blank"
                    download="Seller.csv"
                    >Download Now
                </a>
                <a href="/admin/addbrand" class="mr-sm-4 mr-2 btn-d-outline d-inline-block"><svg
                        xmlns="http://www.w3.org/2000/svg" width="14.681" height="14.681" viewBox="0 0 14.681 14.681"
                        class="plus">
                        <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1 1)">
                            <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181" transform="translate(-11.659 -7.5)"
                                fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="2" />
                            <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181" transform="translate(-7.5 -11.659)"
                                fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="2" />
                        </g>
                    </svg>
                    Add New Brand
                </a>
                <a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Assistance</a>
            </div>
        </div>
        <!--- /HEAD  --->
        <div class="card cardui carduipro p-4 mb-0">
            <div class="notifications-container theme-tab">

                <div class="tab-content">
                    <!--Filters-->
                    <div class="row d-flex justify-content-between mt-0  report-cta">
                        <div class="col-sm-5 mb-3">
                            <div class="report-input">
                                <div class="input-group-custom">
                                    <input type="text" placeholder="Search by Name" class="form-control" [(ngModel)]="search_term" (change)="getbrands()">
                                    <div class="input-group-append-custom">
                                        <img src="assets/images/svg/search_logo.svg">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col-sm-7 mb-3 text-sm-right text-center">
                            <a href="javascript:void(0);" class="d-inline-block fltr-round-btn radius10 txtseconday">
                                <img src="assets/images/svg/filter.svg">
                                Filters
                            </a>

                            <div style="display:none" class="filter-show-div">
                                <div class="row no-gutters">
                                    <div class="col-md-3">
                                        <div class="left-filter">
                                            <ul>
                                                <li class="active"><a href="javascript:;">Category</a></li>
                                                <li><a href="javascript:;">Listing Available</a></li>
                                                <li><a href="javascript:;">Brand</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-md-9 right-contcc">
                                        <div class="right-filter lsting">
                                            <ul class="list-group">
                                                <li class="list-group-item">
                                                    <div class="css-check">
                                                        <input class="css-checkbx" type="checkbox" />
                                                        <label class="css-label">Smart Phones (45)</label>
                                                    </div>
                                                </li>
                                                <li class="list-group-item">
                                                    <div class="css-check">
                                                        <input class="css-checkbx" type="checkbox" />
                                                        <label class="css-label">Feature Phones (45)</label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="footer-cta">
                                            <div class="mr-3">
                                                <a href="javascript:;"><svg xmlns="http://www.w3.org/2000/svg"
                                                        width="10.239" height="10.245" viewBox="0 0 10.239 10.245">
                                                        <path id="Icon_material-refresh"
                                                            data-name="Icon material-refresh"
                                                            d="M14.749,7.5a5.123,5.123,0,1,0,1.332,4.9H14.749a3.84,3.84,0,1,1-3.618-5.123,3.787,3.787,0,0,1,2.7,1.14l-2.062,2.062h4.482V6Z"
                                                            transform="translate(-6.015 -6)" fill="#ff7135" />
                                                    </svg> &nbsp;&nbsp;<span class="link-alt fs14">Reset
                                                        Filters</span></a>
                                            </div>
                                            <div><a href="" class="btn buybtn fs16 radius10 ripple">Apply</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <!--End Filters-->
                    <div class="table-responsive brndmgnttable" *ngIf="records.length > 0">
                        <table class="table custom-table xs">
                            <thead>
                                <tr>
                                    <th width="18%">Brand / Item Name</th>
                                    <th width="18%">Category</th>
                                    <th>Brand website link</th>
                                    <th width="10%">Brand Service Centers</th>
                                    <th width="15%">Country of Origin</th>
                                    <th>Brand Owner Company Name</th>
                                    <th width="10%" class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- TR -->
                                <tr *ngFor="let record of records; let i = index;">
                                    <td>
                                        <div class="order-info">
                                            <div class="order-img">
                                                <img src="{{ record.brand_logo }}" />
                                            </div>
                                            <div class="pd-seller-cntnt">
                                                <div class="txtdefault">
                                                    <a href="javascript:void(0);" class="link-alt fs14">{{
                                                        record.brand_name }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{ record.categories }}</td>
                                    <td>{{ record.brand_website }}</td>
                                    <td>{{ record.service_centers }}</td>
                                    <td>{{ record.country_of_origin }}</td>
                                    <td>{{ record.owner_company_name }}</td>
                                    <td class="d-flex align-items-center">

                                        <a href="/admin/editbrand/{{record.id}}" class="place-bid">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" width="14" height="14"
                                                viewBox="0 0 7.984 7.984">
                                                <defs>
                                                    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1"
                                                        gradientUnits="objectBoundingBox">
                                                        <stop offset="0" stop-color="#79b7ff" />
                                                        <stop offset="1" stop-color="#07f" />
                                                    </linearGradient>
                                                </defs>
                                                <path id="np_edit_3094244_000000"
                                                    d="M6.485-.008A1.491,1.491,0,0,0,5.428.43L.41,5.447,2.527,7.563,7.544,2.546A1.5,1.5,0,0,0,6.485-.008ZM.151,5.893,0,7.706a.249.249,0,0,0,.269.269l1.812-.154Z"
                                                    transform="translate(0.003 0.008)" fill="url(#linear-gradient)" />
                                            </svg>
                                        </a>
                                        <span class="vdivider mr-3"></span>
                                        <a (click)="openWindowCustomClass(content, record)" href="javascript:;"><img
                                                src="assets/images/icons/delete-icon.svg"></a>
                                    </td>
                                </tr>
                                <!-- /TR -->
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-40" *ngIf="brand_pagination.totalPages> 1">
            <div class="col-md-6">
                <div class="text-left pl-3">
                    <nav class="mt-3 tbpg rows-per-page">
                        <div>Rows per page:</div>
                        <select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
                            <option *ngFor="let option of page_per_view_options" [ngValue]="option.value">
                                {{option.name}}</option>
                        </select>
                    </nav>
                </div>
            </div>
            <div class="col-md-6" *ngIf="brand_pagination.pages && brand_pagination.pages.length">
                <div class="text-right pr-3">
                    <nav class="mt-3 tbpg">
                        <ul class="pagination align-items-center">
                            <li class="page-item" [ngClass]="{disabled:brand_pagination.currentPage === 1}"><a
                                    class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a></li>

                            <li class="page-item" [ngClass]="{disabled:brand_pagination.currentPage === 1}"><a
                                    class="page-link sprevicon" href="javascript:;"
                                    (click)="setpage(brand_pagination.currentPage - 1)"></a></li>

                            <li class="page-item" i *ngFor="let page of brand_pagination.pages"
                                [ngClass]="{active:brand_pagination.currentPage === page}"><a class="page-link"
                                    href="javascript:;" (click)="setpage(page)">{{page}}</a></li>

                            <li *ngIf="(brand_pagination.totalPages - brand_pagination.startPage) > 5 ">
                                <a (click)="setpage(brand_pagination.startPage + 5)">...</a>
                            </li>
                            <li *ngIf="(brand_pagination.totalPages - brand_pagination.startPage) > 5">
                                <a (click)="setpage(brand_pagination.totalPages)">{{brand_pagination.totalPages}}</a>
                            </li>

                            <li class="page-item"
                                [ngClass]="{disabled:brand_pagination.currentPage === brand_pagination.totalPages}"><a
                                    class="page-link snexticon" href="javascript:;"
                                    (click)="setpage(brand_pagination.currentPage + 1)"></a></li>
                            <li class="page-item"
                                [ngClass]="{disabled:brand_pagination.currentPage === brand_pagination.totalPages}"><a
                                    class="page-link nexticon" href="javascript:;"
                                    (click)="setpage(brand_pagination.totalPages)"></a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    <!-- REMOVE BRAND MODAL -->
    <ng-template #content let-modal>
        <div class="modal-header nobd text-center pt-4 pb-0">
            <h4 class="modal-title fs28 txtdefault wt700 mx-auto pt-3">Remove Product</h4>
            <button type="button" class="btn-close close rmvmdl" aria-label="Close"
                (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <p class="fs12 txtseconday py-3 mb-2 text-center">
                <span class="mr-2" placement="top" ngbTooltip="Tooltop content goes here">
                    <svg id="Group_46" data-name="Group 46" xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                        viewBox="0 0 15 15">
                        <circle id="Ellipse_1" data-name="Ellipse 1" cx="7.5" cy="7.5" r="7.5" fill="#07f" />
                        <path id="Path_2235" data-name="Path 2235"
                            d="M7,8.171l-.128.523q-.576.227-.919.346a2.425,2.425,0,0,1-.8.119,1.591,1.591,0,0,1-1.084-.34,1.1,1.1,0,0,1-.387-.864,3.056,3.056,0,0,1,.029-.416q.029-.213.093-.48l.48-1.7q.064-.244.108-.462a2.01,2.01,0,0,0,.044-.4A.616.616,0,0,0,4.3,4.047a.759.759,0,0,0-.513-.128,1.345,1.345,0,0,0-.381.058c-.131.039-.242.076-.336.11L3.2,3.564q.471-.192.9-.329A2.7,2.7,0,0,1,4.916,3.1a1.552,1.552,0,0,1,1.068.335,1.111,1.111,0,0,1,.375.869c0,.074-.008.2-.026.39a2.606,2.606,0,0,1-.1.512L5.759,6.9a4.492,4.492,0,0,0-.1.466,2.4,2.4,0,0,0-.047.4.57.57,0,0,0,.15.459.85.85,0,0,0,.523.122,1.509,1.509,0,0,0,.4-.061A2.21,2.21,0,0,0,7,8.171Zm.121-7.1a.99.99,0,0,1-.334.753,1.138,1.138,0,0,1-.8.312,1.15,1.15,0,0,1-.809-.312.988.988,0,0,1-.337-.753A1,1,0,0,1,5.169.314a1.194,1.194,0,0,1,1.614,0A1,1,0,0,1,7.117,1.07Z"
                            transform="translate(2.406 2.92)" fill="#fff" />
                    </svg>
                </span>

                Removing the brand will result in deactivation of corresponding product listings from Equibiz.
            </p>
            <p class="fs16 txtseconday pb-3 mb-2 text-center">Are you sure you want to remove the Brand?</p>
            <div class="mb-3 w-100 text-center">
                <img class="align-middle mr-3" *ngIf="selectedRecord.brand_logo"
                    src="{{ selectedRecord.brand_logo }}" />
                <span class="fs16 wt700 txtdefault align-middle">{{ selectedRecord.brand_name }}</span>
            </div>
        </div>
        <div class="modal-footer  text-center nobd mx-auto pb-4 mb-3">
            <button type="button" class="btn-d-outline d-inline-block cancel-outline mr-3"
                (click)="modal.close('Close click')">Cancel</button>
            <button type="button" class="btn buybtn fs16 radius10" (click)="deletebrand()">Remove</button>
        </div>
    </ng-template>
    <!-- /REMOVE BRAND MODAL -->