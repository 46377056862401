import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-supplier-performance',
    templateUrl: './supplier-performance.component.html',
    styleUrls: ['./supplier-performance.component.scss']
})
export class SupplierPerformanceComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }
    ratingclassstatus: boolean = false;
    ratingst: boolean = false;
    ratingtglclass() {
        this.ratingclassstatus = !this.ratingclassstatus;
    }

    ratingsort() {
        this.ratingst = !this.ratingst;
    }
    public val = null;
    config = {
        labelField: 'label',
        valueField: 'value',

    };

    data = [
        {
            label: 'Option 1',
            value: 'Option 1'
        },
        {
            label: 'Option 2',
            value: 'Option 2'
        },
        {
            label: 'Option 3',
            value: 'Option 3'
        }
    ]



    ngAfterViewChecked() {
    }

    public changed() {
    }

}
