import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { SellerInfoService } from './seller-info.service';

@Component({
    selector: 'app-serller-info',
    templateUrl: './serller-info.component.html',
    styleUrls: ['./serller-info.component.scss']
})
export class SerllerInfoComponent implements OnInit {
    active = 1;
    public seller_id:string;
    public seller_detail:any = {};
    constructor(
        private _sellerinfoservice: SellerInfoService,
        private _toastr: ToasterService,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.route.queryParams
            .subscribe(params => {
                if(params['s_id'] !== undefined){
                    this.seller_id = params['s_id'];
                }

                if (params['limit'] !== undefined) {
                    this.active = 2;
                }
                this.getsellerdetail();
            }
        );
    }

    getsellerdetail(){
        this._sellerinfoservice.getsellerdetail({s_id: this.seller_id}).subscribe((data) => {
            if(data['status'] == "success"){
                this.seller_detail = data['record'];
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

}
