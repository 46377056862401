<ngx-loading-bar></ngx-loading-bar>
<router-outlet></router-outlet>
<!-- <app-tap-to-top></app-tap-to-top> -->

<!-- <div *ngIf="message;">
    <h1>{{ message.notification.title }}</h1>
    <h2>{{ message.notification.body }}</h2>
  </div>
  <div *ngIf="!message;">
    No Message Arrived
</div> -->