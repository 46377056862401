<!-- footer mobile -->
<div class="mb-footer sl-mb-footer text-center"> 
	<div class="row">
		<div class="col-3">
			<a href="" class="d-inline-block w-100">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="19.201" height="12.801" viewBox="0 0 19.201 12.801">
					  <path id="Path_8745" data-name="Path 8745" d="M10.6,6A9.611,9.611,0,0,0,1,15.6v2.56a.64.64,0,0,0,.64.64H19.561a.639.639,0,0,0,.64-.64V15.6A9.611,9.611,0,0,0,10.6,6Zm8.32,11.521H2.28V15.6a8.32,8.32,0,0,1,16.641,0Zm-.64-1.92v.64a.64.64,0,1,1-1.28,0V15.6a6.4,6.4,0,0,0-12.8,0v.64a.64.64,0,1,1-1.28,0V15.6a7.68,7.68,0,0,1,15.361,0Zm-4.933-4.293-1.92,1.92,0,.006a1.9,1.9,0,0,0-.824-.193,1.92,1.92,0,1,0,1.92,1.92,1.893,1.893,0,0,0-.193-.824l.006,0,1.92-1.92a.64.64,0,0,0-.905-.905ZM10.6,15.6a.64.64,0,1,1,.64-.64.641.641,0,0,1-.64.64Z" transform="translate(-1 -6)" fill="#1f1d44"/>
					</svg>
				</span>			
				<p class="txtdefault fs12">Dashboard</p>
			</a>
		</div>
		<div class="col-3">
			<a href="javascript:void(0);" [routerLink]="['/seller/mylisting']" class="d-inline-block w-100">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="14.745" height="18.372" viewBox="0 0 14.745 18.372">
						<g id="shopping-bag_3_" data-name="shopping-bag (3)" transform="translate(-48.7 0.1)">
							<path id="Path_8744" data-name="Path 8744" d="M63.342,15.709,62.3,3.991a.5.5,0,0,0-.5-.457H59.663a3.59,3.59,0,0,0-7.18,0H50.342a.5.5,0,0,0-.5.457L48.8,15.709c0,.015,0,.03,0,.045a2.59,2.59,0,0,0,2.728,2.419h9.09a2.59,2.59,0,0,0,2.728-2.419A.181.181,0,0,0,63.342,15.709ZM56.073,1a2.589,2.589,0,0,1,2.587,2.531H53.486A2.589,2.589,0,0,1,56.073,1Zm4.545,16.166h-9.09A1.6,1.6,0,0,1,49.8,15.776l1-11.234h1.68V6.065a.5.5,0,1,0,1,0V4.541h5.177V6.065a.5.5,0,1,0,1,0V4.541h1.68l1,11.234A1.6,1.6,0,0,1,60.618,17.169Z" fill="#1f1d44" stroke="#1f1d44" stroke-width="0.2"/>
						</g>
					</svg>
				</span>			
				<p class="txtdefault fs12">Products</p>
			</a>
		</div>
		<div class="col-3">
			<a href="javascript:void(0);" [routerLink]="['/seller/my-wallet']" class="d-inline-block lwopacity w-100 sellbtn">
				<span>
					<img src="assets/images/icons/wallet-menu.png">
				</span>			
				<p class="txtdefault txtch fs12">Wallet</p>
			</a>
		</div>
		<div class="col-3">
			<a href="javascript:void(0);" class="d-inline-block w-100" [routerLink]="['/seller/order-history']">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="18.623" height="18.576" viewBox="0 0 18.623 18.576">
					  <g id="delivery-box" transform="translate(0.224 0.204)">
						<path id="Path_8740" data-name="Path 8740" d="M.378,19.569a.376.376,0,0,0,.175-.043l8.7-4.543a.379.379,0,0,0,.2-.335V6.129A.378.378,0,0,0,8.91,5.79l-8.7,4.354A.379.379,0,0,0,0,10.483V19.19a.379.379,0,0,0,.182.324A.373.373,0,0,0,.378,19.569ZM8.7,14.418.756,18.565V10.716L8.7,6.741Z" transform="translate(8.714 -1.397)" fill="#1f1d44" stroke="#1f1d44" stroke-width="0.4"/>
						<path id="Path_8741" data-name="Path 8741" d="M20.588,19.569a.378.378,0,0,0,.379-.379V10.482a.379.379,0,0,0-.209-.338l-8.7-4.354a.379.379,0,0,0-.548.338v8.518a.379.379,0,0,0,.2.335l8.7,4.543a.376.376,0,0,0,.175.043Zm-.379-8.852v7.849l-7.944-4.148V6.741Zm-8.323,3.931h-.008Z" transform="translate(-11.508 -1.397)" fill="#1f1d44" stroke="#1f1d44" stroke-width="0.4"/>
						<path id="Path_8742" data-name="Path 8742" d="M17.792,5.111a.378.378,0,0,0,.17-.717L9.248.04a.38.38,0,0,0-.338,0L.208,4.394a.379.379,0,0,0,.338.678L9.079.8l8.544,4.269a.377.377,0,0,0,.17.04Z" transform="translate(0.001 0)" fill="#1f1d44" stroke="#1f1d44" stroke-width="0.4"/>
						<path id="Path_8743" data-name="Path 8743" d="M6.386,12.025a.379.379,0,0,0,.379-.379V7.716l8.493-4.249a.379.379,0,0,0-.339-.678l-8.7,4.354a.381.381,0,0,0-.209.339v4.165a.379.379,0,0,0,.379.379Z" transform="translate(-1.843 -0.668)" fill="#1f1d44" stroke="#1f1d44" stroke-width="0.4"/>
					  </g>
					</svg>
				</span>			
				<p class="txtdefault fs12">Orders</p>
			</a>
		</div>
		<div class="col-3">
			<a href="javascript:void(0);" class="d-inline-block w-100"  (click)="moreEvent()"  >
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="5.88" viewBox="0 0 20 5.88">
					  <g id="Group_13963" data-name="Group 13963" transform="translate(-5816 -994)">
						<g id="Group_189" data-name="Group 189" transform="translate(5830.121 994)">
						  <g id="Group_188" data-name="Group 188">
							<path id="Path_327" data-name="Path 327" d="M364.424,180.742a2.94,2.94,0,1,0,2.94,2.94A2.943,2.943,0,0,0,364.424,180.742Zm0,4.434a1.494,1.494,0,1,1,1.494-1.494A1.5,1.5,0,0,1,364.424,185.176Z" transform="translate(-361.484 -180.742)" fill="#1f1d44"/>
						  </g>
						</g>
						<g id="Group_191" data-name="Group 191" transform="translate(5823.061 994)">
						  <g id="Group_190" data-name="Group 190">
							<path id="Path_328" data-name="Path 328" d="M183.682,180.742a2.94,2.94,0,1,0,2.94,2.94A2.943,2.943,0,0,0,183.682,180.742Zm0,4.434a1.494,1.494,0,1,1,1.494-1.494A1.5,1.5,0,0,1,183.682,185.176Z" transform="translate(-180.742 -180.742)" fill="#1f1d44"/>
						  </g>
						</g>
						<g id="Group_13962" data-name="Group 13962" transform="translate(5816 994)">
						  <g id="Group_190-2" data-name="Group 190">
							<path id="Path_328-2" data-name="Path 328" d="M183.682,180.742a2.94,2.94,0,1,0,2.94,2.94A2.943,2.943,0,0,0,183.682,180.742Zm0,4.434a1.494,1.494,0,1,1,1.494-1.494A1.5,1.5,0,0,1,183.682,185.176Z" transform="translate(-180.742 -180.742)" fill="#1f1d44"/>
						  </g>
						</g>
					  </g>
					</svg>

				</span>			
				<p class="txtdefault fs12">More</p>
			</a>
		</div>
	</div>
</div>
<!-- /footer mobile -->