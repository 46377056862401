<app-header-buyer></app-header-buyer>
<app-sidebar-buyer></app-sidebar-buyer>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<h3 class="fs24 wt700 txtdefault">Order History</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Payments</a>
						</li>
						<li class="breadcrumb-item active">Order History</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:void(0);" [routerLink]="['/buyer/assistance']" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="us-tab">
			<nav ngbNav #nav="ngbNav" class="nav-tabs">
			  <ng-container ngbNavItem>
				<ng-template ngbNavContent>
				  	<div class="card cardui carduipro p-4 mb-0 table-card">
						<div class="notifications-container theme-tab xs-scroller">
				          	<ngb-tabset [justify]="'left'" [type]="'pills'" class="tabs tab-title">
								<!-- All Orders -->
					            <ngb-tab>
									<ng-template ngbTabTitle><span (click)="getbuyerorders('all')">All Orders</span></ng-template>
					            </ngb-tab>

								<!-- Booked Orders -->
								<ngb-tab>
									<ng-template ngbTabTitle><span (click)="getbuyerorders('booked')">Booked</span></ng-template>
					            </ngb-tab>

								<!-- Approved Orders -->
								<ngb-tab>
									<ng-template ngbTabTitle><span (click)="getbuyerorders('approved')">Approved</span></ng-template>
					            </ngb-tab>

								<!-- Rejected Orders -->
								<ngb-tab>
									<ng-template ngbTabTitle><span (click)="getbuyerorders('rejected')">Rejected</span></ng-template>
					            </ngb-tab>

								<!-- Payment Done Orders -->
								<ngb-tab>
									<ng-template ngbTabTitle><span (click)="getbuyerorders('payment_done')">Payment Done</span></ng-template>
					            </ngb-tab>

								<!-- Ready to Dispatch Orders -->
								<ngb-tab>
									<ng-template ngbTabTitle><span (click)="getbuyerorders('ready_to_dispatch')">Ready to Dispatch</span></ng-template>
					            </ngb-tab>

								<!-- Quality Done Orders -->
								<ngb-tab>
									<ng-template ngbTabTitle><span (click)="getbuyerorders('quality_done')">Quality Done</span></ng-template>
					            </ngb-tab>

								<!-- Dispatched Orders -->
								<ngb-tab>
									<ng-template ngbTabTitle><span (click)="getbuyerorders('dispatched')">Dispatched</span></ng-template>
					            </ngb-tab>

								<!-- Delivered Orders -->
								<ngb-tab>
									<ng-template ngbTabTitle><span (click)="getbuyerorders('delivered')">Delivered</span></ng-template>
					            </ngb-tab>

							</ngb-tabset>
							
				    	</div>

						<div class="tab-content">
							<!--Filters-->
							<div class="row d-flex justify-content-between mt-20 report-cta">
							  <div class="col-md-5">
								  <div class="report-input">
									  <div class="input-group-custom">
										  <input type="text" placeholder="Search by Product name, Order ID & Transaction ID" 
										  class="form-control" [(ngModel)]="search_term" (change)="getbuyerordersfilters()">
										  <div class="input-group-append-custom">
											  <img src="assets/images/svg/search_logo.svg">
										  </div>
									  </div>
								  </div>
							  </div>
	
							  <div class="col-md-7 mt-xs-10">
								  <div class="d-flex justify-content-end">
									  <!-- <div class="mr-3">
										  <a href="javascript:void(0);" class="d-inline-block fltr-round-btn radius10 txtseconday">
											  <img src="assets/images/svg/filter.svg">
											  Filters
										  </a>
									  </div> -->
									  <div class="form-container mt-0 mb-0 mr-3">
										  <div class="report-input floating-label">
											  <div class="input-group-custom">
												  <input type="text" [(ngModel)]="from_date" (ngModelChange) = "getbuyerordersfilters()" class="form-control" placeholder=" " ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" autocomplete="off"/>
												  <span class="highlight"></span>
												  <label>From Date</label>
												  <div class="input-group-append-custom">
													  <img src="assets/images/icons/filter-calendar.svg">
												  </div>
											  </div>
										  </div>
									  </div>
	
									  <div class="form-container mt-0 mb-0 mr-3">
										  <div class="form-container m-0">
											  <div class="report-input floating-label">
												  <div class="input-group-custom">
													  <input type="text" [(ngModel)]="to_date" (ngModelChange) = "getbuyerordersfilters()" class="form-control" placeholder=" " ngbDatepicker #dd="ngbDatepicker" (click)="dd.toggle()" autocomplete="off"/>
													  <span class="highlight"></span>
													  <label>To Date</label>
													  <div class="input-group-append-custom">
														  <img src="assets/images/icons/filter-calendar.svg">
													  </div>
												  </div>
											  </div>
										  </div>
									  </div>
	
									  <!-- <div>
										  <a href="javascript:void(0);" class="btn-d-outline d-inline-block">
											  <svg xmlns="http://www.w3.org/2000/svg" width="12.681" height="17.061" viewBox="0 0 12.681 17.061">
												<g id="np_export_2535025_000000" transform="translate(-32.121 -25.962)">
												  <path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#ff7135"/>
												  <path id="Path_2356" data-name="Path 2356" d="M44.093,70.07H32.83a.709.709,0,1,0,0,1.419H44.093a.709.709,0,0,0,0-1.419Z" transform="translate(0 -28.466)" fill="#ff7135"/>
												</g>
											  </svg>
											  Export
										  </a>
									  </div> -->
								  </div>
							  </div>
						  </div>
						  <!--End Filters-->
						  <table class="table custom-table xs">
							  <thead>
								  <tr>
									  <th>Order Id</th>
									  <th>Transaction Id</th>
									  <th width="18%">Product Name</th>
									  <th>Market Place</th>
									  <th>Price (in ₹)</th>
									  <th>Pre-Booked On <i class="long-arrow-down"></i></th>
									  <th>Order Status</th>
									  <th>Action</th>
								  </tr>
							  </thead>
							  <tbody>
								  <!--Empty State-->
								  <tr *ngIf="!buyer_orders.length">
									  <td colspan="7">
										  <div class="row pt-40 pb-40">
											  <div class="col-md-12 text-center">
												  <div class="mb-20"><img src="/assets/images/empty-state.png">
												  </div>
												  <div class="fs16">There are no records to display!</div>
											  </div>
										  </div>
									  </td>
								  </tr>
								  <!--End Empty State-->
								  <tr *ngFor="let record of buyer_orders;">
									  <td>{{ record.order_id }}</td>
									  <td>{{ record.transaction_id }}</td>
									  <td>
										  <div class="order-info">
											  <div class="order-img">
												  <img src="{{ record.product_image }}" />
											  </div>
											  <div class="pd-seller-cntnt">
												  <div class="txtdefault">
													  <a href="javascript:;" class="order-link">{{ record.product_name }}</a>
												  </div>
											  </div>
										  </div>
									  </td>
									  <td>{{ record.map_type }}</td>
									  <td>{{ record.total_amount }}</td>
									  <td>{{ record.prebookedon }}</td>											
									  <td><div>{{ record.order_status }}</div>
										  <!-- <span class="fs10 radius10 badge badge-d-outline">Dispatch in 16:00 hrs</span>
										  <span class="fs10 radius10 badge badge-d-outline dspatchbadge-d-outline">Delayed in 2 days</span> -->
									  </td>
									  
									  <td class="d-flex align-items-center">
										  <a href="javascript:void(0);" [routerLink]="['/buyer/buyer-view-order-details/'+record.id]" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Order</a>
										  <!-- <a href="javascript:;"><img src="assets/images/icons/exclamation.svg"></a> -->
									  </td>
								  </tr>
							  </tbody>
						  </table>
						</div>
						
					</div>
					
					<div class="row mb-40" *ngIf="buyer_order_pagination.totalPages> 1">
						<div class="col-md-6">
							<div class="text-left pl-3">
								<nav class="mt-3 tbpg rows-per-page">
									<div>Rows per page:</div>
									<select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
										<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">
											{{option.name}}</option>
									</select>
								</nav>
							</div>
						</div>
						<div class="col-md-6">
							<div class="text-right md-text-left pr-3">
								<nav class="mt-3 tbpg">
									<ul class="pagination align-items-center">
										<li class="page-item" [ngClass]="{disabled:buyer_order_pagination.currentPage === 1}"><a
												class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a>
										</li>
	
										<li class="page-item" [ngClass]="{disabled:buyer_order_pagination.currentPage === 1}"><a
												class="page-link sprevicon" href="javascript:;"
												(click)="setpage(buyer_order_pagination.currentPage - 1)"></a></li>
	
										<li class="page-item" i *ngFor="let page of buyer_order_pagination.pages"
											[ngClass]="{active:buyer_order_pagination.currentPage === page}"><a class="page-link"
												href="javascript:;" (click)="setpage(page)">{{page}}</a></li>
	
										<li *ngIf="(buyer_order_pagination.totalPages - buyer_order_pagination.startPage) > 5 ">
											<a (click)="setpage(buyer_order_pagination.startPage + 5)">...</a>
										</li>
										<li *ngIf="(buyer_order_pagination.totalPages - buyer_order_pagination.startPage) > 5">
											<a
												(click)="setpage(buyer_order_pagination.totalPages)">{{buyer_order_pagination.totalPages}}</a>
										</li>
	
										<li class="page-item"
											[ngClass]="{disabled:buyer_order_pagination.currentPage === buyer_order_pagination.totalPages}">
											<a class="page-link snexticon" href="javascript:;"
												(click)="setpage(buyer_order_pagination.currentPage + 1)"></a>
										</li>
										<li class="page-item"
											[ngClass]="{disabled:buyer_order_pagination.currentPage === buyer_order_pagination.totalPages}">
											<a class="page-link nexticon" href="javascript:;"
												(click)="setpage(buyer_order_pagination.totalPages)"></a>
										</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</ng-template>
			  </ng-container>
			</nav>

			<div [ngbNavOutlet]="nav" class="mt-2"></div>
		</div>
	</div>
</div>