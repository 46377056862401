import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { MybidsService } from './my-bids.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-my-bids',
    templateUrl: './my-bids.component.html',
    styleUrls: ['./my-bids.component.scss']
})
export class MyBidsComponent implements OnInit {

    public search_term:string = '';
    public closeResult = '';
    public bids_records: any = [];
    public watchlist_records: any = [];
    public bid_edit_type = 'quantity';
    public bid_view_history = false;
    public bid_history_records: any = [];
    public selected_bid: any = {};
    public updated_quantity: number;
    public updated_price: number;
    constructor(
        private _mybidsservice: MybidsService,
        private _toastr: ToasterService,
        private modalService: NgbModal,
    ) { }

    ngOnInit(): void {
        this.getmybids();
    }

    getmybids() {
        this._mybidsservice.getmybids({search: this.search_term}).subscribe((data) => {
            if (data['status'] == "success") {
                this.bids_records = data['records'];
                this.watchlist_records = [];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    deletebid(record) {
        this._mybidsservice.deletebid({ bid_id: record.bid_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Bid record has been deleted successfully.');
                this.getmybids();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    acceptcounteroffer() {
        this._mybidsservice.acceptcounteroffer({ bid_id: this.selected_bid.bid_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Counter offer accepted successfully.');
                this.modalService.dismissAll();
                this.getmybids();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    openCounterOffer(content2, record, type) {
        this.bid_edit_type = type;
        this._mybidsservice.editbiddetails({ bid_id: record.bid_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.selected_bid = data['basic'];
                this.bid_history_records = data['records'];
                this.modalService.open(content2, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    open(content, record) {
        this.selected_bid = record;
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    showhistory() {
        this.bid_view_history = !this.bid_view_history;
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    updatebid(type) {
        if (type == 'price' && !this.updated_price) {
            this._toastr.error('Please enter bid price.');
            return false;
        } else if (type == 'quantity' && !this.updated_quantity) {
            this._toastr.error('Please enter bid quantity.');
            return false;
        }
        var value = type == 'price' ? this.updated_price : this.updated_quantity;
        this._mybidsservice.updatebid({ type: type, value: value, bid_id: this.selected_bid.bid_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.updated_price = null;
                this.updated_quantity = null;
                this.modalService.dismissAll();
                this.getmybids();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    changebidedit(type) {
        this.bid_edit_type = type;
    }

}
