<app-header-buyer></app-header-buyer>
<app-sidebar-buyer></app-sidebar-buyer>
<div class="wrpmain-buyer">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<h3 class="fs24 wt700 txtdefault">Bank Details</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Profile</a>
						</li>
						<li class="breadcrumb-item active">Bank Details</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:void(0);" [routerLink]="['/buyer/assistance']" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4">
			<div class="form-container mdl-frm-lbl mb-3">
				<div class="row">
					<div class="col-sm-12 mb-4">
						<div class="floating-label mb-0">      
						  <input type="text" value="{{ bank_detail.bank_name }}" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>Bank Name</label>
						</div> 
					</div>
				</div>
				<div class="row">
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input value="{{ bank_detail.account_name }}" type="text" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>Account Holder Name</label>
						</div> 
					</div>
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input value="{{ bank_detail.account_number }}" type="text" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>Account Number</label>
						</div> 
					</div>	
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input value="{{ bank_detail.ifsc_code }}" type="text" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>IFSC Code</label>
						</div> 
					</div>
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input value="{{ bank_detail.branch }}" value="" type="text" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>Bank Branch</label>
						</div> 
					</div>	
				</div>
				<div class="text-center">
					<a href="javascript:void(0);" [routerLink]="['/buyer/buyer-wallet']" class="mr-sm-4  mb-3 btn-d-outline d-inline-block">Wallet Details</a>
					<a href="javascript:void(0);" [routerLink]="['/buyer/buyer-transaction-history']" class="btn-d-outline d-inline-block">View Your Transactions</a>
				</div>
			</div>
		</div>
	</div>
</div>

<app-footer-buyer></app-footer-buyer>