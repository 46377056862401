import { Component, OnInit } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { SellerProductListingService } from './product-listing.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginationService } from 'src/app/helpers/pagination.service';

@Component({
    selector: 'app-seller-product-listing',
    templateUrl: './product-listing.component.html',
    styleUrls: ['./product-listing.component.scss']
})
export class SellerProductListingComponent implements OnInit {

    public active_ids = 'toggle-1, toggle-2, toggle-3, brand, startingprice, activationstatus, supplylocation, countryorigin';
    public filter_marketplace = [];
    public filter_category: any = [];
    public filter_brands: any = [];
    public filter_prices = [];
    public filter_variants: any = [];

    public brands_array: any = [];
    public category_array: any = [];
    public marketplace_array: any = [];
    public price_array: any = [];
    public variant_array: any = [];
    public supply_locations_array: any = [];
    public country_array: any = [];
    public total_products: number;
    public seller_name: string;
    public seller_id: string;
    public paramsquery: any;

    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 10;
    public seller_pagination: any = {};
    public sort_by: string = 'relevance';
    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];

    sorting_order = [
        {
            label: 'Relevance',
            value: 'relevance'
        },
        {
            label: 'Price Low to High',
            value: 'low'
        },
        {
            label: 'Price High to Low',
            value: 'high'
        },
        {
            label: 'Product Ratings',
            value: 'rating'
        },
        {
            label: 'Latest Arrivals',
            value: 'latest'
        }
    ];

    classstatus: boolean = false;
    constructor(
        private _toastr: ToasterService,
        private _productlisting: SellerProductListingService,
        private route: ActivatedRoute,
        private router: Router,
        private _pagination: PaginationService,
    ) { }
    minValue: number = 0;
    maxValue: number = 10000;
    public productListing: any = [];
    options: Options = {
        floor: 0,
        ceil: 10000
    };
    ngOnInit(): void {
        this.route.queryParams
            .subscribe(params => {
                this.paramsquery = params;
                if (params['s_id'] !== undefined) {
                    this.seller_id = params['s_id'];
                }

                if (params['limit'] !== undefined) {
                    this.page_per_view = params['limit'];
                }

                if (params['offset'] !== undefined) {
                    this.offset = params['offset'];
                }

                if (params['current_page'] !== undefined) {
                    this.current_page = params['current_page'];
                }

                if (params['sort_by'] !== undefined) {
                    this.sort_by = params['sort_by'];
                }

                if (params['brand'] !== undefined && params['brand'] != '') {
                    this.brands_array = params['brand'].split(',');
                }

                if (params['category'] !== undefined && params['category'] != '') {
                    this.category_array = params['category'].split(',');
                }

                if (params['price'] !== undefined && params['price'] != '') {
                    this.price_array = params['price'].split(',');
                }

                if (params['maps'] !== undefined && params['maps'] != '') {
                    this.marketplace_array = params['maps'].split(',');
                }

                if (params['variant'] !== undefined && params['variant'] != '') {
                    this.variant_array = params['variant'].split(',');
                }

                if (params['slo'] !== undefined && params['slo'] != '') {
                    this.supply_locations_array = params['slo'].split(',');
                }

                if (params['country'] !== undefined && params['country'] != '') {
                    this.country_array = params['country'].split(',');
                }
                this.getsellerproducts();
            }
            );
    }
    getsellerproducts() {
        this._productlisting.getproductslisting(this.paramsquery).subscribe((data) => {
            if (data['status'] == "success") {
                this.filter_marketplace = data['filter_marketplace'];
                this.filter_category = data['filter_category'];
                this.filter_brands = data['filter_brands'];
                this.filter_prices = data['filter_prices'];
                this.filter_variants = data['filter_variants'];
                this.total_products = data['total_records'];
                this.seller_name = data['seller_name'];
                this.minValue = data['min_price'];
                this.maxValue = data['max_price'];
                for (let i = 0; i < this.filter_variants.length; i++) {
                    this.active_ids = this.active_ids + ', ' + this.filter_variants[i].slug;
                }
                this.productListing = data['records'];
                let total_records = data['total_records'];
                let limit = data['limit'];
                let pages = Math.ceil(total_records / limit);
                this.seller_pagination = this._pagination.getPager(total_records, this.current_page, limit);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }
    tglclass() {
        this.classstatus = !this.classstatus;
    }
    public val = null;
    config = {
        labelField: 'label',
        valueField: 'value',
    };

    data = [
        {
            label: 'Option 1',
            value: 'Option 1'
        },
        {
            label: 'Option 2',
            value: 'Option 2'
        },
        {
            label: 'Option 3',
            value: 'Option 3'
        }
    ];



    ratingst: boolean = false;
    ratingsort() {
        this.ratingst = !this.ratingst;
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.createparams('pagination', '');
    }

    setpageperview() {
        this.current_page = 1;
        this.createparams('pagination', '');
    }

    createparams(type, record) {
        if (type != 'pagination' && type != 'custom_price' && type != 'sort_by') {
            record.selected = !record.selected;
            if (record.selected) {
                if (type == 'brand') {
                    this.brands_array.push(record.id);
                } else if (type == 'category') {
                    this.category_array.push(record.id);
                } else if (type == 'marketplace') {
                    this.marketplace_array.push(record.slug);
                } else if (type == 'price') {
                    this.price_array = [];
                    this.price_array.push(record.slug);
                } else if (type == 'variant') {
                    this.variant_array.push(record.slug);
                } else if (type == 'slo') {
                    this.supply_locations_array.push(record.slug);
                } else if (type == 'country') {
                    this.country_array.push(record.slug);
                }
            } else {
                if (type == 'brand') {
                    const index: number = this.brands_array.indexOf(record.id);
                    this.brands_array.splice(index, 1);
                } else if (type == 'category') {
                    const index: number = this.category_array.indexOf(record.id);
                    this.category_array.splice(index, 1);
                } else if (type == 'marketplace') {
                    const index: number = this.marketplace_array.indexOf(record.slug);
                    this.marketplace_array.splice(index, 1);
                } else if (type == 'price') {
                    const index: number = this.price_array.indexOf(record.slug);
                    this.price_array.splice(index, 1);
                } else if (type == 'variant') {
                    const index: number = this.variant_array.indexOf(record.slug);
                    this.variant_array.splice(index, 1);
                } else if (type == 'slo') {
                    const index: number = this.supply_locations_array.indexOf(record.slug);
                    this.supply_locations_array.splice(index, 1);
                } else if (type == 'country') {
                    const index: number = this.country_array.indexOf(record.slug);
                    this.country_array.splice(index, 1);
                }
            }
        }

        if (type == 'custom_price') {
            this.price_array = [];
            this.price_array.push(this.minValue + "-" + this.maxValue);
        }

        var array_params: any = {};
        array_params.s_id = this.seller_id;
        if (this.brands_array.length > 0) {
            array_params.brand = this.brands_array.toString();
        }
        if (this.category_array.length > 0) {
            array_params.category = this.category_array.toString();
        }
        if (this.marketplace_array.length > 0) {
            array_params.maps = this.marketplace_array.toString();
        }
        if (this.price_array.length > 0) {
            array_params.price = this.price_array.toString();
        }
        if (this.variant_array.length > 0) {
            array_params.variant = this.variant_array.toString();
        }
        if (this.supply_locations_array.length > 0) {
            array_params.slo = this.supply_locations_array.toString();
        }

        array_params.limit = this.page_per_view;
        array_params.offset = this.offset;
        array_params.current_page = this.current_page;
        array_params.sort_by = this.sort_by;

        this.router.navigate(['/seller-info/'], { queryParams: array_params })
    }

    changesorting() {
        
    }

}
