<div class="card p-md-3 p-2 px-md-4 helppara radius15">
	<div class="card-body">
		<div class="row mb-4 ">
			<div class="col-sm-12">
				<p class="txtseconday">Report or raise an issue against the seller? <a class="warmlink" href="javascript:void(0);">Go to Assitance </a></p>
			</div>							
		</div>
		<div class="mb-4">
			<h4 class="mb-3 txtdefault f-600 fs16">To contact Seller write to:</h4>
			<p class="parahelp txtseconday">{{ help_seller_detail.trade_name }} <br />
				Subject: Contact Seller - {{ help_seller_detail.gstin }}<br />
				c/o Equibiz Seller Services Private Limited,<br />
				{{ help_seller_detail.address }}</p>
		</div>
		<div class="row">
			<div class="col-sm-12 txtseconday">
				<p>Equibiz is a marketplace on which third party sellers sell products to customers. In order to contact a seller directly:</p>
				<p>1. Please write to the address mentioned above.</p>
				<p>2. Insert the link to the seller storefront (URL of this page), if possible.</p>
				<p>3. Subject line should state: “Contact Seller”.</p>
				<p>4. Please mention “To Seller Mailbox; Contact Seller” on the envelope</p>
				<p>Please write to us with the details mentioned above so that your communication can be sent to the concerned seller</p>
			</div>
		</div>
	</div>
  </div> 