<app-header-one [sticky]="true"></app-header-one>
<div class="container pb-5">
    <!-- BREADCRUMB -->
    <nav class="mb-md-2 pt-2 pb-md-2" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
        <ol class="breadcrumb p-0 bg-transparent">
            <li class="breadcrumb-item"><a href="javascript:void(0);" class="txtseconday" [routerLink]="['/home']">Home
                </a></li>
            <li class="breadcrumb-item" *ngFor="let cate of category_flow;">
                <a href="javascript:void(0);" class="txtseconday" (click)="categorylink(cate.slug)">{{ cate.name }}
                </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">{{ category_name }}</li>
        </ol>
    </nav>
    <!-- /BREADCRUMB -->

    <div class="row">
        <!-- FILTER -->
        <div class="col-lg-3 mt-2 pt-2 filtercol filtermbl" [ngClass]="classstatus ? 'showfilter' : ''">
            <!-- <div class="brand-sbx p-3 radius10">
				<h4 class="fs16 wt700 txtdefault mb-2">Search what you are looking for?</h4>
				<div class="srch-ipt">
				   <input class="form-control fs14 border" type="text" placeholder="Search here" id="example-search-input">
				   <img src="assets/images/svg/search_logo.svg">
				</div>
			</div> -->
            <div class="fltrshead">
                <h4>Filter</h4>
                <a (click)="tglclass()" class="closefilter" href="javascript:void(0);"><svg _ngcontent-serverApp-c132=""
                        xmlns="http://www.w3.org/2000/svg" width="6.508" height="6.508" viewBox="0 0 6.508 6.508">
                        <path _ngcontent-serverApp-c132="" id="np_cross_1707162_000000"
                            d="M30.883,30.883a.507.507,0,0,1,.717,0l2.389,2.389,2.389-2.389a.507.507,0,0,1,.717.717L34.7,33.988l2.389,2.389a.507.507,0,0,1-.717.717L33.988,34.7,31.6,37.094a.507.507,0,0,1-.717-.717l2.389-2.389L30.883,31.6A.507.507,0,0,1,30.883,30.883Z"
                            transform="translate(-30.734 -30.734)" fill="#1f1d44"></path>
                    </svg></a>
            </div>
            <div class="lsting">
                <ngb-accordion #acc="ngbAccordion" activeIds="{{ active_ids }}" class="accordion">
                    <ngb-panel id="toggle-1" opened class="bg-transparent" title="Market Preference">
                        <ng-template ngbPanelContent>
                            <ul class="list-group">
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" value="bulk"
                                            (click)="gotosearch('market_preference', 'bulk')" />
                                        <label class="css-label">Bulk</label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" value="wholesale"
                                            (click)="gotosearch('market_preference', 'wholesale')" />
                                        <label class="css-label">Wholesale</label>
                                    </div>
                                </li>
                            </ul>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="toggle-2" title="Product Types">
                        <ng-template ngbPanelContent>
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let category of filter_category; let i = index;">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" value="{{ category.slug }}"
                                            (change)="gotosearch('category', category.id)" />
                                        <label class="css-label">{{ category.name }} ({{ category.count }})</label>
                                    </div>
                                </li>
                            </ul>
                        </ng-template>
                    </ngb-panel>
                    
                    <ngb-panel id="brand" title="Brands">
                        <ng-template ngbPanelContent>
                            <div class="input-group mb-3 inptsearch">
                                <span class="input-group-text">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.685" height="13.681"
                                        viewBox="0 0 13.685 13.681">
                                        <path id="np_search_1120430_000000"
                                            d="M29.587,29.163l-2.728-2.748A5.6,5.6,0,0,0,28.3,22.649a5.649,5.649,0,1,0-5.65,5.669,5.578,5.578,0,0,0,3.767-1.461l2.728,2.728a.281.281,0,0,0,.423,0,.261.261,0,0,0,.019-.422Zm-6.937-1.5a5.025,5.025,0,1,1,5.034-5.016A5.052,5.052,0,0,1,22.65,27.665Z"
                                            transform="translate(-16.501 -16.5)" fill="#5e6a7c" stroke="#5e6a7c"
                                            stroke-width="1" />
                                    </svg>
                                </span>
                                <input type="text" class="form-control" placeholder="Search here"
                                    ([ngModel])="brand_search">
                            </div>
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let brand of filter_brands; let i = index;">
                                    <!-- | filter:{name: brand_search} -->
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" value="brand.slug"
                                            (change)="gotosearch('brands', brand.id)" />
                                        <label class="css-label">{{ brand.brand_name }}</label>
                                    </div>
                                </li>
                            </ul>
                            <!-- <a class="vwmre" href="javascript:void(0);">View More</a> -->
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="startingprice" title="Starting Price">
                        <ng-template ngbPanelContent>
                            <div class="input-group mb-3 inptsearch">
                                <span class="input-group-text">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.685" height="13.681"
                                        viewBox="0 0 13.685 13.681">
                                        <path id="np_search_1120430_000000"
                                            d="M29.587,29.163l-2.728-2.748A5.6,5.6,0,0,0,28.3,22.649a5.649,5.649,0,1,0-5.65,5.669,5.578,5.578,0,0,0,3.767-1.461l2.728,2.728a.281.281,0,0,0,.423,0,.261.261,0,0,0,.019-.422Zm-6.937-1.5a5.025,5.025,0,1,1,5.034-5.016A5.052,5.052,0,0,1,22.65,27.665Z"
                                            transform="translate(-16.501 -16.5)" fill="#5e6a7c" stroke="#5e6a7c"
                                            stroke-width="1" />
                                    </svg>
                                </span>
                                <input type="text" class="form-control" placeholder="Search here">
                            </div>
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let price of filter_prices; let i = index;">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox"
                                            (change)="gotosearch('price', price.min, price.max)" />
                                        <label class="css-label">{{ price.name }}</label>
                                    </div>
                                </li>
                            </ul>
                            <div class="pricerange">
                                <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"
                                    (click)="gotosearch('price-slider', '', '')"></ngx-slider>
                                <div class="row g-2">
                                    <div class="col-6">
                                        <input class="form-control" type="number" [(ngModel)]="minValue"
                                            (change)="gotosearch('price-slider', '', '')">
                                    </div>
                                    <div class="col-6">
                                        <input class="form-control" type="number" [(ngModel)]="maxValue"
                                            (change)="gotosearch('price-slider', '', '')">
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel *ngFor="let variant of filter_variants; let i = index;" id="{{variant.slug}}"
                        title="{{ variant.name }}">
                        <ng-template ngbPanelContent>
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let meta of variant.metas; let k = index;">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" value="{{ meta.slug }}"
                                            (change)="gotosearch('variant', meta.slug)" />
                                        <label class="css-label">{{ meta.name }}</label>
                                    </div>
                                </li>
                            </ul>
                        </ng-template>
                    </ngb-panel>

                    <ngb-panel *ngFor="let variant of filter_attribute; let i = index;" id="{{variant.name}}"
                        title="{{ variant.name }}">
                        <ng-template ngbPanelContent>
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let meta of variant.metas; let k = index;">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" value="{{ meta.slug }}"
                                            (change)="gotosearch('technical', meta.slug)" />
                                        <label class="css-label">{{ meta.name }}</label>
                                    </div>
                                </li>
                            </ul>
                        </ng-template>
                    </ngb-panel>

                    
                </ngb-accordion>
               
                <div class="tex-center clr-apl-btn showintablet">
                    <a href="javascript:void(0);" class="buybtn d-inline-block radius10">Apply</a>
                </div>
            </div>
        </div>
        <!-- /FILTER -->
        <!-- LISTING -->
        <div class="col-lg-9 pt-3 listing-content">
            <!-- TOTAL LISTING -->
            <div class="row">
                <div class="col-md-6 mb-3">
                    <div class="tl-list tl-acs  radius10">
                        <h4 class="fs20 wt700 textwhite mb-2">{{ category_name }}</h4>
                        <p class="mb-0">&nbsp;</p>
                    </div>
                </div>
                <div class="col-md-6 mb-3">
                    <div class="tl-list radius10">
                        <h4 class="fs20 wt700 txtdefault mb-2">{{ total_listing }} listings <i class="fs14">from {{
                                total_supplier }} Suppliers</i></h4>
                        <span class="fs12 txtdefault mr-4"><img class="mr-1" src="assets/images/svg/bulk.svg" /> {{
                            total_bulk }} Bulk</span>
                        <span class="fs12 txtdefault"><img class="mr-1" src="assets/images/svg/wholesale.svg" /> {{
                            total_wholesale }} Wholesale</span>
                        <img class="bulkfile" src="assets/images/svg/file.svg" />
                    </div>
                </div>
                
            </div>

            <!-- /TOTAL LISTING  -->
            <!-- SORTING -->
            <div class="row d-flex align-items-center">
                <div class="col-lg-8  srtlist">
                    <h3 class="txtdefault f-700 fs16">{{ category_name }} <span class="fs14 wt400">({{
                            total_products }} products)</span></h3>
                </div>
                <div class="col-lg-4 listingsort">
                    <div class="fltsortnv mt-0">
                        <a href="javascript:void(0);" (click)="ratingsort()" class="sorticon mr-2">
                            <img src="assets/images/svg/sort.svg">
                        </a>
                        <a href="javascript:void(0);" (click)="tglclass()" class="filtericon">
                            <img src="assets/images/svg/filter.svg">
                        </a>
                    </div>
                </div>
            </div>
            <!-- /SORTING  -->
            <div class="pb-3" *ngIf="brands_list.length > 0">
                <h4 class="txtdefault f-700 fs16 mb-10">Top Brands</h4>
                <div class="tpbrand mt-10">
                    <div class="row align-items-center text-center">
                        <div class="col-3 col-md-2" *ngFor="let brand of brands_list; let i = index;">
                            <a href="javascript:void(0);" class="active" (click)="gotosearch('brands', brand.id)">
                                {{ brand.brand_name }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- LATEST ARRIVAL PRODUCT ITEMS -->
            <div class="prdt-rw pt-4" *ngIf="latest_products.length > 0">
                <h4 class="wt700 fs16 txtdefault mb-3 pb-1">{{ latest_products_name }} &nbsp;
                    <a href="javascript:;" class="link-alt fs14" (click)="gotosearch('common_link', category_name)">View all</a></h4>
                <div class="row p-list-item">
                    <!-- REPEAT -->
                    <div class="col-6 col-md-4 col-xl-3  mb-3" *ngFor="let latest of latest_products; let i = index;">
                        <div class="product-item  pt-2" [routerLink]="['/product/details/'+latest.unique_id]">
                            <div class="ex-deal" style="display:block" *ngIf="latest.total_bid > 0"><span>{{
                                    latest.total_bid }} {{ latest.total_bid > 1 ?'Bids': 'Bid' }}</span></div>
                            <div class='image pt-0'>
                                <div class="d-table">
                                    <div class="d-table-cell align-middle">
                                        <img [src]='latest.p_front_image'>
                                    </div>
                                </div>
                            </div>
                            <div class="product-name fs14">
                                {{ latest.name }}
                            </div>
                            <div class="ratings">
                                <ngb-rating [max]="5" [(rate)]="latest.star_rating" [readonly]="true" class="rating_star"></ngb-rating>
                                <span class='star-count'>({{latest.total_rating}})</span>
                            </div>
                            <div class="row no-gutters d-flex justify-content-between">
                                <div class="col">
                                    <div class="seller-count">
                                        Total Listing <br /> <span>{{ latest.total_seller }} Sellers</span>
                                    </div>
                                </div>
                                <div class="col-sm-7">
                                    <div class="price">
                                        Starting at <br /><span><i class="fa fa-inr"></i> {{ latest.sales_price }} <b
                                                class="strikethrough">{{ latest.regular_price }}</b></span>
                                    </div>
                                </div>
                                <div class="initiate-bid" *ngIf="latest.bulk">
                                    <a href="javascript:void(0);" (click)="initiatebid(latest.unique_id)" class="btn-d-outline mr-2 d-inline-block place-bid">Initiate Bid</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /REPEAT -->

                </div>
                <div class="text-center">
                    <a href="javascript:;" (click)="gotosearch('common_link', category_name)" class="btn-d-outline d-inline-block">View All</a>
                </div>
            </div>
            <!-- / LATEST ARRIVAL PRODUCT ITEMS -->

            <div class="my-4 pbnr" *ngIf="latest_arrival_banner">
                <img src='{{ latest_arrival_banner}}'>
            </div>

            <!-- TOP SELLING PRODUCT ITEMS -->
            <div class="prdt-rw pt-4" *ngIf="top_selling_products.length > 0">
                <h4 class="wt700 fs16 txtdefault mb-3 pb-1">{{top_selling_name}} &nbsp;
                    <a href="javascript:;" class="link-alt fs14" (click)="gotosearch('common_link', category_name)">View all</a></h4>
                <div class="row p-list-item">
                    <!-- REPEAT -->
                    <div class="col-6 col-md-4 col-xl-3  mb-3"
                        *ngFor="let product of top_selling_products; let i = index;">
                        <div class="product-item  pt-2" [routerLink]="['/product/details/'+product.unique_id]">
                            <div class="ex-deal" style="display:block" *ngIf="product.total_bid > 0">
                                <span>{{product.total_bid}} {{ product.total_bid > 1 ?'Bids': 'Bid' }}</span>
                            </div>
                            <div class='image pt-0'>
                                <div class="d-table">
                                    <div class="d-table-cell align-middle">
                                        <img [src]='product.p_front_image'>
                                    </div>
                                </div>
                            </div>
                            <div class="product-name fs14">
                                {{ product.name }}
                            </div>
                            <div class="ratings">
                                <ngb-rating [max]="5" [(rate)]="product.star_rating" [readonly]="true" class="rating_star"></ngb-rating>
                                <span class='star-count'>({{product.total_rating}})</span>
                            </div>
                            <div class="row no-gutters d-flex justify-content-between">
                                <div class="col">
                                    <div class="seller-count">
                                        Total Listing <br /> <span>{{ product.total_seller }} Sellers</span>
                                    </div>
                                </div>
                                <div class="col-sm-7">
                                    <div class="price">
                                        Starting at <br /><span><i class="fa fa-inr"></i> {{ product.sales_price }} <b
                                                class="strikethrough">{{ product.regular_price }}</b></span>
                                    </div>
                                </div>
                                <div class="initiate-bid" *ngIf="product.bulk">
                                    <a href="javascript:void(0);" (click)="initiatebid(product.unique_id)" class="btn-d-outline mr-2 d-inline-block place-bid">Initiate Bid</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /REPEAT -->
                </div>

                <div class="text-center">
                    <a href="javascript:;" (click)="gotosearch('common_link', category_name)" class="btn-d-outline d-inline-block">View All</a>
                </div>
            </div>
            <!-- /TOP SELLING PRODUCT ITEMS -->

            <div class="my-4 pbnr" *ngIf="top_selling_banner">
                <img src='{{ top_selling_banner}}'>
            </div>

            <!-- FAST MOVING PRODUCT ITEMS -->
            <div class="prdt-rw" *ngIf="fast_selling_products.length > 0">
                <h4 class="wt700 fs16 txtdefault mb-3 pb-1">{{ fast_selling_name }}
                    <!-- <b>{{ selected_brand.brand_name }}</b> -->
                    &nbsp;
                    <a href="javascript:;" class="link-alt fs14" (click)="gotosearch('common_link', category_name)">View all</a>
                    </h4>
                <div class="row p-list-item">
                    <!-- REPEAT -->
                    <div class="col-6 col-md-4 col-xl-3  mb-3"
                        *ngFor="let product of fast_selling_products; let i = index;">
                        <div class="product-item  pt-2" [routerLink]="['/product/details/'+product.unique_id]">
                            <div class="ex-deal" style="display:block" *ngIf="product.total_bid > 0"><span>{{
                                product.total_bid }} {{ product.total_bid > 1 ?'Bids': 'Bid' }}</span></div>
                            <div class='image pt-0'>
                                <div class="d-table">
                                    <div class="d-table-cell align-middle">
                                        <img [src]='product.p_front_image' title="{{ product.name }}">
                                    </div>
                                </div>
                            </div>
                            <div class="product-name fs14">
                                <span title="{{ product.name }}">{{ product.name }}</span>
                            </div>
                            <div class="ratings">
                                <ngb-rating [max]="5" [(rate)]="product.star_rating" [readonly]="true" class="rating_star"></ngb-rating>
                                <span class='star-count'>({{product.total_rating}})</span>
                            </div>
                            <div class="row no-gutters d-flex justify-content-between">
                                <div class="col-5">
                                    <div class="seller-count">
                                        Total Listing <br /> <span>{{ product.total_seller }} Sellers</span>
                                    </div>
                                </div>
                                <div class="col-7">
                                    <div class="price">
                                        Starting at <br /><span><i class="fa fa-inr"></i> {{ product.sales_price }} <b
                                                class="strikethrough">{{ product.regular_price }}</b></span>
                                    </div>
                                </div>
                                <div class="initiate-bid" *ngIf="product.bulk">
                                    <a href="javascript:void(0);" (click)="initiatebid(product.unique_id)" class="btn-d-outline mr-2 d-inline-block place-bid">Initiate Bid</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /REPEAT -->
                </div>
                <div class="text-center">
                    <a href="javascript:;" (click)="gotosearch('common_link', category_name)" class="btn-d-outline d-inline-block">View All</a>
                </div>
            </div>
            <!-- /FAST MOVING PRODUCT ITEMS -->

            <div class="my-4 pbnr" *ngIf="fast_selling_banner">
                <img src='{{ fast_selling_banner}}'>
            </div>

            <!-- DYNAMIC SECTION PRODUCT ITEMS -->
            <div class="prdt-rw pt-4" *ngIf="dynamic_section_products.length > 0 && dynamic_section_status == 'yes'">
                <h4 class="wt700 fs16 txtdefault mb-3 pb-1">{{ dynamic_section_name }} &nbsp;
                    <a href="javascript:;" class="link-alt fs14" (click)="gotosearch('common_link', category_name)">View all</a></h4>
                <div class="row p-list-item">
                    <!-- REPEAT -->
                    <div class="col-6 col-md-4 col-xl-3  mb-3" *ngFor="let record of dynamic_section_products; let i = index;">
                        <div class="product-item  pt-2" [routerLink]="['/product/details/'+record.unique_id]">
                            <div class="ex-deal" style="display:block" *ngIf="record.total_bid > 0"><span>{{
                                    record.total_bid }} {{ record.total_bid > 1 ?'Bids': 'Bid' }}</span></div>
                            <div class='image pt-0'>
                                <div class="d-table">
                                    <div class="d-table-cell align-middle">
                                        <img [src]='record.p_front_image'>
                                    </div>
                                </div>
                            </div>
                            <div class="product-name fs14">
                                {{ record.name }}
                            </div>
                            <div class="ratings">
                                <ngb-rating [max]="5" [(rate)]="record.star_rating" [readonly]="true" class="rating_star"></ngb-rating>
                                <span class='star-count'>({{record.total_rating}})</span>
                            </div>
                            <div class="row no-gutters d-flex justify-content-between">
                                <div class="col">
                                    <div class="seller-count">
                                        Total Listing <br /> <span>{{ record.total_seller }} Sellers</span>
                                    </div>
                                </div>
                                <div class="col-sm-7">
                                    <div class="price">
                                        Starting at <br /><span><i class="fa fa-inr"></i> {{ record.sales_price }} <b
                                                class="strikethrough">{{ record.regular_price }}</b></span>
                                    </div>
                                </div>
                                <div class="initiate-bid" *ngIf="record.bulk">
                                    <a href="javascript:void(0);" (click)="initiatebid(record.unique_id)" class="btn-d-outline mr-2 d-inline-block place-bid">Initiate Bid</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /REPEAT -->

                </div>
                <div class="text-center">
                    <a href="javascript:;" (click)="gotosearch('common_link', category_name)" class="btn-d-outline d-inline-block">View All</a>
                </div>
            </div>
            <!-- /DYNAMIC SECTION PRODUCT ITEMS -->
            
            <div class="my-4 pbnr" *ngIf="dynamic_section_banner">
                <img src='{{ dynamic_section_banner}}'>
            </div>
            

        </div>
        <!-- /LISTING -->
    </div>
</div>

<app-footer-one></app-footer-one>