import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from '../../helpers/toastr.service';
import { MediaService } from './media.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { AddNewProductService } from '../add-new-product/add-new-product.service';
import { ViewProductDetailsService } from '../view-product-details/view-product.service';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {

    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 20;
    public media_pagination: any = {};
    public page_per_view_options = [
        {
            value: 20,
            name: 20
        },
        {
            value: 40,
            name: 40
        }
    ];

    closeResult = '';
    public search_term: string = '';
    public records:any = [];
    public media_files:any = [];
    public filter_category:string = '';
    public filter_brand:string = '';
    public brand:string = '';
    public category:string = '';

    public brand_list:any = [];
    public category_list:any = [];
  constructor(
    private modalService: NgbModal,
    private _pageservice: MediaService,
    private _toastr: ToasterService,
    private _pagination: PaginationService,
    private clipboard: Clipboard,
    private _addnewproductservice: AddNewProductService,
    private _viewproductdetails: ViewProductDetailsService,
    ) { }

    ngOnInit(): void {
        this.medialist();
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.medialist();
    }

    setpageperview() {
        this.current_page = 1;
        this.medialist();
    }

    copyText(textToCopy: string) {
        this.clipboard.copy(textToCopy);
        this._toastr.success('Image url copied successfully.');
    }

    open(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true,}).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    medialist() {
        var data = {
            search: this.search_term,
            category: this.filter_category,
            brand: this.filter_brand,
            limit: this.page_per_view, 
            offset: this.offset, 
            current_page: this.current_page, 
        }
        this._pageservice.getmedia(data).subscribe((data) => {
            if(data['status'] == "success"){
                this.records = data['records'];
                let total_records = data['total_records'];
                this.media_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    addfiles() {
        let data: any = new FormData();
        for (var i = 0; i < this.media_files.length; i++) {
            data.append("file[]", this.media_files[i]);
        }
        data.append('category', this.category);
        data.append('brand', this.brand);
        this._pageservice.savemedia(data).subscribe((data) => {
            if(data['status'] == "success"){
                this._toastr.success('New media files has been added successfully.');
                this.medialist();
                this.modalService.dismissAll();
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    uploadFile(event: any) {
        this.media_files = [];
        for (var i = 0; i < event.target.files.length; i++) {
            this.media_files.push(event.target.files[i]);
        }
    }

    keyword = 'name';
    brandKeyword = 'name';

    selectEvent(item) {
        this.category = item.c_id;
    }
    selectFilterCategoryEvent(item) {
        this.filter_category = item.c_id;
        this.medialist();
    }


    onChangeSearch(val: string) {
        this._viewproductdetails.searchcategory({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.category_list = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    onFocused(e) {
        // do something when input is focused
    }

    selectBrandEvent(item) {
        this.brand = item.id;
    }

    selectFilterBrandEvent(item) {
        this.filter_brand = item.id;
        this.medialist();
    }

    onChangeSearchBrand(val: string) {
        this._addnewproductservice.searchbrand({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.brand_list = data['searchResult'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    onFocusedBrand(e) {
        // do something when input is focused
    }
    

}
