import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SignupModalComponent } from "../components/modal/signup-modal/signup-modal.component";
import { SignupService } from "../components/modal/signup-modal/signup-modal.service";

@Component({
  selector: 'app-equibiz-pay-later',
  templateUrl: './equibiz-pay-later.component.html',
  styleUrls: ['./equibiz-pay-later.component.scss']
})
export class EquibizPayLaterComponent implements OnInit {
  @ViewChild("signIn") SignIn: SignupModalComponent;
  public token: string;

  constructor(
    public _singupservice: SignupService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.token = this._singupservice.gettoken();
    setTimeout(() => {
      const token = this.route.snapshot.paramMap.get('reset-token');
      if (token) {
        this.SignIn.openSigninModal('reset', '', token);
      }
      const settoken = this.route.snapshot.paramMap.get('signup-token');
      if (settoken) {
        this.SignIn.openSigninModal('set', '', settoken);
      }
    }, 500);
  }

}
