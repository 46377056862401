import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class EncrDecrService {
  constructor() { }
 
  //The set method is use for encrypt the value.
  set(value){
    if(value){
      var keys = environment.keyforencdec;
      var key = CryptoJS.enc.Utf8.parse(keys);
      var iv = CryptoJS.enc.Utf8.parse(keys);
      var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
      {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });
  
      return encrypted.toString().replace('+','m43PPbm2FE').replace('/','8TbeV5c8iO').replace('=','3RWq3ereF4');
    }else{
      return '';
    }
    
  }

  //The get method is use for decrypt the value.
  get(value){
    var keys = environment.keyforencdec;
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var value = value.toString().replace('m43PPbm2FE', '+' ).replace('8TbeV5c8iO', '/').replace('3RWq3ereF4', '=');
    var decrypted = CryptoJS.AES.decrypt(value, key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}