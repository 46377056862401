<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<h3 class="fs24 wt700 txtdefault">Wallet Details</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Wallet</a>
						</li>
						<li class="breadcrumb-item active">Wallet Details</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4 mb-40">
			<div class="rg-b-form">
				<div class="form-container input-group mt-0 mb-0">
					<div class="floating-label mb-0">      
						<input type="text" value="{{ wallet_details.wallet_balance }}" disabled class="form-control" placeholder=" " />
						<span class="highlight"></span>
						<label>Wallet Balance</label>
					</div> 
					<!-- <span class="input-group-addon">
						<button [routerLink]="['/wallet-balance']" class="btn btn-outline-secondary fs16 btn-o-default btn-block d-flex align-items-center" type="button">
							<span class="icon">+</span> Add Money
						</button>
					</span> -->
				</div>
			</div>
			<div class="form-container mdl-frm-lbl mb-3">
				<div class="row">
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input value="{{ wallet_details.user_id }}" disabled type="text" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>UserID</label>
						</div> 
					</div>
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input value="{{ wallet_details.account_number }}" disabled type="text" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>Your Equibiz Account Number</label>
						</div> 
					</div>	
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input value="{{ wallet_details.name }}" disabled type="text" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>Beneficiary Name</label>
						</div> 
					</div>
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input value="{{ wallet_details.ifsc }}" disabled value="" type="text" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>Your Equibiz IFSC</label>
						</div> 
					</div>	
				</div>
				<div class="text-center">
					<!-- <a href="javascript:void(0);" class="mr-sm-4 btn-d-outline d-inline-block">Add money to the Wallet</a> -->
					<a href="javascript:void(0);" class="mr-sm-4 btn-d-outline d-inline-block" [routerLink]="['/admin/wallet/transaction-history']">View Your Transactions</a>
					<!-- <a href="javascript:void(0);" class="btn-d-outline d-inline-block" [routerLink]="['/admin/settings/bank-account-details']">View Bank Details</a> -->
				</div>
			</div>
		</div>
	</div>
</div>

