import { Component, OnInit, Input, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-admin-article-details',
  templateUrl: './admin-article-details.component.html',
  styleUrls: ['./admin-article-details.component.scss']
})
export class AdminArticleDetailsComponent implements OnInit {

    @Input() class: string;
    @Input() sticky: boolean = false; // Default false

    public stick: boolean = false;
    public closeResult: string;
    constructor(private modalService: NgbModal) { }

    currentSection = 'scroll1';

    onSectionChange(sectionId: string) {
        this.currentSection = sectionId;
    }

    scrollTo(section) {
        document.querySelector('#' + section)
            .scrollIntoView();
    }

    ngOnInit(): void {

    }
    disabled = false;
    // @HostListener Decorator
    @HostListener("window:scroll", [])
    onWindowScroll() {
        let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (number >= 150 && window.innerWidth > 400) {
            this.stick = true;
        } else {
            this.stick = false;
        }
    }


    open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

}
