import { Component, OnInit } from '@angular/core';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { SignupService } from 'src/app/shared/components/modal/signup-modal/signup-modal.service';
import { TransportationDocumentService } from './transportation-document.service';

@Component({
  selector: 'app-transportation-document',
  templateUrl: './transportation-document.component.html',
  styleUrls: ['./transportation-document.component.scss']
})
export class TransportationDocumentComponent implements OnInit {

    public search_term:string = '';
    public from_date:any = '';
    public to_date:any = '';
    public seller_type:string;

    public invoice_type:string = 'mydocuments';

    constructor(
        private _pageservice: TransportationDocumentService,
        private _toastr: ToasterService,
        private _pagination: PaginationService,
        private _signupservice: SignupService
    ) { }

    ngOnInit(): void {
        this.seller_type = this._signupservice.getsellertype();
        this._signupservice.sellerTypeChanged.subscribe((response: string) => {
            this.seller_type = this._signupservice.getsellertype();
        });
        this.getinvoicerecords();
    }

    public page_records: any = [];
    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 10;
    public page_pagination: any = {};
    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        if(this.invoice_type == 'mydocuments'){
            this.invoicerecords();
        }else{
            this.ssinvoicerecords();
        }
    }

    setpageperview() {
        this.current_page = 1;
        if(this.invoice_type == 'mydocuments'){
            this.invoicerecords();
        }else{
            this.ssinvoicerecords();
        }
    }

    changeinvoicetype(type){
        this.invoice_type = type;
        this.getinvoicerecords();
    }

    getinvoicerecords() {
        this.search_term = '';
        this.from_date = '';
        this.to_date = '';
        this.page_per_view = 10;
        this.offset = 0;
        this.current_page = 1;
        if(this.invoice_type == 'mydocuments'){
            this.invoicerecords();
        }else{
            this.ssinvoicerecords();
        }
        
    }

    getdocumentsrecordsfilters(){
        this.page_per_view = 10;
        this.offset = 0;
        this.current_page = 1;
        if(this.invoice_type == 'mydocuments'){
            this.invoicerecords();
        }else{
            this.ssinvoicerecords();
        }
    }

    invoicerecords() {
        let data = { 
            limit: this.page_per_view, 
            offset: this.offset, 
            current_page: this.current_page, 
            search: this.search_term,
            from_date: this.from_date,
            to_date: this.to_date
        };
        this._pageservice.invoicerecords(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.page_records = data['records'];
                let total_records = data['total_records'];
                this.page_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    ssinvoicerecords() {
        let data = { 
            limit: this.page_per_view, 
            offset: this.offset, 
            current_page: this.current_page, 
            search: this.search_term,
            from_date: this.from_date,
            to_date: this.to_date
        };
        this._pageservice.subsellerinvoice(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.page_records = data['records'];
                let total_records = data['total_records'];
                this.page_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }
}
