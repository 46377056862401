<app-header-buyer></app-header-buyer>
<app-sidebar-buyer></app-sidebar-buyer>
<div class="wrpmain-buyer">
    <div class="container">
        <!--- HEAD  --->
        <div class="row align-items-center mb-4 pinfo-head">
            <div class="col-sm-6 col-7 pr-0">
                <nav class="breadcrumb breadcrumb-dui mb-0">
                    <ol class="breadcrumb p-0 bg-transparent mb-0">
                        <li class="breadcrumb-item">
                            <a
                                class="txtseconday"
                                href="javascript:void(0);"
                                [routerLink]="['/home']"
                            >
                                Home
                            </a>
                        </li>
                        <li class="breadcrumb-item">
                            <a
                                class="txtseconday"
                                [routerLink]="['/buyer/buyer-wallet']"
                            >
                                Wallet and Payments
                            </a>
                        </li>
                        <li class="breadcrumb-item active">
                            Add Money Confirmation
                        </li>
                    </ol>
                </nav>
            </div>
            <div class="col-sm-6 col-5 text-right pl-0">
                <a
                    href="javascript:void(0);"
                    [routerLink]="['/buyer/assistance']"
                    class="btn buybtn fs16 radius10 ripple"
                    >Assistance</a
                >
            </div>
        </div>
        <!--- /HEAD  --->
        <h3 class="f-700 fs22 txtdefault pb-10">
            <a href="javascript:;" class="go-back"
                ><i class="fa fa-angle-left"></i
            ></a>
            Add Money Confirmation
        </h3>

        <div class="row">
            <div class="col-md-12">
                <div class="alert alert-success" *ngIf="successMessage">
                    {{ successMessage }}
                </div>
                <div class="alert alert-danger" *ngIf="errorMessage">
                    {{ errorMessage }}
                </div>
            </div>
        </div>
    </div>
</div>
