import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { CmsTermsofUseService } from './terms-of-use.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class CmsTermsOfUseComponent implements OnInit {

    config: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '15rem',
        minHeight: '5rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        defaultParagraphSeparator: 'p',
        defaultFontName: 'Arial',
        toolbarHiddenButtons: [],
        customClasses: [
          {
            name: 'quote',
            class: 'quote',
          },
          {
            name: 'redText',
            class: 'redText',
          },
          {
            name: 'titleText',
            class: 'titleText',
            tag: 'h1',
          },
        ],
      };
    
    public terms_of_use:any = '';
    public cms_type:string = 'terms-of-use';
    public buttonLoading:boolean = false;

    constructor(
        private _cmsservice: CmsTermsofUseService,
        private _toastr: ToasterService
    ) { }

    ngOnInit(): void {
        this.getcmsdata();
    }

    cmsupdate(){
        this.buttonLoading = true;
        this._cmsservice.savecms({type: this.cms_type, values: this.terms_of_use }).subscribe((data) => {
            if (data['status'] == "success") {
                this.buttonLoading = false;
                this._toastr.success('CMS details has been updated successfully');
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getcmsdata() {
        this._cmsservice.getcms({type: this.cms_type}).subscribe((data) => {
            if (data['status'] == "success") {
                this.terms_of_use = data['values'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

}
