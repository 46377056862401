<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">User Management</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:;" [routerLink]="['/admin/manage-buyer']" *ngIf="personal_detail.usertype == 1">Buyer</a>
							<a class="txtseconday" href="javascript:;" [routerLink]="['/admin/manage-seller']" *ngIf="personal_detail.usertype == 2">Seller</a>
						</li>
						<li class="breadcrumb-item active">View Details</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:;" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="row d-flex align-items-center justify-content-between mb-20">
			<div class="col-md-6">
				<h3 class="f-700 fs22 txtdefault pb-0" *ngIf="personal_detail.usertype == 1">
					<a href="javascript:;" class="go-back" [routerLink]="['/admin/manage-buyer']">
					<i class="fa fa-angle-left"></i></a> View Details</h3>
				<h3 class="f-700 fs22 txtdefault pb-0" *ngIf="personal_detail.usertype == 2">
					<a href="javascript:;" class="go-back" [routerLink]="['/admin/manage-seller']">
						<i class="fa fa-angle-left"></i></a> View Details</h3>
			</div>

			<div class="col-md-6">
				<div class="registration-status mb-0 text-right">
					<ul class="mb-0">
						<li [ngClass]="{'completed': personal_detail.document_verified}"><i class="fa fa-check-circle"></i>&nbsp;Business Details</li>	
						<li [ngClass]="{'completed': personal_detail.business_verified}"><i class="fa fa-check-circle"></i>&nbsp;Authorized Persons</li>
						<li [ngClass]="{'completed': personal_detail.bank_verified}"><i class="fa fa-check-circle"></i>&nbsp;Bank Details</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="row mb-10">
			<div class="col-md-12 pb-2">
				<div class="custom-tab-menu">
					<ul *ngIf="personal_detail.usertype == 1">
						<li><a href="javascript:;" [routerLink]="['/admin/manage-buyer/view-details/', buyerId]" class="active">Personal Details</a></li>
						<li><a href="javascript:;" [routerLink]="['/admin/manage-buyer/buyer-business-details/', buyerId]" class="active">Business Details</a></li>
						<li><a href="javascript:;" [routerLink]="['/admin/manage-buyer/buyer-bank-details/', buyerId]">Bank Details</a></li>
					</ul>
					<ul *ngIf="personal_detail.usertype == 2">
						<li><a href="javascript:;" [routerLink]="['/admin/manage-seller/view-details/', buyerId]" class="active">Personal Details</a></li>
						<li><a href="javascript:;" [routerLink]="['/admin/manage-seller/seller-business-details/', buyerId]" class="active">Business Details</a></li>
						<li><a href="javascript:;" [routerLink]="['/admin/manage-seller/seller-bank-details/', buyerId]">Bank Details</a></li>
					</ul>
				</div>
			</div>

			<div class="col-md-12 pt-2 pb-2">
				<div class="text-right">
					<a href="javascript:;" [routerLink]="['/admin/manage-buyer/buyer-bank-details/', buyerId]" class="btn buybtn-success fs16 radius10 ripple mr-2" *ngIf="personal_detail.usertype == 1">Continue</a>
					<a href="javascript:;" [routerLink]="['/admin/manage-seller/seller-bank-details/', buyerId]" class="btn buybtn-success fs16 radius10 ripple mr-2" *ngIf="personal_detail.usertype == 2">Continue</a>
					<a href="javascript:;" class="btn buybtn-success fs16 radius10 ripple mr-2" *ngIf="personal_detail.admin_verified == 1">Approved</a>
					<a href="javascript:;" (click)="openmodal(rejectbuyer)" class="btn buybtn-danger fs16 radius10 ripple" *ngIf="personal_detail.admin_verified == 0">Reject</a>
					<a href="javascript:;" class="btn buybtn-danger fs16 radius10 ripple" *ngIf="personal_detail.admin_verified == 2">Rejected</a>
				</div>
			</div>
		</div>

		<div class="card cardui carduipro p-4 mb-2">
			<div class="pb-2">
				<div class="d-table">
					<div class="d-table-cell align-middle proavtwrp">
						<div class="proavt">						
							<img src="assets/images/svg/sellerbuilding.svg">
						</div>
					</div>
					<div class="d-table-cell align-middle pl-3">
						<h4 class="f-700 fs20 txtdefault pb-0">{{ business_detail.buyer_b_name}}
						<img class="collapse" src="assets/images/svg/success.svg" />
						</h4>
						<div class="tg-grp" *ngIf="business_detail.business_category">
							<span>{{ business_detail.business_category}}</span> 
							<!-- <span>Retail Trader</span> -->
						</div>
					</div>
				</div>
			</div>	
		</div>
		<div class="bsn-dtl-p">
		<!-- GST DOCUMENTS -->
		<div class="card cardui carduipro p-4 mb-2">
			<h4 class="f-700 fs20 txtdefault pb-10">GST Documents</h4>
			<div class="profileipt" *ngIf="!business_detail.no_gst">
				<!-- INPUT ROW -->
				<div class="form-container mt-0 mb-4">
					<div class="floating-label mb-10 pr-0"> 
						<input type="text" class="form-control" placeholder=" " readonly value="{{ business_detail.gst_number }}">						  
					   <span class="highlight"></span>
					   <label>GSTIN Number</label>	
						
					</div>
				</div>
				<!-- /INPUT ROW -->				
				<div class="row">
					<!-- INPUT ROW -->
					<div class="col-sm-6 ">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label mb-10 flselcted"> 
								<input type="text" class="form-control" placeholder=" " readonly>
								<button class="btn btn-outline-secondary fs16 btn-o-default" type="button">
									<span><a href="{{ business_detail.gst_document_path?business_detail.gst_document_path:'' }}" target="_blank">{{ business_detail.gst_document_path ? 'View File':'No File' }}</a></span>
									<svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107" viewBox="0 0 18.71 12.107">
								  <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
									<path id="Path_2337" data-name="Path 2337" d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z" transform="translate(-14.582 -27.082)" fill="#ff7135"/>
									<g id="Group_15718" data-name="Group 15718">
									  <g id="Group_15717" data-name="Group 15717">
										<g id="Group_15716" data-name="Group 15716">
										  <path id="Path_2338" data-name="Path 2338" d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4" transform="translate(-36.046 -39.347)" fill="#ff7135"/>
										</g>
									  </g>
									</g>
								  </g>
								</svg>

								</button>	  
							   <span class="highlight"></span>
							   <label>GST Document</label>
							   <div class="docselected"><img src="assets/images/svg/attach.svg"><span>GST Document.pdf</span></div>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
					<!-- INPUT ROW -->
					<div class="col-sm-6">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label mb-10 pr-0"> 
								<input type="text" class="form-control" readonly placeholder=" " value="{{ business_detail.gst_filling_frequency }}">  
							   <span class="highlight"></span>
							   <label>GST Filing Frequency</label>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
					<!-- INPUT ROW -->
					<div class="col-sm-6">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label mb-10 pr-0"> 
								<div class="floating-label-inr">
									<input type="text" class="form-control" readonly placeholder=" " value="{{ business_detail.gst_last_payment_date }}">  
								   <span class="highlight"></span>
								   <label>Last GST Payment Date</label>
							   </div>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
					<!-- INPUT ROW -->
					<div class="col-sm-6">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label mb-10"> 
								<div class="floating-label-inr flselcted">
									<input type="text" class="form-control" placeholder=" ">
									<button class="btn btn-outline-secondary fs16 btn-o-default" type="button">
									<span><a href="{{ business_detail.gst_last_filled_path?business_detail.gst_last_filled_path:'' }}" target="_blank">{{ business_detail.gst_last_filled_path? 'View File': 'No File' }}</a></span>
									<svg xmlns="http://www.w3.org/2000/svg" width="15.415" height="15.415" viewBox="0 0 15.415 15.415">
									  <path id="Path_2419" data-name="Path 2419" d="M7.708,0a7.708,7.708,0,1,0,7.708,7.708A7.7,7.7,0,0,0,7.708,0Zm2.869,8.272H9.133v3.062a.514.514,0,0,1-.514.514H6.8a.514.514,0,0,1-.514-.514V8.272H4.839a.514.514,0,0,1-.405-.83L7.3,3.765a.514.514,0,0,1,.81,0l2.869,3.677A.514.514,0,0,1,10.576,8.272Z" fill="#ff7135"/>
									</svg>
									</button>	  
								   <span class="highlight"></span>
								   <label>Last Filled GST Document</label>
								   <div class="docselected"><img src="assets/images/svg/attach.svg"><span>Last Filled GST Document.pdf</span></div>
							   </div>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
				</div>					
				
			</div>
			<div class="profileipt" *ngIf="business_detail.no_gst">
							
				<div class="row">
					<!-- INPUT ROW -->
					<div class="col-sm-6 ">
						<div class="form-container mt-0 mb-4">
							<div class="floating-label mb-10 pr-0"> 
								<input type="text" class="form-control" placeholder=" " readonly value="{{ business_detail.nogstbusinessdocvalue }}">						  
							<span class="highlight"></span>
							<label>Business Document</label>	
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->	
					<!-- INPUT ROW -->
					<div class="col-sm-6 ">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label mb-10 flselcted"> 
								<input type="text" class="form-control" placeholder=" " readonly>
								<button class="btn btn-outline-secondary fs16 btn-o-default" type="button">
									<span><a href="{{ business_detail.nogstbusinessdocpath? business_detail.nogstbusinessdocpath: ''}}" target="_blank">{{ business_detail.nogstbusinessdocpath?'View File': 'No File' }}</a></span>
									<svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107" viewBox="0 0 18.71 12.107">
								  <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
									<path id="Path_2337" data-name="Path 2337" d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z" transform="translate(-14.582 -27.082)" fill="#ff7135"/>
									<g id="Group_15718" data-name="Group 15718">
									  <g id="Group_15717" data-name="Group 15717">
										<g id="Group_15716" data-name="Group 15716">
										  <path id="Path_2338" data-name="Path 2338" d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4" transform="translate(-36.046 -39.347)" fill="#ff7135"/>
										</g>
									  </g>
									</g>
								  </g>
								</svg>

								</button>	  
							   <span class="highlight"></span>
							   <label>Business Document</label>
							   <div class="docselected"><img src="assets/images/svg/attach.svg"><span>Business Document.pdf</span></div>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
				</div>					
				
			</div>
		</div>
		<!-- /GST DOCUMENTS -->
		<!-- Identity DOCUMENTS -->
		<div class="card cardui carduipro p-4 mb-2">
			<h4 class="f-700 fs20 txtdefault pb-10">Identity Documents</h4>
			<div class="profileipt">
				<div class="row">
					<!-- INPUT ROW -->
					<div class="col-sm-6 ">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label mb-10 pr-0"> 
								<input type="text" class="form-control" placeholder="" readonly value="{{ business_detail.pan_number }}">
							   <span class="highlight"></span>
							   <label>PAN Number</label>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
					<!-- INPUT ROW -->
					<div class="col-sm-6">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label mb-10 flselcted"> 
								<input type="text" readonly class="form-control" placeholder=" ">
								<button class="btn btn-outline-secondary fs16 btn-o-default" type="button">
									<span><a href="{{business_detail.pan_document_path?business_detail.pan_document_path:''}}" target="_blank">{{ business_detail.pan_document_path? 'View File' : 'No File' }}</a></span>
									<svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107" viewBox="0 0 18.71 12.107">
								  <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
									<path id="Path_2337" data-name="Path 2337" d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z" transform="translate(-14.582 -27.082)" fill="#ff7135"/>
									<g id="Group_15718" data-name="Group 15718">
									  <g id="Group_15717" data-name="Group 15717">
										<g id="Group_15716" data-name="Group 15716">
										  <path id="Path_2338" data-name="Path 2338" d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4" transform="translate(-36.046 -39.347)" fill="#ff7135"/>
										</g>
									  </g>
									</g>
								  </g>
								</svg>								
								</button>	  
							   <span class="highlight"></span>
							   <label>PAN Card Document</label>
							   <div class="docselected"><img src="assets/images/svg/attach.svg"><span>PAN Card.pdf</span></div>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
					<!-- INPUT ROW -->
					<div class="col-sm-6">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label mb-10 pr-0"> 
								<input type="text" readonly class="form-control" placeholder=" " value="{{ business_detail.aadhar_number }}">
							   <span class="highlight"></span>
							   <label>Aadhaar Number</label>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
					<!-- INPUT ROW -->
					<div class="col-sm-6">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label flselcted mb-10"> 
								<input type="text" class="form-control" readonly placeholder=" ">
								<button class="btn btn-outline-secondary fs16 btn-o-default" type="button">
								<span><a href="{{business_detail.aadhar_document_path?business_detail.aadhar_document_path:''}}" target="_blank"> {{ business_detail.aadhar_document_path? 'View File' : 'No File' }}</a></span>
									<svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107" viewBox="0 0 18.71 12.107">
								  <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
									<path id="Path_2337" data-name="Path 2337" d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z" transform="translate(-14.582 -27.082)" fill="#ff7135"/>
									<g id="Group_15718" data-name="Group 15718">
									  <g id="Group_15717" data-name="Group 15717">
										<g id="Group_15716" data-name="Group 15716">
										  <path id="Path_2338" data-name="Path 2338" d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4" transform="translate(-36.046 -39.347)" fill="#ff7135"/>
										</g>
									  </g>
									</g>
								  </g>
								</svg>
								</button>	  
							   <span class="highlight"></span>
							   <label>Aadhaar Card Document</label>
							   <div class="docselected"><img src="assets/images/svg/attach.svg"><span>Aadhaar Number.pdf</span></div>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
				</div>					
				
			</div>
		</div>
		<!-- /Identity DOCUMENTS -->
		<!-- Addresses -->
		<div class="card cardui carduipro p-4 mb-2">
			<h4 class="f-700 fs20 txtdefault pb-10">Addresses</h4>
			<div class="profileipt">
				<!-- INPUT ROW -->
				<div class="form-container mt-0 mb-4">
					<div class="floating-label2 mb-10 flselcted"> 
						<input type="text" class="form-control" placeholder=" " value="{{ business_detail.registered_office_address_txt }}">
						<button class="btn btn-outline-secondary fs16 btn-o-default" type="button" *ngIf="business_detail.reg_req_status">
							<span><a href="javascript:;" (click)="adrsmdl(newregisteradrs)">Update Registered Address</a></span>
							<svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107" viewBox="0 0 18.71 12.107">
						  <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
							<path id="Path_2337" data-name="Path 2337" d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z" transform="translate(-14.582 -27.082)" fill="#ff7135"/>
							<g id="Group_15718" data-name="Group 15718">
							  <g id="Group_15717" data-name="Group 15717">
								<g id="Group_15716" data-name="Group 15716">
								  <path id="Path_2338" data-name="Path 2338" d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4" transform="translate(-36.046 -39.347)" fill="#ff7135"/>
								</g>
							  </g>
							</g>
						  </g>
						</svg>

						</button>	  
					   <span class="highlight"></span>
					   <label>Registered Office Address</label>
					</div>
				</div>

				<!-- /INPUT ROW -->	
				<!-- INPUT ROW -->
				<div class="form-container mt-0 mb-4" *ngFor="let addr of business_detail.secondary_locations; let i = index;">
					<div class="floating-label mb-10 pr-0"> 
						<input type="text" class="form-control" readonly placeholder=" "  value="{{ getfulladdress(addr) }}">						  
					   <span class="highlight"></span>
					   <label>Shipping Address - {{i+1}}</label>	
						<a href="javascript:void(0);" class="btn-d-outline place-bid shpdflt" *ngIf=" i == 0">Default</a>
					</div>
				</div>
				<!-- /INPUT ROW -->
			</div>
		</div>
		<!-- /Addresses -->
		<!-- Designated Person -->
		<div class="card cardui carduipro p-4 mb-2" *ngIf="business_detail.owner_first_name">
			<h4 class="f-700 fs20 txtdefault pb-10">Designated Person</h4>
			<div class="">
				<div class="d-table">
					<div class="d-table-cell align-middle text-center  txtavtarwrp">
						<div class="txtavtar fs20 textwhite wt700">{{ getnamecode(business_detail.owner_firstname, business_detail.owner_lastname) }}</div>
					</div>
					<div class="d-table-cell dsg-pinfo pl-3 align-middle">
						<h5 class="fs14 wt700 mb-1 mt-0 txtdefault">{{ business_detail.owner_firstname }} {{ business_detail.owner_lastname }} <span class="fs12 wt400 textsecondary">Owner</span></h5>
						<p class="fs12 txtdefault wt400 mr-4 d-inline-block mb-0"><i class="d-inline-block p-2 callicon"></i> +91 {{ business_detail.owner_mobile }}</p>
						<!-- {{ business_detail.owner_countrycode }}  -->
						<p class="fs12 txtdefault wt500 d-inline-block mb-0" *ngIf="business_detail.owner_email"><i class="d-inline-block p-2 envelopeicon"></i> {{ business_detail.owner_email }}</p>
					</div>
				</div>
			</div>
		</div>	
		<!-- /Designated Person -->
		<!-- Authorized Persons -->
		<div class="card cardui carduipro p-4 mb-40" *ngIf="business_detail.authorized_persons.length > 0">
			<h4 class="f-700 fs20 txtdefault pb-10" *ngIf="business_detail.authorized_persons[0].name">Authorized Persons</h4>
			<!-- REPEAT -->	
			<div class="mb-3" *ngFor="let person of business_detail.authorized_persons;">
				<div class="d-table" *ngIf="person.name">
					<div class="d-table-cell align-middle text-center  txtavtarwrp">
						<div class="txtavtar fs20 textwhite wt700">{{ getnamecode(person.name) }}</div>
					</div>
					<div class="d-table-cell dsg-pinfo pl-3 align-middle">
						<h5 class="fs14 wt700 mb-1 mt-0 txtdefault">{{ person.name}}</h5>
						<p class="fs12 txtdefault wt400 mr-4 d-inline-block mb-0"><i class="d-inline-block p-2 callicon"></i> +91 {{ person.phone_number}}</p>
						<!-- {{person.country_code}} -->
						<p class="fs12 txtdefault wt500 d-inline-block mb-0" *ngIf="person.email"><i class="d-inline-block p-2 envelopeicon"></i> sanjay.gupta@gmail.com</p>
					</div>
				</div>
			</div>
			<!-- /REPEAT -->	
		</div>	
		<!-- /Authorized Persons -->
	</div>
</div>

<!-- EDIT PROFILE PHOTO -->
<ng-template #rejectbuyer let-modal>
	<div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
	   <h5 class="modal-title text-center pb-20">Reject with Reasons</h5>
	   <div class="bid-order-info">
		 <div class="order-img">
			 <img src="assets/images/icons/info.png" />
		 </div>
		 <div class="pd-seller-cntnt">
			 <div class="fs12">The {{ personal_detail.usertype == 'buyer'? 'buyer':'seller' }} will be notified to upload a fresh document immediately.</div>
		 </div>
	 </div>

	 <div class="form-container">
		 <div class="floating-label mb-20">      
			<select class="form-control" [(ngModel)]="reject_reason">
				<option value="" selected="selected">Selected</option>
				<option *ngFor="let item of reasons; let i = index;" value="{{item.value}}">{{ item.name }}</option>
			</select>
			<span class="highlight"></span>
			<label>Reason of Rejection</label>
		 </div>
		 <div class="floating-label mb-0">      
			<textarea class="form-control floating-textarea" [(ngModel)]="reject_message"></textarea>
			<!-- Please reupload the GST Filed document for the last month. -->
			<span class="highlight"></span>
			<label>Message</label>
		 </div>
	 </div>

	 <div class="text-center pt-30 pb-20">
		<button type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')" class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4">
		   Cancel
			</button>
			<button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4" (click)="changestatus('reject')">Notify the Buyer</button>
	 </div>
	</div>
</ng-template>
<!-- EDIT PROFILE PHOTO -->

<!-- ADD NEW BILLING ADDRESS -->
<ng-template #newregisteradrs let-modal>
	<div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
            <h5 class="modal-title text-center cg-ttl pb-2">Update Registered Address</h5>
			<form [formGroup]="addRegisteredForm">
				<div class="form-container mdl-frm-lbl mb-3">
					<div class="row">
						<div class="col-sm-12 mb-4">
							<div class="floating-label mb-0">      
							<textarea class="form-control" placeholder="" formControlName="address"></textarea>
							<span class="highlight"></span>
							<label>Address</label>
							</div> 
						</div>
					</div>

					
					<div class="row">

						<div class="col-sm-6 mb-4">
							<div class="floating-label mb-0">
								<select class="form-control" formControlName="state" (change)="getcity()">
									<option selected="selected" value="">Please Select</option>
									<option *ngFor="let item of stateArray" [ngValue]="item.value">{{item.name}}</option>
								</select>
								<span class="highlight"></span>
								<label>State</label>
							</div>
						</div>
						<div class="col-sm-6 mb-4">
							<div class="floating-label mb-0">
								<select class="form-control" formControlName="city">
									<option selected="selected" value="">Please Select</option>
									<option *ngFor="let item of cityArray" [ngValue]="item.value">{{item.name}}</option>
								</select>
								<span class="highlight"></span>
								<label>City</label>
							</div>
						</div>

						<div class="col-sm-6 mb-4">
							<div class="floating-label mb-0">      
							<input type="text" class="form-control" placeholder="" formControlName="pincode" />
							<span class="highlight"></span>
							<label>Pincode</label>
							</div> 
						</div>
					</div>
				</div>   

				<div class="cta  pb-4 text-center">
					<button  class="btn btn-mod btn-color btn-medium btn-round ripple mnbtn250" (click)="updateregisteredform()" [disabled]="addRegisteredForm.invalid">Update Address</button>
					
				</div>
			</form>

      </div>
</ng-template>
<!-- /ADD NEW ADDRESS -->