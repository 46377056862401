<header class="sticky sellerheader" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="">
    <div class="equibiz-header">
      <div class="logo-bar">
        <div class="container">
          <div class="row no-gutters align-items-center justify-content-between">
             <div class="col-md-3">
             	<a [routerLink]="['/home']"><img src="assets/images/svg/eq_logo.svg"></a>
             </div>
             <div class="col-md-6">
                <div class="input-group-custom">
					<div class="ng-autocomplete">
						<ng-autocomplete 
							[data]="result" 
							[searchKeyword]="keyword" 
							(selected)='selectEvent($event)'
							(inputChanged)='onChangeSearch($event)' 
							(inputFocused)='onFocused($event)' 
							[itemTemplate]="itemTemplate"
							placeholder="Search Products, Brands and Categories"
							(keydown.enter)="submitsearch($event)"
							 >
						</ng-autocomplete>
						<ng-template #itemTemplate let-item>
							<a href="javascript:void(0)">
								<div class="d-flex">
									<span [innerHTML]="item.name"></span>
									<span class="current text-right"> in {{item.parent}}</span>
								</div>
							</a>
						</ng-template>
						
						<div class="search-icon"><img src="assets/images/svg/search_logo.svg"></div>
					</div>
                   
                </div>
             </div>
             <div class="col-md-3 mblsignup">
                <!-- Post Login-->
				<div class="text-right d-flex align-items-center justify-content-end">
	                <a href="javascript:;" class="mr-5" [routerLink]="['/cart']">
	                	<div class="cart">
	                		<img src="assets/images/icons/cart.svg">
	                		<div class="cart-count"><span>{{ cart_count }}</span></div>
	                	</div>
	                </a>
	                <a class="mr-5" href="javascript:void(0);">
		  				<div class="header-notifications">
                            <ul class="header-dropdown">
                                 <li class="onhover-dropdown mobile-account">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.1" height="19.463" viewBox="0 0 20.1 19.463">
                                       <g id="Group_98" data-name="Group 98" transform="translate(-1331.4 -25.5)">
                                          <g id="np_bell_4209940_000000" transform="translate(1313.9 8.363)" opacity="0.6">
                                             <path id="Path_2300" data-name="Path 2300" d="M44.794,76.5H40.215a.715.715,0,1,0,0,1.431h4.579a.715.715,0,1,0,0-1.431Z" transform="translate(-15.705 -41.331)" fill="#5e6a7c"/>
                                             <path id="Path_2301" data-name="Path 2301" d="M35.385,32.479H34.3V26.1a7.5,7.5,0,1,0-14.994,0v6.382H18.215a.715.715,0,0,0,0,1.431h17.17a.715.715,0,1,0,0-1.431Z" transform="translate(0 0)" fill="#5e6a7c"/>
                                          </g>
                                          <circle id="Ellipse_6" data-name="Ellipse 6" cx="4" cy="4" r="4" transform="translate(1343 26)" fill="#07f" stroke="#fff" stroke-width="1"/>
                                       </g>
                                    </svg>
                                    <span class="header-notifications-container">
                                    <ul class="onhover-show-div">
                                       <li class="header"><div class="fs14 f-700 text-dark">Notification</div></li>
                                       <li>No Notifications Found</li>
                                       <!-- <li>
                                          <div class="new-one"></div>
                                          <div class="fs14 text-dark">
                                            Book now to get 10% Off. It is an Excellent Deal
                                          </div>
                                          <div class="fs13">2 min ago</div>
                                        </li>
                                        <li>
                                          <div class="new-one"></div>
                                          <div class="fs14 text-dark">
                                            Xiaomi has launched a new product. Xiaomi has launched a new product.
                                          </div>
                                          <div class="fs13">15 mins ago</div>
                                        </li>
                                        <li>
                                            <div class="fs14 text-dark">
                                              Pre-book amount Rs. 7,999 is to be paid.
                                            </div>
                                            <div class="fs13">Yesterday, 12:54 pm</div>
                                        </li>
                                        <li class="footer text-center">
                                            <a href="javascript:;" class="link-alt fs14">View all notifications</a>
                                        </li> -->
                                    </ul>
                                    </span>
                                 </li>
                            </ul>
                        </div>
		  			</a>
		  			<div class="profile" [routerLink]="['/buyer/dashboard']">
		  				<div class="icon"><img [src]="profile_image?profile_image:'assets/images/product/as-avt.jpg'"></div>
		  				<div>Hi {{name}}</div>
		  			</div>
		  		</div>
		  		<!--End Post Login-->
             </div>
          </div>
        </div>
      </div>

      <div class="divider"></div>
		<div class="loc-head-mn">
		  <div class="container">
			<div class="row no-gutters align-items-center">
			  <div class="col-md-4">
				<ul class="header-dropdown">
				  <li class="sell-on-equibiz" *ngIf="!token">
					<a href="javascript:;"><img src="assets/images/svg/star_round.svg"> Sell on Equibiz</a>
				  </li>
				  <!-- <li class="hideinmbl"><span class="divider-line"></span></li> -->

				  <!-- <li class="onhover-dropdown mobile-account menu-item topbar-menu hideinmbl">
					<img src="assets/images/svg/location_gray.svg"> All India
					<ul class="onhover-show-div w-3">
						<li><a herf="javascript:;">All India</a></li>
						<li><a herf="javascript:;">Mumbai</a></li>
						<li><a herf="javascript:;">Bengaluru</a></li>
						<li><a herf="javascript:;">Hyderabad</a></li>
						<li><a herf="javascript:;">Pune</a></li>
						<li><a herf="javascript:;">Chennai</a></li>
						<li><a herf="javascript:;">Delhi</a></li>
						<li><a herf="javascript:;">Kolkata</a></li>
					</ul>
				  </li> -->
				</ul>
			  </div>
			  <div class="col-md-8 text-right right-header">
				<ul class="header-dropdown">
				  	<!-- <li class="onhover-dropdown mobile-account menu-item topbar-menu">
						Equibiz Exclusive
						<ul class="onhover-show-div w-2">
							<li><div class="header-title">Equibiz Exclusive</div></li>
							<li><a href="javascript:;">Latest Gadgets on Equibiz</a></li>
							<li><a href="javascript:;">News and Reviews</a></li>
						</ul>
				  	</li> -->
				  	<!-- <li class="menu-item"><a href="javascript:;">EB Market Pulse</a></li> -->
				  	<li class="onhover-dropdown mobile-account menu-item topbar-menu">
						Assistance
						<ul class="onhover-show-div w-3">
							<li *ngFor="let ass of assistance_records"><a herf="javascript:;" [routerLink]="['/buyer/more-articles/'+ass.id]">{{ ass.title }}</a></li>
							<li><a href="javascript:;" [routerLink]="['/buyer/assistance']" class="btn btn-custom">Help Center</a></li>
						</ul>
				  	</li>
				  	<!--Post Login-->
					<li class="mobile-account menu-item" *ngIf="token">
						<div class="d-flex align-items-end" [routerLink]="['/buyer/dashboard']">
							<div>EB Dashboard</div>	
						</div>		
					</li>
				  	<!-- <li class="onhover-dropdown mobile-account menu-item topbar-menu" *ngIf="token">
						EB Dashboard
						<div class="onhover-show-div to-left w-5">
							<div class="pb-10"><a href="javascript:;" [routerLink]="['/buyer/dashboard']" class="header-title">Dashboard</a></div>
							<div class="row">
								<div class="col-md-4">
									<ul>
										<li><div class="megamenu-title">My Wallet</div></li>
										<li><a href="javascript:;" [routerLink]="['/buyer/buyer-wallet']">Wallet Balance</a></li>
										<li><a href="javascript:;" [routerLink]="['/buyer/buyer-transaction-history']">Transaction History</a></li>
										<li><a href="javascript:;" [routerLink]="['/buyer/buyer-add-money']">Add Money</a></li>
									</ul>
								</div>

								<div class="col-md-4">
									<ul>
										<li><div class="megamenu-title">My Orders</div></li>
										<li><a href="javascript:;" [routerLink]="['/buyer/my-bids']">My Bids</a></li>
										<li><a href="javascript:;" [routerLink]="['/buyer/buyer-track-orders']">Track My Orders</a></li>
										<li><a href="javascript:;" [routerLink]="['/buyer/buyer-track-orders']">Orders History</a></li>
									</ul>
								</div>

								<div class="col-md-4">
									<ul>
										<li><div class="megamenu-title">My Profile</div></li>
										<li><a href="javascript:;" [routerLink]="['/buyer/personal-details']">Personal Details</a></li>
										<li><a href="javascript:;" [routerLink]="['/buyer/business-details']">Business Details</a></li>
										<li><a href="javascript:;" [routerLink]="['/buyer/bank-details']">Account Details</a></li>
									</ul>
								</div>
							</div>
							<div class="row mt-20">
								<div class="col-md-4">
									<ul>
										<li><div class="megamenu-title">My Wishlist</div></li>
										<li><a href="javascript:;" [routerLink]="['/buyer/buyer-wishlist']">Saved Wishlist</a></li>
										<li><a href="javascript:;" [routerLink]="['/buyer/manage-categories']">Saved Categories</a></li>
									</ul>
								</div>

								<div class="col-md-4">
									<ul>
										<li><div class="megamenu-title">My Invoices</div></li>
										<li><a href="javascript:;">Order Invoices</a></li>
										<li><a href="javascript:;">Charges Invoices</a></li>
										<li><a href="javascript:;">Transportation Docs</a></li>
									</ul>
								</div>

								<div class="col-md-4">
									<ul>
										<li><div class="megamenu-title">My Business Report</div></li>
										<li><a href="javascript:;">Tax Report</a></li>
										<li><a href="javascript:;">Purchase Report</a></li>
										<li><a href="javascript:;">Payment Report</a></li>
										<li><a href="javascript:;">Order Report</a></li>
									</ul>
								</div>
							</div>
						</div>
				  	</li> -->
				  	<li class="mobile-account menu-item" *ngIf="token">
				  		<div class="d-flex align-items-end">
					  		<div><img src="assets/images/icons/wallet-icon.svg"></div> &nbsp;
					  		<div>{{ wallet_amount }}</div>	
				  		</div>		
				  	</li>
				  	<!--End Post Login-->
				</ul>
			  </div>
			</div>
		  </div>
	  	</div>
    </div>
  </div>
</header>
