import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { AddProductService } from './addproduct.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '../../helpers/toastr.service';
import { EncrDecrService } from '../../helpers/EncrDecrService';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { PreviewComponent } from 'src/app/shared/components/modal/preview/preview.component';
import { Plugins } from 'protractor/built/plugins';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
    selector: 'app-add-product',
    templateUrl: './add-product.component.html',
    styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {

    @ViewChild("preview") Preview: PreviewComponent;

    consentForm: FormGroup;
    uniqueForm: FormGroup;
    productForm: FormGroup;
    marketplaceForm: FormGroup;
    pricingForm: FormGroup;
    paymentForm: FormGroup;    

    active = 9;
    sampleactive = 1;
    public qty_type:string = 'plus';
    public child_qty_type:string = 'plus';
    disabled = true;
    public new_qty:number;
    public addquantity_map_type:string;
    public child_new_qty:number;
    public child_addquantity_map_type:string;
    public child_selected_record:any = {};
    public mrp_price:number = 0;
    public wholesale_price_warning_msg:string;
    public wholesale_mrp_price_error_msg:string;
    public bulk_mrp_price_error_msg:string;
    public wholesale_price_warning:boolean = false;
    public bulk_price_warning_msg:string;
    public bulk_price_warning:boolean = false;
    public child_product:boolean = false;
    public child_records:any = [];
    public seller_address: any = [];
    public upper_bracket_amount: number;
    public lower_bracket_amount: number;
    public bulk_realization_details: any = {};
    public wholesale_realization_details: any = {};
    public display_details: any = {};
    public hsns: any = [];
    public selected_hsn_id: string;
    public is_variant: boolean = false;
    public listing_status: boolean;
    public variant_status: boolean;
    public slides = [];
    public unique_id: string;
    public active_variant_id: string;
    public consent_file: string;
    public consent_file_status: boolean = false;
    public variants = [];
    public active_variants = [];
    public info: any = {};
    public countryArray: Array<Object> = [];
    public netrealization_type:string = 'inside';
    public packArray: Array<Object> = [
        {
            name: 1,
            value: 1,
        },
        {
            name: 2,
            value: 2,
        },
        {
            name: 3,
            value: 3,
        },
        {
            name: 4,
            value: 4,
        },
        {
            name: 5,
            value: 5,
        },
        {
            name: 6,
            value: 6,
        },
        {
            name: 7,
            value: 7,
        },
        {
            name: 8,
            value: 8,
        },

        {
            name: 9,
            value: 9,
        },
        {
            name: 10,
            value: 10,
        },
    ];

    editor_config: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '15rem',
        minHeight: '0',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter text here...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '',
        fonts: [
            {class: 'arial', name: 'Arial'},
            {class: 'times-new-roman', name: 'Times New Roman'},
            {class: 'calibri', name: 'Calibri'},
            {class: 'comic-sans-ms', name: 'Comic Sans MS'}
        ],
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ],
        // uploadUrl: 'v1/image',
        // upload: (file: File) => { },
        // uploadWithCredentials: false,
        sanitize: true,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
            [],
            []
        ]
    };

    units = [
        {
            label: 'In Months',
            value: 'month'
        },
        {
            label: 'In Year',
            value: 'year'
        }
    ]

    deatweight_units = [
        {
            label: 'In Gram',
            value: 'gram'
        },
        {
            label: 'In KG',
            value: 'kg'
        }
    ]

    product_status_array = [
        {
            label: 'Brand New',
            value: 'brand-new'
        },
        {
            label: 'Activated',
            value: 'activated'
        },
        {
            label: 'Fresh',
            value: 'fresh'
        }
    ]

    spicemoney_options = [
        { label: '1', value: 1},
        { label: '2', value: 2},
        { label: '3', value: 3},
        { label: '4', value: 4},
        { label: '5', value: 5},
        { label: '6', value: 6},
        { label: '7', value: 7},
        { label: '8', value: 8},
        { label: '9', value: 9},
        { label: '10', value: 10},
        { label: '11', value: 11},
        { label: '12', value: 12},
        { label: '13', value: 13},
        { label: '14', value: 14},
        { label: '15', value: 15},
        { label: '16', value: 16},
        { label: '17', value: 17},
        { label: '18', value: 18},
        { label: '19', value: 19},
        { label: '20', value: 20},
        { label: '21', value: 21},
        { label: '22', value: 22},
        { label: '23', value: 23},
        { label: '24', value: 24},
        { label: '25', value: 25},
        { label: '26', value: 26},
        { label: '27', value: 27},
        { label: '28', value: 28},
        { label: '29', value: 29},
        { label: '30', value: 30},
    ]

    public combo_products_list:any = [];

    public other_charges = ['Logistics Charges: INR 10,000', 'Convenience Charges: INR 12,000'];
    public warranty_summary : string ='';
    constructor(
        private modalService: NgbModal,
        private _addproductservice: AddProductService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private _router: Router,
        private _toastr: ToasterService,
        private _EncrDecr: EncrDecrService,
    ) { }
    flactive = 13;
    ngOnInit(): void {
        this.unique_id = this.route.snapshot.paramMap.get('uniqueId');

        this.getcountry();

        //Consent Form
        this.consentForm = this.fb.group({
            legit_purchase: this.fb.control(false, [Validators.required]),
            terms_conditions: this.fb.control(false, [Validators.required]),
            govt_conditions: this.fb.control(false, [Validators.required]),
        });

        //Unique Form
        this.uniqueForm = this.fb.group({
            eb_product_id: this.fb.control(null),
            sku: this.fb.control(null, []),
            hsn: this.fb.control(null, [Validators.required]),
            crs: this.fb.control(null, []),
        });

        //Product Form
        this.productForm = this.fb.group({
            market_operated_price: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            total_minimum_order_qty: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            total_minimum_order_qty_bulk: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            country: this.fb.control("", [Validators.required]),
            no_item_perpack: this.fb.control("", [Validators.required]),
            product_status: this.fb.control("", [Validators.required]),
            length: this.fb.control(null, [Validators.required]),
            width: this.fb.control(null, [Validators.required]),
            height: this.fb.control(null, [Validators.required]),
            deadweight: this.fb.control(null, [Validators.required]),
            deadweight_unit: this.fb.control(null, [Validators.required]),
            domestic_warranty: this.fb.control(null, []),
            domestic_warranty_unit: this.fb.control(null, []),
            international_warranty: this.fb.control(null, []),
            international_warranty_unit: this.fb.control(null, []),
            warranty_summary: this.fb.control(null, []),
            warranty_server_type: this.fb.control(null, []),
            warranty_by_brand: this.fb.control(false, []),
        });

        

        //Marketplace Form
        this.marketplaceForm = this.fb.group({
            wholesale: this.fb.control(false, [Validators.required]),
            bulk: this.fb.control(false, [Validators.required]),
            bid_offer: this.fb.control(false, [Validators.required]),
        });

        //Pricing Form
        this.pricingForm = this.fb.group({
            wholesale_price: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            spicemoney_percentage: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            wholesale_qty: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            wholesale_min_qty: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            bulk_price: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            bulk_qty: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            //bulk_min_qty: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            lowest_bid_amt: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            bid_upper_moq: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            bid_lower_moq: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
        });

        //Payment Form
        this.paymentForm = this.fb.group({
            payment_type: this.fb.control(null),
            product_location: this.fb.control(null, [Validators.required]),
            delivery_date: this.fb.control(null, [Validators.required]),
        });

        this.getproductvariants();
    }

    get prf() { return this.productForm.controls; }


    setRequired() {
        return [Validators.required];
    }

    setRequiredAndInteger() {
        return [Validators.required, Validators.pattern("^[0-9]*$")];
    }



    isWarrantyChange(event) {
        if (this.productForm.value.warranty_by_brand) {
            this.productForm.get('domestic_warranty').clearValidators();
            this.productForm.controls['domestic_warranty'].updateValueAndValidity();
            this.productForm.get('domestic_warranty_unit').clearValidators();
            this.productForm.controls['domestic_warranty_unit'].updateValueAndValidity();
            this.productForm.get('international_warranty').clearValidators();
            this.productForm.controls['international_warranty'].updateValueAndValidity();
            this.productForm.get('international_warranty_unit').clearValidators();
            this.productForm.controls['international_warranty_unit'].updateValueAndValidity();
            this.productForm.get('warranty_summary').clearValidators();
            this.productForm.controls['warranty_summary'].updateValueAndValidity();
            this.productForm.get('warranty_server_type').clearValidators();
            this.productForm.controls['warranty_server_type'].updateValueAndValidity();
        } else {
            this.productForm.get('domestic_warranty').setValidators(this.setRequired());
            this.productForm.controls['domestic_warranty'].updateValueAndValidity();
            this.productForm.get('domestic_warranty_unit').setValidators(this.setRequired());
            this.productForm.controls['domestic_warranty_unit'].updateValueAndValidity();
            this.productForm.get('international_warranty').setValidators(this.setRequired());
            this.productForm.controls['international_warranty'].updateValueAndValidity();
            this.productForm.get('international_warranty_unit').setValidators(this.setRequired());
            this.productForm.controls['international_warranty_unit'].updateValueAndValidity();
            this.productForm.get('warranty_summary').setValidators(this.setRequired());
            this.productForm.controls['warranty_summary'].updateValueAndValidity();
            this.productForm.get('warranty_server_type').setValidators(this.setRequired());
            this.productForm.controls['warranty_server_type'].updateValueAndValidity();
        }
    }

    getproductvariants() {
        this._addproductservice.getvariants({ unique_id: this.unique_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.variants = data['variantlist'];
                this.is_variant = data['is_variant'];
                var active_record = this.variants.find(element => {
                    return element.selected == true;
                });
                this.active_variants = this.variants.filter((product) => product.selected == true);
                this.active_variant_id = active_record.id;
                this.getcomboproducts();
                this.getsellerproductdetails(this.active_variant_id);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getsellerproductdetails(variant_id) {
        this.active_variant_id = variant_id;
        this._addproductservice.getsellerproductdetails({ unique_id: this.unique_id, variant_id: this.active_variant_id }).subscribe((data) => {
            if (data['status'] == "success") {
                var records = data['records'];
                this.info = data['productinfo'];
                let images = [];

                this.child_product = data['child'];
                this.child_records = data['child_records'];

                this.upper_bracket_amount = records.upper_bracket_amount;
                this.lower_bracket_amount = records.lower_bracket_amount;

                if (this.info.front_image) {
                    images.push({ img: this.info.front_image });
                }

                if (this.info.back_image) {
                    images.push({ img: this.info.back_image });
                }

                if (this.info.sales_image) {
                    images.push({ img: this.info.sales_image });
                }

                if (this.info.side_image) {
                    images.push({ img: this.info.side_image });
                }

                if (this.info.top_image) {
                    images.push({ img: this.info.top_image });
                }

                this.slides = images;

                this.listing_status = records.listing_status;
                this.variant_status = records.variant_status;

                this.consentForm.patchValue({
                    legit_purchase: records.legit_purchase,
                    terms_conditions: records.terms_conditions,
                    govt_conditions: records.govt_conditions
                });

                this.uniqueForm.patchValue({
                    eb_product_id: this.info.unique_no,
                    sku: records.sku,
                    hsn: records.hsn.name,
                    crs: records.crs
                });

                this.selected_hsn_id = records.hsn.code;

                this.productForm.patchValue({
                    market_operated_price: this.info.market_operating_price,
                    country: records.country,
                    no_item_perpack: records.no_item_perpack,
                    product_status: records.product_status,
                    length: records.length,
                    width: records.width,
                    height: records.height,
                    deadweight: records.deadweight,
                    deadweight_unit: records.deadweight_unit,
                    domestic_warranty: records.domestic_warranty,
                    domestic_warranty_unit: records.domestic_warranty_unit,
                    international_warranty: records.international_warranty,
                    international_warranty_unit: records.international_warranty_unit,
                    // warranty_summary: records.warranty_summary,
                    warranty_server_type: records.warranty_server_type,
                    warranty_by_brand: records.warranty_by_brand,
                    total_minimum_order_qty: records.wholesale_min_qty,
                    total_minimum_order_qty_bulk: records.bulk_min_qty
                });
                    this.warranty_summary = records.warranty_summary;


                //Marketplace Form
                this.marketplaceForm.patchValue({
                    wholesale: records.wholesale,
                    bulk: records.bulk,
                    bid_offer: records.bid_offer,
                });

                //Pricing Form
                this.pricingForm.patchValue({
                    wholesale_price: records.wholesale_price,
                    spicemoney_percentage: records.spicemoney_percentage,
                    wholesale_qty: records.wholesale_qty,
                    wholesale_min_qty: records.wholesale_min_qty,
                    bulk_price: records.bulk_price,
                    bulk_qty: records.bulk_qty,
                    //bulk_min_qty: records.bid_lower_moq,
                    lowest_bid_amt: records.lowest_bid_amt,
                    bid_upper_moq: records.bid_upper_moq,
                    bid_lower_moq: records.bid_lower_moq
                });

                this.mrp_price = records.mrp;

                //Payment Form
                this.paymentForm.patchValue({
                    payment_type: records.payment_type,
                    product_location: records.product_location_index,
                    delivery_date: records.delivery_date,
                });

                this.seller_address = records.seller_address;

                this.changebulkmarketplace();
                setTimeout(() => {
                    this.netrealizationvalue('wholesale');
                }, 2000);

                setTimeout(() => {
                    this.netrealizationvalue('bulk');
                }, 5000);


            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    uploadfile(event, type) {
        const file = event.target.files;
        this.consent_file = file[0];
        this.consent_file_status = true;
    }

    updatevariant() {
        this._addproductservice.updatevariant({ unique_id: this.unique_id, variants: this.variants }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Product variant has been updated.');
                this.modalService.dismissAll();
                this.getproductvariants();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    submitConsent() {
        let data: any = new FormData();
        data.append('uniqueId', this.unique_id);
        data.append('variantId', this.active_variant_id);
        data.append('legit_purchase', this.consentForm.get('legit_purchase').value);
        data.append('govt_conditions', this.consentForm.get('govt_conditions').value);
        data.append('terms_conditions', this.consentForm.get('terms_conditions').value);
        data.append("document", this.consent_file);

        this._addproductservice.saveconsent(data).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Consent details has been saved successfully.');
                this.active = 10;
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    submitUnique() {
        if (!this.selected_hsn_id) {
            this._toastr.error('Please enter HSN Number');
            return false;
        }
        let data: any = {
            uniqueId: this.unique_id,
            variantId: this.active_variant_id,
            eb_product_id: this._EncrDecr.set(this.uniqueForm.get('eb_product_id').value),
            sku: this._EncrDecr.set(this.uniqueForm.get('sku').value),
            hsn: this._EncrDecr.set(this.selected_hsn_id),
            crs: this._EncrDecr.set(this.uniqueForm.get('crs').value)
        };
        this._addproductservice.saveunique(data).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Unique ID details has been saved successfully.');
                this.active = 11;
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    submitProduct() {
        let data: any = {
            market_operated_price: this._EncrDecr.set(this.productForm.get('market_operated_price').value),
            total_minimum_order_qty: this._EncrDecr.set(this.productForm.get('total_minimum_order_qty').value),
            total_minimum_order_qty_bulk: this._EncrDecr.set(this.productForm.get('total_minimum_order_qty_bulk').value),
            uniqueId: this.unique_id,
            variantId: this.active_variant_id,
            country: this._EncrDecr.set(this.productForm.get('country').value),
            no_item_perpack: this._EncrDecr.set(this.productForm.get('no_item_perpack').value),
            product_status: this._EncrDecr.set(this.productForm.get('product_status').value),
            length: this._EncrDecr.set(this.productForm.get('length').value),
            width: this._EncrDecr.set(this.productForm.get('width').value),
            height: this._EncrDecr.set(this.productForm.get('height').value),
            deadweight: this._EncrDecr.set(this.productForm.get('deadweight').value),
            deadweight_unit: this._EncrDecr.set(this.productForm.get('deadweight_unit').value),
            domestic_warranty: this._EncrDecr.set(this.productForm.get('domestic_warranty').value),
            domestic_warranty_unit: this._EncrDecr.set(this.productForm.get('domestic_warranty_unit').value),
            international_warranty: this._EncrDecr.set(this.productForm.get('international_warranty').value),
            international_warranty_unit: this._EncrDecr.set(this.productForm.get('international_warranty_unit').value),
            // warranty_summary: this._EncrDecr.set(this.productForm.get('warranty_summary').value),
            warranty_server_type: this._EncrDecr.set(this.productForm.get('warranty_server_type').value),
            warranty_summary: this._EncrDecr.set(this.warranty_summary)
        };

        this._addproductservice.saveproduct(data).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Product details has been saved successfully.');
                this.active = 12;
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    submitMarketplace() {
        let data: any = {
            uniqueId: this.unique_id,
            variantId: this.active_variant_id,
            wholesale: this.marketplaceForm.get('wholesale').value,
            bulk: this.marketplaceForm.get('bulk').value,
            bid_offer: this.marketplaceForm.get('bid_offer').value,
        };


        this._addproductservice.savemarketplace(data).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Marketplace details has been saved successfully.');
                if(this.child_product){
                    this.active = 15;
                }else{
                    this.active = 13;
                }
                
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    submitChild() {
        this.active = 14;
    }

    submitPricing() {
        if (this.marketplaceForm.get('bulk').value) {
            if(!this.bulk_realization_details.inside_allow_save){
                this._toastr.error('Your bulk inside city net realization value is negative. Please check.');
                return false;
            }
            if(!this.bulk_realization_details.outside_allow_save){
                this._toastr.error('Your bulk outside city net realization value is negative. Please check.');
                return false;
            }  
        }
        if (this.marketplaceForm.get('wholesale').value) {
            if(!this.wholesale_realization_details.inside_allow_save){
                this._toastr.error('Your wholesale inside city net realization value is negative. Please check.');
                return false;
            }
            if(!this.wholesale_realization_details.outside_allow_save){
                this._toastr.error('Your wholesale outside city net realization value is negative. Please check.');
                return false;
            }  
        }
        let data: any = {
            uniqueId: this.unique_id,
            variantId: this.active_variant_id,
            wholesale_price: this._EncrDecr.set(this.pricingForm.get('wholesale_price').value),
            spicemoney_percentage: this._EncrDecr.set(this.pricingForm.get('spicemoney_percentage').value),
            wholesale_qty: this._EncrDecr.set(this.pricingForm.get('wholesale_qty').value),
            wholesale_min_qty: this._EncrDecr.set(this.pricingForm.get('wholesale_min_qty').value),
            bulk_price: this._EncrDecr.set(this.pricingForm.get('bulk_price').value),
            bulk_qty: this._EncrDecr.set(this.pricingForm.get('bulk_qty').value),
            bulk_min_qty: this._EncrDecr.set(this.pricingForm.get('bid_lower_moq').value),
            lowest_bid_amt: this._EncrDecr.set(this.pricingForm.get('lowest_bid_amt').value),
            bid_upper_moq: this._EncrDecr.set(this.pricingForm.get('bid_upper_moq').value),
            bid_lower_moq: this._EncrDecr.set(this.pricingForm.get('bid_lower_moq').value),
        };

        this._addproductservice.savepricing(data).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Pricing details has been saved successfully.');
                this.active = 14;
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    submitPayment() {
        if (this.marketplaceForm.get('bulk').value && !this.paymentForm.get('payment_type').value) {
            this._toastr.error('Please select payment type');
            return false;
        }
        let data: any = {
            uniqueId: this.unique_id,
            variantId: this.active_variant_id,
            payment_type: this.paymentForm.get('payment_type').value,
            product_location: this.paymentForm.get('product_location').value,
            delivery_date: this.paymentForm.get('delivery_date').value,
        };

        this._addproductservice.savepayment(data).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Payment details has been added successfully.');
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getcountry() {
        this._addproductservice.getcountry(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.countryArray = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getcomboproducts() {
        this._addproductservice.getcomboproducts({unique_id: this.unique_id}).subscribe((data) => {
            if (data['status'] == "success") {
                this.combo_products_list = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    updatestatus(type) {
        var value = this.listing_status;
        if (type == 'variant') {
            value = this.variant_status;
        }
        this._addproductservice.updatestatus({ unique_id: this.unique_id, variant_id: this.active_variant_id, type: type, value: value }).subscribe((data) => {
            if (data['status'] == "success") {
            } else if (data['status'] == "error") {
                this.variant_status = false;
                this._toastr.error(data['message']);
            }
        });
    }

    config = {
        labelField: 'label',
        valueField: 'value',
    };
    openWindowCustomClass(content) {
        this.modalService.open(content, { windowClass: 'modal-750' });
    }

    openaddquantitymodal(content, type) {
        this.new_qty = 0;
        this.addquantity_map_type = type;
        this.modalService.open(content, { windowClass: 'modal-750' });
    }

    openaddchildquantitymodal(content, type, child:any) {
        this.child_new_qty = 0;
        this.child_addquantity_map_type = type;
        this.child_selected_record = child;
        this.modalService.open(content, { windowClass: 'modal-750' });
    }

    openbreakup(contentbreakup, type) {
        if (type == 'wholesale') {
            this.display_details = this.wholesale_realization_details;
        } else {
            this.display_details = this.bulk_realization_details;
        }
        this.modalService.open(contentbreakup, { ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true, }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    closeResult = '';
    ratingclassstatus: boolean = false;
    ratingtglclass() {
        this.ratingclassstatus = !this.ratingclassstatus;
    }

    showcomperr: boolean = false;
    showcompmsg() {
        this.showcomperr = true;
    }
    imagesSlider = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: ".thumbs",
    };
    thumbnailsSlider = {
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: true,
        focusOnSelect: true,
        asNavFor: ".feedback",
        //prevArrow:'.client-thumbnails .prev-arrow',
        // nextArrow:'.client-thumbnails .next-arrow',
    };

    openPreview(unique_id, variant_id) {
        this.Preview.openPreviewModal(unique_id, variant_id, null, null, 'seller');
    }

    changemarketplace(type) {
        if (type == 'wholesale') {

        } else if (type == 'bulk') {
            this.changebulkmarketplace();
        } else if (type == 'bid_offer') {

        }
    }

    changebulkmarketplace() {
        if (this.marketplaceForm.get('bulk').value == false) {
            this.marketplaceForm.get('bid_offer').setValue(false);
        }
    }

    keyword = 'name';

    selectHsnEvent(item) {
        this.selected_hsn_id = item.id;
        //this.uniqueForm.get('hsn').setValue(item.name);
    }

    onChangeSearchHsn(val: string) {
        this._addproductservice.searchhsn({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.hsns = data['searchResult'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    onFocusedHsn(e) {
        // do something when input is focused
    }

    netrealizationvalue(type) {
        if(type == 'wholesale'){
            var listing_price = this.pricingForm.get('wholesale_price').value;
            var quantity = this.pricingForm.get('wholesale_min_qty').value;
            var spicemoney_percentage = this.pricingForm.get('spicemoney_percentage').value;
            if(listing_price > 0 && (listing_price > this.upper_bracket_amount || listing_price < this.lower_bracket_amount)){
                this.wholesale_price_warning = true;
                if(listing_price > this.upper_bracket_amount){
                    this.wholesale_price_warning_msg = 'Your price is higher than the MOP price';
                }else{
                    this.wholesale_price_warning_msg = 'Your price is lower than the MOP price';
                }
            }else{
                this.wholesale_price_warning = false;
                this.wholesale_price_warning_msg = '';
            }

            if(listing_price > 0 && listing_price > this.mrp_price){
                this.wholesale_mrp_price_error_msg = 'Your wholesale price should not be higher than MRP price('+this.mrp_price+')';
            }else{
                this.wholesale_mrp_price_error_msg = '';
            }
            
        }else{
            var listing_price = this.pricingForm.get('bulk_price').value;
            var quantity = this.pricingForm.get('bid_lower_moq').value;
            if(listing_price > 0 && (listing_price > this.upper_bracket_amount || listing_price < this.lower_bracket_amount)){
                this.bulk_price_warning = true;
                if(listing_price > this.upper_bracket_amount){
                    this.bulk_price_warning_msg = 'Your price is higher than the MOP price';
                }else{
                    this.bulk_price_warning_msg = 'Your price is lower than the MOP price';
                }
            }else{
                this.bulk_price_warning = false;
                this.bulk_price_warning_msg = '';
            }

            if(listing_price > 0 && listing_price > this.mrp_price){
                this.bulk_mrp_price_error_msg = 'Your bulk price should not be higher than MRP price('+this.mrp_price+')';
            }else{
                this.bulk_mrp_price_error_msg = '';
            }
        }

        if (listing_price > 0 && quantity > 0) {
            this._addproductservice.netrealizationvalue({ product_id: this.active_variant_id, listing_price: listing_price, quantity: quantity, market_place: type, spicemoney_percentage: spicemoney_percentage }).subscribe((data) => {
                if (data['status'] == "success") {
                    if (type == 'bulk') {
                        this.bulk_realization_details = data['record'];
                    } else {
                        this.wholesale_realization_details = data['record'];
                    }
                } else if (data['status'] == "error") {
                    this._toastr.error(data['message']);
                }
            });
        }
    }

    addquantity(){
        this._addproductservice.addquantity({ product_id: this.active_variant_id, quantity: this.new_qty, market_place: this.addquantity_map_type, qty_type: this.qty_type }).subscribe((data) => {
            if (data['status'] == "success") {
                var total_stock = data['qty'];
                if(this.addquantity_map_type == 'wholesale'){
                    this.pricingForm.patchValue({
                        wholesale_qty: total_stock
                    });
                }else if(this.addquantity_map_type == 'bulk'){
                    this.pricingForm.patchValue({
                        bulk_qty: total_stock
                    });
                }

                this._toastr.success('Quantity has been added successfully.');
                this.new_qty = 0;
                this.addquantity_map_type = '';
                this.modalService.dismissAll();
                
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    result = [];
    searchedProducts = [];

    selectEvent(item) {
        this._addproductservice.addcomboproduct({ unique_id: this.unique_id, child_id: item.id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.searchedProducts = data['searchResult'];
                this.getcomboproducts();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    onChangeSearch(val: string) {
        this._addproductservice.ownproductsearch({ search: val, unique_id: this.unique_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.result = [];
                this.result = data['searchResult'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    onFocused(e) {
    }

    removecombo(rec){
        this._addproductservice.removecomboproduct({ id: rec.id }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Product has been removed successfully.');
                this.getcomboproducts();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    updatechild(child:any){
        let data = {
            unique_id: this.unique_id,
            id: child.id,
            status:child.status,
            wholesale_price: child.wholesale_price,
            wholesale_min_qty: child.wholesale_min_qty,
            bulk_price: child.bulk_price,
            bulk_min_qty: child.bulk_min_qty
        };
        this._addproductservice.updatechildproduct(data).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Child product details updated successfully.');
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    comboviewdetails(rec){
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this._router.navigate(['/seller/addproduct/'+rec.unique_id]));
    }

    updatechildinventory(){
        let data = {
            unique_id: this.unique_id,
            id: this.child_selected_record.id,
            quantity: this.child_new_qty,
            market_place: this.child_addquantity_map_type,
            qty_type: this.child_qty_type,
            product_id: this.active_variant_id
        };
        this._addproductservice.updatechildproductinventory(data).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Quantity has been added successfully.');
                this.child_new_qty = 0;
                this.child_addquantity_map_type = '';
                this.child_qty_type = 'plus';
                this.modalService.dismissAll();
                this.getsellerproductdetails(this.active_variant_id);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }
}
