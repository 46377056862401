<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<h3 class="fs24 wt700 txtdefault">Wallet Balance</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Wallet</a>
						</li>
						<li class="breadcrumb-item active">Wallet Balance</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->

		<div class="card cardui carduipro p-4 mb-40">
			<div class="form-container mdl-frm-lbl mb-3 mt-0">
				<h3 class="f-700 fs20 txtdefault pb-20">Equibiz Wallet</h3>
				<div class="wallet-balance">
					<div class="mr-sm-3">
						<img src="assets/images/icons/wallet-balance.svg">
					</div>
					<div>
						<p>Current Balance:</p>
						<h4>{{ user_info.walletbalance }}</h4>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-6 mb-2">
						<div class="floating-label mb-0">      
						  <input type="text" class="form-control" placeholder="" [(ngModel)]="topup_amount"/>
						  <span class="highlight"></span>
						  <label>Enter amount (min ₹1,000)</label>
						</div> 
					</div>
				</div>
				<div class="help-info">Transaction Charges (incl)  <a href="javascript:;" class="fs14 link-alt">Know more</a></div>

				<div class="wallet-amount">
					<ul>
						<li *ngFor="let amount of amount_array;" (click)="changevalue(amount)"><a href="javascript:;" [ngClass]="{ 'selected': amount.selected == true}">₹ {{ amount.label }}</a></li>
					</ul>
				</div>
				<div class="text-left mt-30">
					<a href="javascript:;" (click)="walletbalance()" class="mr-sm-4 btn-d-outline d-inline-block"><svg xmlns="http://www.w3.org/2000/svg" width="14.681" height="14.681" viewBox="0 0 14.681 14.681" class="plus">
					  <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1 1)">
					    <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181" transform="translate(-11.659 -7.5)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					    <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181" transform="translate(-7.5 -11.659)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					  </g>
					</svg> Add Money</a>
					<i placement="top" ngbTooltip="more info goes here"><img src="assets/images/icons/shield-alt.svg" /></i>
					&nbsp;Safe and securely add money to your wallet.
				</div>
				<div id="drop_in_container"></div>
			</div>
		</div>
	</div>
</div>

