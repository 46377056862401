<app-header-one [sticky]="true"></app-header-one>
<script src="https://unpkg.com/card@2.3.0/dist/card.js"></script>
<section class="page-section">
	<div class="container">
		<div class="row d-flex justify-content-center">
			<div class="col-md-8">
				<div class="text-center">
					<!--Failed container-->
					<div class="failed mt-2">
						<div class="mt-20 mb-20"><img src="assets/images/danger.png"></div>
						<div class="text-danger f-700 fs18">Sorry! We're unable to process your order</div>
						<div class="mb-3 fs14 f-600">Please try again and confirm that<br>all details are correct</div>
					</div>
                </div>
			</div>
		</div>
	</div>
</section>
<app-footer-one></app-footer-one>