import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BuyerMoreArticlesService {

    constructor(private _http: HttpClient) { }

    getassistance(data){
        return this._http.post(environment.api_url+'assistance/details',data);
    }

}
