import { Component, OnInit } from '@angular/core';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { SignupService } from 'src/app/shared/components/modal/signup-modal/signup-modal.service';
import { OrderHistoryService } from '../order-history/order-history.service';

@Component({
  selector: 'app-return-orders',
  templateUrl: './return-orders.component.html',
  styleUrls: ['./return-orders.component.scss']
})
export class ReturnOrdersComponent implements OnInit {

    public search_term:string = '';
    public return_orders: any = [];
    public supplier_return_orders: any = [];
    public from_date:any = '';
    public to_date:any = '';
    constructor(
        private _toastr: ToasterService,
        private _pagination: PaginationService,
        private _orderhistoryservice: OrderHistoryService,
        private _signupservice: SignupService
    ) { }

    public seller_type:string;


    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 10;
    public return_order_pagination: any = {};
    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];

    //Sub seller orders
    public ss_search_term:string = '';
    public ss_from_date:any = '';
    public ss_to_date:any = '';

    public sub_current_page: number = 1;
    public sub_offset: number = 0;
    public sub_page_per_view: number = 10;
    public supplier_order_pagination: any = {};

    ngOnInit(): void {
        this.seller_type = this._signupservice.getsellertype();
        this._signupservice.sellerTypeChanged.subscribe((response: string) => {
            this.seller_type = this._signupservice.getsellertype();
        });
        this.getsellerorders();

        setTimeout( () => {
            if(this.seller_type == 'super'){
                this.getsubsellerorders();
            }
        }, 500);
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.sellerorders();
    }

    setpageperview() {
        this.current_page = 1;
        this.sellerorders();
    }

    getsellerorders() {
        this.search_term = '';
        this.from_date = '';
        this.to_date = '';
        this.page_per_view = 10;
        this.offset = 0;
        this.current_page = 1;
        this.sellerorders();
    }

    getsellerordersfilters(){
        this.page_per_view = 10;
        this.offset = 0;
        this.current_page = 1;
        this.sellerorders();
    }

    sellerorders() {
        let data = { 
            limit: this.page_per_view, 
            offset: this.offset, 
            current_page: this.current_page, 
            search: this.search_term,
            from_date: this.from_date,
            to_date: this.to_date
        };

        this._orderhistoryservice.sellerreturnorders(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.return_orders = data['records'];
                let total_records = data['total_records'];
                this.return_order_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }


    subsetpage(page) {
        this.sub_current_page = page;
        this.sub_offset = (page - 1) * this.sub_page_per_view;
        this.subsellerorders();
    }

    subsetpageperview() {
        this.sub_current_page = 1;
        this.subsellerorders();
    }

    getsubsellerorders() {
        this.sub_page_per_view = 10;
        this.sub_offset = 0;
        this.sub_current_page = 1;
        this.subsellerorders();
    }

    getsubsellerordersfilters(){
        this.sub_page_per_view = 10;
        this.sub_offset = 0;
        this.sub_current_page = 1;
        this.subsellerorders();
    }

    subsellerorders() {
        let data = { 
            limit: this.sub_page_per_view,
            offset: this.sub_offset,
            current_page: this.sub_current_page,
            search: this.ss_search_term,
            from_date: this.ss_from_date,
            to_date: this.ss_to_date
        }
        this._orderhistoryservice.subsellerreturnorders(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.supplier_return_orders = data['records'];
                let total_records = data['total_records'];
                this.supplier_order_pagination = this._pagination.getPager(total_records, this.sub_current_page, this.sub_page_per_view);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

}
