import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ManageProductCategoriesService {

  constructor(private _http: HttpClient) { }

  getcategory(data) {
    return this._http.post(environment.api_url+'getcategory',data);
  }

  updatecategorystatus(data) {
    return this._http.post(environment.api_url+'updatecategorystatus',data);
  }

}
