import { Component, OnInit } from '@angular/core';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { TransactionHistoryService } from './transaction-history.service';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss']
})
export class TransactionHistoryComponent implements OnInit {

    public search_term:string = '';
    public from_date:any = '';
    public to_date:any = '';
    public transaction_records:any = [];
    constructor(
        private _transactionhistory: TransactionHistoryService,
        private _toastr: ToasterService,
	    private _pagination: PaginationService
    ) { }

	public current_page: number = 1;
	public offset: number = 0;
	public page_per_view: number = 20;
	public transaction_pagination: any = {};
	public page_per_view_options = [
		{
			value: 20,
			name: 20
		},
		{
			value: 40,
			name: 40
		}
	];

  	ngOnInit(): void {
		this.gettransactiondetails();
  	}

	setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.gettransactiondetails();
    }

    setpageperview() {
        this.current_page = 1;
        this.gettransactiondetails();
    }

    gettransaction() {
        this.page_per_view = 10;
        this.offset = 0;
        this.current_page = 1;
        this.gettransactiondetails();
    }

    getsupersellers() {
        this.page_per_view = 10;
        this.offset = 0;
        this.current_page = 1;
        this.gettransactiondetails();
    }

    gettransactiondetails() {
        let data = { 
            limit: this.page_per_view, 
            offset: this.offset, 
            current_page: this.current_page, 
            search: this.search_term,
            from_date: this.from_date,
            to_date: this.to_date
        };
        this._transactionhistory.gettransactiondetails(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.transaction_records = data['records'];
                let total_records = data['total_records'];
                this.transaction_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

  	openDetails(record){
  		record.view_details = !record.view_details;
  	}

}
