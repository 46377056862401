<app-header-buyer></app-header-buyer>
<app-sidebar-buyer></app-sidebar-buyer>
<div class="wrpmain-buyer">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<h3 class="fs24 wt700 txtdefault">Business Details</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Profile</a>
						</li>
						<li class="breadcrumb-item active">Business Details</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 pl-0 text-right">
				<a href="javascript:void(0);" [routerLink]="['/buyer/assistance']" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4 mb-2">
			<div class="pb-2">
				<div class="d-table">
					<div class="d-table-cell align-middle proavtwrp">
						<div class="proavt">						
							<img src="{{ business_detail.brandlogo }}">
							<a (click)="openmdl(profilephoto)" href="javascript:void(0);" class="editpro">
								<img src="assets/images/svg/edit.svg" />
							</a>
						</div>
					</div>
					<div class="d-table-cell align-middle pl-3">
						<h4 class="f-700 fs20 txtdefault pb-0">{{ business_detail.buyer_b_name}} 
							<!-- <img class="hinfoactive" src="assets/images/svg/infoactive.svg" /> -->
						<img class="collapse" src="assets/images/svg/success.svg" />
						</h4>
						<div class="tg-grp">
							<span>{{ business_detail.business_category }}</span> 
						</div>
					</div>
				</div>
			</div>	
		</div>
		<div class="bsn-dtl-p">
		<!-- GST DOCUMENTS -->
		<div class="card cardui carduipro p-4 mb-2" *ngIf="!business_detail.no_gst">
			<h4 class="f-700 fs20 txtdefault pb-10">GST Documents</h4>
			<div class="profileipt">
				<!-- INPUT ROW -->
				<div class="form-container mt-0 mb-4">
					<div class="floating-label mb-10 pr-0"> 
						<input type="text" class="form-control" placeholder="" readonly value="{{ business_detail.gst_number }}">						  
					   <span class="highlight"></span>
					   <label>GSTIN Number</label>	
						
					</div>
				</div>
				<!-- /INPUT ROW -->				
				<div class="row">
					<!-- INPUT ROW -->
					<div class="col-sm-6 ">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label mb-10 flselcted"> 
								<input type="text" class="form-control" placeholder=" ">
								<button class="btn btn-outline-secondary fs16 btn-o-default" type="button">
									<span><a href="{{ business_detail.gst_document_path }}" target="_blank">View File</a></span>
									<svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107" viewBox="0 0 18.71 12.107">
								  <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
									<path id="Path_2337" data-name="Path 2337" d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z" transform="translate(-14.582 -27.082)" fill="#ff7135"/>
									<g id="Group_15718" data-name="Group 15718">
									  <g id="Group_15717" data-name="Group 15717">
										<g id="Group_15716" data-name="Group 15716">
										  <path id="Path_2338" data-name="Path 2338" d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4" transform="translate(-36.046 -39.347)" fill="#ff7135"/>
										</g>
									  </g>
									</g>
								  </g>
								</svg>

								</button>	  
							   <span class="highlight"></span>
							   <label>GST Document</label>
							   <div class="docselected"><img src="assets/images/svg/attach.svg"><span>GST Document.pdf</span></div>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
					<!-- INPUT ROW -->
					<div class="col-sm-6">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label mb-10"> 
								<input type="text" class="form-control" placeholder="" value="{{ business_detail.gst_filling_frequency }}">
								<button (click)="gstmdl(gstfrq)" class="btn btn-outline-secondary fs16 btn-o-default" type="button">
									<span>Change</span>
									<svg xmlns="http://www.w3.org/2000/svg" width="16.615" height="16.617" viewBox="0 0 16.615 16.617">
									  <path id="np_edit_3094244_000000" d="M13.5-.008A3.1,3.1,0,0,0,11.3.9L.857,11.344l4.4,4.4L15.7,5.307A3.114,3.114,0,0,0,13.5-.008ZM.317,12.273,0,16.046a.519.519,0,0,0,.561.561l3.771-.32Z" transform="translate(0.003 0.008)" fill="#ff7135"/>
									</svg>
								</button>	  
							   <span class="highlight"></span>
							   <label>GST Filing Frequency</label>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
					<!-- INPUT ROW -->
					<div class="col-sm-6">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label mb-10"> 
								<div class="floating-label-inr">
									<input type="text" class="form-control" placeholder=" " value="{{ business_detail.last_filled_gst_date }}">
									<button class="btn btn-outline-secondary fs16 btn-o-default" type="button">
									<span>Update</span>
									<svg xmlns="http://www.w3.org/2000/svg" width="15.415" height="15.415" viewBox="0 0 15.415 15.415">
									  <path id="Path_2419" data-name="Path 2419" d="M7.708,0a7.708,7.708,0,1,0,7.708,7.708A7.7,7.7,0,0,0,7.708,0Zm2.869,8.272H9.133v3.062a.514.514,0,0,1-.514.514H6.8a.514.514,0,0,1-.514-.514V8.272H4.839a.514.514,0,0,1-.405-.83L7.3,3.765a.514.514,0,0,1,.81,0l2.869,3.677A.514.514,0,0,1,10.576,8.272Z" fill="#ff7135"/>
									</svg>
									</button>	  
								   <span class="highlight"></span>
								   <label>Last GST Payment Date</label>
							   </div>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
					<!-- INPUT ROW -->
					<div class="col-sm-6">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label mb-10"> 
								<div class="floating-label-inr flselcted">
									<input type="text" class="form-control" placeholder="" (change)="updatefile('$events', 'last_gst_date')">
									<button class="btn btn-outline-secondary fs16 btn-o-default" type="button">
									<span>Update</span>
									<svg xmlns="http://www.w3.org/2000/svg" width="15.415" height="15.415" viewBox="0 0 15.415 15.415">
									  <path id="Path_2419" data-name="Path 2419" d="M7.708,0a7.708,7.708,0,1,0,7.708,7.708A7.7,7.7,0,0,0,7.708,0Zm2.869,8.272H9.133v3.062a.514.514,0,0,1-.514.514H6.8a.514.514,0,0,1-.514-.514V8.272H4.839a.514.514,0,0,1-.405-.83L7.3,3.765a.514.514,0,0,1,.81,0l2.869,3.677A.514.514,0,0,1,10.576,8.272Z" fill="#ff7135"/>
									</svg>
									</button>	  
								   <span class="highlight"></span>
								   <label>Last Filled GST Document</label>
								   <div class="docselected"><img src="assets/images/svg/attach.svg"><span>Last Filled GST Document.pdf</span></div>
							   </div>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
				</div>
			</div>
		</div>

		<div class="card cardui carduipro p-4 mb-2" *ngIf="business_detail.no_gst">
			<h4 class="f-700 fs20 txtdefault pb-10">Business Documents</h4>
			<div class="profileipt">
				<div class="row">
					<div class="col-sm-6">
						<!-- INPUT ROW -->
						<div class="form-container mt-0 mb-4">
							<div class="floating-label mb-10 pr-0"> 
								<input type="text" class="form-control" placeholder=" " readonly value="{{ business_detail.nogstbusinessdocvalue }}">						  
							<span class="highlight"></span>
							<label>Business Document</label>	
							</div>
						</div>
						<!-- /INPUT ROW -->	
					</div>
					<!-- INPUT ROW -->
					<div class="col-sm-6 ">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label mb-10 flselcted"> 
								<input type="text" class="form-control" placeholder=" ">
								<button class="btn btn-outline-secondary fs16 btn-o-default" type="button">
									<span><a href="{{ business_detail.nogstbusinessdocpath }}" target="_blank">View File</a></span>
									<svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107" viewBox="0 0 18.71 12.107">
								  <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
									<path id="Path_2337" data-name="Path 2337" d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z" transform="translate(-14.582 -27.082)" fill="#ff7135"/>
									<g id="Group_15718" data-name="Group 15718">
									  <g id="Group_15717" data-name="Group 15717">
										<g id="Group_15716" data-name="Group 15716">
										  <path id="Path_2338" data-name="Path 2338" d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4" transform="translate(-36.046 -39.347)" fill="#ff7135"/>
										</g>
									  </g>
									</g>
								  </g>
								</svg>

								</button>	  
							   <span class="highlight"></span>
							   <label>Business Document</label>
							   <div class="docselected"><img src="assets/images/svg/attach.svg"><span>Business Document.pdf</span></div>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
				</div>					
				
			</div>
		</div>
		<!-- /GST DOCUMENTS -->
		<!-- Identity DOCUMENTS -->
		<div class="card cardui carduipro p-4 mb-2">
			<h4 class="f-700 fs20 txtdefault pb-10">Identity Documents</h4>
			<div class="profileipt">
				<div class="row">
					<!-- INPUT ROW -->
					<div class="col-sm-6 ">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label mb-10 pr-0"> 
								<input type="text" class="form-control" placeholder=" " readonly value="{{ business_detail.pan_number }}">
							   <span class="highlight"></span>
							   <label>PAN Number</label>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
					<!-- INPUT ROW -->
					<div class="col-sm-6">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label mb-10 flselcted"> 
								<input type="text" class="form-control" placeholder=" ">
								<button class="btn btn-outline-secondary fs16 btn-o-default" type="button">
									<span><a href="{{business_detail.pan_document_path}}" target="_blank">View File</a></span>
									<svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107" viewBox="0 0 18.71 12.107">
								  <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
									<path id="Path_2337" data-name="Path 2337" d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z" transform="translate(-14.582 -27.082)" fill="#ff7135"/>
									<g id="Group_15718" data-name="Group 15718">
									  <g id="Group_15717" data-name="Group 15717">
										<g id="Group_15716" data-name="Group 15716">
										  <path id="Path_2338" data-name="Path 2338" d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4" transform="translate(-36.046 -39.347)" fill="#ff7135"/>
										</g>
									  </g>
									</g>
								  </g>
								</svg>								
								</button>	  
							   <span class="highlight"></span>
							   <label>PAN Card Document</label>
							   <div class="docselected"><img src="assets/images/svg/attach.svg"><span>PAN Card.pdf</span></div>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
					<!-- INPUT ROW -->
					<div class="col-sm-6">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label mb-10 pr-0"> 
								<input type="text" class="form-control" placeholder=" " value="{{ business_detail.aadhar_number }}">
							   <span class="highlight"></span>
							   <label>Aadhaar Number</label>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
					<!-- INPUT ROW -->
					<div class="col-sm-6">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label flselcted mb-10"> 
								<input type="text" class="form-control" placeholder=" ">
								<button class="btn btn-outline-secondary fs16 btn-o-default" type="button">
								<span><a href="{{ business_detail.aadhar_document_path }}"target="_blank">View File</a></span>
									<svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107" viewBox="0 0 18.71 12.107">
								  <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
									<path id="Path_2337" data-name="Path 2337" d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z" transform="translate(-14.582 -27.082)" fill="#ff7135"/>
									<g id="Group_15718" data-name="Group 15718">
									  <g id="Group_15717" data-name="Group 15717">
										<g id="Group_15716" data-name="Group 15716">
										  <path id="Path_2338" data-name="Path 2338" d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4" transform="translate(-36.046 -39.347)" fill="#ff7135"/>
										</g>
									  </g>
									</g>
								  </g>
								</svg>
								</button>	  
							   <span class="highlight"></span>
							   <label>Aadhaar Card Document</label>
							   <div class="docselected"><img src="assets/images/svg/attach.svg"><span>Aadhaar Number.pdf</span></div>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
					<!-- INPUT ROW -->
					<div class="col-sm-6">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label mb-10 pr-0"> 
								<input type="text" class="form-control" placeholder="" readonly value="{{ business_detail.iec_number }}">
							   <span class="highlight"></span>
							   <label>IEC Number</label>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
					<!-- INPUT ROW -->
					<div class="col-sm-6">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label flselcted mb-10"> 
								<input type="text" class="form-control" placeholder=" ">
								<button class="btn btn-outline-secondary fs16 btn-o-default" type="button">
								<span><a href="{{business_detail.iec_document_path}}">View File</a></span>
								<svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107" viewBox="0 0 18.71 12.107">
								  <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
									<path id="Path_2337" data-name="Path 2337" d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z" transform="translate(-14.582 -27.082)" fill="#ff7135"/>
									<g id="Group_15718" data-name="Group 15718">
									  <g id="Group_15717" data-name="Group 15717">
										<g id="Group_15716" data-name="Group 15716">
										  <path id="Path_2338" data-name="Path 2338" d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4" transform="translate(-36.046 -39.347)" fill="#ff7135"/>
										</g>
									  </g>
									</g>
								  </g>
								</svg>


								</button>	  
							   <span class="highlight"></span>
							   <label>IEC Document</label>
							    <div class="docselected"><img src="assets/images/svg/attach.svg"><span>IEC Document.pdf</span></div>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
				</div>					
				
			</div>
		</div>
		<!-- /Identity DOCUMENTS -->
		<!-- Addresses -->
		<div class="card cardui carduipro p-4 mb-2">
			<h4 class="f-700 fs20 txtdefault pb-10">Addresses</h4>
			<div class="profileipt">

				<div class="form-container mt-0 mb-4 p-eml-rt">
					<div class="floating-label2 mb-10 flselcted"> 
						<input type="text" class="form-control" placeholder=" " value="{{ business_detail.registered_office_address_txt }}">
						<button class="btn btn-outline-secondary fs16 btn-o-default mt-xs-10" type="button">
							<span><a href="javascript:;" (click)="addaddr(newregisteradrs)">Request to change address</a></span>
							<svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107" viewBox="0 0 18.71 12.107">
						  <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
							<path id="Path_2337" data-name="Path 2337" d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z" transform="translate(-14.582 -27.082)" fill="#ff7135"/>
							<g id="Group_15718" data-name="Group 15718">
							  <g id="Group_15717" data-name="Group 15717">
								<g id="Group_15716" data-name="Group 15716">
								  <path id="Path_2338" data-name="Path 2338" d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4" transform="translate(-36.046 -39.347)" fill="#ff7135"/>
								</g>
							  </g>
							</g>
						  </g>
						</svg>

						</button>	  
					   <span class="highlight"></span>
					   <label>Registered Office Address</label>
					</div>
				</div>

				<!-- INPUT ROW -->
				<div class="form-container mt-0 mb-4" *ngFor="let addr of business_detail.secondary_locations; let i = index;">
					<div class="floating-label mb-10 pr-0">
						<input type="text" class="form-control" placeholder=" " value="{{ getfulladdress(addr) }}">						  
					   <span class="highlight"></span>
					   <label>Shipping/Billing Address - {{i+1}}</label>	
						<a href="javascript:void(0);" *ngIf="addr.is_default" class="btn-d-outline place-bid shpdflt">Default</a>
					</div>
					<div class="text-right mb-2">
						<a class="editicon p-3 align-middle d-inline-block" href="javascript:void(0);" (click)="editaddress(editaddressModal, addr, i)"></a>
						<span class="dvdln mx-2 align-middle d-inline-block"> </span>
						<a class="p-3 deleteicon align-middle d-inline-block" href="javascript:void(0);" (click)="deleteaddress(addr, i)"></a>
					</div>
				</div>
				<!-- /INPUT ROW -->
			</div>
			<div class="text-center mb-4 pb-2">
				<a href="javascript:void(0);" (click)="addaddr(newadrs)" class="btn-d-outline px-4 d-inline-block py-3 place-bid ">
				<svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="14.681" height="14.681" viewBox="0 0 14.681 14.681">
				  <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1 1)">
					<path id="Path_8" data-name="Path 8" d="M18,7.5V20.181" transform="translate(-11.659 -7.5)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					<path id="Path_9" data-name="Path 9" d="M7.5,18H20.181" transform="translate(-7.5 -11.659)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
				  </g>
				</svg>

				Add New Address
				
				</a>
			</div>
		</div>
		<!-- /Addresses -->
		<!-- Designated Person -->
		<div class="card cardui carduipro p-4 mb-2" *ngIf="business_detail.owner_firstname">
			<h4 class="f-700 fs20 txtdefault pb-10">Designated Person</h4>
			<div class="">
				<div class="d-table">
					<div class="d-table-cell align-middle text-center  txtavtarwrp">
						<div class="txtavtar fs20 textwhite wt700">{{ getnamecode(business_detail.owner_firstname, business_detail.owner_lastname) }}</div>
					</div>
					<div class="d-table-cell dsg-pinfo pl-3 align-middle">
						<h5 class="fs14 wt700 mb-1 mt-0 txtdefault">{{ business_detail.owner_firstname }} {{ business_detail.owner_lastname }} <span class="fs12 wt400 textsecondary">Owner</span></h5>
						<p class="fs12 txtdefault wt400 mr-4 d-inline-block mb-0"><i class="d-inline-block p-2 callicon"></i> +91 {{ business_detail.owner_mobile }}</p>
						<p class="fs12 txtdefault wt500 d-inline-block mb-0" *ngIf="business_detail.owner_email"><i class="d-inline-block p-2 envelopeicon"></i> {{ business_detail.owner_email }}</p>
					</div>
				</div>
			</div>
		</div>	
		<!-- /Designated Person -->
		<!-- Authorized Persons -->
		<div class="card cardui carduipro p-4 mb-2" *ngIf="business_detail.authorized_persons.length > 0">
			<h4 class="f-700 fs20 txtdefault pb-10" *ngIf="business_detail.authorized_persons[0].name">Authorized Persons</h4>
			<!-- REPEAT -->	
			<div class="mb-3" *ngFor="let person of business_detail.authorized_persons;">
				<div class="d-table">
					<div class="d-table-cell align-middle text-center  txtavtarwrp">
						<div class="txtavtar fs20 textwhite wt700">{{ getnamecode(person.name) }}</div>
					</div>
					<div class="d-table-cell dsg-pinfo pl-3 align-middle">
						<h5 class="fs14 wt700 mb-1 mt-0 txtdefault">{{ person.name}}</h5>
						<p class="fs12 txtdefault wt400 mr-4 d-inline-block mb-0"><i class="d-inline-block p-2 callicon"></i> +91 {{ person.phone_number}}</p>
						<p class="fs12 txtdefault wt500 d-inline-block mb-0" *ngIf="person.email"><i class="d-inline-block p-2 envelopeicon"></i>{{ person.email }} </p>
					</div>
				</div>
			</div>
			<!-- /REPEAT -->	
			<div class="text-center pt-4 pb-2">
				<a (click)="adrsmdl(newnewauthprsn)" href="javascript:void(0);" class="btn-d-outline d-inline-block px-4 py-3 place-bid ">
				<svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="14.681" height="14.681" viewBox="0 0 14.681 14.681">
				  <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1 1)">
					<path id="Path_8" data-name="Path 8" d="M18,7.5V20.181" transform="translate(-11.659 -7.5)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					<path id="Path_9" data-name="Path 9" d="M7.5,18H20.181" transform="translate(-7.5 -11.659)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
				  </g>
				</svg>

				Add New Person
				
				</a>
			</div>
		</div>	
		<!-- /Authorized Persons -->
		</div>
	</div>
</div>
<!-- EDIT LOGO -->
<ng-template #profilephoto let-modal>
	<div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
            <h5 class="modal-title text-center">Edit Logo</h5>

            <div class="form-container">
				<div class="file-inpt-cntnt radius10 text-center">
					<input type="file" class="fltyp" (change)="uploadfile($event)">
					<p class="fs16 txtseconday mb-0">Drag and Drop an Image Here</p>
				</div>
            </div>   

            <div class="cta  pb-4 text-right">
                <a  class="btn btn-mod btn-color btn-medium btn-round ripple" (click)="uploadbrandlogo()">Upload from Device</a>
				<a  class="resend d-inline-block ml-3">Remove Photo</a>
            </div>

      </div>
</ng-template>
<!-- EDIT LOGO -->
<!-- GST FILLING -->
<ng-template #gstfrq let-modal>
	<div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
            <h5 class="modal-title text-center cg-ttl">Change GST Filing Frequency</h5>

            <div class="text-left pt-4">
				<p class="fs14 txtdefault">Select your GST Filing Frequency</p>
				<div class="gstfq-wp roundradio">
					<div class="cssinptchk css-check mb-3">
						<input type="radio" name="gstfq" class="css-checkbx" />
						<label class="css-label">Monthly</label>
					</div>
					<div class="cssinptchk css-check mb-3">
						<input type="radio" name="gstfq" class="css-checkbx" />
						<label class="css-label">Quaterly</label>
					</div>
				</div>
            </div>   

            <div class="cta  pb-4 pt-4">
                <a  class="btn btn-mod btn-color btn-medium btn-round ripple btn-block">Update</a>
				
            </div>

      </div>
</ng-template>
<!-- GST FILLING -->
<!-- ADD NEW ADDRESS -->
<ng-template #newadrs let-modal>
	<div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
            <h5 class="modal-title text-center cg-ttl pb-2">Add New Shipping/Billing Address</h5>
			<form [formGroup]="addShippingForm">
				<div class="form-container mdl-frm-lbl mb-3">
					<div class="row">
						<div class="col-sm-12 mb-4">
							<div class="floating-label mb-0">      
							<textarea class="form-control" placeholder="" formControlName="address"></textarea>
							<span class="highlight"></span>
							<label>Address</label>
							</div> 
						</div>
					</div>

					
					<div class="row">

						<div class="col-sm-6 mb-4">
							<div class="floating-label mb-0">
								<select class="form-control" formControlName="state" (change)="getcity()">
									<option selected="selected" value="">Please Select</option>
									<option *ngFor="let item of stateArray" [ngValue]="item.value">{{item.name}}</option>
								</select>
								<span class="highlight"></span>
								<label>State</label>
							</div>
						</div>
						<div class="col-sm-6 mb-4">
							<div class="floating-label mb-0">
								<select class="form-control" formControlName="city">
									<option selected="selected" value="">Please Select</option>
									<option *ngFor="let item of cityArray" [ngValue]="item.value">{{item.name}}</option>
								</select>
								<span class="highlight"></span>
								<label>City</label>
							</div>
						</div>

						<div class="col-sm-6 mb-4">
							<div class="floating-label mb-0">      
							<input type="text" class="form-control" placeholder="" formControlName="pincode" />
							<span class="highlight"></span>
							<label>Pincode</label>
							</div> 
						</div>
						<div class="col-sm-6 mb-4">
							<div class="floating-label mb-0">      
							<input type="text" class="form-control" placeholder="" formControlName="address_name" />
							<span class="highlight"></span>
							<label>Name this address as (optional)</label>
							</div> 
						</div>
					</div>
				</div>   

				<div class="cta  pb-4 text-center">
					<button  class="btn btn-mod btn-color btn-medium btn-round ripple mnbtn250" (click)="saveshippingform()" [disabled]="addShippingForm.invalid">Add Address</button>
					
				</div>
			</form>

      </div>
</ng-template>
<!-- /ADD NEW ADDRESS -->
<!-- ADD NEW AUTHORIZED PERSON -->
<ng-template #newnewauthprsn let-modal>
	<div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
            <h5 class="modal-title text-center cg-ttl pb-2">Add New Authorized Person</h5>
			<form [formGroup]="personsForm">
				<div class="form-container mdl-frm-lbl mb-3">
					<div class="row">
						<div class="col-sm-12 mb-4">
							<div class="floating-label mb-0">      
							<input type="text" class="form-control" placeholder=" " formControlName="name"/>
							<span class="highlight"></span>
							<label>Full Name</label>
							</div> 
						</div>
					</div>
					<div class="row">
						<div class="col-sm-6 mb-4">
							<div class="floating-label mb-0">      
							<input type="text" class="form-control" placeholder=" "  formControlName="phone_number"/>
							<span class="highlight"></span>
							<label>Mobile Number</label>
							</div> 
						</div>
						<div class="col-sm-6 mb-4">
							<div class="floating-label mb-0">      
							<input type="text" class="form-control" placeholder=" "  formControlName="email"/>
							<span class="highlight"></span>
							<label>Email ID</label>
							</div> 
						</div>					
					</div>
				</div>   

				<div class="cta  pb-4 text-center">
					<button  class="btn btn-mod btn-color btn-medium btn-round ripple mnbtn250" [disabled]="personsForm.invalid" (click)="saveauthorizedpersons()">Add Person</button>
					
				</div>
			</form>

      </div>
</ng-template>
<!-- /ADD NEW AUTHORIZED PERSON -->
<app-footer-buyer></app-footer-buyer>


<!-- ADD NEW ADDRESS -->
<ng-template #editaddressModal let-modal>
	<div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
            <h5 class="modal-title text-center cg-ttl pb-2">Update Shipping/Billing Address</h5>
			<form [formGroup]="updateShippingForm">
            <div class="form-container mdl-frm-lbl mb-3">
				<div class="row">
					<div class="col-sm-12 mb-4">
						<div class="floating-label mb-0">      
						  <textarea class="form-control" placeholder="" formControlName="address"></textarea>
						  <span class="highlight"></span>
						  <label>Address</label>
						</div> 
					</div>
				</div>
				<div class="row">
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">
							<select class="form-control" formControlName="state" (change)="getcity('update')">
								<option selected="selected" value="">Please Select</option>
								<option *ngFor="let item of stateArray" [ngValue]="item.value">{{item.name}}</option>
							</select>
							<span class="highlight"></span>
							<label>State</label>
						</div>
					</div>
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">
							<select class="form-control" formControlName="city">
								<option selected="selected" value="">Please Select</option>
								<option *ngFor="let item of cityArray" [ngValue]="item.value">{{item.name}}</option>
							</select>
							<span class="highlight"></span>
							<label>City</label>
						</div>
					</div>
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input type="text" class="form-control" placeholder=" " formControlName="pincode" />
						  <span class="highlight"></span>
						  <label>Pincode</label>
						</div> 
					</div>
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input type="text" class="form-control" placeholder=" " formControlName="address_name" />
						  <span class="highlight"></span>
						  <label>Name this address as (optional)</label>
						</div> 
					</div>
				</div>
			</div>   

            <div class="cta  pb-4 text-center">
                <button  class="btn btn-mod btn-color btn-medium btn-round ripple mnbtn250" [disabled]="updateShippingForm.invalid" (click)="updateshippingform()" >Update Address</button>
				
            </div>
		</form>

      </div>
</ng-template>
<!-- /ADD NEW ADDRESS -->

<!-- ADD NEW BILLING ADDRESS -->
<ng-template #newregisteradrs let-modal>
	<div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
		<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
            <h5 class="modal-title text-center cg-ttl pb-2">Add New Registered Address</h5>
			<form [formGroup]="addRegisteredForm">
				<div class="form-container mdl-frm-lbl mb-3">
					<div class="row">
						<div class="col-sm-12 mb-4">
							<div class="floating-label mb-0">      
							<textarea class="form-control" placeholder="" formControlName="address"></textarea>
							<span class="highlight"></span>
							<label>Address</label>
							</div> 
						</div>
					</div>

					
					<div class="row">

						<div class="col-sm-6 mb-4">
							<div class="floating-label mb-0">
								<select class="form-control" formControlName="state" (change)="getcity('registered')">
									<option selected="selected" value="">Please Select</option>
									<option *ngFor="let item of stateArray" [ngValue]="item.value">{{item.name}}</option>
								</select>
								<span class="highlight"></span>
								<label>State</label>
							</div>
						</div>
						<div class="col-sm-6 mb-4">
							<div class="floating-label mb-0">
								<select class="form-control" formControlName="city">
									<option selected="selected" value="">Please Select</option>
									<option *ngFor="let item of cityArray" [ngValue]="item.value">{{item.name}}</option>
								</select>
								<span class="highlight"></span>
								<label>City</label>
							</div>
						</div>

						<div class="col-sm-6 mb-4">
							<div class="floating-label mb-0">      
							<input type="text" class="form-control" placeholder="" formControlName="pincode" />
							<span class="highlight"></span>
							<label>Pincode</label>
							</div> 
						</div>
					</div>
				</div>   

				<div class="cta  pb-4 text-center">
					<button  class="btn btn-mod btn-color btn-medium btn-round ripple mnbtn250" (click)="saveregisteredform()" [disabled]="addRegisteredForm.invalid">Add Address</button>
					
				</div>
			</form>

      </div>
</ng-template>
<!-- /ADD NEW ADDRESS -->