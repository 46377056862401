import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ManageBuyerDetailsService } from '../view-details/view-details.service';
import { ActivatedRoute } from '@angular/router';
import { EncrDecrService } from 'src/app/helpers/EncrDecrService';
import { RegistrationDetailsService } from 'src/app/registration/registration-details/registration-details.service';
import { ToasterService } from 'src/app/helpers/toastr.service';

@Component({
    selector: 'app-buyer-bank-details',
    templateUrl: './buyer-bank-details.component.html',
    styleUrls: ['./buyer-bank-details.component.scss']
})
export class BuyerBankDetailsComponent implements OnInit {

    constructor(
        private modalService: NgbModal,
        private _managebuyerdetailsservice: ManageBuyerDetailsService,
        private _toastr: ToasterService,
        private _EncrDecr: EncrDecrService,
        private route: ActivatedRoute,
        private _registrationdetailsservice: RegistrationDetailsService
    ) { }
    closeResult = '';
    public bankLoading:boolean = false;
    public reject_reason = '';
    public reject_message = '';
    public personal_detail:any = {};
    public buyerId:string = '';
    public bank_detail:any = {};

    public reasons = [
        {
            name: 'Improper Documents',
            value: 'Improper Documents',
        },
        {
            name: 'Address Verification pending',
            value: 'Address Verification pending',
        },
        {
            name: 'Aadhar number mismatch',
            value: 'Aadhar number mismatch',
        },
        {
            name: 'Incorrect PAN number',
            value: 'Incorrect PAN number',
        },
        {
            name: 'Aadhar not Linked with Account',
            value: 'Aadhar not Linked with Account',
        },
        {
            name: 'Missing Authorized Person',
            value: 'Missing Authorized Person',
        },
        {
            name: 'Other',
            value: 'Other',
        }
    ];


    ngOnInit(): void {
        this.buyerId = this.route.snapshot.paramMap.get('id');
        this.getdetails();
    }

    ifscdetails() {
        let ifsc_code = this._EncrDecr.set(this.bank_detail.ifsc_code);
        this._registrationdetailsservice.getifscdetails({ ifsc_code: ifsc_code }).subscribe((data) => {
            if (data['status'] == "success") {
                this.bank_detail.bank_name = data['ifscdetails']['BANK'];
                this.bank_detail.branch = data['ifscdetails']['BRANCH'];
                this.bank_detail.branch_address = data['ifscdetails']['ADDRESS'];
            }
            else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    bankDetailsSave() {
        this.bankLoading = true;
        let data: any = new FormData();
        data.append('user_id', this.buyerId);
        data.append('ifsc_code', this._EncrDecr.set(this.bank_detail.ifsc_code));
        data.append("business_name", this._EncrDecr.set(this.bank_detail.account_name));
        data.append('bank_name', this._EncrDecr.set(this.bank_detail.bank_name));
        data.append("branch_name", this._EncrDecr.set(this.bank_detail.branch));
        data.append('branch_address', this._EncrDecr.set(this.bank_detail.branch_address));
        data.append("account_number", this._EncrDecr.set(this.bank_detail.account_number));
        data.append('confirm_account_number', this._EncrDecr.set(this.bank_detail.account_number));

        this._managebuyerdetailsservice.updatebank(data).subscribe((data) => {
            this.bankLoading = false;
            if (data['status'] == "success") {
                this._toastr.success('Bank details has been updated successfully.');
                this.getdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            } else if (data['status'] == 'validation') {
                this._toastr.validation(data['errors'])
            }
        }, (error) => {
            this.bankLoading = false;
        });
    }

    getdetails() {
        this._managebuyerdetailsservice.getdetails({id: this.buyerId}).subscribe((data) => {
            if(data['status'] == "success"){
                this.bank_detail = data['records']['bank_detail'];
                this.personal_detail = data['records']['personal_detail'];
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    changestatus(type){
        if(type == 'reject'){
            if(!this.reject_reason){
                this._toastr.error('Please select reject reason.');
                return false;
            }
            if(!this.reject_message){
                this._toastr.error('Please select reject message.');
                return false;
            }
            var data = {reason: this.reject_reason, message: this.reject_message, user_id: this.buyerId, type: type};
        }else{
            var data = {reason: '', message: '', user_id: this.buyerId, type: type};
        }
        this._managebuyerdetailsservice.changestatus(data).subscribe((data) => {
            if(data['status'] == "success"){
                if(this.personal_detail.usertype == 1){
                    if(type == 'reject'){
                        this._toastr.success('Buyer rejected')
                    }else{
                        this._toastr.success('Buyer approved')
                    }
                }else{
                    if(type == 'reject'){
                        this._toastr.success('Seller rejected')
                    }else{
                        this._toastr.success('Seller approved')
                    }
                }
                this.getdetails();
                this.modalService.dismissAll();
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    openmdl(profilephoto) {
        this.modalService.open(profilephoto, { windowClass: 'midmodal' });
    }
    openmodal(rejectbuyer) {
        this.modalService.open(rejectbuyer, { ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'mblbtm', size: 'md' });
    }
    openattribute(addattribute) {
        this.modalService.open(addattribute, { ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'mblbtm', size: 'lg' });
    }

    open(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'mblbtm' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

}
