<app-header-seller></app-header-seller>
<app-sidebar-seller></app-sidebar-seller>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Payments</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" [routerLink]="['/my-wallet']">My Wallet</a>
						</li>
						<li class="breadcrumb-item active">Request Withdrawal</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:void(0);" [routerLink]="['/seller/assistance']" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<h3 class="f-700 fs22 txtdefault pb-10"><a href="javascript:;" class="go-back"><i class="fa fa-angle-left"></i></a> Request for Withdrawing Money</h3>

		<div class="card cardui carduipro p-4 mb-40">
			<div class="form-container mdl-frm-lbl mb-3 mt-0">
				<h3 class="f-700 fs20 txtdefault pb-20">Equibiz Wallet</h3>
				<div class="wallet-balance">
					<div class="mr-sm-3">
						<img src="assets/images/icons/wallet-balance.svg">
					</div>
					<div>
						<p>Current Balance:</p>
						<h4>₹ 1,05,500.08</h4>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-6 mb-2">
						<div class="floating-label mb-0">      
						  <input type="text" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>Enter amount (min ₹1,000)</label>
						</div> 
					</div>
				</div>
				<div class="help-info">Transaction Charges : 0.1%  <a href="javascript:;" class="fs14 link-alt">Know more</a></div>

				<div class="wallet-amount">
					<ul>
						<li><a href="javascript:;" class="selected">₹ 10,000</a></li>
						<li><a href="javascript:;">₹ 20,000</a></li>
						<li><a href="javascript:;">₹ 50,000</a></li>
						<li><a href="javascript:;">₹ 1,00,000</a></li>
					</ul>
				</div>
				<div class="text-left">
					<a href="javascript:void(0);" class="mr-sm-4 btn-d-outline d-inline-block">Request Withdrawal</a>
					<i placement="top" ngbTooltip="more info goes here"><img src="assets/images/svg/info.svg" /></i>
					&nbsp;The process of your withdrawal request may take up to 3 working days
				</div>
			</div>
		</div>

		<div class="mb-2 faqpanel accordionarrow">
			<h4 class="f-700 fs22 txtdefault pb-10">Frequently Asked Questions</h4>
			<ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
			  <ngb-panel title="When will I receive the money in my bank account after my withdrawal request is processed?">
				<ng-template ngbPanelContent>
				  <p class="txtdefault fs16 lnht24 mb-0">Once we process your withdrawal request, our bank debits our account, and the following SMS confirming the withdrawal is sent to your registered mobile number:</p>
				</ng-template>
			  </ngb-panel>
			  <ngb-panel title="What is the maximum amount I can add to the Wallet?">
				<ng-template ngbPanelContent>
				  <p  class="txtdefault fs16 lnht24 mb-0">Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
				</ng-template>
			  </ngb-panel>
			  <ngb-panel title="What is the maximum amount I can add to the Wallet?">
				<ng-template ngbPanelContent>
				 <p  class="txtdefault fs16 lnht24 mb-0"> Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
				</ng-template>
			  </ngb-panel>
			  <ngb-panel title="What is the maximum amount I can add to the Wallet?">
				<ng-template ngbPanelContent>
				  Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
				</ng-template>
			  </ngb-panel>
			</ngb-accordion>
		</div>

		<div class="mb-60">
			<img src="assets/images/card-payments.png">
		</div>
	</div>
</div>


<app-footer-seller></app-footer-seller>