import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {NgbNavChangeEvent} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-edit-listting',
  templateUrl: './edit-listting.component.html',
  styleUrls: ['./edit-listting.component.scss']
})
export class EditListtingComponent implements OnInit {

  active=9;
  sampleactive=1;
disabled = true;
  constructor(private modalService: NgbModal) { }
flactive = 13;
  ngOnInit(): void {
  }
 config = {
    labelField: 'label',
    valueField: 'value',
     
  }; 
data = [
    {
      label: 'India',
      value: 'india'
    },
    {
      label: 'in $',
      value: 'in $'
    }
  ]
 prdata = [
    {
      label: 'in % of M',
      value: 'in % of M'
    },
    {
      label: 'in % of M',
      value: 'in % of M'
    }
  ] 
  gbstorage=[
	{
      label: 'In GB',
      value: 'In GB'
    },
    {
      label: 'In GB',
      value: 'In GB'
    }
  ]
  regdata = [
    {
      label: 'Registered Brand Owner',
      value: 'owner'
    },
    {
      label: 'Option2',
      value: 'Option2'
    }
  ]
  monthdata = [
    {
      label: 'In Months',
      value: 'In Months'
    },
    {
      label: 'In Year',
      value: 'In Year'
    }
  ]
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'modal-500' });
  }



closeResult = '';
  
ratingclassstatus: boolean = false;
ratingtglclass(){
    this.ratingclassstatus = !this.ratingclassstatus;       
}
  
showcomperr: boolean = false;
showcompmsg(){
this.showcomperr = true;
}
imagesSlider = {
 slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  asNavFor:".thumbs",
};
thumbnailsSlider = {
  slidesToShow: 5,
  slidesToScroll: 1,
  dots: true,
  focusOnSelect: true,
  asNavFor:".feedback",
  //prevArrow:'.client-thumbnails .prev-arrow',
 // nextArrow:'.client-thumbnails .next-arrow',
};





slides = [
    {img: "assets/images/product/mobile/mobile.png"},
    {img: "assets/images/product/mobile/oppof5-3.jpg"},
    {img: "assets/images/product/mobile/oppof5-4.jpg"},
	 
    {img: "assets/images/product/mobile/oppof5-5.jpg"}
  ];

}
   