import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ManageProductCategoriesService } from './manage-product-categories.service';
import { EncrDecrService } from '../../helpers/EncrDecrService';
import { ToastrService } from 'ngx-toastr';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-manage-product-categories',
    templateUrl: './manage-product-categories.component.html',
    styleUrls: ['./manage-product-categories.component.scss']
})
export class ManageProductCategoriesComponent implements OnInit {

    closeResult = '';
    public name: string;
    public parent_name: string;
    public remove_id: string;
    constructor(
        private modalService: NgbModal,
        private _manageproductcategoryservices: ManageProductCategoriesService,
        private _EncrDecr: EncrDecrService,
        private _toastr: ToastrService
    ) { }

    public categories: any = [];

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.getcategories();
    }

    getcategories() {
        let cat_id = 'all';
        this._manageproductcategoryservices.getcategory({ cat_id: cat_id, parent: 'no' }).subscribe((data) => {
            if (data['status'] == "success") {
                this.categories = data['categories'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    updatecategorystatus(c_id, type) {
        this._manageproductcategoryservices.updatecategorystatus({ c_id: c_id, type: type }).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this.getcategories();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    showchild(records, childs) {
        records.open = !records.open;
        if (childs.length > 0) {
            for (let i = 0; i < childs.length; i++) {
                childs[i].show = !childs[i].show;
                if (!childs[i].open) {
                    childs[i].open = true;
                }
                if (!childs[i].show && childs[i].ifchild) {
                    this.updatehidechild(childs[i].child);
                }
            }
        }
    }

    updatehidechild(childs) {
        if (childs.length > 0) {
            for (let i = 0; i < childs.length; i++) {
                childs[i].show = false;
                if (!childs[i].open) {
                    childs[i].open = false;
                }
                if (!childs[i].show && childs[i].ifchild) {
                    this.updatehidechild(childs[i].child);
                }
            }
        }
    }

    public val = null;

    open(content, name, parent_name, c_id) {
        this.name = name;
        this.parent_name = parent_name;
        this.remove_id = c_id;
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true, }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    openeditcategory(editcategory) {
        this.modalService.open(editcategory, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    config = {
        labelField: 'label',
        valueField: 'value',

    };
    data = [
        {
            label: 'Price Low to High',
            value: 'Price High to Low'
        },
        {
            label: 'Product Ratings',
            value: 'Product Ratings'
        },
        {
            label: 'Relevance',
            value: 'Relevance'
        },
        {
            label: 'Latest Arrivals',
            value: 'Latest Arrivals'
        }
    ]

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
    }
}
