import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-charges-and-commision',
  templateUrl: './charges-and-commision.component.html',
  styleUrls: ['./charges-and-commision.component.scss']
})
export class ChargesAndCommisionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
