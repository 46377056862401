<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="">
    <div class="equibiz-header">
      <div class="logo-bar">
        <div class="container">
          <div class="row d-flex justify-content-between">
             <div class="col-md-3 d-flex align-items-center">
                <a [routerLink]="['/home']"><img src="assets/images/svg/eq_logo.svg"></a>
             </div>
             <div class="col-md-9">
             	<div class="text-right right-header">
             		<ul class="header-dropdown">
					  <li class="menu-item"><a href="javascript:;" [routerLink]="['/how-it-works']">Home</a></li>
					  <li class="menu-item"><a href="javascript:;" [routerLink]="['/about-us']" class="active">About Us</a></li>
					  <li class="menu-item"><a href="javascript:;" [routerLink]="['/services']">Services</a></li>
					  <li class="menu-item"><a href="javascript:;" [routerLink]="['/partners']">Partners</a></li>
					</ul>
             	</div>
             </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<section class="page-section pt-50 bg-white">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="seller-banner-container" style="background-image: url('assets/images/about-us.jpg');">
					<div class="row">
						<div class="col-md-5 text-left">
							<h3 class="hs-line-6 font-alt mb-20">
								End To End Solution For 
								The Ultimate Marketplace
							</h3>
							<div class="section-text-xs text-left mb-30">
								Welcome to India’s premium B2B trading platform and marketplace. Welcome to Equibiz
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="page-section bg-image-orange">
	<div class="container">
		<div class="row d-flex align-items-center justify-content-between">
			<div class="col-md-6">
				<img src="assets/images/about-img.png" class="img-fluid">
			</div>
			<div class="col-md-5">
				<h2 class="hs-line-7 text-left font-alt mb-10">About us</h2>
				<p>Equibiz started its operations in 2021 with an objective to be more than just an aggregator. It’s a unique electronic trading platform, connecting buyers and sellers directly across boundaries. It’s an online trading platform based on the principles of price discovery and transparent trading with a singular objective of facilitating its partners to increase their revenues and profits.</p>
			</div>
		</div>
	</div>
</section>

<section class="page-section bg-white">
	<div class="container">
		<div class="row d-flex justify-content-center">
			<div class="col-md-12">
				<h2 class="hs-line-7 text-center font-alt mb-30">Our Journey</h2>
			</div>
		</div>
		<div class="sell-product">
			<div class="line"></div>
		</div>
		<div class="row">
			<div class="col-md-4">
				<div class="alt-features-item text-center">
                    <div class="alt-features-icon orange">
                        Sept<br>2019
                    </div>
                    <div class="alt-features-descr">
                        Digixpress Trade Private Limited was set up by Mr. Sunil Kumar in Sept 2019 with an idea to launch Equibiz platform
                    </div>
                </div>
			</div>

			<div class="col-md-4">
				<div class="alt-features-item text-center">
                    <div class="alt-features-icon orange">
                        Apr<br>2021
                    </div>
                    <div class="alt-features-descr">
                        Ver 1.0 of Equibiz was launched to facilitate bulk deals in consumer electronics segment focusing on mobile phones in the beginning.
                    </div>
                </div>
			</div>

			<div class="col-md-4">
				<div class="alt-features-item text-center">
                    <div class="alt-features-icon orange">
                        May<br>2022
                    </div>
                    <div class="alt-features-descr">
                        Ver 2.0 of Equibiz was launched with an objective to directly connect buyers with sellers by facilitating wholesale and bulk deals in the segment of consumer electronics.
                    </div>
                </div>
			</div>
		</div>

		<div class="row d-flex justify-content-center">
			<div class="col-md-10">
				<div class="equibiz-count mt-30">
					<div class="row d-flex justify-content-between">
						<div class="col-md-4">
							<div class="count-info">
								<img src="assets/images/icons/cities.png" class="mr-3">
								<div>
									<h4 class="count-no">25000+</h4>
									<p>Pincodes</p>
								</div>
							</div>
						</div>
						<div class="col-md-5">
							<div class="count-info">
								<img src="assets/images/icons/customers.png" class="mr-3">
								<div>
									<h4 class="count-no">1000+</h4>
									<p>Customers</p>
								</div>
							</div>
						</div>
						<div class="col-md-3">
							<div class="count-info">
								<img src="assets/images/icons/categories.png" class="mr-3">
								<div>
									<h4 class="count-no">15+</h4>
									<p>Categories</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="page-section bg-white">
	<div class="container">
		<div class="row d-flex align-items-center">
			<div class="col-md-6">
				<h2 class="hs-line-7 text-left font-alt mb-30">Our Mission & Vision</h2>

				<div class="mission-card">
					<div class="mb-50">
						<img src="assets/images/icons/mission.png">
						<h4 class="f-700 fs26 text-orange mt-20 mb-20">Mission</h4>
						<div class="text-dark fs15 f-500">
							Our mission is to bring together businesses from all over the country - from the smallest to the largest - on a single, fair and transparent B2B platform. As a company, we operate on the principles of honesty and integrity
						</div>
					</div>

					<div>
						<img src="assets/images/icons/vision.png">
						<h4 class="f-700 fs26 text-orange mt-20 mb-20">Vision</h4>
						<div class="text-dark fs15 f-500">Our vision is to become a local reference for all products traded over B2B platforms. Think B2B, think eQuibiz</div>
					</div>
				</div>
			</div>

			<div class="col-md-6">
				<h2 class="hs-line-7 text-left font-alt mb-30">Our Speciality</h2>
				<div>
					<img src="assets/images/speciality.jpg" class="img-fluid">
				</div>
			</div>
		</div>	
	</div>
</section>

<!-- <section class="page-section bg-light-blue">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h2 class="hs-line-7 text-center font-alt mb-50">Meet Our Team</h2>
			</div>
		</div>
		<div class="row d-flex justify-content-center">
			<div class="col-md-4">
				<div class="team-item">	
					<div class="card-alt shadow text-center team-item-image">
						<img src="assets/images/sunil.png" class="author">
						<div class="mt-10">
							<div class="text-dark f-700 fs26">Mr. Sunil Kumar</div>
							<div class="text-dark f-600 fs20">CEO and Founder</div>
						</div>
						<div class="team-item-detail">
							<div class="team-item-descr">
								<h4>Mr. Sunil Kumar</h4>
								Having more than 20 years of experience in the banking industry and a keen knack of pursuing and overcoming challenges, Mr. Sunil has worked in the PSU sector and Foreign Banks. Mr. Sunil started his entrepreneurial journey in 2018 by setting up his own venture in the area of mobility. Soon, the journey paved way for a much larger vision of overhauling the online B2B segment and the foundation of Equibiz was laid.
							</div>
						</div>
					</div>
				</div> 
			</div>
			<div class="col-md-4">
				<div class="team-item">	
					<div class="card-alt shadow text-center team-item-image">
						<img src="assets/images/rajesh.png" class="author">
						<div class="mt-10">
							<div class="text-dark f-700 fs26">Mr. Rajesh Pathak</div>
							<div class="text-dark f-600 fs20">Head Sales</div>
						</div>
						<div class="team-item-detail">
							<div class="team-item-descr">
								<h4>Mr. Rajesh Pathak</h4>
								MBA (Marketing & Strategy), MDI Gurgaon 19+ years of experience in Business Development, Channel Management, Trade Marketing, Strategic and Business Expansion/Scaling roles.
							</div>
						</div>
					</div>
				</div> 
			</div>
		</div>
	</div>
</section> -->
<app-footer-one></app-footer-one>