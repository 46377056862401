import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { RequestCallbackListService } from './request-callback-list.service';
@Component({
  selector: 'app-request-callback-list',
  templateUrl: './request-callback-list.component.html',
  styleUrls: ['./request-callback-list.component.scss']
})
export class RequestCallbackListComponent implements OnInit {

  closeResult = '';
  public selected_record:any = {};
  public requestcallback_records:any = [];

  public current_page: number = 1;
  public offset: number = 0;
  public page_per_view: number = 10;
  public requestcallback_pagination: any = {};
  public page_per_view_options = [
      {
          value: 10,
          name: 10
      },
      {
          value: 20,
          name: 20
      }
  ];

  constructor(
      private modalService: NgbModal,
      private _requestcallbackservice: RequestCallbackListService,
      private _toastr: ToasterService,
      private _pagination: PaginationService
  ) {}

  ngOnInit(): void {
      this.getrequestcallback();
  }

  getrequestcallback() {
      let limit = this.page_per_view;
      let offset = this.offset;
      let current_page = this.current_page;
      this._requestcallbackservice.getrequestcallback({ limit: limit, offset: offset, current_page: current_page }).subscribe((data) => {
          if (data['status'] == "success") {
              let total_records = data['total_records'];
              this.requestcallback_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
              this.requestcallback_records = data['records'];
          } else if (data['status'] == "error") {
              this._toastr.error(data['message']);
          }
      });
  }

  setpage(page) {
      this.current_page = page;
      this.offset = (page - 1) * this.page_per_view;
      this.getrequestcallback();
  }

  setpageperview() {
      this.current_page = 1;
      this.getrequestcallback();
  }

}
