import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { BuyerManageCategoriesService } from './buyer-manage-categories.service';

@Component({
  selector: 'app-buyer-manage-categories',
  templateUrl: './buyer-manage-categories.component.html',
  styleUrls: ['./buyer-manage-categories.component.scss']
})
export class BuyerManageCategoriesComponent implements OnInit {

    public records:any = [];
    public select_all:boolean = false;
    constructor(
        private _pageservice: BuyerManageCategoriesService,
        private _toastr: ToasterService
    ) { }

    ngOnInit(): void {
        this.getcategories();
    }

    getcategories() {
        this._pageservice.getcategories(null).subscribe((data) => {
            if(data['status'] == "success"){
                this.records = data['records'];
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    resetcategory(){
        for(var i = 0; i < this.records.length; i++){
            this.records[i].selected = false;
        }
        this.select_all = false;
    }

    changeall(){
        if(this.select_all){
            for(var i = 0; i < this.records.length; i++){
                this.records[i].selected = true;
            }
        }else{
            for(var i = 0; i < this.records.length; i++){
                this.records[i].selected = false;
            }
        }
    }

    updatecheckbox(){
        var checked = this.records.filter((product) => product.selected == true);
        if(this.records.length == checked.length){
            this.select_all = true;
        }else{
            this.select_all = false;
        }
    }

    savecategories() {
        this._pageservice.savecategories({records: this.records}).subscribe((data) => {
            if(data['status'] == "success"){
                this._toastr.success('Your preference categories has been saved successfully.');
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

}
