import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CmsTermsofUseService {

  constructor(private _http: HttpClient) { }

    savecms(data) {
        return this._http.post(environment.api_url+'admin/cms/add',data);
    }

    getcms(data) {
      return this._http.post(environment.api_url+'admin/cms/data',data);
    }

}
