import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { AllBidsService } from './all-bids.service';
import { ProductCategoryService } from 'src/app/admin/product-category/product-category.service';
import { AddListingService } from 'src/app/seller/add-listing/addlisting.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginationService } from 'src/app/helpers/pagination.service';

@Component({
  selector: 'app-all-bids',
  templateUrl: './all-bids.component.html',
  styleUrls: ['./all-bids.component.scss']
})
export class AllBidsComponent implements OnInit {

  closeResult = '';
  public bid_status:string = 'pending';
  public product_id: string;
  public bid_records: any = [];
  public mybid_records: any = [];
  public selected_bid: any = {};
  public new_bid_amount: number;
  keyword = 'name';
  public selected_category: string = '';

    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 10;
    public all_bids_pagination: any = {};
    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];

  constructor(
      private modalService: NgbModal,
      private _allbidsservice: AllBidsService,
      private _toastr: ToasterService,
      private _router: Router,
      private _route: ActivatedRoute,
      private _editproductcategoryservice: ProductCategoryService,
        private _addlistingservice: AddListingService,
        private _pagination: PaginationService
  ) { }

  @ViewChild('tabset') tabset;

  ngOnInit(): void {
      this.product_id = this._route.snapshot.paramMap.get('product_id');
      this.getproductbids();
      setTimeout( () => {
        this.getbidmylisting();
      }, 1000);
      
  }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.getproductbids();
    }

    setpageperview() {
        this.current_page = 1;
        this.getproductbids();
    }

  getproductbids() {
    let limit = this.page_per_view;
    let offset = this.offset;
    let current_page = this.current_page;
      this._allbidsservice.getproductbidlisting({ limit: limit, offset: offset, current_page: current_page, bid_type: 'all', category: this.selected_category }).subscribe((data) => {
          if (data['status'] == "success") {
                let total_records = data['total_records'];
                this.all_bids_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
                this.bid_records = data['records'];
                this.mybid_records = this.bid_records.filter((product) => product.is_seller == true);
          } else if (data['status'] == "error") {
              this._toastr.error(data['message']);
          }
      });
  }

  getbidmylisting() {
      this._allbidsservice.getbidmylisting({ bid_type: 'all', bid_status: this.bid_status }).subscribe((data) => {
          if (data['status'] == "success") {
              this.mybid_records = data['records'];
          } else if (data['status'] == "error") {
              this._toastr.error(data['message']);
          }
      });
  }

  open(content, record) {
      this._allbidsservice.getbidinfo({ bid_id: record.bid_id }).subscribe((data) => {
          if (data['status'] == "success") {
              this.selected_bid = data['record'];
              this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
                  this.closeResult = `Closed with: ${result}`;
              }, (reason) => {
                  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
              });
          } else if (data['status'] == "error") {
              this._toastr.error(data['message']);
          }
      });
  }


  acceptbid() {
      this._allbidsservice.acceptbid({ bid_id: this.selected_bid.bid_id }).subscribe((data) => {
          if (data['status'] == "success") {
              this.modalService.dismissAll();
              this._toastr.success('Bid offer has been accepted.');
              this.getproductbids();
              this.getbidmylisting();
          } else if (data['status'] == "error") {
              this._toastr.error(data['message']);
          }
      });
  }

  declinebid() {
      this._allbidsservice.declinebid({ bid_id: this.selected_bid.bid_id }).subscribe((data) => {
          if (data['status'] == "success") {
              this.modalService.dismissAll();
              this._toastr.success('Bid offer has been declined.');
              this.getproductbids();
              this.getbidmylisting();
          } else if (data['status'] == "error") {
              this._toastr.error(data['message']);
          }
      });
  }

  openCounterOffer(content2, record) {
      this.modalService.dismissAll();
      this._allbidsservice.getbidinfo({ bid_id: record.bid_id }).subscribe((data) => {
          if (data['status'] == "success") {
              this.selected_bid = data['record'];
              this.modalService.open(content2, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
                  this.closeResult = `Closed with: ${result}`;
              }, (reason) => {
                  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
              });
          } else if (data['status'] == "error") {
              this._toastr.error(data['message']);
          }
      });
  }

  savecounteroffer() {
      this._allbidsservice.savecounteroffer({ bid_id: this.selected_bid.bid_id, amount: this.new_bid_amount }).subscribe((data) => {
          if (data['status'] == "success") {
              this.modalService.dismissAll();
              this._toastr.success('Counter offer has been submitted.');
              this.getproductbids();
              this.getbidmylisting();
          } else if (data['status'] == "error") {
              this._toastr.error(data['message']);
          }
      });
  }

  openRemoveProduct(content3) {
      this.modalService.open(content3, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return `with: ${reason}`;
      }
    }


    result = [];
    presult = [];

    selectEvent(item) {
        this.selected_category = item.c_id;
        this.tabset.select('allBids');
        this.getproductbids();
        setTimeout(() => {
            this.getbidmylisting();
        }, 3000);
    }

    onChangeSearch(val: string) {
        this.selected_category = val;
        this._editproductcategoryservice.searchcategory({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.result = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    onFocused(e) {
        // do something when input is focused
    }

    pselectEvent(item) {
        this._router.navigate(['/seller/bids-and-offers/' + item.product_id]);
    }

    ponChangeSearch(val: string) {
        this._addlistingservice.productsearch({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.presult = data['searchResult'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    ponFocused(e) {
        // do something when input is focused
    }

}
