<!-- PREVIEW MODAL -->
<ng-template #preview let-modal>
  
    <div class="modal-body">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-sm-9">
					<h4 class="fs18 txtdefault wt700 mb-0">You’re previewing the page. Changes won’t be visible to the buyers unless you save them.</h4>
				</div>
				<div class="col-sm-3 text-right">
					<a (click)="modal.dismiss('Cross click')" href="javascript:void(0);" class="mr-4 btn-d-outline d-inline-block">X Close Preview</a>
				</div>
			</div>
			   <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
			<!-- BREADCRUMB -->
			<nav class="mb-2 pt-2 pb-2" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
			  <ol class="breadcrumb p-0 bg-transparent">
				<li class="breadcrumb-item"><a href="javascript:void(0);" class="txtseconday">Home </a></li>
				<li *ngFor="let cat of record.category_title; let i = index;" class="breadcrumb-item" ><a href="javascript:void(0);" class="txtseconday">{{ cat }} </a></li>
				<li class="breadcrumb-item active" aria-current="page">{{ record.name }}</li>
			  </ol>
			</nav>
			<!-- /BREADCRUMB -->
			<!-- PRODUCT DESCRIPTION -->
			<div class="row mb-4 pdl-rw">
				<div class="col-lg-6 dtlitem flx45">	
					<div class="feedback-slides mb-4  ">
						   <div class="client-feedback dtlfull">
								<ngx-slick-carousel 
									class="carousel feedback" 
									#slickModal="slick-carousel" 
									[config]="imagesSlider">
									<div ngxSlickItem *ngFor="let slide of slides" class="slide">
											<img src="{{ slide.img }}" alt="" >
									</div>
								</ngx-slick-carousel>
							</div>
						</div>

						<div class="client-thumbnails dtlthmb text-center">
							 <ngx-slick-carousel 
								class="carousel thumbs" 
								#slickModal="slick-carousel" 
								[config]="thumbnailsSlider ">
								<div ngxSlickItem *ngFor="let slide of slides" class="slide">
									<div class="thmbnaildtl">
										<img src="{{ slide.img }}" alt="" width="100%">
									</div>	
								</div>
							</ngx-slick-carousel>
						</div>
				</div>
				<div class="col-lg-6 flx55">	
					<div class="pdscp-top th-cmn-bd pb-1 mb-3">
						<h2 class="fs22 f-600 mb-2">{{ record.name }}
							<span class="shareitem">
								<svg xmlns="http://www.w3.org/2000/svg" width="17.246" height="19.043" viewBox="0 0 17.246 19.043">
								  <path id="Icon_ionic-md-share" data-name="Icon ionic-md-share" d="M18.864,16.586a2.618,2.618,0,0,0-1.874.718l-6.87-3.971a3.217,3.217,0,0,0,.1-.67,3.216,3.216,0,0,0-.1-.67L16.894,8.07a2.875,2.875,0,1,0-.913-2.105,3.2,3.2,0,0,0,.1.67L9.3,10.558a2.892,2.892,0,0,0-1.97-.765A2.844,2.844,0,0,0,4.5,12.663a2.892,2.892,0,0,0,4.852,2.105l6.822,3.971a2.4,2.4,0,0,0-.1.622,2.786,2.786,0,1,0,2.786-2.775Z" transform="translate(-4.5 -3.094)" fill="#1f1d44"/>
								</svg>
							</span>
						</h2>
						<p class="fs13 f-600 txtdefault pt-2">Product Rating:<span class="f-800 dark-text mx-1">{{ record.product_rating }}</span>
						<span class="ratings star-ratings pr-1 d-inline-block">
							<i class="fa fa-star" aria-hidden="true"></i>					
						</span>
						</p>
					</div>
					<div class="pdscp-cntnt"> 
						<p class="fs14 f-600 txtdefault mb-2">Total Listing: <span class="f-800 dark-text">{{ record.total_stock }} Seller</span></p>
						<p class="fs14 f-600 txtdefault mb-3 mr-3  d-inline-block">Starting From: <span class="f-800 ml-1 fs20 txtactive">₹ {{ record.starting_sale_price }}</span> <span class=" fs16 txtdisable ml-1 crossprice">₹ {{ record.starting_regular_price }}</span></p>
						<p class="pricetag d-inline-block"><i class="p-2 mr-2"></i><span class="f-800 dark-text" *ngIf="record.offer_percentage > 0">{{ record.offer_percentage }}% Off</span> on MRP</p>


						<div class="select-size" *ngFor="let meta of record.product_variant; let i = index;">
							<div class="f-600">{{ meta.name }}:</div>
							<div class="select-prod-size mb-30">
								<ul>
									<li><a href="javascript:;" *ngFor="let vari of meta.meta;let i = index;"  [ngClass]="{'selected': vari.selected }" (click)="getselectedvariant(vari, meta)">{{ vari.variant_value }}</a></li>
								</ul>
							</div>
						</div>
						<div class="mb-3">
							<a href="javascript:void(0);" class="mr-4 btn-d-outline d-inline-block">View Seller Listing</a>
							<a href="javascript:void(0);" class="p-2 my-3 fs16 add-whislist-link ">
								<svg xmlns="http://www.w3.org/2000/svg" width="19.035" height="16.303" viewBox="0 0 19.035 16.303"><path id="Path_2367" data-name="Path 2367" d="M15.328,3.559A5.062,5.062,0,0,0,11.017,5.7,5.058,5.058,0,0,0,6.707,3.55,4.762,4.762,0,0,0,2,8.248c0,5.852,9.017,10.541,9.017,10.541S20.035,14.1,20.035,8.248A4.748,4.748,0,0,0,15.328,3.559Z" transform="translate(-1.5 -3.05)" fill="none" stroke="#1f1d44" stroke-width="1"/></svg>
								<span class="fs16">Add to Wishlist</span>
							</a>
						</div>
						<div class="keyfeat pt-2">
							<h4 class="f-600 fs14">Key Features:</h4>
							{{ record.key_features }}
						</div>

						<div class="keyfeat pt-2" *ngIf="record.warranty.length > 0">
							<p class="txtdefault" *ngFor="let war of record.warranty;">
								<span class="txtseconday f-500">{{ war.key }}:</span>&nbsp;{{ war.value }}
							</p>
						</div>

					</div>
				</div>
			</div>
			<!-- /PRODUCT DESCRIPTION -->
			
			<!-- INSTRUCTION -->
			<div class="mb-4 pb-1">
				<h4 class="f-700 fs16 txtdefault pb-20">Instructions from the Manufacturer</h4>
				<p class="fs14 txtseconday lnht24">{{ record.instruction_from_manufacturer }}</p>
			</div>
			<!-- /INSTRUCTION -->
			<!-- Manufacturer -->
			<div class="mb-4 pb-3" *ngIf="record.bannerImage.length">
				<h4 class="f-700 fs16 txtdefault mb-2 pb-1">From the Manufacturer</h4>
				<div class="photorow" *ngFor="let banner of record.bannerImage;">
					<img src="{{ banner }}" />
				</div>
			</div>
			<!-- /Manufacturer -->
			<!-- INSTRUCTION -->
			<div class="mb-50" *ngIf="chunked.length > 0">
				<h4 class="f-700 fs16 txtdefault mb-2 pb-2">Technical Specification &nbsp;<span class="fontdefault f-500 fs14 txtfade">{{ record.name }}</span></h4>

				<div class="tech-spec">
					<!--Repeat-->
					<div class="row no-gutters" *ngFor="let chunk of chunked; let i = index;">
						<div class="col-md-6" *ngFor="let rec of chunk; let k = index;">
							<div class="tech-item d-flex">
								<div class="title">{{ rec.label }}</div>
								<div class="desc" *ngIf="rec.type != 'link'">{{ rec.value }}</div>
								<div class="desc" *ngIf="rec.type == 'link'"><a href="{{rec.value}}" target="_bland">{{ rec.value }}</a></div>	
							</div>
						</div>
					</div>
					<!--Repeat-->
				</div>
			</div>
			<!-- /INSTRUCTION -->
			
		</div>
	</div>
</ng-template>	
<!-- /PREVIEW MODAL -->