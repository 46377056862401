import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class EditSellerListingService {

    constructor(private _http: HttpClient) { }

    getcountry(data) {
        return this._http.post(environment.api_url+'master/countries/list',data);
    }

    getvariants(data) {
        return this._http.post(environment.api_url+'admin/seller/product/variant',data);
    }

    getsellerproductdetails(data) {
        return this._http.post(environment.api_url+'admin/seller/product/details', data);
    }

    saveunique(data) {
        return this._http.post(environment.api_url+'admin/seller/product/uniqueid/add',data);
    }

    saveproduct(data) {
        return this._http.post(environment.api_url+'admin/seller/product/detail/add',data);
    }

    savemarketplace(data) {
        return this._http.post(environment.api_url+'admin/seller/product/marketplace/add',data);
    }

    savepricing(data) {
        return this._http.post(environment.api_url+'admin/seller/product/price/add',data);
    }

    savepayment(data) {
        return this._http.post(environment.api_url+'admin/seller/product/payment/add',data);
    }

    updatestatus(data){
        return this._http.post(environment.api_url+'admin/seller/product/status',data);
    }

    searchhsn(data) {
        return this._http.post(environment.api_url+'master/hsn/autocomplete', data);
    }

    netrealizationvalue(data) {
        return this._http.post(environment.api_url+'admin/seller/product/netrealization', data);
    }

    

    

    

}