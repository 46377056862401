<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
    <div class="container">
        <!--- HEAD  --->
        <div class="row align-items-center mb-4 pinfo-head">
            <div class="col-sm-6 col-7 pr-0">
                <nav class="breadcrumb breadcrumb-dui mb-0">
                    <ol class="breadcrumb p-0 bg-transparent  mb-0">
                        <li class="breadcrumb-item">
                            <a class="txtseconday" href="javascript:void(0);"
                                [routerLink]="['/admin/product-listing']">Product Management</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a class="txtseconday" [routerLink]="['/admin/product-listing']"
                                [queryParams]="{tab:1}">Product Listing</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a class="txtseconday" [routerLink]="['/admin/product-listing']"
                                [queryParams]="{tab:3}">Seller Listing</a>
                        </li>
                        <li class="breadcrumb-item active">Edit Seller Listing</li>
                    </ol>
                </nav>
            </div>
            <div class="col-sm-6 col-5 text-right pl-0">
                &nbsp;
            </div>
        </div>
        <!--- /HEAD  --->
        <div class="row d-flex align-items-center mb-20">
            <div class="col-md-6">
                <h3 class="f-700 fs22 txtdefault pb-0"><a href="javascript:;" class="go-back"><i
                            class="fa fa-angle-left"></i></a> Edit Seller Listing</h3>
            </div>
            <div class="col-md-6 text-right">
                <a href="javascript:;" class="btn buybtn fs16 radius10 ripple">Manage Seller</a>
            </div>
        </div>

        <div class="row mb-10">
            <div class="col-md-8 pb-2">
                <div class="row">
                    <div class="col-sm-2 col-3 seller-thumb">
                        <img src="{{ info.logo }}" />
                    </div>
                    <div class="col-sm-10 col-9 pl-0 seller-info">
                        <h4 class="f-600 fs22">{{ info.seller_name }}</h4>
                        <div class="fs12 txtseconday mb-1">Seller</div>
                        <span class="pb-sm-2 pl-3 seller-loc fs12">
                            <svg xmlns="http://www.w3.org/2000/svg" width="9.375" height="11.752"
                                viewBox="0 0 9.375 11.752">
                                <path id="Path_2306" data-name="Path 2306"
                                    d="M25.689,5.029a4.825,4.825,0,0,0-4.154,5.16c.285,3.272,3.293,5.711,4.324,6.457a.562.562,0,0,0,.677-.012c1.071-.836,4.357-3.67,4.357-6.942a4.691,4.691,0,0,0-5.2-4.663Zm.514,6.524a1.861,1.861,0,1,1,1.861-1.861A1.861,1.861,0,0,1,26.2,11.553Z"
                                    transform="translate(-21.517 -5.002)" fill="#5e6a7c" />
                            </svg>{{ info.address }}</span>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                &nbsp;
            </div>
        </div>

        <div class="row mt-30 mb-40">
            <div class="col-md-8">
                <div class="bid-order-info d-flex align-items-center">
                    <div class="order-img">
                        <img src="{{ info.front_image }}" />
                    </div>
                    <div class="pd-seller-cntnt">
                        <h5 class="mb-0 fs18">{{ info.name }}</h5>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="d-flex align-items-center justify-content-end">
                    Listing Status &nbsp;&nbsp;
                    <div class="tglbtn">
                        <label class="switch">
                            <input type="checkbox" [(ngModel)]="listing_status" (change)="updatestatus('listing')">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="add-pro-wrap">
            <ul ngbNav [(activeId)]="sampleactive" #prodtlnav="ngbNav" class="nav-tabs mbltype">
                <li *ngFor="let variant of active_variants; let i = index;" [ngbNavItem]="i+1"
                    (click)="getsellerproductdetails(variant.id)">
                    <a ngbNavLink>{{ variant.variant_name }}</a>
                </li>
            </ul>
        </div>



        <div class="add-pro-wrap">
            <div class="row">
                <!-- RIGHT COL -->
                <div class="col-md-12 ad-p-rgt">
                    <div class="card card-ui p-0">
                        <div class="card-body add-pro-wrap pt-0 p-4">
                            <ul ngbNav #prdctinfonav="ngbNav" [(activeId)]="active" class="nav-tabs">
                                <li [ngbNavItem]="10">
                                    <a ngbNavLink>Unique ID</a>
                                    <ng-template ngbNavContent>
                                        <form [formGroup]="uniqueForm">
                                            <div class="d-flex align-items-center justify-content-end mt-20">
                                                Variant Status &nbsp;&nbsp;
                                                <div class="tglbtn">
                                                    <label class="switch">
                                                        <input type="checkbox" [(ngModel)]="variant_status"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (change)="updatestatus('variant')">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>

                                            <div class="form-container">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="floating-label">
                                                            <input placeholder=" " disabled="" class="form-control"
                                                                value="" formControlName="eb_product_id">
                                                            <span class="highlight"></span>
                                                            <label>Equibiz Product Identification Number (EBPID)</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="floating-label">
                                                            <input placeholder=" " class="form-control" value=""
                                                                formControlName="sku">
                                                            <span class="highlight"></span>
                                                            <label>SKU</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="floating-label">
                                                            <div class="ng-autocomplete">
                                                                <ng-autocomplete [data]="hsns" [searchKeyword]="keyword"
                                                                    (selected)='selectHsnEvent($event)'
                                                                    (inputChanged)='onChangeSearchHsn($event)'
                                                                    (inputFocused)='onFocusedHsn($event)'
                                                                    [itemTemplate]="itemTemplate" formControlName="hsn"
                                                                    placeholder="HSN Number">
                                                                </ng-autocomplete>

                                                                <ng-template #itemTemplate let-item>
                                                                    <a href="javascript:void(0)">
                                                                        <div class="d-flex">
                                                                            <span [innerHTML]="item.name"></span>
                                                                        </div>
                                                                    </a>
                                                                </ng-template>
                                                                <div class="search-icon"><img
                                                                        src="assets/images/svg/search_logo.svg"></div>
                                                            </div>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                        <!-- <div><a href="javascript:;" class="link-alt fs14">Find relevant HSN Code</a></div> -->
                                                    </div>

                                                    <div class="col-md-6"
                                                        *ngIf="info.seller_type == 'registered-brand-owner'">
                                                        <div class="floating-label">
                                                            <input placeholder=" " class="form-control"
                                                                value="{{ info.seller_crs_number }}" disabled>
                                                            <span class="highlight"></span>
                                                            <label>Compulsory Registration Scheme (CRS)</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <button class="btn buybtn fs16 radius10 ripple"
                                                [disabled]="uniqueForm.invalid" (click)="submitUnique()">Next</button>
                                        </form>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="11">
                                    <a ngbNavLink>Product Details</a>
                                    <ng-template ngbNavContent>
                                        <form [formGroup]="productForm">
                                            <div class="d-flex align-items-center justify-content-end mt-20">
                                                Variant Status &nbsp;&nbsp;
                                                <div class="tglbtn">
                                                    <label class="switch">
                                                        <input type="checkbox" [(ngModel)]="variant_status"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (change)="updatestatus('variant')">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>

                                            <div class="form-container">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="floating-label">
                                                            <input placeholder=" " class="form-control"
                                                                value="" formControlName="market_operated_price">
                                                            <span class="highlight"></span>
                                                            <label>Market Operated Price</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                                <span class="help-block"
                                                            *ngIf="prf.market_operated_price.invalid && prf.market_operated_price.touched">Please
                                                            enter valid operating price</span>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="floating-label">
                                                            <select class="form-control" formControlName="country">
                                                                <option selected="selected" value="">Please Select
                                                                </option>
                                                                <option *ngFor="let code of countryArray"
                                                                    [ngValue]="code.value">{{code.name}}</option>
                                                            </select>
                                                            <span class="highlight"></span>
                                                            <label>Country of Origin<span class="req">*</span></label>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="floating-label">
                                                            <select class="form-control"
                                                                formControlName="no_item_perpack">
                                                                <option selected="selected" value="">Please Select
                                                                </option>
                                                                <option *ngFor="let code of packArray"
                                                                    [ngValue]="code.value">{{code.name}}</option>
                                                            </select>
                                                            <span class="highlight"></span>
                                                            <label>Pack of<span class="req">*</span></label>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="floating-label">
                                                            <select class="form-control"
                                                                formControlName="product_status">
                                                                <option selected="selected" value="">Select</option>
                                                                <option *ngFor="let code of product_status_array"
                                                                    [ngValue]="code.value">{{code.label}}</option>
                                                            </select>
                                                            <span class="highlight"></span>
                                                            <label>Product Status<span class="req">*</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <hr class="mt-0 mb-30">

                                            <h4 class="fs16 f-700 mb-4">Product Dimensions</h4>

                                            <div class="form-container">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <div class="floating-label">
                                                                    <input placeholder=" " class="form-control" value=""
                                                                        formControlName="length">
                                                                    <span class="highlight"></span>
                                                                    <label>Length (in cm)</label>
                                                                    <i placement="top"
                                                                        ngbTooltip="Tooltop content goes here"
                                                                        class="infoinput info-alert"></i>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="floating-label">
                                                                    <input placeholder=" " class="form-control" value=""
                                                                        formControlName="width">
                                                                    <span class="highlight"></span>
                                                                    <label>Width (in cm)</label>
                                                                    <i placement="top"
                                                                        ngbTooltip="Tooltop content goes here"
                                                                        class="infoinput info-alert"></i>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="floating-label">
                                                                    <input placeholder=" " class="form-control" value=""
                                                                        formControlName="height">
                                                                    <span class="highlight"></span>
                                                                    <label>Height (in cm)</label>
                                                                    <i placement="top"
                                                                        ngbTooltip="Tooltop content goes here"
                                                                        class="infoinput info-alert"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="floating-label">
                                                            <input placeholder=" " class="form-control"
                                                                value="228 grams" formControlName="deadweight">
                                                            <span class="highlight"></span>
                                                            <label>Dead Weight</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 selectactive">
                                                        <ng-selectize [config]="config" [options]="deatweight_units"
                                                            formControlName="deadweight_unit" placeholder="Select">
                                                        </ng-selectize>
                                                    </div>
                                                </div>
                                            </div>

                                            <hr class="mt-0 mb-30">

                                            <h4 class="fs16 f-700 mb-4">Warranty</h4>
                                            <div class="custom-control custom-checkbox mb-0">
                                                <input type="checkbox" class="custom-control-input"
                                                    id="agrcustomCheckBox" name="checkbox1"
                                                    formControlName="warranty_by_brand"
                                                    (change)="isWarrantyChange($event)">
                                                <label class="custom-control-label" for="agrcustomCheckBox">
                                                    Warranty facilitated by Brand
                                                </label>
                                            </div>
                                            <!-- FORM BLOCK REPEAT -->
                                            <div class="form-container"
                                                *ngIf="!productForm.get('warranty_by_brand').value">
                                                <div class="row">

                                                    <div class="col-sm-8 mb-4 col-12 col-xl-9">
                                                        <div class="floating-label  mb-0">
                                                            <input class="form-control pr-5" placeholder=" "
                                                                formControlName="domestic_warranty">
                                                            <span class="highlight"></span>
                                                            <label>Domestic Warranty</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4 mb-4 col-12 pl-sm-0 col-xl-3 selectactive">
                                                        <ng-selectize [config]="config" [options]="units"
                                                            formControlName="domestic_warranty_unit"
                                                            placeholder="Select"> </ng-selectize>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-8 mb-4 col-12 col-xl-9">
                                                        <div class="floating-label mb-0">
                                                            <input class="form-control pr-5" placeholder=" "
                                                                formControlName="international_warranty">
                                                            <span class="highlight"></span>
                                                            <label>International Warranty</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4 mb-4 col-12 pl-md-0 col-xl-3 selectactive">
                                                        <ng-selectize [config]="config"
                                                            formControlName="international_warranty_unit"
                                                            [options]="units" placeholder="Select"> </ng-selectize>
                                                    </div>
                                                </div>
                                                <div class="floating-label">
                                                    <input class="form-control pr-5" placeholder=" "
                                                        formControlName="warranty_summary">
                                                    <span class="highlight"></span>
                                                    <label>Warranty Summary</label>
                                                    <i placement="top" ngbTooltip="Tooltop content goes here"
                                                        class="infoinput info-alert"></i>
                                                </div>
                                                <div class="floating-label">
                                                    <input class="form-control pr-5" placeholder=" "
                                                        formControlName="warranty_server_type">
                                                    <span class="highlight"></span>
                                                    <label>Warranty Service Type</label>
                                                    <i placement="top" ngbTooltip="Tooltop content goes here"
                                                        class="infoinput info-alert"></i>
                                                </div>
                                            </div>
                                            <!-- /FORM BLOCK REPEAT -->

                                            <div class="mt-40">
                                                <button type="button" class="btn px-4 buybtn fs16 radius10"
                                                    [disabled]="productForm.invalid" (click)="submitProduct()">Save and
                                                    Next</button>
                                            </div>
                                        </form>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="12">
                                    <a ngbNavLink>Marketplace</a>
                                    <ng-template ngbNavContent class="slrtypeui">
                                        <form [formGroup]="marketplaceForm">
                                            <div class="d-flex align-items-center justify-content-end mt-20">
                                                Variant Status &nbsp;&nbsp;
                                                <div class="tglbtn">
                                                    <label class="switch">
                                                        <input type="checkbox" [(ngModel)]="variant_status"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (change)="updatestatus('variant')">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="form-container form-ipt-info lstnobd">
                                                <div class="mb-2" *ngIf="info.admin_wholesale">
                                                    <div class="css-check mb-2">
                                                        <input name="wholesale" class="css-checkbx" type="checkbox"
                                                            formControlName="wholesale" />
                                                        <label class="css-label txtdefault wt700">Wholesale
                                                            <p class="wt500">Lorem ipsum dollar sit amet, consuecatur
                                                                elit, sed do elumsod tempor inodient ut</p>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="mb-2" *ngIf="info.admin_bulk">
                                                    <div class="css-check mb-3">
                                                        <input name="bulk" class="css-checkbx" type="checkbox"
                                                            formControlName="bulk" (change)="changebulkmarketplace()" />
                                                        <label class="css-label txtdefault wt700">Bulk
                                                            <p class="wt500">Lorem ipsum dollar sit amet, consuecatur
                                                                elit, sed do elumsod tempor inodient ut</p>
                                                        </label>
                                                    </div>
                                                    <div class="css-check ml-4" *ngIf="info.admin_bid">
                                                        <input name="bid_offer" class="css-checkbx" type="checkbox"
                                                            formControlName="bid_offer" />
                                                        <label class="css-label txtdefault wt700">Bid & Offers
                                                            <p class="wt500">Lorem ipsum dollar sit amet, consuecatur
                                                                elit, sed do elumsod tempor inodient ut</p>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-20">
                                                <button type="button" class="btn px-4 buybtn fs16 radius10"
                                                    [disabled]="marketplaceForm.invalid"
                                                    (click)="submitMarketplace()">Save and Next</button>
                                            </div>
                                        </form>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="13">
                                    <a ngbNavLink>Stock & Pricing</a>
                                    <ng-template ngbNavContent>
                                        <form [formGroup]="pricingForm">
                                            <div class="d-flex align-items-center justify-content-end mt-20">
                                                Variant Status &nbsp;&nbsp;
                                                <div class="tglbtn">
                                                    <label class="switch">
                                                        <input type="checkbox" [(ngModel)]="variant_status"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (change)="updatestatus('variant')">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>

                                            <h4 class="fs16 f-700 mb-4" *ngIf="marketplaceForm.get('wholesale').value">
                                                Wholesale</h4>

                                            <!-- FORM BLOCK REPEAT -->
                                            <div class="form-container" *ngIf="marketplaceForm.get('wholesale').value">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="floating-label">
                                                            <input class="form-control pr-5" placeholder=" "
                                                                formControlName="wholesale_price"
                                                                (change)="netrealizationvalue('wholesale')">
                                                            <span class="highlight"></span>
                                                            <label>Listed Price</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                        <div class="alert alert-warning p-2"  *ngIf="wholesale_price_warning">
                                                            <div class="fs13"><b>Warning:</b>  {{ wholesale_price_warning_msg }}</div>
                                                        </div>

                                                        <div class="alert alert-danger p-2"  *ngIf="wholesale_mrp_price_error_msg">
                                                            <div class="fs13"><b>Error:</b>  {{ wholesale_mrp_price_error_msg }}</div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="floating-label">
                                                                    <input class="form-control pr-5" placeholder=" "
                                                                        formControlName="wholesale_qty">
                                                                    <span class="highlight"></span>
                                                                    <label>Total Stock Quantity</label>
                                                                    <i placement="top"
                                                                        ngbTooltip="Tooltop content goes here"
                                                                        class="infoinput info-alert"></i>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="floating-label">
                                                                    <input class="form-control pr-5" placeholder=" "
                                                                        formControlName="wholesale_min_qty"
                                                                        (change)="netrealizationvalue('wholesale')">
                                                                    <span class="highlight"></span>
                                                                    <label>Minimum Order Quantity</label>
                                                                    <i placement="top"
                                                                        ngbTooltip="Tooltop content goes here"
                                                                        class="infoinput info-alert"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6"
                                                        *ngIf="wholesale_realization_details.net_realization != ''">
                                                        <div class="floating-label">
                                                            <span class="highlight"></span>
                                                            <label>Net Realization Value: <b>{{
                                                                    wholesale_realization_details.net_realization
                                                                    }}</b></label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"
                                                                (click)="openbreakup(contentbreakup, 'wholesale')"></i>
                                                        </div>
                                                        <div class="mr-2"><img src="assets/images/icons/info.png"></div>
                                                        <div>This net realization value is based on an MOQ order. The
                                                            charges may increase for an order with quantity more than
                                                            the MOQ.</div>
                                                    </div>

                                                </div>
                                            </div>

                                            <hr class="mt-0 mb-30" *ngIf="marketplaceForm.get('wholesale').value">

                                            <h4 class="fs16 f-700 mb-4" *ngIf="marketplaceForm.get('bulk').value">Bulk
                                            </h4>

                                            <!-- FORM BLOCK REPEAT -->
                                            <div class="form-container" *ngIf="marketplaceForm.get('bulk').value">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="floating-label">
                                                            <input class="form-control pr-5" placeholder=" "
                                                                formControlName="bulk_price"
                                                                (change)="netrealizationvalue('bulk')">
                                                            <span class="highlight"></span>
                                                            <label>Listed Price</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                        <div class="alert alert-warning p-2" *ngIf="bulk_price_warning">
                                                            <div class="fs13"><b>Warning:</b> {{ bulk_price_warning_msg }}</div>
                                                        </div>
                                                        <div class="alert alert-danger p-2"  *ngIf="bulk_mrp_price_error_msg">
                                                            <div class="fs13"><b>Error:</b>  {{ bulk_mrp_price_error_msg }}</div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="floating-label">
                                                                    <input class="form-control pr-5" placeholder=" "
                                                                        formControlName="bulk_qty">
                                                                    <span class="highlight"></span>
                                                                    <label>Total Stock Quantity</label>
                                                                    <i placement="top"
                                                                        ngbTooltip="Tooltop content goes here"
                                                                        class="infoinput info-alert"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6"
                                                        *ngIf="marketplaceForm.get('bid_offer').value">
                                                        <div class="floating-label">
                                                            <input class="form-control pr-5" placeholder=" "
                                                                formControlName="lowest_bid_amt">
                                                            <span class="highlight"></span>
                                                            <label>Lowest Bid Price (Bids & Offers)</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3"
                                                        *ngIf="marketplaceForm.get('bulk').value">
                                                        <div class="floating-label">
                                                            <input class="form-control pr-5" placeholder=" "
                                                                formControlName="bid_upper_moq">
                                                            <span class="highlight"></span>
                                                            <label>Bid Upper MOQ</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3"
                                                        *ngIf="marketplaceForm.get('bulk').value">
                                                        <div class="floating-label">
                                                            <input class="form-control pr-5" placeholder=" "
                                                                formControlName="bid_lower_moq"
                                                                (change)="netrealizationvalue('bulk')">
                                                            <span class="highlight"></span>
                                                            <label>Bid Lower MOQ</label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"></i>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6"
                                                        *ngIf="bulk_realization_details.net_realization != ''">
                                                        <div class="floating-label">
                                                            <span class="highlight"></span>
                                                            <label>Net Realization Value: <b>{{
                                                                    bulk_realization_details.net_realization
                                                                    }}</b></label>
                                                            <i placement="top" ngbTooltip="Tooltop content goes here"
                                                                class="infoinput info-alert"
                                                                (click)="openbreakup(contentbreakup, 'bulk')"></i>
                                                        </div>
                                                        <div class="mr-2"><img src="assets/images/icons/info.png"></div>
                                                        <div>This net realization value is based on an MOQ order. The
                                                            charges may increase for an order with quantity more than
                                                            the MOQ.</div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="mt-20">
                                                <button type="button" class="btn px-4 buybtn fs16 radius10"
                                                    [disabled]="pricingForm.invalid || bulk_mrp_price_error_msg || wholesale_mrp_price_error_msg" (click)="submitPricing()">Save and
                                                    Next</button>
                                            </div>
                                        </form>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="14">
                                    <a ngbNavLink>Payment & Shipment</a>
                                    <ng-template ngbNavContent>
                                        <form [formGroup]="paymentForm">
                                            <div class="d-flex align-items-center justify-content-end mt-20">
                                                Variant Status &nbsp;&nbsp;
                                                <div class="tglbtn">
                                                    <label class="switch">
                                                        <input type="checkbox" [(ngModel)]="variant_status"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (change)="updatestatus('variant')">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>

                                            <h4 class="fs16 f-700 mb-4">Product Location</h4>
                                            <div class="floating-label mb-10 qs-chk-rp">
                                                <div class="row">
                                                    <div class="col-md-12"
                                                        *ngFor="let addr of seller_address; let k = index;">
                                                        <div class="cssinptchk">
                                                            <span class="css-check">
                                                                <input class="css-checkbx" name="product_location"
                                                                    formControlName="product_location" value="{{k}}"
                                                                    type="radio" />
                                                                <label class="css-label"></label>
                                                            </span>
                                                            <p>
                                                                <b>{{ addr.address }}, {{ addr.city }}, {{ addr.state }}
                                                                    {{ addr.pincode }}</b>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <h4 class="fs16 f-700 mb-4">Payment Type (Bulk)</h4>
                                            <div class="floating-label mb-10 qs-chk-rp"
                                                *ngIf="marketplaceForm.get('bulk').value">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="cssinptchk">
                                                            <span class="css-check">
                                                                <input class="css-checkbx" name="payment_type"
                                                                    formControlName="payment_type" value="full_payment"
                                                                    type="radio" />
                                                                <label class="css-label"></label>
                                                            </span>
                                                            <p>
                                                                <b>Full advance payment</b><br />
                                                                <span class="fs12">Lorem ipsum dolor sit amet,
                                                                    consectetur adipiscing elit, sed do eiusmod tempor
                                                                    incididunt ut</span>
                                                            </p>

                                                        </div>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <div class="cssinptchk">
                                                            <span class="css-check">
                                                                <input class="css-checkbx" name="payment_type"
                                                                    formControlName="payment_type" value="token_payment"
                                                                    type="radio" />
                                                                <label class="css-label"></label>
                                                            </span>
                                                            <p>
                                                                <b>Token & Final payment before dispatch</b>
                                                                &nbsp;&nbsp;<span class="pd-taglist"><span
                                                                        class="f-600 fs10 radius15">Equibiz
                                                                        Suggested</span></span> <br />
                                                                <span class="fs12">Lorem ipsum dolor sit amet,
                                                                    consectetur adipiscing elit, sed do eiusmod tempor
                                                                    incididunt ut</span>
                                                            </p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center"
                                                *ngIf="marketplaceForm.get('wholesale').value">
                                                <div class="mr-2"><img src="assets/images/icons/info.png"></div>
                                                <div>For wholesale - full payment option by default</div>
                                            </div>

                                            <hr class="mt-30 mb-30">

                                            <h4 class="fs16 f-700 mb-4">Delivery Date</h4>

                                            <div class="floating-label mb-10 qs-chk-rp">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="cssinptchk">
                                                            <span class="css-check">
                                                                <input class="css-checkbx" name="delivery_date"
                                                                    formControlName="delivery_date" value="1"
                                                                    type="radio" />
                                                                <label class="css-label"></label>
                                                            </span>
                                                            <p>Ready to Ship</p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <div class="cssinptchk">
                                                            <span class="css-check">
                                                                <input class="css-checkbx" name="delivery_date"
                                                                    formControlName="delivery_date" value="2"
                                                                    type="radio" />
                                                                <label class="css-label"></label>
                                                            </span>
                                                            <p>Dispatch in 24 hours</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="cssinptchk">
                                                            <span class="css-check">
                                                                <input class="css-checkbx" name="delivery_date"
                                                                    formControlName="delivery_date" value="3"
                                                                    type="radio" />
                                                                <label class="css-label"></label>
                                                            </span>
                                                            <p>Dispatch in 48 hours</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="cssinptchk">
                                                            <span class="css-check">
                                                                <input class="css-checkbx" name="delivery_date"
                                                                    formControlName="delivery_date" value="4"
                                                                    type="radio" />
                                                                <label class="css-label"></label>
                                                            </span>
                                                            <p>Dispatch after 2 days</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-20">
                                                <button type="button" class="btn px-4 buybtn fs16 radius10"
                                                    [disabled]="paymentForm.invalid" (click)="submitPayment()">Save
                                                    Changes</button>
                                            </div>
                                        </form>
                                    </ng-template>
                                </li>
                            </ul>

                            <div [ngbNavOutlet]="prdctinfonav" class="mt-2"></div>
                        </div>
                    </div>
                </div>
                <!-- /RIGHT COL -->
            </div>
        </div>


        <div [ngbNavOutlet]="prodtlnav" class="mt-2"></div>
    </div>
</div>

<!-- Order Total -->
<ng-template #contentbreakup let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>

    <div class="modal-body pb-3">
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead text-center">
                    <h4 class="txtdefault wt700 fs22 mb-20">Net Realization Value</h4>
                </div>

                <div class="inrcard p-3 radius10 position-relative">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <input type="radio" value="inside" name="netrealization_type" [(ngModel)]="netrealization_type">&nbsp;Inside City&nbsp;&nbsp;
                                <input type="radio" value="outside" name="netrealization_type" [(ngModel)]="netrealization_type">&nbsp;Outside City
                            </div>
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6 py-1">Order Quantity:</div>
                                <div class="col-md-6 py-1 text-right">{{ display_details.quantity }}</div>
                            </div>
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6 py-1">Price Per Unit: &nbsp;<i placement="top"
                                        ngbTooltip="Inclusive of GST"><img src="/assets/images/icons/info.png"></i>
                                </div>
                                <div class="col-md-6 py-1 text-right">{{ display_details.listing_price }}</div>
                            </div>
                            <hr>
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6 py-1">Net Goods Value:</div>
                                <div class="col-md-6 py-1 text-right">{{ display_details.without_gst }}</div>
                            </div>
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6 py-1">GST:</div>
                                <div class="col-md-6 py-1 text-right">{{ display_details.gst_amount }}</div>
                            </div>

                            <div class="row d-flex justify-content-between" *ngIf="display_details.other_charges.length > 0 && netrealization_type == 'inside'">
                                <div class="col-6 pr-0 " ngbDropdown>
                                    <p class="fs14 txtactive" id="dropdownBasic1" ngbDropdownToggle>Other Charges
                                    </p>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                        <button ngbDropdownItem
                                            *ngFor="let other of display_details.other_charges; let i = index;">{{ other
                                            }} </button>
                                    </div>
                                </div>
                                <div class="col-md-6 py-1 text-right">{{ display_details.other_charges_amount }}</div>
                            </div>

                            <div class="row d-flex justify-content-between" *ngIf="display_details.outside_other_charges.length > 0 && netrealization_type == 'outside'">
                                <div class="col-6 pr-0 " ngbDropdown>
                                    <p class="fs14 txtactive" id="dropdownBasic1" ngbDropdownToggle>Other Charges
                                    </p>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                        <button ngbDropdownItem
                                            *ngFor="let other of display_details.outside_other_charges; let i = index;">{{ other
                                            }} </button>
                                    </div>
                                </div>
                                <div class="col-md-6 py-1 text-right">{{ display_details.outside_other_charges_amount }}</div>
                            </div>
                            <hr>
                            <div class="row d-flex justify-content-between" *ngIf="netrealization_type == 'inside'">
                                <div class="col-md-6 py-1 f-700">Net Realization Value:</div>
                                <div class="col-md-6 py-1 text-right f-700">{{ display_details.net_realization }}</div>
                            </div>
                            <div class="row d-flex justify-content-between" *ngIf="netrealization_type == 'outside'">
                                <div class="col-md-6 py-1 f-700">Net Realization Value:</div>
                                <div class="col-md-6 py-1 text-right f-700">{{ display_details.outside_net_realization }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- /UPLOAD INVOICE -->