import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input,
  Injectable, PLATFORM_ID, Inject } from '@angular/core';
  import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PreviewService } from './preview.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

    @ViewChild("preview", { static: false }) Preview: TemplateRef<any>;
    public closeResult: string;
    public modalOpen: boolean = false;
    public variant_id:string;
    public unique_id:string;
    public selected_variants:any = [];

    public technical_feature:any = [];
    public chunked:any = [];
    

    public record:any = {};
    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private modalService: NgbModal,
        private _previewservice: PreviewService,
        private _toastr: ToasterService
    ) { }

  ngOnInit(): void {
  }

    getselectedvariant(variant, meta){
        for(let i = 0; i< meta.meta.length; i++){
            if(variant.slug == meta.meta[i].slug){
                meta.meta[i].selected = true;
            }else{
                meta.meta[i].selected = false;
            }
        }
        this.selected_variants = [];
        for(let i = 0; i < this.record.product_variant.length; i++){
            var selected_variant = this.record.product_variant[i].meta.filter((product) => product.selected == true);
            if(selected_variant){
                this.selected_variants.push(selected_variant[0].slug);
            }
        }
        this.openPreviewModal(this.unique_id, this.variant_id, this.selected_variants, variant);
    }

    openPreviewModal(unique_id, variant_id, searchFilters = null, selectedSlug = null, template_type = null) {
        this.unique_id = unique_id;
        this.variant_id= variant_id;
        this._previewservice.getdetail({unique_id: this.unique_id, variant_id: this.variant_id, filters:searchFilters, selectedSlug, template_type: template_type}).subscribe((data) => {
            if(data['status'] == "success"){
                this.record = data['record'];
                this.chunked = [];
                let size = 2;
                for (let i = 0;  i < this.record.technical_feature.length; i += size) {
                    this.chunked.push(this.record.technical_feature.slice(i, i + size))
                }
                this.slides = [];
                if(this.record.front_image){
                    this.slides.push({'img': this.record.front_image});
                }
                if(this.record.back_image){
                    this.slides.push({'img': this.record.back_image});
                }
                if(this.record.top_image){
                    this.slides.push({'img': this.record.top_image});
                }
                if(this.record.side_image){
                    this.slides.push({'img': this.record.side_image});
                }
                if(this.record.sales_image){
                    this.slides.push({'img': this.record.sales_image});
                }
                this.modalService.dismissAll();
                this.modalOpen = true;
                if (isPlatformBrowser(this.platformId)) { // For SSR 
                    this.modalService.open(this.Preview, { windowClass: 'p-preview-modal', backdropClass: 'hidebackdrop' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
                }
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
    } else {
        return `with: ${reason}`;
    }
}

ngOnDestroy() {
    if(this.modalOpen){
         this.modalService.dismissAll();
    }
}

imagesSlider = {
  slidesToShow: 1,
   slidesToScroll: 1,
   arrows: false,
   fade: true,
   asNavFor:".thumbs",
 };
 thumbnailsSlider = {
   slidesToShow: 4,
   slidesToScroll: 1,
   dots: true,
   focusOnSelect: true,
   asNavFor:".feedback",
   //prevArrow:'.client-thumbnails .prev-arrow',
  // nextArrow:'.client-thumbnails .next-arrow',
 };

 slides = [];


}
