import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class ManageCandfService {

    constructor(private _http: HttpClient) { }

    getcandfrequest(data) {
        return this._http.post(environment.api_url + 'admin/candf/supersellerrequestlist', data);
    }

    getcandfsellers(data) {
        return this._http.post(environment.api_url + 'admin/candf/supersellerapprovelist', data);
    }

}
