<app-header-seller></app-header-seller>
<app-sidebar-seller></app-sidebar-seller>
<div class="wrpmain pb-4">
    <div class="container">
        <!--- HEAD  --->
        <div class="row align-items-center pinfo-head">
            <div class="col-12 col-md-5 mb-4">
                <h3 class="fs24 wt700 txtdefault">My Product</h3>
                <nav class="breadcrumb breadcrumb-dui mb-0">
                    <ol class="breadcrumb p-0 bg-transparent  mb-0">
                        <li class="breadcrumb-item active">My Product</li>
                    </ol>
                </nav>
            </div>
            <div class="col-12 col-md-7  mb-4 text-md-right text-center md-block-flex-justify">
                <a [routerLink]="['/seller/addlisting']" class="mr-sm-4 mr-2 btn-d-outline d-inline-block">
                    <svg
                        xmlns="http://www.w3.org/2000/svg" width="14.681" height="14.681" viewBox="0 0 14.681 14.681"
                        class="plus">
                        <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1 1)">
                            <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181" transform="translate(-11.659 -7.5)"
                                fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="2" />
                            <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181" transform="translate(-7.5 -11.659)"
                                fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="2" />
                        </g>
                    </svg>
                    Add New Product
                </a>
                <a href="javascript:void(0);" [routerLink]="['/seller/assistance']" class="btn buybtn fs16 radius10 ripple">Assistance</a>
            </div>
        </div>

        <div class="us-tab">
            <nav ngbNav #nav="ngbNav" class="nav-tabs">
                <ng-container ngbNavItem>
                    <a ngbNavLink>My Products</a>
                    <ng-template ngbNavContent>
                        <div class="card cardui carduipro p-4 mb-0">
                            <div class="notifications-container theme-tab">
                                <ngb-tabset [justify]="'left'" [type]="'pills'" class="tabs tab-title">
                                    <ngb-tab>
                                        <ng-template ngbTabTitle>My Listing</ng-template>
                                        <ng-template ngbTabContent>
                                            <div class="tab-content">
                                                <!--Filters-->
                                                <div class="row d-flex justify-content-between mt-20 report-cta">
                                                    <div class="col-lg-5 mb-3">
                                                        <div class="report-input">
                                                            <div class="input-group-custom">
                                                                <input type="text"
                                                                    placeholder="Search by a Product Name, Product ID or Cate…"
                                                                    class="form-control" [(ngModel)]="search_term" (change)="getproductlisting()">
                                                                <div class="input-group-append-custom">
                                                                    <img src="assets/images/svg/search_logo.svg">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-5 mb-3">
                                                        <div class="report-input">
                                                            <div class="input-group-custom">
                                                                <select name="status" class="form-control" [(ngModel)]="product_status" (change)="getproductlisting()">
                                                                    <option value="">All Status</option>
                                                                    <option value="active">Active</option>
                                                                    <option value="inactive">Inactive</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- <div class="col-lg-7">
                                                        <div
                                                            class="d-flex clbxgrd justify-content-end lg-justify-content-start">
                                                            <div class="mr-3">
                                                                <a href="javascript:void(0);"
                                                                    class="d-inline-block fltr-round-btn radius10 txtseconday">
                                                                    <img src="assets/images/svg/filter.svg">
                                                                    Filters
                                                                </a>
                                                                <div style="display:none" class="filter-show-div">
                                                                    <div class="row no-gutters">
                                                                        <div class="col-md-3">
                                                                            <div class="left-filter">
                                                                                <ul>
                                                                                    <li class="active"><a
                                                                                            href="javascript:;">Category</a>
                                                                                    </li>
                                                                                    <li><a href="javascript:;">Listing
                                                                                            Available</a></li>
                                                                                    <li><a href="javascript:;">Brand</a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-9 right-contcc">
                                                                            <div class="right-filter lsting">
                                                                                <ul class="list-group">
                                                                                    <li class="list-group-item">
                                                                                        <div class="css-check">
                                                                                            <input class="css-checkbx"
                                                                                                type="checkbox" />
                                                                                            <label
                                                                                                class="css-label">Smart
                                                                                                Phones (45)</label>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li class="list-group-item">
                                                                                        <div class="css-check">
                                                                                            <input class="css-checkbx"
                                                                                                type="checkbox" />
                                                                                            <label
                                                                                                class="css-label">Feature
                                                                                                Phones (45)</label>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>

                                                                            <div class="footer-cta">
                                                                                <div class="mr-3">
                                                                                    <a href="javascript:;"><svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            width="10.239"
                                                                                            height="10.245"
                                                                                            viewBox="0 0 10.239 10.245">
                                                                                            <path
                                                                                                id="Icon_material-refresh"
                                                                                                data-name="Icon material-refresh"
                                                                                                d="M14.749,7.5a5.123,5.123,0,1,0,1.332,4.9H14.749a3.84,3.84,0,1,1-3.618-5.123,3.787,3.787,0,0,1,2.7,1.14l-2.062,2.062h4.482V6Z"
                                                                                                transform="translate(-6.015 -6)"
                                                                                                fill="#ff7135" />
                                                                                        </svg> &nbsp;&nbsp;<span
                                                                                            class="link-alt fs14">Reset
                                                                                            Filters</span></a>
                                                                                </div>
                                                                                <div><a href=""
                                                                                        class="btn buybtn fs16 radius10 ripple">Apply</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div class="form-container mt-0 mb-0 mr-3">
                                                                <div class="report-input floating-label">
                                                                    <div class="input-group-custom">
                                                                        <input type="text" value="Last 30 Days"
                                                                            class="form-control" placeholder=" " />
                                                                        <span class="highlight"></span>
                                                                        <label>Date</label>
                                                                        <div class="input-group-append-custom">
                                                                            <img
                                                                                src="assets/images/icons/filter-calendar.svg">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <a href="javascript:void(0);"
                                                                    class="btn-d-outline d-inline-block">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="12.681" height="17.061"
                                                                        viewBox="0 0 12.681 17.061">
                                                                        <g id="np_export_2535025_000000"
                                                                            transform="translate(-32.121 -25.962)">
                                                                            <path id="Path_2355" data-name="Path 2355"
                                                                                d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z"
                                                                                transform="translate(-0.003)"
                                                                                fill="#ff7135" />
                                                                            <path id="Path_2356" data-name="Path 2356"
                                                                                d="M44.093,70.07H32.83a.709.709,0,1,0,0,1.419H44.093a.709.709,0,0,0,0-1.419Z"
                                                                                transform="translate(0 -28.466)"
                                                                                fill="#ff7135" />
                                                                        </g>
                                                                    </svg>
                                                                    Export
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <!--End Filters-->
                                                <div class="table-responsive brndmgnttable">
                                                    <table class="table custom-table xs">
                                                        <thead>
                                                            <tr>
                                                                <th>Product ID</th>
                                                                <th width="18%">Product Name</th>
                                                                <th width="10%">Category</th>
                                                                <th>Listed On</th>
                                                                <th>Total Stock Quantity</th>
                                                                <th width="15%">Listing Available</th>
                                                                <th>Status</th>
                                                                <th width="18%">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <ng-container *ngIf="records.length > 0">
                                                                <tr *ngFor="let record of records; let i = index;">
                                                                    <td>{{ record.product_code }}</td>
                                                                    <td>
                                                                        <div class="order-info">
                                                                            <div class="order-img">
                                                                                <img src="{{ record.image }}" />
                                                                            </div>
                                                                            <div class="pd-seller-cntnt">
                                                                                <div class="txtdefault">
                                                                                    <a href="javascript:;"
                                                                                        class="order-link">{{
                                                                                        record.name }}</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>{{ record.category_name }}</td>
                                                                    <td>{{ record.listed_on }}</td>
                                                                    <td>{{ record.total_qty }}</td>
                                                                    <td [innerHTML]="record.marketplace"></td>
                                                                    <td>
                                                                        <div class="tglbtn no-evt">
                                                                            <label class="switch">
                                                                                <input type="checkbox"
                                                                                    [(ngModel)]="record.status"  (change)="updatestatus('listing', record)">
                                                                                <span class="slider round"></span>
                                                                                <i>{{
                                                                                    record.status?'Active':'Inactive'}}</i>
                                                                            </label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="d-flex align-items-center">
                                                                        <a href="/seller/viewlisting/{{record.uniqueId}}"
                                                                            class="btn-d-outline ripple d-inline-block place-bid mr-3">View
                                                                            Listing</a>
                                                                        <a href="javascript:voi(0);"
                                                                            class="add-whislist-link"><span>Past
                                                                                Orders</span></a>
                                                                    </td>
                                                                </tr>
                                                            </ng-container>

                                                            <!--Empty State-->
                                                            <tr *ngIf="!records.length">
                                                                <td colspan="8">
                                                                    <div class="row pt-40 pb-40">
                                                                        <div class="col-md-12 text-center">
                                                                            <div class="mb-20"><img
                                                                                    src="/assets/images/empty-state.png">
                                                                            </div>
                                                                            <div class="fs16">There are no records to
                                                                                display!</div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <!--End Empty State-->

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="row mb-40" *ngIf="records.length > 0">
                                                <div class="col-md-6">
                                                    <div class="text-left pl-3">
                                                        <nav class="mt-3 tbpg rows-per-page">
                                                            <div>Rows per page:</div>
                                                            <select class="select-box" [(ngModel)]="page_per_view"
                                                                (change)="setpageperview()">
                                                                <option *ngFor="let option of page_per_view_options"
                                                                    [ngValue]="option.value">{{option.name}}</option>
                                                            </select>
                                                        </nav>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="product_listing_pagination.pages && product_listing_pagination.pages.length">
                                                    <div class="text-right md-text-left pr-3">
                                                        <nav class="mt-3 tbpg">
                                                            <ul class="pagination align-items-center">
                                                                <li class="page-item"
                                                                    [ngClass]="{disabled:product_listing_pagination.currentPage === 1}">
                                                                    <a class="page-link previcon" href="javascript:;"
                                                                        (click)="setpage(1)"></a>
                                                                </li>

                                                                <li class="page-item"
                                                                    [ngClass]="{disabled:product_listing_pagination.currentPage === 1}">
                                                                    <a class="page-link sprevicon" href="javascript:;"
                                                                        (click)="setpage(product_listing_pagination.currentPage - 1)"></a>
                                                                </li>

                                                                <li class="page-item" i
                                                                    *ngFor="let page of product_listing_pagination.pages"
                                                                    [ngClass]="{active:product_listing_pagination.currentPage === page}">
                                                                    <a class="page-link" href="javascript:;"
                                                                        (click)="setpage(page)">{{page}}</a>
                                                                </li>

                                                                <li
                                                                    *ngIf="(product_listing_pagination.totalPages - product_listing_pagination.startPage) > 5 ">
                                                                    <a
                                                                        (click)="setpage(product_listing_pagination.startPage + 5)">...</a>
                                                                </li>
                                                                <li
                                                                    *ngIf="(product_listing_pagination.totalPages - product_listing_pagination.startPage) > 5">
                                                                    <a
                                                                        (click)="setpage(product_listing_pagination.totalPages)">{{product_listing_pagination.totalPages}}</a>
                                                                </li>

                                                                <li class="page-item"
                                                                    [ngClass]="{disabled:product_listing_pagination.currentPage === product_listing_pagination.totalPages}">
                                                                    <a class="page-link snexticon" href="javascript:;"
                                                                        (click)="setpage(product_listing_pagination.currentPage + 1)"></a>
                                                                </li>
                                                                <li class="page-item"
                                                                    [ngClass]="{disabled:product_listing_pagination.currentPage === product_listing_pagination.totalPages}">
                                                                    <a class="page-link nexticon" href="javascript:;"
                                                                        (click)="setpage(product_listing_pagination.totalPages)"></a>
                                                                </li>
                                                            </ul>
                                                        </nav>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-tab>
                                    <ngb-tab>
                                        <ng-template ngbTabTitle>Requested Listing</ng-template>
                                        <ng-template ngbTabContent>
                                            <div class="tab-content">
                                                <!--Filters-->
                                                <div class="row d-flex justify-content-between mt-20 report-cta">
                                                    <div class="col-lg-5 mb-3">
                                                        <div class="report-input">
                                                            <div class="input-group-custom">
                                                                <input type="text"
                                                                    placeholder="Search by a Product Name, Product ID or Cate…"
                                                                    class="form-control" [(ngModel)]="rsearch_term" (change)="requestedlisting()">
                                                                <div class="input-group-append-custom">
                                                                    <img src="assets/images/svg/search_logo.svg">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- <div class="col-lg-7">
                                                        <div
                                                            class="d-flex clbxgrd justify-content-end lg-justify-content-start">
                                                            <div class="mr-3">
                                                                <a href="javascript:void(0);"
                                                                    class="d-inline-block fltr-round-btn radius10 txtseconday">
                                                                    <img src="assets/images/svg/filter.svg">
                                                                    Filters
                                                                </a>
                                                                <div style="display:none" class="filter-show-div">
                                                                    <div class="row no-gutters">
                                                                        <div class="col-md-3">
                                                                            <div class="left-filter">
                                                                                <ul>
                                                                                    <li class="active"><a
                                                                                            href="javascript:;">Category</a>
                                                                                    </li>
                                                                                    <li><a href="javascript:;">Listing
                                                                                            Available</a></li>
                                                                                    <li><a href="javascript:;">Brand</a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-9 right-contcc">
                                                                            <div class="right-filter lsting">
                                                                                <ul class="list-group">
                                                                                    <li class="list-group-item">
                                                                                        <div class="css-check">
                                                                                            <input class="css-checkbx"
                                                                                                type="checkbox" />
                                                                                            <label
                                                                                                class="css-label">Smart
                                                                                                Phones (45)</label>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li class="list-group-item">
                                                                                        <div class="css-check">
                                                                                            <input class="css-checkbx"
                                                                                                type="checkbox" />
                                                                                            <label
                                                                                                class="css-label">Feature
                                                                                                Phones (45)</label>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>

                                                                            <div class="footer-cta">
                                                                                <div class="mr-3">
                                                                                    <a href="javascript:;"><svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            width="10.239"
                                                                                            height="10.245"
                                                                                            viewBox="0 0 10.239 10.245">
                                                                                            <path
                                                                                                id="Icon_material-refresh"
                                                                                                data-name="Icon material-refresh"
                                                                                                d="M14.749,7.5a5.123,5.123,0,1,0,1.332,4.9H14.749a3.84,3.84,0,1,1-3.618-5.123,3.787,3.787,0,0,1,2.7,1.14l-2.062,2.062h4.482V6Z"
                                                                                                transform="translate(-6.015 -6)"
                                                                                                fill="#ff7135" />
                                                                                        </svg> &nbsp;&nbsp;<span
                                                                                            class="link-alt fs14">Reset
                                                                                            Filters</span></a>
                                                                                </div>
                                                                                <div><a href=""
                                                                                        class="btn buybtn fs16 radius10 ripple">Apply</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div class="form-container mt-0 mb-0 mr-3">
                                                                <div class="report-input floating-label">
                                                                    <div class="input-group-custom">
                                                                        <input type="text" value="Last 30 Days"
                                                                            class="form-control" placeholder=" " />
                                                                        <span class="highlight"></span>
                                                                        <label>Date</label>
                                                                        <div class="input-group-append-custom">
                                                                            <img
                                                                                src="assets/images/icons/filter-calendar.svg">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <a href="javascript:void(0);"
                                                                    class="btn-d-outline d-inline-block">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="12.681" height="17.061"
                                                                        viewBox="0 0 12.681 17.061">
                                                                        <g id="np_export_2535025_000000"
                                                                            transform="translate(-32.121 -25.962)">
                                                                            <path id="Path_2355" data-name="Path 2355"
                                                                                d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z"
                                                                                transform="translate(-0.003)"
                                                                                fill="#ff7135" />
                                                                            <path id="Path_2356" data-name="Path 2356"
                                                                                d="M44.093,70.07H32.83a.709.709,0,1,0,0,1.419H44.093a.709.709,0,0,0,0-1.419Z"
                                                                                transform="translate(0 -28.466)"
                                                                                fill="#ff7135" />
                                                                        </g>
                                                                    </svg>
                                                                    Export
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <!--End Filters-->
                                                <div class="table-responsive brndmgnttable">
                                                    <table class="table custom-table xs">
                                                        <thead>
                                                            <tr>
                                                                <th width="18%">Product Name</th>
                                                                <th>Modal Name</th>
                                                                <th>Brand Name</th>
                                                                <th width="10%">Category</th>
                                                                <th width="10%">Sub-Category</th>
                                                                <th>Requested On</th>
                                                                <th>Status</th>
                                                                <th width="18%">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <ng-container *ngIf="requested_records.length > 0">
                                                                <tr
                                                                    *ngFor="let list of requested_records; let k = index;">
                                                                    <td>
                                                                        <div class="order-info">
                                                                            <!-- <div class="order-img">
																			<img src="assets/images/p-2.jpg" />
																		</div> -->
                                                                            <div class="pd-seller-cntnt">
                                                                                <div class="txtdefault">
                                                                                    <a href="javascript:;"
                                                                                        class="order-link">{{
                                                                                        list.product_name }}</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>{{ list.model_no }}</td>
                                                                    <td>{{ list.brand_name }}</td>
                                                                    <td>{{ list.parent_category }}</td>
                                                                    <td>{{ list.category_name }}</td>
                                                                    <td>{{ list.date }}</td>
                                                                    <td>{{ list.status }}</td>
                                                                    <td class="d-flex align-items-center">

                                                                        <button
                                                                            class="btn-d-outline ripple d-inline-block place-bid mr-3"
                                                                            [disabled]="list.status != 'Approved'">Start
                                                                            Listing</button>
                                                                        <a (click)="openWindowCustomClass(content, list)"
                                                                            href="javascript:;"><img
                                                                                src="assets/images/icons/delete-icon.svg"></a>
                                                                    </td>
                                                                </tr>
                                                            </ng-container>

                                                            <!--Empty State-->
                                                            <tr *ngIf="!requested_records.length">
                                                                <td colspan="8">
                                                                    <div class="row pt-40 pb-40">
                                                                        <div class="col-md-12 text-center">
                                                                            <div class="mb-20"><img
                                                                                    src="/assets/images/empty-state.png">
                                                                            </div>
                                                                            <div class="fs16">There are no records to
                                                                                display!</div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <!--End Empty State-->

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div class="row mb-40" *ngIf="requested_records.length > 0">
                                                <div class="col-md-6">
                                                    <div class="text-left pl-3">
                                                        <nav class="mt-3 tbpg rows-per-page">
                                                            <div>Rows per page:</div>
                                                            <select class="select-box" [(ngModel)]="req_page_per_view"
                                                                (change)="setreqpageperview()">
                                                                <option *ngFor="let option of page_per_view_options"
                                                                    [ngValue]="option.value">{{option.name}}</option>
                                                            </select>
                                                        </nav>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"
                                                    *ngIf="request_listing_pagination.pages && request_listing_pagination.pages.length">
                                                    <div class="text-right pr-3">
                                                        <nav class="mt-3 tbpg">
                                                            <ul class="pagination align-items-center">
                                                                <li class="page-item"
                                                                    [ngClass]="{disabled:request_listing_pagination.currentPage === 1}">
                                                                    <a class="page-link previcon" href="javascript:;"
                                                                        (click)="setreqpage(1)"></a>
                                                                </li>

                                                                <li class="page-item"
                                                                    [ngClass]="{disabled:request_listing_pagination.currentPage === 1}">
                                                                    <a class="page-link sprevicon" href="javascript:;"
                                                                        (click)="setreqpage(request_listing_pagination.currentPage - 1)"></a>
                                                                </li>

                                                                <li class="page-item" i
                                                                    *ngFor="let page of request_listing_pagination.pages"
                                                                    [ngClass]="{active:request_listing_pagination.currentPage === page}">
                                                                    <a class="page-link" href="javascript:;"
                                                                        (click)="setreqpage(page)">{{page}}</a>
                                                                </li>

                                                                <li
                                                                    *ngIf="(request_listing_pagination.totalPages - request_listing_pagination.startPage) > 5 ">
                                                                    <a
                                                                        (click)="setreqpage(request_listing_pagination.startPage + 5)">...</a>
                                                                </li>
                                                                <li
                                                                    *ngIf="(request_listing_pagination.totalPages - request_listing_pagination.startPage) > 5">
                                                                    <a
                                                                        (click)="setreqpage(request_listing_pagination.totalPages)">{{request_listing_pagination.totalPages}}</a>
                                                                </li>

                                                                <li class="page-item"
                                                                    [ngClass]="{disabled:request_listing_pagination.currentPage === request_listing_pagination.totalPages}">
                                                                    <a class="page-link snexticon" href="javascript:;"
                                                                        (click)="setreqpage(request_listing_pagination.currentPage + 1)"></a>
                                                                </li>
                                                                <li class="page-item"
                                                                    [ngClass]="{disabled:request_listing_pagination.currentPage === request_listing_pagination.totalPages}">
                                                                    <a class="page-link nexticon" href="javascript:;"
                                                                        (click)="setreqpage(request_listing_pagination.totalPages)"></a>
                                                                </li>
                                                            </ul>
                                                        </nav>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>

                                    </ngb-tab>

                                </ngb-tabset>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
                <ng-container ngbNavItem *ngIf="seller_type == 'super'">
                    <a ngbNavLink>Supplier Products</a>
                    <ng-template ngbNavContent>
                        <div class="card cardui carduipro p-4 mb-0">
                            <div class="notifications-container theme-tab">
                                <ngb-tabset [justify]="'left'" [type]="'pills'" class="tabs tab-title">
                                    <ngb-tab>
                                        <ng-template ngbTabTitle>Active Products ({{ sub_approved_records.length }})
                                        </ng-template>
                                        <ng-template ngbTabContent>
                                            <div class="tab-content">
                                                <!--Filters-->
                                                <div class="row d-flex mt-20 report-cta">
                                                    <div class="col-md-5 mb-3">
                                                        <div class="report-input">
                                                            <div class="input-group-custom">
                                                                <input type="text"
                                                                    placeholder="Search by a Product Name, Product ID or Cate…"
                                                                    class="form-control" [(ngModel)]="ssasearch_term" (change)="getsubsellerapprovedproducts()">
                                                                <div class="input-group-append-custom">
                                                                    <img src="assets/images/svg/search_logo.svg">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-4">
                                                        <div class="report-input">
                                                            <div class="input-group-custom">
                                                                <select class="form-control" style="padding: 0 5px;" [(ngModel)]="ssasupplier_id" (change)="getsubsellerapprovedproducts()">
                                                                    <option value="">Select Supplier</option>
                                                                    <option *ngFor="let rec of supplier_records;" value="{{ rec.id }}">{{ rec.value }}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- <div class="col-md-7">
                                                        <div
                                                            class="d-flex clbxgrd justify-content-end lg-justify-content-start">
                                                            <div class="mr-3">
                                                                <a href="javascript:void(0);"
                                                                    class="d-inline-block fltr-round-btn radius10 txtseconday">
                                                                    <img src="assets/images/svg/filter.svg">
                                                                    Filters
                                                                </a>
                                                            </div>

                                                            <div class="form-container mt-0 mb-0 mr-3">
                                                                <div class="report-input floating-label">
                                                                    <div class="input-group-custom">
                                                                        <input type="text" value="Last 30 Days"
                                                                            class="form-control" placeholder=" " />
                                                                        <span class="highlight"></span>
                                                                        <label>Date</label>
                                                                        <div class="input-group-append-custom">
                                                                            <img
                                                                                src="assets/images/icons/filter-calendar.svg">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <a href="javascript:void(0);"
                                                                    class="btn-d-outline d-inline-block">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="12.681" height="17.061"
                                                                        viewBox="0 0 12.681 17.061">
                                                                        <g id="np_export_2535025_000000"
                                                                            transform="translate(-32.121 -25.962)">
                                                                            <path id="Path_2355" data-name="Path 2355"
                                                                                d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z"
                                                                                transform="translate(-0.003)"
                                                                                fill="#ff7135" />
                                                                            <path id="Path_2356" data-name="Path 2356"
                                                                                d="M44.093,70.07H32.83a.709.709,0,1,0,0,1.419H44.093a.709.709,0,0,0,0-1.419Z"
                                                                                transform="translate(0 -28.466)"
                                                                                fill="#ff7135" />
                                                                        </g>
                                                                    </svg>
                                                                    Export
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <!--End Filters-->
                                                <div class="table-responsive brndmgnttable">
                                                    <table class="table custom-table xs">
                                                        <thead>
                                                            <tr>
                                                                <th>Product ID</th>
                                                                <th width="18%">Product Name</th>
                                                                <th>Supplier Name</th>
                                                                <th width="10%">Category</th>
                                                                <th>Listed On</th>
                                                                <th>Total Stock Quantity</th>
                                                                <th width="15%">Listing Available</th>
                                                                <th>Status</th>
                                                                <th width="18%">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <!--Empty State-->
                                                            <tr *ngIf="!sub_approved_records.length">
                                                                <td colspan="9">
                                                                    <div class="row pt-40 pb-40">
                                                                        <div class="col-md-12 text-center">
                                                                            <div class="mb-20"><img src="/assets/images/empty-state.png">
                                                                            </div>
                                                                            <div class="fs16">There are no records to display!</div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <!--End Empty State-->
                                                            <tr *ngFor="let sa_record of sub_approved_records;">
                                                                <td>{{ sa_record.product_code }}</td>
                                                                <td>
                                                                    <div class="order-info">
                                                                        <div class="order-img">
                                                                            <img
                                                                                src="{{ sa_record.image?sa_record.image:'assets/images/p-2.jpg'}}" />
                                                                        </div>
                                                                        <div class="pd-seller-cntnt">
                                                                            <div class="txtdefault">
                                                                                <a href="javascript:;"
                                                                                    class="order-link">{{
                                                                                    sa_record.name }}</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>{{ sa_record.seller_name }}</td>
                                                                <td>{{ sa_record.category_name }}</td>
                                                                <td>{{ sa_record.listed_on }}</td>
                                                                <td>{{ sa_record.total_qty }}</td>
                                                                <td [innerHtml]="sa_record.marketplace"></td>
                                                                <td>
                                                                    <div class="tglbtn no-evt">
                                                                        <label class="switch">
                                                                            <input type="checkbox"
                                                                                [checked]="sa_record.status == 1"
                                                                                (change)="changeproductstatus(sa_record.sp_id)">
                                                                            <span class="slider round"></span>
                                                                            <i>{{ sa_record.status == 1?
                                                                                'Active':'Inactive' }}</i>
                                                                        </label>
                                                                    </div>
                                                                </td>
                                                                <td class="d-flex align-items-center">
                                                                    <a href="javascript:;"
                                                                        [routerLink]="['/seller/viewlisting/'+sa_record.uniqueId]"
                                                                        [queryParams]="{seller:sa_record.seller_id}"
                                                                        class="btn-d-outline ripple d-inline-block place-bid mr-3">View
                                                                        Listing</a>
                                                                        <a href="javascript:;"
                                                                        class="btn-d-outline ripple d-inline-block place-bid mr-3"
                                                                        (click)="openmargin(addMargin, sa_record, 'update')">Update
                                                                        Margin</a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="row mb-40"
                                                    *ngIf="sub_approved_listing_pagination.totalPages> 1">
                                                    <div class="col-md-6">
                                                        <div class="text-left pl-3">
                                                            <nav class="mt-3 tbpg rows-per-page">
                                                                <div>Rows per page:</div>
                                                                <select class="select-box"
                                                                    [(ngModel)]="sub_approved_page_per_view"
                                                                    (change)="setsubapprovedpageperview()">
                                                                    <option *ngFor="let option of page_per_view_options"
                                                                        [ngValue]="option.value">
                                                                        {{option.name}}</option>
                                                                </select>
                                                            </nav>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="text-right pr-3">
                                                            <nav class="mt-3 tbpg">
                                                                <ul class="pagination align-items-center">
                                                                    <li class="page-item"
                                                                        [ngClass]="{disabled:sub_approved_listing_pagination.currentPage === 1}">
                                                                        <a class="page-link previcon"
                                                                            href="javascript:;"
                                                                            (click)="setsubapprovedpage(1)"></a>
                                                                    </li>

                                                                    <li class="page-item"
                                                                        [ngClass]="{disabled:sub_approved_listing_pagination.currentPage === 1}">
                                                                        <a class="page-link sprevicon"
                                                                            href="javascript:;"
                                                                            (click)="setsubapprovedpage(sub_approved_listing_pagination.currentPage - 1)"></a>
                                                                    </li>

                                                                    <li class="page-item" i
                                                                        *ngFor="let page of sub_approved_listing_pagination.pages"
                                                                        [ngClass]="{active:sub_approved_listing_pagination.currentPage === page}">
                                                                        <a class="page-link" href="javascript:;"
                                                                            (click)="setsubapprovedpage(page)">{{page}}</a>
                                                                    </li>

                                                                    <li
                                                                        *ngIf="(sub_approved_listing_pagination.totalPages - sub_approved_listing_pagination.startPage) > 5 ">
                                                                        <a
                                                                            (click)="setsubapprovedpage(sub_approved_listing_pagination.startPage + 5)">...</a>
                                                                    </li>
                                                                    <li
                                                                        *ngIf="(sub_approved_listing_pagination.totalPages - sub_approved_listing_pagination.startPage) > 5">
                                                                        <a
                                                                            (click)="setsubapprovedpage(sub_approved_listing_pagination.totalPages)">{{sub_approved_listing_pagination.totalPages}}</a>
                                                                    </li>

                                                                    <li class="page-item"
                                                                        [ngClass]="{disabled:sub_approved_listing_pagination.currentPage === sub_approved_listing_pagination.totalPages}">
                                                                        <a class="page-link snexticon"
                                                                            href="javascript:;"
                                                                            (click)="setsubapprovedpage(sub_approved_listing_pagination.currentPage + 1)"></a>
                                                                    </li>
                                                                    <li class="page-item"
                                                                        [ngClass]="{disabled:sub_approved_listing_pagination.currentPage === sub_approved_listing_pagination.totalPages}">
                                                                        <a class="page-link nexticon"
                                                                            href="javascript:;"
                                                                            (click)="setsubapprovedpage(sub_approved_listing_pagination.totalPages)"></a>
                                                                    </li>
                                                                </ul>
                                                            </nav>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-tab>
                                    <ngb-tab>
                                        <ng-template ngbTabTitle>Pending for Approval ({{ sub_pending_records.length }})
                                        </ng-template>
                                        <ng-template ngbTabContent>
                                            <div class="tab-content">
                                                <!--Filters-->
                                                <div class="row d-flex mt-20 report-cta">
                                                    <div class="col-md-4">
                                                        <div class="report-input">
                                                            <div class="input-group-custom">
                                                                <input type="text"
                                                                    placeholder="Search by a Product Name, Product ID or Cate…"
                                                                    class="form-control" [(ngModel)]="sspsearch_term" (change)="getsubsellerpendingproducts()">
                                                                <div class="input-group-append-custom">
                                                                    <img src="assets/images/svg/search_logo.svg">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-4">
                                                        <div class="report-input">
                                                            <div class="input-group-custom">
                                                                <select class="form-control" style="padding: 0 5px;" [(ngModel)]="sspsupplier_id" (change)="getsubsellerpendingproducts()">
                                                                    <option value="">Select Supplier</option>
                                                                    <option *ngFor="let rec of supplier_records;" value="{{ rec.id }}">{{ rec.value }}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- <div class="col-md-7">
                                                        <div
                                                            class="d-flex clbxgrd justify-content-end lg-justify-content-start">
                                                            <div class="mr-3">
                                                                <a href="javascript:void(0);"
                                                                    class="d-inline-block fltr-round-btn radius10 txtseconday">
                                                                    <img src="assets/images/svg/filter.svg">
                                                                    Filters
                                                                </a>
                                                            </div>

                                                            <div class="form-container mt-0 mb-0 mr-3">
                                                                <div class="report-input floating-label">
                                                                    <div class="input-group-custom">
                                                                        <input type="text" value="Last 30 Days"
                                                                            class="form-control" placeholder=" " />
                                                                        <span class="highlight"></span>
                                                                        <label>Date</label>
                                                                        <div class="input-group-append-custom">
                                                                            <img
                                                                                src="assets/images/icons/filter-calendar.svg">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <a href="javascript:void(0);"
                                                                    class="btn-d-outline d-inline-block">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="12.681" height="17.061"
                                                                        viewBox="0 0 12.681 17.061">
                                                                        <g id="np_export_2535025_000000"
                                                                            transform="translate(-32.121 -25.962)">
                                                                            <path id="Path_2355" data-name="Path 2355"
                                                                                d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z"
                                                                                transform="translate(-0.003)"
                                                                                fill="#ff7135" />
                                                                            <path id="Path_2356" data-name="Path 2356"
                                                                                d="M44.093,70.07H32.83a.709.709,0,1,0,0,1.419H44.093a.709.709,0,0,0,0-1.419Z"
                                                                                transform="translate(0 -28.466)"
                                                                                fill="#ff7135" />
                                                                        </g>
                                                                    </svg>
                                                                    Export
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <!--End Filters-->
                                                <div class="table-responsive brndmgnttable">
                                                    <table class="table custom-table xs">
                                                        <thead>
                                                            <tr>
                                                                <th>Product ID</th>
                                                                <th width="18%">Product Name</th>
                                                                <th>Supplier Name</th>
                                                                <th width="10%">Category</th>
                                                                <th>Listed On</th>
                                                                <th>Total Stock Quantity</th>
                                                                <th width="15%">Listing Available</th>
                                                                <th>Status</th>
                                                                <th width="18%">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <ng-container>

                                                                <!--Empty State-->
                                                                <tr *ngIf="!sub_pending_records.length">
                                                                    <td colspan="9">
                                                                        <div class="row pt-40 pb-40">
                                                                            <div class="col-md-12 text-center">
                                                                                <div class="mb-20"><img src="/assets/images/empty-state.png">
                                                                                </div>
                                                                                <div class="fs16">There are no records to display!</div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <!--End Empty State-->

                                                                <tr
                                                                    *ngFor="let sp_record of sub_pending_records; let i = index;">
                                                                    <td>{{ sp_record.product_code }}</td>
                                                                    <td>
                                                                        <div class="order-info">
                                                                            <div class="order-img">
                                                                                <img
                                                                                    src="{{ sp_record.image?sp_record.image:'assets/images/p-2.jpg'}}" />
                                                                            </div>
                                                                            <div class="pd-seller-cntnt">
                                                                                <div class="txtdefault">
                                                                                    <a href="javascript:;"
                                                                                        class="order-link">{{
                                                                                        sp_record.name }}</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>{{ sp_record.seller_name }}</td>
                                                                    <td>{{ sp_record.category_name }}</td>
                                                                    <td>{{ sp_record.listed_on }}</td>
                                                                    <td>{{ sp_record.total_qty }}</td>
                                                                    <td [innerHtml]="sp_record.marketplace"></td>
                                                                    <td>Pending</td>
                                                                    <td class="d-flex align-items-center">
                                                                        <a href="javascript:;"
                                                                            [routerLink]="['/seller/viewlisting/'+sp_record.uniqueId]"
                                                                            [queryParams]="{seller:sp_record.seller_id}"
                                                                            class="btn-d-outline ripple d-inline-block place-bid mr-3">View
                                                                            Listing</a>
                                                                        <a href="javascript:;"
                                                                            class="btn-d-outline ripple d-inline-block place-bid mr-3"
                                                                            (click)="openmargin(addMargin, sp_record, 'add')">Add
                                                                            Margin</a>
                                                                        <a href="javascript:void(0);"
                                                                            (click)="subsellerpendingreject(sp_record)"
                                                                            class="btn-d-outline-danger ripple d-inline-block place-bid2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                width="17.366" height="17.366"
                                                                                viewBox="0 0 17.366 17.366">
                                                                                <path id="np_cross_4440767_000000"
                                                                                    d="M27.176,25.335l6.444-6.444a1.3,1.3,0,1,0-1.841-1.841l-6.444,6.444L18.891,17.05a1.3,1.3,0,0,0-1.841,1.841l6.444,6.444L17.05,31.78a1.3,1.3,0,1,0,1.841,1.841l6.444-6.444,6.444,6.444A1.3,1.3,0,0,0,33.62,31.78Z"
                                                                                    transform="translate(-16.652 -16.652)"
                                                                                    fill="#be1e1e" />
                                                                            </svg>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            </ng-container>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="row mb-40"
                                                    *ngIf="sub_pending_listing_pagination.totalPages> 1">
                                                    <div class="col-md-6">
                                                        <div class="text-left pl-3">
                                                            <nav class="mt-3 tbpg rows-per-page">
                                                                <div>Rows per page:</div>
                                                                <select class="select-box"
                                                                    [(ngModel)]="sub_pending_page_per_view"
                                                                    (change)="setsubpendingpageperview()">
                                                                    <option *ngFor="let option of page_per_view_options"
                                                                        [ngValue]="option.value">
                                                                        {{option.name}}</option>
                                                                </select>
                                                            </nav>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="text-right pr-3">
                                                            <nav class="mt-3 tbpg">
                                                                <ul class="pagination align-items-center">
                                                                    <li class="page-item"
                                                                        [ngClass]="{disabled:sub_pending_listing_pagination.currentPage === 1}">
                                                                        <a class="page-link previcon"
                                                                            href="javascript:;"
                                                                            (click)="setsubpendingpage(1)"></a>
                                                                    </li>

                                                                    <li class="page-item"
                                                                        [ngClass]="{disabled:sub_pending_listing_pagination.currentPage === 1}">
                                                                        <a class="page-link sprevicon"
                                                                            href="javascript:;"
                                                                            (click)="setsubpendingpage(sub_pending_listing_pagination.currentPage - 1)"></a>
                                                                    </li>

                                                                    <li class="page-item" i
                                                                        *ngFor="let page of sub_pending_listing_pagination.pages"
                                                                        [ngClass]="{active:sub_pending_listing_pagination.currentPage === page}">
                                                                        <a class="page-link" href="javascript:;"
                                                                            (click)="setsubpendingpage(page)">{{page}}</a>
                                                                    </li>

                                                                    <li
                                                                        *ngIf="(sub_pending_listing_pagination.totalPages - sub_pending_listing_pagination.startPage) > 5 ">
                                                                        <a
                                                                            (click)="setsubpendingpage(sub_pending_listing_pagination.startPage + 5)">...</a>
                                                                    </li>
                                                                    <li
                                                                        *ngIf="(sub_pending_listing_pagination.totalPages - sub_pending_listing_pagination.startPage) > 5">
                                                                        <a
                                                                            (click)="setsubpendingpage(sub_pending_listing_pagination.totalPages)">{{sub_pending_listing_pagination.totalPages}}</a>
                                                                    </li>

                                                                    <li class="page-item"
                                                                        [ngClass]="{disabled:sub_pending_listing_pagination.currentPage === sub_pending_listing_pagination.totalPages}">
                                                                        <a class="page-link snexticon"
                                                                            href="javascript:;"
                                                                            (click)="setsubpendingpage(sub_pending_listing_pagination.currentPage + 1)"></a>
                                                                    </li>
                                                                    <li class="page-item"
                                                                        [ngClass]="{disabled:sub_pending_listing_pagination.currentPage === sub_pending_listing_pagination.totalPages}">
                                                                        <a class="page-link nexticon"
                                                                            href="javascript:;"
                                                                            (click)="setsubpendingpage(sub_pending_listing_pagination.totalPages)"></a>
                                                                    </li>
                                                                </ul>
                                                            </nav>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>

                                    </ngb-tab>

                                </ngb-tabset>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </nav>

            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>

    </div>
</div>
<!-- REMOVE PRODUCT MODAL -->
<ng-template #content let-modal>
    <div class="modal-header nobd text-center pt-4 pb-0">
        <h4 class="modal-title fs22 txtdefault f-700 mx-auto pt-3">Delete Request</h4>
        <button type="button" class="btn-close close rmvmdl" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <p class="fs12 txtseconday py-3 mb-2 ">
            <span class="mr-2">
                <svg id="Group_46" data-name="Group 46" xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                    viewBox="0 0 15 15">
                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="7.5" cy="7.5" r="7.5" fill="#07f" />
                    <path id="Path_2235" data-name="Path 2235"
                        d="M7,8.171l-.128.523q-.576.227-.919.346a2.425,2.425,0,0,1-.8.119,1.591,1.591,0,0,1-1.084-.34,1.1,1.1,0,0,1-.387-.864,3.056,3.056,0,0,1,.029-.416q.029-.213.093-.48l.48-1.7q.064-.244.108-.462a2.01,2.01,0,0,0,.044-.4A.616.616,0,0,0,4.3,4.047a.759.759,0,0,0-.513-.128,1.345,1.345,0,0,0-.381.058c-.131.039-.242.076-.336.11L3.2,3.564q.471-.192.9-.329A2.7,2.7,0,0,1,4.916,3.1a1.552,1.552,0,0,1,1.068.335,1.111,1.111,0,0,1,.375.869c0,.074-.008.2-.026.39a2.606,2.606,0,0,1-.1.512L5.759,6.9a4.492,4.492,0,0,0-.1.466,2.4,2.4,0,0,0-.047.4.57.57,0,0,0,.15.459.85.85,0,0,0,.523.122,1.509,1.509,0,0,0,.4-.061A2.21,2.21,0,0,0,7,8.171Zm.121-7.1a.99.99,0,0,1-.334.753,1.138,1.138,0,0,1-.8.312,1.15,1.15,0,0,1-.809-.312.988.988,0,0,1-.337-.753A1,1,0,0,1,5.169.314a1.194,1.194,0,0,1,1.614,0A1,1,0,0,1,7.117,1.07Z"
                        transform="translate(2.406 2.92)" fill="#fff" />
                </svg>
            </span>
            By deleting the request, the Equibiz team won’t be able to see your requested listing.
        </p>
        <p class="fs16 txtseconday pb-3 mb-2 text-center">Are you sure you want to delete the requested listing?</p>
        <div class="d-table mb-4 w-100">
            <!-- <div class="d-table-cell cart-thumb align-middle">
				<img width="60" src="assets/images/products/p-2.jpg">
			</div> -->
            <div class="d-table-cell pl-3 align-middle">
                <p class="txtdefault wt700 mb-0 fs14 lnht20">{{ selected_list.product_name }}</p>
            </div>
        </div>
    </div>
    <div class="modal-footer  text-center nobd mx-auto pb-4 mb-3">
        <button type="button" class="btn-d-outline d-inline-block cancel-outline mr-3"
            (click)="modal.close('Close click')">Cancel</button>
        <button type="button" class="btn buybtn fs16 radius10" (click)="removerequestedlist()">Remove</button>
    </div>
</ng-template>
<!-- /REMOVE PRODUCT MODAL -->

<!-- ADD MARGIN MODAL -->
<ng-template #addMargin let-modal>
    <div class="modal-header nobd text-center pt-4 pb-0">
        <h4 class="modal-title fs22 txtdefault f-700 mx-auto pt-3">{{ margin_type == 'add'?'Add':'Update' }} Margin</h4>
        <button type="button" class="btn-close close rmvmdl" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="inline-scrollbard">
            <p class="fs12 txtseconday mb-2">
                <span class="mr-2">
                    <svg id="Group_46" data-name="Group 46" xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                        viewBox="0 0 15 15">
                        <circle id="Ellipse_1" data-name="Ellipse 1" cx="7.5" cy="7.5" r="7.5" fill="#07f" />
                        <path id="Path_2235" data-name="Path 2235"
                            d="M7,8.171l-.128.523q-.576.227-.919.346a2.425,2.425,0,0,1-.8.119,1.591,1.591,0,0,1-1.084-.34,1.1,1.1,0,0,1-.387-.864,3.056,3.056,0,0,1,.029-.416q.029-.213.093-.48l.48-1.7q.064-.244.108-.462a2.01,2.01,0,0,0,.044-.4A.616.616,0,0,0,4.3,4.047a.759.759,0,0,0-.513-.128,1.345,1.345,0,0,0-.381.058c-.131.039-.242.076-.336.11L3.2,3.564q.471-.192.9-.329A2.7,2.7,0,0,1,4.916,3.1a1.552,1.552,0,0,1,1.068.335,1.111,1.111,0,0,1,.375.869c0,.074-.008.2-.026.39a2.606,2.606,0,0,1-.1.512L5.759,6.9a4.492,4.492,0,0,0-.1.466,2.4,2.4,0,0,0-.047.4.57.57,0,0,0,.15.459.85.85,0,0,0,.523.122,1.509,1.509,0,0,0,.4-.061A2.21,2.21,0,0,0,7,8.171Zm.121-7.1a.99.99,0,0,1-.334.753,1.138,1.138,0,0,1-.8.312,1.15,1.15,0,0,1-.809-.312.988.988,0,0,1-.337-.753A1,1,0,0,1,5.169.314a1.194,1.194,0,0,1,1.614,0A1,1,0,0,1,7.117,1.07Z"
                            transform="translate(2.406 2.92)" fill="#fff" />
                    </svg>
                </span>
                Margin is the profit that you will earn on facilitating your supplier’s listing. The new listed price after adding margin will be visible to all buyers over the platform in your name.
            </p>
    
            <table class="table table-bordered mt-4 mb-3" *ngIf="!selected_sub_pending_record.child">
                <thead>
                    <tr>
                        <th>Price Type</th>
                        <th>Minimum Qty</th>
                        <th>Current Listed Price</th>
                        <th>New Listed Price</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="selected_sub_pending_record.wholesale">
                        <th>Wholesale</th>
                        <th>{{ selected_sub_pending_record.wholesale_min_qty }}</th>
                        <td>₹ {{ selected_sub_pending_record.wholesale_price }}</td>
                        <td>₹ {{ selected_sub_pending_record.wholesale_total_price }}</td>
                    </tr>
                    <tr *ngIf="selected_sub_pending_record.bulk">
                        <th>Bulk</th>
                        <th>{{ selected_sub_pending_record.bulk_min_qty }}</th>
                        <td>₹ {{ selected_sub_pending_record.bulk_price }}</td>
                        <td>₹ {{ selected_sub_pending_record.bulk_total_price }}</td>
                    </tr>
                </tbody>
            </table>

            <ng-container *ngIf="selected_sub_pending_record.child">
                <ng-container *ngFor="let child of selected_sub_pending_record.child_records">
                    <h4>{{ child.name }}</h4>
                    <table class="table table-bordered mt-4 mb-3">
                        <thead>
                            <tr>
                                <th>Price Type</th>
                                <th>Minimum Qty</th>
                                <th>Current Listed Price</th>
                                <th>New Listed Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="child.wholesale">
                                <th>Wholesale</th>
                                <th>{{ child.wholesale_min_qty }}</th>
                                <td>₹ {{ child.wholesale_price }}</td>
                                <td>₹ {{ child.wholesale_total_price }}</td>
                            </tr>
                            <tr *ngIf="child.bulk">
                                <th>Bulk</th>
                                <th>{{ child.bulk_min_qty }}</th>
                                <td>₹ {{ child.bulk_price }}</td>
                                <td>₹ {{ child.bulk_total_price }}</td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>
            </ng-container>
    
            <div class="form-container mdl-frm-lbl mb-3" *ngIf="!selected_sub_pending_record.child">
                <div class="row">
                    <div class="col-md-6" *ngIf="selected_sub_pending_record.wholesale">
                        <div class="floating-label mb-0">
                            <input type="text" class="form-control" value=""
                                [(ngModel)]="selected_sub_pending_record.wholesale_margin" (keyup)="calculatetotal()" (change)="supersellernetrealization('wholesale')" />
                            <span class="highlight"></span>
                            <label>Enter your margin for Wholesale segment</label>
                        </div>
                    </div>
                    <div class="col-md-6" *ngIf="selected_sub_pending_record.bulk">
                        <div class="floating-label mb-0">
                            <input type="text" class="form-control" value=""
                                [(ngModel)]="selected_sub_pending_record.bulk_margin" (keyup)="calculatetotal()"  (change)="supersellernetrealization('bulk')"/>
                            <span class="highlight"></span>
                            <label>Enter your margin for Bulk segment</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-container mdl-frm-lbl mb-3" *ngIf="selected_sub_pending_record.child">
                <div class="row">
                    <div class="col-md-6" *ngIf="selected_sub_pending_record.wholesale">
                        <div class="floating-label mb-0">
                            <input type="text" class="form-control" value=""
                                [(ngModel)]="selected_sub_pending_record.wholesale_margin" (keyup)="calculatechildtotal()" />
                            <span class="highlight"></span>
                            <label>Enter your margin for Wholesale segment</label>
                        </div>
                    </div>
                    <div class="col-md-6" *ngIf="selected_sub_pending_record.bulk">
                        <div class="floating-label mb-0">
                            <input type="text" class="form-control" value=""
                                [(ngModel)]="selected_sub_pending_record.bulk_margin" (keyup)="calculatechildtotal()"/>
                            <span class="highlight"></span>
                            <label>Enter your margin for Bulk segment</label>
                        </div>
                    </div>
                </div>
            </div>
    
            <p class="fs13"><b>For Example:</b> If the supplier’s listed price is 10,000/ and you wanted to add a margin of Rs 200 based on the prevalent demand-supply dynamics, the profit on every order received from the buyer will be Rs 200/. Your supplier will issue the invoice in your name @ Rs10,000/ and you will in turn raise the invoice in the name of the buyer at @10,200/.</p>
    
                <hr>
            <div class="row"  *ngIf="!selected_sub_pending_record.child">
                <div class="col-md-6" *ngIf="wholesale_netrealization.without_gst">
                    <div class="rtrqsthead text-center">
                        <h4 class="txtdefault wt700 fs16 mb-10">Net Realization Amount (Wholesale)</h4>
                    </div>
                    <div class="inrcard p-3 radius10 position-relative">
                        <div class="form-group">
                            <input type="radio" value="inside" name="netrealization_type_wholesale" [(ngModel)]="netrealization_type_wholesale">&nbsp;Inside City&nbsp;&nbsp;
                            <input type="radio" value="outside" name="netrealization_type_wholesale" [(ngModel)]="netrealization_type_wholesale">&nbsp;Outside City
                        </div>
                        <div class="row d-flex justify-content-between">
                            <div class="col-md-6 card-price-info">Net Goods Value:</div>
                            <div class="col-md-6 card-price-info text-right">{{ wholesale_netrealization.without_gst }}</div>
                        </div>
                        <div class="row d-flex justify-content-between">
                            <div class="col-md-6 card-price-info">GST:</div>
                            <div class="col-md-6 card-price-info text-right">{{ wholesale_netrealization.gst_amount }}</div>
                        </div>
                        <div class="row d-flex justify-content-between">
                            <div class="col-md-6 card-price-info">Convenience Charges:</div>
                            <div class="col-md-6 card-price-info text-right">{{ wholesale_netrealization.convience_fee }}</div>
                        </div>
                        <div class="row d-flex justify-content-between" *ngIf="netrealization_type_wholesale == 'inside'">
                            <div class="col-md-6 card-price-info">Logistics Charges:</div>
                            <div class="col-md-6 card-price-info text-right">{{ wholesale_netrealization.logistics_fee }}</div>
                        </div>

                        <div class="row d-flex justify-content-between" *ngIf="netrealization_type_wholesale == 'outside'">
                            <div class="col-md-6 card-price-info">Logistics Charges:</div>
                            <div class="col-md-6 card-price-info text-right">{{ wholesale_netrealization.outside_logistics_fee }}</div>
                        </div>
                        <div class="row d-flex justify-content-between">
                            <div class="col-md-6 card-price-info">TCS Charges:</div>
                            <div class="col-md-6 card-price-info text-right">{{ wholesale_netrealization.tcs_amount }}</div>
                        </div>
                        <div class="row d-flex justify-content-between">
                            <div class="col-md-6 card-price-info">TDS Charges:</div>
                            <div class="col-md-6 card-price-info text-right">{{ wholesale_netrealization.tds_amount }}</div>
                        </div>
                        <hr class="mt-1 mb-1">
                        <div class="row d-flex justify-content-between" *ngIf="netrealization_type_wholesale == 'inside'">
                            <div class="col-md-6 card-price-info f-700">Net Realization Value:</div>
                            <div class="col-md-6 card-price-info text-right f-700">{{ wholesale_netrealization.net_realization }}</div>
                        </div>
                        <div class="row d-flex justify-content-between" *ngIf="netrealization_type_wholesale == 'outside'">
                            <div class="col-md-6 card-price-info f-700">Net Realization Value:</div>
                            <div class="col-md-6 card-price-info text-right f-700">{{ wholesale_netrealization.outside_net_realization }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6" *ngIf="bulk_netrealization.without_gst">
                    <div class="rtrqsthead text-center">
                        <h4 class="txtdefault wt700 fs16 mb-10">Net Realization Amount (Bulk)</h4>
                    </div>
                    <div class="inrcard p-3 radius10 position-relative">
                        <div class="form-group">
                            <input type="radio" value="inside" name="netrealization_type_bulk" [(ngModel)]="netrealization_type_bulk">&nbsp;Inside City&nbsp;&nbsp;
                            <input type="radio" value="outside" name="netrealization_type_bulk" [(ngModel)]="netrealization_type_bulk">&nbsp;Outside City
                        </div>
                        <div class="row d-flex justify-content-between">
                            <div class="col-md-6 card-price-info">Net Goods Value:</div>
                            <div class="col-md-6 card-price-info text-right">{{ bulk_netrealization.without_gst }}</div>
                        </div>
                        <div class="row d-flex justify-content-between">
                            <div class="col-md-6 card-price-info">GST:</div>
                            <div class="col-md-6 card-price-info text-right">{{ bulk_netrealization.gst_amount }}</div>
                        </div>
                        <div class="row d-flex justify-content-between">
                            <div class="col-md-6 card-price-info">Convenience Charges:</div>
                            <div class="col-md-6 card-price-info text-right">{{ bulk_netrealization.convience_fee }}</div>
                        </div>
                        <div class="row d-flex justify-content-between" *ngIf="netrealization_type_bulk == 'inside'">
                            <div class="col-md-6 card-price-info">Logistics Charges:</div>
                            <div class="col-md-6 card-price-info text-right">{{ bulk_netrealization.logistics_fee }}</div>
                        </div>
                        <div class="row d-flex justify-content-between" *ngIf="netrealization_type_bulk == 'outside'">
                            <div class="col-md-6 card-price-info">Logistics Charges:</div>
                            <div class="col-md-6 card-price-info text-right">{{ bulk_netrealization.outside_logistics_fee }}</div>
                        </div>
                        <div class="row d-flex justify-content-between">
                            <div class="col-md-6 card-price-info">TCS Amount:</div>
                            <div class="col-md-6 card-price-info text-right">{{ bulk_netrealization.tcs_amount }}</div>
                        </div>
                        <div class="row d-flex justify-content-between">
                            <div class="col-md-6 card-price-info">TDS Amount:</div>
                            <div class="col-md-6 card-price-info text-right">{{ bulk_netrealization.tds_amount }}</div>
                        </div>
                        <hr class="mt-1 mb-1">
                        <div class="row d-flex justify-content-between" *ngIf="netrealization_type_bulk == 'inside'">
                            <div class="col-md-6 card-price-info f-700">Net Realization Value:</div>
                            <div class="col-md-6 card-price-info text-right f-700">{{ bulk_netrealization.net_realization }}</div>
                        </div>
                        <div class="row d-flex justify-content-between" *ngIf="netrealization_type_bulk == 'outside'">
                            <div class="col-md-6 card-price-info f-700">Net Realization Value:</div>
                            <div class="col-md-6 card-price-info text-right f-700">{{ bulk_netrealization.outside_net_realization }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--end inline-scrollbard-->
    </div>
    
    <div class="modal-footer text-center nobd mx-auto pb-4 mb-3">
        <button type="button" class="btn buybtn fs16 radius10" (click)="addmargintoproduct()">{{ margin_type == 'add'?'Add and Approve
            Listing':'Update Margin' }}</button>
    </div>
</ng-template>
<!-- /REMOVE PRODUCT MODAL -->

<app-footer-seller></app-footer-seller>