import { Component, OnInit } from '@angular/core';
import { AddNewCategoryService } from './add-new-category.service';
import { EncrDecrService } from '../../helpers/EncrDecrService';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ToasterService } from '../../helpers/toastr.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-add-new-category',
    templateUrl: './add-new-category.component.html',
    styleUrls: ['./add-new-category.component.scss']
})
export class AddNewCategoryComponent implements OnInit {

    addCategoryForm: FormGroup;
    public businessLoading: boolean = false;
    previewImage: any = 'assets/images/icons/attachment.png';
    previewName: string = 'Category Image';
    subPreviewImage: any = '';

    images: string[] = [];

    constructor(
        private _addnewcategoryservice: AddNewCategoryService,
        private _EncrDecr: EncrDecrService,
        private fb: FormBuilder,
        private _toastr: ToasterService,
    ) { }

    ngOnInit() {
        //Add Category Form
        this.addCategoryForm = this.fb.group({
            name: this.fb.control('', [Validators.required]),
            image: this.fb.control('', []),
            imageSource: new FormControl('', []),
            description: this.fb.control('', []),
            sub_categories: this.fb.array([this.createSubcategory()]),
            fileSource: new FormControl('', [])
        });
    }

    get add() { return this.addCategoryForm.controls; }

    createSubcategory() {
        return this.fb.group({
            name: '',
            image: '',
            preview: 'assets/images/icons/attachment.png',
            new_file: false
        });
    }

    newSubCategory() {
        (this.addCategoryForm.controls['sub_categories'] as FormArray).push(this.createSubcategory());
    }

    addproductcategory() {
        this.businessLoading = true;
        let data: any = new FormData();
        data.append('name', this._EncrDecr.set(this.addCategoryForm.get('name').value));
        data.append('parent_image', this.addCategoryForm.get('imageSource').value);
        data.append('parent', 'yes');
        data.append('description', this._EncrDecr.set(this.addCategoryForm.get('description').value));
        data.append('sub_categories', JSON.stringify(this.addCategoryForm.get('sub_categories').value));
        let length = this.addCategoryForm.get('fileSource').value.length;
        for (let i = 0; i < length; i++) {
            data.append("file_source_" + i, this.addCategoryForm.get('fileSource').value[i]);
        }
        this._addnewcategoryservice.addcategory(data).subscribe((data) => {
            this.businessLoading = false;
            if (data['status'] == "success") {
                this.addCategoryForm.reset();
                this._toastr.success(data['message']);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            } else if (data['status'] == 'validation') {
                this._toastr.validation(data['errors'])
            }
        }, (error) => {
            this.businessLoading = false;
        });
    }

    onFileChange(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.addCategoryForm.patchValue({
                imageSource: file
            });
            this.previewName = file.name;
            const reader = new FileReader();
            reader.onload = e => this.previewImage = reader.result;
            reader.readAsDataURL(file);
        }
    }

    onFileChangeSubcategory(event: any, i) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];

            const readerr = new FileReader();
            readerr.onload = e => this.subPreviewImage = readerr.result;
            setTimeout(() => {

                this.images.push(file);
                ((this.addCategoryForm.get('sub_categories') as FormArray).at(i) as FormGroup).get('new_file').patchValue(true);
                ((this.addCategoryForm.get('sub_categories') as FormArray).at(i) as FormGroup).get('preview').patchValue(this.subPreviewImage);

            }, 500)
            readerr.readAsDataURL(file);

            this.addCategoryForm.patchValue({
                fileSource: this.images
            });
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.addCategoryForm.get('sub_categories')['controls'], event.previousIndex, event.currentIndex);
        moveItemInArray(this.addCategoryForm.get('sub_categories').value, event.previousIndex, event.currentIndex);
    }

}
