<app-header-one [sticky]="true"></app-header-one>
<div class="container pb-5">
    <div class="cart-wrap">
        <h2 class="fs28 wt700 pt-4 txtdefault text-capitalize">My Cart</h2>
        <!-- BREADCRUMB -->
        <nav class="mb-md-2 pt-2 pb-md-0" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
            <ol class="breadcrumb p-0 bg-transparent">
                <li class="breadcrumb-item"><a href="javascript:void(0);" class="txtseconday"
                        [routerLink]="['/home']">Home </a></li>
                <li class="breadcrumb-item active" aria-current="page">My Cart</li>
            </ol>
        </nav>
        <!-- /BREADCRUMB -->
        <div class="cart-content">
            <!-- <div class="my-4">
				<div class="css-check">
					<input class="css-checkbx" type="checkbox" />
					<label class="css-label">Select and Checkout</label>
				</div>								
			</div> -->
            <div class="row" *ngIf="cart_records.length > 0">
                <!-- CART ITEMS -->
                <div class="col-lg-9 ">
                    <ngb-accordion #a="ngbAccordion" class="cartitemlist" activeIds="custom-panel-1">
                        <ngb-panel *ngFor="let cart of cart_records; let i = index;" id="custom-panel-{{i+1}}">
                            <ng-template ngbPanelHeader let-opened="opened">
                                <div class="d-flex align-items-center justify-content-between">
                                    <h3 class="m-0 fs20 txtdefault f-700 cs-tltle">
                                        <img [src]="cart.logo?cart.logo:'assets/images/svg/seller.svg'" /> {{
                                        cart.name}}
                                        <div class="fs16 txtseconday d-inline-block strclr"> ({{cart.rating}} <i
                                                class="fa fa-star"></i>)</div>
                                    </h3>
                                    <a href="javascript:void(0);" class="add-whislist-link rmvalldata"
                                        (click)="removeall(cart)"><span>Remove All</span></a>
                                    <button ngbPanelToggle class="btn btn-link p-0"> &nbsp; </button>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <!-- REPEAT -->
                                <div class="cs-item-row" *ngFor="let meta of cart.meta; let k = index;">
                                    <ng-container *ngIf="meta.child == 'normal'">
                                        <div class="row">
                                            <div class="col-md-5 pr-0">
                                                <h4 class="fs16 f-700 mb-sm-4 mb-3 txtdefault">Product Info:</h4>
                                                <div class="d-table mb-4 w-100">
                                                    <div class="d-table-cell cart-thumb align-middle">
                                                        <img
                                                            [src]="meta.image?meta.image:'assets/images/products/p-2.jpg'" />
                                                    </div>
                                                    <div class="d-table-cell pl-3 align-middle">
                                                        <p class="txtdefault wt700 mb-0 fs14 lnht20">{{ meta.product_name }}
                                                        </p>
                                                        <div class="pd-taglist">
                                                            <span class="f-600 fs10 radius15">{{ meta.map_type }}</span>
                                                            <span *ngIf="meta.payment" class="f-600 fs10 radius15">{{ meta.payment }}</span>
                                                            <span *ngIf="meta.shipping" class="f-600 fs10 radius15">{{ meta.shipping }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2 col-4 pr-0 text-center">
                                                <h4 class="fs14 f-700 mb-lg-4 mb-0 pb-2">Total Stock</h4>
                                                <p class="fs14 txtseconday pt-3">{{ meta.total_stock }}</p>
                                            </div>
                                            <div class="col-md-2 col-4 px-0 text-center">
                                                <h4 class="fs14 f-700 px-0 mb-lg-4 mb-0 pb-2">Price Per Unit</h4>
                                                <p class="fs14 txtseconday pt-3">₹ {{ meta.price_per_unit }}</p>
                                            </div>
                                            <div class="col-md-3 col-4 pl-md-0 px-0 text-center">
                                                <h4 class="fs14 f-700 mb-lg-2 pb-2">Order quantity</h4>
                                                <div class="cs-odqty">
                                                    <div class="input-group">
                                                        <span class="input-group-btn">
                                                            <button type="button" class="quantity-left-minus btn"
                                                                (click)="changequantity(meta, 'minus')" min="1">
                                                                -
                                                            </button>
                                                        </span>
                                                        <input type="text" id="quantity" name="quantity"
                                                            class="form-control input-number" [(ngModel)]="meta.order_qty"
                                                            (change)="changequantity(meta, 'change')" min="1"
                                                            max="10000">
                                                        <span class="input-group-btn">
                                                            <button type="button"
                                                                class="quantity-right-plus btn text-success"
                                                                (click)="changequantity(meta, 'plus')">
                                                                +
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-table w-100 whlst-tblt-center">
                                            <!-- <div class="d-table-cell hideintablet cart-thumb emp-thumb align-middle">

                                            </div> -->
                                            <div class="d-table-cell pl-3 align-middle">
                                                <a href="javascript:void(0);" class="add-whislist-link"
                                                    (click)="wishlist(meta)"><span>{{ meta.wishlist? "Remove from wishlist":
                                                        "Add to Wishlist" }}</span></a>
                                                <a href="javascript:;" (click)="removeproduct(cart, meta)"><span
                                                        class="rmvitem"><img
                                                            src="assets/images/svg/remove.svg" /></span></a>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="meta.child == 'parentchild'">
                                        
                                        <div class="row">
                                            <div class="col-md-5 pr-0">
                                                <h4 class="fs16 f-700 mb-sm-4 mb-3 txtdefault">Product Info:</h4>
                                                <div class="d-table mb-4 w-100">
                                                    <div class="d-table-cell cart-thumb align-middle">
                                                        <img
                                                            [src]="meta.image?meta.image:'assets/images/products/p-2.jpg'" />
                                                    </div>
                                                    <div class="d-table-cell pl-3 align-middle">
                                                        <p class="txtdefault wt700 mb-0 fs14 lnht20">{{ meta.product_name }}
                                                        </p>
                                                        <div class="pd-taglist">
                                                            <span class="f-600 fs10 radius15">{{ meta.map_type }}</span>
                                                            <span *ngIf="meta.payment" class="f-600 fs10 radius15">{{ meta.payment }}</span>
                                                            <span *ngIf="meta.shipping" class="f-600 fs10 radius15">{{ meta.shipping }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-ui mb-3 shadow-2 parentChild p-10" *ngFor="let child of meta.child_records; let j = index">
                                            <div class="row">
                                                <div class="col-md-5 pr-0">
                                                    <div class="d-table mb-4 w-100">
                                                        <div class="d-table-cell cart-thumb align-middle productImg">
                                                            <img [src]="child.image?child.image:'assets/images/products/p-2.jpg'" />
                                                        </div>
                                                        <div class="d-table-cell pl-3 align-middle">
                                                            <p class="txtdefault wt700 mb-0 fs14 lnht20">{{ child.product_name }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-2 col-4 pr-0 text-center">
                                                    <h4 class="fs14 f-700 mb-lg-4 mb-0 pb-2">Total Stock</h4>
                                                    <p class="fs14 txtseconday pt-4">{{ child.total_stock }}</p>
                                                </div>
                                                <div class="col-md-2 col-4 px-0 text-center">
                                                    <h4 class="fs14 f-700 px-0 mb-lg-4 mb-0 pb-2">Price Per Unit</h4>
                                                    <p class="fs14 txtseconday pt-4">₹ {{ child.price_per_unit }}</p>
                                                </div>
                                                <div class="col-md-3 col-4 pl-md-0 px-0 text-center">
                                                    <h4 class="fs14 f-700 mb-lg-2 pb-2">Order quantity</h4>
                                                    <div class="cs-odqty">
                                                        <div class="input-group">
                                                            <span class="input-group-btn">
                                                                <button type="button" class="quantity-left-minus btn"
                                                                    (click)="changequantity(child, 'minus')" min="1">
                                                                    -
                                                                </button>
                                                            </span>
                                                            <input type="text" id="quantity" name="quantity"
                                                                class="form-control input-number" [(ngModel)]="child.order_qty"
                                                                (change)="changequantity(child, 'change')" min="1"
                                                                max="10000">
                                                            <span class="input-group-btn">
                                                                <button type="button"
                                                                    class="quantity-right-plus btn text-success"
                                                                    (click)="changequantity(child, 'plus')">
                                                                    +
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-table w-100 whlst-tblt-center">
                                            <div class="d-table-cell pl-3 align-middle">
                                                <a href="javascript:void(0);" class="add-whislist-link"
                                                    (click)="wishlist(meta)"><span>{{ meta.wishlist? "Remove from wishlist":
                                                        "Add to Wishlist" }}</span></a>
                                                <a href="javascript:;" (click)="removeproduct(cart, meta)"><span
                                                        class="rmvitem"><img
                                                            src="assets/images/svg/remove.svg" /></span></a>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <!-- /REPEAT -->
                            </ng-template>
                        </ngb-panel>

                    </ngb-accordion>
                </div>
                <!-- CART ITEMS -->
                <!-- CART TOTAL -->
                <div class="col-lg-3 cart-total">
                    <div class="card card-ui">
                        <div class="card-header th-cmn-bd">
                            <p class="fs14 txtdefault">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="13.184" height="14.061" viewBox="0 0 13.184 14.061">
                                    <defs>
                                        <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1"
                                            gradientUnits="objectBoundingBox">
                                            <stop offset="0" stop-color="#79b7ff" />
                                            <stop offset="1" stop-color="#07f" />
                                        </linearGradient>
                                    </defs>
                                    <path id="Icon_awesome-shield-alt" data-name="Icon awesome-shield-alt"
                                        d="M13.5,2.3,8.225.1A1.322,1.322,0,0,0,7.211.1L1.938,2.3a1.317,1.317,0,0,0-.813,1.217c0,5.452,3.145,9.22,6.084,10.445a1.323,1.323,0,0,0,1.013,0c2.354-.981,6.086-4.367,6.086-10.445A1.319,1.319,0,0,0,13.5,2.3ZM7.72,12.259l0-10.464,4.831,2.013A9.412,9.412,0,0,1,7.72,12.259Z"
                                        transform="translate(-1.125 -0.002)" fill="url(#linear-gradient)" />
                                </svg>

                                Safe and Secure Purchase.
                            </p>
                        </div>
                        <div class="card-body">
                            <div class="row mb-3">
                                <div class="col-6 pr-0">
                                    <p class="fs14 txtseconday">Net Goods Value:</p>
                                </div>
                                <div class="col-sm-6 pl-0 text-right">
                                    <label class="fs14 txtdefault">{{ netamount }}</label>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-6 pr-0">
                                    <p class="fs14 txtseconday">GST</p>
                                </div>
                                <div class="col-6 pl-0 text-right">
                                    <label class="fs14 txtdefault">{{ totalgst }}</label>
                                </div>
                            </div>
                            <div class="row mb-3 ecaretarrow">
                                <div class="col-6 pr-0  " ngbDropdown>
                                    <p class="fs14 txtactive" id="dropdownBasic1" ngbDropdownToggle>Other Charges
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.143" height="5.849"
                                            viewBox="0 0 10.143 5.849">
                                            <path id="Path_2277" data-name="Path 2277"
                                                d="M.9.155A.528.528,0,0,0,.155.9l3.92,3.92L.155,8.742A.528.528,0,0,0,.9,9.488L5.194,5.195a.527.527,0,0,0,0-.746Z"
                                                transform="translate(9.893 0.25) rotate(90)" fill="#ff7135"
                                                stroke="#ff7135" stroke-width="0.5" />
                                        </svg>
                                        <i placement="top" ngbTooltip="other charges include shipping charges"><img
                                                src="assets/images/svg/info.svg" /></i>

                                    </p>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                        *ngIf="other_charges.length > 0">
                                        <button ngbDropdownItem *ngFor="let other of other_charges; let i = index;">{{
                                            other }} </button>
                                    </div>
                                </div>
                                <div class="col-6 pl-0 text-right">
                                    <label class="fs14  txtdefault">{{ otheramount }}</label>
                                </div>
                            </div>
                            <div class="pt-4 cs-sb-tl">
                                <div class="row mb-3">
                                    <div class="col-6 pr-0">
                                        <p class="fs12 txtdefault">Total goods value (with GST):</p>
                                    </div>
                                    <div class="col-6  pl-0 text-right">
                                        <p class="fs12 txtdefault">{{ total_goods_value }}</p>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-6 pr-0">
                                        <p class="fs14 txtdefault f-700">Total Order Value:</p>
                                    </div>
                                    <div class="col-6 pl-0 text-right f-700">
                                        <p class="fs14 txtdefault">{{ totalamount }}</p>
                                    </div>
                                </div>

                                <div class="row d-flex justify-content-center mb-3">
                                    <div class="col-md-12 text-center">
                                        <a href="javascript:;" class="link-alt fs12"
                                            (click)="openWindowCustomClass(content2)">View Detailed Price Breakup</a>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center pb-3">
                                <button class="buybtn d-block btn fs16 radius10" [routerLink]="['/checkout']">Checkout
                                    at {{ checkout_amount }}</button>
                            </div>

                        </div>
                    </div>
                    <div class="text-center pt-2">
                        <p class="fs14 txtseconday mb-3">Equibiz offers the payment methods below.</p>
                        <div class="payment-options crt-p-lst">
                            <ul>
                                <li><img src='assets/images/svg/mastercard.svg'></li>
                                <li><img src='assets/images/svg/visa.svg'></li>
                                <li><img src='assets/images/svg/americanbank.svg'></li>
                                <li><img src='assets/images/svg/paypal.svg'></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- /CART TOTAL -->
            </div>

            <div class="row d-flex justify-content-center" *ngIf="cart_records.length == 0">
                <div class="col-md-8">
                    <div class="text-center">
                        <img src="/assets/images/empty-cart.png">
                        <div class="mt-5 mb-1 fs18 f-700">Your cart is empty!</div>
                        <div class="mb-3 fs14 f-600">Add items to it now.</div>
                        <div class="text-center mt-1">
                            <button class="buybtn btn fs16 radius10" [routerLink]="['/home']">Show Now</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- PRODUCT -->
            <div class="products" *ngIf="related_products.length > 0">
                <div class="title1 pt-4 mb-2 mt-4">
                    <h2 class="title-inner1">Similar Items Related To Your Cart</h2>
                </div>
                <owl-carousel-o class="product-m" [options]="customOptions">
                    <ng-template carouselSlide *ngFor="let product of related_products; let i = index;">
                        <div class="product-item">
                            <div class="ex-deal" style="display:block" *ngIf="product.total_bid > 0"><span>{{
                                product.total_bid }} {{ product.total_bid > 1 ?'Bids': 'Bid' }}</span></div>
                            <div class='image'>
                                <div class="d-table w-100">
                                    <div class="d-table-cell">
                                        <a [routerLink]="['/product/details/'+product.unique_id]"><img
                                                src='{{ product.p_front_image }}'></a>
                                    </div>
                                </div>
                            </div>
                            <div class="product-name">
                                <a [routerLink]="['/product/details/'+product.unique_id]" style="color: inherit;">{{
                                    product.name }}</a>
                            </div>
                            <div class="ratings">
                                <ngb-rating [max]="5" [(rate)]="product.star_rating" [readonly]="true" class="rating_star"></ngb-rating>
                                <span class='star-count'>({{ product.total_rating }})</span>
                            </div>
                            <div class="row no-gutters d-flex justify-content-between">
                                <div class="col">
                                    <div class="seller-count" *ngIf="product.total_seller > 0">
                                        Total Listing <span>{{ product.total_seller}} Sellers</span>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="price">
                                        Starting at <span><i class="fa fa-inr"></i> {{ product.sales_price }} <b
                                                class="strikethrough">{{ product.regular_price }}</b></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</div>

<!-- Order Total -->
<ng-template #content2 let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>
    <div class="modal-body pb-3">
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead text-center">
                    <h4 class="txtdefault f-700 fs16 mb-20">Detailed Price Breakup</h4>
                </div>

                <div class="inrcard p-3 radius10 position-relative mb-2"
                    *ngFor="let record of popupbox_records; let i = index;">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-4 card-price-info f-700">Product:</div>
                                <div class="col-md-8 card-price-info text-right f-700">
                                    <span class="mr-3">{{ record.product_name }}</span>
                                    <span class="pd-taglist">
                                        <span class="f-600 fs10 radius15">{{ record.map_type }}</span>
                                    </span>
                                </div>
                            </div>
                            <hr class="mt-1 mb-1">
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6 card-price-info">Payable Amount:</div>
                                <div class="col-md-6 card-price-info text-right">{{ record.pay_amount }} ({{
                                    record.pay_percentage }}%)</div>
                            </div>
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6 card-price-info">Pending Amount:</div>
                                <div class="col-md-6 card-price-info text-right">{{ record.pending_amount }} ({{
                                    record.pending_percentage }}%)</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="inrcard p-3 radius10 position-relative">
                    <div class="row d-flex justify-content-between">
                        <div class="col-md-6 card-price-info">Payable Amount:</div>
                        <div class="col-md-6 card-price-info text-right">{{ subtotal_amount }}</div>
                    </div>
                    <div class="row d-flex justify-content-between">
                        <div class="col-md-6 card-price-info">Other Charges:</div>
                        <div class="col-md-6 card-price-info text-right">{{ otheramount }}</div>
                    </div>
                    <hr class="mt-1 mb-1">
                    <div class="row d-flex justify-content-between">
                        <div class="col-md-6 f-700">Total Checkout Amount:</div>
                        <div class="col-md-6 text-right f-700">{{ checkout_amount }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- /UPLOAD INVOICE -->


<app-footer-one></app-footer-one>