<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
    <div class="mobile-fix-option"></div>
    <div class="">
      <div class="equibiz-header">
        <div class="logo-bar">
          <div class="container">
            <div class="row d-flex justify-content-between">
               <div class="col-md-3 d-flex align-items-center">
                  <a [routerLink]="['/home']"><img src="assets/images/svg/eq_logo.svg"></a>
                  <span class="separator"></span>
                  <a href="javascript:;">Help Center</a>
               </div>
               <div class="col-md-6">
                  <div class="input-group-custom">
                     <input class="form-control py-2 border-right-0 border" type="text" placeholder="Search Products, Brands and Categories" id="example-search-input">
                     <div class="input-group-append-custom">
                        <img src="assets/images/svg/search_logo.svg">
                     </div>
                  </div>
               </div>
               <div class="col-md-3">
                   <div class="text-right right-header">
                       <ul class="header-dropdown">
                        <li class="onhover-dropdown mobile-account menu-item topbar-menu">
                          Assistance
                          <ul class="onhover-show-div w-3">
                              <li><div class="header-title">Assistance</div></li>
                              <li><a herf="javascript:;">Delivery</a></li>
                              <li><a herf="javascript:;">Product & Stock</a></li>
                              <li><a herf="javascript:;">Collection</a></li>
                              <li><a href="javascript:;">Data Privacy</a></li>
                              <li><a href="javascript:;">Order Cancellation</a></li>
                              <li><a href="javascript:;">Payment & Promo</a></li>
                              <li><a href="javascript:;">Returns & Refunds</a></li>
                              <li><a href="javascript:;">Equibiz Account</a></li>
                              <li><a href="javascript:;" class="btn btn-custom">Help Center</a></li>
                          </ul>
                        </li>
                        <li class="menu-item"><a href="javascript:;" [routerLink]="['/admin/dashboard']">Go back to Equibiz</a></li>
                      </ul>
                   </div>
               </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  
  <section class="small-section bg-gray-lighter pt-10 pb-80">
      <div class="container">
          <div class="row">
              <div class="col-md-12">
                  <!-- BREADCRUMB -->
                  <nav class="mb-2 pt-2 pb-2" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                    <ol class="breadcrumb p-0 bg-transparent">
                      <li class="breadcrumb-item"><a href="javascript:void(0);" class="txtseconday">Equibiz Help Center</a></li>
                      <li class="breadcrumb-item"><a [routerLink]="['/admin/assistance']" class="txtseconday">Assistance Topics</a></li>
                      <li class="breadcrumb-item"><a class="txtseconday">Intro to Equibiz</a></li>
                    </ol>
                  </nav>
                  <!-- /BREADCRUMB -->
  
                  <!--Introduction to Equibiz-->
                  <div class="assistance-box">
                      <h2 class="section-title text-left font-alt mb-30 mt-0">{{ assistance_record.title }}</h2>
                      <div class="inner-content" [innerHtml]=" assistance_record.detail_desc">
                      </div>
                  </div>
  
              </div>
          </div>
      </div>
  </section>
  
  <app-footer-one></app-footer-one>