import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private router: Router,
    ) { 
        const gaScript = document.createElement('script');
        gaScript.innerHTML = `gtag('event', 'conversion', {'send_to': 'AW-10853447367/_mWcCOuVqbQDEMftqbco'});
        `;
        document.head.appendChild(gaScript);
    }

    ngOnInit(): void {
        setTimeout( () => {
           this.router.navigate(['/buyer/buyer-track-orders']);
        }, 4000);
    }

}
