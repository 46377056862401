import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { CmsTermsofUseService } from '../terms-of-use/terms-of-use.service';

@Component({
  selector: 'app-anti-counterfeiting-policy',
  templateUrl: './anti-counterfeiting-policy.component.html',
  styleUrls: ['./anti-counterfeiting-policy.component.scss']
})
export class CmsAntiCounterfeitingPolicyComponent implements OnInit {

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
        {
            name: 'quote',
            class: 'quote',
        },
        {
            name: 'redText',
            class: 'redText'
        },
        {
            name: 'titleText',
            class: 'titleText',
            tag: 'h1',
        },
    ],
    // uploadUrl: 'v1/image',
    // upload: (file: File) => { },
    // uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
        [],
        []
    ]
};

public anti_counterfeiting_content:string = '';
public buttonLoading:boolean = false;
public cms_type:string = 'anti_counterfeiting_policy';
constructor(
    private _cmsservice: CmsTermsofUseService,
    private _toastr: ToasterService
) { }

ngOnInit(): void {
    this.getcmsdata();
}

cmsupdate(){
    this.buttonLoading = true;
    this._cmsservice.savecms({type: this.cms_type, values: this.anti_counterfeiting_content }).subscribe((data) => {
        if (data['status'] == "success") {
            this.buttonLoading = false;
            this._toastr.success('CMS details has been updated successfully');
        } else if (data['status'] == "error") {
            this._toastr.error(data['message']);
        }
    });
}

getcmsdata() {
    this._cmsservice.getcms({type: this.cms_type}).subscribe((data) => {
        if (data['status'] == "success") {
            this.anti_counterfeiting_content = data['values'];
        } else if (data['status'] == "error") {
            this._toastr.error(data['message']);
        }
    });
}

}
