<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain pb-4">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center pinfo-head">
			<div class="col-md-6 mb-4">
				<h3 class="fs24 wt700 txtdefault">Logistic Partners List</h3>				
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">	
						<li class="breadcrumb-item"><a href="javascript:void(0);" class="txtseconday">Logistics Management </a></li>
						<li class="breadcrumb-item active">Logistic Partners List</li>
					</ol>
				</nav>
			</div>
			<div class="col-md-6  mb-4 text-md-right text-center">
				<a href="javascript:;" [routerLink]="['/admin/logistic-partners-list/add-new-partner']" class="mr-sm-4 mr-2 btn-d-outline d-inline-block"><svg xmlns="http://www.w3.org/2000/svg" width="14.681" height="14.681" viewBox="0 0 14.681 14.681" class="plus">
					  <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1 1)">
					    <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181" transform="translate(-11.659 -7.5)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					    <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181" transform="translate(-7.5 -11.659)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					  </g>
					</svg>
					Add New Partner
				</a>
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4 mb-0">
			<div class="notifications-container theme-tab">
				
				<div class="tab-content">
					<!--Filters-->
					<div class="row d-flex justify-content-between mt-0  report-cta">
						<div class="col-sm-5 mb-3">
							<div class="report-input">
								<div class="input-group-custom">
									<input type="text" placeholder="Search by Name" class="form-control">
									<div class="input-group-append-custom">
										<img src="assets/images/svg/search_logo.svg">
									</div>
								</div>
							</div>
						</div>
					</div>
					<!--End Filters-->
					<div class="table-responsive brndmgnttable">
						<table class="table custom-table xs">
							<thead>
								<tr>
									<th>Logistic Partner</th>
									<th>GST Number</th>
									<th>Last Updated <i class="long-arrow-down"></i></th>
									<th width="50%">Action</th>
								</tr>
							</thead>
							<tbody>
								<!-- TR -->
								<tr *ngFor="let record of partnerlist_records;">
									<td>
										<div class="d-flex align-items-center">
											<!-- <div class="mr-2"><img src="assets/images/icons/sellerinfo.svg"></div> -->
											<div>{{ record.name }}</div>
										</div>
									</td>
									<td>{{ record.gst }}</td>
									<td>{{ record.updated_date }}</td>
									<td class="d-flex align-items-center">
										<a *ngIf="record.pincode_path" target="_blank" href="{{ record.pincode_path }}" class="btn-d-outline ripple d-inline-block place-bid mr-3">
											<div class="d-flex align-items-center">
												<div class="mr-2"><img src="assets/images/icons/csv.png"></div>
												<div>PinCode Map</div>
											</div>
										</a>
										<a *ngIf="record.tracking_path" target="_blank" href="{{ record.tracking_path }}" class="btn-d-outline ripple d-inline-block place-bid mr-3">
											<div class="d-flex align-items-center">
												<div class="mr-2"><img src="assets/images/icons/csv.png"></div>
												<div>Tracking Services</div>
											</div>
										</a>
										<a href="javascript:void(0);" [routerLink]="['/admin/logistic-partners-list/edit-new-partner/'+record.id]" class="place-bid">
											<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14" height="14" viewBox="0 0 7.984 7.984">
											  <defs>
												<linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
												  <stop offset="0" stop-color="#79b7ff"/>
												  <stop offset="1" stop-color="#07f"/>
												</linearGradient>
											  </defs>
											  <path id="np_edit_3094244_000000" d="M6.485-.008A1.491,1.491,0,0,0,5.428.43L.41,5.447,2.527,7.563,7.544,2.546A1.5,1.5,0,0,0,6.485-.008ZM.151,5.893,0,7.706a.249.249,0,0,0,.269.269l1.812-.154Z" transform="translate(0.003 0.008)" fill="url(#linear-gradient)"/>
											</svg>
										</a>
										<span class="vdivider mr-3"></span>
										<a href="javascript:;" (click)="open(content, record)"><img src="assets/images/icons/delete-icon.svg"></a>
									</td>
								</tr>
								<!-- /TR -->

								<!--Empty State-->
								<tr *ngIf="!partnerlist_records.length">
									<td colspan="4">
										<div class="row pt-40 pb-40">
											<div class="col-md-12 text-center">
												<div class="mb-20"><img src="/assets/images/empty-state.png"></div>
												<div class="fs16">There are no records to display!</div>
											</div>
										</div>
									</td>
								</tr>
								<!--End Empty State-->

							</tbody>
						</table>
					</div>	
				</div>		               
			</div>
		</div>
		<div class="row mb-40" *ngIf="partnerlist_pagination.totalPages> 1">
			<div class="col-md-6">
				<div class="text-left pl-3">
					<nav class="mt-3 tbpg rows-per-page">
						<div>Rows per page:</div>
						<select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
							<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">{{option.name}}</option>
						</select>
					</nav>
				</div> 
			</div>
			<div class="col-md-6" *ngIf="partnerlist_pagination.pages && partnerlist_pagination.pages.length">
				<div class="text-right pr-3">
					<nav class="mt-3 tbpg">
					  <ul class="pagination align-items-center">
						<li class="page-item" [ngClass]="{disabled:partnerlist_pagination.currentPage === 1}"><a class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a></li>

						<li class="page-item" [ngClass]="{disabled:partnerlist_pagination.currentPage === 1}"><a class="page-link sprevicon" href="javascript:;" (click)="setpage(partnerlist_pagination.currentPage - 1)"></a></li>

						<li class="page-item"i *ngFor="let page of partnerlist_pagination.pages" [ngClass]="{active:partnerlist_pagination.currentPage === page}"><a class="page-link" href="javascript:;" (click)="setpage(page)">{{page}}</a></li>

						<li *ngIf="(partnerlist_pagination.totalPages - partnerlist_pagination.startPage) > 5 ">
							<a (click)="setpage(partnerlist_pagination.startPage + 5)">...</a>
						</li>
						 <li *ngIf="(partnerlist_pagination.totalPages - partnerlist_pagination.startPage) > 5" >
							<a (click)="setpage(partnerlist_pagination.totalPages)">{{partnerlist_pagination.totalPages}}</a>
						</li>
						
						<li class="page-item" [ngClass]="{disabled:partnerlist_pagination.currentPage === partnerlist_pagination.totalPages}"><a class="page-link snexticon" href="javascript:;"  (click)="setpage(partnerlist_pagination.currentPage + 1)"></a></li>
						<li class="page-item" [ngClass]="{disabled:partnerlist_pagination.currentPage === partnerlist_pagination.totalPages}"><a class="page-link nexticon" href="javascript:;" (click)="setpage(partnerlist_pagination.totalPages)"></a></li>
					  </ul>
					</nav>
				</div> 
			</div>
		</div> 
	</div>	

	<!-- VERIFY EMAIL -->
<ng-template #content let-modal>
	<div class="modal-body text-center mdlcmnui pt-4 mt-3">
        <h5 class="modal-title mb-20">Remove Logistic Partner</h5>
        <div class="bid-order-info">
			<div class="order-img">
				<img src="assets/images/icons/info.png" />
			</div>
			<div class="pd-seller-cntnt">
				<div class="fs12">By removing the logistic partner, the orders may get affected. </div>
			</div>
		</div>

        <div class="modal-subtitle text-center pt-30 pb-30">Are you sure you want to remove the {{ selected_record.name }} Logistic partner?</div>

        <!-- <div class="fs16">Currently transiting: <b>238 orders</b></div> -->

        <div class="text-center pt-30 pb-20">
           <button type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')" class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4">
		      Cancel
		   	</button>
		   	<button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4" (click)="deletepartner()">
		      Remove
		   	</button>
        </div>
      </div>
</ng-template>
