import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ManageBuyerService {

  constructor(private _http: HttpClient) { }

  managebuyer(data) {
    return this._http.post(environment.api_url+'admin/buyerrecords/verified',data);
  }

  registeredbuyer(data) {
      return this._http.post(environment.api_url+'admin/buyerrecords/unverified',data);
  }

    updatestatus(data) {
        return this._http.post(environment.api_url+'admin/user/updatestatus',data);
    }

}
