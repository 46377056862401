import { Component, OnInit } from '@angular/core';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { ManageSubsellerService } from './manage-subseller.service';
import { SignupService } from 'src/app/shared/components/modal/signup-modal/signup-modal.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-manage-subseller',
    templateUrl: './manage-subseller.component.html',
    styleUrls: ['./manage-subseller.component.scss']
})
export class ManageSubsellerComponent implements OnInit {

    public search_term:string = '';
    public tab_type:string = 'approved';

    public candfseller_records: any = [];
    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 20;
    public candfseller_pagination: any = {};

    public candfrequest_records: any = [];
    public rcurrent_page: number = 1;
    public roffset: number = 0;
    public rpage_per_view: number = 20;
    public candfrequest_pagination: any = {};

    public page_per_view_options = [
        {
            value: 20,
            name: 20
        },
        {
            value: 40,
            name: 40
        }
    ];
    public seller_type:string;

    constructor(
        private _managecadnfservice: ManageSubsellerService,
        private _toastr: ToasterService,
        private _pagination: PaginationService,
        private _signupservice: SignupService,
        private _router: ActivatedRoute,
        private _route: Router
    ) { }

    ngOnInit(): void {
        this.getcandfsellers();
        this.seller_type = this._signupservice.getsellertype();
        if(this.seller_type != 'super'){
            this._route.navigate(['/seller/dashboard']);
        }
    }

    changetab(type){
        this.search_term = '';
        this.tab_type = type;
        if(type == 'approved'){
            this.getcandfsellers();
        }else if(type == 'pending'){
            this.getcandfrequest();
        }
    }

    filtersearch(){
        if(this.tab_type == 'approved'){
            this.getcandfsellers();
        }else if(this.tab_type == 'pending'){
            this.getcandfrequest();
        }
    }
    

    getcandfrequest() {
        this._managecadnfservice.getcandfrequest({ search: this.search_term, limit: this.rpage_per_view, offset: this.roffset, current_page: this.rcurrent_page }).subscribe((data) => {
            if (data['status'] == "success") {
                this.candfrequest_records = data['records'];
                let total_records = data['total_records'];
                this.candfrequest_pagination = this._pagination.getPager(total_records, this.rcurrent_page, this.rpage_per_view);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getcandfsellers() {
        this._managecadnfservice.getcandfsellers({ search: this.search_term, limit: this.page_per_view, offset: this.offset, current_page: this.current_page }).subscribe((data) => {
            if (data['status'] == "success") {
                this.candfseller_records = data['records'];
                let total_records = data['total_records'];
                this.candfseller_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.getcandfsellers();
    }

    setpageperview() {
        this.current_page = 1;
        this.getcandfsellers();
    }

    setrpage(page) {
        this.rcurrent_page = page;
        this.roffset = (page - 1) * this.rpage_per_view;
        this.getcandfrequest();
    }

    setrpageperview() {
        this.rcurrent_page = 1;
        this.getcandfrequest();
    }

}
