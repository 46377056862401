<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain tpslgbx pb-4">
	<div class="container ">
		<div class="row align-items-center">
			<div class="col-md-6 ">
				<!-- BREADCRUMB -->
				<nav class=" pt-2 pb-2" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
				  <ol class="breadcrumb p-0 bg-transparent">
					<li class="breadcrumb-item"><a href="javascript:void(0);" class="txtseconday">Order Management  </a></li>		
					<li class="breadcrumb-item"><a href="javascript:void(0);" class="txtseconday"> Orders </a></li>	
					<li class="breadcrumb-item active" aria-current="page">Duplicate Order</li>
				  </ol>		
				</nav>
				<!-- /BREADCRUMB -->
			</div>
			<div class="col-md-6 mb-4 text-right">
				<a href="javascript:void(0);" class="btn px-4 buybtn fs16 radius10 ml-sm-3 ml-2">Assistance</a>
			</div>
		</div>
		<div class="row align-items-center adprotop">
			<div class="col-md-12 mb-4">
				<h4 class="wt700 mb-0 txtdefault fs22">
				<a href="javascript:void();" class="go-back"><i class="fa fa-angle-left"></i></a>
				Duplicate Order</h4>
			</div>
			
		</div>
		<!-- CONTENT -->
		<div class="row"> 
			<div class="col-xl-8 mb-4">
				<!-- CARD -->
				<div class="card card-ui mb-4">
					<div class="row mb-4 align-items-center">
						<div class="col-md-8">
							<div>
								<label class="fs16 txtdefault align-middle wt700 mb-0 mr-2">Order #EQB9876598754ABCD</label>
								<span class="d-inline-block align-middle w100">
									<ng-selectize [config]="config" [options] = "data" placeholder="Bulk">		</ng-selectize>
								</span>
							</div>
							<div class="mbl-top-sp">
								<span class="d-inline-block w-100 mx160 align-middle mr-2">
									<ng-selectize [config]="config" [options] = "data" placeholder="Wallet Payment">		</ng-selectize>
								</span>
								<label class="fs16 txtseconday align-middle wt500">on 27/08/2021, 06:20:25 pm <img class="dateicn" src="assets/images/admin/date.png" /></label>
							</div>
						</div>
						<div class="col-md-4 text-md-right mbl-top-sp">
							<div>
								<label class="fs16 txtdefault wt700">Order Status:</label>
								<span class="text-left">
									<ng-selectize [config]="config" [options] = "data" placeholder="Delivered">		</ng-selectize>
								</span>
							</div>
						</div>
					</div>
					<!-- ORDER TOP -->
					<div class="ordertop mb-4 pb-3">
						<div class="row">
							<div class="col-sm-4">
								<label class="wt500 txtdefault mb-1">Order ID:</label>
								<p class="wt500 txtseconday mb-0">EB2021061548772</p>
							</div>
							<div class="col-sm-3">
								<label class="wt500 txtdefault mb-1">Order Date:</label>
								<p class="wt500 txtseconday mb-0">15-12-2021 <img class="dateicn" src="assets/images/admin/date.png" /></p>
							</div>
							<div class="col-sm-3">
								<label class="wt500 txtdefault mb-1">Prebooked on:</label>
								<p class="wt500 txtseconday mb-0">12/12/2021 <img class="dateicn" src="assets/images/admin/date.png" /></p>
							</div>				
						</div>
					</div>
					<!-- /ORDER TOP -->
					<!-- BILLING INFO -->
					<div class="bl-card mb-4">
						<h3 class="fs16 wt700 txtdefault mb-4">Billing Details</h3>				
						<div class="inrcard p-md-4 p-3 radius10 position-relative">
							<a class="edit-icon blicon" href="javascript:void(0)"></a>
							<div class="mb-4">
								<label class="wt500 txtdefault mb-1">Address:</label>
								<p class="wt500 txtseconday mb-0">A 609, Express Zone, Off Western Express Highway, Goregaon East, Mumbai-400063</p>
							</div>
							<div class="mb-4">
								<label class="wt500 txtdefault mb-1">Email:</label>
								<p class="wt500 txtseconday mb-0">manoj@3minds.com</p>
							</div>
							<div >
								<label class="wt500 txtdefault mb-1">Mobile::</label>
								<p class="wt500 txtseconday mb-0">+91 9876543210</p>
							</div>
						</div>
					</div>
					<!-- /BILLING INFO -->
					<!-- BILLING INFO -->
					<div class="bl-card mb-4">
						<h3 class="fs16 wt700 txtdefault mb-4">Shipping Address</h3>				
						<div class="inrcard p-md-4 p-3 radius10 position-relative">
							<a class="edit-icon blicon" href="javascript:void(0)"></a>
							<div class="mb-4">
								<label class="wt500 txtdefault mb-1">Address:</label>
								<p class="wt500 txtseconday mb-0">A 609, Express Zone, Off Western Express Highway, Goregaon East, Mumbai-400063</p>
							</div>
							<div class="mb-4">
								<label class="wt500 txtdefault mb-1">Email:</label>
								<p class="wt500 txtseconday mb-0">manoj@3minds.com</p>
							</div>
							<div >
								<label class="wt500 txtdefault mb-1">Mobile::</label>
								<p class="wt500 txtseconday mb-0">+91 9876543210</p>
							</div>
						</div>
					</div>
					<!-- /BILLING INFO -->
					<div class="row">
						<div class="col-sm-5 mbl-top-sp">
							<ng-selectize [config]="config" [options] = "data" placeholder="Logistic Preference">		</ng-selectize>	
						</div>
						<div class="col-sm-7 mbl-top-sp">
							<ng-selectize [config]="config" [options] = "data" placeholder="Payment Preference">		</ng-selectize>	
						</div>
					</div>
				</div>
				<!-- /CARD -->
				<!-- CARD -->
				<div class="card card-ui dc-pinfo">
					<!-- REPEAT -->
					<div class="cs-item-row">
						<div class="row">
							<div class="col-lg-7 pr-lg-0">
								<h4 class="fs16 f-700 mb-sm-4 mb-3 txtdefault">Product Info:</h4>
								<div class="d-table mb-4 w-100">
									<div class="d-table-cell cart-thumb align-middle">
										<img src="assets/images/products/p-2.jpg" />
									</div>
									<div class="d-table-cell pl-3 align-middle">
										<p class="txtdefault wt700 mb-0 fs14 lnht20">Vivo X60 Pro (Midnight Black, 12GB RAM, 256GB Storage) | Upto 12 Months No Cost EMI | Extra 4000 Off on Exchange
										<a href="javascript:void(0)" class="edit-icon blicon position-static"></a>
										</p>
									</div>
								</div>
							</div>
							
							
							<div class="col-lg-3 col-6 pl-md-0 px-0 text-center">
								<h4 class="fs14 txtdefault mb-lg-2 pb-2">Order quantity</h4>
								<div class="cs-odqty mb-3">
									<div class="input-group">
										<span class="input-group-btn">
										<button type="button" class="quantity-left-minus btn " >
											-
										</button>
										</span>
										<input type="text" id="quantity" name="quantity" class="form-control input-number" value="10" min="1" max="100">
										<span class="input-group-btn">
											<button type="button" class="quantity-right-plus btn text-success" >
												+
											</button>
										</span>
									</div>
								</div>	
							</div>
							<div class="col-lg-2  col-6 px-0 text-center">
								<h4 class="fs14 txtdefault px-0 mb-lg-4 mb-0 pb-2">Price Per Unit</h4>
								<p class="fs14 txtseconday pt-3">₹ 10,000</p>
							</div>
						</div>						
				  </div>
				  <!-- /REPEAT -->
					<!-- CARD TOTAL -->
					<div class="row pt-4">
						<div class="col-md-5 pr-md-0 mb-4">
							<div class="inrcard p-3  radius10 position-relative">
								<div class="row">
									<div class="col-6 px-md-0 text-md-right">
										<p class="txtseconday mb-2">10% Amount:</p>
									</div>
									<div class="col-6 pr-md-0 text-md-left text-right">
										<p class="txtseconday mb-2">₹ 56,490.00</p>
									</div>
								</div>
								<div class="row">
									<div class="col-6 px-md-0 text-md-right">
										<p class="txtseconday mb-2">90% Amount:</p>
									</div>
									<div class="col-6 pr-md-0 text-md-left text-right">
										<p class="txtseconday mb-2">₹ 4,50,000.00</p>
									</div>
								</div>
								<div class="row">
									<div class="col-6 px-md-0 text-md-right">
										<p class="txtseconday mb-2">Total Amount:</p>
									</div>
									<div class="col-6 pr-md-0 text-md-left text-right">
										<p class="txtseconday mb-2">₹ 5,06,490.00</p>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-7">
							<!-- ROW -->
							<div class="row">
								<div class="col-sm-9 text-right pr-md-0">
									<p class="txtseconday mb-3">Buyer Convenience Charge (with GST):</p>
								</div>
								<div class="col-sm-3 pl-0 text-right">
									<label class="txtdefault mb-3">₹ 120</label>
								</div>
							</div>
							<!-- /ROW -->
							<!-- ROW -->
							<div class="row">
								<div class="col-sm-9 text-right pr-md-0">
									<p class="txtseconday mb-3">Seller Convenience Charge (with GST):</p>
								</div>
								<div class="col-sm-3  pl-0  text-right">
									<label class="txtdefault mb-3">₹ 120</label>
								</div>
							</div>
							<!-- /ROW -->
							<!-- ROW -->
							<div class="row">
								<div class="col-sm-9 text-right pr-md-0">
									<p class="txtseconday mb-3">Buyer Logistics Charges :</p>
								</div>
								<div class="col-sm-3 pl-0 text-right">
									<label class="txtdefault mb-3">₹ 520</label>
								</div>
							</div>
							<!-- /ROW -->
							<!-- ROW -->
							<div class="row">
								<div class="col-sm-9 text-right pr-md-0">
									<p class="txtseconday mb-3">Seller Logistics Charges :</p>
								</div>
								<div class="col-sm-3 pl-0  text-right">
									<label class="txtdefault mb-3">₹ 0</label>
								</div>
							</div>
							<!-- /ROW -->
							<!-- ROW -->
							<div class="row">
								<div class="col-sm-9 text-right pr-md-0">
									<p class="txtseconday mb-3">Buyer Insurance Charges:</p>
								</div>
								<div class="col-sm-3 pl-0  text-right">
									<label class="txtdefault mb-3">₹ 325</label>
								</div>
							</div>
							<!-- /ROW -->
							<!-- ROW -->
							<div class="row">
								<div class="col-sm-9 text-right pr-md-0">
									<p class="txtseconday mb-3">Total Goods Value (without GST):</p>
								</div>
								<div class="col-sm-3  pl-0 text-right">
									<label class="txtdefault mb-3">₹ 5,06,490</label>
								</div>
							</div>
							<!-- /ROW -->
							<!-- ROW -->
							<div class="row">
								<div class="col-sm-9 text-right pr-md-0">
									<p class="txtseconday mb-3">GST:</p>
								</div>
								<div class="col-sm-3 pl-0  text-right">
									<label class="txtdefault mb-3">₹ 91,168</label>
								</div>
							</div>
							<!-- /ROW -->
							<!-- ROW -->
							<div class="row">
								<div class="col-sm-9 text-right pr-md-0">
									<p class="txtseconday mb-3">Total Order Value:</p>
								</div>
								<div class="col-sm-3 pl-0 text-right">
									<label class="txtdefault mb-3">₹ 5,06,490</label>
								</div>
							</div>
							<!-- /ROW -->
						</div>
					</div>
					<!-- /CARD TOTAL -->
				</div>
				<!-- /CARD -->
			</div>
			<div class="col-xl-4">
				<div class="accordionarrow faqpanel doc-od">
					<ngb-accordion #acc="ngbAccordion">
					  <ngb-panel id="toggle-1" title="Logistic Management">
						<ng-template ngbPanelContent>
							<div class="mb-4 pb-2">
								<ng-selectize [config]="config" [options] = "data" placeholder="Shipping Company"></ng-selectize>
							</div>
							<div class="mb-4">
								<label class="fs14 txtdefault mb-1">Tracking Number:</label>
								<p class="fs14 txtseconday mb-0">EB2021061548772</p>
							</div>
							<a href="javascript:void(0);" class="btn-d-outline d-inline-block">Update</a>
						</ng-template>
					  </ngb-panel>
					  <ngb-panel id="toggle-2" title="Order Logs">
						<ng-template ngbPanelContent>
							<div class="odlgrwrpt">
								<div class="odlgrw">
									<label class="fs14 txtdefault mb-1">Created New Order</label>
									<p class="fs14 txtseconday mb-0">27/08/2021, 12:01:05pm</p>
								</div>
								<a href="javascript:void(0);" class="link-alt d-inline-block mt-3">View More</a>
							</div>
						</ng-template>
					  </ngb-panel>
					  <ngb-panel id="toggle-3" title="Order Notes">
						<ng-template ngbPanelContent>
							<div class="odchatnotes">
								<p class="mb-0 txtdefault">You can add manual Order notes here for optimum order management</p>
							</div>
							<div class="adnts">
								 <input placeholder="Add your note here"  class="form-control"  >
								 <button class="btn"></button>
							</div>
						</ng-template>
					  </ngb-panel>
					</ngb-accordion>
			</div>
		</div>
	
		<!-- /CONTENT -->
	</div>
</div>		