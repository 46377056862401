import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AdminChargesInvoiceService {

  constructor(private _http: HttpClient) { }

  invoicerecords(data) {
    return this._http.post(environment.api_url+'admin/report/chargeinvoice',data);
  }

  buyerchargeinvoicegenerate(data) {
    return this._http.post(environment.api_url+'admin/buyer/chargeinvoice/generate',data);
  }

  sellerchargeinvoicegenerate(data) {
    return this._http.post(environment.api_url+'admin/seller/chargeinvoice/generate',data);
  }

  subsellerchargeinvoicegenerate(data) {
    return this._http.post(environment.api_url+'admin/subseller/chargeinvoice/generate',data);
  }

}
