<div class="row mb-3 ">
		<div class="col-sm-12">
			<div class="sortselect" [ngClass]="ratingst ? 'showsort' : ''">
				<div class="fltrshead srthead"> 
					<h4>Sort By</h4> 
					<a  (click)="ratingsort()"  class="closefilter" href="javascript:void(0);"><svg _ngcontent-serverApp-c132="" xmlns="http://www.w3.org/2000/svg" width="6.508" height="6.508" viewBox="0 0 6.508 6.508"><path _ngcontent-serverApp-c132="" id="np_cross_1707162_000000" d="M30.883,30.883a.507.507,0,0,1,.717,0l2.389,2.389,2.389-2.389a.507.507,0,0,1,.717.717L34.7,33.988l2.389,2.389a.507.507,0,0,1-.717.717L33.988,34.7,31.6,37.094a.507.507,0,0,1-.717-.717l2.389-2.389L30.883,31.6A.507.507,0,0,1,30.883,30.883Z" transform="translate(-30.734 -30.734)" fill="#1f1d44"></path></svg></a>
				</div>
				<select class="form-control  cs-form-control text-capitalize" [(ngModel)]="sort_by_rating" (change)="ratingsort()">
					<option value="date_asc">Sort By Date (ASC)</option>
					<option value="date_desc">Sort By Date (DESC)</option>
					<option value="rating_asc">Sort By Rating (ASC)</option>
					<option value="rating_desc">Sort By Rating (DESC)</option>
				</select>
			</div>
		</div>
	</div>
	<div class="ovlrating hideinmd">
		<h4 class="fs14 f-600 mb-2">Overall Rating</h4>
		<div class="ratingcal">
			<div class="ratings star-ratings pr-1 d-inline-block">
				<ngb-rating [max]="5" [(rate)]="star_count" [readonly]="true" class="rating_star"></ngb-rating>
			</div>
			<span class="fs16 weight700 txtdefault">{{ star_count }}</span> out of <span class="fs16 weight700 txtdefault">5</span><br />
			<i class="fs12 txtseconday pt-2 ">({{ rating_total_records }} ratings)</i>
			<div class="fltsortnv mt-0">
				<a href="javascript:void(0);" (click)="ratingsort()"  class="sorticon mr-2"> 
					<img src="assets/images/svg/sort.svg">
				</a> 
				<a href="javascript:void(0);" (click)="ratingtglclass()"  class="filtericon">
					<img src="assets/images/svg/filter.svg">
				</a> 
			</div>
		</div>
	</div>
	
	<div class="row">
		<div class="col-lg-3 filtercol lsting rating-filter filtermbl" [ngClass]="ratingclassstatus ? 'showfilter' : ''">
			<div class="fltrshead"> 
				<h4>Filter</h4> 
				<a  (click)="ratingtglclass()"  class="closefilter" href="javascript:void(0);"><svg _ngcontent-serverApp-c132="" xmlns="http://www.w3.org/2000/svg" width="6.508" height="6.508" viewBox="0 0 6.508 6.508"><path _ngcontent-serverApp-c132="" id="np_cross_1707162_000000" d="M30.883,30.883a.507.507,0,0,1,.717,0l2.389,2.389,2.389-2.389a.507.507,0,0,1,.717.717L34.7,33.988l2.389,2.389a.507.507,0,0,1-.717.717L33.988,34.7,31.6,37.094a.507.507,0,0,1-.717-.717l2.389-2.389L30.883,31.6A.507.507,0,0,1,30.883,30.883Z" transform="translate(-30.734 -30.734)" fill="#1f1d44"></path></svg></a>
			</div>
			<div class="ovlrating">
				<h4 class="fs14 f-600 mb-2">Overall Rating</h4>
				<div class="ratingcal">
					<div class="ratings star-ratings pr-1 d-inline-block">
						<ngb-rating [max]="5" [(rate)]="star_count" [readonly]="true" class="rating_star"></ngb-rating>
					</div>
					<span class="fs16 f-700 txtdefault">{{ star_count }}</span> out of <span class="fs16 f-700 txtdefault">5</span><br />
					<i class="fs12 txtseconday pt-2 ">({{ rating_total_records }} ratings)</i>
					
				</div>
			</div>
			<ngb-accordion #acc="ngbAccordion" activeIds="ratingfilter, toggle-2, toggle-3, brand, startingprice, ram, internalstorage, screensize, activationstatus, supplylocation, countryorigin" class="accordion">
				<ngb-panel id="ratingfilter" opened class="bg-transparent" title="Filter By Ratings">
					<ng-template ngbPanelContent >
						<ul class="list-group">
							<li class="list-group-item">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" (change)="updatereviewfilters(5)"/>
									<label class="css-label">
										<span class="fs16 txtseconday mr-2">5</span>
										<span class="ratings star-ratings pr-1 d-inline-block">
										<i class="fa fa-star" aria-hidden="true"></i>
										<i class="fa fa-star" aria-hidden="true"></i>
										<i class="fa fa-star" aria-hidden="true"></i>
										<i class="fa fa-star" aria-hidden="true"></i>
										<i class="fa fa-star" aria-hidden="true"></i>
									</span>
									</label>
								</div>								
							</li>
							<li class="list-group-item">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" (change)="updatereviewfilters(4)"/>
									<label class="css-label">
									<span class="fs16 txtseconday mr-2">4 and above</span>
									<span class="ratings star-ratings pr-1 d-inline-block">
									<i class="fa fa-star" aria-hidden="true"></i>
									<i class="fa fa-star" aria-hidden="true"></i>
									<i class="fa fa-star" aria-hidden="true"></i>
									<i class="fa fa-star" aria-hidden="true"></i>
									<i class="fa fa-star-o" aria-hidden="true"></i>
								</span>
								</label>
								</div>								
							</li>
							<li class="list-group-item">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" (change)="updatereviewfilters(3)"/>
									<label class="css-label">
									<span class="fs16 txtseconday mr-2">3 and above</span>
									<span class="ratings star-ratings pr-1 d-inline-block">
									<i class="fa fa-star" aria-hidden="true"></i>
									<i class="fa fa-star" aria-hidden="true"></i>
									<i class="fa fa-star" aria-hidden="true"></i>
									<i class="fa fa-star-o" aria-hidden="true"></i>
									<i class="fa fa-star-o" aria-hidden="true"></i>
								</span>
								</label>
								</div>								
							</li>
							<li class="list-group-item">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" (change)="updatereviewfilters(2)"/>
									<label class="css-label">
									<span class="fs16 txtseconday mr-2">2 and above</span>
									<span class="ratings star-ratings pr-1 d-inline-block">
									<i class="fa fa-star" aria-hidden="true"></i>
									<i class="fa fa-star" aria-hidden="true"></i>
									<i class="fa fa-star-o" aria-hidden="true"></i>
									<i class="fa fa-star-o" aria-hidden="true"></i>
									<i class="fa fa-star-o" aria-hidden="true"></i>
								</span>
								</label>
								</div>								
							</li>
							<li class="list-group-item">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" (change)="updatereviewfilters(1)"/>
									<label class="css-label">
									<span class="fs16 txtseconday mr-2">1 and above</span>
									<span class="ratings star-ratings pr-1 d-inline-block">
									<i class="fa fa-star" aria-hidden="true"></i>
									<i class="fa fa-star-o" aria-hidden="true"></i>
									<i class="fa fa-star-o" aria-hidden="true"></i>
									<i class="fa fa-star-o" aria-hidden="true"></i>
									<i class="fa fa-star-o" aria-hidden="true"></i>
								</span>
								</label>
								</div>								
							</li>
						</ul>
					</ng-template>
				</ngb-panel>
				<!-- <ngb-panel id="toggle-2" title="Filter By Ratings">
					<ng-template ngbPanelContent>
						<ul class="list-group">
							<li class="list-group-item">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" />
									<label class="css-label">All</label>
								</div>								
							</li>
							<li class="list-group-item">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" />
									<label class="css-label">Only Images</label>
								</div>
							</li>
							<li class="list-group-item">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" />
									<label class="css-label">Only Videos</label>
								</div>
							</li>
						</ul>
					</ng-template>
				</ngb-panel> -->
			</ngb-accordion>
			<div class="tex-center clr-apl-btn showintablet">			
				<a href="javascript:void(0);" class="btn-d-outline mr-3 d-inline-block place-bid">Clear All</a>
				<a href="javascript:void(0);" class="buybtn d-inline-block radius10">Apply</a>
			</div>
		</div>
		<div class="col-lg-9 listing-content rating-content pl-lg-4">
			<div class="card p-3 px-2 pt-0 radius15">
				<div class="card-body pt-0">
					<div class="rating-table-responsive"> 
						<div class="rating-table">
							<!-- THEAD -->
							<div class="row rating-head py-4 align-items-center rating-bd ">
								<div class="col-sm-2">
									<p class="txtdefault mb-0">User’s Rating</p>
								</div>
								<div class="col-sm-2">
									<p class="txtdefault mb-0">Date of Review</p>
								</div>
								<div class="col-sm-6">
									<p class="txtdefault mb-0">User’s Review</p>
								</div>
								<div class="col-sm-2">
									<p class="txtdefault mb-0">Is User Satisfied?</p>
								</div>
							</div>
							<!-- /THEAD -->
							<div class="col-md-12 text-center" *ngIf="!review_records.length">
								<div class="mb-20"><img src="/assets/images/empty-state.png">
								</div>
								<div class="fs14">Records not available.</div>
							</div>
							<!-- REPEAT -->
							<div class="row py-4 align-items-center rating-bd" *ngFor="let rec of review_records;">
								<div class="col-lg-2">
									<div class="ratings star-ratings">
										<ngb-rating [max]="5" [(rate)]="rec.rating" [readonly]="true" class="rating_star"></ngb-rating>
									</div>
								</div>
								<div class="col-lg-2 col-6 dtrt md-ord4">
									<p class="txtseconday mb-0">{{ rec.review_date }}</p>
								</div>
								<div class="col-lg-6 rtdescp"> 
									<p class="txtseconday mb-0 lnht20">{{ rec.review }}</p>
								</div>
								<div class="col-lg-2 col-6">
									<label class="satisfiedusr fs14 f-600 txtdefault">Is User Satisfied?</label>
									<p class="txtseconday mb-0">{{ rec.satisfied }}</p>
								</div>
							</div>
							<!-- /REPEAT -->
							<!-- <a href="javascript:void(0)" class="add-whislist-link mt-4 showintablet"><span>View More</span></a> -->
						</div>
					</div> 								
				</div>
			  </div> 
			<div class="row mb-40" *ngIf="review_pagination.totalPages> 1">
				<div class="col-md-6">
					<div class="text-left pl-3">
						<nav class="mt-3 tbpg rows-per-page">
							<div>Rows per page:</div>
							<select class="select-box" [(ngModel)]="page_per_view"
								(change)="setpageperview()">
								<option *ngFor="let option of page_per_view_options"
									[ngValue]="option.value">
									{{option.name}}</option>
							</select>
						</nav>
					</div>
				</div>
				<div class="col-md-6">
					<div class="text-right pr-3">
						<nav class="mt-3 tbpg">
							<ul class="pagination align-items-center">
								<li class="page-item"
									[ngClass]="{disabled:review_pagination.currentPage === 1}"><a
										class="page-link previcon" href="javascript:;"
										(click)="setpage(1)"></a>
								</li>
	
								<li class="page-item"
									[ngClass]="{disabled:review_pagination.currentPage === 1}"><a
										class="page-link sprevicon" href="javascript:;"
										(click)="setpage(review_pagination.currentPage - 1)"></a></li>
	
								<li class="page-item" i *ngFor="let page of review_pagination.pages"
									[ngClass]="{active:review_pagination.currentPage === page}"><a
										class="page-link" href="javascript:;"
										(click)="setpage(page)">{{page}}</a></li>
	
								<li
									*ngIf="(review_pagination.totalPages - review_pagination.startPage) > 5 ">
									<a (click)="setpage(review_pagination.startPage + 5)">...</a>
								</li>
								<li
									*ngIf="(review_pagination.totalPages - review_pagination.startPage) > 5">
									<a
										(click)="setpage(review_pagination.totalPages)">{{review_pagination.totalPages}}</a>
								</li>
	
								<li class="page-item"
									[ngClass]="{disabled:review_pagination.currentPage === review_pagination.totalPages}">
									<a class="page-link snexticon" href="javascript:;"
										(click)="setpage(review_pagination.currentPage + 1)"></a>
								</li>
								<li class="page-item"
									[ngClass]="{disabled:review_pagination.currentPage === review_pagination.totalPages}">
									<a class="page-link nexticon" href="javascript:;"
										(click)="setpage(review_pagination.totalPages)"></a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
			</div>
		</div>
	</div>