<app-header-one [sticky]="true"></app-header-one>
<div class="container pb-5">
    <!-- BREADCRUMB -->
    <nav class="mb-2 pt-2 pb-2" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
        <ol class="breadcrumb p-0 bg-transparent">
            <li class="breadcrumb-item"><a href="javascript:void(0);" class="txtseconday" [routerLink]="['/home']">Home
                </a></li>
            <li class="breadcrumb-item" *ngFor="let cat of details.category_title; let i = index;">
                <a href="javascript:void(0);" class="txtseconday" [routerLink]="['/category/'+cat.slug]">{{ cat.name
                    }}
                </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">{{ details.name }}</li>
        </ol>
    </nav>
    <!-- /BREADCRUMB -->
    <!-- PRODUCT DESCRIPTION -->
    <div class="row mb-4 pdl-rw">
        <div class="col-lg-6 dtlitem flx45">
            <div class="feedback-slides mb-4  ">
                <div class="client-feedback dtlfull">
                    <ngx-slick-carousel class="carousel feedback" #slickModal="slick-carousel" [config]="imagesSlider">
                        <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                            <img src="{{ slide.img }}" alt="">
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>

            <div class="client-thumbnails dtlthmb text-center">
                <ngx-slick-carousel class="carousel thumbs" #slickModal="slick-carousel" [config]="thumbnailsSlider ">
                    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                        <div class="thmbnaildtl">
                            <img src="{{ slide.img }}" alt="" width="100%">
                        </div>
                    </div>
                </ngx-slick-carousel>
            </div>
        </div>
        <div class="col-lg-6 flx55">
            <div class="pdscp-top th-cmn-bd pb-1 mb-3">
                <h2 class="fs22 f-600 mb-2">{{ details.name }}
                    <span class="shareitem">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17.246" height="19.043"
                            viewBox="0 0 17.246 19.043">
                            <path id="Icon_ionic-md-share" data-name="Icon ionic-md-share"
                                d="M18.864,16.586a2.618,2.618,0,0,0-1.874.718l-6.87-3.971a3.217,3.217,0,0,0,.1-.67,3.216,3.216,0,0,0-.1-.67L16.894,8.07a2.875,2.875,0,1,0-.913-2.105,3.2,3.2,0,0,0,.1.67L9.3,10.558a2.892,2.892,0,0,0-1.97-.765A2.844,2.844,0,0,0,4.5,12.663a2.892,2.892,0,0,0,4.852,2.105l6.822,3.971a2.4,2.4,0,0,0-.1.622,2.786,2.786,0,1,0,2.786-2.775Z"
                                transform="translate(-4.5 -3.094)" fill="#1f1d44" />
                        </svg>
                    </span>
                </h2>
                <p class="fs13 f-600 txtdefault pt-2 mb-xs-0">Product Rating:<span class="f-800 dark-text mx-1">{{
                        details.product_rating }}</span>
                        <ngb-rating [max]="5" [(rate)]="details.product_rating" [readonly]="true" class="rating_star"></ngb-rating>
                </p>
            </div>
            <div class="pdscp-cntnt">
                <p class="fs14 f-600 txtdefault mb-2">Total Listing: <span class="f-800 dark-text">{{
                        details.total_seller}} Seller</span></p>
                <p class="fs14 f-600 txtdefault mb-3 mr-3  d-inline-block">Starting From: <span
                        class="f-800 ml-1 fs20 txtactive">{{ details.sale_price }}</span> 
                        <span *ngIf="!details.child" class=" fs16 txtdisable ml-1 crossprice">{{ details.regular_price }}</span></p>
                <p class="pricetag d-inline-block" *ngIf="details.offer_percentage"><i class="p-2 mr-2"></i><span
                        class="f-800 dark-text">{{details.offer_percentage}}% Off</span> on MRP</p>


                <div class="select-size" *ngFor="let variant of details.product_variant; let i = index;">
                    <div class="f-600" *ngIf="variant.name != 'Color'">{{ variant.name }}:</div>
                    <div class="f-600" *ngIf="variant.name == 'Color'">{{ variant.name }}: {{ variant.sname }}</div>
                    <div class="select-prod-size mb-30" *ngIf="variant.name != 'Color'">
                        <ul>
                            <li *ngFor="let meta of variant.meta; let k = index;">
                                <a href="javascript:;" [ngClass]="{'selected': meta.selected === true}"
                                    (click)="getselectedvariant(meta, variant)">{{ meta.variant_value }}</a>
                            </li>
                        </ul>
                    </div>

                    <div class="select-prod-color mb-30" *ngIf="variant.name == 'Color'">
                        <ul>
                            <li *ngFor="let meta of variant.meta; let k = index;">
                                <a href="javascript:;" [ngClass]="{'selected': meta.selected === true}"
                                    (click)="getselectedvariant(meta, variant)"><img src="{{ meta.image }}"
                                        alt="{{ meta.variant_value }}" title="{{ meta.variant_value }}"></a>
                            </li>
                        </ul>
                    </div>


                </div>
                <!-- <div class="select-color mt-30 mb-20">
					<div class="f-600">Color: Blue</div>
					
				</div> -->


                <div class="mb-3">
                    <a href="javascript:void(0);" class="mr-4 btn-d-outline d-inline-block"
                        (click)="focussellerlisting()">View Seller Listing</a>

                    <a href="javascript:void(0);" class="p-2 my-3 fs16 add-whislist-link" (click)="wishlist()" id="wishlistDiv">
                        <img src="/assets/images/icons/heart.svg" *ngIf="details.wishlist">&nbsp;
                        <svg *ngIf="!details.wishlist" xmlns="http://www.w3.org/2000/svg" width="19.035" height="16.303"
                            viewBox="0 0 19.035 16.303">
                            <path id="Path_2367" data-name="Path 2367"
                                d="M15.328,3.559A5.062,5.062,0,0,0,11.017,5.7,5.058,5.058,0,0,0,6.707,3.55,4.762,4.762,0,0,0,2,8.248c0,5.852,9.017,10.541,9.017,10.541S20.035,14.1,20.035,8.248A4.748,4.748,0,0,0,15.328,3.559Z"
                                transform="translate(-1.5 -3.05)" fill="none" stroke="#1f1d44" stroke-width="1" />
                        </svg>
                        <span class="fs16">{{ details.wishlist? "Remove from wishlist": "Add to Wishlist" }}</span>
                    </a>
                </div>
                <div class="keyfeat pt-2">
                    <h4 class="f-600 fs14">Key Features:</h4>
                    {{ details.key_features }}
                    <!-- <p class="txtdefault "><span class="txtseconday f-500">RAM:</span> ‎8 GB</p>
					<p class="txtdefault"><span class="txtseconday f-500">Connectivity technologies:</span> ‎WiFi 5g</p>
					<p class="txtdefault "><span class="txtseconday f-500">Special features:</span> ‎Camera Fingerprint Scanner; Front Camera; Dual_Sim; Face Unlock; LED Flash</p>
					<p class="txtdefault"><span class="txtseconday f-500">Battery Power Rating:</span> ‎4500</p>
					<p class="txtdefault "><span class="txtseconday f-500">Display technology:</span> ‎‎AMOLED</p>
					<p class="txtdefault"><span class="txtseconday f-500">Camera features:</span> ‎Triple Rear Camera (50 MP + 8 MP + 2 MP) | 32 MP Front Camera</p> -->
                </div>

                <!-- <div class="keyfeat pt-2" *ngIf="details.warranty.length > 0">
					<p class="txtdefault" *ngFor="let war of details.warranty;">
						<span class="txtseconday f-500">{{ war.key }}:</span>&nbsp;{{ war.value }}
					</p>
				</div> -->
            </div>
        </div>
    </div>
    <!-- /PRODUCT DESCRIPTION -->
    <!-- SELLER LISTING -->
    <div class="seller-listing mb-md-4 pb-3" id="viewSellerListing">
        <div class="seller-head row align-items-center mb-3">
            <div class="col-md-6">
                <h4 class="fs16 f-700 txtdefault mb-0">Seller Listing <span class="fontdefault f-500 fs14">({{
                        details.total_seller }} Sellers for {{ details.name }})</span></h4>
            </div>
            <div class="col-md-6 cmp-sort text-right">
                <span class="fs12 txtseconday mr-2 slrlblinfo" *ngIf="showcomperr && displaycompare != 2">Please select
                    atleast 2 sellers</span>
                <!-- use cmplist-selected class when selected 2 seller -->
                <a *ngIf="displaycompare != 2" (click)="showcompmsg()" [ngClass]="showcomperr ? 'compare-active' : ''"
                    href="javascript:void(0);" class="txtseconday a-cmn-bd d-inline-block compare-btn ">Compare Listing
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.044" height="15.209" viewBox="0 0 15.044 15.209">
                        <g id="np_compare_2794343_000000" transform="translate(17.587 -2.027) rotate(90)">
                            <path id="Path_8719" data-name="Path 8719"
                                d="M3.08,6.677,4.117,5.64,5.755,4l.376-.376H5.255L7.93,6.3l.376.376A.619.619,0,1,0,9.183,5.8L8.146,4.764,6.508,3.126,6.131,2.75a.63.63,0,0,0-.876,0L4.218,3.787,2.582,5.425,2.206,5.8a.619.619,0,1,0,.875.876Z"
                                transform="translate(0 -0.025)" />
                            <path id="Path_8720" data-name="Path 8720"
                                d="M21.255,3.163V14.03c0,.147,0,.3,0,.443v.019a.619.619,0,1,0,1.238,0V3.624c0-.147,0-.3,0-.443V3.163a.619.619,0,1,0-1.238,0Z"
                                transform="translate(-16.18)" />
                            <path id="Path_8721" data-name="Path 8721"
                                d="M51.889,71.464l2.675,2.675.376.376a.63.63,0,0,0,.876,0l1.037-1.037,1.638-1.638.376-.376a.619.619,0,0,0-.876-.876l-1.037,1.037-1.638,1.638-.376.376h.876L54.779,72.6l-1.638-1.638-.376-.376a.619.619,0,0,0-.876.876Z"
                                transform="translate(-41.809 -57.109)" />
                            <path id="Path_8722" data-name="Path 8722"
                                d="M72.165,30.113V19.246c0-.147,0-.3,0-.443v-.019a.619.619,0,0,0-1.238,0V29.651c0,.147,0,.3,0,.443v.019a.619.619,0,0,0,1.238,0Z"
                                transform="translate(-57.979 -13.145)" />
                        </g>
                    </svg>

                </a>
                <span>
                    <a href="javascript:void(0);" class="fs14 add-whislist-link" *ngIf="displaycompare == 2"
                        (click)="clearalllisting()">
                        <span>Clear All</span>
                    </a>&nbsp;&nbsp;
                    <a href="javascript:void(0);" class="btn-d-outline mr-4  place-bid" *ngIf="displaycompare == 2"
                        (click)="addmorelisting()">
                        Add more listings
                    </a>
                </span>
                <div class="cstm-slct-wrp inlblk hideintablet">
                    <select class="form-control  cs-form-control text-capitalize">
                        <option value="">Sort By Relevance</option>

                    </select>
                </div>
                <div class="fltsortnv showintablet">
                    <a href="javascript:void(0);" class="sorticon mr-2">
                        <img src="assets/images/svg/sort.svg">
                    </a>
                </div>
            </div>
        </div>
        <!-- BULK -->
        <div class="card p-3 pt-2 radius15 pdnavtabs" [ngClass]="showcomperr ? 'showallcheck' : ''">
            <ngb-tabset [destroyOnHide]="false">
                <ngb-tab *ngIf="(user_token && user_reg_status == 'yes' && user_status == 'yes' && bulk_records.length > 0) || !user_token || user_reg_status == 'no' || user_status == 'no'">
                    <ng-template ngbTabTitle>
                        <div (click)="changecomparetab('bulk')">Bulk</div>
                    </ng-template>
                    <ng-template ngbTabContent>

                        <div class="table-responsive">
                            <table class="table p-slr-tbl tbl-tobox-tablet p-slr-tblwdth">
                                <thead class="txtdefault fs14 theader">
                                    <tr>
                                        <th width="28%" scope="col">Seller Name</th>
                                        <th scope="col">Supply Location</th>
                                        <th scope="col">Min Order <span class="theader-subline">(Qty)</span></th>
                                        <th scope="col">Total Listed <span class="theader-subline">(Qty)</span></th>
                                        <th scope="col">Dispatched By</th>
                                        <th scope="col" width="14%">Price Per Unit 
                                            <span class="theader-subline">(Inclusive of GST)</span></th>
                                        <th width="20%" class="actncl" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="user_status == 'no' || bulk_records.length == 0">
                                        <tr>
                                            <td colspan="7">
                                                <div class="row pt-40 pb-40">
                                                    <div class="col-md-12 text-center" *ngIf="!user_token">
                                                        <div class="mb-20"><img src="/assets/images/empty-state.png">
                                                        </div>
                                                        <div class="fs14">Login Required!<br>You must be logged in to
                                                            view the best deals available for this product.</div>
                                                        <div class="mt-20">
                                                            <a href="javascript:;" class="link-alt fs16"
                                                                (click)="SignIn.openSigninModal('home')">LOGIN</a>
                                                            &nbsp;|&nbsp; <a href="javascript:;" class="link-alt fs16"
                                                                (click)="SignIn.openSigninModal('home')">SIGNUP</a>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-12 text-center"
                                                        *ngIf="user_token && user_reg_status == 'no'">
                                                        <div class="mb-20"><img src="/assets/images/empty-state.png">
                                                        </div>
                                                        <div class="fs14">Complete Registration<br>You must be complete
                                                            your registration process.</div>
                                                        <div class="mt-20">
                                                            <a href="javascript:;" class="link-alt fs16"
                                                                [routerLink]="['/registration/buyer/details']">REGISTRATION</a>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-12 text-center"
                                                        *ngIf="user_token && user_reg_status == 'yes' && user_status == 'no'">
                                                        <div class="mb-20"><img src="/assets/images/empty-state.png">
                                                        </div>
                                                        <div class="fs14">Wait for Admin Approval<br>Admin will verify
                                                            and approve your registration detials ASAP.</div>
                                                    </div>

                                                    <div class="col-md-12 text-center"
                                                        *ngIf="user_token && user_reg_status == 'yes' && user_status == 'yes' && bulk_records.length == 0">
                                                        <div class="mb-20"><img src="/assets/images/empty-state.png">
                                                        </div>
                                                        <div class="fs14">Records not available.</div>
                                                        <!-- <div class="mt-20">
												<a href="javascript:;" class="link-alt fs16" [routerLink]="['/registration/buyer/details']">REGISTRATION</a>
											</div> -->
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="user_token && user_reg_status == 'yes' && bulk_records.length > 0">
                                        <!-- REPEAT -->
                                        <tr *ngFor="let listing of bulk_records; let i = index;"
                                            [ngClass]="{ 'hidetr': (compare_tab == 'bulk' && displaycompare == 2 && !listing.selected)}">
                                            <td>
                                                <div class="pd-seller">
                                                    <div class="css-check">
                                                        <input class="css-checkbx" type="checkbox"
                                                            [checked]="listing.selected"
                                                            (change)="changelisting(listing)" />
                                                        <label class="css-label">
                                                            &nbsp;
                                                        </label>
                                                    </div>
                                                    <div class="pd-seller-avt">
                                                        <img [src]="listing.logo?listing.logo:'assets/images/products/seller.jpg'"
                                                            alt="listing.seller_name" title="listing.seller_name" />
                                                    </div>
                                                    <div class="pd-seller-cntnt">
                                                        <div class="txtdefault mb-0">
                                                            <span class="seller-name-tk">
                                                                <a [routerLink]="['/seller-info']"
                                                                    [queryParams]="{s_id: listing.s_id}"
                                                                    class="txtdefault">{{ listing.seller_name }}</a>
                                                            </span>
                                                        </div>
                                                        <div class="mb-0 mt-0">
                                                            <span class="txtseconday mr-2">{{listing.star_rating }}</span>
                                                            <ngb-rating [max]="5" [(rate)]="listing.star_rating" [readonly]="true" class="rating_star"></ngb-rating>
                                                        </div>
                                                        <p class="txtseconday mb-0 showintablet">{{
                                                            listing.supply_location }}</p>
                                                        <div class="pd-taglist">
                                                            <span class="f-600 fs10 radius15">{{ listing.delivery_txt
                                                                }}</span>
                                                            <span class="f-600 fs10 radius15"
                                                                *ngIf="listing.pro_status">{{ listing.pro_status
                                                                }}</span>
                                                            <span class="f-600 fs10 radius15"
                                                                *ngIf="listing.made_in">Made in {{ listing.made_in
                                                                }}</span>

                                                                <span class="f-600 fs10 radius15"
                                                                *ngIf="listing.shipping">{{ listing.shipping
                                                                }}</span>

                                                                <span class="f-600 fs10 radius15"
                                                                *ngIf="listing.payment">{{ listing.payment
                                                                }}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </td>
                                            <td class="hideintablet">
                                                <p class="txtseconday mb-0">{{ listing.supply_location }}</p>
                                            </td>
                                            <td class="tbltflex2">
                                                <p class="txtseconday mb-0"><span class="txtdefault ghostlabeltblet">Min Order: </span>{{ listing.min_order }}</p>
                                            </td>
                                            <td class="hideintablet">
                                                <p class="txtseconday mb-0">{{ listing.total_listed }}</p>
                                            </td>
                                            <td class="hideintablet">
                                                <p class="txtseconday mb-0 "><span
                                                        class="txtdefault ghostlabeltblet">Delivery By: </span>{{
                                                    listing.deliver_by }}</p>
                                            </td>
                                            <td class="tbltflex2 md-txt-rt">

                                                <p class="fs12 f-600 txtdefault pb-1 mb-0">
                                                    <span class="f-700 ml-1 fs14 txtactive">{{ listing.sale_price
                                                        }}</span>
                                                    <span class=" fs12 txtdisable ml-1 crossprice fontdefault" *ngIf="!details.child">{{
                                                        listing.regular_price }}</span>
                                                </p>
                                                <p class="pricetag fs12 mb-0 fontdefault txtdefault"
                                                    *ngIf="listing.offer_percentage">
                                                    <i class="p-2 mr-2"></i>
                                                    <span class="f-700">{{ listing.offer_percentage }}% Off</span> on
                                                    MRP
                                                </p>
                                                <div class="pd-taglist" *ngIf="listing.child">
                                                    <span class="f-600 fs10 radius15">{{ listing.child_count }} Child Products</span>
                                                </div>


                                                
                                            </td>
                                            <td>
                                                <a href="javascript:void(0);" *ngIf="!listing.outofstock"
                                                    class="buybtn d-inline-block place-bid mr-2 radius10"
                                                    [routerLink]="['/deal/'+listing.sp_id+'/bulk']">Buy Now</a>
                                                <a href="javascript:void(0);" *ngIf="listing.bid_offer && !listing.outofstock && !details.child"
                                                    (click)="open(content, listing)"
                                                    class="btn-d-outline mr-2 d-inline-block place-bid">Place a Bid</a>
                                                <a href="javascript:void(0);" *ngIf="!listing.bid_offer && !listing.outofstock && !details.child"
                                                    (click)="open(content, listing)"
                                                    class="btn-d-outline mr-2 d-inline-block place-bid">Initiate Bid</a>
                                                <a href="javascript:void(0);" *ngIf="!listing.outofstock && !details.child" class="d-inline-block pluscart"
                                                    [routerLink]="['/deal/'+listing.sp_id+'/bulk']"></a>

                                                <a href="javascript:void(0);" *ngIf="listing.outofstock"
                                                    class="buybtn d-inline-block place-bid mr-2 radius10">Out of Stock</a>

                                            </td>
                                        </tr>
                                        <!-- /REPEAT -->
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </ng-template>
                </ngb-tab>

                <ngb-tab *ngIf="(user_token && user_reg_status == 'yes' && user_status == 'yes' && wholesale_records.length > 0) || !user_token || user_reg_status == 'no' || user_status == 'no'">
                    <ng-template ngbTabTitle>
                        <div (click)="changecomparetab('wholesale')">Wholesale</div>
                    </ng-template>
                    <ng-template ngbTabContent>
                        <div class="table-responsive">
                            <table class="table p-slr-tbl tbl-tobox-tablet p-slr-tblwdth">
                                <thead class="txtdefault fs14 theader">
                                    <tr>
                                        <th width="28%" scope="col">Seller Name</th>
                                        <th scope="col">Supply Location</th>
                                        <th scope="col">Min Order <span class="theader-subline">(Qty)</span></th>
                                        <th scope="col">Total Listed <span class="theader-subline">(Qty)</span></th>
                                        <th scope="col">Dispatched By</th>
                                        <th scope="col" width="14%">Price Per Unit <span
                                                class="theader-subline">(Inclusive of GST)</span></th>
                                        <th width="14%" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="user_status == 'no' || wholesale_records.length == 0">
                                        <td colspan="7">
                                            <div class="row pt-40 pb-40">
                                                <div class="col-md-12 text-center" *ngIf="!user_token">
                                                    <div class="mb-20"><img src="/assets/images/empty-state.png"></div>
                                                    <div class="fs14">Login Required!<br>You must be logged in to view
                                                        the best deals available for this product.</div>
                                                    <div class="mt-20">
                                                        <a href="javascript:;" class="link-alt fs16"
                                                            (click)="SignIn.openSigninModal('home')">LOGIN</a>
                                                        &nbsp;|&nbsp; <a href="javascript:;" class="link-alt fs16"
                                                            (click)="SignIn.openSigninModal('home')">SIGNUP</a>
                                                    </div>
                                                </div>

                                                <div class="col-md-12 text-center"
                                                    *ngIf="user_token && user_reg_status == 'no'">
                                                    <div class="mb-20"><img src="/assets/images/empty-state.png"></div>
                                                    <div class="fs14">Complete Registration<br>You must be complete your
                                                        registration process.</div>
                                                    <div class="mt-20">
                                                        <a href="javascript:;" class="link-alt fs16"
                                                            [routerLink]="['/registration/buyer/details']">REGISTRATION</a>
                                                    </div>
                                                </div>



                                                <div class="col-md-12 text-center"
                                                    *ngIf="user_token && user_reg_status == 'yes' && user_status == 'no'">
                                                    <div class="mb-20"><img src="/assets/images/empty-state.png"></div>
                                                    <div class="fs14">Wait for Admin Approval<br>Admin will verify and
                                                        approve your registration detials ASAP.</div>
                                                </div>

                                                <div class="col-md-12 text-center"
                                                    *ngIf="user_token && user_reg_status == 'yes' && user_status == 'yes' && wholesale_records.length == 0">
                                                    <div class="mb-20"><img src="/assets/images/empty-state.png"></div>
                                                    <div class="fs14">Records not available.</div>
                                                    <!-- <div class="mt-20">
												<a href="javascript:;" class="link-alt fs16" [routerLink]="['/registration/buyer/details']">REGISTRATION</a>
											</div> -->
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <ng-container *ngIf="user_token && user_reg_status == 'yes' && wholesale_records.length > 0">
                                        <!-- REPEAT -->
                                        <tr *ngFor="let listing of wholesale_records; let i = index;"
                                            [ngClass]="{ 'hidetr': (compare_tab == 'wholesale' && displaycompare == 2 && !listing.selected)}">
                                            <td>
                                                <div class="pd-seller">
                                                    <div class="css-check">
                                                        <input class="css-checkbx" type="checkbox"
                                                            [checked]="listing.selected"
                                                            (change)="changelisting(listing)" />
                                                        <label class="css-label">
                                                            &nbsp;
                                                        </label>
                                                    </div>
                                                    <div class="pd-seller-avt">
                                                        <img [src]="listing.logo?listing.logo:'assets/images/products/seller.jpg'"
                                                            alt="listing.seller_name" title="listing.seller_name" />
                                                    </div>
                                                    <div class="pd-seller-cntnt">
                                                        <div class="txtdefault">
                                                            <div class="seller-name-tk">
                                                                <a [routerLink]="['/seller-info']"
                                                                    [queryParams]="{s_id: listing.s_id}"
                                                                    class="txtdefault">
                                                                    {{ listing.seller_name }}
                                                                </a>
                                                            </div>
                                                            <div class="mb-0 mt-0">
                                                                <span class="txtseconday mr-2">{{
                                                                    listing.star_rating }}</span>
                                                                    <ngb-rating [max]="5" [(rate)]="listing.star_rating" [readonly]="true" class="rating_star"></ngb-rating>
                                                                <span class="f-600 fs10 radius15" *ngIf="listing.shipping">{{ listing.shipping }}</span>
                                                            </div>
                                                        </div>
                                                        <p class="txtseconday mb-0 showintablet">{{ listing.supply_location }}</p>
                                                        <div class="pd-taglist">
                                                            <span class="f-600 fs10 radius15">{{ listing.delivery_txt }}</span>
                                                            <span class="f-600 fs10 radius15" *ngIf="listing.pro_status">{{ listing.pro_status }}</span>
                                                            <span class="f-600 fs10 radius15" *ngIf="listing.made_in">Made in {{ listing.made_in }}</span>
                                                            <span class="f-600 fs10 radius15" *ngIf="listing.payment">{{ listing.payment }}</span>
                                                            <span class="f-600 fs10 radius15" *ngIf="listing.shipping">{{ listing.shipping }}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </td>
                                            <td>
                                                <p class="txtseconday mb-0"><span class="txtdefault ghostlabeltblet">Supply Location: </span> {{ listing.supply_location }}</p>
                                            </td>
                                            <td class="hideintablet">
                                                <p class="txtseconday mb-0"><span class="txtdefault ghostlabeltblet">Min Order (Qty): </span> {{ listing.min_order }}</p>
                                            </td>
                                            <td class="hideintablet">
                                                <p class="txtseconday mb-0"><span class="txtdefault ghostlabeltblet">Total Listed (Qty): </span> {{ listing.total_listed }}</p>
                                            </td>
                                            <td>
                                                <p class="txtseconday mb-0"><span class="txtdefault ghostlabeltblet">Dispatched By: </span> {{ listing.deliver_by }}</p>
                                            </td>
                                            <td>
                                                <p class="fs12 f-600 txtdefault pb-1 mb-0">
                                                    <span class="f-700 ml-1 fs14 txtactive">{{ listing.sale_price
                                                        }}</span>
                                                    <span class=" fs12 txtdisable ml-1 crossprice fontdefault" *ngIf="!details.child">{{
                                                        listing.regular_price }}</span>
                                                </p>
                                                <p class="pricetag fs12 mb-0 fontdefault txtdefault"
                                                    *ngIf="listing.offer_percentage">
                                                    <i class="p-2 mr-2"></i>
                                                    <span class="f-700">{{ listing.offer_percentage }}% Off</span> on
                                                    MRP
                                                </p>
                                                <div class="pd-taglist" *ngIf="listing.child">
                                                    <span class="f-600 fs10 radius15">{{ listing.child_count }} Child Products</span>
                                                </div>
                                            </td>
                                            <td>
                                                <a *ngIf="!listing.outofstock" href="javascript:void(0);"
                                                    class="buybtn d-inline-block place-bid mr-2 radius10"
                                                    [routerLink]="['/deal/'+listing.sp_id+'/wholesale']">Buy Now</a>

                                                    <a href="javascript:void(0);" *ngIf="listing.outofstock"
                                                    class="buybtn d-inline-block place-bid mr-2 radius10">Out of Stock</a>

                                                <a href="javascript:void(0);" *ngIf="!listing.outofstock" class="d-inline-block pluscart"
                                                    [routerLink]="['/deal/'+listing.sp_id+'/wholesale']"></a>

                                            </td>
                                        </tr>
                                        <!-- /REPEAT -->
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
            <!-- /BULK -->
        </div>
    </div>
    <!-- /SELLER LISTING -->
    <!-- INSTRUCTION -->
    <div class="mb-4 pb-1 mb-xs-0">
        <h4 class="f-700 fs16 txtdefault pb-20 pb-xs-0">Instructions from the Manufacturer</h4>
        <p class="fs14 txtseconday lnht24">{{ details.instruction_from_manufacturer }}</p>
    </div>
    <!-- /INSTRUCTION -->
    <!-- Manufacturer -->
    <div class="mb-4 pb-3 mb-xs-10">
        <h4 class="f-700 fs16 txtdefault mb-2 pb-1 pb-xs-0">From the Manufacturer</h4>
        <div class="photorow" *ngFor="let banner of details.bannerImage">
            <img src="{{banner}}" />
        </div>
    </div>
    <!-- /Manufacturer -->

    <!-- INSTRUCTION -->
    <div class="mb-4" *ngIf="chunked.length > 0">
        <h4 class="f-700 fs16 txtdefault mb-2 pb-2">Technical Specifications &nbsp;<span
                class="fontdefault f-500 fs14 txtfade">{{ details.name }}</span></h4>

        <div class="tech-spec">
            <!--Repeat-->
            <div class="row no-gutters" *ngFor="let chunk of chunked; let i = index;">
                <div class="col-md-6" *ngFor="let rec of chunk; let k = index;">
                    <div class="tech-item d-flex">
                        <div class="title">{{ rec.label }}</div>
                        <div class="desc">{{ rec.value }}</div>
                    </div>
                </div>
            </div>
            <!--Repeat-->
        </div>
    </div>
    <!-- /INSTRUCTION -->
    <div class="mb-4">
        <div class="row mb-3 align-items-center p-rvw">
            <div class="col-sm-6">
                <h4 class="f-700 fs16 txtdefault mb-2 pb-1">Product Reviews & Ratings</h4>
            </div>
            <div class="col-sm-6 text-right">
                <div class="cstm-slct-wrp hideintab d-inline-block w-100 maxsrt">
                    <select class="form-control  cs-form-control text-capitalize" [(ngModel)]="sort_by_rating" (change)="reviewsorting()">
                        <option value="date_asc">Sort By Date (ASC)</option>
                        <option value="date_desc">Sort By Date (DESC)</option>
                        <option value="rating_asc">Sort By Rating (ASC)</option>
                        <option value="rating_desc">Sort By Rating (DESC)</option>
                    </select>
                </div>

            </div>
        </div>
        <div class="showintablet ovrl-tbl-rt">
            <div class="row align-items-center">
                <div class="col-6 pr-0">
                    <div class="ovlrating hideinmd">
                        <h4 class="fs14 f-600 mb-2">Overall Rating</h4>
                        <div class="ratingcal">
                            <div class="ratings star-ratings pr-1 d-inline-block">
                                <ngb-rating [max]="5" [(rate)]="star_count" [readonly]="true" class="rating_star"></ngb-rating>
                            </div>
                            <span class="fs16 f-700 txtdefault">{{ star_count }}</span> out of <span
                                class="fs16 f-700 txtdefault">5</span><br />
                            <i class="fs12 txtseconday pt-2 ">({{ rating_total_records }} ratings)</i>
                            <div class="fltsortnv">
                                <a href="javascript:void(0);" class="sorticon mr-2">
                                    <img src="assets/images/svg/sort.svg">
                                </a>
                                <a href="javascript:void(0);" (click)="ratingtglclass()" class="filtericon">
                                    <img src="assets/images/svg/filter.svg">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 text-right" *ngIf="user_token && user_reg_status == 'yes' && user_status == 'yes'">
                    <div class="py-2">
                        <a href="javascript:;" routerLink="['/buyer/write-review']" class="btn-d-outline  d-inline-block">Write a Review</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 filtercol lsting rating-filter filtermbl"
                [ngClass]="ratingclassstatus ? 'showfilter' : ''">
                <div class="fltrshead">
                    <h4>Filter</h4>
                    <a (click)="ratingtglclass()" class="closefilter" href="javascript:void(0);"><svg
                            _ngcontent-serverApp-c132="" xmlns="http://www.w3.org/2000/svg" width="6.508" height="6.508"
                            viewBox="0 0 6.508 6.508">
                            <path _ngcontent-serverApp-c132="" id="np_cross_1707162_000000"
                                d="M30.883,30.883a.507.507,0,0,1,.717,0l2.389,2.389,2.389-2.389a.507.507,0,0,1,.717.717L34.7,33.988l2.389,2.389a.507.507,0,0,1-.717.717L33.988,34.7,31.6,37.094a.507.507,0,0,1-.717-.717l2.389-2.389L30.883,31.6A.507.507,0,0,1,30.883,30.883Z"
                                transform="translate(-30.734 -30.734)" fill="#1f1d44"></path>
                        </svg></a>
                </div>
                <div class="ovlrating">
                    <h4 class="fs14 f-600 mb-2">Overall Rating</h4>
                    <div class="ratingcal">
                        <div class="ratings star-ratings pr-1 d-inline-block">
                            <ngb-rating [max]="5" [(rate)]="star_count" [readonly]="true" class="rating_star"></ngb-rating>
                        </div>
                        <span class="fs16 f-700 txtdefault">{{ star_count }}</span> out of <span
                            class="fs16 f-700 txtdefault">5</span><br />
                        <i class="fs12 txtseconday pt-2 pb-3">({{ rating_total_records }} ratings)</i><br />
                        <div class="py-2 pt-3" *ngIf="user_token && user_reg_status == 'yes' && user_status == 'yes'">
                            <a href="javascript:;" [routerLink]="['/buyer/write-review']" class="btn-d-outline  d-inline-block ">Write a Review</a>
                        </div>
                    </div>

                </div>
                <ngb-accordion #acc="ngbAccordion"
                    activeIds="ratingfilter, toggle-2, toggle-3, brand, startingprice, ram, internalstorage, screensize, activationstatus, supplylocation, countryorigin"
                    class="accordion">
                    <ngb-panel id="ratingfilter" opened class="bg-transparent" title="Filter By Ratings">
                        <ng-template ngbPanelContent>
                            <ul class="list-group">
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" (change)="updatereviewfilters(5)" />
                                        <label class="css-label">
                                            <span class="fs16 txtseconday mr-2">5</span>
                                            <span class="ratings star-ratings pr-1 d-inline-block">
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" (change)="updatereviewfilters(4)"/>
                                        <label class="css-label">
                                            <span class="fs16 txtseconday mr-2">4 and above</span>
                                            <span class="ratings star-ratings pr-1 d-inline-block">
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox"  (change)="updatereviewfilters(3)"/>
                                        <label class="css-label">
                                            <span class="fs16 txtseconday mr-2">3 and above</span>
                                            <span class="ratings star-ratings pr-1 d-inline-block">
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox"  (change)="updatereviewfilters(2)"/>
                                        <label class="css-label">
                                            <span class="fs16 txtseconday mr-2">2 and above</span>
                                            <span class="ratings star-ratings pr-1 d-inline-block">
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox"  (change)="updatereviewfilters(1)"/>
                                        <label class="css-label">
                                            <span class="fs16 txtseconday mr-2">1 and above</span>
                                            <span class="ratings star-ratings pr-1 d-inline-block">
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </ng-template>
                    </ngb-panel>
                    <!-- <ngb-panel id="toggle-2" title="Filter By Ratings" style="display:none;">
                        <ng-template ngbPanelContent>
                            <ul class="list-group">
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" />
                                        <label class="css-label">All</label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" />
                                        <label class="css-label">Only Images</label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" />
                                        <label class="css-label">Only Videos</label>
                                    </div>
                                </li>
                            </ul>
                        </ng-template>
                    </ngb-panel> -->

                </ngb-accordion>
            </div>
            <div class="col-lg-9 listing-content rating-content pl-lg-4">
                <div class="card cardfadembl p-3 px-0 pt-0 radius15">
                    <div class="card-body pt-0">
                        <div class="pd-rt-rw">
                            <table class="table p-slr-tbl">
                                <thead class="txtdefault fs14 theader">
                                    <tr>
                                        <th width="14%" class="text-center" scope="col">Product Image</th>
                                        <th width="14%" scope="col">Date of Review</th>
                                        <th width="40%" scope="col">User’s Review</th>
                                        <th scope="col">User’s Rating</th>
                                        <!-- <th scope="col" class="text-center">Action</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <!--Empty State-->
                                    <tr *ngIf="!review_records.length">
                                        <td colspan="4">
                                            <div class="row pt-40 pb-40">
                                                <div class="col-md-12 text-center">
                                                    <div class="mb-20"><img src="/assets/images/empty-state.png">
                                                    </div>
                                                    <div class="fs16">There are no reviews to display!</div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <!--End Empty State-->
                                    <!-- REPEAT -->
                                    <tr *ngFor="let rec of review_records;">
                                        <td class="text-center od3">
                                            <img width="50" *ngIf="rec.product_image" src="{{ rec.product_image }}">
                                        </td>
                                        <td class="od4">
                                            <p class="txtseconday mb-0">{{ rec.review_date }}</p>
                                        </td>
                                        <td class="od2">
                                            <p class="txtseconday mb-0 lnht24">{{ rec.review }}</p>
                                        </td>
                                        <td class="od1">
                                            <div class="ratings star-ratings">
                                                <ngb-rating [max]="5" [(rate)]="rec.rating" [readonly]="true" class="rating_star"></ngb-rating>
                                            </div>
                                        </td>
                                        <!-- <td class="text-center od5">
                                            <div ngbDropdown class="d-inline-block drpdwnbx" placement="bottom-right">
                                                <button class="btn btn-outline-primary dotdrpdwn" id="dropdownBasic1"
                                                    ngbDropdownToggle>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </button>
                                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                    <button class="txtseconday" ngbDropdownItem>Option 1</button>
                                                    <button class="txtseconday" ngbDropdownItem>Option 2</button>
                                                </div>
                                            </div>
                                        </td> -->
                                    </tr>
                                    <!-- /REPEAT -->
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row mb-40" *ngIf="review_pagination.totalPages> 1">
                    <div class="col-md-6">
                        <div class="text-left pl-3">
                            <nav class="mt-3 tbpg rows-per-page">
                                <div>Rows per page:</div>
                                <select class="select-box" [(ngModel)]="page_per_view"
                                    (change)="setpageperview()">
                                    <option *ngFor="let option of page_per_view_options"
                                        [ngValue]="option.value">
                                        {{option.name}}</option>
                                </select>
                            </nav>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="text-right pr-3">
                            <nav class="mt-3 tbpg">
                                <ul class="pagination align-items-center">
                                    <li class="page-item"
                                        [ngClass]="{disabled:review_pagination.currentPage === 1}"><a
                                            class="page-link previcon" href="javascript:;"
                                            (click)="setpage(1)"></a>
                                    </li>
        
                                    <li class="page-item"
                                        [ngClass]="{disabled:review_pagination.currentPage === 1}"><a
                                            class="page-link sprevicon" href="javascript:;"
                                            (click)="setpage(review_pagination.currentPage - 1)"></a></li>
        
                                    <li class="page-item" i *ngFor="let page of review_pagination.pages"
                                        [ngClass]="{active:review_pagination.currentPage === page}"><a
                                            class="page-link" href="javascript:;"
                                            (click)="setpage(page)">{{page}}</a></li>
        
                                    <li
                                        *ngIf="(review_pagination.totalPages - review_pagination.startPage) > 5 ">
                                        <a (click)="setpage(review_pagination.startPage + 5)">...</a>
                                    </li>
                                    <li
                                        *ngIf="(review_pagination.totalPages - review_pagination.startPage) > 5">
                                        <a
                                            (click)="setpage(review_pagination.totalPages)">{{review_pagination.totalPages}}</a>
                                    </li>
        
                                    <li class="page-item"
                                        [ngClass]="{disabled:review_pagination.currentPage === review_pagination.totalPages}">
                                        <a class="page-link snexticon" href="javascript:;"
                                            (click)="setpage(review_pagination.currentPage + 1)"></a>
                                    </li>
                                    <li class="page-item"
                                        [ngClass]="{disabled:review_pagination.currentPage === review_pagination.totalPages}">
                                        <a class="page-link nexticon" href="javascript:;"
                                            (click)="setpage(review_pagination.totalPages)"></a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- INSTRUCTION -->
    <div class="mb-5 faqpanel accordionarrow">
        <h4 class="f-700 fs22 txtdefault pb-20">Frequently Asked Questions</h4>
        <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
            <ngb-panel title="What is a bulk and wholesale Segment?">
                <ng-template ngbPanelContent>
                    <p class="txtdefault fs16 lnht24 mb-0">Equibiz facilitates both large and small value transactions to meet the requirements of different stakeholders such as brand, wholesaler, distributor, retailer, corporate, exporter, importer, etc. Under the bulk segment, the suppliers have the freedom to select deal terms such as minimum order quantity, minimum bid price, supply location, payment option, etc. Whereas under the wholesale segment, the minimum transaction value must be INR 3,000/- or above and the trade terms are set to facilitate the retailer’s or small value buyer’s requirement.</p>
                </ng-template>
            </ngb-panel>
            <ngb-panel title="What is bid and offer?">
                <ng-template ngbPanelContent>
                    <p class="txtdefault fs16 lnht24 mb-0">A bid is a counter price of any product set by the buyer against the listed price of the seller under the bid & offer segment. This proprietary feature of our e-commerce platform Equibiz is to facilitate the trading of products under the bulk listing. If the seller agrees with the bid set by the buyer, the seller can close the deal at the price set by the buyer. Apart from this, the rest of the other deal conditions will remain the same as set by the seller at the time of product listing.</p>
                </ng-template>
            </ngb-panel>
            <ngb-panel title="What is the minimum order quantity?">
                <ng-template ngbPanelContent>
                    <p class="txtdefault fs16 lnht24 mb-0">It is the minimum quantity of products below which the seller is not willing to accept the order.</p>
                </ng-template>
            </ngb-panel>
            <ngb-panel title="How do I get the purchased products? Who will provide the logistic services?">
                <ng-template ngbPanelContent>
                    As a buyer, you need not worry about arranging a courier or any 3rd party logistics as Equibiz provides integrated logistics services to arrange the shipment of the consignment from the supplier’s place. 
                </ng-template>
            </ngb-panel>
            <ngb-panel title="What are the charges buyers are required to pay?">
                <ng-template ngbPanelContent>
                    The buyer may require paying charges for logistics, convenience, and insurance(optional). The charges are displayed to the buyer at the time of deal booking.
                </ng-template>
            </ngb-panel>
            <ngb-panel title="As a buyer, can I add more than one delivery location?">
                <ng-template ngbPanelContent>
                    Yes, the buyer has the option to select the preferred delivery location at the time of deal booking. The buyer can set more than one delivery location from the buyer panel.
                </ng-template>
            </ngb-panel>
            <ngb-panel title="What are the Logistics options available at the time of Deal Booking ?">
                <ng-template ngbPanelContent>
                    Standard and Express delivery logistics options are available at the time of deal booking and buyers can choose any one logistics option as per their business requirement. The expected delivery time in the case of express delivery is three to five working days and in the case of standard, it is five to seven working days.
                </ng-template>
            </ngb-panel>
            <ngb-panel title="How do I make the payment for my purchase?">
                <ng-template ngbPanelContent>
                    At the time of check out, there are various payment options like Equibiz internal wallet, Net Banking, Debit Card, Credit Card, UPI, etc. available to select as a payment method. Except for the Equibiz wallet, other payment options may have certain Payment Gateway charges which will be applicable at the time of making a payment.
                </ng-template>
            </ngb-panel>
            <ngb-panel title="What is the payment timeline for the value of the bulk and wholesale segment deal?">
                <ng-template ngbPanelContent>
                    As per the Equibiz payment guidelines, for the purchase of products listed under the bulk segment, as per the seller’s preference, either full payment at the time of booking or a token amount (10 % of the deal value) to be paid by the buyer at the time of pre-booking and the balance payment (90% of the deal value) before the dispatch date. For the wholesale segment transactions, full payment of the deal value along with the charges must be paid by the buyers at the time of deal booking.
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
    <!-- /INSTRUCTION -->
    <!-- MORE PRODUCTS -->
    <h4 class="f-700 fs20 txtdefault mb-3 pb-1" *ngIf="more_products.length > 0">Similar Products <img
            src='{{ brand_logo }}' style="height:30px"></h4>
    <div class="row p-list-item row-cols-xl-5 row-cols-lg-4 row-cols-md-3  pd-list-item"
        *ngIf="more_products.length > 0">
        <!-- REPEAT -->
        <div class="col-6 col-md-3 mb-3" *ngFor="let product of more_products; let i = index;">
            <div class="product-item  pt-2">
                <div class="ex-deal" style="display:block" *ngIf="product.total_bid > 0"><span>{{
                    product.total_bid }} {{ product.total_bid > 1 ?'Bids': 'Bid' }}</span></div>
                <div class='image pt-0'>
                    <div class="d-table">
                        <div class="d-table-cell align-middle" (click)="moreproductlink(product.unique_id)">
                            <img src="{{ product.p_front_image }}">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="product-name fs14" (click)="moreproductlink(product.unique_id)">
                            {{ product.name }}
                        </div>
                        <div class="ratingitem py-1">
                            <div class="ratings star-ratings pr-1 d-inline-block">
                                <ngb-rating [max]="5" [(rate)]="product.star_count" [readonly]="true" class="rating_star"></ngb-rating>
                            </div>

                            <i class="fs10 txtseconday pt-2 ">({{ product.total_rating }})</i>

                        </div>
                    </div>
                </div>
                <div class="row no-gutters d-flex justify-content-between">
                    <div class="col-5">
                        <div class="seller-count txtdefault">
                            Total Stock <br /> <span class="fs14 f-700">{{ product.total_stock }}</span>
                        </div>
                    </div>
                    <div class="col-7">
                        <div class="price">
                            Starting at <br /><span> {{ product.sales_price }} <b
                                    class="strikethrough">{{ product.regular_price }}</b></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /REPEAT -->

    </div>
</div>
<!-- /MORE PRODUCTS -->

<!-- PLACE BID MODAL -->
<ng-template #content let-modal>
    <button type="button" class="close closemodal" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body p-0">
        <div class="modal-scrollbar">
            <div class="pd-seller mb-3 mt-2">
                <div class="pd-seller-avt">
                    <img [src]="selected_bid.logo?selected_bid.logo:'assets/images/products/seller.jpg'" />
                </div>
                <div class="pd-seller-cntnt">
                    <div class="txtdefault mb-2 mdl-pd-sellername">
                        <span class="seller-name-tk">{{ selected_bid.seller_name}} <i placement="bottom" ngbTooltip="You are placing your bid against this Seller"> <img src="/assets/images/icons/info.png"></i></span>
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-md-7">
                    <div class="mdl-crd">
                        <div class="row mb-3">
                            <p class="col-md-7 mb-0 fs14 txtseconday">
                                Min Order Qty:
                            </p>
                            <label class="col-md-5 mb-0 text-right fs14 txtdefault">
                                {{ selected_bid.min_order_qty }}
                            </label>
                        </div>
                        <div class="row mb-3">
                            <p class="col-md-7 fs14 mb-0  txtseconday">
                                Price/Unit:
                            </p>
                            <label class="col-md-5 fs14 mb-0 text-right txtdefault">
                                {{ selected_bid.price }}
                            </label>
                        </div>
                        <div class="row">
                            <p class="col-md-7 fs14 mb-0  txtseconday">
                                Supply Location:
                            </p>
                            <label class="col-md-5 fs14 mb-0 text-right txtdefault">
                                {{ selected_bid.supply_location }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="pl-md-2 vallbid">
                        <label class="f-600 pr-3 pt-2">Highest Bid by the Buyer</label>
                        <h4 class="f-700 fs20 txtactive">{{ selected_bid.current_min_bid }}</h4>
                        <a class="mr-3 fs16 txtactive warm-link" href="javascript:void(0);"
                            [routerLink]="['/buyer/bids-offers/'+details.id]" target="_blank">View all {{
                            selected_bid.current_bid_count?selected_bid.current_bid_count:'' }} Bids</a>
                        <span class="d-inline-block fs14 txtseconday"><img class="mr-1" src='assets/images/svg/eye.svg'> {{
                            selected_bid.watching_bid }} Buyers watching this Bid</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group plcbidinpt mb-0">
                        <div class="row">
                            <div class="col-md-6">
                                <input type="number" class="form-control radius10 txtseconday"
                                    placeholder="Enter the Bid Quantity" [(ngModel)]="bid_quantity"
                                    min="{{ selected_bid.min_order_qty }}">
                                <p class="fs12 txtdefault mb-1">Please Enter {{ selected_bid.min_order_qty}} or more</p>
                            </div>
                            <div class="col-md-6">
                                <input type="text" class="form-control radius10 txtseconday"
                                    placeholder="Enter the Bid Amount" [(ngModel)]="selected_bid.bid_amount">
                                <p class="fs12 txtdefault mb-1">Please Enter {{ selected_bid.lowest_bid_amt}} or more</p>
                            </div>
                        </div>
                    </div>
                    <!-- <a class="mr-3 fs16 txtactive warm-link" href="javascript:void(0);">View Bid Breakdown</a> -->
                </div>
            </div>
            <hr>
            <h4 class="txtdefault fs16 wt700 mb-3">Select Logistics</h4>
            <div class="row">
                <div class="col-md-6">
                    <div class="ord-adrs-artcl">
                        <p class="txtdefault fs14 lnht24 mb-0 cssinptchk">
                            <span class="css-check">
                                <input class="css-checkbx" name="logistics_type" type="radio"
                                    [checked]="selected_delivery == 'standard'" (click)="changedelivery('standard')" />
                                <input class="css-checkbx" name="logistics_type" type="radio" />
                                <label class="css-label">&nbsp;</label>
                            </span>
                            <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="21.334" height="15.778"
                                viewBox="0 0 21.334 15.778">
                                <path id="np_delivery_4410921_000000"
                                    d="M18.4,30.9a2.778,2.778,0,0,1-2.688-2.083H11.368a2.778,2.778,0,0,1-5.375,0H3.819a.694.694,0,0,1-.694-.694V16.319a.694.694,0,0,1,.694-.694H17.014a.694.694,0,0,1,.694.694V19.1h2.778a.7.7,0,0,1,.542.264l2.778,3.472a.7.7,0,0,1,.153.431v4.861a.694.694,0,0,1-.694.694H21.091A2.778,2.778,0,0,1,18.4,30.9Zm-1.389-2.778a1.389,1.389,0,1,0,.465-1.035,1.389,1.389,0,0,0-.465,1.035ZM8.681,26.737a1.39,1.39,0,1,0,.982.407,1.388,1.388,0,0,0-.982-.407Zm12.41.694H22.57V23.507l-2.417-3.021H17.709v4.952a2.718,2.718,0,0,1,.694-.09,2.778,2.778,0,0,1,2.688,2.083Zm-9.723,0h4.347a2.692,2.692,0,0,1,.6-1.146V17.014H4.514V27.431H5.993a2.778,2.778,0,0,1,5.375,0Z"
                                    transform="translate(-2.875 -15.375)" fill="#1f1d44" stroke="#fcfcfc"
                                    stroke-width="0.5" />
                            </svg> Standard Delivery
                        </p>
                        <p class="txtseconday fs12 dlnht20 mb-2">
                            Dispatched by {{ selected_bid.standard_delivery_date }}</p>
                        <a class="fs14 add-whislist-link" href="javascript:void(0);" (click)="openlogistics(logisticsCharges)"><span>View Details</span></a>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="ord-adrs-artcl">
                        <p class="txtdefault fs14 lnht24 mb-0 cssinptchk">
                            <span class="css-check">
                                <input class="css-checkbx" name="logistics_type" type="radio"
                                [checked]="selected_delivery == 'express'" (click)="changedelivery('express')" />
                                <label class="css-label">&nbsp;</label>
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20.398" height="13.82"
                            viewBox="0 0 20.398 13.82">
                            <path id="np_delivery_2964601_000000"
                                d="M24.071,23.787a2.357,2.357,0,0,0-2.121-1.311H18.581V19.941a.429.429,0,0,0-.429-.429H7.691a.426.426,0,0,0-.3.125.43.43,0,0,0,.3.734H17.723V30.655H13.306a2.246,2.246,0,0,0-4.41,0H7.464a.43.43,0,1,0,0,.86H8.9a2.246,2.246,0,0,0,4.41,0h5.821a2.246,2.246,0,0,0,4.41,0h1.432a.431.431,0,0,0,.429-.43V26.541a.435.435,0,0,0-.045-.192ZM11.1,32.472a1.388,1.388,0,1,1,1.388-1.388A1.388,1.388,0,0,1,11.1,32.472ZM21.95,23.335a1.5,1.5,0,0,1,1.353.837l1.235,2.471v.3H18.581v-3.6Zm-.618,9.137a1.388,1.388,0,1,1,1.387-1.388,1.388,1.388,0,0,1-1.387,1.388Zm2.205-1.818h0a2.246,2.246,0,0,0-4.41,0h-.545V27.8h5.956v2.858Zm-16.9-7.866h6.149a.43.43,0,1,1,0,.86H6.639a.43.43,0,1,1,0-.86ZM5,26.257a.429.429,0,0,1,.429-.43h5.323a.43.43,0,1,1,0,.86H5.429A.429.429,0,0,1,5,26.257Z"
                                transform="translate(-5 -19.511)" fill="#1f1d44" />
                        </svg> Express Delivery
                        </p>
                        <!-- <p class="txtdefault fs14 lnht24 mb-0">Shipping charges: {{ detail.standard_delivery_charge }}</p> -->
                        <p class="txtseconday fs12 dlnht20 mb-2">
                            Dispatched by {{ selected_bid.express_delivery_date }}</p>
                        <a class="fs14 add-whislist-link" href="javascript:void(0);"><span (click)="openlogistics(logisticsChargesexpress)">View Details</span></a>
                    </div>
                </div>
            </div>
            <hr>
            <h4 class="txtdefault fs16 wt700 mb-3">Select Shipping Address</h4>
            <div class="row mb-10">
                <div class="col-md-12">
                    <div class="ord-adrs-artcl" *ngFor="let addr of selected_bid.buyer_shipping_address; let i = index;">
                        <p class="txtdefault fs14 lnht24 mb-0 cssinptchk">
                            <span class="css-check">
                                <input class="css-checkbx" name="supply_location" type="radio" [checked]="addr.selected" (click)="changesupplylocation(i)"/>
                                <label class="css-label">&nbsp;</label>
                            </span>
                            {{ addr.address }}
                        </p>
                    </div>
                </div>
            </div>
            <hr>
            <h4 class="txtdefault fs16 wt700 mb-3">Billing Address</h4>
            <div class="mb-3"><a (click)="changebillingaddress()" href="javascript:void(0);"
                class="link-alt fs13">Request to Change Address
            </a></div>
            <div class="row mb-10">
                <div class="col-md-12">
                    <div class="ord-adrs-artcl">
                        <p class="txtdefault fs14 lnht24 mb-0 cssinptchk">
                            {{ selected_bid.billing_address }}
                        </p>
                    </div>
                </div>
            </div>
            <hr>
            <h4 class="txtdefault fs16 wt700 mb-3">Insurance <i placement="top"
                ngbTooltip="Insurance is optional and Please read the Terms & Conditions carefully before buying.">
                <img src="/assets/images/icons/info.png">
            </i></h4>
            <div class="row d-flex align-items-center">
                <div class="col-md-7">
                    <div class="text-muted">By adding Insurance, I agree with insurance and accept all
                        the <a href="javascript:;" class="text-orange" (click)="openinsurance(contentinsurance)">terms & conditions</a></div>
                </div>
                <div class="col-md-5">
                    <div class="text-right">
                        <a href="javascript:;" class="mr-2 btn-d-outline fs14 place-bid d-inline-block" 
                        (click)="openinsurance(contentinsurance)">{{ !enable_insurance? 'Add insurance':'Remove insurance' }}</a>
                    </div>
                </div>
            </div>
        </div>
        <!--end modal-scrollbar-->
    </div>

    <div class="nobd">
        <div class="row d-flex justify-content-center mt-3">
            <div class="col-md-12">
                <div class="text-center">
                    <button class="buybtn d-inline-block px-4 radius10"
                        [disabled]="!selected_bid.bid_amount" type="button" (click)="placebid()">Place
                        Bid</button>
                </div>
            </div>
        </div>
        <hr>
        <p class="fs12 text-center">
            Please note that transaction under bids and offer uses Equibiz wallet as a payment method. Request buyer partners to maintain adequate funds in their wallet account before placing a bid for seamless transaction experience.
        </p>
    </div>
</ng-template>
<!-- /PLACE BID MODAL -->

<!-- LOGISTIC CHARGES MODAL -->
<ng-template #logisticsCharges let-modal>
    <div class="modal-header text-center nobd pt-4 mt-1 pb-1 d-block">
        <h4 class="modal-title mx-auto f-700 fs20 txtdefault">Standard Delivery (Inclusive GST%)</h4>
        <div class="fs12 f-600 mb-2">Under this delivery mode you are expected to receive stock in 6 working days.</div>
        <button type="button" class="close closemodal" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">

        <div class="modal-scrollbar">
            <div class="notifications-container theme-tab">
                <ngb-tabset [justify]="'left'" [type]="'pills'" class="tabs tab-title">
                    <ngb-tab>
                        <ng-template ngbTabTitle>Rate for within City</ng-template>
                        <ng-template ngbTabContent>
                            <div class="tab-content">
                                <table class="table table-bordered xs">
                                    <thead>
                                        <tr>
                                            <th>Weight Bracket</th>
                                            <th>Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let record of standard_city_records;">
                                            <td>{{ record.weight }}</td>
                                            <td>{{ record.total }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab>
                        <ng-template ngbTabTitle>Rate for Rest of India</ng-template>
                        <ng-template ngbTabContent>
                            <div class="tab-content">
                                <table class="table table-custom xs">
                                    <thead>
                                        <tr>
                                            <th>Weight Bracket</th>
                                            <th>Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let record of standard_outsidecity_records;">
                                            <td>{{ record.weight }}</td>
                                            <td>{{ record.total }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
            </div>
        </div>
    </div>

</ng-template>
<!-- /SHIPPING ADDRESS MODAL -->

<!-- LOGISTIC CHARGES MODAL -->
<ng-template #logisticsChargesexpress let-modal>
    <div class="modal-header text-center nobd pt-4 mt-1 pb-1 d-block">
        <h4 class="modal-title mx-auto f-700 fs20 txtdefault">Express Delivery (Inclusive of GST%)</h4>
        <div class="fs12 f-600 mb-2">Recommended for the long distance movement of bulk consignment of products like mobile phones, tablets,etc. 
         Under this mode, logistic partner will preferably move the consignment by air and you are expected to receive stock in 3 working days.</div>
        <button type="button" class="close closemodal" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="modal-scrollbar">
            <div class="notifications-container theme-tab">
                <ngb-tabset [justify]="'left'" [type]="'pills'" class="tabs tab-title">
                    <ngb-tab>
                        <ng-template ngbTabContent>
                            <div class="tab-content">
                                <table class="table table-bordered xs">
                                    <thead>
                                        <tr>
                                            <th>Weight Bracket</th>
                                            <th>Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let record of express_records;">
                                            <td>{{ record.weight }}</td>
                                            <td>{{ record.total }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
            </div>
        </div>
    </div>

</ng-template>
<!-- /LOGISTIC CHARGES MODAL -->

<!-- Insurance Details MODAL -->
<ng-template #contentinsurance let-modal>
    <div class="modal-header nobd text-center pt-4 pb-0">
        <h4 class="modal-title fs22 txtdefault f-700 mx-auto pt-3">Insurance Terms & Conditions</h4>
    </div>
    <div class="modal-body">
        <div class="terms-conditions">
            <h5><b>Tata AIG General Insurance Company Limited</b></h5>
            <div class="fs18 f-700 mb-10">MARINE CARGO OPEN POLICY</div>
            <div><b>VOYAGE: </b></div>
            <p>From anywhere in India to anywhere in India.</p>
            <div><b>INSURING CLAUSES: </b></div>
            <p>Inland Transit (Rail/Road/Air) Clause - A 2010<br>
            Strikes Riots and Civil Commotion Clause - 2010<br>
            Limitation of Liability Clause<br>
            Termination of Transit Clause (Terrorism) JC 2009/056 (01.01.09)<br>
            Institute Radioactive Contamination, Chemical, Biological, Bio-Chemical, Electromagnetic Weapons Exclusion Clause CL.370 (10.11.03)<br>
            Institute Cyber Attack Exclusion Clause CL.380 (10.11.03)<br>
            Institute Standard Conditions for Cargo Contracts 1/4/82<br>
            Important Notice Clause</p>
            <div><b>WARRANTIES, CONDITIONS & EXCLUSIONS:</b></div>
            <p>Institute Replacement clause: In the event of loss or damage to any part or parts of an insured machine caused by a peril covered by the Policy, the sum recoverable shall not exceed the cost of replacement or repair of such part or parts plus charges for forwarding and refitting, if incurred, but excluding duty unless the full duty is included in the amount insured, in which case loss, if any, sustained by payment of additional duty shall also be recoverable.</p>
            <p>ISSUING OF CERTIFICATES CONDITION: The Assured will be authorized to issue Certificate of Insurance through e-marine which is Web based tool. As per Statutory Provision, premium adequacy of sum insured has to be ensured at any point of time. (not applicable for Project policies). This condition is applicable for those voyages where type of declaration opted as Marine certificate under the policy.</p>
            <p>CANCELLATION CLAUSE CONDITION: This contract may be cancelled by either the Company or the Assured giving 30 day&rsquo;s notice in writing to take effect from the midnight of date of notice but risks covered by Institute War Clauses may be cancelled at seven day&rsquo;s notice and risks covered by the Institute Strikes Clauses may be cancelled at seven day&rsquo;s notice, or at forty-eight hour&rsquo;s notice in respect of shipments to or from the United States of America. Notice shall commence from midnight of the day when it is issued but cancellation shall not apply to any risk which have attached in accordance with the cover granted hereunder before the cancellation becomes effective.</p>
            <p>Excluding coverage in respect of Institute War Clauses (Cargo) and Institute Strikes Clauses (Cargo) where Tata AIG risk commences from Bahrain, Kuwait, Oman, Qatar, Saudi Arabia and United Arab Emirates and/or where Tata AIG risk terminates at Bahrain, Kuwait, Oman, Qatar, Saudi Arabia and United Arab Emirates. The Reinstatement of Institute War Clauses (Cargo) and Institute Strikes Clauses (Cargo) shall be subject to an additional premium at the rate of 0.05% on the shipment value, to be paid/remitted to the Tata AIG prior to the commencement of the transit, failing which Tata AIG shall be absolved of all liabilities</p>
            <p>VERIFICATION OF RECORD CONDITION: The Company shall have the privilege at any time during business hours to inspect the records of the Assured in respect of the shipment or sending or risk falling within the terms of this contract.</p>
            <p>COMMUNICABLE DISEASE EXCLUSION LMA 5394 (amended) This exclusion applies to all original risks (including individual declarations made under facilities). 1. Notwithstanding any provision to the contrary within this insurance agreement, this insurance agreement excludes any loss, damage, liability, claim, cost or expense of whatsoever nature, directly or indirectly caused by, contributed to by, resulting from, a rising out of, or in connection with a Communicable Disease or the fear or threat (whether actual or perceived) of a Communicable Disease regardless of any other cause or event contributing concurrently or in any other sequence thereto. 2. As used herein, a Communicable Disease means any disease which can be transmitted by means of any substance or agent from any organism to another organism where:</p>
            <p>2.1. the substance or agent includes, but is not limited to, a virus, bacterium, parasite or other organism or any variation thereof, whether deemed living or not, and</p>
            <p>2.2. the method of transmission, whether direct or indirect, includes but is not limited to, airborne transmission, bodily fluid transmission, transmission from or to any surface or object, solid, liquid or gas or between organisms, and</p>
            <p>2.3. the disease, substance or agent can cause or threaten damage to human health or human welfare or can cause or threaten damage to, deterioration of, loss of value of, marketability of or loss of use of property. Notwithstanding the foregoing, losses resulting directly or indirectly from, or caused by, contributed to by, resulting from, arising out of, or in connection with any otherwise covered peril under the Policy and not otherwise excluded under this insurance agreement shall be covered.</p>
            <p>Notwithstanding anything to the contrary stated in this policy below conditions shall be applicable for Return/ Used / Second Hand/ Scrap/Rejected items: (a) All pre-existing losses or damages prior to commencement of transit under the policy are specifically excluded from the scope of cover. (b) Coverage shall be subject to Institute Cargo Clause &apos;B&apos; + War &amp; Strikes / Inland Transit Clause &lsquo;B&rsquo; + Strike Riots Civil Commotion + Non-Delivery of the consignment on the conveyance, as applicable (c) Basis of Valuation applicable shall be: Depreciated Market Value. (d) Replacement (second-hand) Machinery Clause : In the event of claim for loss or damage to any part or parts of the Insured Interest in consequence of a peril covered by this insurance, the amount recoverable here under shall not exceed such proportion of the cost of replacement of the parts lost or damaged as the insured value bears to the value of new machinery, plus additional charges for forwarding and refitting the new parts if incurred. Provided always that in no case shall the liability of Underwriters exceed the insured value of the complete machine. (d) In case mode of conveyance under the policy also cover transits through air then, such type of cargoes will be covered for loss or damage where proximate cause is due to Fire, Explosion, Lightning, Collision with or by the carrying vehicle/aircraft, Overturning of the carrying vehicle/aircraft, Derailment or accidents of like nature to the carrying railway wagon/ vehicle/aircraft, Strike Riots Civil Commotion and Non Delivery of the consignment on the conveyance Excluding loss and damage to the software from the scope of the policy.</p>
            <div><b>JOINT EXCESS LOSS CYBER LOSSES CLAUSE (JX2020-007) </b></div>
            <p>1. Notwithstanding any other term of this Contract save for Clause 2 below, in no case shall this Contract cover loss, damage, liability, or expense directly or indirectly caused by or contributed to by or arising from the use or operation of an Information Technology Device as a means for inflicting harm.</p>
            <p>2. Where this clause is endorsed on policies covering risks of war, civil war, revolution, rebellion, insurrection, or civil strife arising therefrom, or any hostile act by or against a belligerent power, or terrorism or any person acting from a political motive, Clause 1 shall not exclude losses which would otherwise be covered arising from the use of any Information Technology Device in the launch and/or guidance system and/or firing mechanism of any weapon or missile.</p>
            <p>3.Where the use or operation of an Information Technology Device was not used as a means of inflicting harm, any ensuing loss, damage, liability or expense shall be excluded unless a physical peril named in the Information Technology Hazards Clause within JELC CL432 (16/10/2017) was also a significant cause of a loss. In such case this Contract shall cover the loss, damage, liability or expense in accordance with but only to the extent provided for in that Clause, whether that Clause forms part of this Contract or not.</p>
            <p>4. For the purposes of this clause, an Information Technology Device shall mean any computer system, hardware, software, programme, code, data, process, virus, information repository, microchip, integrated circuit or similar device in or connected with computer equipment or non-computer equipment, whether the property of a direct insured or not.</p>
            <p>In case transit is through post, courier or registered post parcel no claim for loss /damage will be admitted if proved to be due to incorrect/ambiguous insufficient address on the package or having been unaccepted by the addressee.</p>
            <p>ADDITIONAL CONDITIONS APPLICABLE FOR TAIL-END RISKS OF CIF/CIP IMPORTS or where the overseas leg is not required to be insured by the Importer: Notwithstanding anything to the contrary stated in this policy below conditions shall be applicable: (a) All pre-existing damages prior to commencement of the transit under the policy are specifically excluded. (b) Coverage shall be subject to Inland Transit Clause &lsquo;B&rsquo; + SRCC + Non-delivery of the carrying conveyance + Act of God (AOG) perils. (c) In case mode of conveyance under the policy also cover transits through air then subject matter insured will be covered for Fire, Explosion, Lightning, Collision with or by the carrying vehicle/aircraft, Overturning of the carrying vehicle/aircraft, Derailment or accidents of like nature to the carrying railway wagon/ vehicle/aircraft, Strike Riots Civil Commotion, Non Delivery of the consignment on the conveyance and Act of God (AOG) perils Notwithstanding to the contrary, Duration Clause (6.1.6) under the Inland Transit Clause (2010) is deleted and replaced with &quot;in respect of transit by Air only until expiry of 30 days after unloading the subject- matter insured from the aircraft at the final place of discharge&quot;</p>
            <p>In case claim experience declared by the client at the time of quoting or gets deteriorated post quoting, the same should be declared to the Insurer, failing which Insurer shall be absolved of all liabilities ab-initio Excluding mechanical, electrical, electronic, electro-mechanical and internal derangement losses from scope of cover of the policy unless the loss or damage is caused due to Institute Cargo Clauses - B (2009) perils or Inland Transit Clause - B (2010) perils, as applicable. In case mode of conveyance under the policy also cover transits through air then, such losses will be covered where proximate cause of loss is due to : Fire, Explosion, Lightning, Collision with or by the carrying vehicle/aircraft, Overturning of the carrying vehicle/aircraft, Derailment or accidents of like nature to the carrying railway wagon/ vehicle/aircraft Excluding damage to labels unless the goods themselves are damaged at the same time. Excluding bulk cargo, break-bulk cargo and road tanker movements from the scope of the policy. Warranted that if the weight of the cargo exceeds than Registration Laden weight/ Licensed Carrying Capacity of the vehicle, as mentioned in the Registration Certificate of the vehicle, then any loss or damage attributable to overloading is not covered under the policy, unless at the time of loading, the assured or its employees were not privy to such overloading where such loading is taking place after the attachment of risk as per the Duration Clause of the corresponding Institute Cargo Clauses (2009) and /or Inland Transit Clauses (2010) as applicable . Warranted that the Goods are transported in Closed Wagons/Trucks or Trucks to be covered with Tarpaulin or any other water proof Material. However, any loss due to Ingress of Rain Water/Moisture in Cargo because of Improper Precaution / Faulty Tarpaulin / Material used by transporter for which client is not Privy is Considered to be covered.</p>
            <p>Excluding Shortages from Seal intact containers and/or sound packing and/or unexplained shortages Where any item insured under this policy consists of articles in a pair or set, the Company&rsquo;s liability shall not exceed the value of any particular part or parts which may be lost or damaged without reference to any special value which such articles may have as part of such pair or set and in any event not more than a proportionate part of the insured value of the pair or set of Valuation stated in the Policy.(not applicable for Project policies)</p>
            <p>Excluding Denting, Bending, Scratching, Chipping, Damage to Paint &amp; Polish work unless caused by Inland Transit Clause (B)/ Institute Cargo Clause (B) perils</p>
            <p>Excluding Rusting, oxidization, discoloration, unless caused by Inland Transit Clause (B)/ Institute Cargo Clause (B) perils</p>
            <div><b>EXCESS</b></div>
            <p>DOMESTIC : 1 % of consignment value or INR 10,000 whichever is higher for each and every claim.</p>
        </div>
    </div>
    <div class="modal-footer  text-center nobd mx-auto pb-4 mb-3">
        <button type="button" class="btn-d-outline d-inline-block cancel-outline mr-3"
            (click)="modal.close('Close click')">Close</button>
        <button type="button" class="btn buybtn fs16 radius10" *ngIf="!enable_insurance" (click)="enableinsurance('yes', contentinsurance)">{{
            !enable_insurance? 'Agree and Apply':'Remove insurance' }}</button>
    </div>
</ng-template>
<!-- /Insurance Details MODAL -->


<app-footer-one></app-footer-one>
<app-signup-modal #signIn></app-signup-modal>