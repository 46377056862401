import { Component, OnInit, ViewChild } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'src/app/helpers/toastr.service';

//Chart - start
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexStroke,
  ApexGrid
} from "ng-apexcharts";
import { PaginationService } from 'src/app/helpers/pagination.service';
import { PendingOrdersService } from '../pending-orders/pending-orders.service';
import { SubSellerDashboardService } from './subsellerdashboard.service';
import { HeaderOneService } from 'src/app/shared/header/header-one/header-one.service';
import { SignupService } from 'src/app/shared/components/modal/signup-modal/signup-modal.service';
import { OrderHistoryService } from '../order-history/order-history.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-subseller-dashboard',
  templateUrl: './subseller-dashboard.component.html',
  styleUrls: ['./subseller-dashboard.component.scss']
})
export class SubsellerDashboardComponent implements OnInit {

    //chart - start
    @ViewChild("chart") chart: ChartComponent;
    public chartOptions: any = {};
    //chart - end

    closeResult = '';
    public user_name:string = '';

    //Months Array
    public months_array:any = [];

    //Filter
    public sales_overview_filter:any = 1;
    public pending_order_filter:any = 1;
    public order_filter:any = 1;

    //Sales Overview
    public total_sales:string;
    public total_orders:number;

    //Pending Orders
    public total_pending:number;
    public bulk_orders:number;
    public wholesale_orders:number;
    public rejected_orders:number;

    //Orders
    public orders_details:any = {};

    //Seller Orders
    public seller_orders: any = [];
    public seller_current_page: number = 1;
    public seller_offset: number = 0;
    public seller_page_per_view: number = 10;
    public seller_order_pagination: any = {};
    public search_term:string = '';
    public from_date:any = '';
    public to_date:any = '';
    
    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];

    config = {
		labelField: 'label',
		valueField: 'value',
  	};
    
    public pending_orders_records:any = [];
    public current_page:number = 1;
    public offset:number = 0;
    public page_per_view:number = 10;
    public pending_order_pagination:any = {};

    constructor(
        private modalService: NgbModal,
        private _sellerdashboardservice: SubSellerDashboardService,
        private _toastr: ToasterService,
        private _pendingorderservice: PendingOrdersService,
        private _pagination: PaginationService,
        public _headerservice: HeaderOneService,
        public _signupservice: SignupService,
        private _orderhistoryservice: OrderHistoryService,
    ) {}

    open(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'mblbtm' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    getuserdetails() {
        this._headerservice.getuserdetails(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.user_name = data['data']['name']
            }
        }, (error) => {

        });
    }

    getdashboardmonths(){
        this._sellerdashboardservice.dashboardmonths(null).subscribe((data) => {
            if(data['status'] == "success"){
                this.months_array = data['month'];
                this.mydashboarddetails('sales_overview');
                this.mydashboarddetails('pending_order');
                this.mydashboarddetails('orders');
                this.getpendingorders();
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    mydashboarddetails(type){
        let month = 0;
        if(type == 'sales_overview'){
            month = this.sales_overview_filter;
        }else if(type == 'pending_order'){
            month = this.pending_order_filter;
        }else if(type == 'orders'){
            month = this.order_filter;
        }
        let filter = this.months_array[month-1];
        this._sellerdashboardservice.dashboarddetails({type:type, month: filter}).subscribe((data) => {
            if(data['status'] == "success"){
                if(type == 'sales_overview'){

                    this.total_orders = data['total_order'];
                    this.total_sales = data['total_sales'];
                    //Chart - start
                    this.chartOptions = {
                        series: [
                            {
                                name: "Sales",
                                data: data['weekly_avg']['weekly_avg_sales']
                            }
                        ],
                        chart: {
                            height: 350,
                            type: "line",
                            zoom: {
                                enabled: false
                            }
                        },
                        dataLabels: {
                            enabled: false
                        },
                        stroke: {
                            curve: "straight"
                        },
                        title: {
                            text: "Average Order Value: "+data['avg_amount'],
                            align: "left"
                        },
                        grid: {
                            row: {
                                colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                                opacity: 0.5
                            }
                        },
                        xaxis: {
                            categories: data['weekly_avg']['weekly_avg_month']
                        }
                    };
                    //Chart - end
                }else if(type == 'pending_order'){
                    this.total_pending = data['total_pending'];
                    this.bulk_orders = data['bulk'];
                    this.wholesale_orders = data['wholesale'];
                    this.rejected_orders = data['rejected_order'];
                }else if(type == 'orders'){
                    this.orders_details = data;
                }
                
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }



    //Pending order Table
    getpendingorders(){
        let limit = this.page_per_view;
        let offset = this.offset;
        let current_page = this.current_page;
        let data = {
            limit: limit,
            offset: offset,
            current_page: current_page,
            search: '',
            sort: 'latest'
        };
        this._pendingorderservice.getpendingorders(data).subscribe((data) => {
            if(data['status'] == "success"){
                let total_records = data['total_records'];
                this.pending_order_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
                this.pending_orders_records = data['records'];
            }else if(data['status'] == "error"){
              this._toastr.error(data['message']);
            }
        });
    }

    acceptorder(record){
        this._pendingorderservice.acceptorder({id: record.id}).subscribe((data) => {
            if(data['status'] == "success"){
                this._toastr.success('Order has been accepted successfully.');
                this.getpendingorders();
            }else if(data['status'] == "error"){
              this._toastr.error(data['message']);
            }
        });
    }

    rejectorder(record){
        this._pendingorderservice.rejectorder({id: record.id}).subscribe((data) => {
            if(data['status'] == "success"){
                this._toastr.success('Order has been accepted successfully.');
                this.getpendingorders();
            }else if(data['status'] == "error"){
              this._toastr.error(data['message']);
            }
        });
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page-1)*this.page_per_view;
        this.getpendingorders();
    }

    setpageperview(){
        this.current_page = 1;
        this.getpendingorders();
    }



    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    openmdl(profilephoto) {
        this.modalService.open(profilephoto, { windowClass: 'midmodal' });
    }
  
    ngOnInit(): void {
        this.getdashboardmonths();
        this.getuserdetails();
        this.sellerorders();
    }

    sellerorders() {
        let data = { 
            limit: this.page_per_view, 
            offset: this.seller_offset, 
            current_page: this.seller_current_page, 
            search: this.search_term,
            from_date: this.from_date,
            to_date: this.to_date
        };

        this._orderhistoryservice.subsellerorders(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.seller_orders = data['records'];
                let total_records = data['total_records'];
                this.seller_order_pagination = this._pagination.getPager(total_records, this.seller_current_page, this.page_per_view);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getsellerordersfilters(){
        this.page_per_view = 10;
        this.seller_offset = 0;
        this.seller_current_page = 1;
        this.sellerorders();
    }

    

    setsellerpage(page) {
        this.seller_current_page = page;
        this.seller_offset = (page-1)*this.page_per_view;
        this.sellerorders();
    }

    setsellerpageperview(){
        this.seller_current_page = 1;
        this.sellerorders();
    }

}
