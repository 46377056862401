<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Settings</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" [routerLink]="['/admin/settings/access-management']">Access Management</a>
						</li>
						<li class="breadcrumb-item active">Grant Access</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<!--- /HEAD  --->
		<div class="row d-flex align-items-center mb-10">
			<div class="col-md-6">
				<h3 class="f-700 fs22 txtdefault pb-0"><a href="javascript:;" class="go-back"><i class="fa fa-angle-left"></i></a> Grant Access</h3>
			</div>
		</div>
		<div class="card cardui carduipro p-4 mb-20">
			<div class="form-container mdl-frm-lbl mb-3">
				<div class="row">
					<div class="col-md-12">
						<div class="floating-label mb-4">      
						  <input value="Shubham Jaiswal" type="text" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>Name</label>
						</div> 
					</div>
					<div class="col-md-6">
						<div class="floating-label mb-4">      
						  <input value="abhaydas@gmail.com" type="text" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>Email ID</label>
						</div> 
					</div>
					<div class="col-md-6">
						<div class="floating-label mb-4">      
						  <input value="9876504321" type="text" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>Mobile Number</label>
						</div> 
					</div>
				</div>
			</div>

			<h4 class="fs18 f-700 mb-20">Access Type</h4>
			<div class="d-flex align-items-center mb-20">
              <div class="custom-check">
                 <div class="css-check">
                    <input class="css-checkbx" type="checkbox" />
                    <label class="css-label">
                    &nbsp;
                    </label>
                 </div>
              </div>
              <div>Select All</div>
           </div>

           <div class="custom-control custom-checkbox mb-10">
				<input type="checkbox" class="custom-control-input"
					id="agrcustomCheckBox" name="checkbox1">
				<label class="custom-control-label" for="agrcustomCheckBox">
					User Management
				</label>
			</div>

			<div class="custom-control custom-checkbox mb-10">
				<input type="checkbox" class="custom-control-input"
					id="agrcustomCheckBox" name="checkbox1">
				<label class="custom-control-label" for="agrcustomCheckBox">
					Order Management
				</label>
			</div>

			<div class="custom-control custom-checkbox mb-10">
				<input type="checkbox" class="custom-control-input"
					id="agrcustomCheckBox" name="checkbox1">
				<label class="custom-control-label" for="agrcustomCheckBox">
					Product Management
				</label>
			</div>

			<div class="custom-control custom-checkbox mb-10">
				<input type="checkbox" class="custom-control-input"
					id="agrcustomCheckBox" name="checkbox1">
				<label class="custom-control-label" for="agrcustomCheckBox">
					Logistics Management
				</label>
			</div>


			<div class="mb-20">
				<p>By granting access you agree with <a href="javascript:;" class="link-alt fs14">Management Access Agreement</a></p>
			</div>

			<div class="mt-0"><input type="submit" value="Grant Access" class="btn px-4 buybtn fs16 radius10 "/></div>
		</div>
	</div>
</div>
