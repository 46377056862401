import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BuyerCategoryCmsService {

  constructor(private _http: HttpClient) { }

    getrecords(data) {
        return this._http.post(environment.api_url+'master/brand/list',data);
    }

    getcategories(data) {
        return this._http.post(environment.api_url+'master/brand/delete',data);
    }

    getcategorycmsdata(data) {
        return this._http.post(environment.api_url+'admin/cms/category/product',data);
    }

    searchcategoryproduct(data) {
        return this._http.post(environment.api_url+'admin/cms/category/product/search',data);
    }

    savecategoryproductcms(data) {
        return this._http.post(environment.api_url+'admin/cms/category/product/add',data);
    }

    uploadcategorybanner(data) {
        return this._http.post(environment.api_url+'admin/cms/category/product/add/banner',data);
    }

    // getcategorycmsdata(data) {
    //     return this._http.post(environment.api_url+'master/brand/delete',data);
    // }

}
