<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
    <div class="container pb-4">
        <!--- HEAD  --->
        <div class="row align-items-center mb-4 pinfo-head">
            <div class="col-sm-12">
                <nav class="breadcrumb breadcrumb-dui mb-0">
                    <ol class="breadcrumb p-0 bg-transparent  mb-0">
                        <li class="breadcrumb-item">
                            <a class="txtseconday" href="javascript:void(0);"
                                [routerLink]="['/admin/product-listing']">Product Management</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a class="txtseconday" [routerLink]="['/admin/brandmanagment']">Brands Management</a>
                        </li>
                        <li class="breadcrumb-item active">Edit Brand</li>
                    </ol>
                </nav>
            </div>
        </div>
        <!--- /HEAD  --->
        <div class="row d-flex align-items-center mb-20">
            <div class="col-md-12">
                <h3 class="f-700 fs22 txtdefault pb-0">
                    <a href="javascript:;" [routerLink]="['/admin/brandmanagment']" class="go-back"><i
                            class="fa fa-angle-left"></i></a> Edit Brand
                </h3>
            </div>
        </div>
        <!-- CARD -->
        <form [formGroup]="brandForm">
            <div class="card card-ui p-0 profileipt ">
                <div class="card-body add-pro-wrap pt-0 p-4">
                    <div class="form-container">
                        <!-- INPUT ROW -->
                        <div class="row align-items-center">
                            <div class="col-sm-2 imgwidthcol mb-4">
                                <div class="brndlg">
                                    <img class="w-100" src="{{ brand_logo_image?brand_logo_image: '' }}" />
                                    <a href="javascript:void(0);">
                                        <input type="file" class="upldfile" (change)="uploadfile($event)" />
                                        <img class="w-100" src="assets/images/admin/lgupld.png" />
                                    </a>
                                </div>
                            </div>
                            <div class="col  mb-4">
                                <div class="floating-label pr-0  mb-0">
                                    <input placeholder=" " class="form-control" formControlName="brand_name">
                                    <span class="highlight"></span>
                                    <label>Brand Name</label>
                                    <i placement="top" ngbTooltip="Tooltop content goes here"
                                        class="infoinput info-alert"></i>
                                    <span class="help-block"
                                        *ngIf="bf.brand_name.invalid && bf.brand_name.touched">Please enter brand
                                        name</span>
                                </div>
                            </div>
                        </div>
                        <!-- /INPUT ROW -->
                        <!-- INPUT ROW -->
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="floating-label mb-4 flselcted">
                                    <div class="position-relative">

                                        <div class="selected-categories">
                                            <ul *ngIf="categoryListName.length > 0">
                                                <li *ngFor="let cat of categoryListName;">{{ cat }}<a
                                                        href="javascript:;" (click)="removecategory(cat)">x</a></li>
                                            </ul>
                                        </div>
                                        <i placement="top" ngbTooltip="Tooltop content goes here"
                                            class="infoinput info-alert"></i>
                                        <span class="help-block"
                                            *ngIf="bf.categories.invalid && bf.categories.touched">Please select
                                            categories</span>
                                    </div>
                                    <button class="btn btn-outline-secondary fs16 btn-o-default" type="button"
                                        (click)="openeditcategory(editcategory)">
                                        <svg class="d-inline-block" xmlns="http://www.w3.org/2000/svg" width="14.681"
                                            height="14.681" viewBox="0 0 14.681 14.681" class="plus">
                                            <g id="Icon_feather-plus" data-name="Icon feather-plus"
                                                transform="translate(1 1)">
                                                <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181"
                                                    transform="translate(-11.659 -7.5)" fill="none" stroke="#ff7135"
                                                    stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181"
                                                    transform="translate(-7.5 -11.659)" fill="none" stroke="#ff7135"
                                                    stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                            </g>
                                        </svg>
                                        <span>
                                            Add New
                                        </span>
                                    </button>
                                    <span class="highlight"></span>
                                    <label>Categories</label>

                                </div>
                            </div>
                        </div>

                        <!-- /INPUT ROW -->
                        <!-- INPUT ROW -->
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="floating-label mb-4  pr-0">
                                    <input placeholder=" " class="form-control" value=""
                                        formControlName="brand_website">
                                    <span class="highlight"></span>
                                    <label>Brand website link</label>
                                    <i placement="top" ngbTooltip="Tooltop content goes here"
                                        class="infoinput info-alert"></i>
                                    <span class="help-block"
                                        *ngIf="bf.brand_website.invalid && bf.brand_website.touched">Please enter valid
                                        brand website</span>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="floating-label  mb-4  pr-0">
                                    <input placeholder=" " class="form-control" value=""
                                        formControlName="service_centers">
                                    <span class="highlight"></span>
                                    <label>Brand Service Center Link </label>
                                    <i placement="top" ngbTooltip="Tooltop content goes here"
                                        class="infoinput info-alert"></i>
                                    <span class="help-block"
                                        *ngIf="bf.service_centers.invalid && bf.service_centers.touched">Please enter
                                        valid brand
                                        service center link</span>
                                </div>
                            </div>
                        </div>
                        <!-- /INPUT ROW -->
                        <!-- INPUT ROW -->
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="floating-label  mb-4  pr-0">
                                    <input placeholder=" " class="form-control" value=""
                                        formControlName="owner_company_name">
                                    <span class="highlight"></span>
                                    <label>Manufacturer Name and address </label>
                                    <i placement="top" ngbTooltip="Tooltop content goes here"
                                        class="infoinput info-alert"></i>
                                    <span class="help-block"
                                        *ngIf="bf.owner_company_name.invalid && bf.owner_company_name.touched">Please
                                        enter
                                        brand
                                        owner company name</span>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <ng-selectize [config]="config" formControlName="country_of_origin"
                                    [options]="countryArray" placeholder="Location of the Company"></ng-selectize>
                                <span class="help-block"
                                    *ngIf="bf.country_of_origin.invalid && bf.country_of_origin.touched">Please select
                                    location of the company</span>
                            </div>
                        </div>
                        <!-- /INPUT ROW -->
                        <div class="mt-20">
                            <button type="button" class="btn buybtn fs16 px-4 mr-4 radius10 ripple" (click)="brandsubmit()" [disabled]="brandLoading || brandForm.invalid">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="brandLoading"></span>
                                &nbsp;Update
                            </button>

                            
                            <a class="cancel-outline btn-d-outline d-inline-block px-4" href="javascript:void(0);"
                                [routerLink]="['/admin/brandmanagment']">Cancel</a>
                        </div>
                    </div>

                    <div class="fs12 mt-20">Note: Recommended category image resolution is 180px x 50px</div>
                </div>
            </div>
        </form>
        <!-- /CARD -->
    </div>
</div>


<!-- Edit Category -->
<ng-template #editcategory let-modal>
    <div class="modal-body mdlcmnui pt-4 mt-3">
        <h5 class="modal-title text-center mb-20">Select Category</h5>

        <div class="text-right mt-20 mb-10">
            <a href="javascript:;" class="link-alt color-orange">Product Categories</a>
        </div>
        <div class="row mt-10">
            <div class="col-md-12">
                <div class="form-container mdl-frm-lbl mb-4 mt-0">
                    <div class="floating-label mb-0">
                        <div class="ng-autocomplete">
                            <ng-autocomplete [data]="result" [searchKeyword]="keyword" (selected)='selectEvent($event)'
                                (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)'
                                [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate"
                                placeholder="Search by Category Name">
                            </ng-autocomplete>
                            <ng-template #itemTemplate let-item>
                                <a href="javascript:void(0)">
                                    <div class="d-flex">
                                        <span [innerHTML]="item.name"></span>
                                        <!-- <span [innerHTML]="item.current" class="current"></span> -->
                                    </div>
                                </a>
                            </ng-template>

                            <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                            </ng-template>
                            <div class="search-icon"><img src="assets/images/svg/search_logo.svg"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>