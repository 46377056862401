import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AdminPersonalDetailsService {

    constructor(private _http: HttpClient) { }

    userbasicinfo(data) {
        return this._http.post(environment.api_url+'admin/userbasicinfo',data);
    }

    uploadprofileimage(data){
        return this._http.post(environment.api_url+'admin/updateprofileimage',data);
    }

    changepassword(data){
        return this._http.post(environment.api_url+'admin/changepassword',data);
    }

    changevalue(data){
        return this._http.post(environment.api_url+'admin/changeprofilebyotp',data);
    }

    changeprofile(data){
        return this._http.post(environment.api_url+'admin/changeprofile',data);
    }

    
}