<app-header-seller></app-header-seller>
<app-sidebar-seller></app-sidebar-seller>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<h3 class="fs24 wt700 txtdefault">My Wallet</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Payments</a>
						</li>
						<li class="breadcrumb-item active">My Wallet</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:void(0);" [routerLink]="['/seller/assistance']" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4 mb-40">
			<div class="rg-b-form">
				<div class="form-container input-group mt-0 mb-10">
					<div class="floating-label mb-0">      
						<input type="text" value="{{ wallet_details.wallet_balance }}" disabled class="form-control" placeholder=" " />
						<span class="highlight"></span>
						<label>Wallet Balance</label>
					</div> 
				</div>
			</div>
			<div class="rg-b-form" *ngIf="wallet_details.unclearbalance">
				<div class="form-container mt-10 mb-0">
					<div class="floating-label mb-0">      
						<input type="text" value="{{ wallet_details.unclearbalance }}" disabled class="form-control" placeholder=" " />
						<span class="highlight"></span>
						<label>Uncleared</label>
					</div> 
				</div>
			</div>
			<div class="form-container mdl-frm-lbl mb-3">
				<div class="row">
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input value="{{ wallet_details.user_id }}" disabled type="text" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>UserID</label>
						</div> 
					</div>
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input value="{{ wallet_details.account_number }}" disabled type="text" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>Your Equibiz Account Number</label>
						</div> 
					</div>	
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input value="{{ wallet_details.name }}" disabled type="text" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>Beneficiary Name</label>
						</div> 
					</div>
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input value="{{ wallet_details.ifsc }}" disabled type="text" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>Your Equibiz IFSC</label>
						</div> 
					</div>	
				</div>
				<div class="text-center md-block-flex-justify">
					<!-- <a href="javascript:void(0);" class="mr-sm-4 btn-d-outline d-inline-block">Add Money</a> -->
					<a href="javascript:void(0);" class="mr-sm-4 btn-d-outline d-inline-block" [routerLink]="['/seller/transaction-history']">View Your Transactions</a>
					<a href="javascript:void(0);" class="btn-d-outline d-inline-block" [routerLink]="['/seller/bank-details']">View Bank Details</a>
				</div>
			</div>
		</div>

		<div class="mb-2 faqpanel accordionarrow">
			<h4 class="f-700 fs22 txtdefault pb-10">Frequently Asked Questions</h4>
			<ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
			  	<ngb-panel title="What is the Equibiz internal wallet account?">
					<ng-template ngbPanelContent>
					  <p class="txtdefault fs16 lnht24 mb-0">It is a digital construct of the nodal (A type of Escrow) account to aggregate all your funding and payment in one place. Your wallet account number is mapped with your bank account provided at the time of registration.</p>
					</ng-template>
			  	</ngb-panel>
			  	<ngb-panel title="Can this wallet balance be used for making payments outside the Equibiz platform?">
					<ng-template ngbPanelContent>
					  <p class="txtdefault fs16 lnht24 mb-0">No, you can use the wallet balance to make a purchase over the Equibiz platform only.</p>
					</ng-template>
			  	</ngb-panel>
			  	<ngb-panel title="What are the advantages of using Equibiz internal wallet for making purchase payments?">
					<ng-template ngbPanelContent>
					 <p class="txtdefault fs16 lnht24 mb-0">The wallet funding charges using internet banking are free.</p>
					</ng-template>
			  	</ngb-panel>
			  	<ngb-panel title="How do I fund my Equibiz wallet account?">
					<ng-template ngbPanelContent>
					  <p class="txtdefault fs16 lnht24 mb-0">You can use internet banking to transfer funds to your wallet account. You must register your Equibiz wallet account details as a beneficiary through internet banking. You can then transfer the amount to the wallet account before you initiate any purchase.</p>
					</ng-template>
			  	</ngb-panel>
			  	<ngb-panel title="Is Equibiz wallet compulsory as a payment option for purchase?">
					<ng-template ngbPanelContent>
					  <p class="txtdefault fs16 lnht24 mb-0">No, you can use any of the listed payment options. In the case of other payment options, you may have to pay certain transaction charges, displayed at the time of payment.</p>
					</ng-template>
			  	</ngb-panel>
			</ngb-accordion>
		</div>

		<div class="mb-60">
			<img src="assets/images/card-payments.png">
		</div>
	</div>
</div>

<app-footer-seller></app-footer-seller>
