<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
    <div class="mobile-fix-option"></div>
    <div class="">
        <div class="equibiz-header">
            <div class="logo-bar">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7">
                            <a [routerLink]="['/home']"><img src="assets/images/svg/eq_logo.svg"></a>
                        </div>
                        <div class="col-md-5">
                            <div class="text-right">
                                <!--a href="javascript:;" class="link-alt">Know Why?</a-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<section class="small-section">
    <div class="container rg-b-form">
        <aw-wizard>
            <form [formGroup]="businessDetailsForm">
                <!-- Business Details  -->
                <aw-wizard-step stepTitle="Business Details">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="section-title f-600 text-left mb-10">Business Details <i placement="bottom"
                                    ngbTooltip="Enter your business details such as GST number, PAN number, etc. for completing registration"
                                    class="info-alert"></i></div>
                            <div class="bg-container-one mb-3">
                                <div class="form-container mt-0 mb-0 gstn-rt-ipt">
                                    <div class="floating-label mb-10">
                                        <input class="form-control" type="text" placeholder=" " formControlName="gstin"
                                            (change)="gstdetails()"
                                            [readonly]="businessDetailsForm.get('no_gst').value">
                                        <span class="highlight"></span>
                                        <label>Enter GSTIN</label>
                                        <div class="form-text-overlay"><span>GSTIN will auto-fill the business
                                                information</span></div>
                                        <span class="help-block" *ngIf="bd.gstin.invalid && bd.gstin.touched">GSTIN
                                            number invalid</span>
                                    </div>
                                </div>
                                <div class="custom-control custom-checkbox mb-0" *ngIf="userType == 'buyer'">
                                    <input type="checkbox" class="custom-control-input" id="customCheckBox"
                                        name="no_gst" formControlName="no_gst" (change)="isGstChange($event)">
                                    <label class="custom-control-label" for="customCheckBox">
                                        I do not have a GSTIN
                                    </label>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-container mb-0">
                                            <div class="floating-label mb-0">
                                                <input class="form-control" type="text" placeholder=" "
                                                    formControlName="business_name">
                                                <span class="highlight"></span>
                                                <label>Business Name<span class="req">*</span></label>
                                                <span class="help-block"
                                                    *ngIf="bd.business_name.invalid && bd.business_name.touched">enter
                                                    business name</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-container mb-0">
                                            <div class="floating-label mb-0 cstm-slct-wrp">
                                                <select class="form-control" formControlName="business_category">
                                                    <option selected="selected" value="">Please Select</option>
                                                    <option *ngFor="let item of businessCategoryArray"
                                                        [ngValue]="item.value">{{item.name}}</option>
                                                </select>
                                                <span class="highlight"></span>
                                                <label>Select Business Category<span class="req">*</span></label>
                                                <span class="help-block"
                                                    *ngIf="bd.business_category.invalid && bd.business_category.touched">select
                                                    business category</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-container mb-0">
                                            <div class="floating-label mb-0 cstm-slct-wrp">
                                                <select class="form-control" formControlName="business_type">
                                                    <option selected="selected" value="">Please Select</option>
                                                    <option *ngFor="let item of businessTypeArray"
                                                        [ngValue]="item.value">{{item.name}}</option>
                                                </select>
                                                <span class="highlight"></span>
                                                <label>Select Business Type<span class="req">*</span></label>
                                                <span class="help-block"
                                                    *ngIf="bd.business_type.invalid && bd.business_type.touched">select
                                                    business type</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-container mb-0">
                                            <div class="floating-label mb-0">
                                                <input class="form-control" type="text" placeholder=" "
                                                    formControlName="previous_year_turnover">
                                                <div class="form-text-overlay">
                                                    <span>(in Cr.)</span>
                                                </div>
                                                <span class="highlight"></span>
                                                <label>Previous Year Turnover<span class="req">*</span></label>
                                                <span class="help-block"
                                                    *ngIf="bd.previous_year_turnover.invalid && bd.previous_year_turnover.touched">enter
                                                    previous year trunover (in Cr.)</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordionarrow">
                                <ngb-accordion #a="ngbAccordion" activeIds="docverify, regadrs, prsntofc">
                                    <!-- DOCUMENT VERIFICATION  -->
                                    <ngb-panel id="docverify">
                                        <ng-template ngbPanelHeader *ngIf="!businessDetailsForm.get('no_gst').value">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h4 class="fs18 f-600 mb-0 txtdefault">Document Verificaion <i
                                                        placement="top" ngbTooltip="Document Verificaion"
                                                        class="info-alert"></i></h4>
                                                <button ngbPanelToggle class="btn btn-link text-left pl-0"></button>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div class="bg-container-one">
                                                <!-- REPEAT  -->
                                                <div class="form-container input-group mt-0 mb-3"
                                                    *ngIf="!businessDetailsForm.get('no_gst').value">
                                                    <div class="floating-label mb-0">
                                                        <input class="form-control" type="text"
                                                            placeholder="Upload GST Document" disabled>
                                                        <span class="highlight"></span>
                                                        <label>Upload GST Document <span class="req">*</span></label>
                                                    </div>
                                                    <span class="input-group-addon">
                                                        <button class="btn-d-outline d-inline-block ripple "
                                                            type="button" id="button-addon2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="7.532"
                                                                height="15.065" viewBox="0 0 7.532 15.065">
                                                                <path id="Icon_material-attach-file"
                                                                    data-name="Icon material-attach-file"
                                                                    d="M17.005,4.924V12.8a2.739,2.739,0,1,1-5.478,0V4.239a1.712,1.712,0,0,1,3.424,0v7.19a.685.685,0,0,1-1.37,0V4.924H12.554v6.505a1.712,1.712,0,1,0,3.424,0V4.239a2.739,2.739,0,0,0-5.478,0V12.8a3.764,3.764,0,0,0,3.766,3.766c2.082,0,3.766-3.766,3.766-3.766V4.924Z"
                                                                    transform="translate(-10.5 -1.5)" fill="#ff7135" />
                                                            </svg>{{ gst_file?'Replace File':'Attach File'}}
                                                            <input type="file" value="" class="csfile"
                                                                (change)="uploadFile($event, 'gst', businessDetailsForm.get('gstin').value)">

                                                        </button>
                                                    </span>
                                                </div>

                                                <!-- /REPEAT  -->
                                                <!-- REPEAT  -->
                                                <div class="form-container input-group mt-0 mb-3 infoinpt"
                                                    *ngIf="!businessDetailsForm.get('no_gst').value">
                                                    <div class="floating-label mb-0">
                                                        <select class="form-control"
                                                            formControlName="gst_filing_frequency">
                                                            <option selected="selected" value="">Please Select</option>
                                                            <option *ngFor="let item of gstFilingArray"
                                                                [ngValue]="item.value">{{item.name}}</option>
                                                        </select>
                                                        <span class="highlight"></span>
                                                        <label>GST Filing Frequency <span class="req">*</span></label>
                                                        <i placement="top" ngbTooltip="GST Filing Frequency"
                                                            class="info-alert"></i>
                                                    </div>
                                                    <span class="input-group-addon gstflbtn">

                                                    </span>

                                                </div>
                                                <!-- /REPEAT  -->
                                                <!-- REPEAT  -->

                                                <div class="form-container input-group mt-0 mb-3 infoinpt"
                                                    *ngIf="!businessDetailsForm.get('no_gst').value">
                                                    <div class="floating-label mb-0">
                                                        <input class="form-control" placeholder="yyyy-mm-dd"
                                                            [formControlName]="'last_filed_gst_date'" ngbDatepicker
                                                            #d="ngbDatepicker" (click)="d.toggle()" autocomplete="off">
                                                        <span class="highlight"></span>
                                                        <label>Last Filed GST Date <span class="req">*</span></label>
                                                        <i placement="top" ngbTooltip="Last Filed GST Date"
                                                            class="info-alert"></i>
                                                        <span class="help-block"
                                                            *ngIf="bd.last_filed_gst_date.invalid && bd.last_filed_gst_date.touched">select
                                                            last gst date</span>
                                                    </div>
                                                    <span class="input-group-addon gstflbtn">

                                                    </span>
                                                    <span class="input-group-addon" style="display: none;">
                                                        <button class="btn-d-outline d-inline-block ripple "
                                                            type="button" id="button-addon2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="7.532"
                                                                height="15.065" viewBox="0 0 7.532 15.065">
                                                                <path id="Icon_material-attach-file"
                                                                    data-name="Icon material-attach-file"
                                                                    d="M17.005,4.924V12.8a2.739,2.739,0,1,1-5.478,0V4.239a1.712,1.712,0,0,1,3.424,0v7.19a.685.685,0,0,1-1.37,0V4.924H12.554v6.505a1.712,1.712,0,1,0,3.424,0V4.239a2.739,2.739,0,0,0-5.478,0V12.8a3.764,3.764,0,0,0,3.766,3.766c2.082,0,3.766-3.766,3.766-3.766V4.924Z"
                                                                    transform="translate(-10.5 -1.5)" fill="#ff7135" />
                                                            </svg>{{ gst_date_file?'Replace File':'Attach File'}}<input
                                                                type="file" value="" class="csfile"
                                                                (change)="uploadFile($event, 'last_gst_file', businessDetailsForm.get('last_filed_gst_date').value)"></button>
                                                    </span>
                                                </div>
                                                <!-- /REPEAT  -->

                                                <!-- REPEAT  -->

                                                <div class="form-container input-group mt-0 mb-3 infoinpt"
                                                    *ngIf="businessDetailsForm.get('no_gst').value">
                                                    <div class="floating-label mb-0">
                                                        <select class="form-control"
                                                            formControlName="business_document">
                                                            <option selected="selected" value="">Please Select</option>
                                                            <option *ngFor="let item of businessDocumentsArray"
                                                                [ngValue]="item.value">{{item.name}}</option>
                                                        </select>
                                                        <span class="highlight"></span>
                                                        <label>Upload Business Document</label>
                                                    </div>
                                                    <span class="input-group-addon">
                                                        <button class="btn-d-outline d-inline-block ripple "
                                                            type="button" id="button-addon2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="7.532"
                                                                height="15.065" viewBox="0 0 7.532 15.065">
                                                                <path id="Icon_material-attach-file"
                                                                    data-name="Icon material-attach-file"
                                                                    d="M17.005,4.924V12.8a2.739,2.739,0,1,1-5.478,0V4.239a1.712,1.712,0,0,1,3.424,0v7.19a.685.685,0,0,1-1.37,0V4.924H12.554v6.505a1.712,1.712,0,1,0,3.424,0V4.239a2.739,2.739,0,0,0-5.478,0V12.8a3.764,3.764,0,0,0,3.766,3.766c2.082,0,3.766-3.766,3.766-3.766V4.924Z"
                                                                    transform="translate(-10.5 -1.5)" fill="#ff7135" />
                                                            </svg>{{ business_document_file? 'Replace File':'Attach
                                                            File' }}<input type="file" value="" class="csfile"
                                                                (change)="uploadFile($event, 'nogst', businessDetailsForm.get('business_document').value)"></button>
                                                    </span>
                                                </div>
                                                <!-- /REPEAT  -->

                                                <!-- REPEAT  -->

                                                <div class="form-container input-group mt-0 mb-3">
                                                    <div class="floating-label mb-0">
                                                        <input class="form-control" type="text" placeholder=""
                                                            formControlName="pan_number">
                                                        <span class="highlight"></span>
                                                        <label>PAN Number <span class="req"
                                                                *ngIf="businessDetailsForm.get('no_gst').value">*</span></label>
                                                        <span class="help-block"
                                                            *ngIf="bd.pan_number.invalid && bd.pan_number.touched">enter
                                                            valid pan number</span>
                                                    </div>
                                                    <span class="input-group-addon">
                                                        <button class="btn-d-outline d-inline-block ripple "
                                                            type="button" id="button-addon2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="7.532"
                                                                height="15.065" viewBox="0 0 7.532 15.065">
                                                                <path id="Icon_material-attach-file"
                                                                    data-name="Icon material-attach-file"
                                                                    d="M17.005,4.924V12.8a2.739,2.739,0,1,1-5.478,0V4.239a1.712,1.712,0,0,1,3.424,0v7.19a.685.685,0,0,1-1.37,0V4.924H12.554v6.505a1.712,1.712,0,1,0,3.424,0V4.239a2.739,2.739,0,0,0-5.478,0V12.8a3.764,3.764,0,0,0,3.766,3.766c2.082,0,3.766-3.766,3.766-3.766V4.924Z"
                                                                    transform="translate(-10.5 -1.5)" fill="#ff7135" />
                                                            </svg>{{ pan_file?'Replace File':'Attach File'}}<input
                                                                type="file" value="" class="csfile"
                                                                (change)="uploadFile($event, 'pan', businessDetailsForm.get('pan_number').value)"></button>
                                                    </span>
                                                </div>
                                                <!-- /REPEAT  -->
                                                <!-- REPEAT  -->
                                                <div class="form-container input-group mt-0 mb-0 infoinpt">
                                                    <div class="floating-label mb-0">
                                                        <input class="form-control" type="text" placeholder=" "
                                                            formControlName="aadhar_card_id">
                                                        <i placement="top" ngbTooltip="Non mandatory" class="info-alert"
                                                            *ngIf="!businessDetailsForm.get('no_gst').value"></i>
                                                        <span class="highlight"></span>
                                                        <label>Upload Aadhar Card ID</label>

                                                        <span class="help-block"
                                                            *ngIf="bd.aadhar_card_id.invalid && bd.aadhar_card_id.touched">enter
                                                            valid aadhar card id</span>
                                                    </div>
                                                    <span class="input-group-addon">
                                                        <button class="btn-d-outline d-inline-block ripple "
                                                            type="button" id="button-addon2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="7.532"
                                                                height="15.065" viewBox="0 0 7.532 15.065">
                                                                <path id="Icon_material-attach-file"
                                                                    data-name="Icon material-attach-file"
                                                                    d="M17.005,4.924V12.8a2.739,2.739,0,1,1-5.478,0V4.239a1.712,1.712,0,0,1,3.424,0v7.19a.685.685,0,0,1-1.37,0V4.924H12.554v6.505a1.712,1.712,0,1,0,3.424,0V4.239a2.739,2.739,0,0,0-5.478,0V12.8a3.764,3.764,0,0,0,3.766,3.766c2.082,0,3.766-3.766,3.766-3.766V4.924Z"
                                                                    transform="translate(-10.5 -1.5)" fill="#ff7135" />
                                                            </svg>{{ aadhar_file?'Replace File':'Attach File'}}<input
                                                                type="file" value="" class="csfile"
                                                                (change)="uploadFile($event, 'aadhar', businessDetailsForm.get('aadhar_card_id').value)"></button>
                                                    </span>
                                                </div>

                                                <!-- /REPEAT  -->

                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                    <!-- /DOCUMENT VERIFICATION  -->
                                    <!-- REGISTERED ADDRESS DETAILS  -->
                                    <ngb-panel id="regadrs">
                                        <ng-template ngbPanelHeader>
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h4 class="fs18 f-600 mb-0 txtdefault">Registered Address Details <i
                                                        placement="top" ngbTooltip="Registered Address Details"
                                                        class="info-alert"></i></h4>
                                                <button ngbPanelToggle class="btn btn-link text-left pl-0"></button>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div class="bg-container-one ">
                                                <div class="form-container mt-0 mb-0">
                                                    <div class="floating-label mb-0">
                                                        <input class="form-control" type="text" placeholder=" "
                                                            formControlName="registered_office_address">
                                                        <span class="highlight"></span>
                                                        <label>Registered Office Address</label>
                                                        <span class="help-block"
                                                            *ngIf="bd.registered_office_address.invalid && bd.registered_office_address.touched">enter
                                                            office address</span>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-container mb-0">
                                                            <div class="floating-label mb-0 cstm-slct-wrp">
                                                                <select class="form-control"
                                                                    formControlName="registered_office_state"
                                                                    (change)="getcityandlocations()">
                                                                    <option selected="selected" value="">Please Select
                                                                    </option>
                                                                    <option *ngFor="let item of stateArray"
                                                                        [ngValue]="item.value">{{item.name}}</option>
                                                                </select>
                                                                <span class="highlight"></span>
                                                                <label>State<span class="req">*</span></label>
                                                                <span class="help-block"
                                                                    *ngIf="bd.registered_office_address.invalid && bd.registered_office_address.touched">select
                                                                    office state</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 ">
                                                        <div class="form-container mb-0">
                                                            <div class="floating-label mb-0 cstm-slct-wrp">
                                                                <select class="form-control"
                                                                    formControlName="registered_office_city"
                                                                    (change)="sameasregisteredoffice()">
                                                                    <option selected="selected" value="">Please Select
                                                                    </option>
                                                                    <option *ngFor="let item of cityArray"
                                                                        [ngValue]="item.value">{{item.name}}</option>
                                                                </select>
                                                                <span class="highlight"></span>
                                                                <label>City<span class="req">*</span></label>
                                                                <span class="help-block"
                                                                    *ngIf="bd.registered_office_city.invalid && bd.registered_office_city.touched">select
                                                                    office city</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 ">
                                                        <div class="form-container  mb-0">
                                                            <div class="floating-label mb-0">
                                                                <input class="form-control" type="text" placeholder=" "
                                                                    formControlName="registered_office_pincode">
                                                                <span class="highlight"></span>
                                                                <label>Pincode<span class="req">*</span></label> <span
                                                                    class="help-block"
                                                                    *ngIf="bd.registered_office_pincode.invalid && bd.registered_office_pincode.touched">select
                                                                    office pincode</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                    <!-- /REGISTERED ADDRESS DETAILS  -->
                                    <!-- SHIPPING ADDRESS DETAILS  -->
                                    <ngb-panel id="prsntofc">
                                        <ng-template ngbPanelHeader>
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h4 class="fs18 f-600 mb-0 txtdefault">Present Office/Shipping Address
                                                    <i placement="top" ngbTooltip="Present Office/Shipping Address"
                                                        class="info-alert"></i>
                                                </h4>
                                                <button ngbPanelToggle class="btn btn-link text-left pl-0"></button>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div class="bg-container-one ">
                                                <div class="form-container mt-0 mb-0">
                                                    <div class="floating-label mb-10 cstm-slct-wrp">
                                                        <select class="form-control" formControlName="primary_location">
                                                            <option selected="selected" value="">Please Select</option>
                                                            <option *ngFor="let item of cityArray"
                                                                [ngValue]="item.value">{{item.name}}</option>
                                                        </select>
                                                        <span class="help-block"
                                                            *ngIf="bd.primary_location.invalid && bd.primary_location.touched">select
                                                            primary location</span>
                                                        <span class="highlight"></span>
                                                        <label>Primary {{ userType_text }} Location<span
                                                                class="req">*</span></label>
                                                    </div>
                                                </div>
                                                <div class="custom-control custom-checkbox mb-0">
                                                    <input type="checkbox" class="custom-control-input"
                                                        id="acustomCheckBox" name="same_as_registered_office"
                                                        formControlName="same_as_registered_office"
                                                        (change)="sameasregisteredoffice()">
                                                    <label class="custom-control-label" for="acustomCheckBox">
                                                        Same as registered office &nbsp;<i *ngIf="userType=='buyer'" placement="top" ngbTooltip="please select location, detail address you can input from your buyer account on the dashboard"></i>
                                                            &nbsp;<i *ngIf="userType=='seller'" placement="top" ngbTooltip="please select location, detail address you can input from your supplier account on the dashboard">
                                                        <img src="assets/images/icons/info.png">
                                                    </i>
                                                    </label>
                                                </div>

                                                <div class="row"
                                                    *ngIf="!businessDetailsForm.get('same_as_registered_office').value">
                                                    <div class="col-md-12">
                                                        <div class="form-container mb-0"
                                                            formArrayName="secondary_locations"
                                                            *ngFor="let item of businessDetailsForm.get('secondary_locations')['controls']; let i = index">
                                                            <div class="floating-label mb-0 cstm-slct-wrp"
                                                                [formGroupName]="i">
                                                                <select class="form-control" formControlName="city">
                                                                    <option selected="selected" value="">Please Select
                                                                    </option>
                                                                    <option *ngFor="let item of cityArray"
                                                                        [ngValue]="item.value">{{ item.name }}</option>
                                                                </select>
                                                                <span class="highlight"></span>
                                                                <label>Secondary {{ userType_text }} Location<span
                                                                        class="req">*</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="text-center pt-4"
                                                    *ngIf="!businessDetailsForm.get('same_as_registered_office').value">
                                                    <button class="btn-d-outline d-inline-block ripple place-bid"
                                                        (click)="newSupplyLocation()">
                                                        <svg class="mr-2" xmlns="http://www.w3.org/2000/svg"
                                                            width="14.681" height="14.681" viewBox="0 0 14.681 14.681">
                                                            <g id="Icon_feather-plus" data-name="Icon feather-plus"
                                                                transform="translate(1 1)">
                                                                <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181"
                                                                    transform="translate(-11.659 -7.5)" fill="none"
                                                                    stroke="#ff7135" stroke-linecap="round"
                                                                    stroke-linejoin="round" stroke-width="2" />
                                                                <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181"
                                                                    transform="translate(-7.5 -11.659)" fill="none"
                                                                    stroke="#ff7135" stroke-linecap="round"
                                                                    stroke-linejoin="round" stroke-width="2" />
                                                            </g>
                                                        </svg> Add New Supply Location</button>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                    <!-- /SHIPPING ADDRESS DETAILS  -->
                                </ngb-accordion>

                            </div>

                        </div>
                        <div class="col-lg-4 mt-4 pt-4">
                            <div class="fs22 f-600 txtdefault mb-3">Having Questions?</div>

                            <div class="accordionarrow">
                                <ngb-accordion #b="ngbAccordion" activeIds="gstnfaq1">
                                    <!-- QS REPEAT  -->
                                    <ngb-panel id="gstnfaq1">
                                        <ng-template ngbPanelHeader>
                                          <div class="d-flex align-items-center justify-content-between">
                                            <h4 class="fs18 f-600 mb-0 txtdefault">What is GST?</h4>
                                            <button ngbPanelToggle class="btn btn-link text-left pl-0"></button>                            
                                          </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <p class="fs16 lnht20 mb-0 txtsecondary">GST stands for Goods and Services Tax, levied on the supply of goods or services or both in India. GST subsumes several existing indirect taxes which were earlier levied by the Centre and State Governments including Central Excise duty, Service Tax, VAT, Purchase Tax, Central Sales Tax, Entry Tax, Local Body Taxes, Octroi, Luxury Tax, etc.</p>              
                                        </ng-template>
                                    </ngb-panel>
                                    <!-- /QS REPEAT  -->
                                    <!-- QS REPEAT  -->
                                    <ngb-panel >
                                        <ng-template ngbPanelHeader>
                                          <div class="d-flex align-items-center justify-content-between">
                                            <h4 class="fs18 f-600 mb-0 txtdefault">Is the Aadhar number compulsory?</h4>
                                            <button ngbPanelToggle class="btn btn-link text-left pl-0"></button>                            
                                          </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                          <p class="fs16 lnht20 mb-0 txtsecondary">Aadhar is not compulsory except in the case of buyers who cannot provide the GST number of their business entity.</p>        
                                        </ng-template>
                                      </ngb-panel>
                                      <!-- /QS REPEAT  -->
                                      <!-- QS REPEAT  -->
                                      <ngb-panel >
                                        <ng-template ngbPanelHeader>
                                          <div class="d-flex align-items-center justify-content-between">
                                            <h4 class="fs18 f-600 mb-0 txtdefault">What is the last GST filling date?</h4>
                                            <button ngbPanelToggle class="btn btn-link text-left pl-0"></button>                            
                                          </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                          <p class="fs16 lnht20 mb-0 txtsecondary">The last GSTR 1 filling date is expected to be updated by the sellers for transparency and increased chances of deal-making over the platform.</p>   
                                        </ng-template>
                                      </ngb-panel>
                                       <!-- /QS REPEAT  -->
                                        <!-- QS REPEAT  -->
                                      <ngb-panel >
                                        <ng-template ngbPanelHeader>
                                          <div class="d-flex align-items-center justify-content-between">
                                            <h4 class="fs18 f-600 mb-0 txtdefault">What is GST filing frequency?</h4>
                                            <button ngbPanelToggle class="btn btn-link text-left pl-0"></button>                            
                                          </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                          <p class="fs16 lnht20 mb-0 txtsecondary">GST filling frequency is periodicity opted by the GST number holder of filing return. Monthly GST filing frequency is desirable in the case of the seller.</p>   
                                        </ng-template>
                                      </ngb-panel>
                                       <!-- /QS REPEAT  -->
                                       <ngb-panel *ngIf="userType=='buyer'">
                                        <ng-template ngbPanelHeader>
                                          <div class="d-flex align-items-center justify-content-between">
                                            <h4 class="fs18 f-600 mb-0 txtdefault">Can I add more than one supply location?</h4>
                                            <button ngbPanelToggle class="btn btn-link text-left pl-0"></button>                            
                                          </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                          <p class="fs16 lnht20 mb-0 txtsecondary">Yes, you can add more than one supply location by accessing your dashboard.</p> 
                                        </ng-template>
                                      </ngb-panel>

                                      <ngb-panel *ngIf="userType=='seller'">
                                        <ng-template ngbPanelHeader>
                                          <div class="d-flex align-items-center justify-content-between">
                                            <h4 class="fs18 f-600 mb-0 txtdefault">Can I add more than one shipping address</h4>
                                            <button ngbPanelToggle class="btn btn-link text-left pl-0"></button>                            
                                          </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                          <p class="fs16 lnht20 mb-0 txtsecondary">Yes, you can add more than one shipping address accessing your dashboard.</p> 
                                        </ng-template>
                                      </ngb-panel>

                                    </ngb-accordion>
                            </div>  
                        </div>
                        <div class="col-lg-8">
                            <div class="text-right">
                                <button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4"
                                    (click)="businessDetailsSave()"
                                    [disabled]="businessLoading || businessDetailsForm.invalid">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                        *ngIf="businessLoading"></span>
                                    &nbsp;Save and Next
                                    <svg class="ml-2" xmlns="http://www.w3.org/2000/svg" width="13" height="10.396"
                                        viewBox="0 0 13 10.396">
                                        <path id="np_arrow_2304592_000000"
                                            d="M37.831,34.712l-3.64-4.42a.8.8,0,0,0-1.1-.11.813.813,0,0,0-.11,1.1l2.592,3.145H25.781a.78.78,0,1,0,0,1.56s7.991-.786,9.791,0-2.592,3.145-2.592,3.145a.82.82,0,0,0,.11,1.1.8.8,0,0,0,1.1-.11l3.64-4.42a.808.808,0,0,0,0-.991Z"
                                            transform="translate(-25.001 -30.009)" fill="#fff" />
                                    </svg>
                                </button>
                                <!--  -->
                            </div>
                        </div>
                    </div>

                </aw-wizard-step>
                <!-- /Business Details  -->
            </form>
            <!-- Designated Person Details  -->
            <form [formGroup]="personDetailsForm">
                <aw-wizard-step stepTitle="Designated / Authorised Persons" awOptionalStep>
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="accordionarrow">
                                <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-1, custom-panel-2">
                                    <!-- Designated Person Details  -->
                                    <ngb-panel id="custom-panel-1">
                                        <ng-template ngbPanelHeader>
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h4 class="fs18 f-600 mb-0 txtdefault">Designated Person Details <i placement="top" ngbTooltip="The owner/partner/director/Karta/designate person/etc of the business entity"></i></h4>
                                                <button ngbPanelToggle class="btn btn-link text-left pl-0"></button>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div class="bg-container-one">
                                                <div class="form-container mb-0 mt-0">
                                                    <div class="floating-label mb-0 cstm-slct-wrp">
                                                        <select class="form-control" formControlName="designation">
                                                            <option value="">Please Select</option>
                                                            <option
                                                                *ngFor="let desi of designationArray; let i = index;"
                                                                [ngValue]="desi.value">{{desi.name}}</option>
                                                        </select>
                                                        <span class="highlight"></span>
                                                        <label>Designation<span class="req">*</span></label>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-container mb-0">
                                                            <div class="floating-label mb-0">
                                                                <input class="form-control" type="text" placeholder=" "
                                                                    formControlName="first_name">
                                                                <span class="highlight"></span>
                                                                <label>First Name<span class="req">*</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-container mb-0">
                                                            <div class="floating-label mb-0">
                                                                <input class="form-control" type="text" placeholder=" "
                                                                    formControlName="last_name">
                                                                <span class="highlight"></span>
                                                                <label>Last Name<span class="req">*</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mlinptwrp">
                                                    <div class="form-container mb-0 phnmcl">
                                                        <div class="floating-label mb-0 cstm-slct-wrp">
                                                            <select class="form-control" formControlName="country_code">
                                                                <option value="">Select</option>
                                                                <option *ngFor="let code of countryArray"
                                                                    [ngValue]="code.phone_code">{{code.name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-container mb-0">
                                                        <div class="floating-label mb-0">
                                                            <input class="form-control wpphnmbr" type="text"
                                                                placeholder=" " formControlName="phone_number">
                                                            <span class="highlight"></span>
                                                            <label>Phone Number<span class="req">*</span></label>
                                                            <input
                                                                value="{{ personDetailsForm.get('country_code').value }}"
                                                                readonly class="form-control cntryprefix" type="text"
                                                                placeholder=" ">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                    <!-- /Designated Person Details  -->
                                    <!-- Authorized Persons Details  -->
                                    <ngb-panel id="custom-panel-2">
                                        <ng-template ngbPanelHeader>
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h4 class="fs18 f-600 mb-0 txtdefault">Authorized Persons Details
                                                    <span>(Optional)</span>
                                                    <i placement="top" ngbTooltip="The second in command authorized by the business entity for accessing the platform and trade alerts"
                                                        class="info-alert"></i>
                                                </h4>
                                                <button ngbPanelToggle class="btn btn-link text-left pl-0"></button>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div class="bg-container-one" formArrayName="authorized_persons"
                                                *ngFor="let item of personDetailsForm.get('authorized_persons')['controls']; let i = index">
                                                <div [formGroupName]="i">
                                                    <div class="form-container mb-0" [ngClass]="{'mt-0': i == 0}">
                                                        <div class="floating-label mb-0">
                                                            <input class="form-control" type="text" placeholder=" "
                                                                formControlName="name">
                                                            <span class="highlight"></span>
                                                            <label>Name of Person {{i+1}}<span
                                                                    class="req">*</span></label>
                                                        </div>
                                                    </div>
                                                    <div class="mlinptwrp">
                                                        <div class="form-container mb-0 phnmcl">
                                                            <div class="floating-label mb-0 cstm-slct-wrp">
                                                                <select class="form-control"
                                                                    formControlName="country_code">
                                                                    <option value="">Select</option>
                                                                    <option *ngFor="let code of countryArray"
                                                                        [(ngValue)]="code.phone_code">{{ code.name }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="form-container mb-0">
                                                            <div class="floating-label mb-0">
                                                                <input class="form-control wpphnmbr" type="text"
                                                                    placeholder=" " formControlName="phone_number">
                                                                <span class="highlight"></span>
                                                                <label>Phone Number<span class="req">*</span></label>
                                                                <input
                                                                    value="{{ personDetailsForm.get('authorized_persons').value[i].country_code}}"
                                                                    readonly class="form-control cntryprefix"
                                                                    type="text" placeholder=" ">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="text-center pt-4">
                                                    <button class="btn-d-outline d-inline-block ripple place-bid"
                                                        (click)="addAuthorizedPersons()">
                                                        <svg class="mr-2" xmlns="http://www.w3.org/2000/svg"
                                                            width="14.681" height="14.681" viewBox="0 0 14.681 14.681">
                                                            <g id="Icon_feather-plus" data-name="Icon feather-plus"
                                                                transform="translate(1 1)">
                                                                <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181"
                                                                    transform="translate(-11.659 -7.5)" fill="none"
                                                                    stroke="#ff7135" stroke-linecap="round"
                                                                    stroke-linejoin="round" stroke-width="2" />
                                                                <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181"
                                                                    transform="translate(-7.5 -11.659)" fill="none"
                                                                    stroke="#ff7135" stroke-linecap="round"
                                                                    stroke-linejoin="round" stroke-width="2" />
                                                            </g>
                                                        </svg> Add</button>
                                                </div>
                                            </div>


                                        </ng-template>
                                    </ngb-panel>
                                    <!-- /Authorized Persons Details  -->
                                </ngb-accordion>

                            </div>

                        </div>
                        <div class="col-lg-4">
                            <div class="fs22 f-600 txtdefault mb-3">Having Questions?</div>

                            <div class="accordionarrow">
                                <ngb-accordion #b="ngbAccordion" activeIds="authqs1">
                                    <!-- QS REPEAT  -->
                                    <ngb-panel id="authqs1">
                                        <ng-template ngbPanelHeader>
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h4 class="fs18 f-600 mb-0 txtdefault">Who can be the designated person?</h4>
                                                <button ngbPanelToggle class="btn btn-link text-left pl-0"></button>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <p class="fs16 lnht20 mb-0 txtsecondary">Designate person should be the key person of the business onboarded over the platform.</p>
                                        </ng-template>
                                    </ngb-panel>
                                    <!-- /QS REPEAT  -->
                                    <!-- QS REPEAT  -->
                                    <ngb-panel>
                                        <ng-template ngbPanelHeader>
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h4 class="fs18 f-600 mb-0 txtdefault">Who can be the authorized person?</h4>
                                                <button ngbPanelToggle class="btn btn-link text-left pl-0"></button>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <p class="fs16 lnht20 mb-0 txtsecondary">The authorized person could be your employee/business associate or any other person to whom you want to provide limited access to the platform dashboard.</p>
                                        </ng-template>
                                    </ngb-panel>
                                    <!-- /QS REPEAT  -->
                                </ngb-accordion>
                            </div>
                        </div>
                        <div class="col-lg-8 text-right pt-4">
                            <button type="button" class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4"
                                awPreviousStep>
                                <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="13" height="10.396"
                                    viewBox="0 0 13 10.396">
                                    <path id="np_arrow_2304592_000000"
                                        d="M25.17,34.712l3.64-4.42a.8.8,0,0,1,1.1-.11.813.813,0,0,1,.11,1.1l-2.592,3.145H37.22a.78.78,0,1,1,0,1.56H27.429l2.592,3.145a.82.82,0,0,1-.11,1.1.8.8,0,0,1-1.1-.11L25.17,35.7a.808.808,0,0,1,0-.991Z"
                                        transform="translate(-25.001 -30.01)" fill="#5e6a7c" />
                                </svg>
                                Previous</button>
                            <button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4"
                                (click)="personDetailsSave()" [disabled]="personsLoading || personDetailsForm.invalid">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                    *ngIf="personsLoading"></span>&nbsp;Save and Next
                                <!--  --><svg class="ml-2" xmlns="http://www.w3.org/2000/svg" width="13" height="10.396"
                                    viewBox="0 0 13 10.396">
                                    <path id="np_arrow_2304592_000000"
                                        d="M37.831,34.712l-3.64-4.42a.8.8,0,0,0-1.1-.11.813.813,0,0,0-.11,1.1l2.592,3.145H25.781a.78.78,0,1,0,0,1.56s7.991-.786,9.791,0-2.592,3.145-2.592,3.145a.82.82,0,0,0,.11,1.1.8.8,0,0,0,1.1-.11l3.64-4.42a.808.808,0,0,0,0-.991Z"
                                        transform="translate(-25.001 -30.009)" fill="#fff" />
                                </svg>
                            </button>
                        </div>
                    </div>

                </aw-wizard-step>
            </form>
            <!-- /Designated Person Details  -->
            <!-- Bank Details -->
            <form [formGroup]="bankDetailsForm">
                <aw-wizard-step stepTitle="Bank Details" awOptionalStep>
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="section-title text-left mb-10">Bank Details <i placement="top"
                                    ngbTooltip="Please input your business account bank details" class="info-alert"></i></div>
                            <div class="bg-container-one mb-3">
                                <div class="form-container mt-0 mb-0">
                                    <div class="floating-label mb-10">
                                        <input class="form-control" type="text" placeholder=" "
                                            formControlName="ifsc_code" (change)="ifscdetails()">
                                        <span class="highlight"></span>
                                        <label>IFSC Code</label>
                                        <div class="form-text-overlay mt-1"><a class="txtlink"
                                                href="javascript:void(0);">IFSC lookup</a></div>
                                    </div>
                                    <div class="bnkinforow fs14 txtdefault"
                                        *ngIf="this.bankDetailsForm.get('bank_name').value">
                                        <img src="assets/images/svg/bank.svg" />
                                        {{ this.bankDetailsForm.get('bank_name').value }}
                                    </div>
                                    <div class="bnkinforow fs14 txtdefault"
                                        *ngIf="this.bankDetailsForm.get('branch_address').value">
                                        <img src="assets/images/svg/map.svg" />
                                        {{ this.bankDetailsForm.get('branch_address').value }}
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-container mb-0 mt-3 pt-1">
                                            <div class="floating-label mb-0">
                                                <input class="form-control" type="text" placeholder=" "
                                                    formControlName="business_name">
                                                <span class="highlight"></span>
                                                <label>Business Name<span class="req">*</span></label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="form-container mb-0">
                                            <div class="floating-label mb-0">
                                                <input class="form-control" type="text" placeholder=" "
                                                    formControlName="account_number">
                                                <span class="highlight"></span>
                                                <label>Account Number<span class="req">*</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-container mb-0">
                                            <div class="floating-label mb-0">
                                                <input class="form-control" type="text" placeholder=" "
                                                    formControlName="confirm_account_number">
                                                <span class="highlight"></span>
                                                <label>Confirm Account Number<span class="req">*</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- REPEAT  -->
                                    <div class="col-md-12">
                                        <div class="form-container input-group mb-3 infoinpt">
                                            <div class="floating-label mb-0">
                                                <input class="form-control" type="text" placeholder="ChequeBookCaptures"
                                                    disabled>
                                                <span class="highlight"></span>
                                                <label>Upload Cancelled Cheque Leaf <span class="req">*</span></label><i
                                                    placement="top" ngbTooltip="Upload a jpg image of your canceled cheque"
                                                    class="info-alert"></i>
                                            </div>
                                            <span class="input-group-addon">
                                                <button class="btn-d-outline d-inline-block ripple " type="button"
                                                    id="button-addon2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="7.532"
                                                        height="15.065" viewBox="0 0 7.532 15.065">
                                                        <path id="Icon_material-attach-file"
                                                            data-name="Icon material-attach-file"
                                                            d="M17.005,4.924V12.8a2.739,2.739,0,1,1-5.478,0V4.239a1.712,1.712,0,0,1,3.424,0v7.19a.685.685,0,0,1-1.37,0V4.924H12.554v6.505a1.712,1.712,0,1,0,3.424,0V4.239a2.739,2.739,0,0,0-5.478,0V12.8a3.764,3.764,0,0,0,3.766,3.766c2.082,0,3.766-3.766,3.766-3.766V4.924Z"
                                                            transform="translate(-10.5 -1.5)" fill="#ff7135" />
                                                    </svg>{{ cheque_file?'Replace File':'Attach File'}}
                                                    <input type="file" value="" class="csfile"
                                                        (change)="uploadFile($event, 'cheque', 'cheque_no')">

                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                    <!-- /REPEAT  -->
                                </div>
                                <div class="custom-control custom-checkbox mb-0">
                                    <input type="checkbox" class="custom-control-input" id="agrcustomCheckBox"
                                        name="checkbox1" formControlName="agree_terms">
                                    <label class="custom-control-label" for="agrcustomCheckBox">
                                        b)  By submitting, I agree with the Equibiz <a class="txtlink"
                                            href="javascript:void(0);" [routerLink]="['/privacy-policy']"
                                            target="_blank">Privacy Policy</a> and <a class="txtlink"
                                            href="javascript:void(0);" [routerLink]="['/terms-of-use']"
                                            target="_blank">Terms and Conditions</a>. Please visit the policy section on the website www.equibiz.in for more details.
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="fs22 f-600 txtdefault mb-3">Having Questions?</div>

                            <div class="accordionarrow">
                                <ngb-accordion #b="ngbAccordion" activeIds="prvdqs1">
                                    <!-- QS REPEAT  -->
                                    <ngb-panel id="prvdqs1">
                                        <ng-template ngbPanelHeader>
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h4 class="fs18 f-600 mb-0 txtdefault">Why provide bank account details?</h4>
                                                <button ngbPanelToggle class="btn btn-link text-left pl-0"></button>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <p class="fs16 lnht20 mb-0 txtsecondary">Bank account details are essential for mapping with the Equibiz internal account, for making payment of charges, receiving deal proceeds amount, etc.</p>
                                        </ng-template>
                                    </ngb-panel>
                                    <!-- /QS REPEAT  -->
                                    <!-- QS REPEAT  -->
                                    <ngb-panel>
                                        <ng-template ngbPanelHeader>
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h4 class="fs18 f-600 mb-0 txtdefault">Is uploading a cancel cheque mandatory?</h4>
                                                <button ngbPanelToggle class="btn btn-link text-left pl-0"></button>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <p class="fs16 lnht20 mb-0 txtsecondary">Cancel cheque is mandatory to ensure the account number is mapped correctly for deal amount transfer and payment of charges.</p>
                                        </ng-template>
                                    </ngb-panel>
                                    <!-- /QS REPEAT  -->
                                </ngb-accordion>
                            </div>
                        </div>
                        <div class="col-md-8 text-right">
                            <button type="button" class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4"
                                awPreviousStep>
                                <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="13" height="10.396"
                                    viewBox="0 0 13 10.396">
                                    <path id="np_arrow_2304592_000000"
                                        d="M25.17,34.712l3.64-4.42a.8.8,0,0,1,1.1-.11.813.813,0,0,1,.11,1.1l-2.592,3.145H37.22a.78.78,0,1,1,0,1.56H27.429l2.592,3.145a.82.82,0,0,1-.11,1.1.8.8,0,0,1-1.1-.11L25.17,35.7a.808.808,0,0,1,0-.991Z"
                                        transform="translate(-25.001 -30.01)" fill="#5e6a7c" />
                                </svg>
                                Previous

                            </button>
                            <button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4"
                                (click)="bankDetailsSave()"
                                [disabled]="bankLoading || bankDetailsForm.invalid || !bankDetailsForm.get('agree_terms').value">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                    *ngIf="bankLoading"></span>&nbsp;
                                Save and Next
                                <svg class="ml-2" xmlns="http://www.w3.org/2000/svg" width="13" height="10.396"
                                    viewBox="0 0 13 10.396">
                                    <path id="np_arrow_2304592_000000"
                                        d="M37.831,34.712l-3.64-4.42a.8.8,0,0,0-1.1-.11.813.813,0,0,0-.11,1.1l2.592,3.145H25.781a.78.78,0,1,0,0,1.56s7.991-.786,9.791,0-2.592,3.145-2.592,3.145a.82.82,0,0,0,.11,1.1.8.8,0,0,0,1.1-.11l3.64-4.42a.808.808,0,0,0,0-.991Z"
                                        transform="translate(-25.001 -30.009)" fill="#fff" />
                                </svg></button>
                        </div>
                    </div>
                </aw-wizard-step>
            </form>
            <!-- /Bank Details -->
            <!-- THANKS PAGE -->
            <aw-wizard-step stepTitle="Thank you" awOptionalStep>
                <div class="row">
                    <div class="col-lg-8">
                        <div class="bg-container-one mb-3">
                            <div class="text-center py-sm-5 py-4 my-md-4 thnkpage mx-auto">
                                <img src="assets/images/svg/thankcheck.svg" class="mb-4" />

                                <div *ngIf="userType=='buyer'">
                                    <h4 class="txtdefault fs22 f-600 mb-4 pt-2">Thanks for submitting your details</h4>
                                    <p class="fs16 txtsecondary mb-4">“Welcome to the Equibiz platform. Enjoy premium features such as Bid and Offer, Bulk and Wholesale market segment, etc. which will take your business to a new level. Leverage Equibiz to buy products in small or large quantities from brand to the large supplier”</p>
                                    <p><b>Equibiz Wishes You a Happy Business!!</b></p>
                                </div>

                                <div *ngIf="userType=='seller'">
                                    <h4 class="txtdefault fs22 f-600 mb-4 pt-2">Thanks for submitting your details.</h4>
                                    <p class="fs16 txtsecondary mb-4">“Welcome to the Equibiz platform. Enjoy premium features such as Bid and Offer, Bulk and Wholesale market segment, Supplier Management Panel, etc. which will take your business to a new level. Leverage Equibiz to reach out to verified buyers across the country and expand your reach without any hassles”</p>
                                    <p><b>Equibiz Wishes You a Happy Business!!</b></p>
                                </div>

                                <a *ngIf="userType == 'buyer'" href="javascript:void(0);" [routerLink]="['/home']"
                                    class="btn btn-mod btn-color btn-medium btn-round ripple px-4">Go to Buyer
                                    Portal</a>
                                <a *ngIf="userType == 'seller'" href="javascript:void(0);"
                                    [routerLink]="['/seller/dashboard']"
                                    class="btn btn-mod btn-color btn-medium btn-round ripple px-4">Go to Seller
                                    Portal</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="fs22 f-600 txtdefault mb-3">Having Questions?</div>

                        <div class="accordionarrow" *ngIf="userType=='buyer'">
                            <ngb-accordion #b="ngbAccordion" activeIds="thnkpnl">
                                <!-- QS REPEAT  -->
                                <ngb-panel id="thnkpnl">
                                    <ng-template ngbPanelHeader>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <h4 class="fs18 f-600 mb-0 txtdefault">What is bid and offer?</h4>
                                            <button ngbPanelToggle class="btn btn-link text-left pl-0"></button>
                                        </div>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <p class="fs16 lnht20 mb-0 txtsecondary">A bid is a counter price of any product set by the buyer against the listed price of the seller under the bid & offer segment. This proprietary feature of our e-commerce platform, Equibiz, facilitates the trading of products under the bulk listing. If the seller agrees with the bid set by the buyer, the seller can close the deal at the price set by the buyer. Other deal conditions will remain the same as set by the seller at the time of product listing.</p>
                                    </ng-template>
                                </ngb-panel>
                                <!-- /QS REPEAT  -->
                                <!-- QS REPEAT  -->
                                <ngb-panel>
                                    <ng-template ngbPanelHeader>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <h4 class="fs18 f-600 mb-0 txtdefault">What are logistics options available at the time of deal booking?
                                            </h4>
                                            <button ngbPanelToggle class="btn btn-link text-left pl-0"></button>
                                        </div>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <p class="fs16 lnht20 mb-0 txtsecondary">Standard and Express delivery logistics options are available at the time of deal booking and buyers can choose any one logistics option as per their business requirement. The expected delivery time in the case of express delivery is three to five working days, and in the case of standard, five to seven working days.</p>
                                    </ng-template>
                                </ngb-panel>
                                <!-- /QS REPEAT  -->
                                <!-- QS REPEAT  -->
                                <ngb-panel>
                                    <ng-template ngbPanelHeader>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <h4 class="fs18 f-600 mb-0 txtdefault">As a buyer, can I set more than one delivery location?
                                            </h4>
                                            <button ngbPanelToggle class="btn btn-link text-left pl-0"></button>
                                        </div>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <p class="fs16 lnht20 mb-0 txtsecondary">The buyer has the option to select the delivery location at the time of deal booking. The buyer can set more than one delivery location from the buyer panel.</p>
                                    </ng-template>
                                </ngb-panel>
                                <!-- /QS REPEAT  -->
                            </ngb-accordion>
                        </div>

                        <div class="accordionarrow" *ngIf="userType=='seller'">
                            <ngb-accordion #b="ngbAccordion" activeIds="thnkpnl">
                                <!-- QS REPEAT  -->
                                <ngb-panel id="thnkpnl">
                                    <ng-template ngbPanelHeader>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <h4 class="fs18 f-600 mb-0 txtdefault">What is a bulk and wholesale listing?</h4>
                                            <button ngbPanelToggle class="btn btn-link text-left pl-0"></button>
                                        </div>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <p class="fs16 lnht20 mb-0 txtsecondary">A bid is a counter price of any product set by the buyer against the listed price of the seller under the bid & offer segment. This proprietary feature of our e-commerce platform, Equibiz, facilitates the trading of products under the bulk listing. If the seller agrees with the bid set by the buyer, the seller can close the deal at the price set by the buyer. The rest of the deal conditions will remain the same as set by the seller at the time of product listing.</p>
                                    </ng-template>
                                </ngb-panel>
                                <!-- /QS REPEAT  -->
                                <!-- QS REPEAT  -->
                                <ngb-panel>
                                    <ng-template ngbPanelHeader>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <h4 class="fs18 f-600 mb-0 txtdefault">What are logistics options available at the time of deal booking?
                                            </h4>
                                            <button ngbPanelToggle class="btn btn-link text-left pl-0"></button>
                                        </div>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <p class="fs16 lnht20 mb-0 txtsecondary">Equibiz facilitates both large and small value transactions to meet the requirements of different stakeholders such as brands, wholesalers, distributors, retailers, corporates, exporters, importers, etc. Under the bulk segment, the suppliers have the freedom to select deal terms, such as minimum order quantity, minimum bid price, supply location, payment option, etc. Whereas under the wholesale segment, the minimum transaction value must be INR 3,000/- or above and the trade terms are set to facilitate the retailer’s or small value buyer’s requirement.</p>
                                    </ng-template>
                                </ngb-panel>
                                <!-- /QS REPEAT  -->
                                <!-- QS REPEAT  -->
                                <ngb-panel>
                                    <ng-template ngbPanelHeader>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <h4 class="fs18 f-600 mb-0 txtdefault">What is a supplier management panel?
                                            </h4>
                                            <button ngbPanelToggle class="btn btn-link text-left pl-0"></button>
                                        </div>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <p class="fs16 lnht20 mb-0 txtsecondary">You, as an e-commerce seller, can onboard your supplier through a seller supplier panel. You can manage and publish supplier product listings in your name over the platform by adding a trade margin. Manage invoices, waybill, payments, etc. of your supplier without any hassles and increase your business margin and turnover.</p>
                                    </ng-template>
                                </ngb-panel>
                                <!-- /QS REPEAT  -->
                            </ngb-accordion>
                        </div>
                    </div>
                </div>
            </aw-wizard-step>
            <!-- THANKS PAGE -->
        </aw-wizard>

    </div>
</section>