import { Component, OnInit } from '@angular/core';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { ManageCandfService } from './manage-candf.service';

@Component({
    selector: 'app-managecandf',
    templateUrl: './managecandf.component.html',
    styleUrls: ['./managecandf.component.scss']
})
export class ManagecandfComponent implements OnInit {

    public search_term:string = '';
    public r_search_term:string = '';

    public candfseller_records: any = [];
    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 20;
    public candfseller_pagination: any = {};

    public candfrequest_records: any = [];
    public rcurrent_page: number = 1;
    public roffset: number = 0;
    public rpage_per_view: number = 20;
    public candfrequest_pagination: any = {};

    public page_per_view_options = [
        {
            value: 20,
            name: 20
        },
        {
            value: 40,
            name: 40
        }
    ];

    constructor(
        private _managecadnfservice: ManageCandfService,
        private _toastr: ToasterService,
        private _pagination: PaginationService
    ) { }

    ngOnInit(): void {
        this.getcandfrequest();
        this.getcandfsellers();
    }

    getcandfrequest() {
        this._managecadnfservice.getcandfrequest({ limit: this.rpage_per_view, offset: this.roffset, current_page: this.rcurrent_page, search: this.r_search_term }).subscribe((data) => {
            if (data['status'] == "success") {
                this.candfrequest_records = data['records'];
                let total_records = data['total_records'];
                this.candfrequest_pagination = this._pagination.getPager(total_records, this.rcurrent_page, this.rpage_per_view);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getcandfsellers() {
        this._managecadnfservice.getcandfsellers({ limit: this.page_per_view, offset: this.offset, current_page: this.current_page, search: this.search_term }).subscribe((data) => {
            if (data['status'] == "success") {
                this.candfseller_records = data['records'];
                let total_records = data['total_records'];
                this.candfseller_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.getcandfsellers();
    }

    setpageperview() {
        this.current_page = 1;
        this.getcandfsellers();
    }

    setrpage(page) {
        this.rcurrent_page = page;
        this.roffset = (page - 1) * this.rpage_per_view;
        this.getcandfrequest();
    }

    setrpageperview() {
        this.rcurrent_page = 1;
        this.getcandfrequest();
    }

}
