import { Component, OnInit,Input } from '@angular/core';
@Component({
  selector: 'app-footer-seller',
  templateUrl: './footer-seller.component.html',
  styleUrls: ['./footer-seller.component.scss']
})
export class FooterSellerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
 @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True

  public today: number = Date.now();
disabled = false;
  
morestatus: boolean = false;
catstatus: boolean = false;
walletstatus: boolean = false;
ordertstatus: boolean = false;
profilestatus: boolean = false;
invoicestatus: boolean = false;
wishliststatus: boolean = false;
categorystatus: boolean = false;
reportstatus: boolean = false;
locstatus:boolean = false;
moreEvent(){
    this.morestatus = !this.morestatus;       
}
walletEvent(){
    this.walletstatus = !this.walletstatus;       
}
swcatgkEvent(){
	this.catstatus = !this.catstatus;       
}
orderEvent(){
	this.ordertstatus = !this.ordertstatus;       
}
profileEvent(){
	this.profilestatus = !this.profilestatus;       
}

wishlistEvent(){
	this.wishliststatus = !this.wishliststatus;       
}
invoiceEvent(){
    this.invoicestatus = !this.invoicestatus;       
}
categoryEvent(){
	this.categorystatus = !this.categorystatus;       
}
reportEvent(){
	this.reportstatus = !this.reportstatus;       
}
locationevent(){
	this.locstatus = !this.locstatus;
	}
}
