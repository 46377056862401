import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delivery-report',
  templateUrl: './delivery-report.component.html',
  styleUrls: ['./delivery-report.component.scss']
})
export class DeliveryReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
