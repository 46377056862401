import { Component, OnInit, Input, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SignupService } from 'src/app/shared/components/modal/signup-modal/signup-modal.service';
import { SellerAssistanceService } from './seller-assistance.service';

@Component({
  selector: 'app-seller-assistance',
  templateUrl: './seller-assistance.component.html',
  styleUrls: ['./seller-assistance.component.scss']
})
export class SellerAssistanceComponent implements OnInit {

	@Input() class: string;
	@Input() sticky: boolean = false; // Default false
  	public stick: boolean = false;
	  public assistance_records = [];
	public search_val:string;
  	constructor(
		private _buyerassistanceservice: SellerAssistanceService,
		private _signupservices: SignupService,
		private _route: ActivatedRoute,
		private _router: Router
	) { }
  	ngOnInit(): void {
		this.getassistances();
  	}

  	disabled = false;
  	// @HostListener Decorator
    @HostListener("window:scroll", [])
    onWindowScroll() {
        let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	    if (number >= 150 && window.innerWidth > 400) { 
  	        this.stick = true;
  	     } else {
  	         this.stick = false;
  	     }
    }

	getassistances() {
        this._buyerassistanceservice.getassistances({user_type:'both'}).subscribe((data) => {
            if(data['status'] == "success"){
                this.assistance_records = data['records'];
            }
        },(error) => {
        });
    }

	searchassistance() {
		this.assistance_records = [];
        this._buyerassistanceservice.searchassistance({user_type:'both', search: this.search_val}).subscribe((data) => {
            if(data['status'] == "success"){
                this.assistance_records = data['records'];
            }
        },(error) => {
        });
    }

	buyerlisting(){
		this._signupservices.logout();
		this._router.navigate(['/home']);
	}

}
