<app-header-seller></app-header-seller>
<app-sidebar-seller></app-sidebar-seller>
<div class="wrpmain tpslgbx pb-4">
    <div class="container ">
        <!-- BREADCRUMB -->
        <nav class=" pt-2 pb-2" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
            <ol class="breadcrumb p-0 bg-transparent">
                <li class="breadcrumb-item"><a href="javascript:void(0);" class="txtseconday"
                        [routerLink]="['/seller/mylisting']">My Products </a></li>
                <li class="breadcrumb-item active" aria-current="page">Add Listing</li>
            </ol>
        </nav>
        <!-- /BREADCRUMB -->
        <div class="row align-items-center adprotop">
            <div class="col-md-6 mb-4">
                <h4 class="wt700 mb-0 txtdefault fs22">
                    <a href="javascript:void();" class="go-back"><i class="fa fa-angle-left"></i></a>
                    Add Listing
                </h4>
            </div>
            <div class="col-md-6 mb-4 text-right">
                <a href="javascript:void(0);" class="sglcatalog txtdefault radius10">
                    <img src="assets/images/admin/video.png">
                    How to upload Single catalog?</a>
                <a href="javascript:void(0);" [routerLink]="['/seller/assistance']" class="btn px-4 buybtn fs16 radius10 ml-sm-3 ml-2">Assistance</a>
            </div>
        </div>
        <div class="row align-items-center mb-4 mb-xs-10">
            <div class="col-sm-12 text-center text-lg-left">
                <ul class="prdnav">
                    <li class="active">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 8.591 7.338">
                                <path id="np_tick_1318229_000000"
                                    d="M37.756,43.764a.449.449,0,1,0-.615.655l2.394,2.244a.449.449,0,0,0,.662-.051l4.189-5.386a.449.449,0,1,0-.708-.552l-3.888,5Z"
                                    transform="translate(-36.444 -39.946)" fill="#1f1f37" stroke="#1f1f37"
                                    stroke-width="1" />
                            </svg>
                        </span>
                        <p class="mb-1 fs16">Select Product - - -</p>
                    </li>
                    <li>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 8.591 7.338">
                                <path id="np_tick_1318229_000000"
                                    d="M37.756,43.764a.449.449,0,1,0-.615.655l2.394,2.244a.449.449,0,0,0,.662-.051l4.189-5.386a.449.449,0,1,0-.708-.552l-3.888,5Z"
                                    transform="translate(-36.444 -39.946)" fill="#1f1f37" stroke="#1f1f37"
                                    stroke-width="1" />
                            </svg>
                        </span>
                        <p class="mb-1 fs16">Listing Details</p>
                    </li>
                </ul>
            </div>
        </div>
        <!-- SEARCH -->
        <div class="row mb-4">
            <div class="col-xl-12 col-lg-12 text-right">
                <div class="input-group-custom pro-catg-srch">

                    <div class="ng-autocomplete">
                        <ng-autocomplete [data]="result" 
                        [searchKeyword]="keyword" 
                        (selected)='selectEvent($event)'
                        (inputChanged)='onChangeSearch($event)'
                        (inputFocused)='onFocused($event)'
                        [itemTemplate]="itemTemplate" 
                        placeholder="Search by Product name, UPC, EAN, ISBN or ASIN">
                        </ng-autocomplete>
                        <ng-template #itemTemplate let-item>
                            <a href="javascript:void(0)">
                                <div class="d-flex">
                                    <span [innerHTML]="item.name"></span>
                                </div>
                            </a>
                        </ng-template>
                        <div class="input-group-append-custom"><img src="assets/images/svg/search_logo.svg"></div>
                    </div>
                </div>
                <a href="javascript:void(0);" class="link-alt pt-2 d-inline-block fs12">Cannot find a product?</a>
            </div>
        </div>

        <h4 class="txtdefault fs16 wt700 mb-4" *ngIf="searchedProducts.length > 0">{{ searchedProducts.length }}
            Products found for “{{ searchword }}”</h4>
        <ngb-accordion #a="ngbAccordion" class="cartitemlist headercartitemlist mb-5" activeIds=activeId
            *ngIf="searchedProducts.length > 0">
            <!-- PANEL -->
            <ngb-panel *ngFor="let result of searchedProducts; let i = index;" id="custom-panel-{{i}}">
                <ng-template ngbPanelHeader let-opened="opened">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="pt-2 cs-item-row pb-2">
                            <div class="row align-items-center">
                                <div class="col-lg-7 pr-0">
                                    <div class="d-table mb-2 w-100">
                                        <div class="d-table-cell cart-thumb align-middle">
                                            <img [src]="result.image" />
                                        </div>
                                        <div class="d-table-cell pl-3 align-middle">
                                            <p class="txtdefault wt700 mb-0 fs14 lnht20">{{ result.name }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-6 pr-0" *ngIf="result.brand_required">
                                    <a href="javascript:void(0);"
                                        class="dspatchbadge-d-outline fs10 wt700 radius10 badge-d-outline">Requires
                                        Brand Approval</a>
                                </div>
                                <div class="col-lg-2 col-sm-6 text-right" *ngIf="result.variants.length">
                                    <button ngbPanelToggle class="add-whislist-link p-0 bg-white nobd fs14"
                                        (click)="showhidetab(result)"><span>{{ result.show == false?'Show all':'Hide
                                            all'}} {{result.variants.length}} Variants</span></button>
                                </div>
                                <div class="col-sm-4 text-right" *ngIf="!result.variants.length">
                                    <a href="javascript:void(0);" class="btn-d-outline d-inline-block"
                                        (click)="start_listing(result)" *ngIf="!result.added">
                                        Start Listing
                                    </a>
                                    <a href="javascript:void(0);" class="btn-d-outline d-inline-block"
                                        [routerLink]="['/seller/addproduct/'+result.uniqueId]" *ngIf="result.added">
                                        View Listing
                                    </a>
                                </div>
                            </div>
                            <div class="d-table w-100 whlst-tblt-center mbl-center">
                                <div class="d-table-cell hideintablet cart-thumb emp-thumb align-middle">

                                </div>
                                <div class="d-table-cell pl-3 align-middle">
                                    <a (click)="openPreview(result.uniqueId, result.id)" href="javascript:void(0);"
                                        class="add-whislist-link">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.391" height="12.976"
                                            viewBox="0 0 17.391 12.976">
                                            <g id="Icon_feather-eye" data-name="Icon feather-eye"
                                                transform="translate(-0.9 -5.4)">
                                                <path id="Path_10492" data-name="Path 10492"
                                                    d="M1.5,11.888S4.444,6,9.6,6s8.1,5.888,8.1,5.888-2.944,5.888-8.1,5.888S1.5,11.888,1.5,11.888Z"
                                                    fill="none" stroke="#ff7135" stroke-linecap="round"
                                                    stroke-linejoin="round" stroke-width="1.2" />
                                                <path id="Path_10493" data-name="Path 10493"
                                                    d="M17.916,15.708A2.208,2.208,0,1,1,15.708,13.5,2.208,2.208,0,0,1,17.916,15.708Z"
                                                    transform="translate(-6.112 -3.82)" fill="none" stroke="#ff7135"
                                                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" />
                                            </g>
                                        </svg>

                                        <span>
                                            View Preview</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbPanelContent *ngIf="result.variants.length > 0">
                    <div class="row pt-4 mb-4 align-items-center">
                        <div class="col-sm-8">
                            <h4 class="wt700 txtdefault fs14 mb-0">Select variants to list</h4>
                        </div>
                        <div class="col-sm-4 text-right">
                            <a href="javascript:void(0);" class="btn-d-outline d-inline-block"
                                (click)="start_listing(result)" *ngIf="!result.added">
                                Start Listing
                            </a>
                            <a href="javascript:void(0);" class="btn-d-outline d-inline-block"
                                [routerLink]="['/seller/addproduct/'+result.uniqueId]" *ngIf="result.added">
                                View Listing
                            </a>
                        </div>
                    </div>
                    <!-- REPEAT -->
                    <div class="variant-row" *ngFor="let variant of result.variants; let k = index;">
                        <div class="row align-items-center">
                            <div class="col-sm-2 col-12 thumb-pro">
                                <img [src]="variant.image" />
                            </div>
                            <div class="col-sm-2 col-4 pr-0" *ngFor="let type of variant.variant_meta;">
                                <label class="txtdefault wt500">{{ type.label_name }}:</label>
                                <p class="txtseconday wt500 mb-0">{{ type.variant_value }}</p>
                            </div>
                            <!-- <div class="col-sm-2 col-3 pr-0">
								<label class="txtdefault wt500">RAM:</label>
								<p class="txtseconday wt500 mb-0">4 GB</p>
							</div>
							<div class="col-sm-3 col-5 pr-0">
								<label class="txtdefault wt500">Internal Memory:</label>
								<p class="txtseconday wt500 mb-0">64 GB</p>
							</div> -->
                            <div class="col-sm-2 col-12 ml-auto pl-0 text-sm-right">
                                <div class="selecteditm">
                                    <input type="checkbox" class="css-checkbx" [(ngModel)]="variant.selected">
                                    <a href="javascript:;" class="cancel-outline px-4 btn-d-outline d-inline-block"
                                        *ngIf="!result.added">
                                        <span>Select</span>
                                        <span class="slctspan">
                                            <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="13.601"
                                                height="9.577" viewBox="0 0 13.601 9.577">
                                                <path id="Path_10541" data-name="Path 10541"
                                                    d="M44.031,37.937s-4.363,5.537-6.751,6.4-2.8-2.961-2.8-2.961a1.2,1.2,0,0,0-1.737,1.658l3.632,3.791a1.145,1.145,0,0,0,.83.355,1.1,1.1,0,0,0,.828-.316l7.62-7.226a1.184,1.184,0,0,0,.039-1.658,1.145,1.145,0,0,0-1.658-.039Z"
                                                    transform="translate(-32.407 -37.6)" fill="#35cb4e" />
                                            </svg>
                                            Selected
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /REPEAT -->

                </ng-template>
            </ngb-panel>
            <!-- /PANEL -->

        </ngb-accordion>
        <!-- ADD PRODUCT IN 2 STEP -->
        <div class="row mt-5">
            <!-- COL -->
            <div class="col-lg-6">
                <h4 class="wt700 txtdefault fs16 mb-5 mb-xs-20">Add products in just 2 steps</h4>
                <div class="d-flex w-100 pb-5 position-relative">
                    <div class="joinerline">
                    </div>
                    <div class="flx-avt flex-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="21.176" height="21.177" viewBox="0 0 21.176 21.177">
                            <defs>
                                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1"
                                    gradientUnits="objectBoundingBox">
                                    <stop offset="0" stop-color="#79b7ff" />
                                    <stop offset="1" stop-color="#07f" />
                                </linearGradient>
                            </defs>
                            <path id="np_search_3179536_000000"
                                d="M31.936,30.791l-5.817-5.845a9.136,9.136,0,0,0,1.332-2.2A8.536,8.536,0,0,0,11.63,16.328,8.536,8.536,0,0,0,22.749,27.446a7.589,7.589,0,0,0,2.2-1.332L30.8,31.933a.827.827,0,0,0,.571.244.815.815,0,0,0,.57-1.386Zm-15.442-8.21a.788.788,0,0,1,0,1.142.79.79,0,0,1-1.143,0,5.92,5.92,0,0,1-1.739-4.214A6.017,6.017,0,0,1,15.352,15.3a.807.807,0,0,1,1.142,1.142,4.3,4.3,0,0,0-1.278,3.045,4.436,4.436,0,0,0,1.279,3.1Z"
                                transform="translate(-11.005 -11)" fill="url(#linear-gradient)" />
                        </svg>
                    </div>
                    <div class="flex-auto pl-4">
                        <h4 class="fs18 wt700 mb-3 txtdefault">Select Product</h4>
                        <p class="fs16  txtseconday">Search the product by brand name, model name and EBPID</p>
                    </div>
                </div>
                <div class="d-flex w-100 pb-5">
                    <div class="flx-avt flex-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19.99"
                            height="22.884" viewBox="0 0 19.99 22.884">
                            <defs>
                                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1"
                                    gradientUnits="objectBoundingBox">
                                    <stop offset="0" stop-color="#79b7ff" />
                                    <stop offset="1" stop-color="#07f" />
                                </linearGradient>
                            </defs>
                            <path id="np_details_2941955_000000"
                                d="M26.319,2.5H10.694A2.187,2.187,0,0,0,8.5,4.695V23.189a2.187,2.187,0,0,0,2.195,2.195H26.3a2.187,2.187,0,0,0,2.195-2.195V4.695A2.167,2.167,0,0,0,26.319,2.5ZM14.046,20.319h-.337a1.11,1.11,0,0,1,0-2.219h.337a1.11,1.11,0,0,1,0,2.219Zm0-5.256h-.337a1.11,1.11,0,0,1,0-2.219h.337a1.11,1.11,0,0,1,0,2.219Zm0-5.257h-.337A1.107,1.107,0,0,1,12.6,8.7a1.092,1.092,0,0,1,1.11-1.11h.337a1.107,1.107,0,0,1,1.11,1.11A1.092,1.092,0,0,1,14.046,9.806ZM23.3,20.319H17.759a1.11,1.11,0,0,1,0-2.219h5.571a1.107,1.107,0,0,1,1.11,1.11,1.16,1.16,0,0,1-1.134,1.11Zm0-5.256H17.759a1.11,1.11,0,0,1,0-2.219h5.571a1.107,1.107,0,0,1,1.11,1.11,1.161,1.161,0,0,1-1.134,1.11Zm0-5.257H17.759a1.107,1.107,0,0,1-1.11-1.11,1.092,1.092,0,0,1,1.11-1.11h5.571a1.107,1.107,0,0,1,1.11,1.11A1.128,1.128,0,0,1,23.3,9.806Z"
                                transform="translate(-8.499 -2.5)" fill="url(#linear-gradient)" />
                        </svg>


                    </div>
                    <div class="flex-auto pl-4">
                        <h4 class="fs18 wt700 mb-3 txtdefault">Listing Details</h4>
                        <p class="fs16 txtseconday">Provide all the listing-related details of the selected products and don’t forget to activate your listing and variant status.</p>
                    </div>
                </div>
            </div>
            <!-- /COL -->
            <!-- COL -->
            <div class="col-lg-6 ">
                <div class="card proctg tw-procp">
                    <div class="card-body  px-4 pt-5 pb-0">
                        <div class="row p-list-item">
                            <!-- REPEAT -->
                            <div class="col-6 px-2">
                                <div class="product-item  pt-2 mb-3">
                                    <div class='image pt-0'>
                                        <div class="d-table">
                                            <div class="d-table-cell align-middle">
                                                <img src='assets/images/product/mobile/oppof5.jpg'>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-name fs14">
                                        OPPO F5 (Red, 64GB Storage, 8GB RAM)
                                    </div>
                                    <div class="ratings">
                                        <i class="fa fa-star" aria-hidden="true"></i>
                                        <i class="fa fa-star" aria-hidden="true"></i>
                                        <i class="fa fa-star" aria-hidden="true"></i>
                                        <i class="fa fa-star" aria-hidden="true"></i>
                                        <i class="fa fa-star" aria-hidden="true"></i>
                                        <span class='star-count'>(119)</span>
                                    </div>
                                    <div class="row no-gutters d-flex justify-content-between">
                                        <div class="col-5">
                                            <div class="seller-count">
                                                Total Listing <br /> <span>3 sellers</span>
                                            </div>
                                        </div>
                                        <div class="col-7">
                                            <div class="price">
                                                Starting at <br /><span><i class="fa fa-inr"></i> 10,000 <b
                                                        class="strikethrough">8000</b></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- /REPEAT -->
                            <!-- REPEAT -->
                            <div class="col-6 scmdprdct px-2 mt-4 pt-2">
                                <div class="product-item  pt-2">
                                    <div class='image pt-0'>
                                        <div class="d-table">
                                            <div class="d-table-cell align-middle">
                                                <img src='assets/images/product/mobile/oppof5.jpg'>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-name fs14">
                                        OPPO F5 (Red, 64GB Storage, 8GB RAM)
                                    </div>
                                    <div class="ratings">
                                        <i class="fa fa-star" aria-hidden="true"></i>
                                        <i class="fa fa-star" aria-hidden="true"></i>
                                        <i class="fa fa-star" aria-hidden="true"></i>
                                        <i class="fa fa-star" aria-hidden="true"></i>
                                        <i class="fa fa-star" aria-hidden="true"></i>
                                        <span class='star-count'>(119)</span>
                                    </div>
                                    <div class="row no-gutters d-flex justify-content-between">
                                        <div class="col-5">
                                            <div class="seller-count">
                                                Total Listing <br /> <span>3 sellers</span>
                                            </div>
                                        </div>
                                        <div class="col-7">
                                            <div class="price">
                                                Starting at <br /><span><i class="fa fa-inr"></i> 10,000 <b
                                                        class="strikethrough">8000</b></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- /REPEAT -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- /COL -->
        </div>
        <!-- /ADD PRODUCT IN 2 STEP -->
        <!-- TOP SELLING PRODUCTS -->
        <h4 class="wt700 txtdefault fs16 mb-4 pt-3">Top Selling Products</h4>
        <div class="prdt-rw">
            <div class="row p-list-item">
                <!-- REPEAT -->
                <div class="col-6 col-lg-3  mb-3">
                    <div class="product-item  pt-2">
                        <div class='image pt-0'>
                            <div class="d-table">
                                <div class="d-table-cell align-middle">
                                    <img src='assets/images/product/mobile/oppof5.jpg'>
                                </div>
                            </div>
                        </div>
                        <div class="product-name fs14">
                            Samsung Galaxy M21 2021 Edition (Arctic Blue, 4GB RAM, 64GB Storage) | FHD+ sAMOLED | 6
                            Months Free Screen Replacement for Prime
                        </div>
                        <a href="javascript:void(0);" class="link-alt mt-3 fs14 d-inline-block">Show all 7 Variants</a>
                    </div>
                </div>
                <!-- /REPEAT -->
                <!-- REPEAT -->
                <div class="col-6 col-lg-3  mb-3">
                    <div class="product-item  pt-2">
                        <div class='image pt-0'>
                            <div class="d-table">
                                <div class="d-table-cell align-middle">
                                    <img src='assets/images/product/mobile/oppof5.jpg'>
                                </div>
                            </div>
                        </div>
                        <div class="product-name fs14">
                            Samsung Galaxy M21 2021 Edition (Arctic Blue, 4GB RAM, 64GB Storage) | FHD+ sAMOLED | 6
                            Months Free Screen Replacement for Prime
                        </div>
                        <a href="javascript:void(0);" class="link-alt mt-3 fs14 d-inline-block">Show all 7 Variants</a>
                    </div>
                </div>
                <!-- /REPEAT -->
                <!-- REPEAT -->
                <div class="col-6 col-lg-3  mb-3">
                    <div class="product-item  pt-2">
                        <div class='image pt-0'>
                            <div class="d-table">
                                <div class="d-table-cell align-middle">
                                    <img src='assets/images/product/mobile/oppof5.jpg'>
                                </div>
                            </div>
                        </div>
                        <div class="product-name fs14">
                            Samsung Galaxy M21 2021 Edition (Arctic Blue, 4GB RAM, 64GB Storage) | FHD+ sAMOLED | 6
                            Months Free Screen Replacement for Prime
                        </div>
                        <a href="javascript:void(0);" class="link-alt mt-3 fs14 d-inline-block">Show all 7 Variants</a>
                    </div>
                </div>
                <!-- /REPEAT -->
                <!-- REPEAT -->
                <div class="col-6 col-lg-3  mb-3">
                    <div class="product-item  pt-2">
                        <div class='image pt-0'>
                            <div class="d-table">
                                <div class="d-table-cell align-middle">
                                    <img src='assets/images/product/mobile/oppof5.jpg'>
                                </div>
                            </div>
                        </div>
                        <div class="product-name fs14">
                            Samsung Galaxy M21 2021 Edition (Arctic Blue, 4GB RAM, 64GB Storage) | FHD+ sAMOLED | 6
                            Months Free Screen Replacement for Prime
                        </div>
                        <a href="javascript:void(0);" class="link-alt mt-3 fs14 d-inline-block">Show all 7 Variants</a>
                    </div>
                </div>
                <!-- /REPEAT -->
            </div>
        </div>
        <!-- /TOP SELLING PRODUCTS -->
        <!-- TOP SELLING CATEGORY -->
        <div class="top-selling mt-5">
            <h4>Top Selling Categories</h4>
            <div class="row rws-md-4">
                <div class="col">
                    <div class='ts-category'>
                        <div class="icon"><img class="w1" src="assets/images/svg/categories/mobile.svg"></div>
                        <div class="title">Mobile Phone</div>
                    </div>
                </div>
                <div class="col">
                    <div class='ts-category'>
                        <div class="icon"><img class="w1" src="assets/images/svg/categories/headset.svg"></div>
                        <div class="title">Ear/Head Phones</div>
                    </div>
                </div>
                <div class="col">
                    <div class='ts-category'>
                        <div class="icon"><img class="w1" src="assets/images/svg/categories/computer.svg"></div>
                        <div class="title">Laptops & Desktops</div>
                    </div>
                </div>
                <div class="col">
                    <div class='ts-category'>
                        <div class="icon"><img class="w1" src="assets/images/svg/categories/printers.svg"></div>
                        <div class="title">Printers & Scanners</div>
                    </div>
                </div>
                <div class="col">
                    <div class='ts-category'>
                        <div class="icon"><img class="w1" src="assets/images/svg/categories/camera.svg"></div>
                        <div class="title">Digital Camera</div>
                    </div>
                </div>
                <div class="col">
                    <div class='ts-category'>
                        <div class="icon"><img class="w1" src="assets/images/svg/categories/chair.svg"></div>
                        <div class="title">Chairs & Seatings</div>
                    </div>
                </div>
                <div class="col">
                    <div class='ts-category'>
                        <div class="icon"><img class="w1" src="assets/images/icons/grill.svg"></div>
                        <div class="title">Grills & Smokers</div>
                    </div>
                </div>
                <div class="col">
                    <div class='ts-category'>
                        <div class="icon"><img class="w1" src="assets/images/icons/grill.svg"></div>
                        <div class="title">Grills & Smokers</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /TOP SELLING CATEGORY -->
        <!-- INSTRUCTION -->
        <div class="mb-5 mt-5 faqpanel accordionarrow">
            <h4 class="f-700 fs16 txtdefault pb-20">Frequently Asked Questions</h4>
            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
                <ngb-panel title="What is BIS CRS?">
                    <ng-template ngbPanelContent>
                        <p class="txtseconday fs16 lnht24 mb-0">Bureau of Indian Standards grants licence to the manufacturers to use or apply Standard Mark with unique R-number, through registration based on self-declaration of conformity for goods and articles as per Indian Standards. The grant of licence and its operation under Compulsory Registration Scheme are carried out as per the conformity assessment scheme under Scheme - II of Schedule - II of BIS (Conformity Assessment) Regulations, 2018'.</p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="What is SKU">
                    <ng-template ngbPanelContent>
                        <p class="txtdefault fs16 lnht24 mb-0">SKU is a distinct type of item for sale, purchase, or tracked in inventory, such as a product or service, and all attributes associated with the item type that distinguish it from other item types.</p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="What is MOP?">
                    <ng-template ngbPanelContent>
                        <p class="txtdefault fs16 lnht24 mb-0">MOP is typically the base selling price that is decided between the retailer and the manufacturer/distributor beforehand.</p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="What is marketplace?">
                    <ng-template ngbPanelContent>
                        Marketplace at Equibiz comprises of three segments, wholesale, bulk and bids & offer. You can select any one or both market segments for listing your products and reach out to different types of buyers.
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
        <!-- /INSTRUCTION -->
    </div>
</div>

<app-preview #preview></app-preview>

<app-footer-seller></app-footer-seller>