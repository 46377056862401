import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ProductReviewService {
    
    constructor(private _http: HttpClient) { }

    getproductreviews(data) {
        return this._http.post(environment.api_url+'admin/review/product',data);
    }

}
