import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  constructor(private _http: HttpClient) { }

    savecontact(data) {
        return this._http.post(environment.api_url+'enquiry',data);
    }

    savecallrequest(data) {
        return this._http.post(environment.api_url+'subscribe',data);
    }
}
