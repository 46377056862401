import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { EncrDecrService } from 'src/app/helpers/EncrDecrService';

@Injectable({
  providedIn: 'root'
})
export class EditNewPartnerService {

    addNewPartnerForm: FormGroup;
	public pincode_document:any;
	public tracking_document:any;
	public pincode_document_status:boolean = false;
	public tracking_document_status:boolean = false;
    
    constructor(private _http: HttpClient) { }

    partnerinfo(data) {
        return this._http.post(environment.api_url+'admin/logisticpartner/info',data);
    }

    partnerupdate(data) {
        return this._http.post(environment.api_url+'admin/updatelogisticpartner',data);
    }

}
