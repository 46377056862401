import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seller-bank-details',
  templateUrl: './seller-bank-details.component.html',
  styleUrls: ['./seller-bank-details.component.scss']
})
export class SellerBankDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
