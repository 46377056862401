<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Product Management</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" [routerLink]="['/admin/product-listing']">Product Listing</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" [routerLink]="['/admin/product-listing']" [queryParams]="{tab: 2}">Requested Listing</a>
						</li>
						<li class="breadcrumb-item active">View Details</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:;"  [routerLink]="['/admin/product/category/add']" class="mr-sm-4 btn-d-outline d-inline-block"><svg xmlns="http://www.w3.org/2000/svg" width="14.681" height="14.681" viewBox="0 0 14.681 14.681" class="plus">
					  <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1 1)">
					    <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181" transform="translate(-11.659 -7.5)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					    <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181" transform="translate(-7.5 -11.659)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					  </g>
					</svg>
					Add New Product
				</a>
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="row d-flex align-items-center mb-20">
			<div class="col-md-6">
				<h3 class="f-700 fs22 txtdefault pb-0"><a href="javascript:;" [routerLink]="['/admin/product-listing']" [queryParams]="{tab: 2}" class="go-back"><i class="fa fa-angle-left"></i></a> View Details</h3>
			</div>
		</div>

		<div class="row mb-20">
			<div class="col-md-8 pb-2">
				<div class="row">
					<div class="col-sm-2 col-3 seller-thumb">
						<img [src]="record.logo"/>
					</div>
					<div class="col-sm-10 col-9 pl-0 seller-info">
						<h4 class="f-600 fs22">{{ record.seller_name }}</h4>
						<div class="fs12 txtseconday mb-1">Seller</div>
						<span class="pb-sm-2 pl-3 seller-loc fs12">
						<svg xmlns="http://www.w3.org/2000/svg" width="9.375" height="11.752" viewBox="0 0 9.375 11.752">
	  					<path id="Path_2306" data-name="Path 2306" d="M25.689,5.029a4.825,4.825,0,0,0-4.154,5.16c.285,3.272,3.293,5.711,4.324,6.457a.562.562,0,0,0,.677-.012c1.071-.836,4.357-3.67,4.357-6.942a4.691,4.691,0,0,0-5.2-4.663Zm.514,6.524a1.861,1.861,0,1,1,1.861-1.861A1.861,1.861,0,0,1,26.2,11.553Z" transform="translate(-21.517 -5.002)" fill="#5e6a7c"/>
						</svg>{{ record.address }}</span>
					</div>
				</div>
			</div>
		</div>

		<div class="card card-ui card-uiovt byregwrp">
			<div class="form-container">
				<div class="floating-label">      
				  <input class="form-control pr-5" disabled="" value="{{ record.category_name }}">
				  <span class="highlight"></span>
				  <label>Category Name</label>
				</div>
				<div class="d-flex align-items-center" *ngIf="record.is_brand">
					<div class="mr-2"><img src="assets/images/icons/info.png"></div>
					<div>This Product does not have Brand name</div>
				</div>
				<div class="floating-label" *ngIf="!record.is_brand">      
					<input class="form-control pr-5" disabled="" value="{{ record.brand_name }}">
					<span class="highlight"></span>
					<label>Brand Name</label>
				  </div>

				<div class="row mt-30">
					<div class="col-md-6">
						<div class="floating-label">      
						  <input class="form-control pr-5" placeholder="" disabled value="{{ record.product_name }}">
						  <span class="highlight"></span>
						  <label>Product Name</label>
						  <i placement="top" ngbTooltip="Product Name" class="infoinput info-alert"></i>
						</div>
					</div>
					<div class="col-md-6">
						<div class="floating-label">      
						  <input class="form-control pr-5" placeholder="" disabled value="{{ record.model_no }}">
						  <span class="highlight"></span>
						  <label>Modal Number</label>
						  <i placement="top" ngbTooltip="Modal Number" class="infoinput info-alert"></i>
						</div>
					</div>
				</div>

				<div class="floating-label mb-0">      
				   <textarea class="form-control floating-textarea" disabled>{{ record.message }}</textarea>
				   <span class="highlight"></span>
				   <label>Message</label>
				   <i placement="top" ngbTooltip="Modal Number" class="infoinput info-alert"></i>
				</div>

			</div>

			<div>
				<a href="javascript:;" class="btn buybtn fs16 radius10 ripple mr-3" (click)="startrequestedlisting()">Start Listing</a>
				<a href="javascript:;" (click)="open(content)" class="btn-d-outline d-inline-block">Delete Request</a>
			</div>
		</div>
	</div>
</div>

<!-- Delete Request -->
<ng-template #content let-modal>
	<div class="modal-body mdlcmnui pt-4 mt-3">
        <h5 class="modal-title text-center mb-20">Delete Request</h5>

        <div class="bid-order-info">
			<div class="order-img">
				<img src="assets/images/icons/info.png" />
			</div>
			<div class="pd-seller-cntnt">
				<div class="fs12">By deleting the request, Seller will get notified that the listing has been rejected by Equibiz Team. </div>
			</div>
		</div>

		<div class="modal-subtitle text-center pt-30 pb-30">Are you sure you want to delete the requested listing?</div>

        <div class="bid-order-info">
			<!-- <div class="order-img">
				<img src="assets/images/p-2.jpg" />
			</div> -->
			<div class="pd-seller-cntnt">
				<h5 class="mb-10 fs16">{{ record.product_name }}</h5>
			</div>
		</div>

        <div class="text-center pt-30 pb-20">
           <button type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')" class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4">
		      Cancel
		   	</button>
		   	<button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4" (click)="deleterequestlisting()">
		      Remove
		   	</button>
        </div>
      </div>
</ng-template>