<app-header-one [sticky]="true"></app-header-one>

<section class="page-section pt-20">
    <div class="container">
        <!--- HEAD  --->
        <div class="row align-items-center mb-4 pinfo-head">
            <div class="col-sm-6 col-12 pr-0">
                <h3 class="fs24 wt700 txtdefault">My Bids</h3>
                <nav class="breadcrumb breadcrumb-dui mb-0">
                    <ol class="breadcrumb p-0 bg-transparent  mb-0">
                        <li class="breadcrumb-item">
                            <a class="txtseconday" href="javascript:void(0);" [routerLink]="['/home']">Home</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a class="txtseconday" href="javascript:;" [routerLink]="['/buyer/bids-offers']">Bids and Offers</a>
                        </li>
                        <li class="breadcrumb-item active">My Bids</li>
                    </ol>
                </nav>
            </div>
        </div>
        <!--- /HEAD  --->

        <div class="notifications-container theme-tab">
            <ngb-tabset [justify]="'left'" [type]="'pills'" class="tabs tab-title">
                <ngb-tab>
                    <ng-template ngbTabTitle>My Bids ({{ bids_records.length }})</ng-template>
                    <ng-template ngbTabContent>
                        <!--Filters-->
                        <div class="row d-flex align-items-center mt-20 mb-20 report-cta">
                            <div class="col-md-5">
                                <div class="report-input">
                                    <div class="input-group-custom">
                                        <input type="text" placeholder="Search by a Product Name" class="form-control" [(ngModel)]="search_term" (change)="getmybids()">
                                        <div class="input-group-append-custom">
                                            <img src="assets/images/svg/search_logo.svg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-2">
                                <a href="javascript:void(0);"
                                    class="d-inline-block fltr-round-btn radius10 txtseconday">
                                    <img src="assets/images/svg/filter.svg">
                                    Filters
                                </a>
                            </div> -->
                        </div>
                        <!--End Filters-->
                        <!-- Empty Records -->
                        <div class="card cardui carduipro p-4 mb-20" *ngIf="bids_records.length == 0">
                            <div class="tab-content">
                                <div class="row d-flex align-items-center justify-content-between">
                                    <div class="col-md-12 text-center">
                                        <div class="mb-20"><img src="/assets/images/empty-state.png">
                                        </div>
                                        <div class="fs14">Records not available.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                        <!--Declined Card-->
                        <div class="card cardui carduipro p-4 mb-20"
                            *ngFor="let record of bids_records; let i = index;">
                            <div class="tab-content">
                                <div class="row d-flex align-items-center justify-content-between">
                                    <div class="col-12 col-md-6">
                                        <span class="fs20 f-700 mr-4">Bid ID : {{ record.bid_unique_id }}</span>
                                        <span class="status-badge danger"
                                            *ngIf="record.status == 'Declined'">Declined</span>
                                        <span class="status-badge success"
                                            *ngIf="record.status == 'Accepted'">Accepted</span> <span
                                            class="ml-3 text-active f-600" *ngIf="record.status == 'Accepted'">You saved
                                            {{ record.saved_amount }}</span>
                                        <span class="status-badge pending"
                                            *ngIf="record.status == 'Pending'">Pending</span>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="d-flex align-items-center justify-content-end text-muted md-block">
                                            <!-- <a href="javascript:;" class="link-alt fs14 mr-2"
                                                *ngIf="!record.watchlist">Add to Watchlist</a> -->
                                            <span class="mr-2"
                                                *ngIf="!record.watchlist && record.status != 'Accepted'">&nbsp;|&nbsp;</span>
                                            <a href="javascript:;" *ngIf="record.status != 'Accepted'" (click)="deletebid(record)"><img
                                                    src="/assets/images/icons/delete-icon.svg"></a>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="row mb-0">
                                    <div class="col-md-9">
                                        <div class="row">
                                            <div class="col-md-5">
                                                <div class="fs15 f-700 mb-20">Product Info:</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark f-600">Order Qty:</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark f-600">Bidding Price:</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark f-600">Listed Price:</div>
                                            </div>
                                            <div class="col text-center" *ngIf="record.status == 'Accepted'">
                                                <div class="text-muted f-600">Total Amount:</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-3">&nbsp;</div>
                                </div>

                                <div class="row">
                                    <div class="col-md-9">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-md-5">
                                                <div class="d-flex align-items-center">
                                                    <div class="mr-3"><img [src]="record.product_image" class="mx-w-80"></div>
                                                    <h5 class="mb-0 fs14 wt700">{{ record.product_name }}</h5>
                                                </div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark">{{ record.order_qty }} <a href="javascript:;"
                                                        class="ml-1" *ngIf="record.status != 'Accepted' && !record.counter_offer"
                                                        (click)="openCounterOffer(content2, record, 'quantity')"><img
                                                            src="/assets/images/icons/edit.png"></a></div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark">{{ record.counter_offer?record.counter_amount:record.bid_price }}</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark">{{ record.listed_price }}</div>
                                            </div>
                                            <div class="col text-center" *ngIf="record.status == 'Accepted'">
                                                <div class="text-dark">{{ record.total_amount }}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-3">
                                        <div class="mt-20 mb-10 text-right">
                                            <a href="javascript:void(0);"
                                                class="btn px-4 buybtn fs14 place-bid radius10 mr-2"
                                                *ngIf="record.status != 'Accepted' && !record.counter_offer"
                                                (click)="openCounterOffer(content2, record, 'price')">Increase Bid
                                                Price</a>
                                            <a href="javascript:void(0);"
                                                class="btn px-4 buybtn fs14 place-bid radius10 mr-2"
                                                *ngIf="record.status != 'Accepted' && record.counter_offer"
                                                (click)="open(contentacceptcounter, record)">View Counter Offer</a>
                                            <a href="javascript:;" *ngIf="record.status != 'Accepted'"
                                                class="mr-2 btn-d-outline fs14 place-bid d-inline-block"
                                                [routerLink]="['/deal/'+record.sp_id+'/bulk']">Buy it Now</a>

                                            <a href="javascript:void(0);" [routerLink]="['/buyer/buyer-view-order-details/'+record.meta_id]"
                                                class="btn px-4 buybtn fs14 place-bid radius10 mr-2"
                                                *ngIf="record.status == 'Accepted'">View Order</a>

                                            <a href="javascript:;" class="link-alt fs14"
                                                *ngIf="record.status == 'Accepted'">Cancel Order</a>
                                        </div>
                                        <div class="text-right" *ngIf="record.status != 'Accepted'"><a
                                                href="javascript:;" class="link-alt fs14"
                                                [routerLink]="['/buyer/bids-offers/'+record.product_id]">View Other
                                                Bids</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--Accepted Card-->
                        <!-- <div class="card cardui carduipro p-4 mb-20" style="display: none;">
		                  	<div class="tab-content">
		                  		<div class="row d-flex align-items-center justify-content-between">
		                  			<div class="col-md-6">
		                  				<span class="fs20 f-700 mr-4">Bid ID : 12345</span>
		                  				<span class="status-badge success">Accepted</span> <span class="ml-3 text-active f-600">You saved 1,00,000</span>
		                  			</div>
		                  			<div class="col-md-6">
		                  				<div class="d-flex align-items-center justify-content-end text-muted">
		                  					<a href="javascript:;" class="link-alt fs14 mr-2">Add to Watchlist</a>
			                  				<span class="mr-2">&nbsp;|&nbsp;</span>
			                  				<a href="javascript:;"><img src="/assets/images/icons/delete-icon.svg"></a>
		                  				</div>
		                  			</div>
		                  		</div>
		                  		<hr>
		                  		<div class="row mb-0">
									<div class="col-md-9">
										<div class="row">
											<div class="col-md-5">
												<div class="fs15 f-700 mb-20">Product Info:</div>
											</div>
											<div class="col text-center">
												<div class="text-dark f-600">Order Qty:</div>
											</div>
											<div class="col text-center">
												<div class="text-dark f-600">Bidding Price:</div>
											</div>
											<div class="col text-center">
												<div class="text-dark f-600">Listed Price:</div>
											</div>
											<div class="col text-center">
												<div class="text-muted f-600">Total Amount:</div>
											</div>
										</div>
									</div>	

									<div class="col-md-3">&nbsp;</div>
								</div>

								<div class="row">
									<div class="col-md-9">
										<div class="row d-flex align-items-center">
											<div class="col-md-5">
												<div class="d-flex align-items-center">
													<div class="mr-3"><img src="assets/images/p-2.jpg" style="max-width:80px"></div>
													<h5 class="mb-0 fs14 wt700">Vivo X60 Pro (Midnight Black, 12GB RAM, 256GB Storage) | Upto 12 Months No Cost EMI | Extra 4000 Off on Exchange</h5>
												</div>
											</div>
											<div class="col text-center">
												<div class="text-dark">500 <a href="javascript:;" class="ml-1"><img src="/assets/images/icons/edit.png"></a></div>
											</div>
											<div class="col text-center">
												<div class="text-dark">7,599</div>
											</div>
											<div class="col text-center">
												<div class="text-dark">7,999</div>
											</div>
											<div class="col text-center">
												<div class="text-dark">3,09,087</div>
											</div>
										</div>
									</div>	

									<div class="col-md-3">
										<div class="mt-20 text-right">
											<a href="javascript:void(0);" class="btn px-4 buybtn fs14 place-bid radius10 mr-2">View Order</a>
											<a href="javascript:;" class="link-alt fs14">Cancel Order</a>
										</div>
									</div>
								</div>
		                  	</div>
		                </div> -->

                        <!--Pending Card-->
                        <!-- <div class="card cardui carduipro p-4 mb-20" style="display: none;">
		                  	<div class="tab-content">
		                  		<div class="row d-flex align-items-center justify-content-between">
		                  			<div class="col-md-6">
		                  				<span class="fs20 f-700 mr-4">Bid ID : 12345</span>
		                  				<span class="status-badge pending">Pending</span>
		                  			</div>
		                  			<div class="col-md-6">
		                  				<div class="d-flex align-items-center justify-content-end text-muted">
		                  					<a href="javascript:;" class="link-alt fs14 mr-2">Add to Watchlist</a>
			                  				<span class="mr-2">&nbsp;|&nbsp;</span>
			                  				<a href="javascript:;"><img src="/assets/images/icons/delete-icon.svg"></a>
		                  				</div>
		                  			</div>
		                  		</div>
		                  		<hr>
		                  		<div class="row mb-0">
									<div class="col-md-9">
										<div class="row">
											<div class="col-md-5">
												<div class="fs15 f-700 mb-20">Product Info:</div>
											</div>
											<div class="col text-center">
												<div class="text-dark f-600">Order Qty:</div>
											</div>
											<div class="col text-center">
												<div class="text-dark f-600">Bidding Price:</div>
											</div>
											<div class="col text-center">
												<div class="text-dark f-600">Listed Price:</div>
											</div>
										</div>
									</div>	

									<div class="col-md-3">&nbsp;</div>
								</div>

								<div class="row">
									<div class="col-md-9">
										<div class="row d-flex align-items-center">
											<div class="col-md-5">
												<div class="d-flex align-items-center">
													<div class="mr-3"><img src="assets/images/p-2.jpg" style="max-width:80px"></div>
													<h5 class="mb-0 fs14 wt700">Vivo X60 Pro (Midnight Black, 12GB RAM, 256GB Storage) | Upto 12 Months No Cost EMI | Extra 4000 Off on Exchange</h5>
												</div>
											</div>
											<div class="col text-center">
												<div class="text-dark">500 <a href="javascript:;" class="ml-1"><img src="/assets/images/icons/edit.png"></a></div>
											</div>
											<div class="col text-center">
												<div class="text-dark">7,599</div>
											</div>
											<div class="col text-center">
												<div class="text-dark">7,999</div>
											</div>
										</div>
									</div>	

									<div class="col-md-3">
										<div class="mt-20 mb-10 text-right">
											<a href="javascript:void(0);" class="btn px-4 buybtn fs14 place-bid radius10 mr-2">Increase Bid Price</a>
											<a href="javascript:;" class="mr-2 btn-d-outline fs14 place-bid d-inline-block">Buy it Now</a>
										</div>
										<div class="text-right"><a href="javascript:;" class="link-alt fs14">View Other Bids</a></div>
									</div>
								</div>
		                  	</div>
		                </div> -->
                    </ng-template>
                </ngb-tab>
                <!-- <ngb-tab>
	                <ng-template ngbTabTitle>My Watchlist (03)</ng-template>
	                <ng-template ngbTabContent> -->
                <!--Filters-->
                <!-- <div class="row d-flex align-items-center mt-20 mb-20 report-cta">
							<div class="col-md-5">
								<div class="report-input">
									<div class="input-group-custom">
										<input type="text" placeholder="Search by a Product Name" class="form-control">
										<div class="input-group-append-custom">
											<img src="assets/images/svg/search_logo.svg">
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-2">
								<a href="javascript:void(0);" class="d-inline-block fltr-round-btn radius10 txtseconday">
									<img src="assets/images/svg/filter.svg">
									Filters
								</a>
							</div>
						</div> -->
                <!--End Filters-->

                <!--Card-->
                <!-- <div class="card cardui carduipro p-4 mb-20">
		                  	<div class="tab-content">
		                  		<div class="d-flex align-items-center">
		                  			<img src="assets/images/svg/buyer.svg"> <span class="txtseconday ml-2">12 Buyers watching this Bid</span>
		                  		</div>
		                  		<hr>
		                  		<div class="row mb-0">
									<div class="col-md-9">
										<div class="row">
											<div class="col-md-5">
												<div class="fs15 f-700 mb-20">Product Info:</div>
											</div>
											<div class="col text-center">
												<div class="text-dark f-600">Highest Bid Price:</div>
											</div>
											<div class="col text-center">
												<div class="text-dark f-600">Listed Price:</div>
											</div>
										</div>
									</div>	

									<div class="col-md-3">&nbsp;</div>
								</div>

								<div class="row">
									<div class="col-md-9">
										<div class="row d-flex align-items-center">
											<div class="col-md-5">
												<div class="d-flex align-items-center">
													<div class="mr-3"><img src="assets/images/p-2.jpg" style="max-width:80px"></div>
													<h5 class="mb-0 fs14 wt700">Vivo X60 Pro (Midnight Black, 12GB RAM, 256GB Storage) | Upto 12 Months No Cost EMI | Extra 4000 Off on Exchange</h5>
												</div>
											</div>
											<div class="col text-center">
												<div class="text-dark">7,599</div>
											</div>
											<div class="col text-center">
												<div class="text-dark">7,999</div>
											</div>
										</div>
									</div>	

									<div class="col-md-3">
										<div class="mt-20 mb-10 text-right">
											<a href="javascript:void(0);" class="btn px-4 buybtn fs14 place-bid radius10 mr-2">Place a Bid</a>
											<a href="javascript:;" class="mr-2 btn-d-outline fs14 place-bid d-inline-block">Buy it Now</a>
										</div>
										<div class="text-right"><a href="javascript:;" class="link-alt fs14">View Other Bids</a></div>
									</div>
								</div>
		                  	</div>
		                </div> -->

                <!--Card-->
                <!-- <div class="card cardui carduipro p-4 mb-20">
		                  	<div class="tab-content">
		                  		<div class="d-flex align-items-center">
		                  			<img src="assets/images/svg/buyer.svg"> <span class="txtseconday ml-2">12 Buyers watching this Bid</span>
		                  		</div>
		                  		<hr>
		                  		<div class="row mb-0">
									<div class="col-md-9">
										<div class="row">
											<div class="col-md-5">
												<div class="fs15 f-700 mb-20">Product Info:</div>
											</div>
											<div class="col text-center">
												<div class="text-dark f-600">Highest Bid Price:</div>
											</div>
											<div class="col text-center">
												<div class="text-dark f-600">Listed Price:</div>
											</div>
										</div>
									</div>	

									<div class="col-md-3">&nbsp;</div>
								</div>

								<div class="row">
									<div class="col-md-9">
										<div class="row d-flex align-items-center">
											<div class="col-md-5">
												<div class="d-flex align-items-center">
													<div class="mr-3"><img src="assets/images/p-2.jpg" style="max-width:80px"></div>
													<h5 class="mb-0 fs14 wt700">Vivo X60 Pro (Midnight Black, 12GB RAM, 256GB Storage) | Upto 12 Months No Cost EMI | Extra 4000 Off on Exchange</h5>
												</div>
											</div>
											<div class="col text-center">
												<div class="text-dark">7,599</div>
											</div>
											<div class="col text-center">
												<div class="text-dark">7,999</div>
											</div>
										</div>
									</div>	

									<div class="col-md-3">
										<div class="mt-20 mb-10 text-right">
											<a href="javascript:void(0);" class="btn px-4 buybtn fs14 place-bid radius10 mr-2">Place a Bid</a>
											<a href="javascript:;" class="mr-2 btn-d-outline fs14 place-bid d-inline-block">Buy it Now</a>
										</div>
										<div class="text-right"><a href="javascript:;" class="link-alt fs14">View Other Bids</a></div>
									</div>
								</div>
		                  	</div>
		                </div> -->

                <!--Card-->
                <!-- <div class="card cardui carduipro p-4 mb-20">
		                  	<div class="tab-content">
		                  		<div class="d-flex align-items-center">
		                  			<img src="assets/images/svg/buyer.svg"> <span class="txtseconday ml-2">12 Buyers watching this Bid</span>
		                  		</div>
		                  		<hr>
		                  		<div class="row mb-0">
									<div class="col-md-9">
										<div class="row">
											<div class="col-md-5">
												<div class="fs15 f-700 mb-20">Product Info:</div>
											</div>
											<div class="col text-center">
												<div class="text-dark f-600">Highest Bid Price:</div>
											</div>
											<div class="col text-center">
												<div class="text-dark f-600">Listed Price:</div>
											</div>
										</div>
									</div>	

									<div class="col-md-3">&nbsp;</div>
								</div>

								<div class="row">
									<div class="col-md-9">
										<div class="row d-flex align-items-center">
											<div class="col-md-5">
												<div class="d-flex align-items-center">
													<div class="mr-3"><img src="assets/images/p-2.jpg" style="max-width:80px"></div>
													<h5 class="mb-0 fs14 wt700">Vivo X60 Pro (Midnight Black, 12GB RAM, 256GB Storage) | Upto 12 Months No Cost EMI | Extra 4000 Off on Exchange</h5>
												</div>
											</div>
											<div class="col text-center">
												<div class="text-dark">7,599</div>
											</div>
											<div class="col text-center">
												<div class="text-dark">7,999</div>
											</div>
										</div>
									</div>	

									<div class="col-md-3">
										<div class="mt-20 mb-10 text-right">
											<a href="javascript:void(0);" class="btn px-4 buybtn fs14 place-bid radius10 mr-2">Place a Bid</a>
											<a href="javascript:;" class="mr-2 btn-d-outline fs14 place-bid d-inline-block">Buy it Now</a>
										</div>
										<div class="text-right"><a href="javascript:;" class="link-alt fs14">View Other Bids</a></div>
									</div>
								</div>
		                  	</div>
		                </div> -->
                <!-- </ng-template>
	            </ngb-tab>									 -->
            </ngb-tabset>
        </div>

        <div class="row mt-40" style="display: none;">
            <div class="col-md-12">
                <div class="fs15 f-700 mb-10">Items You May Bid</div>

                <div class="row">
                    <div class="col">
                        <div class="product-item">
                            <div class="ex-deal"><span>Excellent Deal</span></div>
                            <div class='image'>
                                <img src='assets/images/p-2.jpg'>
                            </div>
                            <div class="row">
                                <div class="col-6 pr-0">
                                    <div class="product-name">
                                        boAt Airdopes 121v2 Truly Wireless...
                                    </div>
                                </div>
                                <div class="col-6 pr-0 pl-2">
                                    <span class="buyerbadge txtseconday"><img src="assets/images/svg/buyer.svg">12
                                        Buyers</span>
                                </div>
                            </div>
                            <div class="ratings">
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <span class='star-count'>(119)</span>
                            </div>
                            <div class="row no-gutters d-flex justify-content-between">
                                <div class="col">
                                    <div class="seller-count">
                                        Total Listing <span>3 Sellers</span>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="price">
                                        Starting at <span><i class="fa fa-inr"></i> 10,000 <b
                                                class="strikethrough">8000</b></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-item">
                            <div class="ex-deal"><span>Excellent Deal</span></div>
                            <div class='image'>
                                <img src='assets/images/p-2.jpg'>
                            </div>
                            <div class="row">
                                <div class="col-6 pr-0">
                                    <div class="product-name">
                                        boAt Airdopes 121v2 Truly Wireless...
                                    </div>
                                </div>
                                <div class="col-6 pr-0 pl-2">
                                    <span class="buyerbadge txtseconday"><img src="assets/images/svg/buyer.svg">12
                                        Buyers</span>
                                </div>
                            </div>
                            <div class="ratings">
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <span class='star-count'>(119)</span>
                            </div>
                            <div class="row no-gutters d-flex justify-content-between">
                                <div class="col">
                                    <div class="seller-count">
                                        Total Listing <span>3 Sellers</span>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="price">
                                        Starting at <span><i class="fa fa-inr"></i> 10,000 <b
                                                class="strikethrough">8000</b></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-item">
                            <div class="ex-deal"><span>Excellent Deal</span></div>
                            <div class='image'>
                                <img src='assets/images/p-2.jpg'>
                            </div>
                            <div class="row">
                                <div class="col-6 pr-0">
                                    <div class="product-name">
                                        boAt Airdopes 121v2 Truly Wireless...
                                    </div>
                                </div>
                                <div class="col-6 pr-0 pl-2">
                                    <span class="buyerbadge txtseconday"><img src="assets/images/svg/buyer.svg">12
                                        Buyers</span>
                                </div>
                            </div>
                            <div class="ratings">
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <span class='star-count'>(119)</span>
                            </div>
                            <div class="row no-gutters d-flex justify-content-between">
                                <div class="col">
                                    <div class="seller-count">
                                        Total Listing <span>3 Sellers</span>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="price">
                                        Starting at <span><i class="fa fa-inr"></i> 10,000 <b
                                                class="strikethrough">8000</b></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-item">
                            <div class="ex-deal"><span>Excellent Deal</span></div>
                            <div class='image'>
                                <img src='assets/images/p-2.jpg'>
                            </div>
                            <div class="row">
                                <div class="col-6 pr-0">
                                    <div class="product-name">
                                        boAt Airdopes 121v2 Truly Wireless...
                                    </div>
                                </div>
                                <div class="col-6 pr-0 pl-2">
                                    <span class="buyerbadge txtseconday"><img src="assets/images/svg/buyer.svg">12
                                        Buyers</span>
                                </div>
                            </div>
                            <div class="ratings">
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <span class='star-count'>(119)</span>
                            </div>
                            <div class="row no-gutters d-flex justify-content-between">
                                <div class="col">
                                    <div class="seller-count">
                                        Total Listing <span>3 Sellers</span>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="price">
                                        Starting at <span><i class="fa fa-inr"></i> 10,000 <b
                                                class="strikethrough">8000</b></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-item">
                            <div class="ex-deal"><span>Excellent Deal</span></div>
                            <div class='image'>
                                <img src='assets/images/p-2.jpg'>
                            </div>
                            <div class="row">
                                <div class="col-6 pr-0">
                                    <div class="product-name">
                                        boAt Airdopes 121v2 Truly Wireless...
                                    </div>
                                </div>
                                <div class="col-6 pr-0 pl-2">
                                    <span class="buyerbadge txtseconday"><img src="assets/images/svg/buyer.svg">12
                                        Buyers</span>
                                </div>
                            </div>
                            <div class="ratings">
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <span class='star-count'>(119)</span>
                            </div>
                            <div class="row no-gutters d-flex justify-content-between">
                                <div class="col">
                                    <div class="seller-count">
                                        Total Listing <span>3 Sellers</span>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="price">
                                        Starting at <span><i class="fa fa-inr"></i> 10,000 <b
                                                class="strikethrough">8000</b></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-one></app-footer-one>

<!-- Edit Bid Price MODAL -->
<ng-template #content2 let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>

    <div class="modal-body pb-3">
        <div class="row align-items-center mb-20">
            <div class="col-md-12">
                <div class="rtrqsthead">
                    <h4 class="txtdefault text-center wt700 fs22 mb-20">{{ bid_edit_type == 'price' ? 'Edit Bid Price' :
                        'Edit Order Quantity' }}</h4>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div>
                            <b>Current Minimum Bid</b><br>
                            <p class="f-700 color-orange fs16">{{ selected_bid.current_min_bid }} <a href="javascript:;"
                                    *ngIf="bid_edit_type == 'quantity'" (click)="changebidedit('price')"><img
                                        src="/assets/images/icons/edit.png"></a></p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div>
                            <b>Total Listed Quantity</b><br>
                            <p class="f-700 fs16">{{ selected_bid.listed_qty }} <a href="javascript:;"
                                    *ngIf="bid_edit_type == 'price'" (click)="changebidedit('quantity')"><img
                                        src="/assets/images/icons/edit.png"></a></p>
                        </div>
                    </div>
                </div>

                <!--Edit Bid Price-->
                <div class="row mt-30" *ngIf="bid_edit_type == 'price'">
                    <div class="col-md-10">
                        <div class="profileipt">
                            <div class="form-container mt-0 mb-0 p-eml-rt">
                                <div class="floating-label mb-10">
                                    <input type="text" class="form-control" placeholder="" [(ngModel)]="updated_price">
                                    <button class="btn fs16 btn-o-default" type="button" [disabled]="!updated_price"
                                        (click)="updatebid('price')">
                                        <span>Place a Bid</span>
                                    </button>
                                    <span class="highlight"></span>
                                    <label>Enter the New Bid Price</label>
                                </div>
                            </div>
                        </div>
                        <p class="mb-0 fs12">Please Enter {{ selected_bid.lowest_bid_amt }} or more</p>
                    </div>
                </div>

                <!--Edit Bid Quantity-->
                <div class="row mt-30" *ngIf="bid_edit_type == 'quantity'">
                    <div class="col-md-10">
                        <div class="profileipt">
                            <div class="form-container mt-0 mb-0 p-eml-rt">
                                <div class="floating-label mb-10">
                                    <input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="updated_quantity">
                                    <button class="btn fs16 btn-o-default" type="button" [disabled]="!updated_quantity"
                                        (click)="updatebid('quantity')">
                                        <span>Save Changes</span>
                                    </button>
                                    <span class="highlight"></span>
                                    <label>Enter the New Order Quantity</label>
                                </div>
                            </div>
                        </div>
                        <p class="mb-0 fs12">Please Enter {{ selected_bid.min_qty }} or more</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="text-left fs12">
                    By Placing a bid, you are agreeing to buy this item if your bid is accepted and will be required to
                    pay withing 4 days. The seller has 2 hours to respond.

                    <div><a href="javascript:;" class="link-alt fs14" (click)="showhistory()">{{ bid_view_history ?
                            'Hide Bid History' : 'View Bid History' }}</a></div>

                    <div class="accept-bid" *ngIf="bid_view_history">
                        <table class="table custom-table xs mb-0">
                            <thead>
                                <tr>
                                    <th>Bid Status</th>
                                    <th>Bid Time</th>
                                    <th>Order Quantity</th>
                                    <th>Bidding Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let history of bid_history_records; let k = index;">
                                    <td>{{ history.status }}</td>
                                    <td>{{ history.bid_time }}</td>
                                    <td>{{ history.order_qty }}</td>
                                    <td>{{ history.bidding_amount }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- /Edit Bid Price MODAL -->


<!-- ACCEPT COUNTER OFFER MODAL -->
<ng-template #contentacceptcounter let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>
    <div class="modal-body pb-3">
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead">
                    <h4 class="txtdefault text-center wt700 fs22 mb-20">Accept Counter Offer</h4>
                </div>

                <div class="accept-bid">
                    <table class="table custom-table xs mb-0">
                        <thead>
                            <tr>
                                <th>Bid Id</th>
                                <th>Order Qty</th>
                                <th>Bid Price</th>
                                <th>Counter Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ selected_bid.bid_unique_id }}</td>
                                <td>{{ selected_bid.order_qty }}</td>
                                <td>{{ selected_bid.bid_price }}</td>
                                <td>{{ selected_bid.counter_amount }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="text-left mt-30">
                    <div class="text-muted mb-10">Are you sure you want to accept this Counter Offer?</div>
                    <div class="d-flex align-items-center">
                        <a href="javascript:void(0);" class="btn-d-outline-success ripple d-inline-block place-bid2"
                            (click)="acceptcounteroffer()">
                            Accept Offer
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead text-center">
                    <h4 class="txtdefault wt700 fs16 mb-10">Net Payable Amount</h4>
                </div>

                <div class="inrcard p-3 radius10 position-relative">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6 card-price-info">Net Goods Value:</div>
                                <div class="col-md-6 card-price-info text-right">{{ selected_bid.breakdown.without_gst
                                    }}</div>
                            </div>
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6 card-price-info">GST:</div>
                                <div class="col-md-6 card-price-info text-right">{{ selected_bid.breakdown.gst_amount }}
                                </div>
                            </div>
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6 card-price-info">Convenience Charges:</div>
                                <div class="col-md-6 card-price-info text-right">{{ selected_bid.breakdown.convience_fee
                                    }}</div>
                            </div>
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6 card-price-info">Logistics Charges:</div>
                                <div class="col-md-6 card-price-info text-right">{{ selected_bid.breakdown.logistics_fee
                                    }}</div>
                            </div>
                            <!-- <div class="row d-flex justify-content-between">
							<div class="col-md-6 card-price-info">Coupon Discount:</div>
							<div class="col-md-6 card-price-info text-right text-active">- {{ discount_amount }}</div>
						</div> -->
                            <hr class="mt-1 mb-1">
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6 card-price-info f-700">Total Order Value:</div>
                                <div class="col-md-6 card-price-info text-right f-700">{{
                                    selected_bid.breakdown.net_realization }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- /ACCEPT BID MODAL -->