import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class SellerAuthGuard implements CanActivate {
    constructor(private auth: AuthService, private router: Router) {

    }
    canActivate() {
        if (this.auth.isLoggedIn() && this.auth.loggedInUserType(2)) {
            return true;
        }
        if (this.auth.isLoggedIn() && this.auth.loggedInUserType(1)) {
            this.router.navigate(['/home']);
            return false;
        }
        if (this.auth.isLoggedIn() && this.auth.loggedInUserType(3)) {
            this.router.navigate(['/admin/dashboard']);
            return false;
        }
        this.auth.logoutUser();
        this.router.navigate(['/home']);
        return false;

    }

}
