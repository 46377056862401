import { Component, OnInit } from '@angular/core';
import { ToasterService } from '../../../helpers/toastr.service';
import { EncrDecrService } from '../../../helpers/EncrDecrService';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CmsBuyerHomeService } from './buyer-home.service';

@Component({
  selector: 'app-buyer-home',
  templateUrl: './buyer-home.component.html',
  styleUrls: ['./buyer-home.component.scss']
})
export class BuyerHomeComponent implements OnInit {

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  public latest_arraivals_records:any = [];
  public top_selling_products_records:any = [];
  public fast_moving_records:any = [];
  public dynamic_section_records:any = [];
  public dynamic_section_name:string = '';
  public dynamic_section_status:string = 'no';
  public type:string;
  public cat_type:string;

  public top_selling_categories:any = [];
  public deals_categories_records:any = [];
  public buttonLoading:boolean = false;
  closeResult = '';
  public brand_logo_name: string;
  public brand_logo_image: any;
  public brand_logo: string = '';
  public countryArray = [];
  public categories = [];
  public brand_logo_status: boolean = false;
  constructor(
      private modalService: NgbModal,
      private _toastr: ToasterService,
      private _cmsbuyerhomeservice: CmsBuyerHomeService,
      private _EncrDecr: EncrDecrService,
      private fb: FormBuilder,
  ) { }

  ngOnInit(): void {

      this.dropdownList = [];
      this.dropdownSettings = {
          singleSelection: false,
          idField: 'value',
          textField: 'name',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 100,
          allowSearchFilter: true
      };

      this.getcmsdata();

  }
  config = {
      labelField: 'name',
      valueField: 'value',

  };



  onItemSelect(item: any) {
  }
  onSelectAll(items: any) {
  }

  onItemDeSelect($event) {
  }

  savecms() {
      this.categories = [];
      for (let i = 0; i < this.selectedItems.length; i++) {
          var id = this.selectedItems[i].value;
          this.categories.push(id);
      }
        this.buttonLoading = true;
      
      this._cmsbuyerhomeservice.savecms(null).subscribe((data) => {
          if (data['status'] == "success") {
              this.brand_logo_name = '';
              this.brand_logo_image = '';
              this.dropdownList = [];
              this._toastr.success('New brand added successfully.');
          } else if (data['status'] == "error") {
              this._toastr.error(data['message']);
          }
          this.buttonLoading = false;
      }, (error) => {
          this.buttonLoading = false;
      });
  }

    getcmsdata() {
        this._cmsbuyerhomeservice.getcmsdata(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.latest_arraivals_records = data['records']['latest_products'];
                this.top_selling_products_records = data['records']['top_selling_products'];
                this.fast_moving_records = data['records']['fast_moving_products'];
                this.dynamic_section_records = data['records']['dynamic_section_records'];
                this.dynamic_section_name = data['records']['dynamic_section_name'];
                this.dynamic_section_status = data['records']['dynamic_section_status'];
                this.deals_categories_records = data['records']['deals_categories'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

  savecategoryimage(event, cat){
      const file = event.target.files[0];
      cat.image = file;
      cat.image_name = file.name;
  }

  uploadfile(event) {
      const file = event.target.files[0];
      this.brand_logo = file;
      this.brand_logo_status = true;
      this.brand_logo_name = file.name;
      const reader = new FileReader();
      reader.onload = e => this.brand_logo_image = reader.result;
      reader.readAsDataURL(file);
  }


  openproductsearch(productsearch, type) {
      this.type = type;
      this.modalService.open(productsearch, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

    opencategorysearch(categorysearch, type) {
        this.cat_type = type;
        this.modalService.open(categorysearch, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return `with: ${reason}`;
      }
  }

    removerecords(index,type) {
        if(type == 'deals_categories'){
            this.deals_categories_records.splice(index, 1);
        }else if(type == 'latest_products'){
            this.latest_arraivals_records.splice(index, 1);
        }else if(type == 'top_selling_products'){
            this.top_selling_products_records.splice(index, 1);
        }else if(type == 'fast_moving_products'){
            this.fast_moving_records.splice(index, 1);
        }else if(type == 'dynamic_section_products'){
            this.dynamic_section_records.splice(index, 1);
        }
        
    }

    cmsupdate(type){
        var values = [];
        var dynamic_section_name = '';
        var dynamic_section_status = 'no';
        if(type == 'latest_products'){
            values = this.latest_arraivals_records;
        }else if(type == 'top_selling_products'){
            values = this.top_selling_products_records;
        }else if(type == 'fast_moving_products'){
            values = this.fast_moving_records;
        }else if(type == 'deals_categories'){
            values = this.deals_categories_records;
        }else if(type == 'dynamic_section_products'){
            values = this.dynamic_section_records;
            type = 'home_dynamic_section';
            dynamic_section_name = this.dynamic_section_name;
            dynamic_section_status = this.dynamic_section_status;
        }
        // if(values.length < 5){
        //     this._toastr.error('Please select minimun 5 record');
        //     return false;
        // }
        this._cmsbuyerhomeservice.savehomecms({type: type, values: values, section_name: dynamic_section_name, section_status: dynamic_section_status}).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('CMS details has been updated successfully');
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

  keyword = 'name'
  result = [];
  category_result = [];

    selectEvent(item) {
        if(this.type == 'latest_products'){
            this.latest_arraivals_records.push({id: item.id, name: item.name});
        }else if(this.type == 'top_selling_products'){
            this.top_selling_products_records.push({id: item.id, name: item.name});
        }else if(this.type == 'fast_moving_products'){
            this.fast_moving_records.push({id: item.id, name: item.name});
        }else if(this.type == 'dynamic_section_products'){
            this.dynamic_section_records.push({id: item.id, name: item.name});
        }
        this.result = [];
    }

  onChangeSearch(val: string) {
      this._cmsbuyerhomeservice.searchproduct({ search: val }).subscribe((data) => {
          if (data['status'] == "success") {
              this.result = data['searchResult'];
          } else if (data['status'] == "error") {
              this._toastr.error(data['message']);
          }
      });
  }

  onFocused(e) {
      // do something when input is focused
  }


    cselectEvent(item) {
        if(this.cat_type == 'deals_categories'){
            this.deals_categories_records.push({id: item.c_id, name: item.c_name});
        }
        this.category_result = [];
    }

    conChangeSearch(val: string) {
        this._cmsbuyerhomeservice.searchcategory({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.category_result = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    conFocused(e) {
    // do something when input is focused
    }

}
