<app-header-one [sticky]="true"></app-header-one>
<div class="container pb-5">
    <!-- BREADCRUMB -->
    <nav class="mb-md-2 pt-2 pb-md-2" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
        <ol class="breadcrumb p-0 bg-transparent">
            <li class="breadcrumb-item">
                <a href="javascript:void(0);" class="txtseconday" [routerLink]="['/home']">Home
                </a>
            </li>
            <li class="breadcrumb-item" *ngFor="let cate of category_flow;">
                <a href="javascript:void(0);" class="txtseconday">{{ cate }}
                </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">{{ category_name }}</li>
        </ol>
    </nav>
    <!-- /BREADCRUMB -->

    <div class="row">
        <!-- FILTER -->
        <div class="col-lg-3 mt-2 pt-2 filtercol filtermbl" [ngClass]="classstatus ? 'showfilter' : ''">
            <div class="brand-sbx p-3 radius10">
                <h4 class="fs16 wt700 txtdefault mb-2">Search what you are looking for?</h4>
                <div class="srch-ipt">
                    <input class="form-control fs14 border" type="text" placeholder="Search here"
                        id="example-search-input">
                    <img src="assets/images/svg/search_logo.svg">
                </div>
            </div>
            <div class="fltrshead">
                <h4>Filter</h4>
                <a (click)="tglclass()" class="closefilter" href="javascript:void(0);"><svg _ngcontent-serverApp-c132=""
                        xmlns="http://www.w3.org/2000/svg" width="6.508" height="6.508" viewBox="0 0 6.508 6.508">
                        <path _ngcontent-serverApp-c132="" id="np_cross_1707162_000000"
                            d="M30.883,30.883a.507.507,0,0,1,.717,0l2.389,2.389,2.389-2.389a.507.507,0,0,1,.717.717L34.7,33.988l2.389,2.389a.507.507,0,0,1-.717.717L33.988,34.7,31.6,37.094a.507.507,0,0,1-.717-.717l2.389-2.389L30.883,31.6A.507.507,0,0,1,30.883,30.883Z"
                            transform="translate(-30.734 -30.734)" fill="#1f1d44"></path>
                    </svg></a>
            </div>
            <div class="lsting">
                <ngb-accordion #acc="ngbAccordion" activeIds="{{ active_ids }}" class="accordion">
                    <ngb-panel id="toggle-1" opened class="bg-transparent" title="Market Preference">
                        <ng-template ngbPanelContent>
                            <ul class="list-group">
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" value="bulk" />
                                        <label class="css-label">Bulk</label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" value="wholesale" />
                                        <label class="css-label">Wholesale</label>
                                    </div>
                                </li>
                            </ul>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="toggle-2" title="Product Types">
                        <ng-template ngbPanelContent>
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let category of filter_category; let i = index;">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" value="{{ category.slug }}" />
                                        <label class="css-label">{{ category.name }} ({{ category.count }})</label>
                                    </div>
                                </li>
                            </ul>
                        </ng-template>
                    </ngb-panel>
                    <!-- <ngb-panel id="toggle-3" title="Model">
						<ng-template ngbPanelContent>
							<div class="input-group mb-3 inptsearch">
							  <span class="input-group-text">
								<svg xmlns="http://www.w3.org/2000/svg" width="13.685" height="13.681" viewBox="0 0 13.685 13.681">
	  <path id="np_search_1120430_000000" d="M29.587,29.163l-2.728-2.748A5.6,5.6,0,0,0,28.3,22.649a5.649,5.649,0,1,0-5.65,5.669,5.578,5.578,0,0,0,3.767-1.461l2.728,2.728a.281.281,0,0,0,.423,0,.261.261,0,0,0,.019-.422Zm-6.937-1.5a5.025,5.025,0,1,1,5.034-5.016A5.052,5.052,0,0,1,22.65,27.665Z" transform="translate(-16.501 -16.5)" fill="#5e6a7c" stroke="#5e6a7c" stroke-width="1"/>
	</svg>

							  </span>
							  <input type="text" class="form-control" placeholder="Search here" >
							</div>
							<ul class="list-group">
								<li class="list-group-item">
									<div class="css-check">
										<input class="css-checkbx" type="checkbox" />
										<label class="css-label">A31</label>
									</div>								
								</li>
								<li class="list-group-item">
									<div class="css-check">
										<input class="css-checkbx" type="checkbox" />
										<label class="css-label">A15</label>
									</div>
								</li>
								<li class="list-group-item">
									<div class="css-check">
										<input class="css-checkbx" type="checkbox" />
										<label class="css-label">A21</label>
									</div>								
								</li>
								<li class="list-group-item">
									<div class="css-check">
										<input class="css-checkbx" type="checkbox" />
										<label class="css-label">F5</label>
									</div>
								</li>
								<li class="list-group-item">
									<div class="css-check">
										<input class="css-checkbx" type="checkbox" />
										<label class="css-label">F17</label>
									</div>								
								</li>
								<li class="list-group-item">
									<div class="css-check">
										<input class="css-checkbx" type="checkbox" />
										<label class="css-label">Reno2</label>
									</div>
								</li>
							</ul>
							<a class="vwmre" href="javascript:void(0);">View More</a>
						</ng-template>
					</ngb-panel> -->
                    <ngb-panel id="brand" title="Brands">
                        <ng-template ngbPanelContent>
                            <div class="input-group mb-3 inptsearch">
                                <span class="input-group-text">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.685" height="13.681"
                                        viewBox="0 0 13.685 13.681">
                                        <path id="np_search_1120430_000000"
                                            d="M29.587,29.163l-2.728-2.748A5.6,5.6,0,0,0,28.3,22.649a5.649,5.649,0,1,0-5.65,5.669,5.578,5.578,0,0,0,3.767-1.461l2.728,2.728a.281.281,0,0,0,.423,0,.261.261,0,0,0,.019-.422Zm-6.937-1.5a5.025,5.025,0,1,1,5.034-5.016A5.052,5.052,0,0,1,22.65,27.665Z"
                                            transform="translate(-16.501 -16.5)" fill="#5e6a7c" stroke="#5e6a7c"
                                            stroke-width="1" />
                                    </svg>
                                </span>
                                <input type="text" class="form-control" placeholder="Search here"
                                    ([ngModel])="brand_search">
                            </div>
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let brand of filter_brands; let i = index;">
                                    <!-- | filter:{name: brand_search} -->
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" value="brand.slug" />
                                        <label class="css-label">{{ brand.brand_name }}</label>
                                    </div>
                                </li>
                            </ul>
                            <a class="vwmre" href="javascript:void(0);">View More</a>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="startingprice" title="Starting Price">
                        <ng-template ngbPanelContent>
                            <div class="input-group mb-3 inptsearch">
                                <span class="input-group-text">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.685" height="13.681"
                                        viewBox="0 0 13.685 13.681">
                                        <path id="np_search_1120430_000000"
                                            d="M29.587,29.163l-2.728-2.748A5.6,5.6,0,0,0,28.3,22.649a5.649,5.649,0,1,0-5.65,5.669,5.578,5.578,0,0,0,3.767-1.461l2.728,2.728a.281.281,0,0,0,.423,0,.261.261,0,0,0,.019-.422Zm-6.937-1.5a5.025,5.025,0,1,1,5.034-5.016A5.052,5.052,0,0,1,22.65,27.665Z"
                                            transform="translate(-16.501 -16.5)" fill="#5e6a7c" stroke="#5e6a7c"
                                            stroke-width="1" />
                                    </svg>
                                </span>
                                <input type="text" class="form-control" placeholder="Search here">
                            </div>
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let price of filter_prices; let i = index;">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" />
                                        <label class="css-label">{{ price.name }}</label>
                                    </div>
                                </li>
                            </ul>
                            <div class="pricerange">
                                <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options">
                                </ngx-slider>
                                <div class="row g-2">
                                    <div class="col-6">
                                        <input class="form-control" type="number" [(ngModel)]="minValue">
                                    </div>
                                    <div class="col-6">
                                        <input class="form-control" type="number" [(ngModel)]="maxValue">
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel *ngFor="let variant of filter_variants; let i = index;" id="{{variant.slug}}"
                        title="{{ variant.name }}">
                        <ng-template ngbPanelContent>
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let meta of variant.metas; let k = index;">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" value="{{ meta.slug }}" />
                                        <label class="css-label">{{ meta.name }}</label>
                                    </div>
                                </li>
                            </ul>
                        </ng-template>
                    </ngb-panel>
                    <!-- <ngb-panel id="activationstatus" title="Product Status">
						<ng-template ngbPanelContent>
							<div class="savefilter no-border pt-3">
								<div class="icnsavefilter">
									<div class="css-check">
										<input class="css-checkbx" type="checkbox" />
										<label class="css-label">Activated</label>
										
									</div>		
									<i placement="top" ngbTooltip="from wholesaler, retailer, etc">
									<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
									  <g id="Group_68" data-name="Group 68" transform="translate(-545 -281)">
										<circle id="Ellipse_1" data-name="Ellipse 1" cx="7.5" cy="7.5" r="7.5" transform="translate(545 281)" fill="#07f"/>
										<path id="Path_2235" data-name="Path 2235" d="M7,8.171l-.128.523q-.576.227-.919.346a2.425,2.425,0,0,1-.8.119,1.591,1.591,0,0,1-1.084-.34,1.1,1.1,0,0,1-.387-.864,3.056,3.056,0,0,1,.029-.416q.029-.213.093-.48l.48-1.7q.064-.244.108-.462a2.01,2.01,0,0,0,.044-.4A.616.616,0,0,0,4.3,4.047a.759.759,0,0,0-.513-.128,1.345,1.345,0,0,0-.381.058c-.131.039-.242.076-.336.11L3.2,3.564q.471-.192.9-.329A2.7,2.7,0,0,1,4.916,3.1a1.552,1.552,0,0,1,1.068.335,1.111,1.111,0,0,1,.375.869c0,.074-.008.2-.026.39a2.606,2.606,0,0,1-.1.512L5.759,6.9a4.492,4.492,0,0,0-.1.466,2.4,2.4,0,0,0-.047.4.57.57,0,0,0,.15.459.85.85,0,0,0,.523.122,1.509,1.509,0,0,0,.4-.061A2.21,2.21,0,0,0,7,8.171Zm.121-7.1a.99.99,0,0,1-.334.753,1.138,1.138,0,0,1-.8.312,1.15,1.15,0,0,1-.809-.312.988.988,0,0,1-.337-.753A1,1,0,0,1,5.169.314a1.194,1.194,0,0,1,1.614,0A1,1,0,0,1,7.117,1.07Z" transform="translate(547.406 283.92)" fill="#fff"/>
									  </g>
									</svg>
									</i>
								</div>	
							</div>

							<div class="savefilter no-border pt-3 mb-10">
								<div class="icnsavefilter">
									<div class="css-check">
										<input class="css-checkbx" type="checkbox" />
										<label class="css-label">Fresh</label>
										
									</div>		
									<i placement="top" ngbTooltip="(Directly from Brand or Brand Authorized Seller)">
									<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
									  <g id="Group_68" data-name="Group 68" transform="translate(-545 -281)">
										<circle id="Ellipse_1" data-name="Ellipse 1" cx="7.5" cy="7.5" r="7.5" transform="translate(545 281)" fill="#07f"/>
										<path id="Path_2235" data-name="Path 2235" d="M7,8.171l-.128.523q-.576.227-.919.346a2.425,2.425,0,0,1-.8.119,1.591,1.591,0,0,1-1.084-.34,1.1,1.1,0,0,1-.387-.864,3.056,3.056,0,0,1,.029-.416q.029-.213.093-.48l.48-1.7q.064-.244.108-.462a2.01,2.01,0,0,0,.044-.4A.616.616,0,0,0,4.3,4.047a.759.759,0,0,0-.513-.128,1.345,1.345,0,0,0-.381.058c-.131.039-.242.076-.336.11L3.2,3.564q.471-.192.9-.329A2.7,2.7,0,0,1,4.916,3.1a1.552,1.552,0,0,1,1.068.335,1.111,1.111,0,0,1,.375.869c0,.074-.008.2-.026.39a2.606,2.606,0,0,1-.1.512L5.759,6.9a4.492,4.492,0,0,0-.1.466,2.4,2.4,0,0,0-.047.4.57.57,0,0,0,.15.459.85.85,0,0,0,.523.122,1.509,1.509,0,0,0,.4-.061A2.21,2.21,0,0,0,7,8.171Zm.121-7.1a.99.99,0,0,1-.334.753,1.138,1.138,0,0,1-.8.312,1.15,1.15,0,0,1-.809-.312.988.988,0,0,1-.337-.753A1,1,0,0,1,5.169.314a1.194,1.194,0,0,1,1.614,0A1,1,0,0,1,7.117,1.07Z" transform="translate(547.406 283.92)" fill="#fff"/>
									  </g>
									</svg>
									</i>
								</div>	
							</div>						
						</ng-template>
					</ngb-panel> -->
                    <ngb-panel id="supplylocation" title="Supply Location">
                        <ng-template ngbPanelContent>
                            <ul class="list-group">
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" />
                                        <label class="css-label">Bhiwandi</label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" />
                                        <label class="css-label">Jaipur</label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" />
                                        <label class="css-label">Mumbai</label>
                                    </div>
                                </li>

                            </ul>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="countryorigin" title="Country of origin">
                        <ng-template ngbPanelContent>
                            <ul class="list-group">
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" />
                                        <label class="css-label">Made in India</label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" />
                                        <label class="css-label">Made in Japan</label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" />
                                        <label class="css-label">Made in South Korea</label>
                                    </div>
                                </li>

                            </ul>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="savefilter pt-3">
                    <div class="icnsavefilter">
                        <div class="css-check">
                            <input class="css-checkbx" type="checkbox" />
                            <label class="css-label">Save as a quick filter</label>

                        </div>
                        <i placement="top"
                            ngbTooltip="This will act as a default filter every time you will search. However, you can add or change anytime.">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                                <g id="Group_68" data-name="Group 68" transform="translate(-545 -281)">
                                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="7.5" cy="7.5" r="7.5"
                                        transform="translate(545 281)" fill="#07f" />
                                    <path id="Path_2235" data-name="Path 2235"
                                        d="M7,8.171l-.128.523q-.576.227-.919.346a2.425,2.425,0,0,1-.8.119,1.591,1.591,0,0,1-1.084-.34,1.1,1.1,0,0,1-.387-.864,3.056,3.056,0,0,1,.029-.416q.029-.213.093-.48l.48-1.7q.064-.244.108-.462a2.01,2.01,0,0,0,.044-.4A.616.616,0,0,0,4.3,4.047a.759.759,0,0,0-.513-.128,1.345,1.345,0,0,0-.381.058c-.131.039-.242.076-.336.11L3.2,3.564q.471-.192.9-.329A2.7,2.7,0,0,1,4.916,3.1a1.552,1.552,0,0,1,1.068.335,1.111,1.111,0,0,1,.375.869c0,.074-.008.2-.026.39a2.606,2.606,0,0,1-.1.512L5.759,6.9a4.492,4.492,0,0,0-.1.466,2.4,2.4,0,0,0-.047.4.57.57,0,0,0,.15.459.85.85,0,0,0,.523.122,1.509,1.509,0,0,0,.4-.061A2.21,2.21,0,0,0,7,8.171Zm.121-7.1a.99.99,0,0,1-.334.753,1.138,1.138,0,0,1-.8.312,1.15,1.15,0,0,1-.809-.312.988.988,0,0,1-.337-.753A1,1,0,0,1,5.169.314a1.194,1.194,0,0,1,1.614,0A1,1,0,0,1,7.117,1.07Z"
                                        transform="translate(547.406 283.92)" fill="#fff" />
                                </g>
                            </svg>
                        </i>
                    </div>
                </div>
                <div class="tex-center clr-apl-btn showintablet">
                    <a href="javascript:void(0);" class="btn-d-outline mr-3 d-inline-block place-bid">Clear All</a>
                    <a href="javascript:void(0);" class="buybtn d-inline-block radius10">Apply</a>
                </div>
            </div>
        </div>
        <!-- /FILTER -->
        <!-- LISTING -->
        <div class="col-lg-9 pt-3 listing-content">
            <!-- TOTAL LISTING -->
            <div class="row">
                <div class="col-md-6 mb-md-5 mb-4">
                    <div class="tl-list radius10">
                        <h4 class="fs20 wt700 txtdefault mb-2">{{ total_listing }} listings <i class="fs14">from {{
                                total_supplier }} Suppliers</i></h4>
                        <span class="fs12 txtdefault mr-4"><img class="mr-1" src="assets/images/svg/bulk.svg" /> {{
                            total_bulk }} Bulk</span>
                        <span class="fs12 txtdefault"><img class="mr-1" src="assets/images/svg/wholesale.svg" /> {{
                            total_wholesale }} Wholesale</span>
                        <img class="bulkfile" src="assets/images/svg/file.svg" />
                    </div>
                </div>
                <div class="col-md-6 mb-sm-5 mb-4">
                    <div class="tl-list tl-acs  radius10">
                        <h4 class="fs20 wt700 textwhite mb-2">Tablets Accessories</h4>
                        <span class="fs12 textwhite">Get quality accessories, equipment at wholesale prices. </span>
                        <div class="dscnt-badge">
                            <p class="textwhite wt700">Earn Profit Upto</p>
                            <h3 class="text-white fs16 wt700">30%</h3>
                        </div>
                    </div>
                </div>
            </div>

            <!-- /TOTAL LISTING  -->
            <!-- SORTING -->
            <div class="row mb-4">
                <div class="col-lg-8  srtlist">
                    <h3 class="txtdefault f-700 fs16 mb-3"><img src="assets/images/huawai.png"> <span
                            class="fs14 wt400">(51 products search in the result)</span></h3>
                    <!-- <span class="btn btn-outline-dark f-600 txtdefault">Smart Phones
						<a class="crosstag">
							<svg xmlns="http://www.w3.org/2000/svg" width="6.508" height="6.508" viewBox="0 0 6.508 6.508">
							  <path id="np_cross_1707162_000000" d="M30.883,30.883a.507.507,0,0,1,.717,0l2.389,2.389,2.389-2.389a.507.507,0,0,1,.717.717L34.7,33.988l2.389,2.389a.507.507,0,0,1-.717.717L33.988,34.7,31.6,37.094a.507.507,0,0,1-.717-.717l2.389-2.389L30.883,31.6A.507.507,0,0,1,30.883,30.883Z" transform="translate(-30.734 -30.734)" fill="#1f1d44"/>
							</svg>
						</a>
					</span>					
					<a class="btn-link nav-btn-link" href="javascript:void(0);">Clear All</a> -->
                </div>
                <div class="col-lg-4 listingsort">
                    <div class="fltsortnv mt-0">
                        <a href="javascript:void(0);" (click)="ratingsort()" class="sorticon mr-2">
                            <img src="assets/images/svg/sort.svg">
                        </a>
                        <a href="javascript:void(0);" (click)="tglclass()" class="filtericon">
                            <img src="assets/images/svg/filter.svg">
                        </a>
                    </div>
                    <div class="sortselect" [ngClass]="ratingst ? 'showsort' : ''">
                        <div class="fltrshead srthead">
                            <h4>Sort By</h4>
                            <a (click)="ratingsort()" class="closefilter" href="javascript:void(0);"><svg
                                    _ngcontent-serverApp-c132="" xmlns="http://www.w3.org/2000/svg" width="6.508"
                                    height="6.508" viewBox="0 0 6.508 6.508">
                                    <path _ngcontent-serverApp-c132="" id="np_cross_1707162_000000"
                                        d="M30.883,30.883a.507.507,0,0,1,.717,0l2.389,2.389,2.389-2.389a.507.507,0,0,1,.717.717L34.7,33.988l2.389,2.389a.507.507,0,0,1-.717.717L33.988,34.7,31.6,37.094a.507.507,0,0,1-.717-.717l2.389-2.389L30.883,31.6A.507.507,0,0,1,30.883,30.883Z"
                                        transform="translate(-30.734 -30.734)" fill="#1f1d44"></path>
                                </svg></a>
                        </div>
                        <ng-selectize [config]="config" [options]="data" placeholder="Sort By Relevance">

                        </ng-selectize>
                    </div>

                </div>
            </div>
            <!-- /SORTING  -->
            <!-- PRODUCT ITEMS -->
            <div class="prdt-rw" *ngIf="fast_selling_products.length > 0">
                <div class="row p-list-item">
                    <!-- REPEAT -->
                    <div class="col-6 col-md-4 col-xl-3  mb-3"
                        *ngFor="let product of fast_selling_products; let i = index;">
                        <div class="product-item  pt-2" [routerLink]="['/product/details/'+product.unique_id]">
                            <!-- <div class="ex-deal"><span>Excellent Deal</span></div> -->
                            <div class='image pt-0'>
                                <div class="d-table">
                                    <div class="d-table-cell align-middle">
                                        <img [src]='product.p_front_image'>
                                    </div>
                                </div>
                            </div>
                            <div class="product-name fs14">
                                {{ product.brand_name }}&nbsp;{{ product.model_number }} ({{ product.variant_names}})
                            </div>
                            <div class="ratings" *ngIf="product.star_rating.length > 0">
                                <i class="fa fa-star" aria-hidden="true" *ngFor="let star of product.star_rating"></i>
                                <span class='star-count'>({{product.total_rating}})</span>
                            </div>
                            <div class="row no-gutters d-flex justify-content-between">
                                <div class="col-5">
                                    <div class="seller-count">
                                        Total Listing <br /> <span>{{ product.total_seller }} Sellers</span>
                                    </div>
                                </div>
                                <div class="col-7">
                                    <div class="price">
                                        Starting at <br /><span><i class="fa fa-inr"></i> {{ product.sales_price }} <b
                                                class="strikethrough">{{ product.regular_price }}</b></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /REPEAT -->
                </div>
            </div>
            <!-- /PRODUCT ITEMS -->
            <!-- BANNER CROUSEL -->
            <div class="bnrcrslwp py-4">
                <ngb-carousel>
                    <ng-template ngbSlide>
                        <div class="bnr-img-wrapper">
                            <a href="javascript:void(0);">
                                <img src="assets/images/categories/banner-1-min.png" alt="mobile banner">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template ngbSlide>
                        <div class="bnr-img-wrapper">
                            <a href="javascript:void(0);">
                                <img src="assets/images/categories/banner-2-min.png" alt="mobile banner">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template ngbSlide>
                        <div class="bnr-img-wrapper">
                            <a href="javascript:void(0);">
                                <img src="assets/images/categories/banner-3-min.png" alt="mobile banner">
                            </a>
                        </div>
                    </ng-template>
                </ngb-carousel>
            </div>
            <!-- /BANNER CROUSEL -->
            <div class="pb-3">
                <h4 class="txtdefault wt700 fs16 mb-3">Other Popular Brands</h4>
                <div class="tpbrand my-4 px-4 py-2">
                    <div class="row align-items-center text-center">
                        <!-- REPEAT -->
                        <div class="col-3" *ngFor="let brand of brands_list; let i = index;">
                            <a href="javascript:void(0);" class="active" (click)="getbrandproduct(brand)">
                                <img [src]="brand.brand_logo" alt="{{ brand.brand_name }}"
                                    title="{{ brand.brand_name }}">
                            </a>
                        </div>
                        <!-- /REPEAT -->

                    </div>
                </div>
            </div>
        </div>
        <!-- /LISTING -->
    </div>
</div>

<app-footer-one></app-footer-one>