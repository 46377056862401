import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { LogisticsPartnerListService } from './logistic-partners-list.service';

@Component({
    selector: 'app-logistic-partners-list',
    templateUrl: './logistic-partners-list.component.html',
    styleUrls: ['./logistic-partners-list.component.scss']
})
export class LogisticPartnersListComponent implements OnInit {

    closeResult = '';
    public selected_record:any = {};
    public partnerlist_records:any = [];

    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 10;
    public partnerlist_pagination: any = {};
    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];

  	constructor(
        private modalService: NgbModal,
        private _logisticspartnerlist: LogisticsPartnerListService,
        private _toastr: ToasterService,
        private _pagination: PaginationService
    ) {}

    ngOnInit(): void {
        this.getlogisticspartners();
    }

    getlogisticspartners() {
        let limit = this.page_per_view;
        let offset = this.offset;
        let current_page = this.current_page;
        this._logisticspartnerlist.getlogisticspartners({ limit: limit, offset: offset, current_page: current_page }).subscribe((data) => {
            if (data['status'] == "success") {
                let total_records = data['total_records'];
                this.partnerlist_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
                this.partnerlist_records = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    deletepartner() {
        this._logisticspartnerlist.deletepartner({ partner_id: this.selected_record.id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this._toastr.success('Logistic partner has been deleted successfully.');
                this.getlogisticspartners();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.getlogisticspartners();
    }

    setpageperview() {
        this.current_page = 1;
        this.getlogisticspartners();
    }

    open(content, record) {
        this.selected_record = record;
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'mblbtm' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

}
