import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from '../../helpers/toastr.service';
import { CountriesService } from './countries.service';

@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.scss']
})
export class CountriesComponent implements OnInit {

    closeResult = '';
    public search_term: string = '';
    public name:string = '';
    public country_code:string = '';
    public phone_code:string = '';

    public edit_name:string = '';
    public edit_country_code:string = '';
    public edit_phone_code:string = '';
    public editId:string = '';

    public records:any = [];
  constructor(
    private modalService: NgbModal,
    private _pageservice: CountriesService,
    private _toastr: ToasterService,
    private _pagination: PaginationService
    ) { }

    ngOnInit(): void {
        this.countrylist();
    }
    open(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true,}).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    openupdate(content, record) {
        this.edit_name = record.name;
        this.edit_country_code = record.countrycode;
        this.edit_phone_code = record.phone_code;
        this.editId = record.id;
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true,}).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    countrylist() {
        this._pageservice.getcountries({ search: this.search_term }).subscribe((data) => {
            if(data['status'] == "success"){
                this.records = data['records'];
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    addcountries() {
        this._pageservice.savecountry({name: this.name, countrycode: this.country_code, phonecode: this.phone_code}).subscribe((data) => {
            if(data['status'] == "success"){
                this._toastr.success('New country details has been created successfully.');
                this.countrylist();
                this.modalService.dismissAll();
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }


    updatecountries() {
        this._pageservice.updatecountry({id: this.editId, name: this.edit_name, countrycode: this.edit_country_code, phonecode: this.edit_phone_code}).subscribe((data) => {
            if(data['status'] == "success"){
                this._toastr.success('Country details has been updated successfully.');
                this.countrylist();
                this.modalService.dismissAll();
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

}
