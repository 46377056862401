import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ManageBuyerDetailsService } from './view-details.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-details',
  templateUrl: './view-details.component.html',
  styleUrls: ['./view-details.component.scss']
})
export class ViewDetailsComponent implements OnInit {

    closeResult = '';
    public buyerId = '';
    public personal_detail:any = {};
    public business_detail:any = {};
    public bank_detail:any = {};
    public reject_reason = '';
    public reject_message = '';
    public profileLoading:boolean = false;
    constructor(
        private modalService: NgbModal,
        private _managebuyerdetailsservice: ManageBuyerDetailsService,
        private _toastr: ToastrService,
        private route: ActivatedRoute,
    ) {}
    config = {
      labelField: 'label',
      valueField: 'value',
    
    }; 
    keyword = 'name';
    result = [
        {
           id: 1,
           icon: '123 ',
           value: 'Numeric Field'
        },
    ];

    public reasons = [
        {
            name: 'Improper Documents',
            value: 'Improper Documents',
        },
        {
            name: 'Address Verification pending',
            value: 'Address Verification pending',
        },
        {
            name: 'Aadhar number mismatch',
            value: 'Aadhar number mismatch',
        },
        {
            name: 'Incorrect PAN number',
            value: 'Incorrect PAN number',
        },
        {
            name: 'Aadhar not Linked with Account',
            value: 'Aadhar not Linked with Account',
        },
        {
            name: 'Missing Authorized Person',
            value: 'Missing Authorized Person',
        },
        {
            name: 'Other',
            value: 'Other',
        }
    ];

    changestatus(type){
        if(!this.reject_reason){
            this._toastr.error('Please select reject reason.');
            return false;
        }
        if(!this.reject_message){
            this._toastr.error('Please select reject message.');
            return false;
        }
        var data = {reason: this.reject_reason, message: this.reject_message, user_id: this.buyerId, type: type};
        this._managebuyerdetailsservice.changestatus(data).subscribe((data) => {
            if(data['status'] == "success"){
                if(this.personal_detail.usertype == 1){
                    this._toastr.success('Buyer rejected')
                }else{
                    this._toastr.success('Seller rejected')
                }
                this.getdetails();
                this.modalService.dismissAll();
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }



    selectEvent(item) {
        // do something with selected item
    }
     
    onChangeSearch(val: string) {
        // fetch remote data from here
        // And reassign the 'data' which is binded to 'data' property.
    }

    onFocused(e){
        // do something when input is focused
    }
    open(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'mblbtm' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    getdetails() {
        this._managebuyerdetailsservice.getdetails({id: this.buyerId}).subscribe((data) => {
            if(data['status'] == "success"){
                this.bank_detail = data['records']['bank_detail'];
                this.business_detail = data['records']['business_detail'];
                this.personal_detail = data['records']['personal_detail'];
            }else if(data['status'] == "error"){
              this._toastr.error(data['message']);
            }
        });
    }

    updateprofile() {
        this.profileLoading = true;
        this._managebuyerdetailsservice.updateprofile({user_id: this.buyerId, email: this.personal_detail.email}).subscribe((data) => {
            if(data['status'] == "success"){
                this._toastr.success('Email has been updated successfully.');
                this.getdetails();
                this.profileLoading = false;
            }else if(data['status'] == "error"){
                this.profileLoading = false;
              this._toastr.error(data['message']);
            }
        }, (error) => {
            this.profileLoading = false;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    openmdl(profilephoto) {
        this.modalService.open(profilephoto, { windowClass: 'midmodal' });
    }
    openmodal(rejectbuyer) {
        this.modalService.open(rejectbuyer, { ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'mblbtm', size: 'md' });
    }
    openattribute(addattribute) {
        this.modalService.open(addattribute, { ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'mblbtm', size: 'lg' });
    }
    ngOnInit(): void {
        this.buyerId = this.route.snapshot.paramMap.get('id');
        this.getdetails();

    }
    monthdata = [
    {
        label: 'In Months',
        value: 'In Months'
    },
    {
        label: 'In Year',
        value: 'In Year'
    }
  ]
}
