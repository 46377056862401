import { Component, OnInit } from "@angular/core";
import { BuyerBuyNowPayLaterService } from "./buyer-buy-now-pay-later.component.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToasterService } from "../../helpers/toastr.service";

@Component({
  selector: "app-buyer-buy-now-pay-later",
  templateUrl: "./buyer-buy-now-pay-later.component.html",
  styleUrls: ["./buyer-buy-now-pay-later.component.scss"],
})
export class BuyerBuyNowPayLaterComponent implements OnInit {
  public paymentStatus: any = {};
  public bnplDues: any = {};
  public status: any = null;
  public repayment_url: any = "";
  public duesData: any = [];
  public activation_url: string = '';

  constructor(private _pageservice: BuyerBuyNowPayLaterService,
    private _toastr: ToasterService,
    private router: Router
    ) {}

  ngOnInit(): void {
    this.bnplStatus();
    this.bnplDuesDetails();
  }

  // bnplUpdateDuesDetails() {
  //   let payload = {};
  //   this._pageservice.bnplUpdateDuesDetails(payload).subscribe((data) => {
  //     if (data["status"] == "success") {
  //       this.bnplDuesDetails();
  //     }
  //   });
  // }

  bnplDuesDetails() {
    let payload = {};
    this._pageservice.bnplDuesDetails(payload).subscribe((data) => {
      if (data["status"] == "success") {
        this.duesData = data?.['result'];
      }
    });
  }

  bnplStart() {
    let payload = {};
    this._pageservice.bnplStart(payload).subscribe((data) => {
      if (data["status"] == "success") {
        if (data["activation_url"]) {
          window.open(data["activation_url"], "_blank");
        }
        this.bnplStatus();
      }
    });
  }

  bnplInformation() {
    let data = {};
    this._pageservice.bnplInformation(data).subscribe((data) => {
      if (data["status"] == "success") {
        this.status = data?.["result"]?.["status"];
        this.repayment_url = data?.["result"]?.["repayment_url"];
        this.bnplDues = data?.["result"]?.['bnplDues'];
      }
    });
  }

  bnplStatus() {
    let data = {};
    this._pageservice.bnplStatus(data).subscribe((data) => {
      if (data["status"] == "success") {
          this.activation_url = (data["activation_url"]);
          this.paymentStatus = {
          status: data["bnpl_status"],
          accountDetails: data["accountDetails"]
          }
        this.bnplInformation();
      }
    });
  }

  viewDetails (id) {
      this.router.navigate([`/buyer/buyer-view-order-details/${id}`]);
  }

  viewAllOrders(){
    this.router.navigate([`/buyer/bnpl/transaction/reports`]);
  }
}
