import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class BuyerBuyNowPayLaterService {

    constructor(private _http: HttpClient) { }

    bnplStart(data) {
        return this._http.post(environment.api_url + 'bnpl/start', data);
    }
    
    bnplStatus(data) {
        return this._http.post(environment.api_url + 'bnpl/getStatus', data);
    }

    bnplInformation(data) {
        return this._http.post(environment.api_url + 'bnpl/information', data);
    }

    bnplDuesDetails(data) {
        return this._http.post(environment.api_url + 'bnpl/dues', data);
    }

    bnplUpdateDuesDetails(data) {
        return this._http.post(environment.api_url + 'bnpl/updatedues', data);
    }
}