import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { ViewSellerReviewService } from './view-seller-reviews.service';

@Component({
  selector: 'app-view-seller-review',
  templateUrl: './view-seller-review.component.html',
  styleUrls: ['./view-seller-review.component.scss']
})
export class ViewSellerReviewComponent implements OnInit {
    public review_id:string = '';
    public review_details:any = {};
    constructor(
        private _viewsellerreview: ViewSellerReviewService,
        private _toastr: ToasterService,
        private _route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.review_id = this._route.snapshot.paramMap.get('id');
        this.reviewdetails();
    }

    reviewdetails() {
        this._viewsellerreview.reviewdetails({ id: this.review_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.review_details = data['record'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    reviewstatus(val) {
        this._viewsellerreview.reviewstatus({ id: this.review_id, type: 'seller', status: val  }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Review status has been updated');
                this.reviewdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    updatereview() {
        this._viewsellerreview.updatereview({ id: this.review_id, type: 'seller', rating: this.review_details.product_review_rating , notes: this.review_details.product_review_desc }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Review details has been updated');
                this.reviewdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

}
