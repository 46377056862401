import { Component, OnInit, Input, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  @Input() class: string;
  @Input() sticky: boolean = false; // Default false

    public stick: boolean = false;
    constructor() { }
    ngOnInit(): void {

    }
     disabled = false;
    // @HostListener Decorator
    @HostListener("window:scroll", [])
    onWindowScroll() {
        let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (number >= 150 && window.innerWidth > 400) { 
            this.stick = true;
         } else {
             this.stick = false;
         }
    }
}
