<app-header-one [sticky]="true"></app-header-one>

<section class="small-section">
	<div class="container">
		<div class="row d-flex justify-content-center">
			<div class="col-md-4">
				<h2 class="section-title font-alt"><span class="color-blue">Let’s Talk</span>, And Trade.<br>Get In <span class="color-orange">Touch With Us</span> Now!</h2>
			</div>
		</div>
	</div>
</section>

<section class="small-section">
	<div class="container">
		<div class="row">
			<div class="col-md-6">
				<div class="contact-left-container">
					<h2 class="hs-line-1">Request call back</h2>

					<div class="row mb-40">
						<div class="col-md-8">
							<div class="form-container mt-0">
								<div class="floating-label mb-0">      
								   <input class="form-control" type="text" placeholder=" " [(ngModel)]="call_request_phone">
								   <span class="highlight"></span>
								   <label>Phone Number<span class="req">*</span></label>								  
								</div>
							</div>	
						</div>
						<div class="col-md-4">
							<button class="btn btn-mod btn-color btn-small btn-round ripple" type="button" (click)="savecallrequest()">Request Call Back</button>
						</div>
					</div>
					
					<h2 class="hs-line-1">EQ - Headquarter</h2>

					<div class="box-alt-item mb-20">
	                    <div class="box-alt-icon">
	                        <img src="assets/images/icons/mail.svg">
	                    </div>
	                    <h3 class="box-alt-desc"><a href="mailto:support@equibiz.in">support@equibiz.in</a></h3>
	                </div>
	                <div class="box-alt-item mb-20">
	                    <div class="box-alt-icon">
	                        <img src="assets/images/icons/phone-call.svg">
	                    </div>
	                    <h3 class="box-alt-desc"><a href="tel:18002102194">18002102194</a></h3>
	                </div>
	                <div class="box-alt-item mb-20">
	                    <div class="box-alt-icon">
	                        <img src="assets/images/icons/map-pin.svg">
	                    </div>
	                    <h3 class="box-alt-desc">Office No: 206 A, Gambhir Industrial Estate, Off. Aarey Road, Opp. Paramount Print, Goregaon (East), Mumbai - 400063</h3>
	                </div>
	            </div>
			</div>

			<div class="col-md-6">
				<div class="contact-form-container">
					<h2 class="hs-line-1">Enquiry / Get Info</h2>

					<!-- Basic Form-->
					<form [formGroup]="contactForm">
						<div class="select-type">
							<a href="javascript:;" [ngClass]="{'selected': user_type === 'buyer'}" (click)="updateusertype('buyer')">I am a Buyer</a>
							<a href="javascript:;" [ngClass]="{'selected': user_type === 'seller'}" (click)="updateusertype('seller')">I am a Seller</a>
						</div>
						<div class="form-container mb-3">
							<div class="floating-label mb-0">      
							   <input class="form-control" type="text" placeholder=" " formControlName="user_name">
							   <span class="highlight"></span>
							   <label>Your Name</label>
							</div>
						</div>
						<div class="form-container mb-3">
							<div class="floating-label mb-0">      
							   <input class="form-control" type="text" placeholder=" " formControlName="user_email">
							   <span class="highlight"></span>
							   <label>Email Address</label>
							</div>
						</div>
						<div class="form-container mb-3">
							<div class="floating-label mb-0">      
							   <input class="form-control" type="text" placeholder=" " formControlName="user_mobile">
							   <span class="highlight"></span>
							   <label>Mobile Number</label>
							</div>
						</div>
						<div class="form-container mb-3">
							<div class="floating-label mb-0">      
							   <textarea class="form-control floating-textarea" placeholder=" "  formControlName="user_message"></textarea>
							   <span class="highlight"></span>
							   <label>Message</label>
							</div>
						</div>
						<div class="text-right">
							<button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4" (click)="savecontact()" [disabled]="buttonLoading || contactForm.invalid">
								<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="cbuttonLoading"></span>
	                                &nbsp;Send 
							</button>
						</div>
					</form>
					<!-- End Basic Form-->

					<!--Know More Form-->
					<form class="d-none">
						<div class="form-container mb-0">
							<div class="floating-label mb-0">      
							   <select class="form-control">
								  	<option>Please Select</option>
								  	<option>Collection</option>
								  	<option>Return & Refunds</option>
								  	<option>Product & Stock</option>
								  	<option>Data Privacy</option>
								  	<option>Logistic Partner</option>
								  	<option>Payment & Promo</option>
								</select>
							   <span class="highlight"></span>
							   <label>Select the type of enquiry<span class="req">*</span></label>
							</div>
						</div>
						<div class="form-container mb-3">
							<div class="floating-label mb-0">      
							   <textarea class="form-control floating-textarea" placeholder=" "></textarea>
							   <span class="highlight"></span>
							   <label>Message</label>
							</div>
						</div>
						<div class="text-right">
							<button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4">Send 
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="small-section bg-light-blue">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h2 class="hs-line-1 mb-10">Got a Question?</h2>
				<div class="section-text text-left">
					<p>Reach out to our client associates for further assistance.</p>
				</div>
			</div>
		</div>


		<div class="row faqpanel accordionarrow">
			<div class="col-md-12">
				<ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
				  <ngb-panel title="What is Equibiz?">
					<ng-template ngbPanelContent>
					  <p>Equibiz is a B2B e-commerce platform where we enable our users to connect with verified buyers and sellers and help them conduct the transaction with utmost transparency. Equibiz is powered with premium features such as bid and offer, price negotiation, bulk & wholesale market segmentation, etc. which ensure the best deal to our partners every time they trade.</p>
					  <p>Equibiz will provide integrated third-party logistics services, reliable payment services, quality control, integrated invoice & waybill services, and other value-added services to ensure a seamless and hassle-free deal-making experience.</p>
					</ng-template>
				  </ngb-panel>
				  <ngb-panel title="Advantage Equibiz?">
					<ng-template ngbPanelContent>
					  <ul>
						<li>Buy or sell in Bulk and Wholesale/Retail</li>
						<li>Buy or Sell at your price (BID & OFFER)</li>
						<li>Price Negotiation (BID & OFFER)</li>
						<li>Supplier Management</li>
						<li>Price Protection (Fixing of minimum BID price)</li>
						<li>Creation of a credible user profile (Through ratings and reviews)</li>
						<li>Access to a large number of KYC verified buyers and sellers</li>
						<li>Hassel free integrated third-party logistics services</li>
						<li>Direct deal with verified sellers (Brand, Wholesaler, Distributors, etc.)</li>
						<li>Optimization of working capital (buying as per the requirement in bulk/wholesale)</li>
					  </ul>
					</ng-template>
				  </ngb-panel>
				  <ngb-panel title="What is a bulk and wholesale listing?">
					<ng-template ngbPanelContent>
					 <p>Equibiz facilitates both large and small value transactions to meet the requirements of different stakeholders such as brand, wholesaler, distributor, retailer, corporate, exporter, importer, etc. Under the bulk segment, the suppliers have the freedom to select deal terms such as minimum order quantity, minimum bid price, supply location, payment option, etc. Whereas under the wholesale segment, the minimum transaction value must be INR 3,000/- or above and the trade terms are set to facilitate the retailer’s or small value buyer’s requirement.</p>
					</ng-template>
				  </ngb-panel>
				  <ngb-panel title="What is bid and offer?">
					<ng-template ngbPanelContent>
					  <p>A bid is a counter price of any product set by the buyer against the listed price of the seller under the bid & offer segment. This proprietary feature of our e-commerce platform Equibiz is to facilitate the trading of products under the bulk listing. If the seller agrees with the bid set by the buyer, the seller can close the deal at the price set by the buyer. Apart from this, the rest of the other deal conditions will remain the same as set by the seller at the time of product listing.</p>
					</ng-template>
				  </ngb-panel>
				  <ngb-panel title="How to start transacting over Equibiz?">
					<ng-template ngbPanelContent>
						<ul>
							<li>To start transacting over Equibiz and reap its benefits, sign up using your mobile number, email ID, or via social signup options. Login to your account and complete the business registration process by submitting business details and documents. Once the registration request is approved, you are good to start trading.</li>
							<li>Step 1: Log in to the portal www.equibiz.in</li>
							<li>Step 2: Click on Sign Up and input mobile number and password</li>
							<li>Step 3: Log in with your provided credentials</li>
							<li>Step 4: Input business details and documents</li>
							<li>Step 5: Start Trading</li>
						</ul>
					</ng-template>
				  </ngb-panel>
				  <ngb-panel title="What documents are required to register on Equibiz, and are there any registration charges?">
					<ng-template ngbPanelContent>
					  <p>A valid GST certificate (regular, not composite), business bank account, and PAN number are required to register on Equibiz. Registration over the Equibiz platform is free. However, transaction-related charges may apply to both buyers and sellers, which are displayed at the time of the transaction.</p>
					</ng-template>
				  </ngb-panel>
				  <ngb-panel title="Can I register my business if I don’t have GST number?">
					<ng-template ngbPanelContent>
					  <p>If you want to register as a seller, GST number is a must, however, in the case of a buyer, in place of GST, the buyer must provide other supporting trade licenses documents such as shop and establishment certificate, Udyog Aadhar, etc.</p>
					</ng-template>
				  </ngb-panel>
				  <ngb-panel title="What is Deal Booking Process:">
					<ng-template ngbPanelContent>
					  <ul>
						<li><b>I am a buyer; how do I book a deal over Equibiz?</b></li>
						<li>Step 1: Log in to your account www.equibiz.in/login</li>
						<li>Step 2: Identify deal segment; Bulk or Wholesale</li>
						<li>Step 3: Select a Product to buy</li>
						<li>Step 4: Pre-fund the wallet with the total deal amount if the selected product is listed in the bulk segment</li>
						<li>Step 5: Book the product after selecting payment and logistics options; Instant request is sent to the supplier for approval in case of the bulk segment and no such requirement in case of the wholesale segment.</li>
						<li>Step 6: For the Bulk segment, a deal is successfully booked once accepted by the seller, and a pre-booking amount will be debited from the wallet. In the case of the Wholesale segment, the deal will be booked by making instant payment using the wallet or other listed payment options.</li>
						<li>Step 7: Make the balance payment a day before the dispatch for completion of deal booking under the Bulk segment.</li>
						<li>Step 8: The product will be dispatched as per the deal terms.</li>
					  </ul>
					</ng-template>
				  </ngb-panel>
				  <ngb-panel title="Seller Listing Process:">
					<ng-template ngbPanelContent>
					  <ul>
						<li>I am a seller; how can I list my products over Equibiz?</li>
						<li>Sellers can list their products by selecting the deal listing option in the supplier’s dashboard. Sellers will find the products that are available for listing on the ‘Add Product’ screen, and after filling in the product/deal related pieces of information, they can list the product for trade.</li>
						<li>Sellers can also place a request for the products that are not available for a listing by using ‘Post your requirement’ form or by sending an email to support@equbiz.in, or they can call customer care for the addition of new products to the listing menu. After approval, sellers will be notified, and then they can list and trade.</li>
						<li>Listing your products is easy and you can follow the simple steps mentioned below to create a single listing.</li>
						<li>Step 1: Log in to your Seller Account and click on “Product Management"</li>
						<li>Step 2: Click on ‘Add New Product'</li>
						<li>Step-3: Search and select your product from the search results.</li>
						<li>Step-4: Select the ‘variant’ that you wish to list your product under.</li>
						<li>Step-5: In the case of the branded products, the seller may require to provide purchase invoice or Brand authorisation letter.</li>
						<li>Step-6: Enter product specifications, provide product images, set marketplace & pricing and your product is live.</li>
					  </ul>
					</ng-template>
				  </ngb-panel>
				  <ngb-panel title="I am a seller, what is the bulk and wholesale market segment?">
					<ng-template ngbPanelContent>
					  <p>The seller may choose to list the product under bulk or wholesale segment depending upon the business requirement and preferred target segment. It is suggested that listing deals in the wholesale segment must be targeted at retailers, dealers, small traders, etc. as the minimum cart value is kept at INR 3,000/ for buyers. Whereas, listing in the bulk segment/bid & offer must be targeted at the wholesaler, stockiest, distributors, retail chain stores, corporates, etc. which is linked to the  minimum order quantity set by the seller along with the fulfillment of minimum single listing value criteria of INR 3000/ or more.</p>
					</ng-template>
				  </ngb-panel>
				  <ngb-panel title="What are logistics options available at the time of deal booking?">
					<ng-template ngbPanelContent>
					  <p>Standard and Express delivery logistics options are available at the time of deal booking and buyers can choose any one logistics option as per their business requirement. The expected delivery time in the case of express delivery it is three to five working days and in the case of standard, it is five to seven working days.</p>
					</ng-template>
				  </ngb-panel>
				  <ngb-panel title="How do I reach Equibiz for any support?">
					<ng-template ngbPanelContent>
					  <p>Equibiz is happy to serve its partners 24*7. You can call on 18002102194 write to us at support@equibiz.in. To know more, get in touch with our executive and give wings to your business.</p>
					</ng-template>
				  </ngb-panel>
				  <ngb-panel title="How do I add money to my Equibiz wallet?">
					<ng-template ngbPanelContent>
						<p>Buyers and sellers can easily add money to their wallet by following these simple steps</p>
						<ul>
							<li><b>Buyer :</b></li>
							<li>Step 1: Log in to your Buyer account.</li>
							<li>Step 2: Click on the Wallet icon from the secondary header menu</li>
							<li>Step 3 : Click on “Add money”</li>
							<li>Step 3: Enter the amount you wish to add or select from a pre-defined set of values</li>
							<li>Step 4: Complete the transaction via Debit Card, Credit Card, UPI or other payment methods</li>
						</ul>

						<ul>
							<li><b>Seller :</b></li>
							<li>Step 1: Log in to your seller account.</li>
							<li>Step 2: Click on “Payments”</li>
							<li>Step 3 : Click on “Add money to wallet”</li>
							<li>Step 3: Enter the amount you wish to add or select from a pre-defined set of values</li>
							<li>Step 4: Complete the transaction via Debit Card, Credit Card, UPI or other payment methods</li>
						</ul>

					</ng-template>
				  </ngb-panel>
				  <ngb-panel title="What is Equibiz Wallet?">
					<ng-template ngbPanelContent>
					  <p>Equibiz Wallet is a digital solution that both buyers and sellers can use to make payments for purchases, charges, and related transactions. Buyers and sellers can transfer funds in the Equibiz wallet through net banking and the wallet cannot be used for making payments outside the Equibiz portal. The entire transaction is routed through the NODAL (type of Escrow) account as mandated by the law.</p>
					</ng-template>
				  </ngb-panel>
				</ngb-accordion>
			</div>
		</div>
	</div>
</section>

<section class="pt-0">
	<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d60296.70752014053!2d72.7832349!3d19.1714182!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b74342b4da69%3A0x6addbe7a70eb7b51!2svenwire!5e0!3m2!1sen!2sin!4v1688629749847!5m2!1sen!2sin" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</section>
<app-footer-one></app-footer-one>