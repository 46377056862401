import { Component, OnInit } from '@angular/core';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { SignupService } from 'src/app/shared/components/modal/signup-modal/signup-modal.service';
import { OrderHistoryService } from './order-history.service';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {

    public search_term:string = '';
    public from_date:any = '';
    public to_date:any = '';
    constructor(
        private _toastr: ToasterService,
        private _pagination: PaginationService,
        private _orderhistoryservice: OrderHistoryService,
        private _signupservice: SignupService
    ) { }

    public seller_type:string;
    public order_type:string = 'all';

    public seller_orders_count:number = 0;
    public seller_orders: any = [];

    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 10;
    public seller_order_pagination: any = {};
    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];

    //Sub seller orders
    public ss_search_term:string = '';
    public ss_from_date:any = '';
    public ss_to_date:any = '';

    public subseller_orders:any = [];
    public sub_current_page: number = 1;
    public sub_offset: number = 0;
    public sub_page_per_view: number = 10;
    public sub_seller_order_pagination: any = {};

    ngOnInit(): void {
        this.seller_type = this._signupservice.getsellertype();
        this._signupservice.sellerTypeChanged.subscribe((response: string) => {
            this.seller_type = this._signupservice.getsellertype();
        });
        this.getsellerorders('all');

        setTimeout( () => {
            this.getsubsellerorders();
        }, 500);
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.sellerorders();
    }

    setpageperview() {
        this.current_page = 1;
        this.sellerorders();
    }

    getsellerorders(type) {
        this.search_term = '';
        this.from_date = '';
        this.to_date = '';
        this.order_type = type;
        this.page_per_view = 10;
        this.offset = 0;
        this.current_page = 1;
        this.sellerorders();
    }

    getsellerordersfilters(){
        this.page_per_view = 10;
        this.offset = 0;
        this.current_page = 1;
        this.sellerorders();
    }

    sellerorders() {
        var type:any = 'all';
        if(this.order_type == 'booked'){
            type = 1
        }else if(this.order_type == 'approved'){
            type = 2
        }else if(this.order_type == 'rejected'){
            type = 3
        }else if(this.order_type == 'payment_done'){
            type = 4
        }else if(this.order_type == 'ready_to_dispatch'){
            type = 6
        }else if(this.order_type == 'quality_done'){
            type = 7
        }else if(this.order_type == 'dispatched'){
            type = 8
        }else if(this.order_type == 'delivered'){
            type = 9
        }

        let data = { 
            limit: this.page_per_view, 
            offset: this.offset, 
            current_page: this.current_page, 
            type: type,
            search: this.search_term,
            from_date: this.from_date,
            to_date: this.to_date
        };

        this._orderhistoryservice.sellerorders(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.seller_orders = data['records'];
                this.seller_orders_count = data['total_records'];
                let total_records = data['total_records'];
                this.seller_order_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }


    subsetpage(page) {
        this.sub_current_page = page;
        this.sub_offset = (page - 1) * this.sub_page_per_view;
        this.subsellerorders();
    }

    subsetpageperview() {
        this.sub_current_page = 1;
        this.subsellerorders();
    }

    getsubsellerorders() {
        this.sub_page_per_view = 10;
        this.sub_offset = 0;
        this.sub_current_page = 1;
        this.subsellerorders();
    }

    getsubsellerordersfilters(){
        this.sub_page_per_view = 10;
        this.sub_offset = 0;
        this.sub_current_page = 1;
        this.subsellerorders();
    }

    subsellerorders() {
        let data = { 
            limit: this.sub_page_per_view,
            offset: this.sub_offset,
            current_page: this.sub_current_page,
            search: this.ss_search_term,
            from_date: this.ss_from_date,
            to_date: this.ss_to_date
        }
        this._orderhistoryservice.subsellerorders(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.subseller_orders = data['records'];
                let total_records = data['total_records'];
                this.sub_seller_order_pagination = this._pagination.getPager(total_records, this.sub_current_page, this.sub_page_per_view);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

}
