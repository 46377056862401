<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">User Management</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" [routerLink]="['/admin/managecandf']">C & F</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" [routerLink]="['/admin/managecandf']">Manage C&F</a>
						</li>
						<li class="breadcrumb-item active">View Details</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				&nbsp;
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="row d-flex align-items-center mb-20">
			<div class="col-md-6">
				<h3 class="f-700 fs22 txtdefault pb-0"><a href="javascript:;" class="go-back"><i class="fa fa-angle-left"></i></a> View Details</h3>
			</div>
			<div class="col-md-6">
				<div class="text-right">
					<a href="javascript:;" (click)="open(content)" class="btn buybtn-danger fs16 radius10 ripple">
						<div class="d-flex align-items-center">
							<div class="mr-2"><img src="assets/images/icons/delete-icon.svg"></div>
							<div>Remove as C&F</div>
						</div>
					</a>
				</div>
			</div>
		</div>

		<div class="row mb-10">
			<div class="col-md-8 pb-2">
				<div class="row">
					<div class="col-sm-2 col-3 seller-thumb">
						<img src="assets/images/svg/vendor.svg" />
					</div>
					<div class="col-sm-10 col-9 pl-0 seller-info">
						<h4 class="f-600 fs22">Digixpress Trade Private Limited</h4>
						<div class="fs12 txtseconday mb-1">Seller</div>
						<span class="pb-sm-2 pl-3 seller-loc fs12">
						<svg xmlns="http://www.w3.org/2000/svg" width="9.375" height="11.752" viewBox="0 0 9.375 11.752">
	  					<path id="Path_2306" data-name="Path 2306" d="M25.689,5.029a4.825,4.825,0,0,0-4.154,5.16c.285,3.272,3.293,5.711,4.324,6.457a.562.562,0,0,0,.677-.012c1.071-.836,4.357-3.67,4.357-6.942a4.691,4.691,0,0,0-5.2-4.663Zm.514,6.524a1.861,1.861,0,1,1,1.861-1.861A1.861,1.861,0,0,1,26.2,11.553Z" transform="translate(-21.517 -5.002)" fill="#5e6a7c"/>
						</svg>Mumbai, Maharashtra, India</span>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				&nbsp;
			</div>
		</div>

		<div class="card cardui carduipro p-4 mb-20">
			<h5 class="fs22 f-700 mb-0">Personal Details</h5>
			<hr>
			<div class="row">
				<div class="col-md-3">
					<div class="text-muted">Email ID:</div>
					<div class="text-dark f-600">abhaydas@gmail.com</div>
				</div>
				<div class="col-md-3">
					<div class="text-muted">Mobile Number:</div>
					<div class="text-dark f-600">+91 9876504321</div>
				</div>
			</div>
		</div>

		<div class="card cardui carduipro p-4 mb-40">
			<div class="d-flex align-items-center justify-content-between">
				<h5 class="fs22 f-700 mb-0">Business Details</h5>
				<div>
					<a href="javascript:;" class="btn-d-outline d-inline-block place-bid">Terms and Conditions for Bilateral Deal</a>
				</div>
			</div>
			<hr>
			<!--GST Details-->
			<h5 class="fs18 f-700 mt-20 mb-20">GST Details</h5>
			<div class="row mb-20">
				<div class="col-md-3">
					<div class="text-muted">GSTIN Number:</div>
					<div class="text-dark f-600">987BMDPT65043</div>
				</div>
				<div class="col-md-3">
					<div class="text-muted">Mobile Number:</div>
					<div class="text-dark f-600">
						<a href="javascript:;" class="link-alt fs14">
							<svg xmlns="http://www.w3.org/2000/svg" width="5.899" height="11.799" viewBox="0 0 5.899 11.799">
							  <path id="Icon_material-attach-file" data-name="Icon material-attach-file" d="M15.595,4.182v6.168a2.145,2.145,0,1,1-4.29,0v-6.7a1.341,1.341,0,0,1,2.682,0V9.277a.536.536,0,0,1-1.073,0V4.182h-.8V9.277a1.341,1.341,0,0,0,2.682,0V3.645a2.145,2.145,0,0,0-4.29,0v6.7a2.95,2.95,0,0,0,5.9,0V4.182Z" transform="translate(-10.5 -1.5)" fill="#ff7135"/>
							</svg> GST Document.pdf
						</a>
					</div>
				</div>
				<div class="col-md-3">
					<div class="text-muted">GST Filing Frequency:</div>
					<div class="text-dark f-600">Quaterly</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-3">
					<div class="text-muted">Last GST Payment Date:</div>
					<div class="text-dark f-600">987BMDPT65043</div>
				</div>
				<div class="col-md-3">
					<div class="text-muted">Last Filled GST Document:</div>
					<div class="text-dark f-600">
						<a href="javascript:;" class="link-alt fs14">
							<svg xmlns="http://www.w3.org/2000/svg" width="5.899" height="11.799" viewBox="0 0 5.899 11.799">
							  <path id="Icon_material-attach-file" data-name="Icon material-attach-file" d="M15.595,4.182v6.168a2.145,2.145,0,1,1-4.29,0v-6.7a1.341,1.341,0,0,1,2.682,0V9.277a.536.536,0,0,1-1.073,0V4.182h-.8V9.277a1.341,1.341,0,0,0,2.682,0V3.645a2.145,2.145,0,0,0-4.29,0v6.7a2.95,2.95,0,0,0,5.9,0V4.182Z" transform="translate(-10.5 -1.5)" fill="#ff7135"/>
							</svg> GST Document.pdf
						</a>
					</div>
				</div>
				<div class="col-md-3">
					<div class="text-muted">GST Filing Frequency:</div>
					<div class="text-dark f-600">Quaterly</div>
				</div>
			</div>

			<!--Identity Documents-->
			<h5 class="fs18 f-700 mt-20 mb-20">Identity Documents</h5>

			<div class="row mb-20">
				<div class="col-md-3">
					<div class="text-muted">PAN Number:</div>
					<div class="text-dark f-600">987BMDPT65043</div>
				</div>
				<div class="col-md-3">
					<div class="text-muted">PAN Card Document:</div>
					<div class="text-dark f-600">
						<a href="javascript:;" class="link-alt fs14">
							<svg xmlns="http://www.w3.org/2000/svg" width="5.899" height="11.799" viewBox="0 0 5.899 11.799">
							  <path id="Icon_material-attach-file" data-name="Icon material-attach-file" d="M15.595,4.182v6.168a2.145,2.145,0,1,1-4.29,0v-6.7a1.341,1.341,0,0,1,2.682,0V9.277a.536.536,0,0,1-1.073,0V4.182h-.8V9.277a1.341,1.341,0,0,0,2.682,0V3.645a2.145,2.145,0,0,0-4.29,0v6.7a2.95,2.95,0,0,0,5.9,0V4.182Z" transform="translate(-10.5 -1.5)" fill="#ff7135"/>
							</svg> PAN_Card.pdf
						</a>
					</div>
				</div>
				<div class="col-md-3">
					<div class="text-muted">Aadhaar Number:</div>
					<div class="text-dark f-600">987650432738</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-3">
					<div class="text-muted">Aadhaar Card Document:</div>
					<div class="text-dark f-600">
						<a href="javascript:;" class="link-alt fs14">
							<svg xmlns="http://www.w3.org/2000/svg" width="5.899" height="11.799" viewBox="0 0 5.899 11.799">
							  <path id="Icon_material-attach-file" data-name="Icon material-attach-file" d="M15.595,4.182v6.168a2.145,2.145,0,1,1-4.29,0v-6.7a1.341,1.341,0,0,1,2.682,0V9.277a.536.536,0,0,1-1.073,0V4.182h-.8V9.277a1.341,1.341,0,0,0,2.682,0V3.645a2.145,2.145,0,0,0-4.29,0v6.7a2.95,2.95,0,0,0,5.9,0V4.182Z" transform="translate(-10.5 -1.5)" fill="#ff7135"/>
							</svg> Aadhar_Card.pdf
						</a>
					</div>
				</div>
				<div class="col-md-3">
					<div class="text-muted">IEC Number:</div>
					<div class="text-dark f-600">9876504321</div>
				</div>
				<div class="col-md-3">
					<div class="text-muted">IEC Document:</div>
					<div class="text-dark f-600">
						<a href="javascript:;" class="link-alt fs14">
							<svg xmlns="http://www.w3.org/2000/svg" width="5.899" height="11.799" viewBox="0 0 5.899 11.799">
							  <path id="Icon_material-attach-file" data-name="Icon material-attach-file" d="M15.595,4.182v6.168a2.145,2.145,0,1,1-4.29,0v-6.7a1.341,1.341,0,0,1,2.682,0V9.277a.536.536,0,0,1-1.073,0V4.182h-.8V9.277a1.341,1.341,0,0,0,2.682,0V3.645a2.145,2.145,0,0,0-4.29,0v6.7a2.95,2.95,0,0,0,5.9,0V4.182Z" transform="translate(-10.5 -1.5)" fill="#ff7135"/>
							</svg> GST Document.pdf
						</a>
					</div>
				</div>
			</div>

			<!--Address-->
			<h5 class="fs18 f-700 mt-20 mb-20">Address</h5>

			<div class="row mb-20">
				<div class="col-md-8">
					<div class="text-muted">Registered Office Address:</div>
					<div class="text-dark f-600">MS Ali Road, Grant Road East, Bharat Nagar, Girgaon, Mumbai, Maharashtra 400007</div>
				</div>
			</div>

			<div class="row mb-20">
				<div class="col-md-8">
					<div class="text-muted">Shipping Address - 1:</div>
					<div class="text-dark f-600">
						MS Ali Road, Grant Road East, Bharat Nagar, Girgaon, Mumbai, Maharashtra 400007
						<a href="javascript:void(0);" class="default-tag ml-3">Default</a>
					</div>
				</div>
			</div>

			<div class="row mb-20">
				<div class="col-md-8">
					<div class="text-muted">Shipping Address - 2:</div>
					<div class="text-dark f-600">MS Ali Road, Grant Road East, Bharat Nagar, Girgaon, Mumbai, Maharashtra 400007</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Remove as C&F -->
<ng-template #content let-modal>
   	<div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
      	<h5 class="modal-title text-center pb-20">Remove as C&F</h5>

      	<div class="bid-order-info">
			<div class="order-img">
				<img src="assets/images/icons/info.png" />
			</div>
			<div class="pd-seller-cntnt">
				<div class="fs12">By removing the C&F, all the sub-sellers falling under this C&F will be paying the TDS/TCS for all upcoming orders.</div>
			</div>
		</div>

        <div class="modal-subtitle text-center pt-30 pb-30">Are you sure you want to remove the ABC Technologies Private Ltd C&F?</div>

		<div class="row d-flex align-items-center justify-content-center mb-30">
			<div class="col-sm-2 col-3 seller-thumb">
				<img src="assets/images/svg/vendor.svg" />
			</div>
			<div class="col-sm-10 col-9 pl-0 seller-info">
				<h4 class="f-600 fs18 text-left">Digixpress Trade Private Limited</h4>
			</div>
		</div>
		<!-- <div class="fs16">Currently transiting: <b>238 orders</b></div> -->

        <div class="text-center pt-30 pb-20">
           <button type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')" class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4">
		      Cancel
		   	</button>
		   	<button type="button" class="btn btn-mod btn-danger btn-medium btn-round ripple px-4">
		      Remove as C&F
		   	</button>
        </div>
   	</div>
</ng-template>
<!-- Remove as C&F -->