import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class HsnandGstManagementService {

  constructor(private _http: HttpClient) { }

    uploadexcel(data) {
        return this._http.post(environment.api_url+'master/hsn/import',data);
    }

    gethsndetails(data) {
        return this._http.post(environment.api_url+'/master/hsn/listing',data);
    }

    savegstrate(data) {
        return this._http.post(environment.api_url+'master/hsn/update',data);
    }

    addnewhsngst(data) {
      return this._http.post(environment.api_url+'master/hsn/add',data);
  }

}
