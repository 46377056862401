<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain mgwrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head mgb-sm-ui-rw">
			<div class="col-md-6 ">
				<h3 class="fs28 txtdefault wt700">HSN + GST Management</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);" [routerLink]="['/admin/product-listing']">Product Management</a>
						</li>
						<li class="breadcrumb-item active">HSN + GST Management</li>
					</ol>
				</nav>
			</div>
			<div class="col-md-6 text-right sm-ui-rw">
				<a href="javascript:;" (click)="opennew(addnewhsngst)" class="mr-sm-4 btn-d-outline d-inline-block">
					<svg xmlns="http://www.w3.org/2000/svg" width="14.681" height="14.681" viewBox="0 0 14.681 14.681" class="plus">
					  <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1 1)">
					    <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181" transform="translate(-11.659 -7.5)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					    <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181" transform="translate(-7.5 -11.659)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					  </g>
					</svg>
					Add New HSN & GST
				</a>
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ml-3">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<!--Filters-->
		<div class="row d-flex justify-content-between mt-20 report-cta mb-20">
			<div class="col-md-5">
				<div class="report-input">
					<div class="input-group-custom">
						<input type="text" placeholder="Search by a HSN Code or Keyword" class="form-control" [(ngModel)]="search_key" (keyup)="hsnsearch()">
						<div class="input-group-append-custom">
							<img src="assets/images/svg/search_logo.svg">
						</div>
					</div>
				</div>
			</div>

			<div class="col-md-7">
				<div class="text-right">
					<a href="javascript:void(0);" class="ripple d-inline-block place-bid mr-3">
					<div class="d-flex align-items-center">
						<div class="mr-2"><img src="assets/images/icons/csv.png"></div>
						<div>Download Sample</div>
					</div>
				</a>
				<input type="file" #hsnfile (change)="uploadhsnfile($event)" style="display: none;">
				<a href="javascript:void(0);" (click)="hsnfile.click()" class="btn-d-outline ripple d-inline-block place-bid">
					<div class="d-flex align-items-center">
						<div class="mr-2"><img src="assets/images/icons/csv.png"></div>
						<div>Upload Excel Sheet</div>
					</div>
				</a>
				</div>
			</div>
		</div>
		<!--End Filters-->

		<div class="card card-ui card-uiovt">
			<!-- <p>Chapter</p> -->
			<div class="gstcodepanel doc-od">
				<table class="table custom-table xs">
					<thead>
						<tr>
							<th width="65%">Description</th>
							<th>HSN Code</th>
							<th>Suggested GST Rate</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let record of hsn_records;">	
							<td>{{ record.description }}</td>
							<td>{{ record.code }}</td>
							<td>{{ record.gst }}% 
								<a href="javascript:;" (click)="open(content, record)" class="ml-2">
									<img src="assets/images/icons/edit-big.png">
								</a>
							</td>
						</tr>
						<!--Empty State-->
						<tr *ngIf="!hsn_records.length">
							<td colspan="7">
								<div class="row pt-40 pb-40">
									<div class="col-md-12 text-center">
										<div class="mb-20"><img src="/assets/images/empty-state.png"></div>
										<div class="fs16">There are no records to display!</div>
									</div>
								</div>
							</td>
						</tr>
						<!--End Empty State-->
					</tbody>
				</table>
				<!-- <ngb-accordion [closeOthers]="true" #acc="ngbAccordion">
					<ngb-panel>
						<ng-template ngbPanelHeader>
							<button ngbPanelToggle class="btn btn-link">
							  	<div class="d-flex align-items-center justify-content-between">
									<div>Nuclear reactors, boilers, machinery.</div>
									<div class="link-alt fs14 mr-80">Show 7 HSN Codes</div>
							  	</div>
						  	</button>
						</ng-template>
						<ng-template ngbPanelContent>
							<div class="table-responsive">
								<table class="table custom-table xs">
									<thead>
										<tr>
											<th width="65%">Description</th>
											<th>HSN Code</th>
											<th>Suggested GST Rate</th>
										</tr>
									</thead>
									<tbody>
										<tr>	
											<td>Ships Derricks; Cranes Including Cable Cranes; Mobile Lifting Frames, Straddle Carriers And Works Trucks Fitted With A Crane - Overhead Travelling Cranes, Transporter Cranes.</td>
											<td>123456</td>
											<td>18% <a href="javascript:;" (click)="open(content)" class="ml-2"><img src="assets/images/icons/edit-big.png"></a></td>
										</tr>
										<tr>	
											<td>Ships Derricks; Cranes Including Cable Cranes; Mobile Lifting Frames, Straddle Carriers And Works Trucks Fitted With A Crane - Overhead Travelling Cranes, Transporter Cranes.</td>
											<td>123456</td>
											<td>18% <a href="javascript:;" (click)="open(content)" class="ml-2"><img src="assets/images/icons/edit-big.png"></a></td>
										</tr>
										<tr>	
											<td>Ships Derricks; Cranes Including Cable Cranes; Mobile Lifting Frames, Straddle Carriers And Works Trucks Fitted With A Crane - Overhead Travelling Cranes, Transporter Cranes.</td>
											<td>123456</td>
											<td>18% <a href="javascript:;" (click)="open(content)" class="ml-2"><img src="assets/images/icons/edit-big.png"></a></td>
										</tr>
										<tr>	
											<td>Ships Derricks; Cranes Including Cable Cranes; Mobile Lifting Frames, Straddle Carriers And Works Trucks Fitted With A Crane - Overhead Travelling Cranes, Transporter Cranes.</td>
											<td>123456</td>
											<td>18% <a href="javascript:;" (click)="open(content)" class="ml-2"><img src="assets/images/icons/edit-big.png"></a></td>
										</tr>
										<tr>	
											<td>Mobile Phones</td>
											<td>123456</td>
											<td>18% <a href="javascript:;" (click)="open(content)" class="ml-2"><img src="assets/images/icons/edit-big.png"></a></td>
										</tr>
										<tr>	
											<td>Ships Derricks; Cranes Including Cable Cranes; Mobile Lifting Frames, Straddle Carriers And Works Trucks Fitted With A Crane - Overhead Travelling Cranes, Transporter Cranes.</td>
											<td>123456</td>
											<td>18% <a href="javascript:;" (click)="open(content)" class="ml-2"><img src="assets/images/icons/edit-big.png"></a></td>
										</tr>
									</tbody>
								</table>
							</div>
						</ng-template>
					</ngb-panel>
					<ngb-panel>
						<ng-template ngbPanelHeader>
							<button ngbPanelToggle class="btn btn-link">
							  	<div class="d-flex align-items-center justify-content-between">
									<div>Nuclear reactors, boilers, machinery.</div>
									<div class="link-alt fs14 mr-80">Show 7 HSN Codes</div>
							  	</div>
						  	</button>
						</ng-template>
						<ng-template ngbPanelContent>
							<div class="table-responsive">
								<table class="table custom-table xs">
									<thead>
										<tr>
											<th width="65%">Description</th>
											<th>HSN Code</th>
											<th>Suggested GST Rate</th>
										</tr>
									</thead>
									<tbody>
										<tr>	
											<td>Ships Derricks; Cranes Including Cable Cranes; Mobile Lifting Frames, Straddle Carriers And Works Trucks Fitted With A Crane - Overhead Travelling Cranes, Transporter Cranes.</td>
											<td>123456</td>
											<td>18% <a href="javascript:;" (click)="open(content)" class="ml-2"><img src="assets/images/icons/edit-big.png"></a></td>
										</tr>
										<tr>	
											<td>Ships Derricks; Cranes Including Cable Cranes; Mobile Lifting Frames, Straddle Carriers And Works Trucks Fitted With A Crane - Overhead Travelling Cranes, Transporter Cranes.</td>
											<td>123456</td>
											<td>18% <a href="javascript:;" (click)="open(content)" class="ml-2"><img src="assets/images/icons/edit-big.png"></a></td>
										</tr>
										<tr>	
											<td>Ships Derricks; Cranes Including Cable Cranes; Mobile Lifting Frames, Straddle Carriers And Works Trucks Fitted With A Crane - Overhead Travelling Cranes, Transporter Cranes.</td>
											<td>123456</td>
											<td>18% <a href="javascript:;" (click)="open(content)" class="ml-2"><img src="assets/images/icons/edit-big.png"></a></td>
										</tr>
										<tr>	
											<td>Ships Derricks; Cranes Including Cable Cranes; Mobile Lifting Frames, Straddle Carriers And Works Trucks Fitted With A Crane - Overhead Travelling Cranes, Transporter Cranes.</td>
											<td>123456</td>
											<td>18% <a href="javascript:;" (click)="open(content)" class="ml-2"><img src="assets/images/icons/edit-big.png"></a></td>
										</tr>
										<tr>	
											<td>Mobile Phones</td>
											<td>123456</td>
											<td>18% <a href="javascript:;" (click)="open(content)" class="ml-2"><img src="assets/images/icons/edit-big.png"></a></td>
										</tr>
										<tr>	
											<td>Ships Derricks; Cranes Including Cable Cranes; Mobile Lifting Frames, Straddle Carriers And Works Trucks Fitted With A Crane - Overhead Travelling Cranes, Transporter Cranes.</td>
											<td>123456</td>
											<td>18% <a href="javascript:;" (click)="open(content)" class="ml-2"><img src="assets/images/icons/edit-big.png"></a></td>
										</tr>
									</tbody>
								</table>
							</div>
						</ng-template>
					</ngb-panel>
					<ngb-panel>
						<ng-template ngbPanelHeader>
							<button ngbPanelToggle class="btn btn-link">
							  	<div class="d-flex align-items-center justify-content-between">
									<div>Nuclear reactors, boilers, machinery.</div>
									<div class="link-alt fs14 mr-80">Show 7 HSN Codes</div>
							  	</div>
						  	</button>
						</ng-template>
						<ng-template ngbPanelContent>
							<div class="table-responsive">
								<table class="table custom-table xs">
									<thead>
										<tr>
											<th width="65%">Description</th>
											<th>HSN Code</th>
											<th>Suggested GST Rate</th>
										</tr>
									</thead>
									<tbody>
										<tr>	
											<td>Ships Derricks; Cranes Including Cable Cranes; Mobile Lifting Frames, Straddle Carriers And Works Trucks Fitted With A Crane - Overhead Travelling Cranes, Transporter Cranes.</td>
											<td>123456</td>
											<td>18% <a href="javascript:;" (click)="open(content)" class="ml-2"><img src="assets/images/icons/edit-big.png"></a></td>
										</tr>
										<tr>	
											<td>Ships Derricks; Cranes Including Cable Cranes; Mobile Lifting Frames, Straddle Carriers And Works Trucks Fitted With A Crane - Overhead Travelling Cranes, Transporter Cranes.</td>
											<td>123456</td>
											<td>18% <a href="javascript:;" (click)="open(content)" class="ml-2"><img src="assets/images/icons/edit-big.png"></a></td>
										</tr>
										<tr>	
											<td>Ships Derricks; Cranes Including Cable Cranes; Mobile Lifting Frames, Straddle Carriers And Works Trucks Fitted With A Crane - Overhead Travelling Cranes, Transporter Cranes.</td>
											<td>123456</td>
											<td>18% <a href="javascript:;" (click)="open(content)" class="ml-2"><img src="assets/images/icons/edit-big.png"></a></td>
										</tr>
										<tr>	
											<td>Ships Derricks; Cranes Including Cable Cranes; Mobile Lifting Frames, Straddle Carriers And Works Trucks Fitted With A Crane - Overhead Travelling Cranes, Transporter Cranes.</td>
											<td>123456</td>
											<td>18% <a href="javascript:;" (click)="open(content)" class="ml-2"><img src="assets/images/icons/edit-big.png"></a></td>
										</tr>
										<tr>	
											<td>Mobile Phones</td>
											<td>123456</td>
											<td>18% <a href="javascript:;" (click)="open(content)" class="ml-2"><img src="assets/images/icons/edit-big.png"></a></td>
										</tr>
										<tr>	
											<td>Ships Derricks; Cranes Including Cable Cranes; Mobile Lifting Frames, Straddle Carriers And Works Trucks Fitted With A Crane - Overhead Travelling Cranes, Transporter Cranes.</td>
											<td>123456</td>
											<td>18% <a href="javascript:;" (click)="open(content)" class="ml-2"><img src="assets/images/icons/edit-big.png"></a></td>
										</tr>
									</tbody>
								</table>
							</div>
						</ng-template>
					</ngb-panel>
				</ngb-accordion> -->
			</div>
		</div>
		<div class="row mb-40" *ngIf="hsn_pagination.totalPages > 1">
			<div class="col-md-6">
				<div class="text-left pl-3">
					<nav class="mt-3 tbpg rows-per-page">
						<div>Rows per page:</div>
						<select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
							<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">{{option.name}}</option>
						</select>
					</nav>
				</div> 
			</div>
			<div class="col-md-6" *ngIf="hsn_pagination.pages && hsn_pagination.pages.length">
				<div class="text-right pr-3">
					<nav class="mt-3 tbpg">
					  <ul class="pagination align-items-center">
						<li class="page-item" [ngClass]="{disabled:hsn_pagination.currentPage === 1}"><a class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a></li>

						<li class="page-item" [ngClass]="{disabled:hsn_pagination.currentPage === 1}"><a class="page-link sprevicon" href="javascript:;" (click)="setpage(hsn_pagination.currentPage - 1)"></a></li>

						<li class="page-item"i *ngFor="let page of hsn_pagination.pages" [ngClass]="{active:hsn_pagination.currentPage === page}"><a class="page-link" href="javascript:;" (click)="setpage(page)">{{page}}</a></li>

						<li *ngIf="(hsn_pagination.totalPages - hsn_pagination.startPage) > 5 ">
							<a (click)="setpage(hsn_pagination.startPage + 5)">...</a>
						</li>
						 <li *ngIf="(hsn_pagination.totalPages - hsn_pagination.startPage) > 5" >
							<a (click)="setpage(hsn_pagination.totalPages)">{{hsn_pagination.totalPages}}</a>
						</li>
						
						<li class="page-item" [ngClass]="{disabled:hsn_pagination.currentPage === hsn_pagination.totalPages}"><a class="page-link snexticon" href="javascript:;"  (click)="setpage(hsn_pagination.currentPage + 1)"></a></li>
						<li class="page-item" [ngClass]="{disabled:hsn_pagination.currentPage === hsn_pagination.totalPages}"><a class="page-link nexticon" href="javascript:;" (click)="setpage(hsn_pagination.totalPages)"></a></li>
					  </ul>
					</nav>
				</div> 
			</div>
		</div>
	</div>
</div>	

<!-- Edit GST Rate -->
<ng-template #content let-modal>
	<div class="modal-body text-center mdlcmnui pt-4 mt-3">
        <h5 class="modal-title mb-20">Edit GST Rate</h5>
        <div class="bid-order-info">
			<div class="order-img">
				<img src="assets/images/icons/info.png" />
			</div>
			<div class="pd-seller-cntnt">
				<div class="fs12 text-left">If you find any variation in the GST rate, you can suggest GST rates for correction.</div>
			</div>
		</div>

        <div class="form-container mt-30 mb-10">
			<div class="floating-label">
				<div class="input-group-custom">
					<input type="text" value="" class="form-control" [(ngModel)]="selected_record.gst" placeholder=""/>
					<span class="highlight"></span>
					<label>GST Rate (in %)</label>
				</div>
			</div>
		</div>

        <div class="text-center pt-30 pb-20">
           <button type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')" class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4">
		      Cancel
		   	</button>
		   	<button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4" (click)="savegstrate()">
		      Save Changes
		   	</button>
        </div>
      </div>
</ng-template>

<!-- Add New HSN & GST -->
<ng-template #addnewhsngst let-modal>
	<div class="modal-body text-center mdlcmnui pt-4 mt-3">
        <h5 class="modal-title mb-20">Add New HSN & GST</h5>
        <form  [formGroup]="addnewhsn">
			<div class="form-container mt-30 mb-10">
				<div class="floating-label">
					<div class="input-group-custom">
						<textarea name="description" class="form-control" id="description" cols="30" rows="3" formControlName="description"></textarea>
						<span class="highlight"></span>
						<label>Description</label>
						<span class="help-block" *ngIf="an.description.invalid && an.description.touched">This field is required</span>
					</div>
				</div>
			</div>

			<div class="form-container mt-30 mb-10">
				<div class="floating-label">
					<div class="input-group-custom">
						<input type="text" value="" class="form-control" name="hsn_code" formControlName="hsn_code" placeholder=""/>
						<span class="highlight"></span>
						<label>HSN Code</label>
						<span class="help-block" *ngIf="an.hsn_code.invalid && an.hsn_code.touched">Please enter valid HSN code</span>
					</div>
				</div>
			</div>

			<div class="form-container mt-30 mb-10">
				<div class="floating-label">
					<div class="input-group-custom">
						<input type="text" value="" class="form-control" name="product" formControlName="product" placeholder=""/>
						<span class="highlight"></span>
						<label>Product</label>
					</div>
				</div>
			</div>

			<div class="form-container mt-30 mb-10">
				<div class="floating-label">
					<div class="input-group-custom">
						<input type="text" value="" class="form-control" name="gst" formControlName="gst" placeholder=""/>
						<span class="highlight"></span>
						<label>GST Rate (in %)</label>
						<span class="help-block" *ngIf="an.gst.invalid && an.gst.touched">Please enter valid GST rate</span>
					</div>
				</div>
			</div>

			<div class="text-center pt-30 pb-20">
			<button type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')" class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4">
				Cancel
				</button>
				<button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4" (click)="addgstrate()" [disabled]="addHsnLoading || addnewhsn.invalid">
					<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="addHsnLoading"></span>
					&nbsp;Save Changes
				</button>
			</div>
		</form>
      </div>
</ng-template>
