<app-header-buyer></app-header-buyer>
<app-sidebar-buyer></app-sidebar-buyer>
<div class="wrpmain-buyer">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<h3 class="fs24 wt700 txtdefault">Manage Categories</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);" [routerLink]="['/']">Home</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);" [routerLink]="['/buyer/dashboard']">EB Dashboard</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">My Categories</a>
						</li>
						<li class="breadcrumb-item active">Manage Categories</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:void(0);" [routerLink]="['/buyer/assistance']" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4 mb-40">
			<div class="notifications-container theme-tab">
				<ngb-tabset [justify]="'left'" [type]="'pills'" class="tabs tab-title">
		            <ngb-tab>
		                <ng-template ngbTabTitle>All Categories ({{ records.length }})</ng-template>
		                <ng-template ngbTabContent>
		                	<!-- <h4 class="fs15 f-700 mb-10">Choose your preferences</h4> -->

		                	<div class="row d-flex align-items-center mb-20">
		                		<!-- <div class="col-md-4">
		                			<div class="report-input">
										<div class="input-group-custom">
											<input type="text" placeholder="Search by Category Name" class="form-control">
											<div class="input-group-append-custom">
												<img src="assets/images/svg/search_logo.svg">
											</div>
										</div>
									</div>
		                		</div> -->

		                		<div class="col-md-12">
		                			<div class="d-flex align-items-center justify-content-end">
			                			<a href="javascript:;" class="mr-3" (click)="resetcategory()"><svg xmlns="http://www.w3.org/2000/svg" width="12.687" height="12.687" viewBox="0 0 12.687 12.687">
										  <path id="Icon_awesome-undo-alt" data-name="Icon awesome-undo-alt" d="M6.645.562a6.073,6.073,0,0,0-4.2,1.688l-.878-.878A.59.59,0,0,0,.563,1.79V5.083a.59.59,0,0,0,.59.59H4.446a.59.59,0,0,0,.417-1.007L3.837,3.641a4.128,4.128,0,1,1,.1,6.122.294.294,0,0,0-.4.014l-.975.975a.3.3,0,0,0,.012.428A6.094,6.094,0,1,0,6.645.562Z" transform="translate(-0.313 -0.312)" fill="#ff7135" stroke="#fff" stroke-width="0.5"/>
										</svg> Reset</a>
			                			<a href="javascript:;" class="btn-d-outline ripple d-inline-block" (click)="savecategories()">Save Preferences</a>
		                			</div>
		                		</div>
		                	</div>

		                	<div class="css-check">
								<input class="css-checkbx" type="checkbox" name="select_all" [(ngModel)]="select_all" (change)="changeall()" />
								<label class="css-label">Select All</label>
							</div>


							<div class="list-categories mt-30">
								<div class="row">
									<div class="col-md-4" *ngFor="let rec of records">
										<div class="item">
											<div class="css-check">
												<input class="css-checkbx" type="checkbox" [(ngModel)]="rec.selected" (change)="updatecheckbox()"/>
												<label class="css-label"><img src="{{ rec.image }}" class="mr-2">{{ rec.name }}</label>
											</div>
										</div>
									</div>
								</div>
							</div>
		                </ng-template>
		            </ngb-tab>
		        </ngb-tabset>
		    </div>
		</div>
	</div>
</div>
