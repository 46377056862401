<app-header-seller></app-header-seller>
<app-sidebar-seller></app-sidebar-seller>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<h3 class="fs24 wt700 txtdefault">Pending Orders for Acceptance</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Orders</a>
						</li>
						<li class="breadcrumb-item active">Pending Orders for Acceptance</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<div class="d-flex align-items-center justify-content-end">
					<!-- <i placement="top" ngbTooltip="This will act as a default filter every time you will toggle.">
						<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
						  <g id="Group_68" data-name="Group 68" transform="translate(-545 -281)">
							<circle id="Ellipse_1" data-name="Ellipse 1" cx="7.5" cy="7.5" r="7.5" transform="translate(545 281)" fill="#07f"/>
							<path id="Path_2235" data-name="Path 2235" d="M7,8.171l-.128.523q-.576.227-.919.346a2.425,2.425,0,0,1-.8.119,1.591,1.591,0,0,1-1.084-.34,1.1,1.1,0,0,1-.387-.864,3.056,3.056,0,0,1,.029-.416q.029-.213.093-.48l.48-1.7q.064-.244.108-.462a2.01,2.01,0,0,0,.044-.4A.616.616,0,0,0,4.3,4.047a.759.759,0,0,0-.513-.128,1.345,1.345,0,0,0-.381.058c-.131.039-.242.076-.336.11L3.2,3.564q.471-.192.9-.329A2.7,2.7,0,0,1,4.916,3.1a1.552,1.552,0,0,1,1.068.335,1.111,1.111,0,0,1,.375.869c0,.074-.008.2-.026.39a2.606,2.606,0,0,1-.1.512L5.759,6.9a4.492,4.492,0,0,0-.1.466,2.4,2.4,0,0,0-.047.4.57.57,0,0,0,.15.459.85.85,0,0,0,.523.122,1.509,1.509,0,0,0,.4-.061A2.21,2.21,0,0,0,7,8.171Zm.121-7.1a.99.99,0,0,1-.334.753,1.138,1.138,0,0,1-.8.312,1.15,1.15,0,0,1-.809-.312.988.988,0,0,1-.337-.753A1,1,0,0,1,5.169.314a1.194,1.194,0,0,1,1.614,0A1,1,0,0,1,7.117,1.07Z" transform="translate(547.406 283.92)" fill="#fff"/>
						  </g>
						</svg>
					</i>&nbsp; Auto Accept Orders &nbsp;&nbsp;
					<div class="tglbtn">
						<label class="switch">
						  <input type="checkbox">
						  <span class="slider round"></span>
						</label>
					</div> -->
					<a href="javascript:void(0);" [routerLink]="['/seller/assistance']" class="btn buybtn fs16 radius10 ml-3">Assistance</a>
				</div>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card card-ui card-uiovt byregwrp table-card">
			<div class="mgb-head">
				<div class="row mb-4 pb-2 align-items-center mb-xs-10">
					<div class="col-md-7">
						<div class="srch-ipt srch50">
							<img src="assets/images/svg/search_logo.svg">
							<input placeholder="Search by Order ID, Transaction ID & Product Name" type="text" class="form-control" [(ngModel)]="search_term" (change)="searchpendingorders()">
						</div>
					</div>	
					<div class="col-md-5">
						<div class="tpbr">
							<label class="fs12 txtseconday tpbrlbl">Sort By</label>
							<ng-selectize [config]="config" [options] = "sortingdata" [(ngModel)]="sorting_by" (ngModelChange)="searchpendingorders()">
							</ng-selectize>	
						</div>
					</div>	
				</div>
				<!-- <a href="javascript:void(0);" class="d-inline-block fltr-round-btn radius10 txtseconday">
					<img src="assets/images/svg/filter.svg">
					Filters
				</a> -->
			</div>
			<div class="table-responsive">
				<table class="table custom-table">
				  <thead>
					  <tr>
					  	<!-- <th width="2%">
					  		<div class="custom-check">
								<div class="css-check">
									<input class="css-checkbx" type="checkbox" />
									<label class="css-label">
										&nbsp;
									</label>
								</div>	
							</div>
					  	</th> -->
						<th>Product Name</th>
						<th>Order ID</th>
						<th>Transaction ID</th>
						<th>Deal Date</th>
						<th>Deal Amt</th>
						<th>Order Qty</th>
						<th>Time Left</th>
						<th>Actions</th>
					  </tr>
				  </thead>
				  <tbody>
					<!--Empty State-->
					  <tr *ngIf="!pending_orders_records.length">
						<td colspan="8">
							<div class="row pt-40 pb-40">
								<div class="col-md-12 text-center">
									<div class="mb-20"><img src="/assets/images/empty-state.png">
									</div>
									<div class="fs16">There are no records to display!</div>
								</div>
							</div>
						</td>
					</tr>
					<!--End Empty State-->
					<!-- REPEAT -->
					<tr *ngFor="let order of pending_orders_records;">
						<!-- <td>
							<div class="css-check">
								<input class="css-checkbx" type="checkbox" />
								<label class="css-label">
									&nbsp;
								</label>
							</div>	
						</td> -->
						<td>
							<div class="order-info">
								<div class="order-img">
									<img src="{{ order.product_image }}" />
								</div>
								<div class="pd-seller-cntnt">
									<div class="txtdefault">
										<a href="javascript:;" class="order-link">{{ order.product_name }}</a>
									</div>
								</div>
							</div>
							<div class="pd-taglist">
								<span class="f-600 fs10 radius15">{{ order.marketplace}}</span>
								<span class="f-600 fs10 radius15">{{ order.dispatched_in }}</span>
							</div>
						</td>
						<td><a href="javascript:;" class="order-link">{{ order.order_id }}</a></td>
						<td><a href="javascript:;" class="order-link">{{ order.transaction_id }}</a></td>
						<td>{{ order.booking_on }}</td>
						<td>{{ order.booked_amount }}</td>
						<td>{{ order.order_qty }}</td>
						<td>{{ order.time_left }}</td>
						<td>
							<a href="javascript:void(0);" class="btn-d-outline-success ripple d-inline-block place-bid2 mr-2" (click)="acceptorder(order)">
								<img src="assets/images/icons/success.png">
							</a>
							<a href="javascript:void(0);" class="btn-d-outline-danger ripple d-inline-block place-bid2 mr-2" (click)="rejectorder(order)">
								<img src="assets/images/icons/cancel.png">
							</a>
							<a href="javascript:void(0);" [routerLink]="['/seller/view-order-details/'+order.id]" class="btn-d-outline ripple d-inline-block place-bid">View Order</a>
						</td>
					  </tr>
					<!-- /REPEAT -->
				  </tbody>
				</table>
			</div> 
		</div>

		<div class="row mb-40"  *ngIf="pending_order_pagination.totalPages > 1">
			<div class="col-md-6">
				<div class="text-left pl-3">
					<nav class="mt-3 tbpg rows-per-page">
						<div>Rows per page:</div>
						<select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
							<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">{{option.name}}</option>
						</select>
					</nav>
				</div> 
			</div>
			<div class="col-md-6" *ngIf="pending_order_pagination.pages && pending_order_pagination.pages.length">
				<div class="text-right md-text-left pr-3">
					<nav class="mt-3 tbpg">
					  <ul class="pagination align-items-center">
						<li class="page-item" [ngClass]="{disabled:pending_order_pagination.currentPage === 1}"><a class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a></li>

						<li class="page-item" [ngClass]="{disabled:pending_order_pagination.currentPage === 1}"><a class="page-link sprevicon" href="javascript:;" (click)="setpage(pending_order_pagination.currentPage - 1)"></a></li>

						<li class="page-item"i *ngFor="let page of pending_order_pagination.pages" [ngClass]="{active:pending_order_pagination.currentPage === page}"><a class="page-link" href="javascript:;" (click)="setpage(page)">{{page}}</a></li>

						<li *ngIf="(pending_order_pagination.totalPages - pending_order_pagination.startPage) > 5 ">
							<a (click)="setpage(pending_order_pagination.startPage + 5)">...</a>
						</li>
						 <li *ngIf="(pending_order_pagination.totalPages - pending_order_pagination.startPage) > 5" >
							<a (click)="setpage(pending_order_pagination.totalPages)">{{pending_order_pagination.totalPages}}</a>
						</li>
						
						<li class="page-item" [ngClass]="{disabled:pending_order_pagination.currentPage === pending_order_pagination.totalPages}"><a class="page-link snexticon" href="javascript:;"  (click)="setpage(pending_order_pagination.currentPage + 1)"></a></li>
						<li class="page-item" [ngClass]="{disabled:pending_order_pagination.currentPage === pending_order_pagination.totalPages}"><a class="page-link nexticon" href="javascript:;" (click)="setpage(pending_order_pagination.totalPages)"></a></li>
					  </ul>
					</nav>
				</div> 
			</div>
		</div>

	</div>
</div>


<app-footer-seller></app-footer-seller>