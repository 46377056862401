import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BuyerViewOrdersDetailsService {

    constructor(private _http: HttpClient) { }

    getorderdetails(data) {
        return this._http.post(environment.api_url+'buyer/myorder/details',data);
    }

    returnrequest(data) {
      return this._http.post(environment.api_url+'buyer/order/returnrequest',data);
  }

}
