import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AddProductService {

    constructor(private _http: HttpClient) { }

    getcountry(data) {
        return this._http.post(environment.api_url+'master/countries/list',data);
    }

    getcomboproducts(data) {
        return this._http.post(environment.api_url+'seller/comboproduct',data);
    }

    ownproductsearch(data) {
        return this._http.post(environment.api_url+'seller/product/autocomplete',data);
    }

    addcomboproduct(data) {
        return this._http.post(environment.api_url+'seller/addcomboproduct',data);
    }

    removecomboproduct(data) {
        return this._http.post(environment.api_url+'seller/deletecomboproduct',data);
    }

    updatestatus(data){
        return this._http.post(environment.api_url+'seller/product/status',data);
    }

    getvariants(data) {
        return this._http.post(environment.api_url+'seller/product/variant',data);
    }

    updatevariant(data) {
        return this._http.post(environment.api_url+'seller/product/variant/update',data);
    }

    saveconsent(data) {
        return this._http.post(environment.api_url+'seller/product/consent/add',data);
    }

    saveunique(data) {
        return this._http.post(environment.api_url+'seller/product/uniqueid/add',data);
    }

    saveproduct(data) {
        return this._http.post(environment.api_url+'seller/product/detail/add',data);
    }

    savemarketplace(data) {
        return this._http.post(environment.api_url+'seller/product/marketplace/add',data);
    }

    savepricing(data) {
        return this._http.post(environment.api_url+'seller/product/price/add',data);
    }

    savepayment(data) {
        return this._http.post(environment.api_url+'seller/product/payment/add',data);
    }

    getsellerproductdetails(data) {
        return this._http.post(environment.api_url+'seller/product/details', data);
    }
    searchhsn(data) {
        return this._http.post(environment.api_url+'master/hsn/autocomplete', data);
    }

    netrealizationvalue(data) {
        return this._http.post(environment.api_url+'seller/product/netrealization', data);
    }

    addquantity(data) {
        return this._http.post(environment.api_url+'seller/product/inventory/add', data);
    }

    updatechildproduct(data) {
        return this._http.post(environment.api_url+'seller/product/updatechildproduct', data);
    }

    updatechildproductinventory(data) {
        return this._http.post(environment.api_url+'seller/product/updatechildproductinventory', data);
    }

}