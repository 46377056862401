import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seller-notification',
  templateUrl: './seller-notification.component.html',
  styleUrls: ['./seller-notification.component.scss']
})
export class SellerNotificationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  ratingclassstatus: boolean = false;
	ratingst: boolean = false;
	ratingtglclass(){
	    this.ratingclassstatus = !this.ratingclassstatus;       
	}

}
