import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { SubscribersListService } from './subscribers-list.service';
@Component({
  selector: 'app-subscribers-list',
  templateUrl: './subscribers-list.component.html',
  styleUrls: ['./subscribers-list.component.scss']
})
export class SubscribersListComponent implements OnInit {

  closeResult = '';
  public selected_record:any = {};
  public subscribers_records:any = [];

  public current_page: number = 1;
  public offset: number = 0;
  public page_per_view: number = 10;
  public subscribers_pagination: any = {};
  public page_per_view_options = [
      {
          value: 10,
          name: 10
      },
      {
          value: 20,
          name: 20
      }
  ];

  constructor(
      private modalService: NgbModal,
      private _subscribersservice: SubscribersListService,
      private _toastr: ToasterService,
      private _pagination: PaginationService
  ) {}

  ngOnInit(): void {
      this.getsubscribers();
  }

  getsubscribers() {
      let limit = this.page_per_view;
      let offset = this.offset;
      let current_page = this.current_page;
      this._subscribersservice.getsubscribers({ limit: limit, offset: offset, current_page: current_page }).subscribe((data) => {
          if (data['status'] == "success") {
              let total_records = data['total_records'];
              this.subscribers_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
              this.subscribers_records = data['records'];
          } else if (data['status'] == "error") {
              this._toastr.error(data['message']);
          }
      });
  }

  setpage(page) {
      this.current_page = page;
      this.offset = (page - 1) * this.page_per_view;
      this.getsubscribers();
  }

  setpageperview() {
      this.current_page = 1;
      this.getsubscribers();
  }

  open(content, record) {
      this.selected_record = record;
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'mblbtm' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return `with: ${reason}`;
      }
  }

}
