import { Component, OnInit, ViewChild } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
//Chart - start
import {
    ChartComponent,
    ApexAxisChartSeries,
    ApexChart,
    ApexXAxis,
    ApexDataLabels,
    ApexTitleSubtitle,
    ApexStroke,
    ApexGrid
} from "ng-apexcharts";
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { AdminOrderService } from '../order/order.service';
import { AdminDashboardService } from './admindashboard.service';
@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

    closeResult = '';
    constructor(
        private modalService: NgbModal,
        private _admindashboardservice: AdminDashboardService,
        private _toastr: ToasterService,
        private _orderservice: AdminOrderService,
        private _pagination: PaginationService
    ) {}

    //chart - start
    @ViewChild("chart") chart: ChartComponent;
    public chartOptions: any = {};
    //chart - end

    //Months Array
    public months_array:any = [];

    //Filter
    public purchase_overview_filter:any = 1;
    public pending_order_filter:any = 1;
    public order_filter:any = 1;

    //Purchase Overview
    public total_purchase:string;
    public total_orders:number;

    //Pending Orders
    public total_pending:number;
    public bulk_orders:number;
    public wholesale_orders:number;
    public rejected_orders:number;

    //User Management Details
    public approved_buyer:number;
    public approved_seller:number;
    public pending_buyer:number;
    public pending_seller:number;

    //Product Management
    public active_product_listing:number;
    public active_product_template:number;
    public requested_listing:number;

    //Orders
    public orders_details:any = {};

    //Admin Orders
    public order_type:string = 'all';

    config = {
		labelField: 'label',
		valueField: 'value',
  	};

    public search_term:string = '';
    public from_date:any = '';
    public to_date:any = '';

    public map_type:string = 'all';
    public allorder_records: any = [];
    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 20;
    public allorder_pagination: any = {};
    public page_per_view_options = [
        {
            value: 20,
            name: 20
        },
        {
            value: 40,
            name: 40
        }
    ];

    getdashboardmonths(){
        this._admindashboardservice.dashboardmonths(null).subscribe((data) => {
            if(data['status'] == "success"){
                this.months_array = data['month'];
                this.mydashboarddetails('purchase_overview');
                setTimeout( () => {
                    this.mydashboarddetails('usermanagementoverview');
                    this.mydashboarddetails('producttemplateoverview');
                }, 500);

                setTimeout( () => {
                    this.mydashboarddetails('pending_order');
                    this.mydashboarddetails('orders');
                }, 1500);
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.adminorders();
    }

    setpageperview() {
        this.current_page = 1;
        this.adminorders();
    }

    getorders(type) {
        this.search_term = '';
        this.from_date = '';
        this.to_date = '';
        this.order_type = type;
        this.page_per_view = 10;
        this.offset = 0;
        this.current_page = 1;
        this.adminorders();
    }

    getordersfilters(){
        this.page_per_view = 10;
        this.offset = 0;
        this.current_page = 1;
        this.adminorders();
    }

    adminorders() {
        var type:any = 'all';
        if(this.order_type == 'booked'){
            type = 1
        }else if(this.order_type == 'approved'){
            type = 2
        }else if(this.order_type == 'rejected'){
            type = 3
        }else if(this.order_type == 'payment_done'){
            type = 4
        }else if(this.order_type == 'ready_to_dispatch'){
            type = 6
        }else if(this.order_type == 'quality_done'){
            type = 7
        }else if(this.order_type == 'dispatched'){
            type = 8
        }else if(this.order_type == 'delivered'){
            type = 9
        }

        let data = { 
            limit: this.page_per_view, 
            offset: this.offset, 
            current_page: this.current_page, 
            type: type,
            search: this.search_term,
            from_date: this.from_date,
            to_date: this.to_date,
            map_type: this.map_type
        };
        this._orderservice.getorders(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.allorder_records = data['records'];
                let total_records = data['total_records'];
                this.allorder_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    mydashboarddetails(type){
        let month:any = 0;
        if(type == 'purchase_overview'){
            month = this.purchase_overview_filter;
        }else if(type == 'pending_order'){
            month = this.pending_order_filter;
        }else if(type == 'usermanagementoverview'){
            month = '';
        }else if(type == 'orders'){
            month = this.order_filter;
        }else if(type == 'producttemplateoverview'){
            month = '';
        }
        let filter = this.months_array[month-1];
        this._admindashboardservice.dashboarddetails({type:type, month: filter}).subscribe((data) => {
            if(data['status'] == "success"){
                if(type == 'purchase_overview'){

                    this.total_orders = data['total_order'];
                    this.total_purchase = data['total_sales'];
                    //Chart - start
                    this.chartOptions = {
                        series: [
                            {
                                name: "Purchase",
                                data: data['weekly_avg']['weekly_avg_sales']
                            }
                        ],
                        chart: {
                            height: 350,
                            type: "line",
                            zoom: {
                                enabled: false
                            }
                        },
                        dataLabels: {
                            enabled: false
                        },
                        stroke: {
                            curve: "straight"
                        },
                        title: {
                            text: "Average Order Value: "+data['avg_amount'],
                            align: "left"
                        },
                        grid: {
                            row: {
                                colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                                opacity: 0.5
                            }
                        },
                        xaxis: {
                            categories: data['weekly_avg']['weekly_avg_month']
                        }
                    };
                    //Chart - end
                }else if(type == 'pending_order'){
                    this.total_pending = data['total_pending'];
                    this.bulk_orders = data['bulk'];
                    this.wholesale_orders = data['wholesale'];
                    this.rejected_orders = data['rejected_order'];
                }else if(type == 'orders'){
                    this.orders_details = data;
                }else if(type == 'usermanagementoverview'){
                    this.approved_buyer = data['buyer_approved'];
                    this.approved_seller = data['seller_approved'];
                    this.pending_buyer = data['buyer_pending'];
                    this.pending_seller = data['seller_pending'];
                }else if(type == 'producttemplateoverview'){
                    this.active_product_listing = data['product_listing'];
                    this.active_product_template = data['template_listing'];
                    this.requested_listing = data['requested_listing'];
                }
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    open(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'mblbtm' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
        return `with: ${reason}`;
        }
    }
    openmdl(profilephoto) {
        this.modalService.open(profilephoto, { windowClass: 'midmodal' });
    }
  
    ngOnInit(): void {
        this.getdashboardmonths();
        this.getorders('all');
    }

}
