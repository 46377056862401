import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AddMoneyCardConfirmationService {

    constructor(private _http: HttpClient) { }

    confirmorderstatus(data) {
        return this._http.post(environment.api_url+'buyer/cf/addtowallet/verifyandconfirm',data);
    }

}
