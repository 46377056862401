<app-header-buyer></app-header-buyer>
<app-sidebar-buyer></app-sidebar-buyer>
<div class="wrpmain-buyer">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-md-8 pr-0">
				<h3 class="fs24 wt700 txtdefault">Wishlist</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">EB Dashboard</a>
						</li>
						<li class="breadcrumb-item active">Wishlist</li>
					</ol>
				</nav>
			</div>
			<div class="col-12 col-md-4 text-right pl-0 mt-xs-10">
				<div class="report-input">
					<div class="input-group-custom">
						<input type="text" placeholder="Search by Product name" class="form-control">
						<div class="input-group-append-custom">
							<img src="assets/images/svg/search_logo.svg">
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="row" *ngIf="wishlist_records.length > 0">
			<ng-container>
				<div class="col-md-3" *ngFor="let record of wishlist_records; let i = index;">
					<div class="product-item">
						<div class="heart" (click)="wishlist(record)">
							<img src="/assets/images/icons/heart.svg">
						</div>
						<div class='image'>
							<img src='{{ record.product_image }}'>
						</div>
						<div class="product-name">
							{{ record.name }}
						</div>
						<div class="ratings">
							<i class="fa fa-star" aria-hidden="true" *ngFor="let rating of record.star_rating;"></i>
							<!-- <i class="fa fa-star" aria-hidden="true"></i>
							<i class="fa fa-star" aria-hidden="true"></i>
							<i class="fa fa-star" aria-hidden="true"></i>
							<i class="fa fa-star" aria-hidden="true"></i> -->
						<span class='star-count'>({{ record.total_rating }})</span>
						</div>
						<div class="row">
							<div class="col-md-12">
							<div class="price">
								Starting at <span><i class="fa fa-inr"></i> {{ record.sales_price }}</span>
							</div>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
		<div class="row" *ngIf="wishlist_records.length == 0">
			<ng-container>
				<div class="col-md-12">
					<div class="card cardui carduipro p-4 mb-40">
						<div class="text-center">
							<div class="mb-20"><img src="/assets/images/empty-state.png"></div>
							<div class="fs15 f-600">There are no records to display!</div>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>
