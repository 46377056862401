import { Component, OnInit, Input, HostListener, ViewChild } from '@angular/core';
import { SignupModalComponent } from "../../../shared/components/modal/signup-modal/signup-modal.component";
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderOneService } from './header-one.service';
import { SignupService } from "../../../shared/components/modal/signup-modal/signup-modal.service";
import { HomeService } from 'src/app/home/home.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { BuyerAssistanceService } from 'src/app/buyer/buyer-assistance/buyer-assistance.service';

@Component({
    selector: 'app-header-one',
    templateUrl: './header-one.component.html',
    styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {

    @Input() class: string;
    @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
    @Input() topbar: boolean = true; // Default True
    @Input() sticky: boolean = false; // Default false

    @ViewChild("signIn") SignIn: SignupModalComponent;

    public stick: boolean = false;
    public token: string;
    public usertype: any;
    public name: string;
    public wallet_amount: string;
    public cart_count: number;
    public profile_image:string;

    public user_token:string;
    public user_reg_status:string;
    public user_status:string;
    public user_details: any = {};

    public assistance_records:any = [];
    public menu_categories:any = [];
    

    constructor(
        private _headerservice: HeaderOneService,
        private route: ActivatedRoute,
        public router: Router,
        public _singupservice: SignupService,
        public _homeservice: HomeService,
        public _toastr: ToasterService,
        private _buyerassistanceservice: BuyerAssistanceService,
    ) {}

    ngOnInit() {
        this.user_details.name = '';
        this.user_token = this.token = this._singupservice.gettoken();
        this.usertype = this._singupservice.getusertype();
        this.user_status = this._singupservice.getuserstatus();
        this.user_details = this._singupservice.getuserdetails();
        this.user_reg_status = this._singupservice.getuserregstatus();
        this._singupservice.tokenChanged.subscribe((response: string) => {
            this.token = response;
            this.user_token = response;
        });

        this._singupservice.userLoggedinUserDetails.subscribe((response: string) => {
            this.user_details = response;
        });

        this._singupservice.userRegStatusChanged.subscribe((response: string) => {
            this.user_reg_status = response;
        });


        //Assitance Details
        this.assistance_records = this._singupservice.getbuyerassistance();
        this._singupservice.buyerAssistanceChanged.subscribe((response: string) => {
            this.assistance_records = response;
        });

        if(!this.assistance_records || this.assistance_records.length == 0){
            this.getassistances();
        }


        setTimeout(() => {
            const token = this.route.snapshot.paramMap.get('reset-token');
            if (token) {
                this.SignIn.openSigninModal('reset', '', token);
            }
            const settoken = this.route.snapshot.paramMap.get('signup-token');
            if (settoken) {
                this.SignIn.openSigninModal('set', '', settoken);
            }
        }, 500);

        if (this.token) {
            if(!this.user_details || !this.user_details.name){
                this.getuserdetails();
            }else{
                this.name = this.user_details.name;
                this.wallet_amount = this.user_details.wallet_amount;
                this.cart_count = this.user_details.cart_count;
                this.profile_image = this.user_details.profile_image;
            }
            setTimeout( () => {
                this.getmenucategory();
            }, 2000)
            
        }
    }

    getmenucategory() {
        this._homeservice.getfavcategories(null).subscribe((data) => {
            if (data['status'] == "success") {
                if(this.user_token){
                    this.menu_categories = data['records'];
                }
            } else if (data['status'] == "error") {
                //this._toastr.error(data['message']);
            }
        }, (error) => {

        });
    }

    getassistances() {
        this._buyerassistanceservice.getassistances({user_type:'both'}).subscribe((data) => {
            if(data['status'] == "success"){
                this.assistance_records = data['records'];
                let assistance_array = [];
                for (var i = 0; i < this.assistance_records.length; i++) {
                    assistance_array.push({title: this.assistance_records[i].title, id: this.assistance_records[i].id});
                }
                this._singupservice.updateassistance(assistance_array);
            }
        },(error) => {
        });
    }

    getuserdetails() {
        this._headerservice.getuserdetails(null).subscribe((data) => {
            if (data['status'] == "success") {
                this._singupservice.updatestatus(data['data']['adminverified']);
                this._singupservice.updateregstatus(data['data']['reg_status']);
                this.user_reg_status = data['data']['reg_status'];
                this.name = data['data']['name']
                this.wallet_amount = data['data']['walletbalance']
                this.cart_count = data['data']['cart_count'];
                this.profile_image = data['data']['profile_image'];
                var user_details = {name: this.name, wallet_amount: this.wallet_amount, cart_count: this.cart_count, profile_image: this.profile_image};
                this._singupservice.updateloggedinuserdetails(user_details);
            }
        }, (error) => {

        });
    }

    // @HostListener Decorator
    @HostListener("window:scroll", [])
    onWindowScroll() {
        let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (number >= 150 && window.innerWidth > 400) {
            this.stick = true;
        } else {
            this.stick = false;
        }
    }

    result = [];
    keyword = 'name';
    public search_value: string;
    public limit = 20;
    public offset = 0;
    public current_page = 1;

    selectEvent(item) {
        var slug = item.parent;
        if(item.from == 'product'){
            slug = item.slug;
        }else if(item.from == 'category'){
            slug = item.name;
        }
        this.router.navigate(['/search'], { queryParams: { q: slug, limit: this.limit, offset: this.offset, current_page: this.current_page } });
        // do something with selected item
    }

    onChangeSearch(val: string) {
        this.search_value = val;
        // fetch remote data from here
        // And reassign the 'data' which is binded to 'data' property.
        this._homeservice.searchcategory({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.result = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    submitsearch(event) {
        this.router.navigate(['/search'], { queryParams: { q: this.search_value, limit: this.limit, offset: this.offset, current_page: this.current_page } });
    }

    onFocused(e) {
        // do something when input is focused
    }

    logout(){
        this._singupservice.logout();
    }

}
