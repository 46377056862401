import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-withdrawal-request',
  templateUrl: './withdrawal-request.component.html',
  styleUrls: ['./withdrawal-request.component.scss']
})
export class WithdrawalRequestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
