<app-header-one [sticky]="true"></app-header-one>
<div class="container pb-5">
    <!-- BREADCRUMB -->
    <nav class="mb-md-2 pt-2 pb-md-2" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
        <ol class="breadcrumb p-0 bg-transparent">
            <li class="breadcrumb-item"><a href="javascript:void(0);" class="txtseconday" [routerLink]="['/home']">Home
                </a></li>
            <li class="breadcrumb-item" *ngFor="let cate of category_flow;">
                <a href="javascript:void(0);" class="txtseconday" [routerLink]="['/category/'+cate.slug]">{{ cate.name
                    }}
                </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">{{ category_name }}</li>
        </ol>
    </nav>
    <!-- /BREADCRUMB -->

    <div class="row">
        <!-- FILTER -->
        <div class="col-lg-3 mt-2 pt-2 filtercol filtermbl" [ngClass]="classstatus ? 'showfilter' : ''">
            <!-- <div class="brand-sbx p-3 radius10">
				<h4 class="fs16 wt700 txtdefault mb-2">Search what you are looking for?</h4>
				<div class="srch-ipt">
				   <input class="form-control fs14 border" type="text" placeholder="Search here" id="example-search-input">
				   <img src="assets/images/svg/search_logo.svg">
				</div>
			</div> -->
            <div class="fltrshead">
                <h4>Filter</h4>
                <a (click)="tglclass()" class="closefilter" href="javascript:void(0);"><svg _ngcontent-serverApp-c132=""
                        xmlns="http://www.w3.org/2000/svg" width="6.508" height="6.508" viewBox="0 0 6.508 6.508">
                        <path _ngcontent-serverApp-c132="" id="np_cross_1707162_000000"
                            d="M30.883,30.883a.507.507,0,0,1,.717,0l2.389,2.389,2.389-2.389a.507.507,0,0,1,.717.717L34.7,33.988l2.389,2.389a.507.507,0,0,1-.717.717L33.988,34.7,31.6,37.094a.507.507,0,0,1-.717-.717l2.389-2.389L30.883,31.6A.507.507,0,0,1,30.883,30.883Z"
                            transform="translate(-30.734 -30.734)" fill="#1f1d44"></path>
                    </svg></a>
            </div>
            <div class="lsting">
                <ngb-accordion #acc="ngbAccordion" class="accordion" activeIds="{{ active_ids }}">  <!-- activeIds="{{ active_ids }}" -->
                    <ngb-panel id="toggle-1" opened class="bg-transparent" title="Market Preference">
                        <ng-template ngbPanelContent>
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let mlist of filter_marketplace; let i = index;">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" [checked]="mlist.selected"
                                            (change)="createparams('marketplace', mlist)" />
                                        <label class="css-label">{{ mlist.name }}</label>
                                    </div>
                                </li>
                            </ul>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="toggle-2" title="Product Types" *ngIf="filter_category.length > 0">
                        <ng-template ngbPanelContent>
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let category of filter_category; let i = index;">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" value="{{ category.slug }}"
                                            [checked]="category.selected"
                                            (change)="createparams('category', category)" />
                                        <label class="css-label">{{ category.name }} ({{ category.count }})</label>
                                    </div>
                                </li>
                            </ul>
                        </ng-template>
                    </ngb-panel>
                    <!-- <ngb-panel id="toggle-3" title="Model">
						<ng-template ngbPanelContent>
							<div class="input-group mb-3 inptsearch">
							  <span class="input-group-text">
								<svg xmlns="http://www.w3.org/2000/svg" width="13.685" height="13.681" viewBox="0 0 13.685 13.681">
	  <path id="np_search_1120430_000000" d="M29.587,29.163l-2.728-2.748A5.6,5.6,0,0,0,28.3,22.649a5.649,5.649,0,1,0-5.65,5.669,5.578,5.578,0,0,0,3.767-1.461l2.728,2.728a.281.281,0,0,0,.423,0,.261.261,0,0,0,.019-.422Zm-6.937-1.5a5.025,5.025,0,1,1,5.034-5.016A5.052,5.052,0,0,1,22.65,27.665Z" transform="translate(-16.501 -16.5)" fill="#5e6a7c" stroke="#5e6a7c" stroke-width="1"/>
	</svg>

							  </span>
							  <input type="text" class="form-control" placeholder="Search here" >
							</div>
							<ul class="list-group">
								<li class="list-group-item">
									<div class="css-check">
										<input class="css-checkbx" type="checkbox" />
										<label class="css-label">A31</label>
									</div>								
								</li>
								<li class="list-group-item">
									<div class="css-check">
										<input class="css-checkbx" type="checkbox" />
										<label class="css-label">A15</label>
									</div>
								</li>
								<li class="list-group-item">
									<div class="css-check">
										<input class="css-checkbx" type="checkbox" />
										<label class="css-label">A21</label>
									</div>								
								</li>
								<li class="list-group-item">
									<div class="css-check">
										<input class="css-checkbx" type="checkbox" />
										<label class="css-label">F5</label>
									</div>
								</li>
								<li class="list-group-item">
									<div class="css-check">
										<input class="css-checkbx" type="checkbox" />
										<label class="css-label">F17</label>
									</div>								
								</li>
								<li class="list-group-item">
									<div class="css-check">
										<input class="css-checkbx" type="checkbox" />
										<label class="css-label">Reno2</label>
									</div>
								</li>
							</ul>
							<a class="vwmre" href="javascript:void(0);">View More</a>
						</ng-template>
					</ngb-panel> -->
                    <ngb-panel id="brand" title="Brands">
                        <ng-template ngbPanelContent>
                            <div class="input-group mb-3 inptsearch">
                                <span class="input-group-text">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.685" height="13.681"
                                        viewBox="0 0 13.685 13.681">
                                        <path id="np_search_1120430_000000"
                                            d="M29.587,29.163l-2.728-2.748A5.6,5.6,0,0,0,28.3,22.649a5.649,5.649,0,1,0-5.65,5.669,5.578,5.578,0,0,0,3.767-1.461l2.728,2.728a.281.281,0,0,0,.423,0,.261.261,0,0,0,.019-.422Zm-6.937-1.5a5.025,5.025,0,1,1,5.034-5.016A5.052,5.052,0,0,1,22.65,27.665Z"
                                            transform="translate(-16.501 -16.5)" fill="#5e6a7c" stroke="#5e6a7c"
                                            stroke-width="1" />
                                    </svg>
                                </span>
                                <input type="text" class="form-control" placeholder="Search here"
                                    ([ngModel])="brand_search">
                            </div>
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let brand of filter_brands; let i = index;">
                                    <!-- | filter:{name: brand_search} -->
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" value="brand.slug"
                                            [checked]="brand.selected" (change)="createparams('brand', brand)" />
                                        <label class="css-label">{{ brand.brand_name }}</label>
                                    </div>
                                </li>
                            </ul>
                            <!-- <a class="vwmre" href="javascript:void(0);">View More</a> -->
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="startingprice" title="Starting Price">
                        <ng-template ngbPanelContent>
                            <div class="input-group mb-3 inptsearch">
                                <span class="input-group-text">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.685" height="13.681"
                                        viewBox="0 0 13.685 13.681">
                                        <path id="np_search_1120430_000000"
                                            d="M29.587,29.163l-2.728-2.748A5.6,5.6,0,0,0,28.3,22.649a5.649,5.649,0,1,0-5.65,5.669,5.578,5.578,0,0,0,3.767-1.461l2.728,2.728a.281.281,0,0,0,.423,0,.261.261,0,0,0,.019-.422Zm-6.937-1.5a5.025,5.025,0,1,1,5.034-5.016A5.052,5.052,0,0,1,22.65,27.665Z"
                                            transform="translate(-16.501 -16.5)" fill="#5e6a7c" stroke="#5e6a7c"
                                            stroke-width="1" />
                                    </svg>
                                </span>
                                <input type="text" class="form-control" placeholder="Search here">
                            </div>
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let price of filter_prices; let i = index;">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" [checked]="price.selected"
                                            (change)="createparams('price', price)" />
                                        <label class="css-label">{{ price.name }}</label>
                                    </div>
                                </li>
                            </ul>
                            <div class="pricerange">
                                <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"
                                    (click)="createparams('custom_price', '')"></ngx-slider>
                                <div class="row g-2">
                                    <div class="col-6">
                                        <input class="form-control" type="number" [(ngModel)]="minValue"
                                            (change)="createparams('custom_price', '')">
                                    </div>
                                    <div class="col-6">
                                        <input class="form-control" type="number" [(ngModel)]="maxValue"
                                            (change)="createparams('custom_price', '')">
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel *ngFor="let variant of filter_variants; let i = index;" id="{{variant.slug}}"
                        title="{{ variant.name }}">
                        <ng-template ngbPanelContent>
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let meta of variant.metas; let k = index;">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" [checked]="meta.selected"
                                            (change)="createparams('variant', meta)" />
                                        <label class="css-label">{{ meta.name }}</label>
                                    </div>
                                </li>
                            </ul>
                        </ng-template>
                    </ngb-panel>

                    <ngb-panel *ngFor="let variant of filter_attribute; let i = index;" id="{{variant.name}}"
                        title="{{ variant.name }}">
                        <ng-template ngbPanelContent>
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let meta of variant.metas; let k = index;">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" [checked]="meta.selected"
                                            (change)="createparams('technical', meta)" />
                                        <label class="css-label">{{ meta.name }}</label>
                                    </div>
                                </li>
                            </ul>
                        </ng-template>
                    </ngb-panel>
                    <!-- <ngb-panel id="activationstatus" title="Product Status">
						<ng-template ngbPanelContent>
							<div class="savefilter no-border pt-3">
								<div class="icnsavefilter">
									<div class="css-check">
										<input class="css-checkbx" type="checkbox" />
										<label class="css-label">Activated</label>
										
									</div>		
									<i placement="top" ngbTooltip="from wholesaler, retailer, etc">
									<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
									  <g id="Group_68" data-name="Group 68" transform="translate(-545 -281)">
										<circle id="Ellipse_1" data-name="Ellipse 1" cx="7.5" cy="7.5" r="7.5" transform="translate(545 281)" fill="#07f"/>
										<path id="Path_2235" data-name="Path 2235" d="M7,8.171l-.128.523q-.576.227-.919.346a2.425,2.425,0,0,1-.8.119,1.591,1.591,0,0,1-1.084-.34,1.1,1.1,0,0,1-.387-.864,3.056,3.056,0,0,1,.029-.416q.029-.213.093-.48l.48-1.7q.064-.244.108-.462a2.01,2.01,0,0,0,.044-.4A.616.616,0,0,0,4.3,4.047a.759.759,0,0,0-.513-.128,1.345,1.345,0,0,0-.381.058c-.131.039-.242.076-.336.11L3.2,3.564q.471-.192.9-.329A2.7,2.7,0,0,1,4.916,3.1a1.552,1.552,0,0,1,1.068.335,1.111,1.111,0,0,1,.375.869c0,.074-.008.2-.026.39a2.606,2.606,0,0,1-.1.512L5.759,6.9a4.492,4.492,0,0,0-.1.466,2.4,2.4,0,0,0-.047.4.57.57,0,0,0,.15.459.85.85,0,0,0,.523.122,1.509,1.509,0,0,0,.4-.061A2.21,2.21,0,0,0,7,8.171Zm.121-7.1a.99.99,0,0,1-.334.753,1.138,1.138,0,0,1-.8.312,1.15,1.15,0,0,1-.809-.312.988.988,0,0,1-.337-.753A1,1,0,0,1,5.169.314a1.194,1.194,0,0,1,1.614,0A1,1,0,0,1,7.117,1.07Z" transform="translate(547.406 283.92)" fill="#fff"/>
									  </g>
									</svg>
									</i>
								</div>	
							</div>

							<div class="savefilter no-border pt-3 mb-10">
								<div class="icnsavefilter">
									<div class="css-check">
										<input class="css-checkbx" type="checkbox" />
										<label class="css-label">Fresh</label>
										
									</div>		
									<i placement="top" ngbTooltip="(Directly from Brand or Brand Authorized Seller)">
									<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
									  <g id="Group_68" data-name="Group 68" transform="translate(-545 -281)">
										<circle id="Ellipse_1" data-name="Ellipse 1" cx="7.5" cy="7.5" r="7.5" transform="translate(545 281)" fill="#07f"/>
										<path id="Path_2235" data-name="Path 2235" d="M7,8.171l-.128.523q-.576.227-.919.346a2.425,2.425,0,0,1-.8.119,1.591,1.591,0,0,1-1.084-.34,1.1,1.1,0,0,1-.387-.864,3.056,3.056,0,0,1,.029-.416q.029-.213.093-.48l.48-1.7q.064-.244.108-.462a2.01,2.01,0,0,0,.044-.4A.616.616,0,0,0,4.3,4.047a.759.759,0,0,0-.513-.128,1.345,1.345,0,0,0-.381.058c-.131.039-.242.076-.336.11L3.2,3.564q.471-.192.9-.329A2.7,2.7,0,0,1,4.916,3.1a1.552,1.552,0,0,1,1.068.335,1.111,1.111,0,0,1,.375.869c0,.074-.008.2-.026.39a2.606,2.606,0,0,1-.1.512L5.759,6.9a4.492,4.492,0,0,0-.1.466,2.4,2.4,0,0,0-.047.4.57.57,0,0,0,.15.459.85.85,0,0,0,.523.122,1.509,1.509,0,0,0,.4-.061A2.21,2.21,0,0,0,7,8.171Zm.121-7.1a.99.99,0,0,1-.334.753,1.138,1.138,0,0,1-.8.312,1.15,1.15,0,0,1-.809-.312.988.988,0,0,1-.337-.753A1,1,0,0,1,5.169.314a1.194,1.194,0,0,1,1.614,0A1,1,0,0,1,7.117,1.07Z" transform="translate(547.406 283.92)" fill="#fff"/>
									  </g>
									</svg>
									</i>
								</div>	
							</div>						
						</ng-template>
					</ngb-panel> -->
                    <ngb-panel id="supplylocation" title="Supply Location">
                        <ng-template ngbPanelContent>
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let slo of supply_locations; let i = index;">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" [checked]="slo.selected"
                                            (change)="createparams('slo', slo)" />
                                        <label class="css-label">{{ slo.name }}</label>
                                    </div>
                                </li>
                            </ul>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="countryorigin" title="Country of origin">
                        <ng-template ngbPanelContent>
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let country of countries; let i = index;">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" [checked]="country.selected"
                                            (change)="createparams('country', country)" />
                                        <label class="css-label">{{ country.name }}</label>
                                    </div>
                                </li>
                            </ul>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <!-- <div class="savefilter pt-3">
					<div class="icnsavefilter">
						<div class="css-check">
							<input class="css-checkbx" type="checkbox" />
							<label class="css-label">Save as a quick filter</label>
							
						</div>		
						<i placement="top" ngbTooltip="This will act as a default filter every time you will search. However, you can add or change anytime.">
						<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
						  <g id="Group_68" data-name="Group 68" transform="translate(-545 -281)">
							<circle id="Ellipse_1" data-name="Ellipse 1" cx="7.5" cy="7.5" r="7.5" transform="translate(545 281)" fill="#07f"/>
							<path id="Path_2235" data-name="Path 2235" d="M7,8.171l-.128.523q-.576.227-.919.346a2.425,2.425,0,0,1-.8.119,1.591,1.591,0,0,1-1.084-.34,1.1,1.1,0,0,1-.387-.864,3.056,3.056,0,0,1,.029-.416q.029-.213.093-.48l.48-1.7q.064-.244.108-.462a2.01,2.01,0,0,0,.044-.4A.616.616,0,0,0,4.3,4.047a.759.759,0,0,0-.513-.128,1.345,1.345,0,0,0-.381.058c-.131.039-.242.076-.336.11L3.2,3.564q.471-.192.9-.329A2.7,2.7,0,0,1,4.916,3.1a1.552,1.552,0,0,1,1.068.335,1.111,1.111,0,0,1,.375.869c0,.074-.008.2-.026.39a2.606,2.606,0,0,1-.1.512L5.759,6.9a4.492,4.492,0,0,0-.1.466,2.4,2.4,0,0,0-.047.4.57.57,0,0,0,.15.459.85.85,0,0,0,.523.122,1.509,1.509,0,0,0,.4-.061A2.21,2.21,0,0,0,7,8.171Zm.121-7.1a.99.99,0,0,1-.334.753,1.138,1.138,0,0,1-.8.312,1.15,1.15,0,0,1-.809-.312.988.988,0,0,1-.337-.753A1,1,0,0,1,5.169.314a1.194,1.194,0,0,1,1.614,0A1,1,0,0,1,7.117,1.07Z" transform="translate(547.406 283.92)" fill="#fff"/>
						  </g>
						</svg>
						</i>
					</div>	
				</div> -->
                <div class="tex-center clr-apl-btn showintablet">
                    <a href="javascript:void(0);" class="btn-d-outline mr-3 d-inline-block place-bid" *ngIf="all_filters.maps.length > 0 || all_filters.category.length > 0 || all_filters.brand.length > 0 || all_filters.prices.length > 0 || all_filters.variants.length > 0"
                        (click)="clearallfilters()">Clear All</a>
                    <a href="javascript:void(0);" class="buybtn d-inline-block radius10" (click)="tglclass()">Apply</a>
                </div>
            </div>
        </div>
        <!-- /FILTER -->
        <!-- LISTING -->
        <div class="col-lg-9 pt-3 listing-content">

            <!-- SORTING -->
            <div class="row mb-4">
                <div class="col-lg-8  srtlist">
                    <h3 class="txtdefault f-700 fs16 mb-3"><b>{{ search_key }}</b>&nbsp; <span class="fs14 wt400">({{
                            total_products }} products search in the result)</span></h3>
                    <span class="btn btn-outline-dark f-600 txtdefault"
                        *ngFor="let f_maps of all_filters.maps; let i = index;" (click)="removefilter(f_maps, 'maps')">
                        {{ f_maps.name }}
                        <a class="crosstag">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6.508" height="6.508"
                                viewBox="0 0 6.508 6.508">
                                <path id="np_cross_1707162_000000"
                                    d="M30.883,30.883a.507.507,0,0,1,.717,0l2.389,2.389,2.389-2.389a.507.507,0,0,1,.717.717L34.7,33.988l2.389,2.389a.507.507,0,0,1-.717.717L33.988,34.7,31.6,37.094a.507.507,0,0,1-.717-.717l2.389-2.389L30.883,31.6A.507.507,0,0,1,30.883,30.883Z"
                                    transform="translate(-30.734 -30.734)" fill="#1f1d44" />
                            </svg>
                        </a>
                    </span>

                    <span class="btn btn-outline-dark f-600 txtdefault"
                        *ngFor="let f_category of all_filters.category; let i = index;"
                        (click)="removefilter(f_category, 'category')">
                        {{ f_category.name }}
                        <a class="crosstag">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6.508" height="6.508"
                                viewBox="0 0 6.508 6.508">
                                <path id="np_cross_1707162_000000"
                                    d="M30.883,30.883a.507.507,0,0,1,.717,0l2.389,2.389,2.389-2.389a.507.507,0,0,1,.717.717L34.7,33.988l2.389,2.389a.507.507,0,0,1-.717.717L33.988,34.7,31.6,37.094a.507.507,0,0,1-.717-.717l2.389-2.389L30.883,31.6A.507.507,0,0,1,30.883,30.883Z"
                                    transform="translate(-30.734 -30.734)" fill="#1f1d44" />
                            </svg>
                        </a>
                    </span>

                    <span class="btn btn-outline-dark f-600 txtdefault"
                        *ngFor="let f_brand of all_filters.brand; let i = index;"
                        (click)="removefilter(f_brand, 'brand')">
                        {{ f_brand.brand_name }}
                        <a class="crosstag">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6.508" height="6.508"
                                viewBox="0 0 6.508 6.508">
                                <path id="np_cross_1707162_000000"
                                    d="M30.883,30.883a.507.507,0,0,1,.717,0l2.389,2.389,2.389-2.389a.507.507,0,0,1,.717.717L34.7,33.988l2.389,2.389a.507.507,0,0,1-.717.717L33.988,34.7,31.6,37.094a.507.507,0,0,1-.717-.717l2.389-2.389L30.883,31.6A.507.507,0,0,1,30.883,30.883Z"
                                    transform="translate(-30.734 -30.734)" fill="#1f1d44" />
                            </svg>
                        </a>
                    </span>

                    <span class="btn btn-outline-dark f-600 txtdefault"
                        *ngFor="let f_prices of all_filters.prices; let i = index;"
                        (click)="removefilter(f_prices, 'price')">
                        {{ f_prices.name }}
                        <a class="crosstag">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6.508" height="6.508"
                                viewBox="0 0 6.508 6.508">
                                <path id="np_cross_1707162_000000"
                                    d="M30.883,30.883a.507.507,0,0,1,.717,0l2.389,2.389,2.389-2.389a.507.507,0,0,1,.717.717L34.7,33.988l2.389,2.389a.507.507,0,0,1-.717.717L33.988,34.7,31.6,37.094a.507.507,0,0,1-.717-.717l2.389-2.389L30.883,31.6A.507.507,0,0,1,30.883,30.883Z"
                                    transform="translate(-30.734 -30.734)" fill="#1f1d44" />
                            </svg>
                        </a>
                    </span>

                    <span class="btn btn-outline-dark f-600 txtdefault"
                        *ngFor="let f_variant of all_filters.variants; let i = index;"
                        (click)="removefilter(f_variant, 'variant')">
                        {{ f_variant.name }}
                        <a class="crosstag">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6.508" height="6.508"
                                viewBox="0 0 6.508 6.508">
                                <path id="np_cross_1707162_000000"
                                    d="M30.883,30.883a.507.507,0,0,1,.717,0l2.389,2.389,2.389-2.389a.507.507,0,0,1,.717.717L34.7,33.988l2.389,2.389a.507.507,0,0,1-.717.717L33.988,34.7,31.6,37.094a.507.507,0,0,1-.717-.717l2.389-2.389L30.883,31.6A.507.507,0,0,1,30.883,30.883Z"
                                    transform="translate(-30.734 -30.734)" fill="#1f1d44" />
                            </svg>
                        </a>
                    </span>
                    <span class="btn btn-outline-dark f-600 txtdefault"
                        *ngFor="let f_technical of all_filters.technical; let i = index;"
                        (click)="removefilter(f_technical, 'technical')">
                        {{ f_technical.name }}
                        <a class="crosstag">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6.508" height="6.508"
                                viewBox="0 0 6.508 6.508">
                                <path id="np_cross_1707162_000000"
                                    d="M30.883,30.883a.507.507,0,0,1,.717,0l2.389,2.389,2.389-2.389a.507.507,0,0,1,.717.717L34.7,33.988l2.389,2.389a.507.507,0,0,1-.717.717L33.988,34.7,31.6,37.094a.507.507,0,0,1-.717-.717l2.389-2.389L30.883,31.6A.507.507,0,0,1,30.883,30.883Z"
                                    transform="translate(-30.734 -30.734)" fill="#1f1d44" />
                            </svg>
                        </a>
                    </span>
                    <a  class="btn-link nav-btn-link" href="javascript:void(0);" *ngIf="category_slug" [routerLink]="['/category/'+category_slug]">Go back</a>&nbsp;&nbsp;
                    <a class="btn-link nav-btn-link" href="javascript:void(0);"
                        *ngIf="all_filters.maps.length > 0 || all_filters.category.length > 0 || all_filters.brand.length > 0 || all_filters.prices.length > 0 || all_filters.variants.length > 0"
                        (click)="clearallfilters()">Clear All</a>
                </div>
                <div class="col-lg-4 listingsort">
                    <div class="fltsortnv mt-0">
                        <a href="javascript:void(0);" (click)="ratingsort()" class="sorticon mr-2">
                            <img src="assets/images/svg/sort.svg">
                        </a>
                        <a href="javascript:void(0);" (click)="tglclass()" class="filtericon">
                            <img src="assets/images/svg/filter.svg">
                        </a>
                    </div>
                    <div class="sortselect" [ngClass]="ratingst ? 'showsort' : ''">
                        <div class="fltrshead srthead">
                            <h4>Sort By</h4>
                            <a (click)="ratingsort()" class="closefilter" href="javascript:void(0);"><svg
                                    _ngcontent-serverApp-c132="" xmlns="http://www.w3.org/2000/svg" width="6.508"
                                    height="6.508" viewBox="0 0 6.508 6.508">
                                    <path _ngcontent-serverApp-c132="" id="np_cross_1707162_000000"
                                        d="M30.883,30.883a.507.507,0,0,1,.717,0l2.389,2.389,2.389-2.389a.507.507,0,0,1,.717.717L34.7,33.988l2.389,2.389a.507.507,0,0,1-.717.717L33.988,34.7,31.6,37.094a.507.507,0,0,1-.717-.717l2.389-2.389L30.883,31.6A.507.507,0,0,1,30.883,30.883Z"
                                        transform="translate(-30.734 -30.734)" fill="#1f1d44"></path>
                                </svg></a>
                        </div>
                        <select class="form-control" [(ngModel)]="sort_by" (ngModelChange)="changesorting()">
                            <option *ngFor="let rec of sorting_order" value="{{ rec.value }}">{{ rec.label }}</option>
                        </select>
                    </div>

                </div>
            </div>
            <!-- /SORTING  -->
            <!-- PRODUCT ITEMS -->
            <div class="prdt-rw" *ngIf="search_products.length > 0">
                <div class="row p-list-item">
                    <!-- REPEAT -->
                    <div class="col-6 col-md-4 col-xl-3  mb-3" *ngFor="let product of search_products; let i = index;">
                        <div class="product-item  pt-2" [routerLink]="['/product/details/'+product.unique_id]">
                            <div class="ex-deal" style="display:block" *ngIf="product.total_bid > 0"><span>{{
                                product.total_bid }} {{ product.total_bid > 1 ?'Bids': 'Bid' }}</span></div>
                            <div class='image pt-0'>
                                <div class="d-table">
                                    <div class="d-table-cell align-middle">
                                        <img [src]='product.p_front_image' title="{{ product.name }}">
                                    </div>
                                </div>
                            </div>
                            <div class="product-name fs14">
                                <span title="{{ product.name }}">{{ product.name }}</span>
                            </div>
                            <div class="ratings">
                                <ngb-rating [max]="5" [(rate)]="product.star_rating" [readonly]="true" class="rating_star"></ngb-rating>
                                <span class='star-count'>({{product.total_rating}})</span>
                            </div>
                            <div class="row no-gutters d-flex justify-content-between">
                                <div class="col-5">
                                    <div class="seller-count">
                                        Total Listing <br /> <span>{{ product.total_seller }} Sellers</span>
                                    </div>
                                </div>
                                <div class="col-7">
                                    <div class="price">
                                        Starting at <br /><span><i class="fa fa-inr"></i> {{ product.sales_price }} <b
                                                class="strikethrough">{{ product.regular_price }}</b></span>
                                    </div>
                                </div>
                                <div class="initiate-bid" *ngIf="product.bulk">
                                    <a href="javascript:void(0);" (click)="initiatebid(product.unique_id)" class="btn-d-outline mr-2 d-inline-block place-bid">Initiate Bid</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /REPEAT -->
                </div>
            </div>

            <div class="prdt-rw" *ngIf="!search_products.length">
                <div class="row pt-40 pb-40">
                    <div class="col-md-12 text-center">
                        <div class="mb-20"><img src="/assets/images/empty-state.png"></div>
                        <div class="fs16">There are no records to display!</div>
                    </div>
                </div>
            </div>
            <!-- /PRODUCT ITEMS -->
            <div class="row" *ngIf="search_pagination.totalPages> 1">
                <div class="col-md-6">
                    <div class="text-left pl-3">
                        <nav class="mt-3 tbpg rows-per-page">
                            <div>Rows per page:</div>
                            <select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
                                <option *ngFor="let option of page_per_view_options" [ngValue]="option.value">
                                    {{option.name}}</option>
                            </select>
                        </nav>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text-right pr-3">
                        <nav class="mt-3 tbpg">
                            <ul class="pagination align-items-center">
                                <li class="page-item" [ngClass]="{disabled:search_pagination.currentPage === 1}"><a
                                        class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a></li>

                                <li class="page-item" [ngClass]="{disabled:search_pagination.currentPage === 1}"><a
                                        class="page-link sprevicon" href="javascript:;"
                                        (click)="setpage(search_pagination.currentPage - 1)"></a></li>

                                <li class="page-item" i *ngFor="let page of search_pagination.pages"
                                    [ngClass]="{active:search_pagination.currentPage === page}"><a class="page-link"
                                        href="javascript:;" (click)="setpage(page)">{{page}}</a></li>

                                <li *ngIf="(search_pagination.totalPages - search_pagination.startPage) > 5 ">
                                    <a (click)="setpage(search_pagination.startPage + 5)">...</a>
                                </li>
                                <li *ngIf="(search_pagination.totalPages - search_pagination.startPage) > 5">
                                    <a
                                        (click)="setpage(search_pagination.totalPages)">{{search_pagination.totalPages}}</a>
                                </li>

                                <li class="page-item"
                                    [ngClass]="{disabled:search_pagination.currentPage === search_pagination.totalPages}">
                                    <a class="page-link snexticon" href="javascript:;"
                                        (click)="setpage(search_pagination.currentPage + 1)"></a>
                                </li>
                                <li class="page-item"
                                    [ngClass]="{disabled:search_pagination.currentPage === search_pagination.totalPages}">
                                    <a class="page-link nexticon" href="javascript:;"
                                        (click)="setpage(search_pagination.totalPages)"></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <!-- BANNER CROUSEL -->
            <div class="bnrcrslwp py-4">
                <ngb-carousel>
                    <ng-template ngbSlide>
                        <div class="bnr-img-wrapper">
                            <a href="javascript:void(0);">
                                <img src="assets/images/categories/banner-1-min.png" alt="mobile banner">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template ngbSlide>
                        <div class="bnr-img-wrapper">
                            <a href="javascript:void(0);">
                                <img src="assets/images/categories/banner-2-min.png" alt="mobile banner">
                            </a>
                        </div>
                    </ng-template>
                    <ng-template ngbSlide>
                        <div class="bnr-img-wrapper">
                            <a href="javascript:void(0);">
                                <img src="assets/images/categories/banner-3-min.png" alt="mobile banner">
                            </a>
                        </div>
                    </ng-template>
                </ngb-carousel>
            </div>
            <!-- /BANNER CROUSEL -->

            <!-- SIMILAR ITEMS -->
            <div class="prdt-rw" *ngIf="fast_selling_products.length > 0">
                <h4 class="txtdefault wt700 fs16 mb-3">Similar Items Related to Your Search</h4>
                <div class="row p-list-item">
                    <!-- REPEAT -->
                    <div class="col-6 col-md-4 col-xl-3  mb-3"
                        *ngFor="let product of fast_selling_products; let i = index;">
                        <div class="product-item  pt-2">
                            <div class="ex-deal" style="display:block" *ngIf="product.total_bid > 0"><span>{{
                                product.total_bid }} {{ product.total_bid > 1 ?'Bids': 'Bid' }}</span></div>
                            <div class='image pt-0'>
                                <div class="d-table">
                                    <div class="d-table-cell align-middle">
                                        <img [src]='product.p_front_image'>
                                    </div>
                                </div>
                            </div>
                            <div class="product-name fs14">
                                {{ product.brand_name }}&nbsp;{{ product.model_number }} ({{ product.variant_names}})
                            </div>
                            <div class="ratings">
                                <ngb-rating [max]="5" [(rate)]="product.star_rating" [readonly]="true" class="rating_star"></ngb-rating>
                                <span class='star-count'>({{product.total_rating}})</span>
                            </div>
                            <div class="row no-gutters d-flex justify-content-between">
                                <div class="col-5">
                                    <div class="seller-count">
                                        Total Listing <br /> <span>{{ product.total_seller }} Sellers</span>
                                    </div>
                                </div>
                                <div class="col-7">
                                    <div class="price">
                                        Starting at <br /><span><i class="fa fa-inr"></i> {{ product.sales_price }} <b
                                                class="strikethrough">{{ product.regular_price }}</b></span>
                                    </div>
                                </div>
                                <div class="initiate-bid" *ngIf="product.bulk">
                                    <a href="javascript:void(0);" (click)="initiatebid(product.unique_id)" class="btn-d-outline mr-2 d-inline-block place-bid">Initiate Bid</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /REPEAT -->
                </div>
            </div>
            <!-- /SIMILAR ITEMS -->

            <div class="pb-3" *ngIf="brands_list.length > 0">
                <h4 class="txtdefault f-700 fs16 mb-3">Brands Related to Your Search</h4>
                <div class="tpbrand my-4 px-4">
                    <div class="row align-items-center text-center">
                        <!-- REPEAT -->
                        <div class="col-md-2" *ngFor="let brand of brands_list; let i = index;">
                            <a href="javascript:void(0);" class="active" (click)="createparams('brand', brand)">
                                {{ brand.brand_name }}
                            </a>
                        </div>
                        <!-- /REPEAT -->

                    </div>
                </div>
            </div>
        </div>
        <!-- /LISTING -->
    </div>
</div>

<app-footer-one></app-footer-one>