import { Component, OnInit } from '@angular/core';
import { ToasterService } from '../../../helpers/toastr.service';
import { EncrDecrService } from '../../../helpers/EncrDecrService';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { BuyerCategoryCmsService } from '../buyer-category/buyer-category-cms.service';
import { CmsBuyerHomeService } from '../buyer-home/buyer-home.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-buyer-category-edit',
  templateUrl: './buyer-category-edit.component.html',
  styleUrls: ['./buyer-category-edit.component.scss']
})
export class BuyerCategoryEditComponent implements OnInit {

    dropdownList = [];
    selectedItems = [];
    dropdownSettings = {};
    
    public dynamic_section:any = [];
    public dynamic_section_name:string = '';
    public dynamic_section_status:string = 'yes';
    public dynamic_section_banner:any = {};
    public dynamic_section_banner_path:string = '';

    public fast_selling_records:any = [];
    public fast_selling_name:string = '';
    public fast_selling_banner:any = {};
    public fast_selling_banner_path:string = '';
    // public fast_selling_status:string = 'yes';

    public top_selling_products_records:any = [];
    public top_selling_name:string = '';
    public top_selling_banner:any = {};
    public top_selling_banner_path:string = '';
    // public top_selling_status:string = 'yes';

    public latest_arrival:any = [];
    public latest_arrival_name:string = '';
    public latest_arrival_banner:any = {};
    public latest_arrival_banner_path:string = '';
    // public latest_arrival_status:string = 'yes';

    
    public type:string;
    public cat_type:string;

    public top_selling_categories:any = [];
    public deals_categories_records:any = [];

    public buttonLoading:boolean = false;
  closeResult = '';
  public brand_logo_name: string;
  public brand_logo_image: any;
  public brand_logo: string = '';
  public countryArray = [];
  public categories = [];
  public brand_logo_status: boolean = false;
  public category_name:string = '';
  constructor(
      private modalService: NgbModal,
      private _toastr: ToasterService,
      private _cmsbuyerhomeservice: CmsBuyerHomeService,
      private _pageservice: BuyerCategoryCmsService,
      private _EncrDecr: EncrDecrService,
      private fb: FormBuilder,
      private route: ActivatedRoute
  ) { }

  public category_id:string = '';

    ngOnInit(): void {
        this.category_id = this.route.snapshot.paramMap.get('id');
        this.dropdownList = [];
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'value',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 100,
            allowSearchFilter: true
        };

        this.getcmsdata();
    }
  config = {
      labelField: 'name',
      valueField: 'value',

  };



  onItemSelect(item: any) {
  }
  onSelectAll(items: any) {
  }

  onItemDeSelect($event) {
  }

    getcmsdata() {
        this._pageservice.getcategorycmsdata({category_id: this.category_id}).subscribe((data) => {
            if (data['status'] == "success") {
                this.fast_selling_records = data['fast_selling'];
                this.fast_selling_name = data['fast_selling_name'];
                this.fast_selling_banner_path = data['fast_seller_banner'];
                //this.fast_selling_status = data['fast_selling_status'];

                this.top_selling_products_records = data['top_selling'];
                this.top_selling_name = data['top_selling_name'];
                this.top_selling_banner_path = data['top_selling_banner'];
                //this.top_selling_status = data['top_selling_status'];

                this.latest_arrival = data['latest_arrival'];
                this.latest_arrival_name = data['latest_arrival_name'];
                this.latest_arrival_banner_path = data['latest_arrival_banner'];
                //this.latest_arrival_status = data['latest_arrival_status'];

                this.dynamic_section = data['dynamic_section_records'];
                this.dynamic_section_name = data['section_name'];
                this.dynamic_section_status = data['section_status'];
                this.dynamic_section_banner_path = data['section_banner'];
                this.category_name = data['category_name'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

  savecategoryimage(event, cat){
      const file = event.target.files[0];
      cat.image = file;
      cat.image_name = file.name;
  }

  uploadfile(event) {
      const file = event.target.files[0];
      this.brand_logo = file;
      this.brand_logo_status = true;
      this.brand_logo_name = file.name;
      const reader = new FileReader();
      reader.onload = e => this.brand_logo_image = reader.result;
      reader.readAsDataURL(file);
  }


  openproductsearch(productsearch, type) {
      this.type = type;
      this.modalService.open(productsearch, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

    opencategorysearch(categorysearch, type) {
        this.cat_type = type;
        this.modalService.open(categorysearch, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return `with: ${reason}`;
      }
  }

    removerecords(index,type) {
        if(type == 'fast_selling'){
            this.fast_selling_records.splice(index, 1);
        }else if(type == 'top_selling'){
            this.top_selling_products_records.splice(index, 1);
        }else if(type == 'latest_arrival'){
            this.latest_arrival.splice(index, 1);
        }else if(type == 'dynamic_section'){
            this.dynamic_section.splice(index, 1);
        }
        
    }

    cmsupdate(){
        var data = {
            category_id: this.category_id,

            fast_selling: this.fast_selling_records,
            fast_selling_name: this.fast_selling_name,
            //fast_selling_status: this.fast_selling_status,

            top_selling: this.top_selling_products_records,
            top_selling_name: this.top_selling_name,
            //top_selling_status: this.top_selling_status,

            latest_arrival: this.latest_arrival,
            latest_arrival_name: this.latest_arrival_name,
            //latest_arrival_status: this.latest_arrival_status,

            section_value: this.dynamic_section,
            section_name: this.dynamic_section_name,
            section_status: this.dynamic_section_status
        }
        this._pageservice.savecategoryproductcms(data).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('CMS details has been updated successfully');
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

  keyword = 'name'
  result = [];
  category_result = [];

    selectEvent(item) {
        if(this.type == 'fast_selling'){
            this.fast_selling_records.push({id: item.product_id, name: item.name});
        }else if(this.type == 'top_selling'){
            this.top_selling_products_records.push({id: item.product_id, name: item.name});
        }else if(this.type == 'latest_arrival'){
            this.latest_arrival.push({id: item.product_id, name: item.name});
        }else if(this.type == 'dynamic_section'){
            this.dynamic_section.push({id: item.product_id, name: item.name});
        }
        this.result = [];
    }

  onChangeSearch(val: string) {
      this._pageservice.searchcategoryproduct({ category_id: this.category_id, search: val }).subscribe((data) => {
          if (data['status'] == "success") {
              this.result = data['records'];
          } else if (data['status'] == "error") {
              this._toastr.error(data['message']);
          }
      });
  }

  onFocused(e) {
      // do something when input is focused
  }

    conFocused(e) {
    // do something when input is focused
    }

    uploadBanner(event, type) {
        const file = event.target.files[0];
        let data: any = new FormData();
        data.append("document", file);
        data.append("category_id", this.category_id);
        data.append("type", type);

        this._pageservice.uploadcategorybanner(data).subscribe((data) => {
            if (data['status'] == "success") {
                if(type == 'latest_arrival_banner'){
                    this.latest_arrival_banner_path = data['url'];
                }else if(type == 'top_selling_banner'){
                    this.top_selling_banner_path = data['url'];
                }else if(type == 'fast_selling_banner'){
                    this.fast_selling_banner_path = data['url'];
                }else if(type == 'dynamic_section_banner'){
                    this.dynamic_section_banner_path = data['url'];
                }
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

}
