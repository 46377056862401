import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BuyerAssistanceService {

    constructor(private _http: HttpClient) { }

    getassistances(data){
        return this._http.post(environment.api_url+'assistance',data);
    }

    searchassistance(data){
      return this._http.post(environment.api_url+'assistance/search',data);
  }

}
