<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain pb-4">
    <div class="container">
        <!--- HEAD  --->
        <div class="row align-items-center pinfo-head">
            <div class="col-md-6 mb-4">
                <h3 class="fs24 wt700 txtdefault">Countries</h3>
                <nav class="breadcrumb breadcrumb-dui mb-0">
                    <ol class="breadcrumb p-0 bg-transparent  mb-0">
                        <li class="breadcrumb-item"><a class="txtseconday">Setting </a></li>
                        <li class="breadcrumb-item active">Countries</li>
                    </ol>
                </nav>
            </div>
            <div class="col-md-6  mb-4 text-md-right text-center">
                <a (click)="open(addcountry)" href="javascript:;" class="mr-sm-4 mr-2 btn-d-outline d-inline-block"><svg
                        xmlns="http://www.w3.org/2000/svg" width="14.681" height="14.681" viewBox="0 0 14.681 14.681"
                        class="plus">
                        <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1 1)">
                            <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181" transform="translate(-11.659 -7.5)"
                                fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="2" />
                            <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181" transform="translate(-7.5 -11.659)"
                                fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="2" />
                        </g>
                    </svg>
                    Add New Country
                </a>
                <a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Assistance</a>
            </div>
        </div>
        <!--- /HEAD  --->
        <div class="card cardui carduipro p-4 mb-0">
            <div class="notifications-container theme-tab">

                <div class="tab-content">
                    <!--Filters-->
                    <div class="row d-flex justify-content-between mt-0  report-cta">
                        <div class="col-sm-5 mb-3">
                            <div class="report-input">
                                <div class="input-group-custom">
                                    <input type="text" placeholder="Search by Name" class="form-control" [(ngModel)]="search_term" (change)="countrylist()">
                                    <div class="input-group-append-custom">
                                        <img src="assets/images/svg/search_logo.svg">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--End Filters-->
                    <div class="table-responsive brndmgnttable" *ngIf="records.length > 0">
                        <table class="table custom-table xs">
                            <thead>
                                <tr>
                                    <th width="18%">Country Name</th>
                                    <th width="18%">Country Code</th>
                                    <th>Phone Code</th>
                                    <th width="10%" class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- TR -->
                                <tr *ngFor="let record of records; let i = index;">
                                    <td>{{ record.name }}</td>
                                    <td>{{ record.countrycode }}</td>
                                    <td>{{ record.phone_code }}</td>
                                    <td class="d-flex align-items-center">
                                        <a  (click)="openupdate(updatecountry, record)" href="javascript:;" class="place-bid">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" width="14" height="14"
                                                viewBox="0 0 7.984 7.984">
                                                <defs>
                                                    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1"
                                                        gradientUnits="objectBoundingBox">
                                                        <stop offset="0" stop-color="#79b7ff" />
                                                        <stop offset="1" stop-color="#07f" />
                                                    </linearGradient>
                                                </defs>
                                                <path id="np_edit_3094244_000000"
                                                    d="M6.485-.008A1.491,1.491,0,0,0,5.428.43L.41,5.447,2.527,7.563,7.544,2.546A1.5,1.5,0,0,0,6.485-.008ZM.151,5.893,0,7.706a.249.249,0,0,0,.269.269l1.812-.154Z"
                                                    transform="translate(0.003 0.008)" fill="url(#linear-gradient)" />
                                            </svg>
                                        </a>
                                    </td>
                                </tr>
                                <!-- /TR -->
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>


	<!-- ADD COUNTRY DETAILS -->
<ng-template #addcountry let-modal>
    <div class="modal-body text-center mdlcmnui pt-4 mt-3">
       <h5 class="modal-title mb-10 ">Add Country Details</h5>
           <div class="bid-order-info d-flex align-items-center">
             <div class="order-img">
                 <img src="assets/images/icons/info.png" />
             </div>
             <div class="pd-seller-cntnt">
                 <div class="fs12">The change will occur across all the buyers and sellers.</div>
             </div>
         </div>
           <div class="form-container mdl-frm-lbl mb-1">
             <div class="row">
					<div class="col-md-12">
						<div class="floating-label mb-4">
						<input name="name" [(ngModel)]="name" type="text" class="form-control" placeholder=" " />
						<span class="highlight"></span>
						<label>Country Name</label>
						</div> 
					</div>

					<div class="col-md-12">
						<div class="floating-label mb-4">      
						<input name="country_code" [(ngModel)]="country_code" type="text" class="form-control" placeholder=" " />
						<span class="highlight"></span>
						<label>Country Code</label>
						</div> 
					</div>

					<div class="col-md-12">
						<div class="floating-label mb-4">      
						<input name="phone_code" [(ngModel)]="phone_code" type="text" class="form-control" placeholder=" " />
						<span class="highlight"></span>
						<label>Phone Code</label>
						</div> 
					</div>
             </div>
         </div>
           <div class="text-center nobd mx-auto pb-4 mb-3">
             <button type="button" class="btn-d-outline d-inline-block cancel-outline mr-3" (click)="modal.close('Close click')">Cancel</button>
             <button type="button" class="btn buybtn fs16 radius10" (click)="addcountries()">Save Changes</button>
           </div>
    </div>
 </ng-template>
 <!-- ADD COUNTRY DETAILS -->

<!-- UPDATE COUNTRY DETAILS -->
<ng-template #updatecountry let-modal>
   <div class="modal-body text-center mdlcmnui pt-4 mt-3">
      <h5 class="modal-title mb-10 ">Edit Country Details</h5>
      	<div class="bid-order-info d-flex align-items-center">
			<div class="order-img">
				<img src="assets/images/icons/info.png" />
			</div>
			<div class="pd-seller-cntnt">
				<div class="fs12">The change will occur across all the buyers and sellers.</div>
			</div>
		</div>
      	<div class="form-container mdl-frm-lbl mb-1">
			<div class="row">
				<div class="col-md-12">
					<div class="floating-label mb-4">
					<input name="edit_name" [(ngModel)]="edit_name" type="text" class="form-control" placeholder=" " />
					<span class="highlight"></span>
					<label>Country Name</label>
					</div> 
				</div>

				<div class="col-md-12">
					<div class="floating-label mb-4">      
					<input name="edit_country_code" [(ngModel)]="edit_country_code" type="text" class="form-control" placeholder=" " />
					<span class="highlight"></span>
					<label>Country Code</label>
					</div> 
				</div>

				<div class="col-md-12">
					<div class="floating-label mb-4">      
					<input name="edit_phone_code" [(ngModel)]="edit_phone_code" type="text" class="form-control" placeholder=" " />
					<span class="highlight"></span>
					<label>Phone Code</label>
					</div> 
				</div>
			</div>
		</div>
      	<div class="text-center nobd mx-auto pb-4 mb-3">
		    <button type="button" class="btn-d-outline d-inline-block cancel-outline mr-3" (click)="modal.close('Close click')">Cancel</button>
			<button type="button" class="btn buybtn fs16 radius10" (click)="updatecountries()">Update Changes</button>
		  </div>
   </div>
</ng-template>
<!-- UPDATE COUNTRY DETAILS -->