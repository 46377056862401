<app-header-seller></app-header-seller>
<app-sidebar-seller></app-sidebar-seller>
<div class="wrpmain">
    <div class="container">
        <!--- HEAD  --->
        <div class="row align-items-center mb-4 pinfo-head">
            <div class="col-sm-6 col-7 pr-0">
                <h3 class="fs24 wt700 txtdefault">Track My Orders</h3>
                <nav class="breadcrumb breadcrumb-dui mb-0">
                    <ol class="breadcrumb p-0 bg-transparent mb-0">
                        <li class="breadcrumb-item">
                            <a class="txtseconday" href="javascript:void(0);"
                                >Orders</a
                            >
                        </li>
                        <li class="breadcrumb-item active">Track My Orders</li>
                    </ol>
                </nav>
            </div>
            <div class="col-sm-6 col-5 text-right pl-0">
                <a
                    href="javascript:void(0);"
                    class="btn buybtn fs16 radius10 ml-3"
                    [routerLink]="['/seller/assistance']"
                    >Assistance</a
                >
            </div>
        </div>
        <!--- /HEAD  --->

        <!-- REPEAT -->
        <div
            class="card card-ui mb-3"
            *ngFor="let order of orders; let i = index"
        >
            <div class="cs-item-row pt-xs-0">
                <div class="row">
                    <div class="col-lg-8 pr-0">
                        <div class="d-table mb-4 w-100">
                            <div class="d-table-cell cart-thumb align-middle">
                                <img src="{{ order.product_image }}" />
                            </div>
                            <div class="d-table-cell pl-3 align-middle">
                                <p class="txtdefault wt700 mb-0 fs14">
                                    {{ order.produt_name }}
                                    <span class="badge-d-outline odbdge fs10">{{
                                        order.map_type
                                    }}</span>
                                    <span *ngIf="order.spice_money == 'yes'" class="badge-d-outline odbdge fs10">{{
                                        "Spice Money"
                                    }}</span>
                                </p>
                                <p class="txtseconday my-1 fs14">
                                    <span class="txtdefault wt500"
                                        >Order ID:
                                    </span>
                                    {{ order.order_id }}
                                </p>
                                <p class="txtseconday my-1 fs14">
                                    <span class="txtdefault wt500"
                                        >Transaction ID:
                                    </span>
                                    {{ order.transaction_id }}
                                </p>
                                <button class="mr-sm-4 btn-d-outline d-inline-block md-block mb-xs-10" [routerLink]="['/seller/view-order-details/'+order.id]">
									<svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107" viewBox="0 0 18.71 12.107">
										<g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
										  <path id="Path_2337" data-name="Path 2337" d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z" transform="translate(-14.582 -27.082)" fill="#ff7135"/>
										  <g id="Group_15718" data-name="Group 15718">
											<g id="Group_15717" data-name="Group 15717">
											  <g id="Group_15716" data-name="Group 15716">
												<path id="Path_2338" data-name="Path 2338" d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4" transform="translate(-36.046 -39.347)" fill="#ff7135"/>
											  </g>
											</g>
										  </g>
										</g>
									  </svg>
									View Order Details
								</button>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12 text-right md-text-left mb-xs-10">
                        <h4 class="fs14 txtdefault mb-2" *ngIf="order.approved_status == 0 || order.approved_status == 1"><b>Expected Delivery Date:</b> {{ order.expected_delivery_date }}</h4>
                        <h4 class="fs14 txtdefault mb-2" *ngIf="order.approved_status == 2"><b>Supplier Rejected:</b> {{ order.approved_status_date.date }}&nbsp;{{ order.approved_status_date.time }}</h4>
                        <button  *ngIf="order.delivered_status == 1 && order.return_request" (click)="openWindowReturnRequest(returnrequest, order)"  class="btn btn-xs btn-d-outline d-inline-block md-block mb-xs-10 place-bid">
							View Return Request
						</button>
					</div>
                </div>
                <!-- TRACK BAR -->
                <div class="trackbar mt-xs-10 {{ order.approved_status == 2|| order.cancel_status == 1?'progresssteps-cancelled':'progresssteps'+order.current_stage }}">
                    <div class="row text-center table-card">
                        <!-- COL -->
                        <div class="col trckstep1 prgrstrckstep1">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Booked</h4>
                            <p class="fs12 txtseconday" *ngIf="order.booked_status">
                                {{ order.booked_status_date.date}}<br />{{ order.booked_status_date.time }}
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep2 prgrstrckstep2" *ngIf="order.approved_status !=0">
                            <span class="icontick"></span>

							<h4 class="fs12 txtdefault wt700"  *ngIf="order.approved_status == 1 && order.cancel_status == 0">Supplier Approved</h4>
							<h4 class="fs12 txtdefault wt700"  *ngIf="order.approved_status == 2 && order.cancel_status == 1">Supplier Rejected</h4>
                            <h4 class="fs12 txtdefault wt700"  *ngIf="order.approved_status == 1 && order.cancel_status == 1">Buyer Cancelled</h4>
							<p class="fs12 txtseconday" *ngIf="(order.approved_status == 1 || order.approved_status == 2) && order.cancel_status == 0">{{ order.approved_status_date.date }}<br>{{ order.approved_status_date.time }}</p>
                            <p class="fs12 txtseconday" *ngIf="order.approved_status == 1 && order.cancel_status == 1">{{ order.cancel_status_date.date }}<br>{{ order.cancel_status_date.time }}</p>
                        </div>

                        <div class="col trckstep2 prgrstrckstep2" *ngIf="order.approved_status ==0">
                            <span class="icontick"></span>
							<h4 class="fs12 txtdefault wt700"  *ngIf="order.approved_status == 0 && order.cancel_status == 0">Pending for <br/>Approval</h4>
							<h4 class="fs12 txtdefault wt700"  *ngIf="order.approved_status == 0 && order.cancel_status == 1">Cancelled</h4>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep3 prgrstrckstep3" *ngIf="order.approved_status != 2">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700"> Payment Status </h4>
                            <p class="fs12 txtseconday" *ngIf="order.payment_status == 1 && order.cancel_status == 0">
                                {{ order.payment_status_date.date }}<br>{{ order.payment_status_date.time }}
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep4 prgrstrckstep4">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Stock Status</h4>
                            <p class="fs12 txtseconday" *ngIf="order.stock_status == 1 && order.cancel_status == 0">
                                {{ order.stock_status_date.date }}<br>{{ order.stock_status_date.time }}
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep5 prgrstrckstep5">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Quality Check</h4>
                            <p class="fs12 txtseconday">
                                {{ order.quality_status_date.date }}<br />{{ order.quality_status_date.time }}
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep6 prgrstrckstep6">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Dispatched</h4>
                            <p class="fs12 txtseconday">
                                {{ order.dispatched_status_date.date }}<br>{{ order.dispatched_status_date.time }}
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep7 prgrstrckstep7">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Delivered</h4>
                            <p class="fs12 txtseconday">
                                {{ order.delivered_status_date.date }}<br>{{ order.delivered_status_date.time }}
                            </p>
                        </div>
                        <!-- /COL -->
                    </div>
                    <div class="row align-items-center" *ngIf="order.approved_status == 0 && order.cancel_status == 0">
                        <div class="col-sm-2">&nbsp;</div>
                        <div class="col-sm-3">
                            <a
                                href="javascript:void(0);"
                                class="btn-d-outline-danger ripple d-inline-block place-bid2 mr-2"
                                (click)="rejectorder(order)"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17.366"
                                    height="17.366"
                                    viewBox="0 0 17.366 17.366"
                                >
                                    <path
                                        id="np_cross_4440767_000000"
                                        d="M27.176,25.335l6.444-6.444a1.3,1.3,0,1,0-1.841-1.841l-6.444,6.444L18.891,17.05a1.3,1.3,0,0,0-1.841,1.841l6.444,6.444L17.05,31.78a1.3,1.3,0,1,0,1.841,1.841l6.444-6.444,6.444,6.444A1.3,1.3,0,0,0,33.62,31.78Z"
                                        transform="translate(-16.652 -16.652)"
                                        fill="#be1e1e"
                                    />
                                </svg>
                            </a>
                            <a
                                href="javascript:void(0);"
                                class="btn-d-outline-success ripple d-inline-block place-bid2"
                                (click)="acceptorder(order)"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24.175"
                                    height="16.822"
                                    viewBox="0 0 24.175 16.822"
                                >
                                    <path
                                        id="np_tick_1446951_000000"
                                        d="M24.933,43.667a1.473,1.473,0,0,0,2.1,0L40.9,29.762a1.476,1.476,0,0,0-2.066-2.1L25.967,40.532l-6.308-6.307a1.486,1.486,0,1,0-2.1,2.1Z"
                                        transform="translate(-17.121 -27.286)"
                                        fill="#35cb4e"
                                    />
                                </svg>
                                {{ order.time_left }} to Approve
                            </a>
                        </div>
                    </div>

                    <div class="row justify-content-center" *ngIf="order.payment_status == 1 && order.quality_status == 0 && order.cancel_status == 0">
                        <div class="col-md-4 text-center">
                            <a
                                class="btn-d-outline d-inline-block"
                                href="javascript:void(0);"
                                (click)="readytodispatch(order)"
                            >
                                Ready to Dispatch
                            </a>
                        </div>
                    </div>

                    <div class="row" *ngIf="order.superseller_invoice_status && order.is_super_seller == 'yes' && order.is_super_seller_user == 'yes'">
                        <div class="col-md-3"></div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-3 text-center" *ngIf="order.superseller_invoice_status">
                                    <div class="mt-10">
                                        <a
                                            href="{{ order.superseller_invoice_path }}"
                                            target="_blank"
                                            class="d-flex align-items-center link-alt fs14"
                                            ><img
                                                src="/assets/images/icons/pdf.svg"
                                                class="mr-2"
                                                style="max-width: 18px" />
                                            Order Invoice
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-3 text-center"*ngIf="order.invoice_status">
                                    <div class="mt-10">
                                        <a
                                            href="{{ order.invoice_path }}"
                                            target="_blank"
                                            class="d-flex align-items-center link-alt fs14"
                                            ><img
                                                src="/assets/images/icons/pdf.svg"
                                                class="mr-2"
                                                style="max-width: 18px" />
                                            Buyer Invoice
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-3 text-center"*ngIf="order.superseller_eway_bill_status && order.superseller_eway_bill_path">
                                    <div class="mt-10">
                                        <a
                                            href="{{ order.superseller_eway_bill_path }}"
                                            target="_blank"
                                            class="d-flex align-items-center link-alt fs14"
                                            ><img
                                                src="/assets/images/icons/pdf.svg"
                                                class="mr-2"
                                                style="max-width: 18px" />
                                            E-way Bill
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-3 text-center" *ngIf="order.shipping_label_status">
                                    <div class="mt-10">
                                        <a
                                            href="{{ order.shipping_label_path }}"
                                            target="_blank"
                                            class="d-flex align-items-center link-alt fs14"
                                            ><img
                                                src="/assets/images/icons/pdf.svg"
                                                class="mr-2"
                                                style="max-width: 18px" />
                                                Shipping Label
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-3 text-center" *ngIf="order.manifest_status">
                                    <div class="mt-10">
                                        <a
                                            href="{{ order.manifest_path }}"
                                            target="_blank"
                                            class="d-flex align-items-center link-alt fs14"
                                            ><img
                                                src="/assets/images/icons/pdf.svg"
                                                class="mr-2"
                                                style="max-width: 18px" />
                                                Manifest
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="order.is_super_seller == 'yes' && order.is_subseller == 'yes'">
                        <div class="col-md-3"></div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-3 text-center" *ngIf="order.imei_status && order.imei_path">
                                    <div class="mt-10">
                                        <a
                                            href="{{ order.imei_path }}"
                                            target="_blank"
                                            class="d-flex align-items-center link-alt fs14"
                                            ><img
                                                src="/assets/images/icons/pdf.svg"
                                                class="mr-2"
                                                style="max-width: 18px" />
                                            IMEI Bill
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-3 text-center" *ngIf="order.superseller_invoice_status && order.superseller_invoice_path">
                                    <div class="mt-10">
                                        <a
                                            href="{{ order.superseller_invoice_path }}"
                                            target="_blank"
                                            class="d-flex align-items-center link-alt fs14"
                                            ><img
                                                src="/assets/images/icons/pdf.svg"
                                                class="mr-2"
                                                style="max-width: 18px" />
                                            Invoice
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-3 text-center" *ngIf="order.shipping_label_status">
                                    <div class="mt-10">
                                        <a
                                            href="{{ order.shipping_label_path }}"
                                            target="_blank"
                                            class="d-flex align-items-center link-alt fs14"
                                            ><img
                                                src="/assets/images/icons/pdf.svg"
                                                class="mr-2"
                                                style="max-width: 18px" />
                                            Shipping Label
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-3 text-center" *ngIf="order.manifest_status">
                                    <div class="mt-10">
                                        <a
                                            href="{{ order.manifest_path }}"
                                            target="_blank"
                                            class="d-flex align-items-center link-alt fs14"
                                            ><img
                                                src="/assets/images/icons/pdf.svg"
                                                class="mr-2"
                                                style="max-width: 18px" />
                                            Manifest
                                        </a>
                                    </div>
                                </div>

                                <div class="col-md-3 text-center" *ngIf="order.admin_seller_charge_invoice_path">
                                    <div class="mt-10">
                                        <a
                                            href="{{ order.admin_seller_charge_invoice_path }}"
                                            target="_blank"
                                            class="d-flex align-items-center link-alt fs14"
                                            ><img
                                                src="/assets/images/icons/pdf.svg"
                                                class="mr-2"
                                                style="max-width: 18px" />
                                            Charge Invoice
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="order.is_super_seller == 'no'">
                        <div class="col-md-3"></div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-3 text-center" *ngIf="order.imei_status && order.imei_path">
                                    <div class="mt-10">
                                        <a
                                            href="{{ order.imei_path }}"
                                            target="_blank"
                                            class="d-flex align-items-center link-alt fs14"
                                            ><img
                                                src="/assets/images/icons/pdf.svg"
                                                class="mr-2"
                                                style="max-width: 18px" />
                                            IMEI Bill
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-3 text-center"  *ngIf="order.invoice_status && order.invoice_path">
                                    <div class="mt-10">
                                        <a
                                            href="{{ order.invoice_path }}"
                                            target="_blank"
                                            class="d-flex align-items-center link-alt fs14"
                                            ><img
                                                src="/assets/images/icons/pdf.svg"
                                                class="mr-2"
                                                style="max-width: 18px" />
                                            Invoice
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-3 text-center" *ngIf="order.shipping_label_status">
                                    <div class="mt-10">
                                        <a
                                            href="{{ order.shipping_label_path }}"
                                            target="_blank"
                                            class="d-flex align-items-center link-alt fs14"
                                            ><img
                                                src="/assets/images/icons/pdf.svg"
                                                class="mr-2"
                                                style="max-width: 18px" />
                                            Shipping Label
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-3 text-center" *ngIf="order.manifest_status">
                                    <div class="mt-10">
                                        <a
                                            href="{{ order.manifest_path }}"
                                            target="_blank"
                                            class="d-flex align-items-center link-alt fs14"
                                            ><img
                                                src="/assets/images/icons/pdf.svg"
                                                class="mr-2"
                                                style="max-width: 18px" />
                                            Manifest
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /TRACK BAR -->
            </div>
        </div>
        <!-- /REPEAT -->


        <div class="row mb-40" *ngIf="seller_order_pagination.totalPages> 1">
			<div class="col-md-6">
				<div class="text-left pl-3">
					<nav class="mt-3 tbpg rows-per-page">
						<div>Rows per page:</div>
						<select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
							<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">
								{{option.name}}</option>
						</select>
					</nav>
				</div>
			</div>
			<div class="col-md-6">
				<div class="text-right md-text-left pr-3">
					<nav class="mt-3 tbpg">
						<ul class="pagination align-items-center">
							<li class="page-item" [ngClass]="{disabled:seller_order_pagination.currentPage === 1}"><a
									class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a>
							</li>

							<li class="page-item" [ngClass]="{disabled:seller_order_pagination.currentPage === 1}"><a
									class="page-link sprevicon" href="javascript:;"
									(click)="setpage(seller_order_pagination.currentPage - 1)"></a></li>

							<li class="page-item" i *ngFor="let page of seller_order_pagination.pages"
								[ngClass]="{active:seller_order_pagination.currentPage === page}"><a class="page-link"
									href="javascript:;" (click)="setpage(page)">{{page}}</a></li>

							<li *ngIf="(seller_order_pagination.totalPages - seller_order_pagination.startPage) > 5 ">
								<a (click)="setpage(seller_order_pagination.startPage + 5)">...</a>
							</li>
							<li *ngIf="(seller_order_pagination.totalPages - seller_order_pagination.startPage) > 5">
								<a
									(click)="setpage(seller_order_pagination.totalPages)">{{seller_order_pagination.totalPages}}</a>
							</li>

							<li class="page-item"
								[ngClass]="{disabled:seller_order_pagination.currentPage === seller_order_pagination.totalPages}">
								<a class="page-link snexticon" href="javascript:;"
									(click)="setpage(seller_order_pagination.currentPage + 1)"></a>
							</li>
							<li class="page-item"
								[ngClass]="{disabled:seller_order_pagination.currentPage === seller_order_pagination.totalPages}">
								<a class="page-link nexticon" href="javascript:;"
									(click)="setpage(seller_order_pagination.totalPages)"></a>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</div>









        <!-- REPEAT -->
        <div class="card card-ui mb-3" *ngIf="show_sample_data">
            <div class="cs-item-row pt-xs-0">
                <div class="row">
                    <div class="col-lg-8 pr-0">
                        <div class="d-table mb-4 w-100">
                            <div class="d-table-cell cart-thumb align-middle">
                                <img src="assets/images/products/p-2.jpg" />
                            </div>
                            <div class="d-table-cell pl-3 align-middle">
                                <p class="txtdefault wt700 mb-0 fs14">
                                    OnePlus Nord 2 5G (Blue Haze, 8GB RAM,
                                    128GB)
                                    <span class="badge-d-outline odbdge fs10"
                                        >Bulk</span
                                    >
                                </p>
                                <p class="txtseconday my-1 fs14">
                                    <span class="txtdefault wt500"
                                        >Order ID:
                                    </span>
                                    EQB9876598754ABCD
                                </p>
                                <a
                                    href="javascript:void(0);"
                                    class="add-whislist-link"
                                    ><span>View Order Details</span></a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-6 text-right">
                        <h4 class="fs14 txtdefault mb-2 text-inactive">
                            Cancelled due to non-payment
                        </h4>
                        <p class="fs14 txtseconday mb-0">1st Sept 2021</p>
                    </div>
                </div>
                <!-- TRACK BAR -->
                <div class="trackbar progresssteps-cancelled">
                    <div class="row text-center">
                        <!-- COL -->
                        <div class="col trckstep1 prgrstrckstep1">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Booked</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep2 prgrstrckstep2">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Approved</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep3 prgrstrckstep3">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">
                                Payment Status
                            </h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                    </div>
                </div>
                <!-- /TRACK BAR -->
            </div>
        </div>
        <!-- /REPEAT -->
        <!-- REPEAT -->
        <div class="card card-ui mb-3" *ngIf="show_sample_data">
            <div class="cs-item-row pt-xs-0">
                <div class="row">
                    <div class="col-lg-8 pr-0">
                        <div class="d-table mb-4 w-100">
                            <div class="d-table-cell cart-thumb align-middle">
                                <img src="assets/images/products/p-2.jpg" />
                            </div>
                            <div class="d-table-cell pl-3 align-middle">
                                <p class="txtdefault wt700 mb-0 fs14">
                                    OnePlus Nord 2 5G (Blue Haze, 8GB RAM,
                                    128GB)
                                </p>
                                <p class="txtseconday my-1 fs14">
                                    <span class="txtdefault wt500"
                                        >Order ID:
                                    </span>
                                    EQB9876598754ABCD
                                </p>
                                <a
                                    href="javascript:void(0);"
                                    class="add-whislist-link"
                                    ><span>View Order Details</span></a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12 text-right md-text-left mb-xs-10">
                        <h4 class="fs14 txtdefault mb-2">
                            Expected Delivery Date:
                        </h4>
                        <p class="fs14 txtseconday mb-0">1st Sept 2021</p>
                    </div>
                </div>
                <!-- TRACK BAR -->
                <div class="trackbar mt-xs-10 progresssteps4">
                    <div class="row text-center table-card">
                        <!-- COL -->
                        <div class="col trckstep1 prgrstrckstep1">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Pre Booked</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep2 prgrstrckstep2">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Confirmed</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep3 prgrstrckstep3">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">
                                Payment Status
                            </h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep4 prgrstrckstep4">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Stock Status</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep5 prgrstrckstep5">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Quality Check</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep6 prgrstrckstep6">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Dispatched</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep7 prgrstrckstep7">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Delivered</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                    </div>
                    <div class="row align-items-center">
                        <div class="col-sm-3">
                            <p class="fs12 txtseconday mb-0">
                                <strong class="txtdefault">Reason:</strong>
                                Change in mood
                            </p>
                            <p class="fs10 txtseconday mb-0">
                                (Cancellation Request)
                            </p>
                        </div>
                        <div class="col-sm-3">
                            <a
                                class="btn-d-outline d-inline-block"
                                href="javascript:void(0);"
                            >
                                View Request
                            </a>
                        </div>
                    </div>
                </div>
                <!-- /TRACK BAR -->
                <!-- RETURN REQUEST -->
                <div class="rtrqst">
                    <div class="rtrqsthead">
                        <h4 class="txtdefault wt700 fs14">
                            Cancellation Request
                        </h4>
                        <p class="txtseconday fs14">
                            Request received on 24th Jan 2022, 11:15:25 AM
                        </p>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 pr-0">
                            <div class="d-table mb-4 w-100">
                                <div
                                    class="d-table-cell cart-thumb align-middle"
                                >
                                    <img src="assets/images/products/p-2.jpg" />
                                </div>
                                <div class="d-table-cell pl-3 align-middle">
                                    <p class="txtdefault wt700 mb-0 fs14">
                                        OnePlus Nord 2 5G (Blue Haze, 8GB RAM,
                                        128GB)
                                    </p>
                                    <p class="txtseconday my-1 fs14">
                                        <span class="txtdefault wt500"
                                            >Order ID:
                                        </span>
                                        EQB9876598754ABCD
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-container mb-0">
                        <div class="row mb-3 align-items-end">
                            <div class="col-xxl-6 col-lg-7">
                                <div class="floating-label">
                                    <input
                                        placeholder=" "
                                        class="form-control"
                                        value="Incomplete technical info"
                                    />
                                    <span class="highlight"></span>
                                    <label>Reason for Cancellation</label>
                                </div>
                                <div class="floating-label">
                                    <textarea
                                        placeholder=" "
                                        class="form-control txtareaht80"
                                    >
Multiple RAM variants missing</textarea
                                    >
                                    <span class="highlight"></span>
                                    <label>Message from Buyer</label>
                                </div>
                            </div>
                            <div class="col-xxl-6 col-lg-5 text-right">
                                <input
                                    type="submit"
                                    value="Accept"
                                    class="btn px-4 buybtn fs16 radius10 btn-success"
                                />
                                <a
                                    href="javascript:void(0);"
                                    class="btn-d-outline btn-danger-outline d-inline-block ml-sm-3 ml-2"
                                    >Reject</a
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /RETURN REQUEST -->
            </div>
        </div>
        <!-- /REPEAT -->
        <!-- REPEAT -->
        <div class="card card-ui mb-3" *ngIf="show_sample_data">
            <div class="cs-item-row pt-xs-0">
                <div class="row">
                    <div class="col-lg-8 pr-0">
                        <div class="d-table mb-4 w-100">
                            <div class="d-table-cell cart-thumb align-middle">
                                <img src="assets/images/products/p-2.jpg" />
                            </div>
                            <div class="d-table-cell pl-3 align-middle">
                                <p class="txtdefault wt700 mb-0 fs14">
                                    OnePlus Nord 2 5G (Blue Haze, 8GB RAM,
                                    128GB)
                                </p>
                                <p class="txtseconday my-1 fs14">
                                    <span class="txtdefault wt500"
                                        >Order ID:
                                    </span>
                                    EQB9876598754ABCD
                                </p>
                                <a
                                    href="javascript:void(0);"
                                    class="add-whislist-link"
                                    ><span>View Order Details</span></a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12 text-right md-text-left mb-xs-10">
                        <h4 class="fs14 txtdefault mb-2">
                            Expected Delivery Date:
                        </h4>
                        <p class="fs14 txtseconday mb-0">1st Sept 2021</p>
                    </div>
                </div>
                <!-- TRACK BAR -->
                <div class="trackbar mt-xs-10 progresssteps5">
                    <div class="row text-center table-card">
                        <!-- COL -->
                        <div class="col trckstep1 prgrstrckstep1">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Pre Booked</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep2 prgrstrckstep2">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Confirmed</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep3 prgrstrckstep3">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">
                                Payment Status
                            </h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep4 prgrstrckstep4">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Stock Status</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep5 prgrstrckstep5">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Quality Check</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep6 prgrstrckstep6">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Dispatched</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep7 prgrstrckstep7">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Delivered</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                    </div>
                    <div class="row align-items-center">
                        <div class="col-sm-9">
                            <p class="fs12 txtseconday mb-0">
                                <strong class="txtdefault">Reason:</strong>
                                Change in mood
                            </p>
                            <p class="fs10 txtseconday mb-0">
                                (Cancellation Request)
                            </p>
                        </div>
                        <div class="col-sm-3 text-right">
                            <a
                                class="btn px-4 buybtn fs16 radius10 ml-sm-3 ml-2"
                                href="javascript:void(0);"
                            >
                                View Invoices
                            </a>
                        </div>
                    </div>
                </div>
                <!-- /TRACK BAR -->
            </div>
        </div>
        <!-- /REPEAT -->
        <!-- REPEAT -->
        <div class="card card-ui mb-3" *ngIf="show_sample_data">
            <div class="cs-item-row pt-xs-0">
                <div class="row">
                    <div class="col-lg-8 pr-0">
                        <div class="d-table mb-4 w-100">
                            <div class="d-table-cell cart-thumb align-middle">
                                <img src="assets/images/products/p-2.jpg" />
                            </div>
                            <div class="d-table-cell pl-3 align-middle">
                                <p class="txtdefault wt700 mb-0 fs14">
                                    OnePlus Nord 2 5G (Blue Haze, 8GB RAM,
                                    128GB)
                                    <span class="badge-d-outline odbdge fs10"
                                        >Bulk</span
                                    >
                                </p>
                                <p class="txtseconday my-1 fs14">
                                    <span class="txtdefault wt500"
                                        >Order ID:
                                    </span>
                                    EQB9876598754ABCD
                                </p>
                                <a
                                    href="javascript:void(0);"
                                    class="add-whislist-link"
                                    ><span>View Order Details</span></a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12 text-right md-text-left mb-xs-10">
                        <h4 class="fs14 txtdefault mb-2">
                            Expected Delivery Date:
                        </h4>
                        <p class="fs14 txtseconday mb-0">1st Sept 2021</p>
                    </div>
                </div>
                <!-- TRACK BAR -->
                <div class="trackbar mt-xs-10 progresssteps4">
                    <div class="row text-center table-card">
                        <!-- COL -->
                        <div class="col trckstep1 prgrstrckstep1">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Booked</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep2 prgrstrckstep2">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Approved</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep3 prgrstrckstep3">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">
                                Payment Status
                            </h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep4 prgrstrckstep4">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Stock Status</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep5 prgrstrckstep5">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Quality Check</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep6 prgrstrckstep6">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Dispatched</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep7 prgrstrckstep7">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Delivered</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-md-4 text-center">
                            <a
                                class="btn-d-outline d-inline-block"
                                href="javascript:void(0);"
                            >
                                Ready to Dispatch
                            </a>
                        </div>
                    </div>
                </div>
                <!-- /TRACK BAR -->
            </div>
        </div>
        <!-- /REPEAT -->
        <!-- REPEAT -->
        <div class="card card-ui mb-3" *ngIf="show_sample_data">
            <div class="cs-item-row pt-xs-0">
                <div class="row">
                    <div class="col-lg-8 pr-0">
                        <div class="d-table mb-4 w-100">
                            <div class="d-table-cell cart-thumb align-middle">
                                <img src="assets/images/products/p-2.jpg" />
                            </div>
                            <div class="d-table-cell pl-3 align-middle">
                                <p class="txtdefault wt700 mb-0 fs14">
                                    OnePlus Nord 2 5G (Blue Haze, 8GB RAM,
                                    128GB)
                                    <span class="badge-d-outline odbdge fs10"
                                        >Bulk</span
                                    >
                                </p>
                                <p class="txtseconday my-1 fs14">
                                    <span class="txtdefault wt500"
                                        >Order ID:
                                    </span>
                                    EQB9876598754ABCD
                                </p>
                                <a
                                    href="javascript:void(0);"
                                    class="add-whislist-link"
                                    ><span>View Order Details</span></a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12 text-right md-text-left mb-xs-10">
                        <h4 class="fs14 txtdefault mb-2">
                            Expected Delivery Date:
                        </h4>
                        <p class="fs14 txtseconday mb-0">1st Sept 2021</p>
                    </div>
                </div>
                <!-- TRACK BAR -->
                <div class="trackbar mt-xs-0 progresssteps4">
                    <div class="row text-center table-card">
                        <!-- COL -->
                        <div class="col trckstep1 prgrstrckstep1">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Booked</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep2 prgrstrckstep2">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Approved</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep3 prgrstrckstep3">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">
                                Payment Status
                            </h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep4 prgrstrckstep4">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Stock Status</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep5 prgrstrckstep5">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Quality Check</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep6 prgrstrckstep6">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Dispatched</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep7 prgrstrckstep7">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Delivered</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                    </div>
                    <div class="row">
                        <div class="col-md-6"></div>
                        <div class="col-md-4 text-center">
                            <a
                                class="btn-d-outline d-inline-block"
                                href="javascript:void(0);"
                            >
                                Replace IMEI List
                            </a>
                            <div class="mt-10">
                                <a
                                    href="javascript:;"
                                    class="d-flex align-items-center link-alt fs14"
                                    ><img
                                        src="/assets/images/icons/pdf.svg"
                                        class="mr-2"
                                        style="max-width: 18px" />
                                    IMEI….24.pdf
                                    <img
                                        src="/assets/images/icons/np_remove.png"
                                        class="ml-2"
                                /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /TRACK BAR -->
            </div>
        </div>
        <!-- /REPEAT -->
        <!-- REPEAT -->
        <div class="card card-ui mb-3" *ngIf="show_sample_data">
            <div class="cs-item-row pt-xs-0">
                <div class="row">
                    <div class="col-lg-8 pr-0">
                        <div class="d-table mb-4 w-100">
                            <div class="d-table-cell cart-thumb align-middle">
                                <img src="assets/images/products/p-2.jpg" />
                            </div>
                            <div class="d-table-cell pl-3 align-middle">
                                <p class="txtdefault wt700 mb-0 fs14">
                                    OnePlus Nord 2 5G (Blue Haze, 8GB RAM,
                                    128GB)
                                    <span class="badge-d-outline odbdge fs10"
                                        >Bulk</span
                                    >
                                </p>
                                <p class="txtseconday my-1 fs14">
                                    <span class="txtdefault wt500"
                                        >Order ID:
                                    </span>
                                    EQB9876598754ABCD
                                </p>
                                <a
                                    href="javascript:void(0);"
                                    class="add-whislist-link"
                                    ><span>View Order Details</span></a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12 text-right md-text-left mb-xs-10">
                        <h4 class="fs14 txtdefault mb-2">
                            Expected Delivery Date:
                        </h4>
                        <p class="fs14 txtseconday mb-0">1st Sept 2021</p>
                    </div>
                </div>
                <!-- TRACK BAR -->
                <div class="trackbar mt-xs-10 progresssteps5">
                    <div class="row text-center table-card">
                        <!-- COL -->
                        <div class="col trckstep1 prgrstrckstep1">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Booked</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep2 prgrstrckstep2">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Approved</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep3 prgrstrckstep3">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">
                                Payment Status
                            </h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep4 prgrstrckstep4">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Stock Status</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep5 prgrstrckstep5">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Quality Check</h4>
                            <div
                                class="qc-verified d-flex align-items-center justify-content-center"
                            >
                                30/50 verified
                                <i
                                    placement="top"
                                    ngbTooltip="This will act as a default filter every time you will toggle."
                                    ><img
                                        src="/assets/images/icons/info.png"
                                        class="ml-2"
                                /></i>
                            </div>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep6 prgrstrckstep6">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Dispatched</h4>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep7 prgrstrckstep7">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Delivered</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                    </div>
                    <div class="row">
                        <div class="col-md-3"></div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-3 text-center">
                                    <a
                                        class="btn-d-outline d-inline-block place-bid"
                                        href="javascript:void(0);"
                                    >
                                        Replace E-way Bill
                                    </a>
                                    <div class="mt-10">
                                        <a
                                            href="javascript:;"
                                            class="d-flex align-items-center link-alt fs14"
                                            ><img
                                                src="/assets/images/icons/pdf.svg"
                                                class="mr-2"
                                                style="max-width: 18px" />
                                            EWAY….24.pdf
                                            <img
                                                src="/assets/images/icons/np_remove.png"
                                                class="ml-2"
                                        /></a>
                                    </div>
                                </div>
                                <div class="col-md-3 text-center">
                                    <a
                                        class="btn-d-outline d-inline-block place-bid"
                                        href="javascript:void(0);"
                                    >
                                        Replace Invoice
                                    </a>
                                    <div class="mt-10">
                                        <a
                                            href="javascript:;"
                                            class="d-flex align-items-center link-alt fs14"
                                            ><img
                                                src="/assets/images/icons/pdf.svg"
                                                class="mr-2"
                                                style="max-width: 18px" />
                                            EWAY….24.pdf
                                            <img
                                                src="/assets/images/icons/np_remove.png"
                                                class="ml-2"
                                        /></a>
                                    </div>
                                </div>
                                <div class="col-md-3 text-center">
                                    <a
                                        class="btn-d-outline d-inline-block place-bid"
                                        href="javascript:void(0);"
                                    >
                                        Replace Label
                                    </a>
                                    <div class="mt-10">
                                        <a
                                            href="javascript:;"
                                            class="d-flex align-items-center link-alt fs14"
                                            ><img
                                                src="/assets/images/icons/pdf.svg"
                                                class="mr-2"
                                                style="max-width: 18px" />
                                            EWAY….24.pdf
                                            <img
                                                src="/assets/images/icons/np_remove.png"
                                                class="ml-2"
                                        /></a>
                                    </div>
                                </div>
                                <div class="col-md-3 text-center">
                                    <a
                                        class="btn-d-outline d-inline-block place-bid"
                                        href="javascript:void(0);"
                                    >
                                        Replace Manifest
                                    </a>
                                    <div class="mt-10">
                                        <a
                                            href="javascript:;"
                                            class="d-flex align-items-center link-alt fs14"
                                            ><img
                                                src="/assets/images/icons/pdf.svg"
                                                class="mr-2"
                                                style="max-width: 18px" />
                                            EWAY….24.pdf
                                            <img
                                                src="/assets/images/icons/np_remove.png"
                                                class="ml-2"
                                        /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /TRACK BAR -->
            </div>
        </div>
        <!-- /REPEAT -->
        <!-- REPEAT -->
        <div class="card card-ui mb-3" *ngIf="show_sample_data">
            <div class="cs-item-row pt-xs-0">
                <div class="row">
                    <div class="col-lg-8 pr-0">
                        <div class="d-table mb-4 w-100">
                            <div class="d-table-cell cart-thumb align-middle">
                                <img src="assets/images/products/p-2.jpg" />
                            </div>
                            <div class="d-table-cell pl-3 align-middle">
                                <p class="txtdefault wt700 mb-0 fs14">
                                    OnePlus Nord 2 5G (Blue Haze, 8GB RAM,
                                    128GB)
                                    <span class="badge-d-outline odbdge fs10"
                                        >Bulk</span
                                    >
                                </p>
                                <p class="txtseconday my-1 fs14">
                                    <span class="txtdefault wt500"
                                        >Order ID:
                                    </span>
                                    EQB9876598754ABCD
                                </p>
                                <a
                                    href="javascript:void(0);"
                                    class="add-whislist-link"
                                    ><span>View Order Details</span></a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12 text-right md-text-left mb-xs-10">
                        <h4 class="fs14 txtdefault mb-2">
                            Expected Delivery Date:
                        </h4>
                        <p class="fs14 txtseconday mb-0">1st Sept 2021</p>
                    </div>
                </div>
                <!-- TRACK BAR -->
                <div class="trackbar mt-xs-10 progresssteps7">
                    <div class="row text-center table-card">
                        <!-- COL -->
                        <div class="col trckstep1 prgrstrckstep1">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Booked</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep2 prgrstrckstep2">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Approved</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep3 prgrstrckstep3">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">
                                Payment Status
                            </h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep4 prgrstrckstep4">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Stock Status</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep5 prgrstrckstep5">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Quality Check</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep6 prgrstrckstep6">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Dispatched</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep7 prgrstrckstep7">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Delivered</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                    </div>
                    <div class="row d-flex justify-content-end">
                        <div class="col-md-3 text-right">
                            <a
                                class="btn-d-outline d-inline-block place-bid"
                                href="javascript:void(0);"
                            >
                                <img src="/assets/images/icons/np_locate.png" />
                                Live Tracking
                            </a>
                        </div>
                    </div>
                </div>
                <!-- /TRACK BAR -->
            </div>
        </div>
        <!-- /REPEAT -->
        <!-- REPEAT -->
        <div class="card card-ui mb-3" *ngIf="show_sample_data">
            <div class="cs-item-row pt-xs-0">
                <div class="row">
                    <div class="col-lg-8 pr-0">
                        <div class="d-table mb-4 w-100">
                            <div class="d-table-cell cart-thumb align-middle">
                                <img src="assets/images/products/p-2.jpg" />
                            </div>
                            <div class="d-table-cell pl-3 align-middle">
                                <p class="txtdefault wt700 mb-0 fs14">
                                    OnePlus Nord 2 5G (Blue Haze, 8GB RAM,
                                    128GB)
                                </p>
                                <p class="txtseconday my-1 fs14">
                                    <span class="txtdefault wt500"
                                        >Order ID:
                                    </span>
                                    EQB9876598754ABCD
                                </p>
                                <a
                                    href="javascript:void(0);"
                                    class="add-whislist-link"
                                    ><span>View Order Details</span></a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12 text-right md-text-left mb-xs-10">
                        <h4 class="fs14 txtdefault mb-2">
                            Expected Delivery Date:
                        </h4>
                        <p class="fs14 txtseconday mb-0">1st Sept 2021</p>
                    </div>
                </div>
                <!-- TRACK BAR -->
                <div class="trackbar mt-xs-10 progresssteps7">
                    <div class="row text-center table-card">
                        <!-- COL -->
                        <div class="col trckstep1 prgrstrckstep1">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Pre Booked</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep2 prgrstrckstep2">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Confirmed</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep3 prgrstrckstep3">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">
                                Payment Status
                            </h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep4 prgrstrckstep4">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Stock Status</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep5 prgrstrckstep5">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Quality Check</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep6 prgrstrckstep6">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Dispatched</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                        <!-- COL -->
                        <div class="col trckstep7 prgrstrckstep7">
                            <span class="icontick"></span>
                            <h4 class="fs12 txtdefault wt700">Delivered</h4>
                            <p class="fs12 txtseconday">
                                27/08/2021<br />11:54pm
                            </p>
                        </div>
                        <!-- /COL -->
                    </div>
                    <div class="row align-items-center">
                        <div class="col-sm-9">
                            <p class="fs12 txtseconday mb-0">
                                <strong class="txtdefault">Reason:</strong>
                                Damaged Product
                            </p>
                            <p class="fs10 txtseconday mb-0">
                                (Return Request)
                            </p>
                        </div>
                        <div class="col-sm-3 text-right">
                            <a
                                class="btn-d-outline d-inline-block"
                                href="javascript:void(0);"
                            >
                                View Request
                            </a>
                        </div>
                    </div>
                </div>
                <!-- /TRACK BAR -->
                <!-- /TRACK BAR -->
                <!-- RETURN REQUEST -->
                <div class="rtrqst">
                    <div class="rtrqsthead">
                        <h4 class="txtdefault wt700 fs14">Return Request</h4>
                        <p class="txtseconday fs14">
                            Request received on 24th Jan 2022, 11:15:25 AM
                        </p>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 pr-0">
                            <div class="d-table mb-4 w-100">
                                <div
                                    class="d-table-cell cart-thumb align-middle"
                                >
                                    <img src="assets/images/products/p-2.jpg" />
                                </div>
                                <div class="d-table-cell pl-3 align-middle">
                                    <p class="txtdefault wt700 mb-0 fs14">
                                        OnePlus Nord 2 5G (Blue Haze, 8GB RAM,
                                        128GB)
                                    </p>
                                    <p class="txtseconday my-1 fs14">
                                        <span class="txtdefault wt500"
                                            >Order ID:
                                        </span>
                                        EQB9876598754ABCD
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-container mb-0">
                        <div class="row mb-3">
                            <div class="col-xxl-6 col-lg-7">
                                <div class="floating-label">
                                    <input
                                        placeholder=" "
                                        class="form-control"
                                        value="Incomplete technical info"
                                    />
                                    <span class="highlight"></span>
                                    <label>Reason for Cancellation</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xxl-6 col-lg-7">
                                <div class="floating-label">
                                    <textarea
                                        placeholder=" "
                                        class="form-control txtareaht80"
                                    >
Multiple RAM variants missing</textarea
                                    >
                                    <span class="highlight"></span>
                                    <label>Message from Buyer</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-end">
                        <div class="col-sm-7">
                            <div class="row img-option-list align-items-bottom">
                                <div class="col-sm-3 text-center">
                                    <a
                                        class="d-inline-block w-100"
                                        href="javascript:void(0);"
                                    >
                                        <img
                                            src="assets/images/admin/mfront.png"
                                        />
                                    </a>
                                    <p class="fs12 txtseconday mb-0">
                                        Front image*
                                    </p>
                                </div>
                                <div class="col-sm-3 text-center">
                                    <a
                                        class="d-inline-block w-100"
                                        href="javascript:void(0);"
                                    >
                                        <img
                                            src="assets/images/admin/back.png"
                                        />
                                    </a>
                                    <p class="fs12 txtseconday mb-0">
                                        Back image*
                                    </p>
                                </div>
                                <div class="col-sm-3 text-center">
                                    <a
                                        class="d-inline-block w-100"
                                        href="javascript:void(0);"
                                    >
                                        <img
                                            src="assets/images/admin/side.png"
                                        />
                                    </a>
                                    <p class="fs12 txtseconday mb-0">
                                        Side image*
                                    </p>
                                </div>
                                <div class="col-sm-3 text-center">
                                    <a
                                        class="d-inline-block w-100"
                                        href="javascript:void(0);"
                                    >
                                        <img
                                            src="assets/images/admin/top.png"
                                        />
                                    </a>
                                    <p class="fs12 txtseconday mb-0">
                                        Top image*
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-5 text-right">
                            <input
                                type="submit"
                                value="Accept"
                                class="btn px-4 buybtn fs16 radius10 btn-success"
                            />
                            <a
                                href="javascript:void(0);"
                                class="btn-d-outline btn-danger-outline d-inline-block ml-sm-3 ml-2"
                                >Reject</a
                            >
                        </div>
                    </div>
                    <!-- /RETURN REQUEST -->
                </div>
            </div>
            <!-- /REPEAT -->
        </div>
    </div>
</div>

<app-footer-seller></app-footer-seller>

<!-- RETURN REQUEST -->
<ng-template #returnrequest let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>

    <div class="modal-body">
        <div class="row align-items-center">
            <div class="col-sm-7">
                <div class="">
                    <div class="rtrqsthead">
                        <h4 class="txtdefault wt700 fs22">Return Request</h4>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 pr-0 rtnrqtmdl">
                            <div class="d-table mb-4 w-100">
                                <div class="d-table-cell cart-thumb align-middle">
                                    <img src="{{ return_order.product_image }}" />
                                </div>
                                <div class="d-table-cell pl-2 align-middle">
                                    <p class="txtdefault wt700 mb-0 fs12">{{ return_order.produt_name }}
                                    </p>
                                    <p class="txtseconday my-1 fs12"><span class="txtdefault wt500">Order ID: </span>
                                        {{ return_order.order_id }}</p>
                                    <div class="badge-d-outline odbdge d-inline fs10" *ngIf="return_order.return_request"><b>Return Status:</b> {{ return_status_text }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-container mb-0 mt-0">
                        <div class="row" *ngIf="return_order.parentchild != 'yes'">
                            <div class="col-lg-12 ">
                                <div class="floating-label">
                                    <input type="text" placeholder=" " class="form-control" name="return_quantity" [(ngModel)]="return_quantity">
                                    <span class="highlight"></span>
                                    <label>No. of Products</label>
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="return_order.parentchild == 'yes'">
                            <div class="col-lg-12" *ngFor="let ch of return_order.parentchild_records">
                                <div class="floating-label">
                                    <input type="text" placeholder=" " class="form-control" name="return_quantity" [(ngModel)]="ch.return_qty">
                                    <span class="highlight"></span>
                                    <label>{{ ch.name }} - No. of Products</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 ">
                                <div class="floating-label">
                                    <input type="text" placeholder=" " class="form-control" name="return_reason" [(ngModel)]="return_reason">
                                    <span class="highlight"></span>
                                    <label>Reason for Cancellation</label>
                                </div>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-lg-12 ">
                                <div class="floating-label">
                                    <textarea placeholder="" name="return_message" [(ngModel)]="return_message" class="form-control txtareaht80"></textarea>
                                    <span class="highlight"></span>
                                    <label>Message to Seller</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="client-thumbnails dtlthmb odimgsz text-center img-option-list" *ngIf="return_order.return_request">
                        <ngx-slick-carousel class="carousel thumbs" #slickModal="slick-carousel"
                            [config]="thumbnailsSlider ">
                            <div ngxSlickItem *ngFor="let slide of return_updated_image" class="slide">
                                <a class="d-inline-block w-100" href="javascript:void(0);">
                                    <img src="{{ slide.img }}" alt="">
                                </a>
                                <!-- <p class="fs12 txtseconday mb-0">Front image*</p> -->
                            </div>
                        </ngx-slick-carousel>
                    </div>
                    <div class="text-right" *ngIf="return_status == 1">
                        <a href="javascript:void(0);" class="btn buybtn-success fs16 radius10 ripple mr-2" (click)="submitreturn('approve')">Approve</a>
                        <a href="javascript:void(0);" class="btn-d-outline btn-danger-outline d-inline-block ml-sm-3 ml-2" (click)="submitreturn('reject')">Reject</a>
                    </div>
                    <div class="text-right" *ngIf="return_status > 1">
                        <a href="javascript:void(0);" class="btn-d-outline btn-danger-outline d-inline-block ml-sm-3 ml-2" (click)="modal.dismiss('Cross click')">Cancel</a>
                    </div>
                    
                </div>
            </div>
            <div class="col-sm-5 text-center">
                <div class="feedback-slides  ">
                    <div class="client-feedback dtlfull">
                        <ngx-slick-carousel class="carousel feedback" #slickModal="slick-carousel"
                            [config]="imagesSlider">
                            <div ngxSlickItem *ngFor="let slide of return_updated_image" class="slide ">
                                <div class="thmbnaildtl">
                                    <img src="{{ slide.img }}" alt="" width="100%">
                                </div>
                            </div>
                        </ngx-slick-carousel>
                    </div>
                </div>
                <p class="fs10 txtseconday pt-3">Image Preview</p>
            </div>
        </div>
    </div>

</ng-template>
<!-- /RETURN REQUEST -->