<app-header-one [sticky]="true"></app-header-one>

<section class="page-section pt-20">
	<div class="container">
		<!--- /HEAD  --->
		<div class="row d-flex align-items-center mb-20">
			<div class="col-md-12">
				<h3 class="f-700 fs22 txtdefault pb-0">
                    <a href="javascript:;" class="go-back"><i class="fa fa-angle-left"></i></a> Product/Seller Review
                </h3>
			</div>			
		</div>
		<!-- CARD -->

		<div class="notifications-container theme-tab">
			<ngb-tabset [justify]="'left'" [type]="'pills'" class="tabs tab-title">
	            <ngb-tab>
	                <ng-template ngbTabTitle>Write a Review</ng-template>
	                <ng-template ngbTabContent>
						<!--Write Review Card-->
	                	<div class="card cardui carduipro p-4 mb-20" *ngFor="let record of review_orders; let i = index;">
		                  	<div class="tab-content">
		                  		<div class="d-flex align-items-center">
									<div class="mr-2"><img src="{{ record.seller_logo }}"></div>
									<h4 class="f-700 fs20 txtdefault pb- mb-0">{{ record.seller_name }}</h4>
								</div>
								<hr>
								<div class="fs15 f-700 mb-20">Product Info:</div>
								<div class="row d-flex align-items-center mb-30">
									<div class="col-md-6">
										<div class="d-flex align-items-center">
											<div class="mr-3"><img src="{{ record.product_image }}" style="max-width:80px"></div>
											<h5 class="mb-0 fs14 wt700">{{ record.product_name }}</h5>
										</div>
									</div>	

									<div class="col-md-6 text-right">
										<div class="row">
											<div class="col">
												<b>Booked on:</b>
												<div class="text-muted">{{ record.prebookedon}}</div>
											</div>
											<div class="col">
												<b>Total deal amount:</b>
												<div class="text-muted">{{ record.total_amount }}</div>
											</div>
											<div class="col">
												<b>Order Details:</b>
												<div class="text-muted"><a href="javascript:;" [routerLink]="['/buyer/buyer-view-order-details/'+record.id]" class="link-alt fs14">View Details</a></div>
											</div>
										</div>
									</div>
								</div>

								<!--Product Rating-->
								<div class="row mb-20" *ngIf="record.product_rating">
									<div class="col-md-3">
										<div class="fs15 f-700 mb-10">Product Rating:</div>
										<div class="ratings star-ratings pr-1 d-inline-block">
											<ngb-rating [max]="5" [(rate)]="record.product_review_rating" [readonly]="false"></ngb-rating>
										</div>
									</div>	

									<div class="col-md-4">
										<div class="fs15 f-700 mb-10">Product Image: <span class="fs12 text-muted">(Select only 1 image)</span></div>
										<input type="file" class="btn-d-outline ripple d-inline-block" (change)="uploadFile($event, record)" placeholder="Add a Photo or Video">
										<!-- <a href="javascript:;" class="btn-d-outline ripple d-inline-block">+ Add a photo or video</a> -->
									</div>	
								</div>
								<div class="form-container mb-3" *ngIf="record.product_rating">
									<div class="floating-label mb-0">      
										<textarea class="form-control floating-textarea" placeholder=" " [(ngModel)]="record.product_review_desc"></textarea>
										<span class="highlight"></span>
										<label>Your Product Review</label>
									</div>
									<div class="help-block">Characters Limit: 200 words</div>
								</div>
								<hr>
								<!--End Product Rating-->

								<!--Seller Rating-->
								<div class="row mb-30">
									<div class="col-md-3">
										<div class="fs15 f-700 mb-10">Seller Rating:</div>
										<div class="ratings star-ratings pr-1 d-inline-block">
											<ngb-rating [max]="5" [(rate)]="record.seller_review_rating" [readonly]="false"></ngb-rating>
										</div>
									</div>	

									<div class="col-md-4">
										<div class="fs15 f-700 mb-10">Are you satisfied with the seller?</div>
										<select name="satisfied" id="satisfied" class="form-control" [(ngModel)]="record.seller_review_satisfied">
											<option value="yes" selected>Yes</option>
											<option value="no">No</option>
										</select>
									</div>	

									<div class="col-md-4" *ngIf="!record.product_rating">
										<div class="text-right">
											<a href="javascript:;" class="link-alt fs14" (click)="updateproductreview(record)">Write product review +</a>
										</div>
									</div>
								</div>

									<div class="form-container mb-3">
										<div class="floating-label mb-0">      
										   <textarea class="form-control floating-textarea" placeholder=" " [(ngModel)]="record.seller_review_desc"></textarea>
										   <span class="highlight"></span>
										   <label>Add Seller review</label>
										</div>
										<div class="help-block">Characters Limit: 200 words</div>
									</div>

									<div class="text-right">
										<button class="btn buybtn fs16 radius10 ripple px-4" type="button" (click)="submitreview(record)">Submit Review</button>
									</div>
								<!--End Seller Rating-->
		                  	</div>
		                </div>

						<div *ngIf="!review_orders.length">
							<div class="row pt-40 pb-40">
                                <div class="col-md-12 text-center">
                                    <div class="mb-20"><img src="/assets/images/empty-state.png">
                                    </div>
                                    <div class="fs16">There are no order to write a review!</div>
                                </div>
                            </div>
						</div>

		                <!-- <h4 class="fs16 f-700">Other orders awaiting your review</h4> -->
		                
	                </ng-template>
	            </ngb-tab>	
	            <ngb-tab>
	                <ng-template ngbTabTitle>My Reviews</ng-template>
	                <ng-template ngbTabContent>
						<!--My Review Card-->
	                	<div class="card cardui carduipro p-4 mb-20" *ngFor="let record of myreview_orders;">
		                  	<div class="tab-content">
		                  		<div class="d-flex align-items-center">
									<div class="mr-2"><img src="{{ record.seller_logo }}"></div>
									<h4 class="f-700 fs20 txtdefault pb- mb-0">{{ record.seller_name }}</h4>
								</div>
								<hr>
								<div class="fs15 f-700 mb-20">Product Info:</div>
								<div class="row d-flex align-items-center mb-30">
									<div class="col-md-6">
										<div class="d-flex align-items-center">
											<div class="mr-3"><img src="{{ record.product_image }}" style="max-width:80px"></div>
											<h5 class="mb-0 fs14 wt700">{{ record.product_name }}</h5>
										</div>
									</div>	

									<div class="col-md-6 text-right">
										<div class="row">
											<div class="col">
												<b>Booked on:</b>
												<div class="text-muted">{{ record.prebookedon }}</div>
											</div>
											<div class="col">
												<b>Total deal amount:</b>
												<div class="text-muted">{{ record.total_amount }}</div>
											</div>
											<div class="col">
												<b>Order Details:</b>
												<div class="text-muted"><a href="javascript:;" [routerLink]="['/buyer/buyer-view-order-details/'+record.id]" class="link-alt fs14">View Details</a></div>
											</div>
										</div>
									</div>
								</div>

								<!--Product Rating-->
								<div class="row mb-20">
									<div class="col-md-3">
										<div class="fs15 f-700 mb-10">Product Rating:</div>
										<div class="ratings star-ratings pr-1 d-inline-block">
											<ngb-rating [max]="5" [(rate)]="record.product_review_rating" [readonly]="false"></ngb-rating>
										</div>
									</div>	

									<div class="col-md-4">
										<div class="fs15 f-700 mb-10">Product Image: <span class="fs12 text-muted">(Select only 1 image)</span></div>
										<div class="review-image"><img src="{{ record.product_review_file }}"></div>
									</div>	
								</div>
								<div class="form-container mb-3">
									<div class="floating-label mb-0">      
										<textarea class="form-control floating-textarea" placeholder=" " [(ngModel)]="record.product_review_desc"></textarea>
										<span class="highlight"></span>
										<label>Your Product Review</label>
									</div>
								</div>
								<hr>
								<!--End Product Rating-->

								<!--Seller Rating-->
								<div class="row mb-30">
									<div class="col-md-3">
										<div class="fs15 f-700 mb-10">Seller Rating:</div>
										<div class="ratings star-ratings pr-1 d-inline-block">
											<ngb-rating [max]="5" [(rate)]="record.seller_review_rating" [readonly]="false"></ngb-rating>
										</div>
									</div>	

									<div class="col-md-4">
										<div class="fs15 f-700 mb-10">Are you satisfied with the seller?</div>
										{{ record.seller_review_satisfied }}
									</div>	

									<div class="col-md-4">
										
									</div>
								</div>

								<div class="form-container mb-3">
									<div class="floating-label mb-0">      
										<textarea class="form-control floating-textarea" placeholder=" " [(ngModel)]="record.seller_review_desc"></textarea>
										<span class="highlight"></span>
										<label>Add Seller review</label>
									</div>
								</div>
								<!--End Seller Rating-->
		                  	</div>
		                </div>
	                </ng-template>
	            </ngb-tab>									
	    	</ngb-tabset>
	    </div>
	</div>
</section>

<section class="small-section bg-light-blue">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mb-5 faqpanel accordionarrow">
					<h4 class="f-700 fs22 txtdefault pb-20">Frequently Asked Questions</h4>
					<ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
		            <ngb-panel title="What is a bulk and wholesale Segment?">
		                <ng-template ngbPanelContent>
		                    <p class="txtdefault fs16 lnht24 mb-0">Equibiz facilitates both large and small value transactions to meet the requirements of different stakeholders such as brand, wholesaler, distributor, retailer, corporate, exporter, importer, etc. Under the bulk segment, the suppliers have the freedom to select deal terms such as minimum order quantity, minimum bid price, supply location, payment option, etc. Whereas under the wholesale segment, the minimum transaction value must be INR 3,000/- or above and the trade terms are set to facilitate the retailer’s or small value buyer’s requirement.</p>
		                </ng-template>
		            </ngb-panel>
		            <ngb-panel title="What is bid and offer?">
		                <ng-template ngbPanelContent>
		                    <p class="txtdefault fs16 lnht24 mb-0">A bid is a counter price of any product set by the buyer against the listed price of the seller under the bid & offer segment. This proprietary feature of our e-commerce platform Equibiz is to facilitate the trading of products under the bulk listing. If the seller agrees with the bid set by the buyer, the seller can close the deal at the price set by the buyer. Apart from this, the rest of the other deal conditions will remain the same as set by the seller at the time of product listing.</p>
		                </ng-template>
		            </ngb-panel>
		            <ngb-panel title="What is the minimum order quantity?">
		                <ng-template ngbPanelContent>
		                    <p class="txtdefault fs16 lnht24 mb-0">It is the minimum quantity of products below which the seller is not willing to accept the order.</p>
		                </ng-template>
		            </ngb-panel>
		            <ngb-panel title="How do I get the purchased products? Who will provide the logistic services?">
		                <ng-template ngbPanelContent>
		                    As a buyer, you need not worry about arranging a courier or any 3rd party logistics as Equibiz provides integrated logistics services to arrange the shipment of the consignment from the supplier’s place. 
		                </ng-template>
		            </ngb-panel>
		            <ngb-panel title="What are the charges buyers are required to pay?">
		                <ng-template ngbPanelContent>
		                    The buyer may require paying charges for logistics, convenience, and insurance(optional). The charges are displayed to the buyer at the time of deal booking.
		                </ng-template>
		            </ngb-panel>
		            <ngb-panel title="As a buyer, can I add more than one delivery location?">
		                <ng-template ngbPanelContent>
		                    Yes, the buyer has the option to select the preferred delivery location at the time of deal booking. The buyer can set more than one delivery location from the buyer panel.
		                </ng-template>
		            </ngb-panel>
		            <ngb-panel title="What are the Logistics options available at the time of Deal Booking ?">
		                <ng-template ngbPanelContent>
		                    Standard and Express delivery logistics options are available at the time of deal booking and buyers can choose any one logistics option as per their business requirement. The expected delivery time in the case of express delivery is three to five working days and in the case of standard, it is five to seven working days.
		                </ng-template>
		            </ngb-panel>
		            <ngb-panel title="How do I make the payment for my purchase?">
		                <ng-template ngbPanelContent>
		                    At the time of check out, there are various payment options like Equibiz internal wallet, Net Banking, Debit Card, Credit Card, UPI, etc. available to select as a payment method. Except for the Equibiz wallet, other payment options may have certain Payment Gateway charges which will be applicable at the time of making a payment.
		                </ng-template>
		            </ngb-panel>
		            <ngb-panel title="What is the payment timeline for the value of the bulk and wholesale segment deal?">
		                <ng-template ngbPanelContent>
		                    As per the Equibiz payment guidelines, for the purchase of products listed under the bulk segment, as per the seller’s preference, either full payment at the time of booking or a token amount (10 % of the deal value) to be paid by the buyer at the time of pre-booking and the balance payment (90% of the deal value) before the dispatch date. For the wholesale segment transactions, full payment of the deal value along with the charges must be paid by the buyers at the time of deal booking.
		                </ng-template>
		            </ngb-panel>
		        </ngb-accordion>
				</div>
			</div>
		</div>
	</div>
</section>
<app-footer-one></app-footer-one>