import { Component, OnInit } from '@angular/core';
import { CmsTermsofUseService } from 'src/app/admin/cms/terms-of-use/terms-of-use.service';
import { ToasterService } from 'src/app/helpers/toastr.service';

@Component({
  selector: 'app-infringement-policy',
  templateUrl: './infringement-policy.component.html',
  styleUrls: ['./infringement-policy.component.scss']
})
export class InfringementPolicyComponent implements OnInit {

  public cms_type:string = 'ipreports_infringement_policy';
  public page_content:any = '';

  constructor(
    private _cmsservice: CmsTermsofUseService,
    private _toastr: ToasterService

  ) { }

  ngOnInit(): void {
    this.getcmsdata();
  }

  getcmsdata() {
    this._cmsservice.getcms({type: this.cms_type}).subscribe((data) => {
        if (data['status'] == "success") {
          this.page_content = data['values'];
        } else if (data['status'] == "error") {
            this._toastr.error(data['message']);
        }
    });
}

}
