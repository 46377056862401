import { Component, OnInit, Pipe } from '@angular/core';
import { CmsTermsofUseService } from 'src/app/admin/cms/terms-of-use/terms-of-use.service';
import { ToasterService } from 'src/app/helpers/toastr.service';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {

  public cms_type:string = 'terms-of-use';
  public terms_of_use:any = '';

  constructor(
    private _cmsservice: CmsTermsofUseService,
    private _toastr: ToasterService

  ) { }

  ngOnInit(): void {
    this.getcmsdata();
  }

  getcmsdata() {
    this._cmsservice.getcms({type: this.cms_type}).subscribe((data) => {
        if (data['status'] == "success") {
          this.terms_of_use = data['values'];
        } else if (data['status'] == "error") {
            this._toastr.error(data['message']);
        }
    });
}

}
