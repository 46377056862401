import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class OrderHistoryService {

    constructor(private _http: HttpClient) { }

    sellerorders(data) {
        return this._http.post(environment.api_url + 'seller/orders', data);
    }

    subsellerorders(data) {
        return this._http.post(environment.api_url + 'subseller/orders', data);
    }

    sellerreturnorders(data) {
        return this._http.post(environment.api_url + 'seller/returnrequestorders', data);
    }

    subsellerreturnorders(data) {
        return this._http.post(environment.api_url + 'subseller/returnrequestorders', data);
    }

}