<app-header-seller></app-header-seller>
<app-sidebar-seller></app-sidebar-seller>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Payments</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" [routerLink]="['/my-wallet']">My Wallet</a>
						</li>
						<li class="breadcrumb-item active">Add Money</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:void(0);" [routerLink]="['/seller/assistance']" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<h3 class="f-700 fs22 txtdefault pb-10"><a href="javascript:;" class="go-back"><i class="fa fa-angle-left"></i></a> Add Money</h3>

		<div class="card cardui carduipro p-4 mb-40">
			<div class="form-container mdl-frm-lbl mb-3 mt-0">
				<h3 class="f-700 fs20 txtdefault pb-20">Equibiz Wallet</h3>
				<div class="wallet-balance">
					<div class="mr-sm-3">
						<img src="assets/images/icons/wallet-balance.svg">
					</div>
					<div>
						<p>Current Balance:</p>
						<h4>₹ 1,05,500.08</h4>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-6 mb-2">
						<div class="floating-label mb-0">      
						  <input type="text" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>Enter amount (min ₹1,000)</label>
						</div> 
					</div>
				</div>
				<div class="help-info">Transaction Charges : 0.1%  <a href="javascript:;" class="fs14 link-alt">Know more</a></div>

				<div class="wallet-amount">
					<ul>
						<li><a href="javascript:;" class="selected">₹ 10,000</a></li>
						<li><a href="javascript:;">₹ 20,000</a></li>
						<li><a href="javascript:;">₹ 50,000</a></li>
						<li><a href="javascript:;">₹ 1,00,000</a></li>
					</ul>
				</div>
				<div class="text-left mt-30">
					<a href="javascript:;" class="mr-sm-4 btn-d-outline d-inline-block"><svg xmlns="http://www.w3.org/2000/svg" width="14.681" height="14.681" viewBox="0 0 14.681 14.681" class="plus">
					  <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1 1)">
					    <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181" transform="translate(-11.659 -7.5)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					    <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181" transform="translate(-7.5 -11.659)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					  </g>
					</svg> Add Money</a>
					<i placement="top" ngbTooltip="more info goes here"><img src="assets/images/icons/shield-alt.svg" /></i>
					&nbsp;Safe and securely add money to your wallet.
				</div>
			</div>
		</div>

		<div class="mb-2 faqpanel accordionarrow">
			<h4 class="f-700 fs22 txtdefault pb-10">Frequently Asked Questions</h4>
			<ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
			  	<ngb-panel title="What is the Equibiz internal wallet account?">
					<ng-template ngbPanelContent>
					  <p class="txtdefault fs16 lnht24 mb-0">It is a digital construct of the nodal (A type of Escrow) account to aggregate all your funding and payment in one place. Your wallet account number is mapped with your bank account provided at the time of registration.</p>
					</ng-template>
			  	</ngb-panel>
			  	<ngb-panel title="Can this wallet balance be used for making payments outside the Equibiz platform?">
					<ng-template ngbPanelContent>
					  <p class="txtdefault fs16 lnht24 mb-0">No, you can use the wallet balance to make a purchase over the Equibiz platform only.</p>
					</ng-template>
			  	</ngb-panel>
			  	<ngb-panel title="What are the advantages of using Equibiz internal wallet for making purchase payments?">
					<ng-template ngbPanelContent>
					 <p class="txtdefault fs16 lnht24 mb-0">The wallet funding charges using internet banking are free.</p>
					</ng-template>
			  	</ngb-panel>
			  	<ngb-panel title="How do I fund my Equibiz wallet account?">
					<ng-template ngbPanelContent>
					  <p class="txtdefault fs16 lnht24 mb-0">You can use internet banking to transfer funds to your wallet account. You must register your Equibiz wallet account details as a beneficiary through internet banking. You can then transfer the amount to the wallet account before you initiate any purchase.</p>
					</ng-template>
			  	</ngb-panel>
			  	<ngb-panel title="Is Equibiz wallet compulsory as a payment option for purchase?">
					<ng-template ngbPanelContent>
					  <p class="txtdefault fs16 lnht24 mb-0">No, you can use any of the listed payment options. In the case of other payment options, you may have to pay certain transaction charges, displayed at the time of payment.</p>
					</ng-template>
			  	</ngb-panel>
			</ngb-accordion>
		</div>
	</div>
</div>

<app-footer-seller></app-footer-seller>