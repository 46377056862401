<app-header-one [sticky]="true">
	<!-- Event snippet for Website traffic conversion page --> 
<script> gtag('event', 'conversion', {'send_to': 'AW-10853447367/_mWcCOuVqbQDEMftqbco'}); </script>
</app-header-one>
<script src="https://unpkg.com/card@2.3.0/dist/card.js"></script>
<section class="page-section">
	<div class="container">
		<div class="row d-flex justify-content-center">
			<div class="col-md-8">
				<div class="text-center" *ngIf="!successMessage && !errorMessage">
					<img src="/assets/images/empty-state.png">
					<div class="mt-3 mb-3">
						<img src="/assets/images/loader.gif">
					</div>
					<div class="mt-1 mb-2 fs18 f-700">Please wait.<br>We are processing your order</div>
				</div>
				<div class="text-center">
					<!--Success container-->
					<div class="success mt-2" *ngIf="successMessage">
						<div class="mt-20 mb-20"><img src="assets/images/success.png"></div>
						<div class="text-success f-700 fs18">Hurray! Order Placed Successfully!</div>
						<div class="mb-3 fs14 f-600">Redirecting, Please Wait...</div>
					</div>

					<!--Failed container-->
					<div class="failed mt-2" *ngIf="errorMessage">
						<div class="mt-20 mb-20"><img src="assets/images/danger.png"></div>
						<div class="text-danger f-700 fs18">Sorry! We're unable to process your order</div>
						<div class="mb-3 fs14 f-600">Please try again and confirm that<br>all details are correct</div>
					</div>
                </div>
			</div>
		</div>
	</div>
</section>
	


<app-footer-one></app-footer-one>