import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { WalletBalanceService } from './wallet-balance.service';

@Component({
    selector: 'app-wallet-balance',
    templateUrl: './wallet-balance.component.html',
    styleUrls: ['./wallet-balance.component.scss']
})
export class WalletBalanceComponent implements OnInit {

    constructor(
        private _walletbalanceservice: WalletBalanceService,
        private _toastr: ToasterService
    ) { }
    public topup_amount = 10000;
    public user_info: any = {};
    ngOnInit(): void {
        this.invokeStripe();
        this.userbasicinfo();
    }

    public amount_array: any = [
        {
            amount: 10000,
            label: '10,000',
            selected: true,
        },
        {
            amount: 20000,
            label: '20,000',
            selected: false,
        },
        {
            amount: 50000,
            label: '50,000',
            selected: false,
        },
        {
            amount: 100000,
            label: '1,00,000',
            selected: false,
        }
    ];

    changevalue(amount) {
        this.amount_array.forEach(function (record) {
            record.selected = false;
        });
        amount.selected = true;
        this.topup_amount = amount.amount;
    }

    walletbalance() {
        this._walletbalanceservice.walletbalance({ amount: this.topup_amount }).subscribe((data) => {
            if (data['status'] == "success") {
                this.makepayment(data['order_token']);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    makepayment(ordertoken) {
        let parent = document.getElementById('drop_in_container');
        parent.innerHTML = '';
        let cashfree = new (<any>window).Cashfree();
        cashfree.initialiseDropin(parent, {
            orderToken: ordertoken,
            onSuccess: function (data) {
                if (data.order && data.order.status == 'PAID') {
                    alert('order is paid. Call api to verify');
                }
            },
            onFailure: function (data) {
                alert(data.order.errorText);
            },
            components: ['card', 'upi', 'app', 'netbanking'], //'order-details',
            style: {
                backgroundColor: '#ffffff',
                color: '#11385b',
                fontFamily: 'Lato',
                fontSize: '14px',
                errorColor: '#ff0000',
                theme: 'light', //(or dark)
            },
        });
    }

    invokeStripe() {
        if (!window.document.getElementById('stripe-script')) {
            const script = window.document.createElement('script');
            script.id = 'stripe-script';
            script.type = 'text/javascript';
            script.src = 'https://sdk.cashfree.com/js/ui/1.0.26/dropinClient.sandbox.js';
            script.onload = () => { };
            window.document.body.appendChild(script);
        }
    }

    userbasicinfo() {
        this._walletbalanceservice.userbasicinfo(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.user_info = data['data'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

}
