import { Component, OnInit } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from './category.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { ProductDetailsService } from '../product-details/product-details.service';

@Component({
    selector: 'app-category',
    templateUrl: './category.component.html',
    styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

    classstatus: boolean = false;
    public sort_by:string = ''
    public category_flow: any = [];
    public category_name: string;
    public category_slug: string = '';
    public total_listing: number;
    public total_supplier: number;
    public total_wholesale: number;
    public selected_brand: any = {};
    public total_bulk: number;
    public total_products: number;
    public brand_search: string;
    public top_selling_products: any = [];
    public fast_selling_products: any = [];
    public latest_products: any = [];

    public latest_arrival_banner:any = '';
    public fast_selling_banner:any = '';
    public top_selling_banner:any = '';
    public dynamic_section_banner:any = '';

    public latest_products_name: string = '';
    public fast_selling_name: string = '';
    public top_selling_name: string = '';

    public dynamic_section_products: any = [];
    public dynamic_section_name:string = '';
    public dynamic_section_status:string = 'no';
    public brands_list: any = [];
    public filter_brands: any = [];
    public filter_category: any = [];
    public filter_variants: any = [];
    public filter_attribute: any = [];
    public search_terms: any = [];
    public active_ids = 'toggle-1, toggle-2, toggle-3, brand, startingprice, activationstatus, supplylocation, countryorigin';
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private _categoryservice: CategoryService,
        private _productdetailsservice: ProductDetailsService,
        private _toastr: ToasterService
    ) { }
    minValue: number = 0;
    maxValue: number = 10000;
    options: Options = {
        floor: 0,
        ceil: 10000
    };
    public supply_locations = [
        {
            name: 'Chennai',
            slug: 'chennai',
        },
        {
            name: 'Mumbai',
            slug: 'mumbai',
        },
        {
            name: 'Delhi',
            slug: 'delhi'
        }
    ];
    public countries = [
        {
            name: 'Made in India',
            slug: 'india',
        },
        {
            name: 'Made in Japan',
            slug: 'japan',
        },
        {
            name: 'Made in Tamilnadu',
            slug: 'tn'
        }
    ];
    public filter_prices = [
        {
            min: 0,
            max: 5000,
            name: 'Below 5000',
        },
        {
            min: 5000,
            max: 10000,
            name: '5000 - 10000',
        },
        {
            min: 10000,
            max: 15000,
            name: '10000 - 15000',
        },
        {
            min: 15000,
            max: 20000,
            name: '15000 - 20000',
        },
        {
            min: 20000,
            max: 20000000,
            name: 'Above 20000',
        },
    ];
    ngOnInit() {
        this.category_slug = this.route.snapshot.paramMap.get('category_slug');
        this.getproducts();
    }
    initiatebid(uniqueId){
        this._productdetailsservice.viewsellerlisting = true;
        this.router.navigate(['/product/details/'+uniqueId]);
    }
    tglclass() {
        this.classstatus = !this.classstatus;
    }
    public val = null;
    config = {
        labelField: 'label',
        valueField: 'value',
    };

    redirectTo(uri: string) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate([uri]));
    }

    categorylink(slug) {
        this.redirectTo('/category/' + slug);
    }


    getproducts() {
        this._categoryservice.getproducts({ category_slug: this.category_slug }).subscribe((data) => {
            if (data['status'] == "success") {
                this.category_name = data['category_name'];
                this.category_flow = data['category_flow'];
                this.total_listing = data['list_count'];
                this.total_supplier = data['supplier_count'];
                this.total_wholesale = data['wholesale_count'];
                this.total_bulk = data['bulk_count'];
                this.total_products = data['product_count'];
                this.brands_list = data['brands'];
                if (this.brands_list.length > 0) {
                    this.selected_brand = this.brands_list[0];
                    //this.getbrandproduct(this.selected_brand);
                }

                this.fast_selling_banner = data['fast_seller_banner'];
                this.top_selling_banner = data['top_selling_banner'];
                this.latest_arrival_banner = data['latest_arrival_banner'];
                this.dynamic_section_banner = data['dynamic_section_banner'];

                this.fast_selling_products = data['fastselling_records']
                this.top_selling_products = data['topselling_records'];
                this.latest_products = data['latestarrival_records'];

                this.latest_products_name = data['latest_arrival_name'];
                this.top_selling_name = data['top_selling_name'];
                this.fast_selling_name = data['fast_selling_name'];

                this.dynamic_section_products = data['dynamic_section_records'];
                this.dynamic_section_name = data['dynamic_section_name'];
                this.dynamic_section_status = data['dynamic_section_status'];

                this.filter_brands = data['filter_brands'];
                this.filter_category = data['filter_category'];
                this.filter_variants = data['filter_variants'];
                this.filter_attribute = data['filter_attribute'];
                for (let i = 0; i < this.filter_variants.length; i++) {
                    this.active_ids = this.active_ids + ', ' + this.filter_variants[i].slug;
                }
                for (let i = 0; i < this.filter_attribute.length; i++) {
                    this.active_ids = this.active_ids + ', ' + this.filter_attribute[i].name;
                }
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getbrandproduct(brand) {
        this.selected_brand = brand;
        this._categoryservice.getbrandproduct({ brand_slug: brand.slug, category_slug: this.category_slug }).subscribe((data) => {
            if (data['status'] == "success") {
                this.fast_selling_products = data['brand_records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    public limit = 20;
    public offset = 0;
    public current_page = 1;

    gotosearch(type, value, value1 = null) {
        var queryparams: any = {};
        queryparams.q = this.category_name;
        queryparams.cat_page = this.category_slug;

        if (type == 'market_preference') {
            queryparams.maps = value;
        } else if (type == 'category') {
            queryparams.category = value;
        } else if (type == 'brands') {
            queryparams.brand = value;
        } else if (type == 'price') {
            queryparams.price = value + '-' + value1;
        } else if (type == 'price-slider') {
            queryparams.price = this.minValue + '-' + this.maxValue;
        } else if (type == 'variant') {
            queryparams.variant = value;
        } else if (type == 'technical') {
            queryparams.technical = value;
        } else if (type == 'supply_location') {
            queryparams.slo = value;
        } else if (type == 'country') {
            queryparams.country = value;
        }

        queryparams.limit = this.limit;
        queryparams.offset = this.offset;
        queryparams.current_page = this.current_page;
        this.router.navigate(['/search'], { queryParams: queryparams });
    }

    sorting_order = [
        {
            label: 'Relevance',
            value: 'relevance'
        },
        {
            label: 'Price Low to High',
            value: 'low'
        },
        {
            label: 'Price High to Low',
            value: 'high'
        },
        {
            label: 'Product Ratings',
            value: 'rating'
        },
        {
            label: 'Latest Arrivals',
            value: 'latest'
        }
    ];


    ratingst: boolean = false;
    ratingsort() {
        this.ratingst = !this.ratingst;
    }

    changesorting() {
        
    }

}
