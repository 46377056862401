import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { BuyerViewOrdersDetailsService } from './buyer-view-orders.service';

@Component({
    selector: 'app-buyer-view-order-details',
    templateUrl: './buyer-view-order-details.component.html',
    styleUrls: ['./buyer-view-order-details.component.scss']
})
export class BuyerViewOrderDetailsComponent implements OnInit {

    closeResult = '';
    public order_id:string;
    public order_details:any = {};
    public other_charges = ['Logistics Charges: INR 10,000', 'Convenience Charges: INR 12,000'];

    //return request
    public return_quantity:any = '';
    public return_reason:string = '';
    public return_message:string = '';
    public return_images:any = [];
    public return_updated_image:any = [];
    public return_status:string = '';

    constructor(
        private modalService: NgbModal,
        private _vieworderdetailsservice: BuyerViewOrdersDetailsService,
        private _route: ActivatedRoute,
        private _toastr: ToasterService
    ) { }

    ngOnInit(): void {
        this.order_id = this._route.snapshot.paramMap.get('orderId');
        this.getorderdetails();
    }

    getorderdetails(){
        this._vieworderdetailsservice.getorderdetails({id: this.order_id}).subscribe((data) => {
            if(data['status'] == "success"){
                this.order_details = data['records'];
                if(this.order_details.return_request){
                    this.return_quantity = this.order_details.return_request_info.quantity;
                    this.return_reason = this.order_details.return_request_info.reason;
                    this.return_message = this.order_details.return_request_info.message;
                    this.return_status = this.order_details.return_request_info.status;
                    this.return_updated_image = this.order_details.return_request_info.images;
                }
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    imageUpload(event: any) {
        for (var i = 0; i < event.target.files.length; i++) {
            this.return_images.push(event.target.files[i]);
        }
    }

    submitreturn(){
        let total_quantity = 0;
        if(this.order_details.parentchild != 'yes'){
            total_quantity = this.return_quantity;
        }else{
            for(let k = 0; k < this.order_details.parentchild_records.length; k++){
                var qty = this.order_details.parentchild_records[k].return_qty;
                if(qty){
                    total_quantity = total_quantity + qty;
                }
            }
        }
        if(total_quantity == 0){
            this._toastr.error('Please enter return quantity');
            return false;
        }
        if(!this.return_reason){
            this._toastr.error('Please enter return reason');
            return false;
        }
        let data: any = new FormData();
        let child_records = [];
        if(this.order_details.parentchild == 'yes'){
            child_records = this.order_details.parentchild_records;
        }
        data.append('child_records', JSON.stringify(child_records));
        data.append('meta_id', this.order_id);
        data.append('quantity', this.return_quantity);
        data.append('reason', this.return_reason);
        data.append('message', this.return_message);
        for (var i = 0; i < this.return_images.length; i++) {
            data.append("file[]", this.return_images[i]);
        }
        this._vieworderdetailsservice.returnrequest(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.return_images = [];
                this.return_quantity = '';
                this.return_reason = '';
                this.return_message = '';
                this._toastr.success('Return request has been submitted successfully.');
                this.modalService.dismissAll();
                this.getorderdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        }, (error) => {
            this._toastr.error(data['message']);
        });
    }

    open(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true,}).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    openWindowCustomClass(content) {
        this.modalService.open(content, { windowClass: 'modal-500' });
    }

    openWindowReturnRequest(content) {
        this.modalService.open(content, { windowClass: 'midmodal-lg', size: 'lg', centered: true, });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    imagesSlider = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: ".thumbs",
    };
    thumbnailsSlider = {
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: true,
        focusOnSelect: true,
        asNavFor: ".feedback",
        //prevArrow:'.client-thumbnails .prev-arrow',
        // nextArrow:'.client-thumbnails .next-arrow',
    };
    slides = [
        { img: "assets/images/admin/mfront.png" },
        { img: "assets/images/admin/back.png" },
        { img: "assets/images/admin/top.png" },

        { img: "assets/images/admin/side.png" }
    ];

}
