import { Component, OnInit } from '@angular/core';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { BuyerOrderHistoryService } from './buyer-order-history.service';

@Component({
  selector: 'app-buyer-order-history',
  templateUrl: './buyer-order-history.component.html',
  styleUrls: ['./buyer-order-history.component.scss']
})
export class BuyerOrderHistoryComponent implements OnInit {

    public search_term:string = '';
    public from_date:any = '';
    public to_date:any = '';
    constructor(
        private _toastr: ToasterService,
        private _pagination: PaginationService,
        private _orderhistoryservice: BuyerOrderHistoryService,
    ) { }

    public order_type:string = 'all';

    //All Buyer Orders
    public buyer_orders_count:number = 0;
    public buyer_orders: any = [];

    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 10;
    public buyer_order_pagination: any = {};
    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];

    ngOnInit(): void {
        this.getbuyerorders('all');
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.buyerorders();
    }

    setpageperview() {
        this.current_page = 1;
        this.buyerorders();
    }

    getbuyerorders(type) {
        this.search_term = '';
        this.from_date = '';
        this.to_date = '';
        this.order_type = type;
        this.page_per_view = 10;
        this.offset = 0;
        this.current_page = 1;
        this.buyerorders();
    }

    getbuyerordersfilters(){
        this.page_per_view = 10;
        this.offset = 0;
        this.current_page = 1;
        this.buyerorders();
    }

    buyerorders() {
        var type:any = 'all';
        if(this.order_type == 'booked'){
            type = 1
        }else if(this.order_type == 'approved'){
            type = 2
        }else if(this.order_type == 'rejected'){
            type = 3
        }else if(this.order_type == 'payment_done'){
            type = 4
        }else if(this.order_type == 'ready_to_dispatch'){
            type = 6
        }else if(this.order_type == 'quality_done'){
            type = 7
        }else if(this.order_type == 'dispatched'){
            type = 8
        }else if(this.order_type == 'delivered'){
            type = 9
        }

        let data = { 
            limit: this.page_per_view, 
            offset: this.offset, 
            current_page: this.current_page, 
            type: type,
            search: this.search_term,
            from_date: this.from_date,
            to_date: this.to_date
        };
        this._orderhistoryservice.buyerorders(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.buyer_orders = data['records'];
                this.buyer_orders_count = data['total_records'];
                let total_records = data['total_records'];
                this.buyer_order_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

}
