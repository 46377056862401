import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ViewBidService {

    constructor(private _http: HttpClient) { }

    getproductbid(data) {
        return this._http.post(environment.api_url+'/seller/bids/orders/details',data);
    }

    acceptbid(data) {
        return this._http.post(environment.api_url+'seller/bids/accept',data);
    }

    savecounteroffer(data){
        return this._http.post(environment.api_url+'seller/bids/counteroffer',data);
    }

    getbidinfo(data) {
        return this._http.post(environment.api_url+'seller/bids/info',data);
    }

    declinebid(data) {
        return this._http.post(environment.api_url+'seller/bids/decline',data);
    }

}
