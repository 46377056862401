<section class="w-h bg-blue pt-0">
  <div class="equibiz-header">
      <div class="logo-bar">
        <div class="container">
          <div class="row d-flex justify-content-center">
             <div class="col-md-12 text-center">
                <a [routerLink]="['/home']"><img src="assets/images/svg/eq_logo.svg"></a>
             </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
	    <!--Start admin card login-->
	    <div class="admin-login popup-container">
	      <div class="modal-body text-center">
	      	<div class="mb-30"><img src="assets/images/icons/warning.png"></div>
	      	<div class="fs16 f-700 mb-20" *ngIf="user_reg_status == 'yes' && user_status == 'no'">Your account is pending for approval from admin.</div>
	      		<a href="javascript:;" (click)="logout()" class="btn btn-mod btn-color btn-medium btn-round ripple">Logout</a>

	          	<div class="fs16 f-700" *ngIf="user_reg_status == 'no'">Please complete your registration to get onboarded into our portal. <a href="javascript:;" [routerLink]="['/registration/seller/details']">Go To Registration</a></div>
	      </div>
	  </div>
	</div>
</section>