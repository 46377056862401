<app-header-seller></app-header-seller>
<app-sidebar-seller></app-sidebar-seller>
<div class="wrpmain">
	<div class="container">

		<!--- HEAD  --->
		<div class="row align-items-center justify-content-between mb-4 pinfo-head">
			<div class="col-md-6 col-7">
				<h3 class="fs24 f-700 txtdefault">Hello {{ user_name }},</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">Welcome to Equibiz Platform</li>
					</ol>
				</nav>
			</div>
			<!-- <div class="col-md-4 col-5 text-right">
				<div class="form-container mt-2 mb-0">
               <div class="report-input floating-label">
                  <div class="input-group-custom">
                     <input type="text" value="Last 30 Days" class="form-control" placeholder=" " />
                     <span class="highlight"></span>
                     <label>Date</label>
                     <div class="input-group-append-custom">
                        <img src="assets/images/icons/filter-calendar.svg">
                     </div>
                  </div>
               </div>
            </div>
			</div> -->
		</div>
		<div class="us-tab">
            <nav ngbNav #nav="ngbNav" class="nav-tabs">
                <ng-container ngbNavItem>
                    <a href="javascript:;" class="nav-link" [routerLink]="['/seller/dashboard']">My Dashboard</a>
                </ng-container>
                <ng-container ngbNavItem>
                    <a href="javascript:;" class="nav-link active">Sub-Seller Dashboard</a>
                </ng-container>
            </nav>
        </div>
		
		<div class="row">
         	<div class="col-md-12">
	            <div class="card cardui carduipro p-4 mb-20">
	               <div class="row">
	                  <div class="col-md-3">
	                     <div class="fs16 f-700 mb-20">Sales Overview</div>
	                  </div>
	                  <div class="col-md-9">
	                     <div class="row">
							<div class="col-md-8">
							</div>		
							<div class="col-md-4">
								<ng-selectize [config]="config" [options] = "months_array" placeholder="" [(ngModel)]="sales_overview_filter" (ngModelChange)="mydashboarddetails('sales_overview')">
								</ng-selectize>	
							</div>		
						 </div>
	                  </div>
	               </div>
	               <div class="row align-items-center">
	                  <div class="col-md-3 mb-xs-20">
	                     <div class="dash-left">
	                        <div class="d-flex align-items-center justify-content-between mb-2">
	                           <div><img src="assets/images/icons/bag.png"></div>
	                           <!-- <div class="key-value">+ 25.27%</div> -->
	                        </div>
	                        <div class="fs24 f-700">{{ total_sales }}</div>
	                        <div class="text-muted fs16 f-500">Sales</div>

	                        <hr>

	                        <div class="d-flex align-items-center justify-content-between mb-2">
	                           <div><img src="assets/images/icons/money.png"></div>
	                           <!-- <div class="key-value">+ 35.18%</div> -->
	                        </div>
	                        <div class="fs24 f-700">{{ total_orders }}</div>
	                        <div class="text-muted fs16 f-500">Total Order</div>
	                     </div>
	                  </div>
	                  <div class="col-md-9">
	                     <div>
							<div id="chart">
								<apx-chart
									[series]="chartOptions.series"
									[chart]="chartOptions.chart"
									[xaxis]="chartOptions.xaxis"
									[dataLabels]="chartOptions.dataLabels"
									[grid]="chartOptions.grid"
									[stroke]="chartOptions.stroke"
									[title]="chartOptions.title"
								></apx-chart>
							</div>
	                     </div>
	                  </div>
	               </div>
	            </div>
         	</div>
      	</div>

      	<div class="row">
         	<div class="col-md-12">
	            <div class="card cardui carduipro p-4 mb-20">
					<div class="row d-flex align-items-center mb-20">
					<div class="col-md-8">
						<div class="fs16 f-700 mb-0">Pending Orders for Acceptance</div>
					</div>		
					<div class="col-md-4">
						<ng-selectize [config]="config" [options] = "months_array" placeholder="" [(ngModel)]="pending_order_filter" (ngModelChange)="mydashboarddetails('pending_order')">
						</ng-selectize>	
					</div>		
				 </div>
	               <div class="row">
	                  <div class="col-md-3">
	                     <div class="dash-left mb-20 mb-xs-10">
	                        <a href="javascript:;">
	                           <!-- <div class="d-flex align-items-center justify-content-between">
	                              <div><img src="assets/images/chart-02.png"></div>
	                              <div><img src="assets/images/icons/right-arrow.png"></div>
	                           </div> -->
	                           <div class="fs24 f-700 text-dark">{{ total_pending }}</div>
	                           <div class="text-muted fs16 f-500">Total Pending</div>
	                        </a>
	                     </div>
	                  </div>
	                  <div class="col-md-3">
	                     <div class="dash-left mb-20 mb-xs-10">
	                        <a href="javascript:;">
	                           <!-- <div class="d-flex align-items-center justify-content-between">
	                              <div><img src="assets/images/chart-02.png"></div>
	                              <div><img src="assets/images/icons/right-arrow.png"></div>
	                           </div> -->
	                           <div class="fs24 f-700 text-dark">{{ bulk_orders }}</div>
	                           <div class="text-muted fs16 f-500">Bulk Orders</div>
	                        </a>
	                     </div>
	                  </div>
	                  <div class="col-md-3">
	                     <div class="dash-left mb-20 mb-xs-10">
	                        <a href="javascript:;">
	                           <!-- <div class="d-flex align-items-center justify-content-between">
	                              <div><img src="assets/images/chart-02.png"></div>
	                              <div><img src="assets/images/icons/right-arrow.png"></div>
	                           </div> -->
	                           <div class="fs24 f-700 text-dark">{{ wholesale_orders }}</div>
	                           <div class="text-muted fs16 f-500">Wholesale Orders</div>
	                        </a>
	                     </div>
	                  </div>
	                  <div class="col-md-3">
	                     <div class="dash-left mb-20 mb-xs-10">
	                        <a href="javascript:;">
	                           <!-- <div class="d-flex align-items-center justify-content-between">
	                              <div><img src="assets/images/chart-02.png"></div>
	                              <div><img src="assets/images/icons/right-arrow.png"></div>
	                           </div> -->
	                           <div class="fs24 f-700 text-dark">{{ rejected_orders }}</div>
	                           <div class="text-muted fs16 f-500">Rejected Orders</div>
	                        </a>
	                     </div>
	                  </div>
	               </div>


				   <div class="card card-ui card-uiovt byregwrp table-card" *ngIf="pending_orders_records.length > 0">
					<div class="table-responsive">
						<table class="table custom-table">
						  <thead>
							  <tr>
								<th>Product Name</th>
								<th>Order ID</th>
								<th>Transaction ID</th>
								<th>Deal Date</th>
								<th>Deal Amt</th>
								<th>Order Qty</th>
								<th>View Order</th>
							  </tr>
						  </thead>
						  <tbody>
							<!-- REPEAT -->
							<tr *ngFor="let order of pending_orders_records;">
								<td>
									<div class="order-info">
										<div class="order-img">
											<img src="{{ order.product_image }}" />
										</div>
										<div class="pd-seller-cntnt">
											<div class="txtdefault">
												<a href="javascript:;" class="order-link">{{ order.product_name }}</a>
											</div>
										</div>
									</div>
									<div class="pd-taglist">
										<span class="f-600 fs10 radius15">{{ order.marketplace}}</span>
										<span class="f-600 fs10 radius15">{{ order.dispatched_in }}</span>
									</div>
								</td>
								<td><a href="javascript:;" class="order-link">{{ order.order_id }}</a></td>
								<td><a href="javascript:;" class="order-link">{{ order.transaction_id }}</a></td>
								<td>{{ order.booking_on }}</td>
								<td>{{ order.booked_amount }}</td>
								<td>{{ order.order_qty }}</td>
								<td>
									<a href="javascript:void(0);" [routerLink]="['/seller/view-order-details/'+order.id]" class="btn-d-outline ripple d-inline-block place-bid">View Order</a>
								</td>
							  </tr>
							<!-- /REPEAT -->
						  </tbody>
						</table>
					</div> 
				</div>
		
				<div class="row mb-40"  *ngIf="pending_order_pagination.totalPages > 1">
					<div class="col-md-6">
						<div class="text-left pl-3">
							<nav class="mt-3 tbpg rows-per-page">
								<div>Rows per page:</div>
								<select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
									<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">{{option.name}}</option>
								</select>
							</nav>
						</div> 
					</div>
					<div class="col-md-6" *ngIf="pending_order_pagination.pages && pending_order_pagination.pages.length">
						<div class="text-right md-text-left pr-3">
							<nav class="mt-3 tbpg">
							  <ul class="pagination align-items-center">
								<li class="page-item" [ngClass]="{disabled:pending_order_pagination.currentPage === 1}"><a class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a></li>
		
								<li class="page-item" [ngClass]="{disabled:pending_order_pagination.currentPage === 1}"><a class="page-link sprevicon" href="javascript:;" (click)="setpage(pending_order_pagination.currentPage - 1)"></a></li>
		
								<li class="page-item"i *ngFor="let page of pending_order_pagination.pages" [ngClass]="{active:pending_order_pagination.currentPage === page}"><a class="page-link" href="javascript:;" (click)="setpage(page)">{{page}}</a></li>
		
								<li *ngIf="(pending_order_pagination.totalPages - pending_order_pagination.startPage) > 5 ">
									<a (click)="setpage(pending_order_pagination.startPage + 5)">...</a>
								</li>
								 <li *ngIf="(pending_order_pagination.totalPages - pending_order_pagination.startPage) > 5" >
									<a (click)="setpage(pending_order_pagination.totalPages)">{{pending_order_pagination.totalPages}}</a>
								</li>
								
								<li class="page-item" [ngClass]="{disabled:pending_order_pagination.currentPage === pending_order_pagination.totalPages}"><a class="page-link snexticon" href="javascript:;"  (click)="setpage(pending_order_pagination.currentPage + 1)"></a></li>
								<li class="page-item" [ngClass]="{disabled:pending_order_pagination.currentPage === pending_order_pagination.totalPages}"><a class="page-link nexticon" href="javascript:;" (click)="setpage(pending_order_pagination.totalPages)"></a></li>
							  </ul>
							</nav>
						</div> 
					</div>
				</div>



	            </div>
         	</div>
      	</div>

		  <div class="row">
			<div class="col-md-12">
			   <div class="card cardui carduipro p-4 mb-20">
				<div class="row d-flex align-items-center mb-20">
					<div class="col-md-8">
						<div class="fs16 f-700 mb-0">Orders</div>
					</div>		
					<div class="col-md-4">
						<ng-selectize [config]="config" [options] = "months_array" placeholder="" [(ngModel)]="order_filter" (ngModelChange)="mydashboarddetails('orders')">
						</ng-selectize>	
					</div>		
				 </div>

				  <div class="row">
					 <div class="col-md-3">
						<div class="dash-left mb-xs-10 mb-20">
						   <a href="javascript:;">
							  <div class="fs24 f-700 text-dark">{{ orders_details.total_order }}</div>
							  <div class="text-muted fs16 f-500">All Orders</div>
						   </a>
						</div>
					 </div>
					 <div class="col-md-3">
						<div class="dash-left mb-xs-10 mb-20">
						   <a href="javascript:;">
							  <div class="fs24 f-700 text-dark">{{ orders_details.booked }}</div>
							  <div class="text-muted fs16 f-500">Booked Orders</div>
						   </a>
						</div>
					 </div>
					 <div class="col-md-3">
						<div class="dash-left mb-xs-10 mb-20">
						   <a href="javascript:;">
							  <div class="fs24 f-700 text-dark">{{ orders_details.approved }}</div>
							  <div class="text-muted fs16 f-500">Approved Orders</div>
						   </a>
						</div>
					 </div>
					 <div class="col-md-3">
						<div class="dash-left mb-xs-10 mb-20">
						   <a href="javascript:;">
							  <div class="fs24 f-700 text-dark">{{ orders_details.rejected }}</div>
							  <div class="text-muted fs16 f-500">Rejected Orders</div>
						   </a>
						</div>
					 </div>

					 <div class="col-md-3">
						<div class="dash-left mb-xs-10 mb-20">
						   <a href="javascript:;">
							  <div class="fs24 f-700 text-dark">{{ orders_details.payment_done }}</div>
							  <div class="text-muted fs16 f-500">Payment Done</div>
						   </a>
						</div>
					 </div>

					 <div class="col-md-3">
						<div class="dash-left mb-xs-10 mb-20">
						   <a href="javascript:;">
							  <div class="fs24 f-700 text-dark">{{ orders_details.ready_to_dispatch }}</div>
							  <div class="text-muted fs16 f-500">Ready To Dispatch</div>
						   </a>
						</div>
					 </div>

					 <div class="col-md-3">
						<div class="dash-left mb-xs-10 mb-20">
						   <a href="javascript:;">
							  <div class="fs24 f-700 text-dark">{{ orders_details.qc_done }}</div>
							  <div class="text-muted fs16 f-500">Quality Done</div>
						   </a>
						</div>
					 </div>

					 <div class="col-md-3">
						<div class="dash-left mb-xs-10 mb-20">
						   <a href="javascript:;">
							  <div class="fs24 f-700 text-dark">{{ orders_details.dispatched }}</div>
							  <div class="text-muted fs16 f-500">Dispatched</div>
						   </a>
						</div>
					 </div>

					 <div class="col-md-3">
						<div class="dash-left mb-xs-10 mb-20">
						   <a href="javascript:;">
							  	<div class="fs24 f-700 text-dark">{{ orders_details.delivered }}</div>
							  	<div class="text-muted fs16 f-500">Delivered</div>
						   </a>
						</div>
					 </div>
				  </div>



			   </div>
			</div>
		 </div>

		
		 <div class="card cardui carduipro p-4 mb-0 table-card">
			<div class="notifications-container theme-tab xs-scroller ">
				  <ngb-tabset [justify]="'left'" [type]="'pills'" class="tabs tab-title">
					<!-- All Orders -->
					<ngb-tab>
						<ng-template ngbTabTitle><span (click)="sellerorders()">All Orders</span></ng-template>
					</ngb-tab>							
				</ngb-tabset>
			</div>

			<div class="tab-content">
				<!--Filters-->
				<div class="row d-flex justify-content-between mt-20 report-cta">
				  <div class="col-md-5">
					  <div class="report-input">
						  <div class="input-group-custom">
							<input type="text" placeholder="Search by Product name, Order ID & Transaction ID" 
							class="form-control" [(ngModel)]="search_term" (change)="getsellerordersfilters()">
							  <div class="input-group-append-custom">
								  <img src="assets/images/svg/search_logo.svg">
							  </div>
						  </div>
					  </div>
				  </div>

				  <div class="col-md-7 mt-xs-10">
					  <div class="d-flex justify-content-end">
							  <div class="form-container mt-0 mb-0 mr-3">
							  <div class="report-input floating-label">
									<div class="input-group-custom">
										<input type="text" [(ngModel)]="from_date" (ngModelChange) = "getsellerordersfilters()" class="form-control" placeholder=" " ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" autocomplete="off"/>
										<span class="highlight"></span>
										<label>From Date</label>
										<div class="input-group-append-custom">
											<img src="assets/images/icons/filter-calendar.svg">
										</div>
									</div>
							  </div>
							  </div>

							  <div class="form-container mt-0 mb-0 mr-3">
								<div class="form-container m-0">
									<div class="report-input floating-label">
										<div class="input-group-custom">
											<input type="text" [(ngModel)]="to_date" (ngModelChange) = "getsellerordersfilters()" class="form-control" placeholder=" " ngbDatepicker #dd="ngbDatepicker" (click)="dd.toggle()" autocomplete="off"/>
											<span class="highlight"></span>
											<label>To Date</label>
											<div class="input-group-append-custom">
												<img src="assets/images/icons/filter-calendar.svg">
											</div>
										</div>
									</div>
								</div>
							</div>

						  <!-- <div>
							  <a href="javascript:void(0);" class="btn-d-outline d-inline-block">
								  <svg xmlns="http://www.w3.org/2000/svg" width="12.681" height="17.061" viewBox="0 0 12.681 17.061">
									<g id="np_export_2535025_000000" transform="translate(-32.121 -25.962)">
									  <path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#ff7135"/>
									  <path id="Path_2356" data-name="Path 2356" d="M44.093,70.07H32.83a.709.709,0,1,0,0,1.419H44.093a.709.709,0,0,0,0-1.419Z" transform="translate(0 -28.466)" fill="#ff7135"/>
									</g>
								  </svg>
								  Export
							  </a>
						  </div> -->
					  </div>
				  </div>
			  </div>
			  <!--End Filters-->
			  <table class="table custom-table xs">
				  <thead>
					  <tr>
						  <th>Order Id</th>
						  <th>Transaction Id</th>
						  <th width="18%">Product Name</th>
						  <th>Market Place</th>
						  <th>Price (in ₹)</th>
						  <th>Pre-Booked On <i class="long-arrow-down"></i></th>
						  <th>Order Status</th>
						  <th>Action</th>
					  </tr>
				  </thead>
				  <tbody>
					  <!--Empty State-->
					  <tr *ngIf="!seller_orders.length">
						  <td colspan="8">
							  <div class="row pt-40 pb-40">
								  <div class="col-md-12 text-center">
									  <div class="mb-20"><img src="/assets/images/empty-state.png">
									  </div>
									  <div class="fs16">There are no records to display!</div>
								  </div>
							  </div>
						  </td>
					  </tr>
					  <!--End Empty State-->
					  <tr *ngFor="let record of seller_orders;">
						  <td>{{ record.order_id }}</td>
						  <td>{{ record.transaction_id }}</td>
						  <td>
							  <div class="order-info">
								  <div class="order-img">
									  <img src="{{ record.product_image }}" />
								  </div>
								  <div class="pd-seller-cntnt">
									  <div class="txtdefault">
										  <a href="javascript:;" class="order-link">{{ record.product_name }}</a>
									  </div>
								  </div>
							  </div>
						  </td>
						  <td>{{ record.map_type }}</td>
						  
						  <td>{{ record.total_amount }}</td>
						  <td>{{ record.prebookedon }}</td>											
						  <td><div>{{ record.order_status }}</div>
							  <!-- <span class="fs10 radius10 badge badge-d-outline">Dispatch in 16:00 hrs</span>
							  <span class="fs10 radius10 badge badge-d-outline dspatchbadge-d-outline">Delayed in 2 days</span> -->
						  </td>
						  
						  <td class="d-flex align-items-center">
							  <a href="javascript:void(0);" [routerLink]="['/seller/view-order-details/'+record.id]" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Order</a>
							  <!-- <a href="javascript:;"><img src="assets/images/icons/exclamation.svg"></a> -->
						  </td>
					  </tr>
				  </tbody>
			  </table>
			</div>
			
		</div>
		
		<div class="row mb-40" *ngIf="seller_order_pagination.totalPages> 1">
			<div class="col-md-6">
				<div class="text-left pl-3">
					<nav class="mt-3 tbpg rows-per-page">
						<div>Rows per page:</div>
						<select class="select-box" [(ngModel)]="page_per_view" (change)="setsellerpageperview()">
							<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">
								{{option.name}}</option>
						</select>
					</nav>
				</div>
			</div>
			<div class="col-md-6">
				<div class="text-right md-text-left pr-3">
					<nav class="mt-3 tbpg">
						<ul class="pagination align-items-center">
							<li class="page-item" [ngClass]="{disabled:seller_order_pagination.currentPage === 1}"><a
									class="page-link previcon" href="javascript:;" (click)="setsellerpage(1)"></a>
							</li>

							<li class="page-item" [ngClass]="{disabled:seller_order_pagination.currentPage === 1}"><a
									class="page-link sprevicon" href="javascript:;"
									(click)="setsellerpage(seller_order_pagination.currentPage - 1)"></a></li>

							<li class="page-item" i *ngFor="let page of seller_order_pagination.pages"
								[ngClass]="{active:seller_order_pagination.currentPage === page}"><a class="page-link"
									href="javascript:;" (click)="setsellerpage(page)">{{page}}</a></li>

							<li *ngIf="(seller_order_pagination.totalPages - seller_order_pagination.startPage) > 5 ">
								<a (click)="setsellerpage(seller_order_pagination.startPage + 5)">...</a>
							</li>
							<li *ngIf="(seller_order_pagination.totalPages - seller_order_pagination.startPage) > 5">
								<a
									(click)="setsellerpage(seller_order_pagination.totalPages)">{{seller_order_pagination.totalPages}}</a>
							</li>

							<li class="page-item"
								[ngClass]="{disabled:seller_order_pagination.currentPage === seller_order_pagination.totalPages}">
								<a class="page-link snexticon" href="javascript:;"
									(click)="setsellerpage(seller_order_pagination.currentPage + 1)"></a>
							</li>
							<li class="page-item"
								[ngClass]="{disabled:seller_order_pagination.currentPage === seller_order_pagination.totalPages}">
								<a class="page-link nexticon" href="javascript:;"
									(click)="setsellerpage(seller_order_pagination.totalPages)"></a>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</div>




	</div>
</div>
<app-footer-seller></app-footer-seller>


