import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductDetailsService } from './product-details.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { BidOffersService } from '../bids-offers/bid-offers.service';
import { SignupService } from 'src/app/shared/components/modal/signup-modal/signup-modal.service';
import { SignupModalComponent } from 'src/app/shared/components/modal/signup-modal/signup-modal.component';
import { PaginationService } from 'src/app/helpers/pagination.service';

@Component({
    selector: 'app-product-details',
    templateUrl: './product-details.component.html',
    styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {

    @ViewChild("signIn") SignIn: SignupModalComponent;
    public review_records: any = [];
    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 20;
    public review_pagination: any = {};

    public page_per_view_options = [
        {
            value: 20,
            name: 20
        },
        {
            value: 40,
            name: 40
        }
    ];

    public filter_by_rating:any = [];
    public sort_by_rating:string = 'date_desc';
    public star_count:number;
    public rating_total_records:number;
    public modalInsuranceReference:any;
    public enable_insurance: boolean = false;
    public supply_location:string;
    closeResult = '';
    public selected_delivery: string = 'standard';
    public bid_quantity: number;
    public user_status: string;
    public unique_id: string;
    public user_token: string;
    public user_reg_status;
    public add_more_listing = false;
    public clear_all_listing = false;
    public compare_listing = true;
    public compare_tab = 'bulk';
    public details: any = {};
    public selected_variants: any = [];
    public wholesale_records: any = [];
    public bulk_records: any = [];
    public selected_bid: any = {};
    public warranty: any = [];
    public more_products: any = [];
    public brand_logo: string;

    public technical_feature: any = [];
    public chunked: any = [];

    public express_records:any = [];
    public standard_city_records:any = [];
    public standard_outsidecity_records:any = [];

    constructor(
        private modalService: NgbModal,
        private _route: ActivatedRoute,
        private _router: Router,
        private _productdetailsservice: ProductDetailsService,
        private _toastr: ToasterService,
        private _bidofferservice: BidOffersService,
        private _signupservice: SignupService,
        private _pagination: PaginationService
    ) { }

    ngOnInit(): void {
        this.unique_id = this._route.snapshot.paramMap.get('uid');
        this.user_status = this._signupservice.getuserstatus();
        this.user_token = this._signupservice.gettoken();
        this.user_reg_status = this._signupservice.getuserregstatus();
        this.getproductdetails();
        this.getreviews();
        if(this._productdetailsservice.viewsellerlisting){
            setTimeout( () => {
                this.focussellerlisting();
            }, 1000);
            //this._productdetailsservice.viewsellerlisting = false;
        }
    }

    getlogisticscharges() {
        this._productdetailsservice.getlogisticscharges(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.express_records = data['expressrecord'];
                this.standard_city_records = data['standardwithincity'];
                this.standard_outsidecity_records = data['standardoutsidecity'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    wishlist() {
        if (this.user_token) {
            this._productdetailsservice.wishlist({ unique_id: this.unique_id }).subscribe((data) => {
                if (data['status'] == "success") {
                    this.details.wishlist = data['wishlist'];
                    this._toastr.success(data['msg']);
                } else if (data['status'] == "error") {
                    this._toastr.error(data['message']);
                }
            });
        } else {
            this.SignIn.openSigninModal('home')
        }
    }

    getmoreproducts() {
        this._productdetailsservice.moreproducts({ unique_id: this.unique_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.more_products = data['records'];
                this.brand_logo = data['brand_logo'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    reviewsorting(){
        this.offset = 0;
        this.current_page = 1;
        this.getreviews();
    }

    getreviews() {
        this._productdetailsservice.getreviews({ limit: this.page_per_view, offset: this.offset, current_page: this.current_page, unique_id: this.unique_id, rating: this.filter_by_rating, sorting: this.sort_by_rating, product_id: '', seller_id: '' }).subscribe((data) => {
            if (data['status'] == "success") {
                this.review_records = data['records'];
                let total_records = data['total_records'];
                this.rating_total_records = data['total_records'];
                this.star_count = data['star_count'];
                this.review_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.getreviews();
    }

    setpageperview() {
        this.current_page = 1;
        this.getreviews();
    }

    updatereviewfilters(val){
        if(this.filter_by_rating.indexOf(val) !== -1){
            var index = this.filter_by_rating.indexOf(val);
            this.filter_by_rating.splice(index, 1);
        } else{
            this.filter_by_rating.push(val);
        }
        this.reviewsorting();
    }

    redirectTo(uri: string) {
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this._router.navigate([uri]));
    }

    moreproductlink(slug) {
        this.redirectTo('/product/details/' + slug);
    }

    focussellerlisting() {
        document.getElementById("wishlistDiv").scrollIntoView();
    }

    changelisting(listing) {
        listing.selected = !listing.selected;
    }

    changecomparetab(tab) {
        this.compare_tab = tab;
    }



    getproductdetails(searchFilters = null, selectedSlug = null) {
        this._productdetailsservice.getproductdetails({ unique_id: this.unique_id, filters: searchFilters, selectedSlug }).subscribe((data) => {
            if (data['status'] == "success") {
                this.details = data['record'];
                this.chunked = [];
                let size = 2;
                for (let i = 0; i < this.details.technical_feature.length; i += size) {
                    this.chunked.push(this.details.technical_feature.slice(i, i + size))
                }

                this.slides = [];
                if (this.details.front_image) {
                    this.slides.push({ 'img': this.details.front_image });
                }
                if (this.details.back_image) {
                    this.slides.push({ 'img': this.details.back_image });
                }
                if (this.details.top_image) {
                    this.slides.push({ 'img': this.details.top_image });
                }
                if (this.details.side_image) {
                    this.slides.push({ 'img': this.details.side_image });
                }
                if (this.details.sales_image) {
                    this.slides.push({ 'img': this.details.sales_image });
                }
                this.getmoreproducts();
                setTimeout(() => {
                    if (this.user_token && this.user_reg_status == 'yes') {
                        this.getproductlisting(data['record']['id']);
                    }
                    this.getlogisticscharges();
                }, 1000);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getselectedvariant(variant, meta) {
        for (let i = 0; i < meta.meta.length; i++) {
            if (variant.slug == meta.meta[i].slug) {
                meta.meta[i].selected = true;
            } else {
                meta.meta[i].selected = false;
            }
        }
        this.selected_variants = [];
        for (let i = 0; i < this.details.product_variant.length; i++) {
            var selected_variant = this.details.product_variant[i].meta.filter((product) => product.selected == true);
            if (selected_variant) {
                this.selected_variants.push(selected_variant[0].slug);
            }
        }
        this.getproductdetails(this.selected_variants, variant);
    }

    getproductlisting(product_id) {
        this._productdetailsservice.getproductlisting({ unique_id: this.unique_id, product_id: product_id }).subscribe((data) => {
            if (data['status'] == "success") {
                if (this.user_status == 'yes') {
                    this.wholesale_records = data['wholesale_records'];
                    this.bulk_records = data['bulk_records'];
                } else {
                    this.wholesale_records = [];
                    this.bulk_records = [];
                }

            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    addtocart(type, sp_id, map_type) {
        this._productdetailsservice.addtocart({ unique_id: this.unique_id, sp_id: sp_id, map_type: map_type }).subscribe((data) => {
            if (data['status'] == "success") {
                if (data['exist'] == true) {
                    this._toastr.error(data['msg']);
                    this._router.navigate(['/cart']);
                } else {
                    if (type == 'buynow') {
                        this._toastr.success('Product added in cart.');
                        this._router.navigate(['/cart']);
                    } else {
                        this._toastr.success('Product added in cart.');
                    }
                }

            } else if (data['status'] == "already") {
                this._router.navigate(['/cart']);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    ratingclassstatus: boolean = false;
    ratingtglclass() {
        this.ratingclassstatus = !this.ratingclassstatus;
    }

    showcomperr: boolean = false;
    displaycompare = 1;
    showcompmsg() {
        if (!this.showcomperr) {
            this.showcomperr = true;
        } else {
            if (this.compare_tab == 'bulk') {
                var records = this.bulk_records.filter((product) => product.selected == true);
                if (records.length < 2) {
                    this._toastr.error('Please select atleast 2 sellers');
                    return false;
                }
                this.displaycompare = 2;
            } else {
                var records = this.wholesale_records.filter((product) => product.selected == true);
                if (records.length < 2) {
                    this._toastr.error('Please select atleast 2 sellers');
                    return false;
                }
                this.displaycompare = 2;
            }
        }
    }

    clearalllisting() {
        this.displaycompare = 1;
        this.showcomperr = false;
        if (this.compare_tab == 'bulk') {
            var records = this.bulk_records.filter((product) => product.selected = false);
        } else {
            var records = this.wholesale_records.filter((product) => product.selected = false);
        }
    }

    addmorelisting() {
        this.displaycompare = 1;
    }
    imagesSlider = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: ".thumbs",
    };
    thumbnailsSlider = {
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: true,
        focusOnSelect: true,
        asNavFor: ".feedback",
        //prevArrow:'.client-thumbnails .prev-arrow',
        // nextArrow:'.client-thumbnails .next-arrow',
    };

    slides = [];

    open(content, listing) {
        this._productdetailsservice.getbiddetails({ sp_id: listing.sp_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.selected_bid = data['record'];
                this.selected_bid.bid_amount = '';
                this.modalService.dismissAll();
                this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    openlogistics(content) {
        this.modalService.open(content, {
            ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, windowClass: 'modal-ui'
        }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    placebid() {
        this._productdetailsservice.savebiddetails({insurance: this.enable_insurance, logistics_type: this.selected_delivery, supply_location: this.supply_location, sp_id: this.selected_bid.sp_id, amount: this.selected_bid.bid_amount, quantity: this.bid_quantity }).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this._bidofferservice.bid_placed = true;
                this._bidofferservice.bid_id = data['bid_id'];
                this._router.navigate(['/buyer/bids-offers/' + data['product_id']]);
            } else if (data['status'] == "error") {
                //this.modalService.dismissAll();
                this._toastr.error(data['message']);
            }
        });
    }

    changedelivery(type) {
        this.selected_delivery = type;
    }

    openinsurance(contentinsurance, type = 'button') {
        if (this.enable_insurance && type == 'button') {
            this.enableinsurance('no', contentinsurance);
        } else {
            this.modalInsuranceReference = this.modalService.open(contentinsurance, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, }).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }

    enableinsurance(type = 'no', contentinsurance) {
        if(type == 'no'){
            this.enable_insurance = false;
        }else{
            this.enable_insurance = true;
        }
        //this.modalService.dismissAll();
    }

    changesupplylocation(index){
        this.supply_location = index;
    }

    changebillingaddress(){
        this.modalService.dismissAll();
        this._router.navigate(['/buyer/business-details']);
    }
}
