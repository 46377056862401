import { Component, OnInit } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { ActivatedRoute } from '@angular/router';
import { BrandService } from './brand.service';
import { ToasterService } from 'src/app/helpers/toastr.service';

@Component({
    selector: 'app-brand',
    templateUrl: './brand.component.html',
    styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit {

    classstatus: boolean = false;
    public category_flow: any = [];
    public category_name: string;
    public category_slug: string = '';
    public total_listing: number;
    public total_supplier: number;
    public total_wholesale: number;
    public selected_brand: any = {};
    public total_bulk: number;
    public total_products: number;
    public brand_search: string;
    public top_selling_products: any = [];
    public fast_selling_products: any = [];
    public latest_products: any = [];
    public brands_list: any = [];
    public filter_brands: any = [];
    public filter_category: any = [];
    public filter_variants: any = [];
    public active_ids = 'toggle-1, toggle-2, toggle-3, brand, startingprice, activationstatus, supplylocation, countryorigin';
    constructor(
        private route: ActivatedRoute,
        private _brandservice: BrandService,
        private _toastr: ToasterService
    ) { }
    minValue: number = 0;
    maxValue: number = 10000;
    options: Options = {
        floor: 0,
        ceil: 10000
    };
    public filter_prices = [
        {
            min: 0,
            max: 5000,
            name: 'Below 5000',
        },
        {
            min: 5000,
            max: 10000,
            name: '5000 - 10000',
        },
        {
            min: 10000,
            max: 15000,
            name: '10000 - 15000',
        },
        {
            min: 15000,
            max: 20000,
            name: '15000 - 20000',
        },
        {
            min: 20000,
            max: 20000000,
            name: 'Above 20000',
        },
    ];
    ngOnInit() {
        this.category_slug = this.route.snapshot.paramMap.get('brand_slug');
        this.getproducts();
    }
    tglclass() {
        this.classstatus = !this.classstatus;
    }
    public val = null;
    config = {
        labelField: 'label',
        valueField: 'value',
    };

    getproducts() {
        this._brandservice.getproducts({ category_slug: this.category_slug }).subscribe((data) => {
            if (data['status'] == "success") {
                this.category_name = data['category_name'];
                this.category_flow = data['category_flow'];
                this.total_listing = data['list_count'];
                this.total_supplier = data['supplier_count'];
                this.total_wholesale = data['wholesale_count'];
                this.total_bulk = data['bulk_count'];
                this.total_products = data['product_count'];
                this.brands_list = data['brands'];
                if (this.brands_list.length > 0) {
                    this.selected_brand = this.brands_list[0];
                    this.getbrandproduct(this.selected_brand);
                }
                this.top_selling_products = data['topselling_records'];
                this.latest_products = data['latestarrival_records'];
                this.filter_brands = data['filter_brands'];
                this.filter_category = data['filter_category'];
                this.filter_variants = data['filter_variants'];
                for (let i = 0; i < this.filter_variants.length; i++) {
                    this.active_ids = this.active_ids + ', ' + this.filter_variants[i].slug;
                }
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getbrandproduct(brand) {
        this.selected_brand = brand;
        this._brandservice.getbrandproduct({ brand_slug: brand.slug }).subscribe((data) => {
            if (data['status'] == "success") {
                this.fast_selling_products = data['brand_records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    data = [
        {
            label: 'Price Low to High',
            value: 'Price Low to High'
        },
        {
            label: 'Price High to Low',
            value: 'Price High to Low'
        },
        {
            label: 'Product Ratings',
            value: 'Product Ratings'
        },
        {
            label: 'Relevance',
            value: 'Relevance'
        },
        {
            label: 'Latest Arrivals',
            value: 'Latest Arrivals'
        }
    ];




    ratingst: boolean = false;
    ratingsort() {
        this.ratingst = !this.ratingst;
    }
}
