import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ContactUsService } from './contact-us.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
    contactForm: FormGroup;
    constructor(
        private fb: FormBuilder,
        private _contactservice: ContactUsService,
        private _toastr: ToasterService
    ) { }

    public call_request_phone:any = '';
    public buttonLoading:boolean = false;
    
    public cbuttonLoading:boolean = false;
    public user_type:string = 'buyer';
    public user_name:string;
    public user_email:string;
    public user


    ngOnInit(): void {
        this.contactForm = this.fb.group({
            user_name: this.fb.control(null, [Validators.required]),
            user_message: this.fb.control(null, [Validators.required]),
            user_mobile: this.fb.control(null, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
            user_email: this.fb.control(null, [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])
        });
    }

    get cf() { return this.contactForm.controls; }

    savecallrequest(){
        this.buttonLoading = true;
        this._contactservice.savecallrequest({type: 'callback', value: this.call_request_phone}).subscribe((data) => {
            this.buttonLoading = false;
            if(data['status'] == "success"){
                this.call_request_phone = '';
                this._toastr.success('Equibiz team will call you soon.');
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }else if(data['status'] == 'validation'){
                this._toastr.validation(data['errors'])
            }
        },(error) => {
          this.buttonLoading = false;
        });
    }

    updateusertype(type){
        this.user_type = type;
    }

    savecontact(){
        this.cbuttonLoading = true;
        var data = {
            type: this.user_type,
            name: this.contactForm.get('user_name').value,
            message: this.contactForm.get('user_message').value,
            mobile: this.contactForm.get('user_mobile').value,
            email: this.contactForm.get('user_email').value
        };
        this._contactservice.savecontact(data).subscribe((data) => {
            this.cbuttonLoading = false;
            if(data['status'] == "success"){
                this.contactForm.reset();
                this._toastr.success(data['msg']);
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }else if(data['status'] == 'validation'){
                this._toastr.validation(data['errors'])
            }
        },(error) => {
          this.cbuttonLoading = false;
        });
    }

}
