import { Component, OnInit, Input } from '@angular/core';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FooterOneService } from 'src/app/shared/footer/footer-one/footer-one.service';

@Component({
  selector: 'app-footer-buyer',
  templateUrl: './footer-buyer.component.html',
  styleUrls: ['./footer-buyer.component.scss']
})
export class FooterBuyerComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True

  constructor(
    private _footerservice: FooterOneService,
    private _toastr: ToasterService,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    this.getcategories();
  }
  public categories: any = [];
  public today: number = Date.now();

    getcategories() {
        this._footerservice.getcategories(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.categories = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        }, (error) => {

        });
    }
disabled = false;
  
morestatus: boolean = false;
catstatus: boolean = false;
walletstatus: boolean = false;
ordertstatus: boolean = false;
profilestatus: boolean = false;
invoicestatus: boolean = false;
wishliststatus: boolean = false;
categorystatus: boolean = false;
reportstatus: boolean = false;
locstatus:boolean = false;

hideall(){
    this.morestatus = false;
    this.catstatus = false;
    this.walletstatus = false;
    this.ordertstatus = false;
    this.profilestatus = false;
    this.invoicestatus = false;
    this.wishliststatus = false;
    this.categorystatus = false;
    this.reportstatus = false;
    this.locstatus = false;
}
moreEvent(){
    this.morestatus = false;
    this._router.navigate(['/buyer/dashboard']);       
}
walletEvent(){
    this.walletstatus = !this.walletstatus;       
}
swcatgkEvent(slug=''){
    if(this.catstatus){
        this.hideall();
    }else{
        this.catstatus = true;
    }
    if(slug){
        this.redirectTo('/category/' + slug);
    }    
}

redirectTo(uri: string) {
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this._router.navigate([uri]));
}
orderEvent(){
	this.ordertstatus = !this.ordertstatus;       
}
profileEvent(){
	this.profilestatus = !this.profilestatus;       
}

wishlistEvent(){
	this.wishliststatus = !this.wishliststatus;       
}
invoiceEvent(){
    this.invoicestatus = !this.invoicestatus;       
}
categoryEvent(){
	this.categorystatus = !this.categorystatus;       
}
reportEvent(){
	this.reportstatus = !this.reportstatus;       
}
locationevent(){
	this.locstatus = !this.locstatus;
	}

}
