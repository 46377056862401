<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<h3 class="fs24 wt700 txtdefault">Bank Account Details</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Settings</a>
						</li>
						<li class="breadcrumb-item active">Bank Account Details</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4">
			<div class="profileipt">
			<!-- INPUT ROW -->	
				<div class="form-container mt-0 mb-0 p-eml-rt">
					<div class="floating-label mb-0"> 
						<input type="text" class="form-control" value="State Bank of India (SBI)" placeholder=" ">
						<button class="btn btn-outline-secondary fs16 btn-o-default" type="button" (click)="open(content)">
							<span>Change</span>
							<svg xmlns="http://www.w3.org/2000/svg" width="16.615" height="16.617" viewBox="0 0 16.615 16.617">
							  <path id="np_edit_3094244_000000" d="M13.5-.008A3.1,3.1,0,0,0,11.3.9L.857,11.344l4.4,4.4L15.7,5.307A3.114,3.114,0,0,0,13.5-.008ZM.317,12.273,0,16.046a.519.519,0,0,0,.561.561l3.771-.32Z" transform="translate(0.003 0.008)" fill="#ff7135"/>
							</svg>
						</button>	  
					   <span class="highlight"></span>
					   <label>Bank Name</label>
					</div>
				</div>							
				<!-- /INPUT ROW -->
			</div>
			<div class="form-container mdl-frm-lbl mb-3">
				<div class="row">
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input value="3 Minds Digital Pvt. Ltd." type="text" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>Account Holder Name</label>
						</div> 
					</div>
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input value="************4321" type="text" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>Account Number</label>
						</div> 
					</div>	
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input value="HDFC9876504" type="text" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>IFSC Code</label>
						</div> 
					</div>
					<div class="col-sm-6 mb-4">
						<div class="floating-label mb-0">      
						  <input value="Thane, Mumbai" value="" type="text" class="form-control" placeholder=" " />
						  <span class="highlight"></span>
						  <label>Bank Branch</label>
						</div> 
					</div>	
				</div>
				<div class="text-center">
					<a href="javascript:void(0);" class="mr-sm-4  mb-3 btn-d-outline d-inline-block">View Your Transactions</a>
					<a href="javascript:void(0);" class="btn-d-outline d-inline-block">View Bank Details</a>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- UPDATE BANK DETAILS -->
<ng-template #content let-modal>
   <div class="modal-body text-center mdlcmnui pt-4 mt-3">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
      </button>
      <h5 class="modal-title mb-10 ">Edit Bank Account Details</h5>
      	<div class="form-container mdl-frm-lbl mb-3">
			<div class="row">
				<div class="col-md-12">
					<div class="floating-label mb-4">      
					  <input value="State Bank of India (SBI)" type="text" class="form-control" placeholder=" " />
					  <span class="highlight"></span>
					  <label>Bank Name</label>
					</div> 
				</div>
				<div class="col-md-12">
					<div class="floating-label mb-4">      
					  <input value="3 Minds Digital Pvt. Ltd." type="text" class="form-control" placeholder=" " />
					  <span class="highlight"></span>
					  <label>Account Holder Name</label>
					</div> 
				</div>
				<div class="col-md-12">
					<div class="floating-label mb-4">      
					  <input value="************4321" type="text" class="form-control" placeholder=" " />
					  <span class="highlight"></span>
					  <label>Account Number</label>
					</div> 
				</div>	
				<div class="col-md-12">
					<div class="floating-label mb-4">      
					  <input value="9876504321" type="text" class="form-control" placeholder=" " />
					  <span class="highlight"></span>
					  <label>IFSC Code</label>
					</div> 
				</div>
				<div class="col-md-12">
					<div class="floating-label mb-0">      
					  <input value="Thane, Mumbai" type="text" class="form-control" placeholder=" " />
					  <span class="highlight"></span>
					  <label>Bank Branch</label>
					</div> 
				</div>	
			</div>
			<div>
				<img src="assets/images/icons/info.png"> An OTP will be sent to a linked mobile number with the bank
			</div>
		</div>
      	<div class="cta mt-40 pb-4 mb-1">
         	<button class="btn btn-mod btn-color btn-medium btn-round ripple btn-block" (click)="openmdl(verifyMobile)">Send Code</button>
      	</div>
   </div>
</ng-template>
<!-- UPDATE BANK DETAILS -->
<!-- OTP VERIFICATION -->
<ng-template #verifyMobile let-modal>
   <div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
      </button>
      <h5 class="modal-title">Edit Bank Account Details</h5>
      <div class="modal-subtitle pt-4">Enter the OTP sent on<br>+91 *******432</div>
      <div class="form-container">
         <form action="" class="mt-40 mb-10 vrf-frm-ipt">
            <input class="otp" type="text" oninput='digitValidate(this)' onkeyup='tabChange(1)' maxlength=1 > <!--  form-error -->
            <input class="otp" type="text" oninput='digitValidate(this)' onkeyup='tabChange(2)' maxlength=1 >
            <input class="otp" type="text" oninput='digitValidate(this)' onkeyup='tabChange(3)' maxlength=1 >
            <input class="otp" type="text" oninput='digitValidate(this)'onkeyup='tabChange(4)' maxlength=1 >
         </form>
      </div>
      <div class="pt-20 pb-20">
         <div class="modal-subtitle">Didn’t received code?<br>
            <a href="javascript:;" class="resend">Resend Code</a>
         </div>
      </div>
      <div class="cta mt-40 pb-4 mb-1">
         <button class="btn btn-mod btn-color btn-medium btn-round ripple btn-block">Continue</button>
      </div>
   </div>
</ng-template>
<!-- OTP VERIFICATION -->