import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class OrderQualityCheckService {

    constructor(private _http: HttpClient) { }

    pendingqc(data) {
        return this._http.post(environment.api_url + 'admin/order/pendingqc/list', data);
    }

    completedqc(data) {
        return this._http.post(environment.api_url + 'admin/order/qcdone/list', data);
    }

}