<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain pb-4">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center pinfo-head">
			<div class="col-md-5 mb-4">
				<h3 class="fs24 wt700 txtdefault">Activity Log</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">						
						<li class="breadcrumb-item "><a href="javascript:void(0);" class="txtseconday">Settings</a></li>
						<li class="breadcrumb-item active">Activity Log</li>
					</ol>
				</nav>
			</div>
			<div class="col-md-7  mb-4 text-md-right text-center">
				
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4 mb-0">
			<div class="notifications-container theme-tab">
				<ngb-tabset [justify]="'left'" [type]="'pills'" class="tabs tab-title">
		            <ngb-tab>
		                <ng-template ngbTabTitle>All Activities</ng-template>
		                <ng-template ngbTabContent>
		                  	<div class="tab-content">
		                  		<!--Filters-->
		                  		<div class="row d-flex justify-content-between mt-20 report-cta">
									<div class="col-lg-5 mb-3">
										<div class="report-input">
											<div class="input-group-custom">
												<input type="text" placeholder="Search by Order ID or Transaction ID" class="form-control">
												<div class="input-group-append-custom">
													<img src="assets/images/svg/search_logo.svg">
												</div>
											</div>
										</div>
									</div>

									<div class="col-lg-7">
										<div class="d-flex clbxgrd justify-content-end lg-justify-content-start">
											<div class="mr-3">
												<a href="javascript:void(0);" class="d-inline-block fltr-round-btn radius10 txtseconday">
													<img src="assets/images/svg/filter.svg">
													Filters
												</a>
											</div>

											
											<div class="form-container mt-0 mb-0 mr-3">
												<div class="report-input floating-label">
													<div class="input-group-custom">
														<input type="text" value="Last 30 Days" class="form-control" placeholder=" " />
														<span class="highlight"></span>
														<label>Date</label>
														<div class="input-group-append-custom">
															<img src="assets/images/icons/filter-calendar.svg">
														</div>
													</div>
												</div>
											</div>

											<div>
												<a href="javascript:void(0);" class="btn-d-outline d-inline-block">
													<svg xmlns="http://www.w3.org/2000/svg" width="12.681" height="17.061" viewBox="0 0 12.681 17.061">
													  <g id="np_export_2535025_000000" transform="translate(-32.121 -25.962)">
													    <path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#ff7135"/>
													    <path id="Path_2356" data-name="Path 2356" d="M44.093,70.07H32.83a.709.709,0,1,0,0,1.419H44.093a.709.709,0,0,0,0-1.419Z" transform="translate(0 -28.466)" fill="#ff7135"/>
													  </g>
													</svg>
													Export
												</a>
											</div>
										</div>
									</div>
								</div>

								<div class="table-responsive brndmgnttable odmgnttabl"> 
									<table class="table custom-table xs">
										<thead>
											<tr>
												<th>Date</th>
												<th>Activity</th>
												<th>User Type</th>
												<th>Actions</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Shubham Jaiswal</b> changed his Email Id</td>
												<td>Buyer</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Tanishq Jaiswal</b> changed his Mobile Number</td>
												<td>Seller</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Tanishq Jaiswal</b> has successfully added the OPPO 16GB A5...</td>
												<td>Seller</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Tanishq Jaiswal</b> changed his Mobile Number</td>
												<td>Seller</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Shubham Jaiswal</b> changed his Email Id</td>
												<td>Buyer</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>

											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Shubham Jaiswal</b> changed his Email Id</td>
												<td>Buyer</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
										</tbody>
									</table>
								</div>	
		                  	</div>
		                </ng-template>
		            </ngb-tab>	
					<ngb-tab>
		                <ng-template ngbTabTitle>Wallet</ng-template>
		                <ng-template ngbTabContent>
		                  	<div class="tab-content">
		                  		<!--Filters-->
		                  		<div class="row d-flex justify-content-between mt-20 report-cta">
									<div class="col-lg-5 mb-3">
										<div class="report-input">
											<div class="input-group-custom">
												<input type="text" placeholder="Search by Order ID or Transaction ID" class="form-control">
												<div class="input-group-append-custom">
													<img src="assets/images/svg/search_logo.svg">
												</div>
											</div>
										</div>
									</div>

									<div class="col-lg-7">
										<div class="d-flex clbxgrd justify-content-end lg-justify-content-start">
											<div class="mr-3">
												<a href="javascript:void(0);" class="d-inline-block fltr-round-btn radius10 txtseconday">
													<img src="assets/images/svg/filter.svg">
													Filters
												</a>
											</div>

											
											<div class="form-container mt-0 mb-0 mr-3">
												<div class="report-input floating-label">
													<div class="input-group-custom">
														<input type="text" value="Last 30 Days" class="form-control" placeholder=" " />
														<span class="highlight"></span>
														<label>Date</label>
														<div class="input-group-append-custom">
															<img src="assets/images/icons/filter-calendar.svg">
														</div>
													</div>
												</div>
											</div>

											<div>
												<a href="javascript:void(0);" class="btn-d-outline d-inline-block">
													<svg xmlns="http://www.w3.org/2000/svg" width="12.681" height="17.061" viewBox="0 0 12.681 17.061">
													  <g id="np_export_2535025_000000" transform="translate(-32.121 -25.962)">
													    <path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#ff7135"/>
													    <path id="Path_2356" data-name="Path 2356" d="M44.093,70.07H32.83a.709.709,0,1,0,0,1.419H44.093a.709.709,0,0,0,0-1.419Z" transform="translate(0 -28.466)" fill="#ff7135"/>
													  </g>
													</svg>
													Export
												</a>
											</div>
										</div>
									</div>
								</div>

								<div class="table-responsive brndmgnttable odmgnttabl"> 
									<table class="table custom-table xs">
										<thead>
											<tr>
												<th>Date</th>
												<th>Activity</th>
												<th>User Type</th>
												<th>Actions</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Shubham Jaiswal</b> changed his Email Id</td>
												<td>Buyer</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Tanishq Jaiswal</b> changed his Mobile Number</td>
												<td>Seller</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Tanishq Jaiswal</b> has successfully added the OPPO 16GB A5...</td>
												<td>Seller</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Tanishq Jaiswal</b> changed his Mobile Number</td>
												<td>Seller</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Shubham Jaiswal</b> changed his Email Id</td>
												<td>Buyer</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>

											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Shubham Jaiswal</b> changed his Email Id</td>
												<td>Buyer</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
										</tbody>
									</table>
								</div>	
		                  	</div>
		                </ng-template>
		            </ngb-tab>
		            <ngb-tab>
		                <ng-template ngbTabTitle>User Management</ng-template>
		                <ng-template ngbTabContent>
		                  	<div class="tab-content">
		                  		<!--Filters-->
		                  		<div class="row d-flex justify-content-between mt-20 report-cta">
									<div class="col-lg-5 mb-3">
										<div class="report-input">
											<div class="input-group-custom">
												<input type="text" placeholder="Search by Order ID or Transaction ID" class="form-control">
												<div class="input-group-append-custom">
													<img src="assets/images/svg/search_logo.svg">
												</div>
											</div>
										</div>
									</div>

									<div class="col-lg-7">
										<div class="d-flex clbxgrd justify-content-end lg-justify-content-start">
											<div class="mr-3">
												<a href="javascript:void(0);" class="d-inline-block fltr-round-btn radius10 txtseconday">
													<img src="assets/images/svg/filter.svg">
													Filters
												</a>
											</div>

											
											<div class="form-container mt-0 mb-0 mr-3">
												<div class="report-input floating-label">
													<div class="input-group-custom">
														<input type="text" value="Last 30 Days" class="form-control" placeholder=" " />
														<span class="highlight"></span>
														<label>Date</label>
														<div class="input-group-append-custom">
															<img src="assets/images/icons/filter-calendar.svg">
														</div>
													</div>
												</div>
											</div>

											<div>
												<a href="javascript:void(0);" class="btn-d-outline d-inline-block">
													<svg xmlns="http://www.w3.org/2000/svg" width="12.681" height="17.061" viewBox="0 0 12.681 17.061">
													  <g id="np_export_2535025_000000" transform="translate(-32.121 -25.962)">
													    <path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#ff7135"/>
													    <path id="Path_2356" data-name="Path 2356" d="M44.093,70.07H32.83a.709.709,0,1,0,0,1.419H44.093a.709.709,0,0,0,0-1.419Z" transform="translate(0 -28.466)" fill="#ff7135"/>
													  </g>
													</svg>
													Export
												</a>
											</div>
										</div>
									</div>
								</div>

								<div class="table-responsive brndmgnttable odmgnttabl"> 
									<table class="table custom-table xs">
										<thead>
											<tr>
												<th>Date</th>
												<th>Activity</th>
												<th>User Type</th>
												<th>Actions</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Shubham Jaiswal</b> changed his Email Id</td>
												<td>Buyer</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Tanishq Jaiswal</b> changed his Mobile Number</td>
												<td>Seller</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Tanishq Jaiswal</b> has successfully added the OPPO 16GB A5...</td>
												<td>Seller</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Tanishq Jaiswal</b> changed his Mobile Number</td>
												<td>Seller</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Shubham Jaiswal</b> changed his Email Id</td>
												<td>Buyer</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>

											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Shubham Jaiswal</b> changed his Email Id</td>
												<td>Buyer</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
										</tbody>
									</table>
								</div>	
		                  	</div>
		                </ng-template>
		            </ngb-tab>	
		            <ngb-tab>
		                <ng-template ngbTabTitle>Order Management</ng-template>
		                <ng-template ngbTabContent>
		                  	<div class="tab-content">
		                  		<!--Filters-->
		                  		<div class="row d-flex justify-content-between mt-20 report-cta">
									<div class="col-lg-5 mb-3">
										<div class="report-input">
											<div class="input-group-custom">
												<input type="text" placeholder="Search by Order ID or Transaction ID" class="form-control">
												<div class="input-group-append-custom">
													<img src="assets/images/svg/search_logo.svg">
												</div>
											</div>
										</div>
									</div>

									<div class="col-lg-7">
										<div class="d-flex clbxgrd justify-content-end lg-justify-content-start">
											<div class="mr-3">
												<a href="javascript:void(0);" class="d-inline-block fltr-round-btn radius10 txtseconday">
													<img src="assets/images/svg/filter.svg">
													Filters
												</a>
											</div>

											
											<div class="form-container mt-0 mb-0 mr-3">
												<div class="report-input floating-label">
													<div class="input-group-custom">
														<input type="text" value="Last 30 Days" class="form-control" placeholder=" " />
														<span class="highlight"></span>
														<label>Date</label>
														<div class="input-group-append-custom">
															<img src="assets/images/icons/filter-calendar.svg">
														</div>
													</div>
												</div>
											</div>

											<div>
												<a href="javascript:void(0);" class="btn-d-outline d-inline-block">
													<svg xmlns="http://www.w3.org/2000/svg" width="12.681" height="17.061" viewBox="0 0 12.681 17.061">
													  <g id="np_export_2535025_000000" transform="translate(-32.121 -25.962)">
													    <path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#ff7135"/>
													    <path id="Path_2356" data-name="Path 2356" d="M44.093,70.07H32.83a.709.709,0,1,0,0,1.419H44.093a.709.709,0,0,0,0-1.419Z" transform="translate(0 -28.466)" fill="#ff7135"/>
													  </g>
													</svg>
													Export
												</a>
											</div>
										</div>
									</div>
								</div>

								<div class="table-responsive brndmgnttable odmgnttabl"> 
									<table class="table custom-table xs">
										<thead>
											<tr>
												<th>Date</th>
												<th>Activity</th>
												<th>User Type</th>
												<th>Actions</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Shubham Jaiswal</b> changed his Email Id</td>
												<td>Buyer</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Tanishq Jaiswal</b> changed his Mobile Number</td>
												<td>Seller</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Tanishq Jaiswal</b> has successfully added the OPPO 16GB A5...</td>
												<td>Seller</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Tanishq Jaiswal</b> changed his Mobile Number</td>
												<td>Seller</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Shubham Jaiswal</b> changed his Email Id</td>
												<td>Buyer</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>

											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Shubham Jaiswal</b> changed his Email Id</td>
												<td>Buyer</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
										</tbody>
									</table>
								</div>	
		                  	</div>
		                </ng-template>
		            </ngb-tab>	
		            <ngb-tab>
		                <ng-template ngbTabTitle>Product Management</ng-template>
		                <ng-template ngbTabContent>
		                  	<div class="tab-content">
		                  		<!--Filters-->
		                  		<div class="row d-flex justify-content-between mt-20 report-cta">
									<div class="col-lg-5 mb-3">
										<div class="report-input">
											<div class="input-group-custom">
												<input type="text" placeholder="Search by Order ID or Transaction ID" class="form-control">
												<div class="input-group-append-custom">
													<img src="assets/images/svg/search_logo.svg">
												</div>
											</div>
										</div>
									</div>

									<div class="col-lg-7">
										<div class="d-flex clbxgrd justify-content-end lg-justify-content-start">
											<div class="mr-3">
												<a href="javascript:void(0);" class="d-inline-block fltr-round-btn radius10 txtseconday">
													<img src="assets/images/svg/filter.svg">
													Filters
												</a>
											</div>

											
											<div class="form-container mt-0 mb-0 mr-3">
												<div class="report-input floating-label">
													<div class="input-group-custom">
														<input type="text" value="Last 30 Days" class="form-control" placeholder=" " />
														<span class="highlight"></span>
														<label>Date</label>
														<div class="input-group-append-custom">
															<img src="assets/images/icons/filter-calendar.svg">
														</div>
													</div>
												</div>
											</div>

											<div>
												<a href="javascript:void(0);" class="btn-d-outline d-inline-block">
													<svg xmlns="http://www.w3.org/2000/svg" width="12.681" height="17.061" viewBox="0 0 12.681 17.061">
													  <g id="np_export_2535025_000000" transform="translate(-32.121 -25.962)">
													    <path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#ff7135"/>
													    <path id="Path_2356" data-name="Path 2356" d="M44.093,70.07H32.83a.709.709,0,1,0,0,1.419H44.093a.709.709,0,0,0,0-1.419Z" transform="translate(0 -28.466)" fill="#ff7135"/>
													  </g>
													</svg>
													Export
												</a>
											</div>
										</div>
									</div>
								</div>

								<div class="table-responsive brndmgnttable odmgnttabl"> 
									<table class="table custom-table xs">
										<thead>
											<tr>
												<th>Date</th>
												<th>Activity</th>
												<th>User Type</th>
												<th>Actions</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Shubham Jaiswal</b> changed his Email Id</td>
												<td>Buyer</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Tanishq Jaiswal</b> changed his Mobile Number</td>
												<td>Seller</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Tanishq Jaiswal</b> has successfully added the OPPO 16GB A5...</td>
												<td>Seller</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Tanishq Jaiswal</b> changed his Mobile Number</td>
												<td>Seller</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Shubham Jaiswal</b> changed his Email Id</td>
												<td>Buyer</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>

											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Shubham Jaiswal</b> changed his Email Id</td>
												<td>Buyer</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
										</tbody>
									</table>
								</div>	
		                  	</div>
		                </ng-template>
		            </ngb-tab>		
		            <ngb-tab>
		                <ng-template ngbTabTitle>Logistics Management</ng-template>
		                <ng-template ngbTabContent>
		                  	<div class="tab-content">
		                  		<!--Filters-->
		                  		<div class="row d-flex justify-content-between mt-20 report-cta">
									<div class="col-lg-5 mb-3">
										<div class="report-input">
											<div class="input-group-custom">
												<input type="text" placeholder="Search by Order ID or Transaction ID" class="form-control">
												<div class="input-group-append-custom">
													<img src="assets/images/svg/search_logo.svg">
												</div>
											</div>
										</div>
									</div>

									<div class="col-lg-7">
										<div class="d-flex clbxgrd justify-content-end lg-justify-content-start">
											<div class="mr-3">
												<a href="javascript:void(0);" class="d-inline-block fltr-round-btn radius10 txtseconday">
													<img src="assets/images/svg/filter.svg">
													Filters
												</a>
											</div>

											
											<div class="form-container mt-0 mb-0 mr-3">
												<div class="report-input floating-label">
													<div class="input-group-custom">
														<input type="text" value="Last 30 Days" class="form-control" placeholder=" " />
														<span class="highlight"></span>
														<label>Date</label>
														<div class="input-group-append-custom">
															<img src="assets/images/icons/filter-calendar.svg">
														</div>
													</div>
												</div>
											</div>

											<div>
												<a href="javascript:void(0);" class="btn-d-outline d-inline-block">
													<svg xmlns="http://www.w3.org/2000/svg" width="12.681" height="17.061" viewBox="0 0 12.681 17.061">
													  <g id="np_export_2535025_000000" transform="translate(-32.121 -25.962)">
													    <path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#ff7135"/>
													    <path id="Path_2356" data-name="Path 2356" d="M44.093,70.07H32.83a.709.709,0,1,0,0,1.419H44.093a.709.709,0,0,0,0-1.419Z" transform="translate(0 -28.466)" fill="#ff7135"/>
													  </g>
													</svg>
													Export
												</a>
											</div>
										</div>
									</div>
								</div>

								<div class="table-responsive brndmgnttable odmgnttabl"> 
									<table class="table custom-table xs">
										<thead>
											<tr>
												<th>Date</th>
												<th>Activity</th>
												<th>User Type</th>
												<th>Actions</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Shubham Jaiswal</b> changed his Email Id</td>
												<td>Buyer</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Tanishq Jaiswal</b> changed his Mobile Number</td>
												<td>Seller</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Tanishq Jaiswal</b> has successfully added the OPPO 16GB A5...</td>
												<td>Seller</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Tanishq Jaiswal</b> changed his Mobile Number</td>
												<td>Seller</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Shubham Jaiswal</b> changed his Email Id</td>
												<td>Buyer</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>

											<tr>
												<td>12/04/2021, 11:54 pm</td>
												<td><b>Shubham Jaiswal</b> changed his Email Id</td>
												<td>Buyer</td>
												<td><a href="javascript:;" class="link-alt fs14">View Activity</a></td>
											</tr>
										</tbody>
									</table>
								</div>	
		                  	</div>
		                </ng-template>
		            </ngb-tab>							
	        	</ngb-tabset>
			</div>			
		</div>
		<div class="row mb-40">
			<div class="col-md-6">
				<div class="text-left pl-3">
					<nav class="mt-3 tbpg rows-per-page">
						<div>Rows per page:</div>
						<select class="select-box">
							<option>10</option>
							<option>20</option>
						</select>
					</nav>
				</div> 
			</div>
			<div class="col-md-6">
				<div class="text-right pr-3">
					<nav class="mt-3 tbpg">
					  <ul class="pagination align-items-center">
						<li class="page-item"><a class="page-link previcon" href="#"></a></li>
						<li class="page-item"><a class="page-link sprevicon" href="#"></a></li>
						<li class="page-item"><a class="page-link" href="#">1</a></li>
						<li class="page-item"><a class="page-link" href="#">2</a></li>
						<li class="page-item"><a class="page-link" href="#">3</a></li>
						<li class="page-item"><a class="page-link" href="#">4</a></li>
						<li class="page-item"><a class="page-link snexticon" href="#"></a></li>
						<li class="page-item"><a class="page-link nexticon" href="#"></a></li>
					  </ul>
					</nav>
				</div> 
			</div>
		</div> 
	</div>
</div>	 