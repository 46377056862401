import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class HeaderOneService {

  constructor(private _http: HttpClient) { }

    getuserdetails(data) {
        return this._http.post(environment.api_url+'userbasicinfo',data);
    }
}
