import { Component, OnInit, Input, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SellerTestimonialSlider } from '../shared/data/slider';
import { SignupService } from '../shared/components/modal/signup-modal/signup-modal.service';
import { SignupModalComponent } from '../shared/components/modal/signup-modal/signup-modal.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-seller',
  templateUrl: './seller.component.html',
  styleUrls: ['./seller.component.scss']
})
export class SellerComponent implements OnInit {
	@Input() class: string;
	@Input() sticky: boolean = false; // Default false

    @ViewChild("signIn") SignIn: SignupModalComponent;

  	public stick: boolean = false;
  	constructor(
      public router: Router,
      public _singupservice: SignupService,
    ) { }
    public ProductSliderConfig: any = SellerTestimonialSlider;

    
  	ngOnInit() {
      
  }
  	 disabled = false;
  	// @HostListener Decorator
    @HostListener("window:scroll", [])
    onWindowScroll() {
        let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	    if (number >= 150 && window.innerWidth > 400) { 
  	        this.stick = true;
  	     } else {
  	         this.stick = false;
  	     }
    }
    customOptions: OwlOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: false,
      dots: false,
      margin:10,
      navSpeed: 700,
      navText: ['', ''],
      responsive: {
        0: {
          items:1
        },
        400: {
          items: 1
        },
        740: {
          items: 2
        },
        940: {
          items: 2
        }
      },
      nav: true
    }
}