import { Component, OnInit } from '@angular/core';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { ProductStockListingService } from './product-stocks-listing.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AddBrandService } from 'src/app/admin/add-brand/add-brand.service';
import { MakePaymentService } from '../wallet/make-payment/make-payment.service';

@Component({
  selector: 'app-product-stock-listing',
  templateUrl: './product-stock-listing.component.html',
  styleUrls: ['./product-stock-listing.component.scss']
})
export class ProductStockListingComponent implements OnInit {

    closeResult = '';
    activeTab = "productListing";
    public export_path:string = '';
    public export_loading:boolean = false;
    public market_place:string = '';
    public category_id:string = '';
    public search_term:string = '';
    public seller_id:string = '';
    public stock_listing_pagination: any = {};
    public stockListing: any = [];
    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 10;
    config = {
        labelField: 'name',
        valueField: 'value',

    };
    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];
    public marketplace_options = [
        {
            value: '',
            name: 'All',
        },
        {
            value: 'wholesale',
            name: 'Wholesale'
        },
        {
            value: 'bulk',
            name: 'Bulk'
        }
    ];
    constructor(
        private modalService: NgbModal,
        private _pageservice: ProductStockListingService,
        private _toastr: ToasterService,
        private _pagination: PaginationService,
        private _addbrandservice: AddBrandService,
        private _makepaymentservice: MakePaymentService,
    ) {
        
     }

    ngOnInit(): void {
        this.getproductlistingfilters();
    }

    getproductlistingfilters(){
        this.page_per_view = 10;
        this.offset = 0;
        this.current_page = 1;
        this.getproductlisting();
    }

    getproductlisting() {
        let limit = this.page_per_view;
        let offset = this.offset;
        let current_page = this.current_page;
        let data = { 
            limit: limit,
            offset: offset,
            current_page: current_page,
            search: this.search_term,
            category: this.category_id,
            market_place: this.market_place,
            seller_id: this.seller_id
        };
        this._pageservice.getdetails(data).subscribe((data) => {
            if (data['status'] == "success") {
                let total_records = data['total_records'];
                this.stock_listing_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
                this.stockListing = data['searchResults'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }


    open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true, }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.getproductlisting();
    }

    setpageperview() {
        this.current_page = 1;
        this.getproductlisting();
    }

    keyword = 'name'
    result = [];

    selectEvent(item) {
        this.category_id = item.c_id;
        this.getproductlistingfilters();
    }

    onChangeSearch(val: string) {
        this._addbrandservice.searchcategory({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.result = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    onFocused(e) {
        // do something when input is focused
    }

    resultSeller = [];
    public selected_user_type:string = 'seller';

    selectEventSeller(item) {
        this.seller_id = item.id;
        this.getproductlistingfilters();
    }

    onChangeSearchSeller(val: string) {
		this.resultSeller = [];
		setTimeout( () => {
			this._makepaymentservice.searchuser({type: this.selected_user_type, search: val}).subscribe((data) => {
				if (data['status'] == "success") {
					this.resultSeller = [];
					this.resultSeller = data['searchResult'];
				} else if (data['status'] == "error") {
					this._toastr.error(data['message']);
				}
			});
		}, 1000)
  	}

    onFocusedSeller(e) {
        // do something when input is focused
    }

}
