import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { CartService } from './cart.service';
import { CheckoutService } from '../checkout/checkout.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ProductDetailsService } from '../product-details/product-details.service';
@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

    public cart_records: any = [];
    public popupbox_records: any = [];
    public records: any = [];
    public subtotal_amount: number;
    public netamount: number;
    public otheramount: number;
    public totalamount: number;
    public total_goods_value: number;
    public totalgst: number;
    public other_charges: any = [];
    public checkout_amount: number;
    public related_products: any = [];

    constructor(
        private modalService: NgbModal,
        private _cartservice: CartService,
        private _toastr: ToasterService,
        private _checkoutservice: CheckoutService,
        private _productdetailsservice: ProductDetailsService
    ) { }
    active = 1;
    disabled = false;
    ngOnInit(): void {
        this.getcartdetails();
        this.cartrelatedproducts();
    }

    openWindowCustomClass(content) {
        this.modalService.open(content, { windowClass: 'viewAllProducts', size: 'md', centered: true, });
    }

    wishlist(meta) {
        this._productdetailsservice.wishlist({ unique_id: meta.unique_id }).subscribe((data) => {
            if (data['status'] == "success") {
                meta.wishlist = data['wishlist'];
                this._toastr.success(data['msg']);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    cartrelatedproducts() {
        this._cartservice.cartrelatedproducts(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.related_products = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    changequantity(record, type) {
        if (type == 'minus') {
            var qty = parseInt(record.order_qty) - 1;
            if (qty < 1) {
                qty = 1;
            } else {
                qty = qty;
            }
        } else if (type == 'plus') {
            qty = record.order_qty + 1;
        } else {
            var mqty = parseInt(record.order_qty);
            if (mqty < 1) {
                qty = 1;
            } else {
                qty = mqty;
            }
        }
        this.updatedetails('quantity', record.id, qty, record);
    }

    updatedetails(type, id, value, record) {
        this._checkoutservice.updatedetails({ type: type, id: id, value: value }).subscribe((data) => {
            if (data['status'] == "success") {
                record.order_qty = value;
                this.getcartdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getcartdetails() {
        this._cartservice.getcartdetails(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.cart_records = data['cart_records'];
                this.popupbox_records = data['price_breakdown'];
                this.netamount = data['netamount'];
                this.otheramount = data['otheramount'];
                this.totalamount = data['totalamount'];
                this.total_goods_value = data['total_goods_value'];
                this.totalgst = data['totalgst'];
                this.checkout_amount = data['checkout_amount'];
                this.subtotal_amount = data['sub_total'];
                this.other_charges = data['other_charges'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    removeall(cart) {
        this._cartservice.removeall({ sid: cart.sid }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Products removed from cart.');
                this.getcartdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }


    removeproduct(cart, meta) {
        this._cartservice.removeproduct({ sid: cart.sid, id: meta.id }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Products removed from cart.');
                this.getcartdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    customOptions: OwlOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: false,
        dots: false,
        margin: 10,
        navSpeed: 700,
        navText: ['<i class="ti-angle-left"></i>', '<i class="ti-angle-right"></i>'],
        responsive: {
            0: {
                items: 2
            },
            400: {
                items: 2
            },
            740: {
                items: 3
            },
            940: {
                items: 5
            }
        },
        nav: true
    }
}
