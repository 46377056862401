import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-duplicate-order',
  templateUrl: './duplicate-order.component.html',
  styleUrls: ['./duplicate-order.component.scss']
})
export class DuplicateOrderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
 config = {
    labelField: 'label',
    valueField: 'value',
     
  };  
data = [
    {
      label: 'India',
      value: 'india'
    },
    {
      label: 'in $',
      value: 'in $'
    }
  ]
}   
