import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ViewListingService {

    constructor(private _http: HttpClient) { }

    getproductdetails(data) {
        return this._http.post(environment.api_url+'seller/product/view',data);
    }

    updatestatus(data){
      return this._http.post(environment.api_url+'seller/product/status',data);
    }

    deletesellerlisting(data) {
      return this._http.post(environment.api_url+'seller/product/delete', data);
  }

}