import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegistrationComponent } from './registration/registration.component';
import { RegistrationDetailsComponent } from './registration/registration-details/registration-details.component';
import { ContactUsComponent } from './shared/contact-us/contact-us.component';
import { BuyerNotificationComponent } from './buyer/buyer-notification/buyer-notification.component';
import { BuyerAssistanceComponent } from './buyer/buyer-assistance/buyer-assistance.component';
import { MoreArticlesComponent } from './buyer/buyer-assistance/more-articles/more-articles.component';
import { ArticleDetailsComponent } from './buyer/buyer-assistance/article-details/article-details.component';
import { ProductDetailsComponent } from './buyer/product-details/product-details.component';
import { BrandComponent } from './buyer/brand/brand.component';
import { DealComponent } from './buyer/deal/deal.component';
import { CartComponent } from './buyer/cart/cart.component';
import { BidsOffersComponent } from './buyer/bids-offers/bids-offers.component';
import { MyBidsComponent } from './buyer/my-bids/my-bids.component';
import { WriteReviewComponent } from './buyer/write-review/write-review.component';
import { BuyerManageCategoriesComponent } from './buyer/buyer-manage-categories/buyer-manage-categories.component';
import { BuyerWalletComponent } from './buyer/buyer-wallet/buyer-wallet.component';
import { BuyerAddMoneyComponent } from './buyer/buyer-add-money/buyer-add-money.component';
import { BuyerManagePaymentMethodsComponent } from './buyer/buyer-manage-payment-methods/buyer-manage-payment-methods.component';
import { BuyerAddPaymentMethodComponent } from './buyer/buyer-add-payment-method/buyer-add-payment-method.component';
import { BuyerTransactionHistoryComponent } from './buyer/buyer-transaction-history/buyer-transaction-history.component';
import { BuyerTrackOrdersComponent } from './buyer/buyer-track-orders/buyer-track-orders.component';
import { BuyerViewOrderDetailsComponent } from './buyer/buyer-view-order-details/buyer-view-order-details.component';
import { BuyerWishlistComponent } from './buyer/buyer-wishlist/buyer-wishlist.component';
import { CheckoutComponent } from './buyer/checkout/checkout.component';
import { PrivacyPolicyComponent } from './shared/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './shared/terms-of-use/terms-of-use.component';
import { InfringementPolicyComponent } from './shared/infringement-policy/infringement-policy.component';
import { AntiCounterfeitingPolicyComponent } from './shared/anti-counterfeiting-policy/anti-counterfeiting-policy.component';
import { CancellationReturnsPolicyComponent } from './shared/cancellation-returns-policy/cancellation-returns-policy.component';
import { InsurancePolicyComponent } from './shared/insurance-policy/insurance-policy.component';
import { HowItWorksComponent } from './shared/how-it-works/how-it-works.component';
import { ServicesComponent } from './shared/services/services.component';
import { PartnersComponent } from './shared/partners/partners.component';
import { AboutUsComponent } from './shared/about-us/about-us.component';

import { SellerComponent } from './seller/seller.component';
import { SellerdashboardComponent } from './seller/sellerdashboard/sellerdashboard.component';
import { BankDetailsComponent } from './seller/bank-details/bank-details.component';
import { MyWalletComponent } from './seller/my-wallet/my-wallet.component';
import { WithdrawalRequestComponent } from './seller/sellerdashboard/withdrawal-request/withdrawal-request.component';
import { AddMoneyComponent } from './seller/sellerdashboard/add-money/add-money.component';
import { ManagePaymentMethodsComponent } from './seller/sellerdashboard/manage-payment-methods/manage-payment-methods.component';
import { AddPaymentMethodComponent } from './seller/sellerdashboard/add-payment-method/add-payment-method.component';
import { TransactionHistoryComponent } from './seller/transaction-history/transaction-history.component';
import { PendingOrdersComponent } from './seller/pending-orders/pending-orders.component';
import { BiddingOrdersComponent } from './seller/bidding-orders/bidding-orders.component';
import { ViewBidsComponent } from './seller/sellerdashboard/view-bids/view-bids.component';
import { TrackMyOrderComponent } from './seller/track-my-order/track-my-order.component';
import { OrderHistoryComponent } from './seller/order-history/order-history.component';
import { ViewOrderDetailsComponent } from './seller/view-order-details/view-order-details.component';
import { BusinessDetailsComponent } from './seller/business-details/business-details.component';
import { OrderReportsComponent } from './seller/sellerdashboard/order-reports/order-reports.component';
import { ReturnReportComponent } from './seller/sellerdashboard/order-reports/return-report/return-report.component';
import { CancellationReportComponent } from './seller/sellerdashboard/order-reports/cancellation-report/cancellation-report.component';
import { DeliveryReportComponent } from './seller/sellerdashboard/order-reports/delivery-report/delivery-report.component';
import { DetailedOrderReportComponent } from './seller/sellerdashboard/order-reports/detailed-order-report/detailed-order-report.component';
import { SellerReportsComponent } from './seller/sellerdashboard/seller-reports/seller-reports.component';
import { SellerReportComponent } from './seller/sellerdashboard/seller-reports/seller-report/seller-report.component';
import { PaymentReportsComponent } from './seller/sellerdashboard/payment-reports/payment-reports.component';
import { ChargesAndCommisionComponent } from './seller/sellerdashboard/payment-reports/charges-and-commision/charges-and-commision.component';
import { ConsolidatedPaymentReportComponent } from './seller/sellerdashboard/payment-reports/consolidated-payment-report/consolidated-payment-report.component';
import { OutstandingPaymentComponent } from './seller/sellerdashboard/payment-reports/outstanding-payment/outstanding-payment.component';
import { RefundReportComponent } from './seller/sellerdashboard/payment-reports/refund-report/refund-report.component';
import { SettledPaymentComponent } from './seller/sellerdashboard/payment-reports/settled-payment/settled-payment.component';
import { WalletTransactionComponent } from './seller/sellerdashboard/payment-reports/wallet-transaction/wallet-transaction.component';
import { SubSellerDetailsComponent } from './seller/sub-seller-details/sub-seller-details.component';
import { SubSellerOrdersComponent } from './seller/sellerdashboard/seller-management/sub-seller-orders/sub-seller-orders.component';
import { SubSellerRequestComponent } from './seller/sellerdashboard/seller-management/sub-seller-request/sub-seller-request.component';
import { JoinACandfComponent } from './seller/join-a-candf/join-a-candf.component';
import { TaxReportsComponent } from './seller/sellerdashboard/tax-reports/tax-reports.component';
import { GstrReportComponent } from './seller/sellerdashboard/tax-reports/gstr-report/gstr-report.component';
import { TdsReportComponent } from './seller/sellerdashboard/tax-reports/tds-report/tds-report.component';
import { TcsReportComponent } from './seller/sellerdashboard/tax-reports/tcs-report/tcs-report.component'
import { SignatureInvoicingComponent } from './seller/sellerdashboard/signature-invoicing/signature-invoicing.component';
import { MyInvoicesComponent } from './seller/my-invoices/my-invoices.component';
import { TransportationDocumentComponent } from './seller/my-invoices/transportation-document/transportation-document.component';
import { ChargesInvoiceComponent } from './seller/my-invoices/charges-invoice/charges-invoice.component';
import { DealInvoiceComponent } from './seller/my-invoices/deal-invoice/deal-invoice.component';
import { BidsAndOffersComponent } from './seller/sellerdashboard/bids-and-offers/bids-and-offers.component';
import { SellerNotificationComponent } from './seller/seller-notification/seller-notification.component';
import { SellerAssistanceComponent } from './seller/seller-assistance/seller-assistance.component';
import { SellerArticleDetailsComponent } from './seller/seller-assistance/seller-article-details/seller-article-details.component';
import { SellerMoreArticlesComponent } from './seller/seller-assistance/seller-more-articles/seller-more-articles.component';
import { AdminComponent } from './admin/admin.component';
import { ManageSellerComponent } from './admin/manage-seller/manage-seller.component';
import { SupplierPerformanceComponent } from './admin/manage-seller/supplier-performance/supplier-performance.component';
import { WalletComponent } from './admin/wallet/wallet.component';
import { WalletBalanceComponent } from './admin/wallet/wallet-balance/wallet-balance.component';
import { MakePaymentComponent } from './admin/wallet/make-payment/make-payment.component';
import { WalletTransactionHistoryComponent } from './admin/wallet/wallet-transaction-history/wallet-transaction-history.component';
import { SellerAuthGuard } from './helpers/seller-auth.guard';
import { AddNewProductComponent } from './admin/add-new-product/add-new-product.component';
import { ProductCategoryComponent } from './admin/product-category/product-category.component';
import { ProductListingComponent } from './admin/product-listing/product-listing.component';
import { ViewProductDetailsComponent } from './admin/view-product-details/view-product-details.component';
import { HsnandgstmanagementComponent } from './admin/hsnandgstmanagement/hsnandgstmanagement.component';
import { TestingComponent } from './testing/testing.component';
import { ManageProductCategoriesComponent } from './admin/manage-product-categories/manage-product-categories.component';
import { EditMainCategoryComponent } from './admin/edit-main-category/edit-main-category.component';
import { EditSubCategoryComponent } from './admin/edit-sub-category/edit-sub-category.component';
import { AddNewCategoryComponent } from './admin/add-new-category/add-new-category.component';
import { AdminHomeComponent } from './admin/home/home.component';
import { ManageBuyerComponent } from './admin/manage-buyer/manage-buyer.component';
import { ManagecandfComponent } from './admin/managecandf/managecandf.component';
import { ViewCanfRequestsComponent } from './admin/managecandf/view-canf-requests/view-canf-requests.component';
import { ViewApprovedCandfComponent } from './admin/managecandf/view-approved-candf/view-approved-candf.component';
import { ViewSubSellersComponent } from './admin/managecandf/view-sub-sellers/view-sub-sellers.component';
import { ViewSubSellerDetailsComponent } from './admin/managecandf/view-sub-seller-details/view-sub-seller-details.component';
import { ViewDetailsComponent } from './admin/manage-buyer/view-details/view-details.component';
import { BuyerBusinessDetailsComponent } from './admin/manage-buyer/buyer-business-details/buyer-business-details.component';
import { BuyerBankDetailsComponent } from './admin/manage-buyer/buyer-bank-details/buyer-bank-details.component';
import { SellerViewDetailsComponent } from './admin/manage-seller/seller-view-details/seller-view-details.component';
import { SellerBusinessDetailsComponent } from './admin/manage-seller/seller-business-details/seller-business-details.component';
import { SellerBankDetailsComponent } from './admin/manage-seller/seller-bank-details/seller-bank-details.component';
import { ViewRequestedDetailsComponent } from './admin/view-requested-details/view-requested-details.component';
import { ViewSellerListingProductsComponent } from './admin/view-seller-listing-products/view-seller-listing-products.component';
import { EditSellerListingComponent } from './admin/edit-seller-listing/edit-seller-listing.component';
import { AdminDashboardComponent } from './admin/dashboard/dashboard.component';
import { BuyerdashboardComponent } from './buyer/buyerdashboard/buyerdashboard.component';
import { BusinessDetailsBuyerComponent } from './buyer/buyerdashboard/business-details-buyer/business-details-buyer.component';
import { BankDetailsBuyerComponent } from './buyer/buyerdashboard/bank-details-buyer/bank-details-buyer.component';
import { MyListingComponent } from './seller/my-listing/my-listing.component';
import { EditListtingComponent } from './admin/edit-listting/edit-listting.component';
import { AdminAuthGuard } from './helpers/admin-auth.guard';
import { BuyerAuthGuard } from './helpers/buyer-auth.guard';
import { LoggedinAuthGuard } from './helpers/loggedin-auth.guard';
import { GuestGuard } from './helpers/guest.guard';
import { RegistrationAuthGuard } from './helpers/registration-auth.guard';
import { ViewListingComponent } from './seller/view-listing/view-listing.component';
import { PostOrdersComponent } from './seller/post-orders/post-orders.component';
import { AddListingComponent } from './seller/add-listing/add-listing.component';
import { AddProductComponent } from './seller/add-product/add-product.component';
import { BrandManagementComponent } from './admin/brand-management/brand-management.component';
import { AddBrandComponent } from './admin/add-brand/add-brand.component';
import { EditBrandComponent } from './admin/edit-brand/edit-brand.component';
import { ReviewModerationComponent } from './admin/review-moderation/review-moderation.component';
import { ViewProductReviewComponent } from './admin/review-moderation/view-product-review/view-product-review.component';
import { SellerReviewsComponent } from './admin/review-moderation/seller-reviews/seller-reviews.component';
import { ViewSellerReviewComponent } from './admin/review-moderation/view-seller-review/view-seller-review.component';
import { LogisticPartnersListComponent } from './admin/logistic-partners-list/logistic-partners-list.component';
import { AddNewPartnerComponent } from './admin/logistic-partners-list/add-new-partner/add-new-partner.component';
import { EditNewPartnerComponent } from './admin/logistic-partners-list/edit-new-partner/edit-new-partner.component';
import { DuplicateOrderComponent } from './admin/duplicate-order/duplicate-order.component';
import { TrackOrderComponent } from './admin/track-order/track-order.component';
import { OrderDetailsComponent } from './admin/order-details/order-details.component';
import { OrderComponent } from './admin/order/order.component';
import { PostYourReqiurementComponent } from './seller/post-your-reqiurement/post-your-reqiurement.component';
import { PersonalDetailsComponent } from './seller/personal-details/personal-details.component';
import { OrderQualityCheckComponent } from './admin/order-quality-check/order-quality-check.component';
import { ViewPendingQcDetailsComponent } from './admin/order-quality-check/view-pending-qc-details/view-pending-qc-details.component';
import { AdminPersonalDetailsComponent } from './admin/settings/personal-details/personal-details.component';
import { BankAccountDetailsComponent } from './admin/settings/bank-account-details/bank-account-details.component';
import { AuthorizedPersonsComponent } from './admin/settings/authorized-persons/authorized-persons.component';
import { AccessManagementComponent } from './admin/settings/access-management/access-management.component';
import { GrantAccessComponent } from './admin/settings/access-management/grant-access/grant-access.component';
import { AddBanksGlobalComponent } from './admin/settings/add-banks-global/add-banks-global.component';
import { ActivityLogsComponent } from './admin/settings/activity-logs/activity-logs.component';
import { CategoryComponent } from './buyer/category/category.component';
import { SearchComponent } from './buyer/search/search.component';
import { SerllerInfoComponent } from './buyer/serller-info/serller-info.component';
import { PersonalDetailsBuyerComponent } from './buyer/personal-details-buyer/personal-details-buyer.component';
import { CardConfirmationComponent } from './buyer/card-confirmation/card-confirmation.component';
import { AddMoneyCardConfirmationComponent } from './buyer/add-money-card-confirmation/add-money-card-confirmation.component';
import { UnclearBalanceComponent } from './admin/unclear-balance/unclear-balance.component';
import { PaymentSuccessComponent } from './buyer/payment-success/payment-success.component';
import { PaymentFailedComponent } from './buyer/payment-failed/payment-failed.component';
import { AllBidsAndOffersComponent } from './buyer/all-bids-and-offers/all-bids-and-offers.component';
import { LogisticsChargesComponent } from './admin/logistics-charges/logistics-charges.component';
import { ManageSubsellerComponent } from './seller/manage-subseller/manage-subseller.component';
import { SubSellerProductsComponent } from './seller/sub-seller-products/sub-seller-products.component';
import { BuyerOrderHistoryComponent } from './buyer/buyer-order-history/buyer-order-history.component';
import { BuyerHomeComponent } from './admin/cms/buyer-home/buyer-home.component';
import { AllBidsComponent } from './seller/all-bids/all-bids.component';
import { RegistrationPendingComponent } from './seller/registration-pending/registration-pending.component';

import { SubscribersListComponent } from './admin/subscribers-list/subscribers-list.component';
import { RequestCallbackListComponent } from './admin/request-callback-list/request-callback-list.component';
import { EnquiryListComponent } from './admin/enquiry-list/enquiry-list.component';
import { CmsLogisticsPolicyComponent } from './admin/cms/logistics-policy/logistics-policy.component';
import { CmsIpReportsInfringementPolicyComponent } from './admin/cms/ip-reports-infringement-policy/ip-reports-infringement-policy.component';
import { CmsTermsOfUseComponent } from './admin/cms/terms-of-use/terms-of-use.component';
import { CmsPrivacyPolicyComponent } from './admin/cms/privacy-policy/privacy-policy.component';
import { CmsAntiCounterfeitingPolicyComponent } from './admin/cms/anti-counterfeiting-policy/anti-counterfeiting-policy.component';
import { AdminAssistanceComponent } from './admin/admin-assistance/admin-assistance.component';
import { AdminMoreArticlesComponent } from './admin/admin-assistance/admin-more-articles/admin-more-articles.component';
import { AdminArticleDetailsComponent } from './admin/admin-assistance/admin-article-details/admin-article-details.component';
import { SubsellerDashboardComponent } from './seller/subseller-dashboard/subseller-dashboard.component';
import { InvoiceComponent } from './buyer/invoice/invoice.component';
import { CmsReturnPolicyComponent } from './admin/cms/return-policy/return-policy.component';
import { ReturnPolicyComponent } from './shared/return-policy/return-policy.component';
import { BuyerCategoryComponent } from './admin/cms/buyer-category/buyer-category.component';
import { BuyerCategoryAddComponent } from './admin/cms/buyer-category-add/buyer-category-add.component';
import { BuyerCategoryEditComponent } from './admin/cms/buyer-category-edit/buyer-category-edit.component';
import { BuyerChargesInvoiceComponent } from './buyer/buyer-charges-invoice/buyer-charges-invoice.component';
import { AdminChargesInvoiceComponent } from './admin/admin-charges-invoice/admin-charges-invoice.component';
import { AdminAllBidsComponent } from './admin/admin-all-bids/admin-all-bids.component';
import { AdminProductBidsComponent } from './admin/admin-product-bids/admin-product-bids.component';
import { BuyerTransportationDocumentsComponent } from './buyer/buyer-transportation-documents/buyer-transportation-documents.component';
import { StocksDetailsComponent } from './seller/stocks-details/stocks-details.component';
import { UnconfirmedOrdersComponent } from './admin/unconfirmed-orders/unconfirmed-orders.component';
import { UnconfirmedWalletComponent } from './admin/unconfirmed-wallet/unconfirmed-wallet.component';
import { CountriesComponent } from './admin/countries/countries.component';
import { MediaComponent } from './admin/media/media.component';
import { ReturnOrdersComponent } from './seller/return-orders/return-orders.component';
import { ReturnRequestOrdersComponent } from './admin/return-request-orders/return-request-orders.component';
import { ProductStockListingComponent } from './admin/product-stock-listing/product-stock-listing.component';
import { BuyerBuyNowPayLaterComponent } from './buyer/buyer-buy-now-pay-later/buyer-buy-now-pay-later.component';
import { BnplPaymentSuccessComponent } from './buyer/bnpl-payment-success/bnpl-payment-success.component';
import { BnplTransactionReportsComponent } from './buyer/bnpl-transaction-reports/bnpl-transaction-reports.component';
import { AdminBnplTransactionReportsComponent} from './admin/admin-bnpl-transaction-reports/admin-bnpl-transaction-reports.component';
import { BnplChargesComponent} from './admin/bnpl-charges/bnpl-charges.component';
import { EquibizPayLaterComponent } from './shared/equibiz-pay-later/equibiz-pay-later.component';
import { SpiceMoneyUsersComponent } from './admin/spice-money-users/spice-money-users.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'product',
        redirectTo: 'product',
        pathMatch: 'full'
    },
    {
        path: 'testing',
        component: TestingComponent
    },
    {
        path: 'how-it-works',
        component: HowItWorksComponent
    },
    {
        path: 'services',
        component: ServicesComponent
    },
    {
        path: 'partners',
        component: PartnersComponent
    },
    {
        path: 'about-us',
        component: AboutUsComponent
    },
    {
        path: 'contact-us',
        component: ContactUsComponent
    },
    {
        path: 'buyer-notification',
        component: BuyerNotificationComponent
    },
    {
        path: 'buyer/assistance',
        component: BuyerAssistanceComponent
    },
    {
        path: 'buyer/more-articles/:id',
        component: MoreArticlesComponent
    },
    {
        path: 'buyer/article-details/:id',
        component: ArticleDetailsComponent
    },
    {
        path: 'equibiz-pay-later',
        component: EquibizPayLaterComponent
    },

    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
        canActivate: [LoggedinAuthGuard]
    },

    {
        path: 'seller-info',
        component: SerllerInfoComponent
    },
    {
        path: 'product/details/:uid',
        component: ProductDetailsComponent
    },
    {
        path: 'brand/:brand_slug',
        component: BrandComponent
    },
    {
        path: 'category/:category_slug',
        component: CategoryComponent
    },
    {
        path: 'search',
        component: SearchComponent
    },
    {
        path: 'deal/:seller_product_id/:market_place',
        component: DealComponent
    },
    {
        path: 'cart',
        component: CartComponent
    },
    {
        path: 'checkout',
        component: CheckoutComponent
    },
    {
        path: 'buyer/bids-offers/:product_id',
        component: BidsOffersComponent
    },
    {
        path: 'buyer/my-bids',
        component: MyBidsComponent
    },
    {
        path: 'buyer/write-review',
        component: WriteReviewComponent
    },
    {
        path: 'buyer/buyer-wallet',
        component: BuyerWalletComponent
    },
    {
        path: 'buyer/buyer-add-money',
        component: BuyerAddMoneyComponent
    },
    {
        path: 'buyer/buyer-manage-payment-methods',
        component: BuyerManagePaymentMethodsComponent
    },
    {
        path: 'buyer/buyer-transaction-history',
        component: BuyerTransactionHistoryComponent
    },
    {
        path: 'buyer/buyer-view-order-details/:orderId',
        component: BuyerViewOrderDetailsComponent
    },
    {
        path: 'buyer/buyer-wishlist',
        component: BuyerWishlistComponent
    },
    
    // {
    //     path: 'buyer/buyer-add-payment-method',
    //     component: BuyerAddPaymentMethodComponent
    // },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent
    },
    {
        path: 'return-policy',
        component: ReturnPolicyComponent
    },
    {
        path: 'terms-of-use',
        component: TermsOfUseComponent
    },
    {
        path: 'infringement-policy',
        component: InfringementPolicyComponent
    },
    {
        path: 'anti-counterfeiting-policy',
        component: AntiCounterfeitingPolicyComponent
    },
    {
        path: 'logistics-policy',
        component: CancellationReturnsPolicyComponent
    },
    {
        path: 'insurance-policy',
        component: InsurancePolicyComponent
    },
    {
        path: 'bankdetails',
        component: BankDetailsComponent
    },
    
    {
        path: 'withdrawal-request',
        component: WithdrawalRequestComponent
    },
    {
        path: 'add-money',
        component: AddMoneyComponent
    },
    {
        path: 'manage-payment-methods',
        component: ManagePaymentMethodsComponent
    },
    {
        path: 'add-payment-method',
        component: AddPaymentMethodComponent
    },
    
    {
        path: 'manageseller',
        component: ManageSellerComponent
    },
    

    
    {
        path: 'view-bids/:product_id',
        component: ViewBidsComponent
    },
    {
        path: 'seller/bids-and-offers/:product_id',
        component: BidsAndOffersComponent
    },
    
    {
        path: 'order-reports',
        component: OrderReportsComponent
    },
    {
        path: 'return-report',
        component: ReturnReportComponent
    },
    {
        path: 'cancellation-report',
        component: CancellationReportComponent
    },
    {
        path: 'delivery-report',
        component: DeliveryReportComponent
    },
    {
        path: 'detailed-order-report',
        component: DetailedOrderReportComponent
    },
    {
        path: 'seller-reports',
        component: SellerReportsComponent
    },
    {
        path: 'seller-report',
        component: SellerReportComponent
    },
    {
        path: 'payment-reports',
        component: PaymentReportsComponent
    },
    {
        path: 'charges-and-commission',
        component: ChargesAndCommisionComponent
    },
    {
        path: 'consolidated-payment-report',
        component: ConsolidatedPaymentReportComponent
    },
    {
        path: 'outstanding-payment',
        component: OutstandingPaymentComponent
    },
    {
        path: 'refund-report',
        component: RefundReportComponent
    },
    {
        path: 'settled-payment',
        component: SettledPaymentComponent
    },
    {
        path: 'wallet-transaction',
        component: WalletTransactionComponent
    },
    {
        path: 'tax-reports',
        component: TaxReportsComponent
    },
    {
        path: 'gstr-report',
        component: GstrReportComponent
    },
    {
        path: 'tds-report',
        component: TdsReportComponent
    },
    {
        path: 'tcs-report',
        component: TcsReportComponent
    },

    {
        path: 'buyer/buyer-buy-now-pay-later',
        component: BuyerBuyNowPayLaterComponent
    },




    //Seller Routes
    {
        path: "seller",
        canActivate: [SellerAuthGuard],
        children: [
            { path: "mylisting", component: MyListingComponent },
            { path: "mylisting/stocks", component: StocksDetailsComponent },
            { path: "viewlisting/:uniqueId", component: ViewListingComponent },
            { path: "postorders", component: PostOrdersComponent },
            { path: 'addlisting', component: AddListingComponent },
            { path: 'addproduct/:uniqueId', component: AddProductComponent },
            { path: 'dashboard', component: SellerdashboardComponent },
            { path: 'subseller/dashboard', component: SubsellerDashboardComponent },
            { path: 'editlisting', component: EditListtingComponent },
            { path: 'personal-details', component: PersonalDetailsComponent },
            { path: 'business-details', component: BusinessDetailsComponent },
            { path: 'bank-details', component: BankDetailsComponent },
            { path: 'postyourreqiurement', component: PostYourReqiurementComponent },
            { path: 'signature-invoicing', component: SignatureInvoicingComponent },
            { path: 'seller-notification', component: SellerNotificationComponent },
            { path: 'assistance', component: SellerAssistanceComponent },
            { path: 'more-articles/:id', component: SellerMoreArticlesComponent },
            { path: 'article-details/:id', component: SellerArticleDetailsComponent },
            { path: 'sub-sellers', component: ManageSubsellerComponent },
            { path: 'sub-seller-products/:id', component: SubSellerProductsComponent },
            { path: 'sub-seller-details/:id', component: SubSellerDetailsComponent },
            { path: 'sellerdashboard/seller-management/sub-seller-orders', component: SubSellerOrdersComponent },
            { path: 'sellerdashboard/seller-management/sub-seller-request', component: SubSellerRequestComponent },
            { path: 'join-a-candf', component: JoinACandfComponent },
            { path: 'view-order-details/:orderId', component: ViewOrderDetailsComponent },
            { path: 'pending-orders', component: PendingOrdersComponent },
            { path: 'track-my-order', component: TrackMyOrderComponent },
            { path: 'my-wallet', component: MyWalletComponent },
            { path: 'transaction-history', component: TransactionHistoryComponent },
            { path: 'bidding-orders', component: BiddingOrdersComponent },
            { path: 'my-invoices', component: MyInvoicesComponent },
            { path: 'order-history', component: OrderHistoryComponent },
            { path: 'order-return', component: ReturnOrdersComponent },
            { path: 'all-bids', component: AllBidsComponent},
            { path: 'pending', component: RegistrationPendingComponent },
            { path: 'transportation-document', component: TransportationDocumentComponent },
            { path: 'charges-invoice', component: ChargesInvoiceComponent },
            { path: 'deal-invoice', component: DealInvoiceComponent },
        ]
    },
    {
        path: 'seller/login',
        component: SellerComponent,
        canActivate: [GuestGuard]
    },




    //Admin Routes
    {
        path: "admin",
        canActivate: [AdminAuthGuard],
        children: [
            { path: "dashboard", component: AdminDashboardComponent },
            { path: "manage-buyer", component: ManageBuyerComponent },
            { path: "manage-buyer/view-details/:id", component: ViewDetailsComponent },
            { path: 'manage-buyer/buyer-business-details/:id', component: BuyerBusinessDetailsComponent },
            { path: 'manage-buyer/buyer-bank-details/:id', component: BuyerBankDetailsComponent },
            { path: 'manage-seller', component: ManageSellerComponent },
            { path: "manage-seller/view-details/:id", component: ViewDetailsComponent },
            { path: 'manage-seller/seller-business-details/:id', component: BuyerBusinessDetailsComponent },
            { path: 'manage-seller/seller-bank-details/:id', component: BuyerBankDetailsComponent },
            { path: 'manage-seller/seller-view-details', component: SellerViewDetailsComponent },
            { path: 'manage-seller/seller-business-details', component: SellerBusinessDetailsComponent },
            { path: 'manage-seller/seller-bank-details', component: SellerBankDetailsComponent },
            { path: 'manage-product-categories', component: ManageProductCategoriesComponent },
            { path: 'product-category/add', component: AddNewCategoryComponent },
            { path: 'product-category/edit-category/:slug', component: EditMainCategoryComponent },
            { path: 'view-requested-details/:id', component: ViewRequestedDetailsComponent },
            { path: 'view-seller-listing-products/:id', component: ViewSellerListingProductsComponent },
            { path: 'edit-seller-listing/:sellerId/:uniqueId', component: EditSellerListingComponent },
            { path: 'product-category/edit-sub-category/:slug', component: EditSubCategoryComponent },
            { path: 'products/add/:uniqueId', component: AddNewProductComponent },
            { path: 'product/category/add', component: ProductCategoryComponent },
            { path: 'product-listing', component: ProductListingComponent },
            { path: 'view-product-details/:id', component: ViewProductDetailsComponent },
            { path: 'product/brand-management', component: BrandManagementComponent },
            { path: 'wallet/make-payment', component: MakePaymentComponent },
            { path: 'wallet/balance', component: WalletBalanceComponent },
            { path: 'wallet/transaction-history', component: WalletTransactionHistoryComponent },
            { path: 'media', component: MediaComponent },
            { path: 'settings/countries', component: CountriesComponent },
            { path: 'wallet', component: WalletComponent },
            { path: 'all-bids', component: AdminAllBidsComponent },
            { path: 'all-bids/:productId', component: AdminProductBidsComponent },
            { path: 'unclear/balance', component: UnclearBalanceComponent },
            { path: 'brandmanagment', component: BrandManagementComponent },
            { path: 'addbrand', component: AddBrandComponent },
            { path: 'editbrand/:brandId', component: EditBrandComponent },
            { path: 'review-moderation/product-reviews', component: ReviewModerationComponent },
            { path: 'review-moderation/seller-reviews', component: SellerReviewsComponent },
            { path: 'review-moderation/view-product-review/:id', component: ViewProductReviewComponent },
            { path: 'review-moderation/view-seller-review/:id', component: ViewSellerReviewComponent },
            { path: 'logistic-partners-list', component: LogisticPartnersListComponent },
            { path: 'logistic-partners-list/add-new-partner', component: AddNewPartnerComponent },
            { path: 'logistic-partners-list/edit-new-partner/:id', component: EditNewPartnerComponent },
            { path: 'duplicateorder', component: DuplicateOrderComponent },
            { path: 'trackorder', component: TrackOrderComponent },
            { path: 'ordderdetails/:orderId', component: OrderDetailsComponent },
            { path: 'order', component: OrderComponent },
            { path: 'order-return', component: ReturnRequestOrdersComponent },
            { path: 'managecandf', component: ManagecandfComponent },
            { path: 'view-canf-requests/:id', component: ViewCanfRequestsComponent },
            { path: 'view-approved-candf', component: ViewApprovedCandfComponent },
            { path: 'view-sub-sellers/:id', component: ViewSubSellersComponent },
            { path: 'view-sub-seller-details', component: ViewSubSellerDetailsComponent },
            { path: 'hsnandgstmanagement', component: HsnandgstmanagementComponent },
            { path: 'settings/personal-details', component: AdminPersonalDetailsComponent },
            { path: 'settings/bank-account-details', component: BankAccountDetailsComponent },
            { path: 'settings/authorized-persons', component: AuthorizedPersonsComponent },
            { path: 'settings/access-management', component: AccessManagementComponent },
            { path: 'settings/access-management/grant-access', component: GrantAccessComponent },
            { path: 'settings/add-banks-global', component: AddBanksGlobalComponent },
            { path: 'settings/activity-logs', component: ActivityLogsComponent },
            { path: 'order-quality-check', component: OrderQualityCheckComponent },
            { path: 'view-pending-qc-details/:id', component: ViewPendingQcDetailsComponent },
            { path: 'logistics-charges', component: LogisticsChargesComponent },
            { path: 'cms/buyer-home', component: BuyerHomeComponent },
            { path: 'subscribers', component: SubscribersListComponent},
            { path: 'charges-invoice', component: AdminChargesInvoiceComponent },
            { path: 'unconfirmed-orders', component: UnconfirmedOrdersComponent },
            { path: 'unconfirmed-wallet', component: UnconfirmedWalletComponent },
            { path: 'request-callback', component: RequestCallbackListComponent },
            { path: 'enquiry-list', component: EnquiryListComponent },
            { path: 'cms/terms', component: CmsTermsOfUseComponent },
            { path: 'cms/privacy-policy', component: CmsPrivacyPolicyComponent },
            { path: 'cms/return-policy', component: CmsReturnPolicyComponent },
            { path: 'cms/ip-reports-infringement-policy', component: CmsIpReportsInfringementPolicyComponent },
            { path: 'cms/anti-counterfeiting-policy', component: CmsAntiCounterfeitingPolicyComponent },
            { path: 'cms/logistics-policy', component: CmsLogisticsPolicyComponent },
            { path: 'assistance', component: AdminAssistanceComponent },
            { path: 'more-articles/:id', component: AdminMoreArticlesComponent },
            { path: 'article-details/:id', component: AdminArticleDetailsComponent },
            { path: 'supplier-performance', component: SupplierPerformanceComponent },
            { path: 'cms/buyer-category', component: BuyerCategoryComponent },
            { path: 'cms/buyer-category/add', component: BuyerCategoryAddComponent },
            { path: 'cms/buyer-category/edit/:id', component: BuyerCategoryEditComponent },
            { path: 'cms/buyer-category/edit/:id', component: BuyerCategoryEditComponent },
            { path: 'stock-listing', component: ProductStockListingComponent },
            { path: 'bnpl/transaction/reports', component: AdminBnplTransactionReportsComponent },
            { path: 'spice/money/reports', component: SpiceMoneyUsersComponent },
            { path: 'bnpl-charges', component: BnplChargesComponent },
        ]
    },
    {
        path: 'admin/login',
        component: AdminHomeComponent,
        canActivate: [GuestGuard]
    },

    //Buyer Routes
    {
        path: "buyer",
        canActivate: [BuyerAuthGuard],
        children: [
            { path: "dashboard", component: BuyerdashboardComponent },
            { path: "personal-details", component: PersonalDetailsBuyerComponent },
            { path: 'business-details', component: BusinessDetailsBuyerComponent },
            { path: 'bank-details', component: BankDetailsBuyerComponent },
            { path: 'card-confirmation', component: CardConfirmationComponent },
            { path: 'add-money/card/callback', component: AddMoneyCardConfirmationComponent },
            { path: 'payment/success', component: PaymentSuccessComponent },
            { path: 'payment/failed', component: PaymentFailedComponent },
            { path: 'bnpl/payment/success', component: BnplPaymentSuccessComponent },
            { path: 'bnpl/transaction/reports', component: BnplTransactionReportsComponent },
            { path: 'bids-offers', component: AllBidsAndOffersComponent },
            { path: 'buyer-track-orders', component: BuyerTrackOrdersComponent },
            { path: 'order-history', component: BuyerOrderHistoryComponent },
            { path: 'invoice', component: InvoiceComponent },
            { path: 'charges-invoice', component: BuyerChargesInvoiceComponent },
            { path: 'manage-categories', component: BuyerManageCategoriesComponent },
            { path: 'transportation-documents', component: BuyerTransportationDocumentsComponent },
        ]
    },

    //Common Routes
    { path: 'registration', component: RegistrationComponent, canActivate: [GuestGuard] },
    { path: 'registration/:slug/details', component: RegistrationDetailsComponent, canActivate: [RegistrationAuthGuard], },
    { path: 'buyer/business-details', component: BusinessDetailsBuyerComponent },
    { path: 'buyer/bank-details', component: BankDetailsBuyerComponent },
    { path: 'reset-password/:reset-token', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
    { path: 'setpassword/:signup-token', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },

    {
        path: '**', // Navigate to Home Page if not found any page
        redirectTo: 'home',
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabled',
        useHash: false,
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
        relativeLinkResolution: 'legacy'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
