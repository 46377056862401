import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buyer-add-payment-method',
  templateUrl: './buyer-add-payment-method.component.html',
  styleUrls: ['./buyer-add-payment-method.component.scss']
})
export class BuyerAddPaymentMethodComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
