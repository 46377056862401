<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain pb-4">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center pinfo-head">
			<div class="col-md-5 mb-4">
				<h3 class="fs24 wt700 txtdefault">Orders</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">						
						<li class="breadcrumb-item "><a href="javascript:void(0);" class="txtseconday">Order Management</a></li>
						<li class="breadcrumb-item active">Orders</li>
					</ol>
				</nav>
			</div>
			<div class="col-md-7  mb-4 text-md-right text-center">
				
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4 mb-0">
			<div class="tab-content">
				<!--Filters-->
				<div class="row d-flex justify-content-between mt-20 report-cta">
					<div class="col-lg-5 mb-3">
						<div class="report-input">
							<div class="input-group-custom">
								<input type="text" placeholder="Search by Order ID, Transaction ID, Product Name & Brand" class="form-control" 
								class="form-control" [(ngModel)]="search_term" (change)="getinvoicerecordsfilters()">
								<div class="input-group-append-custom">
									<img src="assets/images/svg/search_logo.svg">
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg-7">
						<div class="d-flex clbxgrd justify-content-end lg-justify-content-start">
							<div class="form-container mt-0 mb-0 mr-3">
								<div class="report-input floating-label">
									<div class="input-group-custom">
										<input type="text" [(ngModel)]="from_date" (ngModelChange) = "getinvoicerecordsfilters()" class="form-control" placeholder=" " ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" autocomplete="off"/>
										<span class="highlight"></span>
										<label>From Date</label>
										<div class="input-group-append-custom">
											<img src="assets/images/icons/filter-calendar.svg">
										</div>
									</div>
								</div>
							</div>

							<div class="form-container mt-0 mb-0 mr-3">
								<div class="form-container m-0">
									<div class="report-input floating-label">
										<div class="input-group-custom">
											<input type="text" [(ngModel)]="to_date" (ngModelChange) = "getinvoicerecordsfilters()" class="form-control" placeholder=" " ngbDatepicker #dd="ngbDatepicker" (click)="dd.toggle()" autocomplete="off"/>
											<span class="highlight"></span>
											<label>To Date</label>
											<div class="input-group-append-custom">
												<img src="assets/images/icons/filter-calendar.svg">
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!--End Filters-->
				<div class="cssinptchk d-inline-block mr-4 position-relative pl-4">
					<span class="css-check">
						<input class="css-checkbx" name="map_type" type="radio" value="all" [(ngModel)]="map_type" (ngModelChange)="getinvoicerecordsfilters()">
						<label class="css-label"></label>
					</span>
					<p>All</p>															
				</div>
				<div class="cssinptchk d-inline-block mr-3 position-relative pl-4">
					<span class="css-check">
						<input class="css-checkbx" name="map_type" type="radio" value="bulk"  [(ngModel)]="map_type" (ngModelChange)="getinvoicerecordsfilters()"/>
						<label class="css-label"></label>
					</span>
					<p>Bulk</p>															
				</div>
				<div class="cssinptchk d-inline-block mr-3 position-relative pl-4">
					<span class="css-check">
						<input class="css-checkbx" name="map_type" type="radio" value="wholesale"  [(ngModel)]="map_type" (ngModelChange)="getinvoicerecordsfilters()"/>
						<label class="css-label"></label>
					</span>
					<p>Wholesale</p>															
				</div>
				<div class="table-responsive brndmgnttable odmgnttabl"> 
					<table class="table custom-table xs">
						<thead>
							<tr>
                                <th>Buyer Invoice No.</th>
                                <th>Seller Invoice No.</th>
                                <th>Mkt. Place</th>
                                <th>Order ID</th>
                                <th>Order Date</th>
                                <th>Buyer Name</th>
                                <th>Seller Name</th>
                                <th>C & F</th>
                                <th>Order Qty.</th>
                                <th>Amount</th>
                                <th>Buyer Total Charges</th>
                                <th>Seller Total Charges</th>
                                <th>Status</th>
                                <th>Buyer Charge Invoice</th>
                                <th>Seller Charge Invoice</th>
                            </tr>
						</thead>
						<tbody>
							<!--Empty State-->
							<tr *ngIf="!page_records.length">
								<td colspan="13">
									<div class="row pt-40 pb-40">
										<div class="col-md-12 text-center">
											<div class="mb-20"><img src="/assets/images/empty-state.png">
											</div>
											<div class="fs16">There are no records to display!</div>
										</div>
									</div>
								</td>
							</tr>
							<!--End Empty State-->
							<tr *ngFor="let rec of page_records;">
								<td>{{ rec.buyer_invoice_number }}</td>
                                <td>{{ rec.seller_invoice_number }}</td>
                                <td>{{ rec.map_type }}</td>
                                <td>{{ rec.order_id }}</td>
                                <td>{{ rec.order_status }}</td>
                                <td>{{ rec.buyer_name }}</td>
                                <td>{{ rec.seller_name }}</td>
                                <td>{{ rec.candf }}</td>
                                <td>{{ rec.qty }}</td>
                                <td>{{ rec.total_amount }}</td>
                                <td>{{ rec.buyer_charge }}</td>
                                <td>{{ rec.seller_charge }}</td>
                                <td>{{ rec.order_status }}</td>
                                <td>
                                    <a *ngIf="rec.buyer_invoice_number"  href="{{ rec.buyer_invoice_path }}" target="_blank"><img src="/assets/images/icons/pdf.png"></a><br/><br/>
									<a *ngIf="rec.buyer_invoice_number"  href="javascript:;" (click)="generatechargesinvoice(rec, 'buyer')">Regenerate</a>
                                    <a *ngIf="!rec.buyer_invoice_number"  href="javascript:;" (click)="generatechargesinvoice(rec, 'buyer')">Generate</a>
                                </td>
                                <td>
                                    <a *ngIf="rec.seller_invoice_number"  href="{{ rec.seller_invoice_path }}" target="_blank"><img src="/assets/images/icons/pdf.png"></a><br/><br/>
									<a *ngIf="rec.seller_invoice_number"  href="javascript:;" (click)="generatechargesinvoice(rec, 'seller')">Regenerate</a>
                                    <a *ngIf="!rec.seller_invoice_number"  href="javascript:;" (click)="generatechargesinvoice(rec, 'seller')">Generate</a>
                                </td>
							</tr>
						</tbody>
					</table>
				</div>	
				</div>
			<div class="row mb-40" *ngIf="page_pagination.totalPages> 1">
				<div class="col-md-6">
					<div class="text-left pl-3">
						<nav class="mt-3 tbpg rows-per-page">
							<div>Rows per page:</div>
							<select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
								<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">
									{{option.name}}</option>
							</select>
						</nav>
					</div>
				</div>
				<div class="col-md-6">
					<div class="text-right pr-3">
						<nav class="mt-3 tbpg">
							<ul class="pagination align-items-center">
								<li class="page-item" [ngClass]="{disabled:page_pagination.currentPage === 1}"><a
										class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a>
								</li>

								<li class="page-item" [ngClass]="{disabled:page_pagination.currentPage === 1}"><a
										class="page-link sprevicon" href="javascript:;"
										(click)="setpage(page_pagination.currentPage - 1)"></a></li>

								<li class="page-item" i *ngFor="let page of page_pagination.pages"
									[ngClass]="{active:page_pagination.currentPage === page}"><a class="page-link"
										href="javascript:;" (click)="setpage(page)">{{page}}</a></li>

								<li *ngIf="(page_pagination.totalPages - page_pagination.startPage) > 5 ">
									<a (click)="setpage(page_pagination.startPage + 5)">...</a>
								</li>
								<li *ngIf="(page_pagination.totalPages - page_pagination.startPage) > 5">
									<a
										(click)="setpage(page_pagination.totalPages)">{{page_pagination.totalPages}}</a>
								</li>

								<li class="page-item"
									[ngClass]="{disabled:page_pagination.currentPage === page_pagination.totalPages}">
									<a class="page-link snexticon" href="javascript:;"
										(click)="setpage(page_pagination.currentPage + 1)"></a>
								</li>
								<li class="page-item"
									[ngClass]="{disabled:page_pagination.currentPage === page_pagination.totalPages}">
									<a class="page-link nexticon" href="javascript:;"
										(click)="setpage(page_pagination.totalPages)"></a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
			</div> 
		</div>
	</div>
</div>	