import { Component, OnInit } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchService } from './search.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { merge } from 'hammerjs';
import { PaginationService } from '../../helpers/pagination.service';
import { map } from 'rxjs/operators';
import { ProductDetailsService } from '../product-details/product-details.service';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

    classstatus: boolean = false;

    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 20;
    public search_pagination: any = {};
    public sort_by: string = 'relevance';
    public page_per_view_options = [
        {
            value: 20,
            name: 20
        },
        {
            value: 40,
            name: 40
        }
    ];

    public category_flow: any = [];
    public category_name: string;
    public category_slug: string = '';
    public total_listing: number;
    public total_supplier: number;
    public total_wholesale: number;
    public selected_brand: any = {};
    public total_bulk: number;
    public total_products: number;
    public brand_search: string;
    public search_products: any = [];
    public fast_selling_products: any = [];
    public latest_products: any = [];
    public brands_list: any = [];
    public filter_brands: any = [];
    public filter_category: any = [];
    public filter_variants: any = [];
    public filter_attribute: any = [];
    public paramsquery: any;
    public active_ids = '';
    //public active_ids = 'toggle-1, toggle-2, toggle-3, brand, startingprice, activationstatus, supplylocation, countryorigin';

    public search_key: any = '';
    public brands_array: any = [];
    public category_array: any = [];
    public marketplace_array: any = [];
    public price_array: any = [];
    public variant_array: any = [];
    public technical_array: any = [];
    public supply_locations_array: any = [];
    public country_array: any = [];
    public all_filters: any = [];

    constructor(
        private route: ActivatedRoute,
        private _searchservice: SearchService,
        private router: Router,
        private _toastr: ToasterService,
        private _pagination: PaginationService,
        private _productdetailsservice: ProductDetailsService,
    ) { }
    minValue: number = 0;
    maxValue: number = 10000;
    options: Options = {
        floor: 0,
        ceil: 10000
    };
    public filter_marketplace = [];
    public supply_locations = [
        {
            name: 'Chennai',
            slug: 'chennai',
            selected: false,
        },
        {
            name: 'Mumbai',
            slug: 'mumbai',
            selected: false,
        },
        {
            name: 'Delhi',
            slug: 'delhi',
            selected: false,
        }
    ];
    public countries = [
        {
            name: 'Made in India',
            slug: 'india',
            selected: false,
        },
        {
            name: 'Made in Japan',
            slug: 'japan',
            selected: false,
        },
        {
            name: 'Made in Tamilnadu',
            slug: 'tn',
            selected: false,
        }
    ];
    public filter_prices = [];
    ngOnInit() {
        this.all_filters['maps'] = [];
        this.all_filters['category'] = [];
        this.all_filters['brand'] = [];
        this.all_filters['prices'] = [];
        this.all_filters['variants'] = [];
        this.all_filters['technical'] = [];
        this.route.queryParams
            .subscribe(params => {
                this.paramsquery = params;

                if (params['q'] !== undefined) {
                    this.search_key = params['q'];
                }

                if (params['cat_page'] !== undefined) {
                    this.category_slug = params['cat_page'];
                }

                if (params['sort_by'] !== undefined) {
                    this.sort_by = params['sort_by'];
                }

                if (params['brand'] !== undefined && params['brand'] != '') {
                    this.brands_array = params['brand'].split(',');
                }

                if (params['category'] !== undefined && params['category'] != '') {
                    this.category_array = params['category'].split(',');
                }

                if (params['price'] !== undefined && params['price'] != '') {
                    this.price_array = params['price'].split(',');
                }

                if (params['maps'] !== undefined && params['maps'] != '') {
                    this.marketplace_array = params['maps'].split(',');
                }

                if (params['variant'] !== undefined && params['variant'] != '') {
                    this.variant_array = params['variant'].split(',');
                }

                if (params['technical'] !== undefined && params['technical'] != '') {
                    this.technical_array = params['technical'].split(',');
                }

                if (params['slo'] !== undefined && params['slo'] != '') {
                    this.supply_locations_array = params['slo'].split(',');
                }

                if (params['country'] !== undefined && params['country'] != '') {
                    this.country_array = params['country'].split(',');
                }

                this.getproducts();
            }
            );
        
    }
    tglclass() {
        this.classstatus = !this.classstatus;
    }
    initiatebid(uniqueId){
        this._productdetailsservice.viewsellerlisting = true;
        this.router.navigate(['/product/details/'+uniqueId]);
    }
    public val = null;
    config = {
        labelField: 'label',
        valueField: 'value',
    };

    getproducts() {
        this._searchservice.getproducts(this.paramsquery).subscribe((data) => {
            if (data['status'] == "success") {
                this.category_name = data['category_name'];
                this.category_flow = data['category_flow'];
                this.total_listing = data['list_count'];
                this.total_supplier = data['supplier_count'];
                this.total_wholesale = data['wholesale_count'];
                this.total_bulk = data['bulk_count'];
                this.total_products = data['total_records'];
                this.brands_list = data['brands'];
                this.search_products = data['records'];
                this.latest_products = data['latestarrival_records'];
                this.filter_brands = data['filter_brands'];
                this.filter_category = data['filter_category'];
                this.filter_variants = data['filter_variants'];
                this.filter_attribute = data['filter_attribute'];
                this.filter_marketplace = data['filter_marketplace'];
                this.filter_prices = data['filter_prices'];
                this.minValue = data['min_price'];
                this.maxValue = data['max_price'];

                // for (let i = 0; i < this.filter_variants.length; i++) {
                //     this.active_ids = this.active_ids + ', ' + this.filter_variants[i].slug;
                // }

                //all filters
                let f_marketplace = this.filter_marketplace.filter((f) => f.selected == true);
                let f_category = this.filter_category.filter((f) => f.selected == true);
                let f_brands = this.filter_brands.filter((f) => f.selected == true);
                let f_prices = this.filter_prices.filter((f) => f.selected == true);
                let f_variants = [];
                for (let i = 0; i < this.filter_variants.length; i++) {
                    var vari = this.filter_variants[i].metas.filter((f) => f.selected == true);
                    for (let k = 0; k < vari.length; k++) {
                        f_variants.push(vari[k]);
                    }
                }

                let f_attribute = [];
                for (let i = 0; i < this.filter_attribute.length; i++) {
                    var vari = this.filter_attribute[i].metas.filter((f) => f.selected == true);
                    for (let k = 0; k < vari.length; k++) {
                        f_attribute.push(vari[k]);
                    }
                }

                this.all_filters['maps'] = f_marketplace;
                this.all_filters['category'] = f_category;
                this.all_filters['brand'] = f_brands;
                this.all_filters['prices'] = f_prices;
                this.all_filters['variants'] = f_variants;
                this.all_filters['technical'] = f_attribute;
                let total_records = data['total_records'];
                let pages = Math.ceil(total_records / this.page_per_view);
                this.search_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    removefilter(record, type) {
        if (type == 'category' || type == 'brand') {
            this.deleteMsg(record.id, type);
        } else {
            this.deleteMsg(record.slug, type);
        }
    }

    deleteMsg(msg: string, type) {
        if (type == 'maps') {
            const index: number = this.marketplace_array.indexOf(msg);
            if (index !== -1) {
                this.marketplace_array.splice(index, 1);
            }
            const index1: number = this.all_filters.maps.indexOf(msg);
            if (index1 !== -1) {
                this.all_filters.maps.splice(index1, 1);
            }
        } else if (type == 'category') {
            const index: number = this.category_array.indexOf(msg);
            if (index !== -1) {
                this.category_array.splice(index, 1);
            }
            const index1: number = this.all_filters.category.indexOf(msg);
            if (index1 !== -1) {
                this.all_filters.category.splice(index1, 1);
            }
        } else if (type == 'brand') {
            const index: number = this.brands_array.indexOf(msg);
            if (index !== -1) {
                this.brands_array.splice(index, 1);
            }
            const index1: number = this.all_filters.brand.indexOf(msg);
            if (index1 !== -1) {
                this.all_filters.brand.splice(index1, 1);
            }
        } else if (type == 'price') {
            const index: number = this.price_array.indexOf(msg);
            if (index !== -1) {
                this.price_array.splice(index, 1);
            }
            const index1: number = this.all_filters.prices.indexOf(msg);
            if (index1 !== -1) {
                this.all_filters.prices.splice(index1, 1);
            }
        } else if (type == 'variant') {
            const index: number = this.variant_array.indexOf(msg);
            if (index !== -1) {
                this.variant_array.splice(index, 1);
            }
            const index1: number = this.all_filters.variants.indexOf(msg);
            if (index1 !== -1) {
                this.all_filters.variants.splice(index1, 1);
            }
        } else if (type == 'technical') {
            const index: number = this.technical_array.indexOf(msg);
            if (index !== -1) {
                this.technical_array.splice(index, 1);
            }
            const index1: number = this.all_filters.technical.indexOf(msg);
            if (index1 !== -1) {
                this.all_filters.technical.splice(index1, 1);
            }
        }
        this.setpageperview();

    }

    clearallfilters() {
        this.brands_array = [];
        this.category_array = [];
        this.marketplace_array = [];
        this.price_array = [];
        this.variant_array = [];
        this.technical_array = [];
        this.supply_locations_array = [];
        this.country_array = [];
        this.setpageperview();
    }

    sorting_order = [
        {
            label: 'Relevance',
            value: 'relevance'
        },
        {
            label: 'Price Low to High',
            value: 'low'
        },
        {
            label: 'Price High to Low',
            value: 'high'
        },
        {
            label: 'Product Ratings',
            value: 'rating'
        },
        {
            label: 'Latest Arrivals',
            value: 'latest'
        }
    ];

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.createparams('pagination', '');
    }

    setpageperview() {
        this.current_page = 1;
        this.createparams('pagination', '');
    }

    ratingst: boolean = false;
    ratingsort() {
        this.ratingst = !this.ratingst;
    }

    createparams(type, record) {
        if(type != 'pagination'){
            this.current_page = 1;
            this.offset = 0;
        }
        if (type != 'pagination' && type != 'custom_price' && type != 'sort_by') {
            record.selected = !record.selected;
            if (record.selected) {
                if (type == 'brand') {
                    this.brands_array.push(record.id);
                } else if (type == 'category') {
                    this.category_array.push(record.id);
                } else if (type == 'marketplace') {
                    this.marketplace_array.push(record.slug);
                } else if (type == 'price') {
                    this.price_array = [];
                    this.price_array.push(record.slug);
                } else if (type == 'variant') {
                    this.variant_array.push(record.slug);
                } else if (type == 'technical') {
                    this.technical_array.push(record.slug);
                } else if (type == 'slo') {
                    this.supply_locations_array.push(record.slug);
                } else if (type == 'country') {
                    this.country_array.push(record.slug);
                }
            } else {
                if (type == 'brand') {
                    const index: number = this.brands_array.indexOf(record.id);
                    this.brands_array.splice(index, 1);
                } else if (type == 'category') {
                    const index: number = this.category_array.indexOf(record.id);
                    this.category_array.splice(index, 1);
                } else if (type == 'marketplace') {
                    const index: number = this.marketplace_array.indexOf(record.slug);
                    this.marketplace_array.splice(index, 1);
                } else if (type == 'price') {
                    const index: number = this.price_array.indexOf(record.slug);
                    this.price_array.splice(index, 1);
                } else if (type == 'variant') {
                    const index: number = this.variant_array.indexOf(record.slug);
                    this.variant_array.splice(index, 1);
                } else if (type == 'technical') {
                    const index: number = this.technical_array.indexOf(record.slug);
                    this.technical_array.splice(index, 1);
                } else if (type == 'slo') {
                    const index: number = this.supply_locations_array.indexOf(record.slug);
                    this.supply_locations_array.splice(index, 1);
                } else if (type == 'country') {
                    const index: number = this.country_array.indexOf(record.slug);
                    this.country_array.splice(index, 1);
                }
            }
        }
        
        if (type == 'custom_price') {
            this.price_array = [];
            this.price_array.push(this.minValue + "-" + this.maxValue);
        }

        var array_params: any = {};
        array_params.q = this.search_key;
        if (this.brands_array.length > 0) {
            array_params.brand = this.brands_array.toString();
        }
        if (this.category_array.length > 0) {
            array_params.category = this.category_array.toString();
        }
        if (this.marketplace_array.length > 0) {
            array_params.maps = this.marketplace_array.toString();
        }
        if (this.price_array.length > 0) {
            array_params.price = this.price_array.toString();
        }
        if (this.variant_array.length > 0) {
            array_params.variant = this.variant_array.toString();
        }
        if (this.technical_array.length > 0) {
            array_params.technical = this.technical_array.toString();
        }
        if (this.supply_locations_array.length > 0) {
            array_params.slo = this.supply_locations_array.toString();
        }
        let limit = this.page_per_view;
        let offset = this.offset;
        let current_page = this.current_page;

        array_params.limit = this.page_per_view;
        array_params.offset = this.offset;
        array_params.current_page = this.current_page;
        array_params.sort_by = this.sort_by;
        this.router.navigate(['/search'], { queryParams: array_params })
    }

    changesorting() {
        this.createparams('sort_by', '');
    }

}
