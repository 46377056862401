<app-header-seller></app-header-seller>
<app-sidebar-seller></app-sidebar-seller>
<div class="wrpmain pb-4">
    <div class="container pb-4 custom-scroll-listing">
        <!--- HEAD  --->
        <div class="row align-items-center mb-4 pinfo-head">
            <div class="col-sm-12">
                <nav class="breadcrumb breadcrumb-dui mb-0">
                    <ol class="breadcrumb p-0 bg-transparent  mb-0">
                        <li class="breadcrumb-item">
                            <a class="txtseconday" href="javascript:void(0);" [routerLink]="['/seller/mylisting']">My
                                Products </a>
                        </li>
                        <li class="breadcrumb-item">
                            <a class="txtseconday" [routerLink]="['/seller/mylisting']">My Listings</a>
                        </li>

                        <li class="breadcrumb-item active">{{ variant_name }}</li>
                    </ol>
                </nav>
            </div>
            <div class="col-sm-6 col-5 text-right pl-0">
                &nbsp;
            </div>
        </div>
        <!--- /HEAD  --->
        <div class="row d-flex align-items-center mb-20">
            <div class="col-6 col-sm-6 mb-2">
                <h3 class="f-700 fs22 txtdefault pb-0">
                    <a href="javascript:;" class="go-back" [routerLink]="['/seller/mylisting']"><i
                            class="fa fa-angle-left"></i></a>
                            View Lisiting</h3>
            </div>

            <div class="col-6 col-sm-6 text-md-right text-center pl-0">
                <a (click)="openPreview()" href="javascript:void(0);" class="btn buybtn fs16 radius10">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17.391" height="12.976" viewBox="0 0 17.391 12.976">
                        <g id="Icon_feather-eye" data-name="Icon feather-eye" transform="translate(-0.9 -5.4)">
                            <path id="Path_10492" data-name="Path 10492"
                                d="M1.5,11.888S4.444,6,9.6,6s8.1,5.888,8.1,5.888-2.944,5.888-8.1,5.888S1.5,11.888,1.5,11.888Z"
                                fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="1.2" />
                            <path id="Path_10493" data-name="Path 10493"
                                d="M17.916,15.708A2.208,2.208,0,1,1,15.708,13.5,2.208,2.208,0,0,1,17.916,15.708Z"
                                transform="translate(-6.112 -3.82)" fill="none" stroke="#fff" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="1.2" />
                        </g>
                    </svg>
                    Buyer preview
                </a>
            </div>
        </div>
        <div class="el-crousal">
            <div class="row mb-4 pb-3">
                <div class="col-md-8 ">
                    <div class="bid-order-info">
                        <div class="order-img">
                            <div class="feedback-slides  ">
                                <div class="client-feedback dtlfull">
                                    <ngx-slick-carousel class="carousel feedback" #slickModal="slick-carousel"
                                        [config]="imagesSlider">
                                        <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                                            <img src="{{ slide.img }}" alt="">
                                        </div>
                                    </ngx-slick-carousel>
                                </div>
                            </div>

                        </div>
                        <div class="pd-seller-cntnt">
                            <!--Product Name-->
                            <h5 class="mb-10 fs18">{{ variant_name }}</h5>

                            <!--Edit / Delete-->
                            <div class="text-left light-text mt-10" *ngIf="flag">
                                <a href="/seller/addproduct/{{unique_id}}" class="link-alt"><img
                                        src="assets/images/icons/edit-big.png">Edit Listing</a>
                                &nbsp; | &nbsp;
                                <a href="javascript:;" (click)="open(removeproduct)"><img
                                        src="assets/images/icons/delete-icon.svg"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="d-flex align-items-center justify-content-end">
                        &nbsp; Listing Status &nbsp;&nbsp;
                        <div class="tglbtn dactivestatus inactivedata">
                            <label class="switch">
                                <input type="checkbox" [(ngModel)]="listing_status" (change)="updatestatus('listing')"
                                    *ngIf="flag">
                                <input type="checkbox" [(ngModel)]="listing_status" disabled *ngIf="!flag">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="client-thumbnails dtlthmb text-center">
                <ngx-slick-carousel class="carousel thumbs" #slickModal="slick-carousel" [config]="thumbnailsSlider ">
                    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                        <div class="thmbnaildtl">
                            <img src="{{ slide.img }}" alt="" width="100%">
                        </div>
                    </div>
                </ngx-slick-carousel>
            </div>
        </div>
        <!-- TABING -->
        <div class="add-pro-wrap">
            <ul ngbNav #prodtlnav="ngbNav" [(activeId)]="active" class="nav-tabs mb-4">
                <li *ngFor="let record of records; let i = index;" [ngbNavItem]="i+21" (click)="variantinfo(record)">
                    <a ngbNavLink>{{ record.variant_name }}</a>
                    <ng-template ngbNavContent>
                        <!-- PRODUCT DESCRIPTION -->

                        <div class="view-bids-marketplace" *ngIf="flag">
                            <a href="javascript:void(0);" class="btn-d-outline ripple d-inline-block"
                                [routerLink]="['/seller/bids-and-offers/'+basic_info.product_id]">
                                View Bids Marketplace
                            </a>
                        </div>

                        <div class="card card-ui p-0">
                            <div class="card-header bg-white py-3 px-4">
                                <h4 class="txtdefault fs16 wt700 mb-3">Product Description</h4>
                                <div class="row">
                                    <div class="col-md-9">
                                        <span class="mr-4 txtseconday"><label class="txtdefault">EBPID:</label>{{
                                            record.productinfo.product_desc.ebpid }}</span>
                                        <span class="mr-4 txtseconday"><label class="txtdefault">HSN:</label>{{
                                            record.productinfo.product_desc.hsn }}</span>
                                        <span class="mr-4 txtseconday"><label class="txtdefault">SKU:</label>{{
                                            record.productinfo.product_desc.sku }}</span>
                                        <span class="mr-4 txtseconday"><label class="txtdefault">CRS:</label>{{
                                            record.productinfo.product_desc.crs }}</span>
                                    </div>
                                    <div class="col-md-3" *ngIf="!record.productinfo.child">
                                        <div class="text-right">
                                            <span class="txtseconday fs22 wt700 align-top rpslst"><label
                                                    class="txtdefault fs14 wt500 mb-0">Listed Price:</label> ₹ {{
                                                record.productinfo.product_desc.listed_price }}</span>
                                            &nbsp;
                                            <a href="javascript:;" *ngIf="flag"
                                                [routerLink]="['/seller/addproduct/'+unique_id]"><svg
                                                    class="align-middle" xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink" width="16.615"
                                                    height="16.617" viewBox="0 0 16.615 16.617">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1"
                                                            gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#79b7ff" />
                                                            <stop offset="1" stop-color="#07f" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="np_edit_3094244_000000"
                                                        d="M13.5-.008A3.1,3.1,0,0,0,11.3.9L.857,11.344l4.4,4.4L15.7,5.307A3.114,3.114,0,0,0,13.5-.008ZM.317,12.273,0,16.046a.519.519,0,0,0,.561.561l3.771-.32Z"
                                                        transform="translate(0.003 0.008)"
                                                        fill="url(#linear-gradient)" />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div class="card-body add-pro-wrap p-4 ">
                                <div class="vl-pdscp">
                                    <!-- PRODUCT DSCRIPTION KEY -->
                                    <div class="row mb-xs-20">
                                        <div class="d-flex w-100">
                                            <div class="col-3">
                                                <p class="txtseconday">General Description:</p>
                                            </div>
                                            <div class="flex-fill">
                                                <p class="txtdefault mb-0">{{
                                                    record.productinfo.product_desc.general_desc }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- /PRODUCT DSCRIPTION KEY -->
                                    <!-- PRODUCT DSCRIPTION KEY -->
                                    <div class="row mb-4 mb-xs-20">
                                        <div class="d-flex w-100">
                                            <div class="col-3">
                                                <p class="txtseconday">Key Features:</p>
                                            </div>
                                            <div class="flex-fill">
                                                <ul class="txtdefault  mb-0">
                                                    {{ record.productinfo.product_desc.key_features }}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- /PRODUCT DSCRIPTION KEY -->
                                    <!-- PRODUCT DSCRIPTION KEY -->
                                    <div class="row mb-4 mb-xs-20">
                                        <div class="d-flex w-100">
                                            <div class="col-3">
                                                <p class="txtseconday mb-md-0">Return Period:</p>
                                            </div>
                                            <div class="flex-fill">
                                                <p class="txtdefault mb-0">{{
                                                    record.productinfo.product_desc.return_period }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- /PRODUCT DSCRIPTION KEY -->
                                    <!-- PRODUCT DSCRIPTION KEY -->
                                    <div class="row mb-4 mb-xs-20">
                                        <div class="d-flex w-100">
                                            <div class="col-3">
                                                <p class="txtseconday">Return Summary:</p>
                                            </div>
                                            <div class="flex-fill">
                                                <p class="txtdefault  mb-0">{{
                                                    record.productinfo.product_desc.return_summary }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- /PRODUCT DSCRIPTION KEY -->
                                    <!-- PRODUCT DSCRIPTION KEY -->
                                    <div class="row mb-xs-20" *ngIf="!record.productinfo.product_desc.warranty_by_brand">
                                        <div class="d-flex w-100">
                                            <div class="col-3">
                                                <p class="txtseconday">Domestic Warranty:</p>
                                            </div>
                                            <div class="flex-fill">
                                                <p class="txtdefault  mb-0">{{
                                                    record.productinfo.product_desc.domestic_warranty }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- /PRODUCT DSCRIPTION KEY -->
                                    <!-- PRODUCT DSCRIPTION KEY -->
                                    <div class="row mb-xs-20" *ngIf="!record.productinfo.product_desc.warranty_by_brand">
                                        <div class="d-flex  w-100">
                                            <div class="col-3">
                                                <p class="txtseconday">International Warranty:</p>
                                            </div>
                                            <div class="flex-fill">
                                                <p class="txtdefault  mb-0">{{
                                                    record.productinfo.product_desc.international_warranty }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- /PRODUCT DSCRIPTION KEY -->
                                    <!-- PRODUCT DSCRIPTION KEY -->
                                    <div class="row mb-xs-20" *ngIf="!record.productinfo.product_desc.warranty_by_brand">
                                        <div class="d-flex  w-100">
                                            <div class="col-3">
                                                <p class="txtseconday">Warranty Summary:</p>
                                            </div>
                                            <div class="flex-fill">
                                                <p class="txtdefault ">{{
                                                    record.productinfo.product_desc.warranty_summary }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- /PRODUCT DSCRIPTION KEY -->
                                    <!-- PRODUCT DSCRIPTION KEY -->
                                    <div class="row mb-4 mb-xs-20" *ngIf="!record.productinfo.product_desc.warranty_by_brand">
                                        <div class="d-flex  w-100">
                                            <div class="col-3">
                                                <p class="txtseconday">Warranty Service Type:</p>
                                            </div>
                                            <div class="flex-fill">
                                                <p class="txtdefault  mb-0">{{
                                                    record.productinfo.product_desc.warranty_service_type }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- /PRODUCT DSCRIPTION KEY -->
                                    <div class="row mb-4 mb-xs-20" *ngIf="record.productinfo.product_desc.warranty_by_brand">
                                        <div class="d-flex  w-100">
                                            <div class="col-3">
                                                <p class="txtseconday"> Warranty by Brand:</p>
                                            </div>
                                            <div class="flex-fill">
                                                <p class="txtdefault  mb-0">Facilitated by brand</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- PRODUCT DSCRIPTION KEY -->
                                    <div class="row">
                                        <div class="d-flex w-100">
                                            <div class="col-3">
                                                <p class="txtseconday">Feature Banner Image:</p>
                                            </div>
                                            <div class="flex-fill">
                                                <div class="row">
                                                    <div class="col-md-3"
                                                        *ngFor="let item of record.productinfo.product_desc.feature_banner_image;">
                                                        <img class="img-fluid" src="{{ item }}" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <!-- /PRODUCT DSCRIPTION KEY -->

                                </div>
                            </div>
                        </div>
                        <!-- /PRODUCT DSCRIPTION -->
                        <!-- TECHNICAL DETAILS -->
                        <div class="card card-ui p-0 mt-4">
                            <div class="card-header bg-white p-4">
                                <div class="row">
                                    <div class="col-9 col-sm-9">
                                        <h4 class="txtdefault fs16 wt700 my-1">Technical Details</h4>
                                    </div>
                                    <div class="col-3 col-sm-3 text-right">
                                        <a href="javascript:void(0);" *ngIf="flag"
                                            [routerLink]="['/seller/addproduct/'+unique_id]">
                                            <svg class="align-middle" xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" width="16.615"
                                                height="16.617" viewBox="0 0 16.615 16.617">
                                                <defs>
                                                    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1"
                                                        gradientUnits="objectBoundingBox">
                                                        <stop offset="0" stop-color="#79b7ff" />
                                                        <stop offset="1" stop-color="#07f" />
                                                    </linearGradient>
                                                </defs>
                                                <path id="np_edit_3094244_000000"
                                                    d="M13.5-.008A3.1,3.1,0,0,0,11.3.9L.857,11.344l4.4,4.4L15.7,5.307A3.114,3.114,0,0,0,13.5-.008ZM.317,12.273,0,16.046a.519.519,0,0,0,.561.561l3.771-.32Z"
                                                    transform="translate(0.003 0.008)" fill="url(#linear-gradient)" />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body add-pro-wrap p-4 ">
                                <div class="vl-pdscp">
                                    <!-- ROW -->
                                    <div class="row ">
                                        <!-- TECHNICAL KEY REPEAT -->
                                        <div class="col-3 mb-3"
                                            *ngFor="let item of record.productinfo.technical_feature">
                                            <p class="txtseconday mb-1">{{ item.label }}:</p>
                                            <p class="txtdefault">{{ item.value }}</p>
                                        </div>
                                        <!-- /TECHNICAL KEY REPEAT -->
                                    </div>
                                    <!-- /ROW -->

                                </div>
                            </div>
                        </div>
                        <!-- /TECHNICAL DETAILS -->
                        <!-- STOCK & PRICING -->
                        <div class="card card-ui p-0 mt-4">
                            <div class="card-header bg-white p-4">
                                <div class="row">
                                    <div class="col-9 col-sm-9">
                                        <h4 class="txtdefault fs16 wt700 my-1">Stock & Pricing</h4>
                                    </div>
                                    <div class="col-3 col-sm-3 text-right">
                                        <a href="javascript:void(0);" *ngIf="flag"
                                            [routerLink]="['/seller/addproduct/'+unique_id]">
                                            <svg class="align-middle" xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" width="16.615"
                                                height="16.617" viewBox="0 0 16.615 16.617">
                                                <defs>
                                                    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1"
                                                        gradientUnits="objectBoundingBox">
                                                        <stop offset="0" stop-color="#79b7ff" />
                                                        <stop offset="1" stop-color="#07f" />
                                                    </linearGradient>
                                                </defs>
                                                <path id="np_edit_3094244_000000"
                                                    d="M13.5-.008A3.1,3.1,0,0,0,11.3.9L.857,11.344l4.4,4.4L15.7,5.307A3.114,3.114,0,0,0,13.5-.008ZM.317,12.273,0,16.046a.519.519,0,0,0,.561.561l3.771-.32Z"
                                                    transform="translate(0.003 0.008)" fill="url(#linear-gradient)" />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body add-pro-wrap p-4" *ngIf="flag">
                                <div class="vl-pdscp">
                                    <!-- ROW -->
                                    <div class="row mb-3" *ngIf="!record.productinfo.child">
                                        <!-- COL -->
                                        <div class="col-4">
                                            <p class="txtseconday mb-1">Marketplace:</p>
                                            <p class="txtdefault">{{ record.productinfo.stockandpricing.marketplace }}
                                            </p>
                                        </div>
                                        <!-- /COL -->
                                        <!-- COL -->
                                        <div class="col-3">
                                            <p class="txtseconday mb-1">GST Rate:</p>
                                            <p class="txtdefault">{{ record.productinfo.stockandpricing.gst_rate }}</p>
                                        </div>
                                        <!-- /COL -->
                                    </div>
                                    <!-- /ROW -->

                                    <!-- ROW -->
                                    <div class="row mb-3" *ngIf="record.productinfo.child && record.productinfo.child_records.length > 0">
                                        <!-- COL -->
                                        <div class="col-4">
                                            <p class="txtseconday mb-1">Marketplace:</p>
                                            <p class="txtdefault">{{ record.productinfo.child_records[0].marketplace }}
                                            </p>
                                        </div>
                                        <!-- /COL -->
                                        <!-- COL -->
                                        <div class="col-3">
                                            <p class="txtseconday mb-1">GST Rate:</p>
                                            <p class="txtdefault">{{ record.productinfo.child_records[0].gst_rate }}</p>
                                        </div>
                                        <!-- /COL -->
                                    </div>
                                    <!-- /ROW -->

                                    <ng-container *ngIf="!record.productinfo.child">
                                        <!-- ROW -->
                                        <h4 class="wt700 fs16 txtdefault  mb-3"
                                            *ngIf="record.productinfo.stockandpricing.is_wholesale">Wholesale</h4>
                                        <div class="row mb-3" *ngIf="record.productinfo.stockandpricing.is_wholesale">
                                            <!-- COL -->
                                            <div class="col-4">
                                                <p class="txtseconday mb-1">Listed Price:</p>
                                                <p class="txtdefault">₹ {{
                                                    record.productinfo.stockandpricing.wholesale.price }}</p>
                                            </div>
                                            <!-- /COL -->
                                            <!-- COL -->
                                            <div class="col-3">
                                                <p class="txtseconday mb-1">Total Stock Quantity:</p>
                                                <p class="txtdefault">{{
                                                    record.productinfo.stockandpricing.wholesale.total_stock_qty }}</p>
                                            </div>
                                            <!-- /COL -->
                                            <!-- COL -->
                                            <div class="col-5">
                                                <p class="txtseconday mb-1">Minimum Order Quantity:</p>
                                                <p class="txtdefault">{{
                                                    record.productinfo.stockandpricing.wholesale.min_order_qty}}</p>
                                            </div>
                                            <!-- /COL -->
                                        </div>
                                        <!-- /ROW -->
                                        <!-- ROW -->
                                        <h4 class="wt700 fs16 txtdefault  mb-3"
                                            *ngIf="record.productinfo.stockandpricing.is_bulk">Bulk</h4>
                                        <div class="row mb-3" *ngIf="record.productinfo.stockandpricing.is_bulk">
                                            <!-- COL -->
                                            <div class="col-4">
                                                <p class="txtseconday mb-1">Listed Price:</p>
                                                <p class="txtdefault">₹ {{ record.productinfo.stockandpricing.bulk.price }}
                                                </p>
                                            </div>
                                            <!-- /COL -->
                                            <!-- COL -->
                                            <div class="col-3">
                                                <p class="txtseconday mb-1">Total Stock Quantity:</p>
                                                <p class="txtdefault">{{ record.productinfo.stockandpricing.bulk.total_stock_qty }}
                                                </p>
                                            </div>
                                            <!-- /COL -->
                                            <!-- COL -->
                                            <div class="col-5">
                                                <p class="txtseconday mb-1">Minimum Order Quantity:</p>
                                                <p class="txtdefault">{{
                                                    record.productinfo.stockandpricing.bulk.min_order_qty }}</p>
                                            </div>
                                            <!-- /COL -->
                                        </div>
                                        <!-- /ROW -->
                                    </ng-container>
                                    
                                    <ng-container *ngIf="record.productinfo.child">
                                        <ng-container *ngFor="let child of record.productinfo.child_records">
                                            <!-- ROW -->
                                            <h3>{{ child.name }}</h3>
                                            <h4 class="wt700 fs16 txtdefault  mb-3"
                                                *ngIf="child.is_wholesale">Wholesale</h4>
                                            <div class="row mb-3" *ngIf="child.is_wholesale">
                                                <!-- COL -->
                                                <div class="col-4">
                                                    <p class="txtseconday mb-1">Listed Price:</p>
                                                    <p class="txtdefault">₹ {{
                                                        child.wholesale_price }}</p>
                                                </div>
                                                <!-- /COL -->
                                                <!-- COL -->
                                                <div class="col-3">
                                                    <p class="txtseconday mb-1">Total Stock Quantity:</p>
                                                    <p class="txtdefault">{{
                                                        child.wholesale_qty }}</p>
                                                </div>
                                                <!-- /COL -->
                                                <!-- COL -->
                                                <div class="col-5">
                                                    <p class="txtseconday mb-1">Minimum Order Quantity:</p>
                                                    <p class="txtdefault">{{
                                                        child.wholesale_min_qty}}</p>
                                                </div>
                                                <!-- /COL -->
                                            </div>
                                            <!-- /ROW -->
                                            <!-- ROW -->
                                            <h4 class="wt700 fs16 txtdefault  mb-3"
                                                *ngIf="child.is_bulk">Bulk</h4>
                                            <div class="row mb-3" *ngIf="child.is_bulk">
                                                <!-- COL -->
                                                <div class="col-4">
                                                    <p class="txtseconday mb-1">Listed Price:</p>
                                                    <p class="txtdefault">₹ {{ child.bulk_price }}
                                                    </p>
                                                </div>
                                                <!-- /COL -->
                                                <!-- COL -->
                                                <div class="col-3">
                                                    <p class="txtseconday mb-1">Total Stock Quantity:</p>
                                                    <p class="txtdefault">{{ child.bulk_qty }}
                                                    </p>
                                                </div>
                                                <!-- /COL -->
                                                <!-- COL -->
                                                <div class="col-5">
                                                    <p class="txtseconday mb-1">Minimum Order Quantity:</p>
                                                    <p class="txtdefault">{{
                                                        child.bulk_min_qty }}</p>
                                                </div>
                                                <!-- /COL -->
                                            </div>
                                            <!-- /ROW -->
                                        </ng-container>
                                    </ng-container>

                                    <!-- ROW -->
                                    <h4 class="wt700 fs16 txtdefault mb-3"
                                        *ngIf="!record.productinfo.child && record.productinfo.stockandpricing.is_bid">Bids & Offers</h4>
                                    <div class="row mb-3" *ngIf="!record.productinfo.child && record.productinfo.stockandpricing.is_bid">
                                        <!-- COL -->
                                        <div class="col-4">
                                            <p class="txtseconday mb-1">Listed Price:</p>
                                            <p class="txtdefault">₹ {{
                                                record.productinfo.stockandpricing.bidsandoffers.lowest_bid_amt }}</p>
                                        </div>
                                        <!-- /COL -->
                                    </div>
                                    <!-- /ROW -->

                                </div>
                            </div>

                            <div class="card-body add-pro-wrap p-4" *ngIf="!flag">
                                <div class="vl-pdscp" *ngIf="!record.productinfo.child">
                                    <!-- ROW -->
                                    <h4 class="wt700 fs16 txtdefault  mb-3"
                                        *ngIf="record.productinfo.stockandpricing.is_wholesale">Wholesale</h4>
                                    <div class="row mb-3" *ngIf="record.productinfo.stockandpricing.is_wholesale">
                                        <!-- COL -->
                                        <div class="col-4">
                                            <p class="txtseconday mb-1">Listing Price:</p>
                                            <p class="txtdefault">₹ {{
                                                record.productinfo.stockandpricing.wholesale.price }}</p>
                                        </div>
                                        <!-- /COL -->
                                        <!-- COL -->
                                        <div class="col-3">
                                            <p class="txtseconday mb-1">Margin Amount:</p>
                                            <p class="txtdefault">{{
                                                record.productinfo.stockandpricing.wholesale.margin }}</p>
                                        </div>
                                        <!-- /COL -->
                                    </div>
                                    <!-- /ROW -->
                                    <!-- ROW -->
                                    <h4 class="wt700 fs16 txtdefault  mb-3"
                                        *ngIf="record.productinfo.stockandpricing.is_bulk">Bulk</h4>
                                    <div class="row mb-3" *ngIf="record.productinfo.stockandpricing.is_bulk">
                                        <!-- COL -->
                                        <div class="col-4">
                                            <p class="txtseconday mb-1">Listing Price:</p>
                                            <p class="txtdefault">₹ {{ record.productinfo.stockandpricing.bulk.price }}
                                            </p>
                                        </div>
                                        <!-- /COL -->
                                        <!-- COL -->
                                        <div class="col-3">
                                            <p class="txtseconday mb-1">Margin Amount:</p>
                                            <p class="txtdefault">{{ record.productinfo.stockandpricing.bulk.margin }}
                                            </p>
                                        </div>
                                        <!-- /COL -->
                                    </div>
                                    <!-- /ROW -->
                                    <!-- ROW -->
                                    <h4 class="wt700 fs16 txtdefault mb-3"
                                        *ngIf="record.productinfo.stockandpricing.is_bid">Bids & Offers</h4>
                                    <div class="row mb-3" *ngIf="record.productinfo.stockandpricing.is_bid">
                                        <!-- COL -->
                                        <div class="col-4">
                                            <p class="txtseconday mb-1">Listing Price:</p>
                                            <p class="txtdefault">₹ {{
                                                record.productinfo.stockandpricing.bidsandoffers.lowest_bid_amt }}</p>
                                        </div>
                                        <!-- /COL -->
                                    </div>
                                    <!-- /ROW -->

                                </div>

                                <div class="vl-pdscp" *ngIf="record.productinfo.child">
                                    <ng-container *ngFor="let child of record.productinfo.child_records">
                                        <h3>{{ child.name }}</h3>
                                        <!-- ROW -->
                                        <h4 class="wt700 fs16 txtdefault  mb-3"
                                            *ngIf="child.is_wholesale">Wholesale</h4>
                                        <div class="row mb-3" *ngIf="child.is_wholesale">
                                            <!-- COL -->
                                            <div class="col-4">
                                                <p class="txtseconday mb-1">Listing Price:</p>
                                                <p class="txtdefault">₹ {{
                                                    child.wholesale_price }}</p>
                                            </div>
                                            <!-- /COL -->
                                            <!-- COL -->
                                            <div class="col-3">
                                                <p class="txtseconday mb-1">Margin Amount:</p>
                                                <p class="txtdefault">{{
                                                    child.wholesale_margin }}</p>
                                            </div>
                                            <!-- /COL -->
                                        </div>
                                        <!-- /ROW -->
                                        <!-- ROW -->
                                        <h4 class="wt700 fs16 txtdefault  mb-3"
                                            *ngIf="child.is_bulk">Bulk</h4>
                                        <div class="row mb-3" *ngIf="child.is_bulk">
                                            <!-- COL -->
                                            <div class="col-4">
                                                <p class="txtseconday mb-1">Listing Price:</p>
                                                <p class="txtdefault">₹ {{ child.bulk_price }}
                                                </p>
                                            </div>
                                            <!-- /COL -->
                                            <!-- COL -->
                                            <div class="col-3">
                                                <p class="txtseconday mb-1">Margin Amount:</p>
                                                <p class="txtdefault">{{ child.bulk_margin }}
                                                </p>
                                            </div>
                                            <!-- /COL -->
                                        </div>
                                        <!-- /ROW -->
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <!-- /TECHNICAL DETAILS -->
                    </ng-template>
                </li>
            </ul>
        </div>
        <div [ngbNavOutlet]="prodtlnav" class="mt-2"></div>
        <!-- /TABING -->
    </div>
</div>
<!-- REMOVE PRODUCT MODAL -->
<ng-template #removeproduct let-modal>
    <div class="modal-header nobd text-center pt-4 pb-0">
        <h4 class="modal-title fs28 txtdefault wt700 mx-auto pt-3">Remove Product</h4>
        <button type="button" class="btn-close close rmvmdl" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <p class="fs12 txtseconday py-3 mb-2 text-center">
            <span class="mr-2">
                <svg id="Group_46" data-name="Group 46" xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                    viewBox="0 0 15 15">
                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="7.5" cy="7.5" r="7.5" fill="#07f" />
                    <path id="Path_2235" data-name="Path 2235"
                        d="M7,8.171l-.128.523q-.576.227-.919.346a2.425,2.425,0,0,1-.8.119,1.591,1.591,0,0,1-1.084-.34,1.1,1.1,0,0,1-.387-.864,3.056,3.056,0,0,1,.029-.416q.029-.213.093-.48l.48-1.7q.064-.244.108-.462a2.01,2.01,0,0,0,.044-.4A.616.616,0,0,0,4.3,4.047a.759.759,0,0,0-.513-.128,1.345,1.345,0,0,0-.381.058c-.131.039-.242.076-.336.11L3.2,3.564q.471-.192.9-.329A2.7,2.7,0,0,1,4.916,3.1a1.552,1.552,0,0,1,1.068.335,1.111,1.111,0,0,1,.375.869c0,.074-.008.2-.026.39a2.606,2.606,0,0,1-.1.512L5.759,6.9a4.492,4.492,0,0,0-.1.466,2.4,2.4,0,0,0-.047.4.57.57,0,0,0,.15.459.85.85,0,0,0,.523.122,1.509,1.509,0,0,0,.4-.061A2.21,2.21,0,0,0,7,8.171Zm.121-7.1a.99.99,0,0,1-.334.753,1.138,1.138,0,0,1-.8.312,1.15,1.15,0,0,1-.809-.312.988.988,0,0,1-.337-.753A1,1,0,0,1,5.169.314a1.194,1.194,0,0,1,1.614,0A1,1,0,0,1,7.117,1.07Z"
                        transform="translate(2.406 2.92)" fill="#fff" />
                </svg>
            </span>
            Removing the product will lead to removing this product from your listing. The buyer won’t be able to place
            an order for this product.
        </p>
        <p class="fs16 txtseconday pb-3 mb-2 text-center">Are you sure you want to remove the product?</p>
        <div class="d-table mb-4 w-100">
            <div class="d-table-cell cart-thumb align-middle">
                <img width="60" src="{{ slides[0].img }}">
            </div>
            <div class="d-table-cell pl-3 align-middle">
                <p class="txtdefault wt700 mb-0 fs14 lnht20">{{ variant_name }}</p>
            </div>
        </div>
    </div>
    <div class="modal-footer  text-center nobd mx-auto pb-4 mb-3">
        <button type="button" class="btn-d-outline d-inline-block cancel-outline mr-3"
            (click)="modal.close('Close click')">Cancel</button>
        <button type="button" class="btn buybtn fs16 radius10" (click)="deletesellerlisting()">Remove</button>
    </div>
</ng-template>
<!-- /REMOVE PRODUCT MODAL -->

<app-preview #preview></app-preview>

<app-footer-seller></app-footer-seller>