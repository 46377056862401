import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-category-table',
  templateUrl: './category-table.component.html',
  styleUrls: ['./category-table.component.scss']
})
export class CategoryTableComponent implements OnInit {
	
	@Input() cat: any;
	@Input() key: any;
  constructor() { }

  ngOnInit(): void {
  }

}
