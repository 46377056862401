import { Component, OnInit, Input, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { LandingHowitWorks } from '../data/slider';
import { SignupModalComponent } from '../components/modal/signup-modal/signup-modal.component';
import { SignupService } from '../components/modal/signup-modal/signup-modal.service';
import { ActivatedRoute } from '@angular/router';
import { HeaderOneService } from '../header/header-one/header-one.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  @Input() class: string;
  @Input() sticky: boolean = false; // Default false

  @ViewChild("signIn") SignIn: SignupModalComponent;

    public stick: boolean = false;
    constructor(
        private route: ActivatedRoute,
        public _singupservice: SignupService,
        private _headerservice: HeaderOneService,
    ) { }
    public ProductSliderConfig: any = LandingHowitWorks;

    public user_token:string;
    public user_reg_status:string;
    public user_status:string;
    public token: string;
    public usertype: any;
    ngOnInit(): void {

        this.user_token = this.token = this._singupservice.gettoken();
        this.usertype = this._singupservice.getusertype();
        this.user_status = this._singupservice.getuserstatus();
        this._singupservice.tokenChanged.subscribe((response: string) => {
            this.token = response;
            this.user_token = response;
        });
        setTimeout(() => {
            const token = this.route.snapshot.paramMap.get('reset-token');
            if (token) {
                this.SignIn.openSigninModal('reset', '', token);
            }
            const settoken = this.route.snapshot.paramMap.get('signup-token');
            if (settoken) {
                this.SignIn.openSigninModal('set', '', settoken);
            }
        }, 500);
        if (this.token) {
            this.getuserdetails();
        }

    }

    getuserdetails() {
        this._headerservice.getuserdetails(null).subscribe((data) => {
            if (data['status'] == "success") {
                this._singupservice.updatestatus(data['data']['adminverified']);
                this._singupservice.updateregstatus(data['data']['reg_status']);
                this.user_reg_status = data['data']['reg_status'];
            }
        }, (error) => {

        });
    }
     disabled = false;
    // @HostListener Decorator
    @HostListener("window:scroll", [])
    onWindowScroll() {
        let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (number >= 150 && window.innerWidth > 400) { 
            this.stick = true;
         } else {
             this.stick = false;
         }
    }
}