<app-header-admin></app-header-admin>
<div class="main-content">
	<div class="container pb-3">
		<!-- BREADCRUMB -->
		<nav class=" pt-2 pb-2" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
		  <ol class="breadcrumb p-0 bg-transparent">
			<li class="breadcrumb-item"><a href="javascript:void(0);" class="txtseconday">CMS </a></li>		
			<li class="breadcrumb-item active" aria-current="page">Add New Category Product</li>
		  </ol>		
		</nav>
		<!-- /BREADCRUMB -->
		<div class="row align-items-center adprotop">
			<div class="col-md-6 mb-4">
				<h4 class="wt700 mb-0 txtdefault fs22">
				<a href="javascript:void();" class="go-back"><i class="fa fa-angle-left"></i></a>
				Add New Category Product</h4>
			</div>
		</div>
		<!-- SEARCH -->
		<!-- <div class="row mb-4">
			<div class="col-xl-5 col-lg-7">
				<div class="input-group-custom pro-catg-srch">
					<div class="ng-autocomplete">
						<ng-autocomplete 
							[data]="result" 
							[searchKeyword]="keyword" 
							(selected)='selectEvent($event)'
							(inputChanged)='onChangeSearch($event)' 
							(inputFocused)='onFocused($event)' 
							[itemTemplate]="itemTemplate"
							placeholder="Search Category" >
						</ng-autocomplete>
						<ng-template #itemTemplate let-item>
							<a href="javascript:void(0)">
								<div class="d-flex">
									<span [innerHTML]="item.name"></span>
								</div>
							</a>
						</ng-template>
						<div class="search-icon"><img src="assets/images/svg/search_logo.svg"></div>
					</div>
				</div>
			</div>
		</div>	 -->
		<div class="row scrolcustom">   
			<div class="col-sm-12">
				<h4 class="wt700 txtdefault fs14 mb-4">Select a Category</h4>
			</div>
			<!-- COL -->
			<div class="col-lg-3 col-sm-6">
				<div class="card proctg radius10">
					<div class="card-body p-2 py-3">
						<div class="eqmenu-subitems">
						  <ul>
							<li *ngFor="let category of categories; let i = index;">
								<a href="javascript:void(0);" (click)="getsubcategory(category, i, 'parent')">
									<img [src]="category.preview?category.preview:default_preview">{{ category.name }}
								</a>
							</li>
						  </ul>
						</div>
					</div>
				</div>
			</div>
			<!-- /COL -->
			<!-- COL -->
			<div class="col-lg-3 col-sm-6" *ngFor="let sub of subcategories; let i = index;">
				<div class="card proctg radius10">
					<div class="card-body p-2 py-3">
						<div class="eqmenu-subitems">
						  <ul>
							<li *ngFor="let cat of sub;"  [ngClass]="{'selected': !cat.open}"><a href="javascript:void(0);" (click)="getsubcategory(cat, i, 'sub')"> <img [src]="cat.preview?cat.preview:default_preview">{{cat.name }}</a></li>
						  </ul>
						</div>
					</div>
				</div>
			</div>
			<!-- /COL -->
		</div>
	</div>
</div>	
