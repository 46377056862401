import { Component, OnInit } from '@angular/core';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { TransactionReports } from './bnpl-transaction-reports.service';
import { ActivatedRoute, Router } from "@angular/router";
import { ToasterService } from "../../helpers/toastr.service";

@Component({
  selector: 'app-bnpl-transaction-reports',
  templateUrl: './bnpl-transaction-reports.component.html',
  styleUrls: ['./bnpl-transaction-reports.component.scss']
})
export class BnplTransactionReportsComponent implements OnInit {

  public search_term:string = '';
  public from_date:any = '';
  public to_date:any = '';

  constructor(
      private _pageservice: TransactionReports,
      private _pagination: PaginationService,
      private router: Router,
      private _toastr: ToasterService,
  ) { }

  ngOnInit(): void {
      this.getinvoicerecords();
  }

  public page_records: any = [];
  public current_page: number = 1;
  public offset: number = 0;
  public page_per_view: number = 5;
  public page_pagination: any = {};

  setpage(page) {
      this.current_page = page;
      this.offset = (page - 1) * this.page_per_view;
      this.transactionRecords();
  }


  getinvoicerecords() {
      this.search_term = '';
      this.from_date = '';
      this.to_date = '';
      this.page_per_view = 5;
      this.offset = 0;
      this.current_page = 1;
      this.transactionRecords();        
  }

  getinvoicerecordsfilters(){
      this.page_per_view = 5;
      this.offset = 0;
      this.current_page = 1;
      this.transactionRecords();
  }

  transactionRecords() {
      let data = { 
          limit: this.page_per_view, 
          offset: this.offset, 
          current_page: this.current_page, 
          search: this.search_term,
          from_date: this.from_date,
          to_date: this.to_date,
      };
      this._pageservice.transactionRecords(data).subscribe((data) => {
          if (data['status'] == "success") {
            this.page_records = data['data'];
            let total_records = data['pagination'];
            this.page_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
          }
      });
  }

  viewDetails (id) {
        this.router.navigate([`/buyer/buyer-view-order-details/${id}`]);
  }

}

