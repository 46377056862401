<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain mgwrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head mgb-sm-ui-rw">
			<div class="col-md-6 ">
				<h3 class="fs28 txtdefault wt700">Manage Buyer</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">User Management</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Buyer </a>
						</li>
						<li class="breadcrumb-item active">Manage Buyers</li>
					</ol>
				</nav>
			</div>
			<div class="col-md-6 text-right sm-ui-rw">
				<a href="javascript:void(0);" class="btn-d-outline d-inline-block place-bid">
					<svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
					  <g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
						<path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#ff7135"/>
					  </g>
					</svg>					
					Download Report
				</a>
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ml-3">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="us-tab">
			<nav ngbNav #nav="ngbNav" class="nav-tabs">
			  <ng-container ngbNavItem>
				<a ngbNavLink>Manage Buyers</a>
				<ng-template ngbNavContent>
				  <div class="card card-ui card-uiovt mb-4">
					<div class="mgb-head">
						<div class="row mb-4 pb-2 align-items-center">
							<div class="col-xl-6 col-lg-8 col-md-10">
								<div class="report-input">
									<div class="input-group-custom">
										<input type="text" placeholder="Search by Category Name" class="form-control">
										<div class="input-group-append-custom">
											<img src="assets/images/svg/search_logo.svg">
										</div>
									</div>
								</div>
							</div>							
						</div>
						<a href="javascript:void(0);" class="d-inline-block fltr-round-btn radius10 txtseconday">
							<img src="assets/images/svg/filter.svg">
							Filters
						</a>
					</div>
					<!-- REPEAT -->
					<div class="buyer-row th-cmn-bd pb-3 mb-3">
						<div class="row">
							<div class="col-sm-9 col-8">
								<h4 class="fs16 wt700 mb-2">Pranay  Ghosh</h4>
								<p class="fs12 txtdefault envlprw">
								<svg xmlns="http://www.w3.org/2000/svg" width="14.948" height="10.733" viewBox="0 0 14.948 10.733">
								  <path id="np_email_1740173_000000" d="M12.051,23a1.014,1.014,0,0,0-.559.17l5.919,5.607a.587.587,0,0,0,.854,0l5.909-5.607a1.014,1.014,0,0,0-.559-.17Zm-.986.723A1.117,1.117,0,0,0,11,24.09v7.283a1.114,1.114,0,0,0,.17.6l4.184-4.178Zm13.536,0-4.277,4.063L24.5,31.97a1.114,1.114,0,0,0,.17-.6V24.09a1.117,1.117,0,0,0-.066-.367Zm-4.786,4.551-1.068,1.013a1.323,1.323,0,0,1-1.818,0l-1.068-1.008-4.14,4.129a1.024,1.024,0,0,0,.329.055H23.617a1.024,1.024,0,0,0,.329-.055Z" transform="translate(-10.5 -22.229)" fill="#5e6a7c" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" opacity="0.6"/>
								</svg>

								pranay.ghosh@gm***</p>
							</div>
							<div class="col-sm-3 col-4 text-right">
								<div class="tglbtn">
									<label class="switch">
									  <input type="checkbox">
									  <span class="slider round"></span>
									  <i>Active</i>
									</label>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-lg-2 pr-0 col-md-2 col-sm-4">
								<h4 class="txtdefault fs14">Mobile:</h4>
								<p class="txtseconday fs14  mb-0">91 987******0</p>
							</div>
							<div class="col-lg-4 col-md-4 col-sm-4 pr-0">
								<h4 class="txtdefault fs14">Registered Date::</h4>
								<p class="txtseconday fs14 mb-0">
									<span class="mr-xl-3">Sep 29, 2021</span> &nbsp;
									<a href="javascript:void(0);" class="add-whislist-link">
									<span>View Regn. Details</span></a>
								</p>
							</div>
							<div class="col-lg-4 col-md-4 pr-0 col-sm-4">
								<h4 class="txtdefault fs14">Compliance Performance</h4>
								<p class="txtseconday fs14  mb-0">
									8471302432
								</p>
							</div>
							<div class="col-lg-2 col-md-2 pl-md-0 text-right">
								<a href="javascript:void(0);" class="btn-d-outline d-inline-block place-bid">View Orders</a>
							</div>
						</div>
					</div>
					<!-- /REPEAT -->
					<!-- REPEAT -->
					<div class="buyer-row th-cmn-bd pb-3 mb-3">
						<div class="row">
							<div class="col-sm-9 col-8">
								<h4 class="fs16 wt700 mb-2">Pranay  Ghosh</h4>
								<p class="fs12 txtdefault envlprw">
								<svg xmlns="http://www.w3.org/2000/svg" width="14.948" height="10.733" viewBox="0 0 14.948 10.733">
								  <path id="np_email_1740173_000000" d="M12.051,23a1.014,1.014,0,0,0-.559.17l5.919,5.607a.587.587,0,0,0,.854,0l5.909-5.607a1.014,1.014,0,0,0-.559-.17Zm-.986.723A1.117,1.117,0,0,0,11,24.09v7.283a1.114,1.114,0,0,0,.17.6l4.184-4.178Zm13.536,0-4.277,4.063L24.5,31.97a1.114,1.114,0,0,0,.17-.6V24.09a1.117,1.117,0,0,0-.066-.367Zm-4.786,4.551-1.068,1.013a1.323,1.323,0,0,1-1.818,0l-1.068-1.008-4.14,4.129a1.024,1.024,0,0,0,.329.055H23.617a1.024,1.024,0,0,0,.329-.055Z" transform="translate(-10.5 -22.229)" fill="#5e6a7c" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" opacity="0.6"/>
								</svg>

								pranay.ghosh@gm***</p>
							</div>
							<div class="col-sm-3 col-4 text-right">
								<div class="tglbtn inactivedata">
									<label class="switch">
									  <input type="checkbox">
									  <span class="slider round"></span>
									  <i>Inactive</i>
									</label>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-lg-2 pr-0 col-md-2 col-sm-4">
								<h4 class="txtdefault fs14">Mobile:</h4>
								<p class="txtseconday fs14  mb-0">91 987******0</p>
							</div>
							<div class="col-lg-4 col-md-4 col-sm-4 pr-0">
								<h4 class="txtdefault fs14">Registered Date::</h4>
								<p class="txtseconday fs14 mb-0">
									<span class="mr-xl-3">Sep 29, 2021</span> &nbsp;
									<a href="javascript:void(0);" class="add-whislist-link">
									<span>View Regn. Details</span></a>
								</p>
							</div>
							<div class="col-lg-4 col-md-4 pr-0 col-sm-4">
								<h4 class="txtdefault fs14">Compliance Performance</h4>
								<p class="txtseconday fs14  mb-0">
									8471302432
								</p>
							</div>
							<div class="col-lg-2 col-md-2 pl-md-0 text-right">
								<a href="javascript:void(0);" class="btn-d-outline d-inline-block place-bid">View Orders</a>
							</div>
						</div>
					</div>
					<!-- /REPEAT -->
					<!-- REPEAT -->
					<div class="buyer-row th-cmn-bd pb-3 mb-3">
						<div class="row">
							<div class="col-sm-9 col-8">
								<h4 class="fs16 wt700 mb-2">Pranay  Ghosh</h4>
								<p class="fs12 txtdefault envlprw">
								<svg xmlns="http://www.w3.org/2000/svg" width="14.948" height="10.733" viewBox="0 0 14.948 10.733">
								  <path id="np_email_1740173_000000" d="M12.051,23a1.014,1.014,0,0,0-.559.17l5.919,5.607a.587.587,0,0,0,.854,0l5.909-5.607a1.014,1.014,0,0,0-.559-.17Zm-.986.723A1.117,1.117,0,0,0,11,24.09v7.283a1.114,1.114,0,0,0,.17.6l4.184-4.178Zm13.536,0-4.277,4.063L24.5,31.97a1.114,1.114,0,0,0,.17-.6V24.09a1.117,1.117,0,0,0-.066-.367Zm-4.786,4.551-1.068,1.013a1.323,1.323,0,0,1-1.818,0l-1.068-1.008-4.14,4.129a1.024,1.024,0,0,0,.329.055H23.617a1.024,1.024,0,0,0,.329-.055Z" transform="translate(-10.5 -22.229)" fill="#5e6a7c" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" opacity="0.6"/>
								</svg>

								pranay.ghosh@gm***</p>
							</div>
							<div class="col-sm-3 col-4 text-right">
								<div class="tglbtn">
									<label class="switch">
									  <input type="checkbox">
									  <span class="slider round"></span>
									  <i>Active</i>
									</label>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-lg-2 pr-0 col-md-2 col-sm-4">
								<h4 class="txtdefault fs14">Mobile:</h4>
								<p class="txtseconday fs14  mb-0">91 987******0</p>
							</div>
							<div class="col-lg-4 col-md-4 col-sm-4 pr-0">
								<h4 class="txtdefault fs14">Registered Date::</h4>
								<p class="txtseconday fs14 mb-0">
									<span class="mr-xl-3">Sep 29, 2021</span> &nbsp;
									<a href="javascript:void(0);" class="add-whislist-link">
									<span>View Regn. Details</span></a>
								</p>
							</div>
							<div class="col-lg-4 col-md-4 pr-0 col-sm-4">
								<h4 class="txtdefault fs14">Compliance Performance</h4>
								<p class="txtseconday fs14  mb-0">
									8471302432
								</p>
							</div>
							<div class="col-lg-2 col-md-2 pl-md-0 text-right">
								<a href="javascript:void(0);" class="btn-d-outline d-inline-block place-bid">View Orders</a>
							</div>
						</div>
					</div>
					<!-- /REPEAT -->
					<!-- REPEAT -->
					<div class="buyer-row th-cmn-bd pb-3 mb-3">
						<div class="row">
							<div class="col-sm-9 col-8">
								<h4 class="fs16 wt700 mb-2">Pranay  Ghosh</h4>
								<p class="fs12 txtdefault envlprw">
								<svg xmlns="http://www.w3.org/2000/svg" width="14.948" height="10.733" viewBox="0 0 14.948 10.733">
								  <path id="np_email_1740173_000000" d="M12.051,23a1.014,1.014,0,0,0-.559.17l5.919,5.607a.587.587,0,0,0,.854,0l5.909-5.607a1.014,1.014,0,0,0-.559-.17Zm-.986.723A1.117,1.117,0,0,0,11,24.09v7.283a1.114,1.114,0,0,0,.17.6l4.184-4.178Zm13.536,0-4.277,4.063L24.5,31.97a1.114,1.114,0,0,0,.17-.6V24.09a1.117,1.117,0,0,0-.066-.367Zm-4.786,4.551-1.068,1.013a1.323,1.323,0,0,1-1.818,0l-1.068-1.008-4.14,4.129a1.024,1.024,0,0,0,.329.055H23.617a1.024,1.024,0,0,0,.329-.055Z" transform="translate(-10.5 -22.229)" fill="#5e6a7c" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" opacity="0.6"/>
								</svg>

								pranay.ghosh@gm***</p>
							</div>
							<div class="col-sm-3 col-4 text-right">
								<div class="tglbtn inactivedata">
									<label class="switch">
									  <input type="checkbox">
									  <span class="slider round"></span>
									  <i>Inactive</i>
									</label>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-lg-2 pr-0 col-md-2 col-sm-4">
								<h4 class="txtdefault fs14">Mobile:</h4>
								<p class="txtseconday fs14  mb-0">91 987******0</p>
							</div>
							<div class="col-lg-4 col-md-4 col-sm-4 pr-0">
								<h4 class="txtdefault fs14">Registered Date::</h4>
								<p class="txtseconday fs14 mb-0">
									<span class="mr-xl-3">Sep 29, 2021</span> &nbsp;
									<a href="javascript:void(0);" class="add-whislist-link">
									<span>View Regn. Details</span></a>
								</p>
							</div>
							<div class="col-lg-4 col-md-4 pr-0 col-sm-4">
								<h4 class="txtdefault fs14">Compliance Performance</h4>
								<p class="txtseconday fs14  mb-0">
									8471302432
								</p>
							</div>
							<div class="col-lg-2 col-md-2 pl-md-0 text-right">
								<a href="javascript:void(0);" class="btn-d-outline d-inline-block place-bid">View Orders</a>
							</div>
						</div>
					</div>
					<!-- /REPEAT -->
					<!-- REPEAT -->
					<div class="buyer-row th-cmn-bd pb-3 mb-3">
						<div class="row">
							<div class="col-sm-9 col-8">
								<h4 class="fs16 wt700 mb-2">Pranay  Ghosh</h4>
								<p class="fs12 txtdefault envlprw">
								<svg xmlns="http://www.w3.org/2000/svg" width="14.948" height="10.733" viewBox="0 0 14.948 10.733">
								  <path id="np_email_1740173_000000" d="M12.051,23a1.014,1.014,0,0,0-.559.17l5.919,5.607a.587.587,0,0,0,.854,0l5.909-5.607a1.014,1.014,0,0,0-.559-.17Zm-.986.723A1.117,1.117,0,0,0,11,24.09v7.283a1.114,1.114,0,0,0,.17.6l4.184-4.178Zm13.536,0-4.277,4.063L24.5,31.97a1.114,1.114,0,0,0,.17-.6V24.09a1.117,1.117,0,0,0-.066-.367Zm-4.786,4.551-1.068,1.013a1.323,1.323,0,0,1-1.818,0l-1.068-1.008-4.14,4.129a1.024,1.024,0,0,0,.329.055H23.617a1.024,1.024,0,0,0,.329-.055Z" transform="translate(-10.5 -22.229)" fill="#5e6a7c" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" opacity="0.6"/>
								</svg>

								pranay.ghosh@gm***</p>
							</div>
							<div class="col-sm-3 col-4 text-right">
								<div class="tglbtn">
									<label class="switch">
									  <input type="checkbox">
									  <span class="slider round"></span>
									  <i>Active</i>
									</label>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-lg-2 pr-0 col-md-2 col-sm-4">
								<h4 class="txtdefault fs14">Mobile:</h4>
								<p class="txtseconday fs14  mb-0">91 987******0</p>
							</div>
							<div class="col-lg-4 col-md-4 col-sm-4 pr-0">
								<h4 class="txtdefault fs14">Registered Date::</h4>
								<p class="txtseconday fs14 mb-0">
									<span class="mr-xl-3">Sep 29, 2021</span> &nbsp;
									<a href="javascript:void(0);" class="add-whislist-link">
									<span>View Regn. Details</span></a>
								</p>
							</div>
							<div class="col-lg-4 col-md-4 pr-0 col-sm-4">
								<h4 class="txtdefault fs14">Compliance Performance</h4>
								<p class="txtseconday fs14  mb-0">
									8471302432
								</p>
							</div>
							<div class="col-lg-2 col-md-2 pl-md-0 text-right">
								<a href="javascript:void(0);" class="btn-d-outline d-inline-block place-bid">View Orders</a>
							</div>
						</div>
					</div>
					<!-- /REPEAT -->
				  </div>
				</ng-template>
			  </ng-container>
			  <ng-container ngbNavItem>
				<a ngbNavLink>Buyer Registration</a>
				<ng-template ngbNavContent>					
					<div class="card card-ui card-uiovt byregwrp">
						<div class="mgb-head">
							<div class="row mb-4 pb-2 align-items-center">
								<div class="col-xl-6 col-lg-5 col-md-7">
									<div class="report-input">
										<div class="input-group-custom">
											<input type="text" placeholder="Search by Category Name" class="form-control">
											<div class="input-group-append-custom">
												<img src="assets/images/svg/search_logo.svg">
											</div>
										</div>
									</div>
								</div>	
								<div class="col-xl-6 col-lg-4 col-md-5">
									<div class="tpbr">
										<label class="fs12 txtseconday tpbrlbl">Type of Buyer</label>
										<ng-selectize [config]="config" [options] = "data" placeholder="" >
										</ng-selectize>	
									</div>
								</div>	
								 
							</div>
							<a href="javascript:void(0);" class="d-inline-block fltr-round-btn radius10 txtseconday">
								<img src="assets/images/svg/filter.svg">
								Filters
							</a>
						</div>
						<!-- REPEAT -->
						<div class="buyer-row th-cmn-bd pb-3 mb-3">
							<div class="row">
								<div class="col-sm-12">
									<h4 class="fs16 wt700 mb-2">Kiran Kumar &nbsp; <span class="tg-grp  align-middle tg-grp-sp"><span>Bulk</span></span></h4>
									<p class="fs12 txtdefault envlprw envllocprw">
									<svg xmlns="http://www.w3.org/2000/svg" width="9.375" height="11.752" viewBox="0 0 9.375 11.752">
									  <g id="np_location_4211118_000000" transform="translate(-21.517 -5.002)" opacity="0.6">
										<path id="Path_2306" data-name="Path 2306" d="M25.689,5.029a4.825,4.825,0,0,0-4.154,5.16c.285,3.272,3.293,5.711,4.324,6.457a.562.562,0,0,0,.677-.012c1.071-.836,4.357-3.67,4.357-6.942a4.691,4.691,0,0,0-5.2-4.663Zm.514,6.524a1.861,1.861,0,1,1,1.861-1.861A1.861,1.861,0,0,1,26.2,11.553Z" transform="translate(0 0)" fill="#5e6a7c"/>
									  </g>
									</svg>

									Mumbai, 400001</p>
								</div>
								
							</div>
							<div class="row">
								<div class="col-md-3 col-sm-6">
									<h4 class="txtdefault fs14">GSTIN:</h4>
									<p class="txtseconday fs14  mb-0">95FEBAE0240S8T8</p>
								</div>
								<div class="col-md-2 col-sm-6 pr-md-0">
									<h4 class="txtdefault fs14">PAN Number:</h4>
									<p class="txtseconday fs14 mb-0">
										AUTPP1234D
									</p>
								</div>
								<div class="col-md-2 col-sm-6 pr-md-0">
									<h4 class="txtdefault fs14">Registered On</h4>
									<p class="txtseconday fs14  mb-0">
										Jun 24, 2020
									</p>
								</div>
								<div class="col-md-2 col-sm-6 pl-md-0">
									<h4 class="txtdefault fs14">User Status</h4>
									<p class="txtseconday fs14  mb-0">
										Signed Up
									</p>
								</div>
								<div class="col-md-3 pl-md-0 text-right">
									<div class="mgb-action-wrp">
										<a href="javascript:void(0);" class="w-100 text-center btn-d-outline d-inline-block place-bid">View Details</a>
										
										<div ngbDropdown class="d-inline-block mgb-action-in">
											<div class="mgb-action" id="dropdownBasic1" ngbDropdownToggle>
												<span></span>
												<span></span>
												<span></span>
											</div>
											
											  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
												<button ngbDropdownItem>Activate</button>
												<button ngbDropdownItem>Deactivate</button>
											  </div>
											</div>
									</div>
								</div>
							</div>
						</div>
						<!-- /REPEAT -->
						<!-- REPEAT -->
						<div class="buyer-row th-cmn-bd pb-3 mb-3">
							<div class="row">
								<div class="col-sm-12">
									<h4 class="fs16 wt700 mb-2">Kiran Kumar &nbsp; <span class="tg-grp  align-middle tg-grp-sp"><span>Bulk</span></span></h4>
									<p class="fs12 txtdefault envlprw envllocprw">
									<svg xmlns="http://www.w3.org/2000/svg" width="9.375" height="11.752" viewBox="0 0 9.375 11.752">
									  <g id="np_location_4211118_000000" transform="translate(-21.517 -5.002)" opacity="0.6">
										<path id="Path_2306" data-name="Path 2306" d="M25.689,5.029a4.825,4.825,0,0,0-4.154,5.16c.285,3.272,3.293,5.711,4.324,6.457a.562.562,0,0,0,.677-.012c1.071-.836,4.357-3.67,4.357-6.942a4.691,4.691,0,0,0-5.2-4.663Zm.514,6.524a1.861,1.861,0,1,1,1.861-1.861A1.861,1.861,0,0,1,26.2,11.553Z" transform="translate(0 0)" fill="#5e6a7c"/>
									  </g>
									</svg>

									Mumbai, 400001</p>
								</div>
								
							</div>
							<div class="row">
								<div class="col-md-3 col-sm-6">
									<h4 class="txtdefault fs14">GSTIN:</h4>
									<p class="txtseconday fs14  mb-0">95FEBAE0240S8T8</p>
								</div>
								<div class="col-md-2 col-sm-6 pr-md-0">
									<h4 class="txtdefault fs14">PAN Number:</h4>
									<p class="txtseconday fs14 mb-0">
										AUTPP1234D
									</p>
								</div>
								<div class="col-md-2 col-sm-6 pr-md-0">
									<h4 class="txtdefault fs14">Registered On</h4>
									<p class="txtseconday fs14  mb-0">
										Jun 24, 2020
									</p>
								</div>
								<div class="col-md-2 col-sm-6 pl-md-0">
									<h4 class="txtdefault fs14">User Status</h4>
									<p class="txtseconday fs14  mb-0">
										Signed Up
									</p>
								</div>
								<div class="col-md-3 pl-md-0 text-right">
									<div class="mgb-action-wrp">
										<a href="javascript:void(0);" class="w-100 text-center btn-d-outline d-inline-block place-bid">View and Verify Details</a>
										
										<div ngbDropdown class="d-inline-block mgb-action-in">
											<div class="mgb-action" id="dropdownBasic1" ngbDropdownToggle>
												<span></span>
												<span></span>
												<span></span>
											</div>
											
											  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
												<button ngbDropdownItem>Activate</button>
												<button ngbDropdownItem>Deactivate</button>
											  </div>
											</div>
									</div>
								</div>
							</div>
						</div>
						<!-- /REPEAT -->
						<!-- REPEAT -->
						<div class="buyer-row th-cmn-bd pb-3 mb-3">
							<div class="row">
								<div class="col-sm-12">
									<h4 class="fs16 wt700 mb-2">Kiran Kumar &nbsp; <span class="tg-grp  align-middle tg-grp-sp"><span>Bulk</span></span></h4>
									<p class="fs12 txtdefault envlprw envllocprw">
									<svg xmlns="http://www.w3.org/2000/svg" width="9.375" height="11.752" viewBox="0 0 9.375 11.752">
									  <g id="np_location_4211118_000000" transform="translate(-21.517 -5.002)" opacity="0.6">
										<path id="Path_2306" data-name="Path 2306" d="M25.689,5.029a4.825,4.825,0,0,0-4.154,5.16c.285,3.272,3.293,5.711,4.324,6.457a.562.562,0,0,0,.677-.012c1.071-.836,4.357-3.67,4.357-6.942a4.691,4.691,0,0,0-5.2-4.663Zm.514,6.524a1.861,1.861,0,1,1,1.861-1.861A1.861,1.861,0,0,1,26.2,11.553Z" transform="translate(0 0)" fill="#5e6a7c"/>
									  </g>
									</svg>

									Mumbai, 400001</p>
								</div>
								
							</div>
							<div class="row">
								<div class="col-md-3 col-sm-6">
									<h4 class="txtdefault fs14">GSTIN:</h4>
									<p class="txtseconday fs14  mb-0">95FEBAE0240S8T8</p>
								</div>
								<div class="col-md-2 col-sm-6 pr-md-0">
									<h4 class="txtdefault fs14">PAN Number:</h4>
									<p class="txtseconday fs14 mb-0">
										AUTPP1234D
									</p>
								</div>
								<div class="col-md-2 col-sm-6 pr-md-0">
									<h4 class="txtdefault fs14">Registered On</h4>
									<p class="txtseconday fs14  mb-0">
										Jun 24, 2020
									</p>
								</div>
								<div class="col-md-2 col-sm-6 pl-md-0">
									<h4 class="txtdefault fs14">User Status</h4>
									<p class="txtseconday fs14  mb-0">
										Signed Up
									</p>
								</div>
								<div class="col-md-3 pl-md-0 text-right">
									<div class="mgb-action-wrp">
										<a href="javascript:void(0);" class="w-100 text-center btn-d-outline d-inline-block place-bid">View Details</a>
										
										<div ngbDropdown class="d-inline-block mgb-action-in">
											<div class="mgb-action" id="dropdownBasic1" ngbDropdownToggle>
												<span></span>
												<span></span>
												<span></span>
											</div>
											
											  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
												<button ngbDropdownItem>Activate</button>
												<button ngbDropdownItem>Deactivate</button>
											  </div>
											</div>
									</div>
								</div>
							</div>
						</div>
						<!-- /REPEAT -->
						<!-- REPEAT -->
						<div class="buyer-row th-cmn-bd pb-3 mb-3">
							<div class="row">
								<div class="col-sm-12">
									<h4 class="fs16 wt700 mb-2">Kiran Kumar &nbsp; <span class="tg-grp  align-middle tg-grp-sp"><span>Bulk</span></span></h4>
									<p class="fs12 txtdefault envlprw envllocprw">
									<svg xmlns="http://www.w3.org/2000/svg" width="9.375" height="11.752" viewBox="0 0 9.375 11.752">
									  <g id="np_location_4211118_000000" transform="translate(-21.517 -5.002)" opacity="0.6">
										<path id="Path_2306" data-name="Path 2306" d="M25.689,5.029a4.825,4.825,0,0,0-4.154,5.16c.285,3.272,3.293,5.711,4.324,6.457a.562.562,0,0,0,.677-.012c1.071-.836,4.357-3.67,4.357-6.942a4.691,4.691,0,0,0-5.2-4.663Zm.514,6.524a1.861,1.861,0,1,1,1.861-1.861A1.861,1.861,0,0,1,26.2,11.553Z" transform="translate(0 0)" fill="#5e6a7c"/>
									  </g>
									</svg>

									Mumbai, 400001</p>
								</div>
								
							</div>
							<div class="row">
								<div class="col-md-3 col-sm-6">
									<h4 class="txtdefault fs14">GSTIN:</h4>
									<p class="txtseconday fs14  mb-0">95FEBAE0240S8T8</p>
								</div>
								<div class="col-md-2 col-sm-6 pr-md-0">
									<h4 class="txtdefault fs14">PAN Number:</h4>
									<p class="txtseconday fs14 mb-0">
										AUTPP1234D
									</p>
								</div>
								<div class="col-md-2 col-sm-6 pr-md-0">
									<h4 class="txtdefault fs14">Registered On</h4>
									<p class="txtseconday fs14  mb-0">
										Jun 24, 2020
									</p>
								</div>
								<div class="col-md-2 col-sm-6 pl-md-0">
									<h4 class="txtdefault fs14">User Status</h4>
									<p class="txtseconday fs14  mb-0">
										Signed Up
									</p>
								</div>
								<div class="col-md-3 pl-md-0 text-right">
									<div class="mgb-action-wrp">
										<a href="javascript:void(0);" class="w-100 text-center btn-d-outline d-inline-block place-bid">View Details</a>
										
										<div ngbDropdown class="d-inline-block mgb-action-in">
											<div class="mgb-action" id="dropdownBasic1" ngbDropdownToggle>
												<span></span>
												<span></span>
												<span></span>
											</div>
											
											  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
												<button ngbDropdownItem>Activate</button>
												<button ngbDropdownItem>Deactivate</button>
											  </div>
											</div>
									</div>
								</div>
							</div>
						</div>
						<!-- /REPEAT -->
						<!-- REPEAT -->
						<div class="buyer-row th-cmn-bd pb-3 mb-3">
							<div class="row">
								<div class="col-sm-12">
									<h4 class="fs16 wt700 mb-2">Kiran Kumar &nbsp; <span class="tg-grp  align-middle tg-grp-sp"><span>Bulk</span></span></h4>
									<p class="fs12 txtdefault envlprw envllocprw">
									<svg xmlns="http://www.w3.org/2000/svg" width="9.375" height="11.752" viewBox="0 0 9.375 11.752">
									  <g id="np_location_4211118_000000" transform="translate(-21.517 -5.002)" opacity="0.6">
										<path id="Path_2306" data-name="Path 2306" d="M25.689,5.029a4.825,4.825,0,0,0-4.154,5.16c.285,3.272,3.293,5.711,4.324,6.457a.562.562,0,0,0,.677-.012c1.071-.836,4.357-3.67,4.357-6.942a4.691,4.691,0,0,0-5.2-4.663Zm.514,6.524a1.861,1.861,0,1,1,1.861-1.861A1.861,1.861,0,0,1,26.2,11.553Z" transform="translate(0 0)" fill="#5e6a7c"/>
									  </g>
									</svg>

									Mumbai, 400001</p>
								</div>
								
							</div>
							<div class="row">
								<div class="col-md-3 col-sm-6">
									<h4 class="txtdefault fs14">GSTIN:</h4>
									<p class="txtseconday fs14  mb-0">95FEBAE0240S8T8</p>
								</div>
								<div class="col-md-2 col-sm-6 pr-md-0">
									<h4 class="txtdefault fs14">PAN Number:</h4>
									<p class="txtseconday fs14 mb-0">
										AUTPP1234D
									</p>
								</div>
								<div class="col-md-2 col-sm-6 pr-md-0">
									<h4 class="txtdefault fs14">Registered On</h4>
									<p class="txtseconday fs14  mb-0">
										Jun 24, 2020
									</p>
								</div>
								<div class="col-md-2 col-sm-6 pl-md-0">
									<h4 class="txtdefault fs14">User Status</h4>
									<p class="txtseconday fs14  mb-0">
										Signed Up
									</p>
								</div>
								<div class="col-md-3 pl-md-0 text-right">
									<div class="mgb-action-wrp">
										<a href="javascript:void(0);" class="w-100 text-center btn-d-outline d-inline-block place-bid">View Details</a>
										
										<div ngbDropdown class="d-inline-block mgb-action-in">
											<div class="mgb-action" id="dropdownBasic1" ngbDropdownToggle>
												<span></span>
												<span></span>
												<span></span>
											</div>
											
											  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
												<button ngbDropdownItem>Activate</button>
												<button ngbDropdownItem>Deactivate</button>
											  </div>
											</div>
									</div>
								</div>
							</div>
						</div>
						<!-- /REPEAT -->
					  </div>
				</ng-template>
			  </ng-container>
			  
			</nav>

			<div [ngbNavOutlet]="nav" class="mt-2"></div>
		</div>
	</div>
</div>	
