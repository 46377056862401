<app-header-one [sticky]="true"></app-header-one>

<section class="small-section pb-0">
	<div class="container">
		<div class="row d-flex justify-content-between align-items-start">
			<div class="col-md-6">
				<h2 class="section-title text-left mb-0 font-alt">Notifications</h2>
				<!-- BREADCRUMB -->
				<nav class="mb-2 pt-2 pb-2" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
				  <ol class="breadcrumb p-0 bg-transparent">
					<li class="breadcrumb-item"><a [routerLink]="['/home']" class="txtseconday">Home</a></li>
					<li class="breadcrumb-item active" aria-current="page">Notifications</li>
				  </ol>
				</nav>
				<!-- /BREADCRUMB -->
			</div>

			<div class="col-md-6">
				<div class="text-right">
					<a href="javascript:;" class="btn-d-outline d-inline-block">
						<svg xmlns="http://www.w3.org/2000/svg" width="19.187" height="20.284" viewBox="0 0 19.187 20.284">
						  <g id="noun_setting_4270623" transform="translate(-1.703 -1)">
						    <g id="Layer_2" data-name="Layer 2" transform="translate(1.703 1)">
						      <path id="Path_8672" data-name="Path 8672" d="M20.82,13.731a2.035,2.035,0,0,0-.956-1.248,1.54,1.54,0,0,1-.766-1.327v-.027A1.538,1.538,0,0,1,19.865,9.8,2.053,2.053,0,0,0,20.615,7L19.544,5.143a2.051,2.051,0,0,0-2.8-.75l-.072.039a1.387,1.387,0,0,1-1.4,0l-.156-.091a1.385,1.385,0,0,1-.7-1.208V3.049A2.053,2.053,0,0,0,12.368,1H10.225A2.053,2.053,0,0,0,8.176,3.049v.084a1.385,1.385,0,0,1-.7,1.212l-.16.087a1.386,1.386,0,0,1-1.4,0L5.846,4.39a2.053,2.053,0,0,0-2.8.751L1.978,7a2.053,2.053,0,0,0,.751,2.8,1.56,1.56,0,0,1,0,2.688,2.049,2.049,0,0,0-.751,2.8l1.03,1.785a2.136,2.136,0,0,0,2.914.78,1.388,1.388,0,0,1,1.4,0l.156.087a1.386,1.386,0,0,1,.694,1.213,2.136,2.136,0,0,0,2.134,2.133h1.974a2.136,2.136,0,0,0,2.134-2.133,1.386,1.386,0,0,1,.7-1.212l.156-.09a1.387,1.387,0,0,1,1.4,0,2.135,2.135,0,0,0,2.913-.78l1.029-1.782a2.035,2.035,0,0,0,.208-1.556Zm-1.56.775-1.025,1.785a.574.574,0,0,1-.78.209,2.951,2.951,0,0,0-2.965,0l-.132.078a2.946,2.946,0,0,0-1.5,2.568.573.573,0,0,1-.573.573H10.31a.573.573,0,0,1-.573-.573,2.946,2.946,0,0,0-1.5-2.575L8.115,16.5A2.916,2.916,0,0,0,6.626,16.1a2.972,2.972,0,0,0-1.482.4.574.574,0,0,1-.78-.209L3.33,14.506a.488.488,0,0,1,.179-.669,3.121,3.121,0,0,0,0-5.391.488.488,0,0,1-.179-.668L4.4,5.924a.488.488,0,0,1,.667-.179l.073.042a2.954,2.954,0,0,0,2.975-.008l.125-.073a2.944,2.944,0,0,0,1.5-2.572V3.049a.489.489,0,0,1,.489-.489h2.142a.489.489,0,0,1,.489.489v.084a2.947,2.947,0,0,0,1.5,2.575l.126.072a2.953,2.953,0,0,0,2.975.007l.072-.041a.488.488,0,0,1,.668.179L19.263,7.78a.488.488,0,0,1-.179.668,3.1,3.1,0,0,0-1.547,2.681v.026a3.1,3.1,0,0,0,1.547,2.683.489.489,0,0,1,.179.669Z" transform="translate(-1.703 -1)" fill="#ff7135"/>
						      <path id="Path_8673" data-name="Path 8673" d="M12.9,9a3.9,3.9,0,1,0,3.9,3.9A3.9,3.9,0,0,0,12.9,9Zm0,6.241a2.34,2.34,0,1,1,2.34-2.34A2.34,2.34,0,0,1,12.9,15.241Z" transform="translate(-3.307 -2.759)" fill="#ff7135"/>
						    </g>
						  </g>
						</svg>
						 &nbsp;
						Notification Settings
					</a>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="small-section pt-0">
	<div class="container">
		<div class="row">
			<div class="col-md-3 filtercol lsting rating-filter filtermbl" [ngClass]="ratingclassstatus ? 'showfilter' : ''">
				<div class="fltrshead"> 
					<h4>Filter</h4> 
					<a (click)="ratingtglclass()"  class="closefilter" href="javascript:void(0);"><svg _ngcontent-serverApp-c132="" xmlns="http://www.w3.org/2000/svg" width="6.508" height="6.508" viewBox="0 0 6.508 6.508"><path _ngcontent-serverApp-c132="" id="np_cross_1707162_000000" d="M30.883,30.883a.507.507,0,0,1,.717,0l2.389,2.389,2.389-2.389a.507.507,0,0,1,.717.717L34.7,33.988l2.389,2.389a.507.507,0,0,1-.717.717L33.988,34.7,31.6,37.094a.507.507,0,0,1-.717-.717l2.389-2.389L30.883,31.6A.507.507,0,0,1,30.883,30.883Z" transform="translate(-30.734 -30.734)" fill="#1f1d44"></path></svg></a>
				</div>
				<ngb-accordion #acc="ngbAccordion" activeIds="ratingfilter, toggle-2, toggle-3, brand, startingprice, ram, internalstorage, screensize, activationstatus, supplylocation, countryorigin" class="accordion">
					<ngb-panel id="ratingfilter" opened class="bg-transparent" title="Show All">
						<ng-template ngbPanelContent >
							<ul class="list-group">
								<li class="list-group-item">
									<div class="css-check">
										<input class="css-checkbx" type="checkbox" />
										<label class="css-label">
											<span class="fs16 txtseconday">Unread Notifications</span>
										</label>
									</div>								
								</li>
								<li class="list-group-item">
									<div class="css-check">
										<input class="css-checkbx" type="checkbox" />
										<label class="css-label">
											<span class="fs16 txtseconday">Read Notifications</span>
										</label>
									</div>								
								</li>
							</ul>
						</ng-template>
					</ngb-panel>
					<ngb-panel id="toggle-2" title="Notification Type">
						<ng-template ngbPanelContent>
							<ul class="list-group">
								<li class="list-group-item">
									<div class="css-check">
										<input class="css-checkbx" type="checkbox" />
										<label class="css-label">Payments & Transactions</label>
									</div>								
								</li>
								<li class="list-group-item">
									<div class="css-check">
										<input class="css-checkbx" type="checkbox" />
										<label class="css-label">Orders</label>
									</div>
								</li>
								<li class="list-group-item">
									<div class="css-check">
										<input class="css-checkbx" type="checkbox" />
										<label class="css-label">Deals & Offers</label>
									</div>
								</li>
								<li class="list-group-item">
									<div class="css-check">
										<input class="css-checkbx" type="checkbox" />
										<label class="css-label">System Notifications</label>
									</div>
								</li>
								
							</ul>
						</ng-template>
					</ngb-panel>
					
				</ngb-accordion>
				<div class="tex-center clr-apl-btn showintablet">			
					<a href="javascript:void(0);" class="btn-d-outline mr-3 d-inline-block place-bid">Clear All</a>
					<a href="javascript:void(0);" class="buybtn d-inline-block radius10">Apply</a>
				</div>
			</div>
			<div class="col-md-9 listing-content rating-content pl-lg-4">
				<div class="card px-2 pt-0 radius15">
					<div class="card-body pb-60">
						<div class="notifications-container theme-tab">
							<div class="mark-all">
								<a href="">Mark all as read</a>
							</div>
				          	<ngb-tabset [justify]="'left'" [type]="'pills'" class="tabs tab-title">
					            <ngb-tab>
					                <ng-template ngbTabTitle>All Notifications (45)</ng-template>
					                <ng-template ngbTabContent>
					                  <div class="tab-content">
					                    	<ul>
					                    		<li>
					                    			<div class="new-one"></div>
					                    			<div class="info">
					                    				<img src="assets/images/icons/notify-book-now.svg">
					                    				Book now to get 10% Off. It is an Excellent Deal
					                    			</div>
					                    			<div class="time">2 min ago</div>
					                    		</li>
					                    		<li>
					                    			<div class="info">
					                    				<div class="new-one"></div>
					                    				<img src="assets/images/icons/notify-new-launch.svg">
					                    				Xiaomi has launched a new product. Xiaomi has launched a new product.
					                    			</div>
					                    			<div class="time">15 mins ago</div>
					                    		</li>
					                    		<li>
					                    			<div class="info">
					                    				<img src="assets/images/icons/notify-wallet.svg">
					                    				Pre-book amount Rs. 7,999 is to be paid.
					                    			</div>
					                    			<div class="time">Yesterday, 12:54 pm</div>
					                    		</li>
					                    		<li>
					                    			<div class="info">
					                    				<img src="assets/images/icons/notify-new-trade.svg">
					                    				<a href="javascript:;">Digixpress Trade Private Limited</a>  has accepted your Bid amount Rs. 7,999.
					                    			</div>
					                    			<div class="time">27/07/2021, 01:30 pm</div>
					                    		</li>
					                    		<li>
					                    			<div class="info">
					                    				<img src="assets/images/icons/notify-new-trade.svg">
					                    				<a href="javascript:;">Digixpress Trade Private Limited</a>  has accepted your Bid amount Rs. 7,999.
					                    			</div>
					                    			<div class="time">27/07/2021, 01:30 pm</div>
					                    		</li>
					                    	</ul>
					                  </div>
					                </ng-template>
					            </ngb-tab>	
					            <ngb-tab>
					                <ng-template ngbTabTitle>Payments & Transactions</ng-template>
					                <ng-template ngbTabContent>
					                  <div class="tab-content">
					                    	<ul>
					                    		<li>
					                    			<div class="info">
					                    				<img src="assets/images/icons/notify-wallet.svg">
					                    				Pre-book amount Rs. 7,999 is to be paid.
					                    			</div>
					                    			<div class="time">Yesterday, 12:54 pm</div>
					                    		</li>
					                    		<li>
					                    			<div class="info">
					                    				<img src="assets/images/icons/notify-new-trade.svg">
					                    				<a href="javascript:;">Digixpress Trade Private Limited</a>  has accepted your Bid amount Rs. 7,999.
					                    			</div>
					                    			<div class="time">27/07/2021, 01:30 pm</div>
					                    		</li>
					                    		<li>
					                    			<div class="info">
					                    				<img src="assets/images/icons/notify-new-trade.svg">
					                    				<a href="javascript:;">Digixpress Trade Private Limited</a>  has accepted your Bid amount Rs. 7,999.
					                    			</div>
					                    			<div class="time">27/07/2021, 01:30 pm</div>
					                    		</li>
					                    	</ul>
					                  </div>
					                </ng-template>
					            </ngb-tab>	
					            <ngb-tab>
					                <ng-template ngbTabTitle>Orders</ng-template>
					                <ng-template ngbTabContent>
					                  	<div class="tab-content">
					                    	<ul>
					                    		<li>
					                    			<div class="info">
					                    				<img src="assets/images/icons/notify-book-now.svg">
					                    				Book now to get 10% Off. It is an Excellent Deal
					                    			</div>
					                    			<div class="time">2 min ago</div>
					                    		</li>
					                    		<li>
					                    			<div class="info">
					                    				<img src="assets/images/icons/notify-wallet.svg">
					                    				Pre-book amount Rs. 7,999 is to be paid.
					                    			</div>
					                    			<div class="time">Yesterday, 12:54 pm</div>
					                    		</li>
					                    	</ul>
					                  	</div>
					                </ng-template>
					            </ngb-tab>
					            <ngb-tab>
					                <ng-template ngbTabTitle>Deals & Offers</ng-template>
					                <ng-template ngbTabContent>
					                  <div class="tab-content">
					                    	<ul>
					                    		<li>
					                    			<div class="info">
					                    				<img src="assets/images/icons/notify-book-now.svg">
					                    				Book now to get 10% Off. It is an Excellent Deal
					                    			</div>
					                    			<div class="time">2 min ago</div>
					                    		</li>
					                    		<li>
					                    			<div class="info">
					                    				<img src="assets/images/icons/notify-new-launch.svg">
					                    				Xiaomi has launched a new product. Xiaomi has launched a new product.
					                    			</div>
					                    			<div class="time">15 mins ago</div>
					                    		</li>
					                    	</ul>
					                  </div>
					                </ng-template>
					            </ngb-tab>	
					            <ngb-tab>
					                <ng-template ngbTabTitle>System Notifications</ng-template>
					                <ng-template ngbTabContent>
					                  <div class="tab-content">
					                    	No Notifications
					                  </div>
					                </ng-template>
					            </ngb-tab>									
				        	</ngb-tabset>
				    	</div>
				    </div>
				</div> 
				<div class="row">
					<div class="col-md-6">
						<div class="text-left">
							<nav class="mt-3 tbpg rows-per-page">
								<div>Rows per page:</div>
								<select class="select-box">
									<option>10</option>
									<option>20</option>
								</select>
							</nav>
						</div> 
					</div>
					<div class="col-md-6">
						<div class="text-right pr-4">
							<nav class="mt-3 tbpg">
							  <ul class="pagination align-items-center">
								<li class="page-item"><a class="page-link previcon" href="#"></a></li>
								<li class="page-item"><a class="page-link sprevicon" href="#"></a></li>
								<li class="page-item"><a class="page-link" href="#">1</a></li>
								<li class="page-item"><a class="page-link" href="#">2</a></li>
								<li class="page-item"><a class="page-link" href="#">3</a></li>
								<li class="page-item"><a class="page-link" href="#">4</a></li>
								<li class="page-item"><a class="page-link snexticon" href="#"></a></li>
								<li class="page-item"><a class="page-link nexticon" href="#"></a></li>
							  </ul>
							</nav>
						</div> 
					</div>
				</div> 
			</div>
		</div>
	</div>
</section>

<app-footer-one></app-footer-one>