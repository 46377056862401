<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain pb-4">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center pinfo-head">
			<div class="col-md-5 mb-4">
				<h3 class="fs24 wt700 txtdefault">Return Orders</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">						
						<li class="breadcrumb-item "><a href="javascript:void(0);" class="txtseconday">Order Management</a></li>
						<li class="breadcrumb-item active">Return Orders</li>
					</ol>
				</nav>
			</div>
			<div class="col-md-7  mb-4 text-md-right text-center">
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4 mb-0">
			<div class="tab-content">
				<!--Filters-->
				<div class="row d-flex justify-content-between mt-20 report-cta">
					<div class="col-lg-5 mb-3">
						<div class="report-input">
							<div class="input-group-custom">
								<input type="text" placeholder="Search by Order ID, Transaction ID, Product Name & Brand" class="form-control" 
								class="form-control" [(ngModel)]="search_term" (change)="getordersfilters()">
								<div class="input-group-append-custom">
									<img src="assets/images/svg/search_logo.svg">
								</div>
							</div>
						</div>
					</div>
                    <!-- <div class="text-right mb-10">
						<a href="javascript:void(0);" (click)="getordersfilters('yes')" class="btn-d-outline d-inline-block place-bid" >
							<svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
							<g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
								<path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#ff7135"/>
							</g>
							</svg>					
							<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
									*ngIf="export_loading"></span>
								&nbsp;Export Report
						</a>
						<a href="{{ export_path }}"
							*ngIf="export_path"
							class="btn btn-default"
							target="_blank"
							download="Seller.csv"
							>Download Now
						</a>
					</div>	 -->
				</div>

				<div class="table-responsive brndmgnttable odmgnttabl"> 
					<table class="table custom-table xs">
						<thead>
							<tr>
								<th>Order No.</th>
								<th>Transaction No.</th>
								<th width="18%">Product Name</th>
								<th>Buyer Name</th>
                                <th width="10%">Seller Name</th>
								<th width="10%">Quantity</th>
								<th width="10%">Reason</th>
								<th>Status</th>
								<th>View</th>
							</tr>
						</thead>
						<tbody>
							<!--Empty State-->
							<tr *ngIf="!return_records.length">
								<td colspan="9">
									<div class="row pt-40 pb-40">
										<div class="col-md-12 text-center">
											<div class="mb-20"><img src="/assets/images/empty-state.png">
											</div>
											<div class="fs16">There are no records to display!</div>
										</div>
									</div>
								</td>
							</tr>
							<!--End Empty State-->
							<tr *ngFor="let record of return_records;">
								<td>{{ record.unique_id }}</td>
								<td>{{ record.order_no }}</td>
								<td>
									<div class="order-info d-flex align-items-start">
										<div class="order-img">
											<img [src]="record.product_image" />
										</div>
										<div class="pd-seller-cntnt">
											<div class="txtdefault">
												<a href="javascript:;" class="order-link">{{ record.product_name }}</a>
											</div>
											<div class="pt-2">
												<span class="py-1 px-3 odbdge btn-d-outline ripple d-inline-block fs12">{{ record.map_type }}</span>
											</div>
										</div>
									</div>

								</td>												
								<td>{{ record.buyer_name }}</td>
								<td>{{ record.seller_name }}</td>
								<td>{{ record.qty }}</td>
								<td>{{ record.reason }}</td>
								<td>{{ record.status }}</td>
								<td>
									<div class="d-flex align-items-center">
										<a href="javascript:void(0);" [routerLink]="['/admin/ordderdetails/'+record.meta_id]" class="btn-d-outline align-middle ripple d-inline-block place-bid mr-3">Order Details</a>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>	
				</div>
			<div class="row mb-40" *ngIf="return_pagination.totalPages> 1">
				<div class="col-md-6">
					<div class="text-left pl-3">
						<nav class="mt-3 tbpg rows-per-page">
							<div>Rows per page:</div>
							<select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
								<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">
									{{option.name}}</option>
							</select>
						</nav>
					</div>
				</div>
				<div class="col-md-6">
					<div class="text-right pr-3">
						<nav class="mt-3 tbpg">
							<ul class="pagination align-items-center">
								<li class="page-item" [ngClass]="{disabled:return_pagination.currentPage === 1}"><a
										class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a>
								</li>

								<li class="page-item" [ngClass]="{disabled:return_pagination.currentPage === 1}"><a
										class="page-link sprevicon" href="javascript:;"
										(click)="setpage(return_pagination.currentPage - 1)"></a></li>

								<li class="page-item" i *ngFor="let page of return_pagination.pages"
									[ngClass]="{active:return_pagination.currentPage === page}"><a class="page-link"
										href="javascript:;" (click)="setpage(page)">{{page}}</a></li>

								<li *ngIf="(return_pagination.totalPages - return_pagination.startPage) > 5 ">
									<a (click)="setpage(return_pagination.startPage + 5)">...</a>
								</li>
								<li *ngIf="(return_pagination.totalPages - return_pagination.startPage) > 5">
									<a
										(click)="setpage(return_pagination.totalPages)">{{return_pagination.totalPages}}</a>
								</li>

								<li class="page-item"
									[ngClass]="{disabled:return_pagination.currentPage === return_pagination.totalPages}">
									<a class="page-link snexticon" href="javascript:;"
										(click)="setpage(return_pagination.currentPage + 1)"></a>
								</li>
								<li class="page-item"
									[ngClass]="{disabled:return_pagination.currentPage === return_pagination.totalPages}">
									<a class="page-link nexticon" href="javascript:;"
										(click)="setpage(return_pagination.totalPages)"></a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
			</div> 
		</div>
	</div>
</div>