import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ViewOrderDetailsService {

    constructor(private _http: HttpClient) { }

    getorderdetails(data) {
        return this._http.post(environment.api_url+'seller/orders/details',data);
    }

    generateinvoice(data) {
        return this._http.post(environment.api_url+'seller/order/generateinvoice',data);
    }

    uploadinvoice(data) {
      return this._http.post(environment.api_url+'seller/order/uploadinvoice',data);
    }

    generatedetails(data) {
      return this._http.post(environment.api_url+'seller/order/uploadotherdocument',data);
    }

    generatemanifest(data) {
      return this._http.post(environment.api_url+'seller/order/generatemanifest',data);
    }

    regenerateinvoice(data) {
      return this._http.post(environment.api_url+'seller/order/regenerateinvoice',data);
    }

    acceptrejectreturn(data) {
      return this._http.post(environment.api_url+'seller/returnrequest/approve',data);
    }
    
}