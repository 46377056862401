<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<h3 class="fs24 wt700 txtdefault">Make Payment</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">Wallet</a>
						</li>
						<li class="breadcrumb-item active">Make Payment</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->

		<div class="card cardui carduipro p-4 mb-40">
			<h3 class="f-700 fs20 txtdefault pb-20">Equibiz Wallet</h3>
			<div class="wallet-balance">
				<div class="mr-sm-3">
					<img src="assets/images/icons/wallet-balance.svg">
				</div>
				<div>
					<p>Current Balance:</p>
					<h4>{{ user_info.walletbalance }}</h4>
				</div>
			</div>
			<div class="row mb-20">
				<div class="col-md-5">
					<div class="form-container mdl-frm-lbl mb-2 mt-0">
						<div class="floating-label mb-0">      
							<div class="ng-autocomplete">
								<ng-autocomplete 
									[data]="result" 
									[searchKeyword]="keyword" 
									(selected)='selectEvent($event)'
							        (inputChanged)='onChangeSearch($event)' 
							        (inputFocused)='onFocused($event)' 
							        [itemTemplate]="itemTemplate"
							        [notFoundTemplate]="notFoundTemplate" 
        							placeholder="Search by Buyer or Seller" 
									[disabled] = "selected_user_type == 'admin'"
									>
							    </ng-autocomplete>
							    <ng-template #itemTemplate let-item>
							        <a href="javascript:void(0)">
							            <div class="d-flex align-items-center justify-content-between">
							                <span [innerHTML]="item.name"></span>
							                <span><button>+ Add</button></span>
							            </div>
							        </a>
							    </ng-template>

							    <ng-template #notFoundTemplate let-notFound>
							        <div [innerHTML]="notFound"></div>
							    </ng-template>
								<div class="search-icon"><img src="assets/images/svg/search_logo.svg"></div>
							</div>
						</div>
					</div>
					<div class="mt-2" *ngIf="from_balance">
						<b>Balance</b>: <span class="color">{{ from_balance }}</span>
					</div>
				</div>
				
				<div class="col-md-3">
					<div class="tpbr auto-w">
						<label class="fs12 txtseconday tpbrlbl">{{ selected_payment_type == 'wallet' ?'From User Type': 'User Type' }}</label>
						<ng-selectize [config]="config" [options] = "user_type" placeholder="" [(ngModel)]="selected_user_type" (change)="changeusertype()">
						</ng-selectize>	
					</div>
				</div>
			</div>

			<div class="row mb-20" *ngIf="selected_payment_type == 'wallet'">
				<div class="col-md-5">
					<div class="form-container mdl-frm-lbl mb-2 mt-0">
						<div class="floating-label mb-0">      
							<div class="ng-autocomplete">
								<ng-autocomplete 
									[data]="toresult" 
									[searchKeyword]="keyword" 
									(selected)='toselectEvent($event)'
							        (inputChanged)='toonChangeSearch($event)' 
							        (inputFocused)='toonFocused($event)' 
							        [itemTemplate]="itemTemplate"
							        [notFoundTemplate]="notFoundTemplate" 
        							placeholder="Search by Buyer or Seller" 
									[disabled] = "selected_to_user_type == 'admin'">
							    </ng-autocomplete>
							    <ng-template #itemTemplate let-item>
							        <a href="javascript:void(0)">
							            <div class="d-flex align-items-center justify-content-between">
							                <span [innerHTML]="item.name"></span>
							                <span><button>+ Add</button></span>
							            </div>
							        </a>
							    </ng-template>

							    <ng-template #notFoundTemplate let-notFound>
							        <div [innerHTML]="notFound"></div>
							    </ng-template>
								<div class="search-icon"><img src="assets/images/svg/search_logo.svg"></div>
							</div>
						</div> 
					</div>
					<div class="mt-2" *ngIf="to_balance">
						<b>Balance</b>: <span class="color">{{ to_balance }}</span>
					</div>
				</div>
				<div class="col-md-3">
					<div class="tpbr auto-w">
						<label class="fs12 txtseconday tpbrlbl">To User Type</label>
						<ng-selectize [config]="config" [options] = "user_type" placeholder="" [(ngModel)]="selected_to_user_type" (change)="changeusertype()">
						</ng-selectize>	
					</div>
				</div>
			</div>

			<div class="form-container mdl-frm-lbl mb-3 mt-10">
				<div class="row">
					<div class="col-md-5 mb-2">
						<div class="floating-label mb-0">      
						  <input type="text" class="form-control" placeholder=" "  [(ngModel)]='payment_amount'/>
						  <span class="highlight"></span>
						  <label>Enter amount (min ₹1,000)</label>
						</div> 
					</div>
					<div class="col-md-3">
						<div class="tpbr auto-w">
							<label class="fs12 txtseconday tpbrlbl">Payment Type</label>
							<ng-selectize [config]="config" [options] = "data" placeholder="" [(ngModel)]="selected_payment_type">
							</ng-selectize>	
						</div>
					</div>
				</div>
				<div class="help-info">Transaction Charges (incl)  <a href="javascript:;" class="fs14 link-alt">Know more</a></div>

				<div class="wallet-amount mb-10">
					<ul>
                        <li *ngFor="let amounts of predefined_amounts;" (click)="updateamount(amounts)"><a
                                href="javascript:;" [ngClass]="{'selected': payment_amount == amounts.value}">₹ {{
                                amounts.label }}</a></li>
                    </ul>
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="form-container mb-3">
							<div class="floating-label mb-0">      
							   <textarea class="form-control floating-textarea" [(ngModel)]="remark"></textarea>
							   <span class="highlight"></span>
							   <label>Remarks</label>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-container mb-3">
							<div class="floating-label mb-0">
								<input type="text" class="form-control" [(ngModel)]="order_transaction_no">
							   <span class="highlight"></span>
							   <label>Order Transaction No.</label>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="rg-b-form">
							<div class="form-container input-group mb-0">
								<div class="floating-label mb-0">      
								   <input class="form-control" type="text" placeholder="{{ document_file_name }}" disabled FormControlName="" value="{{ document_file_name }}">
								   <span class="highlight"></span>
								   <label>Attach Order ID / Reference ID<span class="req">*</span></label>								  
								</div>
								<span class="input-group-addon">
									<button class="btn-d-outline d-inline-block ripple" type="button" id="button-addon2">
										Upload <input type="file" value="" class="csfile" (change)="uploadfile($event)">
									</button>
								</span>
							</div>	
						</div>
					</div>
				</div>
				<div class="text-left mt-20">
					<button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4" (click)="makepayment()" [disabled]="buttonLoading">
						<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="buttonLoading"></span>
                        &nbsp;Transfer
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

