import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class AdminOrderDetailsService {

    constructor(private _http: HttpClient) { }

    orderdetails(data) {
        return this._http.post(environment.api_url + 'admin/order/details', data);
    }

    getlogisticspartner(data) {
        return this._http.post(environment.api_url + 'admin/order/logisticpartner/list', data);
    }

    updatelogisticspartner(data) {
        return this._http.post(environment.api_url + 'admin/order/updatelogisticpartner', data);
    }

    updatestatus(data) {
        return this._http.post(environment.api_url + 'admin/order/updatestatus', data);
    }

    generatedetails(data) {
        return this._http.post(environment.api_url+'admin/order/uploadotherdocument',data);
    }

    generateinvoice(data) {
        return this._http.post(environment.api_url+'admin/order/generateinvoice',data);
    }

    uploadinvoice(data) {
      return this._http.post(environment.api_url+'admin/order/uploadinvoice',data);
    }

    generatemanifest(data) {
        return this._http.post(environment.api_url+'admin/order/generatemanifest',data);
    }

    readytodispatch(data) {
        return this._http.post(environment.api_url+'admin/orders/stockstatus',data);
    }

    acceptqcorder(data) {
        return this._http.post(environment.api_url+'admin/order/acceptqcstatus',data);
    }

    updateorderdetails(data){
        return this._http.post(environment.api_url+'admin/order/updateinvoice/info',data);
    }

    getcity(data) {
        return this._http.post(environment.api_url + 'master/city/list', data);
    }

    getstate(data) {
        return this._http.post(environment.api_url + 'master/state/list', data);
    }

    regenerateinvoice(data) {
        return this._http.post(environment.api_url+'admin/order/regenerateinvoice',data);
    }

    noimeiqc(data) {
        return this._http.post(environment.api_url+'admin/order/noimeiandqc',data);
    }

    acceptrejectreturn(data) {
        return this._http.post(environment.api_url+'admin/returnrequest/approve',data);
    }

    initpickup(data) {
        return this._http.post(environment.api_url+'admin/returnrequest/updatestatus',data);
    }

    proceedtopay(data) {
        return this._http.post(environment.api_url+'admin/order/paypendingamount',data);
    }

}
