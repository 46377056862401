import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { PostYourReqiurementService } from './postyourreqiurement.service';
import { EncrDecrService } from '../../helpers/EncrDecrService';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';

@Component({
    selector: 'app-post-your-reqiurement',
    templateUrl: './post-your-reqiurement.component.html',
    styleUrls: ['./post-your-reqiurement.component.scss']
})
export class PostYourReqiurementComponent implements OnInit {
    requirementForm: FormGroup;
    public buttonLoading:boolean = false;
    constructor(
        private _postyourrequirement: PostYourReqiurementService,
        private _toastr: ToasterService,
        private _EncrDecr: EncrDecrService,
        private fb: FormBuilder,
    ) { }

    ngOnInit(): void {
        //Requirement Form
        this.requirementForm = this.fb.group({
            category: this.fb.control(null, [Validators.required]),
            category_id: this.fb.control(null),
            product_name: this.fb.control(null, [Validators.required]),
            model_number: this.fb.control(null, [Validators.required]),
            brand_name: this.fb.control(null, []),
            no_brand: this.fb.control(false, []),
            message: this.fb.control(null, [Validators.required])
        });
    }

    get reqf() { return this.requirementForm.controls; }

    setRequired() {
        return [Validators.required];
    }

    isBrandChange(){
        if(this.requirementForm.value.no_brand) {
            this.requirementForm.get('brand_name').setValue('');
            //this.requirementForm.get('brand_name').clearValidators();
        } else {
            //this.requirementForm.get('brand_name').setValidators(this.setRequired());
            //this.requirementForm.controls['brand_name'].updateValueAndValidity();
        }
    }

    saverequirement(){
        var category_id = this.requirementForm.get('category_id').value;
        if(!category_id){
            this._toastr.error('Please selecte valid category.');
            return false;
        }
        this.buttonLoading = true;
        let data = {
            category_id: this._EncrDecr.set(this.requirementForm.get('category_id').value),
            product_name: this._EncrDecr.set(this.requirementForm.get('product_name').value),
            model_no: this._EncrDecr.set(this.requirementForm.get('model_number').value),
            brand_name: this._EncrDecr.set(this.requirementForm.get('brand_name').value),
            is_brand: this.requirementForm.get('no_brand').value,
            message: this._EncrDecr.set(this.requirementForm.get('message').value),
        };
        this._postyourrequirement.saverequirement(data).subscribe((data) => {
            this.buttonLoading = false;
            this.requirementForm.reset();
            if(data['status'] == "success"){
                this._toastr.success(data['msg']);
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }else if(data['status'] == 'validation'){
                this._toastr.validation(data['errors'])
            }
        },(error) => {
          this.buttonLoading = false;
        });
    }


    result = [];
    keyword = 'name';

    selectEvent(item) {
        this.requirementForm.get('category_id').setValue(item.c_id);
        // this._postyourrequirement.updatecategory({uniqueId: this.uniqueId, c_id: item.c_id}).subscribe((data) => {
        //     if(data['status'] == "success"){
        //         this.modalService.dismissAll();
        //         this._toastr.success('Product template category has been changed.');
        //         this.getdetails();
        //     }else if(data['status'] == "error"){
        //         this._toastr.error(data['message']);
        //     }
        // });
    }
   
    onChangeSearch(val: string) {
        this._postyourrequirement.searchcategory({search:val}).subscribe((data) => {
            if(data['status'] == "success"){
                this.result = data['records'];
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    onFocused(e){
      // do something when input is focused
    }


}
