import { Component, OnInit } from '@angular/core';
import { PendingOrdersService } from './pending-orders.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { PaginationService } from 'src/app/helpers/pagination.service';

@Component({
  selector: 'app-pending-orders',
  templateUrl: './pending-orders.component.html',
  styleUrls: ['./pending-orders.component.scss']
})
export class PendingOrdersComponent implements OnInit {

    public sorting_by:string = 'latest';
    public search_term:string = '';
    public pending_orders_records:any = [];
    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];
    
    //Seller Registered Records
    public current_page:number = 1;
    public offset:number = 0;
    public page_per_view:number = 10;
    public pending_order_pagination:any = {};
    constructor(
        private _pendingorderservice: PendingOrdersService,
        private _toastr: ToasterService,
        private _pagination: PaginationService
    ) { }

    ngOnInit(): void {
        this.getpendingorders();
    }

    getpendingorders(){
        let limit = this.page_per_view;
        let offset = this.offset;
        let current_page = this.current_page;
        let data = {
            limit: limit,
            offset: offset,
            current_page: current_page,
            search: this.search_term,
            sort: this.sorting_by
        };
        this._pendingorderservice.getpendingorders(data).subscribe((data) => {
            if(data['status'] == "success"){
                let total_records = data['total_records'];
                this.pending_order_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
                this.pending_orders_records = data['records'];
            }else if(data['status'] == "error"){
              this._toastr.error(data['message']);
            }
        });
    }

    searchpendingorders() {
        this.page_per_view = 10;
        this.offset = 0;
        this.current_page = 1;
        this.getpendingorders();
    }

    acceptorder(record){
        this._pendingorderservice.acceptorder({id: record.id}).subscribe((data) => {
            if(data['status'] == "success"){
                this._toastr.success('Order has been accepted successfully.');
                this.getpendingorders();
            }else if(data['status'] == "error"){
              this._toastr.error(data['message']);
            }
        });
    }

    rejectorder(record){
        this._pendingorderservice.rejectorder({id: record.id}).subscribe((data) => {
            if(data['status'] == "success"){
                this._toastr.success('Order has been rejected successfully.');
                this.getpendingorders();
            }else if(data['status'] == "error"){
              this._toastr.error(data['message']);
            }
        });
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page-1)*this.page_per_view;
        this.getpendingorders();
    }

    setpageperview(){
        this.current_page = 1;
        this.getpendingorders();
    }
    public val = null;
    config = {
        labelField: 'label',
        valueField: 'value',

    };
    sortingdata = [
        {
            label: 'Price Low to High',
            value: 'low'
        },
        {
            label: 'Price High to Low',
            value: 'high'
        },
        {
            label: 'Latest Arrivals',
            value: 'latest'
        }
    ]
}
