import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { UnconfirmedwalletService } from './unconfirmed-wallet.service';

@Component({
  selector: 'app-unconfirmed-wallet',
  templateUrl: './unconfirmed-wallet.component.html',
  styleUrls: ['./unconfirmed-wallet.component.scss']
})
export class UnconfirmedWalletComponent implements OnInit {

    constructor(
        private _pageservice: UnconfirmedwalletService,
        private _toastr: ToasterService
    ) { }

    ngOnInit(): void {
        this.getunconfirmedorders();
    }

    public page_records: any = [];

    getunconfirmedorders() {
        this._pageservice.getrecords(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.page_records = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

}
