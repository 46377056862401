<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="">
    <div class="equibiz-header">
      <div class="logo-bar">
        <div class="container">
          <div class="row d-flex justify-content-between">
             <div class="col-md-3 d-flex align-items-center">
                <a [routerLink]="['/home']"><img src="assets/images/svg/eq_logo.svg"></a>
                <span class="separator"></span>
                <a href="javascript:;">Help Center</a>
             </div>
             <div class="col-md-6 d-none">
                <div class="input-group-custom">
                   <input class="form-control py-2 border-right-0 border" type="text" placeholder="Search Products, Brands and Categories" id="example-search-input">
                   <div class="input-group-append-custom">
                      <img src="assets/images/svg/search_logo.svg">
                   </div>
                </div>
             </div>
             <div class="col-md-3">
             	<div class="text-right right-header">
             		<ul class="header-dropdown">
					  <li class="onhover-dropdown mobile-account menu-item topbar-menu">
						Assistance
						<ul class="onhover-show-div w-3">
							<li><div class="header-title">Assistance</div></li>
							<li><a herf="javascript:;">Delivery</a></li>
							<li><a herf="javascript:;">Product & Stock</a></li>
							<li><a herf="javascript:;">Collection</a></li>
							<li><a href="javascript:;">Data Privacy</a></li>
							<li><a href="javascript:;">Order Cancellation</a></li>
							<li><a href="javascript:;">Payment & Promo</a></li>
							<li><a href="javascript:;">Returns & Refunds</a></li>
							<li><a href="javascript:;">Equibiz Account</a></li>
							<li><a href="javascript:;" class="btn btn-custom">Help Center</a></li>
						</ul>
					  </li>
					  <li class="menu-item"><a href="javascript:;" [routerLink]="['/seller/dashboard']">Go back to Equibiz</a></li>
					</ul>
             	</div>
             </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<section class="large-section bg-blue main-search relative">
	<div class="assistance-left">
		<img src="assets/images/assistance-bg-left.png">
	</div>
	<div class="assistance-right">
		<img src="assets/images/assistance-bg-right.png">
	</div>
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="input-group-custom">
                   <input class="form-control py-2 border-right-0 border" [(ngModel)]="search_val" (change)="searchassistance()" (keyup)="searchassistance()" type="text" placeholder="Search your query here" id="example-search-input">
                   <div class="input-group-append-custom">
                      <img src="assets/images/icons/assistance-search.svg">
                      <div class="search-divider"></div>
                   </div>
                </div>
			</div>
		</div>
		<!-- <div class="row">
			<div class="col-md-12">
				<div class="search-results-tag">
					<p>Popular Searches:</p>
					<ul>
						<li><a href="javascript:;">Amount deducted but order not placed</a></li>
						<li><a href="javascript:;">Can I buy products even if I don’t have registered company?</a></li>
					</ul>
				</div>
			</div>
		</div> -->
	</div>
</section>

<section class="small-section bg-gray-lighter">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h2 class="section-title font-alt mb-60 mt-20">Assistance Topics</h2>
			</div>
		</div>
		<div class="row">
			<div class="row" *ngIf="assistance_records.length > 0">
				<div class="col-md-4" *ngFor="let rec of assistance_records;">
					<div class="assistance-box">
						<div class="eb-icon">
							<img src="{{rec.icon?rec.icon:'assets/images/icons/eb-icon.png'}}">
						</div>
						<div class="assistance-title font-alt">{{ rec.title }}</div>
						<ul [innerHtml]="rec.short_desc">
						</ul>
						<div class="mt-10">
							<a [routerLink]="['/seller/more-articles/'+rec.id]" class="link">See more</a>
						</div>
					</div>
				</div>
			</div>
	
			<div class="row" *ngIf="!assistance_records.length">
				<div class="col-md-12">
					<div class="assistance-box">
						Records not available.
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="page-section bg-blue">
	<div class="container text-center">
		<div class="row d-flex justify-content-center">
			<div class="col-md-5">
				<h2 class="section-title text-white font-alt mb-30 mt-20">Want to become a Buyer?</h2>
				<div class="section-text t-white op-7 mb-30">
					<p>Want to buy products with great deal? Out platform has many great deals, solves pain points of buyers that helps to buy you a great product with big discounts.</p>
				</div>
				<div class="mt-30">
					<a href="javascript:void(0);" class="btn btn-mod btn-color btn-medium btn-round ripple px-4" (click)="buyerlisting()">Benefits of Buyer</a>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="page-section bg-white">
	<div class="container text-center">
		<div class="row d-flex justify-content-center">
			<div class="col-md-12">
				<h2 class="section-title f-20 font-alt mb-20 mt-20">Can’t find the answers you’re looking for? We’re here to help.</h2>
				<div class="mt-30">
					<a [routerLink]="['/contact-us']" class="btn btn-mod btn-color btn-medium btn-round ripple px-4">Contact Us</a>
				</div>
			</div>
		</div>
	</div>
</section>

<app-footer-one></app-footer-one>