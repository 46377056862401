import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class MyListingService {

    constructor(private _http: HttpClient) { }

    productlisting(data) {
        return this._http.post(environment.api_url + 'seller/product/listing', data);
    }

    exportproductlisting(data) {
        return this._http.post(environment.api_url + 'seller/product/exportstocklisting', data);
    }

    updatestocklisting(data) {
        return this._http.post(environment.api_url + 'seller/product/inventory/import/add', data);
    }

    requestedlisting(data) {
        return this._http.post(environment.api_url + 'seller/product/requirement/list', data);
    }
    removerequestedlist(data) {
        return this._http.post(environment.api_url + 'seller/product/requirement/delete', data);
    }

    getsubsellerpendingproducts(data) {
        return this._http.post(environment.api_url + 'seller/candf/subsellerproduct/pending/list', data);
    }

    subsellerpendingreject(data) {
        return this._http.post(environment.api_url + 'seller/candf/subsellerproduct/reject', data);
    }

    subsellerpendingapprove(data) {
        return this._http.post(environment.api_url + 'seller/candf/subsellerproduct/approve', data);
    }

    subsellerapprovedproducts(data) {
        return this._http.post(environment.api_url + 'seller/candf/subsellerproduct/approved/list', data);
    }

    changeproductstatus(data) {
        return this._http.post(environment.api_url + 'seller/candf/subsellerproduct/updatestatus', data);
    }

    netrealization(data) {
        return this._http.post(environment.api_url + 'seller/candf/product/netrealization', data);
    }

    supplierlist(data) {
        return this._http.post(environment.api_url + 'seller/candf/supplierlist', data);
    }



}