import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class EditBrandService {

  constructor(private _http: HttpClient) { }

  getbrand(data) {
    return this._http.post(environment.api_url+'master/brand/edit/info',data);
  }

  updatebrand(data) {
    return this._http.post(environment.api_url+'master/brand/update',data);
  }

  getcountry(data) {
    return this._http.post(environment.api_url+'master/countries/list',data);
  }

  searchcategory(data) {
    return this._http.post(environment.api_url+'searchcategory',data);
  }

}
