<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="">
    <div class="equibiz-header">
      <div class="logo-bar">
        <div class="container">
          <div class="row">
             <div class="col-md-7">
                <a [routerLink]="['/home']"><img src="assets/images/svg/eq_logo.svg"></a>
             </div>
             <div class="col-md-5">
              <!-- <div class="text-right" *ngIf="!step_zero">
                <a href="javascript:;" class="link-alt">Know Why?</a>
              </div> -->
             </div>
          </div>
        </div>
      </div>
    </div>
    <div class="steps">
      <ul>
        <li class="current" [ngClass]="{'step-one':step_one, 'step-two':step_two, 'step-three':step_three, 'step-four':step_four, 'step-five':step_five, 'step-six':step_six, 'step-seven':step_seven}"><span>Step 1</span></li>
      </ul>
    </div>
  </div>
</header>

<section class="wizard-container step-zero" *ngIf="step_zero">
  <div class="container">
    <div class="row d-flex justify-content-center">
        <div class="col-md-10">
            <div class="mb-50"><img src="assets/images/on-boarding.svg"></div>
            <h1 class="section-title mb-10">Welcome to Equibiz</h1>
            <div class="section-text text-large">
              We recommend you to help us know more about you. &nbsp;<a href="javascript:;" class="link" placement="top" ngbTooltip="Sharing basic business information will help us serve you better">Know why?</a>
            </div>
            <div class="cta mt-40">
              <a href="javascript:;" class="btn btn-mod btn-color2 btn-medium btn-round ripple" (click)="nextTab('one')">Get Started &nbsp;<i class="arrow-right"></i></a>
            </div>
        </div>
    </div>
  </div>
</section>

<section class="wizard-container step-one" *ngIf="step_one">
  <div class="container">
    <div class="row d-flex justify-content-center">
        <div class="col-md-10">
            <h3 class="step-no">Step 1:</h3>
            <h1 class="section-title mb-20">How would you like to identify yourself?  &nbsp;<a href="javascript:;" class="link-alt fs14 f-500 md-block" placement="top" ngbTooltip="Please identify yourself as a Buyer or Seller based on your business requirement">Know why?</a></h1>
            <div class="select-user-type">
               <ul>
                <li>
                  <div class="user-type-container" [ngClass]="{'selected': userType== 1}" (click)="changeidentify(1)">
                    <div class="icon"><img src="assets/images/icons/buyer.svg"></div>
                    <h4>Buyer</h4>
                  </div>
                </li>
                <li>
                  <div class="user-type-container" [ngClass]="{'selected': userType== 2}" (click)="changeidentify(2)">
                    <div class="icon"><img src="assets/images/icons/seller.svg"></div>
                    <h4>Seller</h4>
                  </div>
                </li>
               </ul>
            </div>
        </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-5">
        <div class="cta mt-40 text-right">
            <button class="btn btn-mod btn-color2 btn-medium btn-round ripple" (click)="nextTab(userType== 2?'two':'five')" [disabled]="!userType">Continue &nbsp;<i class="arrow-right"></i></button>
            </div>
      </div>
    </div>
  </div>
</section>

<section class="wizard-container step-two" *ngIf="step_two">
   <div class="container">
      <div class="row d-flex justify-content-center">
          <div class="col-md-10">
              <h3 class="step-no">Step 2:</h3>
              <h1 class="section-title mb-20">Which business category do you belong to? &nbsp;<a href="javascript:;" class="link-alt fs14 f-500 md-block" placement="top" ngbTooltip="Now that you have identified yourself as a Seller, please select business categories to help personalize your requirements">Know why?</a></h1>
              <div class="category">
                <ul>
                  <li *ngFor="let cat of businessCategoryArray;" [ngClass]="{'selected': businessCategory==cat.value}"  (click)="changebusinesscategory(cat.value)"><span>{{cat.name}}</span></li>
                </ul>
              </div>  
          </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-md-5">
          <div class="row d-flex justify-content-between">
            <div class="col-6 col-md-5">
                <div class="cta mt-40 text-left">
                  <a href="javascript:;" class="btn btn-mod btn-border btn-medium btn-round" (click)="nextTab('one')"><i class="arrow-left"></i>&nbsp; Back</a>
                </div>
            </div>
            <div class="col-6 col-md-5">
                <div class="cta mt-40 text-right">
                  <button class="btn btn-mod btn-color2 btn-medium btn-round ripple" (click)="nextTab('three')" [disabled]="!businessCategory">Continue &nbsp;<i class="arrow-right"></i></button>
                </div>
            </div>
          </div>
      </div>
      </div>
   </div>
   <div class="text-center skip-question">
      <a href="javascript:;" class="link-2" (click)="nextTab('three')">Skip this question</a>
   </div>
</section>

<section class="wizard-container step-three" *ngIf="step_three">
   <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-md-10">
            <h3 class="step-no">Step 3:</h3>
            <h1 class="section-title mb-20">How often do you file your GST returns? &nbsp;<a href="javascript:;" class="link-alt fs14 f-500 md-block" placement="top" ngbTooltip="GST filling frequency is important information a buyer will want to know about a seller. Equibiz suggests monthly filing of GST to all e-commerce sellers to enable buyers to get GST input on time.">Know why?</a></h1>
            <div class="category">
              <ul>
                <li [ngClass]="{'selected': gstReturn=='Monthly'}"  (click)="changegstreturn('Monthly')"><span>Monthly</span></li>
                <li [ngClass]="{'selected': gstReturn=='Quarterly'}"  (click)="changegstreturn('Quarterly')"><span>Quarterly</span></li>
              </ul>
            </div>  
        </div>
      </div>
      <div class="row d-flex justify-content-center">
      <div class="col-md-5">
        <div class="row d-flex justify-content-between">
          <div class="col-6 col-md-5">
              <div class="cta mt-40 text-left">
                <a href="javascript:;" class="btn btn-mod btn-border btn-medium btn-round" (click)="nextTab('two')"><i class="arrow-left"></i>&nbsp; Back</a>
              </div>
          </div>
          <div class="col-6 col-md-5">
              <div class="cta mt-40 text-right">
                <button class="btn btn-mod btn-color2 btn-medium btn-round ripple" (click)="nextTab('four')"  [disabled]="!gstReturn">Continue &nbsp;<i class="arrow-right"></i></button>
              </div>
          </div>
        </div>
      </div>
      </div>
   </div>
   <div class="text-center skip-question">
      <a href="javascript:;" class="link-2" (click)="nextTab('four')">Skip this question</a>
   </div>
</section>

<section class="wizard-container step-four" *ngIf="step_four">
  <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-md-10">
            <h3 class="step-no">Step 4:</h3>
            <h1 class="section-title mb-20">Marketplaces require the seller paying TDS and TCS<br>on every transaction. Share your consent:  &nbsp;<a href="javascript:;" class="link-alt fs14 f-500 md-block" placement="top" ngbTooltip="To become an e-commerce seller, TDS and TCS payments are required on every sale transaction. Please read the details by downloading the extant guidelines. Equibiz suggests updating yourself from time to time by accessing the latest government guidelines.">Know why?</a></h1>
            <div class="section-text text-large">
              Download <a href="https://equibizstage.s3.ap-south-1.amazonaws.com/default/TDS.pdf" target="_blank" class="link">TDS</a> / <a href="https://equibizstage.s3.ap-south-1.amazonaws.com/default/TCS.pdf" target="_blank" class="link">TCS</a> Rate Chart (for year 2021-2022)
            </div>
            <div class="category">
              <ul>
                <li [ngClass]="{'selected': tdsType=='Accept'}"  (click)="changetdstype('Accept')"><span>Accept</span></li>
                <li [ngClass]="{'selected': tdsType=='Not Sure'}"  (click)="changetdstype('Not Sure')"><span>Not Sure</span></li>
              </ul>
            </div>  
        </div>
      </div>
      <div class="row d-flex justify-content-center">
      <div class="col-md-5">
        <div class="row d-flex justify-content-between">
          <div class="col-6 col-md-5">
              <div class="cta mt-40 text-left">
                <a href="javascript:;" class="btn btn-mod btn-border btn-medium btn-round" (click)="nextTab('three')"><i class="arrow-left"></i>&nbsp; Back</a>
              </div>
          </div>
          <div class="col-6 col-md-6">
              <div class="cta mt-40 text-right">
                <button class="btn btn-mod btn-color2 btn-medium btn-round ripple" (click)="open(signupForm)" [disabled]="!tdsType">Continue to Sign up &nbsp;<i class="arrow-right"></i></button>
              </div>
          </div>
        </div>
      </div>
      </div>
  </div>

   <div class="text-center skip-question">
      <a href="javascript:;" class="link-2" (click)="open(signupForm)" >Skip this question</a>
   </div>
</section>

<section class="wizard-container step-five" *ngIf="step_five">
   <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-md-10">
            <h3 class="step-no">Step 2:</h3>
            <h1 class="section-title mb-20">What would be your average minimum single order value? &nbsp;<a href="javascript:;" class="link-alt fs14 f-500 md-block" placement="top" ngbTooltip="Average single order value will help us understand your market fit for bulk or wholesale segments">Know why?</a></h1>
            <div class="category">
              <ul>
                <li [ngClass]="{'selected': minimunOrderValue=='Less than 25k'}"  (click)="changeminimunordervalue('Less than 25k')"><span>Less than 25k</span></li>
                <li [ngClass]="{'selected': minimunOrderValue=='Greater than 25k, Less than 100k'}"  (click)="changeminimunordervalue('Greater than 25k, Less than 100k')"><span>Greater than 25k, Less than 100k</span></li>
                <li [ngClass]="{'selected': minimunOrderValue=='Greater than 100k'}"  (click)="changeminimunordervalue('Greater than 100k')"><span>Greater than 100k</span></li>
              </ul>
            </div>  
        </div>
      </div>
      <div class="row d-flex justify-content-center">
      <div class="col-md-5">
        <div class="row d-flex justify-content-between">
          <div class="col-6 col-md-5">
              <div class="cta mt-40 text-left">
                <a href="javascript:;" class="btn btn-mod btn-border btn-medium btn-round" (click)="nextTab('one')"><i class="arrow-left"></i>&nbsp; Back</a>
              </div>
          </div>
          <div class="col-6 col-md-5">
              <div class="cta mt-40 text-right">
                <button class="btn btn-mod btn-color2 btn-medium btn-round ripple" (click)="nextTab('six')" [disabled]="!minimunOrderValue">Continue &nbsp;<i class="arrow-right"></i></button>
              </div>
          </div>
        </div>
      </div>
      </div>
   </div>

   <div class="skip-question">
      <a href="javascript:;" class="link-2" (click)="nextTab('six')">Skip this question</a>
   </div>
</section>

<section class="wizard-container step-six" *ngIf="step_six">
   <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-md-10">
            <h3 class="step-no">Step 3:</h3>
            <h1 class="section-title mb-20">Do you want to participate in the bulk segment? &nbsp;<a href="javascript:;" class="link-alt fs14 f-500 md-block" placement="top" ngbTooltip="The bulk segment helps you to buy in large quantities at a competitive price.">Know why?</a></h1>
            <p>Note: The MOQ set by the seller for this segment will be higher and accordingly the single order value</p>
            <div class="category">
              <ul>
                <li [ngClass]="{'selected': bulkSegment=='Yes'}"  (click)="changebulksegment('Yes')"><span>Yes</span></li>
                <li [ngClass]="{'selected': bulkSegment=='No'}"  (click)="changebulksegment('No')"><span>No</span></li>
              </ul>
            </div>  
        </div>
      </div>
      <div class="row d-flex justify-content-center">
      <div class="col-md-5">
        <div class="row d-flex justify-content-between">
          <div class="col-6 col-md-5">
              <div class="cta mt-40 text-left">
                <a href="javascript:;" class="btn btn-mod btn-border btn-medium btn-round" (click)="nextTab('five')"><i class="arrow-left"></i>&nbsp; Back</a>
              </div>
          </div>
          <div class="col-6 col-md-5">
              <div class="cta mt-40 text-right">
                <button class="btn btn-mod btn-color2 btn-medium btn-round ripple" (click)="nextTab('seven')" [disabled]="!bulkSegment">Continue &nbsp;<i class="arrow-right"></i></button>
              </div>
          </div>
        </div>
      </div>
      </div>
   </div>

   <div class="text-center skip-question">
      <a href="javascript:;" class="link-2" (click)="nextTab('seven')">Skip this question</a>
   </div>
</section>

<section class="wizard-container step-seven" *ngIf="step_seven">
   <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-md-10">
            <h3 class="step-no">Step 4:</h3>
            <h1 class="section-title mb-20">Which business category do you belong to?  &nbsp;<a href="javascript:;" class="link-alt fs14 f-500 md-block" placement="top" ngbTooltip="Now that you have identified yourself as a Buyer, please select business categories to help personalize your requirements">Know why?</a></h1>
            <div class="category">
              <ul>
                <ng-container *ngFor="let cat of businessCategoryArray;">
                  <li *ngIf="cat.name != 'Brand and OEM'" [ngClass]="{'selected': businessCategory==cat.value}"(click)="changebusinesscategory(cat.value)">
                    <span>{{cat.name}}</span>
                  </li>
                </ng-container>
                
              </ul>
            </div>  
        </div>
      </div>
      <div class="row d-flex justify-content-center">
      <div class="col-md-5">
        <div class="row d-flex justify-content-between">
          <div class="col-6 col-md-5">
              <div class="cta mt-40 text-left">
                <a href="javascript:;" class="btn btn-mod btn-border btn-medium btn-round" (click)="nextTab('six')"><i class="arrow-left"></i>&nbsp; Back</a>
              </div>
          </div>
          <div class="col-6 col-md-5">
              <div class="cta mt-40 text-right">
                <button class="btn btn-mod btn-color2 btn-medium btn-round ripple" (click)="open(signupForm)" [disabled]="!businessCategory">Continue &nbsp;<i class="arrow-right"></i></button>
              </div>
          </div>
        </div>
      </div>
      </div>
   </div>

   <div class="text-center skip-question">
      <a href="javascript:;" class="link-2" (click)="open(signupForm)">Skip this question</a>
   </div>
</section>

<ng-template class="theme-modal" #signupForm let-modal>
  <div class="modal-content popup-container">
    <div class="modal-body">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title">Personal Details</h5>
            <div class="form-container">
               <div class="floating-label mb-0">      
                  <input class="form-control" type="first_name" placeholder="" name="firstName" [(ngModel)] = "firstName">
                  <span class="highlight"></span>
                  <label>First Name</label>
               </div>
               <div class="floating-label mt-20 mb-0">      
                  <input class="form-control" type="last_name" placeholder="" name="lastName" [(ngModel)] = "lastName">
                  <span class="highlight"></span>
                  <label>Last Name</label>
               </div>
               <div class="floating-label mt-20 mb-0">      
                  <input class="form-control" type="phone_number" placeholder="" name="phoneNumber" [(ngModel)] = "phoneNumber" [readonly]="signupType == 'mobile'">
                  <span class="highlight"></span>
                  <label>Phone Number</label>
               </div>
               <div class="floating-label mt-20 mb-0">      
                  <input class="form-control" type="email_address" placeholder="" name="emailAddress" [(ngModel)] = "emailAddress" [readonly]="signupType == 'email'">
                  <span class="highlight"></span>
                  <label>Email Address</label>
               </div>
            </div>   

            <div class="cta mt-40">
                <button class="btn btn-mod btn-color btn-medium btn-round ripple btn-block" (click)="registration()" [disabled]="!firstName || !lastName || !emailAddress || !phoneNumber || buttonLoading"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="buttonLoading"></span>&nbsp;&nbsp;Submit</button>
            </div>

          
      </div>
  </div>
</ng-template>