import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
// import * as moment from 'moment';


//Credit Card Form
import { CreditCardValidators } from 'angular-cc-library';

//Chart - start
import {
    ChartComponent,
    ApexAxisChartSeries,
    ApexChart,
    ApexXAxis,
    ApexDataLabels,
    ApexTitleSubtitle,
    ApexStroke,
    ApexGrid
} from "ng-apexcharts";


export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    dataLabels: ApexDataLabels;
    grid: ApexGrid;
    stroke: ApexStroke;
    title: ApexTitleSubtitle;
};

//Chart - end

@Component({
    selector: 'app-testing',
    templateUrl: './testing.component.html',
    styleUrls: ['./testing.component.scss']
})
export class TestingComponent implements OnInit {

    // readonly DT_FORMAT = 'MM/DD/YYYY';

    // parse(value: string): NgbDateStruct {
    //     if (value) {
    //         value = value.trim();
    //         let mdt = moment(value, this.DT_FORMAT)
    //     }
    //     return null;
    // }
    // format(date: NgbDateStruct): string {
    //     if (!date) return '';
    //     let mdt = moment([date.year, date.month - 1, date.day]);
    //     if (!mdt.isValid()) return '';
    //     return mdt.format(this.DT_FORMAT);
    // }

    public htmlContent:string;

    //Credit Card Form
    creditCardForm: FormGroup;
    submitted: boolean = false;


    //chart - start
    @ViewChild("chart") chart: ChartComponent;
    public chartOptions: any = {};
    //chart - end

    form: FormGroup;
    validationForm: FormGroup;

    name = 'Angular';
    datepickerGroup: FormGroup;
    //formGroup = new FormGroup({});

    constructor(
        private fb: FormBuilder
    ) {
        //Chart - start
        this.chartOptions = {
            series: [
                {
                    name: "Desktops",
                    data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
                }
            ],
            chart: {
                height: 350,
                type: "line",
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: "straight"
            },
            title: {
                text: "Product Trends by Month",
                align: "left"
            },
            grid: {
                row: {
                    colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0.5
                }
            },
            xaxis: {
                categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep"
                ]
            }
        };
        //Chart - end
    }



    ngOnInit() {

        this.creditCardForm = this.fb.group({
            creditCard: ['', [CreditCardValidators.validateCCNumber]],
            expirationDate: ['', [CreditCardValidators.validateExpDate]],
            cvc: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(3)]]
        });

        this.invokeStripe();

        this.form = this.fb.group({
            fullName: this.fb.control(null, this.emailValidators),
            items: this.fb.array([this.createItem()]),
        });

        //Phone Form
        this.datepickerGroup = this.fb.group({
            gstin: this.fb.control(null, []),
            last_gst_date: this.fb.control(null)
        });

        this.datepickerGroup.get('last_gst_date').setValue({
            year: 2022,
            month: 4,
            day: 20
        });

        // this.datepickerGroup.addControl("last_gst_date", new FormControl({day: 20, month:4, year:2022})) //nice
        // this.datepickerGroup.valueChanges.subscribe(val => {
        // })

        //required if validation
        this.validationForm = this.fb.group({
            myCheckbox: [''],
            myEmailField: ['', [
                Validators.maxLength(250),
                Validators.minLength(5),
                Validators.pattern(/.+@.+\..+/)
            ]]
        });
    }

    cardsubmit(form) {
        this.submitted = true;
    }

    submitForm() {
    }

    onSubmit() {
        const invalid = [];
        const controls = this.validationForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        return invalid;
    }

    private emailValidators = [
        Validators.maxLength(250),
        Validators.minLength(5),
        Validators.pattern(/.+@.+\..+/)
    ];

    createItem() {
        return this.fb.group({
            name: 'Jon',
            surname: 'Doe',
        });
    }

    addNext() {
        (this.form.controls['items'] as FormArray).push(this.createItem());
    }

    submit() {
    }

    Movies = [
        'Blade Runner',
        'Cool Hand Luke',
        'Heat',
        'Juice',
        'The Far Side of the World',
        'Morituri',
        'Napoleon Dynamite',
        'Pulp Fiction'
    ];
    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.Movies, event.previousIndex, event.currentIndex);
    }

    change() {
        var res = this.validationForm.get('myCheckbox').value;
        if (res) {
            this.validationForm.get('myEmailField').setValidators(Validators.required);
        } else {
            this.validationForm.get('myEmailField').clearValidators();
        }
    }

    makepayment() {
        let parent = document.getElementById('drop_in_container');
        parent.innerHTML = '';
        let cashfree = new (<any>window).Cashfree();
        cashfree.initialiseDropin(parent, {
            orderToken: 'S1vdovvFtUKvhW21Gqr3',
            onSuccess: function (data) {
                if (data.order && data.order.status == 'PAID') {
                    alert('order is paid. Call api to verify');
                    //data.order.orderId
                    //data.order.status
                    //data.transaction.transactionId
                    //data.transaction.txStatus
                }
            },
            onFailure: function (data) {
                alert(data.order.errorText);
            },
            components: ['card', 'upi', 'app', 'netbanking'], //'order-details',
            style: {
                backgroundColor: '#ffffff',
                color: '#11385b',
                fontFamily: 'Lato',
                fontSize: '14px',
                errorColor: '#ff0000',
                theme: 'light', //(or dark)
            },
        });
    }

    invokeStripe() {
        if (!window.document.getElementById('stripe-script')) {
            const script = window.document.createElement('script');
            script.id = 'stripe-script';
            script.type = 'text/javascript';
            script.src =
                'https://sdk.cashfree.com/js/ui/1.0.26/dropinClient.sandbox.js';
            script.onload = () => { };
            window.document.body.appendChild(script);
        }
    }

}
