import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { ViewcandfRequestService } from './view-candf-requests.service';

@Component({
    selector: 'app-view-canf-requests',
    templateUrl: './view-canf-requests.component.html',
    styleUrls: ['./view-canf-requests.component.scss']
})
export class ViewCanfRequestsComponent implements OnInit {

    closeResult = '';
    public userId = '';
    public personal_detail: any = {};
    public business_detail: any = {};
    public bank_detail: any = {};
    public reject_reason = '';
    public reject_message = '';

    public reject_reason_list = [
        {
            label: 'Improper Documents',
            value: 'Improper Documents',
        },
        {
            label: 'Address Verification pending',
            value: 'Address Verification pending',
        },
        {
            label: 'Aadhar number mismatch',
            value: 'Aadhar number mismatch',
        },
        {
            label: 'Incorrect PAN number',
            value: 'Incorrect PAN number',
        },
        {
            label: 'Aadhar not Linked with Account',
            value: 'Aadhar not Linked with Account',
        },
        {
            label: 'Missing Authorized Person',
            value: 'Missing Authorized Person',
        },
        {
            label: 'Other',
            value: 'Other',
        },
    ];

    constructor(
        private modalService: NgbModal,
        private _viewcandfreqeust: ViewcandfRequestService,
        private _toastr: ToasterService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.userId = this.route.snapshot.paramMap.get('id');
        this.getdetails();
    }

    getdetails() {
        this._viewcandfreqeust.getdetails({ id: this.userId }).subscribe((data) => {
            if (data['status'] == "success") {
                this.bank_detail = data['records']['bank_detail'];
                this.business_detail = data['records']['business_detail'];
                this.personal_detail = data['records']['personal_detail'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true, }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    adminstatus(type) {
        if (type == 'reject') {
            if (!this.reject_reason) {
                this._toastr.error('Please select reject reason.');
                return false;
            }
            if (!this.reject_message) {
                this._toastr.error('Please enter reject message');
                return false;
            }
        }
        this._viewcandfreqeust.changestatus({ seller_id: this.userId, type: type, reject_reason: this.reject_reason, reject_message: this.reject_message }).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                if (type == 'reject') {
                    this._toastr.success('Seller has been rejected successfully.');
                    this.router.navigate(['/admin/managecandf']);
                } else if (type == 'approve') {
                    this.getdetails();
                    this._toastr.success('Seller has been approved successfully.');
                }  else if (type == 'inactive') {
                    this.getdetails();
                    this.router.navigate(['/admin/managecandf']);
                    this._toastr.success('Seller has been removed successfully.');
                }

            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

}