<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<h3 class="fs24 wt700 txtdefault">Manage Product Categories</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);" [routerLink]="['/admin/product-listing']">Product Management</a>
						</li>
						<li class="breadcrumb-item active">Manage Product Categories</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<div class="d-flex align-items-center justify-content-end">
					<a href="javascript:;" [routerLink]="['/admin/product-category/add']" class="mr-sm-2 btn-d-outline d-inline-block"><svg xmlns="http://www.w3.org/2000/svg" width="14.681" height="14.681" viewBox="0 0 14.681 14.681" class="plus">
						  <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1 1)">
						    <path id="Path_8" data-name="Path 8" d="M18,7.5V20.181" transform="translate(-11.659 -7.5)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
						    <path id="Path_9" data-name="Path 9" d="M7.5,18H20.181" transform="translate(-7.5 -11.659)" fill="none" stroke="#ff7135" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
						  </g>
						</svg>
						Add New Category
					</a>
					<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ">Assistance</a>
				</div>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card card-ui card-uiovt byregwrp mb-40">
			<!-- <div class="mgb-head">
				<div class="row mb-4 pb-2 align-items-center">
					<div class="col-xl-6 col-lg-5 col-md-7">
						<div class="srch-ipt srch50">
							<img src="assets/images/svg/search_logo.svg">
							<input placeholder="Search by Order ID or Product Name" type="text" class="form-control">
						</div>
					</div>	
					<div class="col-xl-6 col-lg-4 col-md-5">
						<div class="tpbr">
							<label class="fs12 txtseconday tpbrlbl">Bulk Actions</label>
							<ng-selectize [config]="config" [options] = "data" placeholder="" >
							</ng-selectize>	
						</div>
					</div>	
				</div>
				<a href="javascript:void(0);" class="d-inline-block fltr-round-btn radius10 txtseconday">
					<img src="assets/images/svg/filter.svg">
					Filters
				</a>
			</div> -->
			<div class="table-responsive">
			   <table class="table custom-table xs2 category-table">
			      <thead>
			         <tr>
			            <th width="40%">
			               <div class="d-flex align-items-center">
			                  <div class="custom-check">
			                     <div class="css-check">
			                        <input class="css-checkbx" type="checkbox" />
			                        <label class="css-label">
			                        &nbsp;
			                        </label>
			                     </div>
			                  </div>
			                  <div>Category Name</div>
			               </div>
			            </th>
			            <th>Sub-Categories</th>
			            <th>Status</th>
			            <th>Actions</th>
			            <th>&nbsp;</th>
			         </tr>
			      </thead>
			      <tbody>
					
			        <!-- REPEAT -->
			        <ng-container [ngTemplateOutlet]="trBlock"  *ngFor="let category of categories" [ngTemplateOutletContext] = "{ records: category, key: 1}" >
				        
			    	</ng-container>

			        <!-- <app-category-table [cat]="category" [key]="1"></app-category-table> -->

			       
			      </tbody>
			   </table>
			</div>
		</div>
	</div>
</div>

<ng-template #trBlock let-records='records' let-key='key'>
	<!-- <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)"> -->
		<tr class="level-{{key}}" *ngIf="!records.parent || records.show">
            <td>
               <div class="d-flex align-items-center">
                  <!--reorder-->
                  <div class="reorder" cdkDrag>
                     <img src="assets/images/icons/reorder.png">
                  </div>
                  <!--checkbox-->
                  <div class="css-check">
                     <input class="css-checkbx" type="checkbox" />
                     <label class="css-label">
                     &nbsp;
                     </label>
                  </div>
                  <!--category name-->
                  <div class="catg">
					  <a href="javascript:void(0);" *ngIf="records.status == 0 || records.status == 1"><img src="{{ records.preview }}">{{ records.name }}</a>
					  <a href="javascript:void(0);" *ngIf="records.status == 2"><img src="{{ records.preview }}">Uncategorized</a>
					</div>
               </div>
            </td>
            <td>
               <span class="subcount">{{ records.ifchild? records.child.length: '-' }}</span> &nbsp;
               <i placement="top" ngbTooltip="This will act as a default filter every time you will toggle." *ngIf="records.ifchild">
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                     <g id="Group_68" data-name="Group 68" transform="translate(-545 -281)">
                        <circle id="Ellipse_1" data-name="Ellipse 1" cx="7.5" cy="7.5" r="7.5" transform="translate(545 281)" fill="#07f"/>
                        <path id="Path_2235" data-name="Path 2235" d="M7,8.171l-.128.523q-.576.227-.919.346a2.425,2.425,0,0,1-.8.119,1.591,1.591,0,0,1-1.084-.34,1.1,1.1,0,0,1-.387-.864,3.056,3.056,0,0,1,.029-.416q.029-.213.093-.48l.48-1.7q.064-.244.108-.462a2.01,2.01,0,0,0,.044-.4A.616.616,0,0,0,4.3,4.047a.759.759,0,0,0-.513-.128,1.345,1.345,0,0,0-.381.058c-.131.039-.242.076-.336.11L3.2,3.564q.471-.192.9-.329A2.7,2.7,0,0,1,4.916,3.1a1.552,1.552,0,0,1,1.068.335,1.111,1.111,0,0,1,.375.869c0,.074-.008.2-.026.39a2.606,2.606,0,0,1-.1.512L5.759,6.9a4.492,4.492,0,0,0-.1.466,2.4,2.4,0,0,0-.047.4.57.57,0,0,0,.15.459.85.85,0,0,0,.523.122,1.509,1.509,0,0,0,.4-.061A2.21,2.21,0,0,0,7,8.171Zm.121-7.1a.99.99,0,0,1-.334.753,1.138,1.138,0,0,1-.8.312,1.15,1.15,0,0,1-.809-.312.988.988,0,0,1-.337-.753A1,1,0,0,1,5.169.314a1.194,1.194,0,0,1,1.614,0A1,1,0,0,1,7.117,1.07Z" transform="translate(547.406 283.92)" fill="#fff"/>
                     </g>
                  </svg>
               </i>
            </td>
            <td>
               <div class="d-flex align-items-center justify-container-start">
                  <div class="status">{{ records.status == 1? 'Active': "Inactive"}}</div>
                  <div class="tglbtn">
                     <label class="switch">
                     <input type="checkbox" [checked]="records.status == 1" (change)="updatecategorystatus(records.c_id, 'status')">
                     <span class="slider round"></span>
                     </label>
                  </div>
               </div>
            </td>
            <td>
               <a href="javascript:;" *ngIf="!records.parent" [routerLink]="['/admin/product-category/edit-category', records.c_id]"><img src="assets/images/icons/edit-big.png"></a>
               <a href="javascript:;" *ngIf="records.parent" [routerLink]="['/admin/product-category/edit-sub-category', records.c_id]"><img src="assets/images/icons/edit-big.png"></a>
               {{ !records.ifchild &&  (records.status == 0 || records.status == 1) ? '|':'' }}
               <a href="javascript:;" *ngIf="!records.ifchild && (records.status == 0 || records.status == 1)" (click)="open(content, records.name, records.parent_name, records.c_id)"><img src="assets/images/icons/delete-icon.svg"></a>
            </td>
            <td>
            	<a href="javascript:;" style="cursor: pointer;">
	               	<svg xmlns="http://www.w3.org/2000/svg" width="12.849" height="7.351" viewBox="0 0 12.849 7.351"  (click)="showchild(records, records.child)"  *ngIf="records.ifchild && !records.open">
	                  <path id="Path_2277" data-name="Path 2277" d="M1.154,12.15A.676.676,0,0,1,.2,11.194l5.02-5.02L.2,1.154A.676.676,0,0,1,1.154.2l5.5,5.5a.675.675,0,0,1,0,.956Z" transform="translate(0.25 7.101) rotate(-90)" fill="#1f1d44" stroke="#1f1d44" stroke-width="0.5"/>
	               	</svg>
               	</a>
               	<a href="javascript:;" style="cursor: pointer;">
	               	<svg xmlns="http://www.w3.org/2000/svg" width="12.849" height="7.351" viewBox="0 0 12.849 7.351" (click)="showchild(records, records.child)"  *ngIf="records.ifchild && records.open">
					  <path id="Path_2277" data-name="Path 2277" d="M41.011,23.856a.676.676,0,1,0-.956.956l5.02,5.02-5.02,5.02a.676.676,0,0,0,.956.956l5.5-5.5a.675.675,0,0,0,0-.956Z" transform="translate(36.256 -39.606) rotate(90)" fill="#1f1d44" stroke="#1f1d44" stroke-width="0.5"/>
					</svg>
				</a>
            </td>
        </tr>
	<!-- </div> -->
        <ng-container  *ngIf="records.ifchild">
        <ng-container [ngTemplateOutlet]="trBlock" *ngFor="let category of records.child" [ngTemplateOutletContext] = "{ records: category, key:key+1}">
				        
			    	</ng-container>
			    </ng-container>
</ng-template>


<!-- Remove Product -->
<ng-template #content let-modal>
	<div class="modal-body mdlcmnui pt-4 mt-3">
        <h5 class="modal-title text-center mb-20">Remove Category</h5>

        <div class="bid-order-info">
			<div class="order-img">
				<img src="assets/images/icons/info.png" />
			</div>
			<div class="pd-seller-cntnt">
				<div class="fs12">Removing the category will affect across all the buyers and sellers. The buyer and seller won’t able to see the category. All the products under this category will fall into “Uncategorized” category.</div>
			</div>
		</div>

		<div class="modal-subtitle text-center pt-30 pb-30">Are you sure you want to remove the {{ name }} Category?</div>

        <div class="bid-order-info">
			<div class="pd-seller-cntnt">
				<h5 class="mb-10 fs16 text-center">It will get removed from {{ parent_name }} category.</h5>
			</div>
		</div>

        <div class="text-center pt-30 pb-20">
           <button type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')" class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4">
		      Cancel
		   	</button>
		   	<button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4" (click)="updatecategorystatus(remove_id, 'remove')">
		      Remove
		   	</button>
        </div>
      </div>
</ng-template>