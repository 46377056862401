import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { UnclearBalanceService } from './unclear-balance.service';

@Component({
  selector: 'app-unclear-balance',
  templateUrl: './unclear-balance.component.html',
  styleUrls: ['./unclear-balance.component.scss']
})
export class UnclearBalanceComponent implements OnInit {

    closeResult = '';
    public export_path:string = '';
    public export_loading:boolean = false;
    public search_term:string = '';
    public buttonLoading:boolean = false;
    public selected_record:any = {};
    public unclear_records = [];
  	constructor(
        private _unclearbalance: UnclearBalanceService,
        private _toastr: ToasterService,
        private modalService: NgbModal
    ) {}
    ngOnInit(): void {
        this.getunclearbalance();
    }

    open(content, record) {
        this.selected_record = record;
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'mblbtm' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    getunclearbalance(download='') {
        this._unclearbalance.getunclearbalance({search: this.search_term, download: download}).subscribe((data) => {
            if(data['status'] == "success"){
                if(data['download']){
                    this.export_path = data['path'];
                    this.export_loading = false;
                }else{
                    this.unclear_records = data['records'];
                }
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

    approveunclearbalance(){
        this.buttonLoading = true;
        this._unclearbalance.approveunclear({ wallet_id: this.selected_record.id, order_no: this.selected_record.order_no }).subscribe((data) => {
            if(data['status'] == "success"){
                this.modalService.dismissAll();
                this._toastr.success('Unclear amount has been approved successfully.');
                this.getunclearbalance();
                this.buttonLoading = false;
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
                this.buttonLoading = false;
            }
        }, (error) => {
            this.buttonLoading = false;
        });
    }

}
