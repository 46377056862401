import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SignupService } from "../../../shared/components/modal/signup-modal/signup-modal.service";

@Component({
  selector: 'app-sidebar-buyer',
  templateUrl: './sidebar-buyer.component.html',
  styleUrls: ['./sidebar-buyer.component.scss']
})
export class SidebarBuyerComponent implements OnInit {

    constructor(
        public _signupservice: SignupService,
        public router: Router
    ) { }

    public user_token:string;
    public user_reg_status:string;
    public user_status:string;
    public usertype: any;
    public token: string;

    showside:boolean = false
    ngOnInit(): void {
        this.user_token = this.token = this._signupservice.gettoken();
        this.usertype = this._signupservice.getusertype();
        this.user_status = this._signupservice.getuserstatus();
        this._signupservice.tokenChanged.subscribe((response: string) => {
            this.token = response;
            this.user_token = response;
        });
        this._signupservice.userRegStatusChanged.subscribe((response: string) => {
            this.user_reg_status = response;
        });
        this._signupservice.userStatusChanged.subscribe((response: string) => {
            this.user_status = response;
        });

        if(this.user_reg_status == 'no' || this.user_status == 'no'){
            this.router.navigate(['/home']);
        }
    }
    showsidebar(){
    this.showside= !this.showside
    }

    logout(){
	    this._signupservice.logout();
	    window.location.href="/home";
	}

}
