import { Component, OnInit } from '@angular/core';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { OrderQualityCheckService } from './order-quality-check.service';

@Component({
  selector: 'app-order-quality-check',
  templateUrl: './order-quality-check.component.html',
  styleUrls: ['./order-quality-check.component.scss']
})
export class OrderQualityCheckComponent implements OnInit {

	public search_term:string = '';
    public from_date:any = '';
    public to_date:any = '';
  	constructor(
	  	private _orderqcservice: OrderQualityCheckService,
	  	private _toastr: ToasterService,
	  	private _pagination: PaginationService
  	) { }

  	public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 20;
    public pending_pagination: any = {};
	public pending_records:any = [];
	
	public co_search_term:string = '';
    public co_from_date:any = '';
    public co_to_date:any = '';
	public co_current_page: number = 1;
    public co_offset: number = 0;
    public co_page_per_view: number = 20;
    public completed_pagination: any = {};
	public completed_records:any = [];

	
    public page_per_view_options = [
        {
            value: 20,
            name: 20
        },
        {
            value: 40,
            name: 40
        }
    ];

  ngOnInit(): void {
	  this.getpendingqc();
	  this.getcompletedqc();
  }

  	setpage(page) {
		this.current_page = page;
		this.offset = (page - 1) * this.page_per_view;
		this.pendingqc();
	}

	setpageperview() {
		this.current_page = 1;
		this.pendingqc();
	}

	getpendingqc() {
		this.page_per_view = 10;
		this.offset = 0;
		this.current_page = 1;
		this.pendingqc();
	}

	pendingqc() {
		let data = { 
			limit: this.page_per_view,
			offset: this.offset,
			current_page: this.current_page,
			from_date: this.from_date,
			to_date: this.to_date,
			search: this.search_term
		};
		this._orderqcservice.pendingqc(data).subscribe((data) => {
			if (data['status'] == "success") {
				this.pending_records = data['records'];
				let total_records = data['total_records'];
				this.pending_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
			} else if (data['status'] == "error") {
				this._toastr.error(data['message']);
			}
		});
	}

	setcopage(page) {
		this.co_current_page = page;
		this.co_offset = (page - 1) * this.page_per_view;
		this.completedqc();
	}

	setcopageperview() {
		this.co_current_page = 1;
		this.completedqc();
	}

	getcompletedqc() {
		this.page_per_view = 10;
		this.co_offset = 0;
		this.co_current_page = 1;
		this.completedqc();
	}

	completedqc() {
		let data = { 
			limit: this.page_per_view,
			offset: this.offset,
			current_page: this.current_page,
			from_date: this.co_from_date,
			to_date: this.co_to_date,
			search: this.co_search_term
		};
		this._orderqcservice.completedqc(data).subscribe((data) => {
			if (data['status'] == "success") {
				this.completed_records = data['records'];
				let total_records = data['total_records'];
				this.completed_pagination = this._pagination.getPager(total_records, this.co_current_page, this.page_per_view);
			} else if (data['status'] == "error") {
				this._toastr.error(data['message']);
			}
		});
	}

}
