import { Component, OnInit, Input, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ViewProductDetailsService } from './view-product.service';
import { ToasterService } from '../../helpers/toastr.service';
import { PreviewComponent } from 'src/app/shared/components/modal/preview/preview.component';
import { PaginationService } from '../../helpers/pagination.service';

@Component({
    selector: 'app-view-product-details',
    templateUrl: './view-product-details.component.html',
    styleUrls: ['./view-product-details.component.scss']
})
export class ViewProductDetailsComponent implements OnInit {

    public uniqueId: string;
    public product_info: any = {};

    @ViewChild("preview") Preview: PreviewComponent;


    closeResult = '';
    public search_term:string = '';
    product_status = false;
    constructor(
        private modalService: NgbModal,
        private route: ActivatedRoute,
        public router: Router,
        private _viewproductdetails: ViewProductDetailsService,
        private _toastr: ToasterService,
        private _pagination: PaginationService,
    ) { }

    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 10;
    public seller_listing_pagination: any = {};
    public sellerListing: any = [];

    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];

    ngOnInit(): void {
        this.uniqueId = this.route.snapshot.paramMap.get('id');
        setTimeout(() => {
            this.getdetails();
            this.getsellerlisting();
        });
    }

    getsellerlisting() {
        let limit = this.page_per_view;
        let offset = this.offset;
        let current_page = this.current_page;
        this._viewproductdetails.getsellerlisting({ unique_id: this.uniqueId, limit: limit, offset: offset, current_page: current_page, search: this.search_term }).subscribe((data) => {
            if (data['status'] == "success") {
                let total_records = data['total_records'];
                this.seller_listing_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
                this.sellerListing = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.getsellerlisting();
    }

    setpageperview() {
        this.current_page = 1;
        this.getsellerlisting();
    }

    getdetails() {
        this._viewproductdetails.getdetail({ uniqueId: this.uniqueId }).subscribe((data) => {
            if (data['status'] == "success") {
                this.product_info = data['product_info'];
                if (this.product_info.status == 1) {
                    this.product_status = true;
                } else {
                    this.product_status = false;
                }
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    openpreview() {
        this.Preview.openPreviewModal(this.product_info.uniqueId, this.product_info.product_id);
    }

    open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true, }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    openeditcategory(editcategory) {
        this.modalService.open(editcategory, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    keyword = 'name';

    changestatus(type) {
        this._viewproductdetails.templatestatus({ uniqueId: this.uniqueId, type: type }).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                if (type == 'remove') {
                    this._toastr.success('Product template has been removed from listing.');
                    this.router.navigate(['/admin/product-listing']);
                } else {
                    this._toastr.success('Product template status has been changed.');
                }
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    result = [];

    selectEvent(item) {
        this._viewproductdetails.updatecategory({ uniqueId: this.uniqueId, c_id: item.c_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this._toastr.success('Product template category has been changed.');
                this.getdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    managecategory() {
        this.modalService.dismissAll();
        this.router.navigate(['/admin/manage-product-categories']);
    }

    onChangeSearch(val: string) {
        this._viewproductdetails.searchcategory({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.result = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    onFocused(e) {
        // do something when input is focused
    }
}
