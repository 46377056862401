import { Component, OnInit } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { ManageSellerService } from './manage-seller.service';
import { EncrDecrService } from '../../helpers/EncrDecrService';
import { PaginationService } from '../../helpers/pagination.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-manage-seller',
    templateUrl: './manage-seller.component.html',
    styleUrls: ['./manage-seller.component.scss']
})
export class ManageSellerComponent implements OnInit {

    classstatus: boolean = false;
    public search_term:string = '';
    public un_search_term:string = '';
    public un_status:string = '';
    public un_export_path:string = '';
    public export_path:string = '';
    public export_loading:boolean = false;
    public un_export_loading:boolean = false;
    constructor(
        private _managersellerservice: ManageSellerService,
        private _EncrDecr: EncrDecrService,
        private _pagination: PaginationService,
        private _toastr: ToastrService,
        private _http: HttpClient
    ) { }


    minValue: number = 0;
    maxValue: number = 10000;
    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 10;
    public manage_seller_pagination: any = {};
    public manage_seller_records: any[];

    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];

    public registration_status = [
        {
            name: 'Select Registration Completed Status',
            value: '',
        },
        {
            name: 'Singup Completed',
            value: 0,
        },
        {
            name: 'Business Details',
            value: 1,
        },
        {
            name: 'Authorized Persons',
            value: 2,
        },
        {
            name: 'Bank Details',
            value: 3,
        },
    ]

    //Seller Registered Records
    public sr_current_page: number = 1;
    public sr_offset: number = 0;
    public sr_page_per_view: number = 10;
    public registered_seller_pagination: any = {};
    public registered_seller_records: any[];



    options: Options = {
        floor: 0,
        ceil: 10000
    };


    ngOnInit() {
        this.getmanageseller();
        this.getregisteredseller();
    }


    getmanageseller(download='') {
        this.export_path = '';
        let limit = this.page_per_view;
        let offset = this.offset;
        let current_page = this.current_page;
        if(download == 'yes'){
            this.export_loading = true;
        }
        this._managersellerservice.manageseller({ limit: limit, offset: offset, current_page: current_page, search: this.search_term, download: download }).subscribe((data) => {
            if (data['status'] == "success") {
                if(data['download']){
                    this.export_path = data['path'];
                    this.export_loading = false;
                }else{
                    let total_records = data['total_records'];
                    let pages = Math.ceil(total_records / this.page_per_view);
                    this.manage_seller_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
                    this.manage_seller_records = data['records'];
                }
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getregisteredseller(download='') {
        this.un_export_path = '';
        this.un_export_loading = false;
        let limit = this.sr_page_per_view;
        let offset = this.sr_offset;
        let current_page = this.sr_current_page;
        if(download == 'yes'){
            this.un_export_loading = true;
        }
        this._managersellerservice.registeredseller({ limit: limit, offset: offset, current_page: current_page, search: this.un_search_term, status: this.un_status, download: download }).subscribe((data) => {
            if (data['status'] == "success") {
                if(data['download']){
                    this.un_export_path = data['path'];
                    this.un_export_loading = false;
                }else{
                    let total_records = data['total_records'];
                    let pages = Math.ceil(total_records / this.sr_page_per_view);
                    this.registered_seller_pagination = this._pagination.getPager(total_records, this.sr_current_page, this.sr_page_per_view);
                    this.registered_seller_records = data['records'];
                }
                
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    updatestatus(record) {
        this._managersellerservice.updatestatus({type:'seller', id: record.userid}).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Seller status has been changed successfully.')
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }



    tglclass() {
        this.classstatus = !this.classstatus;
    }
    public val = null;
    config = {
        labelField: 'label',
        valueField: 'value',

    };

    data = [
        {
            label: 'B2B Player',
            value: 'B2B Player'
        },
        {
            label: 'B2B Player 2',
            value: 'B2B Player 2'
        },
        {
            label: 'B2B Player 3',
            value: 'B2B Player 3'
        },
        {
            label: 'B2B Player 4',
            value: 'B2B Player 4'
        }
    ]

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.getmanageseller();
    }

    setpageperview() {
        this.current_page = 1;
        this.getmanageseller();
    }

    setsrpage(page) {
        this.sr_current_page = page;
        this.sr_offset = (page - 1) * this.sr_page_per_view;
        this.getregisteredseller();
    }

    setsrpageperview() {
        this.sr_current_page = 1;
        this.getregisteredseller();
    }



    ngAfterViewChecked() {
    }

    public changed() {
    }
    ratingst: boolean = false;
    ratingsort() {
        this.ratingst = !this.ratingst;
    }
}
