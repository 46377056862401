import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class WriteReviewService {

    constructor(private _http: HttpClient) { }

    getrevieworders(data) {
        return this._http.post(environment.api_url+'buyer/review/pending',data);
    }

    submitreview(data) {
        return this._http.post(environment.api_url+'buyer/review/update',data);
    }

    getmyreviews(data) {
        return this._http.post(environment.api_url+'buyer/review/completed',data);
    }

}
