import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class AddListingService {

    constructor(private _http: HttpClient) { }

    productsearch(data) {
        return this._http.post(environment.api_url + 'seller/searchproduct/autocomplete', data);
    }

    productdetails(data) {
        return this._http.post(environment.api_url + 'searchproduct/details', data);
    }

    productadd(data) {
        return this._http.post(environment.api_url + 'seller/product/add', data);
    }
}