<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain tpslgbx pb-4">
	<div class="container ">
		<div class="row align-items-center">
			<div class="col-md-6 ">
				<!-- BREADCRUMB -->
				<nav class=" pt-2 pb-2" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
				  <ol class="breadcrumb p-0 bg-transparent">
					<li class="breadcrumb-item"><a href="javascript:void(0);" class="txtseconday">Order Management  </a></li>		
					<li class="breadcrumb-item"><a href="javascript:void(0);" class="txtseconday"> Orders </a></li>	
					<li class="breadcrumb-item active" aria-current="page">Details</li>
				  </ol>		
				</nav>
				<!-- /BREADCRUMB -->
			</div>
			<div class="col-md-6 mb-4 text-right">
				<a href="javascript:void(0);" (click)="openWindowCustomClass(content2)" class="btn px-4 buybtn fs16 radius10 ml-sm-3 mr-2">Assistance</a>
                <a href="javascript:;" class="btn-d-outline d-inline-block" *ngIf="detail.delivered_status == 1 && detail.return_request"  (click)="openWindowReturnRequest(returnrequest)">
                    <img src="/assets/images/icons/np_return.svg">View Request Return
                </a>
			</div>
		</div>
		<div class="row align-items-center adprotop">
			<div class="col-md-12 mb-3">
				<h4 class="wt700 mb-0 txtdefault fs22">
				<a href="javascript:void();" class="go-back"><i class="fa fa-angle-left"></i></a>
				Order Details</h4>
			</div>
		</div>
		<!-- CONTENT -->
		<div class="row"> 
			<div class="col-md-9 mb-4">
				<!-- CARD -->
				<div class="card card-ui mb-4">
					<div class="row mb-4 align-items-center">
						<div class="col-md-7">
							<div>
								<label class="fs16 txtdefault align-middle wt700 mb-0 mr-2">Order ID {{ detail.order_no }}</label>
                                <br/>
                                <label class="fs16 txtdefault align-middle wt700 mb-0 mr-2">Transaction ID {{ detail.transaction_id }}</label>
								<span class="badge-d-outline odbdge fs10">
									{{ detail.map_type }}
								</span>
								<!-- <p class="fs12 txtseconday pt-2 mb-0">Wallet Payment on 27/08/2021, 06:20:25 pm </p> -->
							</div>
						</div>
						<div class="col-md-5 text-right">
							<a href="javascript:void(0);" (click)="updatestatus('dispatched')" class="btn px-3 buybtn fs14 radius10" *ngIf="(detail.invoice_status && detail.shipping_label_status && detail.eway_bill_status && detail.manifest_status) && !detail.dispatched_status">Dispatched</a>
							<a href="javascript:void(0);" (click)="updatestatus('delivered')" class="btn px-3 buybtn fs14 radius10" *ngIf="detail.dispatched_status && !detail.delivered_status">Delivered</a>
							<!-- <a href="javascript:void(0);" (click)="openWindowCustomClass(content)" class="btn px-3 buybtn fs14 radius10">Verify Quality Check</a> -->
						</div>
					</div>
					<!-- ORDER TOP -->
					<div class="orderdscp nobd">
						<div class="row">
							<div class="col-sm-6">
								<div class="row">
									<div class="col-sm-7 mb-4">
										<label class="txtdefault mb-1 f-700">Order ID:</label>
										<p class="txtseconday mb-0">{{ detail.order_no }}</p>
									</div>
									<div class="col-sm-5 mb-4">
										<label class="txtdefault mb-1 f-700">Quantity:</label>
										<p class="txtseconday mb-0">{{ detail.qty }}</p>
									</div>
									<div class="col-sm-7 mb-4">
										<label class="txtdefault mb-1 f-700">Order Date:</label>
										<p class="txtseconday mb-0">{{ detail.order_date }}</p>
									</div>
									<div class="col-sm-5 mb-4">
										<label class="txtdefault mb-1 f-700">Prebooked on:</label>
										<p class="txtseconday mb-0">{{ detail.pre_booked_date }}</p>
									</div>
								</div>
							</div>
							<div class="col-sm-6">
								<div class="row">
									<div class="col-sm-6 mb-4">
										<label class="txtdefault mb-1 f-700">Billing Details: 
										<a href="javascript:void(0);" (click)="editorderdetailsmodal(editbillingaddressModal, 'billing')" class="edit-icon position-static"></a></label>
										<p class="txtseconday mb-0">{{ detail.billing_name }}<br />
										{{ detail.billing_address }}, {{ detail.billing_city }}, {{ detail.billing_state }} - {{ detail.billing_pincode }}
										</p>
									</div>
									<div class="col-sm-6 mb-4">
										<label class="txtdefault mb-1 f-700">Shipping Address:
											<a href="javascript:void(0);" (click)="editorderdetailsmodal(editshippingaddressModal, 'shipping')" class="edit-icon position-static"></a>
										</label>
										<p class="txtseconday mb-0">{{ detail.shipping_name }} <br />
										{{ detail.shipping_address }}, {{ detail.shipping_city }}, {{ detail.shipping_state }} - {{ detail.shipping_pincode }}
										</p>
									</div>	
								</div>
							</div>
							<div class="col-sm-3 mb-4">
								<label class="txtdefault mb-1 f-700">Order Status:</label>
								<p class="text-danger mb-0">{{ detail.order_status }}</p>
							</div>
							<div class="col-sm-3 mb-4">
								<label class="txtdefault mb-1 f-700">Buyer Invoice Number:
									<a href="javascript:void(0);" (click)="editinvoicenumber(editinvoicenumberModal, 'invoice', 'buyer')" class="edit-icon position-static"></a>
								</label>
								<p class="text-danger mb-0">{{ detail.invoice_number }}</p>
							</div>
							<div class="col-sm-3 mb-4">
								<label class="txtdefault mb-1 f-700">Email:</label>
								<p class="mb-0">{{ detail.billing_email }}</p>
							</div>
							<div class="col-sm-3 mb-4">
								<label class="txtdefault mb-1 f-700">Email:</label>
								<p class="mb-0">{{ detail.shipping_email }}</p>
							</div>
							<div class="col-sm-6 ">
								<label class="txtdefault mb-1 f-700">Logistic Preference:</label>
								<p class="mb-0">{{ detail.logistic }}</p>
							</div>
							<div class="col-sm-3 mb-4">
								<label class="txtdefault mb-1 f-700">Mobile:</label>
								<p class="mb-0">{{ detail.billing_mobile }}</p>
							</div>
							<div class="col-sm-3 mb-4">
								<label class="txtdefault mb-1 f-700">Mobile:</label>
								<p class="mb-0">{{ detail.shipping_mobile }}</p>
							</div>

							<div class="col-sm-6">
								<div class="row">
									<div class="col-sm-6 mb-4">
										<label class="txtdefault mb-1 f-700">Supplier Registered Details: 
											<a href="javascript:void(0);" (click)="editorderdetailsmodal(editsupplierregaddressModal, 'supplier_reg')" class="edit-icon position-static"></a>
										</label>
										<p class="txtseconday mb-0">{{ detail.supplier_name }}<br />
										{{ detail.supplier_address }}, {{ detail.supplier_city }}, {{ detail.supplier_state }} - {{ detail.supplier_pincode }}
										</p>
									</div>
									<div class="col-sm-6 mb-4">
										<label class="txtdefault mb-1 f-700">Product Location:
											<a href="javascript:void(0);" (click)="editorderdetailsmodal(editdispatchaddressModal, 'dispatch')" class="edit-icon position-static"></a>
										</label>
										<p class="txtseconday mb-0">
										{{ detail.dispatch_from_address }}, {{ detail.dispatch_from_city }}, {{ detail.dispatch_from_state }} - {{ detail.dispatch_from_pincode }}
										</p>
									</div>	
								</div>
							</div>

							<div class="col-sm-6" *ngIf="detail.candf == 'yes'">
								<div class="row">
									<div class="col-sm-6 mb-4">
										<label class="wt500 txtdefault mb-1">E-commerce Seller Billing Details: 
											<a href="javascript:void(0);" (click)="editorderdetailsmodal(editssbillingaddressModal, 'ss_billing')" class="edit-icon position-static"></a>
										</label>
										<p class="wt500 txtseconday mb-0">{{ detail.ss_billing_name }}<br />
										{{ detail.ss_billing_address }}, {{ detail.ss_billing_city }}, {{ detail.ss_billing_state }} - {{ detail.ss_billing_pincode }}
										</p>
									</div>
									<div class="col-sm-6 mb-4">
										<label class="wt500 txtdefault mb-1">E-commerce Seller Shipping Address:
											<a href="javascript:void(0);" (click)="editorderdetailsmodal(editssshippingaddressModal, 'ss_shipping')" class="edit-icon position-static"></a>
										</label>
										<p class="wt500 txtseconday mb-0">{{ detail.ss_shipping_name }} <br />
										{{ detail.ss_shipping_address }}, {{ detail.ss_shipping_city }}, {{ detail.ss_shipping_state }} - {{ detail.ss_shipping_pincode }}
										</p>
									</div>
                                    <div class="col-sm-6 mb-4">
										<label class="wt500 txtdefault mb-1">Ecommerce Seller Invoice Number:
                                            <a href="javascript:void(0);" (click)="editinvoicenumber(editinvoicenumberModal, 'invoice', 'seller')" class="edit-icon position-static"></a>
                                        </label>
                                        <p class="wt500 text-danger mb-0">{{ detail.superseller_invoice_number }}</p>
									</div>
								</div>
							</div>
									
						</div>
					</div>
					<!-- /ORDER TOP -->
				</div>		
				<!-- CARD -->
				<div class="card card-ui dc-pinfo">
					<!-- REPEAT -->
					<div class="cs-item-row">
						<div class="row">
							<div class="col-lg-7 pr-lg-0">
								<h4 class="fs16 f-700 mb-sm-4 mb-3 txtdefault">Product Info:</h4>
								<div class="d-table mb-4 w-100">
									<div class="d-table-cell cart-thumb align-middle">
										<img src="{{ detail.product_image }}" />
									</div>
									<div class="d-table-cell pl-3 align-middle">
										<p class="txtdefault wt700 mb-0 fs14 lnht20">{{ detail.product_name }}
										<a href="javascript:void(0)" class="edit-icon blicon position-static"></a>
										</p>
									</div>
								</div>
							</div>
							
							
							<div class="col-lg-3 col-6 pl-md-0 px-0 text-center">
								<h4 class="fs14 txtdefault mb-lg-2 pb-2">Order quantity</h4>
								<div class="cs-odqty mb-3">
									<div class="input-group">
										<span class="input-group-btn">
										<button type="button" class="quantity-left-minus btn " >
											-
										</button>
										</span>
										<input type="text" id="quantity" name="quantity" class="form-control input-number" value="{{ detail.qty }}" min="1" max="100">
										<span class="input-group-btn">
											<button type="button" class="quantity-right-plus btn text-success" >
												+
											</button>
										</span>
									</div>
								</div>	
							</div>
							<div class="col-lg-2  col-6 px-0 text-center" *ngIf="detail.parentchild != 'yes'">
								<h4 class="fs14 txtdefault px-0 mb-lg-4 mb-0 pb-2">Price Per Unit</h4>
								<p class="fs14 txtseconday pt-3">{{ detail.price_per_unit }}</p>
							</div>
						</div>	
                        
                        <ng-container *ngIf="detail.parentchild == 'yes'">
                            <hr class="mt-10 mb-20">
                            <div class="row table-card">
                                <div class="col-md-12">
                                    <div class="fs14 f-700 mb-2">Child Product Information:</div>
                                    <table class="table custom-table">
                                        <thead>
                                            <tr>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>Quantity</th>
                                                <th>Price per unit</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let child of detail.parentchild_records;">
                                                <td><img src="{{ child.image }}" alt="{{ child.name }}" style="height:80px;width:80px"></td>
                                                <td>{{ child.name }}</td>
                                                <td>{{ child.quantity }}</td>
                                                <td>{{ child.price_per_unit }}</td>
                                                <td>{{ child.status }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </ng-container>
				  </div>
				  <!-- /REPEAT -->
					<!-- CARD TOTAL -->
					<div class="row pt-4">

                        <div class="col-md-6">
                            <!-- ROW -->
                            <div class="row">
                                <div class="col-sm-9 text-right pr-md-0">
                                    <p class="txtseconday mb-3"><b>Buyer Payable</b></p>
                                </div>
                            </div>
                            <!-- /ROW -->

                            <!-- ROW -->
                            <div class="row">
                                <div class="col-sm-9 text-right pr-md-0">
                                    <p class="txtseconday mb-3">Deal Value: {{ detail.total_goods_value }}</p>
                                </div>
                            </div>
                            <!-- /ROW -->

                            <!-- ROW -->
                            <div class="row">
                                <div class="col-sm-9 text-right pr-md-0">
                                    <p class="txtseconday mb-3">Buyer Convenience Charges: {{ detail.buyer_convience_fee }}</p>
                                </div>
                            </div>
                            <!-- /ROW -->

                            <!-- ROW -->
                            <div class="row">
                                <div class="col-sm-9 text-right pr-md-0">
                                    <p class="txtseconday mb-3">Buyer Logistics Charges: {{ detail.buyer_logistics_fee }}</p>
                                </div>
                            </div>
                            <!-- /ROW -->

                            <!-- ROW -->
                            <div class="row">
                                <div class="col-sm-9 text-right pr-md-0">
                                    <p class="txtseconday mb-3">Insurance Charges: {{ detail.insurance_amount }}</p>
                                </div>
                            </div>
                            <!-- /ROW -->

                            <!-- ROW -->
                            <div class="row">
                                <div class="col-sm-9 text-right pr-md-0">
                                    <p class="txtseconday mb-3">PG Charges: {{ detail.payment_charge }}</p>
                                </div>
                            </div>
                            <!-- /ROW -->

                            <!-- ROW -->
                            <div class="row">
                                <div class="col-sm-9 text-right pr-md-0">
                                    <p class="txtseconday mb-3">Total Payable Amount: {{ detail.total_amount }}</p>
                                </div>
                            </div>
                            <!-- /ROW -->
                        </div>

                        <div class="col-md-6">
                            <!-- ROW -->
                            <div class="row">
                                <div class="col-sm-9 text-right pr-md-0">
                                    <p class="txtseconday mb-3"><b>Seller Receivable</b></p>
                                </div>
                            </div>
                            <!-- /ROW -->

                            <!-- ROW -->
                             <div class="row">
                                <div class="col-sm-9 text-right pr-md-0">
                                    <p class="txtseconday mb-3">Deal Value: {{ detail.total_goods_value }}</p>
                                </div>
                            </div>
                            <!-- /ROW -->

                            <!-- ROW -->
                            <div class="row">
                                <div class="col-sm-9 text-right pr-md-0">
                                    <p class="txtseconday mb-3">Seller Convenience Charges: {{ detail.seller_convience_fee }}</p>
                                </div>
                            </div>
                            <!-- /ROW -->

                            <!-- ROW -->
                            <div class="row">
                                <div class="col-sm-9 text-right pr-md-0">
                                    <p class="txtseconday mb-3">Seller Logistics Charges: {{ detail.seller_logistics_fee }}</p>
                                </div>
                            </div>
                            <!-- /ROW -->

                            <!-- ROW -->
                            <div class="row">
                                <div class="col-sm-9 text-right pr-md-0">
                                    <p class="txtseconday mb-3">TDS: {{ detail.tds_amount }}</p>
                                </div>
                            </div>
                            <!-- /ROW -->

                            <!-- ROW -->
                            <div class="row">
                                <div class="col-sm-9 text-right pr-md-0">
                                    <p class="txtseconday mb-3">TCS: {{ detail.tcs_amount }}</p>
                                </div>
                            </div>
                            <!-- /ROW -->

                            <!-- ROW -->
                            <div class="row">
                                <div class="col-sm-9 text-right pr-md-0">
                                    <p class="txtseconday mb-3">Total Receivable Amount: {{ detail.seller_receivable_amount }}</p>
                                </div>
                            </div>
                            <!-- /ROW -->
                        </div>
					</div>
					<!-- /CARD TOTAL -->
				</div>
				<!-- /CARD -->
			</div>
			<!-- SIDEBAR -->
			<div class="col-md-3">
				<div class="row mb-4" *ngIf="detail.cancel_status != 1">
					<div class="col-md-12">
						<div *ngIf="detail.imei_status" class="mb-3">
							<div class="order-meta-title"><b>Download:</b></div>
							<div class="mb-1" *ngIf="detail.imei_status && detail.imei_path"><a href="{{ detail.imei_path }}" target="_blank"><i class="fa fa-hand-o-right"></i> IMEI Bill</a></div>
							<div class="mb-1" *ngIf="detail.candf == 'yes' && detail.superseller_invoice_status && detail.superseller_invoice_path"><a href="{{ detail.superseller_invoice_path }}" target="_blank"><i class="fa fa-hand-o-right"></i> Seller Invoice</a> </div>
							<div class="mb-1" *ngIf="detail.invoice_status && detail.invoice_path"><a href="{{ detail.invoice_path }}" target="_blank"><i class="fa fa-hand-o-right"></i> Buyer Invoice</a> </div>
							<div class="mb-1" *ngIf="detail.eway_bill_status && detail.eway_bill_path"><a href="{{ detail.eway_bill_path }}" target="_blank"><i class="fa fa-hand-o-right"></i> E-Way Bill</a></div>
							<div class="mb-1" *ngIf="detail.shipping_label_status && detail.shipping_label_path"><a href="{{ detail.shipping_label_path }}" target="_blank"><i class="fa fa-hand-o-right"></i> Shipping Label</a></div>	
						</div>
					</div>

	                <div class="col-md-12 mb-3" *ngIf="(detail.candf == 'yes' && detail.superseller_invoice_status && detail.superseller_invoice_path) || (detail.invoice_path && detail.invoice_status)">
						<div *ngIf="detail.imei_status">
							<div class="order-meta-title"><b>Regenerate Invoice:</b></div>
							<div class="mb-1" *ngIf="detail.candf == 'yes' && detail.superseller_invoice_status && detail.superseller_invoice_path"><a href="javascript:;" (click)="regenerateinvoice('seller')"><i class="fa fa-hand-o-right"></i> Ecommerce Seller</a> </div>
							<div class="mb-1" *ngIf="detail.invoice_path && detail.invoice_status"><a href="javascript:;" (click)="regenerateinvoice('buyer')"><i class="fa fa-hand-o-right"></i> Buyer</a> </div>
						</div>
					</div>

	                <div class="col-md-12 mb-3" *ngIf="detail.superseller_eway_bill_status || detail.buyer_eway_bill_status || detail.shipping_label_status">
						<div>
							<div class="order-meta-title"><b>Re-upload Files:</b></div>
							<div class="mb-1" *ngIf="detail.superseller_eway_bill_path"><a href="javascript:;"  (click)="openWindowUploadInvoice(ssewaybill)"><i class="fa fa-hand-o-right"></i> Ecommerce Seller E-way Bill</a> </div>
							<div class="mb-1" *ngIf="detail.eway_bill_path"><a href="javascript:;"  (click)="openWindowUploadInvoice(ewaybill)"><i class="fa fa-hand-o-right"></i> Buyer E-way Bill</a> </div>
	                        <div class="mb-1" *ngIf="detail.shipping_label_path"><a href="javascript:;" (click)="openWindowUploadInvoice(shippinglabel)"><i class="fa fa-hand-o-right"></i> Shipping Label</a> </div>
						</div>
					</div>

					<div class="col-md-12">
	                    <a href="javascript:void(0);"  *ngIf="!detail.imei_status" class="btn px-3 btn-block buybtn fs14 radius10" (click)="noimeiqc()">
							<span class="ml-2">No IMEI & No QC</span>
						</a>

						<!-- Ready to Dispatch-->
						<a href="javascript:void(0);"  *ngIf="detail.payment_status == 1 && detail.quality_status == 0" class="btn px-3 btn-block buybtn fs14 radius10" (click)="readytodispatch()">
							<span class="ml-2">Ready to Dispatch</span>
						</a>

						<!-- IMEI List Upload-->
						<a href="javascript:void(0);" *ngIf="detail.stock_status && !detail.imei_status" class="btn px-3 btn-block buybtn fs14 radius10" (click)="openWindowUploadInvoice(imeilist)">
							<svg xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
							<g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
								<path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#fff"/>
							</g>
							</svg> <span class="ml-2">Upload IMEI List</span>
						</a>

						<!-- Accept verfied products-->
						<a href="javascript:void(0);"  class="btn px-3 buybtn fs14 radius10" *ngIf="detail.buyer_accepted == 1" (click)="acceptqcorder()">
							<svg xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
							<g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
								<path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#fff"/>
							</g>
							</svg> <span class="ml-2">Accept Verified Products ({{ detail.verified}}/{{ detail.qty }})</span>
						</a>

						<!-- Supplier Invoice Generation-->
						<a href="javascript:void(0);" *ngIf="detail.quality_status && (detail.candf == 'yes' && !detail.superseller_invoice_path)" class="btn px-3 buybtn fs14 radius10" (click)="generateinvoice('superseller')">
							<svg xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
							<g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
								<path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#fff"/>
							</g>
							</svg> <span class="ml-2">Generate Ecommerce seller invoice</span>
						</a>

						<!-- Supplier invoice upload -->
						<a href="javascript:void(0);" 
						*ngIf="detail.candf == 'yes' && detail.superseller_invoice_path && !detail.superseller_invoice_status" 
						(click)="openWindowUploadInvoice1(content3, 'superseller')"
						class="btn-d-outline d-inline-block">
						Upload Ecommerce Seller Invoice
						</a>

						<!-- Superseller Invoice Generation-->
						<a href="javascript:void(0);" *ngIf="detail.quality_status && ((detail.candf == 'yes' && detail.superseller_invoice_status) || detail.candf == 'no') && !detail.invoice_path" class="btn px-3 buybtn fs14 radius10" 
						(click)="generateinvoice('buyer')">
							<svg xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
							<g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
								<path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#fff"/>
							</g>
							</svg> <span class="ml-2">Generate Buyer Invoice</span>
						</a>

						<!-- Superseller invoice upload -->
						<a href="javascript:void(0);" 
						*ngIf="((detail.candf == 'yes' && detail.superseller_invoice_status) || (detail.candf == 'no')) && detail.invoice_path  && !detail.invoice_status"
						(click)="openWindowUploadInvoice1(content3, 'buyer')"
						class="btn-d-outline d-inline-block">Upload Buyer Invoice</a>

						<!-- Eway Bill Upload-->
						<a href="javascript:void(0);" *ngIf="detail.invoice_status && (detail.candf == 'yes' && !detail.superseller_eway_bill_status)" class="btn px-3 buybtn fs14 radius10" (click)="openWindowUploadInvoice(ssewaybill)">
							<svg xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
							<g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
								<path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#fff"/>
							</g>
							</svg> <span class="ml-2">Upload Ecommerce Seller Eway Bill</span>
						</a>

	                    <a href="javascript:void(0);" *ngIf="detail.invoice_status && (detail.candf == 'yes' && !detail.superseller_eway_bill_status)" class="btn px-3 buybtn fs14 radius10" (click)="generatedetails('no_ss_eway_bill')">
							<svg xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
							<g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
								<path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#fff"/>
							</g>
							</svg> <span class="ml-2">No Ecommerce Seller Eway Bill</span>
						</a>

						<!-- Eway Bill Upload-->
						<a href="javascript:void(0);" *ngIf="detail.invoice_status && ((detail.candf == 'yes' && detail.superseller_eway_bill_status) || detail.candf == 'no') && !detail.eway_bill_status" class="btn px-3 buybtn fs14 radius10" (click)="openWindowUploadInvoice(ewaybill)">
							<svg xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
							<g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
								<path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#fff"/>
							</g>
							</svg> <span class="ml-2">Upload Buyer Eway Bill</span>
						</a>

	                    <a href="javascript:void(0);" *ngIf="detail.invoice_status && ((detail.candf == 'yes' && detail.superseller_eway_bill_status) || detail.candf == 'no') && !detail.eway_bill_status" class="btn px-3 buybtn fs14 radius10" (click)="generatedetails('no_eway_bill')">
							<svg xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
							<g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
								<path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#fff"/>
							</g>
							</svg> <span class="ml-2">No Buyer Eway Bill</span>
						</a>

						<!-- Shipping label Upload-->
						<a href="javascript:void(0);" *ngIf="(detail.invoice_status && detail.eway_bill_status && !detail.shipping_label_status)" (click)="openWindowUploadInvoice(shippinglabel)" class="btn px-3 buybtn fs14 radius10">
							<svg xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
							<g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
								<path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#fff"/>
							</g>
							</svg> <span class="ml-2">Upload Shipping Label</span>
						</a>

						<!-- Generate & Download Manifest -->
						<a href="javascript:void(0);" *ngIf="detail.invoice_path && detail.shipping_label_status && !detail.manifest_status" class="btn px-3 buybtn fs14 radius10" (click)="generatemanifest()">
							<svg xmlns="http://www.w3.org/2000/svg" width="12.678" height="14.092" viewBox="0 0 12.678 14.092">
							<g id="np_export_2535025_000000" transform="translate(44.801 40.054) rotate(180)">
								<path id="Path_2355" data-name="Path 2355" d="M33.336,32.791l4.419-4.418V39.345a.709.709,0,1,0,1.419,0V28.374l4.419,4.418a.709.709,0,1,0,1-1l-5.627-5.631-.109-.089-.04-.029L38.748,26a.68.68,0,0,0-.071-.021l-.075-.007a.717.717,0,0,0-.277,0l-.061.018h0a.429.429,0,0,0-.072.021l-.071.036-.053.028-.109.089-5.628,5.622a.709.709,0,0,0,1,1Z" transform="translate(-0.003)" fill="#fff"/>
							</g>
							</svg> <span class="ml-2">Generate & Download Manifest</span>
						</a>

					</div>
				</div>

				<div class="accordionarrow faqpanel doc-od">
					<ngb-accordion #acc="ngbAccordion">
					  <ngb-panel id="toggle-1" title="Logistic Management" *ngIf="detail.quality_status == 1">
						<ng-template ngbPanelContent>
							<div class="mb-4 pb-2">
								<ng-selectize [config]="config" [(ngModel)]="detail.logistic_partner_id" [options] = "logistcs_partners" placeholder="Shipping Company"></ng-selectize>
							</div>
							<div class="mb-4">
								<label class="fs14 txtdefault mb-1">Tracking Number:</label>
								<input type="text" class="form-control" [(ngModel)]="detail.tracking_number">
								<!-- <p class="fs14 txtseconday mb-0">EB2021061548772</p> -->
							</div>
							<a href="javascript:void(0);" class="btn-d-outline d-inline-block" (click)="updatelogisticspartner()">Update</a>
						</ng-template>
					  </ngb-panel>
					</ngb-accordion>
				</div>
			</div>
			<!-- /SIDEBAR -->
		</div>
        <div class="card cardui carduipro p-4 mb-40" *ngIf="detail.delivered_status == 1">
			<h3 class="f-700 fs20 txtdefault pb-20">Order transaction</h3>
			<div class="wallet-balance">
				<div class="mr-sm-3">
					<img src="assets/images/icons/wallet-balance.svg">
				</div>
				<div>
					<p>Admin Current Balance:</p>
					<h4>{{ user_info.walletbalance }}</h4>
				</div>
			</div>

            <div class="row mt-40">
                <div class="col-md-8">
                    <div class="row mb-20">
                        <div class="col-md-6">
                            <div class="tpbr auto-w">
                                <label class="fs12 txtseconday tpbrlbl">From User Type</label>
                                <ng-selectize [config]="config" [options] = "user_type" placeholder="" [(ngModel)]="selected_user_type">
                                </ng-selectize>	
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="tpbr auto-w">
                                <label class="fs12 txtseconday tpbrlbl">To User Type</label>
                                <ng-selectize [config]="config" [options] = "user_type" placeholder="" [(ngModel)]="selected_to_user_type">
                                </ng-selectize>	
                            </div>
                        </div>
                    </div>

                    <div class="form-container mdl-frm-lbl mb-3 mt-10">
                        <div class="row">
                            <div class="col-md-6 mb-2">
                                <div class="floating-label mb-0">      
                                <input type="text" class="form-control" placeholder=" "  [(ngModel)]='payment_amount'/>
                                <span class="highlight"></span>
                                <label>Enter amount</label>
                                </div> 
                            </div>
                            <div class="col-md-6">
                                <div class="tpbr auto-w">
                                    <label class="fs12 txtseconday tpbrlbl">Nature of Transaction</label>
                                    <ng-selectize [config]="config" [options] = "charges_types" placeholder="" [(ngModel)]="transaction_type">
                                    </ng-selectize>	
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-container mb-3">
                                    <div class="floating-label mb-0">      
                                    <textarea class="form-control floating-textarea" [(ngModel)]="remark"></textarea>
                                    <span class="highlight"></span>
                                    <label>Remarks</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-left mt-20">
                            <button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4" (click)="makepayment()" [disabled]="buttonLoading">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="buttonLoading"></span>
                                &nbsp;Transfer
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4" *ngIf="detail.chargespaymentlog.length > 0 || detail.discountpaymentlog.length > 0">
                    <div class="mb-2" *ngIf="detail.charge_buyer_invoice_number">
                        <a href="{{ detail.charge_buyer_invoice_path }}" target="_blank"><i  class="fa fa-hand-o-right"></i> View Buyer Charge Invoice</a>
                    </div>

                    <div class="mb-2" *ngIf="detail.charge_buyer_invoice_number">
                        <a href="javascript:;" (click)="generatechargesinvoice(detail, 'buyer')"><i  class="fa fa-hand-o-right"></i> Regenerate Buyer Charge Invoice</a>
                    </div>

                    <div class="mb-2" *ngIf="!detail.charge_buyer_invoice_number">
                        <a href="javascript:;" (click)="generatechargesinvoice(detail, 'buyer')"><i  class="fa fa-hand-o-right"></i> Generate Buyer Charge Invoice</a>
                    </div>

                    <div class="mb-2" *ngIf="detail.charge_seller_invoice_number">
                        <a href="{{ detail.charge_seller_invoice_path }}" target="_blank"><i  class="fa fa-hand-o-right"></i> View Seller Charge Invoice</a>
                    </div>

                    <div class="mb-2" *ngIf="detail.charge_seller_invoice_number">
                        <a href="javascript:;" (click)="generatechargesinvoice(detail, 'seller')"><i  class="fa fa-hand-o-right"></i> Regenerate Seller Charge Invoice</a>
                    </div>
                    
                    <div class="mb-2" *ngIf="!detail.charge_seller_invoice_number">
                        <a href="javascript:;" (click)="generatechargesinvoice(detail, 'seller')"><i  class="fa fa-hand-o-right"></i> Generate Seller Charge Invoice</a>
                    </div>
                </div>
            </div>
            
		</div>

		<div class="fs18 f-700 mb-2">Order Detailed Payment Flow / Action</div>
		<div class="row">
			<div class="col-md-12">
				<div class="card-ui card mb-4" *ngIf="detail.payment_log.length > 0">
					<div class="card-body">
						<div class="odlgrwrpt">
							<div class="odlgrw  pb-3">
								<label class="fs14 txtdefault mb-1">Order Placed (Order ID {{detail.order_no}})</label>
								<p class="fs14 txtseconday mb-0">{{ detail.order_date }}</p>
                                <ng-container *ngFor="let log of detail.payment_log">
                                    <div class="short-info">
                                        <div>
                                            <span [innerHtml]="log.title"></span>
                                            <div class="fs12">Amount to Pay: ₹ {{ log.amount }}</div>
                                            <div class="fs12">Date: {{ log.date }}</div>
										    <div class="fs12" *ngIf="log.paid_status == 'Failed'"><i class="fa fa-exclamation-triangle"></i> Reason: {{ log.paid_info }}</div>
                                        </div>
                                        <div class="d-flex align-items-center" *ngIf="log.paid_status == 'Paid'"><span class="status">{{ log.paid_info }} (₹ {{ log.amount }})</span> <img src="assets/images/success-xs.png"></div>
                                        <div class="d-flex align-items-center" *ngIf="log.paid_status == 'failed'">
                                            <span class="status">Failed</span> <img src="assets/images/failed-xs.png">
                                            <div class="px-2"><a href="javascript:;" class="btn-xs-failed-pay">Proceed to Pay</a></div>
                                        </div>
                                        <div class="d-flex align-items-center" *ngIf="log.paid_status == 'Pending'">
                                            <span class="status">Pending</span>
                                            <div class="px-2"><a href="javascript:;" class="btn-xs-failed-pay" *ngIf="log.pay_btn" (click)="openproceedtopay(proceedtopay, log.id)">Proceed to Pay</a></div>
                                        </div>
                                    </div>
                                </ng-container>
							</div>
						</div>
					</div>
				</div>

                <div class="card-ui card mb-4" *ngIf="detail.qcrefundpaymentlog.length > 0">
					<div class="card-body">
						<div class="odlgrwrpt">
							<div class="odlgrw  pb-3">
								<label class="fs14 txtdefault mb-1">Order QC Refund Payments (Order ID {{detail.order_no}})</label>
								<p class="fs14 txtseconday mb-0">{{ detail.order_date }}</p>
                                <ng-container *ngFor="let log of detail.qcrefundpaymentlog">
                                    <div class="short-info">
                                        <div>
                                            <span [innerHtml]="log.title"></span>
                                            <div class="fs12">Amount to Pay: ₹ {{ log.amount }}</div>
                                            <div class="fs12">Date: {{ log.date }}</div>
										    <div class="fs12" *ngIf="log.paid_status == 'Failed'"><i class="fa fa-exclamation-triangle"></i> Reason: {{ log.paid_info }}</div>
                                        </div>
                                        <div class="d-flex align-items-center" *ngIf="log.paid_status == 'Paid'"><span class="status">{{ log.paid_info }} (₹ {{ log.amount }})</span> <img src="assets/images/success-xs.png"></div>
                                        <div class="d-flex align-items-center" *ngIf="log.paid_status == 'failed'">
                                            <span class="status">Failed</span> <img src="assets/images/failed-xs.png">
                                            <div class="px-2"><a href="javascript:;" class="btn-xs-failed-pay">Proceed to Pay</a></div>
                                        </div>
                                        <div class="d-flex align-items-center" *ngIf="log.paid_status == 'Pending'">
                                            <span class="status">Pending</span>
                                            <div class="px-2"><a href="javascript:;" class="btn-xs-failed-pay" *ngIf="log.pay_btn" (click)="openproceedtopay(proceedtopay, log.id)">Proceed to Pay</a></div>
                                        </div>
                                    </div>
                                </ng-container>
							</div>
						</div>
					</div>
				</div>

                <div class="card-ui card mb-4" *ngIf="detail.qcreorderpaymentlog.length > 0">
					<div class="card-body">
						<div class="odlgrwrpt">
							<div class="odlgrw  pb-3">
								<label class="fs14 txtdefault mb-1">Order QC Payments (Order ID {{detail.order_no}})</label>
								<p class="fs14 txtseconday mb-0">{{ detail.order_date }}</p>
                                <ng-container *ngFor="let log of detail.qcreorderpaymentlog">
                                    <div class="short-info">
                                        <div>
                                            <span [innerHtml]="log.title"></span>
                                            <div class="fs12">Amount to Pay: ₹ {{ log.amount }}</div>
                                            <div class="fs12">Date: {{ log.date }}</div>
										    <div class="fs12" *ngIf="log.paid_status == 'Failed'"><i class="fa fa-exclamation-triangle"></i> Reason: {{ log.paid_info }}</div>
                                        </div>
                                        <div class="d-flex align-items-center" *ngIf="log.paid_status == 'Paid'"><span class="status">{{ log.paid_info }} (₹ {{ log.amount }})</span> <img src="assets/images/success-xs.png"></div>
                                        <div class="d-flex align-items-center" *ngIf="log.paid_status == 'failed'">
                                            <span class="status">Failed</span> <img src="assets/images/failed-xs.png">
                                            <div class="px-2"><a href="javascript:;" class="btn-xs-failed-pay">Proceed to Pay</a></div>
                                        </div>
                                        <div class="d-flex align-items-center" *ngIf="log.paid_status == 'Pending'">
                                            <span class="status">Pending</span>
                                            <div class="px-2"><a href="javascript:;" class="btn-xs-failed-pay" *ngIf="log.pay_btn" (click)="openproceedtopay(proceedtopay, log.id)">Proceed to Pay</a></div>
                                        </div>
                                    </div>
                                </ng-container>
							</div>
						</div>
					</div>
				</div>

                

                <div class="card-ui card mb-4" *ngIf="detail.rejectpaymentlog.length > 0">
					<div class="card-body">
						<div class="odlgrwrpt">
							<div class="odlgrw  pb-3">
								<label class="fs14 txtdefault mb-1">Order Rejected By Seller (Order ID {{detail.order_no}})</label>
								<p class="fs14 txtseconday mb-0">{{ detail.order_date }}</p>
                                <ng-container *ngFor="let log of detail.rejectpaymentlog">
                                    <div class="short-info">
                                        <div>
                                            <span [innerHtml]="log.title"></span>
                                            <div class="fs12">Amount to Pay: ₹ {{ log.amount }}</div>
                                            <div class="fs12">Date: {{ log.date }}</div>
										    <div class="fs12" *ngIf="log.paid_status == 'Failed'"><i class="fa fa-exclamation-triangle"></i> Reason: {{ log.paid_info }}</div>
                                        </div>
                                        <div class="d-flex align-items-center" *ngIf="log.paid_status == 'Paid'"><span class="status">{{ log.paid_info }} (₹ {{ log.amount }})</span> <img src="assets/images/success-xs.png"></div>
                                        <div class="d-flex align-items-center" *ngIf="log.paid_status == 'failed'">
                                            <span class="status">Failed</span> <img src="assets/images/failed-xs.png">
                                            <div class="px-2"><a href="javascript:;" class="btn-xs-failed-pay">Proceed to Pay</a></div>
                                        </div>
                                        <div class="d-flex align-items-center" *ngIf="log.paid_status == 'Pending'">
                                            <span class="status">Pending</span>
                                            <div class="px-2"><a href="javascript:;" class="btn-xs-failed-pay" *ngIf="log.pay_btn" (click)="openproceedtopay(proceedtopay, log.id)">Proceed to Pay</a></div>
                                        </div>
                                    </div>
                                </ng-container>
							</div>
						</div>
					</div>
				</div>

                <div class="card-ui card mb-4" *ngIf="detail.cancelpaymentlog.length > 0">
					<div class="card-body">
						<div class="odlgrwrpt">
							<div class="odlgrw  pb-3">
								<label class="fs14 txtdefault mb-1">Order Cancelled (Order ID {{detail.order_no}})</label>
								<p class="fs14 txtseconday mb-0">{{ detail.order_date }}</p>
                                <ng-container *ngFor="let log of detail.cancelpaymentlog">
                                    <div class="short-info">
                                        <div>
                                            <span [innerHtml]="log.title"></span>
                                            <div class="fs12">Amount to Pay: ₹ {{ log.amount }}</div>
                                            <div class="fs12">Date: {{ log.date }}</div>
										    <div class="fs12" *ngIf="log.paid_status == 'Failed'"><i class="fa fa-exclamation-triangle"></i> Reason: {{ log.paid_info }}</div>
                                        </div>
                                        <div class="d-flex align-items-center" *ngIf="log.paid_status == 'Paid'"><span class="status">{{ log.paid_info }} (₹ {{ log.amount }})</span> <img src="assets/images/success-xs.png"></div>
                                        <div class="d-flex align-items-center" *ngIf="log.paid_status == 'failed'">
                                            <span class="status">Failed</span> <img src="assets/images/failed-xs.png">
                                            <div class="px-2"><a href="javascript:;" class="btn-xs-failed-pay">Proceed to Pay</a></div>
                                        </div>
                                        <div class="d-flex align-items-center" *ngIf="log.paid_status == 'Pending'">
                                            <span class="status">Pending</span>
                                            <div class="px-2"><a href="javascript:;" class="btn-xs-failed-pay" *ngIf="log.pay_btn" (click)="openproceedtopay(proceedtopay, log.id)">Proceed to Pay</a></div>
                                        </div>
                                    </div>
                                </ng-container>
							</div>
						</div>
					</div>
				</div>

                <div class="card-ui card mb-4" *ngIf="detail.returnpaymentlog.length > 0">
					<div class="card-body">
						<div class="odlgrwrpt">
							<div class="odlgrw  pb-3">
								<label class="fs14 txtdefault mb-1">Return Request - {{return_quantity}} quantity (Order ID {{detail.order_no}})</label>
								<p class="fs14 txtseconday mb-0">{{ detail.order_date }}</p>
                                <ng-container *ngFor="let log of detail.returnpaymentlog">
                                    <div class="short-info">
                                        <div>
                                            <span [innerHtml]="log.title"></span>
                                            <div class="fs12">Amount to Pay: ₹ {{ log.amount }}</div>
                                            <div class="fs12">Date: {{ log.date }}</div>
										    <div class="fs12" *ngIf="log.paid_status == 'Failed'"><i class="fa fa-exclamation-triangle"></i> Reason: {{ log.paid_info }}</div>
                                        </div>
                                        <div class="d-flex align-items-center" *ngIf="log.paid_status == 'Paid'"><span class="status">{{ log.paid_info }} (₹ {{ log.amount }})</span> <img src="assets/images/success-xs.png"></div>
                                        <div class="d-flex align-items-center" *ngIf="log.paid_status == 'failed'">
                                            <span class="status">Failed</span> <img src="assets/images/failed-xs.png">
                                            <div class="px-2"><a href="javascript:;" class="btn-xs-failed-pay">Proceed to Pay</a></div>
                                        </div>
                                        <div class="d-flex align-items-center" *ngIf="log.paid_status == 'Pending'">
                                            <span class="status">Pending</span>
                                            <div class="px-2"><a href="javascript:;" class="btn-xs-failed-pay" *ngIf="log.pay_btn" (click)="openproceedtopay(proceedtopay, log.id)">Proceed to Pay</a></div>
                                        </div>
                                    </div>
                                </ng-container>
							</div>
						</div>
					</div>
				</div>

                <div class="card-ui card mb-4" *ngIf="detail.chargespaymentlog.length > 0 || detail.discountpaymentlog.length > 0">
					<div class="card-body">
						<div class="odlgrwrpt">
							<div class="odlgrw  pb-3">
								<label class="fs14 txtdefault mb-1">Charge Management</label>
								<p class="fs14 txtseconday mb-0">{{ detail.order_date }}</p>
                                <ng-container *ngFor="let log of detail.chargespaymentlog">
                                    <div class="short-info">
                                        <div>
                                            <span [innerHtml]="log.title"></span>
                                            <div class="fs12">Amount to Pay: ₹ {{ log.amount }}</div>
                                            <div class="fs12">Date: {{ log.date }}</div>
                                            <div class="fs12">Nature of Charge</div>
                                        </div>
                                        <div class="d-flex align-items-center" *ngIf="log.paid_status == 'Paid'"><span class="status">{{ log.paid_info }} (₹ {{ log.amount }})</span> <img src="assets/images/success-xs.png"></div>
                                    </div>
                                </ng-container>
                                <ng-container *ngFor="let log of detail.discountpaymentlog">
                                    <div class="short-info">
                                        <div>
                                            <span [innerHtml]="log.title"></span>
                                            <div class="fs12">Amount to Pay: ₹ {{ log.amount }}</div>
                                            <div class="fs12">Date: {{ log.date }}</div>
                                            <div class="fs12">Nature of Discount</div>
                                        </div>
                                        <div class="d-flex align-items-center" *ngIf="log.paid_status == 'Paid'"><span class="status">{{ log.paid_info }} (₹ {{ log.amount }})</span> <img src="assets/images/success-xs.png"></div>
                                    </div>
                                </ng-container>
							</div>
						</div>
					</div>
				</div>

				<!-- <div class="card-ui card mb-4" *ngIf="detail.cancel_status == 1">
					<div class="card-body">
						<div class="odlgrwrpt">
							<div class="odlgrw  pb-3">
								<label class="fs14 txtdefault mb-1">Order Quantity Update or Quality Check</label>
								<p class="fs14 txtseconday mb-0">27/08/2021, 12:01:05pm</p>
								<div class="widget-title">Cancel (Refund Journey)</div>
								<div class="short-info">
									<div><b>Admin to Buyer (RAJESH VIJAYNAND PANDEY)</b></div>
									<div class="d-flex align-items-center"><span class="status">Paid (₹ 2,000.00)</span> <img src="assets/images/success-xs.png"></div>
								</div>
								<div class="short-info">
									<div>
										<b>Seller (Shri Enterprises)</b> to Buyer
										<div class="fs12">Amount to Pay: ₹ 2,000.00</div>
										<div class="fs12"><i class="fa fa-exclamation-triangle"></i> Reason: Insufficient balance in seller wallet</div>
									</div>
									<div class="d-flex align-items-center">
										<span class="status">Failed</span> <img src="assets/images/failed-xs.png">
										<div class="px-2"><a href="javascript:;" class="btn-xs-failed-pay">Proceed to Pay</a></div>
									</div>
								</div>
							</div>
						</div>
						<div class="odlgrwrpt">
							<div class="odlgrw">
								<label class="fs14 txtdefault mb-1">New Order Created (Order ID ORDER10944468)</label>
								<p class="fs14 txtseconday mb-0">27/08/2021, 12:01:05pm</p>
								<div class="short-info">
									<div><b>Buyer (RAJESH VIJAYNAND PANDEY)</b> to Admin</div>
									<div class="d-flex align-items-center"><span class="status">Paid (₹ 2,000.00)</span> <img src="assets/images/success-xs.png"></div>
								</div>
								<div class="short-info">
									<div>
										Buyer to <b>Seller (Shri Enterprises)</b>
									</div>
									<div class="d-flex align-items-center"><span class="status">Paid - 10% (₹ 800.00)</span> <img src="assets/images/success-xs.png"></div>
								</div>
								<div class="short-info">
									<div>
										<b>Seller (Shri Enterprises)</b> to Admin
									</div>
									<div class="d-flex align-items-center">
										<span class="status">Paid (₹ 2,000.00)</span> <img src="assets/images/success-xs.png">
									</div>
								</div>
								<div class="short-info">
									<div>
										Buyer to <b>SuperSeller (Shri Enterprises)</b>
									</div>
									<div class="d-flex align-items-center"><span class="status">Paid (₹ 2,000.00)</span> <img src="assets/images/success-xs.png"></div>
								</div>
								<div class="short-info">
									<div>
										<b>Super Seller (Shri Enterprises)</b> to <b>Supplier (Supplier name should come here)</b>
									</div>
									<div class="d-flex align-items-center">
										<span class="status">Paid (₹ 2,000.00)</span> <img src="assets/images/success-xs.png">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> -->

				<!-- <div class="accordionarrow faqpanel doc-od">
					<ngb-accordion #acc="ngbAccordion">
					  <ngb-panel id="toggle-3" title="Order Notes">
						<ng-template ngbPanelContent  style="display:none;">
							<div class="mb-2">
								<div class="odchatnotes">
									<p class="mb-0 txtdefault">You can add manual Order notes here for optimum order management</p>
								</div>
								<span class="txtseconday fs12">Added on 27/08/2021, 12:01pm</span>
							</div>
							<div class="mb-2">
								<div class="odchatnotes">
									<p class="mb-0 txtdefault">You can add manual Order notes here for optimum order management</p>
								</div>
								<span class="txtseconday fs12">Added on 27/08/2021, 12:01pm</span>
							</div>
							<div class="adnts">
								 <input placeholder="Add your note here"  class="form-control"  >
								 <button class="btn"></button>
							</div>
						</ng-template>
					  </ngb-panel>
					</ngb-accordion>
				</div> -->
			</div>
		</div>
	</div>					
</div>		
<!-- RETURN REQUEST -->					
<ng-template #content let-modal>
	<div class="text-right pt-3 pr-3 od-mdl-tl">
	<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
	</div>
  
  <div class="modal-body">
	<div class="row align-items-center">
		<div class="col-sm-7">
			<div class=""> 
				<div class="rtrqsthead"> 
					<h4 class="txtdefault wt700 fs18">Return Request</h4>
					<p class="txtseconday fs12">Request received on 24th Jan 2022, 11:15:25 AM</p>
				</div>
				<div class="row">
					<div class="col-lg-12 pr-0 rtnrqtmdl">
						<div class="d-table mb-4 w-100">
							<div class="d-table-cell cart-thumb align-middle">
								<img src="assets/images/products/p-2.jpg" />
							</div>
							<div class="d-table-cell pl-2 align-middle">
								<p class="txtdefault wt700 mb-0 fs12">OnePlus Nord 2 5G (Blue Haze, 8GB RAM, 128GB)</p>
								<p class="txtseconday my-1 fs12"><span class="txtdefault wt500">Order ID: </span> EQB9876598754ABCD</p>									
							</div>
						</div>
					</div>
				</div>	
				<div class="form-container mb-0 mt-0">	
					<div class="row">
						<div class="col-lg-12 ">
							<div class="floating-label">      
							  <input placeholder=" "  class="form-control" value="Incomplete technical info" >
							  <span class="highlight"></span>
							  <label>Reason for Cancellation</label>								   
							</div>
						</div>
					</div>
					<div class="row ">
						<div class="col-lg-12 ">
							<div class="floating-label">      
							  <textarea placeholder=" " class="form-control txtareaht80">Multiple RAM variants missing</textarea>
							  <span class="highlight"></span>
							  <label>Message from Buyer</label>								   
							</div>
						</div>
					</div>
				</div>
				<div class="client-thumbnails dtlthmb odimgsz text-center img-option-list">
					 <ngx-slick-carousel 
						class="carousel thumbs" 
						#slickModal="slick-carousel" 
						[config]="thumbnailsSlider ">
						<div ngxSlickItem *ngFor="let slide of slides" class="slide">
							<a class="d-inline-block w-100" href="javascript:void(0);">
								<img src="{{ slide.img }}" alt="" >									
							</a>
							<p class="fs12 txtseconday mb-0">Front image*</p>
						</div>
					</ngx-slick-carousel>
				</div>
								
			<div class="text-right">
				<input type="submit" value="Accept" class="btn px-4 buybtn fs16 radius10 btn-success" />
				<a href="javascript:void(0);" class="btn-d-outline btn-danger-outline d-inline-block ml-sm-3 ml-2">Reject</a>
			</div>
		  </div>
		</div>
		<div class="col-sm-5 text-center">			
			<div class="feedback-slides  ">
			   <div class="client-feedback dtlfull">
					<ngx-slick-carousel 
						class="carousel feedback" 
						#slickModal="slick-carousel" 
						[config]="imagesSlider">
						<div ngxSlickItem *ngFor="let slide of slides" class="slide ">	
							<div class="thmbnaildtl">
								<img src="{{ slide.img }}" alt="" width="100%">
							</div>									
						</div>
					</ngx-slick-carousel>
				</div>
			</div>	
			<p class="fs10 txtseconday pt-3">Image Preview</p>
		</div>
	</div>
	</div>
 
</ng-template>
<!-- /RETURN REQUEST -->					
<!-- CANCEL REQUEST -->					
<ng-template #content2 let-modal>
	<div class="text-right pt-3 pr-3 od-mdl-tl">
	<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
	</div>
  
  <div class="modal-body pb-3">
    <div class="row align-items-center">
		<div class="col-sm-12">
			<div class=""> 
				<div class="rtrqsthead"> 
					<h4 class="txtdefault wt700 fs18">Cancellation Request</h4>
					<p class="txtseconday fs12">Request received on 24th Jan 2022, 11:15:25 AM</p>
				</div>
				<div class="row">
					<div class="col-lg-12 pr-0 rtnrqtmdl">
						<div class="d-table mb-4 w-100">
							<div class="d-table-cell cart-thumb align-middle">
								<img src="assets/images/products/p-2.jpg" />
							</div>
							<div class="d-table-cell pl-2 align-middle">
								<p class="txtdefault wt700 mb-0 fs12">OnePlus Nord 2 5G (Blue Haze, 8GB RAM, 128GB)</p>
								<p class="txtseconday my-1 fs12"><span class="txtdefault wt500">Order ID: </span> EQB9876598754ABCD</p>									
							</div>
						</div>
					</div>
				</div>	
				<div class="form-container mb-0 mt-0">	
					<div class="row">
						<div class="col-lg-12 col-xl-7">
							<div class="floating-label">      
							  <input placeholder=" "  class="form-control" value="Incomplete technical info" >
							  <span class="highlight"></span>
							  <label>Reason for Cancellation</label>								   
							</div>
						</div>
					</div>
					<div class="row ">
						<div class="col-lg-12  col-xl-7">
							<div class="floating-label">      
							  <textarea placeholder=" " class="form-control txtareaht80">Multiple RAM variants missing</textarea>
							  <span class="highlight"></span>
							  <label>Message from Buyer</label>								   
							</div>
						</div>
					</div>
				</div>
					
			<div class="text-center">
				<input type="submit" value="Accept" class="btn px-4 buybtn fs16 radius10 btn-success" />
				<a href="javascript:void(0);" class="btn-d-outline btn-danger-outline d-inline-block ml-sm-3 ml-2">Reject</a>
			</div>
		  </div>
		</div>
	
	</div>
	</div>
 
</ng-template>
<!-- /CANCEL REQUEST -->

<!-- UPLOAD INVOICE -->
<ng-template #content3 let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>

    <div class="modal-body pb-3">
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead">
                    <h4 class="txtdefault wt700 fs22 mb-20">Upload Invoice</h4>
                </div>
                <div class="mb-10 f-600 fs16">E-Invoice Declaration <i placement="top"
                        ngbTooltip="This will act as a default filter every time you will toggle."> <img
                            src="/assets/images/icons/info.png"></i></div>
                <div class="css-check mb-30" style="display: none;">
                    <input class="css-checkbx" type="checkbox" />
                    <label class="css-label">Last year turnover was above INR 50 Crores?</label>
                </div>

                <div class="row mb-30">
                    <div class="col-md-12">
                        <div class="generate-invoice">
                            <div><a href="{{ user_invoice_type == 'buyer'?detail.invoice_path:detail.superseller_invoice_path }}" target="_blank">
								<img src="/assets/images/icons/pdf.png" class="mr-2"> Generate Invoice</a>
							</div>
                        </div>
                    </div>
                </div>

                    <div class="row">
                        <!-- INPUT ROW -->
                        <div class="col-md-12">
                            <div class="form-container mt-0 mb-4 p-eml-rt">
                                <div class="floating-label mb-10 flselcted">
                                    <input type="text" class="form-control" placeholder=" ">
                                    
                                    <span class="highlight"></span>
                                    <label>Upload Invoice</label>
                                    <div class="docselected"><img
                                            src="assets/images/svg/attach.svg"><span>Invoice.pdf</span></div>
                                </div>
                            </div>
                        </div>
                        <!-- /INPUT ROW -->
                    </div>

                <div class="text-center">
                    <input type="button" value="Upload" class="btn px-4 buybtn fs16 radius10" (click)="uploadinvoice()"/>
                </div>
            </div>
        </div>
        <hr>
        <div class="row align-items-center">
            <div class="col-md-12">
                <div class="text-center">
                    <i placement="top" ngbTooltip="This will act as a default filter every time you will toggle."> <img
                            src="/assets/images/icons/info.png"></i> Download the Generated Invoice and then upload it.
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- /UPLOAD INVOICE -->




<!-- UPLOAD EWAY BILL -->
<ng-template #ssewaybill let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>

    <div class="modal-body pb-3">
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead">
                    <h4 class="txtdefault wt700 fs22 mb-20">Upload Eway Bill</h4>
                </div>

                <div class="profileipt">
                    <div class="row">
                        <input type="file" #ssewaybillfile style="display: none;" (change)="uploadFile($event, 'ss_eway_bill')">
                        <!-- INPUT ROW -->
                        <div class="col-md-12">
                            <div class="form-container mt-0 mb-4 p-eml-rt">
                                <div class="floating-label mb-10 flselcted">
                                    <input type="text" class="form-control" placeholder=" ">
                                    <button class="btn btn-outline-secondary fs16 btn-o-default" type="button" (click)="ssewaybillfile.click()">
                                        <span><a href="javascript:;">Upload</a></span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107"
                                            viewBox="0 0 18.71 12.107">
                                            <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
                                                <path id="Path_2337" data-name="Path 2337"
                                                    d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z"
                                                    transform="translate(-14.582 -27.082)" fill="#ff7135" />
                                                <g id="Group_15718" data-name="Group 15718">
                                                    <g id="Group_15717" data-name="Group 15717">
                                                        <g id="Group_15716" data-name="Group 15716">
                                                            <path id="Path_2338" data-name="Path 2338"
                                                                d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4"
                                                                transform="translate(-36.046 -39.347)" fill="#ff7135" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>

                                    </button>
                                    <span class="highlight"></span>
                                    <label>Upload Eway Bill</label>
                                    <div class="docselected"><img
                                            src="assets/images/svg/attach.svg"><span>ewaybill.pdf</span></div>
                                </div>
                            </div>
                        </div>
                        <!-- /INPUT ROW -->
                    </div>
                </div>

                <div class="text-center">
                    <input type="button" value="Submit" class="btn px-4 buybtn fs16 radius10" (click)="generatedetails('ss_eway_bill')" />
                </div>
            </div>
        </div>
        <!-- <hr>
        <div class="row align-items-center">
            <div class="col-md-12">
                <div class="text-center">
                    <i placement="top" ngbTooltip="This will act as a default filter every time you will toggle."> <img
                            src="/assets/images/icons/info.png"></i> Download the Generated Invoice and then upload it.
                </div>
            </div>
        </div> -->
    </div>
</ng-template>
<!-- /UPLOAD EWAY BILL -->

<!-- UPLOAD EWAY BILL -->
<ng-template #ewaybill let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>

    <div class="modal-body pb-3">
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead">
                    <h4 class="txtdefault wt700 fs22 mb-20">Upload Eway Bill</h4>
                </div>

                <div class="profileipt">
                    <div class="row">
                        <input type="file" #ewaybillfile style="display: none;" (change)="uploadFile($event, 'eway_bill')">
                        <!-- INPUT ROW -->
                        <div class="col-md-12">
                            <div class="form-container mt-0 mb-4 p-eml-rt">
                                <div class="floating-label mb-10 flselcted">
                                    <input type="text" class="form-control" placeholder=" ">
                                    <button class="btn btn-outline-secondary fs16 btn-o-default" type="button" (click)="ewaybillfile.click()">
                                        <span><a href="javascript:;">Upload</a></span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107"
                                            viewBox="0 0 18.71 12.107">
                                            <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
                                                <path id="Path_2337" data-name="Path 2337"
                                                    d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z"
                                                    transform="translate(-14.582 -27.082)" fill="#ff7135" />
                                                <g id="Group_15718" data-name="Group 15718">
                                                    <g id="Group_15717" data-name="Group 15717">
                                                        <g id="Group_15716" data-name="Group 15716">
                                                            <path id="Path_2338" data-name="Path 2338"
                                                                d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4"
                                                                transform="translate(-36.046 -39.347)" fill="#ff7135" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>

                                    </button>
                                    <span class="highlight"></span>
                                    <label>Upload Eway Bill</label>
                                    <div class="docselected"><img
                                            src="assets/images/svg/attach.svg"><span>ewaybill.pdf</span></div>
                                </div>
                            </div>
                        </div>
                        <!-- /INPUT ROW -->
                    </div>
                </div>

                <div class="text-center">
                    <input type="button" value="Submit" class="btn px-4 buybtn fs16 radius10" (click)="generatedetails('eway_bill')" />
                </div>
            </div>
        </div>
        <!-- <hr>
        <div class="row align-items-center">
            <div class="col-md-12">
                <div class="text-center">
                    <i placement="top" ngbTooltip="This will act as a default filter every time you will toggle."> <img
                            src="/assets/images/icons/info.png"></i> Download the Generated Invoice and then upload it.
                </div>
            </div>
        </div> -->
    </div>
</ng-template>
<!-- /UPLOAD EWAY BILL -->


<!-- UPLOAD mANIFEST -->
<ng-template #manifest let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>

    <div class="modal-body pb-3">
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead">
                    <h4 class="txtdefault wt700 fs22 mb-20">Upload Manifest</h4>
                </div>

                <div class="profileipt">
                    <div class="row">
                        <input type="file" #manifestfile style="display: none;" (change)="uploadFile($event, 'manifest')">
                        <!-- INPUT ROW -->
                        <div class="col-md-12">
                            <div class="form-container mt-0 mb-4 p-eml-rt">
                                <div class="floating-label mb-10 flselcted">
                                    <input type="text" class="form-control" placeholder=" ">
                                    <button class="btn btn-outline-secondary fs16 btn-o-default" type="button" (click)="manifestfile.click()">
                                        <span><a href="javascript:;">Upload</a></span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107"
                                            viewBox="0 0 18.71 12.107">
                                            <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
                                                <path id="Path_2337" data-name="Path 2337"
                                                    d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z"
                                                    transform="translate(-14.582 -27.082)" fill="#ff7135" />
                                                <g id="Group_15718" data-name="Group 15718">
                                                    <g id="Group_15717" data-name="Group 15717">
                                                        <g id="Group_15716" data-name="Group 15716">
                                                            <path id="Path_2338" data-name="Path 2338"
                                                                d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4"
                                                                transform="translate(-36.046 -39.347)" fill="#ff7135" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>

                                    </button>
                                    <span class="highlight"></span>
                                    <label>Upload Manifest</label>
                                    <div class="docselected"><img
                                            src="assets/images/svg/attach.svg"><span>manifest.pdf</span></div>
                                </div>
                            </div>
                        </div>
                        <!-- /INPUT ROW -->
                    </div>
                </div>

                <div class="text-center">
                    <input type="button" value="Submit" class="btn px-4 buybtn fs16 radius10" (click)="generatedetails('manifest')" />
                </div>
            </div>
        </div>
        <!-- <hr>
        <div class="row align-items-center">
            <div class="col-md-12">
                <div class="text-center">
                    <i placement="top" ngbTooltip="This will act as a default filter every time you will toggle."> <img
                            src="/assets/images/icons/info.png"></i> Download the Generated Invoice and then upload it.
                </div>
            </div>
        </div> -->
    </div>
</ng-template>
<!-- /UPLOAD MANIFEST -->

<!-- UPLOAD IMEI LIST -->
<ng-template #imeilist let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>

    <div class="modal-body pb-3">
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead">
                    <h4 class="txtdefault wt700 fs22 mb-20">Upload IMEI List</h4>
                </div>

                <div class="profileipt">
                    <div class="row">
                        
                        <!-- INPUT ROW -->
                        <div class="col-md-12">
                            <div class="form-container mt-0 mb-4 p-eml-rt">
                                <div class="floating-label mb-10 flselcted">
                                    <input type="text" class="form-control" placeholder=" ">
                                    <input type="file" value="Upload" #imeilistfile style="display: none;" (change)="uploadFile($event, 'imeilist')">
                                    <button class="btn btn-outline-secondary fs16 btn-o-default" type="button" (click)="imeilistfile.click()">
                                        <span><a href="javascript:;">Upload</a></span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107"
                                            viewBox="0 0 18.71 12.107">
                                            <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
                                                <path id="Path_2337" data-name="Path 2337"
                                                    d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z"
                                                    transform="translate(-14.582 -27.082)" fill="#ff7135" />
                                                <g id="Group_15718" data-name="Group 15718">
                                                    <g id="Group_15717" data-name="Group 15717">
                                                        <g id="Group_15716" data-name="Group 15716">
                                                            <path id="Path_2338" data-name="Path 2338"
                                                                d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4"
                                                                transform="translate(-36.046 -39.347)" fill="#ff7135" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>

                                    </button>
                                    <span class="highlight"></span>
                                    <label>Upload IMEI</label>
                                    <div class="docselected"><img
                                            src="assets/images/svg/attach.svg"><span>IMEI.xlsx</span></div>
                                </div>
                            </div>
                        </div>
                        <!-- /INPUT ROW -->
                    </div>
                </div>

                <div class="text-center">
                    <input type="button" value="Submit" class="btn px-4 buybtn fs16 radius10" (click)="generatedetails('imeilist')" />
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- /UPLOAD IMEI LIST -->

<!-- UPLOAD SHIPPING LABEL -->
<ng-template #shippinglabel let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>

    <div class="modal-body pb-3">
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead">
                    <h4 class="txtdefault wt700 fs22 mb-20">Upload Shipping Label</h4>
                </div>

                <div class="profileipt">
                    <div class="row">
                        <input type="file" #shippingfile style="display: none;" (change)="uploadFile($event, 'shipping_label')">
                        <!-- INPUT ROW -->
                        <div class="col-md-12">
                            <div class="form-container mt-0 mb-4 p-eml-rt">
                                <div class="floating-label mb-10 flselcted">
                                    <input type="text" class="form-control" placeholder=" ">
                                    <button class="btn btn-outline-secondary fs16 btn-o-default" type="button"  (click)="shippingfile.click()">
                                        <span><a href="javascript:;">Upload</a></span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107"
                                            viewBox="0 0 18.71 12.107">
                                            <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
                                                <path id="Path_2337" data-name="Path 2337"
                                                    d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z"
                                                    transform="translate(-14.582 -27.082)" fill="#ff7135" />
                                                <g id="Group_15718" data-name="Group 15718">
                                                    <g id="Group_15717" data-name="Group 15717">
                                                        <g id="Group_15716" data-name="Group 15716">
                                                            <path id="Path_2338" data-name="Path 2338"
                                                                d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4"
                                                                transform="translate(-36.046 -39.347)" fill="#ff7135" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>

                                    </button>
                                    <span class="highlight"></span>
                                    <label>Upload Shipping Label</label>
                                    <div class="docselected"><img
                                            src="assets/images/svg/attach.svg"><span>shippinglabel.pdf</span></div>
                                </div>
                            </div>
                        </div>
                        <!-- /INPUT ROW -->
                    </div>
                </div>

                <div class="text-center">
                    <input type="button" value="Submit" class="btn px-4 buybtn fs16 radius10" (click)="generatedetails('shipping_label')" />
                </div>
            </div>
        </div>
        <!-- <hr>
        <div class="row align-items-center">
            <div class="col-md-12">
                <div class="text-center">
                    <i placement="top" ngbTooltip="This will act as a default filter every time you will toggle."> <img
                            src="/assets/images/icons/info.png"></i> Download the Generated Invoice and then upload it.
                </div>
            </div>
        </div> -->
    </div>
</ng-template>
<!-- /UPLOAD SHIPPING LABEL -->

<!-- Update invoice number -->
<ng-template #editinvoicenumberModal let-modal>
    <div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title text-center cg-ttl pb-2">Update {{edit_invoice_type == 'buyer'?'Buyer': 'Ecommerce Seller'}} Invoice Number</h5>
            <div class="form-container mdl-frm-lbl mb-3">
                <div class="row">
                    <div class="col-sm-12 mb-4">
                        <div class="floating-label mb-0">
							<input type="text" class="form-control" placeholder="" [(ngModel)]="edit_invoice_number">
                            <span class="highlight"></span>
                            <label>Invoice Number</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="cta  pb-4 text-center">
                <button class="btn btn-mod btn-color btn-medium btn-round ripple mnbtn250"
                     (click)="updateorderdetails()">Update Invoice Number</button>

            </div>

    </div>
</ng-template>
<!-- /ADD NEW ADDRESS -->

<!-- ADD NEW ADDRESS -->
<ng-template #editshippingaddressModal let-modal>
    <div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title text-center cg-ttl pb-2">Update Shipping Address</h5>
            <div class="form-container mdl-frm-lbl mb-3">
                <div class="row">
                    <div class="col-sm-12 mb-4">
                        <div class="floating-label mb-0">
                            <textarea class="form-control" placeholder="" [(ngModel)]="detail.shipping_address"></textarea>
                            <span class="highlight"></span>
                            <label>Address</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <select class="form-control" [(ngModel)]="detail.shipping_state" (change)="getcity('shipping')">
                                <option selected="selected" value="">Please Select</option>
                                <option *ngFor="let item of stateArray" [ngValue]="item.value">{{item.name}}</option>
                            </select>
                            <span class="highlight"></span>
                            <label>State</label>
                        </div>
                    </div>
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <select class="form-control" [(ngModel)]="detail.shipping_city">
                                <option selected="selected" value="">Please Select</option>
                                <option *ngFor="let item of cityArray" [ngValue]="item.value">{{item.name}}</option>
                            </select>
                            <span class="highlight"></span>
                            <label>City</label>
                        </div>
                    </div>
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <input type="text" class="form-control" placeholder=" " [(ngModel)]="detail.shipping_pincode" />
                            <span class="highlight"></span>
                            <label>Pincode</label>
                        </div>
                    </div>
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <input type="text" class="form-control" placeholder=" " [(ngModel)]="detail.shipping_name" />
                            <span class="highlight"></span>
                            <label>Name this address as (optional)</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="cta  pb-4 text-center">
                <button class="btn btn-mod btn-color btn-medium btn-round ripple mnbtn250"
                     (click)="updateorderdetails()">Update Address</button>

            </div>

    </div>
</ng-template>
<!-- /ADD NEW ADDRESS -->

<!-- ADD NEW ADDRESS -->
<ng-template #editbillingaddressModal let-modal>
    <div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title text-center cg-ttl pb-2">Update Billing Address</h5>
            <div class="form-container mdl-frm-lbl mb-3">
                <div class="row">
                    <div class="col-sm-12 mb-4">
                        <div class="floating-label mb-0">
                            <textarea class="form-control" placeholder="" [(ngModel)]="detail.billing_address"></textarea>
                            <span class="highlight"></span>
                            <label>Address</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <select class="form-control" [(ngModel)]="detail.billing_state" (change)="getcity('billing')">
                                <option selected="selected" value="">Please Select</option>
                                <option *ngFor="let item of stateArray" [ngValue]="item.value">{{item.name}}</option>
                            </select>
                            <span class="highlight"></span>
                            <label>State</label>
                        </div>
                    </div>
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <select class="form-control" [(ngModel)]="detail.billing_city">
                                <option selected="selected" value="">Please Select</option>
                                <option *ngFor="let item of cityArray" [ngValue]="item.value">{{item.name}}</option>
                            </select>
                            <span class="highlight"></span>
                            <label>City</label>
                        </div>
                    </div>
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <input type="text" class="form-control" placeholder=" " [(ngModel)]="detail.billing_pincode" />
                            <span class="highlight"></span>
                            <label>Pincode</label>
                        </div>
                    </div>
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <input type="text" class="form-control" placeholder=" " [(ngModel)]="detail.billing_name" />
                            <span class="highlight"></span>
                            <label>Name this address as (optional)</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="cta  pb-4 text-center">
                <button class="btn btn-mod btn-color btn-medium btn-round ripple mnbtn250"
                     (click)="updateorderdetails()">Update Address</button>

            </div>

    </div>
</ng-template>
<!-- /ADD NEW ADDRESS -->

<!-- DISPATCH ADDRESS -->
<ng-template #editdispatchaddressModal let-modal>
    <div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title text-center cg-ttl pb-2">Update Dispatch Address</h5>
            <div class="form-container mdl-frm-lbl mb-3">
                <div class="row">
                    <div class="col-sm-12 mb-4">
                        <div class="floating-label mb-0">
                            <textarea class="form-control" placeholder="" [(ngModel)]="detail.dispatch_from_address"></textarea>
                            <span class="highlight"></span>
                            <label>Address</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <select class="form-control" [(ngModel)]="detail.dispatch_from_state" (change)="getcity('dispatch')">
                                <option selected="selected" value="">Please Select</option>
                                <option *ngFor="let item of stateArray" [ngValue]="item.value">{{item.name}}</option>
                            </select>
                            <span class="highlight"></span>
                            <label>State</label>
                        </div>
                    </div>
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <select class="form-control" [(ngModel)]="detail.dispatch_from_city">
                                <option selected="selected" value="">Please Select</option>
                                <option *ngFor="let item of cityArray" [ngValue]="item.value">{{item.name}}</option>
                            </select>
                            <span class="highlight"></span>
                            <label>City</label>
                        </div>
                    </div>
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <input type="text" class="form-control" placeholder=" " [(ngModel)]="detail.dispatch_from_pincode" />
                            <span class="highlight"></span>
                            <label>Pincode</label>
                        </div>
                    </div>
                    <div class="col-sm-6 mb-4" style="display: none;">
                        <div class="floating-label mb-0">
                            <input type="text" class="form-control" placeholder=" " [(ngModel)]="detail.dispatch_from_name" />
                            <span class="highlight"></span>
                            <label>Name this address as (optional)</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="cta  pb-4 text-center">
                <button class="btn btn-mod btn-color btn-medium btn-round ripple mnbtn250"
                     (click)="updateorderdetails()">Update Address</button>

            </div>

    </div>
</ng-template>
<!-- /DISPATCH ADDRESS -->

<!-- Supplier Registered ADDRESS -->
<ng-template #editsupplierregaddressModal let-modal>
    <div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title text-center cg-ttl pb-2">Update Supplier Registered Address</h5>
            <div class="form-container mdl-frm-lbl mb-3">
                <div class="row">
                    <div class="col-sm-12 mb-4">
                        <div class="floating-label mb-0">
                            <textarea class="form-control" placeholder="" [(ngModel)]="detail.supplier_address"></textarea>
                            <span class="highlight"></span>
                            <label>Address</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <select class="form-control" [(ngModel)]="detail.supplier_state" (change)="getcity('supplier_reg')">
                                <option selected="selected" value="">Please Select</option>
                                <option *ngFor="let item of stateArray" [ngValue]="item.value">{{item.name}}</option>
                            </select>
                            <span class="highlight"></span>
                            <label>State</label>
                        </div>
                    </div>
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <select class="form-control" [(ngModel)]="detail.supplier_city">
                                <option selected="selected" value="">Please Select</option>
                                <option *ngFor="let item of cityArray" [ngValue]="item.value">{{item.name}}</option>
                            </select>
                            <span class="highlight"></span>
                            <label>City</label>
                        </div>
                    </div>
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <input type="text" class="form-control" placeholder=" " [(ngModel)]="detail.supplier_pincode" />
                            <span class="highlight"></span>
                            <label>Pincode</label>
                        </div>
                    </div>
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <input type="text" class="form-control" placeholder=" " [(ngModel)]="detail.supplier_name" />
                            <span class="highlight"></span>
                            <label>Name this address as (optional)</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="cta  pb-4 text-center">
                <button class="btn btn-mod btn-color btn-medium btn-round ripple mnbtn250"
                     (click)="updateorderdetails()">Update Address</button>

            </div>

    </div>
</ng-template>
<!-- /Supplier Registered ADDRESS -->

<!-- Super seller Billing ADDRESS -->
<ng-template #editssbillingaddressModal let-modal>
    <div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title text-center cg-ttl pb-2">Update E-commerce Seller Billing Address</h5>
            <div class="form-container mdl-frm-lbl mb-3">
                <div class="row">
                    <div class="col-sm-12 mb-4">
                        <div class="floating-label mb-0">
                            <textarea class="form-control" placeholder="" [(ngModel)]="detail.ss_billing_address"></textarea>
                            <span class="highlight"></span>
                            <label>Address</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <select class="form-control" [(ngModel)]="detail.ss_billing_state" (change)="getcity('ss_billing')">
                                <option selected="selected" value="">Please Select</option>
                                <option *ngFor="let item of stateArray" [ngValue]="item.value">{{item.name}}</option>
                            </select>
                            <span class="highlight"></span>
                            <label>State</label>
                        </div>
                    </div>
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <select class="form-control" [(ngModel)]="detail.ss_billing_city">
                                <option selected="selected" value="">Please Select</option>
                                <option *ngFor="let item of cityArray" [ngValue]="item.value">{{item.name}}</option>
                            </select>
                            <span class="highlight"></span>
                            <label>City</label>
                        </div>
                    </div>
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <input type="text" class="form-control" placeholder=" " [(ngModel)]="detail.ss_billing_pincode" />
                            <span class="highlight"></span>
                            <label>Pincode</label>
                        </div>
                    </div>
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <input type="text" class="form-control" placeholder=" " [(ngModel)]="detail.ss_billing_name" />
                            <span class="highlight"></span>
                            <label>Name this address as (optional)</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="cta  pb-4 text-center">
                <button class="btn btn-mod btn-color btn-medium btn-round ripple mnbtn250"
                     (click)="updateorderdetails()">Update Address</button>

            </div>

    </div>
</ng-template>
<!-- /Super Seller Billing ADDRESS -->

<!-- Super seller Shipping ADDRESS -->
<ng-template #editssshippingaddressModal let-modal>
    <div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title text-center cg-ttl pb-2">Update E-commerce Seller Shipping Address</h5>
            <div class="form-container mdl-frm-lbl mb-3">
                <div class="row">
                    <div class="col-sm-12 mb-4">
                        <div class="floating-label mb-0">
                            <textarea class="form-control" placeholder="" [(ngModel)]="detail.ss_shipping_address"></textarea>
                            <span class="highlight"></span>
                            <label>Address</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <select class="form-control" [(ngModel)]="detail.ss_shipping_state" (change)="getcity('ss_shipping')">
                                <option selected="selected" value="">Please Select</option>
                                <option *ngFor="let item of stateArray" [ngValue]="item.value">{{item.name}}</option>
                            </select>
                            <span class="highlight"></span>
                            <label>State</label>
                        </div>
                    </div>
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <select class="form-control" [(ngModel)]="detail.ss_shipping_city">
                                <option selected="selected" value="">Please Select</option>
                                <option *ngFor="let item of cityArray" [ngValue]="item.value">{{item.name}}</option>
                            </select>
                            <span class="highlight"></span>
                            <label>City</label>
                        </div>
                    </div>
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <input type="text" class="form-control" placeholder=" " [(ngModel)]="detail.ss_shipping_pincode" />
                            <span class="highlight"></span>
                            <label>Pincode</label>
                        </div>
                    </div>
                    <div class="col-sm-6 mb-4">
                        <div class="floating-label mb-0">
                            <input type="text" class="form-control" placeholder=" " [(ngModel)]="detail.ss_shipping_name" />
                            <span class="highlight"></span>
                            <label>Name this address as (optional)</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="cta  pb-4 text-center">
                <button class="btn btn-mod btn-color btn-medium btn-round ripple mnbtn250"
                    (click)="updateorderdetails()">Update Address
				</button>
            </div>

    </div>
</ng-template>
<!-- /Super Seller Shipping ADDRESS -->

<!-- RETURN REQUEST -->
<ng-template #returnrequest let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>

    <div class="modal-body">
        <div class="row align-items-center">
            <div class="col-sm-7">
                <div class="">
                    <div class="rtrqsthead">
                        <h4 class="txtdefault wt700 fs22">Return Request</h4>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 pr-0 rtnrqtmdl">
                            <div class="d-table mb-4 w-100">
                                <div class="d-table-cell cart-thumb align-middle">
                                    <img src="{{ detail.product_image }}" />
                                </div>
                                <div class="d-table-cell pl-2 align-middle">
                                    <p class="txtdefault wt700 mb-0 fs12">{{ detail.product_name }}
                                    </p>
                                    <p class="txtseconday my-1 fs12"><span class="txtdefault wt500">Order ID: </span>
                                        {{ detail.order_no }}</p>
                                    <div class="badge-d-outline odbdge d-inline fs10" *ngIf="detail.return_request"><b>Return Status:</b> {{ return_status_text }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-container mb-0 mt-0">
                        <div class="row" *ngIf="detail.parentchild != 'yes'">
                            <div class="col-lg-12 ">
                                <div class="floating-label">
                                    <input type="text" placeholder=" " class="form-control" name="return_quantity" [(ngModel)]="return_quantity">
                                    <span class="highlight"></span>
                                    <label>No. of Products</label>
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="detail.parentchild == 'yes'">
                            <div class="col-lg-12" *ngFor="let ch of detail.parentchild_records">
                                <div class="floating-label">
                                    <input type="text" placeholder=" " class="form-control" name="return_quantity" [(ngModel)]="ch.return_qty">
                                    <span class="highlight"></span>
                                    <label>{{ ch.name }} - No. of Products</label>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="!detail.return_request">
                            <div class="col-lg-12 ">
                                <div class="floating-label">
                                    <input type="file" placeholder=" " class="form-control" name="return_images" multiple (change)="imageUpload($event)">
                                    <span class="highlight"></span>
                                    <label>Products Images</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 ">
                                <div class="floating-label">
                                    <input type="text" placeholder=" " class="form-control" name="return_reason" [(ngModel)]="return_reason">
                                    <span class="highlight"></span>
                                    <label>Reason for Cancellation</label>
                                </div>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-lg-12 ">
                                <div class="floating-label">
                                    <textarea placeholder="" name="return_message" [(ngModel)]="return_message" class="form-control txtareaht80"></textarea>
                                    <span class="highlight"></span>
                                    <label>Message to Seller</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="client-thumbnails dtlthmb odimgsz text-center img-option-list" *ngIf="detail.return_request">
                        <ngx-slick-carousel class="carousel thumbs" #slickModal="slick-carousel"
                            [config]="thumbnailsSlider ">
                            <div ngxSlickItem *ngFor="let slide of return_updated_image" class="slide">
                                <a class="d-inline-block w-100" href="javascript:void(0);">
                                    <img src="{{ slide.img }}" alt="">
                                </a>
                                <!-- <p class="fs12 txtseconday mb-0">Front image*</p> -->
                            </div>
                        </ngx-slick-carousel>
                    </div>
                    <div class="text-right" *ngIf="return_status == 0">
                        <a href="javascript:void(0);" class="btn buybtn-success fs16 radius10 ripple mr-2" (click)="submitreturn('approve')">Approve</a>
                        <a href="javascript:void(0);" class="btn-d-outline btn-danger-outline d-inline-block ml-sm-3 ml-2" (click)="submitreturn('reject')">Reject</a>
                    </div>
                    <div class="text-right" *ngIf="return_status == 1 || return_status == 3 || return_status == 6">
                        <a href="javascript:void(0);" class="btn-d-outline btn-danger-outline d-inline-block ml-sm-3 ml-2" (click)="modal.dismiss('Cross click')">Cancel</a>
                    </div>
                    <div class="text-right" *ngIf="return_status == 4">
                        <a href="javascript:void(0);" class="btn buybtn-success fs16 radius10 ripple mr-2" (click)="initpickup('readytopickup')">Ready to Pickup</a>
                        <a href="javascript:void(0);" class="btn-d-outline btn-danger-outline d-inline-block ml-sm-3 ml-2" (click)="modal.dismiss('Cross click')">Cancel</a>
                    </div>
                    <div class="text-right" *ngIf="return_status == 5">
                        <a href="javascript:void(0);" class="btn buybtn-success fs16 radius10 ripple mr-2" (click)="initpickup('delivered')">Delivered</a>
                        <a href="javascript:void(0);" class="btn-d-outline btn-danger-outline d-inline-block ml-sm-3 ml-2" (click)="modal.dismiss('Cross click')">Cancel</a>
                    </div>
                </div>
            </div>
            <div class="col-sm-5 text-center">
                <div class="feedback-slides  ">
                    <div class="client-feedback dtlfull">
                        <ngx-slick-carousel class="carousel feedback" #slickModal="slick-carousel"
                            [config]="imagesSlider">
                            <div ngxSlickItem *ngFor="let slide of return_updated_image" class="slide ">
                                <div class="thmbnaildtl">
                                    <img src="{{ slide.img }}" alt="" width="100%">
                                </div>
                            </div>
                        </ngx-slick-carousel>
                    </div>
                </div>
                <p class="fs10 txtseconday pt-3">Image Preview</p>
            </div>
        </div>
    </div>

</ng-template>
<!-- /RETURN REQUEST -->

<!-- PROCEED TO PAY -->					
<ng-template #proceedtopay let-modal>
	<div class="text-right pt-3 pr-3 od-mdl-tl">
	<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
	</div>
  
  <div class="modal-body pb-3">
    <div class="row align-items-center">
		<div class="col-sm-12">
			<div class=""> 
				<div class="rtrqsthead"> 
					<h4 class="txtdefault wt700 fs18">Proceed to pay</h4>
				</div>
                <div class="row">
                    <div class="col-lg-12 pr-0 rtnrqtmdl">
                        <div class="d-table mb-4 w-100">
                            <div class="d-table-cell cart-thumb align-middle">
                                <img src="{{ detail.product_image }}" />
                            </div>
                            <div class="d-table-cell pl-2 align-middle">
                                <p class="txtdefault wt700 mb-0 fs12">{{ detail.product_name }}
                                </p>
                                <p class="txtseconday my-1 fs12"><span class="txtdefault wt500">Order ID: </span>
                                    {{ detail.order_no }}</p>
                            </div>
                        </div>
                    </div>
                </div>
				<div class="form-container mb-0 mt-0">	
					<div class="row">
						<p>Do you want to proceed this payment?</p>
					</div>
				</div>
					
			<div class="text-center">
                <a href="javascript:void(0);" class="btn px-4 buybtn fs16 radius10 btn-success" (click)="proceedtopayment()">Proceed</a>
				<a href="javascript:void(0);" class="btn-d-outline btn-danger-outline d-inline-block ml-sm-3 ml-2" (click)="modal.dismiss('Cross click')">Cancel</a>
			</div>
		  </div>
		</div>
	
	</div>
	</div>
 
</ng-template>
<!-- /PROCEED TO PAY -->