<!-- add array of elements dynamically  -->

<h4>Text Editor</h4>

<!-- <angular-editor [placeholder]="'Enter text here...'" [(ngModel)]="htmlContent" [config]="config"></angular-editor> -->

<!-- Reactive form array element -->

<form [formGroup]="form" (ngSubmit)="submit()">
  <input type="text" formControlName="fullName" />
  <div
    formArrayName="items"
    *ngFor="let item of form.get('items')['controls']; let i = index"
  >
    <div [formGroupName]="i">
      <input formControlName="name" />
      <input formControlName="surname" />
    </div>
  </div>

  <button type="button" (click)="addNext()">Add Next</button>
  <button type="submit">Submit (look at console)</button>
</form>

<br><br><br><br>


<!-- Angular Drag and Drop -->

<div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
  <div class="example-box" *ngFor="let movieName of Movies" cdkDrag>{{movieName}}</div>
</div>

<br><br><br>


<!-- Testing required if -->

<h2>My form</h2>
  
<form [formGroup]="validationForm" 
(ngSubmit)="onSubmit()">
  <div>
    Make email mandatory <input formControlName="myCheckbox" type="checkbox" (change)="change()">
  </div>
  <div>
    Email: <input formControlName="myEmailField"
                  type="email">
  </div>
  <button [formAction]="submit">Submit</button>
</form>

<h2>Debug</h2>
<ul>
	<li>Is my form  valid? {{validationForm.valid}}</li>
	<li><pre>{{validationForm.controls.myEmailField.errors| json}}</pre></li>
</ul>
<br><br><br><br>


<h2>Date Format</h2>
<div class="container" [formGroup]="datepickerGroup">
  <hr>
  <h1>Hello ng-bootstrap!</h1>
  <hr>  

  <input type="text" formcontrolName="gstin" value="">
  <br><br>

  <input 
    class="form-control"
    [formControlName]="'last_gst_date'"
    ngbDatepicker 
    #d="ngbDatepicker" (click)="d.toggle()"
    />

    

  <button (click)="submitForm()">Submit Form</button>

</div>  

<br/><br/><br/><br/><br/><br/>

<h4>Cash free Demo</h4>
<button (click)="makepayment()">pay now</button>

<div id="drop_in_container"></div>


<br/><br/><br/><br/><br/><br/>
<h4>Apex Chart</h4>
<br/><br/>
<div id="chart" style="width: 500px;">
    <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [xaxis]="chartOptions.xaxis"
        [dataLabels]="chartOptions.dataLabels"
        [grid]="chartOptions.grid"
        [stroke]="chartOptions.stroke"
        [title]="chartOptions.title"
    ></apx-chart>
</div>



<br/><br/>
<h4>Custom Credit Card Form</h4>
<input id="cc-number" type="tel" autocomplete="cc-number" ccNumber>
<br/>
<input type="tel" ccNumber #ccNumber="ccNumber">
<span class="scheme">{{ccNumber.resolvedScheme$ | async}}</span>
<br/>
<input id="cc-exp-date" type="tel" autocomplete="cc-exp" ccExp>
<br/>
<input id="cc-cvc" type="tel" autocomplete="off" ccCVC>
<br/>

<form  [formGroup]="creditCardForm" #demoForm="ngForm" (ngSubmit)="cardsubmit(demoForm)" novalidate>
    <input id="cc-number" formControlName="creditCard" type="tel" autocomplete="cc-number" ccNumber>
    <input id="cc-exp-date" formControlName="expirationDate" type="tel" autocomplete="cc-exp" ccExp>
    <input id="cc-cvc" formControlName="cvc" type="tel" autocomplete="off" ccCvc>
</form>
<br/><br/>
<br/><br/>
<h4>DAte range picker</h4>
<div class = “container”>
  Date: <input type="text"  placeholder="Datepicker"
  class="form-control" bsDatepicker><br>
  </div>
  <br/><br/><br/><br/>

