<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-12 col-12 pr-0">
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);" [routerLink]="['/admin/product-listing']">Product Management</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" [routerLink]="['/admin/product-listing']">Product Listing</a>
						</li>
						<li class="breadcrumb-item active">{{ product_info.product_name }}</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				&nbsp;
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="row d-flex align-items-center mb-20">
			<div class="col-md-6">
				<h3 class="f-700 fs22 txtdefault pb-0"><a href="javascript:;" class="go-back"  [routerLink]="['/admin/product-listing']"><i class="fa fa-angle-left"></i></a> View Details</h3>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:void(0);" (click)="openpreview()" class="btn buybtn fs16 radius10">
					<svg xmlns="http://www.w3.org/2000/svg" width="17.391" height="12.976" viewBox="0 0 17.391 12.976">
					  <g id="Icon_feather-eye" data-name="Icon feather-eye" transform="translate(-0.9 -5.4)">
					    <path id="Path_10492" data-name="Path 10492" d="M1.5,11.888S4.444,6,9.6,6s8.1,5.888,8.1,5.888-2.944,5.888-8.1,5.888S1.5,11.888,1.5,11.888Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
					    <path id="Path_10493" data-name="Path 10493" d="M17.916,15.708A2.208,2.208,0,1,1,15.708,13.5,2.208,2.208,0,0,1,17.916,15.708Z" transform="translate(-6.112 -3.82)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
					  </g>
					</svg>
					Buyer preview
				</a>
			</div>
		</div>

		<div class="row mb-40">
			<div class="col-md-8">
				<div class="bid-order-info">
					<div class="order-img">
						<img [src]="product_info.image" />
					</div>
					<div class="pd-seller-cntnt">
						<!--Product Name-->
						<h5 class="mb-10 fs18">{{ product_info.product_name }}</h5>
						<!--Category Name-->
						<a href="javascript:;" (click)="openeditcategory(editcategory)">	
							<span class="pd-taglist md">
								<span class="radius15">{{ product_info.category_status == 2 ? 'Uncategorized' : product_info.category_name }} <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10.141" height="10.141" viewBox="0 0 10.141 10.141">
								  <defs>
								    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
								      <stop offset="0" stop-color="#79b7ff"/>
								      <stop offset="1" stop-color="#07f"/>
								    </linearGradient>
								  </defs>
								  <path id="np_edit_3094244_000000" d="M8.238-.008A1.894,1.894,0,0,0,6.895.548L.522,6.921,3.21,9.608,9.583,3.236A1.9,1.9,0,0,0,8.238-.008ZM.193,7.487,0,9.79a.317.317,0,0,0,.342.342l2.3-.2Z" transform="translate(0.003 0.008)" fill="url(#linear-gradient)"/>
								</svg>
								</span>
							</span>
						</a>	
						<!--Edit / Delete-->
						<div class="text-left light-text mt-10">
							<a href="javascript:;" class="link-alt" [routerLink]="['/admin/products/add/', uniqueId]"><img src="assets/images/icons/edit-big.png">Edit Template</a>
							&nbsp; | &nbsp;
							<a href="javascript:;" (click)="open(content)" *ngIf="product_info.status != 2"><img src="assets/images/icons/delete-icon.svg"></a>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="d-flex align-items-center justify-content-end">
					<i placement="top" ngbTooltip="This will act as a default filter every time you will toggle.">
						<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
						  <g id="Group_68" data-name="Group 68" transform="translate(-545 -281)">
							<circle id="Ellipse_1" data-name="Ellipse 1" cx="7.5" cy="7.5" r="7.5" transform="translate(545 281)" fill="#07f"/>
							<path id="Path_2235" data-name="Path 2235" d="M7,8.171l-.128.523q-.576.227-.919.346a2.425,2.425,0,0,1-.8.119,1.591,1.591,0,0,1-1.084-.34,1.1,1.1,0,0,1-.387-.864,3.056,3.056,0,0,1,.029-.416q.029-.213.093-.48l.48-1.7q.064-.244.108-.462a2.01,2.01,0,0,0,.044-.4A.616.616,0,0,0,4.3,4.047a.759.759,0,0,0-.513-.128,1.345,1.345,0,0,0-.381.058c-.131.039-.242.076-.336.11L3.2,3.564q.471-.192.9-.329A2.7,2.7,0,0,1,4.916,3.1a1.552,1.552,0,0,1,1.068.335,1.111,1.111,0,0,1,.375.869c0,.074-.008.2-.026.39a2.606,2.606,0,0,1-.1.512L5.759,6.9a4.492,4.492,0,0,0-.1.466,2.4,2.4,0,0,0-.047.4.57.57,0,0,0,.15.459.85.85,0,0,0,.523.122,1.509,1.509,0,0,0,.4-.061A2.21,2.21,0,0,0,7,8.171Zm.121-7.1a.99.99,0,0,1-.334.753,1.138,1.138,0,0,1-.8.312,1.15,1.15,0,0,1-.809-.312.988.988,0,0,1-.337-.753A1,1,0,0,1,5.169.314a1.194,1.194,0,0,1,1.614,0A1,1,0,0,1,7.117,1.07Z" transform="translate(547.406 283.92)" fill="#fff"/>
						  </g>
						</svg>
					</i>&nbsp; Template Status &nbsp;&nbsp;
					<div class="tglbtn">
						<label class="switch">
						  <input type="checkbox" [checked]="product_status" (click)="changestatus('status')">
						  <span class="slider round"></span>
						</label>
					</div>
				</div>
			</div>
		</div>

		<h4 class="f-700 fs20 txtdefault pb-0">Seller Listing</h4>
		<div class="row d-flex mt-20 report-cta mb-20">
			<div class="col-md-5">
				<div class="report-input">
					<div class="input-group-custom">
						<input type="text" placeholder="Search by Seller Name, GSTIN, PAN No. & Email" class="form-control" [(ngModel)]="search_term" (ngModelChange)="getsellerlisting()">
						<div class="input-group-append-custom">
							<img src="assets/images/svg/search_logo.svg">
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="col-md-3 pl-0">
				<a href="javascript:void(0);" class="d-inline-block fltr-round-btn radius10 txtseconday">
					<img src="assets/images/svg/filter.svg">
					Filters
				</a>
			</div> -->
		</div>

		<div class="card card-ui card-uiovt byregwrp">
			<div class="table-responsive">
				<table class="table custom-table">
				  <thead>
					  <tr>
						<th width="18%">Seller Name</th>
						<th width="12%">Seller Type</th>
						<th width="12%">Market Segment</th>
						<th>No. of Variants</th>
						<th>Listed Price</th>
						<th>Total Listed Quantity</th>
						<th width="25%">Action</th>
					  </tr>
				  </thead>
				  <tbody>
					<!-- REPEAT -->
					<tr *ngFor="let listing of sellerListing; let i = index;">
						<td>
							<div class="order-info">
								<div class="order-img">
									<img [src]="listing.logo" />
								</div>
								<div class="pd-seller-cntnt">
									<div class="txtdefault">
										<a href="javascript:;" class="order-link">{{ listing.name }}</a>
									</div>
								</div>
							</div>
						</td>
						<td>{{ listing.seller_type }}</td>
						<td>{{ listing.maps }}</td>
						<td>{{ listing.variants }}</td>
						<td>₹ {{ listing.price }}</td>
						<td>{{ listing.qty }}</td>
						<td>
							<div class="d-flex align-items-center justify-content-between">
								<a href="javascript:;" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Orders</a>
								<div class="light-text">
									<a [routerLink]="['/admin/edit-seller-listing/'+listing.id+'/'+listing.uniqueId]"><img src="assets/images/icons/edit-big.png"></a>
									&nbsp; | &nbsp;
								</div>
								<div>
									<div class="tglbtn">
										<label class="switch">
										  <input type="checkbox" [(ngModel)]="listing.listing_status">
										  <span class="slider round"></span>
										</label>
									</div>
								</div>
							</div>
						</td>
					</tr>
					<!-- /REPEAT -->	
					<!--Empty State-->
					<tr *ngIf="!sellerListing.length">
						<td colspan="7">
							<div class="row pt-40 pb-40">
								<div class="col-md-12 text-center">
									<div class="mb-20"><img src="/assets/images/empty-state.png"></div>
									<div class="fs16">There are no records to display!</div>
								</div>
							</div>
						</td>
					</tr>
					<!--End Empty State-->				
				  </tbody>
				</table>
			</div> 
		</div>
		<div class="row" *ngIf="sellerListing.length > 0">
			<div class="col-md-6">
				<div class="text-left pl-3">
					<nav class="mt-3 tbpg rows-per-page">
						<div>Rows per page:</div>
						<select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
							<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">{{option.name}}</option>
						</select>
					</nav>
				</div> 
			</div>
			<div class="col-md-6" *ngIf="seller_listing_pagination.pages && seller_listing_pagination.pages.length">
				<div class="text-right pr-3">
					<nav class="mt-3 tbpg">
					  <ul class="pagination align-items-center">
						<li class="page-item" [ngClass]="{disabled:seller_listing_pagination.currentPage === 1}"><a class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a></li>
						<li class="page-item" [ngClass]="{disabled:seller_listing_pagination.currentPage === 1}"><a class="page-link sprevicon" href="javascript:;" (click)="setpage(seller_listing_pagination.currentPage - 1)"></a></li>
						<li class="page-item"i *ngFor="let page of seller_listing_pagination.pages" [ngClass]="{active:seller_listing_pagination.currentPage === page}"><a class="page-link" href="javascript:;" (click)="setpage(page)">{{page}}</a></li>
						<li *ngIf="(seller_listing_pagination.totalPages - seller_listing_pagination.startPage) > 5 ">
							<a (click)="setpage(seller_listing_pagination.startPage + 5)">...</a>
						</li>
						 <li *ngIf="(seller_listing_pagination.totalPages - seller_listing_pagination.startPage) > 5" >
							<a (click)="setpage(seller_listing_pagination.totalPages)">{{seller_listing_pagination.totalPages}}</a>
						</li>
						<li class="page-item" [ngClass]="{disabled:seller_listing_pagination.currentPage === seller_listing_pagination.totalPages}"><a class="page-link snexticon" href="javascript:;"  (click)="setpage(seller_listing_pagination.currentPage + 1)"></a></li>
						<li class="page-item" [ngClass]="{disabled:seller_listing_pagination.currentPage === seller_listing_pagination.totalPages}"><a class="page-link nexticon" href="javascript:;" (click)="setpage(seller_listing_pagination.totalPages)"></a></li>
					  </ul>
					</nav>
				</div> 
			</div>
		</div>
	</div>
</div>

<!-- Remove Product -->
<ng-template #content let-modal>
	<div class="modal-body mdlcmnui pt-4 mt-3">
        <h5 class="modal-title text-center mb-20">Remove Product</h5>

        <div class="bid-order-info">
			<div class="order-img">
				<img src="assets/images/icons/info.png" />
			</div>
			<div class="pd-seller-cntnt">
				<div class="fs12">Removing the product will lead to removing this product from Equibiz. The buyer and seller won’t be able to see this product. No more orders can be placed.</div>
			</div>
		</div>

		<div class="modal-subtitle text-center pt-30 pb-30">Are you sure you want to remove the product?</div>

        <div class="bid-order-info">
			<div class="order-img">
				<img [src]="product_info.image" />
			</div>
			<div class="pd-seller-cntnt">
				<h5 class="mb-10 fs16">{{ product_info.product_name }}</h5>
			</div>
		</div>

        <div class="text-center pt-30 pb-20">
           <button type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')" class="buybtn btnprev a-cmn-bd btn radius10 ripple fs16 px-4 mr-4">
		      Cancel
		   	</button>
		   	<button type="button" class="btn btn-mod btn-color btn-medium btn-round ripple px-4" (click)="changestatus('remove')">
		      Remove
		   	</button>
        </div>
      </div>
</ng-template>

<!-- Edit Category -->
<ng-template #editcategory let-modal>
	<div class="modal-body mdlcmnui pt-4 mt-3">
        <h5 class="modal-title text-center mb-20">Edit Category</h5>

        <div class="bid-order-info">
			<div class="order-img">
				<img src="assets/images/p-2.jpg" />
			</div>
			<div class="pd-seller-cntnt">
				<h5 class="mt-10 fs16">{{ product_info.brand_name }} {{ product_info.model_number }}  | {{ product_info.product_name }}</h5>
			</div>
		</div>

		<div class="text-right mt-20 mb-10">
			<a href="javascript:;" (click)="managecategory()" class="link-alt color-orange">Manage Product Categories</a>
		</div>
		<div class="row mt-10">
			<div class="col-md-12">
				<div class="form-container mdl-frm-lbl mb-4 mt-0">
					<div class="floating-label mb-0">      
						<div class="ng-autocomplete">
							<ng-autocomplete 
								[data]="result" 
								[searchKeyword]="keyword" 
								(selected)='selectEvent($event)'
						        (inputChanged)='onChangeSearch($event)' 
						        (inputFocused)='onFocused($event)' 
						        [itemTemplate]="itemTemplate"
						        [notFoundTemplate]="notFoundTemplate" 
								placeholder="Search by Category Name" >
						    </ng-autocomplete>
						    <ng-template #itemTemplate let-item>
						        <a href="javascript:void(0)">
						            <div class="d-flex">
						                <span [innerHTML]="item.name"></span>
						                <!-- <span [innerHTML]="item.current" class="current"></span> -->
						            </div>
						        </a>
						    </ng-template>

						    <ng-template #notFoundTemplate let-notFound>
						        <div [innerHTML]="notFound"></div>
						    </ng-template>
							<div class="search-icon"><img src="assets/images/svg/search_logo.svg"></div>
						</div>
					</div> 
				</div>
			</div>
		</div>
      </div>
</ng-template>

<app-preview #preview></app-preview>