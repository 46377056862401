import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate {
    constructor(private auth: AuthService, private router: Router) {

    }
    canActivate() {
        if (this.auth.isLoggedIn() && this.auth.loggedInUserType(3)) {
            return true;
        }
        this.auth.logoutUser();
        this.router.navigate(['/home']);
        return false;
    }

}
