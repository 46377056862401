import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { AdminOrderService } from './order.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
    public export_path:string = '';
    public export_loading:boolean = false;
    public download:string = '';

    closeResult = '';
    public search_term:string = '';
    public from_date:any = '';
    public to_date:any = '';

    constructor(
      private modalService: NgbModal,
      private _toastr: ToasterService,
      private _pagination: PaginationService,
      private _orderservice: AdminOrderService
    ) { }
    public order_type:string = 'all';


    public map_type:string = 'all';
    public allorder_records: any = [];
    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 20;
    public allorder_pagination: any = {};
    public page_per_view_options = [
        {
            value: 20,
            name: 20
        },
        {
            value: 40,
            name: 40
        }
    ];

    ngOnInit(): void {
        this.getorders('all');
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.adminorders();
    }

    setpageperview() {
        this.current_page = 1;
        this.adminorders();
    }

    getorders(type) {
        this.search_term = '';
        this.from_date = '';
        this.to_date = '';
        this.order_type = type;
        this.page_per_view = 10;
        this.offset = 0;
        this.current_page = 1;
        this.adminorders();
    }

    getordersfilters(download=''){
        this.page_per_view = 10;
        this.offset = 0;
        this.current_page = 1;
        this.download = download;
        this.adminorders();
    }

    adminorders() {
        var type:any = 'all';
        if(this.order_type == 'booked'){
            type = 1
        }else if(this.order_type == 'approved'){
            type = 2
        }else if(this.order_type == 'rejected'){
            type = 3
        }else if(this.order_type == 'payment_done'){
            type = 4
        }else if(this.order_type == 'ready_to_dispatch'){
            type = 6
        }else if(this.order_type == 'quality_done'){
            type = 7
        }else if(this.order_type == 'dispatched'){
            type = 8
        }else if(this.order_type == 'delivered'){
            type = 9
        }

        let data = { 
            limit: this.page_per_view, 
            offset: this.offset, 
            current_page: this.current_page, 
            type: type,
            search: this.search_term,
            from_date: this.from_date,
            to_date: this.to_date,
            map_type: this.map_type,
            download: this.download
        };
        this._orderservice.getorders(data).subscribe((data) => {
            if (data['status'] == "success") {
                if(data['download']){
                    this.export_path = data['path'];
                    this.export_loading = false;
                    this.download = '';
                }else{
                    this.download = '';
                    this.export_loading = false;
                    this.export_path = '';
                    this.allorder_records = data['records'];
                    let total_records = data['total_records'];
                    this.allorder_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
                }
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    open(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true,}).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    openWindowCustomClass(content) {
        this.modalService.open(content, { windowClass: 'modal-500' });
    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
}
