<ng-template class="theme-modal" #signIn let-modal>
	<div class="modal-content popup-container">
      <!--Login with Phone Number Container-->
	    <div class="modal-body" *ngIf="showLoginModal">
         <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
         <span aria-hidden="true">&times;</span>
         </button>
         <h5 class="modal-title">Login <span>or</span> Sign Up</h5>
         <form [formGroup]="phoneForm">
         <div class="form-container">
            <div class="floating-label">      
               <input formControlName="phoneNumber" class="form-control" type="text" placeholder="" [(ngModel)]="phoneNumber">
               <span class="highlight"></span>
               <label>Phone Number</label>
               <span class="help-block" *ngIf="s.phoneNumber.invalid && s.phoneNumber.touched">Please enter valid mobile number</span>
            </div>
         </div>
         <div class="cta">
            <button class="btn btn-mod btn-color btn-medium btn-round ripple btn-block" (click)="accountOTP('no')" [disabled]="phoneForm.invalid || buttonLoading"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="buttonLoading"></span>&nbsp;&nbsp;Continue</button>
         </div>
         </form>
         <div class="seperator"><b>or</b></div>
         <div class="social-login">
            <div class="mb-20">
               <a href="javascript:;" class="btn btn-outline btn-round btn-block dflex" (click)="loginWithGoogle()"><i class="icon google"></i> Continue with Google</a>
            </div>
            <div class="mb-20">
               <a href="javascript:;" class="btn btn-outline btn-round btn-block dflex" (click)="loginWithFacebook()"><i class="icon facebook"></i> Continue with Facebook</a>
            </div>
            <div>
               <a href="javascript:;" class="btn btn-outline btn-round btn-block dflex" (click)=loginwithEmail()><i class="icon email"></i> Continue with Email</a>
            </div>
         </div>
      </div>

      <!--OTP Container-->
      <div class="modal-body text-center" *ngIf="showLoginOTPModal">
         <h5 class="modal-title mb-10">OTP Verification</h5>
         <div class="modal-subtitle" *ngIf="this.signupType == 'mobile'">An OTP has been sent to your<br>mobile number +91 {{this.phoneNumber}} <a href="javascript:;" (click) = loginWithPhone()><i class="edit-icon"></i></a></div>
         <div class="modal-subtitle" *ngIf="this.signupType == 'email'">An OTP has been sent to your<br>email {{this.emailAddress}} <a href="javascript:;" (click) = loginwithEmail()><i class="edit-icon"></i></a></div>
         <div class="form-container">
            <form [formGroup]="otpForm" class="mt-40 mb-10">
              <input class="otp" formControlName="otp1" type="text" (keyup)="onDigitInput($event)" maxlength=1 [(ngModel)] = "otp1" autofocus> 
              <!--  form-error -->
              <input class="otp" formControlName="otp2" type="text" (keyup)="onDigitInput($event)" maxlength=1 [(ngModel)] = "otp2">
              <input class="otp" formControlName="otp3" type="text" (keyup)="onDigitInput($event)" maxlength=1 [(ngModel)] = "otp3">
              <input class="otp" formControlName="otp4" type="text" (keyup)="onDigitInput($event)" maxlength=1 [(ngModel)] = "otp4">
            </form>
            <span class="help-block" *ngIf="(o.otp1.invalid && o.otp1.touched) || (o.otp2.invalid && o.otp2.touched) || (o.otp3.invalid && o.otp3.touched) || (o.otp4.invalid && o.otp4.touched)">Incorrect OTP, enter the correct OTP</span>
         </div>

         <div class="pt-20 pb-20">
           <div class="modal-subtitle">Didn’t received code?<br>
              <a href="javascript:;" class="resend" (click)="accountOTP('no')">Resend Code</a>
           </div>
         </div>

         <div class="cta mt-40">
             <button class="btn btn-mod btn-color btn-medium btn-round ripple btn-block" (click)=createpassword() [disabled]="otpForm.invalid || buttonLoading"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="buttonLoading"></span>&nbsp;&nbsp;Login</button>
             <div class="pt-20"><a href="javascript:;" class="resend" (click)=loginwithPassword()>Login with Password</a></div>
         </div>
      </div>

      <!--Set Password Container-->
      <div class="modal-body" *ngIf="showLoginPasswordModal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
            <h5 class="modal-title">Set your Password</h5>
            <form [formGroup]="setPasswordForm">
            <div class="form-container">
               <div class="floating-label mb-0">      
                  <input class="form-control" type="password" placeholder="" formControlName="password" [(ngModel)]="password">
                  <span class="highlight"></span>
                  <label>Password</label>
               </div>
               <div class="alt-service-item">
                  <div class="alt-service-icon">
                     <i class="info-alert"></i>
                  </div>
                  Atleast use 8 characters with a mix of letters, numbers, and special character.
               </div>

               <div class="floating-label mt-20 mb-0">      
                  <input class="form-control" type="password" placeholder="" formControlName="confirm_password" [(ngModel)]="confirm_password">
                  <span class="highlight"></span>
                  <label>Confirm Password</label>
               </div>
               <div class="alt-service-item">
                  <div class="alt-service-icon">
                     <i class="info-alert"></i>
                  </div>
                  Should be same as above.
               </div>
               <!-- <span class="help-block" *ngIf="sp.confirm_password.errors.confirmedValidator">Password and Confirm Password must be match.</span> -->
            </div>   

            <div class="cta mt-40">
                <button class="btn btn-mod btn-color btn-medium btn-round ripple btn-block" (click)=setpassword() [disabled]="setPasswordForm.invalid || buttonLoading"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="buttonLoading"></span>&nbsp;&nbsp;Sign Up</button>
            </div>
          </form>
            <div class="modal-subtitle mt-20"><a href="javascript:;" class="resend" (click)=ngOnDestroy()>Back to Home</a></div>
      </div>

      <!--Re-set Password Container-->
      <div class="modal-body" *ngIf="showResetPasswordModal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
            <h5 class="modal-title">Reset your Password</h5>
            <form [formGroup]="resetPasswordForm">
            <div class="form-container">
               <div class="floating-label mb-0">      
                  <input class="form-control" type="password" placeholder="" formControlName="password" [(ngModel)]="password">
                  <span class="highlight"></span>
                  <label>Password</label>
               </div>
               <div class="alt-service-item">
                  <div class="alt-service-icon">
                     <i class="info-alert"></i>
                  </div>
                  Atleast use 8 characters with a mix of letters, numbers, and special character.
               </div>

               <div class="floating-label mt-20 mb-0">      
                  <input class="form-control" type="password" placeholder="" formControlName="confirm_password" [(ngModel)]="confirm_password">
                  <span class="highlight"></span>
                  <label>Confirm Password</label>
               </div>
               <div class="alt-service-item">
                  <div class="alt-service-icon">
                     <i class="info-alert"></i>
                  </div>
                  Should be same as above.
               </div>
               <!-- <span class="help-block" *ngIf="sp.confirm_password.errors.confirmedValidator">Password and Confirm Password must be match.</span> -->
            </div>   

            <div class="cta mt-40">
                <button class="btn btn-mod btn-color btn-medium btn-round ripple btn-block" (click)=resetpassword() [disabled]="resetPasswordForm.invalid || buttonLoading"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="buttonLoading"></span>&nbsp;&nbsp;Submit</button>
            </div>
          </form>
            <!-- <div class="modal-subtitle mt-20"><a href="javascript:;" class="resend" (click)=ngOnDestroy()>Back to Home</a></div> -->
      </div>

      <!--Enter Personal Details-->
      <!-- <div class="modal-body" *ngIf="showPersonalDetailsModal">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title">Personal Details</h5>

          <div class="form-container">
             <div class="floating-label mb-0">      
                <input class="form-control" type="first_name" placeholder=" ">
                <span class="highlight"></span>
                <label>First Name</label>
             </div>
             <div class="floating-label mt-20 mb-0">      
                <input class="form-control" type="last_name" placeholder=" ">
                <span class="highlight"></span>
                <label>Last Name</label>
             </div>
             <div class="floating-label mt-20 mb-0">      
                <input class="form-control" type="phone_number" placeholder=" ">
                <span class="highlight"></span>
                <label>Phone Number</label>
             </div>
             <div class="floating-label mt-20 mb-0">      
                <input class="form-control" type="email_address" placeholder=" ">
                <span class="highlight"></span>
                <label>Email Address</label>
             </div>
          </div>   

          <div class="cta mt-40">
              <a [routerLink]="['/registration-buyer']" *ngIf="userType != 'seller'" class="btn btn-mod btn-color btn-medium btn-round ripple btn-block">Submit</a>
              <a [routerLink]="['/registration-seller']" *ngIf="userType == 'seller'" class="btn btn-mod btn-color btn-medium btn-round ripple btn-block">Submit</a>
          </div>
      </div> -->

      <!--Login with Email Container-->
      <div class="modal-body" *ngIf="showLoginwithEmailModal">
         <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
         <span aria-hidden="true">&times;</span>
         </button>
         <h5 class="modal-title">Login <span>or</span> Sign Up</h5>
         <form [formGroup]="emailForm">
         <div class="form-container">
            <div class="floating-label">      
               <input class="form-control" type="text" placeholder=" " formControlName="emailAddress" [(ngModel)] = "emailAddress">
               <span class="highlight"></span>
               <label>Email ID</label>
            </div>
         </div>
         <div class="cta">
            <button class="btn btn-mod btn-color btn-medium btn-round ripple btn-block" (click)=verifyemail() [disabled]="emailForm.invalid || buttonLoading"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="buttonLoading"></span>&nbsp;&nbsp;Continue</button>
         </div>
         </form>
         <div class="seperator"><b>or</b></div>
         <div class="social-login">
            <div class="mb-20">
               <a href="javascript:;" class="btn btn-outline btn-round btn-block dflex"  (click)="loginWithGoogle()"><i class="icon google"></i> Continue with Google</a>
            </div>
            <div class="mb-20">
               <a href="javascript:;" class="btn btn-outline btn-round btn-block dflex"  (click)="loginWithFacebook()"><i class="icon facebook"></i> Continue with Facebook</a>
            </div>
            <div>
               <a href="javascript:;" class="btn btn-outline btn-round btn-block dflex" (click)="loginWithPhone()"><i class="icon phone"></i> Continue with Phone Number</a>
            </div>
         </div>
      </div>

      <!--Verify Email Container-->
      <div class="modal-body text-center" *ngIf="verifyEmailModal">
        <div class="verify-icon">
          <img src="assets/images/icons/verify-email-info.svg">
        </div>

        <div class="body-content">
          <h5 class="modal-title mb-10">Verify Email</h5>
          <div class="modal-subtitle">Please click on the link that has been sent to {{ emailAddress }} to continue!</div>
        </div>
        <div class="pt-50 pb-0">
          <div class="modal-subtitle">Didn’t received link?<br>
            <a href="javascript:;" class="resend">Resend link</a>
          </div>
        </div>
      </div>

      <!--Login with Password Container-->
      <div class="modal-body" *ngIf="loginWithPasswordModal">
         <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
         <span aria-hidden="true">&times;</span>
         </button>
         <form [formGroup]="passwordForm">
         <h5 class="modal-title">Enter Password</h5>
         <div class="modal-subtitle">Enter the password for account<br>{{ signupType=="email"? emailAddress: phoneNumber }} <a href="javascript:;" (click) = gotofirstpage()><i class="edit-icon"></i></a></div>
         <div class="form-container mb-10">
            <div class="floating-label mb-0">      
               <input class="form-control" type="password" placeholder="" formControlName="login_password" [(ngModel)]="login_password">
               <span class="highlight"></span>
               <label>Password</label>
            </div>
         </div>
         <div class="help-block" *ngIf="p.login_password.invalid && p.login_password.touched">Please enter the correct password</div>
         <div class="text-right pb-60">
            <a href="javascript:;" class="resend" (click) = forgotPassword()>Forgot Password?</a>
          </div>
          <div class="cta mt-40">
            <button class="btn btn-mod btn-color btn-medium btn-round ripple btn-block" (click)=verifypassword() [disabled]="passwordForm.invalid || buttonLoading"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="buttonLoading"></span>&nbsp;&nbsp;Login</button>
             <div class="text-center pt-20"><a href="javascript:;" class="resend" (click) = "accountOTP('yes')">Login with OTP</a></div>
         </div>
         </form>
      </div>

      <!--Forgot Password Container-->
      <div class="modal-body" *ngIf="forgotPasswordModal">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title">Password recovery</h5>
          <div class="modal-subtitle">Please select the mode for password recovery</div>
          <div class="form-container mb-10">
            <div class="floating-label floating-group mb-0">     
                <div class="phone-icon"><i class="phone"></i></div> 
                <input class="form-control" type="text" placeholder=" " [(ngModel)]="forgotPasswordPhone">
                <span class="highlight"></span>
                <label>Phone Number</label>
                <div class="form-right-arrow" (click) = forgototpsent()><i class="form-arrow"></i></div>
            </div>
            <div class="alt-service-item">
                <div class="alt-service-icon">
                   <i class="info-alert"></i>
                </div>
                OTP will be send to this Phone Number
            </div>
          </div>

          <div class="form-container mb-10">
            <div class="floating-label floating-group mb-0">     
                <div class="email-icon"><i class="email"></i></div> 
                <input class="form-control" type="text" placeholder=" "  [(ngModel)]="forgotPasswordEmail">
                <span class="highlight"></span>
                <label>Email ID</label>
                <div class="form-right-arrow" (click) = forgotemailsent()><i class="form-arrow"></i></div>
            </div>
            <div class="alt-service-item">
                <div class="alt-service-icon">
                   <i class="info-alert"></i>
                </div>
                Link will be send to this Email ID
            </div>
          </div>
          <div class="cta mt-40 pt-50">
             <div class="text-center pt-20"><a href="javascript:;" class="resend" (click)="loginwithPassword()">I remembered my old password</a></div>
         </div>
      </div>

      <!--Email Sent Container-->
      <div class="modal-body text-center" *ngIf="emailSentModal">
        <div class="verify-icon">
          <img src="assets/images/icons/email-sent.svg">
        </div>

        <div class="body-content pt-0 pb-0">
          <h5 class="modal-title mb-10">Email has been sent!</h5>
          <div class="modal-subtitle">Please click on the link that has been sent to {{ emailAddress }} to reset the password</div>
        </div>
        <div class="pt-50 pb-0">
          <div class="modal-subtitle">Didn’t received link?<br>
            <a href="javascript:;" class="resend">Resend link</a>
          </div>
        </div>
        <div class="cta mt-40 pt-30">
             <div class="text-center pt-0"><a href="javascript:;" class="resend" (click)="loginwithPassword()">I remembered my old password</a></div>
         </div>
      </div>

      <!--Forgot OTP Container-->
      <div class="modal-body text-center" *ngIf="forgotOTPSentModal">
         <h5 class="modal-title mb-10">OTP Verification</h5>
         <div class="modal-subtitle">An OTP has been sent to your<br>mobile number +91 {{forgotPasswordPhone}} <a href="javascript:;" (click) = loginWithPhone()><i class="edit-icon"></i></a></div>

         <div class="form-container">
          <form [formGroup]="resetOtpForm" class="mt-40 mb-10">
              <input class="otp" type="text" formControlName="resetOtp1" (keyup)="onDigitInput($event)" maxlength=1 [(ngModel)] = "resetOtp1">
              <input class="otp" type="text" formControlName="resetOtp2" (keyup)="onDigitInput($event)" maxlength=1 [(ngModel)] = "resetOtp2">
              <input class="otp" type="text" formControlName="resetOtp3" (keyup)="onDigitInput($event)" maxlength=1 [(ngModel)] = "resetOtp3">
              <input class="otp" type="text" formControlName="resetOtp4" (keyup)="onDigitInput($event)" maxlength=1 [(ngModel)] = "resetOtp4">
            </form>
            <span class="help-block" *ngIf="(ro.resetOtp1.invalid && ro.resetOtp1.touched) || (ro.resetOtp2.invalid && ro.resetOtp2.touched) || (ro.resetOtp3.invalid && ro.resetOtp3.touched) || (ro.resetOtp4.invalid && ro.resetOtp4.touched)">Incorrect OTP, enter the correct OTP</span>
         </div>

         <div class="pt-20 pb-20">
           <div class="modal-subtitle">Didn’t received code?<br>
              <a href="javascript:;" class="resend">Resend Code</a>
           </div>
         </div>

         <div class="cta mt-40">
             <button class="btn btn-mod btn-color btn-medium btn-round ripple btn-block" (click)=forgotverifyotp() [disabled]="resetOtpForm.invalid || buttonLoading"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="buttonLoading"></span>&nbsp;&nbsp;Set a new password</button>
             <div class="text-center pt-0"><a href="javascript:;" class="resend" (click)="loginwithPassword()">I remembered my old password</a></div>
         </div>
      </div>

	</div>
</ng-template>
