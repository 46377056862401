import { Component, OnInit, Input, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { LandingHowitWorks } from '../data/slider';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent implements OnInit {
  @Input() class: string;
  @Input() sticky: boolean = false; // Default false

    public stick: boolean = false;
    constructor() { }
    public ProductSliderConfig: any = LandingHowitWorks;
    ngOnInit(): void {

    }
     disabled = false;
    // @HostListener Decorator
    @HostListener("window:scroll", [])
    onWindowScroll() {
        let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (number >= 150 && window.innerWidth > 400) { 
            this.stick = true;
         } else {
             this.stick = false;
         }
    }
}
