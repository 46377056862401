<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain pb-4">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center pinfo-head">
			<div class="col-md-5 mb-4">
				<h3 class="fs24 wt700 txtdefault">Unconfirmed Wallet</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">						
						<li class="breadcrumb-item "><a href="javascript:void(0);" class="txtseconday">Order Management</a></li>
						<li class="breadcrumb-item active">Unconfirmed Wallet</li>
					</ol>
				</nav>
			</div>
			<div class="col-md-7  mb-4 text-md-right text-center">
				
				<a href="javascript:void(0);" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4 mb-0">
			<div class="tab-content">
				<div class="table-responsive brndmgnttable odmgnttabl"> 
					<table class="table custom-table xs">
						<thead>
							<tr>
                                <th>Order ID</th>
                                <th>Buyer Name</th>
                                <th>Business Name</th>
                                <th>Total Amount</th>
                                <th>Transaction ID</th>
                            </tr>
						</thead>
						<tbody>
							<!--Empty State-->
							<tr *ngIf="!page_records.length">
								<td colspan="5">
									<div class="row pt-40 pb-40">
										<div class="col-md-12 text-center">
											<div class="mb-20"><img src="/assets/images/empty-state.png">
											</div>
											<div class="fs16">There are no records to display!</div>
										</div>
									</div>
								</td>
							</tr>
							<!--End Empty State-->
							<tr *ngFor="let rec of page_records;">
								<td>{{ rec.order_no }}</td>
                                <td>
                                    <div class="order-info">
                                        <div class="order-img" *ngIf="rec.logo">
                                            <img src="{{ rec.logo? rec.logo: 'assets/images/p-2.jpg' }}" />
                                        </div>
                                        <div class="pd-seller-cntnt">
                                            <div class="txtdefault">
                                                <a href="javascript:;" class="order-link">{{ rec.name }}</a>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ rec.businessname }}</td>
                                <td>{{ rec.amount }}</td>
                                <td>{{ rec.transaction_id }}</td>
							</tr>
						</tbody>
					</table>
				</div>	
			</div>
		</div>
	</div>
</div>	