import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-seller-view-details',
  templateUrl: './seller-view-details.component.html',
  styleUrls: ['./seller-view-details.component.scss']
})
export class SellerViewDetailsComponent implements OnInit {

    closeResult = '';
    constructor(private modalService: NgbModal) {}
    config = {
      labelField: 'label',
      valueField: 'value',
    
    }; 
    open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'mblbtm' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
    }
    openmdl(profilephoto) {
      this.modalService.open(profilephoto, { windowClass: 'midmodal' });
    }
    openmodal(rejectbuyer) {
      this.modalService.open(rejectbuyer, { ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'mblbtm', size: 'md' });
    }
    ngOnInit(): void {
    }
    monthdata = [
    {
      label: 'In Months',
      value: 'In Months'
    },
    {
      label: 'In Year',
      value: 'In Year'
    }
  ]
}