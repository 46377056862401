<app-header-seller></app-header-seller>
<app-sidebar-seller></app-sidebar-seller>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<h3 class="fs24 wt700 txtdefault">TCS Report</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">My Business Reports</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" [routerLink]="['/tax-reports']">Tax Reports</a>
						</li>
						<li class="breadcrumb-item active">TCS Report</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:void(0);" [routerLink]="['/seller/assistance']" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="card cardui carduipro p-4 mb-0">

			<div class="custom-tabs">
				<ul>
					<li><a [routerLink]="['/gstr-report']">GSTR Payment</a></li>
					<li><a [routerLink]="['/tds-report']">TDS Transaction</a></li>
					<li><a [routerLink]="['/tcs-report']" class="active">TCS Payment</a></li>
				</ul>
			</div>
			
			<!--Filters-->
      		<div class="row d-flex justify-content-between mt-20 report-cta">
				<div class="col-md-4">
					<div class="form-container mt-0 mb-0 mr-3">
						<div class="report-input floating-label">
							<div class="input-group-custom">
								<input type="text" value="27/08/2021 - 27/09/2021" class="form-control" placeholder=" " />
								<span class="highlight"></span>
								<label>Date</label>
								<div class="input-group-append-custom">
									<img src="assets/images/icons/filter-calendar.svg">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!--End Filters-->
        	<table class="table custom-table">
				<thead>
					<tr>
						<th>Report ID</th>
						<th>Report Name</th>
						<th>Report Status</th>
						<th>Date Range</th>
						<th>Generated on</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>EBOR99875</td>
						<td>TCS Report - Dec 21</td>
						<td><span class="processing">Generating…</span></td>
						<td>27/08/2021 - 27/09/2021</td>
						<td>--/--/--  |  --:--:--</td>
						<td class="d-flex align-items-center processing-icons">
							<a href="javascript:;"><img src="assets/images/icons/pdf.png"></a>
							<a href="javascript:;"><img src="assets/images/icons/xls.png"></a>
						</td>
					</tr>
					<tr>
						<td>EBOR99875</td>
						<td>TCS Report - Dec 21</td>
						<td>Ready to download</td>
						<td>27/08/2021 - 27/09/2021</td>
						<td>27/08/2021, 12:54 pm</td>
						<td class="d-flex align-items-center">
							<a href="javascript:;"><i placement="bottom" ngbTooltip="File size: 75kb"><img src="assets/images/icons/pdf.png" /></i></a>
							<a href="javascript:;"><img src="assets/images/icons/xls.png"></a>
						</td>
					</tr>
					<tr>
						<td>EBOR99875</td>
						<td>TCS Report - Dec 21</td>
						<td>Ready to download</td>
						<td>27/08/2021 - 27/09/2021</td>
						<td>27/08/2021, 12:54 pm</td>
						<td class="d-flex align-items-center">
							<a href="javascript:;"><i placement="bottom" ngbTooltip="File size: 75kb"><img src="assets/images/icons/pdf.png" /></i></a>
							<a href="javascript:;"><img src="assets/images/icons/xls.png"></a>
						</td>
					</tr>
					<tr>
						<td>EBOR99875</td>
						<td>TCS Report - Dec 21</td>
						<td>Ready to download</td>
						<td>27/08/2021 - 27/09/2021</td>
						<td>27/08/2021, 12:54 pm</td>
						<td class="d-flex align-items-center">
							<a href="javascript:;"><i placement="bottom" ngbTooltip="File size: 75kb"><img src="assets/images/icons/pdf.png" /></i></a>
							<a href="javascript:;"><img src="assets/images/icons/xls.png"></a>
						</td>
					</tr>
					<tr>
						<td>EBOR99875</td>
						<td>TCS Report - Dec 21</td>
						<td>Ready to download</td>
						<td>27/08/2021 - 27/09/2021</td>
						<td>27/08/2021, 12:54 pm</td>
						<td class="d-flex align-items-center">
							<a href="javascript:;"><i placement="bottom" ngbTooltip="File size: 75kb"><img src="assets/images/icons/pdf.png" /></i></a>
							<a href="javascript:;"><img src="assets/images/icons/xls.png"></a>
						</td>
					</tr>
					<tr>
						<td>EBOR99875</td>
						<td>TCS Report - Dec 21</td>
						<td>Ready to download</td>
						<td>27/08/2021 - 27/09/2021</td>
						<td>27/08/2021, 12:54 pm</td>
						<td class="d-flex align-items-center">
							<a href="javascript:;"><i placement="bottom" ngbTooltip="File size: 75kb"><img src="assets/images/icons/pdf.png" /></i></a>
							<a href="javascript:;"><img src="assets/images/icons/xls.png"></a>
						</td>
					</tr>
					<tr>
						<td>EBOR99875</td>
						<td>TCS Report - Dec 21</td>
						<td>Ready to download</td>
						<td>27/08/2021 - 27/09/2021</td>
						<td>27/08/2021, 12:54 pm</td>
						<td class="d-flex align-items-center">
							<a href="javascript:;"><i placement="bottom" ngbTooltip="File size: 75kb"><img src="assets/images/icons/pdf.png" /></i></a>
							<a href="javascript:;"><img src="assets/images/icons/xls.png"></a>
						</td>
					</tr>
					<tr>
						<td>EBOR99875</td>
						<td>TCS Report - Dec 21</td>
						<td>Ready to download</td>
						<td>27/08/2021 - 27/09/2021</td>
						<td>27/08/2021, 12:54 pm</td>
						<td class="d-flex align-items-center">
							<a href="javascript:;"><i placement="bottom" ngbTooltip="File size: 75kb"><img src="assets/images/icons/pdf.png" /></i></a>
							<a href="javascript:;"><img src="assets/images/icons/xls.png"></a>
						</td>
					</tr>
					<tr>
						<td>EBOR99875</td>
						<td>TCS Report - Dec 21</td>
						<td>Ready to download</td>
						<td>27/08/2021 - 27/09/2021</td>
						<td>27/08/2021, 12:54 pm</td>
						<td class="d-flex align-items-center">
							<a href="javascript:;"><i placement="bottom" ngbTooltip="File size: 75kb"><img src="assets/images/icons/pdf.png" /></i></a>
							<a href="javascript:;"><img src="assets/images/icons/xls.png"></a>
						</td>
					</tr>
					<tr>
						<td>EBOR99875</td>
						<td>TCS Report - Dec 21</td>
						<td>Ready to download</td>
						<td>27/08/2021 - 27/09/2021</td>
						<td>27/08/2021, 12:54 pm</td>
						<td class="d-flex align-items-center">
							<a href="javascript:;"><i placement="bottom" ngbTooltip="File size: 75kb"><img src="assets/images/icons/pdf.png" /></i></a>
							<a href="javascript:;"><img src="assets/images/icons/xls.png"></a>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="text-left pl-3">
					<nav class="mt-3 tbpg rows-per-page">
						<div>Rows per page:</div>
						<select class="select-box">
							<option>10</option>
							<option>20</option>
						</select>
					</nav>
				</div> 
			</div>
			<div class="col-md-6">
				<div class="text-right pr-3">
					<nav class="mt-3 tbpg">
					  <ul class="pagination align-items-center">
						<li class="page-item"><a class="page-link previcon" href="#"></a></li>
						<li class="page-item"><a class="page-link sprevicon" href="#"></a></li>
						<li class="page-item"><a class="page-link" href="#">1</a></li>
						<li class="page-item"><a class="page-link" href="#">2</a></li>
						<li class="page-item"><a class="page-link" href="#">3</a></li>
						<li class="page-item"><a class="page-link" href="#">4</a></li>
						<li class="page-item"><a class="page-link snexticon" href="#"></a></li>
						<li class="page-item"><a class="page-link nexticon" href="#"></a></li>
					  </ul>
					</nav>
				</div> 
			</div>
		</div> 
	</div>
</div>