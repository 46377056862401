import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

    constructor(private _http: HttpClient) { }

    getcheckoutdetails(data) {
        return this._http.post(environment.api_url+'checkout',data);
    }

    getuserdetails(data) {
        return this._http.post(environment.api_url+'userbasicinfo',data);
    }

    updatedetails(data) {
        return this._http.post(environment.api_url+'cart/update',data);
    }

    placeorder(data){
        return this._http.post(environment.api_url+'buyer/cf/createorder',data);
    }

    paymentsuccess(data){
        return this._http.post(environment.api_url+'buyer/cf/createorder',data);
    }

    submitcard(data){
        return this._http.post(environment.api_url+'buyer/cf/card/pay',data);
    }

    savedcards(data){
        return this._http.post(environment.api_url+'buyer/cards',data);
    }

    walletpayment(data){
        return this._http.post(environment.api_url+'buyer/wallet/confirmorder',data);
    }

    bnplStatus(data) {
        return this._http.post(environment.api_url + 'bnpl/getStatus', data);
    }

    bnplInformation(data) {
        return this._http.post(environment.api_url + 'bnpl/information', data);
    }

}
