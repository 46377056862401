<app-header-admin></app-header-admin>
<app-sidebar-admin></app-sidebar-admin>
<div class="wrpmain">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center mb-4 pinfo-head">
			<div class="col-sm-6 col-7 pr-0">
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">
							<a class="txtseconday" href="javascript:void(0);">User Management</a>
						</li>
						<li class="breadcrumb-item">
							<a class="txtseconday" [routerLink]="['/admin/manage-seller']">Seller</a>
						</li>
						<li class="breadcrumb-item active">View Details</li>
					</ol>
				</nav>
			</div>
			<div class="col-sm-6 col-5 text-right pl-0">
				<a href="javascript:;" class="btn buybtn fs16 radius10 ripple">Assistance</a>
			</div>
		</div>
		<!--- /HEAD  --->
		<div class="row d-flex align-items-center mb-20">
			<div class="col-md-6">
				<h3 class="f-700 fs22 txtdefault pb-0"><a href="javascript:;" class="go-back"><i class="fa fa-angle-left"></i></a> View Details</h3>
			</div>
		</div>

		<div class="row mb-10">
			<div class="col-md-12 pb-2">
				<div class="custom-tab-menu">
					<ul>
						<li><a [routerLink]="['/admin/manage-seller/seller-view-details']" class="active">Personal Details</a></li>
						<li><a [routerLink]="['/admin/manage-seller/seller-business-details']" class="active">Business Details</a></li>
						<li><a [routerLink]="['/admin/manage-seller/seller-bank-details']"href="javascript:;">Bank Details</a></li>
					</ul>
				</div>
			</div>

			<div class="col-md-12 pt-2 pb-2">
				<div class="text-right">
					<a href="javascript:;" class="btn buybtn-success fs16 radius10 ripple mr-2">Continue</a>
					<a href="javascript:;" class="btn buybtn-danger fs16 radius10 ripple">Reject</a>
				</div>
			</div>
		</div>

		<div class="card cardui carduipro p-4 mb-2">
			<div class="pb-2">
				<div class="d-table">
					<div class="d-table-cell align-middle proavtwrp">
						<div class="proavt">						
							<img src="assets/images/svg/sellerbuilding.svg">
						</div>
					</div>
					<div class="d-table-cell align-middle pl-3">
						<h4 class="f-700 fs20 txtdefault pb-0">Digixpress Trade Private Limited 
						<img class="collapse" src="assets/images/svg/success.svg" />
						</h4>
						<div class="tg-grp">
							<span>Wholesaler</span> 
							<span>Retail Trader</span>
						</div>
					</div>
				</div>
			</div>	
		</div>
		<div class="bsn-dtl-p">
		<!-- GST DOCUMENTS -->
		<div class="card cardui carduipro p-4 mb-2">
			<h4 class="f-700 fs20 txtdefault pb-10">GST Documents</h4>
			<div class="profileipt">
				<!-- INPUT ROW -->
				<div class="form-container mt-0 mb-4">
					<div class="floating-label mb-10 pr-0"> 
						<input type="text" class="form-control" placeholder=" " value="27BMDPT7272G25E">						  
					   <span class="highlight"></span>
					   <label>GSTIN Number</label>	
						
					</div>
				</div>
				<!-- /INPUT ROW -->				
				<div class="row">
					<!-- INPUT ROW -->
					<div class="col-sm-6 ">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label mb-10 flselcted"> 
								<input type="text" class="form-control" placeholder=" ">
								<button class="btn btn-outline-secondary fs16 btn-o-default" type="button">
									<span>View File</span>
									<svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107" viewBox="0 0 18.71 12.107">
								  <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
									<path id="Path_2337" data-name="Path 2337" d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z" transform="translate(-14.582 -27.082)" fill="#ff7135"/>
									<g id="Group_15718" data-name="Group 15718">
									  <g id="Group_15717" data-name="Group 15717">
										<g id="Group_15716" data-name="Group 15716">
										  <path id="Path_2338" data-name="Path 2338" d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4" transform="translate(-36.046 -39.347)" fill="#ff7135"/>
										</g>
									  </g>
									</g>
								  </g>
								</svg>

								</button>	  
							   <span class="highlight"></span>
							   <label>GST Document</label>
							   <div class="docselected"><img src="assets/images/svg/attach.svg"><span>GST Document.pdf</span></div>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
					<!-- INPUT ROW -->
					<div class="col-sm-6">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label mb-10 pr-0"> 
								<input type="text" class="form-control" placeholder=" " value="Quarterly">  
							   <span class="highlight"></span>
							   <label>GST Filing Frequency</label>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
					<!-- INPUT ROW -->
					<div class="col-sm-6">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label mb-10 pr-0"> 
								<div class="floating-label-inr">
									<input type="text" class="form-control" placeholder=" " value="26/08/2021">  
								   <span class="highlight"></span>
								   <label>Last GST Payment Date</label>
							   </div>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
					<!-- INPUT ROW -->
					<div class="col-sm-6">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label mb-10"> 
								<div class="floating-label-inr flselcted">
									<input type="text" class="form-control" placeholder=" ">
									<button class="btn btn-outline-secondary fs16 btn-o-default" type="button">
									<span>Update</span>
									<svg xmlns="http://www.w3.org/2000/svg" width="15.415" height="15.415" viewBox="0 0 15.415 15.415">
									  <path id="Path_2419" data-name="Path 2419" d="M7.708,0a7.708,7.708,0,1,0,7.708,7.708A7.7,7.7,0,0,0,7.708,0Zm2.869,8.272H9.133v3.062a.514.514,0,0,1-.514.514H6.8a.514.514,0,0,1-.514-.514V8.272H4.839a.514.514,0,0,1-.405-.83L7.3,3.765a.514.514,0,0,1,.81,0l2.869,3.677A.514.514,0,0,1,10.576,8.272Z" fill="#ff7135"/>
									</svg>
									</button>	  
								   <span class="highlight"></span>
								   <label>Last Filled GST Document</label>
								   <div class="docselected"><img src="assets/images/svg/attach.svg"><span>GST Document.pdf</span></div>
							   </div>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
				</div>					
				
			</div>
		</div>
		<!-- /GST DOCUMENTS -->
		<!-- Identity DOCUMENTS -->
		<div class="card cardui carduipro p-4 mb-2">
			<h4 class="f-700 fs20 txtdefault pb-10">Identity Documents</h4>
			<div class="profileipt">
				<div class="row">
					<!-- INPUT ROW -->
					<div class="col-sm-6 ">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label mb-10 pr-0"> 
								<input type="text" class="form-control" placeholder=" " value="AURD9876504I">
								  
							   <span class="highlight"></span>
							   <label>PAN Number</label>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
					<!-- INPUT ROW -->
					<div class="col-sm-6">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label mb-10 flselcted"> 
								<input type="text" class="form-control" placeholder=" ">
								<button class="btn btn-outline-secondary fs16 btn-o-default" type="button">
									<span>View File</span>
									<svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107" viewBox="0 0 18.71 12.107">
								  <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
									<path id="Path_2337" data-name="Path 2337" d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z" transform="translate(-14.582 -27.082)" fill="#ff7135"/>
									<g id="Group_15718" data-name="Group 15718">
									  <g id="Group_15717" data-name="Group 15717">
										<g id="Group_15716" data-name="Group 15716">
										  <path id="Path_2338" data-name="Path 2338" d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4" transform="translate(-36.046 -39.347)" fill="#ff7135"/>
										</g>
									  </g>
									</g>
								  </g>
								</svg>								
								</button>	  
							   <span class="highlight"></span>
							   <label>PAN Card Document</label>
							   <div class="docselected"><img src="assets/images/svg/attach.svg"><span>PAN Card.pdf</span></div>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
					<!-- INPUT ROW -->
					<div class="col-sm-6">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label mb-10 pr-0"> 
								<input type="text" class="form-control" placeholder=" " value="4321 6465 3546 2645">
							   <span class="highlight"></span>
							   <label>Aadhaar Number</label>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
					<!-- INPUT ROW -->
					<div class="col-sm-6">
						<div class="form-container mt-0 mb-4 p-eml-rt">
							<div class="floating-label flselcted mb-10"> 
								<input type="text" class="form-control" placeholder=" ">
								<button class="btn btn-outline-secondary fs16 btn-o-default" type="button">
								<span>View File</span>
									<svg xmlns="http://www.w3.org/2000/svg" width="18.71" height="12.107" viewBox="0 0 18.71 12.107">
								  <g id="Group_15719" data-name="Group 15719" transform="translate(0 0)">
									<path id="Path_2337" data-name="Path 2337" d="M23.937,27.082c-5.5,0-9.355,6.053-9.355,6.053s2.806,6.053,9.355,6.053c6.6,0,9.355-6.053,9.355-6.053S29.5,27.082,23.937,27.082Zm0,9.9a3.852,3.852,0,1,1,3.852-3.852A3.828,3.828,0,0,1,23.937,36.987Z" transform="translate(-14.582 -27.082)" fill="#ff7135"/>
									<g id="Group_15718" data-name="Group 15718">
									  <g id="Group_15717" data-name="Group 15717">
										<g id="Group_15716" data-name="Group 15716">
										  <path id="Path_2338" data-name="Path 2338" d="M47.052,45.4A1.651,1.651,0,1,1,45.4,43.75,1.65,1.65,0,0,1,47.052,45.4" transform="translate(-36.046 -39.347)" fill="#ff7135"/>
										</g>
									  </g>
									</g>
								  </g>
								</svg>
								</button>	  
							   <span class="highlight"></span>
							   <label>Aadhaar Card Document</label>
							   <div class="docselected"><img src="assets/images/svg/attach.svg"><span>Aadhaar Number.pdf</span></div>
							</div>
						</div>
					</div>
					<!-- /INPUT ROW -->
				</div>					
				
			</div>
		</div>
		<!-- /Identity DOCUMENTS -->
		<!-- Addresses -->
		<div class="card cardui carduipro p-4 mb-2">
			<h4 class="f-700 fs20 txtdefault pb-10">Addresses</h4>
			<div class="profileipt">
				<!-- INPUT ROW -->
				<div class="form-container mt-0 mb-4">
					<div class="floating-label mb-10 pr-0"> 
						<input type="text" class="form-control" placeholder=" " value="MS Ali Road, Grant Road East, Bharat Nagar, Girgaon, Mumbai, Maharashtra - 400007">						  
					   <span class="highlight"></span>
					   <label>Registered Office Address</label>					   
					</div>
				</div>
				<!-- /INPUT ROW -->	
				<!-- INPUT ROW -->
				<div class="form-container mt-0 mb-4">
					<div class="floating-label mb-10 pr-0"> 
						<input type="text" class="form-control" placeholder=" " value="MS Ali Road, Grant Road East, Bharat Nagar, Girgaon, Mumbai, Maharashtra - 400321">						  
					   <span class="highlight"></span>
					   <label>Shipping Address - 1</label>	
						<a href="javascript:void(0);" class="btn-d-outline place-bid shpdflt">Default</a>
					</div>
				</div>
				<!-- /INPUT ROW -->
				<!-- INPUT ROW -->
				<div class="form-container mt-0 mb-4">
					<div class="floating-label mb-10 pr-0"> 
						<input type="text" class="form-control" placeholder=" " value="MS Ali Road, Grant Road East, Bharat Nagar, Girgaon, Mumbai, Maharashtra - 400321">						  
					   <span class="highlight"></span>
					   <label>Shipping Address - 2</label>					   
					</div>
				</div>
				<!-- /INPUT ROW -->
			</div>
		</div>
		<!-- /Addresses -->
		<!-- Designated Person -->
		<div class="card cardui carduipro p-4 mb-2">
			<h4 class="f-700 fs20 txtdefault pb-10">Designated Person</h4>
			<div class="">
				<div class="d-table">
					<div class="d-table-cell align-middle text-center  txtavtarwrp">
						<div class="txtavtar fs20 textwhite wt700">AK</div>
					</div>
					<div class="d-table-cell dsg-pinfo pl-3 align-middle">
						<h5 class="fs14 wt700 mb-1 mt-0 txtdefault">Abhinav Kumar <span class="fs12 wt400 textsecondary">Owner</span></h5>
						<p class="fs12 txtdefault wt400 mr-4 d-inline-block mb-0"><i class="d-inline-block p-2 callicon"></i> +91 98765 04321</p>
						<p class="fs12 txtdefault wt500 d-inline-block mb-0"><i class="d-inline-block p-2 envelopeicon"></i> abhinav.kumar@gmail.com</p>
					</div>
				</div>
			</div>
		</div>	
		<!-- /Designated Person -->
		<!-- Authorized Persons -->
		<div class="card cardui carduipro p-4 mb-40">
			<h4 class="f-700 fs20 txtdefault pb-10">Authorized Persons</h4>
			<!-- REPEAT -->	
			<div class="mb-3">
				<div class="d-table">
					<div class="d-table-cell align-middle text-center  txtavtarwrp">
						<div class="txtavtar fs20 textwhite wt700">SG</div>
					</div>
					<div class="d-table-cell dsg-pinfo pl-3 align-middle">
						<h5 class="fs14 wt700 mb-1 mt-0 txtdefault">Sanjay Gupta <span class="fs12 wt400 textsecondary">Owner</span></h5>
						<p class="fs12 txtdefault wt400 mr-4 d-inline-block mb-0"><i class="d-inline-block p-2 callicon"></i> +91 98765 04321</p>
						<p class="fs12 txtdefault wt500 d-inline-block mb-0"><i class="d-inline-block p-2 envelopeicon"></i> sanjay.gupta@gmail.com</p>
					</div>
				</div>
			</div>
			<!-- /REPEAT -->	
			<!-- REPEAT -->	
			<div class="mb-3">
				<div class="d-table">
					<div class="d-table-cell align-middle text-center  txtavtarwrp">
						<div class="txtavtar fs20 textwhite wt700 hg-color">PG</div>
					</div>
					<div class="d-table-cell dsg-pinfo pl-3 align-middle">
						<h5 class="fs14 wt700 mb-1 mt-0 txtdefault">Pankaj Gupta <span class="fs12 wt400 textsecondary">Owner</span></h5>
						<p class="fs12 txtdefault wt400 mr-4 d-inline-block mb-0"><i class="d-inline-block p-2 callicon"></i> +91 98765 04321</p>
						<p class="fs12 txtdefault wt500 d-inline-block mb-0"><i class="d-inline-block p-2 envelopeicon"></i> sanjay.gupta@gmail.com</p>
					</div>
				</div>
			</div>
			<!-- /REPEAT -->
		</div>	
		<!-- /Authorized Persons -->
	</div>
</div>