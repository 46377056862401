import { Component, OnInit, Input, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SellerTestimonialSlider } from '../../shared/data/slider';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { EncrDecrService } from '../../helpers/EncrDecrService';
import { HomeService } from './home.service';
import { SignupService } from '../../shared/components/modal/signup-modal/signup-modal.service';
import { Router } from '@angular/router';
import { ToasterService } from '../../helpers/toastr.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class AdminHomeComponent implements OnInit {

    otpForm: FormGroup;
    loginForm: FormGroup;
    passwordForm: FormGroup;
    resetPasswordForm: FormGroup;

    @Input() class: string;
    @Input() sticky: boolean = false; // Default false

    public stick: boolean = false;
    public closeResult: string;
    public buttonLoading: boolean = false;

    public loginModal: boolean = true;
    public loginOTPModal: boolean = false;
    public loginPasswordModal: boolean = false;
    public forgotPasswordModal: boolean = false;
    public resetPasswordModal: boolean = false;

    public signupType: string = 'mobile';
    public uniqueId: any = '';
    public otp: string;
    public otp1: number;
    public otp2: number;
    public otp3: number;
    public otp4: number;
    public login_password;

    constructor(
        private modalService: NgbModal,
        private fb: FormBuilder,
        private _signupservice: SignupService,
        private _EncrDecr: EncrDecrService,
        private _homeservice: HomeService,
        private router: Router,
        private _toastr: ToasterService
    ) { }
    public ProductSliderConfig: any = SellerTestimonialSlider;
    ngOnInit(): void {

        //Login Form
        this.loginForm = new FormGroup({
            uniqueId: new FormControl(null, [Validators.required])
        });

        //OTP Form
        this.otpForm = new FormGroup({
            otp1: new FormControl(null, [Validators.required, Validators.pattern('[- +()0-9]+'), Validators.maxLength(1), Validators.minLength(1)]),
            otp2: new FormControl(null, [Validators.required, Validators.pattern('[- +()0-9]+'), Validators.maxLength(1), Validators.minLength(1)]),
            otp3: new FormControl(null, [Validators.required, Validators.pattern('[- +()0-9]+'), Validators.maxLength(1), Validators.minLength(1)]),
            otp4: new FormControl(null, [Validators.required, Validators.pattern('[- +()0-9]+'), Validators.maxLength(1), Validators.minLength(1)])
        });

        //Password Form
        this.passwordForm = new FormGroup({
            login_password: new FormControl(null, [Validators.required, Validators.minLength(8)])
        });

        //Reset Password Form
        this.resetPasswordForm = new FormGroup({
            reset_password: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(8)]),
            reset_confirm_password: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(8)])
        });
    }

    get o() { return this.otpForm.controls; }
    get l() { return this.loginForm.controls; }
    get p() { return this.passwordForm.controls; }
    get rsp() { return this.resetPasswordForm.controls; }

    onDigitInput(event: any) {
        let element;
        if (event.code !== 'Backspace')
            element = event.srcElement.nextElementSibling;

        if (event.code === 'Backspace')
            element = event.srcElement.previousElementSibling;

        if (element == null)
            return;
        else
            element.focus();
    }

    hideall() {
        this.loginModal = false;
        this.loginOTPModal = false;
        this.loginPasswordModal = false;
        this.forgotPasswordModal = false;
        this.resetPasswordModal = false;
    }

    showLoginModal() {
        this.hideall();
        this.loginModal = true;
    }

    showLoginOTPModal() {
        this.hideall();
        this.loginOTPModal = true;
    }

    showLoginPasswordModal() {
        this.hideall();
        this.loginPasswordModal = true;
    }

    showForgotPasswordModal() {
        this.hideall();
        this.forgotPasswordModal = true;
    }

    showResetPasswordModal() {
        this.hideall();
        this.resetPasswordModal = true;
    }

    disabled = false;
    // @HostListener Decorator
    @HostListener("window:scroll", [])
    onWindowScroll() {
        let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (number >= 150 && window.innerWidth > 400) {
            this.stick = true;
        } else {
            this.stick = false;
        }
    }
    customOptions: OwlOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: false,
        dots: false,
        margin: 10,
        navSpeed: 700,
        navText: ['', ''],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 1
            },
            740: {
                items: 2
            },
            940: {
                items: 2
            }
        },
        nav: true
    }

    //Modal
    open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'md' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }


    //Phone Form Login Submimt
    phoneLoginFormSubmit() {
        this.buttonLoading = true;
        let unique_id = this._EncrDecr.set(this.loginForm.get('uniqueId').value);
        let type = this._EncrDecr.set('otp');
        this._homeservice.otpgenerate({ unique_id: unique_id, type: type }).subscribe((data) => {
            this.buttonLoading = false;
            if (data['status'] == "success") {
                this.showLoginOTPModal();
                this._toastr.success('OTP sent');
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            } else if (data['status'] == 'validation') {
                this._toastr.validation(data['errors'])
            }
        }, (error) => {
            this.buttonLoading = false;
        });
    }

    // resentotp(){
    //     this.phoneLoginFormSubmit();
    // }

    getotp() {
        this.otp = this.otp1 + "" + this.otp2 + "" + this.otp3 + "" + this.otp4;
    }

    verifyotp() {
        this.getotp();
        this.buttonLoading = true;
        let unique_id = this._EncrDecr.set(this.loginForm.get('uniqueId').value);
        let unique_value = this._EncrDecr.set(this.otp);
        let type = this._EncrDecr.set('otp');

        this._homeservice.verifyotp({ unique_id: unique_id, type: type, unique_value: unique_value, notification_token: localStorage.getItem('firebase-token') }).subscribe((data) => {
            this.buttonLoading = false;
            if (data['status'] == "success") {
                this._signupservice.updatetoken(data['data']['token'], data['data']['usertype']);
                this.modalService.dismissAll();
                this.router.navigate(['/admin/dashboard']);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            } else if (data['status'] == 'validation') {
                this._toastr.validation(data['errors'])
            }
        }, (error) => {
            this.buttonLoading = false;
        });
    }

    forgotPassword() {
        this.buttonLoading = true;
        let unique_id = this._EncrDecr.set(this.loginForm.get('uniqueId').value);
        this._homeservice.forgotpassword({ unique_id: unique_id }).subscribe((data) => {
            this.buttonLoading = false;
            if (data['status'] == "success") {
                this.showForgotPasswordModal();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            } else if (data['status'] == 'validation') {
                this._toastr.validation(data['errors'])
            }
        }, (error) => {
            this.buttonLoading = false;
        });
    }


    verifypassword() {
        this.buttonLoading = true;
        let unique_id = this._EncrDecr.set(this.loginForm.get('uniqueId').value);
        let unique_value = this._EncrDecr.set(this.login_password);
        let type = this._EncrDecr.set('password');
        this._homeservice.verifyotp({ unique_id: unique_id, type: type, unique_value: unique_value }).subscribe((data) => {
            this.buttonLoading = false;
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this._signupservice.updatetoken(data['data']['token'], data['data']['usertype']);
                this.router.navigate(['/admin/dashboard']);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            } else if (data['status'] == 'validation') {
                this._toastr.validation(data['errors'])
            }
        }, (error) => {
            this.buttonLoading = false;
        });
    }

    resetpassword() {

    }

}
