import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class JoincfService {

    constructor(private _http: HttpClient) { }

    getsupersellers(data) {
        return this._http.post(environment.api_url + 'seller/candf/approvedlist', data);
    }

    applysuperseller(data) {
        return this._http.post(environment.api_url + '/seller/candf/supersellerrequest', data);
    }

    applysubseller(data) {
        return this._http.post(environment.api_url + '/seller/candf/sellerrequestsubseller', data);
    }

    getsellerdetails(data) {
        return this._http.post(environment.api_url + '/seller/candf/basicinfo', data);
    }

}