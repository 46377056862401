import { Component, OnInit } from '@angular/core';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { BiddingOrdersService } from './bidding-orders.service';

@Component({
  selector: 'app-bidding-orders',
  templateUrl: './bidding-orders.component.html',
  styleUrls: ['./bidding-orders.component.scss']
})
export class BiddingOrdersComponent implements OnInit {

    constructor(
        private _biddingorders: BiddingOrdersService,
        private _toastr: ToasterService,
        private _pagination: PaginationService,
    ) { }
    public bid_records:any = [];
    public current_page:number = 1;
    public offset:number = 0;
    public page_per_view:number = 10;
    public order_pagination:any = {};
    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];
    ngOnInit(): void {
        this.getbidorders();
    }
    getbidorders(){
        let limit = this.page_per_view;
        let offset = this.offset;
        let current_page = this.current_page;
        this._biddingorders.getbidorders({limit: limit, offset: offset, current_page: current_page}).subscribe((data) => {
            if(data['status'] == "success"){
                this.bid_records = data['records'];
                let total_records = data['total_records'];
                let pages = Math.ceil(total_records/this.page_per_view);
                this.order_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
            }else if(data['status'] == "error"){
              this._toastr.error(data['message']);
            }
        });
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page-1)*this.page_per_view;
        this.getbidorders();
    }

    setpageperview(){
        this.current_page = 1;
        this.getbidorders();
    }

}
