import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { AboutSellerService } from './about-seller.service';

@Component({
  selector: 'app-about-seller',
  templateUrl: './about-seller.component.html',
  styleUrls: ['./about-seller.component.scss']
})
export class AboutSellerComponent implements OnInit {

    public more_seller_detail:any = {};
    public seller_id:string;
    constructor(
        private _aboutsellerservice: AboutSellerService,
        private _toastr: ToasterService,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.route.queryParams
            .subscribe(params => {
                if(params['s_id'] !== undefined){
                    this.seller_id = params['s_id'];
                }
                this.getmoresellerdetail();
            }
        );
    }

    getmoresellerdetail(){
        this._aboutsellerservice.getsellermore({s_id: this.seller_id}).subscribe((data) => {
            if(data['status'] == "success"){
                this.more_seller_detail = data['record'];
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

}
