import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { BuyerCategoryCmsService } from './buyer-category-cms.service';

@Component({
  selector: 'app-buyer-category',
  templateUrl: './buyer-category.component.html',
  styleUrls: ['./buyer-category.component.scss']
})
export class BuyerCategoryComponent implements OnInit {

    closeResult = '';
    public search_term: string = '';
    public current_page:number = 1;
    public offset:number = 0;
    public page_per_view:number = 10;
    public page_pagination:any = {};
    public records: any[];
    public selectedRecord:any = {};

    public page_per_view_options = [
        {
            value: 10,
            name: 10
        },
        {
            value: 20,
            name: 20
        }
    ];

    setpage(page) {
        this.current_page = page;
        this.offset = (page-1)*this.page_per_view;
        this.getrecords();
    }

    setpageperview(){
        this.current_page = 1;
        this.getrecords();
    }
  constructor(
    private modalService: NgbModal,
    private _pageservice: BuyerCategoryCmsService,
    private _toastr: ToasterService,
    private _pagination: PaginationService
    ) { }

    ngOnInit(): void {
        //this.getrecords();
    }
    open(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true,}).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    openWindowCustomClass(content, record) {
        this.modalService.open(content, { windowClass: 'modal-500' });
        this.selectedRecord = record;
    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    getrecords() {
        this._pageservice.getrecords({limit: this.page_per_view, offset: this.offset, current_page: this.current_page, search: this.search_term}).subscribe((data) => {
            if(data['status'] == "success"){
                this.records = data['searchResults'];
                let total_records = data['total_records'];
                this.page_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }

}
