import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tcs-report',
  templateUrl: './tcs-report.component.html',
  styleUrls: ['./tcs-report.component.scss']
})
export class TcsReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
