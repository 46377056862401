import { Component, OnInit, Input, HostListener, ViewChild } from '@angular/core';
import { RegistrationService } from './registration.service';
import { SignupService } from '../shared/components/modal/signup-modal/signup-modal.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { EncrDecrService } from '../helpers/EncrDecrService';
import { ToasterService } from '../helpers/toastr.service';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
    // personalDetailsForm: FormGroup;

    @Input() class: string;
    @Input() sticky: boolean = false; // Default false

    public closeResult: string;

    public buttonLoading = false;

    public stick: boolean = false;
    public step_zero: boolean = true;
    public step_one: boolean = false;
    public step_two: boolean = false;
    public step_three: boolean = false;
    public step_four: boolean = false;
    public step_five: boolean = false;
    public step_six: boolean = false;
    public step_seven: boolean = false;

    public userType: number;
    public businessCategory: string = "";
    public gstReturn: string = "";
    public tdsType: string = "";
    public bulkSegment: string = "";
    public minimunOrderValue: string = "";

    public firstName: string;
    public lastName: string;
    public phoneNumber: string;
    public emailAddress: string;
    public signupType: string;
    public secret: any;
    public login_password: string;
    businessCategoryArray: Array<Object> = [];


    constructor(
        private _registration: RegistrationService,
        private modalService: NgbModal,
        private _Activatedroute: ActivatedRoute,
        private _signupservice: SignupService,
        private _router: Router,
        private _EncrDecr: EncrDecrService,
        private _toastr: ToasterService,
        public router: Router,
    ) { }

    ngOnInit(): void {
        this.signupType = this._signupservice.registration_type;
        this.secret = this._signupservice.registration_secret;
        this.login_password = this._signupservice.registration_unique_value;
        this.firstName = this._signupservice.first_name;
        this.lastName = this._signupservice.last_name;

        if (this.signupType == "mobile") {
            this.phoneNumber = this._signupservice.registration_unique_id;
        } else if (this.signupType == "email") {
            this.emailAddress = this._signupservice.registration_unique_id;
        }
        if (!this.secret || !this.signupType) {
            this._router.navigate(['/home']);
        }

        setTimeout(() => {
            //this.getuserdetails();
            this.getbusinesscategory();
        }, 500);

        //Personal Details Form
        // this.personalDetailsForm = new FormGroup({
        //     firstName: new FormControl(null, [Validators.required]),
        //     lastName: new FormControl(null, [Validators.required]),
        //     phoneNumber: new FormControl(null, [Validators.required, Validators.pattern('[- +()0-9]+'), Validators.maxLength(10), Validators.minLength(10)]),
        //     emailAddress: new FormControl(null, [Validators.required, Validators.email])
        // });
    }

    getuserdetails() {
        this._registration.loginuserdetail({ secret: this.secret }).subscribe((data) => {
            if (data['status'] == "success") {

            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            } else if (data['status'] == 'validation') {
                this._toastr.validation(data['errors'])
            }
        }, (error) => {
            this.buttonLoading = false;
        });
    }

    // @HostListener Decorator
    @HostListener("window:scroll", [])
    onWindowScroll() {
        let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (number >= 150 && window.innerWidth > 400) {
            this.stick = true;
        } else {
            this.stick = false;
        }
    }

    hideAllTab() {
        this.step_zero = false;
        this.step_one = false;
        this.step_two = false;
        this.step_three = false;
        this.step_four = false;
        this.step_five = false;
        this.step_six = false;
        this.step_seven = false;
    }

    nextTab(tabName) {
        this.hideAllTab();
        if (tabName == 'zero') {
            this.step_zero = true;
        } else if (tabName == 'one') {
            this.step_one = true;
        } else if (tabName == 'two') {
            this.step_two = true;
        } else if (tabName == 'three') {
            this.step_three = true;
        } else if (tabName == 'four') {
            this.step_four = true;
        } else if (tabName == 'five') {
            this.step_five = true;
        } else if (tabName == 'six') {
            this.step_six = true;
        } else if (tabName == 'seven') {
            this.step_seven = true;
        }
        return false;
    }

    changeidentify(userType) {
        this.userType = userType;
    }

    changebusinesscategory(businessCategory) {
        this.businessCategory = businessCategory;
    }

    changegstreturn(gstReturn) {
        this.gstReturn = gstReturn;
    }

    changetdstype(tdsType) {
        this.tdsType = tdsType;
    }

    changebulksegment(bulkSegment) {
        this.bulkSegment = bulkSegment;
    }

    changeminimunordervalue(minimunOrderValue) {
        this.minimunOrderValue = minimunOrderValue;
    }

    getbusinesscategory() {
        this._registration.getbusinesscategory(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.businessCategoryArray = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    registration() {
        let data = {
            secret: this._EncrDecr.set(this.secret),
            type: this._EncrDecr.set(this.signupType),
            email: this._EncrDecr.set(this.emailAddress),
            mobile: this._EncrDecr.set(this.phoneNumber),
            usertype: this.userType,
            avg_min_order: this._EncrDecr.set(this.minimunOrderValue),
            how_often_gst_return: this._EncrDecr.set(this.gstReturn),
            require_seller_paying_TDS_and_TCS: this._EncrDecr.set(this.tdsType),
            bulk_segment: this._EncrDecr.set(this.bulkSegment),
            business_category: this._EncrDecr.set(this.businessCategory),
            first_name: this._EncrDecr.set(this.firstName),
            last_name: this._EncrDecr.set(this.lastName)
        }
        this.buttonLoading = true;
        this._registration.registration(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.buttonLoading = false;
                this.login("pass", this.emailAddress, this.login_password);
                this.modalService.dismissAll();
            } else if (data['status'] == "error") {
                this.buttonLoading = false;
                this._toastr.error(data['message']);
            } else if (data['status'] == 'validation') {
                this.buttonLoading = false;
                this._toastr.validation(data['errors'])
            }
        }, (error) => {
            this.buttonLoading = false;
        });
    }

    login(loginType, unique_id, unique_value) {
        let data = {
            type: this._EncrDecr.set(this.signupType),
            otporpass: this._EncrDecr.set(loginType),
            secret: this.secret,
            unique_id: this._EncrDecr.set(unique_id),
            unique_value: this._EncrDecr.set(unique_value),
            notification_token: this._EncrDecr.set(""),
            req_from: this._EncrDecr.set("registration"),
        };
        this._signupservice.login(data).subscribe((data) => {
            this.buttonLoading = false;
            if (data['status'] == "success") {

                this.modalService.dismissAll();
                this._signupservice.updatestatus(data['data']['adminverified']);
                this._signupservice.updateregstatus(data['data']['reg_status']);
                this._signupservice.updatetoken(data['data']['token'], data['data']['usertype']);
                var userType = data['data']['usertype'];
                if (data['data']['reg_status'] == 'no') {
                    if (userType == 1) {
                        this.router.navigate(['/registration/buyer/details']);
                    } else if (userType == 2) {
                        this.router.navigate(['/registration/seller/details']);
                    }
                } else {
                    if (data['data']['usertype'] == 2) {
                        this.router.navigate(['/seller/dashboard']);
                    } else {
                        this.router.navigate(['/home']);
                    }
                }

            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            } else if (data['status'] == 'validation') {
                this._toastr.validation(data['errors'])
            }
        }, (error) => {
            this.buttonLoading = false;
        });
    }

    //Modal
    open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'md' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

}
