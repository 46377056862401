import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { MakePaymentService } from '../wallet/make-payment/make-payment.service';
import { AdminOrderDetailsService } from './order.service';
import { AdminChargesInvoiceService } from '../admin-charges-invoice/admin-charges-invoice.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {

    //Nature of charges or discount
    public user_info:any = {};
    public remark:string = '';
    public buttonLoading:boolean = false;
    public selected_user_type:string = 'buyer';
    public selected_payment_type:string = 'wallet';
    public order_transaction_no:string = '';
    public transaction_type:string = '';
	public selected_to_user_type:string = 'seller';
    public payment_amount:number;
    public from_balance:number = 0;
	public to_balance:number = 0;
    keyword = 'search_txt';
  	result = [];
    toresult = [];

  	charges_types = [
    	{
      		label: 'Charge',
      		value: 'ad-charge'
    	},
      	{
      		label: 'Discount',
      		value: 'ad-discount'
    	}
  	]

    user_type = [
		{
			label: 'Admin',
			value: 'admin'
	  	},
      	{
      		label: 'Buyer',
      		value: 'buyer'
    	},
      	{
      		label: 'Seller',
      		value: 'seller'
    	}
  	]

    userbasicinfo() {
        this._makepaymentservice.userbasicinfo(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.user_info = data['data'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    makepayment(){
		this.buttonLoading = true;
        if(!this.remark){
            this._toastr.error('Please enter the remarks');
            return false;
        }
        let data = {
            remark: this.remark,
            payment_type: this.selected_payment_type,
            user_type: this.selected_user_type,
            to_user_type: this.selected_to_user_type,
            amount: this.payment_amount,
            transaction_type: this.transaction_type,
            order_transaction_no: this.order_transaction_no
        }
		this._makepaymentservice.makepayment(data).subscribe((data) => {
            if(data['status'] == "success"){
                this.orderdetails();
				this.remark = '';
				this.selected_user_type = 'buyer';
				this.selected_to_user_type = 'seller';
				this.selected_payment_type =  'wallet';
                this.transaction_type = '';
                this.order_transaction_no = '';
				this.from_balance = 0;
				this.to_balance = 0;
				this.result = [];
				this.payment_amount = 0;
                this._toastr.success('Amount has been transferred successfully.');
				this.buttonLoading = false;
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
				this.buttonLoading = false;
            }
        }, (error) => {
            this.buttonLoading = false;
        });
	}

    charge_generate_loading = false;
    generatechargeinvoice(){
        this.charge_generate_loading = true;
		this._makepaymentservice.generatechargeinvoice({orderId:this.order_id}).subscribe((data) => {
            console.log(data);
            if(data['status'] == "success"){
                this.charge_generate_loading = false;
                this.orderdetails();
                this._toastr.success('Charge invoice has been generated successfully.');
            }else if(data['status'] == "error"){
                this.charge_generate_loading = false;
                this._toastr.error(data['message']);
            }
        }, (error) => {
            this.charge_generate_loading = false;
        });
	}

    //return request
    public return_quantity:any = '';
    public return_reason:string = '';
    public return_message:string = '';
    public return_images:any = [];
    public return_updated_image:any = [];
    public return_status:any = '';
    public return_status_text:string = '';


    public cityArray:any = [];
    public stateArray:any = [];
    public order_payment_log:any = [];
    //Edit Modal Boxes
    public edit_type:string;

    public invoice_number:string;

    public order_id:string;
    public detail:any = {};
    public logistcs_partners:any = [];
    public shipping_label_document:any;
    public eway_bill_document:any;
    public ss_eway_bill_document:any;
    public manifest_document:any;
    public imeilist_doucment:any;
    public user_invoice_type:string = 'buyer';
    public edit_invoice_type:string = '';
    public edit_invoice_number:string = '';
  constructor(
    private modalService: NgbModal,
    private _adminorderdetails: AdminOrderDetailsService,
    private _makepaymentservice: MakePaymentService,
    private _toastr: ToasterService,
    private _route: ActivatedRoute,
    private _pageservice: AdminChargesInvoiceService
    ) { }

    ngOnInit(): void {
        this.order_id = this._route.snapshot.paramMap.get('orderId');
        this.orderdetails();
        this.getstate();
        this.userbasicinfo();
    }

    getstate() {
        this._adminorderdetails.getstate(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.stateArray = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    config = {
        labelField: 'label',
        valueField: 'value',
    };  
data = [
    {
      label: 'India',
      value: 'india'
    },
    {
      label: 'in $',
      value: 'in $'
    }
  ]
   openWindowCustomClass(content) {
        this.modalService.open(content, { windowClass: 'midmodal' });
  }

  openWindowReturnRequest(content) {
    this.modalService.open(content, { windowClass: 'midmodal-lg', size: 'lg', centered: true, });
}

    orderdetails() {
        var type = 'all';
        this._adminorderdetails.orderdetails({ order_id: this.order_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.detail = data['record'];
                this.order_transaction_no = this.detail.transaction_id;
                if(this.detail.return_request){
                    this.return_quantity = this.detail.return_request_info.quantity;
                    this.return_reason = this.detail.return_request_info.reason;
                    this.return_message = this.detail.return_request_info.message;
                    this.return_status = this.detail.return_request_info.status;
                    this.return_status_text = this.detail.return_request_info.status_text;
                    this.return_updated_image = this.detail.return_request_info.images;
                }
                if(this.detail.quality_status){
                    this.getlogisticspartner();
                }
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getlogisticspartner() {
        var type = 'all';
        this._adminorderdetails.getlogisticspartner({ order_id: this.order_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.logistcs_partners = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getcity(type='billing') {
        var data = {};
        if(type == 'billing'){
            data = { state: this.detail.billing_state };
        }else if(type == 'shipping'){
            data = { state: this.detail.shipping_state };
        }else if(type == 'dispatch'){
            data = { state: this.detail.dispatch_from_state };
        }else if(type == 'supplier_reg'){
            data = { state: this.detail.supplier_state };
        }else if(type == 'ss_billing'){
            data = { state: this.detail.ss_billing_state };
        }else if(type == 'ss_shipping'){
            data = { state: this.detail.ss_shipping_state };
        }
        this._adminorderdetails.getcity(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.cityArray = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    editinvoicenumber(newadrs, type, invoice_type) {
        this.edit_type = type;
        this.edit_invoice_type = invoice_type;
        if(invoice_type == 'buyer'){
            this.edit_invoice_number = this.detail.invoice_number;
        }else{
            this.edit_invoice_number = this.detail.superseller_invoice_number;
        }
        this.modalService.open(newadrs, { windowClass: 'lgmodal mblbtm' });
    }

    editorderdetailsmodal(newadrs, type) {
        this.edit_type = type;
        this.getcity(type);
        this.modalService.open(newadrs, { windowClass: 'lgmodal mblbtm' });
    }

    updateorderdetails() {
        var data = {};
        if(this.edit_type == 'invoice'){
            data = {
                order_id: this.order_id,
                type: this.edit_type,
                invoice_type: this.edit_invoice_type,
                value: this.edit_invoice_number
            }
        }else if(this.edit_type == 'billing'){
            data = {
                order_id: this.order_id,
                type: this.edit_type,
                address: this.detail.billing_address,
                state: this.detail.billing_state,
                city: this.detail.billing_city,
                pincode: this.detail.billing_pincode,
                address_name: this.detail.billing_address_name
            }
        }else if(this.edit_type == 'shipping'){
            data = {
                order_id: this.order_id,
                type: this.edit_type,
                address: this.detail.shipping_address,
                state: this.detail.shipping_state,
                city: this.detail.shipping_city,
                pincode: this.detail.shipping_pincode,
                address_name: this.detail.shipping_address_name
            }
        }else if(this.edit_type == 'dispatch'){
            data = {
                order_id: this.order_id,
                type: this.edit_type,
                address: this.detail.dispatch_from_address,
                state: this.detail.dispatch_from_state,
                city: this.detail.dispatch_from_city,
                pincode: this.detail.dispatch_from_pincode
            }
        }else if(this.edit_type == 'supplier_reg'){
            data = {
                order_id: this.order_id,
                type: this.edit_type,
                address: this.detail.supplier_address,
                state: this.detail.supplier_state,
                city: this.detail.supplier_city,
                pincode: this.detail.supplier_pincode,
                address_name: this.detail.supplier_name
            }
        }else if(this.edit_type == 'ss_billing'){
            data = {
                order_id: this.order_id,
                type: this.edit_type,
                address: this.detail.ss_billing_address,
                state: this.detail.ss_billing_state,
                city: this.detail.ss_billing_city,
                pincode: this.detail.ss_billing_pincode,
                address_name: this.detail.ss_billing_name
            }
        }else if(this.edit_type == 'ss_shipping'){
            data = {
                order_id: this.order_id,
                type: this.edit_type,
                address: this.detail.ss_shipping_address,
                state: this.detail.ss_shipping_state,
                city: this.detail.ss_shipping_city,
                pincode: this.detail.ss_shipping_pincode,
                address_name: this.detail.ss_shipping_name
            }
        }
        this._adminorderdetails.updateorderdetails(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.orderdetails();
                this._toastr.success('Order details has been updated successfully.');
                this.modalService.dismissAll();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    openWindowUploadInvoice(content3) {
        this.modalService.open(content3, { windowClass: 'midmodal', centered: true, });
    }

    public proceedtopay_id:string = '';
    openproceedtopay(content3, id:any) {
        this.proceedtopay_id = id;
        this.modalService.open(content3, { windowClass: 'midmodal', centered: true, });
    }

    openWindowUploadInvoice1(content3, type) {
        this.user_invoice_type = type;
        this.modalService.open(content3, { windowClass: 'midmodal', centered: true, });
    }

    uploadinvoice() {
        this._adminorderdetails.uploadinvoice({ orderId: this.order_id, type: this.user_invoice_type }).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this._toastr.success('Invoice has been uploaded successfully.');
                this.orderdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    noimeiqc(){
        this._adminorderdetails.noimeiqc({ orderId: this.order_id}).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('IMEI & QC status has been updated.');
                this.orderdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    generatedetails(type){
        let data: any = new FormData();
        data.append('orderId', this.order_id);
        data.append('type', type);
        if(type == 'shipping_label'){
            data.append("document", this.shipping_label_document);
        }else if(type == 'eway_bill'){
            data.append("document", this.eway_bill_document);
        }else if(type == 'ss_eway_bill'){
            data.append("document", this.ss_eway_bill_document);
        }else if(type == 'manifest'){
            data.append("document", this.manifest_document);
        }else if(type == 'imeilist'){
            data.append("imeilist", this.imeilist_doucment);
        }
        
        this._adminorderdetails.generatedetails(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                if(type == 'shipping_label'){
                    this._toastr.success('Shipping label has been uploaded successfully.');
                }else if(type == 'eway_bill'){
                    this._toastr.success('Eway bill has been uploaded successfully.');
                }else if(type == 'ss_eway_bill'){
                    this._toastr.success('Eway bill has been uploaded successfully.');
                }else if(type == 'manifest'){
                    this._toastr.success('Manifest document has been uploaded successfully.');
                }else if(type == 'imeilist'){
                    this._toastr.success('IMEI list document has been uploaded successfully.');
                }
                    
                this.orderdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    uploadFile(event, type) {
        const file = event.target.files;
        if(type == 'shipping_label'){
            this.shipping_label_document = file[0];
        }else if(type == 'eway_bill'){
            this.eway_bill_document = file[0];
        }else if(type == 'ss_eway_bill'){
            this.ss_eway_bill_document = file[0];
        }else if(type == 'manifest'){
            this.manifest_document = file[0];
        }else if(type == 'imeilist'){
            this.imeilist_doucment = file[0];
        }
        event.target.value = '';
    }

    generateinvoice(type) {
        this._adminorderdetails.generateinvoice({ orderId: this.order_id, type: type }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Invoice has been generated successfully.');
                this.orderdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    regenerateinvoice(type) {
        this._adminorderdetails.regenerateinvoice({ orderId: this.order_id, type: type }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Invoice has been generated successfully.');
                this.orderdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    generatemanifest(){
        this._adminorderdetails.generatemanifest({orderId: this.order_id}).subscribe((data) => {
            if (data['status'] == "success") {
                window.open(data['manifest_path'], '_blank');
                this.orderdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }


    updatelogisticspartner() {
        var type = 'all';
        this._adminorderdetails.updatelogisticspartner({ tracking_no: this.detail.tracking_number, order_id: this.order_id, partner_id: this.detail.logistic_partner_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Logistics details has been updated successfully.');
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    updatestatus(status) {
        this._adminorderdetails.updatestatus({ order_id: this.order_id, status: status }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Order status has been updated successfully.');
                this.orderdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    readytodispatch(){
        this._adminorderdetails.readytodispatch({id: this.order_id}).subscribe((data) => {
            if(data['status'] == "success"){
                this._toastr.success('Stock status has been updated successfully.');
                this.orderdetails();
            }else if(data['status'] == "error"){
              this._toastr.error(data['message']);
            }
        });
    }

    acceptqcorder(){
        this._adminorderdetails.acceptqcorder({id: this.order_id}).subscribe((data) => {
            if(data['status'] == "success"){
                this._toastr.success('Products has been verified successfully.');
                this.orderdetails();
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }
        });
    }
    
   
  imagesSlider = {
	 slidesToShow: 1,
	  slidesToScroll: 1,
	  arrows: false,
	  fade: true,
	  asNavFor:".thumbs",
	};
	thumbnailsSlider = {
	  slidesToShow: 5,
	  slidesToScroll: 1,
	  dots: true,
	  focusOnSelect: true,
	  asNavFor:".feedback",
	  //prevArrow:'.client-thumbnails .prev-arrow',
	 // nextArrow:'.client-thumbnails .next-arrow',
	};
	slides = [];

    imageUpload(event: any) {
        for (var i = 0; i < event.target.files.length; i++) {
            this.return_images.push(event.target.files[i]);
        }
    }

    submitreturn(type){
        let data = {
            meta_id: this.order_id,
            type:type
        }
        this._adminorderdetails.acceptrejectreturn(data).subscribe((data) => {
            if (data['status'] == "success") {
                if(type == 'approve'){
                    this._toastr.success('Return request has been accepted successfully.');
                }else if(type == 'reject'){
                    this._toastr.success('Return request has been rejected successfully.');
                }
                this.modalService.dismissAll();
                this.orderdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        }, (error) => {
            this._toastr.error(data['message']);
        });
    }

    initpickup(type){
        let data = {
            meta_id: this.order_id,
            type:type
        }
        this._adminorderdetails.initpickup(data).subscribe((data) => {
            if (data['status'] == "success") {
                if(type == 'readytopickup'){
                    this._toastr.success('Return pickup has been initiated');
                }else if(type == 'delivered'){
                    this._toastr.success('Return request products has been delivered.');
                }
                this.modalService.dismissAll();
                this.orderdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        }, (error) => {
            this._toastr.error(data['message']);
        });
    }

    proceedtopayment(){
        let data = {
            id: this.proceedtopay_id
        }
        this._adminorderdetails.proceedtopay(data).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Payment has been transferred successfully');
                this.modalService.dismissAll();
                this.orderdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        }, (error) => {
            this._toastr.error(data['message']);
        });
    }

    generatechargesinvoice(rec, type) {
        if(type == 'buyer'){
            this._pageservice.buyerchargeinvoicegenerate({ id: this.order_id }).subscribe((data) => {
                if (data['status'] == "success") {
                    this._toastr.success('Buyer charges invoice has been created successfully.');
                    this.orderdetails();
                } else if (data['status'] == "error") {
                    this._toastr.error(data['message']);
                }
            });
        }else{
            if(rec.candf == 'yes'){
                this._pageservice.subsellerchargeinvoicegenerate({ id: this.order_id }).subscribe((data) => {
                    if (data['status'] == "success") {
                        this._toastr.success('Charges invoice has been created successfully.');
                        this.orderdetails();
                    } else if (data['status'] == "error") {
                        this._toastr.error(data['message']);
                    }
                });
            }else{
                this._pageservice.sellerchargeinvoicegenerate({ id: this.order_id }).subscribe((data) => {
                    if (data['status'] == "success") {
                        this._toastr.success('Charges invoice has been created successfully.');
                        this.orderdetails();
                    } else if (data['status'] == "error") {
                        this._toastr.error(data['message']);
                    }
                });
            }
        }
    }
}
