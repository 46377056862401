<app-header-buyer></app-header-buyer>
<app-sidebar-buyer></app-sidebar-buyer>
<div class="wrpmain-buyer">
	<div class="container">
		<!--- HEAD  --->
		<div class="row align-items-center justify-content-between mb-4 pinfo-head">
			<div class="col-md-6 col-7">
				<h3 class="fs24 f-700 txtdefault">Hello {{ user_name }},</h3>
				<nav class="breadcrumb breadcrumb-dui mb-0">
					<ol class="breadcrumb p-0 bg-transparent  mb-0">
						<li class="breadcrumb-item">Welcome to Equibiz Platform</li>
					</ol>
				</nav>
			</div>
		</div>

		<div class="row">
			<div class="col-md-12">
			   <div class="card cardui carduipro p-4 mb-20">
				  <div class="row">
					 <div class="col-md-3">
						<div class="fs16 f-700 mb-20">Purchased Overview</div>
					 </div>
					 <div class="col-md-9">
						<div class="row">
						   <div class="col-md-8">
						   </div>		
						   <div class="col-md-4">
							   <ng-selectize [config]="config" [options] = "months_array" placeholder="" [(ngModel)]="purchase_overview_filter" (ngModelChange)="mydashboarddetails('purchase_overview')">
							   </ng-selectize>	
						   </div>		
						</div>
					 </div>
				  </div>
				  <div class="row align-items-center">
					 <div class="col-md-3 mb-xs-20">
						<div class="dash-left">
						   <div class="d-flex align-items-center justify-content-between mb-2">
							  <div><img src="assets/images/icons/bag.png"></div>
							  <!-- <div class="key-value">+ 25.27%</div> -->
						   </div>
						   <div class="fs24 f-700">{{ total_purchase }}</div>
						   <div class="text-muted fs16 f-500">Purchased</div>

						   <hr>

						   <div class="d-flex align-items-center justify-content-between mb-2">
							  <div><img src="assets/images/icons/money.png"></div>
							  <!-- <div class="key-value">+ 35.18%</div> -->
						   </div>
						   <div class="fs24 f-700">{{ total_orders }}</div>
						   <div class="text-muted fs16 f-500">Total Order</div>
						</div>
					 </div>
					 <div class="col-md-9">
						<div>
						   <div id="chart">
							   <apx-chart
								   [series]="chartOptions.series"
								   [chart]="chartOptions.chart"
								   [xaxis]="chartOptions.xaxis"
								   [dataLabels]="chartOptions.dataLabels"
								   [grid]="chartOptions.grid"
								   [stroke]="chartOptions.stroke"
								   [title]="chartOptions.title"
							   ></apx-chart>
						   </div>
						</div>
					 </div>
				  </div>
			   </div>
			</div>
		 </div>

		 <div class="row">
			<div class="col-md-12">
			   <div class="card cardui carduipro p-4 mb-20">
				   <div class="row d-flex align-items-center mb-20">
				   <div class="col-md-8">
					   <div class="fs16 f-700 mb-0">Pending Orders for Acceptance</div>
				   </div>		
				   <div class="col-md-4">
					   <ng-selectize [config]="config" [options] = "months_array" placeholder="" [(ngModel)]="pending_order_filter" (ngModelChange)="mydashboarddetails('pending_order')">
					   </ng-selectize>	
				   </div>		
				</div>
				  <div class="row">
					 <div class="col-md-3">
						<div class="dash-left mb-20 mb-xs-10">
						   <a href="javascript:;">
							  <div class="fs24 f-700 text-dark">{{ total_pending }}</div>
							  <div class="text-muted fs16 f-500">Total Pending</div>
						   </a>
						</div>
					 </div>
					 <div class="col-md-3">
						<div class="dash-left mb-20 mb-xs-10">
						   <a href="javascript:;">
							  <div class="fs24 f-700 text-dark">{{ bulk_orders }}</div>
							  <div class="text-muted fs16 f-500">Bulk Orders</div>
						   </a>
						</div>
					 </div>
					 <div class="col-md-3">
						<div class="dash-left mb-20 mb-xs-10">
						   <a href="javascript:;">
							  <div class="fs24 f-700 text-dark">{{ wholesale_orders }}</div>
							  <div class="text-muted fs16 f-500">Wholesale Orders</div>
						   </a>
						</div>
					 </div>
					 <div class="col-md-3">
						<div class="dash-left mb-20 mb-xs-10">
						   <a href="javascript:;">
							  <div class="fs24 f-700 text-dark">{{ rejected_orders }}</div>
							  <div class="text-muted fs16 f-500">Rejected Orders</div>
						   </a>
						</div>
					 </div>
				  </div>



			   </div>
			</div>
		 </div>

		<div class="row">
		   	<div class="col-md-12">
			  	<div class="card cardui carduipro p-4 mb-20">
			  	<div class="row d-flex align-items-center mb-20">
				   	<div class="col-md-8">
					   	<div class="fs16 f-700 mb-0">Orders</div>
				   	</div>		
				   	<div class="col-md-4">
					   <ng-selectize [config]="config" [options] = "months_array" placeholder="" [(ngModel)]="order_filter" (ngModelChange)="mydashboarddetails('orders')">
					   </ng-selectize>	
				   	</div>		
				</div>

				 <div class="row">
					<div class="col-md-3">
					   <div class="dash-left mb-xs-10 mb-20">
						  <a href="javascript:;">
							 <div class="fs24 f-700 text-dark">{{ orders_details.total_order }}</div>
							 <div class="text-muted fs16 f-500">All Orders</div>
						  </a>
					   </div>
					</div>
					<div class="col-md-3">
					   <div class="dash-left mb-xs-10 mb-20">
						  <a href="javascript:;">
							 <div class="fs24 f-700 text-dark">{{ orders_details.booked }}</div>
							 <div class="text-muted fs16 f-500">Booked Orders</div>
						  </a>
					   </div>
					</div>
					<div class="col-md-3">
					   <div class="dash-left mb-xs-10 mb-20">
						  <a href="javascript:;">
							 <div class="fs24 f-700 text-dark">{{ orders_details.approved }}</div>
							 <div class="text-muted fs16 f-500">Approved Orders</div>
						  </a>
					   </div>
					</div>
					<div class="col-md-3">
					   <div class="dash-left mb-xs-10 mb-20">
						  <a href="javascript:;">
							 <div class="fs24 f-700 text-dark">{{ orders_details.rejected }}</div>
							 <div class="text-muted fs16 f-500">Rejected Orders</div>
						  </a>
					   </div>
					</div>

					<div class="col-md-3">
					   <div class="dash-left mb-xs-10 mb-20">
						  <a href="javascript:;">
							 <div class="fs24 f-700 text-dark">{{ orders_details.payment_done }}</div>
							 <div class="text-muted fs16 f-500">Payment Done</div>
						  </a>
					   </div>
					</div>

					<div class="col-md-3">
					   <div class="dash-left mb-xs-10 mb-20">
						  <a href="javascript:;">
							 <div class="fs24 f-700 text-dark">{{ orders_details.ready_to_dispatch }}</div>
							 <div class="text-muted fs16 f-500">Ready To Dispatch</div>
						  </a>
					   </div>
					</div>

					<div class="col-md-3">
					   <div class="dash-left mb-xs-10 mb-20">
						  <a href="javascript:;">
							 <div class="fs24 f-700 text-dark">{{ orders_details.qc_done }}</div>
							 <div class="text-muted fs16 f-500">Quality Done</div>
						  </a>
					   </div>
					</div>

					<div class="col-md-3">
					   <div class="dash-left mb-xs-10 mb-20">
						  <a href="javascript:;">
							 <div class="fs24 f-700 text-dark">{{ orders_details.dispatched }}</div>
							 <div class="text-muted fs16 f-500">Dispatched</div>
						  </a>
					   </div>
					</div>

					<div class="col-md-3">
					   <div class="dash-left mb-xs-10 mb-20">
						  <a href="javascript:;">
								 <div class="fs24 f-700 text-dark">{{ orders_details.delivered }}</div>
								 <div class="text-muted fs16 f-500">Delivered</div>
						  </a>
					   </div>
					</div>
				 </div>



			  </div>
		   </div>
		</div>


		<!-- Buyer Order History -->
		<div class="card cardui carduipro p-4 mb-0 table-card">
			<div class="notifications-container theme-tab xs-scroller">
				  <ngb-tabset [justify]="'left'" [type]="'pills'" class="tabs tab-title">
					<!-- All Orders -->
					<ngb-tab>
						<ng-template ngbTabTitle><span (click)="getbuyerorders('all')">All Orders</span></ng-template>
					</ngb-tab>

					<!-- Booked Orders -->
					<ngb-tab>
						<ng-template ngbTabTitle><span (click)="getbuyerorders('booked')">Booked</span></ng-template>
					</ngb-tab>

					<!-- Approved Orders -->
					<ngb-tab>
						<ng-template ngbTabTitle><span (click)="getbuyerorders('approved')">Approved</span></ng-template>
					</ngb-tab>

					<!-- Rejected Orders -->
					<ngb-tab>
						<ng-template ngbTabTitle><span (click)="getbuyerorders('rejected')">Rejected</span></ng-template>
					</ngb-tab>

					<!-- Payment Done Orders -->
					<ngb-tab>
						<ng-template ngbTabTitle><span (click)="getbuyerorders('payment_done')">Payment Done</span></ng-template>
					</ngb-tab>

					<!-- Ready to Dispatch Orders -->
					<ngb-tab>
						<ng-template ngbTabTitle><span (click)="getbuyerorders('ready_to_dispatch')">Ready to Dispatch</span></ng-template>
					</ngb-tab>

					<!-- Quality Done Orders -->
					<ngb-tab>
						<ng-template ngbTabTitle><span (click)="getbuyerorders('quality_done')">Quality Done</span></ng-template>
					</ngb-tab>

					<!-- Dispatched Orders -->
					<ngb-tab>
						<ng-template ngbTabTitle><span (click)="getbuyerorders('dispatched')">Dispatched</span></ng-template>
					</ngb-tab>

					<!-- Delivered Orders -->
					<ngb-tab>
						<ng-template ngbTabTitle><span (click)="getbuyerorders('delivered')">Delivered</span></ng-template>
					</ngb-tab>

				</ngb-tabset>
				
			</div>

			<div class="tab-content">
				<!--Filters-->
				<div class="row d-flex justify-content-between mt-20 report-cta">
				  <div class="col-md-5">
					  <div class="report-input">
						  <div class="input-group-custom">
							  <input type="text" placeholder="Search by Product name, Order ID & Transaction ID" 
							  class="form-control" [(ngModel)]="search_term" (change)="getbuyerordersfilters()">
							  <div class="input-group-append-custom">
								  <img src="assets/images/svg/search_logo.svg">
							  </div>
						  </div>
					  </div>
				  </div>

				  <div class="col-md-7 mt-xs-10">
					  <div class="d-flex justify-content-end">
						  <div class="form-container mt-0 mb-0 mr-3">
							  <div class="report-input floating-label">
								  <div class="input-group-custom">
									  <input type="text" [(ngModel)]="from_date" (ngModelChange) = "getbuyerordersfilters()" class="form-control" placeholder=" " ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" autocomplete="off"/>
									  <span class="highlight"></span>
									  <label>From Date</label>
									  <div class="input-group-append-custom">
										  <img src="assets/images/icons/filter-calendar.svg">
									  </div>
								  </div>
							  </div>
						  </div>

						  <div class="form-container mt-0 mb-0 mr-3">
							  <div class="form-container m-0">
								  <div class="report-input floating-label">
									  <div class="input-group-custom">
										  <input type="text" [(ngModel)]="to_date" (ngModelChange) = "getbuyerordersfilters()" class="form-control" placeholder=" " ngbDatepicker #dd="ngbDatepicker" (click)="dd.toggle()" autocomplete="off"/>
										  <span class="highlight"></span>
										  <label>To Date</label>
										  <div class="input-group-append-custom">
											  <img src="assets/images/icons/filter-calendar.svg">
										  </div>
									  </div>
								  </div>
							  </div>
						  </div>
					  </div>
				  </div>
			  </div>
			  <!--End Filters-->
			  <table class="table custom-table xs">
				  <thead>
					  <tr>
						  <th>Order Id</th>
						  <th>Transaction Id</th>
						  <th width="18%">Product Name</th>
						  <th>Market Place</th>
						  <th>Price (in ₹)</th>
						  <th>Pre-Booked On <i class="long-arrow-down"></i></th>
						  <th>Order Status</th>
						  <th>Action</th>
					  </tr>
				  </thead>
				  <tbody>
					  <!--Empty State-->
					  <tr *ngIf="!buyer_orders.length">
						  <td colspan="7">
							  <div class="row pt-40 pb-40">
								  <div class="col-md-12 text-center">
									  <div class="mb-20"><img src="/assets/images/empty-state.png">
									  </div>
									  <div class="fs16">There are no records to display!</div>
								  </div>
							  </div>
						  </td>
					  </tr>
					  <!--End Empty State-->
					  <tr *ngFor="let record of buyer_orders;">
						  <td>{{ record.order_id }}</td>
						  <td>{{ record.transaction_id }}</td>
						  <td>
							  <div class="order-info">
								  <div class="order-img">
									  <img src="{{ record.product_image }}" />
								  </div>
								  <div class="pd-seller-cntnt">
									  <div class="txtdefault">
										  <a href="javascript:;" class="order-link">{{ record.product_name }}</a>
									  </div>
								  </div>
							  </div>
						  </td>
						  <td>{{ record.map_type }}</td>
						  <td>{{ record.total_amount }}</td>
						  <td>{{ record.prebookedon }}</td>											
						  <td><div>{{ record.order_status }}</div></td>
						  
						  <td class="d-flex align-items-center">
							  <a href="javascript:void(0);" [routerLink]="['/buyer/buyer-view-order-details/'+record.id]" class="btn-d-outline ripple d-inline-block place-bid mr-3">View Order</a>
						  </td>
					  </tr>
				  </tbody>
			  </table>
			</div>
			
		</div>
		
		<div class="row mb-40" *ngIf="buyer_order_pagination.totalPages> 1">
			<div class="col-md-6">
				<div class="text-left pl-3">
					<nav class="mt-3 tbpg rows-per-page">
						<div>Rows per page:</div>
						<select class="select-box" [(ngModel)]="page_per_view" (change)="setpageperview()">
							<option *ngFor="let option of page_per_view_options" [ngValue]="option.value">
								{{option.name}}</option>
						</select>
					</nav>
				</div>
			</div>
			<div class="col-md-6">
				<div class="text-right md-text-left pr-3">
					<nav class="mt-3 tbpg">
						<ul class="pagination align-items-center">
							<li class="page-item" [ngClass]="{disabled:buyer_order_pagination.currentPage === 1}"><a
									class="page-link previcon" href="javascript:;" (click)="setpage(1)"></a>
							</li>

							<li class="page-item" [ngClass]="{disabled:buyer_order_pagination.currentPage === 1}"><a
									class="page-link sprevicon" href="javascript:;"
									(click)="setpage(buyer_order_pagination.currentPage - 1)"></a></li>

							<li class="page-item" i *ngFor="let page of buyer_order_pagination.pages"
								[ngClass]="{active:buyer_order_pagination.currentPage === page}"><a class="page-link"
									href="javascript:;" (click)="setpage(page)">{{page}}</a></li>

							<li *ngIf="(buyer_order_pagination.totalPages - buyer_order_pagination.startPage) > 5 ">
								<a (click)="setpage(buyer_order_pagination.startPage + 5)">...</a>
							</li>
							<li *ngIf="(buyer_order_pagination.totalPages - buyer_order_pagination.startPage) > 5">
								<a
									(click)="setpage(buyer_order_pagination.totalPages)">{{buyer_order_pagination.totalPages}}</a>
							</li>

							<li class="page-item"
								[ngClass]="{disabled:buyer_order_pagination.currentPage === buyer_order_pagination.totalPages}">
								<a class="page-link snexticon" href="javascript:;"
									(click)="setpage(buyer_order_pagination.currentPage + 1)"></a>
							</li>
							<li class="page-item"
								[ngClass]="{disabled:buyer_order_pagination.currentPage === buyer_order_pagination.totalPages}">
								<a class="page-link nexticon" href="javascript:;"
									(click)="setpage(buyer_order_pagination.totalPages)"></a>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</div>


	</div>
</div>
<app-footer-buyer></app-footer-buyer>