import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AdminPersonalDetailsService } from './personal-details.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '../../../helpers/toastr.service';
import { EncrDecrService } from '../../../helpers/EncrDecrService';

@Component({
    selector: 'app-personal-details',
    templateUrl: './personal-details.component.html',
    styleUrls: ['./personal-details.component.scss']
})
export class AdminPersonalDetailsComponent implements OnInit {

    closeResult = '';
    public user_type: string = 'admin';
    public profile_image_url: any;
    public personal_detail: any = {};
    public profile_image: any;
    public otp: any;
    public otp1: number;
    public otp2: number;
    public otp3: number;
    public otp4: number;
    public changeEmail: boolean = false;
    public changeMobile: boolean = false;
    public changeValue: string;
    public current_password: string;
    public new_password: string;
    public confirm_new_password: string;
    public verify_type: string;
    constructor(
        private modalService: NgbModal,
        private route: ActivatedRoute,
        private _router: Router,
        private _toastr: ToasterService,
        private _EncrDecr: EncrDecrService,
        private _personaldetailsservice: AdminPersonalDetailsService
    ) { }

    getdetails() {
        this._personaldetailsservice.userbasicinfo(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.personal_detail = data['data'];
                this.profile_image_url = this.personal_detail.profile_image;
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    onDigitInput(event: any) {
        let element;
        if (event.code !== 'Backspace')
            element = event.srcElement.nextElementSibling;
        if (event.code === 'Backspace')
            element = event.srcElement.previousElementSibling;
        if (element == null)
            return;
        else
            element.focus();
    }



    open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'mblbtm' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    openmdl(profilephoto) {
        this.modalService.open(profilephoto, { windowClass: 'midmodal' });
    }

    ngOnInit(): void {
        this.getdetails();
    }

    uploadImage(event) {
        const file = event.target.files[0];
        this.profile_image = file;
        this.uploadProfileImage();
    }



    uploadProfileImage() {
        let data: any = new FormData();
        data.append("document", this.profile_image);
        data.append('user_type', this.user_type);
        this._personaldetailsservice.uploadprofileimage(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.profile_image_url = data['image'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    changeemail(content) {
        this.verify_type = 'email';
        this.changeValue = '';
        this.open(content);
    }

    changemobile(content) {
        this.verify_type = 'mobile';
        this.changeValue = '';
        this.open(content);
    }

    submitPassword() {
        var data = {
            current_password: this.current_password,
            new_password: this.new_password,
            confirm_new_password: this.confirm_new_password,
            user_type: this.user_type
        };
        this._personaldetailsservice.changepassword(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this._toastr.success('Password changed.');
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    changeotp(changecontent) {
        if (!this.changeValue) {
            this._toastr.error('Please enter valid value');
            return false;
        }
        this._personaldetailsservice.changevalue({ type: this.verify_type, unique_value: this.changeValue, user_type: this.user_type }).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this.open(changecontent);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }


    changeresendotp(changecontent) {
        this._personaldetailsservice.changevalue({ type: this.verify_type, unique_value: this.changeValue, user_type: this.user_type }).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this.open(changecontent);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    changevalue() {
        this.otp = this.otp1 + "" + this.otp2 + "" + this.otp3 + "" + this.otp4;
        this._personaldetailsservice.changeprofile({ type: this.verify_type, unique_value: this.changeValue, otp: this.otp, user_type: this.user_type }).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                if (this.verify_type == 'email') {
                    this._toastr.success('Email address has been changed successfully.');
                } else {
                    this._toastr.success('Mobile number has been changed successfully.');
                }
                this.getdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }


}
