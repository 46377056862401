import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { EditSellerListingService } from './edit-seller-listing.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '../../helpers/toastr.service';
import { EncrDecrService } from '../../helpers/EncrDecrService';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';

@Component({
    selector: 'app-edit-seller-listing',
    templateUrl: './edit-seller-listing.component.html',
    styleUrls: ['./edit-seller-listing.component.scss']
})
export class EditSellerListingComponent implements OnInit {
    uniqueForm: FormGroup;
    productForm: FormGroup;
    marketplaceForm: FormGroup;
    pricingForm: FormGroup;
    paymentForm: FormGroup;

    active = 10;
    sampleactive = 1;
    disabled = true;
    public mrp_price:number = 0;
    public wholesale_price_warning_msg:string;
    public wholesale_mrp_price_error_msg:string;
    public bulk_mrp_price_error_msg:string;
    public wholesale_price_warning:boolean = false;
    public bulk_price_warning_msg:string;
    public bulk_price_warning:boolean = false;
    public seller_address: any = [];
    public upper_bracket_amount: number;
    public lower_bracket_amount: number;
    public bulk_realization_details: any = {};
    public wholesale_realization_details: any = {};
    public display_details: any = {};
    public hsns: any = [];
    public selected_hsn_id: string;
    public listing_status: boolean;
    public seller_id: string;
    public variant_status: boolean;
    public slides = [];
    public unique_id: string;
    public active_variant_id: string;
    public consent_file: string;
    public consent_file_status: boolean = false;
    public variants = [];
    public active_variants = [];
    public info: any = {};
    public countryArray: Array<Object> = [];
    public netrealization_type:string = 'inside';
    public packArray: Array<Object> = [
        {
            name: 1,
            value: 1,
        },
        {
            name: 2,
            value: 2,
        },
        {
            name: 3,
            value: 3,
        },
        {
            name: 4,
            value: 4,
        },
        {
            name: 5,
            value: 5,
        },
        {
            name: 6,
            value: 6,
        },
        {
            name: 7,
            value: 7,
        },
        {
            name: 8,
            value: 8,
        },

        {
            name: 9,
            value: 9,
        },
        {
            name: 10,
            value: 10,
        },
    ];

    units = [
        {
            label: 'In Months',
            value: 'month'
        },
        {
            label: 'In Year',
            value: 'year'
        }
    ]

    deatweight_units = [
        {
            label: 'In Gram',
            value: 'gram'
        },
        {
            label: 'In KG',
            value: 'kg'
        }
    ]

    product_status_array = [
        {
            label: 'None',
            value: 'none'
        },
        {
            label: 'Activated',
            value: 'activated'
        },
        {
            label: 'Fresh',
            value: 'fresh'
        }
    ]

    constructor(
        private modalService: NgbModal,
        private _editsellerlistingservice: EditSellerListingService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private _router: Router,
        private _toastr: ToasterService,
        private _EncrDecr: EncrDecrService,
    ) { }
    flactive = 13;
    ngOnInit(): void {
        this.unique_id = this.route.snapshot.paramMap.get('uniqueId');
        this.seller_id = this.route.snapshot.paramMap.get('sellerId');
        this.getcountry();

        //Unique Form
        this.uniqueForm = this.fb.group({
            eb_product_id: this.fb.control(null),
            sku: this.fb.control(null, []),
            hsn: this.fb.control(null, [Validators.required]),
            crs: this.fb.control(null, []),
        });

        //Product Form
        this.productForm = this.fb.group({
            market_operated_price: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            country: this.fb.control("", [Validators.required]),
            no_item_perpack: this.fb.control("", [Validators.required]),
            product_status: this.fb.control("", [Validators.required]),
            length: this.fb.control(null, [Validators.required]),
            width: this.fb.control(null, [Validators.required]),
            height: this.fb.control(null, [Validators.required]),
            deadweight: this.fb.control(null, [Validators.required]),
            deadweight_unit: this.fb.control(null, [Validators.required]),
            domestic_warranty: this.fb.control(null, []),
            domestic_warranty_unit: this.fb.control(null, []),
            international_warranty: this.fb.control(null, []),
            international_warranty_unit: this.fb.control(null, []),
            warranty_summary: this.fb.control(null, []),
            warranty_server_type: this.fb.control(null, []),
            warranty_by_brand: this.fb.control(false, []),
        });

        //Marketplace Form
        this.marketplaceForm = this.fb.group({
            wholesale: this.fb.control(true, [Validators.required]),
            bulk: this.fb.control(false, [Validators.required]),
            bid_offer: this.fb.control(false, [Validators.required]),
        });

        //Pricing Form
        this.pricingForm = this.fb.group({
            wholesale_price: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            wholesale_qty: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            wholesale_min_qty: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            bulk_price: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            bulk_qty: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            //bulk_min_qty: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            lowest_bid_amt: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            bid_upper_moq: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
            bid_lower_moq: this.fb.control(null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
        });

        //Payment Form
        this.paymentForm = this.fb.group({
            payment_type: this.fb.control(null),
            product_location: this.fb.control(null, [Validators.required]),
            delivery_date: this.fb.control(null, [Validators.required]),
        });

        this.getproductvariants();

        setTimeout(() => {
            this.netrealizationvalue('wholesale');
            this.netrealizationvalue('bulk');
        }, 5000);
    }

    get prf() { return this.productForm.controls; }

    setRequired() {
        return [Validators.required];
    }

    isWarrantyChange(event) {
        if (this.productForm.value.warranty_by_brand) {
            this.productForm.get('domestic_warranty').clearValidators();
            this.productForm.controls['domestic_warranty'].updateValueAndValidity();
            this.productForm.get('domestic_warranty_unit').clearValidators();
            this.productForm.controls['domestic_warranty_unit'].updateValueAndValidity();
            this.productForm.get('international_warranty').clearValidators();
            this.productForm.controls['international_warranty'].updateValueAndValidity();
            this.productForm.get('international_warranty_unit').clearValidators();
            this.productForm.controls['international_warranty_unit'].updateValueAndValidity();
            this.productForm.get('warranty_summary').clearValidators();
            this.productForm.controls['warranty_summary'].updateValueAndValidity();
            this.productForm.get('warranty_server_type').clearValidators();
            this.productForm.controls['warranty_server_type'].updateValueAndValidity();
        } else {
            this.productForm.get('domestic_warranty').setValidators(this.setRequired());
            this.productForm.controls['domestic_warranty'].updateValueAndValidity();
            this.productForm.get('domestic_warranty_unit').setValidators(this.setRequired());
            this.productForm.controls['domestic_warranty_unit'].updateValueAndValidity();
            this.productForm.get('international_warranty').setValidators(this.setRequired());
            this.productForm.controls['international_warranty'].updateValueAndValidity();
            this.productForm.get('international_warranty_unit').setValidators(this.setRequired());
            this.productForm.controls['international_warranty_unit'].updateValueAndValidity();
            this.productForm.get('warranty_summary').setValidators(this.setRequired());
            this.productForm.controls['warranty_summary'].updateValueAndValidity();
            this.productForm.get('warranty_server_type').setValidators(this.setRequired());
            this.productForm.controls['warranty_server_type'].updateValueAndValidity();
        }
    }

    getproductvariants() {
        this._editsellerlistingservice.getvariants({ unique_id: this.unique_id, seller_id: this.seller_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.variants = data['variantlist'];
                var active_record = this.variants.find(element => {
                    return element.selected == true;
                });
                this.active_variants = this.variants.filter((product) => product.selected == true);
                this.active_variant_id = active_record.id;
                this.getsellerproductdetails(this.active_variant_id);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getsellerproductdetails(variant_id) {
        this.active_variant_id = variant_id;
        this._editsellerlistingservice.getsellerproductdetails({ unique_id: this.unique_id, variant_id: this.active_variant_id, seller_id: this.seller_id }).subscribe((data) => {
            if (data['status'] == "success") {
                var records = data['records'];
                this.info = data['productinfo'];
                this.upper_bracket_amount = records.upper_bracket_amount;
                this.lower_bracket_amount = records.lower_bracket_amount;
                let images = [];
                if (this.info.front_image) {
                    images.push({ img: this.info.front_image });
                }

                if (this.info.back_image) {
                    images.push({ img: this.info.back_image });
                }

                if (this.info.sales_image) {
                    images.push({ img: this.info.sales_image });
                }

                if (this.info.side_image) {
                    images.push({ img: this.info.side_image });
                }

                if (this.info.top_image) {
                    images.push({ img: this.info.top_image });
                }

                this.slides = images;

                this.listing_status = records.listing_status;
                this.variant_status = records.variant_status;

                this.uniqueForm.patchValue({
                    eb_product_id: records.eb_product_id,
                    sku: records.sku,
                    hsn: records.hsn.name,
                    crs: records.crs
                });

                this.selected_hsn_id = records.hsn.code;

                this.productForm.patchValue({
                    market_operated_price: this.info.market_operating_price,
                    country: records.country,
                    no_item_perpack: records.no_item_perpack,
                    product_status: records.product_status,
                    length: records.length,
                    width: records.width,
                    height: records.height,
                    deadweight: records.deadweight,
                    deadweight_unit: records.deadweight_unit,
                    domestic_warranty: records.domestic_warranty,
                    domestic_warranty_unit: records.domestic_warranty_unit,
                    international_warranty: records.internation_warranty,
                    international_warranty_unit: records.internation_warranty_unit,
                    warranty_summary: records.warranty_summary,
                    warranty_server_type: records.warranty_server_type,
                    warranty_by_brand: records.warranty_by_brand,
                });

                //Marketplace Form
                this.marketplaceForm.patchValue({
                    wholesale: records.wholesale,
                    bulk: records.bulk,
                    bid_offer: records.bid_offer,
                });
                //Pricing Form
                this.pricingForm.patchValue({
                    wholesale_price: records.wholesale_price,
                    wholesale_qty: records.wholesale_qty,
                    wholesale_min_qty: records.wholesale_min_qty,
                    bulk_price: records.bulk_price,
                    bulk_qty: records.bulk_qty,
                    //bulk_min_qty: records.bulk_min_qty,
                    lowest_bid_amt: records.lowest_bid_amt,
                    bid_upper_moq: records.bid_upper_moq,
                    bid_lower_moq: records.bid_lower_moq
                });
                this.mrp_price = records.mrp;

                //Payment Form
                this.paymentForm.patchValue({
                    payment_type: records.payment_type,
                    product_location: records.product_location_index,
                    delivery_date: records.delivery_date,
                });

                this.seller_address = records.seller_address;

                this.changebulkmarketplace();


            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    uploadfile(event, type) {
        const file = event.target.files;
        this.consent_file = file[0];
        this.consent_file_status = true;
    }

    submitUnique() {
        let data: any = {
            uniqueId: this.unique_id,
            variantId: this.active_variant_id,
            eb_product_id: this._EncrDecr.set(this.uniqueForm.get('eb_product_id').value),
            sku: this._EncrDecr.set(this.uniqueForm.get('sku').value),
            hsn: this._EncrDecr.set(this.uniqueForm.get('hsn').value),
            crs: this._EncrDecr.set(this.uniqueForm.get('crs').value),
            seller_id: this.seller_id
        };
        this._editsellerlistingservice.saveunique(data).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Unique ID details has been saved successfully.');
                this.active = 11;
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    submitProduct() {

        let data: any = {
            market_operated_price: this._EncrDecr.set(this.productForm.get('market_operated_price').value),
            uniqueId: this.unique_id,
            variantId: this.active_variant_id,
            country: this._EncrDecr.set(this.productForm.get('country').value),
            no_item_perpack: this._EncrDecr.set(this.productForm.get('no_item_perpack').value),
            product_status: this._EncrDecr.set(this.productForm.get('product_status').value),
            length: this._EncrDecr.set(this.productForm.get('length').value),
            width: this._EncrDecr.set(this.productForm.get('width').value),
            height: this._EncrDecr.set(this.productForm.get('height').value),
            deadweight: this._EncrDecr.set(this.productForm.get('deadweight').value),
            deadweight_unit: this._EncrDecr.set(this.productForm.get('deadweight_unit').value),
            domestic_warranty: this._EncrDecr.set(this.productForm.get('domestic_warranty').value),
            domestic_warranty_unit: this._EncrDecr.set(this.productForm.get('domestic_warranty_unit').value),
            internation_warranty: this._EncrDecr.set(this.productForm.get('international_warranty').value),
            internation_warranty_unit: this._EncrDecr.set(this.productForm.get('international_warranty_unit').value),
            warranty_summary: this._EncrDecr.set(this.productForm.get('warranty_summary').value),
            warranty_server_type: this._EncrDecr.set(this.productForm.get('warranty_server_type').value),
            warranty_by_brand: this._EncrDecr.set(this.productForm.get('warranty_by_brand').value),
            seller_id: this.seller_id
        };

        this._editsellerlistingservice.saveproduct(data).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Product details has been saved successfully.');
                this.active = 12;
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    submitMarketplace() {
        let data: any = {
            seller_id: this.seller_id,
            uniqueId: this.unique_id,
            variantId: this.active_variant_id,
            wholesale: this.marketplaceForm.get('wholesale').value,
            bulk: this.marketplaceForm.get('bulk').value,
            bid_offer: this.marketplaceForm.get('bid_offer').value,
        };


        this._editsellerlistingservice.savemarketplace(data).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Marketplace details has been saved successfully.');
                this.active = 13;
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    submitPricing() {
        let data: any = {
            seller_id: this.seller_id,
            uniqueId: this.unique_id,
            variantId: this.active_variant_id,
            wholesale_price: this._EncrDecr.set(this.pricingForm.get('wholesale_price').value),
            wholesale_qty: this._EncrDecr.set(this.pricingForm.get('wholesale_qty').value),
            wholesale_min_qty: this._EncrDecr.set(this.pricingForm.get('wholesale_min_qty').value),
            bulk_price: this._EncrDecr.set(this.pricingForm.get('bulk_price').value),
            bulk_qty: this._EncrDecr.set(this.pricingForm.get('bulk_qty').value),
            bulk_min_qty: this._EncrDecr.set(this.pricingForm.get('bid_lower_moq').value),
            lowest_bid_amt: this._EncrDecr.set(this.pricingForm.get('lowest_bid_amt').value),
            bid_upper_moq: this._EncrDecr.set(this.pricingForm.get('bid_upper_moq').value),
            bid_lower_moq: this._EncrDecr.set(this.pricingForm.get('bid_lower_moq').value),
        };

        this._editsellerlistingservice.savepricing(data).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Pricing details has been saved successfully.');
                this.active = 14;
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    submitPayment() {
        if (this.marketplaceForm.get('bulk').value && !this.paymentForm.get('payment_type').value) {
            this._toastr.error('Please select payment type');
            return false;
        }
        let data: any = {
            seller_id: this.seller_id,
            uniqueId: this.unique_id,
            variantId: this.active_variant_id,
            payment_type: this.paymentForm.get('payment_type').value,
            product_location: this.paymentForm.get('product_location').value,
            delivery_date: this.paymentForm.get('delivery_date').value,
        };

        this._editsellerlistingservice.savepayment(data).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Payment details has been added successfully.');
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getcountry() {
        this._editsellerlistingservice.getcountry(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.countryArray = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    updatestatus(type) {
        var value = this.listing_status;
        if (type == 'variant') {
            value = this.variant_status;
        }
        this._editsellerlistingservice.updatestatus({ seller_id: this.seller_id, unique_id: this.unique_id, variant_id: this.active_variant_id, type: type, value: value }).subscribe((data) => {
            if (data['status'] == "success") {
                this._toastr.success('Product listing status has been changed.');
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    config = {
        labelField: 'label',
        valueField: 'value',
    };
    openWindowCustomClass(content) {
        this.modalService.open(content, { windowClass: 'modal-750' });
    }

    closeResult = '';
    ratingclassstatus: boolean = false;
    ratingtglclass() {
        this.ratingclassstatus = !this.ratingclassstatus;
    }

    showcomperr: boolean = false;
    showcompmsg() {
        this.showcomperr = true;
    }
    imagesSlider = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: ".thumbs",
    };
    thumbnailsSlider = {
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: true,
        focusOnSelect: true,
        asNavFor: ".feedback",
        //prevArrow:'.client-thumbnails .prev-arrow',
        // nextArrow:'.client-thumbnails .next-arrow',
    };

    changebulkmarketplace() {
        if (this.marketplaceForm.get('bulk').value == false) {
            this.marketplaceForm.get('bid_offer').setValue(false);
        }
    }

    keyword = 'name';

    selectHsnEvent(item) {
        this.selected_hsn_id = item.id;
        //this.uniqueForm.get('hsn').setValue(item.name);
    }

    onChangeSearchHsn(val: string) {
        this._editsellerlistingservice.searchhsn({ search: val }).subscribe((data) => {
            if (data['status'] == "success") {
                this.hsns = data['searchResult'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    onFocusedHsn(e) {
        // do something when input is focused
    }

    openbreakup(contentbreakup, type) {
        if (type == 'wholesale') {
            this.display_details = this.wholesale_realization_details;
        } else {
            this.display_details = this.bulk_realization_details;
        }
        this.modalService.open(contentbreakup, { ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true, }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    netrealizationvalue(type) {
        if(type == 'wholesale'){
            var listing_price = this.pricingForm.get('wholesale_price').value;
            var quantity = this.pricingForm.get('wholesale_min_qty').value;
            if(listing_price > 0 && (listing_price > this.upper_bracket_amount || listing_price < this.lower_bracket_amount)){
                this.wholesale_price_warning = true;
                if(listing_price > this.upper_bracket_amount){
                    this.wholesale_price_warning_msg = 'Your price is higher than the MOP admin price';
                }else{
                    this.wholesale_price_warning_msg = 'Your price is lower than the MOP admin price';
                }
            }else{
                this.wholesale_price_warning = false;
                this.wholesale_price_warning_msg = '';
            }

            if(listing_price > 0 && listing_price > this.mrp_price){
                this.wholesale_mrp_price_error_msg = 'Your wholesale price should not be higher than MRP price('+this.mrp_price+')';
            }else{
                this.wholesale_mrp_price_error_msg = '';
            }
            
        }else{
            var listing_price = this.pricingForm.get('bulk_price').value;
            var quantity = this.pricingForm.get('bid_lower_moq').value;
            if(listing_price > 0 && (listing_price > this.upper_bracket_amount || listing_price < this.lower_bracket_amount)){
                this.bulk_price_warning = true;
                if(listing_price > this.upper_bracket_amount){
                    this.bulk_price_warning_msg = 'Your price is higher than the MOP admin price';
                }else{
                    this.bulk_price_warning_msg = 'Your price is lower than the MOP admin price';
                }
            }else{
                this.bulk_price_warning = false;
                this.bulk_price_warning_msg = '';
            }

            if(listing_price > 0 && listing_price > this.mrp_price){
                this.bulk_mrp_price_error_msg = 'Your bulk price should not be higher than MRP price('+this.mrp_price+')';
            }else{
                this.bulk_mrp_price_error_msg = '';
            }
        }
        if (listing_price > 0 && quantity > 0) {
            this._editsellerlistingservice.netrealizationvalue({ seller_id: this.seller_id, product_id: this.active_variant_id, listing_price: listing_price, quantity: quantity, market_place: type }).subscribe((data) => {
                if (data['status'] == "success") {
                    if (type == 'bulk') {
                        this.bulk_realization_details = data['record'];
                    } else {
                        this.wholesale_realization_details = data['record'];
                    }
                } else if (data['status'] == "error") {
                    this._toastr.error(data['message']);
                }
            });
        }
    }

}
