import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SellerTrackOrdersService {

    constructor(private _http: HttpClient) { }

    getorders(data) {
        return this._http.post(environment.api_url+'seller/myorders',data);
    }

    payremainingamount(data) {
     	return this._http.post(environment.api_url+'seller/order/payremaining',data);
    }

    acceptorder(data) {
      	return this._http.post(environment.api_url+'seller/orders/accept',data);
    }

    rejectorder(data) {
        return this._http.post(environment.api_url+'seller/orders/reject',data);
    }

	readytodispatch(data) {
        return this._http.post(environment.api_url+'seller/orders/stockstatus',data);
    }

    acceptrejectreturn(data) {
        return this._http.post(environment.api_url+'seller/returnrequest/approve',data);
    }

}
