import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { DatePipe } from '@angular/common';
import { AppComponent } from './app.component';
import { RegistrationComponent } from './registration/registration.component';
import { WizardModule } from "ngx-wizard";
import { ArchwizardModule } from 'angular-archwizard';
import { ContactUsComponent } from './shared/contact-us/contact-us.component';
import { BuyerAssistanceComponent } from './buyer/buyer-assistance/buyer-assistance.component';
import { MoreArticlesComponent } from './buyer/buyer-assistance/more-articles/more-articles.component';
import { ArticleDetailsComponent } from './buyer/buyer-assistance/article-details/article-details.component';
import { PrivacyPolicyComponent } from './shared/privacy-policy/privacy-policy.component';
import { SellerComponent } from './seller/seller.component';
import { BuyerNotificationComponent } from './buyer/buyer-notification/buyer-notification.component';
import { ScrollSpyDirective } from './scroll-spy.directive';
import { SellerdashboardComponent } from './seller/sellerdashboard/sellerdashboard.component';
import { SidebarSellerComponent } from './seller/sidebar-seller/sidebar-seller.component';
import { BankDetailsComponent } from './seller/bank-details/bank-details.component';
import { BusinessDetailsComponent } from './seller/business-details/business-details.component';
import { HeaderSellerComponent } from './seller/header-seller/header-seller.component';
import { FooterSellerComponent } from './seller/footer-seller/footer-seller.component';
import { AdminComponent } from './admin/admin.component';
import { HeaderAdminComponent } from './admin/header-admin/header-admin.component';
import { SidebarAdminComponent } from './admin/sidebar-admin/sidebar-admin.component';
import { FooterAdminComponent } from './admin/footer-admin/footer-admin.component';
import { ManageSellerComponent } from './admin/manage-seller/manage-seller.component';
import { NgSelectizeModule } from 'ng-selectize';
import { MyWalletComponent } from './seller/my-wallet/my-wallet.component';
import { WithdrawalRequestComponent } from './seller/sellerdashboard/withdrawal-request/withdrawal-request.component';
import { ManagePaymentMethodsComponent } from './seller/sellerdashboard/manage-payment-methods/manage-payment-methods.component';
import { AddPaymentMethodComponent } from './seller/sellerdashboard/add-payment-method/add-payment-method.component';
import { TransactionHistoryComponent } from './seller/transaction-history/transaction-history.component';
import { PendingOrdersComponent } from './seller/pending-orders/pending-orders.component';
import { BiddingOrdersComponent } from './seller/bidding-orders/bidding-orders.component';
import { ViewBidsComponent } from './seller/sellerdashboard/view-bids/view-bids.component';
import { TrackMyOrderComponent } from './seller/track-my-order/track-my-order.component';
import { OrderHistoryComponent } from './seller/order-history/order-history.component';
import { BuyerComponent } from './buyer/buyer.component';
import { AddMoneyComponent } from './seller/sellerdashboard/add-money/add-money.component';
import { OrderReportsComponent } from './seller/sellerdashboard/order-reports/order-reports.component';
import { ReturnReportComponent } from './seller/sellerdashboard/order-reports/return-report/return-report.component';
import { CancellationReportComponent } from './seller/sellerdashboard/order-reports/cancellation-report/cancellation-report.component';
import { DeliveryReportComponent } from './seller/sellerdashboard/order-reports/delivery-report/delivery-report.component';
import { DetailedOrderReportComponent } from './seller/sellerdashboard/order-reports/detailed-order-report/detailed-order-report.component';
import { SellerReportsComponent } from './seller/sellerdashboard/seller-reports/seller-reports.component';
import { SellerReportComponent } from './seller/sellerdashboard/seller-reports/seller-report/seller-report.component';
import { PaymentReportsComponent } from './seller/sellerdashboard/payment-reports/payment-reports.component';
import { ConsolidatedPaymentReportComponent } from './seller/sellerdashboard/payment-reports/consolidated-payment-report/consolidated-payment-report.component';
import { WalletTransactionComponent } from './seller/sellerdashboard/payment-reports/wallet-transaction/wallet-transaction.component';
import { OutstandingPaymentComponent } from './seller/sellerdashboard/payment-reports/outstanding-payment/outstanding-payment.component';
import { SettledPaymentComponent } from './seller/sellerdashboard/payment-reports/settled-payment/settled-payment.component';
import { ChargesAndCommisionComponent } from './seller/sellerdashboard/payment-reports/charges-and-commision/charges-and-commision.component';
import { RefundReportComponent } from './seller/sellerdashboard/payment-reports/refund-report/refund-report.component';
import { TaxReportsComponent } from './seller/sellerdashboard/tax-reports/tax-reports.component';
import { GstrReportComponent } from './seller/sellerdashboard/tax-reports/gstr-report/gstr-report.component';
import { TdsReportComponent } from './seller/sellerdashboard/tax-reports/tds-report/tds-report.component';
import { TcsReportComponent } from './seller/sellerdashboard/tax-reports/tcs-report/tcs-report.component';
import { SellerAssistanceComponent } from './seller/seller-assistance/seller-assistance.component';
import { SellerNotificationComponent } from './seller/seller-notification/seller-notification.component';
import { SellerArticleDetailsComponent } from './seller/seller-assistance/seller-article-details/seller-article-details.component';
import { SellerMoreArticlesComponent } from './seller/seller-assistance/seller-more-articles/seller-more-articles.component';
import { SupplierPerformanceComponent } from './admin/manage-seller/supplier-performance/supplier-performance.component';
import { WalletComponent } from './admin/wallet/wallet.component';
import { WalletBalanceComponent } from './admin/wallet/wallet-balance/wallet-balance.component';
import { MakePaymentComponent } from './admin/wallet/make-payment/make-payment.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { TokenInterceptorService } from './helpers/token-interceptor.service';
import { WalletTransactionHistoryComponent } from './admin/wallet/wallet-transaction-history/wallet-transaction-history.component';
import { AddNewProductComponent } from './admin/add-new-product/add-new-product.component';
import { ProductCategoryComponent } from './admin/product-category/product-category.component';
import { ProductListingComponent } from './admin/product-listing/product-listing.component';
import { ViewProductDetailsComponent } from './admin/view-product-details/view-product-details.component';
import { ProductDetailsComponent } from './buyer/product-details/product-details.component';
import { BrandComponent } from './buyer/brand/brand.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { TestingComponent } from './testing/testing.component';
import { ManageProductCategoriesComponent } from './admin/manage-product-categories/manage-product-categories.component';
import { EditMainCategoryComponent } from './admin/edit-main-category/edit-main-category.component';
import { EditSubCategoryComponent } from './admin/edit-sub-category/edit-sub-category.component';
import { AddNewCategoryComponent } from './admin/add-new-category/add-new-category.component';
import { AdminHomeComponent } from './admin/home/home.component';
import { ManageBuyerComponent } from './admin/manage-buyer/manage-buyer.component';
import { AdminDashboardComponent } from './admin/dashboard/dashboard.component';
import { PaginationService } from './helpers/pagination.service';
import { CategoryTableComponent } from './admin/manage-product-categories/category-table/category-table.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ViewRequestedDetailsComponent } from './admin/view-requested-details/view-requested-details.component';
import { ViewSellerListingProductsComponent } from './admin/view-seller-listing-products/view-seller-listing-products.component';
import { EditSellerListingComponent } from './admin/edit-seller-listing/edit-seller-listing.component';
import { BuyerdashboardComponent } from './buyer/buyerdashboard/buyerdashboard.component';
import { RouterModule } from '@angular/router';
import { SidebarBuyerComponent } from './buyer/buyerdashboard/sidebar-buyer/sidebar-buyer.component';
import { FooterBuyerComponent } from './buyer/buyerdashboard/footer-buyer/footer-buyer.component';
import { HeaderBuyerComponent } from './buyer/buyerdashboard/header-buyer/header-buyer.component';
import { BusinessDetailsBuyerComponent } from './buyer/buyerdashboard/business-details-buyer/business-details-buyer.component';
import { BankDetailsBuyerComponent } from './buyer/buyerdashboard/bank-details-buyer/bank-details-buyer.component';
import { ViewDetailsComponent } from './admin/manage-buyer/view-details/view-details.component';
import { BuyerBusinessDetailsComponent } from './admin/manage-buyer/buyer-business-details/buyer-business-details.component';
import { BuyerBankDetailsComponent } from './admin/manage-buyer/buyer-bank-details/buyer-bank-details.component';
import { SellerViewDetailsComponent } from './admin/manage-seller/seller-view-details/seller-view-details.component';
import { SellerBusinessDetailsComponent } from './admin/manage-seller/seller-business-details/seller-business-details.component';
import { SellerBankDetailsComponent } from './admin/manage-seller/seller-bank-details/seller-bank-details.component';
import { MyListingComponent } from './seller/my-listing/my-listing.component';
import { EditListtingComponent } from './admin/edit-listting/edit-listting.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ViewListingComponent } from './seller/view-listing/view-listing.component';
import { RegistrationDetailsComponent } from './registration/registration-details/registration-details.component';
import { PostOrdersComponent } from './seller/post-orders/post-orders.component';
import { AddListingComponent } from './seller/add-listing/add-listing.component';
import { AddProductComponent } from './seller/add-product/add-product.component';
import { BrandManagementComponent } from './admin/brand-management/brand-management.component';
import { EditBrandComponent } from './admin/edit-brand/edit-brand.component';
import { ReviewModerationComponent } from './admin/review-moderation/review-moderation.component';
import { SellerReviewsComponent } from './admin/review-moderation/seller-reviews/seller-reviews.component';
import { ViewProductReviewComponent } from './admin/review-moderation/view-product-review/view-product-review.component';
import { ViewSellerReviewComponent } from './admin/review-moderation/view-seller-review/view-seller-review.component';
import { SignatureInvoicingComponent } from './seller/sellerdashboard/signature-invoicing/signature-invoicing.component';
import { LogisticPartnersListComponent } from './admin/logistic-partners-list/logistic-partners-list.component';
import { AddNewPartnerComponent } from './admin/logistic-partners-list/add-new-partner/add-new-partner.component';
import { DuplicateOrderComponent } from './admin/duplicate-order/duplicate-order.component';
import { TrackOrderComponent } from './admin/track-order/track-order.component';
import { OrderDetailsComponent } from './admin/order-details/order-details.component';
import { OrderComponent } from './admin/order/order.component';
import { PostYourReqiurementComponent } from './seller/post-your-reqiurement/post-your-reqiurement.component';
import { ManagecandfComponent } from './admin/managecandf/managecandf.component';
import { ViewCanfRequestsComponent } from './admin/managecandf/view-canf-requests/view-canf-requests.component';
import { ViewApprovedCandfComponent } from './admin/managecandf/view-approved-candf/view-approved-candf.component';
import { ViewSubSellersComponent } from './admin/managecandf/view-sub-sellers/view-sub-sellers.component';
import { ViewSubSellerDetailsComponent } from './admin/managecandf/view-sub-seller-details/view-sub-seller-details.component';
import { HsnandgstmanagementComponent } from './admin/hsnandgstmanagement/hsnandgstmanagement.component';
import { AddBrandComponent } from './admin/add-brand/add-brand.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PersonalDetailsComponent } from './seller/personal-details/personal-details.component';
import { OrderQualityCheckComponent } from './admin/order-quality-check/order-quality-check.component';
import { ViewPendingQcDetailsComponent } from './admin/order-quality-check/view-pending-qc-details/view-pending-qc-details.component';
import { AdminPersonalDetailsComponent } from './admin/settings/personal-details/personal-details.component';
import { BankAccountDetailsComponent } from './admin/settings/bank-account-details/bank-account-details.component';
import { AuthorizedPersonsComponent } from './admin/settings/authorized-persons/authorized-persons.component';
import { AccessManagementComponent } from './admin/settings/access-management/access-management.component';
import { GrantAccessComponent } from './admin/settings/access-management/grant-access/grant-access.component';
import { AddBanksGlobalComponent } from './admin/settings/add-banks-global/add-banks-global.component';
import { ActivityLogsComponent } from './admin/settings/activity-logs/activity-logs.component';
import { SubSellerDetailsComponent } from './seller/sub-seller-details/sub-seller-details.component';
import { SubSellerOrdersComponent } from './seller/sellerdashboard/seller-management/sub-seller-orders/sub-seller-orders.component';
import { SubSellerRequestComponent } from './seller/sellerdashboard/seller-management/sub-seller-request/sub-seller-request.component';
import { JoinACandfComponent } from './seller/join-a-candf/join-a-candf.component';
import { PreviewComponent } from './shared/components/modal/preview/preview.component';
import { FilterPipe } from './pipes/filter.pipe';
import { CategoryComponent } from './buyer/category/category.component';
import { SearchComponent } from './buyer/search/search.component';
import { SerllerInfoComponent } from './buyer/serller-info/serller-info.component';
import { AboutSellerComponent } from './buyer/serller-info/about-seller/about-seller.component';
import { HelpComponent } from './buyer/serller-info/help/help.component';
import { SellerProductListingComponent } from './buyer/serller-info/product-listing/product-listing.component';
import { RatingReviewsComponent } from './buyer/serller-info/rating-reviews/rating-reviews.component';
import { DealComponent } from './buyer/deal/deal.component';
import { CartComponent } from './buyer/cart/cart.component';
import { ViewOrderDetailsComponent } from './seller/view-order-details/view-order-details.component';
import { NgbDateCustomParserFormatter } from './helpers/dateformat';
import { MyInvoicesComponent } from './seller/my-invoices/my-invoices.component';
import { TransportationDocumentComponent } from './seller/my-invoices/transportation-document/transportation-document.component';
import { ChargesInvoiceComponent } from './seller/my-invoices/charges-invoice/charges-invoice.component';
import { DealInvoiceComponent } from './seller/my-invoices/deal-invoice/deal-invoice.component';
import { BidsAndOffersComponent } from './seller/sellerdashboard/bids-and-offers/bids-and-offers.component';
import { BidsOffersComponent } from './buyer/bids-offers/bids-offers.component';
import { MyBidsComponent } from './buyer/my-bids/my-bids.component';
import { BuyerWalletComponent } from './buyer/buyer-wallet/buyer-wallet.component';
import { BuyerAddMoneyComponent } from './buyer/buyer-add-money/buyer-add-money.component';
import { BuyerManagePaymentMethodsComponent } from './buyer/buyer-manage-payment-methods/buyer-manage-payment-methods.component';
import { BuyerAddPaymentMethodComponent } from './buyer/buyer-add-payment-method/buyer-add-payment-method.component';
import { BuyerTransactionHistoryComponent } from './buyer/buyer-transaction-history/buyer-transaction-history.component';
import { BuyerTrackOrdersComponent } from './buyer/buyer-track-orders/buyer-track-orders.component';
import { BuyerViewOrderDetailsComponent } from './buyer/buyer-view-order-details/buyer-view-order-details.component';
import { BuyerWishlistComponent } from './buyer/buyer-wishlist/buyer-wishlist.component';
import { CheckoutComponent } from './buyer/checkout/checkout.component';
import { NgApexchartsModule } from "ng-apexcharts";
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { PersonalDetailsBuyerComponent } from './buyer/personal-details-buyer/personal-details-buyer.component';
import { WriteReviewComponent } from './buyer/write-review/write-review.component';
import { BuyerManageCategoriesComponent } from './buyer/buyer-manage-categories/buyer-manage-categories.component';
import { TermsOfUseComponent } from './shared/terms-of-use/terms-of-use.component';
import { InfringementPolicyComponent } from './shared/infringement-policy/infringement-policy.component';
import { AntiCounterfeitingPolicyComponent } from './shared/anti-counterfeiting-policy/anti-counterfeiting-policy.component';
import { CancellationReturnsPolicyComponent } from './shared/cancellation-returns-policy/cancellation-returns-policy.component';
import { CardConfirmationComponent } from './buyer/card-confirmation/card-confirmation.component';
import { AddMoneyCardConfirmationComponent } from './buyer/add-money-card-confirmation/add-money-card-confirmation.component';
import { UnclearBalanceComponent } from './admin/unclear-balance/unclear-balance.component';
import { PaymentSuccessComponent } from './buyer/payment-success/payment-success.component';
import { PaymentFailedComponent } from './buyer/payment-failed/payment-failed.component';
import { AllBidsAndOffersComponent } from './buyer/all-bids-and-offers/all-bids-and-offers.component';
import { HowItWorksComponent } from './shared/how-it-works/how-it-works.component';
import { LogisticsChargesComponent } from './admin/logistics-charges/logistics-charges.component';
import { ServicesComponent } from './shared/services/services.component';
import { PartnersComponent } from './shared/partners/partners.component';
import { AboutUsComponent } from './shared/about-us/about-us.component';
import { ManageSubsellerComponent } from './seller/manage-subseller/manage-subseller.component';
import { SubSellerProductsComponent } from './seller/sub-seller-products/sub-seller-products.component';
import { EditNewPartnerComponent } from './admin/logistic-partners-list/edit-new-partner/edit-new-partner.component';
import { InsurancePolicyComponent } from './shared/insurance-policy/insurance-policy.component';
import { BuyerOrderHistoryComponent } from './buyer/buyer-order-history/buyer-order-history.component';
import { BuyerHomeComponent } from './admin/cms/buyer-home/buyer-home.component';
import { AllBidsComponent } from './seller/all-bids/all-bids.component';
import { RegistrationPendingComponent } from './seller/registration-pending/registration-pending.component';
import { SubscribersListComponent } from './admin/subscribers-list/subscribers-list.component';
import { RequestCallbackListComponent } from './admin/request-callback-list/request-callback-list.component';
import { EnquiryListComponent } from './admin/enquiry-list/enquiry-list.component';

import { CmsIpReportsInfringementPolicyComponent } from './admin/cms/ip-reports-infringement-policy/ip-reports-infringement-policy.component';
import { CmsLogisticsPolicyComponent } from './admin/cms/logistics-policy/logistics-policy.component';
import { CmsTermsOfUseComponent } from './admin/cms/terms-of-use/terms-of-use.component';
import { CmsPrivacyPolicyComponent } from './admin/cms/privacy-policy/privacy-policy.component';
import { CmsAntiCounterfeitingPolicyComponent } from './admin/cms/anti-counterfeiting-policy/anti-counterfeiting-policy.component';
import { AdminAssistanceComponent } from './admin/admin-assistance/admin-assistance.component';
import { AdminArticleDetailsComponent } from './admin/admin-assistance/admin-article-details/admin-article-details.component';
import { AdminMoreArticlesComponent } from './admin/admin-assistance/admin-more-articles/admin-more-articles.component';
import { SubsellerDashboardComponent } from './seller/subseller-dashboard/subseller-dashboard.component';
import { InvoiceComponent } from './buyer/invoice/invoice.component';
import { ReturnPolicyComponent } from './shared/return-policy/return-policy.component';
import { CmsReturnPolicyComponent } from './admin/cms/return-policy/return-policy.component';
import { BuyerCategoryComponent } from './admin/cms/buyer-category/buyer-category.component';
import { BuyerCategoryAddComponent } from './admin/cms/buyer-category-add/buyer-category-add.component';
import { BuyerCategoryEditComponent } from './admin/cms/buyer-category-edit/buyer-category-edit.component';
import { BuyerChargesInvoiceComponent } from './buyer/buyer-charges-invoice/buyer-charges-invoice.component';
import { AdminChargesInvoiceComponent } from './admin/admin-charges-invoice/admin-charges-invoice.component';
import { AdminAllBidsComponent } from './admin/admin-all-bids/admin-all-bids.component';
import { AdminProductBidsComponent } from './admin/admin-product-bids/admin-product-bids.component';
import { BuyerTransportationDocumentsComponent } from './buyer/buyer-transportation-documents/buyer-transportation-documents.component';
import { StocksDetailsComponent } from './seller/stocks-details/stocks-details.component';
import { UnconfirmedOrdersComponent } from './admin/unconfirmed-orders/unconfirmed-orders.component';
import { UnconfirmedWalletComponent } from './admin/unconfirmed-wallet/unconfirmed-wallet.component';
import { CountriesComponent } from './admin/countries/countries.component';
import { MediaComponent } from './admin/media/media.component';

import { environment } from "../environments/environment";
import { initializeApp } from "firebase/app";
import { ReturnOrdersComponent } from './seller/return-orders/return-orders.component';
import { ReturnRequestOrdersComponent } from './admin/return-request-orders/return-request-orders.component';
import { ProductStockListingComponent } from './admin/product-stock-listing/product-stock-listing.component';
import { BuyerBuyNowPayLaterComponent } from './buyer/buyer-buy-now-pay-later/buyer-buy-now-pay-later.component';
import { BnplPaymentSuccessComponent } from './buyer/bnpl-payment-success/bnpl-payment-success.component';
import { BnplTransactionReportsComponent } from './buyer/bnpl-transaction-reports/bnpl-transaction-reports.component';
import { AdminBnplTransactionReportsComponent } from './admin/admin-bnpl-transaction-reports/admin-bnpl-transaction-reports.component';
import { BnplChargesComponent } from './admin/bnpl-charges/bnpl-charges.component';
import { SpiceMoneyUsersComponent } from './admin/spice-money-users/spice-money-users.component';

initializeApp(environment.firebase);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
    declarations: [
        AppComponent,
        RegistrationComponent,
        ContactUsComponent,
        BuyerAssistanceComponent,
        MoreArticlesComponent,
        ArticleDetailsComponent,
        PrivacyPolicyComponent,
        SellerComponent,
        BuyerNotificationComponent,
        ScrollSpyDirective,
        SellerdashboardComponent,
        SidebarSellerComponent,
        BankDetailsComponent,
        BusinessDetailsComponent,
        HeaderSellerComponent,
        FooterSellerComponent,
        AdminComponent,
        HeaderAdminComponent,
        SidebarAdminComponent,
        FooterAdminComponent,
        ManageSellerComponent,
        MyWalletComponent,
        WithdrawalRequestComponent,
        ManagePaymentMethodsComponent,
        AddPaymentMethodComponent,
        TransactionHistoryComponent,
        PendingOrdersComponent,
        BiddingOrdersComponent,
        ViewBidsComponent,
        TrackMyOrderComponent,
        OrderHistoryComponent,
        BuyerComponent,
        AddMoneyComponent,
        OrderReportsComponent,
        ReturnReportComponent,
        CancellationReportComponent,
        DeliveryReportComponent,
        DetailedOrderReportComponent,
        SellerReportsComponent,
        SellerReportComponent,
        PaymentReportsComponent,
        ConsolidatedPaymentReportComponent,
        WalletTransactionComponent,
        OutstandingPaymentComponent,
        SettledPaymentComponent,
        ChargesAndCommisionComponent,
        RefundReportComponent,
        TaxReportsComponent,
        GstrReportComponent,
        TdsReportComponent,
        TcsReportComponent,
        SellerAssistanceComponent,
        SellerNotificationComponent,
        SellerArticleDetailsComponent,
        SellerMoreArticlesComponent,
        SupplierPerformanceComponent,
        WalletComponent,
        WalletBalanceComponent,
        MakePaymentComponent,
        WalletTransactionHistoryComponent,
        AddNewProductComponent,
        ProductCategoryComponent,
        ProductListingComponent,
        ViewProductDetailsComponent,
        ProductDetailsComponent,
        TestingComponent,
        ManageProductCategoriesComponent,
        EditMainCategoryComponent,
        EditSubCategoryComponent,
        AddNewCategoryComponent,
        AdminHomeComponent,
        ManageBuyerComponent,
        AdminDashboardComponent,
        CategoryTableComponent,
        ViewRequestedDetailsComponent,
        ViewSellerListingProductsComponent,
        EditSellerListingComponent,
        BuyerdashboardComponent,
        SidebarBuyerComponent,
        FooterBuyerComponent,
        HeaderBuyerComponent,
        BusinessDetailsBuyerComponent,
        BankDetailsBuyerComponent,
        ViewDetailsComponent,
        BuyerBusinessDetailsComponent,
        BuyerBankDetailsComponent,
        SellerViewDetailsComponent,
        SellerBusinessDetailsComponent,
        SellerBankDetailsComponent,
        MyListingComponent,
        EditListtingComponent,
        ViewListingComponent,
        PostOrdersComponent,
        AddListingComponent,
        AddProductComponent,
        BrandManagementComponent,
        RegistrationDetailsComponent,
        EditBrandComponent,
        ReviewModerationComponent,
        SellerReviewsComponent,
        ViewProductReviewComponent,
        ViewSellerReviewComponent,
        SignatureInvoicingComponent,
        LogisticPartnersListComponent,
        AddNewPartnerComponent,
        DuplicateOrderComponent,
        TrackOrderComponent,
        OrderDetailsComponent,
        OrderComponent,
        PostYourReqiurementComponent,
        ManagecandfComponent,
        ViewCanfRequestsComponent,
        ViewApprovedCandfComponent,
        ViewSubSellersComponent,
        ViewSubSellerDetailsComponent,
        HsnandgstmanagementComponent,
        AddBrandComponent,
        PersonalDetailsComponent,
        OrderQualityCheckComponent,
        ViewPendingQcDetailsComponent,
        AdminPersonalDetailsComponent,
        BankAccountDetailsComponent,
        AuthorizedPersonsComponent,
        AccessManagementComponent,
        GrantAccessComponent,
        AddBanksGlobalComponent,
        ActivityLogsComponent,
        SubSellerProductsComponent,
        SubSellerDetailsComponent,
        SubSellerOrdersComponent,
        SubSellerRequestComponent,
        JoinACandfComponent,
        PreviewComponent,
        BrandComponent,
        FilterPipe,
        CategoryComponent,
        SearchComponent,
        SerllerInfoComponent,
        AboutSellerComponent,
        HelpComponent,
        SellerProductListingComponent,
        RatingReviewsComponent,
        DealComponent,
        CartComponent,
        ViewOrderDetailsComponent,
        MyInvoicesComponent,
        TransportationDocumentComponent,
        ChargesInvoiceComponent,
        DealInvoiceComponent,
        BidsAndOffersComponent,
        BidsOffersComponent,
        MyBidsComponent,
        BuyerWalletComponent,
        BuyerAddMoneyComponent,
        BuyerManagePaymentMethodsComponent,
        BuyerAddPaymentMethodComponent,
        BuyerTransactionHistoryComponent,
        BuyerTrackOrdersComponent,
        BuyerViewOrderDetailsComponent,
        BuyerWishlistComponent,
        CheckoutComponent,
        PersonalDetailsBuyerComponent,
        WriteReviewComponent,
        BuyerManageCategoriesComponent,
        TermsOfUseComponent,
        InfringementPolicyComponent,
        AntiCounterfeitingPolicyComponent,
        CancellationReturnsPolicyComponent,
        CardConfirmationComponent,
        AddMoneyCardConfirmationComponent,
        UnclearBalanceComponent,
        PaymentSuccessComponent,
        PaymentFailedComponent,
        AllBidsAndOffersComponent,
        HowItWorksComponent,
        LogisticsChargesComponent,
        ServicesComponent,
        PartnersComponent,
        AboutUsComponent,
        ManageSubsellerComponent,
        EditNewPartnerComponent,
        InsurancePolicyComponent,
        BuyerOrderHistoryComponent,
        BuyerHomeComponent,
        AllBidsComponent,
        RegistrationPendingComponent,
        SubscribersListComponent,
        RequestCallbackListComponent,
        EnquiryListComponent,
        CmsIpReportsInfringementPolicyComponent,
        CmsLogisticsPolicyComponent,
        CmsTermsOfUseComponent,
        CmsPrivacyPolicyComponent,
        CmsReturnPolicyComponent,
        CmsAntiCounterfeitingPolicyComponent,
        AdminAssistanceComponent,
        AdminArticleDetailsComponent,
        AdminMoreArticlesComponent,
        SubsellerDashboardComponent,
        InvoiceComponent,
        ReturnPolicyComponent,
        BuyerCategoryComponent,
        BuyerCategoryAddComponent,
        BuyerCategoryEditComponent,
        BuyerChargesInvoiceComponent,
        AdminChargesInvoiceComponent,
        AdminAllBidsComponent,
        AdminProductBidsComponent,
        BuyerTransportationDocumentsComponent,
        StocksDetailsComponent,
        UnconfirmedOrdersComponent,
        UnconfirmedWalletComponent,
        CountriesComponent,
        MediaComponent,
        ReturnOrdersComponent,
        ReturnRequestOrdersComponent,
        ProductStockListingComponent,
        BuyerBuyNowPayLaterComponent,
        BnplPaymentSuccessComponent,
        BnplTransactionReportsComponent,
        AdminBnplTransactionReportsComponent,
        BnplChargesComponent,
        SpiceMoneyUsersComponent ,
           ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        BrowserAnimationsModule,
        HttpClientModule,
        AngularEditorModule,
        RouterModule,
        ReactiveFormsModule,
        NgbModule,
        NgApexchartsModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        AutocompleteLibModule,
        SocialLoginModule,
        FormsModule,
        CreditCardDirectivesModule,
        DragDropModule,
        SlickCarouselModule,
        NgxSliderModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            progressBar: false,
            enableHtml: true,
            positionClass: 'toast-bottom-center',
            preventDuplicates: true
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SharedModule,
        AppRoutingModule,
        NgMultiSelectDropDownModule.forRoot(),
        WizardModule,
        ArchwizardModule,
        NgSelectizeModule
    ],
    providers: [
        DatePipe,
        PaginationService,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider('141155467608-oj5kskrqg5gqdjdatipd9fir79l9pv72.apps.googleusercontent.com'),
                    },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider('3191597614431464')
                    }
                ],
            } as SocialAuthServiceConfig,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
