import { Component, OnInit, TemplateRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PersonalDetailsService } from 'src/app/seller/personal-details/personal-details.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '../../../helpers/toastr.service';
import { EncrDecrService } from '../../../helpers/EncrDecrService';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';

@Component({
    selector: 'app-business-details-buyer',
    templateUrl: './business-details-buyer.component.html',
    styleUrls: ['./business-details-buyer.component.scss']
})
export class BusinessDetailsBuyerComponent implements OnInit {

    //@ViewChild('editaddressModal') templateRef: TemplateRef<any>;
    personsForm: FormGroup
    addShippingForm: FormGroup
    addRegisteredForm: FormGroup
    updateShippingForm: FormGroup

    public personal_detail: any = {};
    public selectedIndex: number;
    public business_detail: any = {};
    public authorized_fullname: string;
    public authorized_mobile_number: string;
    public authorized_email_id: string;
    public brand_logo_image: string;
    public selectedAddress: any = {};
    stateArray: any = [];
    cityArray: any = [];
    constructor(
        private modalService: NgbModal,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private _router: Router,
        private _toastr: ToasterService,
        private _EncrDecr: EncrDecrService,
        private _personaldetailsservice: PersonalDetailsService,
    ) { }

    ngOnInit(): void {
        this.business_detail.authorized_persons = {};
        this.personsForm = this.fb.group({
            name: this.fb.control(null, [Validators.required]),
            email: this.fb.control(null, [Validators.required, Validators.email]),
            country_code: this.fb.control(null),
            phone_number: this.fb.control(null, [Validators.required, Validators.pattern('[- +()0-9]+'), Validators.maxLength(10), Validators.minLength(10)]),
        });

        this.addShippingForm = this.fb.group({
            index: this.fb.control(null),
            address: this.fb.control(null, [Validators.required]),
            state: this.fb.control(null, [Validators.required]),
            city: this.fb.control(null, [Validators.required]),
            pincode: this.fb.control(null, [Validators.required, Validators.maxLength(6), Validators.minLength(6)]),
            address_name: this.fb.control(null)
        });

        this.addRegisteredForm = this.fb.group({
            address: this.fb.control(null, [Validators.required]),
            state: this.fb.control(null, [Validators.required]),
            city: this.fb.control(null, [Validators.required]),
            pincode: this.fb.control(null, [Validators.required, Validators.maxLength(6), Validators.minLength(6)]),
        });

        this.updateShippingForm = this.fb.group({
            address: this.fb.control(null, [Validators.required]),
            state: this.fb.control(null, [Validators.required]),
            city: this.fb.control(null, [Validators.required]),
            pincode: this.fb.control(null, [Validators.required, Validators.maxLength(6), Validators.minLength(6)]),
            address_name: this.fb.control(null)
        });

        this.getstate();
        setTimeout(() => {
            this.getdetails();
        }, 3000);
    }

    get pf() { return this.personsForm.controls; }
    getdetails() {
        this._personaldetailsservice.getdetails(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.business_detail = data['records']['business_detail'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getstate() {
        this._personaldetailsservice.getstate(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.stateArray = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getcity(type='add') {
        var data = {};
        if(type == 'update'){
            data = { state: this.updateShippingForm.get('state').value };
        }else if(type == 'add'){
            data = { state: this.addShippingForm.get('state').value };
        }else if(type == 'registered'){
            data = { state: this.addRegisteredForm.get('state').value };
        }
        
        this._personaldetailsservice.getcity(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.cityArray = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    openmdl(profilephoto) {
        this.modalService.open(profilephoto, { windowClass: 'midmodal mblbtm' });
    }
    gstmdl(gstfrq) {
        this.modalService.open(gstfrq, { windowClass: 'smmodal mblbtm' });
    }
    adrsmdl(newadrs) {
        this.modalService.open(newadrs, { windowClass: 'lgmodal mblbtm' });
    }

    updatefile(event, type) {

    }

    saveauthorizedpersons() {
        let data = {
            name: this.personsForm.get('name').value,
            email: this.personsForm.get('email').value,
            phone_number: this.personsForm.get('phone_number').value,
            country_code: ''
        };
        this._personaldetailsservice.saveauthorizedpersons(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this._toastr.success('New person has been added successfully.');
                this.getdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getfulladdress(addr) {
        var address = '';
        if (addr.address) {
            address = addr.address;
        }
        if (addr.city) {
            if (address) {
                address = address + ", " + addr.city;
            } else {
                address = addr.city;
            }

        }
        if (addr.state) {
            if (address) {
                address = address + ", " + addr.state;
            } else {
                address = addr.state;
            }
        }
        if (addr.pincode) {
            if (address) {
                address = address + ", " + addr.pincode;
            } else {
                address = addr.pincode;
            }
        }
        return address;
    }

    saveshippingform() {
        var data = {
            address: this.addShippingForm.get('address').value,
            state: this.addShippingForm.get('state').value,
            city: this.addShippingForm.get('city').value,
            pincode: this.addShippingForm.get('pincode').value,
            address_name: this.addShippingForm.get('address_name').value,
        };
        this._personaldetailsservice.saveshippingform(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this._toastr.success('New shipping address has been added successfully.');
                this.getdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    saveregisteredform() {
        var data = {
            address: this.addRegisteredForm.get('address').value,
            state: this.addRegisteredForm.get('state').value,
            city: this.addRegisteredForm.get('city').value,
            pincode: this.addRegisteredForm.get('pincode').value,
        };
        this._personaldetailsservice.buyerregisteredaddresschange(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this._toastr.success('Your registered address change request has been sent successfully.');
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getnamecode(str, str1 = null) {
        if (str != '' || str1 != '') {
            if (str1) {
                str = str + " " + str1;
            }
            var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
            var acronym = matches.join('');
            return acronym;
        }
        return '';
    }

    editaddress(type,addr, i) {
        this.selectedIndex = i;
        this.updateShippingForm.patchValue({
            address: addr.address,
            state: addr.state,
            city: addr.city,
            pincode: addr.pincode,
            address_name: addr.address_name,
        });
        setTimeout( () => {
            this.getcity('update');
            this.adrsmdl(type);
        }, 500);
        //this.modalService.open(this.templateRef, {});
    }

    updateshippingform() {
        var data = {
            index: this.selectedIndex,
            address: this.updateShippingForm.get('address').value,
            state: this.updateShippingForm.get('state').value,
            city: this.updateShippingForm.get('city').value,
            pincode: this.updateShippingForm.get('pincode').value,
            address_name: this.updateShippingForm.get('address_name').value,
        };
        this._personaldetailsservice.updateshippingform(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this._toastr.success('Shipping address details has been updated successfully.');
                this.getdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    deleteaddress(addr, i) {
        this._personaldetailsservice.deleteshippingform({ index: i }).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this._toastr.success('Shipping address has been deleted successfully.');
                this.getdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    addaddr(type) {
        this.adrsmdl(type);
    }

    uploadfile(event) {
        const file = event.target.files;
        this.brand_logo_image = file[0];
    }

    uploadbrandlogo() {
        let data: any = new FormData();
        data.append("document", this.brand_logo_image);
        this._personaldetailsservice.updatebrandlogo(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.personal_detail.profiel_image = data['image'];
                this.modalService.dismissAll();
                this.getdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

}
